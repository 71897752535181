/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { FaClone } from "react-icons/fa";
import Colors from "styles/colors";
import JSONInput from "react-json-editor-ajrm";

const copyToClipboard = require("copy-text-to-clipboard");

const wrapperCss = {
  marginBottom: "1em",
  position: "relative",
};

const copyCss = {
  background: "transparent",
  border: "none",
  color: Colors.highlight.BRIGHT_BLUE,
  position: "absolute",
  top: "1em",
  right: "3em",
  ":hover": {
    color: "white",
  },
  span: {
    fontStyle: "italic",
    marginRight: "0.5em",
    textDecoration: "underline",
  },
  zIndex: 1000,
};

const CodeSampleEditor = ({ t, code, eventHandler }) => {
  const codeString = JSON.stringify(code, null, 2);

  return (
    <div>
      <div css={wrapperCss}>
        <button
          css={copyCss}
          onClick={(e) => {
            copyToClipboard(codeString);
          }}
        >
          <span>{t("documentation:Copy")}</span> <FaClone />
        </button>
        <JSONInput
          colors={{
            backgroundColor: Colors.background.DARK_GRAY,
            colon: Colors.highlight.BRIGHT_BLUE,
            default: "white",
            keys: Colors.highlight.BRIGHT_BLUE,
            number: Colors.highlight.GREEN,
            primitive: Colors.highlight.LIGHT_BLUE,
            string: Colors.highlight.ORANGE,
          }}
          onChange={(val) => eventHandler("body", null, val.jsObject)}
          placeholder={code}
          width="100%"
        />
      </div>
    </div>
  );
};

CodeSampleEditor.propTypes = {
  code: PropTypes.object.isRequired,
  eventHandler: PropTypes.func,
};
export default withTranslation(["documentation"])(CodeSampleEditor);
