/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { MediaQueries } from "components/responsive";

import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "../components/search/DriveAway.SearchBar.container";
import VinHistorySearchBarContainer from "pages/driveaway/dashboard/components/vin-history-search/DriveAway.VinHistory.SearchBar.container";
import FiltersContainer from "pages/driveaway//components/search/DriveAway.SearchFilters.container";

// Components
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import { DriveAwaySavedSearchesPanel } from "./components/organisms/DriveAway.SavedSearchesPanel.organism";
import { VinHistorySearch } from "../dashboard/components/vin-history-search/DriveAway.VinHistory.Search";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import {
  DpuLocationCell,
  LastMileStoneCell,
  StatusCell as StatusHistoryStatusCell,
  ModifiedByCell,
  CommentCell,
} from "pages/driveaway/components/table-cells/status-history";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";
import { DPUStatus } from "../utils/enums.utils";

export const DriveAwayDashboard = ({
  entityAvailableCount,
  entitySubmittedCount,
  entityApprovedCount,
  entityDeniedCount,
  isEntityAvailableCountLoading,
  isEntitySubmittedCountLoading,
  isEntityApprovedCountLoading,
  isEntityDeniedCountLoading,
  fetchEntityCountForEachStatus,
  searchEntities,
  setSearchFilter,
  clearSearchFilter,
  resetSearchBar,
  clearSearchFilters,
  resetSavedSearch,
  solutionId,
  showFilters,
  toggleShowFilters,
  driveAwayVinHistorySearchResults,
  isVinHistoryLoading,
  isVinHistoryLoadingError,
  vinHistoryLoadingError,
  resetVinHistorySearch,
  clearVinHistoryEntities,
}) => {
  const { t } = useTranslation("driveaway-dashboard");
  useSetTitleOnMount(t("driveaway-dashboard:Dealer Pick Up"));
  useSetDescriptionOnMount(
    t("driveaway-dashboard:All VINs and their related Dealer Pick Up statuses"),
  );

  useTrackWithMixpanelOnce("Viewed Page: DriveAway / Dashboard");

  useEffect(
    () => {
      resetSearchBar();
      clearSearchFilters();
      resetSavedSearch();

      fetchEntityCountForEachStatus();
      return () => {
        resetVinHistorySearch(true);
        clearVinHistoryEntities();
      };
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  const handleAvailableChartClick = () => {
    clearSearchFilter();
    setSearchFilter("ddaStatus", [DPUStatus.AVAILABLE]);
    searchEntities(solutionId);
  };

  const handleSubmittedChartClick = () => {
    clearSearchFilter();
    setSearchFilter("ddaStatus", [DPUStatus.SUBMITTED]);
    searchEntities(solutionId);
  };

  const handleApprovedChartClick = () => {
    clearSearchFilter();
    setSearchFilter("ddaStatus", [DPUStatus.APPROVED]);
    searchEntities(solutionId);
  };

  const handleDeniedChartClick = () => {
    clearSearchFilter();
    setSearchFilter("ddaStatus", [DPUStatus.DENIED]);
    searchEntities(solutionId);
  };

  const handleClickException = (e) => {
    clearSearchFilter();
    setSearchFilter("ddaStatus", [DPUStatus.DELIVERED]);
    searchEntities(solutionId);
  };

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
    >
      <Dashboard.Tabs
        onSelect={(index) => {
          if (index === 0) {
            resetVinHistorySearch();
            clearVinHistoryEntities();
          }
        }}
      >
        <Dashboard.TabList>
          <Dashboard.Tab>{t("driveaway-dashboard:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("driveaway-dashboard:DPU VIN History")}
          </Dashboard.Tab>
        </Dashboard.TabList>

        <Dashboard.TabPanel>
          {/* If we can fetch data, it will be fetched by the effects.
           * We need to then show the UI for that data (dashboard widgets).
           */}
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                display: "flex",
                flexDirection: "column",
              },
              [MediaQueries.mediumAndUp]: {
                display: "grid",
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                gridTemplateColumns: "repeat(12, 1fr)",
                gridTemplateRows: "1fr",
                gridAutoFlow: "row",
              },
            }}
          >
            <div
              css={{
                display: "flex",
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                },
              }}
            >
              <ExceptionsPanel
                title={t("driveaway-dashboard:Finished Vehicle VINs")}
                exceptionGroups={[]}
                handleClickException={handleClickException}
                innerDivStyles={{ flexWrap: "wrap", justifyContent: "center" }}
                totalCountChartElement={
                  <Fragment>
                    {/* Available VINs chart. */}
                    <ExceptionsPanel.Chart
                      exceptionGroups={null}
                      count={entityAvailableCount ?? 0}
                      countIsLoading={isEntityAvailableCountLoading}
                      countLabel={t("driveaway-dashboard:Available")}
                      onClick={handleAvailableChartClick}
                      fillColor={
                        entityAvailableCount > 0 ? Colors.donuts.BLUE : null
                      }
                    />
                    {/* Submitted VINs chart. */}
                    <ExceptionsPanel.Chart
                      exceptionGroups={null}
                      count={entitySubmittedCount ?? 0}
                      countIsLoading={isEntitySubmittedCountLoading}
                      countLabel={t("driveaway-dashboard:Submitted")}
                      onClick={handleSubmittedChartClick}
                      fillColor={
                        entitySubmittedCount > 0 ? Colors.donuts.YELLOW : null
                      }
                    />
                    {/* Approved VINs chart. */}
                    <ExceptionsPanel.Chart
                      exceptionGroups={null}
                      count={entityApprovedCount ?? 0}
                      countIsLoading={isEntityApprovedCountLoading}
                      countLabel={t("driveaway-dashboard:Approved")}
                      onClick={handleApprovedChartClick}
                      fillColor={
                        entityApprovedCount > 0 ? Colors.donuts.GREEN : null
                      }
                    />
                    {/* Denied VINs chart. */}
                    <ExceptionsPanel.Chart
                      exceptionGroups={null}
                      count={entityDeniedCount ?? 0}
                      countIsLoading={isEntityDeniedCountLoading}
                      countLabel={t("driveaway-dashboard:Denied")}
                      onClick={handleDeniedChartClick}
                      fillColor={
                        entityDeniedCount > 0 ? Colors.donuts.RED : null
                      }
                    />
                  </Fragment>
                }
              />
            </div>

            {/* Saved Search */}
            <div
              css={{
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                },
              }}
            >
              <DriveAwaySavedSearchesPanel />
            </div>
          </div>
        </Dashboard.TabPanel>

        <Dashboard.TabPanel>
          <VinHistorySearch
            isLoading={isVinHistoryLoading}
            showError={isVinHistoryLoadingError}
            showErrorMessage={
              vinHistoryLoadingError?.response?.data?.error?.message
            }
            searchResults={driveAwayVinHistorySearchResults}
            SearchBarContainer={VinHistorySearchBarContainer}
            tableProps={{
              data: driveAwayVinHistorySearchResults,
              columns: [
                {
                  Header: t("driveaway-search:Event time"),
                  Cell: (cellInfo) => {
                    return (
                      <DateTimeCell
                        localize
                        dateTime={cellInfo.row.original.eventTs}
                      />
                    );
                  },
                  accessor: "eventTs",
                  width: 85,
                },
                {
                  Header: t("driveaway-search:Status"),
                  Cell: StatusHistoryStatusCell,
                  accessor: (d) => {
                    return {
                      status: d.status,
                      denyReasonCode: d.denyReasonCode,
                    };
                  },
                  width: 120,
                },
                {
                  Header: t("driveaway-search:DPU Location"),
                  Cell: DpuLocationCell,
                  accessor: "dpuLocation",
                  width: 100,
                },
                {
                  Header: t("driveaway-search:Last Milestone"),
                  Cell: LastMileStoneCell,
                  accessor: "milestone",
                  width: 125,
                },
                {
                  Header: t("driveaway-search:Modified By"),
                  Cell: ModifiedByCell,
                  accessor: "modifiedBy",
                },
                {
                  Header: t("driveaway-search:Comments"),
                  Cell: CommentCell,
                  accessor: "comment",
                },
              ],
              isLoading: isVinHistoryLoading,
              showPagination: false,
            }}
          ></VinHistorySearch>
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
};

DriveAwayDashboard.propTypes = {
  entityAvailableCount: PropTypes.number,
  entitySubmittedCount: PropTypes.number,
  entityApprovedCount: PropTypes.number,
  entityDeniedCount: PropTypes.number,
  isEntityAvailableCountLoading: PropTypes.bool,
  isEntitySubmittedCountLoading: PropTypes.bool,
  isEntityApprovedCountLoading: PropTypes.bool,
  isEntityDeniedCountLoading: PropTypes.bool,
  fetchEntityCountForEachStatus: PropTypes.func.isRequired,
  searchEntities: PropTypes.any,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilter: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  resetSavedSearch: PropTypes.func.isRequired,
  solutionId: PropTypes.any,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func,
  driveAwayVinHistorySearchResults: PropTypes.array.isRequired,
  isVinHistoryLoading: PropTypes.bool.isRequired,
  isVinHistoryLoadingError: PropTypes.bool,
  vinHistoryLoadingError: PropTypes.any,
  resetVinHistorySearch: PropTypes.func.isRequired,
  clearVinHistoryEntities: PropTypes.func.isRequired,
};
