import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";
import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers.js";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import {
  searchVins,
  vinsReducer,
} from "pages/shipments/redux/VinSearchStateExtensions";
import qs from "qs";

const STORE_MOUNT_POINT = "dpuAdminToolVinHistorySearch";

const CLEAR_VIN_HISTORY_ENTITIES = `${STORE_MOUNT_POINT}/CLEAR_VIN_HISTORY_ENTITIES`;

const SEARCH_CATEGORIES = [
  {
    queryKey: "vin",
    label: (_) => null,
    placeholder: (t) => t("driveaway-search:Search VIN"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("externalId", filterValue),
    loaderProperty: "searchVins",
    property: "vins",
    loadingProperty: "vinsLoading",
    minCharacters: 17,
    maxCharacters: 17,
  },
];

const fetchVinHistory = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
  state,
) => {
  const params = qs.parse(queryString);
  const externalId = params?.externalId ?? "";
  params.externalId = undefined;
  params.pageNumber = undefined;
  params.pageSize = undefined;
  const newQueryString = qs.stringify(params);

  let url = apiUrl(
    `/dda/vin-history/solution/${solutionId}/entity/${externalId}?${newQueryString}`,
  );

  // Fetch the search
  dispatch(duck.fetch(url));
};

const clearVinHistoryEntities = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VIN_HISTORY_ENTITIES });
  };
};

// Additional selectors for async search results
const getVins = (state) => state[STORE_MOUNT_POINT].vins || [];
const getVinsLoading = (state) => state[STORE_MOUNT_POINT].vinsLoading || false;

const getVinHistorySearchResults = (state) =>
  state[STORE_MOUNT_POINT]?.data || [];

// Reducer
const driveAwayVinHistorySearchBarReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_VIN_HISTORY_ENTITIES:
      if (state.data) {
        return {
          ...state,
          data: [],
          isLoadingError: false,
          hasSearchCriteriaChanged: false,
        };
      }

      return {
        ...state,
        isLoadingError: false,
        hasSearchCriteriaChanged: false,
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  null,
  fetchVinHistory,
  [driveAwayVinHistorySearchBarReducer],
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  searchVins,
  clearVinHistoryEntities,
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getVins,
  getVinsLoading,
  getVinHistorySearchResults,
};

SearchBarState.reducer = chainReducers([SearchBarState.reducer, vinsReducer]);

export default SearchBarState;
