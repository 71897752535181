/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";

import { TooltipTheme } from "./enums";
import Colors from "styles/colors";

const positionFixed = { position: "fixed" }; // This is added to prevent tooltips jitter from the bug in react-bootstrap.
const dangerStyles = {
  ".tooltip-arrow::before": { borderBottomColor: Colors.highlight.RED },
  ".tooltip-inner": { backgroundColor: Colors.highlight.RED },
  ...positionFixed,
};

export const Tooltip = ({
  placement = "bottom",
  theme = TooltipTheme.Default,
  trigger = ["hover", "focus"],
  showDelayInMs = 0,
  hideDelayInMs = 0,
  tooltipChildren,
  style = {},
  className,
  children,
}) => {
  // If we don't have a tooltip to display, just return children
  // This is useful when we want to wrap something with a tooltip but conditionally show it if there's content
  if (!tooltipChildren) {
    return children;
  }

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={{ show: showDelayInMs, hide: hideDelayInMs }}
      overlay={
        <BootstrapTooltip
          css={theme === TooltipTheme.Danger ? dangerStyles : positionFixed}
        >
          {tooltipChildren}
        </BootstrapTooltip>
      }
    >
      {/* A wrapping element is required for the overlay to appear on hover */}
      <span className={className} style={style}>
        {children}
      </span>
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  /**
   * The placement of the tooltip.
   *
   * **Note:** Use react-bootstrap's Tooltip placement prop values for this prop.
   *
   * Reference: https://react-bootstrap.netlify.app/components/overlays/#tooltip-props
   */
  placement: PropTypes.oneOf([
    "auto-start",
    "auto",
    "auto-end",
    "top-start",
    "top",
    "top-end",
    "right-start",
    "right",
    "right-end",
    "bottom-end",
    "bottom",
    "bottom-start",
    "left-end",
    "left",
    "left-start",
  ]),
  /**
   * The theme for the tooltip, which changes the background and text color.
   */
  theme: PropTypes.string,
  /**
   * What triggers the tooltip to appear.
   *
   * Available options:
   * hover
   * click
   * focus
   *
   * You can also pass in an array of strings using any of the above, like so:
   * ["click", "focus"]
   * This will treat it as an "or" statement, eg: Trigger on either a click or focus event.
   *
   * The default is:
   * ["hover", "focus"]
   */
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Delay by this amount (in milliseconds) before displaying the tooltip.
   */
  showDelayInMs: PropTypes.number,
  /**
   * Delay by this amount (in milliseconds) before hiding the tooltip.
   */
  hideDelayInMs: PropTypes.number,
  /**
   * The content to display in the tooltip.
   *
   * __NOTE__: If this is not provided, only `children` will be rendered.
   */
  tooltipChildren: PropTypes.any,
  /**
   * A style object that is passed to the wrapping <span>.
   */
  style: PropTypes.object,
  /**
   * The content to hover to display the tooltip.
   */
  children: PropTypes.any.isRequired,
  /**
   * A string for defining one or more classes that will be added to the tooltip.
   */
  className: PropTypes.string,
};

export { TooltipTheme };
