import moment from "moment";
import apiUrl from "../../api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES, FILTERS } from "./OrganizationSearch.searchOptions";

const STORE_MOUNT_POINT = "organizationSearch";

// URLS
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  let url = `${ORGANIZATIONS_URL}?${queryString}&paginate=t&locationCount=t&allOrgs=t`;

  let config = {
    headers: { "x-time-zone": moment.tz.guess() },
  };

  let transformResponse = (response) => {
    return {
      meta: response.meta,
      data: response.response,
    };
  };

  dispatch(duck.fetch(url, config, transformResponse));
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  { defaultSort: "org_name", reverseSort: false },
);
