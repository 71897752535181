import React from "react";
import { useTranslation } from "react-i18next";
import { Text, FontSize } from "components/atoms/Text.atom";
import { TimeCell } from "components/organisms/base-table/Cell/TimeCell";

export const useActiveExceptionsTable = () => {
  const { t } = useTranslation("organizations");

  let columns = [];

  columns.push({
    Header: t("fv-vin-details:Time"),
    width: 150,
    accessor: "activatedEventDate",
    Cell: (cellInfo) => (
      <TimeCell dateTime={cellInfo.row.original.activatedEventDate} localize />
    ),
  });

  columns.push({
    Header: t("fv-vin-details:Active Exception"),
    accessor: "typeName",
    Cell: (cellInfo) => {
      // H2-708: content of exceptions should be translated before it is
      // presented
      const name = cellInfo.row.original.typeName;
      return (
        <Text block size={FontSize.size12}>
          {t(`exceptions:${name}`)}
        </Text>
      );
    },
  });

  columns.push({
    Header: t("fv-vin-details:Exception Comments"),
    accessor: "activatedComments",
    Cell: (cellInfo) => {
      if (cellInfo.row.original.type === "ITSS") {
        return (
          <div>
            <Text block size={FontSize.size12}>
              {t("fv-vin-details:VIN added to ITSS ID")}
            </Text>
            <Text block size={FontSize.size12}>
              {cellInfo.row.original.activatedComments}
            </Text>
          </div>
        );
      }

      return (
        <Text block size={FontSize.size12}>
          {cellInfo.row.original.activatedComments}
        </Text>
      );
    },
  });

  return columns;
};
