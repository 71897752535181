import styled from "@emotion/styled";
import { MediaQueries } from "components/responsive";

const Styles = styled.div((props) => ({
  width: "100%",

  "@media print": {
    ".table": {
      height: "auto",
    },
  },

  ".table-container": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: props.theme.container.border,
  },

  ".table": {
    flex: "auto 1",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    borderCollapse: "collapse",
    overflow: "auto",
    marginBottom: 0,
    color: props.theme.textColor,
    position: "relative",

    ".thead": {
      minWidth: "fit-content",
      fontWeight: props.theme.header.fontWeight,
      borderBottom: props.theme.header.borderBottom,

      "&.header-fixed": {
        position: "sticky",
        zIndex: 2,
        top: 0,
      },

      ".tr": {
        display: "flex",
        flex: "1 0 auto",

        "&.headers": {
          backgroundColor: props.theme.header.backgroundColor,
          color: props.theme.header.textColor,
          boxShadow: `0 15px 15px 0 ${props.theme.header.boxShadowColor}`,
        },

        "&.filters": {
          background: props.theme.header.filters.backgroundColor,
          ".filter": {
            width: "100%",
            padding: 5,
            background: props.theme.header.filters.backgroundColor,

            input: {
              width: "100%",
              border: `1px solid
                ${props.theme.header.filters.inputBorderColor}`,
              background: props.theme.header.filters.inputBackgroundColor,
              padding: "5px 7px",
              fontSize: "inherit",
              borderRadius: 3,
              fontWeight: 400,
              outlineWidth: 0,
            },
          },
        },
      },
    },

    ".tbody": {
      overflow: "unset",
      minWidth: "fit-content",
      position: "relative",

      ".loading": {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        background: props.theme.body.loading.backgroundColor,
        zIndex: 2,
        overflow: "hidden",

        svg: {
          color: props.theme.body.loading.spinnerColor,
          width: "2.5em",
          height: "2.5em",
        },
      },

      ".loading-text": {
        position: "relative",
        textAlign: "center",
        padding: "0.75rem 1.25rem",
      },

      ".tr": {
        backgroundColor: props.theme.body.row.defaultBackgroundColor,
        filter: "blur(0)",

        ":last-child": {
          ".td": {
            borderBottom: 0,
          },
        },

        "&.highlighted": {
          backgroundColor: props.theme.body.row.highlighted.backgroundColor,
        },

        "&.disabled": {
          backgroundColor: props.theme.body.row.disabled.backgroundColor,
          opacity: 0.75,
        },

        ":hover": {
          backgroundColor: props.theme.body.row.hover.defaultBackgroundColor,
        },

        "&.clickable:hover": {
          cursor: "pointer",
          backgroundColor: props.theme.body.row.clickableHover.backgroundColor,
        },

        "&.highlighted:hover": {
          backgroundColor:
            props.theme.body.row.highlighted.hover.backgroundColor,
        },

        "&.disabled:hover": {
          backgroundColor: props.theme.body.row.disabled.backgroundColor,
        },
      },

      "&.isLoading .tr": {
        filter: "blur(2px)",
      },
    },

    ".tbody .tr:nth-of-type(odd)": {
      backgroundColor: props.theme.body.row.alternateBackgroundColor,

      "&.highlighted": {
        backgroundColor:
          props.theme.body.row.highlighted.alternateBackgroundColor,
      },

      "&.disabled": {
        backgroundColor: props.theme.body.row.disabled.alternateBackgroundColor,
        opacity: 0.75,
      },

      ":hover": {
        backgroundColor: props.theme.body.row.hover.alternateBackgroundColor,
      },

      "&.clickable:hover": {
        backgroundColor: props.theme.body.row.clickableHover.backgroundColor,
      },

      "&.highlighted:hover": {
        backgroundColor:
          props.theme.body.row.highlighted.hover.alternateBackgroundColor,
      },

      "&.disabled:hover": {
        backgroundColor: props.theme.body.row.disabled.alternateBackgroundColor,
      },
    },

    ".th, .td": {
      display: "flex",
      margin: 0,
      borderBottom: `1px solid
        ${props.theme.body.row.borderBottomColor}`,
      borderRight: "3px solid transparent",
      position: "relative",

      ":last-child": {
        borderRight: 0,
      },
    },

    ".th": {
      minHeight: 46,
      userSelect: "none",

      ".header-content": {
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0.5rem",
        display: "flex",
        height: "100%",
        position: "relative",
        width: "100%",

        ".sort-indicator": {
          marginLeft: 10,
          display: "flex",
          opacity: 0,

          "&.isSorted": {
            opacity: 1,
            color: props.theme.header.sorting.selectedColor,
          },
        },
      },

      "&.canResize:hover": {
        borderRight: `3px solid
          ${props.theme.header.resizing.hoverBorderRightColor}`,
      },

      "&.isResizing, &.isResizing:hover": {
        borderRight: `3px solid
          ${props.theme.cell.resizing.borderRightColor}`,
      },

      "&.canSort:hover": {
        backgroundColor: props.theme.header.sorting.hover.backgroundColor,
        cursor: "pointer",

        "& .sort-indicator": {
          opacity: 0.3,

          "&.isSorted": {
            opacity: 1,
            color: props.theme.header.sorting.selectedColor,
          },
        },
      },

      "&.rightAligned .header-content": {
        flexDirection: "row-reverse",

        ".sort-indicator": {
          marginRight: 10,
          marginLeft: 0,
        },
      },

      "&.centerAligned .header-content": {
        justifyContent: "center",
      },

      ".resizer": {
        cursor: "col-resize !important", // react-table automatically adds ew-resize, we want to override it
        display: "inline-block",
        width: 40,
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        transform: "translateX(50%)",
        zIndex: 1,
        touchAction: "none",
      },

      "&:last-child .resizer": {
        transform: "unset",
      },
    },

    ".tr.filters .th.isResizing, .tr.filters .th.isResizing:hover": {
      borderRight: `1px solid
        ${props.theme.cell.resizing.borderRightColor}`,
    },

    ".td": {
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "0.5rem",
      overflow: "hidden",
      borderRight: `1px solid ${props.theme.body.cell.borderRightColor}`,

      "&.rightAligned": {
        justifyContent: "flex-end",
      },

      "&.centerAligned": {
        justifyContent: "center",
      },

      "&.isResizing, &.isResizing:hover": {
        borderRight: `1px solid
          ${props.theme.cell.resizing.borderRightColor}`,
      },
    },
  },

  ".pagination-container": {
    backgroundColor: props.theme.pagination.backgroundColor,
    color: props.theme.pagination.textColor,

    "&.pagination-fixed": {
      position: "sticky",
      bottom: 0,
      zIndex: 2,
    },
  },

  ".-pagination": {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    [MediaQueries.smallAndUp]: {
      flexDirection: "row",
    },
    justifyContent: "space-between",
    alignItems: "stretch",
    flexWrap: "wrap",
    padding: 3,
    boxShadow: `0 0 15px 0 ${props.theme.pagination.boxShadowColor}`,
    borderTop: `2px solid ${props.theme.pagination.borderTopColor}`,

    ".-previous, .-next": {
      flex: "1 1",
      textAlign: "center",
    },

    ".-center": {
      flex: "1.5 1",
      textAlign: "center",
      marginBottom: 0,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-around",
    },

    ".-pageInfo": {
      display: "inline-block",
      margin: "3px 10px",
      whiteSpace: "nowrap",

      ".-pageJump": {
        display: "inline-block",

        input: {
          width: 70,
          textAlign: "center",
        },
      },
    },

    ".-pageSizeOptions": {
      margin: "3px 10px",
    },

    "input, select": {
      border: `1px solid ${props.theme.pagination.input.borderColor}`,
      background: props.theme.pagination.input.backgroundColor,
      padding: "5px 7px",
      fontSize: "inherit",
      borderRadius: 3,
      fontWeight: 400,
      outlineWidth: 0,
    },

    ".-btn": {
      appearance: "none",
      display: "block",
      width: "100%",
      height: "100%",
      border: 0,
      borderRadius: 3,
      padding: 6,
      fontSize: "1em",
      color: props.theme.pagination.input.button.textColor,
      background: props.theme.pagination.input.button.backgroundColor,
      transition: "all 0.1s ease",
      cursor: "pointer",
      outlineWidth: 0,

      "&:hover": {
        background: props.theme.pagination.input.button.hoverBackgroundColor,
      },
    },

    ".-btn[disabled]": {
      opacity: 0.5,
      cursor: "default",

      "&:hover": {
        background: props.theme.pagination.input.button.backgroundColor,
      },
    },
  },
}));

export default Styles;
