/** @jsxImportSource @emotion/react */
import { withTranslation } from "react-i18next";
import Colors from "../../../styles/colors";
import PropTypes from "prop-types";

const fieldCss = {
  border: "1px solid #ddd",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
  fontWeight: 700,
  marginBottom: "1em",
  padding: "1em 0.5em",
};

const badgeCss = {
  backgroundColor: Colors.highlight.BRIGHT_BLUE,
  borderRadius: "2em",
  color: "white",
  marginRight: "0.5em",
  padding: "0.25em 1em",
  textTransform: "uppercase",
};

const serverCss = {
  float: "right",
  fontWeight: "normal",
  marginRight: "8px",
  fontSize: "0.9em",
};

const UrlBuilder = (props) => {
  const { method, path, serverUrl, serverDescription } = props;

  const finalUrl = "https://" + serverUrl + path;

  return (
    <div css={fieldCss}>
      <span css={badgeCss}>{method}</span>
      <span>{finalUrl}</span>
      <span css={serverCss}>{serverDescription}</span>
    </div>
  );
};

UrlBuilder.propTypes = {
  method: PropTypes.string,
  path: PropTypes.string,
  serverUrl: PropTypes.string,
  serverDescription: PropTypes.string,
};
export default withTranslation(["documentation"])(UrlBuilder);
