/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FormLabel, FormGroup, FormControl } from "react-bootstrap";
import TimePicker from "rc-time-picker";

function restProps(props, componentPropTypes) {
  if (!props || !componentPropTypes) {
    return;
  }

  return _.omit(props, Object.keys(componentPropTypes));
}

export function InputElem(props) {
  const { getValidationState } = props;
  let labelElem =
    props.lbl != null ? (
      <FormLabel className="form-label">{props.lbl}</FormLabel>
    ) : null;

  const formGroup = getValidationState ? (
    <FormGroup
      validationState={getValidationState()}
      css={{ marginBottom: "1rem" }}
    >
      {labelElem}
      {
        <FormControl
          onChange={(evt) => props.handler(evt.currentTarget.value, props.evt)}
          placeholder={props.placeholder}
          type="text"
          value={props.val}
          {...restProps(props, InputElem.propTypes)}
        />
      }
    </FormGroup>
  ) : (
    <FormGroup css={{ marginBottom: "1rem" }}>
      {labelElem}
      {
        <FormControl
          onChange={(evt) => props.handler(evt.currentTarget.value, props.evt)}
          placeholder={props.placeholder}
          type="text"
          value={props.val}
          {...restProps(props, InputElem.propTypes)}
        />
      }
    </FormGroup>
  );

  return <div>{formGroup}</div>;
}
InputElem.propTypes = {
  lbl: PropTypes.string,
  evt: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  val: PropTypes.string,
};

export function SelectElem(props) {
  const { t } = props;
  const optionElems = props.list.map((item, i) => {
    const label = t ? t(item) : item;
    return (
      <option key={i} value={i}>
        {label}
      </option>
    );
  });

  let labelElem =
    props.lbl != null ? (
      <FormLabel className="form-label">{props.lbl}</FormLabel>
    ) : null;

  let controlProps = Object.assign({}, props);
  if (t) {
    _.unset(controlProps, "t");
  }

  return (
    <FormGroup css={props.style}>
      {labelElem}
      <FormControl
        as="select"
        placeholder={props.placeholder}
        onChange={(evt) => props.handler(evt.currentTarget.value, props.evt)}
        value={props.val}
        {...restProps(controlProps, SelectElem.propTypes)}
      >
        {optionElems}
      </FormControl>
    </FormGroup>
  );
}
SelectElem.propTypes = {
  list: PropTypes.array.isRequired,
  lbl: PropTypes.string,
  evt: PropTypes.string,
  placeholder: PropTypes.string,
  handler: PropTypes.func,
  val: PropTypes.number.isRequired,
};

export function StaticElem(props) {
  return (
    <FormGroup css={{ marginBottom: "1rem" }}>
      <FormLabel className="form-label">{props.lbl}</FormLabel>
      <FormControl.Static>{props.children}</FormControl.Static>
    </FormGroup>
  );
}
StaticElem.propTypes = {
  lbl: PropTypes.string,
};

export function TimePickerElem(props) {
  const { time, lbl, onChange } = props;
  return (
    <FormGroup css={{ marginBottom: "1rem" }}>
      <FormLabel className="form-label">{lbl}</FormLabel>
      <TimePicker
        className="full-col"
        showSecond={false}
        defaultValue={time}
        format={"h:mm a"}
        use12Hours
        onChange={onChange}
        allowEmpty={false}
      />
    </FormGroup>
  );
}
TimePickerElem.propTypes = {
  lbl: PropTypes.string,
  time: PropTypes.object.isRequired /* a moment instance */,
  onChange: PropTypes.func,
};
