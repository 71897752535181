import { connect } from "react-redux";
import { getSurgicalToteTrackingSolutionId } from "../redux/SurgicalToteTrackingSelectors";
import { SurgicalToteTrackingSearch } from "./SurgicalToteTracking.Search.page";
import SurgicalToteTrackingSearchBarState from "../redux/SurgicalToteTrackingSearchBarState";

function mapStateToProps(state) {
  return {
    solutionId: getSurgicalToteTrackingSolutionId(state),
    isLoading: SurgicalToteTrackingSearchBarState.selectors.getIsLoading(state),
    searchResults:
      SurgicalToteTrackingSearchBarState.selectors.getSearchResults(state),
    totalEntities:
      SurgicalToteTrackingSearchBarState.selectors.getTotalCountForSearch(
        state,
      ),
    totalEntitiesIsLoading:
      SurgicalToteTrackingSearchBarState.selectors.getTotalCountForSearchIsLoading(
        state,
      ),
    sortColumn:
      SurgicalToteTrackingSearchBarState.selectors.getSortColumn(state),
    reverseSort:
      SurgicalToteTrackingSearchBarState.selectors.getReverseSort(state),
    page: SurgicalToteTrackingSearchBarState.selectors.getPage(state),
    pageSize: SurgicalToteTrackingSearchBarState.selectors.getPageSize(state),
    totalPages:
      SurgicalToteTrackingSearchBarState.selectors.getTotalPageCountForSearch(
        state,
      ),
    showFilters:
      SurgicalToteTrackingSearchBarState.selectors.getShowAdvancedSearch(state),
    exportIdentifier:
      SurgicalToteTrackingSearchBarState.selectors.getExportIdentifier(state),
    exportName:
      SurgicalToteTrackingSearchBarState.selectors.getExportName(state),
    isExporting:
      SurgicalToteTrackingSearchBarState.selectors.getIsExporting(state),
    exportFailed:
      SurgicalToteTrackingSearchBarState.selectors.getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetSearch: () => {
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.resetSearchAndFilters(),
      );
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.searchEntities(),
      );
    },
    toggleShowFilters: (showFilters) =>
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.toggleShowFilters(
          showFilters,
        ),
      ),
    pushDetailsView: (id) =>
      dispatch({
        type: "SURGICAL_TOTE_TRACKING_DETAILS",
        payload: { id },
      }),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: (solutionId) => {
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.exportSearch(
          solutionId,
        ),
      );
    },
    clearExportErrors: () => {
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.clearExportErrors(),
      );
    },
    resetExport: () => {
      dispatch(SurgicalToteTrackingSearchBarState.actionCreators.resetExport());
    },
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SurgicalToteTrackingSearch);
