import * as _ from "lodash";
import Colors from "styles/colors";
import { sortEntityExceptions } from "./entity.utils";

type Hold = {
  id: number;
  activatedComments?: string;
  activatedEventDate: string;
  activatedReceiveDate: string;
  clearedComments?: string;
  clearedEventDate?: string;
  clearedReceiveDate?: string;
  status: string;
  type: string;
  typeName: string;
  type_name: string;
};

type Exception = {
  id: number;
  activatedComments?: string;
  activatedEventDate: string;
  activatedReceiveDate: string;
  clearedComments?: string;
  clearedEventDate?: string;
  clearedReceiveDate?: string;
  severity?: "ERROR" | "WARNING" | "OK";
  status: string;
  type: string;
  name?: string;
  typeName: string;
};

type ExceptionCount = Pick<Exception, "severity"> & {
  text: string;
  count: number;
};

export const buildExceptionCounts = (
  holds: Hold[],
  exceptions: Exception[],
): ExceptionCount[] => {
  const excludedTypes = ["In Hold", "On Hold"];
  const filteredExceptions = exceptions.filter(
    (exception) => !excludedTypes.includes(exception.typeName),
  );
  const groupedExceptions = _.groupBy(filteredExceptions, "typeName");
  const exceptionPairs = Object.keys(groupedExceptions).map((typeName) => {
    return {
      text: typeName,
      count: groupedExceptions[typeName].length,
    };
  });

  if (holds && holds.length > 0) {
    exceptionPairs.push({ text: "On Hold", count: holds.length });
  }

  return sortEntityExceptions(exceptionPairs, "text");
};

export const buildExceptionCountsWithSeverity = (
  exceptions: Exception[],
  showAllException = false,
) => {
  // Only show one delayed exception category with total count of all exceptions (on-holds and delayed)
  if (exceptions?.length > 0) {
    let result: any[] = [];
    if (showAllException) {
      result = exceptions.map((exception) => {
        return {
          text: exception.name,
          severity: "ERROR",
          count: 1,
        };
      });
      return result;
    }
    return [
      {
        text: exceptions?.[0]?.typeName,
        severity: exceptions?.[0]?.severity,
        count: exceptions.length,
      },
    ];
  } else {
    return [];
  }
};

export const getExceptionColor = (totalExceptionCount: number) => {
  return totalExceptionCount === 0
    ? Colors.highlight.GREEN
    : Colors.highlight.RED;
};
