/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";

import { Separator } from "components/atoms/Separator.atom";
import {
  ExceptionCountGroup,
  ExceptionCountGroupPropTypes,
} from "components/molecules/ExceptionCountGroup.molecule";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { getExceptionChartData } from "components/utils/exceptions.utils";

export const ExceptionsPanel = ({
  title,
  exceptionGroups,
  totalCount,
  totalCountLabel,
  totalCountIsLoading,
  chartLabel,
  handleClickException,
  handleClickGraph,
  totalCountChartElement,
  outerDivStyles = {},
  innerDivStyles = {},
  showNumberSeparator,
}) => {
  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content
        style={{
          minHeight: 50,
        }}
      >
        <div
          className="d-flex h-100 flex-column flex-xl-row justify-content-around"
          css={{ ...outerDivStyles }}
        >
          <div
            className="align-self-center d-flex"
            css={{ ...innerDivStyles }}
            data-qa={`exception-count-total-${
              totalCountLabel
                ? totalCountLabel.replaceAll(/ +/g, "-").toLowerCase()
                : ""
            }`}
          >
            {totalCountChartElement ?? (
              <ExceptionsPanel.Chart
                exceptionGroups={exceptionGroups}
                count={totalCount}
                countLabel={totalCountLabel}
                countIsLoading={totalCountIsLoading}
                chartLabel={chartLabel}
                onClick={handleClickGraph}
                showNumberSeparator={showNumberSeparator}
              />
            )}
          </div>

          {exceptionGroups.map((exceptionGroup, i) => {
            return (
              <React.Fragment key={i}>
                <ExceptionCountGroup
                  isLoading={exceptionGroup.isLoading}
                  title={exceptionGroup.title}
                  exceptions={exceptionGroup.exceptions}
                  clickHandler={handleClickException}
                  style={exceptionGroup.style}
                  countStyles={exceptionGroup.countStyles}
                  showNumberSeparator={showNumberSeparator}
                />
                {i < exceptionGroups.length - 1 ? (
                  <Separator className="m-md-2" />
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const ExceptionsPanelPropTypes = {
  /** Title for the exception panel. */
  title: PropTypes.string.isRequired,
  /** Array of exception groups. */
  exceptionGroups: PropTypes.arrayOf(
    PropTypes.exact({
      ...ExceptionCountGroupPropTypes,
      includeInDonutChart: PropTypes.bool,
      /** The style object applied to the element wrapping the exception counts. */
      style: PropTypes.object,
    }),
  ),
  /** Total count to display in the chart. */
  totalCount: PropTypes.number,
  /** The label for the total count. */
  totalCountLabel: PropTypes.string,
  /** Loading for the total count. */
  totalCountIsLoading: PropTypes.bool,
  /** Label for the chart. */
  chartLabel: PropTypes.string,
  /** Handler for clicking on an exception. */
  handleClickException: PropTypes.func,
  /** Handler for clicking on a the chart. */
  handleClickGraph: PropTypes.func,
  /** The element(s) used for the chart. Usually displays the total count of excepti */
  totalCountChartElement: PropTypes.node,
  outerDivStyles: PropTypes.object,
  innerDivStyles: PropTypes.object,
  showNumberSeparator: PropTypes.bool,
};

ExceptionsPanel.propTypes = ExceptionsPanelPropTypes;

const ExceptionsPanelChart = ({
  exceptionGroups,
  count,
  countLabel,
  countIsLoading,
  chartLabel,
  onClick,
  percentage,
  chartLabelAlign,
  fontSize,
  styles = {},
  fillColor,
  showNumberSeparator,
}) => {
  // Get the exceptions in exceptionGroups marked as true for includeInDonutChart
  const chartExceptions =
    exceptionGroups
      ?.filter((exceptionGroup) => exceptionGroup.includeInDonutChart)
      .map((exceptionGroup) => exceptionGroup.exceptions)
      .flat() ?? [];

  const exceptionsCount = chartExceptions.reduce(
    (acc, curr) => acc + curr.count,
    0,
  );
  const chartData = getExceptionChartData(
    chartExceptions,
    count,
    exceptionsCount,
  );

  return (
    <DonutChart
      data={chartData}
      handler={onClick ?? null}
      label={chartLabel}
      percentage={percentage}
      total={count}
      totalLabel={countLabel}
      isLoading={countIsLoading}
      chartLabelAlign={chartLabelAlign}
      fontSize={fontSize}
      styles={styles}
      fillColor={fillColor}
      showNumberSeparator={showNumberSeparator}
    />
  );
};
ExceptionsPanelChart.propTypes = {
  exceptionGroups: ExceptionsPanelPropTypes.exceptionGroups,
  count: ExceptionsPanelPropTypes.totalCount,
  countLabel: ExceptionsPanelPropTypes.totalCountLabel,
  countIsLoading: ExceptionsPanelPropTypes.totalCountIsLoading,
  chartLabel: ExceptionsPanelPropTypes.chartLabel,
  onClick: ExceptionsPanelPropTypes.handleClickGraph,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chartLabelAlign: PropTypes.string,
  styles: PropTypes.object,
  fontSize: PropTypes.number,
  fillColor: PropTypes.string,
  showNumberSeparator: PropTypes.bool,
};
ExceptionsPanel.Chart = ExceptionsPanelChart;
