import { useTranslation } from "react-i18next";

import { SourceService } from "modules/notifications/utils/const";

interface UseBatchUploadNotificationProps {
  context: { filename?: string; presignedUrl?: string; text?: string };
  sourceService: string;
}

export const useBatchUploadNotification = ({
  context,
  sourceService,
}: UseBatchUploadNotificationProps) => {
  const { t } = useTranslation("header-bar");

  const source = sourceService?.toUpperCase();
  const isPartViewDealerOnboarding = context?.filename?.includes(
    "partview-dealer-onboarding",
  );

  const getSuccessTranslatedHeaderFooter = () => {
    switch (source) {
      case SourceService.CONTAINER_TRACKING: {
        return {
          header: t("header-bar:Route Management Batch Upload Success"),
          footer: t(
            "header-bar:All batch upload have been successfully processed.",
          ),
        };
      }
      case SourceService.PARTVIEW: {
        // TODO: This if condition has to be corrected to new stich case when implementing CAT-4837
        // To display message don't depend on sourceService
        // Get a new field to display respective messages
        if (isPartViewDealerOnboarding) {
          return {
            header: t(
              "header-bar:PartView Dealer Onboard Uploaded Successfully",
            ),
            footer: t(
              "header-bar:All dealer organizations have been successfully created.",
            ),
          };
        }

        return {
          header: t(
            "header-bar:PartView Delivery Status Update Uploaded Successfully",
          ),
          footer: t(
            "header-bar:All batch upload have been successfully processed.",
          ),
        };
      }
      case SourceService.LOCATION: {
        return {
          header: t("header-bar:Location Import Uploaded Successfully"),
          footer: t("header-bar:All locations have been successfully created."),
        };
      }
      default:
        return {
          header: t("header-bar:Batch Upload Success"),
          footer: t(
            "header-bar:All batch upload have been successfully processed.",
          ),
        };
    }
  };

  const getPartialFailedTranslatedHeaderFooter = () => {
    switch (source) {
      case SourceService.CONTAINER_TRACKING: {
        return {
          header: t("header-bar:Route Management Batch Upload Success"),
          footer: t(
            "header-bar:Batch upload has been processed, but at least one may have failed. Download the attached CSV file for more details.",
          ),
        };
      }
      case SourceService.PARTVIEW: {
        // TODO: This if condition has to be corrected to new stich case when implementing CAT-4837
        // To display message don't depend on sourceService
        // Get a new field to display respective messages
        if (isPartViewDealerOnboarding) {
          return {
            header: t(
              "header-bar:PartView Dealer Onboard Partial Error Log Available for Download",
            ),
            footer: t(
              "header-bar:Your dealer onboard file included rows with errors. Download this report to review the issues.",
            ),
          };
        }

        return {
          header: t(
            "header-bar:PartView Delivery Status Partial Error Log Available For Download",
          ),
          footer: t(
            "header-bar:Your Delivery Status Update file included rows with errors. Download this report to review the issues.",
          ),
        };
      }
      case SourceService.LOCATION: {
        return {
          header: t(
            "header-bar:Location Import Partial Error Report Available For Download",
          ),
          footer: t(
            "header-bar:Your location import was partially successful. Some locations were created, but others encountered errors. Download this report to review the issues.",
          ),
        };
      }
      default:
        return {
          header: t("header-bar:Batch Upload Success"),
          footer: t(
            "header-bar:Batch upload has been processed, but at least one may have failed. Download the attached CSV file for more details.",
          ),
        };
    }
  };

  const getFailedTranslatedHeaderFooter = () => {
    switch (source) {
      case SourceService.CONTAINER_TRACKING: {
        return {
          header: t("header-bar:Route Management Batch Upload Failed"),
          footer: t(
            "header-bar:Download the attached CSV file for more details.",
          ),
        };
      }
      case SourceService.PARTVIEW: {
        // TODO: This if condition has to be corrected to new stich case when implementing CAT-4837
        // To display message don't depend on sourceService
        // Get a new field to display respective messages
        if (isPartViewDealerOnboarding) {
          return {
            header: t(
              "header-bar:PartView Dealer Onboard Partial Error Log Available for Download",
            ),
            footer: t(
              "header-bar:Your dealer onboard file included rows with errors. Download this report to review the issues.",
            ),
          };
        }

        return {
          header: t(
            "header-bar:PartView Delivery Status Error Log Available For Download",
          ),
          footer: t(
            "header-bar:Your Delivery Status Update file included rows with errors. Download this report to review the issues.",
          ),
        };
      }
      case SourceService.LOCATION: {
        return {
          header: t(
            "header-bar:Location Import Error Report Available For Download",
          ),
          footer: t(
            "header-bar:Your location import encountered errors and no locations were created. Download this report to review the issues.",
          ),
        };
      }
      default:
        return {
          header: t("header-bar:Batch Upload Failed"),
          footer: t(
            "header-bar:Download the attached CSV file for more details.",
          ),
        };
    }
  };

  return {
    getSuccessTranslatedHeaderFooter,
    getPartialFailedTranslatedHeaderFooter,
    getFailedTranslatedHeaderFooter,
  };
};
