import axios from "axios";
import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { ActionStatus } from "../const";

const STORE_MOUNT_POINT = "SetManualEtaRangeTool";

// Actions
const CLEAR_ACTION_STATUS = `${STORE_MOUNT_POINT}/CLEAR_ACTION_STATUS`;
const SUBMIT_ACTION = `${STORE_MOUNT_POINT}/SUBMIT_ACTION`;
const SUBMIT_ACTION_SUCCEEDED = `${STORE_MOUNT_POINT}/SUBMIT_ACTION_SUCCEEDED`;
const SUBMIT_ACTION_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_ACTION_FAILED`;
const SUBMIT_ACTION_PARTIAL_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_ACTION_PARTIAL_SUCCESS`;

const getSetManualEtaRangeUrl = (solutionId) => {
  return apiUrl(`/entity/solution/${solutionId}/manual_eta_range`);
};

// Selector
function getActionStatus(state) {
  return state[STORE_MOUNT_POINT].actionStatus;
}

function getRequestResults(state) {
  return state[STORE_MOUNT_POINT].requestResults;
}

//Action Creators
function clearActionStatus() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ACTION_STATUS });
  };
}

function submitBulkAction(solutionId, payload) {
  return (dispatch) => {
    dispatch({ type: SUBMIT_ACTION });
    const url = getSetManualEtaRangeUrl(solutionId);
    const config = {
      headers: {
        Accept: "text/csv",
      },
    };
    return axios
      .post(url, payload, config)
      .then((response) => {
        if (!response.data.status && response.status !== 204) {
          throw new Error("Missing data");
        }
        if (response.data.status === "FAILED") {
          if (!response.data.failed_data) {
            throw new Error("Missing data");
          }
          dispatch({
            type: SUBMIT_ACTION_PARTIAL_SUCCESS,
            payload: response.data.failed_data,
          });
        } else {
          dispatch({
            type: SUBMIT_ACTION_SUCCEEDED,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: SUBMIT_ACTION_FAILED, error });
      });
  };
}

// Initial state
const initialState = {
  actionStatus: null,
  requestResults: null,
};

// Reducer
function SetManualEtaRangeToolReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
        requestResults: null,
      };
    case SUBMIT_ACTION:
      return {
        ...state,
        actionStatus: ActionStatus.IN_PROGRESS,
      };
    case SUBMIT_ACTION_SUCCEEDED:
      return {
        ...state,
        actionStatus: ActionStatus.SUCCESS,
      };
    case SUBMIT_ACTION_FAILED:
      return {
        ...state,
        actionStatus: ActionStatus.FAILED,
      };
    case SUBMIT_ACTION_PARTIAL_SUCCESS:
      return {
        ...state,
        actionStatus: ActionStatus.PARTIAL_SUCCESS,
        requestResults: action.payload,
      };
    default:
      return state;
  }
}

const SetManualETARangeToolState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    clearActionStatus,
    submitBulkAction,
  },
  selectors: {
    getActionStatus,
    getRequestResults,
  },
  reducer: chainReducers([SetManualEtaRangeToolReducer]),
};
export default SetManualETARangeToolState;
