import moment from "moment";
import _ from "lodash";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "./UsersSearch.searchOptions";
import { BrowserStorage } from "utils/browser-storage.utils";

const STORE_MOUNT_POINT = "userSearch";

// URLS
const getOrganizationsUrl = (solutionId, queryString) => {
  const organizationId = BrowserStorage.currentOrganization;
  return apiUrl(`/iam/organizations/${organizationId}/members?${queryString}`);
};

const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  let url = getOrganizationsUrl(solutionId, queryString);

  let config = {
    headers: { "x-time-zone": moment.tz.guess() },
  };

  let transformResponse = (response) => {
    return {
      meta: response.meta,
      data: response.response,
    };
  };

  dispatch(duck.fetch(url, config, transformResponse));
};

const UsersSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  null,
  fetchSearch,
  [],
  { defaultSort: "email", reverseSort: false },
);

UsersSearchBarState.actionCreators = {
  ...UsersSearchBarState.actionCreators,
  exportSearch: _.partial(
    UsersSearchBarState.actionCreators.exportEntities,
    getOrganizationsUrl,
    null,
    { headers: { Accept: "text/csv;version=basic" } },
    "fv-users",
  ),
};

export default UsersSearchBarState;
