/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import SectionHeader from "./SectionHeader";
import CodeSample from "./CodeSample";
import SelectField from "components-old/forms/fields/SelectField";

const requestsCss = {
  padding: "1em 0",
};

const SampleRequests = (props) => {
  const { t, sample } = props;

  // Map to options array
  const codeSampleOptions = _.values(sample).map((example, i) => {
    return {
      label: t(`documentation-remote:${example.summary}`) || "",
      value: i,
      code: example.value,
    };
  });

  // Create trackable state
  const [codeSampleKey, setCodeSampleKey] = useState(null);
  const [codeSampleCode, setCodeSampleCode] = useState(null);

  useEffect(() => {
    // Every time props.sample updates (meaning, SampleRequests has been re-mounted)
    // Reset the sampleKey and sampleCode to the first result
    if (codeSampleOptions.length > 0 && codeSampleKey == null) {
      setCodeSampleKey(codeSampleOptions[0].value);
      setCodeSampleCode(codeSampleOptions[0].code);
    }
  }, [codeSampleOptions, codeSampleKey]);

  const showCodeSample = (key) => {
    setCodeSampleKey(key);
    setCodeSampleCode(codeSampleOptions[key].code);
  };

  return (
    <div id="requests" css={requestsCss}>
      <SectionHeader title={t("documentation:Request Samples")} />
      <SelectField
        options={codeSampleOptions}
        stateValue={codeSampleKey}
        onChange={(selected) => showCodeSample(selected.value)}
      />
      {codeSampleCode && (
        <CodeSample key={codeSampleKey} code={codeSampleCode} />
      )}
    </div>
  );
};

SampleRequests.propTypes = {
  sample: PropTypes.object,
};
export default withTranslation(["documentation", "documentation-remote"])(
  SampleRequests,
);
