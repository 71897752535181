/** @jsxImportSource @emotion/react */
/**
 * TODO: Make a major refactory rethinking everything is here, similar to what
 * have been done on the old BaseMap and its children. Use more elements coming
 * from SimpleMap and spliting this into several files using techniques similar
 * to the one we used on ClumpingMap.
 */
import { createRoot } from "react-dom/client";
import _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import Colors from "styles/colors";

import { withResizeDetector } from "components/hooks/resize-detector";
import { SimpleMap } from "./SimpleMap";
import { getDistance, lerp } from "../utils/geo";
import { calculateRoute } from "../utils/routes";
import {
  hashNum,
  getStopLatLong,
  stopHasLocation,
  getStopModes,
  stopPositionStringify,
  shipmentUpdateStringify,
  getPositionsForShipments,
  getUpdateLatLong,
} from "../utils/RoutingMapUtils";
import { utcStringToMoment } from "../../../utils/date-time";
import {
  getNextStop,
  getCurrentModeName,
} from "../../shipment-detail/ShipmentUtils";

import { CoordinateInfoPopup } from "../widgets/CoordinateInfoPopup";

import {
  LadChicletSVG,
  MadChicletSVG,
  BoxChicletSVG,
  BreadCrumbSVG,
  AlertChicletSVG,
} from "../../../components/chiclets";
import DomainDataState from "../../domain-data/DomainDataState";
import HereMapPlatform from "../platforms/here/HereMapPlatform";
import MapState from "../MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

const TRUCK_MODE = 1;

const GENERIC_HASH_NO_STOPS = "GENERIC_HASH_NO_STOPS";

// Define the Z order of the elements on the map.
// These are defined in ascending order.
// eg: Highest number is highest in the z order and thus shows up on top.
// Note: 0 is the default.
const EXPECTED_ROUTE_Z_INDEX = 1;
const ACTUAL_ROUTE_Z_INDEX = 2;
const ACTUAL_ROUTE_ARROWS_Z_INDEX = 3;
const LOCATION_Z_INDEX = 4;
const MAD_Z_INDEX = 5;
const SELECTED_COORDINATE_Z_INDEX = 6;

const EXPECTED_ROUTE_LINE_WIDTH = 5;
const ACTUAL_ROUTE_LINE_WIDTH = 10;

class RoutingMap extends SimpleMap {
  constructor(props) {
    super(props);

    this.expectedRoutingParameters = {
      routingMode: "fast",
      transportMode: "truck",
      "avoid[features]": "ferry",
      "truck[grossWeight]": "30000", // In kilograms
      "truck[height]": "415", // In centimeters
      "truck[length]": "5300", // In centimeters
      "truck[type]": "tractor",
      "truck[trailerCount]": "1",
      // Disables traffic.
      // If we want to maximize # of waypoints per request, disable this,
      // and make sure to update MAX_WAYPOINTS_PER_REQUEST to 120.
      //departureTime: "any",
      return: "summary,polyline",
    };
    this.actualRoutingParameters = {
      routingMode: "fast",
      transportMode: "truck",
      "avoid[features]": "ferry",
      departureTime: "any", // Disables traffic (unnecessary for the actual route?)
      return: "summary,polyline",
    };

    this.state = {
      hasRescaledOnce: false,
    };

    this.shipmentHash = {};
    this.locationsHash = {};
    this.shipmentGroupings = {};
    this.currentZoom = 0;
    this.processedShipments = [];

    this.handlerExpectedRoute = this.handlerExpectedRoute.bind(this);
    this.handlerActualRoute = this.handlerActualRoute.bind(this);
    this.markerClickHandler = this.markerClickHandler.bind(this);
    this.mapViewChanged = this.mapViewChanged.bind(this);
    this.coordMarkerClickHandler = this.coordMarkerClickHandler.bind(this);
    this.generateExpectedNonTruckRoute =
      this.generateExpectedNonTruckRoute.bind(this);
  }

  componentDidMount() {
    // Clear our state
    this.shipmentHash = {};
    this.locationsHash = {};
    this.shipmentGroupings = {};
    this.processedShipments = [];

    this.initMaps();
    this.initAll();

    this.props.coordinates.forEach((coordinate) =>
      this.geocodeCoordinate(coordinate),
    );
  }

  componentWillUnmount() {
    this.props.clearAllCoordinates();
  }

  initMaps() {
    // Initialize our map
    this.initMap();

    // SH-6704:
    // Due to some race conditions (related to [Bara and Zade believe] loading organizations and defining
    // the activeOrganization), sometimes the map doesn't load correctly.
    // It appears as a white map with no map tiles. Resolving the parent issue is a large undertaking.
    // So we came up with this solution, which forces the map to "resize" and re-zoom (to resolve a zooming issue).
    // We really just needed the map to refresh itself so that it is visible, but resizing caused the map to zoom all the way out,
    // so we called the rescaleMap function which also re-zooms the map as well.
    // Note: Manually resizing the browser window also resolves the white map issue, resizing basically mimicing that.
    // https://stackoverflow.com/questions/71584484/here-maps-for-javascript-is-always-displaying-a-blank-map-in-angular-13-without/71599020#71599020
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.setState({ hasRescaledOnce: false }, this.rescaleMap);
      });
    }, 500);

    // Since we can't predict how long loading orgs will take, let's do this again after 2 seconds.
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.setState({ hasRescaledOnce: false }, this.rescaleMap);
      });
    }, 2000);
  }

  initAll() {
    // H1-1263
    // Process any shipments we know of at mount time
    this.initShipments(this.props.shipments, this.props.isMultileg);
  }

  initShipments(shipments, isMultileg) {
    let _this = this;

    let numShipments = _.size(shipments) - 1;

    shipments.forEach(function (shipment, i) {
      if (!shipment?.id) {
        return;
      }

      let existingShipment = _this.processedShipments.filter(
        (s) => s.id === shipment.id,
      );

      // If it wasn't found, it's a new shipment
      if (existingShipment.length === 0) {
        _this.processedShipments.push(shipment);
        _this.processNewShipment(shipment, i, numShipments, isMultileg);
      }
    });

    // After processing shipments, rescale so that all routes are visible.
    // PV-4: If we added shipments to the array after initial render,
    //       the map would only zoom to the first shipment's route.
    this.setState({ hasRescaledOnce: false }, this.rescaleMap);
  }

  componentDidUpdate(prevProps) {
    const { shipments, isMultileg, showHeatmap, heatmapCoords, selectedLegID } =
      this.props;
    const { width, height } = this.props.size;

    if (selectedLegID !== prevProps.selectedLegID) {
      this.setState({ hasRescaledOnce: false }, this.rescaleMap);
    }

    /* DEV-1307 check for change in shipment due to crossborder tab change, reset map  */
    if (
      !_.isEqual(
        shipments.map((s) => (s ? s.id : null)),
        prevProps.shipments.map((s) => (s ? s.id : null)),
      ) &&
      !_.isEmpty(shipments[0])
    ) {
      this.clearMap([MapCoordinateType.CURRENT_LOCATION]);
      this.shipmentHash = {};
      this.locationsHash = {};
      this.shipmentGroupings = {};
      this.processedShipments = [];
    }

    // Clear map and toggle heat map / route map when showHeatmap changes
    if (showHeatmap && !prevProps.showHeatmap) {
      // DEV-1658 Preserve geofences and LADs when switching into heatmap view.
      this.clearMap([
        MapCoordinateType.CURRENT_LOCATION,
        "geofence",
        "mapmarker-lad",
      ]);
      this.initHeatMap(heatmapCoords);
    } else if (!showHeatmap && prevProps.showHeatmap) {
      this.clearMap([MapCoordinateType.CURRENT_LOCATION]);
      this.shipmentGroupings = {};
      this.processedShipments = [];
      this.shipmentHash = {};
      this.locationsHash = {};

      this.deinitHeatMap();

      // Re-initialize map elements
      this.initAll();
    }

    // If a new shipment is added, calculate the route for it
    if (!_.isEqual(shipments, prevProps.shipments)) {
      this.initShipments(shipments, isMultileg);
    }

    // Only resize the map if our previous values were valid
    // avoiding a race condition at startup where invalid size
    // could be passed to the map
    if (!_.isNil(prevProps.size.width) && !_.isNil(prevProps.size.height)) {
      if (width !== prevProps.size.width || height !== prevProps.size.height) {
        this.debouncedResizeMap();

        /* H1-1324 fix for periodic map max zoom out */
        if (this.getMapZoom() === 0) {
          this.rescaleMap();
        }
      }
    }

    // When coordinates change we need to update them on the map
    if (!_.isEqual(prevProps.coordinates, this.props.coordinates)) {
      this.clearInfoBubbles();

      const coordinatesToRemove = prevProps.coordinates.filter(
        (coordinate) =>
          !this.props.coordinates.some((newCoord) =>
            _.isEqual(coordinate, newCoord),
          ),
      );

      const coordinatesToAdd = this.props.coordinates.filter(
        (coordinate) =>
          !prevProps.coordinates.some((prevCoord) =>
            _.isEqual(coordinate, prevCoord),
          ),
      );

      coordinatesToRemove.forEach((coordinate) =>
        this.clearMapMarkers(coordinate.coordinateType),
      );
      coordinatesToAdd.forEach((coordinate) =>
        this.geocodeCoordinate(coordinate),
      );
    }

    if (
      this.props.allowCaptureScreenshot &&
      prevProps.shouldCaptureScreenshot !==
        this.props.shouldCaptureScreenshot &&
      this.props.shouldCaptureScreenshot
    ) {
      this.captureScreenshot();
    }
  }

  // HASH is 0.6first_lat,0.6first_long:0.6last_lat,0.6last_long
  computeHashFromShipment(shipment) {
    const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");
    if (sortedStops.length === 0) {
      return GENERIC_HASH_NO_STOPS;
    }

    const posFirst = getStopLatLong(sortedStops[0]);
    const posLast = getStopLatLong(sortedStops[sortedStops.length - 1]);
    return `${hashNum(posFirst.lat)},${hashNum(posFirst.lng)}:${hashNum(
      posLast.lat,
    )},${hashNum(posLast.lng)}`;
  }

  computeHashFromLocation(location) {
    const { latitude, longitude } =
      location.location.geofence.properties.center;
    return `mapmarker-lad:${location.location.name}:${hashNum(
      latitude,
    )},${hashNum(longitude)}`;
  }

  matchRouteToShipmentHash(firstCoord, lastCoord, hashTable) {
    // If there is only one entry in the hash
    // table, simply return that entry, and
    // skip the other logic
    const hashKeys = Object.keys(hashTable);
    if (hashKeys.length === 1) {
      return hashKeys[0];
    }

    function coordToPos(coord) {
      if (typeof coord === "string") {
        const parts = coord.split(",");
        return {
          lat: Number.parseFloat(parts[0]),
          lng: Number.parseFloat(parts[1]),
        };
      } else {
        return {
          lat: coord.lat(),
          lng: coord.lng(),
        };
      }
    }

    function stopToPos(stop) {
      return {
        lat: Number.parseFloat(stop.latitude),
        lng: Number.parseFloat(stop.longitude),
      };
    }

    const startPos = coordToPos(firstCoord);
    const endPos = coordToPos(lastCoord);

    let minError = 9999999.99;
    let hash = "";

    for (let key in hashTable) {
      const sortedStops = _.sortBy(
        hashTable[key][0].shipment_stops,
        "stop_sequence",
      );

      let sPos = { lat: 0, lng: 0 };
      let ePos = { lat: 0, lng: 0 };
      if (sortedStops.length >= 2) {
        sPos = stopToPos(sortedStops[0]);
        ePos = stopToPos(sortedStops[sortedStops.length - 1]);
      }
      const d = getDistance(startPos, sPos) + getDistance(endPos, ePos);
      if (d < minError) {
        minError = d;
        hash = key;
      }
    }

    return hash;
  }

  processNewShipment(shipment, ind, numShipments, isMultileg) {
    const shipmentHash = this.shipmentHash;
    const { showBreadCrumbs } = this.props;

    // is a High Value Asset shipment
    const is_HVA = shipment.has_event_refs;
    // Compute the hash for this shipment
    const hash = this.computeHashFromShipment(shipment);

    const isNewRoute = !(hash in shipmentHash);
    let newArray = [];

    // If we already hae a shipment on this
    // route, use the existing array entry
    if (isNewRoute) {
      newArray = [shipment];
    } else {
      // Matching route, add this shipment to the list
      newArray = shipmentHash[hash].slice();
      newArray.push(shipment);
    }

    // Update our hash with the new or updated array
    let newShipment = {};
    newShipment[hash] = newArray;
    this.shipmentHash = _.merge(shipmentHash, newShipment);

    const shipmentModeName = shipment.mode_name?.toLowerCase();
    const isRailOceanAir = ["rail", "ocean", "air"].includes(shipmentModeName);

    // If this is a new route generate markers and routes
    if (isNewRoute) {
      // Generate the chiclets for this shipment
      this.generateStopChiclets(hash, shipment, ind, numShipments, isMultileg);

      // Generate the route for this shipment
      this.calculateExpectedRoute(shipment);

      // H1-1294 Generate an expected route for rail/ocean/air
      if (isRailOceanAir) {
        this.generateExpectedNonTruckRoute(shipment);
      }

      // Auto-resize the map
      this.rescaleMap();

      // Generate G-Force if HVA
      if (is_HVA) {
        this.generateGforceMarkers(shipment);
      }
    }

    // add current position to map
    this.updateChiclet(hash);

    // create breadcrumbs if necessary
    if (showBreadCrumbs) {
      // if this a rail shipment -or- a standalone intermodal shipment,
      // add the rail breadcrumbs for the locations we've received.  If this
      // a truck shipment, call here to calculate the route
      // DEV-1545 LTL will be use the Rail breadcrumb route to map the updates
      let hasTruckStops =
        shipment?.shipment_stops?.filter((s) => s.mode_id === TRUCK_MODE)
          .length !== 0;
      if (
        isRailOceanAir ||
        (shipmentModeName === "intermodal" && !hasTruckStops) ||
        shipmentModeName === "ltl" ||
        shipmentModeName === "parcel" ||
        is_HVA
      ) {
        this.showBreadCrumbs(shipment);
      } else {
        this.calculateActualRoute(shipment);
      }
    }
  }

  // H1-337 Markers for G-Force on HVA
  generateGforceMarkers(shipment) {
    if (shipment.current_location && shipment.current_location.updates) {
      const { updates } = shipment.current_location;

      // only disply for G-Force values that are High (8+)
      let results = updates.filter((u) =>
        u.event_references.find(
          (g) => g.qualifier === "g-force" && g.value >= 8,
        ),
      );

      results.forEach((u, i) => {
        const pos = {
          lat: Number.parseFloat(u.latitude),
          lng: Number.parseFloat(u.longitude),
        };

        let gforce = u.event_references.find((g) => g.qualifier === "g-force");

        const defaultHeight = 64;
        const defaultWidth = 64;

        let svg = AlertChicletSVG({
          backgroundColor: Colors.highlight.RED,
          textColor: "white",
          height: defaultHeight,
          width: defaultWidth,
        });

        const width = 24;
        const height = 24;

        this.createAndAddMapMarker(
          `gforceAlert${i}`,
          pos,
          SELECTED_COORDINATE_Z_INDEX,
          {
            pos: pos,
            gforce: gforce.value,
            time: u.time,
          },
          false,
          svg,
          height,
          width,
          defaultHeight,
          defaultWidth,
          width / 4,
          height / 4,
          width / 2,
          height / 2,
        );
      });
    }
  }

  // DEV-912 Add infowindow with City & State for coordinates pin.
  coordMarkerClickHandler(e, googleMapsMarker) {
    const marker = googleMapsMarker ? googleMapsMarker : e.target;
    const markerdata = marker.getData();
    const content = this.getCoordInfoBubble(markerdata);

    this.createAndAddMapInfoBubble(markerdata.pos, content);
  }

  getCoordInfoBubble(data) {
    const { t } = this.props;
    // HERE maps requires a string or HTML node
    let tempdiv = document.createElement("div");
    const temp = createRoot(tempdiv);
    temp.render(
      <CoordinateInfoPopup data={data} windowType="coordinate" t={t} />,
    );
    return tempdiv;
  }

  createBreadCrumbs(updates, shipmentID) {
    // Need at least two updates to create a line
    if (updates.length <= 1) {
      return;
    }

    // add route to map
    let linestring = this.createMapLineString();

    updates.forEach((obj, i) => {
      const pos = {
        lat: obj.latitude,
        lng: obj.longitude,
      };

      const defaultHeight = 16;
      const defaultWidth = 16;
      const svg = BreadCrumbSVG({
        backgroundColor: "white",
        height: defaultHeight,
        width: defaultWidth,
      });

      const width = 24;
      const height = 24;

      this.createAndAddMapMarker(
        `breadcrumb:${shipmentID}:${i}`,
        pos,
        null,
        null,
        false,
        svg,
        height,
        width,
        defaultHeight,
        defaultWidth,
        width / 4,
        height / 4,
        width / 2,
        height / 2,
      );

      // Creating connecting breadcrumb line
      this.addMapLineStringLatLong(linestring, obj.latitude, obj.longitude);
    });

    this.createAndAddMapPolyline(
      `breadcrumb_route:${shipmentID}`,
      linestring,
      ACTUAL_ROUTE_ARROWS_Z_INDEX,
      ACTUAL_ROUTE_LINE_WIDTH,
      "round",
      true,
    );
  }

  showBreadCrumbs(shipment) {
    this.clearMapMarkers(`breadcrumb:${shipment.id}`);

    if (shipment.current_location) {
      this.createBreadCrumbs(shipment.current_location.updates, shipment.id);
    }
  }

  generateStopChiclets(
    hash,
    shipment,
    shipmentIndex,
    numShipments,
    isMultileg,
  ) {
    const { selectedLocationId, showStopSequence } = this.props;

    const getStopOrder = (stops, index) => {
      if (index === 0) {
        return "O";
      } else if (index === stops.length - 1) {
        return "D";
      } else {
        return index.toString();
      }
    };

    // The following is more complex than I'd like but
    // not sure of another way to do.  The general concept
    // Is that we need to iterate through the shipment stops
    // and add a chiclet for each stop.  We want to display the order
    // of the stops in a bubble in the upper right.  Also
    // if a stop is visited multiple times, that bubble should reflect that
    // So we will create a hash of the shipment stops and store meta data
    // about each of them.

    // Sort the stops, and generate the chiclet for each of them
    _.sortBy(shipment.shipment_stops, "stop_sequence").forEach((obj, i) => {
      const pos = getStopLatLong(obj);

      // Get a hash which will resolve to the same value if a stop is duplicated
      const locHash = this.computeHashFromLocation(obj);

      // If we've already added this stop, get the list of
      // decorators we have so far.  Otherwise we'll start with
      // a blank list
      let decoratorList = [];
      let newLocation = false;
      if (locHash in this.locationsHash) {
        // shallow copy the decorators array for updating them
        decoratorList = this.locationsHash[locHash].decorators.slice();
        // Remove the marker that was created before
        this.removeMapObject(locHash);
      } else {
        newLocation = true;
      }

      // Add the decorator for this stop to our list
      // H1-800 for multileg, display stop index over multiple leg shipments
      const decorator =
        isMultileg &&
        ((shipmentIndex > 0 && shipmentIndex < numShipments) ||
          (i === 0 && numShipments > 0 && shipmentIndex === numShipments))
          ? shipmentIndex
          : getStopOrder(shipment.shipment_stops, i);
      decoratorList.push(decorator);

      // Create a new hash entry to add to the locations hash
      // in our state
      const newLocationInfo = { decorators: decoratorList.slice() };

      let newLocationHash = {};
      newLocationHash[locHash] = newLocationInfo;
      this.locationsHash = _.merge(this.locationsHash, newLocationHash);

      // Create the chiclet and add it to the map
      const lad = obj.location.lad;
      const width = 48;
      const height = 48;
      const displayedDecorator = showStopSequence ? decoratorList.join() : null;

      const defaultHeight = 64;
      const defaultWidth = 64;
      let svg = LadChicletSVG({
        lad: lad,
        chicletStyle: BoxChicletSVG,
        decorator: displayedDecorator,
        height: defaultHeight,
        width: defaultWidth,
      });

      const loc_id = obj.location.id;
      const marker = this.createAndAddMapMarker(
        locHash,
        pos,
        LOCATION_Z_INDEX,
        {
          id: loc_id,
          pos: pos,
          location: obj.location,
          lad: lad,
          groupId: locHash,
        },
        true,
        svg,
        height,
        width,
        defaultHeight,
        defaultWidth,
        24,
        64,
      );

      this.addMapMarkerEventListener(marker, "click", this.markerClickHandler);

      // If this location matches our selected location ID
      // pop up the info bubble for it
      if (!_.isNil(selectedLocationId) && loc_id === selectedLocationId) {
        this.markerClickHandler({ target: marker });
      }

      // If this is a new location, add the geofence object
      if (newLocation) {
        this.drawGeofence(obj.location, false, false);
      }
    });
  }

  markerClickHandler(e, googleMapsMarker) {
    const marker = googleMapsMarker ? googleMapsMarker : e.target;
    const markerdata = marker.getData();

    this.createAndAddMapInfoBubble(
      markerdata.pos,
      this.getInfoBubble(markerdata.location, markerdata.lad),
    );
  }

  requestExpectedRoute(waypoints) {
    if (waypoints.length < 2) {
      console.log("Not enough waypoints to calculate expected route");
      return;
    }

    const {
      mapPlatform,
      mapTypeOverride,
      hereMaps,
      hereMapsPlatform,
      googleMaps,
    } = this.props;

    let params = {
      ...this.expectedRoutingParameters,
    };

    for (let i = 0; i < waypoints.length; i++) {
      params[`waypoint${i}`] = waypoints[i];
    }

    calculateRoute(
      mapPlatform,
      mapTypeOverride,
      hereMaps,
      hereMapsPlatform,
      googleMaps,
      waypoints.length,
      params,
      this.handlerExpectedRoute,
      (e) => {
        console.error("Error in requestExpectedRoute", e);
      },
    );
  }

  // H1-1294 For Ocean, Air, Rail, just make a straight line between the origin (or last update location)/destination of that shipment
  generateExpectedNonTruckRoute(shipment) {
    const hash = this.computeHashFromShipment(shipment);
    let linestring = this.createMapLineString();
    const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");
    const nextStop = getNextStop(sortedStops);

    // first location (origin) of this expected route is either the last update in current location (if one exists)
    // or the origin of the shipment
    let orig =
      shipment.current_location &&
      shipment.current_location.updates &&
      shipment.current_location.updates.length > 0
        ? getUpdateLatLong(
            shipment.current_location.updates[
              shipment.current_location.updates.length - 1
            ],
          )
        : getStopLatLong(sortedStops[0]);

    this.addMapLineStringLatLong(linestring, orig.lat, orig.lng);

    // H1-1632 Make sure predicted route goes through all intermediate stops
    if (sortedStops.length > 2 && nextStop) {
      let stopIdx = nextStop
        ? sortedStops.findIndex((s) => s.id === nextStop.id)
        : 1;
      for (let i = stopIdx < 0 ? 1 : stopIdx; i < sortedStops.length - 1; i++) {
        let stop = sortedStops[i];
        let stopLatLng = getStopLatLong(stop);

        this.addMapLineStringLatLong(
          linestring,
          stopLatLng.lat,
          stopLatLng.lng,
        );
      }
    }

    const dest = getStopLatLong(sortedStops[sortedStops.length - 1]);

    this.addMapLineStringLatLong(linestring, dest.lat, dest.lng);

    this.createAndAddMapPolyline(
      `expected_route:${hash}`,
      linestring,
      ACTUAL_ROUTE_Z_INDEX,
      EXPECTED_ROUTE_LINE_WIDTH,
      "round",
      false,
    );
  }

  calculateExpectedRoute(shipment) {
    const { truckMode } = this.props;

    // Do not display the expected route for rail
    // shipments, we don't want to show a route
    // along a road for a rail
    // H2-367 Do not display the expected route for Ocean as well
    if (
      _.toLower(shipment.mode_name) === "rail" ||
      shipment.mode_name.toLowerCase() === "ocean" ||
      shipment.mode_name.toLowerCase() === "air"
    ) {
      return;
    }

    // H1-474 If has stops but mode is LTL or Parcel, don't compute a route
    if (
      shipment.mode_name.toLowerCase() === "ltl" ||
      shipment.mode_name.toLowerCase() === "parcel"
    ) {
      return;
    }

    if (!("shipment_stops" in shipment)) {
      return;
    }

    // If we do not have any stops, we cannot compute a route
    if (!shipment.shipment_stops) {
      return;
    }
    if (shipment.shipment_stops.length < 2) {
      return;
    }

    // If this shipment is delivered and we are showing
    // breadcrumbs, and we have breadcrumbs to show
    // we will skip rendering the expected route
    if (
      shipment.active_status === "delivered" ||
      shipment.active_status === "arrived"
    ) {
      const waypointData = this.computeActualWaypoints(shipment);
      if (waypointData[0].numWaypoints > 2) {
        return;
      }
    }

    let waypoints = [];
    let nextStop = null;

    const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");

    // DEV-445 for in transit, we want the active route to only waypoint from
    // the current location and through the next pending stop for the expected route
    // instead of from origin to destination. This clears up some cases where
    // the truck's actual route was noticeably off from the expected.
    if (shipment.active_status === "in_transit") {
      nextStop = getNextStop(sortedStops);
    }

    for (let i = 0; i < sortedStops.length; i++) {
      const stop = sortedStops[i];
      if (!stopHasLocation(stop)) {
        continue;
      }

      // Only process from our next step on
      if (nextStop && stop.stop_sequence < nextStop.stop_sequence) {
        continue;
      }

      const { stopMode, nextStopMode } = getStopModes(
        sortedStops,
        i,
        shipment.mode_id,
      );

      // If both this and the next stop are not a rail shipment end any route we have in process
      if (stopMode !== truckMode && nextStopMode !== truckMode) {
        if (waypoints.length >= 1) {
          waypoints.push(stopPositionStringify(stop));
          this.requestExpectedRoute(waypoints);
        }

        waypoints = [];
        continue;
      }

      // If this is the first pending stop, and it is a truck
      // stop, then we can add our current location
      if (
        nextStop !== null &&
        stop.id === nextStop.id &&
        stopMode === truckMode &&
        nextStop.stop_sequence > 1
      ) {
        waypoints.push(shipmentUpdateStringify(shipment.current_location));
      }
      waypoints.push(stopPositionStringify(stop));
    }

    if (waypoints.length > 1) {
      this.requestExpectedRoute(waypoints);
    }
  }

  getActualDeliveryTimeWindow(shipment) {
    if (shipment.shipment_stops < 2) {
      return { minTime: null, maxTime: null };
    }

    // Sort the stops by sequence
    const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");

    // Some shipments miss the pickup geofence, but trigger the other
    let minTime = sortedStops?.[0]?.arrived_at;

    // If min time is null, check the other stops
    // for an arrived at, if there is,
    // set the min time to low, non-null
    // value, so all breadcrumbs will be displayed
    if (minTime === null) {
      minTime = moment.utc(1).format();

      // We now show all breadcrumbs if the origin doesn't have an arrived_at value.
      // Regardless of the state of the other stops.
      // sortedStops.forEach(obj => {
      //   if (obj.arrived_at) {
      //     minTime = moment.utc(1).format();
      //   }
      // });
    }

    const maxTime = sortedStops?.[sortedStops.length - 1]?.arrived_at;

    return {
      minTime: utcStringToMoment(minTime),
      maxTime: utcStringToMoment(maxTime),
    };
  }

  computeActualWaypoints(shipment) {
    const { truckMode } = this.props;

    // We need to prune down the number of waypoints
    // we query, so don't add a waypoint unless we've
    // moved enough
    const DIST_THRESHOLD = 0.01;
    const MAX_WAYPOINTS_PER_REQUEST =
      this.platform.name === HereMapPlatform.name ? 50 : 25;
    let lastPos = { lat: 0.0, lng: 0.0 };

    const requests = [];

    const lastIndex = () => {
      return requests.length - 1;
    };

    const addNewRequest = () => {
      requests.push({
        numWaypoints: 0,
        params: {
          ...this.actualRoutingParameters,
        },
      });
    };
    addNewRequest();

    let numWaypoints = 0;

    // We don't want to show any breadcrumbs outside
    // of the origin pickup and destination dropoff
    // Extract those times from our stops
    const timeWindow = this.getActualDeliveryTimeWindow(shipment);

    let currentMode = truckMode;
    let tripUpdates = [];
    let lastTripUpdate = null;

    // Make sure we've received some updates AND
    // we have arrived at our origin ( eg, the timewindow min
    // value exists )
    if (shipment.current_location && timeWindow.minTime) {
      shipment.current_location.updates.forEach((obj, i) => {
        const timeValue = utcStringToMoment(obj.time);

        // Make sure this doesn't fall outside our actual
        // delivery window, check for before pickup
        if (timeValue < timeWindow.minTime) {
          return;
        }

        // If it's been delivered, skip later updates
        if (timeWindow.maxTime && timeValue > timeWindow.maxTime) {
          return;
        }

        // Pull the transport mode for this update
        const modeValue = _.isNil(obj.mode_id) ? truckMode : obj.mode_id;

        if (modeValue === truckMode) {
          // If this is our first update after a rail section,
          // add the last rail point we were at.  This assumes
          // we do have an arrival update at the last rail station
          if (numWaypoints === 0 && lastTripUpdate) {
            requests[lastIndex()].params[`waypoint${numWaypoints}`] =
              shipmentUpdateStringify(lastTripUpdate);
            numWaypoints += 1;
          }

          let pos = { lat: Number(obj.latitude), lng: Number(obj.longitude) };
          if (getDistance(lastPos, pos) > DIST_THRESHOLD) {
            lastPos = pos;
            requests[lastIndex()].params[`waypoint${numWaypoints}`] =
              shipmentUpdateStringify(obj);
            numWaypoints += 1;
          }
        } else {
          lastTripUpdate = obj;
          lastPos = { lat: 0.0, lng: 0.0 };
          tripUpdates.push(obj);
        }

        // A mode channge will trigger us sending
        // a routing request to connect the waypoints
        if (modeValue !== currentMode) {
          // IF previous mode was truck, send
          // a routing request for the truck leg
          if (currentMode === truckMode) {
            // Add this point as the last waypoint of the truck route
            requests[lastIndex()].params[`waypoint${numWaypoints}`] =
              shipmentUpdateStringify(obj);
            numWaypoints += 1;

            requests[lastIndex()].numWaypoints = numWaypoints;
            addNewRequest();
            numWaypoints = 0;
          }
          currentMode = modeValue;
        }

        // If we've exceeded our waypoints per request
        // limit create a new request, and include the last position
        if (numWaypoints > MAX_WAYPOINTS_PER_REQUEST) {
          requests[lastIndex()].numWaypoints = numWaypoints;
          addNewRequest();
          numWaypoints = 0;
          requests[lastIndex()].params[`waypoint${numWaypoints}`] =
            shipmentUpdateStringify(obj);
          numWaypoints += 1;
        }
      });

      if (numWaypoints >= 2) {
        // Add current location as a waypoint to extend actual route
        requests[lastIndex()].params[`waypoint${numWaypoints}`] =
          shipmentUpdateStringify(shipment.current_location);
        numWaypoints += 1;
      }

      requests[requests.length - 1].numWaypoints = numWaypoints;
    }

    if (tripUpdates.length > 1) {
      this.createBreadCrumbs(tripUpdates, shipment.id);
    }
    return requests;
  }

  calculateActualRoute(shipment) {
    const {
      mapPlatform,
      mapTypeOverride,
      hereMaps,
      hereMapsPlatform,
      googleMaps,
    } = this.props;

    const waypointRequests = this.computeActualWaypoints(shipment);

    waypointRequests.forEach((waypointData) => {
      if (waypointData.numWaypoints > 1) {
        calculateRoute(
          mapPlatform,
          mapTypeOverride,
          hereMaps,
          hereMapsPlatform,
          googleMaps,
          waypointData.numWaypoints,
          waypointData.params,
          this.handlerActualRoute,
          (e) => {
            console.error("Error in calculateActualRoute", e);
          },
        );
      }
    });
  }

  handlerActualRoute(result) {
    const shipmentHash = this.shipmentHash;

    if (this.platform.name === HereMapPlatform.name) {
      let route = result.routes ? result.routes[0] : null;

      if (route === null) {
        console.log("ERROR getting route from HERE Maps");
        console.log(result);
        return;
      }

      if (route) {
        const hash = this.matchRouteToShipmentHash(
          route.sections[0].departure.place.originalLocation.lat +
            "," +
            route.sections[0].departure.place.originalLocation.lng,
          route.sections[route.sections.length - 1].departure.place
            .originalLocation.lat +
            "," +
            route.sections[route.sections.length - 1].departure.place
              .originalLocation.lng,
          shipmentHash,
        );

        // add route to map
        let lineStrings = [];
        route.sections.forEach((section) => {
          lineStrings.push(
            this.convertFlexiblePolylineToLineString(section.polyline),
          );
        });

        let multiLineString = this.createMapMultiLineString(lineStrings);

        this.createAndAddMapPolyline(
          `actual_route:${hash}`,
          multiLineString,
          ACTUAL_ROUTE_ARROWS_Z_INDEX,
          ACTUAL_ROUTE_LINE_WIDTH,
          "round",
          true,
        );
      }
    } else {
      if (!result || !result.routes || result.routes.length === 0) {
        console.log("ERROR getting route from Google Maps");
        console.log(result);
        return;
      }

      const hash = this.matchRouteToShipmentHash(
        result.routes[0].overview_path[0],
        result.routes[0].overview_path[
          result.routes[0].overview_path.length - 1
        ],
        shipmentHash,
      );

      // add route to map
      let linestring = this.createMapLineString();

      result.routes[0].overview_path.forEach((point) => {
        this.addMapLineStringLatLong(linestring, point.lat(), point.lng());
      });

      this.createAndAddMapPolyline(
        `actual_route:${hash}`,
        linestring,
        ACTUAL_ROUTE_ARROWS_Z_INDEX,
        ACTUAL_ROUTE_LINE_WIDTH,
        "round",
        true,
      );
    }
  }

  getDisplayedAssetLocation(shipment) {
    // If no current location, can't do anything
    if (!shipment.current_location) {
      return null;
    }

    // Call the method to get our max time
    const timeWindow = this.getActualDeliveryTimeWindow(shipment);

    // If we have a delivered time, set the asset
    // at the final destination
    if (timeWindow.maxTime) {
      const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");
      return {
        lat: sortedStops[sortedStops.length - 1].location.geofence.properties
          .center.latitude,
        lng: sortedStops[sortedStops.length - 1].location.geofence.properties
          .center.longitude,
      };
    } else {
      return {
        lat: shipment.current_location.latitude,
        lng: shipment.current_location.longitude,
      };
    }
  }

  computeShipmentGroupings(hash) {
    const shipmentHash = this.shipmentHash;

    // ZOOM  Distance
    //
    //  3     5.0
    //  8     0.14      10 m
    const zoom = this.getMapZoom();
    const groupDistance = lerp(zoom, 3, 8, 5.0, 0.14);

    let shipmentGrouping = [];

    // Walk through each shipment and determine the groupings
    const sortedShipments = _.sortBy(shipmentHash[hash], "shipment_id");
    sortedShipments.forEach((shipment, i) => {
      // Call our helper method to get the displayable
      // asset location.  This method prevents the UI
      // updating the asset location beyond the final destination
      const pos = this.getDisplayedAssetLocation(shipment);

      if (!pos) {
        return;
      }

      // Is this position close to any of our other groupings
      let newGrouping = true;

      // TODO make this grouping dependent upon map zoom level
      shipmentGrouping.forEach((group) => {
        const d = getDistance(group.pos, pos);
        if (d < groupDistance) {
          newGrouping = false;
          group.shipments.push(shipment);
        }
      });

      if (newGrouping) {
        shipmentGrouping.push({ pos: pos, shipments: [shipment] });
      }
    });

    const existingGrouping = _.get(this.shipmentGroupings, hash, []);

    let modified = existingGrouping.length !== shipmentGrouping.length;

    if (!modified) {
      // Check each list
      existingGrouping.forEach((value, i) => {
        if (value.shipments.length !== shipmentGrouping[i].shipments.length) {
          modified = true;
        }
      });
    }

    this.shipmentGroupings[hash] = shipmentGrouping;
    return modified;
  }

  updateChiclet(hash) {
    const {
      isMultileg,
      activeShipment,
      hideMads,
      showExceptionOnMAD = true,
    } = this.props;
    // Check to see if our groupings have changed
    // only remove and recreate chiclets if the groupings
    // have changed ( method returns bool)
    if (this.computeShipmentGroupings(hash) === false) {
      return;
    }

    // Remove any existing marker we may have for this route
    // Looks like a draw issue that the initial chiclet
    // draw isn't getting cleared when we draw the new one
    this.clearMapMarkers(`current_position:${hash}`);

    // Add a chiclet for each shipment group we came up with
    const grouping = this.shipmentGroupings[hash];
    grouping.forEach((group, i) => {
      // H1-800 for isMultileg, only display MAD for active shipment
      // H1-1258 update check active shipment for creator_shipment_id as used in Vin Details
      if (
        isMultileg &&
        activeShipment &&
        group.shipments[0].id.toString() !== activeShipment.toString() &&
        group.shipments[0].creator_shipment_id.toString() !==
          activeShipment.toString()
      ) {
        return;
      }

      if (hideMads) {
        return;
      }

      // FIXME, promote exceptions
      const activeException = showExceptionOnMAD
        ? group.shipments[0].active_exceptions_ng
        : "";

      const markerName = `current_position:${hash}:${i}`;

      const stopModeName = getCurrentModeName(
        group.shipments[0],
        this.props.shipmentModes,
      );

      const madDefaultHeight = 64;
      const madDefaultWidth = 64;
      let svg = MadChicletSVG({
        shipmentMode: group.shipments[0].mode_name,
        stopMode: stopModeName,
        activeException: activeException,
        shipmentCount: group.shipments.length,
        height: madDefaultHeight,
        width: madDefaultWidth,
      });

      this.createAndAddMapMarker(
        markerName,
        group.pos,
        MAD_Z_INDEX,
        null,
        false,
        svg,
        35,
        35,
        madDefaultHeight,
        madDefaultWidth,
        29,
        58,
      );
    });
  }

  handlerExpectedRoute(result) {
    const shipmentHash = this.shipmentHash;

    if (this.platform.name === HereMapPlatform.name) {
      let route = result.routes ? result.routes[0] : null;

      if (route === null) {
        console.log("ERROR getting route from HERE Maps");
        console.log(result);
        return;
      }

      if (route) {
        const hash = this.matchRouteToShipmentHash(
          route.sections[0].departure.place.originalLocation.lat +
            "," +
            route.sections[0].departure.place.originalLocation.lng,
          route.sections[route.sections.length - 1].departure.place
            .originalLocation.lat +
            "," +
            route.sections[route.sections.length - 1].departure.place
              .originalLocation.lng,
          shipmentHash,
        );

        // add route to map
        let lineStrings = [];
        route.sections.forEach((section) => {
          lineStrings.push(
            this.convertFlexiblePolylineToLineString(section.polyline),
          );
        });

        let multiLineString = this.createMapMultiLineString(lineStrings);

        this.createAndAddMapPolyline(
          `expected_route:${hash}`,
          multiLineString,
          EXPECTED_ROUTE_Z_INDEX,
          EXPECTED_ROUTE_LINE_WIDTH,
          "miter",
          false,
        );
      }
    } else {
      if (!result || !result.routes || result.routes.length === 0) {
        console.log("ERROR getting route from Google Maps");
        console.log(result);
        return;
      }

      const hash = this.matchRouteToShipmentHash(
        result.routes[0].overview_path[0],
        result.routes[0].overview_path[
          result.routes[0].overview_path.length - 1
        ],
        shipmentHash,
      );

      // add route to map
      let linestring = this.createMapLineString();

      result.routes[0].overview_path.forEach((point) => {
        this.addMapLineStringLatLong(linestring, point.lat(), point.lng());
      });

      this.createAndAddMapPolyline(
        `expected_route:${hash}`,
        linestring,
        EXPECTED_ROUTE_Z_INDEX,
        EXPECTED_ROUTE_LINE_WIDTH,
        "miter",
        false,
      );
    }
  }

  initMap() {
    SimpleMap.prototype.initMap.call(this);
    this.addMapEventListener("mapviewchange", this.mapViewChanged);
  }

  getPlottablePositions() {
    // Add other types coordinates here to include them when rescaling to see all the coodinates together.
    const coordinateTypesToInclude = [
      MapCoordinateType.CURRENT_LOCATION_PACKAGE,
    ];

    // shipments
    const shipmentPositions = getPositionsForShipments(this.shipmentHash);
    const coordinatePositions =
      this.props.coordinates
        .filter(
          (coordinate) =>
            coordinateTypesToInclude.includes(coordinate.coordinateType) &&
            typeof coordinate.lat === "number" &&
            typeof coordinate.long === "number",
        ) // Transform in to the correct shape
        .map((coordinate) => ({
          lat: coordinate.lat,
          lng: coordinate.long,
        })) ?? [];

    if (shipmentPositions.length > 0 && coordinatePositions.length > 0) {
      return [...shipmentPositions, ...coordinatePositions];
    }
    return shipmentPositions;
  }

  rescaleMap() {
    if (!this.map) {
      return;
    }

    // H1-2073: We only want to rescale once, on page load.
    // Otherwise, users' zoom levels will be lost during certain actions
    // (like turning off heatmaps).
    // H1-1324: Force a rescale if zoom is 0.
    if (this.getMapZoom() !== 0 && this.state.hasRescaledOnce) {
      return;
    }

    let boundsRect = this.getCombinedBoundsRect(this.getPlottablePositions());

    if (boundsRect !== null) {
      this.setMapViewBounds(boundsRect);

      this.setState({ hasRescaledOnce: true });
    }
  }

  mapViewChanged() {
    const shipmentHash = this.shipmentHash;

    const zoom = this.getMapZoom();

    if (zoom !== this.currentZoom) {
      for (let key in shipmentHash) {
        this.updateChiclet(key);
      }

      this.currentZoom = zoom;
    }
  }

  createAndAddMapPolyline(
    name,
    linestring,
    zIndex,
    lineWidth,
    lineJoin,
    hasArrows,
  ) {
    return this.platform.createAndAddMapPolyline(
      name,
      linestring,
      zIndex,
      lineWidth,
      lineJoin,
      hasArrows,
    );
  }

  createMapLineString() {
    return this.platform.createMapLineString();
  }

  createMapMultiLineString(lineStrings) {
    return this.platform.createMapMultiLineString(lineStrings);
  }

  addMapLineStringLatLong(linestring, lat, lng) {
    this.platform.addMapLineStringLatLong(linestring, lat, lng);
  }

  convertFlexiblePolylineToLineString(flexiblePolyline) {
    return this.platform.convertFlexiblePolylineToLineString(flexiblePolyline);
  }

  getBoundsRect(pos, boundsBuffer = 0.01) {
    return this.platform.getBoundsRect(pos, boundsBuffer);
  }

  mergeBounds(bounds1, bounds2) {
    return this.platform.mergeBounds(bounds1, bounds2);
  }

  getCombinedBoundsRect(positions) {
    let boundsRect = null;
    positions.forEach((pos) => {
      if (boundsRect === null) {
        boundsRect = this.getBoundsRect(pos, 0.01);
      } else {
        boundsRect = this.mergeBounds(
          boundsRect,
          this.getBoundsRect(pos, 0.01),
        );
      }
    });
    return boundsRect;
  }
}

RoutingMap.propTypes = {
  shipments: PropTypes.array.isRequired,
  showHeatmap: PropTypes.bool.isRequired,
  showBreadCrumbs: PropTypes.bool.isRequired,
  hereMaps: PropTypes.object.isRequired,
  hereMapsPlatform: PropTypes.object.isRequired,
  showStopSequence: PropTypes.bool.isRequired,
  selectedLocationId: PropTypes.number,
  isMultileg: PropTypes.bool,
  activeShipment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowCaptureScreenshot: PropTypes.bool,
  shouldCaptureScreenshot: PropTypes.bool,
  setShouldCaptureScreenshot: PropTypes.func,
  setCapturedScreenshot: PropTypes.func,
  hideMads: PropTypes.bool,
};

function mapStateToProps(state) {
  const shipmentModes = DomainDataState.selectors.getShipmentModes(state);
  const truckMode = DomainDataState.selectors.getTruckMode(state);
  return {
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    shipmentModes,
    truckMode,
    shouldCaptureScreenshot:
      MapState.selectors.getShouldCaptureScreenshot(state),
    coordinates: MapState.selectors.getCoordiantes(state),
    ...state.maps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShouldCaptureScreenshot: (shouldCaptureScreenshot) =>
      dispatch(
        MapState.actionCreators.setShouldCaptureScreenshot(
          shouldCaptureScreenshot,
        ),
      ),
    setCapturedScreenshot: (capturedScreenshot) =>
      dispatch(
        MapState.actionCreators.setCapturedScreenshot(capturedScreenshot),
      ),
    clearAllCoordinates: () =>
      dispatch(MapState.actionCreators.clearAllCoordinates()),
  };
}

const withT = withTranslation(["map"])(RoutingMap);
const sizeMeHOC = withResizeDetector()(withT);
const RoutingMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(sizeMeHOC);
export default RoutingMapContainer;
