import axios from "axios";
import { customerApiUrl } from "api-url";

/*
  Hardcoded for now, per client. Values from the API reference.
*/
export const SHIPPERS = [
  { value: "GMHQ", label: "General Motors" },
  { value: "FORD", label: "Ford Motor Company" },
  { value: "FCSD", label: "Ford Customer Service Division" },
  { value: "FV0219415A", label: "Goodyear Tire & Rubber Company" },
  { value: "PISTON", label: "Piston Automotive" },
  { value: "FREEPORT", label: "Freeport McMoRan" },
  { value: "FV8532823A", label: "AAM" },
  { value: "FV70089309C", label: "El Tejano" },
];

/* STOP_TYPES values from FV API reference */
export const STOP_TYPES = (t) => {
  return [
    { value: "load", label: t("create-shipment:Load") },
    { value: "unload", label: t("create-shipment:Unload") },
    { value: "drop", label: t("create-shipment:Drop") },
    { value: "hook", label: t("create-shipment:Hook") },
    { value: "relay", label: t("create-shipment:Relay") },
    { value: "other", label: t("create-shipment:Other") },
  ];
};

/* STOP_ROLES values from FV API reference */
export const STOP_ROLES = (t) => {
  return [
    { value: "ship_from", label: t("create-shipment:Ship From") },
    { value: "ship_to", label: t("create-shipment:Ship To") },
    { value: "border", label: t("create-shipment:Border") },
    { value: "terminal", label: t("create-shipment:Terminal") },
  ];
};

export function checkValidation(obj) {
  return Object.keys(obj).every((k) => obj[k]);
}

const SHIPMENT_URL = customerApiUrl("/ws/rest/v2/tl/shipment");
const STORE_MOUNT_POINT = "createShipment";

// Actions
const CREATE_SHIPMENT_REQUESTED = `${STORE_MOUNT_POINT}/CREATE_SHIPMENT_REQUESTED`;
const CREATE_SHIPMENT_SUCCEEDED = `${STORE_MOUNT_POINT}/CREATE_SHIPMENT_SUCCEEDED`;
const CREATE_SHIPMENT_FAILED = `${STORE_MOUNT_POINT}/CREATE_SHIPMENT_FAILED`;
const RESET_CREATE_FORM = `${STORE_MOUNT_POINT}/RESET_CREATE_FORM`;

// Action creators
function createShipment(data) {
  const payload = { ...data };

  return (dispatch) => {
    dispatch({ type: CREATE_SHIPMENT_REQUESTED });
    return Promise.all([axios.post(`${SHIPMENT_URL}`, payload)])
      .then((responses) => {
        dispatch({ type: CREATE_SHIPMENT_SUCCEEDED });
      })
      .catch((err) => {
        dispatch({ type: CREATE_SHIPMENT_FAILED, error: err });
      });
  };
}

function resetCreateForm() {
  return {
    type: RESET_CREATE_FORM,
  };
}

// Initial state
export const initialState = {
  createStatus: null,
  selectedShipper: null,
};

// Reducer
function CreateShipmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_SHIPMENT_REQUESTED:
      return Object.assign({}, state, {
        createStatus: "IN_PROGRESS",
      });
    case CREATE_SHIPMENT_SUCCEEDED:
      return Object.assign({}, state, {
        createStatus: "CREATED",
      });
    case CREATE_SHIPMENT_FAILED:
      return Object.assign({}, state, {
        createStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 400
            ? "DUPLICATE"
            : "ERROR",
      });
    case RESET_CREATE_FORM:
      return Object.assign({}, state, {
        createStatus: null,
      });
    default:
      return state;
  }
}

// interface
const CreateShipmentState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    createShipment,
    resetCreateForm,
  },
  selectors: {},
  reducer: CreateShipmentReducer,
};
export default CreateShipmentState;
