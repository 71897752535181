/** @jsxImportSource @emotion/react */
/**
 * Collapse/expand button to be used in our MapSection.
 */
import PropTypes from "prop-types";
import {
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleDown,
  FaAngleDoubleUp,
} from "react-icons/fa";
import _ from "lodash";
import { useState } from "react";

import colors from "../../styles/colors";

const MapSectionExpandCollapseButton = ({
  onExpand = _.noop,
  onCollapse = _.noop,
  startExpanded = false,
  height = 50,
  width = 50,
  isMobile = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  let ButtonIcon = isExpanded ? FaAngleDoubleLeft : FaAngleDoubleRight;
  if (isMobile) {
    ButtonIcon = isExpanded ? FaAngleDoubleUp : FaAngleDoubleDown;
  }

  return (
    <div css={{ cursor: "pointer" }}>
      <figure
        css={{
          alignItems: "center",
          backgroundColor: colors.background.DARK_GRAY,
          height: height,
          position: "absolute",
          justifyContent: "space-around",
          width: width,
          ":hover": {
            backgroundColor: colors.background.DARK_TEAL,
            cursor: "pointer",
          },
        }}
        onClick={() => {
          if (isExpanded) {
            setIsExpanded(false);
            onCollapse();
          } else {
            setIsExpanded(true);
            onExpand();
          }
        }}
      >
        <figcaption
          css={{
            height: "100%",
            color: "white",
            fontSize: 28,
            fontWeight: 400,
            // Center the SVG
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonIcon />
        </figcaption>
      </figure>
    </div>
  );
};

MapSectionExpandCollapseButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  startExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default MapSectionExpandCollapseButton;
