import apiUrl from "api-url";
import { SEARCH_CATEGORIES } from "modules/location-search/LocationSearchCategoryDefs";
import { FILTERS } from "modules/location-search/LocationSearchFilterDefs";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "fvLocationsMatchingViewSearch";

// Helpers
const locationsUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(`/location/locations?verbose=false&${queryString}`);
};

// Actions
const fetchSearch = (queryString = "", _ignored_solutionId, duck, dispatch) => {
  const url = locationsUrl(null, queryString);
  dispatch(duck.fetch(url));
};

export const LocationMatchingViewSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "name",
  },
);
