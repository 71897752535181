import { connect } from "react-redux";
import { FilterSection } from "../../components/search-bar/FilterSection";
import ConnectedCarSearchBarState from "./ConnectedCarSearchBarState";
import ConnectedCarDomainDataState from "../domain-data/ConnectedCarDomainData";
import { FILTERS } from "./ConnectedCarFilterSectionCategoryDefs";
import { getSolutionId } from "../organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const { getLifeCycleOptions, getNgavsStatusCodeOptions, getStatesOptions } =
    ConnectedCarDomainDataState.selectors;

  return {
    auth: getAuthorization(state),
    getLifeCycleOptions: getLifeCycleOptions(state),
    statesFilterOptions: getStatesOptions(state),
    getNgavsStatusCodeOptions: getNgavsStatusCodeOptions(state),
    solutionId: getSolutionId(state),
    searchFilters: ConnectedCarSearchBarState.selectors.getSearchFilters(state),
    hasSearchCriteriaChanged:
      ConnectedCarSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities,
    clearSavedSearch,
    clickedSearchOrEnter,
  } = ConnectedCarSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: (key) => dispatch(clearSearchFilter(key)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => {
      dispatch(clickedSearchOrEnter(false));
      dispatch(searchEntities(solutionId, false));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilterSection);
