/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import Loader from "react-loader";

import Colors from "styles/colors";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import GeofenceBuilderMap from "modules/map/components/GeofenceBuilderMap";
import { useTableColumns } from "./InventoryView.WatchedVins.Columns";

export const InventoryViewWatchedVins = ({
  fetchWatchedVins,
  watchedVinsArray,
  isWatchedVinsLoading,
  watchedVinsPageIndex,
  watchedVinsPageSize,
  watchedVinsPageCount,
  setWatchedVinsPageIndex,
  redirectToVinDetails,
  canRedirectToVinDetails,
  selectedMapCoordinate,
  fetchLocation,
  location,
  isLocationLoading,
  setWatchEntity,
  solutionId,
}) => {
  const { t } = useTranslation("inventory-view");
  const [vinDetails, setVinDetails] = useState({});
  const [unwatchedVins, setUnwatchedVins] = useState([]);

  useEffect(() => {
    fetchWatchedVins(solutionId);
  }, [fetchWatchedVins, solutionId]);

  const toggleWatch = (vin) => {
    let newUnwatchedVins = [...unwatchedVins];

    // VIN checkbox is checked - unwatch it
    newUnwatchedVins.push(vin);
    setUnwatchedVins(newUnwatchedVins);
  };

  // Disable row click when we can't redirect
  let rowClickHandler = null;
  if (canRedirectToVinDetails) {
    rowClickHandler = (row, cell) => {
      // Prevent navigation if clicking in "unwatch" checkbox cell
      if (cell.column.id === "watch") {
        return;
      }

      // Navigate to VIN Details  when clicking row
      redirectToVinDetails(row.original);
    };
  }

  return (
    <Fragment>
      <PanelGroup style={{ marginTop: "1em" }}>
        <PanelGroup.Header title={t("inventory-view:My Watched VINs")} />
        <PanelGroup.Content style={{ padding: 0 }}>
          <BaseTable
            data={watchedVinsArray}
            columns={useTableColumns(
              setVinDetails,
              fetchLocation,
              setWatchEntity,
              toggleWatch,
              solutionId,
              fetchWatchedVins,
              unwatchedVins,
            )}
            theme={Themes.LIGHT}
            isLoading={isWatchedVinsLoading}
            rowClickHandler={rowClickHandler}
            showPagination={true}
            showPageSizeDropdown={false}
            isManualPagination={true}
            pageIndex={watchedVinsPageIndex}
            pageSize={watchedVinsPageSize}
            pageCount={watchedVinsPageCount}
            onPageChange={(newPage) => {
              setWatchedVinsPageIndex(solutionId, newPage, watchedVinsPageSize);
            }}
          />
        </PanelGroup.Content>
      </PanelGroup>
      <Modal
        show={vinDetails?.id != null}
        onHide={() => {
          setVinDetails(null);
        }}
        backdrop="static"
      >
        <ModalHeader
          closeButton={true}
          css={{
            backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
            borderBottom: `1px solid ${Colors.border.BOTTOM_AXIS_BORDER}`,
            color: Colors.dialogs.HEADER_TEXT_COLOR,
          }}
        >
          VIN: {vinDetails?.id}
        </ModalHeader>
        <ModalBody
          css={{
            padding: "0.5em",
            backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
            minHeight: "5rem",
          }}
        >
          <Loader loaded={!isLocationLoading}>
            <div css={{ height: "500px" }}>
              <GeofenceBuilderMap
                selectedLocation={{ ...location }}
                mapLocations={location ? [location] : []}
                selectedLad={location?.lad}
                selectedMapCoordinate={selectedMapCoordinate}
                useBoxChiclets
                enableGeofenceBuilder
              />
            </div>
          </Loader>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

InventoryViewWatchedVins.propTypes = {
  fetchWatchedVins: PropTypes.func,
  watchedVinsArray: PropTypes.array,
  isWatchedVinsLoading: PropTypes.bool,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  redirectToVinDetails: PropTypes.func.isRequired,
  canRedirectToVinDetails: PropTypes.bool.isRequired,
  fetchLocation: PropTypes.func,
  selectedMapCoordinate: PropTypes.object,
  location: PropTypes.object,
  isLocationLoading: PropTypes.bool,
  setWatchEntity: PropTypes.func,
  solutionId: PropTypes.string,
};
