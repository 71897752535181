import { connect } from "react-redux";
import VinViewSearch from "./VinView.Search.page";
import VinViewSearchBarState from "../redux/VinViewSearchBarState";
import VinViewSavedSearchState from "../redux/VinViewSavedSearchState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";

function mapStateToProps(state) {
  const auth = getAuthorization(state);

  return {
    savedSearch: VinViewSearchBarState.selectors.getSelectedSavedSearch(state),
    vinSearchResults: VinViewSearchBarState.selectors.getSearchResults(state),
    showFilters: VinViewSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: VinViewSearchBarState.selectors.getIsLoading(state),
    loadingError: VinViewSearchBarState.selectors.getLoadingError(state),
    isLoadingError: VinViewSearchBarState.selectors.getIsLoadingError(state),
    sortColumn: VinViewSearchBarState.selectors.getSortColumn(state),
    reverseSort: VinViewSearchBarState.selectors.getReverseSort(state),
    page: VinViewSearchBarState.selectors.getPage(state),
    pageSize: VinViewSearchBarState.selectors.getPageSize(state),
    totalPages:
      VinViewSearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      VinViewSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      VinViewSearchBarState.selectors.getTotalCountForSearchIsLoading(state),
    exportIdentifier:
      VinViewSearchBarState.selectors.getExportIdentifier(state),
    exportName: VinViewSearchBarState.selectors.getExportName(state),
    isExporting: VinViewSearchBarState.selectors.getIsExporting(state),
    exportFailed: VinViewSearchBarState.selectors.getExportFailed(state),
    showItssData: auth.hasFeatures([Features.ITSS]),
    selectedDealerOrgId:
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state),
    isDealerOrg: auth.hasOrganizationTypes([OrganizationType.DEALER]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        VinViewSavedSearchState.actionCreators.loadSavedSearch(savedSearch),
      ),
    pushVinViewDetailsPage: (internalId, selectedDealerOrgId = null) => {
      if (selectedDealerOrgId) {
        dispatch({
          type: "VINVIEW_SHIPPER_DETAILS",
          payload: {
            dealerOrgId: selectedDealerOrgId,
            internal_id: internalId,
          },
        });
      } else {
        dispatch({
          type: "VINVIEW_DETAILS",
          payload: { internal_id: internalId },
        });
      }
    },
    toggleShowFilters: (showFilters) =>
      dispatch(
        VinViewSearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    setPagination: (page, pageSize) =>
      dispatch(
        VinViewSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        VinViewSearchBarState.actionCreators.setSort(null, sortColumn, reverse),
      ),
    exportEntities: () => {
      dispatch(VinViewSearchBarState.actionCreators.exportSearch(null));
    },
    clearExportErrors: () => {
      dispatch(VinViewSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: () => {
      dispatch(VinViewSearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(VinViewSearchBarState.actionCreators.searchEntities(null));
    },
    resetExport: () => {
      dispatch(VinViewSearchBarState.actionCreators.resetExport());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VinViewSearch);
