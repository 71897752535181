/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import PropTypes from "prop-types";
import Select from "react-select";
import { Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import {
  Products,
  NotificationEventTypes,
  defaultNotificationEventInformation,
} from "pages/administration/notification-management/notificationmanagement.const";
import {
  useTranslateEventType,
  useTranslateProductName,
} from "pages/administration/notification-management/hooks/useNotificationManagementTranslations";
import { BehindScheduleOptions } from "pages/administration/notification-management/components/molecules/BehindScheduleOptions.molecule";
import { EarlyArrivalOptions } from "pages/administration/notification-management/components/molecules/EarlyArrivalOptions.molecule";

const EventTypeDetail = (props) => {
  const { notificationDetailsObj, setNotificationDetailsObj } = props;

  if (
    notificationDetailsObj.notificationEventType ===
    NotificationEventTypes.ETA_CHANGE
  ) {
    return (
      <BehindScheduleOptions
        css={{ marginTop: "0.2rem", height: "100%" }}
        notificationDetailsObj={notificationDetailsObj}
        setNotificationDetailsObj={setNotificationDetailsObj}
        data-qa="behind-schedule-options"
      />
    );
  } else if (
    notificationDetailsObj.notificationEventType ===
    NotificationEventTypes.EARLY_ARRIVAL
  ) {
    return (
      <EarlyArrivalOptions
        css={{ marginTop: "0.2rem", height: "100%" }}
        notificationDetailsObj={notificationDetailsObj}
        setNotificationDetailsObj={setNotificationDetailsObj}
        data-qa="early-arrival-options"
      />
    );
  }

  return null;
};

EventTypeDetail.propTypes = {
  notificationDetailsObj: PropTypes.shape({
    notificationEventType: PropTypes.string,
    notificationTrigger: PropTypes.string,
    notificationTriggerTimeObj: PropTypes.shape({
      days: PropTypes.number,
      hours: PropTypes.number,
      minutes: PropTypes.number,
    }),
  }),
  setNotificationDetailsObj: PropTypes.func.isRequired,
};

export const EventInformationStep = (props) => {
  const {
    notificationDetailsObj,
    setNotificationDetailsObj,
    setProduct,
    setIsNextButtonDisabled,
    clearFilterCriteria,
  } = props;

  const { t } = useTranslation("notification-management");
  const { translateEventType } = useTranslateEventType();
  const { translateProductName } = useTranslateProductName();

  const [notificationDetailsObjLocal, setNotificationDetailsObjLocal] =
    useState(
      _.isEmpty(notificationDetailsObj)
        ? defaultNotificationEventInformation[NotificationEventTypes.ETA_CHANGE]
        : notificationDetailsObj,
    );

  const [productLocal, setProductLocal] = useState(Products.SHIPMENTS);

  const prevNotificationEventType =
    notificationDetailsObj.notificationEventType;

  useEffect(() => {
    setIsNextButtonDisabled(!detailsValid(notificationDetailsObjLocal));
  }, [notificationDetailsObjLocal, setIsNextButtonDisabled]);

  const { handleStep } = useWizard();

  const resetNotificationDetailsObj = (notificationEventType) => {
    const defaultNotificationDetails =
      defaultNotificationEventInformation[notificationEventType];
    const requiresInputValidation =
      defaultNotificationDetails.requiresInputValidation;
    setNotificationDetailsObjLocal(defaultNotificationDetails);
    if (requiresInputValidation) {
      setIsNextButtonDisabled(false);
    }
  };

  handleStep(() => {
    setProduct(productLocal);
    setNotificationDetailsObj(notificationDetailsObjLocal);
    if (
      !_.isNil(prevNotificationEventType) &&
      prevNotificationEventType !==
        notificationDetailsObjLocal.notificationEventType
    ) {
      clearFilterCriteria();
    }
  });

  return (
    <>
      <Text bold block>
        {t("notification-management:Event Information")}
      </Text>
      <Text block css={{ marginTop: "0.5rem" }}>
        {t(
          "notification-management:What product does this notification apply to?",
        )}
      </Text>
      <Select
        css={{ marginTop: "0.2rem" }}
        options={Object.entries(Products).map(([key, value]) => ({
          value: value,
          label: translateProductName(value),
        }))}
        onChange={(e) => {
          setProductLocal(e.value);
        }}
        value={{
          value: productLocal,
          label: translateProductName(productLocal),
        }}
        data-qa="product-select"
      />
      <Text block css={{ marginTop: "0.5rem" }}>
        {t(
          "notification-management:What type of event should trigger this notification?",
        )}
      </Text>
      <Select
        css={{ marginTop: "0.2rem" }}
        options={Object.entries(NotificationEventTypes)
          // Remove filtering excessive dwell once excessive dwell is implemented.
          .filter(
            ([key, value]) =>
              value !== "EXCESSIVE_DWELL" && value !== "OFF_ROUTE_RAIL",
          )
          .map(([key, value]) => ({
            value: value,
            label: translateEventType(value),
          }))}
        onChange={(option) => {
          if (
            option.value !== notificationDetailsObjLocal.notificationEventType
          ) {
            resetNotificationDetailsObj(option.value);
          }
        }}
        value={{
          value: notificationDetailsObjLocal.notificationEventType,
          label: translateEventType(
            notificationDetailsObjLocal.notificationEventType,
          ),
        }}
        data-qa="event-type-select"
      />
      {!_.isNil(prevNotificationEventType) &&
      prevNotificationEventType !==
        notificationDetailsObjLocal.notificationEventType ? (
        <Text color={Colors.text.RED} block>
          (
          {t(
            "notification-management:Modifying the event type will reset all filters",
          )}
          )
        </Text>
      ) : null}
      <EventTypeDetail
        notificationDetailsObj={notificationDetailsObjLocal}
        setNotificationDetailsObj={setNotificationDetailsObjLocal}
      />
    </>
  );
};

EventInformationStep.propTypes = {
  notificationDetailsObj: PropTypes.shape({
    notificationEventType: PropTypes.string,
    notificationTrigger: PropTypes.string,
    notificationTriggerTimeObj: PropTypes.shape({
      days: PropTypes.number,
      hours: PropTypes.number,
      minutes: PropTypes.number,
    }),
  }),
  setNotificationDetailsObj: PropTypes.func.isRequired,
  product: PropTypes.string,
  setIsNextButtonDisabled: PropTypes.func.isRequired,
  setProduct: PropTypes.func.isRequired,
  clearFilterCriteria: PropTypes.func.isRequired,
};

function detailsValid(notificationDetailsObj) {
  const { notificationEventType, notificationTriggerTimeObj } =
    notificationDetailsObj;
  if (
    notificationEventType === NotificationEventTypes.ETA_CHANGE ||
    notificationEventType === NotificationEventTypes.EARLY_ARRIVAL
  ) {
    const { days, hours, minutes } = notificationTriggerTimeObj;
    return days + hours + minutes > 0;
  } else if (
    notificationEventType === NotificationEventTypes.CARRIER_DELAY ||
    notificationEventType === NotificationEventTypes.EXCESSIVE_DWELL ||
    notificationEventType === NotificationEventTypes.CARRIER_DELAY_CLEARED ||
    notificationEventType === NotificationEventTypes.OFF_ROUTE_RAIL
  ) {
    return true;
  }
  return false;
}
