//External hooks or supporting code from React
import { connect } from "react-redux";
import debouncePromise from "debounce-promise";

//Components
import { SearchBar } from "components/search-bar/SearchBar";
import DomainDataState from "modules/domain-data/DomainDataState";
import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import {
  billOfLadingNumberCategoryLoaderState,
  trailerAndEquipmentNumberLoaderState,
  houseReferenceNumberLoaderState,
  stccCodeLoaderState,
} from "pages/shipments/redux/ShipmentsSearchCategoryStates";
import {
  selectAssetIdOptions,
  selectTrainIdOptions,
} from "./Shipments.SearchCategorySelectors";
import { selectServiceCodeFilterOptions } from "./Shipments.SearchFilterSelectors";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
    getLocations,
    getLocationsLoading,
    getVins,
    getVinsLoading,
  } = ShipmentsSearchBarState.selectors;

  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    locations: getLocations(state),
    canUserSearch: getCanUserSearch(state),
    locationsLoading: getLocationsLoading(state),
    shipmentIDs: ShipmentsDomainDataState.selectors.getShipmentIDs(state),
    routeIDs: ShipmentsDomainDataState.selectors.getRouteIDs(state),
    vins: getVins(state),
    vinsLoading: getVinsLoading(state),
    orderNumbers: ShipmentsDomainDataState.selectors.getOrderNumbers(state),
    tripPlanNumbers:
      ShipmentsDomainDataState.selectors.getTripPlanNumbers(state),
    assetIDs: selectAssetIdOptions(state),
    carriers: DomainDataState.selectors.selectCarriersAlphabetically(state),
    trainIDs: selectTrainIdOptions(state),
    serviceCode: selectServiceCodeFilterOptions(state),
    // STCC Codes
    stccCodeOptions: stccCodeLoaderState.selectors.getResults(state),
    isStccCodeOptionsLoading: stccCodeLoaderState.selectors.getIsLoading(state),
    // House Reference Numbers
    houseReferenceNumberOptions:
      houseReferenceNumberLoaderState.selectors.getResults(state),
    isHouseReferenceNumberOptionsLoading:
      houseReferenceNumberLoaderState.selectors.getIsLoading(state),
    // Bill of Lading number options
    billOfLadingOptions:
      billOfLadingNumberCategoryLoaderState.selectors.getResults(state),
    isBillOfLadingOptionsLoading:
      billOfLadingNumberCategoryLoaderState.selectors.getIsLoading(state),
    // Trailer and Equipment number options
    trailerAndEquipmentNumberOptions:
      trailerAndEquipmentNumberLoaderState.selectors.getResults(state),
    isTrailerAndEquipmentNumberOptionsLoading:
      trailerAndEquipmentNumberLoaderState.selectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchDomainData } = ShipmentsDomainDataState.actionCreators;
  const {
    setSearchCategoryForKey,
    setSearchText,
    setSearchValue,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
    searchLocations,
    searchVins,
  } = ShipmentsSearchBarState.actionCreators;

  return {
    fetchDomainData: () => dispatch(fetchDomainData()),
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) =>
      dispatch(setSearchText(text, ignoreValueChange, true)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: () => dispatch(searchEntities()),
    searchLocations: debouncePromise(
      (query) => dispatch(searchLocations(query)),
      500,
    ),
    searchVins: (query) => dispatch(searchVins(query)),
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    // Category options loaders
    fetchStccCodeOptions: (query, maxResults) => {
      const fetch = stccCodeLoaderState.actionCreators.fetch;
      dispatch(fetch(query, maxResults));
    },
    fetchHouseReferenceNumberOptions: (query, maxResults) => {
      const fetch = houseReferenceNumberLoaderState.actionCreators.fetch;
      dispatch(fetch(query, maxResults));
    },
    fetchBillOfLadingOptions: (query, maxResults) => {
      const fetch = billOfLadingNumberCategoryLoaderState.actionCreators.fetch;
      dispatch(fetch(query, maxResults));
    },
    fetchTrailerAndEquipmentNumberOptions: (query, maxResults) => {
      const fetch = trailerAndEquipmentNumberLoaderState.actionCreators.fetch;
      dispatch(fetch(query, maxResults));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
