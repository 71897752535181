import {
  getBasicQueryString,
  getEverythingQueryString,
} from "../../components/search-bar/search-filter-query-strings";

import { stringIncludesFilterBuilder } from "../../components/search-bar/filters";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("mt-search:Search Everything"),
    placeholder: (t) =>
      t("mt-search:Search Rack ID, Current Location, Tag ID or CSPC"),
    queryBuilder: getEverythingQueryString,
    property: null, // meaning all other properties
    applyFilter: (item, filterValue) => {
      for (const category of SEARCH_CATEGORIES) {
        // Don't call the own filter
        if (category.property === null) {
          continue;
        }
        // Apply all the others
        if (category.applyFilter(item, filterValue)) {
          return true;
        }
      }
      return false;
    },
  },
  {
    queryKey: "idSearch",
    label: (t) => t("mt-search:Rack ID"),
    placeholder: (t) => t("mt-search:Search Rack ID"),
    queryBuilder: getBasicQueryString,
    property: "serialNumbers",
    itemLabelProperty: "label",
    itemValueProperty: "value",
    applyFilter: stringIncludesFilterBuilder("id"),
  },
  {
    queryKey: "tagIdSearch",
    label: (t) => t("mt-search:Tag ID"),
    placeholder: (t) => t("mt-search:Search Tag ID"),
    queryBuilder: getBasicQueryString,
    property: "tagIds",
    itemLabelProperty: "label",
    itemValueProperty: "value",
    applyFilter: stringIncludesFilterBuilder("tagId"),
  },
  {
    queryKey: "cspcIdSearch",
    label: (t) => t("mt-search:CSPC"),
    placeholder: (t) => t("mt-search:Search CSPC"),
    queryBuilder: getBasicQueryString,
    property: "cspcs",
    itemLabelProperty: "label",
    itemValueProperty: "value",
    applyFilter: stringIncludesFilterBuilder("cspcId"),
  },
  {
    queryKey: "descriptionSearch",
    label: (t) => t("mt-search:Rack Type"),
    placeholder: (t) => t("mt-search:Search Rack Type"),
    queryBuilder: getBasicQueryString,
    property: "descriptions",
    itemLabelProperty: "label",
    itemValueProperty: "value",
    applyFilter: stringIncludesFilterBuilder("description"),
  },
  {
    queryKey: "rackLocationSearch",
    label: (t) => t("mt-search:Current Location"),
    placeholder: (t) => t("mt-search:Search Current Location"),
    queryBuilder: getBasicQueryString,
    property: "locations",
    itemLabelProperty: "label",
    itemValueProperty: "value",
    applyFilter: stringIncludesFilterBuilder("rackLocation"),
  },
];
