/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";

import { Modal } from "components/molecules/Modal.molecule";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Button } from "components/atoms/Button.atom";
import { LocationsActionStatus } from "pages/administration/location-management/redux/LocationsState";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { validateHeadersFromCsv } from "shared/utils/csv.utils";

const IMPORT_CSV_HEADERS = [
  "Location Name",
  "Code (location code)",
  "Address 1",
  "Address 2",
  "City",
  "State",
  "Postal Code",
  "Country",
  "LAD",
  "Line of Business",
  "Latitude",
  "Longitude",
];
const IMPORT_CSV_HEADER_LINE = IMPORT_CSV_HEADERS.join(",");

const initFormValuesState = { file: null };

export const ImportLocationsModal = ({
  show,
  hide,
  actionStatus,
  clearActionStatus,
  importLocations,
}) => {
  const { t } = useTranslation("locations");
  const [formValues, setFormValues] = useState(initFormValuesState);
  const formValidation = useValidateForm(formValues);

  const isSubmitting = actionStatus === LocationsActionStatus.STARTING_IMPORT;
  const isSubmissionSuccess =
    actionStatus === LocationsActionStatus.LOCATIONS_IMPORT_SUCCEEDED;
  const isSubmissionFailed =
    actionStatus === LocationsActionStatus.LOCATIONS_IMPORT_FAILED;
  const isImportDisabled =
    !formValidation.isValid || isSubmitting || isSubmissionSuccess;

  const clearForm = () => {
    setFormValues(initFormValuesState);
  };

  const fileSelectHandler = async (file) => {
    if (!file) {
      clearForm();
      return;
    }

    setFormValues({ file });
  };

  const fileDropHandler = (newItems) => {
    let file = null;
    if (newItems?.length === 1) {
      file = newItems[0].getAsFile();
    }

    fileSelectHandler(file);
  };

  const downloadLocationsTemplate = () => {
    const exportFileName = `fv-locations-template-${Date.now()}.csv`;
    fileDownload(IMPORT_CSV_HEADER_LINE, exportFileName);
  };

  const onHide = () => {
    clearForm();
    clearActionStatus();
    hide();
  };

  useEffect(() => {
    if (isSubmissionSuccess) {
      clearForm();
    }
  }, [isSubmissionSuccess]);

  const csvTooltip = (
    <Tooltip id="cvs-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "left",
        }}
      >
        {t("locations:The files must contain the following columns")}:
        <ul css={{ paddingLeft: "1.25em" }}>
          {IMPORT_CSV_HEADERS.map((header) => (
            <li key={header}>{header}</li>
          ))}
        </ul>
      </div>
    </Tooltip>
  );

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      data-qa="modal-import-locations"
    >
      <Modal.Header title={t("locations:Import")} />
      <Modal.Body>
        <div>
          <div
            css={{
              display: "flex",
              marginBottom: "1em",
              alignItems: "center",
            }}
          >
            <span>{t("locations:Select a CSV file to import")}.</span>{" "}
            <Tooltip placement="right" tooltipChildren={csvTooltip}>
              <Icon src={faQuestionCircle} data-qa="icon-info" />
            </Tooltip>
            <Button
              variant="link"
              onClick={() => downloadLocationsTemplate()}
              data-qa="button-download-template"
            >
              {t("locations:Download Template")}
            </Button>
          </div>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <span className="btn btn-light" data-qa="button-browse">
                  {t("locations:Browse")}&hellip;
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      fileSelectHandler(e.target.files[0]);
                    }}
                    hidden
                  />
                </span>
              </label>
              <FormControl
                type="type"
                value={formValues.file?.name ?? ""}
                placeholder={t("locations:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  fileDropHandler(e.dataTransfer.items);
                }}
                readOnly
                data-qa="input-file"
              />
            </InputGroup>
          </FormGroup>
        </div>

        <Alert
          variant={AlertVariant.Success}
          show={isSubmissionSuccess}
          css={{ marginBottom: 0 }}
        >
          {t(
            "locations:Upload successful. You will receive a notification upon completion of location imports.",
          )}
        </Alert>
        <Alert
          variant={AlertVariant.Danger}
          show={isSubmissionFailed}
          css={{ marginBottom: 0 }}
        >
          {t("locations:Upload failed. Please ensure inputs are valid.")}
        </Alert>
        <Alert
          variant={AlertVariant.Danger}
          show={!formValidation.isValid && formValidation.isFileDefined}
          css={{ marginBottom: 0 }}
        >
          {!formValidation.areColumnHeadersValid
            ? t(
                "locations:CSV file column headers are not valid. Please check the selected file.",
              )
            : !formValidation.hasData
            ? t(
                "locations:CSV file must contain at least one location to import.",
              )
            : null}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          disabled={isSubmitting}
          onClick={onHide}
          data-qa="button-cancel"
        >
          {isSubmissionSuccess ? t("locations:Close") : t("locations:Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={isImportDisabled}
          onClick={() => {
            const fileInput = document.getElementById("files");
            const file = fileInput.files[0];
            importLocations(file);
          }}
          data-qa="button-import"
        >
          {isSubmitting ? <Icon src={faSpinner} spin /> : t("locations:Import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImportLocationsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  importLocations: PropTypes.func.isRequired,
};

const initFormValidationState = {
  isValid: false,
  isFileDefined: false,
  hasData: false,
  areColumnHeadersValid: false,
};

function useValidateForm(formValues) {
  const [formValidation, setFormValidation] = useState(initFormValidationState);

  useEffect(() => {
    async function runEffect() {
      const isFileDefined = !_.isNil(formValues.file);
      if (!isFileDefined) {
        setFormValidation(initFormValidationState);
        return;
      }

      // Read only the first line of the file for validating the column headers.
      // The file can be large. We don't want to load the entire file into memory.
      // 256 bytes is at least the first line and a little more to be safe.
      const chunk = formValues.file.slice(0, 256);

      const chunkAsText = await chunk.text();
      const lines = chunkAsText.split(/\r\n|\n/);
      const hasData = lines.length >= 2;

      const areColumnHeadersValid = await validateHeadersFromCsv(
        new File([chunk], formValues.file.name, { type: formValues.file.type }),
        IMPORT_CSV_HEADERS,
        { allowExtra: false },
      );

      setFormValidation({
        isValid: isFileDefined && areColumnHeadersValid && hasData,
        isFileDefined,
        hasData,
        areColumnHeadersValid,
      });
    }

    runEffect();
  }, [formValues]);

  return formValidation;
}
