/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import classNames from "classnames";

import Colors from "styles/colors";
import { isMediumAndDown, useIsMediumAndDown } from "components/responsive";
import {
  translateNavCategoryLabelLookup,
  navCategoryIconLookup,
} from "modules/appnav/nav-utils";

const iconCss = {
  marginTop: -5,
  textAlign: "center",
  fontSize: 24,
  color: Colors.background.DARK_TEAL,
};

const childLinksCss = {
  margin: "-5px 0 10px 0",
  zIndex: 500,
  transition: "opacity 2s ease-out",
};

const mobileGroupCss = {
  transition: "opacity 2s ease-out",
  position: "relative",
  opacity: "1",
  zIndex: "2",
  ".mobile-menu": {
    backgroundColor: Colors.nav.NAVY,
    display: "none",
    marginTop: 3,
    position: "fixed",
    left: 48,

    ".triangle": {
      position: "absolute",
      top: 15,
      left: -10,
      border: "5px solid transparent",
      borderRight: `5px solid ${Colors.nav.NAVY}`,
      height: 5,
      width: 10,
    },
  },

  ".nav-item": {
    paddingLeft: "10px !important",
  },

  a: {
    textDecoration: "none !important",
  },

  "&:hover": {
    ".mobile-menu": {
      display: "block",
    },
  },
};

const desktopGroupCss = {
  a: {
    textDecoration: "none !important",
  },
};

const titleCss = (isOpen, sidebarMinimize) => {
  return {
    alignItems: "center",
    color: Colors.text.HIT_GRAY,
    display: "flex",
    padding: "10px 5px 10px 0",
    position: "relative",
    fontSize: "1.0em",
    marginLeft: "0.5em",

    strong: {
      flexGrow: 1,
      fontWeight: 700,
    },

    "&:hover": {
      color: "white",

      ".navicon": {
        color: Colors.background.AQUA,
      },

      ".triangle": {
        borderBottomColor: isOpen ? "white" : "none",
        borderTopColor: isOpen ? "none" : "white",
      },
    },
  };
};

const triangleCss = (isOpen) => {
  return {
    width: 20,
    minWidth: 20,
    textAlign: "center",
    marginRight: 5,

    ".triangle": {
      display: "inline-block",
      border: "5px solid transparent",
      borderBottom: isOpen ? `5px solid ${Colors.text.HIT_GRAY}` : "none",
      borderTop: isOpen ? "none" : `5px solid ${Colors.text.HIT_GRAY}`,
      height: 5,
      verticalAlign: "middle",
      width: 10,
    },
  };
};

export const NavGroup = ({
  groupKey,
  isOpen,
  toggleOpenGroup,
  sidebarMinimize,
  children,
}) => {
  const { t } = useTranslation("appnav");

  useIsMediumAndDown();
  const med = isMediumAndDown();
  const classDesktop = classNames({
    fader: !sidebarMinimize && !med,
    "fader-hide": sidebarMinimize || med,
  });
  const classMobile = classNames("icon-clickable", {
    "fader-mobile": sidebarMinimize || med,
    "fader-mobile-hide": !sidebarMinimize && !med,
  });

  let translatedTitle = translateNavCategoryLabelLookup(t, groupKey);
  let icon = navCategoryIconLookup(groupKey);

  return (
    <React.Fragment>
      <div className={classDesktop}>
        <div className={classDesktop} css={desktopGroupCss}>
          <div
            className="icon-clickable"
            css={titleCss(isOpen, sidebarMinimize)}
            onClick={() => toggleOpenGroup(groupKey)}
            data-qa={`navigation-group-${groupKey}`}
          >
            <div
              css={iconCss}
              className="navicon"
              style={{
                width: 34,
                minWidth: 34,
                minHeight: 34,
              }}
            >
              {icon}
            </div>
            <strong>{translatedTitle}</strong>
            <div
              className="icon-clickable"
              css={triangleCss(isOpen, sidebarMinimize)}
            >
              <i className="triangle" />
            </div>
          </div>
          <div
            className="child-links"
            css={childLinksCss}
            style={{ display: isOpen ? "block" : "none" }}
          >
            {children}
          </div>
        </div>
      </div>
      <div className={classMobile}>
        <div className={classMobile} css={mobileGroupCss}>
          <div className="mobile-menu">
            <i className="triangle" />
            <div
              css={{
                padding: 10,
              }}
            >
              <strong>{translatedTitle}</strong>
            </div>
            <div css={childLinksCss}>{children}</div>
          </div>
          <div
            css={iconCss}
            style={{
              width: 48,
              height: 48,
              lineHeight: "48px",
            }}
          >
            {icon}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

NavGroup.propTypes = {
  groupKey: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleOpenGroup: PropTypes.func,
  sidebarMinimize: PropTypes.bool,
  children: PropTypes.node,
};
