/**
 * The domain data for this module is generated by getting listing all data
 * from the list endpoint and organizing this in the way we need that.
 */
import axios from "axios";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";
import { translateEntity } from "../mt-search/MetalTrackingSearchBarState";

const STORE_MOUNT_POINT = "mtDomainData";

// Actions
const FVE = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const RECEIVE_METAL_TRACKING_DOMAIN_DATA = FVE(
  "RECEIVE_METAL_TRACKING_DOMAIN_DATA",
);

export function fetchDomainData(solutionId) {
  // H1-1445: Set page size manually
  const url = apiUrl(`/entity/solution/GM_PAT/entity?pageSize=100000`);

  return (dispatch) => {
    return Promise.all([axios.get(`${url}`)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_METAL_TRACKING_DOMAIN_DATA,
          payload: responses[0].data.data,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Helpers
/**
 * Convert stored Set of data to an option
 */
const buildOptionsSelector = (property) => {
  return (state) => {
    return Array.from(
      state[STORE_MOUNT_POINT].metalTrackingDomainData[property],
    ).map((item) => ({
      label: item,
      value: item,
    }));
  };
};

// Selectors
const getSerialNumbers = buildOptionsSelector("serialNumbers");
const getLocations = buildOptionsSelector("locations");
const getTagIds = buildOptionsSelector("tagIds");
const getCspcs = buildOptionsSelector("cspcs");
const getDescriptions = buildOptionsSelector("descriptions");
const getMaxQuantities = buildOptionsSelector("maxQuantities");
const getMaxQuantitiesOrdered = alfBy(getMaxQuantities, "maxQuantity");
const getLocationsAlphabetically = alfBy(getLocations, "name");
const getDescriptionsAlphabetically = alfBy(getDescriptions, "name");

// Initial state
const initialState = {
  metalTrackingDomainData: {
    serialNumbers: [],
    locations: [],
    tagIds: [],
    cspcs: [],
    descriptions: [],
    maxQuantities: [],
  },
};

const MetalTrackingDomainDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_METAL_TRACKING_DOMAIN_DATA:
      let standardizedEntities = action.payload.map(translateEntity);
      let data = {
        serialNumbers: new Set(),
        locations: new Set(),
        tagIds: new Set(),
        cspcs: new Set(),
        descriptions: new Set(),
        maxQuantities: new Set(),
      };
      for (let entity of standardizedEntities) {
        data.serialNumbers.add(entity.id);
        data.locations.add(entity.rackLocation);
        data.tagIds.add(entity.tagId);
        data.cspcs.add(entity.cspcId);
        data.descriptions.add(entity.description);
        data.maxQuantities.add(entity.maxQuantity);
      }
      return {
        ...state,
        metalTrackingDomainData: data,
      };
    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getSerialNumbers,
    getLocations,
    getTagIds,
    getCspcs,
    getLocationsAlphabetically,
    getDescriptions,
    getDescriptionsAlphabetically,
    getMaxQuantities,
    getMaxQuantitiesOrdered,
  },
  reducer: MetalTrackingDomainDataReducer,
};
