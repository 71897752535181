/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import BootstrapModal from "react-bootstrap/Modal";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Button } from "components/atoms/Button.atom";
import BootstrapButton from "react-bootstrap/Button";
import { Icon } from "components/atoms/Icon.atom";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import { useIsExtraSmall } from "components/responsive";
import { Text, FontSize } from "components/atoms/Text.atom";
import { useUncontrolled } from "uncontrollable";
import BootstrapDropdownItem from "react-bootstrap/DropdownItem";
import { PanelGroupTheme } from "./enum";

export const Dropdown = (props) => {
  // This hook allows us to have a controlled or uncontrolled depending on what is passed in.
  // The prop `show` is mapped to its handler `onToggle`.
  // If show is missing in props, the `show` state will be maintained in useUncontrolled.
  const {
    responsiveModalMenu = false,
    onToggle,
    show,
    id,
    variant = "transparent",
    className,
    "data-qa": dataQa,
    icon,
    autoClose = true,
    text,
    textStyle = {},
    children,
    as = BootstrapButton,
    alignMenuRight = true,
  } = useUncontrolled(props, {
    show: "onToggle",
  });

  const isExtraSmall = useIsExtraSmall();
  const showMenuAsModal = responsiveModalMenu && isExtraSmall;

  const handleOnToggle = (isShown, e) => {
    if (onToggle) {
      onToggle(isShown, e);
    }
  };

  const handleOnClose = () => {
    handleOnToggle(false);
  };

  return (
    <BootstrapDropdown
      id={id}
      className={className}
      show={show}
      onToggle={handleOnToggle}
      focusFirstItemOnShow={false}
      style={{ maxWidth: "100%" }}
      data-qa={dataQa}
      autoClose={autoClose}
    >
      <BootstrapDropdown.Toggle
        variant={variant}
        as={as}
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: "2px 10px",
        }}
      >
        <Text
          style={textStyle}
          size={FontSize.size16}
          truncate
          className="me-2"
        >
          {text}
        </Text>
        {icon}
      </BootstrapDropdown.Toggle>
      {!showMenuAsModal ? (
        <BootstrapDropdown.Menu
          rootCloseEvent="click"
          align={alignMenuRight ? "end" : "start"}
        >
          {children}
        </BootstrapDropdown.Menu>
      ) : null}
      {/*
       * Render the modal (for mobile) inside of the Dropdown.
       * H1-3712: This fixed an issue when this component is used in the Organization select dropdown.
       *
       * The assumption is that react-boostrap Dropdown controls the render of the children in some way.
       * If the modal is outside of it, it won't be affected by Dropdown's built in behavior.
       */}
      <BootstrapModal show={showMenuAsModal && show} onHide={handleOnClose}>
        <PanelGroup theme={PanelGroupTheme.Light}>
          <PanelGroup.Header
            title={text ?? ""}
            leftContent={icon}
            rightContent={
              <Button variant="outline-dark" onClick={handleOnClose}>
                <Icon src={faTimes} />
              </Button>
            }
          />
          <PanelGroup.Content style={{ padding: 0 }}>
            {children}
          </PanelGroup.Content>
        </PanelGroup>
      </BootstrapModal>
    </BootstrapDropdown>
  );
};

Dropdown.propTypes = {
  /**
   * This displays the DropDown's menu in a modal on mobile.
   */
  responsiveModalMenu: PropTypes.bool,
  /**
   * The menu content when the dropdown is open.
   */
  children: PropTypes.node.isRequired,
  /**
   * Change the entire dropdown button to a custom component.
   */
  as: PropTypes.elementType,
  /**
   * A callback to get the latest value of if the menu is shown.
   *
   * Example:
   * (isShown) => {
   *   // Do something with the new value
   * }
   *
   */
  onToggle: PropTypes.func,
  /**
   * State to control if the menu is open.
   */
  show: PropTypes.bool,
  /**
   * The element ID for the root Dropdown.
   */
  id: PropTypes.string,
  /**
   * The button variant, which defines it's style, for the Dropdown Toggle.
   *
   * Use the `ButtonVariant` type for this value.
   */
  variant: PropTypes.string,
  /**
   * The class name to apply to the root Dropdown.
   */
  className: PropTypes.string,
  /**
   * An object that will be used as the element styles for the text inside the Dropdown Toggle button.
   */
  textStyle: PropTypes.object,
  /**
   * The test ID for QA.
   */
  "data-qa": PropTypes.string,
  /**
   * The icon to show along side the text.
   */
  icon: PropTypes.node,
  /**
   * The text to show in the dropdown button.
   */
  text: PropTypes.string.isRequired,
  /**
   * Whether or not to align the menu to the right.
   * This value is ignored on mobile.
   */
  alignMenuRight: PropTypes.bool,
  autoClose: PropTypes.string,
};

const DropdownItem = ({
  eventKey,
  disabled,
  children,
  onClick,
  style = {},
  className,
  "data-qa": dataQa,
  as,
}) => {
  return (
    <BootstrapDropdownItem
      disabled={disabled}
      eventKey={eventKey}
      className={className}
      style={style}
      onClick={onClick}
      data-qa={dataQa}
      as={as}
    >
      {children}
    </BootstrapDropdownItem>
  );
};

DropdownItem.propTypes = {
  /**
   * Disable the Dropdown Item from being clickable.
   */
  disabled: PropTypes.bool,
  /**
   * Something to define what the unique aspect of this item is.
   */
  eventKey: PropTypes.any,
  /**
   * An object that will be used as the element styles for the Dropdown Item.
   */
  style: PropTypes.object,
  /**
   * A string for defining one or more classes that will be added to the Dropdown Item.
   */
  className: PropTypes.string,
  /**
   * The event fired when the user clicks the Dropdown Item.
   */
  onClick: PropTypes.func,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
  /**
   * The content inside the Dropdown Item.
   */
  children: PropTypes.any,
  as: PropTypes.elementType,
};

Dropdown.Item = DropdownItem;

const DropdownDivider = () => {
  return <BootstrapDropdown.Divider />;
};

Dropdown.Divider = DropdownDivider;

// Exports for storybook. Need to export so their props can be seen in the docs tab.
// Do not use these exports! Use `Dropdown.Item` instead.
export { DropdownItem, DropdownDivider };
