/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel, FormControl, FormText } from "react-bootstrap";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";

import { useTranslation } from "react-i18next";

import { Modal } from "components/molecules/Modal.molecule";
import { FormButton } from "components-old/modal-elems";
import { ReportTypes } from "pages/reports/search/Reports.columns";

//Create and Edit folder

const validateCreateFolderForm = (folderName, t) => {
  if (!folderName) {
    return { error: true, errorText: t(`reports:"Folder Name" is required`) };
  }
  return { error: false };
};

export const CreateOrEditFolderModal = ({
  show,
  hide,
  report = {},
  toggleCreateAndEditModal = null,
  folderCategory,
  createFolderModal,
  createFolderData,
  editFolderModal,
  editFolderData,
  modalReportType,
}) => {
  const { t } = useTranslation("reports");
  const [folderName, setFolderName] = useState("");
  const [folderDesc, setFolderDesc] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showError, setShowError] = useState(false);
  const [hasRequestedCreate, setHasRequestedCreate] = useState(false);
  const [hasRequestedEdit, setHasRequestedEdit] = useState(false);

  const folderCategoryFunction = (reportType) => {
    switch (reportType) {
      case ReportTypes.CORE_REPORTS:
        return t("reports:Core Reports");
      case ReportTypes.SHARED_REPORTS:
        return t("reports:Shared Reports");
      case ReportTypes.MY_REPORTS:
        return t("reports:My Reports");
      default:
        return reportType;
    }
  };

  useEffect(() => {
    if (show === false) {
      setShowError(false);
      setHasRequestedCreate(false);
      setHasRequestedEdit(false);

      setFolderName("");
      setFolderDesc("");
    }
  }, [show]);

  useEffect(() => {
    if (toggleCreateAndEditModal === false) {
      if (report.directory) {
        setFolderName(report.directory.name);
        setFolderDesc(report.directory.description);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCreateAndEditModal]);

  useEffect(() => {
    if (hasRequestedCreate) {
      // After we requested data
      // Check if there are errors to display
      if (createFolderData?.isLoadingError) {
        setErrorText(t("reports:Something went wrong"));
        setShowError(true);
        setHasRequestedCreate(false);
        return;
      }
      modalReportType(folderCategory);
      hide();
    }
    if (hasRequestedEdit) {
      // After we requested data
      // Check if there are errors to display
      if (editFolderData?.isLoadingError) {
        setErrorText(t("reports:Something went wrong"));
        setShowError(true);
        setHasRequestedEdit(false);
        return;
      }
      modalReportType(folderCategory);
      hide();
    }
    //eslint-disable-next-line
  }, [
    createFolderData,
    editFolderData,
    t,
    hasRequestedCreate,
    hasRequestedEdit,
    setHasRequestedCreate,
    setHasRequestedEdit,
    folderCategory,
  ]);

  let createOrEditButtonText;

  if (toggleCreateAndEditModal) {
    createOrEditButtonText = createFolderData?.isLoading
      ? t("reports:Creating...")
      : t("reports:Create");
  } else {
    createOrEditButtonText = editFolderData?.isLoading
      ? t("reports:Saving...")
      : t("reports:Save");
  }

  return (
    <Modal backdrop="static" show={show} onHide={() => hide()}>
      <Modal.Header
        title={
          toggleCreateAndEditModal
            ? t("reports:Create Folder")
            : t("reports:Edit Folder")
        }
      />
      <Modal.Body>
        <Alert
          variant={AlertVariant.Danger}
          show={showError}
          dismissible
          onDismiss={() => setShowError(false)}
        >
          {errorText}
        </Alert>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel>
            <sup>*</sup>
            {t("reports:Folder Name")}
          </FormLabel>
          <FormControl
            defaultValue={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            onSelect={(e) => setFolderName(e.target.value)}
          />
        </FormGroup>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel>{t("reports:Description")}</FormLabel>
          <FormControl
            as="textarea"
            defaultValue={folderDesc}
            onChange={(e) => setFolderDesc(e.target.value)}
            onSelect={(e) => setFolderDesc(e.target.value)}
          />
        </FormGroup>
        {toggleCreateAndEditModal ? (
          <FormText
            muted
            style={{
              fontSize: "80%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>{t("reports:NOTE")}:</span>
            <span style={{ marginLeft: "0.25rem" }}>
              {t(
                `reports:Folder will be created under 
                 ${folderCategoryFunction(folderCategory)}
                 Category.`,
              )}
            </span>
          </FormText>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          label={t("reports:Cancel")}
          disabled={createFolderData?.isLoading}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType="ACTION"
          label={createOrEditButtonText}
          disabled={createFolderData?.isLoading}
          clickHandler={() => {
            const { error, errorText } = validateCreateFolderForm(
              folderName,
              t,
            );
            if (error) {
              setErrorText(errorText);
              setShowError(true);
              return;
            }

            // to post the details in the backend, createFolder coming from ReportFolderModalState

            let requestCreate;
            let requestEdit;
            if (toggleCreateAndEditModal) {
              requestCreate = createFolderModal(
                folderCategory.toLowerCase(),
                folderName,
                folderDesc,
              );
              requestCreate.then(() => {
                setHasRequestedCreate(true);
              });
            } else {
              requestEdit = editFolderModal(
                report,
                folderCategory.toLowerCase(),
                folderName,
                folderDesc,
              );
              requestEdit.then(() => {
                setHasRequestedEdit(true);
              });
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

CreateOrEditFolderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  report: PropTypes.object,
  toggleCreateAndEditModal: PropTypes.any,
  folderCategory: PropTypes.string,
  createFolderModal: PropTypes.func.isRequired,
  createFolderData: PropTypes.object,
  editFolderModal: PropTypes.func.isRequired,
  editFolderData: PropTypes.object,
  modalReportType: PropTypes.func.isRequired,
};
