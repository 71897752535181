import { connect } from "react-redux";
import ReportSchedulesState from "../../redux/ReportSchedulesState";
import { SendEmailModal } from "./EmailReport.modals";

const { getSendEmailData } = ReportSchedulesState.selectors;
const { sendEmail } = ReportSchedulesState.actionCreators;

const mapStateToProps = (state) => {
  return {
    sendEmailData: getSendEmailData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (...args) => dispatch(sendEmail(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal);
