/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
} from "utils/date-time";
import Colors from "styles/colors";

const DataRenderer = ({ value }) => {
  return <Text>{value}</Text>;
};

const LocationsRenderer = (props) => {
  const { locations } = props.value;
  const elements = locations
    ? locations.slice(0, 3).map((location, index) => (
        <div key={index}>
          <Text>{`${location.details.location_name} (${location.details.location_code})`}</Text>
        </div>
      ))
    : null;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {elements}
      {locations && locations.length > 3 ? (
        <OverlayTrigger
          rootClose
          placement="bottom-end"
          trigger={["click"]}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body
                css={{
                  padding: "0.25em 0 0.25em 0.25em",
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                <SimpleBar
                  autoHide={false}
                  css={{
                    minWidth: "180px",
                    maxHeight: "200px",
                    ".simplebar-scrollbar::before": {
                      backgroundColor: "#fff",
                    },
                  }}
                >
                  <div
                    css={{
                      width: "100%",
                      paddingRight: "5%",
                    }}
                  >
                    <ul
                      css={{
                        margin: 0,
                        padding: "0px",
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.125em",
                      }}
                    >
                      {locations
                        .slice(3, locations.length)
                        .map((location, index) => (
                          <li key={index}>
                            <Text>{`${location.details.location_name} (${location.details.location_code})`}</Text>
                          </li>
                        ))}
                    </ul>
                  </div>
                </SimpleBar>
              </Popover.Body>
            </Popover>
          }
        >
          <Button
            variant="link"
            style={{
              padding: "0px",
              display: "flex",
            }}
          >{`${locations.length - 3} more...`}</Button>
        </OverlayTrigger>
      ) : null}
    </div>
  );
};

const ContainersRenderer = (props) => {
  const { containers } = props.value;
  const elements = containers
    ? containers.slice(0, 3).map((container) => (
        <div key={container.id}>
          <Text>{`${container.containerId} (${container.type})`}</Text>
        </div>
      ))
    : null;
  if (elements) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {elements}
        {containers && containers.length > 3 ? (
          <OverlayTrigger
            rootClose
            placement="bottom-end"
            trigger={["click"]}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body
                  css={{
                    padding: "0.25em 0 0.25em 0.25em",
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  <SimpleBar
                    autoHide={false}
                    css={{
                      minWidth: "180px",
                      maxHeight: "200px",
                      ".simplebar-scrollbar::before": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        paddingRight: "5%",
                      }}
                    >
                      <ul
                        css={{
                          margin: 0,
                          padding: "0px",
                          listStyle: "none",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.125em",
                        }}
                      >
                        {containers
                          .slice(3, containers.length)
                          .map((container) => (
                            <li key={container.id}>
                              <Text>{`${container.containerId} (${container.type})`}</Text>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </SimpleBar>
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              variant="link"
              style={{
                padding: "0px",
                display: "flex",
              }}
            >{`${containers.length - 3} more...`}</Button>
          </OverlayTrigger>
        ) : null}
      </div>
    );
  }
  return null;
};

const ActionRenderer = (props) => {
  const { editRouteHandler, containers, locations, routeName } = props.value;
  const { t } = useTranslation("container-tracking");
  return (
    <Button
      variant="link"
      css={{
        color: Colors.text.GRAY,
        ":hover": {
          color: Colors.background.DARK_BLUE,
        },
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        editRouteHandler(locations, containers, routeName);
      }}
      data-qa="button-new-route"
    >
      <Text italic underline>
        {t("components:Edit Route")}
      </Text>
    </Button>
  );
};

const LastUpdateCell = (props) => {
  const { lastMilestone } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {lastMilestone?.datetime ? (
        <div className="d-flex flex-wrap">
          <Text css={{ marginLeft: "0.25rem" }}>
            {`${localizedTimeFormatter(
              lastMilestone?.datetime,
            )} ${localizedDateFormatter(lastMilestone?.datetime)}`}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

export const getRouteManagementColumns = (t, editRouteHandler) => {
  return [
    {
      Header: t("container-tracking:Route Name"),
      id: "route_name",
      Cell: DataRenderer,
      accessor: "name",
      width: 110,
    },
    {
      Header: t("container-tracking:Route Locations"),
      id: "total",
      Cell: LocationsRenderer,
      accessor: (obj) => {
        return {
          locations: obj?.locations ?? [],
        };
      },
      width: 110,
    },
    {
      Header: t("container-tracking:Associated Containers (Container Type)"),
      id: "containers",
      Cell: ContainersRenderer,
      accessor: (obj) => {
        return {
          containers: obj?.containers ?? [],
        };
      },
      width: 110,
    },
    {
      Header: t("container-tracking:Actions"),
      id: "action",
      Cell: ActionRenderer,
      accessor: (obj) => {
        return {
          containers: obj?.containers ?? [],
          routeName: obj?.name ?? "",
          locations: obj?.locations ?? [],
          editRouteHandler: editRouteHandler,
        };
      },
      width: 110,
    },
  ];
};

export const getManagementColumns = (t) => {
  return [
    {
      Header: t("container-tracking:Container Id"),
      id: "containerId",
      Cell: DataRenderer,
      accessor: "containerId",
      width: 110,
    },
    {
      Header: t("container-tracking:Container Type"),
      id: "containerType",
      Cell: DataRenderer,
      accessor: "type",
      width: 110,
    },
    {
      Header: t("container-tracking:Tag Id"),
      id: "tagId",
      Cell: DataRenderer,
      accessor: "tagId",
      width: 110,
    },
    {
      Header: t("container-tracking:Last Update"),
      id: "lastUpdate",
      Cell: LastUpdateCell,
      accessor: (d) => {
        return {
          lastMilestone: d.lastMilestone,
        };
      },
      width: 110,
    },
  ];
};
