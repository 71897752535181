import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import OrganizationsSearchBarState from "./OrganizationsSearchBarState";

let mapStateToProps = (state) => {
  let {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
  } = OrganizationsSearchBarState.selectors;

  return {
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
  };
};

let {
  setSearchText,
  clearSearchText,
  resetSearchBar,
  setSearchCategoryForKey,
  searchEntities,
  clearSavedSearch,
} = OrganizationsSearchBarState.actionCreators;

let actionCreators = {
  setSearchText,
  clearSearchText,
  resetSearchBar,
  setSearchCategory: setSearchCategoryForKey,
  searchEntities,
  clearSavedSearch,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
