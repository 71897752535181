/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { RotatingExpanderCell } from "components/organisms/base-table/Cell/RotatingExpanderCell";
import Colors from "styles/colors";

const CellRenderer = (props) => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>{props.value}</div>
  );
};

CellRenderer.propTypes = {
  value: PropTypes.string,
};

const StatusCellRenderer = (props) => {
  return (
    <div
      css={{
        textOverflow: "auto",
        overflow: "none",
        color: props.value === "Failed" ? Colors.highlight.RED : "inherit",
      }}
    >
      {props.value}
    </div>
  );
};

StatusCellRenderer.propTypes = {
  value: PropTypes.string,
};

const ShipmentIdCellRenderer = ({
  value,
  shipmentInternalId,
  pushShipmentDetailView,
}) => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>
      {shipmentInternalId ? (
        <Button
          variant="link"
          onClick={() => {
            if (pushShipmentDetailView) {
              pushShipmentDetailView(shipmentInternalId);
            }
          }}
        >
          <Text underline>{value}</Text>
        </Button>
      ) : (
        value
      )}
    </div>
  );
};

ShipmentIdCellRenderer.propTypes = {
  value: PropTypes.string,
  shipmentInternalId: PropTypes.number,
  pushShipmentDetailView: PropTypes.func,
};

// Columns to display in API Logs search results table
export const getColumns = (t, auth, pushShipmentDetailView) => {
  let columns = [];

  columns.push({
    Header: () => null,
    id: "expander",
    Cell: RotatingExpanderCell,
    width: 30,
    maxWidth: 30,
    disableSortBy: true,
    disableResizing: true,
    accessor: () => {
      return {
        t: t,
      };
    },
  });

  columns.push({
    Header: t("documentation:Received Time"),
    accessor: "receivedTime",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Source"),
    accessor: "source",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Event Type"),
    accessor: "eventType",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Shipment ID"),
    accessor: "shipmentId",
    width: 300,
    Cell: (cellProps) => {
      return (
        <ShipmentIdCellRenderer
          {...cellProps}
          // This is the ID that appears in the URL of the details page.
          shipmentInternalId={cellProps.row.original.shipmentInternalId}
          pushShipmentDetailView={pushShipmentDetailView}
        />
      );
    },
  });

  columns.push({
    Header: auth.hasOrganizationTypes("Shipper")
      ? t("documentation:Carrier")
      : t("documentation:Shipper"),
    accessor: "orgName",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Status"),
    accessor: "status",
    Cell: StatusCellRenderer,
  });

  return columns;
};
