import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "../components/search/SurgicalToteTracking.Search.Options";
import apiUrl from "api-url";
import SearchState from "./SurgicalToteTrackingSearchBarState";
import { getSurgicalToteTrackingSolutionId } from "./SurgicalToteTrackingSelectors";

const STORE_MOUNT_POINT = "sttSavedSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: (state) => {
    const solution_id = getSurgicalToteTrackingSolutionId(state);
    return solution_id;
  },
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter,
  },
  isSearchTypeFunction: true,
});
