import { connect } from "react-redux";

import { MetalTrackingDashboardView } from "./MetalTrackingDashboardView";
import MetalTrackingSearchBarState, {
  toggleWatchedRackFlag,
  fetchMetalTrackingEntities,
} from "../mt-search/MetalTrackingSearchBarState.js";
import { getSolutionId } from "../organizations/OrganizationsState";
import TitleState from "shared/redux/TitleState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    metalTrackingEntities:
      MetalTrackingSearchBarState.selectors.getEntities(state),
    solutionId: getSolutionId(state),
  };
}

function mapDispatchToProps(dispatch) {
  // Handling permissions to access some features. To mark something as
  // watched, it needs MANAGE_ENTITY permission. To access locations results
  // it needs VIEW_LOCATIONS.
  let toggleWatchedRackFlagCall = (solutionId, entity) =>
    dispatch(toggleWatchedRackFlag(solutionId, entity));

  let chooseLocation = (value) => {
    dispatch(MetalTrackingSearchBarState.actionCreators.clearSearchText());
    dispatch(MetalTrackingSearchBarState.actionCreators.clearSearchFilters());
    dispatch(
      MetalTrackingSearchBarState.actionCreators.setSearchFilter(
        "rackLocation",
        [value],
      ),
    );
    dispatch({
      type: "METAL_TRACKING_LOCATION_RESULTS",
      payload: { rackLocation: value },
    });
  };

  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setDescription: (description) =>
      dispatch(TitleState.actionCreators.setDescription(description)),
    fetchMetalTrackingEntities: (solutionId) =>
      dispatch(fetchMetalTrackingEntities(solutionId)),
    setSearchFilter: (key, value) => {
      dispatch(MetalTrackingSearchBarState.actionCreators.clearSearchText());
      dispatch(MetalTrackingSearchBarState.actionCreators.clearSearchFilters());
      dispatch(
        MetalTrackingSearchBarState.actionCreators.setSearchFilter(key, value),
      );
      dispatch({ type: "METAL_TRACKING_SEARCH_RESULTS" });
    },
    toggleWatchedRackFlag: toggleWatchedRackFlagCall,
    chooseLocation: chooseLocation,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MetalTrackingDashboardView);
