/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontSize } from "components/atoms/enums";

// Components
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  FlexRowDiv,
  FlexColDiv,
  FlexDiv,
} from "../../../styles/container-elements";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { TextInput } from "components/atoms/TextInput.atom";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";

// Hooks
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useOverrideLocationMutation } from "../react-queries/OverrideCurrentLocation";
import { useNotAllSuccessfulColumns } from "pages/driveaway/components/search/DriveAway.NotAllSuccessful.columns";

export const SetCurrentLocationOverridePage = ({ solutionId }) => {
  const initialState = {
    vin: "",
    locationCode: "",
    importFileCsvData: "",
    bulkActionSubmitBtnDisabled: true,
    showResultModal: false,
  };

  const uploadMutation = useOverrideLocationMutation();

  const columns = useNotAllSuccessfulColumns();

  const { t } = useTranslation("internal-tools");

  useSetTitleOnMount(t("internal-tools:Set Current Location Tool"));

  const csvTooltip = (
    <Fragment>
      <Text>{`${t("internal-tools:The file must contain")}:`}</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "nowrap",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:VIN")}</li>
        <li>{t("internal-tools:Location Code")}</li>
      </ul>
    </Fragment>
  );

  const csvTemplate = {
    fileName: `template-current-location-override-${Date.now()}.csv`,
    headers: ["VIN", "Location Code"],
    data: [
      ["VIN1", "Location1"],
      ["VIN2", "Location2"],
      ["VIN3", "Location3"],
    ],
  };

  SetCurrentLocationOverridePage.propTypes = {
    solutionId: PropTypes.string,
  };

  const requestResults = uploadMutation?.data?.failed_data ?? []; // provides the list of failed VIN's and reasons

  // State
  const [vin, setVin] = useState(initialState.vin);
  const [locationCode, setLocationCode] = useState(initialState.locationCode);
  const [importFileCsvData, setImportFileCsvData] = useState(
    initialState.importFileCsvData,
  );
  const [importFile, setImportFile] = useState(null);
  const [showResultModal, setShowResultModal] = useState(
    initialState.showResultModal,
  );

  // Handlers
  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  // csv file upload handler
  const onBulkSubmitHandler = () => {
    setShowResultModal(true);

    const csvData = importFileCsvData ? importFileCsvData.split("\n") : [];
    const body = {
      csvData: csvData,
    };
    uploadMutation.mutate({ solutionId, payload: body, isBulkUpload: true });
  };

  // single VIN update handler
  const onSingleSubmitHandler = () => {
    setShowResultModal(true);
    const body = {
      vin: vin,
      locationCode: locationCode,
    };
    uploadMutation.mutate({ solutionId, payload: body, isBulkUpload: false });
  };

  const isBulkSubmitEnabled = importFileCsvData?.length > 0;
  const vinRegex = new RegExp(`[A-Z0-9]{17}`);
  const isSubmitEnabled = vinRegex.test(vin) && !_.isEmpty(locationCode.trim());

  return (
    <Fragment>
      <div css={{ padding: "1em", flexDirection: "column" }}>
        <FlexColDiv
          css={{
            padding: "1em",
          }}
        >
          <Text size={FontSize.size18} bold>
            {t("internal-tools:Bulk Action")}
          </Text>
          <Text block={true} style={{ marginBottom: "1em" }}>
            {t("internal-tools:Apply to multiple VINs using a csv file")}
            {":"}
          </Text>
          <FlexRowDiv css={{ marginBottom: "1em" }}>
            <div style={{ marginRight: "1em" }}>
              <Text>{t("internal-tools:Select a CSV file to import")}.</Text>{" "}
              <Tooltip placement="top" tooltipChildren={csvTooltip}>
                <Icon size={FontSize.size18} src={faQuestionCircle} />
              </Tooltip>
            </div>

            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
            />
          </FlexRowDiv>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-light">
                  {t("internal-tools:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onFileLoad;
                      setImportFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importFile?.name ?? ""}
                placeholder={t("internal-tools:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>
          <Button
            variant="primary"
            disabled={!isBulkSubmitEnabled}
            onClick={onBulkSubmitHandler}
            style={{ width: "fit-content" }}
          >
            {t("internal-tools:Upload")}
          </Button>
        </FlexColDiv>
        <hr />
        <div id="singleAction">
          <FlexColDiv
            css={{
              padding: "1em",
            }}
          >
            <Text size={FontSize.size18} bold>
              {t("internal-tools:Single Action")}
            </Text>
            <Text block={true} style={{ marginBottom: "1em" }}>
              {t("internal-tools:Override current location on a VIN")}
            </Text>

            <FlexDiv
              css={{
                marginTop: "1em",
                flexDirection: "row",
                flexGrow: "1",
                [MediaQueries.smallAndDown]: {
                  flexDirection: "column",
                },
              }}
            >
              <FlexColDiv
                css={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  minWidth: "20em",
                }}
              >
                <Text>{t("internal-tools:VIN")}</Text>
                <TextInput onChange={setVin} maxLength={17}></TextInput>
              </FlexColDiv>
              <FlexColDiv
                css={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  minWidth: "20em",
                }}
              >
                <Text>{t("internal-tools:Location Code")}</Text>
                <TextInput onChange={setLocationCode}></TextInput>
              </FlexColDiv>
            </FlexDiv>
            <Button
              variant="primary"
              disabled={!isSubmitEnabled}
              onClick={onSingleSubmitHandler}
              style={{
                width: "fit-content",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              {t("internal-tools:Submit")}
            </Button>
            <div css={{ color: Colors.text.DIM_GRAY }}>
              <Text
                size={FontSize.size18}
                block
                css={{ marginBottom: "0.5em" }}
              >
                {t("internal-tools:Important Notes")}:
              </Text>
              <Text size={FontSize.size16} block>
                *{" "}
                {t(
                  "internal-tools:The Date/Time of the submission will become the “Event Time” of the Current Location.",
                )}
              </Text>
              <Text size={FontSize.size16} block>
                *{" "}
                {t(
                  "internal-tools:Once a qualified Event with a more recent Event Time occurs, the Current Location will then update.",
                )}
              </Text>
            </div>
          </FlexColDiv>
        </div>
      </div>
      <DialogModal
        style={{ width: "50%" }}
        show={showResultModal}
        onHide={() => {
          setShowResultModal(false);
          uploadMutation.reset();
        }}
        isLoading={uploadMutation?.isPending}
        loadStatus={"success"}
        title={
          uploadMutation?.variables?.isBulkUpload
            ? t("internal-tools:Bulk Action")
            : t("internal-tools:Single Action")
        }
        okButtonText={t("internal-tools:Close")}
        submitButtonVariant="primary"
        size="md"
        submitButtonDisabled={true}
        showCloseButton={false}
      >
        {uploadMutation?.isPending ? <UploadInProgressModalBody /> : null}

        {uploadMutation?.isError ? <UploadErrorModalBody /> : null}

        {uploadMutation?.isSuccess ? (
          requestResults.length === 0 ? (
            <UploadAllSuccessModalBody />
          ) : (
            <UploadNotAllSuccessModalBody
              columns={columns}
              requestResults={requestResults}
            />
          )
        ) : null}
      </DialogModal>
    </Fragment>
  );
};

const UploadAllSuccessModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faCheckCircle} color="green" size={FontSize.size18} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t(
              "internal-tools:Action(s) successfully completed for all VIN(s)",
            )}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadErrorModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={FontSize.size18} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("internal-tools:There was an error. Please try again later.")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadNotAllSuccessModalBody = ({ columns, requestResults }) => {
  const { t } = useTranslation("internal-tools");
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={FontSize.size18} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        <Text size={FontSize.size16}>
          {t("internal-tools:Action(s) was not successful for all VIN(s).")}
        </Text>
        <Text css={{ marginTop: "1em" }}>
          {t(
            "internal-tools:Please see the below list for VIN(s) that were not successful and the reasons why",
          )}
          {":"}
        </Text>
        <BaseTable
          theme={Themes.LIGHT}
          columns={columns}
          data={requestResults}
        />
      </div>
    </Fragment>
  );
};

UploadNotAllSuccessModalBody.propTypes = {
  requestResults: PropTypes.array,
  columns: PropTypes.array,
};

const UploadInProgressModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "2.5em 0" }}>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faSpinner} spin size={FontSize.size18} />
      </div>
      <Text
        css={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
        size={FontSize.size16}
      >
        {t("internal-tools:Processing request...")}
      </Text>
    </div>
  );
};
