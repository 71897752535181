const STORE_MOUNT_POINT = "auth";

// Actions
const LOGIN_LOADING = `${STORE_MOUNT_POINT}/LOGIN_LOADING`;

// Action Creators
function setIsLoginLoading(isLoginLoading) {
  return (dispatch) =>
    dispatch({ type: LOGIN_LOADING, payload: { isLoginLoading } });
}

// Selectors
const getIsLoginLoading = (state) => state.auth.isLoginLoading;

// Reducer
const initialState = {
  isLoginLoading: false,
};

function AuthReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: action.payload.isLoginLoading,
      };
    default:
      return state;
  }
}

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { setIsLoginLoading },
  selectors: { getIsLoginLoading },
  reducer: AuthReducer,
};
