import { connect } from "react-redux";

import { LocationLadsListView } from "./LocationLadsListView";
import LadsState from "../lads/LadsState";

const mapStateToProps = (state) => {
  const { getLadsList, selectGroupedLads } = LadsState.selectors;
  return {
    ladsList: getLadsList(state),
    ladsGrouped: selectGroupedLads(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { updateLadLabel } = LadsState.actionCreators;
  return {
    updateLadLabel: (lad, newLabel) => dispatch(updateLadLabel(lad, newLabel)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationLadsListView);
