/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { Icon } from "components/atoms/Icon.atom";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Colors from "styles/colors";

const ValueCell = (props) => {
  const { value, type } = props.value;

  const { t } = useTranslation("vin-eta-validator");

  if (type === "location") {
    const name = value?.name;
    const code = value?.code;

    return (
      <div>
        <Text css={{ marginRight: 4 }}>{name}</Text>
        {code ? (
          <Text>{code}</Text>
        ) : (
          <Icon
            src={faMinus}
            color={Colors.tables.tableHeadingText}
            css={{ display: "block", margin: "auto" }}
          />
        )}
        <LocationAddressComplex
          address1={value?.address}
          city={value?.city}
          state={value?.state}
          postalCode={value?.postalCode}
          country={value?.country}
          defaultTextColor={Colors.text.DARK_BLUE}
        />
      </div>
    );
  }

  if (type === "organization") {
    const name = value?.carrierOrganizationName;
    const scac = value?.carrierOrganizationScac;

    if (!name) {
      return <Text>{t("vin-eta-validator:N/A")}</Text>;
    }

    return (
      <div>
        <Text>{name}</Text> {scac ? <Text>({scac})</Text> : null}
      </div>
    );
  }

  return <Text>{value}</Text>;
};

ValueCell.propTypes = {
  // Regarding the name of the prop:
  // react-table puts the data from "accessor" in the "value" field
  // and this table shows multiple types of data in the Value column
  // which is why the name "value" in the row was chosen.
  value: PropTypes.shape({ value: PropTypes.any, type: PropTypes.string }),
};

export const useExpandedDetailColumns = () => {
  const { t } = useTranslation("vin-eta-validator");
  return [
    { Header: t("vin-eta-validator:Name"), accessor: "name" },
    {
      Header: t("vin-eta-validator:Value"),
      accessor: (row) => ({ value: row.value, type: row.type }),
      Cell: ValueCell,
    },
  ];
};
