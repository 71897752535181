import _ from "lodash";
import { FILTERS } from "./UnresolvedLocation.searchOptions";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "unresolvedLocationsSearch";

//Helpers
const locationsUrl = (solutionId, queryString) => {
  if (queryString) {
    queryString = `?${queryString}`;
  }
  return apiUrl(`/location/unlinked${queryString}`);
};

//Actions
const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  const url = locationsUrl(solutionId, queryString);
  dispatch(
    duck.fetch(url, {
      headers: { Accept: "application/json;version=summary" },
    }),
  );
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [], // No categories.
  FILTERS,
  fetchSearch,
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    locationsUrl,
    null,
    { headers: { Accept: "text/csv" } },
    "unresolved-location-search-results",
  ),
};

export default SearchBarState;
