export function calculateRoute(
  mapPlatform,
  mapTypeOverride,
  hereMaps,
  hereMapsPlatform,
  googleMaps,
  numWaypoints,
  params,
  resultCallback,
  errorCallback,
) {
  const mapType =
    mapTypeOverride?.toUpperCase() ?? mapPlatform?.toUpperCase() ?? null;
  if (mapType === "HERE") {
    // HERE Maps routing v8 expects the waypoint data to have an "origin", "destination", and multiple "via" parameters.
    params["origin"] = params["waypoint0"];
    params["destination"] = params[`waypoint${numWaypoints - 1}`];

    // For every intermediate waypoint, we need to add a "via" param
    let intermediateVia = [];
    if (numWaypoints > 2) {
      for (let i = 1; i < numWaypoints - 1; i++) {
        intermediateVia.push(params[`waypoint${i}`]);
      }
    }

    // Use HERE Maps' built-in function for setting up the "via" params
    if (intermediateVia.length > 0) {
      params["via"] = new hereMaps.service.Url.MultiValueQueryParameter(
        intermediateVia,
      );
    }

    // Remove `waypoint${x}` values before sending to HERE Maps Routing API
    for (let i = 0; i < numWaypoints; i++) {
      delete params[`waypoint${i}`];
    }

    const router = hereMapsPlatform.getRoutingService(null, 8);
    router.calculateRoute(params, resultCallback, errorCallback);
  } else if (mapType === "GOOGLE") {
    let origin = params["waypoint0"];
    let destination = params[`waypoint${numWaypoints - 1}`];

    let waypoints = [];
    for (let i = 0; i < numWaypoints; i++) {
      if (i === 0 || i === numWaypoints - 1) {
        continue;
      }

      let waypoint = params[`waypoint${i}`];
      let waypointLatLng = waypoint.split(",");
      waypoints.push({
        location: new googleMaps.LatLng(waypointLatLng[0], waypointLatLng[1]),
      });
    }

    let directionsService = new googleMaps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: "DRIVING",
      },
      function (response, status) {
        if (status === "OK") {
          resultCallback(response);
        } else {
          errorCallback(response);
        }
      },
    );
  }
}
