/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

const pdfCss = {
  width: "100%",
  height: "100%",
  minHeight: "100em",
};

const ContentPdf = (props) => {
  const { filename, content } = props;

  if (!content) {
    return null;
  }

  return (
    <object
      data={`data:application/pdf;base64,${content}`}
      type="application/pdf"
      css={pdfCss}
    >
      {filename}
    </object>
  );
};

ContentPdf.propTypes = {
  filename: PropTypes.string,
  content: PropTypes.string,
};
export default ContentPdf;
