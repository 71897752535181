import apiUrl from "api-url";
import axios from "axios";
import moment from "moment";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "damageViewDashboardWatchWidget";

//urls
const APPLICATION_BASE_URL = apiUrl("/damageview/");

const watchUrl = (id) => APPLICATION_BASE_URL + `submission/${id}/watch/`;

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

const fetchWatchedSubmissionsSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
) => {
  const url = APPLICATION_BASE_URL + `submission?watch=true&${queryString}`;
  const config = axiosConfig();
  dispatch(duck.fetch(url, config));
};

const watchActions = (solutionId, id, watch = true) => {
  const url = watchUrl(id);
  return async (dispatch) => {
    try {
      watch ? await axios.post(url) : await axios.delete(url);
      dispatch(WatchedWidgetState.actionCreators.searchEntities(solutionId));
    } catch (err) {
      console.log("Error in DamageViewDashboardState: watchActions");
      throw new Error(err);
    }
  };
};

const WatchedWidgetState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  [],
  fetchWatchedSubmissionsSearch,
  [],
  {},
  10,
);

const DamageViewWatchedSubmissionsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    ...WatchedWidgetState.actionCreators,
    watchActions,
  },
  selectors: {
    ...WatchedWidgetState.selectors,
  },
  reducer: WatchedWidgetState.reducer,
};
export default DamageViewWatchedSubmissionsWidgetState;
