import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("container-tracking:Search Everything"),
    placeholder: (t) =>
      t(
        "container-tracking:Search Container ID, Route Name, Container Type, Location Name, Location Code",
      ),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "container_id:contains",
    label: (t) => t("container-tracking:Container ID"),
    placeholder: (t) => t("container-tracking:Search Container ID"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "bucket_name:contains",
    label: (t) => t("container-tracking:Route Name"),
    placeholder: (t) => t("container-tracking:Search Route Name"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "type:contains",
    label: (t) => t("container-tracking:Container Type"),
    placeholder: (t) => t("container-tracking:Search Container Type"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "location_name:contains",
    label: (t) => t("container-tracking:Location Name"),
    placeholder: (t) => t("container-tracking:Search Location Name"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "location_code:contains",
    label: (t) => t("container-tracking:Location Code"),
    placeholder: (t) => t("container-tracking:Search Location Code"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
];
