/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormControl, ButtonToolbar, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Colors from "styles/colors";
import { LadShape } from "modules/lads/utils/lads.utils";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Privileges } from "modules/auth/Authorization";
import { useLadsTranslation } from "modules/lads/utils/lads.utils";

export const LadLabelForm = (props) => {
  const { getTranslatedLadName } = useLadsTranslation();

  const { t, lad, height, updateLadLabel, originalLabel } = props;
  const { default_name: defaultLabel } = lad;

  const [currentLabel, setcurrentLabel] = useState(originalLabel);
  const isDirty = originalLabel !== currentLabel;

  const authorization = useSelector(getAuthorization);
  const readOnly = !authorization.hasPrivileges([
    Privileges.MANAGE_SHIPPER_LOCATIONS,
  ]);

  useEffect(() => {
    setcurrentLabel(originalLabel);
  }, [originalLabel]);

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setcurrentLabel(newLabel);
  };

  const handleSave = (event) => {
    updateLadLabel(lad, currentLabel);
  };

  const handleCancel = (event) => {
    setcurrentLabel(originalLabel);
  };

  return (
    <div>
      <FormControl
        disabled={readOnly}
        onChange={handleChange}
        value={isDirty ? currentLabel : getTranslatedLadName(currentLabel)}
        style={{
          height: `${height}px`,
          width: "100%",
          borderRadius: "2px",
          fontSize: "1.2em",
        }}
      />
      <div>
        <p css={{ paddingTop: "0.25rem" }}>
          <em>{t("lads:Default")}:</em>{" "}
          <strong>{t(`lads:${getTranslatedLadName(defaultLabel)}`)}</strong>
        </p>
        {isDirty && (
          <ButtonToolbar>
            <Button size="xs" onClick={handleCancel}>
              {t("lads:Cancel")}
            </Button>
            <Button size="xs" variant="success" onClick={handleSave}>
              {t("lads:Save")}
            </Button>
          </ButtonToolbar>
        )}
      </div>
    </div>
  );
};

LadLabelForm.propTypes = {
  t: PropTypes.func.isRequired,
  lad: PropTypes.shape(LadShape).isRequired,
  originalLabel: PropTypes.string.isRequired,
  updateLadLabel: PropTypes.func.isRequired,
  height: PropTypes.number,
};

export default withTranslation(["lads"])(LadLabelForm);
