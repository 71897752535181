/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { MediaQueries } from "components/responsive";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import SelectField from "components-old/forms/fields/SelectField";
import Colors from "styles/colors";

/* Used display grid & gap to align the Alert(s) and button at the end of Form(s) second column*/
const alignmentStyle = {
  display: "grid",
  gap: "1em",
  [MediaQueries.smallAndUp]: {
    gridTemplateColumns: "2fr",
  },
  [MediaQueries.largeAndUp]: {
    gridTemplateColumns: "2fr 1fr",
  },
};

const eventDropdownOptions = [
  {
    //Rail - origin - Departed
    label: "6001 (Rail - Release Load)",
    value: "6001",
    stopCode: "O",
  },
  {
    //Rail - origin - Arrival
    label: "6002 (Rail - Pull from Patron)",
    value: "6002",
    stopCode: "O",
  },
  {
    //Rail - Destination - Departed
    label: "6003 (Rail - Released Empty)",
    value: "6003",
    stopCode: "D",
  },
  {
    //Rail - Destination - Arrival
    label: "6005 (Rail - Arrival at Final Destination)",
    value: "6005",
    stopCode: "D",
  },
];

const initialState = {
  stopCode: null,
  importText: "",
  eventCode: null,
  eventTime: null,
  comments: "",
};

export const ShipmentReportArrivalDeparture = ({
  reportArrivalDeparture,
  clearReportArrivalDeparture,
  shipmentReportArrivalDepartureData,
}) => {
  const { t } = useTranslation("shipment-report-arrival-departure");

  useSetTitleOnMount(
    t(
      "shipment-report-arrival-departure:Shipment Report Arrival/Departure Tool",
    ),
  );

  useTrackWithMixpanelOnce(
    "Viewed Page: Shipment Report Arrival/Departure Tool",
  );

  const [data, setData] = useState(initialState);

  const [showAlert, setShowAlert] = useState(false);

  const isSubmitLoading = shipmentReportArrivalDepartureData?.isLoading;
  const responseStatusCode = shipmentReportArrivalDepartureData?.status;
  const errorResponse =
    shipmentReportArrivalDepartureData?.loadingError?.response?.data;

  let eventOptions = useMemo(() => {
    return eventDropdownOptions.filter((e) =>
      data.stopCode ? e.stopCode === data.stopCode : true,
    );
  }, [data.stopCode]);

  useEffect(() => {
    return () => clearReportArrivalDeparture();
  }, [clearReportArrivalDeparture]);

  useEffect(() => {
    if (responseStatusCode) {
      setShowAlert(true);
    }
  }, [responseStatusCode]);

  useEffect(() => {
    //SH-7752 - When the yellow/ green banner message is displayed,
    //all modal inputs are cleared and the submit button is disabled
    if (responseStatusCode === 201 || responseStatusCode === 408) {
      setData(initialState);
    }
  }, [responseStatusCode]);

  // Event handlers.
  const onStopChange = ({ value }) => {
    // Reset eventCode because it will not be applicable with the new stop.
    setData({ ...data, stopCode: value, eventCode: initialState.eventCode });
  };

  const onEventChange = ({ value }) => {
    setData({ ...data, eventCode: value });
  };

  const onEventTimeChange = (dateTime) => {
    setData({ ...data, eventTime: dateTime });
  };

  const onCommentsChange = (e) => {
    setData({ ...data, comments: e.target.value });
  };

  const textChangeHandler = (e) => {
    const batchText = e.target.value ?? "";

    // Ignore if all are empty lines
    // in order to disable the submit button
    if (_.isEmpty(batchText.trim())) {
      setData({ ...data, importText: "" });
    } else {
      setData({ ...data, importText: batchText });
    }
  };

  const canSubmit =
    !isSubmitLoading &&
    !_.isNil(data.stopCode) &&
    !_.isEmpty(data.importText) &&
    !_.isNil(data.eventCode) &&
    !_.isNil(data.eventTime);

  const onSubmitClick = () => {
    setShowAlert(false);

    //remove any additional empty line and then
    //convert to array to avoid unwanted comma at the end
    const shipmentIds = data.importText.trim().split(/\r\n|\n/);

    reportArrivalDeparture({ ...data, shipmentIds }, () =>
      setData(initialState),
    );
  };

  return (
    <Form
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        padding: "1.5em  1em",
      }}
    >
      <h4>
        {t("shipment-report-arrival-departure:Report Arrival/Departure Events")}
      </h4>
      <Form.Group css={{ marginBottom: "1rem", paddingBottom: "0.5em" }}>
        <Form.Text id="comments-help-text">
          <Text block color={Colors.text.GRAY} size={FontSize.size14}>
            {t(
              "shipment-report-arrival-departure:Use this form to report an arrival or departure event on multiple shipments at once.",
            )}
          </Text>
          <Text
            block
            color={Colors.text.GRAY}
            size={FontSize.size14}
            css={{ paddingTop: "0.25em" }}
          >
            {t(
              "shipment-report-arrival-departure:The same event, event time, and comment will be applied to all shipments.",
            )}
          </Text>
        </Form.Text>
      </Form.Group>

      <div
        css={{
          display: "grid",
          gap: "1em",
          [MediaQueries.smallAndUp]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [MediaQueries.largeAndUp]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        }}
      >
        <div>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="stop-for-shipment">
              <Text>{t("shipment-report-arrival-departure:Stop")}</Text>
            </Form.Label>
            <SelectField
              id="stop-for-shipment"
              placeholder={t("shipment-report-arrival-departure:Select...")}
              options={[
                {
                  label: t("shipment-report-arrival-departure:Origin"),
                  value: "O",
                },
                {
                  label: t("shipment-report-arrival-departure:Destination"),
                  value: "D",
                },
              ]}
              stateValue={data.stopCode}
              onChange={onStopChange}
              cursor="pointer"
            />
          </Form.Group>

          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label>
              {t(
                "shipment-report-arrival-departure:Enter Shipment IDs (Line Separated)",
              )}
            </Form.Label>
            <Form.Control
              as="textarea"
              id="delay-shipment-ids"
              aria-describedby="shipment-ids-to-report-delay"
              value={data.importText ?? ""}
              onChange={textChangeHandler}
              css={{ height: "15.6em" }}
            />
          </Form.Group>
        </div>

        <div>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="event-code">
              <Text>{t("shipment-report-arrival-departure:Event")}</Text>
            </Form.Label>
            <SelectField
              id="event-code"
              placeholder={t("shipment-report-arrival-departure:Select...")}
              options={eventOptions}
              stateValue={data.eventCode}
              onChange={onEventChange}
              isDisabled={!data.stopCode}
              cursor="pointer"
            />
          </Form.Group>

          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="event-date-time">
              <Text>{t("shipment-report-arrival-departure:Event Time")}</Text>
            </Form.Label>
            <DateTimePicker
              id="event-date-time"
              data-qa="event-date-time"
              placeholder={t(
                "shipment-report-arrival-departure:Enter Event Time",
              )}
              value={data.eventTime}
              onChange={onEventTimeChange}
              max={moment().toDate()}
              // SH-8735: Format requested by customer
              format="MMM DD, YYYY HH:mm"
              timeFormat="HH:mm"
            />
          </Form.Group>

          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="arrival-departure-comments">
              <Text block>
                {t("shipment-report-arrival-departure:Comments (Optional)")}
              </Text>
            </Form.Label>
            <Form.Control
              as="textarea"
              id="arrival-departure-comments"
              aria-describedby="comments-help-text"
              placeholder={t(
                "shipment-report-arrival-departure:Enter notes about the change here",
              )}
              value={data.comments}
              onChange={onCommentsChange}
              css={{ height: "10em" }}
            />
          </Form.Group>
        </div>
      </div>

      {showAlert ? (
        <div css={alignmentStyle}>
          <Alert
            show={true}
            variant={
              responseStatusCode === 201
                ? AlertVariant.Success
                : responseStatusCode === 408
                ? AlertVariant.Warning
                : AlertVariant.Danger
            }
            dismissible
            onDismiss={() => setShowAlert(false)}
          >
            {responseStatusCode === 201 ? (
              <Text block size={FontSize.size14}>
                {t(
                  "shipment-report-arrival-departure:Successfully processed event(s).",
                )}
              </Text>
            ) : responseStatusCode === 408 ? (
              <Text block size={FontSize.size14}>
                {t(
                  "shipment-report-arrival-departure:Batch Event update is processing. Please review the shipments after a few minutes.",
                )}
              </Text>
            ) : (
              <Text block size={FontSize.size14}>
                {/* Get error message(s) from `errorResponse`. If none, fallback to generic message */}
                {errorResponse?.error?.message ??
                  t("shipment-report-arrival-departure:Something went wrong.")}
              </Text>
            )}
          </Alert>
        </div>
      ) : null}

      <div css={alignmentStyle}>
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="success"
            onClick={onSubmitClick}
            disabled={!canSubmit}
            data-qa="button-submit-report-delay"
          >
            {isSubmitLoading ? (
              <Icon src={faSpinner} spin />
            ) : (
              t("shipment-report-arrival-departure:Submit")
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

ShipmentReportArrivalDeparture.propTypes = {
  reportArrivalDeparture: PropTypes.func,
  clearReportArrivalDeparture: PropTypes.func,
  shipmentReportArrivalDepartureData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
};
