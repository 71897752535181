// Actions
const SELECT_LOB = "end-to-end/SELECT_LOB";
const SELECT_LOCATION = "end-to-end/SELECT_LOCATION";
const SELECT_LAD = "end-to-end/SELECT_LAD";
const SET_SEARCH_STR = "end-to-end/SET_SEARCH_STR";
const TOGGLE_LADS_PANEL = "end-to-end/TOGGLE_LADS_PANEL";
const TOGGLE_LOCATIONS_PANEL = "end-to-end/TOGGLE_LOCATIONS_PANEL";

export function selectLob(lob) {
  return {
    type: SELECT_LOB,
    lob: lob,
  };
}

export function selectLocation(loc) {
  return {
    type: SELECT_LOCATION,
    location: loc,
  };
}

export function selectLad(lad) {
  return {
    type: SELECT_LAD,
    lad: lad,
  };
}

export function toggleLadsPanel(toggle) {
  return {
    type: TOGGLE_LADS_PANEL,
    toggle: toggle,
  };
}

export function toggleLocationsPanel(toggle) {
  return {
    type: TOGGLE_LOCATIONS_PANEL,
    toggle: toggle,
  };
}

export function setSearchStr(str) {
  return {
    type: SET_SEARCH_STR,
    searchStr: str,
  };
}

const initialState = {
  searchStr: "",
  selectedLad: null,
  selectedLob: 0,
  selectedLocation: null,
  showLadsPanel: true,
  showLocationsPanel: false,
};

function EndToEndReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_LADS_PANEL:
      return Object.assign({}, state, {
        showLadsPanel: action.toggle,
      });
    case TOGGLE_LOCATIONS_PANEL:
      return Object.assign({}, state, {
        showLocationsPanel: action.toggle,
      });
    case SELECT_LOB:
      return Object.assign({}, state, {
        selectedLob: action.lob,
      });
    case SELECT_LOCATION:
      return Object.assign({}, state, {
        selectedLocation: action.location,
      });
    case SELECT_LAD:
      return Object.assign({}, state, {
        selectedLad: action.lad,
      });
    case SET_SEARCH_STR:
      return { ...state, searchStr: action.searchStr };
    default:
      return state;
  }
}

export default EndToEndReducer;
