// Displays a table of Watched Containers, and allows user to watch or unwatch each Container

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";

import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import ContainerTrackingMyWatchedContainersState from "pages/containertracking/redux/ContainerTrackingMyWatchedContainersState";
import { ContainerTrackingDetailsWidgetState } from "pages/containertracking/redux/ContainerTrackingDetailsWidgetState";

const ContainerTrackingMyWatchedContainers = (props) => {
  const {
    solutionId,
    watchedContainerArray,
    watchedContainersLoading,
    fetchWatchedContainers,
    watchedContainersPageIndex,
    watchedContainersPageSize,
    watchedContainersPageCount,
    setWatchedContainersPageIndex,
  } = props;
  const { t } = useTranslation("container-tracking");
  const dispatch = useDispatch();

  const [unwatchedContainers, setUnwatchedContainers] = useState([]);

  useEffect(() => {
    fetchWatchedContainers();
  }, [watchedContainersPageIndex, fetchWatchedContainers]);

  let unwatchTimers = {};

  const toggleWatch = (container) => {
    let newUnwatchedContainers = [...unwatchedContainers];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[container]) {
      clearTimeout(unwatchTimers[container]);
    }

    if (newUnwatchedContainers.includes(container)) {
      // containers checkbox has already been unchecked - re-watch it
      newUnwatchedContainers = newUnwatchedContainers.filter(
        (v) => v !== container,
      );
    } else {
      // containers checkbox is checked - unwatch it
      newUnwatchedContainers.push(container);
    }

    setUnwatchedContainers(newUnwatchedContainers);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[container] = setTimeout(() => {
      fetchWatchedContainers();
    }, 2000);
  };

  // Render unchecked Container rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  const CellRenderer = (cellInfo) => {
    const dim = unwatchedContainers.includes(
      cellInfo.row.original.container_uuid,
    );
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = () => {
    return [
      {
        Header: t("container-tracking:Watch"),
        accessor: "watch",
        width: 50,
        disableSortBy: true,
        disableResizing: true,
        centerAligned: true,
        Cell: (cellInfo) => {
          const container = cellInfo.row.original.container_uuid;
          // API returns boolean in a string
          return (
            <WatchToggle
              key={container}
              checked={true}
              onChange={(newWatchValue) => {
                dispatch(
                  ContainerTrackingDetailsWidgetState.actionCreators.watchContainerActions(
                    container,
                    newWatchValue,
                    solutionId,
                  ),
                );
                toggleWatch(container);
              }}
              iconSize={FontSize.size24}
              color={Colors.nav.NAVY}
              checkedColor={Colors.highlight.YELLOW}
            />
          );
        },
      },
      {
        Header: t("container-tracking:Container ID"),
        accessor: "container_identifier",
        Cell: (cellInfo) => {
          const dim = unwatchedContainers.includes(
            cellInfo.row.original.container_uuid,
          );
          return (
            <div
              style={{
                opacity: cellOpacity(dim),
              }}
            >
              {cellInfo.row.original.commentsCount &&
              cellInfo.row.original.commentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>
                      {t("container-tracking:This container contains comments")}
                    </Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    color={Colors.comments.unreadCommentIcon}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
              ) : null}
              <Text bold>{cellInfo.row.original.container_identifier}</Text>
            </div>
          );
        },
        minWidth: 200,
      },
      {
        Header: t("container-tracking:Container Type"),
        accessor: "container_type",
        Cell: CellRenderer,
      },
      {
        Header: t("container-tracking:Status"),
        accessor: "Status",
        Cell: CellRenderer,
        minWidth: 300,
      },
      {
        Header: t("container-tracking:Last Update"),
        accessor: "last_update",
        Cell: (cellInfo) => {
          const last_update = cellInfo.row.original?.last_update;
          return <DateTimeCell dateTime={`${last_update}`} localize />;
        },
      },
    ];
  };

  const rowClickHandler = (row, cell) => {
    const { setSelectedContainerId } = props;
    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }
    // Navigate to Container Details when clicking row
    setSelectedContainerId(row.original.container_uuid);
  };

  return (
    <PanelGroup collapsible style={{ marginTop: "1em" }}>
      <PanelGroup.Header
        title={t("container-tracking:My Watched Containers")}
      />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={watchedContainerArray}
          columns={tableColumns()}
          theme={Themes.LIGHT}
          isLoading={watchedContainersLoading}
          rowClickHandler={rowClickHandler}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          pageIndex={watchedContainersPageIndex}
          pageSize={watchedContainersPageSize}
          pageCount={watchedContainersPageCount}
          onPageChange={(newPage) => {
            setWatchedContainersPageIndex(newPage);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

ContainerTrackingMyWatchedContainers.propTypes = {
  watchedContainerArray: PropTypes.array,
  watchedContainersLoading: PropTypes.bool,
  watchedContainersPageIndex: PropTypes.number,
  watchedContainersPageSize: PropTypes.number,
  watchedContainersPageCount: PropTypes.number,
  solutionId: PropTypes.string,
  fetchWatchedContainers: PropTypes.func.isRequired,
  setWatchedContainersPageIndex: PropTypes.func.isRequired,
  setSelectedContainerId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    getWatchedContainers,
    getWatchedContainersLoading,
    getWatchedContainersPageIndex,
    getWatchedContainersPageSize,
    getWatchedContainersPageCount,
  } = ContainerTrackingMyWatchedContainersState.selectors;

  return {
    watchedContainerArray: getWatchedContainers(state),
    watchedContainersLoading: getWatchedContainersLoading(state),
    watchedContainersPageIndex: getWatchedContainersPageIndex(state),
    watchedContainersPageSize: getWatchedContainersPageSize(state),
    watchedContainersPageCount: getWatchedContainersPageCount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchWatchedContainers, setWatchedContainersPageIndex } =
    ContainerTrackingMyWatchedContainersState.actionCreators;

  return {
    fetchWatchedContainers: () => dispatch(fetchWatchedContainers()),
    setWatchedContainersPageIndex: (pageIndex) =>
      dispatch(setWatchedContainersPageIndex(pageIndex)),
    setSelectedContainerId: (uuid) =>
      dispatch({
        type: "CONTAINER_TRACKING_DETAILS",
        payload: { id: uuid },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerTrackingMyWatchedContainers);
