import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("healthcare:Search Everything"),
    placeholder: (t) =>
      t("healthcare:Search Asset Number or Asset Description"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "id",
    label: (t) => t("healthcare:Asset Number"),
    placeholder: (t) => t("healthcare:Search Asset Number"),
    queryBuilder: getBasicQueryString,
    property: "assetsId", // Same as "assets" property, but can't use same property or the searchBar won't search this
    itemLabelProperty: "id",
    itemValueProperty: "id",
  },
  {
    queryKey: "description",
    label: (t) => t("healthcare:Asset Description"),
    placeholder: (t) => t("healthcare:Search Asset Description"),
    queryBuilder: getBasicQueryString,
    property: "assets",
    itemLabelProperty: "description",
    itemValueProperty: "id",
  },
];
