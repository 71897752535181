/** @jsxImportSource @emotion/react */
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useIsMediumAndDown } from "components/responsive";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import PageHeader from "modules/documentation/documentation-styled-components/PageHeader";
import { Text } from "components/atoms/Text.atom";
import { FlexColDiv, FlexRowDiv } from "styles/container-elements";

const csvTemplate = {
  fileName: `template-set-partview-eta-${Date.now()}.csv`,
  headers: ["Package Container", "ETA", "Location Code"],
  data: [
    ["Package Container 1", "6/1/23", "Location Code 1"],
    ["Package Container 2", "6/5/23", "Location Code 2"],
  ],
};

const initialPackages = {
  importCsv: null,
  importFile: null,
  autoCloseWindowTimer: null,
};

const Instructions = () => {
  const { t } = useTranslation("internal-tools");
  return (
    <div>
      <Text block={true} style={{ marginBottom: "1em" }}>
        {t(
          "internal-tools:The following rules apply to the set PartView ETA tool",
        )}
        {":"}
      </Text>
      <ol>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:You must be logged into the Organization for which you are freezing packages.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:No more than 5000 PartView Packages may be updated at a time.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t("internal-tools:Only valid PartView Packages will be updated.")}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:To freeze a PartView Package ETA, the New PartView Package ETA must be in the future.",
            )}
          </Text>
        </li>
      </ol>
    </div>
  );
};

export const FreezePartViewETA = ({
  requestData,
  requestDataClear,
  freezePartViewEtas,
}) => {
  const [packages, setPackages] = useState(initialPackages);
  const isMobile = useIsMediumAndDown();
  const { t } = useTranslation("internal-tools");
  const title = t("internal-tools:Set PartView ETA");
  useSetTitleOnMount(t("internal-tools:Internal Tools"));

  useEffect(() => {
    requestDataClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileSelectHandler = (newFile) => {
    const reader = new FileReader();
    reader.onload = onFileLoad;
    reader.readAsBinaryString(newFile);
    setPackages((prevState) => ({
      ...prevState,
      importFile: newFile,
    }));
  };

  const onFileLoad = (event) => {
    const data = event.target.result;
    const workbook = XLSX.read(data, { type: "binary", cellDates: true }); // Add 'cellDates' option
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Convert the worksheet to an array of objects
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      raw: false, // Ensure that values are parsed as strings
      dateNF: "m/d/yy", // Set the desired date format
    });

    const csv = convertJsonToCsv(jsonData, workbook.SheetNames[0]);
    if (csv) {
      setPackages((prevState) => ({
        ...prevState,
        importCsv: csv,
      }));
    } else {
      console.log("No valid records to process");
    }
  };

  const fileDropHandler = (newItems) => {
    if (newItems && newItems.length === 1) {
      const file = newItems[0].getAsFile();
      const reader = new FileReader();
      reader.onload = onFileLoad;
      reader.readAsBinaryString(file);
      setPackages((prevState) => ({
        ...prevState,
        importFile: file,
      }));
    }
  };

  const convertJsonToCsv = (jsonData) => {
    if (jsonData.length === 0) {
      return ""; // Return empty string if no records
    }

    const header = Object.keys(jsonData[0]).join(",");
    const rows = jsonData.map((row) => {
      const formattedRow = Object.values(row).map((value) => {
        if (typeof value === "string" && value.includes("/")) {
          // Check if the value is a valid date string
          const date = moment(value, ["M/D/YY", moment.ISO_8601], true);
          if (date.isValid()) {
            return date.format("M/D/YY"); // Format the date as desired
          }
        }
        return value; // Treat the ETA value as is, without conversion
      });
      return formattedRow.join(",");
    });

    rows.unshift(header); // Add the header row to the beginning
    return rows.join("\n");
  };

  const getAlertMessage = (requestData) => {
    const status = requestData?.status;
    if (status === 200) {
      return t(
        "internal-tools:Upload was successful, and ETAs have been updated.",
      );
    } else if (status === 400) {
      const errorMessage =
        requestData?.loadingError?.response?.data?.error?.message;
      if (errorMessage) {
        return `${t("internal-tools:")}${errorMessage}`;
      }
      return t("internal-tools:Unable to upload file. Try again later.");
    } else {
      const data = requestData?.data?.invalid_rows;
      const message = `${t(
        "internal-tools:Upload was partially successful, and ETAs have been updated with the below exceptions",
      )}`;
      const error = [message];
      for (const errorType in data) {
        if (data.hasOwnProperty(errorType)) {
          const errorList = data[errorType];
          const rowNumbers = errorList
            .map((errorEntry) => errorEntry.row_num)
            .join(",");
          error.push(
            `${t(
              "internal-tools:The following rows failed due to",
            )} ${errorType} - ${rowNumbers}`,
          );
        }
      }
      return error.join("\n");
    }
  };

  const getAlertProps = (actionStatus) => {
    const alertProps = {
      show: false,
      variant: null,
      message: "",
    };
    if (requestData.status) {
      alertProps.show = true;
      alertProps.variant =
        requestData.status !== 200 ? AlertVariant.Danger : AlertVariant.Success;
      alertProps.message = getAlertMessage(requestData);
    }
    return alertProps;
  };

  const alertProps = getAlertProps(requestData);

  return (
    <div css={{ padding: "1em", flexDirection: "column" }}>
      <PageHeader title={title} />

      <FlexColDiv
        css={{
          width: isMobile ? "auto" : "50%",
          padding: "1em",
        }}
      >
        <Instructions />
        <FlexRowDiv css={{ marginBottom: "1em" }}>
          <span css={{ marginRight: "0.5rem" }}>
            {t("internal-tools:Select a CSV or XLSX file to import")}.
          </span>
          <DownloadCsvLink
            data={csvTemplate.data}
            headers={csvTemplate.headers}
            label={t("components:Download template (.csv)")}
            filename={csvTemplate.fileName}
            style={{ marginLeft: "1em" }}
          />
        </FlexRowDiv>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <InputGroup>
            <label
              className="input-group-btn"
              css={{ paddingRight: "0.375rem" }}
            >
              <span className="btn btn-light">
                {t("components:Browse")}&hellip;
                <input
                  id="files"
                  type="file"
                  accept=".csv, .xlsx"
                  title={null}
                  onChange={(e) => {
                    fileSelectHandler(e.target.files[0]);
                  }}
                  hidden
                />
              </span>
            </label>
            <FormControl
              type="type"
              value={packages.importFile ? packages.importFile.name : ""}
              placeholder={t("components:Select file")}
              htmlFor="files"
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fileDropHandler(e.dataTransfer.items);
              }}
              readOnly
            />
          </InputGroup>
        </FormGroup>
        <Alert
          variant={alertProps.variant}
          show={alertProps.show}
          css={{ marginBottom: "1em" }}
        >
          {alertProps?.message?.split("\n").map((line, index) => {
            return (
              <p key={index} css={{ margin: 0 }}>
                {line}
              </p>
            );
          })}
        </Alert>
        <Button
          variant="primary"
          disabled={!packages.importFile || requestData.isLoading}
          onClick={() => {
            freezePartViewEtas(packages.importCsv);
          }}
          style={{ width: "fit-content" }}
        >
          {t("components:Upload")}
        </Button>
      </FlexColDiv>
    </div>
  );
};

FreezePartViewETA.propTypes = {
  requestData: PropTypes.shape({
    data: PropTypes.object,
    url: PropTypes.string,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
  requestDataClear: PropTypes.func.isRequired,
  freezePartViewEtas: PropTypes.func.isRequired,
};

export default FreezePartViewETA;
