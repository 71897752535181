import { AnyAction } from "redux";

const MOUNT_POINT = "title";

// Actions
const SET_TITLE = "title/SET_TITLE";
const SET_SUB_TITLE = "title/SET_SUB_TITLE";
const SET_DESCRIPTION = "title/SET_DESCRIPTION";
const CLEAR_TITLES = "title/CLEAR_TITLES";

const setPageTitle = (title: string, subTitle?: string) => {
  let pageTitle = title;

  if (subTitle) {
    pageTitle += ": " + subTitle;
  }

  pageTitle += " - FreightVerify";

  document.title = pageTitle;
};

const setTitle = (title: string) => {
  return {
    type: SET_TITLE,
    title,
  };
};

const setSubTitle = (subTitle?: string) => {
  return {
    type: SET_SUB_TITLE,
    subTitle,
  };
};

const setDescription = (description?: string) => {
  return {
    type: SET_DESCRIPTION,
    description,
  };
};

const clearTitles = () => {
  return {
    type: CLEAR_TITLES,
  };
};

//TODO: Get type for `state` from `store.js/ts`
const getTitle = (state: any) => {
  return state[MOUNT_POINT].title;
};

//TODO: Get type for `state` from `store.js/ts`
const getSubTitle = (state: any) => {
  return state[MOUNT_POINT].subTitle;
};

//TODO: Get type for `state` from `store.js/ts`
const getDescription = (state: any) => {
  return state[MOUNT_POINT].description;
};

type TitleState = {
  title: string;
  subTitle?: string;
  description?: string;
};

const initialState: TitleState = {
  title: "FreightVerify",
};

// reducer to handle the map of all notifications
function titleReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_TITLE:
      setPageTitle(action.title, state.subTitle);

      return {
        ...state,
        title: action.title,
      };
    case SET_SUB_TITLE:
      setPageTitle(state.title, action.subTitle);

      return {
        ...state,
        subTitle: action.subTitle,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case CLEAR_TITLES:
      document.title = "FreightVerify";

      return {
        ...state,
        title: null,
        subTitle: null,
        description: null,
      };
    default:
      return state;
  }
}

export default {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    setTitle,
    setSubTitle,
    setDescription,
    clearTitles,
  },
  selectors: {
    getTitle,
    getSubTitle,
    getDescription,
  },
  reducer: titleReducer,
};
