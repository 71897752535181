/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { sortByTimestamp } from "components/organisms/base-table/Sort/CommonSorts";
import { DateTime } from "components/atoms/DateTime.atom";

const CellRenderer = ({ value }) => {
  return (
    <div
      css={{
        textOverflow: "auto",
        overflow: "none",
        backgroundColor: "transparent !important",
      }}
    >
      {value}
    </div>
  );
};

CellRenderer.propTypes = {
  value: PropTypes.any,
};

const DateTimeCellRenderer = ({ value }) => {
  return <DateTime dateTime={value} plain localize />;
};

DateTimeCellRenderer.propTypes = {
  value: PropTypes.string,
};

export const getColumns = (t) => {
  return [
    {
      Header: t("connected-car:VIN"),
      accessor: "vin",
      width: 200,
      Cell: CellRenderer,
    },
    {
      Header: t("connected-car:Product Type"),
      accessor: "description",
      width: 200,
      Cell: CellRenderer,
    },
    {
      Header: t("connected-car:Location"),
      accessor: "location",
      width: 130,
      Cell: CellRenderer,
    },
    {
      Header: t("connected-car:Timestamp"),
      accessor: "lastPositionTime",
      Cell: DateTimeCellRenderer,
      sortType: sortByTimestamp,
    },
  ];
};
