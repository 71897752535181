/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Text } from "components/atoms/Text.atom";
import { MdClose } from "react-icons/md";
import Colors from "styles/colors";

export const DamageViewImageUploader = ({
  name,
  onChange,
  files,
  setFiles,
  commentData,
  setCommentData,
  style,
}) => {
  const { t } = useTranslation("damage-view");

  const [fileNames, setFileNames] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    ref.current.textContent = commentData;
  }, [ref, commentData]);

  useEffect(() => {
    if (onChange) {
      onChange(name, {
        files: files,
        comments: commentData,
      });
    }
    const names = files.map((file) => {
      return file.name;
    });
    setFileNames(names);
  }, [files, commentData]);

  const changeHandler = (event) => {
    const imageFiles = [...files, ...event.target.files];
    setFiles(imageFiles);
    event.target.value = null;
  };

  return (
    <InputGroup style={style}>
      <label className="input-group-btn">
        <span
          className="btn btn-light"
          css={{
            // cursor: "pointer",
            zIndex: "0 !important",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            ":hover": {
              background: Colors.tabs.BACKGROUND_HOVER,
            },
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {t("damage-view:Browse")}
          <FormControl
            name={name}
            id={name}
            as="input"
            type="file"
            accept="image/png, image/jpeg"
            multiple={true}
            onChange={changeHandler}
            css={{
              display: "none",
            }}
          />
        </span>
      </label>
      <div
        className="form-control"
        htmlFor={name}
        style={{
          zIndex: 0,
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          width: "20%",
          background: Colors.background.DISABLED_INPUT,
          userSelect: "none",
        }}
      >
        {fileNames?.length > 0 ? (
          fileNames.map((name, index) => {
            return (
              <span
                css={{
                  margin: "3px",
                }}
                key={index}
              >
                <span
                  css={{
                    padding: "5px",
                    background: Colors.text.LIGHT_GRAY,
                  }}
                >
                  {name}
                  <MdClose
                    css={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      color: Colors.text.RED,
                    }}
                    onClick={() => {
                      const updatedFiles = files.filter((file) => {
                        return file.name !== name;
                      });
                      setFiles(updatedFiles);
                    }}
                  />
                </span>
              </span>
            );
          })
        ) : (
          <span>
            <Text color={Colors.tabs.BORDER}>
              {t("damage-view:Select file")}
            </Text>
          </span>
        )}
      </div>
      <FormControl
        ref={ref}
        as="span"
        contentEditable
        css={{
          "&:empty": {
            "&:before": {
              content: `'${t("damage-view:Comments")}'`,
              color: Colors.text.GRAY,
            },
          },
        }}
        style={{
          marginLeft: "10px",
          borderRadius: "5px",
        }}
        placeholder={t("damage-view:Comments")}
        onKeyUp={(event) => {
          setCommentData(event.target.innerText);
        }}
      />
    </InputGroup>
  );
};

DamageViewImageUploader.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  commentData: PropTypes.string,
  setCommentData: PropTypes.func,
};
