import { connect } from "react-redux";

import { FilterSection } from "components/search-bar/FilterSection";
import DomainDataState from "modules/domain-data/DomainDataState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import ApiLogsSearchBarState from "./ApiLogsSearchBarState";
import { FILTERS } from "./ApiLogsFilterDefs";

const mapStateToProps = (state) => ({
  shipperFilterOptions: DomainDataState.selectors
    .getShippers(state)
    .map((s) => {
      return { value: s.shipper_organization_id, label: s.shipper_name };
    }),
  carrierFilterOptions: DomainDataState.selectors
    .getCarriers(state)
    .map((s) => {
      return { value: s.id, label: s.name };
    }),
  searchFilters: ApiLogsSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    ApiLogsSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  auth: getAuthorization(state),
});

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchFilter,
    clearSearchFilter,
    clearSavedSearch,
    searchEntities,
  } = ApiLogsSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: (key) => dispatch(clearSearchFilter(key)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId, true)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilterSection);
