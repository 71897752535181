import * as XLSX from "xlsx";

const REGEX_REMOVE_QUOTES_AROUND_CELL = /"([^"]+(?="))"/g;
const isEmptyString = (value) => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

/*
 * Parses a CSV file and returns an array of lines.
 *
 * Note: This function assumes that quotes are used in CSVs only to wrap an entire cell
 * or to escape special characters. It may not handle all possible CSV formats, especially
 * when quotes are used differently within cells.
 *
 * @param {csv-file} uploadFile The CSV file to parse.
 * @returns formatted CSV file
 */
export const getLinesFromCsv = (uploadFile) => {
  return new Promise((resolve, reject) => {
    // Read the contents of the file
    const reader = new FileReader();
    reader.readAsText(uploadFile);

    // When the file loads, create an array of lines
    reader.onload = (file) => {
      const csv = file.target.result.trim();
      const row = csv.split(/\r\n|\n/);

      if (row.length > 1) {
        const parsedLines = row.filter((line) => {
          // Remove commas from each line
          const trimmedLine = line.toString().replace(/,/g, "");
          if (!isEmptyString(trimmedLine)) {
            return line
              .toString()
              .replace(REGEX_REMOVE_QUOTES_AROUND_CELL, "$1");
          }
          return false;
        });

        resolve(parsedLines);
      }
      resolve([]);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};

/*
 * Check that the column headers in a CSV file match the expected names.
 *
 * @param {File} file A File blob of the CSV file to validate.
 * @param {Array<string>} expected The required column headers.
 * @param {Object} [options] Configuration options for validation.
 * @param {boolean} [options.allowExtra=true] If true, extra column headers are allowed at the end.
 * @returns {boolean} True if the headers match, false otherwise.
 */
export async function validateHeadersFromCsv(file, expected, options = {}) {
  let allowExtra = options.allowExtra ?? true;

  let arrayBuffer = await file.arrayBuffer();
  let workbook = XLSX.read(arrayBuffer, {
    // Reference: https://docs.sheetjs.com/docs/api/parse-options/
    type: "array",
    raw: true, // do not parse cell values from strings
    sheetRows: 1, // only read the first row
  });
  let sheet = workbook.Sheets[workbook.SheetNames[0]];

  let range = XLSX.utils.decode_range(sheet["!ref"]);
  let firstColumnIndex = range.s.c;
  let lastColumnIndex = range.e.c;

  let headers = [];
  for (let i = firstColumnIndex; i <= lastColumnIndex; i++) {
    let address = XLSX.utils.encode_cell({ c: i, r: 0 });
    let cell = sheet[address];
    let value = cell?.v?.trim();
    headers.push(value);
  }

  if (!allowExtra && headers.length !== expected.length) {
    return false;
  }

  return expected.reduce((allMatch, expectedHeader, index) => {
    let actualHeader = headers[index];
    return allMatch && expectedHeader === actualHeader;
  }, true);
}
