import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
import { shipmentEtaValidatorVinOptionsState } from "../redux/ShipmentEtaValidatorFilterLoaders";
import { SelectListSize } from "components/molecules/SearchableSelectList.molecule";
import {
  getBasicQueryString,
  getMultiSelectQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: (t) => t("fv-vin-search:VIN"),
    placeholder: (t) =>
      t("fv-vin-search:Search Creator Shipment ID or FV Shipment ID"),
    queryBuilder: getBasicQueryString,
    property: "vins",
  },
  {
    queryKey: "shipmentId",
    label: (t) => t("fv-vin-search:Shipment ID"),
    placeholder: (t) => t("fv-vin-search:Search Shipment ID"),
    queryBuilder: getBasicQueryString,
    property: "shipments",
  },
];

export const FILTERS = [
  {
    queryKey: "entityId",
    Component: AsyncSelectFilterButton,
    isMulti: false,
    label: (t) => t("shipment-eta-validator:VIN"),
    optionsState: shipmentEtaValidatorVinOptionsState,
    queryBuilder: getMultiSelectQueryString,
    selectListSize: SelectListSize.LARGE,
    hideSelectAll: true,
    hideSelectEmpty: true,
    hideFuzzySearch: true,
    prerequisiteForSearchAndFilters: true,
  },
];
