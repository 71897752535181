/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import ReactMarkdown from "react-markdown";

import Colors from "styles/colors";

const schemaTreeCss = {
  backgroundColor: "white",
  marginBottom: "1em",
  ".schema-group .schema-group": {
    marginTop: "1em",
  },
  ".schema-row": {
    backgroundColor: "white",
    padding: "1em 40px",
    position: "relative",
  },
  ".schema-row.alt": {
    backgroundColor: Colors.background.LIGHT_GRAY,
  },
  ".schema-row::before": {
    content: "''",
    position: "absolute",
    top: 24,
    bottom: -25,
    left: 20,
    borderLeft: `1px solid ${Colors.text.GRAY}`,
    zIndex: 1,
  },
  ".schema-row:last-of-type::before": {
    display: "none",
  },
  ".schema-values": {
    display: "flex",
    flexDirection: "row",
  },
  ".schema-key": {
    fontWeight: 600,
    width: 240,
    position: "relative",
  },
  ".schema-required": {
    color: Colors.highlight.RED,
  },
  ".schema-conditional": {
    color: Colors.highlight.ORANGE,
  },
  ".schema-key::before": {
    content: "''",
    position: "absolute",
    top: 10,
    left: -20,
    width: 10,
    borderTop: `1px solid ${Colors.text.GRAY}`,
  },
  ".schema-key::after": {
    content: "''",
    position: "absolute",
    top: 8,
    left: -12,
    width: 5,
    height: 5,
    borderRadius: 5,
    backgroundColor: Colors.text.GRAY,
  },
  ".schema-details": {
    width: "75%",
  },
  ".schema-type": {
    color: Colors.text.GRAY,
  },
  ".schema-details table": {
    width: "100%",
    margin: "1em 0",
  },
  ".schema-details th, .schema-details td": {
    border: `1px solid ${Colors.text.GRAY}`,
    padding: "0.5em 1em",
  },
};

const schemaGroup = (
  t,
  properties,
  requiredProperties,
  conditionalProperties,
  altStyle,
) => {
  // Alternate the BG color for each nested level in the tree
  const useAltStyle = !altStyle;

  return (
    <div className="schema-group">
      {schemaRows(
        t,
        properties,
        requiredProperties,
        conditionalProperties,
        useAltStyle,
      )}
    </div>
  );
};

const schemaRows = (
  t,
  properties,
  requiredProperties,
  conditionalProperties,
  altStyle,
) => {
  return _.keys(properties).map((key, i) => {
    const rowClass = altStyle ? "schema-row alt" : "schema-row";
    const required = requiredProperties && requiredProperties.includes(key);
    const conditional =
      conditionalProperties && conditionalProperties.includes(key);
    const description = properties[key].description || "";
    const childProperties =
      properties[key].properties ||
      (properties[key].items ? properties[key].items.properties : null);
    const childRequiredProperties =
      properties[key].required ||
      (properties[key].items ? properties[key].items.required : null);
    const childConditionalProperties =
      properties[key].conditional ||
      (properties[key].items ? properties[key].items.conditional : null);

    return (
      <div key={i} className={rowClass}>
        <div className="schema-values">
          <div className="schema-key">
            <div>{key}</div>
            {required ? (
              <div className="schema-required">
                {t("documentation:Required")}
              </div>
            ) : null}
            {conditional ? (
              <div className="schema-conditional">
                {t("documentation:Conditional")}
              </div>
            ) : null}
          </div>
          <div className="schema-details">
            <div className="schema-type">
              {properties[key].type || "object"}
            </div>
            <div className="schema-description">
              <ReactMarkdown
                children={t(`documentation-remote:${description}`)}
              />
            </div>
          </div>
        </div>

        {/* Render any nested child properties */}
        {childProperties
          ? schemaGroup(
              t,
              childProperties,
              childRequiredProperties,
              childConditionalProperties,
              altStyle,
            )
          : null}
      </div>
    );
  });
};

const SchemaTree = (props) => {
  const { t, schema } = props;

  return (
    <div css={schemaTreeCss}>
      {schemaGroup(
        t,
        schema.properties,
        schema.required,
        schema.conditional,
        true,
      )}
    </div>
  );
};

SchemaTree.propTypes = {
  schema: PropTypes.object,
};
export default withTranslation(["documentation", "documentation-remote"])(
  SchemaTree,
);
