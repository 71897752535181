import { DependencyType } from "./const";

// ---- Field helper functions ----
const defaultDependencies = { partnerType: "", milestoneEvent: "" };

export const isRequired = (input, dependencies = defaultDependencies) => {
  return isRequiredHelper(input.required, dependencies);
};

const isRequiredHelper = (valueDef, dependencies) => {
  // This was called with the final "required" value
  if (typeof valueDef === "boolean") {
    return valueDef;
  }
  // This was called with an object where we have to
  // look the next value with a "dependency"
  else if (typeof valueDef === "object") {
    if (valueDef.dependency) {
      // Peek the next value by calling this function recusively
      let nextValueDef;
      if (valueDef.dependency === DependencyType.PARTNER_TYPE) {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.partnerType],
          dependencies,
        );
      } else if (valueDef.dependency === DependencyType.MILESTONE_EVENT) {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.milestoneEvent],
          dependencies,
        );
      }

      // If the next call is not undefined or null
      //
      // Note: Using this check so that we don't accidentally
      // interperet the value false as undefined or null.
      // eslint-disable-next-line
      if (nextValueDef != null) {
        return nextValueDef;
      }
    }

    // There wasn't a dependency so we will return
    // the default value
    return valueDef.default;
  }
};
