/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import MapSearchResults from "../../components/map-search-results/MapSearchResults";
import Results from "../../components/map-search-results/Results";
import SearchBar from "./components/SearchBar";
import { RECORD_LIMIT } from "./ConnectedCarSearchBarState";
import { enrichLocationsWithGeofence } from "./utils/map";
import ClumpingMap from "../map/components/ClumpingMap";
import PaginatedLocationInfoPopup from "../map/widgets/PaginatedLocationInfoPopup";
import { getColumns } from "./ConnectedCarColumns";

import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

const ConnectedCarView = (props) => {
  const {
    solutionId,
    setPagination,
    connectedCarEntities,
    totalEntities,
    isLoading,
    isLoadingError,
    isExporting,
    pushVinDetailsScreen,
    clickedSearchOrEnter,
    resetClickedSearchOrEnter,
    areThereFiltersSelected,
    exportIdentifier,
    exportName,
    exportFailed,
    resetSearchBar,
    clearSearchFilters,
    clearEntities,
    setViewCentered,
    resetExport,
  } = props;
  const { t } = useTranslation("connected-car");

  useSetTitleOnMount(t("connected-car:Connected Car Dashboard"));
  useSetDescriptionOnMount(
    t("connected-car:All current Connected Car updates for your organization"),
  );

  useTrackWithMixpanelOnce("Viewed Page: Connected Car / Dashboard");

  const underRecordLimit = totalEntities <= RECORD_LIMIT && !isLoadingError;
  const mapLocations = enrichLocationsWithGeofence(
    connectedCarEntities,
    underRecordLimit,
  );
  const [mapVisibleLocations, setMapVisibleLocations] = useState([]);
  const [mapHoveredLocations, setMapHoveredLocations] = useState([]);
  // Record limit warning modal
  const [showRecordLimitModal, setShowRecordLimitModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  let showWarning = false;
  if (totalEntities >= RECORD_LIMIT) {
    showWarning = true;
  }

  useEffect(
    () => {
      resetSearchBar();
      clearSearchFilters();
      clearEntities();
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  // When user searches again, selected location should become empty
  useEffect(() => {
    setSelectedLocation(null);
  }, [connectedCarEntities]);

  useEffect(() => {
    setShowRecordLimitModal(
      isLoadingError && clickedSearchOrEnter && !isLoading,
    );
  }, [isLoadingError, clickedSearchOrEnter, isLoading]);

  // Set the pageSize to 10,000 to ensure that all VINs are displayed on the map
  // (ReactTable will handle paging client-side)
  useEffect(() => {
    setPagination(solutionId);
  }, [setPagination, solutionId]);

  const selectedIds = mapHoveredLocations.map((item) => item.id);
  if (selectedLocation) {
    selectedIds.push(selectedLocation.id);
  }

  const toggleSelectedLocation = (rowData) => {
    const locationId = rowData.original.vin;
    if (selectedLocation?.id === locationId) {
      setSelectedLocation(null);
    } else {
      const selectedLocation = _.find(mapLocations, { id: locationId });
      setSelectedLocation(selectedLocation);
    }
  };

  return (
    <React.Fragment>
      <MapSearchResults
        isExporting={isExporting}
        isShowingOverlay={
          (totalEntities === 0 && !areThereFiltersSelected) ||
          (isLoadingError && !isLoading)
        }
        isShowingRecordLimitModal={showRecordLimitModal}
        onHideRecordLimitModal={() => {
          setShowRecordLimitModal(false);
          resetClickedSearchOrEnter();
        }}
        search={
          <div className="mx-3 my-2">
            <SearchBar isExporting={isExporting} />
            <ExportAlert className="m-1" exportFailed={exportFailed} />
          </div>
        }
        map={
          <ClumpingMap
            t={t}
            uiButtons={[
              {
                label: "Recenter",
                clickCallback: () => {
                  setViewCentered(true);
                },
              },
            ]}
            selectedLocation={selectedLocation}
            mapLocations={mapLocations}
            popupComponent={PaginatedLocationInfoPopup}
            popupClickHandler={(location) => {
              pushVinDetailsScreen(location.vin);
            }}
            onMapViewChange={(visibleLocations) => {
              setMapVisibleLocations(visibleLocations);
            }}
            onMarkerMouseEnter={(hoveredLocations) => {
              setMapHoveredLocations(hoveredLocations);
            }}
            onMarkerMouseOut={() => {
              setMapHoveredLocations([]);
            }}
            onPopupChangePage={(location) => {
              setSelectedLocation(location);
            }}
            recenterTooltipText={t(
              "connected-car:Recenter the map to view all ADC locations.",
            )}
            setViewCentered={setViewCentered}
          />
        }
        results={
          <Results
            entities={!isLoadingError ? mapVisibleLocations : []}
            total={!isLoadingError ? mapLocations.length : 0}
            totalText={t("connected-car:Total VINs")}
            visible={!isLoadingError ? mapVisibleLocations.length : 0}
            visibleText={t("connected-car:Visible VINs")}
            fixPaginationToBottom={true}
            columns={getColumns(t)}
            isLoading={isLoading}
            onRowClick={toggleSelectedLocation}
            selectedEntitiesIds={selectedIds}
            showWarning={showWarning}
            warningMessage={t(
              `connected-car:More than ${RECORD_LIMIT} results available. Please use filters to refine your search.`,
            )}
            recenterTooltipText={t(
              "connected-car:Recenter the map to view all VINs.",
            )}
            setMapViewCentered={setViewCentered}
            defaultSortColumn={"lastPositionTime"}
            defaultReverseSort={true}
          />
        }
      />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
    </React.Fragment>
  );
};

ConnectedCarView.propTypes = {
  areThereFiltersSelected: PropTypes.bool,
  solutionId: PropTypes.string.isRequired,
  setPagination: PropTypes.func.isRequired,
  connectedCarEntities: PropTypes.array.isRequired,
  totalEntities: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExporting: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool.isRequired,
  pushVinDetailsScreen: PropTypes.func.isRequired,
  clickedSearchOrEnter: PropTypes.bool.isRequired,
  resetClickedSearchOrEnter: PropTypes.func.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  resetSearchBar: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  clearEntities: PropTypes.func.isRequired,
  setViewCentered: PropTypes.func.isRequired,
  resetExport: PropTypes.func,
};

export default ConnectedCarView;
