/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { LinkPanels } from "components/templates/LinkPanels.template";
import { LinkPanel } from "components/molecules/LinkPanel.molecule";
import { Privileges } from "modules/auth/Authorization";

export const AdministrativeToolsView = ({ auth, redirectToRoute }) => {
  const { t } = useTranslation("admin-tools");

  useSetTitleOnMount(t("admin-tools:Administration"));
  useTrackWithMixpanelOnce("Viewing Page: Docs / API Group");

  const showShipmentDwellNotificationManagement = auth.hasPrivileges([
    Privileges.MANAGE_SHIPMENT_DWELL_NOTIFICATIONS,
  ]);

  return (
    <LinkPanels title={t("admin-tools:Administrative Tools")}>
      {showShipmentDwellNotificationManagement ? (
        <LinkPanel
          title={t("admin-tools:Shipment Dwell Notification")}
          description={t(
            "admin-tools:Tool to manage shipment dwell notifications including managing locations, users, phone numbers, and alert level",
          )}
          onClick={() => redirectToRoute("SHIPMENT_DWELL_NOTIFICATION")}
        />
      ) : null}

      <LinkPanel
        title={t("notification-management:Shipment ETA Override")}
        description={t(
          "notification-management:Tool that allows users to override FreightVerify ETA calculations for shipments",
        )}
        onClick={() => redirectToRoute("SHIPMENT_ETA_OVERRIDE")}
      />

      <LinkPanel
        title={t("notification-management:Shipment Report Arrival/Departure")}
        description={t(
          "notification-management:Tool that allows users to update the arrival and departure on multiple shipments at once",
        )}
        onClick={() => redirectToRoute("SHIPMENT_REPORT_ARRIVAL_DEPARTURE")}
      />
    </LinkPanels>
  );
};

AdministrativeToolsView.propTypes = {
  auth: PropTypes.object,
  redirectToRoute: PropTypes.func.isRequired,
};
