import React from "react";
import { useResizeDetector as useResizeDetector_dependency } from "react-resize-detector";
import { OnRefChangeType } from "react-resize-detector/build/types/types";

export type PropsWithSize<P = unknown> = P & {
  size: {
    height?: number;
    width?: number;
  };
};

/**
 * Detect changes to an element's height and width.
 *
 * @example
 * function MyComponent() {
 *   const { ref, size } = useResizeDetector();
 *   return <div ref={ref} />
 * }
 */
export function useResizeDetector<T extends HTMLElement>() {
  const { height, width, ref } = useResizeDetector_dependency<T>({
    refreshMode: "throttle",
    refreshRate: 200,
  });

  const size = React.useMemo(() => {
    return { height, width };
  }, [height, width]);

  return { ref: ref, size: size };
}

/**
 * Detect changes to a rendered component's height and width.
 *
 * NOTE: Only for class components. Consider refactoring to a function component
 * and use `useResizeDetector` instead.
 *
 * @example
 * type MyComponentProps = PropsWithSize<{
 *   innerRef: React.MutableRefObject<HTMLDivElement | null>;
 * }>
 * class MyComponent extends React.Component<MyComponentProps> {
 *   render() {
 *     return (
 *       <div
 *         ref={(element) => {
 *           this.props.innerRef.current = element;
 *         }}
 *       />
 *     );
 *   }
 * }
 * const ComponentWithSize = withResizeDetector<HTMLDivElement>()(MyComponent)
 */
export function withResizeDetector<T extends HTMLElement>() {
  return function Extend(
    WrappedComponent: React.ComponentType<
      PropsWithSize<{
        innerRef: React.Ref<T>;
      }>
    >,
  ) {
    function ComponentWithResizeDetector(
      props: Omit<
        React.ComponentProps<typeof WrappedComponent>,
        "size" | "innerRef"
      >,
    ) {
      const { ref, size } = useResizeDetector<T>();
      return <WrappedComponent {...props} innerRef={ref} size={size} />;
    }

    ComponentWithResizeDetector.displayName = `withResizeDetector(${WrappedComponent.displayName})`;

    return ComponentWithResizeDetector;
  };
}
