/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import * as XLSX from "xlsx";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "components/atoms/Icon.atom";
import { FontSize } from "components/atoms/enums";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import PageHeader from "modules/documentation/documentation-styled-components/PageHeader";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

const currentDate = moment(new Date()).format("MM/DD/YYYY");

const csvTemplate = {
  fileName: `template-partview-delivery-status-update-${Date.now()}.csv`,
  headers: ["Package Number", "Delivery Location Code", "Date"],
  data: [
    ["Package Number 1", "Location Code", `${currentDate}`],
    ["Package Number 2", "Location Code", `${currentDate}`],
    ["Package Number 3", "Location Code", `${currentDate}`],
  ],
};

export const PartViewDeliveryStatusUpdate = ({
  fetchPVDeliveryStatusUpdate,
  requestData,
  requestDataClear,
}) => {
  const { t } = useTranslation("internal-tools");
  const title = t("internal-tools:Delivery Status update");
  useSetTitleOnMount(t("internal-tools:Internal Tools"));

  const [packagesDelivered, setPackagesDelivered] = useState({ importCsv: {} });
  const [importFile, setImportFile] = useState("");

  useEffect(() => {
    return () => requestDataClear();
  }, [requestDataClear]);

  const csvTooltip = (
    <>
      <Text>{t("internal-tools:The file must contain")}:</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "wrap",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:Package Number")}</li>
        <li>{t("internal-tools:Delivery Location Code")}</li>
        <li>
          {t("internal-tools:Date (if empty, current date will be considered)")}
        </li>
      </ul>
    </>
  );

  const convertJsonToCsv = (jsonData) => {
    if (jsonData.length === 0) {
      return ""; // Return empty string if no records
    }
    const header = Object.keys(jsonData[0]).join(",");
    const rows = jsonData.map((row) => {
      const formattedRow = Object.values(row).map((value) => {
        return value; // Treat the ETA value as is, without conversion
      });
      return formattedRow.join(",");
    });

    rows.unshift(header); // Add the header row to the beginning
    return rows.join("\n");
  };

  const onFileLoad = (event) => {
    const data = event.target.result;
    const workbook = XLSX.read(data, { type: "binary", raw: true }); // Add 'cellDates' option
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    // Convert the worksheet to an array of objects
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const csv = convertJsonToCsv(jsonData, workbook.SheetNames[0]);
    if (csv) {
      setPackagesDelivered((prevState) => ({
        ...prevState,
        importCsv: csv,
      }));
    } else {
      console.log("No valid records to process");
    }
  };

  let isDataUploading = requestData?.isLoading;

  const alertMessage = useMemo(() => {
    let status = requestData?.status ?? null;

    const errorMessage =
      requestData?.loadingError?.response?.data?.error?.message ?? null;

    if (!status && !errorMessage) {
      return {
        variant: "",
        message: "",
        show: false,
      };
    }

    const isSuccess = status === 200;

    return {
      variant: isSuccess ? AlertVariant.Success : AlertVariant.Danger,
      message: isSuccess
        ? t(
            "internal-tools:File has been successfully uploaded! Notification will be sent once file is processed.",
          )
        : errorMessage,
      show: true,
    };
  }, [t, requestData]);

  return (
    <>
      <div css={{ padding: "1em" }}>
        <PageHeader title={title} />

        <div
          css={{
            paddingLeft: "1em",
          }}
        >
          <Text size={FontSize.size18} bold>
            {t("internal-tools:Bulk Action")}
          </Text>
          <Text block={true} css={{ marginBottom: "1em" }}>
            {t(
              "internal-tools:Upload packages that will have their statuses updated to Delivered using a .csv file",
            )}
            {":"}
          </Text>
          <div css={{ marginBottom: "1em", display: "flex" }}>
            <div css={{ marginRight: "1em" }}>
              <Text>{t("internal-tools:Select a .csv file to import")}.</Text>{" "}
              <Tooltip placement="top" tooltipChildren={csvTooltip}>
                <Icon src={faQuestionCircle}></Icon>
              </Tooltip>
            </div>

            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
            />
          </div>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-light">
                  {t("internal-tools:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onFileLoad;
                      let fileName = e?.target?.files[0]?.name ?? "";
                      setImportFile(fileName);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importFile}
                placeholder={t("internal-tools:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>

          <Button
            variant="primary"
            disabled={
              packagesDelivered.importCsv.length && !isDataUploading
                ? false
                : true
            }
            onClick={() => {
              fetchPVDeliveryStatusUpdate(
                packagesDelivered.importCsv,
                importFile,
              );
            }}
            css={{ width: "fit-content" }}
          >
            {t("internal-tools:Upload")}
          </Button>

          <Alert
            variant={alertMessage.variant}
            show={alertMessage.show && !isDataUploading}
            css={{ marginTop: "1em" }}
          >
            {alertMessage.message}
          </Alert>
        </div>
      </div>
    </>
  );
};

PartViewDeliveryStatusUpdate.propTypes = {
  requestData: PropTypes.shape({
    data: PropTypes.array,
    url: PropTypes.string,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
  requestDataClear: PropTypes.func.isRequired,
  fetchPVDeliveryStatusUpdate: PropTypes.func.isRequired,
};
