import { connect } from "react-redux";

import { SearchBar } from "components/search-bar/SearchBar";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { LocationMatchingViewSearchBarState } from "modules/location-resolution-edit/LocationMatchingViewSearchBarState";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
  } = LocationMatchingViewSearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    solutionId: getSolutionId(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
    clearSearchFilter,
  } = LocationMatchingViewSearchBarState.actionCreators;

  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) => {
      dispatch(setSearchText(text, ignoreValueChange));
    },
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (
      solutionId,
      resetPagination,
      preventRedirect,
      clickedSearchOrEnter,
    ) => {
      // When users search manually, it needs to search in the whole database.
      if (clickedSearchOrEnter) {
        dispatch(clearSearchFilter("near_location"));
      }
      dispatch(searchEntities(solutionId, resetPagination, preventRedirect));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
