/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import LadsList from "./components/LadsList";
import LocationsList from "./components/LocationsList";
import SimpleMap from "../map/components/SimpleMap";
import colors from "../../styles/colors";
import { FlexDiv } from "../../styles/container-elements";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";

const Section = styled.section({
  display: "flex",
  height: "calc(100vh - 3.3em)",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "white",
});

function getMapFlex(showLadsPanel, showLocationsPanel) {
  if (!showLadsPanel) {
    if (!showLocationsPanel) {
      return 0.95;
    } else {
      return 0.6;
    }
  }

  return 0.7;
}

function locationSort(a, b) {
  let x = a.name.toLowerCase();
  let y = b.name.toLowerCase();
  if (x < y) {
    return -1;
  }

  if (x > y) {
    return 1;
  }

  return 0;
}

const EndToEndView = (props) => {
  const {
    ladsGrouped,
    locations,
    searchStr,
    selectedLad,
    selectedLob,
    selectedLocation,
    setSearchStr,
    showLadsPanel,
    showLocationsPanel,
  } = props;
  const { t } = useTranslation("end-to-end");

  useSetTitleOnMount(t("end-to-end:Locations"));
  useSetDescriptionOnMount(
    t("end-to-end:All the locations of an organization visualized"),
  );

  useTrackWithMixpanelOnce("Viewed Page: BI / Locations / Dashboard");

  const eventHandler = (val, type) => {
    const {
      searchLocation,
      selectLob,
      selectLocation,
      selectLad,
      selectedLad,
      toggleLadsPanel,
      toggleLocationsPanel,
      fetchLadLocations,
      showLocationsPanel,
    } = props;

    switch (type) {
      case "SELECT_LOB":
        selectLob(parseInt(val, 10));
        break;
      case "SELECT_LOCATION":
        selectLocation(val);
        break;
      case "SELECT_LAD":
        selectLad(val);
        // H2-447 fetch locations on lad select
        fetchLadLocations(val.id);
        toggleLadsPanel(false);
        toggleLocationsPanel(true);
        break;
      case "TOGGLE_LADS":
        if (showLocationsPanel) {
          // If Locations list is visible when toggling LADs, just hide the Locations
          toggleLocationsPanel(false);
        } else if (selectedLad && val) {
          // If a LAD is selected when showing the LADs, just re-show the Locations
          toggleLocationsPanel(true);
        } else {
          toggleLadsPanel(val);
        }
        break;
      case "HIDE_LOCATIONS":
        toggleLocationsPanel(false);
        toggleLadsPanel(true);
        selectLad(null);
        break;
      case "LOCATION_LINK":
        const searchQuery = `${val.name} (${val.code})`;
        searchLocation(searchQuery, searchQuery);
        break;
      default:
        break;
    }
  };

  // H2-447 We only have the locations with that LAD code, so only additional filtering needed
  //  is by search text
  let filteredLocations = [];
  if (selectedLad !== null) {
    if (searchStr !== null && searchStr !== "") {
      filteredLocations = locations
        .filter((l) => {
          return (
            (l.name &&
              l.name.toLowerCase().includes(searchStr.toLowerCase())) ||
            (l.city &&
              l.city.toLowerCase().includes(searchStr.toLowerCase())) ||
            (l.state &&
              l.state.toLowerCase().includes(searchStr.toLowerCase())) ||
            (l.code && l.code.toLowerCase().includes(searchStr.toLowerCase()))
          );
        })
        .sort((a, b) => locationSort(a, b));
    } else {
      filteredLocations = locations.sort((a, b) => locationSort(a, b));
    }
  }

  return (
    <FlexDiv>
      <Section
        css={{
          flex: getMapFlex(showLadsPanel, showLocationsPanel),
        }}
      >
        <SimpleMap
          mapLocations={filteredLocations}
          selectedLad={selectedLad}
          selectedLocation={selectedLocation}
          markerIsClickable={true}
          eventHandler={eventHandler}
        />
      </Section>
      {showLocationsPanel ? (
        <Section
          css={{
            backgroundColor: colors.background.DARK_GRAY,
            color: "white",
            flex: 0.35,
          }}
        >
          <LocationsList
            t={t}
            eventHandler={eventHandler}
            locations={filteredLocations}
            selectedLad={selectedLad}
            searchStr={searchStr}
            setSearchStr={setSearchStr}
          />
        </Section>
      ) : null}
      <Section
        css={{
          backgroundColor: colors.background.DARK_GRAY,
          flex: showLadsPanel ? 0.3 : 0.05,
        }}
      >
        <LadsList
          t={t}
          eventHandler={eventHandler}
          ladsGrouped={ladsGrouped}
          selectedLad={selectedLad}
          selectedLob={selectedLob}
          showLadsPanel={showLadsPanel}
          showLocationsPanel={showLocationsPanel}
        />
      </Section>
    </FlexDiv>
  );
};

EndToEndView.propTypes = {
  ladsGrouped: PropTypes.object,
  locations: PropTypes.array,
  searchStr: PropTypes.string,
  selectedLad: PropTypes.object,
  selectedLob: PropTypes.number,
  selectedLocation: PropTypes.object,
  setSearchStr: PropTypes.func,
  showLadsPanel: PropTypes.bool,
  showLocationsPanel: PropTypes.bool,
  searchLocation: PropTypes.func,
  selectLob: PropTypes.func,
  selectLocation: PropTypes.func,
  selectLad: PropTypes.func,
  toggleLadsPanel: PropTypes.func,
  toggleLocationsPanel: PropTypes.func,
  fetchLadLocations: PropTypes.func,
};

export default EndToEndView;
