import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import { SEARCH_CATEGORIES } from "../components/search/DriveAway.searchOptions";
import { FILTERS } from "../components/search/DriveAway.searchOptions";
import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers.js";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { searchVins, vinsReducer } from "./VinSearchStateExtensions";
import { isShipper } from "shared/utils/organizations.utils";

const STORE_MOUNT_POINT = "driveAwaySearch";

const entitiesUrl = (solutionId, queryString) => {
  if (solutionId) {
    return apiUrl(
      `/entity/solution/${solutionId}/entity?${queryString}&ddaFilter=1`,
    );
  }

  return apiUrl(`/entity/internal?${queryString}&ddaFilter=1`);
};

const batchSearchUrl = (solutionId, queryString, batchType) => {
  if (solutionId) {
    return apiUrl(
      `/entity/solution/${solutionId}/batch-search?${queryString}&batchType=${batchType}&ddaFilter=1`,
    );
  }
  return apiUrl(
    `/entity/internal/batch-search?${queryString}&batchType=${batchType}&ddaFilter=1`,
  );
};

const fetchCommentsUrl = (entityId, solutionId) => {
  return apiUrl(`/dda/dpuId/${entityId}/comment`);
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      accept: "application/json;version=dda",
    },
  };
};

// Actions

const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;
const RESET_COMMENTS = `${STORE_MOUNT_POINT}/RESET_COMMENTS`;
const RECEIVE_COMMENTS = `${STORE_MOUNT_POINT}/RECEIVE_COMMENTS`;
const ERROR_FETCHING_DDA_COMMENTS = `${STORE_MOUNT_POINT}/ERROR_FETCHING_DDA_COMMENTS`;

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const isShipperRole = isShipper(state.organizations.activeOrganization);
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;
  if (batchFilter) {
    // Batch search POST
    batchSearch(solutionId, queryString, dispatch, batchFilter, duck);
  } else {
    // Normal search GET
    const url = entitiesUrl(
      isShipperRole ? solutionId : undefined,
      `includeConfigurations=true&${queryString}`,
    );
    const config = axiosConfig();

    // Fetch the search
    dispatch(duck.fetch(url, config));

    // Fetch the count
    dispatch(
      fetchTotalCount(
        isShipperRole ? solutionId : undefined,
        queryString,
        config,
      ),
    );

    // Redirect to DriveAway search page
    dispatch({ type: "DRIVE_AWAY_SEARCH" });
  }
};

const batchSearch = (solutionId, queryString, dispatch, batchFilter, duck) => {
  const batchType = batchFilter.batch_type;
  const url = batchSearchUrl(solutionId, queryString, batchType);
  const data = {
    batch_list: batchFilter.batch_list,
  };
  const config = axiosConfig();

  dispatch({
    type: duck.actions.REQUEST,
  });

  axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: response.data ? response.data : [],
      });
      dispatch({ type: "DRIVE_AWAY_SEARCH" });
    })
    .catch((err) => {
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });

  dispatch(fetchTotalCount(solutionId, queryString, config, batchType, data));
};

const fetchTotalCount = (solutionId, queryString, config, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();
    // Fetch the count (and remove pagination params)
    const countParams = qs.parse(queryString);
    countParams.pageNumber = undefined;
    countParams.pageSize = undefined;
    const countQueryString = qs.stringify(countParams);

    const url = batchType
      ? batchSearchUrl(solutionId, countQueryString, batchType)
      : entitiesUrl(solutionId, countQueryString);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      ...config,
      method: batchType ? "POST" : "GET",
      url,
      data: batchType ? data : undefined,
      headers: {
        ...config.headers,
        accept: "application/json;version=count",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

const fetchComments = (entityId) => {
  return (dispatch) => {
    const url = fetchCommentsUrl(entityId);

    dispatch({ type: RESET_COMMENTS });

    axios
      .get(url)
      .then((res) => {
        if (!res?.data?.data) {
          throw new Error("No data");
        }
        const lastestComment = res.data.data?.[0]?.text;
        dispatch({ type: RECEIVE_COMMENTS, payload: lastestComment });
      })
      .catch((error) => {
        dispatch({ type: ERROR_FETCHING_DDA_COMMENTS });
        console.error(error);
      });
  };
};

const resetComments = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COMMENTS });
  };
};

// Selectors

const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch ?? 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch ?? 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading ?? false;

const getDdaComments = (state) => state[STORE_MOUNT_POINT].ddaComments ?? "";

const getErrorFetchingDdaComments = (state) =>
  state[STORE_MOUNT_POINT].errorFetchingDdaComments ?? false;

// Reducer

const driveAwaySearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
    ddaComments: "",
    errorFetchingDdaComments: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: state.totalCountForSearch ? false : true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };
    case RECEIVE_COMMENTS:
      return {
        ...state,
        ddaComments: action.payload,
      };
    case RESET_COMMENTS:
      return {
        ...state,
        errorFetchingDdaComments: false,
        ddaComments: "",
      };
    case ERROR_FETCHING_DDA_COMMENTS:
      return {
        ...state,
        errorFetchingDdaComments: true,
      };
    default:
      return state;
  }
};

// Additional selectors for async search results
const getVins = (state) => state[STORE_MOUNT_POINT].vins || [];
const getVinsLoading = (state) => state[STORE_MOUNT_POINT].vinsLoading || false;

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [driveAwaySearchBarReducer],
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  searchVins,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    entitiesUrl,
    batchSearchUrl,
    { headers: { Accept: "text/csv;version=dda" } },
    "driveaway-search-results",
  ),
  fetchComments,
  resetComments,
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
  getVins,
  getVinsLoading,
  getDdaComments,
  getErrorFetchingDdaComments,
};

SearchBarState.reducer = chainReducers([SearchBarState.reducer, vinsReducer]);

export default SearchBarState;
