/** @jsxImportSource @emotion/react */
// External
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";

// Components
import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { SurgicalToteTrackingSavedSearch } from "./SurgicalToteTrackingSavedSearch";
import SurgicalToteTrackingSavedSearchModalContainer from "../../components/search/SurgicalToteTrackingSavedSearchModalContainer";

// State
import SurgicalToteTrackingSavedSearchState from "../../redux/SurgicalToteTrackingSavedSearchState";
import SurgicalToteTrackingSearchBarState from "../../redux/SurgicalToteTrackingSearchBarState";

export const SurgicalToteTrackingSavedSearchPanel = () => {
  const savedSearches = useSelector(
    SurgicalToteTrackingSavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    SurgicalToteTrackingSavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(
    () => {
      dispatch(
        SurgicalToteTrackingSavedSearchState.actionCreators.fetchSavedSearches(),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={SurgicalToteTrackingSavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                SurgicalToteTrackingSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
          };
        }}
      />
      <SurgicalToteTrackingSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            SurgicalToteTrackingSearchBarState.actionCreators.resetSearchAndFilters(
              true,
            ),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(
            SurgicalToteTrackingSavedSearchState.actionCreators.deleteSearch(
              id,
            ),
          );
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};
