import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";

export const authPartView = (auth) => {
  // both true for all non dealer users
  const isPartUser =
    auth.hasFeatures([Features.PART_VIEW]) &&
    !auth.hasOrganizationTypes([OrganizationType.DEALER]);
  const isPartSeller = auth.hasFeatures([Features.PART_VIEW]);

  return { isPartUser, isPartSeller };
};
