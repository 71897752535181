/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import SelectFilter from "components/organisms/base-table/Filter/SelectFilter";
import i18n from "i18n";

const parentFilterOptions = [
  { value: "true", label: i18n.t("locations:Parent") },
  { value: "false", label: i18n.t("locations:Not Parent") },
];

const ParentFilter = ({ onChange }) => {
  return (
    <SelectFilter
      placeholder={null}
      options={parentFilterOptions}
      onChange={(option) => {
        onChange(option?.value);
      }}
    />
  );
};

ParentFilter.propTypes = {
  onChange: PropTypes.func,
};

export default ParentFilter;
