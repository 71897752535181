import axios from "axios";
import apiUrl from "api-url";

// URLS
const UNSUBSCRIBE_URL = apiUrl("/subscription/v2");

// Redux mount point
const STORE_MOUNT_POINT = "fvUnsubscribe";

// Actions
const UNSUBSCRIBE_RESET = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_RESET`;
const UNSUBSCRIBE_STARTED = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_STARTED`;
const UNSUBSCRIBE_SUCCESS = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_SUCCESS`;
const UNSUBSCRIBE_FAILED = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_FAILED`;

function resetUnsubscribe() {
  return (dispatch) => {
    dispatch({ type: UNSUBSCRIBE_RESET });
  };
}

function unsubscribe(ruleUuid, unsubscribeUuid) {
  return (dispatch) => {
    dispatch({ type: UNSUBSCRIBE_STARTED });

    const url = `${UNSUBSCRIBE_URL}/${ruleUuid}/unsubscribe/${unsubscribeUuid}`;

    return axios
      .post(url)
      .then((response) => {
        dispatch({ type: UNSUBSCRIBE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: UNSUBSCRIBE_FAILED, errorMessage: error?.message });
      });
  };
}

// Selectors
const getIsUnsubscribeComplete = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribeComplete || false;
const getIsUnsubscribing = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribing || false;
const getIsUnsubscribeError = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribeError || false;
const getUnsubscribeErrorMessage = (state) =>
  state[STORE_MOUNT_POINT].unsubscribeErrorMessage || null;

// Initial state
const initialState = {
  isUnsubscribeComplete: false,
  isUnsubscribing: false,
  isUnsubscribeError: false,
  unsubscribeErrorMessage: null,
};

const UnsubscribeV2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_RESET:
      return {
        ...state,
        ...initialState,
      };
    case UNSUBSCRIBE_STARTED:
      return {
        ...state,
        isUnsubscribing: true,
        isUnsubscribeComplete: false,
        isUnsubscribeError: false,
        unsubscribeErrorMessage: null,
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isUnsubscribing: false,
        isUnsubscribeComplete: true,
        isUnsubscribeError: false,
        unsubscribeErrorMessage: null,
      };
    case UNSUBSCRIBE_FAILED:
      return {
        ...state,
        isUnsubscribing: false,
        isUnsubscribeComplete: false,
        isUnsubscribeError: true,
        unsubscribeErrorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

// interface
const UnsubscribeV2State = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    resetUnsubscribe,
    unsubscribe,
  },
  selectors: {
    getIsUnsubscribeComplete,
    getIsUnsubscribing,
    getIsUnsubscribeError,
    getUnsubscribeErrorMessage,
  },
  reducer: UnsubscribeV2Reducer,
};
export default UnsubscribeV2State;
