import { getFeatureData } from "modules/organizations/OrganizationsState";

export const getSurgicalToteTrackingSolutionId = (state) => {
  const featureData = getFeatureData(state);
  return (
    featureData?.find(
      (feature) => feature.feature_name === "Container Tracking",
    )?.solution ?? null
  );
};
