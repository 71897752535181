/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

// Utils
import {
  getExceptionData,
  getIconData,
} from "pages/finishedvehicle/utils/exceptions.utils";
import { getExceptionChartData } from "components/utils/exceptions.utils";

// State
import { getSolutionId } from "modules/organizations/OrganizationsState";
import FinVehicleSearchBarState from "modules/fv-search/FinVehicleSearchBarState";
import FinVehicleSavedSearchState from "modules/fv-search/FinVehicleSavedSearchState";
import FinVehicleSavedSearchCardsState from "modules/fv-dashboard/FinVehicleSavedSearchCardsState";
import FinVehicleDomainData from "modules/domain-data/FinVehicleDomainData";

const { searchEntities, setSearchFilter, clearSearchFilter } =
  FinVehicleSearchBarState.actionCreators;
const { loadSavedSearch } = FinVehicleSavedSearchState.actionCreators;

export const FinishedVehicleSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
}) => {
  const { t } = useTranslation("fv-dashboard");
  const dispatch = useDispatch();

  const solutionId = useSelector(getSolutionId);
  const savedSearchData = useSelector(
    FinVehicleSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const exceptionTypes = useSelector(
    FinVehicleDomainData.selectors.getExceptionTypes,
  );

  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    dispatch(
      FinVehicleSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        solutionId,
        savedSearch,
      ),
    );
  }, [dispatch, solutionId, savedSearch]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities(solutionId));
  };

  const loadExceptionSavedSearch = (e) => {
    dispatch(loadSavedSearch(savedSearch));
    // Prepopulate the new exception array with the saved search values.
    let searchableValue = [...(savedSearch.search?.exception ?? [])];
    if (e?.reasonCode) {
      searchableValue.push(
        _.find(exceptionTypes, { reasonCode: e.reasonCode }).id,
      );
      dispatch(setSearchFilter("exception", _.uniq(searchableValue)));
      // This query param lets us change the logic for the exception field to be an AND instead of an OR.
      // e.g. Search for VINs that have Fuel Level and Tire Pressure; entitySearchAnd=exception&exception=id1,id2
      // (usually this would be Fuel Level or Tire Pressure)
      dispatch(setSearchFilter("entitySearchAnd", "exception"));
      if (!savedSearch.search.lifeCycleState) {
        dispatch(setSearchFilter("lifeCycleState", ["Active"]));
      }
    }
    dispatch(searchEntities(solutionId));
    // After the search is done, we can remove the param so that search returns to normal behavior.
    dispatch(clearSearchFilter("entitySearchAnd"));
  };

  let loadActiveSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    // The graph displays the Active VIN count
    dispatch(setSearchFilter("lifeCycleState", ["Active"]));
    dispatch(searchEntities(solutionId));
  };

  let loadDeliveredSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("lifeCycleState", ["Delivered"]));
    dispatch(searchEntities(solutionId));
  };

  if (savedSearch.search.lifeCycleState?.length === 1) {
    // Prevent Active search when the user set status to Delivered
    if (savedSearch.search.lifeCycleState[0] === "Delivered") {
      loadActiveSavedSearch = null;
    }

    // Prevent Delivered search when the user set status to Active
    if (savedSearch.search.lifeCycleState[0] === "Active") {
      loadDeliveredSavedSearch = null;
    }
  }

  const exceptionData = useMemo(
    () =>
      getExceptionData(
        data?.exceptions,
        exceptionTypes,
        data?.activeCount,
        false,
      ),
    [data, exceptionTypes],
  );

  const exceptionDataTransformed = exceptionData.map((exception, i) => {
    return {
      ...exception,
      name: t(`exceptions:${exception.name}`),
      icon: {
        ...getIconData(exception.name),
      },
    };
  });

  const deliveredData = [
    {
      name: t("fv-dashboard:Delivered"),
      count: data?.deliveredCount ?? 0,
      icon: {
        ...getIconData("Delivered"),
      },
    },
  ];

  const totalExceptionsCount = useMemo(
    () => exceptionDataTransformed.reduce((acc, curr) => acc + curr.count, 0),
    [exceptionDataTransformed],
  );

  const chartData = getExceptionChartData(
    exceptionDataTransformed,
    data?.activeCount,
    totalExceptionsCount,
  );

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        <ExceptionCountGroup
          title={t("labels:Exceptions")}
          exceptions={exceptionDataTransformed}
          vertical
          clickHandler={loadExceptionSavedSearch}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row",
              justifyContent: "center",
            },
            [MediaQueries.extraLarge]: {
              flexDirection: "column",
              justifyContent: "start",
            },
          }}
        >
          <DonutChart
            data={chartData}
            handler={loadActiveSavedSearch}
            totalLabel={t("fv-dashboard:Active")}
            total={data?.activeCount}
          />
          <ExceptionCountGroup
            title={t("labels:Other")}
            exceptions={deliveredData}
            clickHandler={loadDeliveredSavedSearch}
          />
        </div>
      </div>
    </SavedSearchPanel>
  );
};

FinishedVehicleSavedSearch.propTypes = {
  // These come from the getCardProps in FinishedVehicleSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};
