/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import {
  faDrawCircle,
  faDrawSquare,
  faDrawPolygon,
} from "@fortawesome/pro-light-svg-icons";

import Colors from "styles/colors";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import GeofenceType from "../geofence-edit/geofence-types";
import { Text } from "components/atoms/Text.atom";

export const UpdateGeofenceTypeModal = ({
  show,
  hide,
  handleConfirm,
  fenceType,
}) => {
  const { t } = useTranslation("location-edit");
  const [newType, setNewType] = useState(GeofenceType.RADIAL);

  useEffect(() => setNewType(fenceType), [fenceType]);

  return (
    <Modal show={show} size="lg" onHide={() => hide()}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.DARK_GRAY,
          color: Colors.background.WHITE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={
          <div data-qa="title-modal">
            <Text bold>{t("location-edit:Update Geofence Type")}</Text>
          </div>
        }
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text
            bold
            style={{
              marginBottom: 8,
            }}
          >
            {t("location-edit:Geofence Type")}:
          </Text>
          <ButtonToolbar>
            <ToggleButtonGroup
              size="lg"
              type="radio"
              name="options"
              value={newType}
              onChange={setNewType}
            >
              <ToggleButton
                id="radial"
                variant="light"
                value={GeofenceType.RADIAL}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  src={faDrawCircle}
                  size={FontSize.size28}
                  color={Colors.icon.GEOFENCE}
                  style={{ marginRight: 5 }}
                />
                <Text>{t("location-edit:Radial")}</Text>
              </ToggleButton>
              <ToggleButton
                id="polygonal"
                variant="light"
                value={GeofenceType.POLYGONAL}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  src={faDrawSquare}
                  size={FontSize.size28}
                  color={Colors.icon.GEOFENCE}
                  style={{ marginRight: 5 }}
                />
                <Text>{t("location-edit:Polygonal")}</Text>
              </ToggleButton>
              <ToggleButton
                id="multipolygon"
                variant="light"
                value={GeofenceType.MULTIPOLYGON}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  src={faDrawSquare}
                  size={FontSize.size28}
                  color={Colors.icon.GEOFENCE}
                  style={{ marginRight: 1 }}
                />
                <Icon
                  src={faDrawPolygon}
                  size={FontSize.size28}
                  color={Colors.icon.GEOFENCE}
                  style={{ marginRight: 5 }}
                />
                <Text>{t("location-edit:Multipolygon")}</Text>
              </ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
          <Alert
            show={true}
            dismissible={false}
            variant={AlertVariant.Warning}
            style={{ marginTop: "1em" }}
          >
            <Text bold>{t("location-edit:Warning")}: </Text>
            <Text>
              {t(
                "location-edit:Changing geofence type will delete previous geofence location data.",
              )}
            </Text>
          </Alert>
          <Text bold>{t("location-edit:Do you want to continue?")}</Text>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#e2e2e2",
        }}
      >
        <Button
          variant="default"
          style={{
            backgroundColor: "white",
            marginRight: "0.5em",
            color: "#333",
            border: "1px solid #ccc",
          }}
          onClick={() => hide()}
        >
          {t("location-edit:Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleConfirm(newType);
          }}
        >
          {t("location-edit:Yes, Update")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpdateGeofenceTypeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  fenceType: PropTypes.object,
};
