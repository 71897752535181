/** @jsxImportSource @emotion/react */

// External
import _ from "lodash";
import { useState, useEffect, Fragment } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isShipper } from "shared/utils/organizations.utils";

// Components
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import { RoutingMapContextProvider } from "modules/map/components/RoutingMapContext";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { SurgicalToteTrackingHistoryTab } from "./components/SurgicalToteTrackingHistoryTab";
import { SurgicalToteTrackingExceptionsTab } from "./components/SurgicalToteTrackingExceptionsTab";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Tabs } from "components/molecules/Tabs.molecule";
import { SurgicalToteTrackingDetailsWidget } from "./components/SurgicalToteTrackingDetailsWidget";
import GeofenceBuilderMapContainer from "modules/map/components/GeofenceBuilderMap";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import { CommentFeed } from "components/organisms/CommentFeed.organism";

// Styles
import Colors from "styles/colors";
import {
  tabsCss,
  tabListStyle,
} from "components/multimodal-components/tabStyles";

export const SurgicalToteTrackingDetails = ({
  id,
  toteDetails,
  toteMedia,
  totePositionUpdates,
  isToteLocationDetailsLoading,
  toteLocationDetails,
  fetchToteDetails,
  fetchToteMedia,
  clearToteMedia,
  clearToteDetails,
  addCoordinate,
  selectedMapCoordinate,
  fetchToteActiveExceptions,
  sttActiveExceptions,
  fetchToteHistory,
  toteHistory,
  watchToteActions,
  fetchComments,
  addComment,
  cancelAddComment,
  organization,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  isFetchingComments,
  comments,
  fetchNotification,
}) => {
  const { t } = useTranslation("surgical-tote-tracking");
  const [watch, setWatch] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showHeatmap, setShowHeatmap] = useState(false);

  const mapLocations = toteLocationDetails;

  useSetTitleOnMount(
    t("surgical-tote-tracking:Healthcare Container Tracking Details"),
  );

  useEffect(() => {
    fetchToteHistory(id);
    fetchToteActiveExceptions(id);
    fetchToteDetails(id);
  }, [id, fetchToteHistory, fetchToteActiveExceptions, fetchToteDetails]);

  useEffect(() => {
    clearToteMedia();
    fetchToteMedia(toteDetails?.type);
  }, [toteDetails, clearToteMedia, fetchToteMedia]);

  useEffect(() => {
    // If the details have been updated we need to make sure
    // that our watch checkbox matches the value in the props
    const watchFlag = toteDetails?.watched?.toLowerCase() === "true" ?? false;
    setWatch(watchFlag);
  }, [toteDetails]);

  useEffect(() => {
    if (totePositionUpdates && totePositionUpdates.length) {
      const data = totePositionUpdates[0];
      const { latitude, longitude, time } = data;
      addCoordinate(
        MapCoordinateType.SELECTED_COORDINATE,
        latitude,
        longitude,
        time,
        "0_0",
        data,
        null,
        0,
      );
    }
  }, [totePositionUpdates, addCoordinate]);

  // clean up tote details on unmount
  useEffect(() => {
    return () => {
      clearToteDetails();
    };
  }, [clearToteDetails]);

  const heatmapCoords = totePositionUpdates;

  if (mapLocations && mapLocations.lad && mapLocations.lad.code === "s") {
    mapLocations.lad.code = "h";
    mapLocations.lad.name = "Hospital";
  }

  let detailsChildren = (
    <Tabs
      css={tabsCss}
      onSelect={(index) => {
        setSelectedTabIndex(index);
      }}
      defaultIndex={selectedTabIndex}
    >
      <Tabs.TabList style={tabListStyle}>
        <Tabs.Tab
          style={{
            borderColor: `${Colors.tabs.BORDER}`,
            width: "50%",
          }}
        >
          {t("surgical-tote-tracking:Healthcare Container Details")}
        </Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanel
        style={{
          backgroundColor: `${Colors.background.LIGHT_GRAY}`,
          border: `1px solid ${Colors.tabs.BORDER}`,
        }}
      >
        <div
          css={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <SurgicalToteTrackingDetailsWidget
            toteDetails={toteDetails}
            toteMedia={toteMedia}
            sttActiveExceptions={sttActiveExceptions}
            watch={watch}
            onWatchChange={(newWatchState) => {
              setWatch(newWatchState);
              watchToteActions(id, newWatchState);
            }}
          />
          {toteDetails?.id ? (
            <CommentFeed
              commentShareableWithOrgId={null}
              fetchComments={(pageNumber, pageSize) => {
                return fetchComments(
                  toteDetails?.containerId,
                  pageNumber,
                  pageSize,
                );
              }}
              isFetchingComments={isFetchingComments}
              comments={comments}
              addComment={(data) => {
                return addComment(toteDetails?.containerId, data);
              }}
              cancelAddComment={cancelAddComment}
              updateComment={(commentId, updatedData) => {
                return updateComment(
                  toteDetails?.containerId,
                  commentId,
                  updatedData,
                );
              }}
              cancelUpdateComment={cancelUpdateComment}
              markCommentsRead={(datetime) => {
                return markCommentsRead(toteDetails?.containerId, datetime);
              }}
              fetchNotification={fetchNotification}
              showBatchUpload={false}
              shipperOrgId={
                isShipper(organization) ? organization.organization_id : null
              }
            />
          ) : null}
          <Tabs
            style={{
              marginTop: "1em",
            }}
          >
            <Tabs.TabList>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("surgical-tote-tracking:Tote History")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("surgical-tote-tracking:Active Exceptions")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("surgical-tote-tracking:Coordinates")}
              </Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {toteHistory && toteHistory.length > 0 ? (
                <SurgicalToteTrackingHistoryTab toteHistory={toteHistory} />
              ) : (
                <TabPanelPlaceholder
                  text={t("surgical-tote-tracking:No Tote History available")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {sttActiveExceptions && sttActiveExceptions.length > 0 ? (
                <SurgicalToteTrackingExceptionsTab
                  sttActiveExceptions={sttActiveExceptions}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("surgical-tote-tracking:No Active Exceptions")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {totePositionUpdates.length > 0 ? (
                <CoordinatesTable
                  addCoordinate={addCoordinate}
                  selectedMapCoordinate={selectedMapCoordinate}
                  showCityColumn={false}
                  showStateColumn={false}
                  showCityStateColumn={false}
                  clearCoordinatesByType={() => {}}
                  coords={totePositionUpdates}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("surgical-tote-tracking:No Coordinates Available")}
                />
              )}
            </Tabs.TabPanel>
          </Tabs>
        </div>
      </Tabs.TabPanel>
    </Tabs>
  );
  return (
    <RoutingMapContextProvider>
      <DetailWithMap
        mapChildren={
          <Fragment>
            {heatmapCoords?.length >= 0 ? (
              <HeatMapButton
                isToggled={showHeatmap}
                onToggle={() =>
                  setShowHeatmap((oldShowHeatmap) => !oldShowHeatmap)
                }
                css={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                  zIndex: 2,
                }}
              />
            ) : null}
            {isToteLocationDetailsLoading ||
            !mapLocations ||
            _.isEmpty(mapLocations) ? (
              <GeofenceBuilderMapContainer
                heatmapCoords={heatmapCoords}
                showHeatmap={showHeatmap}
                mapLocations={[]}
                selectedMapCoordinate={selectedMapCoordinate}
                initialZoomLevel={selectedMapCoordinate ? 16 : 4}
              />
            ) : (
              <GeofenceBuilderMapContainer
                heatmapCoords={heatmapCoords}
                showHeatmap={showHeatmap}
                drawAllGeofences
                selectedLocation={{ ...mapLocations }}
                mapLocations={mapLocations ? [mapLocations] : []}
                selectedLad={mapLocations?.lad}
                selectedMapCoordinate={selectedMapCoordinate}
                initialZoomLevel={mapLocations ? 16 : undefined}
                useBoxChiclets
                ladIconHeight={36}
                ladIconWidth={36}
                ladIconWithTextSubscriptHeight={8}
                ladIconWithTextSubscriptWidth={8}
                boxTextSize={3}
                boxTextX={4.25}
                boxTextY={5.25}
                boxRadius={0}
                boxSubscriptTextSize={1.5}
                boxSubscriptTextY={1}
              />
            )}
          </Fragment>
        }
        detailsChildren={
          <Loader loaded={!isToteLocationDetailsLoading}>
            {detailsChildren}
          </Loader>
        }
      ></DetailWithMap>
    </RoutingMapContextProvider>
  );
};

SurgicalToteTrackingDetails.propTypes = {
  id: PropTypes.string,
  toteDetails: PropTypes.object,
  isToteDetailsLoading: PropTypes.bool,
  toteMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  totePositionUpdates: PropTypes.array,
  fetchToteDetails: PropTypes.func,
  fetchToteActiveExceptions: PropTypes.func,
  fetchToteMedia: PropTypes.func,
  clearToteMedia: PropTypes.func,
  addCoordinate: PropTypes.func,
  clearCoordinatesByType: PropTypes.func,
  selectedMapCoordinate: PropTypes.object,
  fetchToteHistory: PropTypes.func,
  toteHistory: PropTypes.array,
};
