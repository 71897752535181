/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// // Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

// // Utils
import { getIconData } from "pages/finishedvehicle/utils/exceptions.utils";

// // State
import SurgicalToteTrackingSearchBarState from "../../redux/SurgicalToteTrackingSearchBarState";
import SurgicalToteTrackingSavedSearchState from "../../redux/SurgicalToteTrackingSavedSearchState";
import SurgicalToteTrackingSavedSearchCardsState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingSavedSearchCardsState";
import { getExceptionChartData } from "components/utils/exceptions.utils";
import { DonutChart } from "components/molecules/DonutChart.molecule";

const { searchEntities } = SurgicalToteTrackingSearchBarState.actionCreators;
const { loadSavedSearch } = SurgicalToteTrackingSavedSearchState.actionCreators;

export const SurgicalToteTrackingSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
}) => {
  const { t } = useTranslation("surgical-tote-tracking");
  const dispatch = useDispatch();

  const savedSearchData = useSelector(
    SurgicalToteTrackingSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    dispatch(
      SurgicalToteTrackingSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
      ),
    );
  }, [dispatch, savedSearch]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities());
  };

  const handleExceptionClick = (exception) => {
    dispatch(
      loadSavedSearch({
        search: {
          ...savedSearch.search,
          activeExceptions: [exception.name],
        },
      }),
    );
    dispatch(searchEntities());
  };

  const getExceptionCount = (exceptionFvId) => {
    const exceptionData = data?.exceptions?.filter(
      (exception) => exception.fvId === exceptionFvId,
    );
    return exceptionData && exceptionData[0]?.count
      ? exceptionData[0].count
      : 0;
  };

  const getExceptionGroups = () => {
    const exceptionData = [
      {
        id: 1,
        name: t("surgical-tote-tracking:Unaccounted"),
        count: getExceptionCount("UNACCOUNTED"),
      },
      {
        id: 2,
        name: t("surgical-tote-tracking:Excessive Dwell"),
        count: getExceptionCount("EXCESSIVE_DWELL"),
        icon: {
          style: { height: "30px", width: "30px" },
          ...getIconData("Excessive Dwell"),
        },
      },
    ];
    return exceptionData;
  };

  const totalExceptionGroups = getExceptionGroups();

  const totalExceptionsCount = useMemo(
    () => totalExceptionGroups.reduce((acc, curr) => acc + curr.count, 0),
    [totalExceptionGroups],
  );

  const chartData = getExceptionChartData(
    totalExceptionGroups,
    data?.count,
    totalExceptionsCount,
  );

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        <ExceptionCountGroup
          exceptions={totalExceptionGroups}
          vertical={true}
          exceptionDirection={"column"}
          clickHandler={handleExceptionClick}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row",
              justifyContent: "center",
            },
            [MediaQueries.extraLarge]: {
              flexDirection: "column",
              justifyContent: "start",
            },
          }}
        >
          <DonutChart
            data={chartData}
            handler={loadFullSavedSearch}
            label={t("surgical-tote-tracking:Total Totes")}
            total={data?.count}
          />
        </div>
      </div>
    </SavedSearchPanel>
  );
};

SurgicalToteTrackingSavedSearch.propTypes = {
  // These come from the getCardProps in SurgicalToteTrackingSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};
