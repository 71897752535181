/** @jsxImportSource @emotion/react */
import { Button } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Colors from "styles/colors";

export const DeletePolygonModal = ({ show, hide, handleConfirm }) => {
  const { t } = useTranslation("location-edit");

  return (
    <Modal show={show} onHide={() => hide()}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("location-edit:Delete polygon")}
      />
      <Modal.Body
        style={{
          backgroundColor: Colors.background.LIGHT_GRAY,
        }}
      >
        {t(
          "location-edit:Warning, this will delete the polygon from this location's geofence - Would you like to proceed?",
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#e2e2e2",
        }}
      >
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => hide()}
        >
          {t("location-edit:Cancel")}
        </Button>
        <Button
          style={{
            backgroundColor: Colors.highlight.RED,
            color: "white",
          }}
          onClick={handleConfirm}
        >
          {t("location-edit:Delete the polygon")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeletePolygonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};
