/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";
import SchemaTree from "./SchemaTree";

const bodyCss = {
  padding: "1em 0",
};

const SampleBody = (props) => {
  const { t, body } = props;

  if (!body) {
    return null;
  }

  return (
    <div id="body" css={bodyCss}>
      <SectionHeader
        title={t("documentation:Request Body")}
        description={body.HTMLdescription}
      />
      <SchemaTree schema={body.content["application/json"].schema} />
    </div>
  );
};

SampleBody.propTypes = {
  body: PropTypes.object.isRequired,
};
export default withTranslation(["documentation"])(SampleBody);
