import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "../ContainerTracingSearchCategoryDefs";
import apiUrl from "api-url";
import SearchState from "./ContainerTrackingSearchBarState";
import { getSolutionId } from "../utils/ContainerTrackingUtils";
import { getFeatureData } from "modules/organizations/OrganizationsState";

const STORE_MOUNT_POINT = "ctSavedSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: (state) => {
    const featureData = getFeatureData(state);
    const solution_id = getSolutionId(featureData);
    return solution_id;
  },
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter,
  },
  isSearchTypeFunction: true,
});
