import { connect } from "react-redux";
import { getFeatureData } from "modules/organizations/OrganizationsState";
import { getSolutionId } from "../utils/ContainerTrackingUtils";
import ContainerTrackingDashboard from "./ContainerTracking.Dashboard.page";
import { ContainerTrackingCountsWidgetState } from "../redux/ContainerTrackingCountsWidgetState";
import ContainerTrackingSearchBarState from "../redux/ContainerTrackingSearchBarState";
import ContainerTypeTableState from "../redux/ContainerTrackingContainerTypeTableState";

function mapStateToProps(state) {
  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getDefaultSortColumn,
    getDefaultReverseSort,
    getSortColumn,
    getReverseSort,
    getSearchFilters,
    getExportName,
    getExportIdentifier,
    getIsExporting,
    getExportFailed,
  } = ContainerTypeTableState.selectors;

  return {
    solutionId: getSolutionId(getFeatureData(state)),
    containerTotals:
      ContainerTrackingCountsWidgetState.selectors.getContainerTotals(state),
    isContainerTotalsLoading:
      ContainerTrackingCountsWidgetState.selectors.getContainerTotalsLoadingFlag(
        state,
      ),
    containerTypeTableResults: getSearchResults(state),
    isContainerTypeTableLoading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    defaultSortColumn: getDefaultSortColumn(state),
    defaultReverseSort: getDefaultReverseSort(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    searchFilters: getSearchFilters(state),
    exportName: getExportName(state),
    exportIdentifier: getExportIdentifier(state),
    isExporting: getIsExporting(state),
    exportFailed: getExportFailed(state),
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...stateProps, ...dispatchProps, ...ownProps };
  return props;
};

const { fetchContainerTotals } =
  ContainerTrackingCountsWidgetState.actionCreators;

const { searchEntities, setSearchFilter, resetSearchAndFilters } =
  ContainerTrackingSearchBarState.actionCreators;

const actionCreators = {
  fetchContainerTotals,
  setSearchFilter,
  searchEntities,
  resetSearchAndFilters,
  fetchContainerType: (solutionId) => (dispatch) =>
    dispatch(ContainerTypeTableState.actionCreators.searchEntities(solutionId)),
  setPagination: (solutionId, page, pageSize) => (dispatch) =>
    dispatch(
      ContainerTypeTableState.actionCreators.setPagination(
        solutionId,
        page,
        pageSize,
      ),
    ),
  setSort: (solutionId, sortColumn, reverse) => (dispatch) =>
    dispatch(
      ContainerTypeTableState.actionCreators.setSort(
        solutionId,
        sortColumn,
        reverse,
      ),
    ),
  setContainerTypeTableFilter: (key, value) => (dispatch) =>
    dispatch(
      ContainerTypeTableState.actionCreators.setSearchFilter(key, value),
    ),
  setFilter: (key, value) => (dispatch) =>
    dispatch(
      ContainerTypeTableState.actionCreators.setSearchFilter(key, value),
    ),
  clearSearchText: () => (dispatch) =>
    dispatch(ContainerTypeTableState.actionCreators.clearSearchText()),
  clearSearchFilters: () => (dispatch) =>
    dispatch(ContainerTypeTableState.actionCreators.clearSearchFilters()),
  clearSearchFilter: (key) => (dispatch) =>
    dispatch(ContainerTypeTableState.actionCreators.clearSearchFilter(key)),
  exportContainerTypes: () => (dispatch) => {
    dispatch(ContainerTypeTableState.actionCreators.exportSearch());
  },
  resetExport: () => (dispatch) => {
    dispatch(ContainerTypeTableState.actionCreators.resetExport());
  },
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(ContainerTrackingDashboard);
