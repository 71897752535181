/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";

export const DetailWithMap = ({ mapChildren, detailsChildren }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        [MediaQueries.largeAndUp]: {
          flexDirection: "row",
          height: "100%",
        },
      }}
    >
      {/* Map */}
      <div
        css={{
          flex: "1 1 0",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          minHeight: "300px",
          maxWidth: "100%",
          [MediaQueries.largeAndUp]: {
            minHeight: "100%",
            flex: "3 3 0",
            maxWidth: "50%",
          },
        }}
      >
        {mapChildren}
      </div>
      {/* Details */}
      <div
        css={{
          flexGrow: 3,
          position: "relative",
          background: Colors.background.LIGHT_GRAY,
          overflow: "auto",
          height: "100%",
          maxWidth: "100%",
          [MediaQueries.largeAndUp]: {
            maxWidth: "50%",
          },
        }}
      >
        {detailsChildren}
      </div>
    </div>
  );
};

DetailWithMap.propTypes = {
  /**
   * The Map and other children components.
   */
  mapChildren: PropTypes.any,
  /**
   * The details components.
   */
  detailsChildren: PropTypes.any,
};
