/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React from "react";
import { Translation } from "react-i18next";
import PropTypes from "prop-types";
import { Tabs } from "components/molecules/Tabs.molecule";
import { tabStyle } from "./tabStyles";
import EndToEndTabIcon from "./EndToEndTabIcon";
import { MadChicletCSS } from "../../components/chiclets";
import { ShowForSmallAndUp } from "../responsive";

const LegTabLabel = styled.span({
  display: "block",
  fontSize: 14,
  marginLeft: 10,
});

/* omits label text entirely if screen too small */
const ResponsiveLegTabLabel = ({ children }) => {
  return (
    <ShowForSmallAndUp>
      <LegTabLabel>{children}</LegTabLabel>
    </ShowForSmallAndUp>
  );
};

ResponsiveLegTabLabel.propTypes = {
  children: PropTypes.node,
};

export const endToEndTab = (eventHandler) => {
  return (
    <Tabs.Tab
      style={tabStyle}
      key="end-to-end"
      onClick={(evt) => eventHandler(null, "SELECT_LEG")}
      data-qa="tab-button-end-to-end"
    >
      <EndToEndTabIcon />
      <Translation ns="components">
        {(t) => (
          <ResponsiveLegTabLabel>
            {t("components:End to End")}
          </ResponsiveLegTabLabel>
        )}
      </Translation>
    </Tabs.Tab>
  );
};

export const legTabs = (
  childSummaries,
  childDetails,
  eventHandler,
  isShipmentDetails,
  onClick = () => {},
  getTabLabel,
) => {
  return childSummaries.map((summary, i) => {
    const shipment = childDetails[summary.shipment_id];
    return shipment ? (
      <Tabs.Tab
        style={tabStyle}
        key={summary.shipment_id}
        onClick={(evt) => {
          eventHandler(
            isShipmentDetails ? shipment.id : shipment.creator_shipment_id,
            "SELECT_LEG",
          );

          if (onClick) {
            onClick(evt);
          }
        }}
        data-qa={`tab-button-leg${i + 1}`}
      >
        <MadChicletCSS
          shipmentMode={shipment.mode_name}
          stopMode={shipment.mode_name}
          width={20}
          height={20}
        />

        <ResponsiveLegTabLabel>
          {getTabLabel ? (
            getTabLabel(shipment)
          ) : (
            <Translation ns="components">
              {(t) => (
                <React.Fragment>
                  {t("components:Leg")} {i + 1}
                </React.Fragment>
              )}
            </Translation>
          )}
        </ResponsiveLegTabLabel>
      </Tabs.Tab>
    ) : null;
  });
};
