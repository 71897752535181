import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useSearchBarState } from "components/search-bar/useSearchBarState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import {
  localizedDateFormatter,
  localizedTimeFormatterWithSeconds,
} from "utils/date-time";
import ApiLogsSearchBarState from "./ApiLogsSearchBarState";

// Hook for interacting with common API Logs state
export const useApiLogs = () => {
  const { t } = useTranslation(["documentation"]);

  const state = useSearchBarState(ApiLogsSearchBarState);
  const auth = useSelector(getAuthorization);

  const { getPage, getPageSize, getLoadingError } =
    ApiLogsSearchBarState.selectors;

  const loadingError = useSelector(getLoadingError);
  const searchResults = !loadingError
    ? formatSearchResults(state.searchResults)
    : [];

  const page = useSelector(getPage);
  const pageSize = useSelector(getPageSize);
  // The count request from the API can timeout when querying against a lot of data.
  // If that request doesn't go through, we can't paginate with BaseTable.
  // The value of totalPages is faked by looking at our data length of the current page.
  // - if it equals the pageSize, assume there is more data.
  // - if it is less than pageSize, assume this is the last page.
  // Note: If the result count on the actual last page is equal to pageSize,
  //       the user will see the last page as empty.
  const totalPages = searchResults.length === pageSize ? page + 2 : page + 1;

  return {
    t,
    auth,
    ...state,
    searchResults,
    page,
    pageSize,
    totalPages,
  };
};

// Hook to apply default filters to the API Logs search
export const useDefaultFilters = () => {
  const dispatch = useDispatch();
  const { setSearchFilter } = ApiLogsSearchBarState.actionCreators;

  useEffect(() => {
    dispatch(setSearchFilter("type", "event-error"));
  }, [dispatch, setSearchFilter]);
};

// Hook to automatically submit initial search
export const useInitialSearch = (searchEntities) => {
  const [searchAttempted, setSearchAttempted] = useState(false);

  useEffect(() => {
    if (!searchAttempted) {
      setSearchAttempted(true);
      searchEntities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAttempted]);
};

// Format the API Logs search results for display
const formatSearchResults = (results) =>
  results.map((row) => {
    const {
      source,
      eventType,
      shipmentId,
      orgName,
      status,
      errorMessage,
      requestBody,
      requestId,
      fvShipmentId,
      shipmentInternalId,
      correlationId,
    } = row;
    const receivedTime = row.receivedTime
      ? `${localizedDateFormatter(
          row.receivedTime,
        )} ${localizedTimeFormatterWithSeconds(row.receivedTime)}`
      : "";

    return {
      receivedTime,
      source,
      eventType,
      shipmentId,
      orgName,
      status,
      errorMessage,
      requestBody,
      requestId,
      fvShipmentId,
      shipmentInternalId,
      correlationId,
    };
  });
