/** @jsxImportSource @emotion/react */
// Third party Libraries
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import {
  DpuLocationCell,
  LastMileStoneCell,
  StatusCell as StatusHistoryStatusCell,
} from "pages/driveaway/components/table-cells/status-history";

export const useColumns = () => {
  const { t } = useTranslation("driveaway-search");

  return [
    {
      Header: t("driveaway-search:Event time"),
      Cell: (cellInfo) => {
        return (
          <DateTimeCell localize dateTime={cellInfo.row.original.eventTs} />
        );
      },
      accessor: "eventTs",
      width: 85,
    },
    {
      Header: t("driveaway-search:Status"),
      Cell: StatusHistoryStatusCell,
      accessor: (d) => {
        return {
          status: d.status,
          denyReasonCode: d.denyReasonCode,
        };
      },
      width: 120,
    },
    {
      Header: t("driveaway-search:DPU Location"),
      Cell: DpuLocationCell,
      accessor: "dpuLocation",
      width: 100,
    },
    {
      Header: t("driveaway-search:Last Milestone"),
      Cell: LastMileStoneCell,
      accessor: "milestone",
      width: 125,
    },
  ];
};
