import apiUrl from "../../api-url";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";

const MOUNT_POINT = "exports";

export const ExportStatus = {
  INITIAL_REQUEST_PENDING: "INITIAL_REQUEST_PENDING",
  NOT_READY: "NOT_READY",
  READY: "READY",
  ERROR: "ERROR",
  TIMEOUT: "TIMEOUT",
  CANCELED: "CANCELED",
  NOT_FOUND: "NOT_FOUND",
  EXPIRED: "EXPIRED",
};

// Currently the drive away pickup form uses the "/support/get_csv_export" endpoint
// but ideally we want to have different endpoints for different file type exports
export const ExportType = {
  CSV: "csv",
  DRIVE_AWAY_PDF: "drive_away_pdf",
};

const POLLING_INTERVAL_DURATION = moment
  .duration(5, "seconds")
  .asMilliseconds();
const POLLING_TIMEOUT_DURATION = moment
  .duration(15, "minutes")
  .asMilliseconds();

// URLs
const getExportUrl = (fileId, exportType = ExportType.CSV) =>
  apiUrl(`/support/get_${exportType}_export?file=${fileId}`);

const getUpdateExportUrl = (fileId, exportType = ExportType.CSV) =>
  apiUrl(`/support/${fileId}/update_${exportType}_export`);

const ADD_EXPORT = "Exports/ADD_EXPORT";
const UPDATE_EXPORT = "Exports/UPDATE_EXPORT";

// Action (thunk): Used to fetch the final download
const fetchExportDownload =
  (exportId, exportType = ExportType.CSV) =>
  (dispatch, getState) => {
    if (!exportId) {
      throw new Error("fetchExportDownload requires 'exportId'");
    }

    const currentExport = getExport(exportId)(getState());

    if (currentExport.status !== ExportStatus.READY) {
      throw new Error(`Export with id '${exportId}' is not ready for download`);
    }
    const noInterceptorsAxios = axios.create();
    // When the "/support/get_pdf_export" is ready check for exportType == ExportType.DRIVE_AWAY_PDF
    if (currentExport.url.includes("pdf-export")) {
      return noInterceptorsAxios
        .get(currentExport.url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, currentExport.exportName);
        });
    }
    return noInterceptorsAxios
      .get(currentExport.url)
      .then((resp) => {
        fileDownload(resp.data, currentExport.exportName);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          updateExport(exportId, { status: ExportStatus.ERROR }, exportType),
        );
      });
  };

const patchExportStatus = (
  exportId,
  status = ExportStatus.CANCELED,
  exportType = ExportType.CSV,
) => {
  if (!exportId) {
    throw new Error("patchExportStatus requires 'exportId'");
  }
  let patchUrl = getUpdateExportUrl(exportId, exportType);
  return axios
    .patch(
      patchUrl,
      { status: status },
      { headers: { Accept: "text/csv;version=full" } },
    )
    .catch((error) => {
      console.error(error);
    });
};

const postExportStatus = (
  exportId,
  status = ExportStatus.NOT_FOUND,
  exportType = ExportType.CSV,
) => {
  if (!exportId) {
    throw new Error("postExportStatus requires 'exportId'");
  }
  let postUrl = getUpdateExportUrl(exportId, exportType);
  return axios.post(
    postUrl,
    { status: status },
    { headers: { Accept: "text/csv;version=full" } },
  );
};

export const handleExportNotification =
  (notificationId, exportContext) => async (dispatch, getState) => {
    const exportState = getExportList(getState());

    const exportId = exportContext.exportId ?? exportContext.export_id;
    if (exportState[exportId] === undefined) {
      await dispatch(
        addExport(exportId, exportState.exportName, {
          notification_id: notificationId,
          status: exportContext.status ?? ExportStatus.INITIAL_REQUEST_PENDING,
          ...exportContext,
        }),
      );
    }
  };

// Action (thunk): Used for polling for export ready status
const pollAsyncExportStatus =
  (exportId, exportType = ExportType.CSV) =>
  (dispatch) => {
    let url = getExportUrl(exportId, exportType);

    dispatch(
      updateExport(
        exportId,
        { status: ExportStatus.INITIAL_REQUEST_PENDING },
        exportType,
      ),
    );

    const pollingIntervalHandle = setInterval(() => {
      axios
        // Request the export status
        .get(url, { headers: { Accept: "text/csv;version=full" } })
        // Set export status based on response
        .then(async (response) => {
          const exportInfo = {
            timestamp: response?.data?.updated_at,
            source: response?.data?.source,
            exportName: response?.data?.exportName,
          };
          switch (response.data.status) {
            case ExportStatus.READY:
              // Stop polling
              clearInterval(pollingIntervalHandle);
              // Set export status
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.READY,
                    url: response.data.url,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.ERROR:
              // Stop polling
              clearInterval(pollingIntervalHandle);
              // Set export status
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.ERROR,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.CANCELED:
              // Stop polling
              clearInterval(pollingIntervalHandle);
              // Set export status
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.CANCELED,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.NOT_FOUND:
              clearInterval(pollingIntervalHandle);
              // Set export status
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.NOT_FOUND,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.TIMEOUT:
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.TIMEOUT,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.NOT_READY:
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.NOT_READY,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            case ExportStatus.EXPIRED:
              await dispatch(
                updateExport(
                  exportId,
                  {
                    status: ExportStatus.EXPIRED,
                    ...exportInfo,
                  },
                  exportType,
                ),
              );
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(
            updateExport(exportId, { status: ExportStatus.ERROR }, exportType),
          );
        });
    }, POLLING_INTERVAL_DURATION);

    const pollingTimeoutHandle = setTimeout(() => {
      // Stop polling
      clearInterval(pollingIntervalHandle);
      // Set the export status
      dispatch(
        updateExport(exportId, { status: ExportStatus.TIMEOUT }),
        exportType,
      );
    }, POLLING_TIMEOUT_DURATION);

    return {
      pollingIntervalHandle,
      pollingTimeoutHandle,
    };
  };

const addExport = (
  exportId,
  exportName,
  payload = {},
  exportType = ExportType.CSV,
) => {
  if (!exportId) {
    throw new Error("addExport requires 'exportId'");
  }

  return (dispatch) => {
    // Get the handles for the polling interval and timeout

    const pollExport =
      (exportId, exportName, hasPolled, exportType) => (dispatch) => {
        if (!hasPolled && payload?.status?.length !== 0) {
          return dispatch(pollAsyncExportStatus(exportId, exportType));
        }
        return { pollingTimeoutHandle: null, pollingIntervalHandle: null };
      };
    const { pollingTimeoutHandle, pollingIntervalHandle } = dispatch(
      pollExport(
        exportId,
        exportName,
        payload?.async_process_completed,
        exportType,
      ),
    );

    payload.pollingIntervalHandle = pollingIntervalHandle;
    payload.pollingTimeoutHandle = pollingTimeoutHandle;

    payload.status = payload.status ?? ExportStatus.NOT_READY;

    payload.exportName = payload.exportName ?? exportName;

    const exportPayload = {
      exportId,
      ...payload,
    };

    dispatch({
      type: ADD_EXPORT,
      payload: { ...exportPayload },
    });
  };
};

const updateExport =
  (
    exportId,
    { status, url = null, timestamp = null, source = null, exportName = null },
    exportType = ExportType.CSV,
  ) =>
  (dispatch, getState) => {
    const exportToUpdate = getExport(exportId)(getState());

    const cancelPolling = () => {
      clearInterval(exportToUpdate.pollingIntervalHandle);
      clearTimeout(exportToUpdate.pollingTimeoutHandle);
    };

    // If the status is ready, we want to:
    // - cancel polling
    if (
      status === ExportStatus.ERROR ||
      status === ExportStatus.EXPIRED ||
      status === ExportStatus.READY
    ) {
      cancelPolling();
    } else if (status === ExportStatus.NOT_FOUND) {
      cancelPolling();
      postExportStatus(exportId, status, exportType);
    } else if (
      status === ExportStatus.TIMEOUT ||
      status === ExportStatus.CANCELED
    ) {
      cancelPolling();
      patchExportStatus(exportId, status, exportType);
    }

    dispatch({
      type: UPDATE_EXPORT,
      payload: {
        exportId,
        status,
        url,
        timestamp,
        source,
        exportName,
      },
    });
  };

const getExport = (exportId) => (state) => {
  return state[MOUNT_POINT][exportId] ?? {};
};
const getExportList = (state) => {
  return state[MOUNT_POINT] ?? [];
};

// Reducer
function exportsReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADD_EXPORT:
      return {
        ...state,
        [action.payload.exportId]: {
          ...action.payload,
        },
      };
    case UPDATE_EXPORT:
      let updatedExport = { ...state[action.payload.exportId] };
      updatedExport.status = action.payload.status;

      if (action.payload.url) {
        updatedExport.url = action.payload.url;
      }

      if (action.payload.downloadTimeoutHandle) {
        updatedExport.downloadTimeoutHandle =
          action.payload.downloadTimeoutHandle;
      }

      // If error, remove all handles (canceled in the action)
      if (action.payload.status === ExportStatus.ERROR) {
        delete updatedExport.pollingIntervalHandle;
        delete updatedExport.pollingTimeoutHandle;
        delete updatedExport.downloadTimeoutHandle;
      }

      updatedExport.exportFailed = action.payload.status === ExportStatus.ERROR;
      updatedExport.isExporting =
        action.payload.status === ExportStatus.INITIAL_REQUEST_PENDING;

      /// most recent timestamp for export notifications
      updatedExport.timestamp = action.payload.timestamp;

      // source for exports
      updatedExport.source = action.payload.source;

      // Filename if export was loaded via notifications
      if (action.payload.exportName) {
        updatedExport.exportName = action.payload.exportName;
      }

      return {
        ...state,
        [action.payload.exportId]: updatedExport,
      };
    default:
      return state;
  }
}

export default {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    addExport,
    updateExport,
    handleExportNotification,
    fetchExportDownload,
    patchExportStatus,
    postExportStatus,
  },
  selectors: {
    getExport,
    getExportList,
  },
  reducer: exportsReducer,
};
