// Displays a table of Watched VINs, and allows user to watch or unwatch each VIN

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import CarrierViewEntityDetailsState from "../../../redux/CarrierViewEntityDetailsState";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import Colors from "styles/colors";

export const WatchedVins = (props) => {
  const {
    watchedVins,
    isWatchedVinsLoading,
    watchedVinsPageIndex,
    watchedVinsPageSize,
    watchedVinsPageCount,
    setWatchedVinsPageIndex,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
  } = props;
  const { t } = useTranslation("carrierview-dashboard");
  const dispatch = useDispatch();

  const [unwatchedVins, setUnwatchedVins] = useState([]);
  let unwatchTimers = {};

  const toggleWatch = (vin) => {
    let newUnwatchedVins = [...unwatchedVins];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[vin]) {
      clearTimeout(unwatchTimers[vin]);
    }

    if (newUnwatchedVins.includes(vin)) {
      // VIN checkbox has already been unchecked - re-watch it
      newUnwatchedVins = newUnwatchedVins.filter((v) => v !== vin);
    } else {
      // VIN checkbox is checked - unwatch it
      newUnwatchedVins.push(vin);
    }

    setUnwatchedVins(newUnwatchedVins);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[vin] = setTimeout(() => {
      fetchWatchedVins();
      fetchWatchedEntitiesTotalPages();
    }, 2000);
  };

  // Render unchecked VIN rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  const CellRenderer = (cellInfo) => {
    const dim = unwatchedVins.includes(cellInfo.row.original.id);
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = (sortKey, sortDir) => {
    return [
      {
        Header: t("carrierview-dashboard:Watch"),
        accessor: "watch",
        width: 50,
        disableSortBy: true,
        disableResizing: true,
        centerAligned: true,
        Cell: (cellInfo) => {
          const vin = cellInfo.row.original.id;
          const internalId = cellInfo.row.original.internal_id;
          // API returns boolean in a string
          const watched = cellInfo.value === "true";
          return (
            <WatchToggle
              key={vin}
              checked={watched ?? false}
              onChange={(newWatchValue) => {
                dispatch(
                  CarrierViewEntityDetailsState.actionCreators.setWatchEntity(
                    null, // setWatchEntity needs solutionId as first parameter until VinViewDetails is fully completed.
                    internalId,
                    newWatchValue,
                  ),
                );
                toggleWatch(vin);
              }}
              iconSize={FontSize.size24}
              color={Colors.nav.NAVY}
              checkedColor={Colors.highlight.YELLOW}
            />
          );
        },
      },
      {
        Header: t("carrierview-dashboard:VIN"),
        accessor: "id",
        Cell: (cellInfo) => {
          const dim = unwatchedVins.includes(cellInfo.row.original.id);

          return (
            <div
              style={{
                opacity: cellOpacity(dim),
              }}
            >
              {cellInfo.row.original.commentsCount &&
              cellInfo.row.original.commentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>
                      {t("carrierview-search:This VIN contains comments")}
                    </Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    color={Colors.comments.unreadCommentIcon}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
              ) : null}
              <Text bold>{cellInfo.row.original.id}</Text>
            </div>
          );
        },
      },
      {
        Header: t("carrierview-dashboard:Product Type"),
        accessor: "description",
        Cell: CellRenderer,
      },
    ];
  };

  const rowClickHandler = (row, cell) => {
    const { pushVinViewDetailsPage } = props;

    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    pushVinViewDetailsPage(row.original.internal_id);
  };

  return (
    <div css={{ position: "relative", minHeight: 54 }}>
      <BaseTable
        data={watchedVins}
        columns={tableColumns()}
        isLoading={isWatchedVinsLoading}
        rowClickHandler={rowClickHandler}
        showPagination={true}
        showPageSizeDropdown={false}
        isManualPagination={true}
        pageCount={watchedVinsPageCount}
        pageSize={watchedVinsPageSize}
        pageIndex={watchedVinsPageIndex}
        onPageChange={(newPage) => {
          setWatchedVinsPageIndex(newPage);
        }}
      />
    </div>
  );
};

WatchedVins.propTypes = {
  watchedVins: PropTypes.array,
  isWatchedVinsLoading: PropTypes.bool,
  fetchWatchedVins: PropTypes.func.isRequired,
  fetchWatchedEntitiesTotalPages: PropTypes.func.isRequired,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  pushVinViewDetailsPage: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushVinViewDetailsPage: (internalId) => {
      dispatch({
        type: "CARRIERVIEW_DETAILS",
        payload: { internal_id: internalId },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(WatchedVins);
