import _ from "lodash";
import axios from "axios";
import apiUrl from "api-url";

const getListNotificationUrl = (queryString) => {
  return apiUrl(`subscription/v2${queryString ? `?${queryString}` : ""}`);
};

const getDeleteNotificationUrl = (id) => {
  return apiUrl(`subscription/v2/${id}`);
};
const STORE_MOUNT_POINT = "NotificationManagementState";

// Actions
const SET_NOTIFICATION_RULE_LIST = `${STORE_MOUNT_POINT}/SET_NOTIFICATION_RULE_LIST`;
const SET_NOTIFICATION_RULE_LIST_LOADING = `${STORE_MOUNT_POINT}/SET_NOTIFICATION_RULE_LIST_LOADING`;
const SET_PAGINATION = `${STORE_MOUNT_POINT}/SET_PAGINATION`;
const SET_PAGE_SIZE = `${STORE_MOUNT_POINT}/SET_PAGE_SIZE`;
const SET_TOTAL_PAGES = `${STORE_MOUNT_POINT}/SET_TOTAL_PAGES`;

const fetchNotificationRuleList = (pageIdx, pageSize) => {
  const queryString = `pageNumber=${pageIdx}&pageSize=${pageSize}`;
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_RULE_LIST_LOADING,
    });
    return Promise.all([axios.get(getListNotificationUrl(queryString))])
      .then((response) => {
        dispatch({
          type: SET_NOTIFICATION_RULE_LIST,
          payload: response[0].data.data,
        });
        dispatch({
          type: SET_TOTAL_PAGES,
          payload: response[0].data.meta.totalPages,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

const setPagination = (newIdx, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAGINATION,
      payload: { pageIdx: newIdx, pageSize: pageSize },
    });
  };
};

const deleteNotification = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_RULE_LIST_LOADING,
    });
    return Promise.all([axios.delete(getDeleteNotificationUrl(id))])
      .then((response) => {
        dispatch(fetchNotificationRuleList(0, 20));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const initialState = {
  notificationRuleList: [],
  notificationRuleListLoading: false,
  pageIdx: 0,
  pageSize: 20,
  totalPages: 0,
};

const NotificationManagementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_NOTIFICATION_RULE_LIST:
      return {
        ...state,
        notificationRuleList: action.payload,
        notificationRuleListLoading: false,
      };
    case SET_NOTIFICATION_RULE_LIST_LOADING:
      return {
        ...state,
        notificationRuleListLoading: true,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pageIdx: action.payload.pageIdx,
        pageSize: action.payload.pageSize,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };
    default:
      return state;
  }
};

const getNotificationRuleList = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.notificationRuleList`, null);
const getNotificationRuleListLoading = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.notificationRuleListLoading`, null);
const getPageIdx = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.pageIdx`, null);
const getPageSize = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.pageSize`, null);
const getTotalPages = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.totalPages`, null);

const NotificationManagementState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchNotificationRuleList,
    setPagination: setPagination,
    deleteNotification,
  },
  selectors: {
    getNotificationRuleList,
    getNotificationRuleListLoading,
    getPageIdx,
    getPageSize,
    getTotalPages,
  },
  reducer: NotificationManagementReducer,
};
export default NotificationManagementState;
