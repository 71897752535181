/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
// eslint-disable-next-line
import React from "react";
import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";

export const TabPanelPlaceholder = ({ text }) => (
  <div
    css={{
      height: 80,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontStyle: "italic",
      color: "#d0cfcf",
    }}
  >
    {text}
  </div>
);

TabPanelPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export const PhotoPlaceholder = () => {
  const { t } = useTranslation("components");

  return (
    <div
      css={{
        height: 100,
        maxWidth: 160,
        // width: 160,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontStyle: "italic",
        textAlign: "center",
      }}
    >
      {t("components:Photo Coming Soon")}
    </div>
  );
};

export const NaPlaceholder = () => {
  const { t } = useTranslation("components");

  return (
    <Text block size={FontSize.size12}>
      {t("components:N/A")}
    </Text>
  );
};
