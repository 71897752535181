/** @jsxImportSource @emotion/react */
/**
 * A map that unite points that are close between them into a group and plot
 * the group into the map. Just when you zoom in sufficiently is where you
 * can really see each marker of the location itself.
 */
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

import { withResizeDetector } from "components/hooks/resize-detector";
import { SimpleMap, enrichMapLocations } from "./SimpleMap";
import MapGroups from "../utils/MapGroups";
import { PinChicletSVG, CircleChicletSVG } from "../../../components/chiclets";
import Colors from "../../../styles/colors";
import MapState from "../MapState";

const LOCATION_MARKER_ZINDEX = 3;
const GROUP_MARKER_ZINDEX = 4;
const GROUP_MAX_LOCATIONS_TO_OPEN_INDIVIDUALLY = 50;

class ClumpingMap extends SimpleMap {
  constructor(props) {
    super(props);

    // Init properties
    this.groups = new MapGroups();
    this._groupsOrderedLocationsCountByZoomLevelCache = {};

    this.state = {
      mapLocations: [],
      hasUpdatedOnce: false,
    };

    // Binding events
    this.onMarkerLocationClick = this.onMarkerLocationClick.bind(this);
    this.onMarkerGroupClick = this.onMarkerGroupClick.bind(this);
    this.onMapViewChange = this.onMapViewChange.bind(this);
    this.onMarkerMouseEnter = this.onMarkerMouseEnter.bind(this);
    this.onMarkerMouseOut = this.onMarkerMouseOut.bind(this);
    this.generateAndRedrawGroups = this.generateAndRedrawGroups.bind(this);
    this.recenterMap = this.recenterMap.bind(this);
  }

  // Init and lifecycle stuff

  initMap() {
    SimpleMap.prototype.initMap.call(this);
    this.addMapEventListener("mapviewchange", this.onMapViewChange);
    this.setMapZoom(3);
  }

  initAll() {
    SimpleMap.prototype.initAll.call(this);
    this.generateAndRedrawGroups();
  }

  componentDidMount() {
    this.groups.clear();
    this._groupsOrderedLocationsCountByZoomLevelCache = {};

    this.initAll();
  }

  componentDidUpdate(prevProps) {
    const { uiButtons } = this.props;
    const { hasUpdatedOnce } = this.state;

    if (!hasUpdatedOnce && uiButtons?.length > 0) {
      uiButtons.forEach((uiButton) => {
        this.platform.addUIButton(uiButton.label, uiButton.clickCallback);
      });
    }

    this.loadLocations(this.generateAndRedrawGroups);
    this._showPopupForSelectedLocation(prevProps);
    this.handleMapSizeChanges(prevProps);
    this.handleHeatmapChanges(prevProps);

    if (!hasUpdatedOnce) {
      this.setState({ hasUpdatedOnce: true });
    }

    // Map was previously uncentered, and a request was made to recenter it
    if (
      prevProps.isViewCentered !== this.props.isViewCentered &&
      this.props.isViewCentered
    ) {
      this.recenterMap();
    }
  }

  /**
   * Show a popup with the selected location changes.
   *
   * @param {Object} prevProps
   */
  _showPopupForSelectedLocation(prevProps) {
    const { selectedLocation } = this.props;
    const hasSelectedLocationChanged =
      selectedLocation !== prevProps.selectedLocation;
    if (hasSelectedLocationChanged) {
      if (selectedLocation === null) {
        this.clearInfoBubbles();
      } else {
        this.zoomSingleLocation(selectedLocation);
        // Once zoom has changed, we need to recalculate groups before showing
        // the popup. If not, the popup will show invalid informations with the
        // old data for the group with less zoom
        this.groups = this.computeMarkersGroupings(this.getVisibleLocations());
        this.showPopupForLocation(selectedLocation);
      }
    }
  }

  /**
   * Check if property mapLocations received changed in comparison with what
   * is on the map, if it changed, draw the new ones.
   *
   * @param {Function} onLocationsLoaded Function executed after locations are
   * loaded.
   **/
  loadLocations(onLocationsLoaded = _.noop) {
    const isLocationsDifferent = (list1, list2) => {
      return !_.isEqual(
        list1.map((item) => item.id),
        list2.map((item) => item.id),
      );
    };

    const restartZoomAndCenterMapOnLocations = (locations) => {
      if (locations.length) {
        this.recenterMap();
      }
    };

    const { mapLocations } = this.props;
    const haveMapLocationsChanged = isLocationsDifferent(
      mapLocations,
      this.state.mapLocations,
    );

    if (haveMapLocationsChanged) {
      restartZoomAndCenterMapOnLocations(mapLocations);
      // XXX: setState is asynchronous and we need to call onMapViewChange with
      // the new values of mapLocations, once sometimes the view does not
      // change for some reason but we need onMapViewChange fired anyways to
      // simplify our data handling externally
      this.setState({ mapLocations: enrichMapLocations(mapLocations) }, () => {
        this._onMapViewChangeBubbleEvent();
        onLocationsLoaded();
      });
    }
  }

  // Getters
  /**
   * Get all locations that are visible on the map right now.
   * @return {Array} Array with the locations that are inside the view bounds.
   */
  getVisibleLocations() {
    return _.filter(this.state.mapLocations, (location) => {
      if (
        !this.isMapViewBoundsContainLatLng(
          location.latitude,
          location.longitude,
        )
      ) {
        return false;
      }
      return true;
    });
  }

  // Events
  //
  /**
   * Handle clicks in single location markers
   */
  onMarkerLocationClick(event, googleMapsMarker) {
    const marker = googleMapsMarker ? googleMapsMarker : event.target;
    const markerData = marker.getData();
    const hasMarkerMultipleLocations = _.isArray(markerData);

    this.showPopupForLocation(
      hasMarkerMultipleLocations ? markerData[0] : markerData,
    );
  }

  /**
   * Handle clicks in grouped markers. It just ungroup the markers in that
   * group.
   */
  onMarkerGroupClick(e, googleMapsMarker) {
    const { mapLocations } = this.props;
    const marker = googleMapsMarker ? googleMapsMarker : e.target;

    const zoomAndUngroup = (groupMarker) => {
      const groupMarkerData = groupMarker.getData();

      // Recreate the points (it will ungroup the ones marked as ungrouped) and
      // draw it again
      this.groups.ungroup(groupMarkerData.id);

      // Remove the group marker
      this.clearMapMarkers(`groupMarker:${groupMarkerData.id}`);

      // Zoom on the ungrouped locations
      this.zoomLocations(groupMarkerData.locations);

      // H2-1162
      // Re-generate everything, because of a race condition happening.
      // On HERE Maps, the click event for the marker runs first,
      // which used to call this.drawGroups(newUngrouped); (newUngrouped was
      // returned from ungroupMarkers) then mapviewchangeend event for the map
      // runs second, which calls generateAndRedrawGroups.
      // On Google Maps, the opposite happens, which causes the markers not to
      // group properly. I needed them both to function similarly (with
      // generateAndRedrawGroups always running) so that the race condition
      // didn't matter.
      this.generateAndRedrawGroups();
    };

    const plusOneZoomAndGenerateNewGroups = (groupMarker) => {
      const groupMarkerData = groupMarker.getData();
      this.setMapCenter(groupMarkerData.position);
      this.currentZoom++;
      this.setMapZoom(this.currentZoom);
      this.generateAndRedrawGroups();
    };

    if (mapLocations) {
      if (
        marker.getData().locations.length <=
        GROUP_MAX_LOCATIONS_TO_OPEN_INDIVIDUALLY
      ) {
        zoomAndUngroup(marker);
      } else {
        plusOneZoomAndGenerateNewGroups(marker);
      }
    }
  }

  /**
   * Anytime the map view is changed, we check if the zoom changes, if the zoom
   * changes we will eventually need to update the groups into the map.
   */
  onMapViewChange() {
    const { setViewCentered } = this.props;

    const zoom = this.getMapZoom();
    const isZoomChanging = zoom !== this.currentZoom;
    const isZoomDecreasing =
      this.currentZoom !== undefined && zoom < this.currentZoom;

    // If zoom changed, we need to compute groupings again, clear the other
    // ones and redraw
    if (isZoomChanging) {
      // If you are zooming out, we will group again the markers, to have a
      // behavior similar to google marker clustering:
      // https://developers.google.com/maps/documentation/javascript/
      // marker-clustering
      if (isZoomDecreasing) {
        this.allowRegroupOfUngroupedMarkers();
      }
      this.generateAndRedrawGroups();
    } else {
      // If zoom does not change, we do not need to compute groups again, we
      // just need to draw the other ones that where not showed on the map
      // before, because of a possible change on view bounds
      this.clearDrawnGroups();
      this.drawGroups(this.groups);
    }
    this.currentZoom = zoom;
    this._onMapViewChangeBubbleEvent();
    if (setViewCentered) {
      setViewCentered(false);
    }
  }

  /**
   * Bubbles the onMapViewChange event to "clients" of this component.
   */
  _onMapViewChangeBubbleEvent() {
    const { onMapViewChange = _.noop } = this.props;
    onMapViewChange(this.getVisibleLocations());
  }

  /**
   * Mouse Enter on any marker will hit this method. We need to replace this
   * from SimpleMap because we need to also handle data coming from group
   * markers.
   */
  onMarkerMouseEnter(event, googleMapsMarker) {
    const marker = googleMapsMarker ? googleMapsMarker : event.target;
    const markerData = marker.getData();

    const { onMarkerMouseEnter = _.noop } = this.props;
    const isGroupMarker = markerData.locations;
    const isSingleMarker = !isGroupMarker && !_.isArray(markerData);

    // Enforce to ever be a list of locations, even when there is only one.
    // Just to have a note there are three cases here:
    // 1) Group markers: locations are in the "locations" property
    // 2) Single marker: markerData is an object and need to be transformed to
    //    an array;
    // 3) Single marker which represents more than one locations: marker data
    //    is already an array of locations.
    if (isGroupMarker) {
      onMarkerMouseEnter(markerData.locations);
    } else if (isSingleMarker) {
      onMarkerMouseEnter([markerData]);
    } else {
      onMarkerMouseEnter(markerData);
    }
  }

  // Drawing

  /**
   * Add a single marker to the map
   */
  addLocationMarker(position, markerId, label = "", data = null) {
    const id = `marker:${markerId}`;

    // Check if it is already added
    if (this.getMapObject(id)) {
      return;
    }

    const defaultHeight = 64;
    const defaultWidth = 64;

    let svg = PinChicletSVG({
      codeLetter: label,
      backgroundColor: Colors.holds.LIGHT_GREEN,
      borderColor: Colors.holds.LIGHT_GREEN,
      height: defaultHeight,
      width: defaultWidth,
    });

    const marker = this.createAndAddMapMarker(
      id,
      position,
      LOCATION_MARKER_ZINDEX,
      data,
      true,
      svg,
      40,
      40,
      defaultHeight,
      defaultWidth,
      30,
      50,
    );

    this.addMapMarkerEventListener(marker, "click", this.onMarkerLocationClick);
    this._addMouseEnterOutForMarker(marker);
    return marker;
  }

  /**
   * Just add two events, one for enter other for out in a marker.
   */
  _addMouseEnterOutForMarker(marker) {
    this.addMapMarkerEventListener(
      marker,
      "mouseenter",
      this.onMarkerMouseEnter,
    );
    this.addMapMarkerEventListener(marker, "mouseout", this.onMarkerMouseOut);
  }

  /**
   * The counting of locations can only be generated for all groups without any
   * filters, that is, using all locations. If we do not do this, colors will
   * change when zooming or moving the map aside.
   *
   * @return {Array} Return an array with all locations count ordered from the
   * smallest to largest.
   */
  _generateGroupsOrderedLocationsCount = (locations, currentZoom) => {
    const cachedGroupsLocationsCount =
      this._groupsOrderedLocationsCountByZoomLevelCache[currentZoom];

    if (cachedGroupsLocationsCount) {
      return cachedGroupsLocationsCount;
    }

    const groups = this.computeMarkersGroupings(locations);
    const groupsLocationsCount = groups
      .values()
      .map((group) => group.locations.length)
      .sort((a, b) => a - b);
    this._groupsOrderedLocationsCountByZoomLevelCache[currentZoom] =
      groupsLocationsCount;

    return groupsLocationsCount;
  };

  /**
   * The color of a group is defined based on the quantity of elements it
   * hosts. The approach here is to use the total locations in each group and
   * define colors relatively to the percentile each value is in.
   */
  _getGroupColor(groupQuantity) {
    const colorsScale = [
      Colors.maps.DARK_BLUE, // 20 percentile
      Colors.maps.LIGHT_BLUE, // 40 percentile
      Colors.maps.LIGHT_ORANGE, // 60 percentile
      Colors.maps.DARK_ORANGE, // 80 percentile
      Colors.maps.LIGHT_RED, // 100 percentile
    ];

    const groupsLocationsCount = this._generateGroupsOrderedLocationsCount(
      this.state.mapLocations,
      this.getMapZoom(),
    );

    const getGroupPercentile = (value) => {
      return (
        (groupsLocationsCount.indexOf(value) / groupsLocationsCount.length) *
        100
      );
    };

    const fromPercentileToColorIndex = (percentile) => {
      if (percentile <= 20) {
        return 0;
      } else if (percentile <= 40) {
        return 1;
      } else if (percentile <= 60) {
        return 2;
      } else if (percentile <= 80) {
        return 3;
      } else {
        return 4;
      }
    };

    const percentile = getGroupPercentile(groupQuantity);
    const colorIndex = fromPercentileToColorIndex(percentile);
    return colorsScale[colorIndex];
  }

  /**
   * Add a group of markers into the map, that is, each group of this
   * represents several markers nearby that are put on the map together
   */
  addGroupMarker(id, position, count, width = 50, height = 50) {
    const color = this._getGroupColor(count);

    const radius = 50;
    const defaultHeight = radius * 4;
    const defaultWidth = radius * 4;

    let svg = CircleChicletSVG({
      text: count,
      backgroundColor: color,
      borderColor: color,
      shadow: true,
      radius: radius,
    });

    const marker = this.createAndAddMapMarker(
      `groupMarker:${id}`,
      position,
      GROUP_MARKER_ZINDEX,
      this.groups.get(id),
      true,
      svg,
      height,
      width,
      defaultHeight,
      defaultWidth,
      100,
      100,
    );

    this.addMapMarkerEventListener(marker, "click", this.onMarkerGroupClick);
    this._addMouseEnterOutForMarker(marker);
    return marker;
  }

  /**
   * Generate and add groups to the map. Only the groups in the visible are of
   * the map will be created.
   */
  generateAndRedrawGroups() {
    this.groups = this.computeMarkersGroupings(this.getVisibleLocations());
    this.clearDrawnGroups();
    this.clearDrawnLocations();
    this.drawGroups(this.groups);
  }

  /**
   * Clear all group markers
   */
  clearDrawnGroups() {
    this.clearMapMarkers("groupMarker:");
  }

  clearDrawnLocations() {
    this.clearMapMarkers("marker:");
  }

  /**
   * Check if there are some changes in the groups (for example, zooming can
   * change this) and redraw the groups from these changes.
   *
   * XXX: For performance reasons it only draw groups that are inside the view
   * bounds, that is, groups that is visible to the user.
   */
  drawGroups(groups) {
    const locationMarkerWithNumber = (group) => {
      return this.addLocationMarker(
        group.locations[0].position,
        group.locations
          .map((item) => item.id)
          .sort()
          .join(""),
        group.locations.length,
        group.locations,
      );
    };

    const singleLocationMarker = (group) => {
      const location = group.locations[0];
      return this.addLocationMarker(
        location.position,
        location.id,
        "",
        location,
      );
    };

    for (let key of groups.keys()) {
      const group = groups.get(key);

      if (
        !this.isMapViewBoundsContainLatLng(
          group.position.lat,
          group.position.lng,
        )
      ) {
        continue;
      }

      if (group.sameLocationGroup) {
        group.marker = locationMarkerWithNumber(group);
      } else if (group.locations.length === 1) {
        group.marker = singleLocationMarker(group);
      } else {
        group.marker = this.addGroupMarker(
          group.id,
          group.position,
          group.locations.length,
        );
      }
    }
  }

  recenterMap() {
    const { mapLocations } = this.props;
    if (mapLocations.length) {
      const pointsConsideredToImproveMapBoundsZoom = [];
      if (_.isEmpty(mapLocations)) {
        return;
      }

      mapLocations.forEach((location) => {
        pointsConsideredToImproveMapBoundsZoom.push({
          longitude: location.longitude,
          latitude: location.latitude,
        });
      });

      if (_.isEmpty(pointsConsideredToImproveMapBoundsZoom)) {
        return;
      }

      // If there is only one point, use the traditional single location zoom
      if (pointsConsideredToImproveMapBoundsZoom.length === 1) {
        this.zoomSingleLocation(mapLocations[0]);
      } else {
        this.zoomLocations(pointsConsideredToImproveMapBoundsZoom);
      }
    }
  }

  // Popups

  /**
   * On clumpingmap popups are assigned to groups. We just find the group which
   * the location pertains to and call its popup.
   *
   * @param {Object} location
   */
  showPopupForLocation(location) {
    const locationGroup = this.groups.getGroupByLocation(location);
    this.showPopupForGroup(locationGroup, location);
    // We ungroup here to assure that, when showing the popup, group will be
    // shown with ungrouped markers
    this.groups.ungroup(locationGroup.id);
  }

  /**
   * Renders popup for a group, depending on the quantity of locations it has.
   *
   * @param {Object} group A group (coming from the MapGroups object)
   * @param {Object} selectedLocation Location that should be presented as
   * selected on the popup.
   */
  showPopupForGroup(group, selectedLocation) {
    const singleLocationClickHandler = (data) => {
      this.createAndAddMapInfoBubble(
        data.position,
        this._getInfoPopupSingleLocationDOMObject(data),
      );
    };

    const multipleLocationsClickHandler = (data) => {
      this.createAndAddMapInfoBubble(
        data[0].position,
        this._getInfoPopupMultipleLocationsDOMObject(data, selectedLocation),
      );
    };
    if (group.locations.length === 1) {
      singleLocationClickHandler(group.locations[0]);
    } else {
      multipleLocationsClickHandler(group.locations);
    }
  }

  /**
   * Renders a single location popup in HTML.
   *
   * @param {Object} location   A location (coming from the mapLocations props
   * list).
   * @return {DOM Object} Return a DOM object with rendered HTML based on the
   * location.
   */
  _getInfoPopupSingleLocationDOMObject(location) {
    const { t } = this.props;
    // HERE maps requires a string or HTML node, that's the reason behind the
    // rendering here
    let tempdiv = document.createElement("div");
    const temp = createRoot(tempdiv);
    temp.render(
      <this.props.popupComponent
        locations={[location]}
        handler={this.props.popupClickHandler}
        t={t}
      />,
    );
    return tempdiv;
  }

  /**
   * Renders a multiple locations popup in HTML.
   *
   * @param {Array} locations   A list of locations (coming from the
   * mapLocations props list) you want to show on the popup together.
   * @return {DOM Object} Return a DOM object with rendered HTML based on the
   * locations list.
   */
  _getInfoPopupMultipleLocationsDOMObject(locations, selectedLocation = null) {
    const { t, onPopupChangePage } = this.props;
    // HERE maps requires a string or HTML node, that's the reason behind the
    // rendering here
    let tempdiv = document.createElement("div");
    const temp = createRoot(tempdiv);
    temp.render(
      <this.props.popupComponent
        locations={locations}
        selectedLocation={selectedLocation}
        handler={this.props.popupClickHandler}
        onPopupChangePage={onPopupChangePage}
        t={t}
      />,
    );
    return tempdiv;
  }

  // Grouping/Ungrouping

  /**
   * Allow markers that were ungrouped to be grouped again
   */
  allowRegroupOfUngroupedMarkers() {
    for (const groupId of this.groups.keys()) {
      const group = this.groups.get(groupId);
      if (group.singleElementGroup) {
        // Remove mandatory limitation for a single element from the group
        group.singleElementGroup = false;
        // Remove mandatory ungrouped info from the location
        group.locations.map((item) => (item.ungrouped = false));
      }
    }
  }

  /**
   * Use distance between locations to compute the way they should be
   * aggregated into groups.
   *
   * @return {Object} Computed groups.
   */
  computeMarkersGroupings(locations) {
    const groups = new MapGroups(this.getMapZoom());

    locations.forEach((location) => {
      if (location.ungrouped === true) {
        groups.addUngroupedLocation(location);
      } else {
        if (!groups.addLocationToExistentGroup(location)) {
          groups.addNewGroupWithOneLocation(location);
        }
      }
    });
    groups.groupUngroupedSameLocationInTheSameGroup();
    // This is important because it will assure that our future zooms will come
    // in the center of the group locations and, naturally, all of them will
    // be shown
    groups.positionGroupsInTheCenterOfItsLocations();
    return groups;
  }
}

ClumpingMap.propTypes = {
  mapLocations: PropTypes.array,
  popupComponent: PropTypes.elementType,
  popupClickHandler: PropTypes.func,
  onMapViewChange: PropTypes.func,
  onMarkerMouseEnter: PropTypes.func,
  onMarkerMouseOut: PropTypes.func,
  onPopupChangePage: PropTypes.func,
  uiButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      clickCallback: PropTypes.func,
    }),
  ),
  setViewCentered: PropTypes.func,
  isViewCentered: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    isViewCentered: MapState.selectors.getIsViewCentered(state),
    ...state.maps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setViewCentered: (isCentered) =>
      dispatch(MapState.actionCreators.setViewCentered(isCentered)),
  };
}

const sizeMeHOC = withResizeDetector()(ClumpingMap);
const ClumpingMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(sizeMeHOC);
export default ClumpingMapContainer;
