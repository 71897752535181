import axios from "axios";
import apiUrl from "api-url";

const STORE_MOUNT_POINT = "surgicalToteTrackingCountsWidget";

//URLs
const BASE_URL = apiUrl("/containertracking/api/reuse-trip-container-totals");

//Action Types
const REQUEST_TOTE_TOTALS = "REQUEST_TOTE_TOTALS";
const RECEIVE_TOTE_TOTALS = "RECEIVE_TOTE_TOTALS";

//Action Creators
const fetchTotesTotals = () => {
  const url = BASE_URL;
  return async (dispatch) => {
    dispatch({ type: REQUEST_TOTE_TOTALS });
    try {
      const response = await axios.get(url);
      dispatch({
        type: RECEIVE_TOTE_TOTALS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

//Selectors
const getTotesTotals = (state) => state[STORE_MOUNT_POINT].toteTotals;
const getTotesTotalsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isTotesTotalsLoading;

//Intial State
const initialState = {
  toteTotals: {},
  isTotesTotalsLoading: false,
};

//Reducer
const SurgicalToteTrackingCountsWidgetReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_TOTE_TOTALS:
      return {
        ...state,
        isTotesTotalsLoading: true,
      };

    case RECEIVE_TOTE_TOTALS:
      return {
        ...state,
        toteTotals: action.payload,
        isTotesTotalsLoading: false,
      };

    default:
      return state;
  }
};

//interface
export const SurgicalToteTrackingCountsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchTotesTotals,
  },
  selectors: {
    getTotesTotals,
    getTotesTotalsLoadingFlag,
  },
  reducer: SurgicalToteTrackingCountsWidgetReducer,
};
