// wrap the given actionCreator in dispatch
export const dispatchify =
  (dispatch) =>
  (actionCreator) =>
  (...args) =>
    dispatch(actionCreator.apply(null, args));

// given a list of actionCreators, call dispatchify() on each of them and return
// an object mapping the action creator's name to the dispatchified version
export const dispatchifyMany =
  (dispatch) =>
  (...actionCreators) =>
    Object.fromEntries(
      actionCreators.map((ac) => [ac.name, dispatchify(dispatch)(ac)]),
    );

export const redirectToRoute = (type) => {
  return { type: type };
};
