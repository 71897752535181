import axios from "axios";
import apiUrl from "../../api-url";

// URLS
const STORE_MOUNT_POINT = "fvEntities";

// Actions

const FVE = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const REQUEST_ENTITY_COUNT = FVE("REQUEST_ENTITY_COUNT");
const RECEIVE_ENTITY_COUNT = FVE("RECEIVE_ENTITY_COUNT");
const REQUEST_DELIVERED_ENTITY_COUNT = FVE("REQUEST_DELIVERED_ENTITY_COUNT");
const RECEIVE_DELIVERED_ENTITY_COUNT = FVE("RECEIVE_DELIVERED_ENTITY_COUNT");
const REQUEST_WATCHED_ENTITIES = FVE("REQUEST_WATCHED_ENTITIES");
const RECEIVE_WATCHED_ENTITIES = FVE("RECEIVE_WATCHED_ENTITIES");
const RECEIVE_WATCHED_ENTITIES_ERROR = FVE("RECEIVE_WATCHED_ENTITIES_ERROR");
const REQUEST_WATCHED_ENTITIES_COUNT = FVE("REQUEST_WATCHED_ENTITIES_COUNT");
const RECEIVE_WATCHED_ENTITIES_COUNT = FVE("RECEIVE_WATCHED_ENTITIES_COUNT");
const RECEIVE_WATCHED_ENTITIES_COUNT_ERROR = FVE(
  "RECEIVE_WATCHED_ENTITIES_COUNT_ERROR",
);
const SET_WATCHED_ENTITIES_PAGE_INDEX = FVE("SET_WATCHED_ENTITIES_PAGE_INDEX");

// Action creators
function fetchEntityCount(solutionId) {
  const countUrl = apiUrl(
    `/entity/solution/${solutionId}/entity?lifeCycleState=Active,Prebuilt`,
  );

  const headers = { headers: { Accept: "application/json;version=count" } };

  return (dispatch) => {
    dispatch({
      type: REQUEST_ENTITY_COUNT,
    });

    return axios
      .get(`${countUrl}`, headers)
      .then((response) => {
        dispatch({
          type: RECEIVE_ENTITY_COUNT,
          payload: response.data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchWatchedVins(solutionId) {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_WATCHED_ENTITIES,
    });

    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
    const pageNumber = state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;

    const params = { watched: 1, pageSize, pageNumber };
    const url = apiUrl(`/entity/solution/${solutionId}/entity-watch`);

    try {
      const response = await axios.get(url, { params });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES,
        payload: { watchedEntities: response.data.data },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_ERROR, error });
    }
  };
}

function fetchWatchedEntitiesTotalPages(solutionId) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_WATCHED_ENTITIES_COUNT });

    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;

    const params = { watched: 1, pageSize, pageNumber: 0 };
    const headers = { Accept: "application/json;version=count" };
    const url = apiUrl(`/entity/solution/${solutionId}/entity`);

    try {
      const response = await axios.get(url, { params, headers });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES_COUNT,
        payload: { totalPages: response.data?.meta?.totalPages },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_COUNT_ERROR, error });
    }
  };
}

function setWatchedVinsPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_ENTITIES_PAGE_INDEX,
    payload: { pageIndex },
  };
}

function fetchEntityDeliveredCount(solutionId) {
  const countUrl = apiUrl(
    `/entity/solution/${solutionId}/entity?lifeCycleState=Delivered`,
  );

  const headers = { headers: { Accept: "application/json;version=count" } };

  return (dispatch) => {
    dispatch({
      type: REQUEST_DELIVERED_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, headers)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_DELIVERED_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Asynchronously return location search results for <Async> select component
function searchVINs(query, solutionId) {
  if (query.length < 2) {
    return {
      options: [],
      hasMore: false,
    };
  }

  const url = apiUrl(
    `/entity/solution/${solutionId}/entity?entityId=${query}&pageNumber=0&pageSize=20&lifeCycleState=Active,Delivered&watched=1`,
  );

  return axios
    .get(url)
    .then((response) => {
      if (
        !response ||
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        return { options: [], hasMore: false };
      } else {
        const vinOptions = buildSearchVinOptions(response.data.data);
        return {
          options: vinOptions,
          hasMore: false,
        };
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

// Convert location search response data into options for <Async> select and typeahead components
export function buildSearchVinOptions(data) {
  return data.map((l) => {
    try {
      return {
        value: l.id,
        label: l.id,
        id: l.id,
      };
    } catch (e) {
      console.error(e);
      return { options: [], hasMore: false };
    }
  });
}

// Selectors
const getEntityCount = (state) => state[STORE_MOUNT_POINT].entityCount || 0;
const getEntityDeliveredCount = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCount || 0;
const getEntityCountLoading = (state) =>
  state[STORE_MOUNT_POINT].entityCountLoading;
const getEntityCountDeliveredLoading = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCountLoading;

const getWatchedVins = (state) => state[STORE_MOUNT_POINT].watchedEntities;
const getWatchedVinsLoading = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesLoading ||
  state[STORE_MOUNT_POINT].watchedEntitiesCountLoading;
const getWatchedVinsPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;
const getWatchedVinsPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
const getWatchedVinsPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageCount;

// Initial state
const initialState = {
  entityCount: 0,
  entityCountLoading: false,
  watchedEntities: [],
  watchedEntitiesLoading: false,
  watchedEntitiesCountLoading: false,
  watchedEntitiesPageIndex: 0,
  watchedEntitiesPageSize: 10,
  watchedEntitiesPageCount: 0,
  entityDeliveredCount: 0,
  entityDeliveredCountLoading: false,
};

const FinVehicleEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_COUNT:
      return {
        ...state,
        entityCountLoading: true,
      };

    case RECEIVE_ENTITY_COUNT:
      return {
        ...state,
        entityCount: action.payload,
        entityCountLoading: false,
      };
    case REQUEST_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCountLoading: true,
      };

    case RECEIVE_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCount: action.payload,
        entityDeliveredCountLoading: false,
      };

    case REQUEST_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntitiesLoading: true,
      };

    case REQUEST_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesCountLoading: true,
      };

    case RECEIVE_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntities: action.payload.watchedEntities,
        watchedEntitiesLoading: false,
      };

    case RECEIVE_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesPageCount: action.payload.totalPages,
        watchedEntitiesCountLoading: false,
      };

    case SET_WATCHED_ENTITIES_PAGE_INDEX:
      return {
        ...state,
        watchedEntitiesPageIndex: action.payload.pageIndex,
      };

    default:
      return state;
  }
};

// interface
const FinVehicleEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityCount,
    fetchEntityDeliveredCount,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    setWatchedVinsPageIndex,
    searchVINs,
  },
  selectors: {
    getEntityCount,
    getEntityDeliveredCount,
    getEntityCountLoading,
    getEntityCountDeliveredLoading,
    getWatchedVins,
    getWatchedVinsLoading,
    getWatchedVinsPageIndex,
    getWatchedVinsPageSize,
    getWatchedVinsPageCount,
  },
  reducer: FinVehicleEntityReducer,
};
export default FinVehicleEntitiesState;
