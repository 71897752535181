import { connect } from "react-redux";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import DomainDataState from "modules/domain-data/DomainDataState";
import EditShipmentState from "modules/shipment-detail/EditShipmentState";
import { ShipmentETAOverride } from "./ShipmentETAOverride.page";

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    carriers: DomainDataState.selectors.selectCarriersAlphabetically(state),
    carriersData: DomainDataState.selectors.getCarriersData(state),
    // Fetch report delay options
    delayOptionsData: EditShipmentState.selectors.getDelayOptionsData(state),
    // Report Carrier Delay
    reportDelayData: EditShipmentState.selectors.getReportDelayData(state),
  };
}

const { fetchDelayOptions, reportDelay, clearReportDelayInState } =
  EditShipmentState.actionCreators;

const actionCreators = {
  fetchDelayOptions,
  reportDelay,
  clearReportDelayInState,
};

export default connect(mapStateToProps, actionCreators)(ShipmentETAOverride);
