import PropTypes from "prop-types";
import _ from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const LobsType = {
  AFTERMARKET_PARTS: "Aftermarket Parts",
  ASSEMBLY: "Assembly",
  FINISHED_PRODUCT: "Finished Product",
  UNCLASSIFIED: "Unclassified",
};

//Lad Names and Lads Default Names
export const Lads = {
  AIRPORT: "Airport",
  ASSEMBLY: "Assembly",
  ASSEMBLY_PLANT: "Assembly Plant",
  BAILMENT: "Bailment",
  BORDER_CROSSING: "Border Crossing",
  CARRIER_FACILITY: "Carrier Facility",
  CARRIER_TERMINAL: "Carrier Terminal",
  COMPONENTS_PLANT: "Components Plant",
  CONSOLIDATION: "Consolidation",
  CONSOLIDATION_CENTER: "Consolidation Center",
  CROSS_DOCK: "Cross-dock",
  CUSTOMER_PLANT: "Customer Plant",
  DEALER: "Dealer",
  DISTRIBUTION: "Distribution",
  DISTRIBUTION_CENTER: "Distribution Center (RDC/PDC/Cross-Dock)",
  EXPORT_CONSOLIDATION: "Export Consolidation",
  FINAL_DESTINATION: "Final Destination",
  FLEET_CUSTOMER: "Fleet Customer",
  FULLFILLMENT_CENTER: "Fullfillment Center",
  IMPORT_DECONSOLIDATION: "Import Deconsolidation",
  LOGISTICS_CENTER: "Logistics Center",
  LOGISTICS_OPTIMIZATION_CENTER: "Logistics Optimization Center(LOC)",
  MASTER_CROSS_DOCK: "Master Cross Dock",
  MINE_SITE: "Mine Site",
  OCEAN_PORT: "Ocean Port",
  OFFSITE_OVERFLOW_FACILITY: "Offsite Overflow Facility",
  ORIGIN_DISTRIBUTION_CENTER: "Origin Distribution Center(ODC)",
  PARKING_LOCATION: "Parking Location",
  PARTS_CROSS_DOCK: "Parts Cross Dock(PDX)",
  PORT_OF_ENTRY: "Port of Entry",
  PORT_PROCESSOR: "Port Processor",
  PROCESSING_CENTER: "Processing Center",
  PROCESSOR: "Processor",
  RAIL_INTERCHANGE: "Rail Interchange",
  RAIL_JIT_CENTER: "Rail JIT Center",
  RAIL_STATION: "Rail Station",
  RESELLER: "Reseller",
  SHIPPING_YARD: "Shipping Yard",
  SUPPLIER: "Supplier",
  TESTING_FACILITY: "Testing Facility",
  TRAILER_YARD: "Trailer Yard",
  UNCLASSIFIED: "Unclassified",
  UPFITTER: "Upfitter",
  UPFITTER_FACILITY: "Upfitter Facility",
  VALUE_ADDED_ASSEMBLY: "Value Added Assembly(VAA)",
  VALUE_ADD_SERVICE: "Value-Add Service",
  VEHICLE_DISTRIBUTION_CENTER: "Vehicle Distribution Center(VDC)",
  WAREHOUSE: "Warehouse",
  YARD: "Yard",
};

export const useLadsTranslation = () => {
  const { t } = useTranslation("lads");

  const getTranslatedLobName = useCallback(
    (name: string) => {
      switch (name) {
        case LobsType.AFTERMARKET_PARTS:
          return t("lads:Aftermarket Parts");
        case LobsType.ASSEMBLY:
          return t("lads:Assembly");
        case LobsType.FINISHED_PRODUCT:
          return t("lads:Finished Product");
        case LobsType.UNCLASSIFIED:
          return t("lads:Unclassified");
        default:
          return name;
      }
    },
    [t],
  );

  const getTranslatedLadName = useCallback(
    (name: string) => {
      switch (name) {
        case Lads.AIRPORT:
          return t("lads:Airport");
        case Lads.ASSEMBLY:
          return t("lads:Assembly");
        case Lads.ASSEMBLY_PLANT:
          return t("lads:Assembly Plant");
        case Lads.BAILMENT:
          return t("lads:Bailment");
        case Lads.BORDER_CROSSING:
          return t("lads:Border Crossing");
        case Lads.CARRIER_FACILITY:
          return t("lads:Carrier Facility");
        case Lads.CARRIER_TERMINAL:
          return t("lads:Carrier Terminal");
        case Lads.COMPONENTS_PLANT:
          return t("lads:Components Plant");
        case Lads.CONSOLIDATION:
          return t("lads:Consolidation");
        case Lads.CONSOLIDATION_CENTER:
          return t("lads:Consolidation Center");
        case Lads.CROSS_DOCK:
          return t("lads:Cross-dock");
        case Lads.CUSTOMER_PLANT:
          return t("lads:Customer Plant");
        case Lads.DEALER:
          return t("lads:Dealer");
        case Lads.DISTRIBUTION:
          return t("lads:Distribution");
        case Lads.DISTRIBUTION_CENTER:
          return t("lads:Distribution Center (RDC/PDC/Cross-Dock)");
        case Lads.EXPORT_CONSOLIDATION:
          return t("lads:Export Consolidation");
        case Lads.FINAL_DESTINATION:
          return t("lads:Final Destination");
        case Lads.FLEET_CUSTOMER:
          return t("lads:Fleet Customer");
        case Lads.FULLFILLMENT_CENTER:
          return t("lads:Fullfillment Center");
        case Lads.IMPORT_DECONSOLIDATION:
          return t("lads:Import Deconsolidation");
        case Lads.LOGISTICS_CENTER:
          return t("lads:Logistics Center");
        case Lads.LOGISTICS_OPTIMIZATION_CENTER:
          return t("lads:Logistics Optimization Center(LOC)");
        case Lads.MASTER_CROSS_DOCK:
          return t("lads:Master Cross Dock");
        case Lads.MINE_SITE:
          return t("lads:Mine Site");
        case Lads.OCEAN_PORT:
          return t("lads:Ocean Port");
        case Lads.OFFSITE_OVERFLOW_FACILITY:
          return t("lads:Offsite Overflow Facility");
        case Lads.ORIGIN_DISTRIBUTION_CENTER:
          return t("lads:Origin Distribution Center(ODC)");
        case Lads.PARKING_LOCATION:
          return t("lads:Parking Location");
        case Lads.PARTS_CROSS_DOCK:
          return t("lads:Parts Cross Dock(PDX)");
        case Lads.PORT_OF_ENTRY:
          return t("lads:Port of Entry");
        case Lads.PORT_PROCESSOR:
          return t("lads:Port Processor");
        case Lads.PROCESSING_CENTER:
          return t("lads:Processing Center");
        case Lads.PROCESSOR:
          return t("lads:Processor");
        case Lads.RAIL_INTERCHANGE:
          return t("lads:Rail Interchange");
        case Lads.RAIL_JIT_CENTER:
          return t("lads:Rail JIT Center");
        case Lads.RAIL_STATION:
          return t("lads:Rail Station");
        case Lads.RESELLER:
          return t("lads:Reseller");
        case Lads.SHIPPING_YARD:
          return t("lads:Shipping Yard");
        case Lads.SUPPLIER:
          return t("lads:Supplier");
        case Lads.TESTING_FACILITY:
          return t("lads:Testing Facility");
        case Lads.TRAILER_YARD:
          return t("lads:Trailer Yard");
        case Lads.UNCLASSIFIED:
          return t("lads:Unclassified");
        case Lads.UPFITTER:
          return t("lads:Upfitter");
        case Lads.UPFITTER_FACILITY:
          return t("lads:Upfitter Facility");
        case Lads.VALUE_ADDED_ASSEMBLY:
          return t("lads:Value Added Assembly(VAA)");
        case Lads.VALUE_ADD_SERVICE:
          return t("lads:Value-Add Service");
        case Lads.VEHICLE_DISTRIBUTION_CENTER:
          return t("lads:Vehicle Distribution Center(VDC)");
        case Lads.WAREHOUSE:
          return t("lads:Warehouse");
        case Lads.YARD:
          return t("lads:Yard");
        default:
          return name;
      }
    },
    [t],
  );

  const getTranslatedLadLobLabel = useCallback(
    (lad: Lad) => {
      if (_.isEmpty(lad)) {
        return getTranslatedLobName(LobsType.UNCLASSIFIED);
      }
      return `${getTranslatedLadName(getLadName(lad))} (${getTranslatedLobName(
        lad.lob_name,
      )})`;
    },
    [getTranslatedLadName, getTranslatedLobName],
  );

  const getTranslatedLadDefinition = useCallback(
    (name: string, description: string) => {
      switch (name) {
        case Lads.AIRPORT:
          return t("lads:Airport facility");
        case Lads.ASSEMBLY:
        case Lads.ASSEMBLY_PLANT:
        case Lads.COMPONENTS_PLANT:
        case Lads.CUSTOMER_PLANT:
          return t("lads:A customer manufacturing or assembly facility");
        case Lads.BAILMENT:
          return t("lads:Bailment and Converted Pool Yard locations");
        case Lads.BORDER_CROSSING:
          return t(
            "lads:Land border crossing points between countries where goods pass through import or export customs",
          );
        case Lads.CARRIER_TERMINAL:
          return t(
            "lads:Carrier-operated facility such as truck terminals and rail interchanges",
          );
        case Lads.CONSOLIDATION:
        case Lads.CONSOLIDATION_CENTER:
        case Lads.EXPORT_CONSOLIDATION:
        case Lads.MASTER_CROSS_DOCK:
        case Lads.ORIGIN_DISTRIBUTION_CENTER:
          return t(
            "lads:A facility where product from multiple sources is received and consolidated for further shipment",
          );
        case Lads.CROSS_DOCK:
        case Lads.PARTS_CROSS_DOCK:
        case Lads.RAIL_JIT_CENTER:
          return t(
            "lads:A facility where materials from an incoming truck or railroad car are loaded directly into outbound trucks or rail cars, with little or no interim storage",
          );
        case Lads.DEALER:
        case Lads.FLEET_CUSTOMER:
        case Lads.RESELLER:
          return t("lads:The endpoint for the shipment of finished products");
        case Lads.DISTRIBUTION:
        case Lads.DISTRIBUTION_CENTER:
        case Lads.FULLFILLMENT_CENTER:
        case Lads.IMPORT_DECONSOLIDATION:
        case Lads.VEHICLE_DISTRIBUTION_CENTER:
          return t(
            "lads:A facility that receives product from multiple sources staged for further shipment such as consolidation centers, distribution centers, JIT centers, warehouses, and cross-docks",
          );
        case Lads.LOGISTICS_CENTER:
        case Lads.LOGISTICS_OPTIMIZATION_CENTER:
        case Lads.VALUE_ADDED_ASSEMBLY:
          return t(
            "lads:A facility typically located in the vicinity of a customer's manufacturing facility that provides services such as line sequencing, sub-assembly, repacking, etc to support production at the plant",
          );
        case Lads.MINE_SITE:
          return t("lads:A location for the extraction of minerals");
        case Lads.OCEAN_PORT:
        case Lads.PORT_OF_ENTRY:
          return t(
            "lads:Air or ocean ports where products are imported or exported",
          );
        case Lads.OFFSITE_OVERFLOW_FACILITY:
        case Lads.SHIPPING_YARD:
        case Lads.TRAILER_YARD:
        case Lads.YARD:
          return t(
            "lads:Open secured areas used for staging loaded trailers, containers, or vehicles for further handling such as intermodal yards, rail yards, trailer switching yards, and container yards",
          );
        case Lads.PARKING_LOCATION:
          return t("lads:Parking Location");
        case Lads.PROCESSING_CENTER:
        case Lads.PROCESSOR:
        case Lads.PORT_PROCESSOR:
        case Lads.UPFITTER_FACILITY:
        case Lads.VALUE_ADD_SERVICE:
          return t(
            "lads:A facility that supports services such as vehicle processing, upfitting, container management, or plant services such as line sequencing, sub-assembly, and repacking in support of plant production",
          );
        case Lads.RAIL_INTERCHANGE:
        case Lads.RAIL_STATION:
          return t(
            "lads:A point in rail routes where railcars are switched from one rail line to another",
          );
        case Lads.SUPPLIER:
          return t(
            "lads:A location that ships parts or materials to customer facilities",
          );
        case Lads.TESTING_FACILITY:
          return t("lads:Diagnostic and Emissions Testing of a VIN");
        case Lads.UNCLASSIFIED:
          return t("lads:A facility that has not been classified");
        case Lads.UPFITTER:
          return t(
            "lads:An upfitter is where conversions are made to a vehicle. A conversion is a set of features added to a vehicle which are intended for a specific use, based on the customer's needs",
          );
        case Lads.WAREHOUSE:
          return t("lads:A facility for storage of excess product inventory");
        default:
          return description.replace(".", "");
      }
    },
    [t],
  );

  return {
    getTranslatedLobName,
    getTranslatedLadName,
    getTranslatedLadLobLabel,
    getTranslatedLadDefinition,
  };
};

export const getLadName = (lad: Lad) =>
  lad.lad_name || lad.name || lad.default_name;

interface Lad {
  code: string;
  default_name: string;
  id: number | string;
  lad_name: string;
  lob_name: string;
  name: string;
  description: string;
}

export const LadShape = {
  code: PropTypes.string.isRequired,
  default_name: PropTypes.string,
  lad_name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lob_name: PropTypes.string,
  description: PropTypes.string.isRequired,
};
