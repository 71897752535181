/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal } from "components/molecules/Modal.molecule";

import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import _ from "lodash";

export const MilestoneReferencesButton = ({ statusUpdate, comments }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const milestoneReferencesColumns = useMemo(() => {
    return [
      {
        Header: t("fv-vin-details:Name"),
        accessor: "qualifier",
        width: 100,
        Cell: (cellInfo) => <Text>{cellInfo.row.original.qualifier}</Text>,
      },
      {
        Header: t("fv-vin-details:Value"),
        accessor: "reference",
        Cell: (cellInfo) => {
          if (
            ["ship to", "ship from"].includes(
              cellInfo.row.original.qualifier.toLowerCase(),
            )
          ) {
            const reference = cellInfo.row.original.reference;
            return (
              <LocationAddressComplex
                name={{
                  value: reference.name,
                  style: {
                    color: "#222",
                    fontSize: "1em !important",
                  },
                }}
                address1={reference.address1}
                address2={reference.address2}
                city={reference.city}
                state={reference.state}
                postalCode={reference.postalCode}
                country={reference.country}
              />
            );
          } else {
            return <Text>{cellInfo.row.original.reference.value}</Text>;
          }
        },
      },
    ];
  }, [t]);

  let referenceData = [];

  if (statusUpdate?.references) {
    referenceData = statusUpdate.references.map((reference) => ({
      qualifier: _.capitalize(reference.qualifier),
      reference,
    }));
  }

  if (comments) {
    referenceData.push({
      qualifier: t("fv-vin-details:Comments"),
      reference: { value: comments },
    });
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div onClick={() => setShowModal(true)} css={{ cursor: "pointer" }}>
        <Icon
          src={faPlusCircle}
          size={FontSize.size12}
          css={{ color: Colors.highlight.GREEN }}
        />
      </div>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header
          closeButton
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            color: Colors.background.DARK_BLUE,
            padding: "0.5rem 0.75rem 0.5rem 0.5rem",
          }}
          title={t("fv-vin-details:Milestone References")}
        />

        <Modal.Body>
          <BaseTable
            data-qa="name-value-table"
            theme={Themes.LIGHT}
            data={referenceData}
            columns={milestoneReferencesColumns}
            pageSize={referenceData?.length > 20 ? referenceData.length : 20}
            disableSortBy={true}
            defaultSortColumn={"label"}
            defaultReverseSort={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

MilestoneReferencesButton.propTypes = {
  milestoneInfo: PropTypes.object,
  statusUpdate: PropTypes.object,
  comments: PropTypes.string,
};
