/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DocumentationState from "./DocumentationState";
import { getActiveOrganization } from "../organizations/OrganizationsState";
import { privateRouteGroupsMap } from "../../routes";
import { getCurrentLocation } from "../../route-selectors";
import { NavGroup } from "../appnav/components/NavGroup";
import { NavLink } from "../appnav/components/NavLink";

const DOCUMENTATION_GROUP_KEY = "DOCUMENTATION";

const docGroup = privateRouteGroupsMap[DOCUMENTATION_GROUP_KEY];
const docRoutesSet = new Set(Object.keys(docGroup.routes));

const MATCHER_FOR_DOC_CATEGORY = {
  API: /^nav-api/,
  USER: /^nav-user/,
  REF: /^nav-edi/,
  UTILS: /^nav-api-utils/,
};

const getNavLinks = (t, navOptions, activeOption) => {
  return navOptions.map((option, i) => {
    return (
      <NavLink
        key={`docnav-${option.name}`}
        to={`/documentation/${option.file}`}
        title={t(`documentation-remote:${option.name}`)}
        isActiveScreen={option === activeOption}
      />
    );
  });
};

const DocumentationNav = ({ isOpen, toggleOpenGroup, sidebarMinimize }) => {
  const { t } = useTranslation(["documentation", "documentation-remote"]);
  const docCategory = useSelector(DocumentationState.selectors.getCategorySlug);
  const currentLocation = useSelector(getCurrentLocation);
  const activeContent = useSelector(DocumentationState.selectors.getContent);
  const contentNav = useSelector(DocumentationState.selectors.getContentNav);
  const activeOrganization = useSelector(getActiveOrganization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(activeOrganization)) {
      dispatch(DocumentationState.actionCreators.fetchContentNav());
    }
  }, [dispatch, activeOrganization]);

  const navOptions = _.get(contentNav, "options", []);
  const activeOption = _.first(
    _.filter(navOptions, (option) => {
      if (!docRoutesSet.has(currentLocation.type)) {
        return false;
      }
      if (activeContent.title === option.name) {
        return true;
      }
      const docMatcher = MATCHER_FOR_DOC_CATEGORY[docCategory];
      if (!docMatcher) {
        return false;
      }
      return docMatcher.test(option.name);
    }),
  );

  const links = getNavLinks(t, navOptions, activeOption);
  if (!links || links.length < 1) {
    return null;
  }

  return (
    <div>
      <NavGroup
        groupKey={DOCUMENTATION_GROUP_KEY}
        title={docGroup.title}
        icon={docGroup.icon}
        isOpen={isOpen}
        toggleOpenGroup={toggleOpenGroup}
        sidebarMinimize={sidebarMinimize}
      >
        {links}
      </NavGroup>
    </div>
  );
};

DocumentationNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpenGroup: PropTypes.func,
  sidebarMinimize: PropTypes.bool.isRequired,
};

export default DocumentationNav;
