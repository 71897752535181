import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./MilestoneDashboardSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import MilestoneDomainDataState from "./MilestoneDomainDataState";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getExportIdentifier,
    getExportName,
    getIsExporting,
  } = SearchBarState.selectors;
  return {
    solutionId: getSolutionId(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    getExportIdentifier: getExportIdentifier(state),
    getExportName: getExportName(state),
    isExporting: getIsExporting(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) => {
      dispatch(setSearchText(text, ignoreValueChange, true));
    },
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId)),
    fetchDomainData: (solutionId) =>
      dispatch(
        MilestoneDomainDataState.actionCreators.fetchDomainData(solutionId),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
