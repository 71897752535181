/** @jsxImportSource @emotion/react */
// Third party Libraries
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";

// Third party UI Libraries
import { Alert, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

// UI Libraries
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Radio } from "components/atoms/Radio.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { FlexRowDiv } from "styles/container-elements";

import { useNotAllSuccessfulColumns } from "./DriveAway.NotAllSuccessful.columns";
import {
  bulkSubmitVinExample,
  bulkApproveVinExample,
  bulkDenyVinExample,
} from "./BulkActionCsvTemplates";
import {
  BulkActionType,
  translateBulkActionType,
} from "pages/driveaway/utils/enums.utils";
import { isNumberOnly } from "pages/driveaway/utils/driveaway.utils";

export const BulkActionModal = ({
  bulkActionTypes,
  show,
  setShow,
  bulkActionSubmit,
  bulkActionStatus,
  resetBulkActionStatus,
  requestResults,
}) => {
  const { t } = useTranslation("driveaway-search");
  const [selectedRadio, setSelectedRadio] = useState(bulkActionTypes[0]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [isLoadingIndicator, setIsLoadingIndicator] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userPhoneNumberExt, setUserPhoneNumberExt] = useState("");
  const [importFile, setImportFile] = useState(null);
  const [importFileCsvData, setImportFileCsvData] = useState("");
  const [phoneNumCountryInfo, setPhoneNumCountryInfo] = useState(null);

  const columns = useNotAllSuccessfulColumns();

  useEffect(() => {
    if (selectedRadio === BulkActionType.BULK_SUBMIT) {
      if (
        userPhoneNumber?.length === 11 &&
        importFileCsvData?.length > 0 &&
        isNumberOnly(userPhoneNumber) &&
        isNumberOnly(userPhoneNumberExt)
      ) {
        setSubmitButtonDisabled(false);
      } else {
        setSubmitButtonDisabled(true);
      }
    } else {
      if (importFileCsvData?.length > 0) {
        setSubmitButtonDisabled(false);
      } else {
        setSubmitButtonDisabled(true);
      }
    }
  }, [userPhoneNumber, importFileCsvData, selectedRadio, userPhoneNumberExt]);

  const resetState = () => {
    resetBulkActionStatus();
    setSelectedRadio(bulkActionTypes[0]);
    setSubmitButtonDisabled(true);
    setIsLoadingIndicator(false);
    setUserPhoneNumber("");
    setImportFile(null);
    setImportFileCsvData("");
  };

  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  const onSubmitHandler = (
    bulkActionType,
    csvData,
    requesterPhoneNumber,
    userPhoneNumberExt,
  ) => {
    // Right now backend doesn't store country code, to avoid future chaos this if block splits phone number
    // into the following format: +X-XXXXXXXXXX
    if (!!requesterPhoneNumber && !!phoneNumCountryInfo?.dialCode) {
      requesterPhoneNumber = `+${requesterPhoneNumber.slice(
        0,
        phoneNumCountryInfo.dialCode.length,
      )}-${requesterPhoneNumber.slice(phoneNumCountryInfo.dialCode.length)}`;
    }
    bulkActionSubmit(
      bulkActionType,
      csvData,
      requesterPhoneNumber,
      userPhoneNumberExt,
    );
  };

  const getTooltipDisplay = (exampleCsv) => {
    return (
      <Fragment>
        <Text>{`${t("components:The file must contain")}:`}</Text>
        <ul style={{ padding: 0, margin: 0, whiteSpace: "nowrap" }}>
          {exampleCsv.headers.map((row, index) => (
            <li key={index}>{row.label}</li>
          ))}
        </ul>
      </Fragment>
    );
  };

  return (
    <DialogModal
      style={{ width: "50%" }}
      show={show}
      onHide={() => {
        setShow(false);
        resetState();
      }}
      isLoading={isLoadingIndicator}
      loadStatus={
        bulkActionStatus === "SUCCESS" ||
        bulkActionStatus === "NOT_ALL_SUCCESS" ||
        bulkActionStatus === "ERROR"
          ? "success"
          : ""
      }
      title={t("driveaway-search:Bulk Action")}
      cancelButtonText={t("Cancel")}
      submitButtonText={t("Submit")}
      okButtonText={t("Ok")}
      submitButtonVariant="primary"
      onSubmit={() => {
        onSubmitHandler(
          selectedRadio,
          importFileCsvData,
          userPhoneNumber,
          userPhoneNumberExt,
        );
        setIsLoadingIndicator(true);
      }}
      size="md"
      submitButtonDisabled={submitButtonDisabled}
    >
      {/* Radio button section, maybe make this into a component in the future */}
      {isLoadingIndicator === false ? (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
          }}
          className="mb-3"
        >
          {bulkActionTypes.map((type, idx) => {
            return (
              <div
                key={`actionTypeRadio_${type}`}
                onClick={() => setSelectedRadio(type)}
                css={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 10,
                  userSelect: "none",
                }}
              >
                <Radio
                  checked={selectedRadio === type}
                  color={Colors.filters.CHECK_DEFAULT_COLOR}
                  checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
                  disabledColor={Colors.text.DISABLED}
                  css={{ alignItems: "center", cursor: "pointer" }}
                />
                <Text>{translateBulkActionType(t, type)}</Text>
              </div>
            );
          })}
        </div>
      ) : null}
      {isLoadingIndicator === false &&
      selectedRadio === BulkActionType.BULK_SUBMIT &&
      (!isNumberOnly(userPhoneNumber) || !isNumberOnly(userPhoneNumberExt)) ? (
        <Alert variant="warning">
          <div>
            {t(
              `driveaway-search:Please enter only numeric values for phone number and extension.`,
            )}
          </div>
        </Alert>
      ) : null}

      {isLoadingIndicator === false ? (
        // Using BulkSubmitRequestBody component for all submit, approve, deny for now.
        // will create separate components for other actions when needed
        <BulkSubmitRequestBody
          t={t}
          getTooltipDisplay={getTooltipDisplay}
          setUserPhoneNumber={setUserPhoneNumber}
          setImportFile={setImportFile}
          importFile={importFile}
          onFileLoad={onFileLoad}
          selectedRadio={selectedRadio}
          setUserPhoneNumberExt={setUserPhoneNumberExt}
          setPhoneNumCountryInfo={setPhoneNumCountryInfo}
        />
      ) : null}
      {isLoadingIndicator === true &&
      bulkActionStatus !== "SUCCESS" &&
      bulkActionStatus !== "NOT_ALL_SUCCESS" &&
      bulkActionStatus !== "ERROR" ? (
        <UploadInProgressModalBody t={t} />
      ) : null}
      {bulkActionStatus === "SUCCESS" ? (
        <UploadAllSuccessModalBody t={t} />
      ) : null}
      {bulkActionStatus === "NOT_ALL_SUCCESS" ? (
        <UploadNotAllSuccessModalBody
          t={t}
          columns={columns}
          requestResults={requestResults}
        />
      ) : null}
      {bulkActionStatus === "ERROR" ? <UploadErrorModalBody t={t} /> : null}
    </DialogModal>
  );
};

const UploadAllSuccessModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faCheckCircle} color="green" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("driveaway-search:Upload successfully completed for all VINs")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadErrorModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("driveaway-search:There was an error. Please try again later.")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadNotAllSuccessModalBody = (props) => {
  const { t, columns, requestResults } = props;

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        <Text size={FontSize.size16}>
          {t("driveaway-search:Upload was not successful for all VINs.")}
        </Text>
        <Text css={{ marginTop: "1em" }}>
          {t(
            "driveaway-search:Please see the below list for VINs that were not successful and the reasons why",
          )}
          {":"}
        </Text>
        {/* Insert BaseTable Here*/}
        <BaseTable
          theme={Themes.LIGHT}
          columns={columns}
          data={requestResults}
        />
      </div>
    </Fragment>
  );
};

const UploadInProgressModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "2.5em 0" }}>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faSpinner} spin size={3} />
      </div>
      <Text
        css={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
        size={FontSize.size16}
      >
        {t("driveaway-search:Upload is in progress...")}
      </Text>
    </div>
  );
};

const BulkSubmitRequestBody = (props) => {
  const {
    t,
    getTooltipDisplay,
    setUserPhoneNumber,
    setImportFile,
    importFile,
    onFileLoad,
    selectedRadio,
    setUserPhoneNumberExt,
    userPhoneNumber,
    setPhoneNumCountryInfo,
  } = props;

  const getVinExample = (selectedRadio) => {
    if (selectedRadio === BulkActionType.BULK_SUBMIT) {
      return bulkSubmitVinExample;
    }
    if (selectedRadio === BulkActionType.BULK_APPROVE) {
      return bulkApproveVinExample;
    }
    if (selectedRadio === BulkActionType.BULK_DENY) {
      return bulkDenyVinExample;
    }
  };

  const getVinExampleFileName = (selectedRadio) => {
    if (selectedRadio === BulkActionType.BULK_SUBMIT) {
      return "bulk-submit-template.csv";
    }
    if (selectedRadio === BulkActionType.BULK_APPROVE) {
      return "bulk-approve-template.csv";
    }
    if (selectedRadio === BulkActionType.BULK_DENY) {
      return "bulk-deny-template.csv";
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "1em" }}>
        <FlexRowDiv
          css={{ marginBottom: "0.5em", justifyContent: "space-between" }}
        >
          <span>
            {t("users:Select a CSV file to import")}{" "}
            <Tooltip
              css={{ marginLeft: "0.3em" }}
              placement="bottom"
              tooltipChildren={getTooltipDisplay(getVinExample(selectedRadio))}
            >
              <FaQuestionCircle css={{ marginBottom: 5 }} />
            </Tooltip>
          </span>

          <DownloadCsvLink
            data={getVinExample(selectedRadio).data}
            headers={getVinExample(selectedRadio).headers}
            label={t("components:Download template (.csv)")}
            filename={getVinExampleFileName(selectedRadio)}
          />
        </FlexRowDiv>
        <FileInput
          t={t}
          setImportFile={setImportFile}
          onFileLoad={onFileLoad}
          importFile={importFile}
        />
        {selectedRadio === BulkActionType.BULK_SUBMIT ? (
          <PhoneNumberInput
            t={t}
            setUserPhoneNumber={setUserPhoneNumber}
            setUserPhoneNumberExt={setUserPhoneNumberExt}
            userPhoneNumber={userPhoneNumber}
            setPhoneNumCountryInfo={setPhoneNumCountryInfo}
          />
        ) : null}
      </div>
      {selectedRadio === BulkActionType.BULK_SUBMIT ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text>
            {t(
              "driveaway-search:By submitting these VINs for a pick up request, the associated carrier will have the opportunity to either approve or deny the request. If approved, designated pick up windows will be provided.",
            )}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

const FileInput = (props) => {
  const { t, setImportFile, onFileLoad, importFile } = props;
  return (
    <FormGroup css={{ marginBottom: "1rem" }}>
      <InputGroup>
        <label className="input-group-btn" css={{ paddingRight: "0.375rem" }}>
          <span className="btn btn-light">
            {t("users:Browse")}&hellip;{" "}
            <input
              id="files"
              type="file"
              accept=".csv"
              title={null}
              onChange={(event) => {
                const reader = new FileReader();
                reader.readAsText(event?.target?.files[0]);
                reader.onload = onFileLoad;
                setImportFile(event?.target?.files[0]);
              }}
              hidden
            />
          </span>
        </label>

        <FormControl
          type="type"
          value={importFile?.name ?? ""}
          placeholder={t("users:Select file")}
          htmlFor="files"
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          readOnly
        />
      </InputGroup>
    </FormGroup>
  );
};

const PhoneNumberInput = (props) => {
  const {
    t,
    setUserPhoneNumber,
    setUserPhoneNumberExt,
    userPhoneNumber,
    setPhoneNumCountryInfo,
  } = props;
  return (
    <FormGroup css={{ marginBottom: "1rem" }}>
      <Text>{t("driveaway-search:Please enter contact phone number")}</Text>
      <div css={{ display: "flex", width: "100%" }}>
        <PhoneInput
          style={{ width: "16em" }}
          inputStyle={{ width: "16em" }}
          value={userPhoneNumber}
          onChange={(newNumber, countryInfo) => {
            setPhoneNumCountryInfo(countryInfo);
            setUserPhoneNumber(newNumber);
          }}
          country={"us"}
          onlyCountries={["us", "ca"]}
          placeholder={"(555) 555-5555"}
          countryCodeEditable={false}
        />
        <FormControl
          css={{ width: "5em" }}
          type="tel"
          placeholder="ext."
          onChange={(e) => {
            setUserPhoneNumberExt(e.target.value);
          }}
        ></FormControl>
      </div>
    </FormGroup>
  );
};

UploadInProgressModalBody.propTypes = {
  t: PropTypes.func,
};

UploadAllSuccessModalBody.propTypes = {
  t: PropTypes.func,
};
UploadNotAllSuccessModalBody.propTypes = {
  t: PropTypes.func,
};

BulkSubmitRequestBody.propTypes = {
  t: PropTypes.func,
  getTooltipDisplay: PropTypes.func,
  setUserPhoneNumber: PropTypes.func,
  setImportFile: PropTypes.func,
  importFile: PropTypes.object,
  onFileLoad: PropTypes.func,
  selectedRadio: PropTypes.string,
  setUserPhoneNumberExt: PropTypes.func,
};

BulkActionModal.propTypes = {
  bulkActionTypes: PropTypes.array,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  bulkActionSubmit: PropTypes.func,
  bulkActionStatus: PropTypes.string,
  resetBulkActionStatus: PropTypes.func,
  requestResults: PropTypes.array,
};
