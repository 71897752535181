import { buildLocationAsyncFilterOptionsState } from "pages/administration/location-management/utils/buildLocationAsyncFilterOptionsState";

export const shipmentDwellNotificationLocationOptionsState =
  buildLocationAsyncFilterOptionsState({
    topic: "shipmentDwellLocationOptions",
    additionalParams: {
      verbose: false,
    },
    additionalQueryOnlyParams: {
      everythingRestrict: "code,name",
    },
    additionalQueryOnlyHeaders: {
      Accept: "application/json,locationCodeName",
    },
  });
