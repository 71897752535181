/** @jsxImportSource @emotion/react */
// External
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";

// Components
import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { VinViewSavedSearch } from "./VinView.SavedSearch.organism";
import VinViewEditSavedSearchModalContainer from "../../../components/search/VinView.EditSavedSearchModal.container";

// State
import VinViewSearchBarState from "../../../redux/VinViewSearchBarState";
import VinViewSavedSearchState from "../../../redux/VinViewSavedSearchState";

export const VinViewSavedSearchesPanel = ({
  isDealerOrg = true,
  selectedDealerOrgId = null,
}) => {
  const savedSearches = useSelector(
    VinViewSavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    VinViewSavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(() => {
    if (isDealerOrg || (!isDealerOrg && selectedDealerOrgId)) {
      dispatch(VinViewSavedSearchState.actionCreators.fetchSavedSearches());
    }
  }, [dispatch, isDealerOrg, selectedDealerOrgId]);

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={VinViewSavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                VinViewSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
            isDealerOrg,
            selectedDealerOrgId,
          };
        }}
      />
      <VinViewEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            VinViewSearchBarState.actionCreators.resetSearchAndFilters(true),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(VinViewSavedSearchState.actionCreators.deleteSearch(id));
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};

VinViewSavedSearchesPanel.propTypes = {
  isDealerOrg: PropTypes.bool,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
