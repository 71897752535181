import _ from "lodash";
import axios from "axios";
import * as math from "mathjs";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { buildLinkPayload } from "modules/location-resolution-edit/LocationMatchingState";
import { LobsType } from "modules/lads/utils/lads.utils";

export const INITIAL_RADIUS_M = math.unit(0.25, "mile").to("m").value;

// URLs
const LOCATIONS_URL = apiUrl("/location/locations");
const UNRESOLVED_LOCATIONS_URL = apiUrl("/location/unlinked");
const HEAT_MAP_URL = apiUrl("/shipping-ng/heat_map");
const DDA_LOCATIONS_URL = apiUrl("/dda/dda/get-dpu-pickup-location");
const DDA_UPDATE_LOCATIONS_URL = apiUrl("/dda/dda/update-dpu-pickup-location");

const heatMapDuck = buildFetchDuck("heatmap");
const locationDuck = buildFetchDuck("searchLocations");
const countriesDuck = buildFetchDuck("locationCountries");
const subdivisionsDuck = buildFetchDuck("subdivisions");

const SET_SEARCH_STR = "locations/SET_SEARCH_STR";
const SET_LOCATION_SAVED = "locations/LOCATION_SAVED";
const SET_IS_LOCATION_SAVING = "locations/SET_IS_LOCATION_SAVING";
const SET_LOCATION_LINKED = "locations/LOCATION_LINKED";

const SET_IS_CONFIG_SAVING = "locations/SET_IS_CONFIG_SAVING";
const SET_IS_INV_LOCATION_DETAILS_LOADING =
  "locations/SET_IS_INV_LOCATION_DETAILS_LOADING";
const SET_IS_DAMAGE_VIEW_LOCATION_CONFIG_LOADING =
  "locations/SET_IS_DAMAGE_VIEW_LOCATION_CONFIG_LOADING";
const SET_IS_ENABLE_DPU_LOADING = "locations/SET_IS_ENABLE_DPU_LOADING";
const SET_IS_DPU_ENABLED = "locations/SET_IS_DPU_ENABLED";
const SET_IS_SCAC_LIST_LOADING = "locations/SET_IS_SCAC_LIST_LOADING";

const ADD_LOCATION_FAILED = "locations/ADD_LOCATION_FAILED";
const CLEAR_ACTION_STATUS = "locations/CLEAR_ACTION_STATUS";
const FETCH_LOCATION_DETAILS = "locations/FETCH_LOCATION_DETAILS";
const RECEIVE_LOCATION_DETAILS = "locations/RECEIVE_LOCATION_DETAILS";
const RECEIVE_DDA_LOCATION_DETAILS = "locations/RECEIVE_DDA_LOCATION_DETAILS";
const RECEIVE_INV_LOCATION_DETAILS = "locations/RECEIVE_INV_LOCATION_DETAILS";
const RECEIVE_DV_LOCATION_DETAILS = "locations/RECEIVE_DV_LOCATION_DETAILS";
const RECEIVE_DDA_SUBDIVISIONS = "locations/RECEIVE_DDA_SUBDIVISIONS";
const RECEIVE_SCAC_LIST = "locations/RECEIVE_SCAC_LIST";
const RECEIVE_LOCATIONS = "locations/RECEIVE_LOCATIONS";
const RECEIVE_LOCATIONS_SUMMARY = "locations/RECEIVE_LOCATIONS_SUMMARY";
const FETCH_UNRESOLVED_LOCATIONS = "locations/FETCH_UNRESOLVED_LOCATIONS";
const RECEIVE_UNRESOLVED_LOCATIONS = "locations/RECEIVE_UNRESOLVED_LOCATIONS";
const RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY =
  "locations/RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY";
const SEARCH_LOCATIONS = "locations/SEARCH_LOCATIONS";
const SEARCH_LOCATIONS_SUMMARY = "locations/SEARCH_LOCATIONS_SUMMARY";
const SET_PAGINATION = "locations/SET_PAGINATION";

const CLEAR_SEARCH_RESULTS = "locations/CLEAR_SEARCH_RESULTS";
const CLEAR_SUBDIVISIONS = "locations/CLEAR_SUBDIVISIONS";
const SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE =
  "locations/subdivisions/SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE";

const FETCH_ORG_LOCATIONS = "locations/FETCH_ORG_LOCATIONS";
const RECEIVE_ORG_LOCATIONS = "locations/RECEIVE_ORG_LOCATIONS";
const FETCH_ORG_LOCATIONS_FAILED = "locations/FETCH_ORG_LOCATIONS_FAILED";

const FETCH_LOCATION_CHILDREN = "locations/FETCH_LOCATION_CHILDREN";
const RECEIVE_LOCATION_CHILDREN = "locations/RECEIVE_LOCATION_CHILDREN";
const FETCH_LOCATION_CHILDREN_FAILED =
  "locations/FETCH_LOCATION_CHILDREN_FAILED";
const SET_LOCATION_CHILDREN_PAGINATION =
  "locations/SET_LOCATION_CHILDREN_PAGINATION";

const RESET_DDA_LOCATION_DATA = "locations/RESET_DDA_LOCATION_DATA";

const IMPORT_LOCATIONS = "locations/IMPORT_LOCATIONS";
const IMPORT_LOCATIONS_SUCCEEDED = "locations/IMPORT_LOCATIONS_SUCCEEDED";
const IMPORT_LOCATIONS_FAILED = "locations/IMPORT_LOCATIONS_FAILED";

export const LocationsActionStatus = {
  LOCATIONS_IMPORT_SUCCEEDED: "LOCATIONS_IMPORT_SUCCEEDED",
  LOCATIONS_IMPORT_FAILED: "LOCATIONS_IMPORT_FAILED",
  STARTING_IMPORT: "STARTING_IMPORT",
};

// Fields coming in the locations summary response.
const LOCATION_SUMMARY_FIELDS = [
  "address",
  "address2",
  "category",
  "city",
  "code",
  "country",
  "id",
  "lad.lad_id",
  "name",
  "postal_code",
  "state",
];

const urlBuilder = ({
  page = 0,
  pageSize = 20,
  searchStr = "",
  verbose = true,
  paginate = true,
  parentLocation = null,
} = {}) => {
  let url = LOCATIONS_URL;
  url += verbose ? "?verbose=true" : "?verbose=false";

  if (paginate) {
    url += `&pageNumber=${page}&pageSize=${pageSize}`;
  }

  if (searchStr) {
    url += `?everything=${searchStr}`;
  }

  if (parentLocation) {
    url += `&childrenOfParentId=${parentLocation}`;
  }

  return url;
};

export function fetchOrgLocations(organizationId, locationIDs) {
  let url = `${LOCATIONS_URL}?verbose=false&all=true&owner_id=${organizationId}`;

  if (locationIDs) {
    const newArrLocationIDs = encodeURIComponent(JSON.stringify(locationIDs));
    url += `&location_ids=${newArrLocationIDs}`;
  }

  return (dispatch) => {
    dispatch({
      type: FETCH_ORG_LOCATIONS,
    });

    return Promise.all([axios.get(url)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ORG_LOCATIONS,
          data: responses[0].data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ORG_LOCATIONS_FAILED,
        });
      });
  };
}

export function fetchLadLocations(code, verbose = false) {
  let qs = `organization_lad_id=${encodeURIComponent(code)}`;

  let searchLocationsUrl = apiUrl(`/location/locations`);
  searchLocationsUrl += verbose ? "?verbose=true" : "?verbose=false";
  const url = `${searchLocationsUrl}&${qs}`;

  return (dispatch) => {
    return Promise.all([axios.get(url)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_LOCATIONS,
          data: responses[0].data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function searchLocations(resetPagination = true) {
  return (dispatch, getState) => {
    if (resetPagination) {
      dispatch({ type: SET_PAGINATION, page: 0, pageSize: 20 });
    }

    //TODO: clear existing search
    const state = getState();
    const url = urlBuilder({
      page: getPage(state),
      pageSize: getPageSize(state),
      verbose: false,
    });

    dispatch(locationDuck.fetch(url));
    dispatch({ type: "LOCATION_SEARCH" });
  };
}

// Retrieves the details for a particular location.
export function fetchLocationDetails(locationId, dereference = false) {
  return (dispatch) => {
    dispatch({ type: FETCH_LOCATION_DETAILS, data: locationId });
    // Append param if fetching a dereferenced location as a Carrier
    const queryString = dereference ? "?dereference=t" : "";

    return Promise.all([
      axios.get(`${LOCATIONS_URL}/${locationId}${queryString}`),
    ])
      .then((responses) => {
        const data = responses[0].data;
        // Replace ID if fetching a dereferenced location as a Carrier
        if (dereference && Array.isArray(data.child_ids)) {
          data.id = data.child_ids[0];
        }
        dispatch({ type: RECEIVE_LOCATION_DETAILS, data: data });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Calls API to retrieve the DDA details for a particular location.
export function fetchDdaLocationDetails(locationId) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_ENABLE_DPU_LOADING,
      value: true,
    });
    dispatch({
      type: SET_IS_SCAC_LIST_LOADING,
      value: true,
    });

    return Promise.all([
      axios.get(`${DDA_LOCATIONS_URL}`, { params: { locationId: locationId } }),
    ])
      .then((responses) => {
        let data = responses[0].data;
        if (responses[0].data?.enabled) {
          dispatch({
            type: SET_IS_DPU_ENABLED,
            value: true,
          });
        } else {
          // set enable status to false
          dispatch({
            type: SET_IS_DPU_ENABLED,
            value: false,
          });
        }

        dispatch({
          type: RECEIVE_SCAC_LIST,
          data: data?.whitelist
            ? data?.whitelist?.map((obj) => {
                return {
                  ...obj,
                  saved: true,
                };
              })
            : [],
        });

        dispatch({
          type: RECEIVE_DDA_LOCATION_DETAILS,
          data: data.pickup_location,
        });

        dispatch({
          type: SET_IS_ENABLE_DPU_LOADING,
          value: false,
        });

        dispatch({
          type: SET_IS_SCAC_LIST_LOADING,
          value: false,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Calls API to update the DDA details for a particular location.
export function updateDdaLocationDetails(
  locationId,
  pickupLocation,
  ActionOptions = {
    showDpu: false,
    showInv: false,
    showDv: false,
    showEnableDpu: false,
  },
  isAddLocationForInventoryViewEnabled = false,
  newCapacity = "",
  prevIsAddLocationForInventoryViewEnabled = false,
  isDamageViewAddLocationEnabled = false,
  prevIsDamageViewAddLocationEnabled = false,
  isDpuAddLocationEnabled = false,
  prevIsDpuAddLocationEnabled = false,
  scacModificationList = [],
) {
  const data = _.omit(pickupLocation, ["postalCode", "specialInstructions"]);
  let promiseForInventoryView = isAddLocationForInventoryViewEnabled
    ? enableInventoryViewLocationDetailsPromise(locationId, newCapacity)
    : disableInventoryViewLocationDetailsPromise(
        locationId,
        newCapacity,
        isAddLocationForInventoryViewEnabled,
        prevIsAddLocationForInventoryViewEnabled,
      );

  let promiseForDamageView = isDamageViewAddLocationEnabled
    ? enableDamageViewLocationPromise(locationId)
    : disableDamageViewLocationPromise(
        locationId,
        isDamageViewAddLocationEnabled,
        prevIsDamageViewAddLocationEnabled,
      );

  let promiseForEnableDpu = null;

  let promiseArray = [];
  if (ActionOptions.showDpu) {
    promiseArray.push(
      axios.patch(`${DDA_UPDATE_LOCATIONS_URL}`, {
        pickup_location: {
          ...data,
          postal_code: pickupLocation?.postalCode ?? "",
          special_instructions: pickupLocation?.specialInstructions ?? "",
        },
        location_id: locationId,
      }),
    );
  }

  if (ActionOptions.showInv) {
    promiseArray.push(promiseForInventoryView);
  }

  if (ActionOptions.showDv) {
    promiseArray.push(promiseForDamageView);
  }

  if (ActionOptions.showEnableDpu) {
    promiseForEnableDpu = isDpuAddLocationEnabled
      ? enableDpuPromise(locationId, scacModificationList)
      : disableDpuPromise(
          locationId,
          isDpuAddLocationEnabled,
          prevIsDpuAddLocationEnabled,
        );
    promiseArray.push(promiseForEnableDpu);
  }

  return (dispatch) => {
    dispatch({
      type: SET_IS_CONFIG_SAVING,
      value: true,
    });
    return Promise.all(promiseArray)
      .then((responses) => {
        dispatch(searchLocations(false));
        dispatch(setLocationSaved(true));
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally((_) => {
        dispatch({
          type: SET_IS_CONFIG_SAVING,
          value: false,
        });
      });
  };
}

export function fetchDdaSubdivisions(countryCode) {
  const url = apiUrl(`/location/subdivisions/${countryCode}`);
  return (dispatch) => {
    return Promise.all([axios.get(url)])
      .then((responses) => {
        let data = responses[0].data;
        dispatch({ type: RECEIVE_DDA_SUBDIVISIONS, data: data });
      })
      .catch((err) => {
        dispatch({ type: RECEIVE_DDA_SUBDIVISIONS, data: [] });
      });
  };
}

function enableInventoryViewLocationDetailsPromise(locationId, newCapacity) {
  const url = apiUrl(
    `/location/locations/${locationId}/feature/INV?capacity=${newCapacity}`,
  );

  return axios.post(url);
}

function disableInventoryViewLocationDetailsPromise(
  locationId,
  newCapacity,
  isCurrentEnabled,
  isPrevEnabled,
) {
  const url = apiUrl(
    `/location/locations/${locationId}/feature/INV?capacity=${newCapacity}`,
  );

  if (!isCurrentEnabled && !isPrevEnabled) {
    return Promise.resolve();
  }
  return axios.delete(url);
}

function enableDamageViewLocationPromise(locationId) {
  const url = apiUrl(`/location/locations/${locationId}/feature/DV`);

  return axios.post(url);
}

function disableDamageViewLocationPromise(
  locationId,
  isCurrentEnabled,
  isPrevEnabled,
) {
  const url = apiUrl(`/location/locations/${locationId}/feature/DV`);

  if (!isCurrentEnabled && !isPrevEnabled) {
    return Promise.resolve();
  }
  return axios.delete(url);
}

function enableDpuPromise(locationId, newScacList) {
  const url = apiUrl(`/dda/dda/dpu-location/${locationId}`);

  return axios.put(url, {
    whitelist: newScacList,
  });
}

function disableDpuPromise(locationId, isCurrentEnabled, isPrevEnabled) {
  const url = apiUrl(`/dda/dda/dpu-location/${locationId}`);

  if (!isCurrentEnabled && !isPrevEnabled) {
    return Promise.resolve();
  }
  return axios.delete(url);
}

export function fetchInventoryViewLocationDetails(locationId) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_INV_LOCATION_DETAILS_LOADING,
      value: true,
    });
    const url = apiUrl(`/location/locations/${locationId}/feature/INV`);
    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_INV_LOCATION_DETAILS,
          data: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchDamageViewLocationDetails(locationId) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DAMAGE_VIEW_LOCATION_CONFIG_LOADING,
      value: true,
    });
    const url = apiUrl(`/location/locations/${locationId}/feature/DV`);
    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_DV_LOCATION_DETAILS,
          data: {
            isDamageViewAddLocationEnabled: response?.data?.enabled ?? false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchUnresolvedLocations(summary = false, locationId = "") {
  let headers = {};
  let actionType = RECEIVE_UNRESOLVED_LOCATIONS;
  if (summary) {
    headers = { Accept: "application/json;version=summary" };
    actionType = RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY;
  }

  return (dispatch) => {
    dispatch({ type: FETCH_UNRESOLVED_LOCATIONS });
    const url = locationId
      ? `${LOCATIONS_URL}?near_location=${locationId}`
      : `${UNRESOLVED_LOCATIONS_URL}`;

    return axios
      .get(url, { headers: headers })
      .then((response) => {
        dispatch({ type: actionType, data: response.data });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // 404 means no data available
          dispatch({ type: actionType, data: [] });
        } else {
          throw new Error(err);
        }
      });
  };
}

export function fetchHeatMapData(locationID) {
  return (dispatch) =>
    dispatch(heatMapDuck.fetch(`${HEAT_MAP_URL}/${locationID}`));
}

export function fetchCountries() {
  const url = apiUrl("/location/countries");
  return (dispatch) => dispatch(countriesDuck.fetch(url));
}

export function fetchSubdivisions(countryCode) {
  const url = apiUrl(`/location/subdivisions/${countryCode}`);
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE,
      payload: { countryCode },
    });
    return dispatch(subdivisionsDuck.fetch(url));
  };
}

export function addLocation(data) {
  const payload = { ...data, organization_lad_id: data.lad.id };
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOCATION_SAVING,
      value: true,
    });

    return Promise.all([axios.post(`${LOCATIONS_URL}`, payload)])
      .then((responses) => {
        dispatch(searchLocations(false));
        dispatch(setLocationSaved(true));
      })
      .catch((err) => {
        dispatch({ type: ADD_LOCATION_FAILED, error: err });
      });
  };
}

export function addAndLinkLocation(
  newLocation,
  unresolvedLocation,
  unresolvedShipment,
) {
  const payload = { ...newLocation, organization_lad_id: newLocation.lad.id };
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOCATION_SAVING,
      value: true,
    });

    return Promise.all([axios.post(`${LOCATIONS_URL}`, payload)])
      .then((responses) => {
        dispatch(setLocationSaved(true));

        // Pull the location ID out of the response
        const newLocationID = responses[0].data.id;

        const linkData = buildLinkPayload(
          unresolvedLocation,
          newLocationID,
          unresolvedShipment,
        );

        // Perform the match location
        dispatch(matchLocation(linkData.id, linkData.payload));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function editLocation(id, data) {
  const payload = { ...data, organization_lad_id: data.lad.id };

  // Re-do the Sequence numbers for Multipolygon's so they match the indexes.
  // The sequence numbers are used by the API for the name, if no name value is provided.
  // So re-defining them based on index just before submitting to the API should guarantee
  // that each sequence number is unique.
  if (payload.geofence.type === "MultiPolygon") {
    payload.geofence.geometry
      .sort((p1, p2) => (p1.sequence > p2.sequence ? 1 : -1))
      .forEach((polygon, index) => (polygon.sequence = index + 1));
  }

  return (dispatch) => {
    dispatch({
      type: SET_IS_LOCATION_SAVING,
      value: true,
    });

    return Promise.all([axios.put(`${LOCATIONS_URL}/${id}`, payload)])
      .then((responses) => {
        dispatch(searchLocations(false));
        dispatch(setLocationSaved(true));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Match does a PUT just like edit, but does not add the organization lad id
export function matchLocation(id, data) {
  return (dispatch) => {
    return Promise.all([axios.put(`${LOCATIONS_URL}/${id}`, data)])
      .then((responses) => {
        dispatch(searchLocations(false));
        dispatch(fetchUnresolvedLocations(true));
        dispatch(setLocationLinked(true));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function deleteLocation(id) {
  return (dispatch) => {
    return Promise.all([axios.delete(`${LOCATIONS_URL}/${id}`)])
      .then((responses) => {
        dispatch(searchLocations(false));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

export function setSearchStr(str) {
  return (dispatch) => {
    dispatch({ type: SET_SEARCH_STR, searchStr: str });
    dispatch(searchLocations(false));
  };
}

export function setLocationSaved(value) {
  return {
    type: SET_LOCATION_SAVED,
    value: value,
  };
}

export function setLocationLinked(value) {
  return {
    type: SET_LOCATION_LINKED,
    value: value,
  };
}

export function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS,
  };
}

export function clearSubdivisions() {
  return {
    type: CLEAR_SUBDIVISIONS,
  };
}

export const setPagination = (page, pageSize) => {
  return (dispatch) => {
    dispatch({ type: SET_PAGINATION, page, pageSize });
    dispatch(searchLocations(false));
  };
};

export const locationLadChanged = (previousLad, currentLad) => {
  return (dispatch) => {
    if (
      previousLad === LobsType.FINISHED_PRODUCT &&
      currentLad !== previousLad
    ) {
      dispatch({ type: RESET_DDA_LOCATION_DATA });
    }
  };
};

export const fetchLocationChildren = (locationId, resetPagination = true) => {
  return (dispatch, getState) => {
    const state = getState();
    const url = urlBuilder({
      page: getLocationChildrenPage(state),
      pageSize: getLocationChildrenPageSize(state),
      parentLocation: locationId,
    });

    dispatch({
      type: FETCH_LOCATION_CHILDREN,
    });

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_LOCATION_CHILDREN,
          data: response.data.data,
          totalPages: response.data.meta.totalPages,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_LOCATION_CHILDREN_FAILED,
          errorMessage: err.message,
        });
      });
  };
};

export const setLocationChildrenPagination = (locationId, page, pageSize) => {
  return (dispatch) => {
    dispatch({ type: SET_LOCATION_CHILDREN_PAGINATION, page, pageSize });
    dispatch(fetchLocationChildren(locationId));
  };
};

export function importLocations(file) {
  return async (dispatch) => {
    dispatch({ type: IMPORT_LOCATIONS });

    let presignedUrl = null;
    try {
      const presignedUrlResponse = await axios.get(
        apiUrl("/location/bulk-upload"),
      );
      presignedUrl = presignedUrlResponse.data?.presignedUrl ?? null;
    } catch (error) {
      console.error(error);
      dispatch({
        type: IMPORT_LOCATIONS_FAILED,
        error,
      });
      return;
    }

    if (!presignedUrl) {
      dispatch({
        type: IMPORT_LOCATIONS_FAILED,
        error: new Error("Failed to get presigned URL"),
      });
      return;
    }

    try {
      await axios.create().put(presignedUrl, file, {
        headers: { "Content-Type": "text/csv" },
      });
      dispatch({ type: IMPORT_LOCATIONS_SUCCEEDED });
    } catch (error) {
      console.error(error);
      dispatch({
        type: IMPORT_LOCATIONS_FAILED,
        error,
      });
    }
  };
}

// helpers

export const buildSearchQueryString = (page = 0, pageSize = 20) => {
  let queryString = "";
  queryString += `&pageNumber=${page}&pageSize=${pageSize}`;
  return queryString;
};

// selectors
export const getLocations = (state) => {
  return state && state.locations && state.locations.locations
    ? state.locations.locations.data
    : [];
};

export const getIsLoadingOrgLocations = (state) => {
  return state.locations.locations.isLoadingOrgLocations;
};

export const getOrgLocations = (state) => {
  return state.locations.locations.orgLocations;
};

export const getIsLoadingOrgLocationsFailed = (state) => {
  return state.locations.locations.isLoadingOrgLocationsFailed;
};

export const getIsLoadingUnresolvedLocations = (state) => {
  return state.locations.locations.isLoadingUnresolvedLocations;
};

export const getUnresolvedLocations = (state) => {
  return state.locations.locations.unresolvedLocationsData;
};

export const isLocationSaving = (state) =>
  state.locations.locations.isLocationSaving;
export const isLocationSaved = (state) =>
  state.locations.locations.locationSaved;
export const isLocationLinked = (state) =>
  state.locations.locations.locationLinked;

export const isConfigSaving = (state) =>
  state.locations.locations.isConfigSaving;
export const isInventoryViewLocationDetailsLoading = (state) =>
  state.locations.locations.isInventoryViewLocationDetailsLoading;

export const isDamageViewLocationConfigLoading = (state) =>
  state.locations.locations.isDamageViewLocationConfigLoading;

export const isEnableDpuLoading = (state) =>
  state.locations.locations.isEnableDpuLoading;

export const isScacListLoading = (state) =>
  state.locations.locations.isScacListLoading;

const isDpuEnabled = (state) => state.locations.locations?.isDpuEnabled;

export const getLocationsById = createSelector([getLocations], (locations) =>
  _.keyBy(locations, "id"),
);

export const getSelectedLocationId = (state) => {
  return _.get(state, "location.payload.location_id");
};

export const getSelectedLocation = createSelector(
  [getSelectedLocationId, getLocationsById],
  (locationId, locationsById) => locationsById[locationId],
);

export const getCurrentSubdivisionsCountryCode = (state) =>
  state.locations.subdivisions.countryCode;

export function getDefaultLocation() {
  return {
    name: "",
    country: "US",
    state: "",
    city: "",
    address: "",
    postal_code: "",
    codes: [],
    geofence: {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [],
      },
      properties: {
        buffer: INITIAL_RADIUS_M,
        center: {
          latitude: null,
          longitude: null,
        },
      },
      lad: {},
    },
    parent_location_details: { location_code: null, location_name: null },
  };
}

export const getSubdivisions = (state) => {
  return state && state.locations && state.locations.subdivisions
    ? state.locations.subdivisions.data
    : [];
};

export const getActionStatus = (state) => {
  return state && state.locations && state.locations.locations
    ? state.locations.locations.actionStatus
    : null;
};

// Select label/value options for <Select> dropdowns
export const getSubdivisionOptions = createSelector(
  [getSubdivisions],
  (subdivisions) =>
    subdivisions.map((subdivision) => ({
      label: subdivision.name,
      value: subdivision.code,
    })),
);

export const getSearchObj = (state) => state.search.searchObj;
export const getSearchCategory = (state) => state.search.searchCategory;
export const getSearchFilters = (state) => state.search.searchFilters;
export const getPage = (state) => state.locations.locations.page;
export const getPageSize = (state) => state.locations.locations.pageSize;
export const getTotalPages = (state) => state.locations.locations.totalPages;
export const getSearchStr = (state) => state.locations.locations.searchStr;
export const getTotalLocations = (state) =>
  state.locations.locations.totalLocations;

export const getLocationChildren = (state) =>
  state.locations.locations.locationChildren;
export const getLocationChildrenErrorMessage = (state) =>
  state.locations.locations.locationChildrenErrorMessage;
export const getIsLoadingLocationChildren = (state) =>
  state.locations.locations.isLoadingLocationChildren;
export const getLocationChildrenPage = (state) =>
  state.locations.locations.locationChildrenPage;
export const getLocationChildrenPageSize = (state) =>
  state.locations.locations.locationChildrenPageSize;
export const getLocationChildrenTotalPages = (state) =>
  state.locations.locations.locationChildrenTotalPages;
export const getDdaLocationData = (state) =>
  state.locations.locations.ddaLocationData;
export const getDdaSubdivisions = (state) =>
  state.locations.locations.ddaSubdivisions;
export const getInventoryViewLocationData = (state) => {
  const invLocationData = state.locations.locations.invLocationData;
  const isAddLocationForInventoryViewEnabled = invLocationData?.capacity
    ? true
    : false;
  const capacity = invLocationData?.capacity ?? "";
  return { isAddLocationForInventoryViewEnabled, capacity };
};
export const getDamageViewLocationData = (state) => {
  const dvLocationData = state.locations.locations.dvLocationData;
  const isDamageViewAddLocationEnabled =
    dvLocationData?.isDamageViewAddLocationEnabled ?? false;
  return { isDamageViewAddLocationEnabled };
};
export const getDpuAddLocationData = (state) => {
  const isDpuAddLocationEnabled = isDpuEnabled(state) ?? false;
  return { isDpuAddLocationEnabled };
};
export const getScacListData = (state) => {
  return state.locations.locations.scacList;
};

// initial state

const initialState = {
  data: [getDefaultLocation()],
  ddaLocationData: {
    pickupLocation: {
      address: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      specialInstructions: null,
    },
  },
  prevData: null,
  searchStr: "",
  isLocationSaving: false,
  isConfigSaving: false,
  isInventoryViewLocationDetailsLoading: false,
  isDamageViewLocationConfigLoading: false,
  isEnableDpuLoading: false,
  isScacListLoading: false,
  isDpuEnabled: false,
  locationSaved: false,
  locationLinked: false,
  actionStatus: null,
  page: 0,
  pageSize: 20,
  totalPages: 0,
  totalLocations: 0,
  isLoadingUnresolvedLocations: false,
  orgLocations: [],
  isLoadingOrgLocations: false,
  isLoadingOrgLocationsFailed: false,
  locationChildren: [],
  isLoadingLocationChildren: false,
  locationChildrenErrorMessage: null,
  locationChildrenPage: 0,
  locationChildrenPageSize: 20,
  locationChildrenTotalPages: 0,
  ddaSubdivisions: null,
  scacList: [{ scac: "", override_scac: "" }],
};

// reducer
function LocationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH_STR:
      return { ...state, searchStr: action.searchStr };
    case SET_IS_LOCATION_SAVING:
      return { ...state, isLocationSaving: action.value };
    case SET_IS_CONFIG_SAVING:
      return { ...state, isConfigSaving: action.value };
    case SET_IS_ENABLE_DPU_LOADING:
      return { ...state, isEnableDpuLoading: action.value };
    case SET_IS_DPU_ENABLED:
      return { ...state, isDpuEnabled: action.value };
    case SET_IS_DAMAGE_VIEW_LOCATION_CONFIG_LOADING:
      return { ...state, isDamageViewLocationConfigLoading: action.value };
    case SET_IS_INV_LOCATION_DETAILS_LOADING:
      return { ...state, isInventoryViewLocationDetailsLoading: action.value };
    case SET_IS_SCAC_LIST_LOADING:
      return { ...state, isScacListLoading: action.value };
    case SET_LOCATION_SAVED:
      return { ...state, locationSaved: action.value, isLocationSaving: false };
    case SET_LOCATION_LINKED:
      return { ...state, locationLinked: action.value };
    case ADD_LOCATION_FAILED:
      return {
        ...state,
        isLocationSaving: false,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 409
            ? "Duplicate_Location"
            : null,
      };
    case IMPORT_LOCATIONS:
      return {
        ...state,
        actionStatus: LocationsActionStatus.STARTING_IMPORT,
      };
    case IMPORT_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        actionStatus: LocationsActionStatus.LOCATIONS_IMPORT_SUCCEEDED,
      };
    case IMPORT_LOCATIONS_FAILED:
      return {
        ...state,
        actionStatus: LocationsActionStatus.LOCATIONS_IMPORT_FAILED,
        lastError: action.error.response
          ? action.error.response.status
          : "Unknown Error",
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
      };
    case FETCH_LOCATION_DETAILS: {
      let locs = _.clone(state.data);
      const locationId = action.data;
      // Look for this location in the locations and mark it as loading
      let existing = locs.find((loc) => loc.id === locationId);

      // If the location doesn't yet exist in the locations array (user entered a URL
      // directly and the location list  was not loaded), insert a temporary
      // placeholder until it loads, otherwise fill the details with default values.
      if (existing) {
        locs.splice(locs.indexOf(existing), 1, {
          isLoadingDetails: true,
          geofence: getDefaultLocation().geofence,
          ...existing,
        });
      } else {
        locs.push({
          id: locationId,
          isLoadingDetails: true,
          ...getDefaultLocation(),
        });
      }

      return {
        ...state,
        data: locs,
      };
    }
    case RECEIVE_LOCATION_DETAILS: {
      let locs = _.clone(state.data);
      const newLocationData = action.data;
      // Check if there's an existing location with this id, if so, merge the incoming
      // data with it.
      let existing = locs.find((loc) => loc.id === newLocationData.id);

      existing
        ? locs.splice(locs.indexOf(existing), 1, {
            ...newLocationData,
            isSummary: false,
            isLoadingDetails: false,
          })
        : locs.push(newLocationData);

      return {
        ...state,
        data: locs,
      };
    }
    case RECEIVE_DDA_LOCATION_DETAILS: {
      return {
        ...state,
        ddaLocationData: {
          pickupLocation: {
            ...action.data,
            postalCode: action.data.postal_code,
            specialInstructions: action.data.special_instructions,
          },
        },
      };
    }
    case RECEIVE_DDA_SUBDIVISIONS: {
      return {
        ...state,
        ddaSubdivisions: action.data,
      };
    }
    case RECEIVE_INV_LOCATION_DETAILS: {
      return {
        ...state,
        invLocationData: action.data ?? {},
        isInventoryViewLocationDetailsLoading: false,
      };
    }
    case RECEIVE_DV_LOCATION_DETAILS: {
      return {
        ...state,
        dvLocationData: action.data ?? {},
        isDamageViewLocationConfigLoading: false,
      };
    }
    case RECEIVE_SCAC_LIST: {
      return {
        ...state,
        scacList: !_.isEmpty(action.data)
          ? action.data
          : [{ scac: "", override_scac: "" }],
      };
    }
    case RECEIVE_LOCATIONS:
    case SEARCH_LOCATIONS:
      return {
        ...state,
        data: action.data,
      };
    case FETCH_UNRESOLVED_LOCATIONS:
      return {
        ...state,
        isLoadingUnresolvedLocations: true,
      };
    case RECEIVE_LOCATIONS_SUMMARY:
    case RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY:
    case SEARCH_LOCATIONS_SUMMARY:
      let idAttr;
      let existingData;
      if (action.type === RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY) {
        idAttr = "location_id";
        existingData = state.unresolvedLocationData;
      } else {
        idAttr = "id";
        existingData = state.data;
      }

      // Potential outdated data pitfall:
      // We're usually loading a summary of the locations when the app is loaded.
      // This poses a race condition: If the user is navigating directly to a location
      // URL, the request for loading the location list could return AFTER the
      // particular location details are already loaded, to prevent overwriting it,
      // check if a location already had its details loaded, if so, merge the incoming
      // summarized values but preserved the details.
      // If the summarized location list request takes too long to finish, we could be
      // merging outdated data!
      const summarizedData = action.data.map((summary) => {
        const existingLocation =
          existingData &&
          existingData.find(
            (loadedLoc) => _.get(loadedLoc, idAttr) === _.get(summary, idAttr),
          );

        if (existingLocation && !existingLocation.isSummary) {
          let existingMerged = existingLocation;
          // Merge the incoming fields from the summary, leave the rest of the details
          // as we loaded it initially.
          LOCATION_SUMMARY_FIELDS.forEach((field) =>
            _.set(existingMerged, field, _.get(summary, field)),
          );
          return existingMerged;
        } else {
          // Complete the rest of the geofence values in the summary.
          let tmpGeofence = getDefaultLocation().geofence;
          if (summary?.geofence?.properties?.center) {
            tmpGeofence.properties.center = summary.geofence.properties.center;
          }
          // Add an attribute to signal that each entry is a summary.
          return { ...summary, isSummary: true, geofence: tmpGeofence };
        }
      });

      if (action.type === RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY) {
        return {
          ...state,
          unresolvedLocationsData: summarizedData,
          isLoadingUnresolvedLocations: false,
        };
      } else {
        return {
          ...state,
          data: summarizedData,
          isLoadingUnresolvedLocations: false,
        };
      }

    case SET_PAGINATION:
      return { ...state, page: action.page, pageSize: action.pageSize };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        //...duck.initialState
      };
    case FETCH_ORG_LOCATIONS:
      return {
        ...state,
        isLoadingOrgLocations: true,
        isLoadingOrgLocationsFailed: false,
      };
    case RECEIVE_ORG_LOCATIONS:
      return {
        ...state,
        isLoadingOrgLocations: false,
        orgLocations: action.data,
      };
    case FETCH_ORG_LOCATIONS_FAILED:
      return {
        ...state,
        isLoadingOrgLocations: true,
        isLoadingOrgLocationsFailed: true,
      };

    case FETCH_LOCATION_CHILDREN:
      return {
        ...state,
        isLoadingLocationChildren: true,
        locationChildrenErrorMessage: null,
      };
    case RECEIVE_LOCATION_CHILDREN:
      return {
        ...state,
        isLoadingLocationChildren: false,
        locationChildren: action.data,
        locationChildrenTotalPages: action.totalPages,
      };
    case FETCH_LOCATION_CHILDREN_FAILED:
      return {
        ...state,
        isLoadingLocationChildren: false,
        locationChildrenErrorMessage: action.errorMessage,
      };
    case SET_LOCATION_CHILDREN_PAGINATION:
      return {
        ...state,
        locationChildrenPage: action.page,
        locationChildrenPageSize: action.pageSize,
      };
    case RESET_DDA_LOCATION_DATA:
      return {
        ...state,
        ddaLocationData: initialState.ddaLocationData,
      };

    default:
      return state;
  }
}

function HeatMapReducer(state = heatMapDuck.initialState, action = {}) {
  switch (action.type) {
    // If we get a response or an error, set the updated UID
    case heatMapDuck.actions.RECEIVE:
    case heatMapDuck.actions.REQUEST_ERROR:
      const newState = Object.assign({}, state, {
        heatMapUid: uuidv4(),
      });
      return heatMapDuck.reducer(newState, action);
    default:
      return heatMapDuck.reducer(state, action);
  }
}

function CountriesReducer(state = countriesDuck.initialState, action = {}) {
  switch (action.type) {
    case countriesDuck.actions.RECEIVE:
    case countriesDuck.actions.REQUEST_ERROR:
      return countriesDuck.reducer(state, action);
    default:
      return countriesDuck.reducer(state, action);
  }
}

function SubdivisionsReducer(
  state = {
    countryCode: null,
    ...subdivisionsDuck.initialState,
  },
  action = {},
) {
  switch (action.type) {
    case subdivisionsDuck.actions.RECEIVE:
    case subdivisionsDuck.actions.REQUEST_ERROR:
      return subdivisionsDuck.reducer(state, action);
    case CLEAR_SUBDIVISIONS:
      state.data = [];
      action.payload = [];
      return subdivisionsDuck.reducer(state, action);
    case SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload.countryCode,
      };
    default:
      return subdivisionsDuck.reducer(state, action);
  }
}

export default combineReducers({
  locations: LocationsReducer,
  heatmap: HeatMapReducer,
  countries: CountriesReducer,
  subdivisions: SubdivisionsReducer,
});
