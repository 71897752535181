/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import PageHeader from "modules/documentation/documentation-styled-components/PageHeader";
import ApiLogsSearchBar from "./ApiLogsSearchBar";
import ApiLogsSearchResults from "./ApiLogsSearchResults";
import ApiLogsAlert from "./ApiLogsAlert";
import { useApiLogs } from "./ApiLogsHooks";

const apiLogsCss = {
  padding: "1em",
};

// API Logs search dashboard
const ApiLogsView = () => {
  const { t } = useApiLogs();

  useSetTitleOnMount(t("documentation:Training & Resources"));

  useTrackWithMixpanelOnce("Viewed Page: Docs / API Logs");

  const dispatch = useDispatch();

  const pushShipmentDetailView = useCallback(
    (shipmentId) => {
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentId },
      });
    },
    [dispatch],
  );

  return (
    <div css={apiLogsCss}>
      <PageHeader title={t("documentation:API Logs")} />
      <ApiLogsSearchBar />
      <ApiLogsAlert />
      <ApiLogsSearchResults pushShipmentDetailView={pushShipmentDetailView} />
    </div>
  );
};

export default ApiLogsView;
