/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";
import { useGetCountryNameByCountryCode } from "pages/administration/utils/location-utils";

// Note: This is a duplicate of components/organisms/base-table/Cell/LocationCell.js
// Eventually, when we move everything to using BaseTable, we can get rid of this.
// If upgrading something to use BaseTable, and it's referencing this Cell component,
// you can switch to using the one above without issue.
const LocationCell = ({
  styles = {},
  location,
  showAddress,
  deliveryType = null,
}) => {
  const { t } = useTranslation(["components", "domain"]);
  const countryName = useGetCountryNameByCountryCode(location.country);

  let deliveryTypeTranslated = null;
  switch (deliveryType?.toLowerCase()) {
    case "attended": {
      deliveryTypeTranslated = t("components:Attended");
      break;
    }
    case "unattended": {
      deliveryTypeTranslated = t("components:Unattended");
      break;
    }
    default: {
      deliveryTypeTranslated = null;
    }
  }

  return (
    <td style={styles}>
      <Text bold block size={FontSize.size12}>
        {location.code}
      </Text>
      <Text block size={FontSize.size12}>
        {location.name}
      </Text>
      {showAddress ? (
        <React.Fragment>
          <Text block size={FontSize.size12}>
            {location.address}
          </Text>
          <Text block size={FontSize.size12}>
            {location.city}
            {location.city && location.state ? ", " : ""}
            {location.state}
            {location.postalCode && " "}
            {location.postalCode}
          </Text>
          {countryName ? <span>{countryName}</span> : null}
        </React.Fragment>
      ) : null}
      {deliveryTypeTranslated ? (
        <Text bold block size={FontSize.size12}>
          {t("components:Delivery Type")}: {deliveryTypeTranslated}
        </Text>
      ) : null}
    </td>
  );
};

LocationCell.propTypes = {
  styles: PropTypes.object,
  location: PropTypes.object,
  showAddress: PropTypes.bool,
  deliveryType: PropTypes.string,
};

export default LocationCell;
