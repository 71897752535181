import { buildAsyncCategoryOptionsState } from "components/search-bar/AsyncCategoryOptionsStateBuilder";
import {
  BILL_OF_LADING_NUMBERS_URL,
  TRAILER_EQUIPMENT_NUMBERS_URL,
  HOUSE_REFERENCE_NUMBERS_URL,
  STCC_CODE_URL,
} from "modules/domain-data/ShipmentsDomainDataState";

export const billOfLadingNumberCategoryLoaderState =
  buildAsyncCategoryOptionsState({
    topic: "BILL_OF_LADING_NUMBER",
    url: BILL_OF_LADING_NUMBERS_URL,
    getResponseData: (data) => data?.bill_of_lading_number ?? [],
    transformResult: (bol) => ({ label: bol, value: bol }),
    debounceWaitTimeInMs: 500,
  });

export const trailerAndEquipmentNumberLoaderState =
  buildAsyncCategoryOptionsState({
    topic: "TRAILER_AND_EQUIPMENT_NUMBER",
    url: TRAILER_EQUIPMENT_NUMBERS_URL,
    getResponseData: (data) => data?.trailer_equipment_number ?? [],
    transformResult: (number) => ({ label: number, value: number }),
    debounceWaitTimeInMs: 500,
  });

export const houseReferenceNumberLoaderState = buildAsyncCategoryOptionsState({
  topic: "HOUSE_REFERENCE_NUMBER",
  url: HOUSE_REFERENCE_NUMBERS_URL,
  getResponseData: (data) => data?.house_reference_number ?? [],
  transformResult: (number) => ({ label: number, value: number }),
  debounceWaitTimeInMs: 500,
});

export const stccCodeLoaderState = buildAsyncCategoryOptionsState({
  topic: "STCC_CODE",
  url: STCC_CODE_URL,
  getResponseData: (data) => data?.stcc_code ?? [],
  transformResult: (code) => ({ label: code, value: code }),
  debounceWaitTimeInMs: 500,
});
