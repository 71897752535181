/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import PropTypes from "prop-types";
import Loader from "react-loader";

import { useTranslation } from "react-i18next";

import ContentJson from "./documentation-styled-components/ContentJson";
import ContentPdf from "./documentation-styled-components/ContentPdf";
import LinkPdf from "./documentation-styled-components/LinkPdf";
import ContentHtml from "./documentation-styled-components/ContentHtml";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

const contentCss = {
  height: "100%",
  display: "flex",
  flex: "1 1 auto",
  "> .loadedContent": {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
};

export const ContentView = ({
  filename,
  baseUrl,
  content,
  isLoading,
  pushDocumentView,
  fetchContent,
}) => {
  const { t } = useTranslation("documentation");

  useSetTitleOnMount(t("documentation:Training & Resources"));

  useTrackWithMixpanelOnce(`Viewed Page: Docs / Content / ${filename}`);

  useEffect(() => {
    if (!baseUrl) {
      fetchContent(filename);
    }
  }, [filename, baseUrl, fetchContent]);

  const isJson =
    filename.includes(".json") &&
    typeof content === "object" &&
    !Array.isArray(content);
  const isUrl = baseUrl && baseUrl.length > 0;
  const isPdf =
    filename.includes(".pdf") && (isUrl || typeof content === "string");
  const isHtml = filename.includes(".htm") && typeof content === "string";

  return (
    <div css={contentCss}>
      <Loader loaded={!isLoading}>
        {isJson ? (
          <ContentJson content={content} pushDocumentView={pushDocumentView} />
        ) : null}
      </Loader>
      {isPdf && !isUrl ? (
        <ContentPdf filename={filename} content={content} />
      ) : null}
      {isPdf && isUrl ? (
        <LinkPdf filename={filename} baseUrl={baseUrl} />
      ) : null}
      {isHtml ? <ContentHtml content={content} /> : null}
    </div>
  );
};

ContentView.propTypes = {
  filename: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  pushDocumentView: PropTypes.func.isRequired,
  fetchContent: PropTypes.func.isRequired,
};
