import _ from "lodash";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { SHIPMENT_DWELL_URL } from "./ShipmentDwellNotificationSearchBarState";

const STORE_MOUNT_POINT = "shipmentDwellNotificationManagement";

// URLs
const CONFIGURE_DWELL_NOTIFICATION_URL = apiUrl(
  "/notification/dwell/configure",
);

const modifyDwellDuck = buildFetchDuck(STORE_MOUNT_POINT, "modifyDwell");
const prefillDuck = buildFetchDuck(STORE_MOUNT_POINT, "prefill");
const locationDuck = buildFetchDuck(STORE_MOUNT_POINT, "location");

// Action Creators

function modifyDwellNotification(locationCode, recipients = []) {
  return (dispatch) => {
    const requestConfig = {
      method: "POST",
      data: {
        recipients: recipients
          // Remove any recipients that don't have a status, because that means they came from the API
          // and don't have any changes (any changes would have set the status to "updated")
          .filter((recipient) => !_.isNil(recipient.status))
          .map((recipient) => {
            // Deleted recipients only need an id and status
            if (recipient.status === "deleted") {
              return {
                id: recipient.id,
                status: recipient.status,
              };
            }

            // Created and updated recipients need everything else
            return {
              id: recipient.id, // Created recipients don't have this
              location_code: locationCode,
              name: recipient.name,
              shift_start: recipient.startTime,
              shift_end: recipient.endTime,
              alert_level: recipient.alertLevel,
              phone_number: recipient.phoneNumber,
              status: recipient.status,
            };
          }),
      },
    };
    return dispatch(
      modifyDwellDuck.fetch(CONFIGURE_DWELL_NOTIFICATION_URL, requestConfig),
    );
  };
}

function fetchPrefillData(code) {
  return (dispatch) => {
    const prefillFetchAction = prefillDuck.fetch(
      SHIPMENT_DWELL_URL,
      {
        params: {
          prefill: "1",
          locationCode: code,
          pageSize: 10000,
        },
      },
      (response) => {
        return response?.recipients?.map((recipient) => {
          return {
            id: recipient.id,
            name: recipient?.name,
            phoneNumber: recipient?.phone_number?.[0],
            alertLevel: recipient?.alert_level,
            startTime: recipient?.shift_start,
            endTime: recipient?.shift_end,
          };
        });
      },
    );

    const locationFetchAction = locationDuck.fetch(
      apiUrl("/location/locations"),
      {
        params: {
          code: code,
        },
      },
    );

    return Promise.all([
      dispatch(prefillFetchAction),
      dispatch(locationFetchAction),
    ]);
  };
}

function clearModifyDwellNotification() {
  return (dispatch) => {
    dispatch(modifyDwellDuck.clear());
  };
}

function clearPrefillData() {
  return (dispatch) => {
    dispatch(prefillDuck.clear());
    dispatch(locationDuck.clear());
  };
}

// Selectors

const getLocation = (state) => {
  const { data = [] } = locationDuck.selectors.getData(state);
  return data?.[0] ?? null;
};

const getRecipients = (state) => {
  const { data = [] } = prefillDuck.selectors.getData(state);
  return data;
};

const getIsPrefillDataLoading = (state) => {
  const { isLoading: isLocationLoading = false } =
    locationDuck.selectors.getData(state);
  const { isLoading: isRecipientsLoading = false } =
    prefillDuck.selectors.getData(state);

  return isLocationLoading || isRecipientsLoading;
};

const getIsModifyDwellNotificationLoading = (state) => {
  return modifyDwellDuck.selectors.getData(state)?.isLoading;
};

const getModifyDwellResponse = (state) => {
  const { isLoadingError, loadingError, status } =
    modifyDwellDuck.selectors.getData(state);
  return {
    isLoadingError,
    loadingError,
    status,
  };
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    modifyDwellNotification,
    fetchPrefillData,
    clearPrefillData,
    clearModifyDwellNotification,
  },
  selectors: {
    getLocation,
    getRecipients,
    getIsPrefillDataLoading,
    getIsModifyDwellNotificationLoading,
    getModifyDwellResponse,
  },
  reducer: chainReducers(
    [modifyDwellDuck.reducer, prefillDuck.reducer, locationDuck.reducer],
    {
      modifyDwell: { data: null, isLoading: false },
      prefill: { data: [], isLoading: false },
      location: { data: [], isLoading: false },
    },
  ),
};
