import { connect } from "react-redux";
import ReportSchedulesState from "../../redux/ReportSchedulesState";
import { EmailScheduleModal } from "./EmailReport.modals";

const { getCreateScheduleData, getUpdateScheduleData, getDeleteScheduleData } =
  ReportSchedulesState.selectors;

const { createSchedule, updateSchedule, deleteSchedule } =
  ReportSchedulesState.actionCreators;

const mapStateToProps = (state) => {
  return {
    createScheduleData: getCreateScheduleData(state),
    updateScheduleData: getUpdateScheduleData(state),
    deleteScheduleData: getDeleteScheduleData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSchedule: (...args) => dispatch(createSchedule(...args)),
    updateSchedule: (...args) => dispatch(updateSchedule(...args)),
    deleteSchedule: (...args) => dispatch(deleteSchedule(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailScheduleModal);
