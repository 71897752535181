/**
 * Implements MapPlatformInterface for Here Maps.
 */
import _ from "lodash";

import MapPlatformInterface from "../MapPlatformInterface";
import GeofenceType, {
  getBoundingBox,
  getType,
  getCenter,
  isFenceValid,
} from "../../../geofence-edit/geofence-types";
import HereMapsShapes from "./HereMapsShapes";

class HereMapPlatform extends MapPlatformInterface {
  static get name() {
    return "HERE";
  }

  constructor(baseMap) {
    super(baseMap);

    const { hereMaps } = this.props;
    this.shapes = new HereMapsShapes(hereMaps);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  // Specific drag stuff for Here Maps
  onDragStart(e) {
    const { hereMaps, enableGeofenceBuilder, enableDraggingGeofence } =
      this.props;

    // Disable the default draggability of the underlying map
    // when starting to drag a marker object
    let target = e.target,
      pointer = e.currentPointer;

    if (
      enableGeofenceBuilder &&
      enableDraggingGeofence &&
      (target instanceof hereMaps.map.Marker ||
        target instanceof hereMaps.map.Polygon ||
        target instanceof hereMaps.map.Circle)
    ) {
      // Set original lat / lng
      const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      this.dragStartLat = pos.lat;
      this.dragLat = pos.lat;
      this.dragStartLng = pos.lng;
      this.dragLng = pos.lng;

      if (target instanceof hereMaps.map.Marker) {
        document.body.style.cursor = "grabbing";
      }
      this.hereMapsBehavior.disable();
    }
  }

  onDrag(e) {
    const {
      hereMaps,
      enableGeofenceBuilder,
      isTracing,
      enableDraggingGeofence,
    } = this.props;
    let target = e.target;
    let pointer = e.currentPointer;

    if (
      !isTracing &&
      enableGeofenceBuilder &&
      enableDraggingGeofence &&
      (target instanceof hereMaps.map.Marker ||
        target instanceof hereMaps.map.Polygon ||
        target instanceof hereMaps.map.Circle)
    ) {
      const newPos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      if (target instanceof hereMaps.map.Marker) {
        target.setGeometry(newPos);
      }

      // Update radial geofence
      if (target instanceof hereMaps.map.Circle) {
        this.updateCircle(newPos, target);
      }
      // Update polygonal geofence
      else {
        // Get the group this target belongs to and update every member's position.
        this.mapObjects[target.getData().groupId].forEach((c) => {
          if (c instanceof hereMaps.map.Polygon) {
            this.updatePolygon(target, newPos, c);
          } else if (
            c instanceof hereMaps.map.Marker &&
            target instanceof hereMaps.map.Polygon
          ) {
            this.updateControlPoint(newPos, c);
          }
        });
      }

      this.dragLat = newPos.lat;
      this.dragLng = newPos.lng;
    }
  }

  onDragEnd(e) {
    const {
      hereMaps,
      onDragPolygonalGeofence,
      onDragRadialGeofence,
      onDragGeofenceControlPoint,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    // Re-enable the default draggability of the underlying map
    // when dragging has completed
    let target = e.target,
      pointer = e.currentPointer;
    if (enableGeofenceBuilder && enableDraggingGeofence) {
      if (target instanceof hereMaps.map.Marker) {
        const targetData = target.getData();
        onDragGeofenceControlPoint(
          Number(targetData.polygonIndex),
          Number(targetData.pointIndex),
          this.map.screenToGeo(pointer.viewportX, pointer.viewportY),
        );
        document.body.style.cursor = "grab";
      } else if (target instanceof hereMaps.map.Circle) {
        onDragRadialGeofence(
          this.dragStartLat - this.dragLat,
          this.dragStartLng - this.dragLng,
        );
      } else if (target instanceof hereMaps.map.Polygon) {
        onDragPolygonalGeofence(
          Number(target.getData().polygonIndex),
          this.dragStartLat - this.dragLat,
          this.dragStartLng - this.dragLng,
        );
      }
      this.hereMapsBehavior.enable();
    }
  }

  updateCircle(newPos, circle) {
    const movementLat = this.dragLat - newPos.lat;
    const movementLng = this.dragLng - newPos.lng;
    const currentPosition = circle.getCenter();
    circle.setCenter({
      lat: currentPosition.lat - movementLat,
      lng: currentPosition.lng - movementLng,
    });
  }

  updateControlPoint(newPos, point) {
    const movementLat = this.dragLat - newPos.lat;
    const movementLng = this.dragLng - newPos.lng;
    const currentPosition = point.getGeometry();
    point.setGeometry({
      lat: currentPosition.lat - movementLat,
      lng: currentPosition.lng - movementLng,
    });
  }

  // Methods to help keeping signatures simpler inside platform implementations
  get hereMapsBehavior() {
    return this.baseMap.hereMapsBehavior;
  }

  set hereMapsBehavior(data) {
    this.baseMap.hereMapsBehavior = data;
  }

  get hereMapsUI() {
    return this.baseMap.hereMapsUI;
  }

  set hereMapsUI(data) {
    this.baseMap.hereMapsUI = data;
  }

  createRasterTileLayer = (style) => {
    const { hereMaps, hereMapsPlatform } = this.props;

    // Found this method in the React docs for HERE Maps
    // https://www.here.com/docs/bundle/maps-api-for-javascript-developer-guide/page/topics/react-practices.html
    const rasterTileService = hereMapsPlatform.getRasterTileService({
      queryParams: {
        style: style,
        size: 512,
        lang: "en",
      },
    });

    const rasterTileProvider = new hereMaps.service.rasterTile.Provider(
      rasterTileService,
    );

    return new hereMaps.map.layer.TileLayer(rasterTileProvider);
  };

  _defaultMapLayers = {
    day: null,
    night: null,
    satellite: null,
    terrain: null,
  };

  // Initialization
  initMap(defaultCenter) {
    if (this.map) {
      return;
    }
    const { hereMaps } = this.props;

    // Manually creating layers because platform.createDefaultLayers uses deprected APIs
    // Available styles: https://www.here.com/docs/bundle/raster-tile-api-developer-guide/page/topics/styles.html
    this._defaultMapLayers.day = this.createRasterTileLayer("explore.day");
    this._defaultMapLayers.night = this.createRasterTileLayer("explore.night");
    this._defaultMapLayers.satellite = this.createRasterTileLayer(
      "explore.satellite.day",
    );
    this._defaultMapLayers.terrain = this.createRasterTileLayer("topo.day");

    this.map = new hereMaps.Map(this.mapDiv, this._defaultMapLayers.day, {
      zoom: 5,
      center: defaultCenter,
      // engineType: Other option is WebGL, which renders in 3D.
      // It works, and has some minor changes to the UI (eg: the arrows look different for
      // actual routes), but unfortunately it creates a lot of lag when dragging polygons.
      // Setting this to P2D (eg: 2D canvas maps) resolved the lagginess.
      engineType: hereMaps.map.render.RenderEngine.EngineType.P2D,
    });

    this.hereMapsBehavior = new hereMaps.mapevents.Behavior(
      new hereMaps.mapevents.MapEvents(this.map),
    );

    // Add map listeners
    this.map.addEventListener("dragstart", this.onDragStart, false);
    this.map.addEventListener("drag", this.onDrag, false);
    this.map.addEventListener("dragend", this.onDragEnd, false);
    this.map.addEventListener("pointermove", this.onMouseMove, false);
    this.map.addEventListener("tap", this.onTap, false);

    // Not using H.ui.UI.createDefault because it requires layers from platform.createDefaultLayers
    //
    // API Reference
    // https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.ui.UI.html#.Options
    this.hereMapsUI = new hereMaps.ui.UI(this.map, {
      unitSystem: hereMaps.ui.UnitSystem.IMPERIAL,
      zoom: {
        alignment: hereMaps.ui.LayoutAlignment.RIGHT_BOTTOM,
      },
      // API Reference
      // https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.ui.MapSettingsControl.html#.Options
      mapsettings: {
        alignment: hereMaps.ui.LayoutAlignment.BOTTOM_RIGHT,
        baseLayers: [
          { label: "Map view", layer: this._defaultMapLayers.day },
          { label: "Satellite", layer: this._defaultMapLayers.satellite },
          { label: "Terrain", layer: this._defaultMapLayers.terrain },
        ],
        // These are overlayed on top of the selected base layer.
        // Supporting the traffic incidents would require upgrading to use the vector map
        // since the raster version is deprecated (from library and direct API)
        // layers: [
        //   // Additional layers defined by H.ui.UI.createDefault
        //   { label: "Show traffic incidents", layer: undefined },
        //   { label: "Public transport", layer: undefined },
        // ],
      },
      scalebar: {
        alignment: hereMaps.ui.LayoutAlignment.BOTTOM_RIGHT,
      },
    });
  }

  initHeatMap(coords) {
    const { hereMaps } = this.props;

    // Set map to dark mode
    // Using this._defaultMapLayers.night does not work because each layer can
    // be null when referenced by this function. Probably an issue when
    // referencing `this` and that is not bound correctly.
    const nightLayer = this.createRasterTileLayer("explore.night");
    this.map.setBaseLayer(nightLayer);

    // Create a provider for a semi-transparent heat map
    let heatmapProvider = new hereMaps.data.heatmap.Provider({
      colors: hereMaps.data.heatmap.Colors.DEFAULT,
      opacity: 0.7,
    });

    // Generate data
    let coords_data = _.map(coords, (c) => {
      return {
        lat: Number.parseFloat(c.latitude),
        lng: Number.parseFloat(c.longitude),
      };
    });

    // Add the data
    heatmapProvider.addData(coords_data);

    // Create TileLayer
    let layer = new hereMaps.map.layer.TileLayer(heatmapProvider);

    // Add a layer for the heatmap provider to the map
    this.mapLayers["heatmap_layer"] = layer;
    this.map.addLayer(layer);
  }

  deinitHeatMap() {
    // Switch back to the normal map
    // Using this._defaultMapLayers.night does not work because each layer can
    // be null when referenced by this function. Probably an issue when
    // referencing `this` and that is not bound correctly.
    const dayLayer = this.createRasterTileLayer("explore.day");
    this.map.setBaseLayer(dayLayer);
  }

  // Events
  onTap(e) {
    const { hereMaps, isTracing, addPointToTrace, onPolygonDrawEnd } =
      this.props;

    if (isTracing) {
      // Tapped on a marker while tracing.
      if (e.target instanceof hereMaps.map.Marker) {
        const data = e.target.getData();
        if (data.pointIndex === 0) {
          onPolygonDrawEnd();
        }
      } else {
        // Didn't click on a marker, add a point here.
        const pointer = e.currentPointer;
        const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
        addPointToTrace(pos);
      }
    }
  }

  onMouseMove(e) {
    const { hereMaps, enableGeofenceBuilder, isTracing } = this.props;

    if (isTracing) {
      const pointer = e.currentPointer;
      const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      this.setState({ mouseGeoCoords: pos });

      document.body.style.cursor = "default";

      if (e.target instanceof hereMaps.map.Marker) {
        const data = e.target.getData();
        if (data && data.pointIndex === 0) {
          // Hovering over the first point while tracing, show that clicking it will
          // complete the polygon.
          document.body.style.cursor = "crosshair";
        } else {
          // Tracing and hovering over nothing important, default icon.
          document.body.style.cursor = "default";
        }
      }
    } else if (
      e.target instanceof hereMaps.map.Marker &&
      enableGeofenceBuilder
    ) {
      const data = e.target.getData();
      if (data && !("lad" in data)) {
        document.body.style.cursor = "grab";
      }
    } else if (
      (e.target instanceof hereMaps.map.Polygon ||
        e.target instanceof hereMaps.map.Circle) &&
      enableGeofenceBuilder
    ) {
      document.body.style.cursor = "move";
    } else {
      document.body.style.cursor = "default";
    }
  }

  // Events Related
  addMapEventListener(eventName, callback) {
    const hereEventsTable = {
      mapviewchange: "mapviewchangeend",
    };
    this.map.addEventListener(hereEventsTable[eventName], callback);
  }

  addMapMarkerEventListener(marker, eventName, callback) {
    const hereEventsTable = {
      click: "tap",
      mouseenter: "pointerenter",
      mouseout: "pointerleave",
    };
    marker.addEventListener(hereEventsTable[eventName], callback);
  }

  // Drawing
  addUIButton(label, clickCallback) {
    const { hereMaps } = this.props;

    // Add custom UI control
    const myCustomControl = new hereMaps.ui.Control();
    myCustomControl.addClass("heremaps-custombutton-wrapper");

    // Add control to map
    this.hereMapsUI.addControl(label, myCustomControl);

    // Set the position of the control in the UI's layout
    myCustomControl.setAlignment(hereMaps.ui.LayoutAlignment.BOTTOM_CENTER);

    // Create custom button
    const myCustomButton = new hereMaps.ui.base.Button({
      label: label,
      // Add listener
      onStateChange: (evt) => {
        if (evt.currentTarget.getState() === "up") {
          clickCallback();
        }
      },
    });

    // Add custom styles
    myCustomButton.addClass("heremaps-custombutton");

    // Add button to control
    myCustomControl.addChild(myCustomButton);
  }

  createAndAddMapMarker(
    name,
    pos,
    zIndex,
    data,
    isClickable,
    iconSvg,
    iconHeight,
    iconWidth,
    iconDefaultHeight,
    iconDefaultWidth,
    iconXOffsetForGoogle,
    iconYOffsetForGoogle,
    iconXAnchorForHERE,
    iconYAnchorForHERE,
  ) {
    const { hereMaps } = this.props;

    const marker = new hereMaps.map.Marker(pos);

    if (zIndex) {
      marker.setZIndex(zIndex);
    }

    if (data) {
      marker.setData(data);
    }

    let icon = null;
    let iconSize = null;
    if (iconHeight && iconWidth) {
      iconSize = { h: iconHeight, w: iconWidth };
    }

    let iconAnchor = null;
    if (iconXAnchorForHERE && iconYAnchorForHERE) {
      iconAnchor = { x: iconXAnchorForHERE, y: iconYAnchorForHERE };
    }

    if (iconSvg) {
      if (iconSize && iconAnchor) {
        icon = new hereMaps.map.Icon(iconSvg, {
          size: iconSize,
          anchor: iconAnchor,
        });
      } else if (iconSize) {
        icon = new hereMaps.map.Icon(iconSvg, {
          size: iconSize,
        });
      } else if (iconAnchor) {
        icon = new hereMaps.map.Icon(iconSvg, {
          anchor: iconAnchor,
        });
      } else {
        icon = new hereMaps.map.Icon(iconSvg);
      }

      if (icon) {
        marker.setIcon(icon);
      }
    }

    this.map.addObject(marker);
    this.mapObjects[name] = marker;

    return marker;
  }

  createAndAddMapPolyline(
    name,
    linestring,
    zIndex,
    lineWidth,
    lineJoin,
    hasArrows,
  ) {
    const { hereMaps } = this.props;

    let routeLine = new hereMaps.map.Polyline(linestring, {
      style: {
        lineWidth: lineWidth,
        lineJoin: lineJoin,
        // To display arrows in WebGL maps.
        // Note: We use P2D. Leaving this here for forwards compatibility in case we change it.
        lineDash: hasArrows ? [1] : [0],
        lineTailCap: hasArrows ? "arrow-tail" : "round",
        lineHeadCap: hasArrows ? "arrow-head" : "round",
      },
      // To display arrows in P2D maps. This will be unnecessary if we switch to WebGL maps.
      arrows: hasArrows ? new hereMaps.map.ArrowStyle({ frequency: 1 }) : false,
    });

    routeLine.setZIndex(zIndex);

    this.map.addObjects([routeLine]);
    this.mapObjects[name] = routeLine;

    return routeLine;
  }

  createMapLineString() {
    const { hereMaps } = this.props;

    return new hereMaps.geo.LineString();
  }

  createMapMultiLineString(lineStrings) {
    const { hereMaps } = this.props;

    return new hereMaps.geo.MultiLineString(lineStrings);
  }

  createAndAddMapInfoBubble(pos, content) {
    const { hereMaps } = this.props;

    // Improve positioning of bubble once heremaps puts the bubble over the
    // marker by default
    const bubbleXY = this.map.geoToScreen(pos);
    const bubble = new hereMaps.ui.InfoBubble(
      this.map.screenToGeo(bubbleXY.x, bubbleXY.y - 20),
      {
        content,
      },
    );
    this.hereMapsUI.addBubble(bubble);
  }

  addMapLineStringLatLong(linestring, lat, lng) {
    linestring.pushLatLngAlt(lat, lng);
  }

  convertFlexiblePolylineToLineString(flexiblePolyline) {
    const { hereMaps } = this.props;

    return hereMaps.geo.LineString.fromFlexiblePolyline(flexiblePolyline);
  }

  drawGeofence(location, geofenceGroup, geofenceMapId, lad) {
    // Radial geofences groups are just the circle itself.
    if (getType(location.geofence) === GeofenceType.RADIAL) {
      this.map.addObject(geofenceGroup);
      this.mapObjects[geofenceMapId] = geofenceGroup;
    } else {
      // Polygon and MultiPolygon geofences are a list of groups: each one with
      // a polygon and control points.
      geofenceGroup.forEach((group, index) => {
        this.map.addObject(group);
        this.mapObjects[group.getData().groupId] = group;
      });
    }
  }

  addTraceGroup(traceGroup) {
    this.map.addObject(traceGroup);
    this.mapObjects[traceGroup.getData().groupId] = traceGroup;
  }

  // Clearing
  clearInfoBubbles() {
    let bubbles = this.hereMapsUI.getBubbles();

    if (!_.isEmpty(bubbles)) {
      bubbles.forEach((b) => {
        this.hereMapsUI.removeBubble(b);
      });
    }
  }

  clearMap(excludeKeyPrefixes = []) {
    // Clear objects
    let delList = [];
    if (!_.isNil(this.mapObjects) && !_.isEmpty(this.mapObjects)) {
      _.forOwn(this.mapObjects, (val, key) => {
        // Don't clear objects with excluded keys
        if (
          excludeKeyPrefixes.length === 0 ||
          !_.some(
            _.map(excludeKeyPrefixes, (w) => key.includes(w)),
            Boolean,
          )
        ) {
          try {
            this.map.removeObject(val);
            delList.push(key);
          } catch (ex) {}
        }
      });

      // Only clear mapObjects that have been removed from the map above
      delList.forEach((key) => {
        delete this.mapObjects[key];
      });

      // H1-1577: Remove any objects still attached to map
      try {
        this.map.getObjects().forEach((o) => {
          // Don't clear objects with excluded keys
          if (
            excludeKeyPrefixes.length === 0 ||
            !_.some(
              _.map(excludeKeyPrefixes, (w) => o.data?.groupId?.includes(w)),
              Boolean,
            )
          ) {
            this.map.removeObject(o);
          }
        });
      } catch (ex) {}
    }

    // Clear layers
    if (!_.isNil(this.mapLayers) && !_.isEmpty(this.mapLayers)) {
      _.forOwn(this.mapLayers, (val, key) => {
        this.map.removeLayer(val);
      });

      this.mapLayers = {};
    }

    this.mapLayers = {};
  }

  clearMapMarkers(prefix) {
    let delList = [];
    for (let key in this.mapObjects) {
      if (key.includes(prefix)) {
        this.map.removeObject(this.mapObjects[key]);
        delList.push(key);
      }
    }
    delList.forEach((value) => {
      delete this.mapObjects[value];
    });
  }

  // Actions
  setMapViewBounds(boundsRect) {
    this.map.getViewModel().setLookAtData(
      {
        bounds: boundsRect,
      },
      true,
    );
  }

  setMapCenter(position) {
    if (position.lat && position.lng) {
      this.map.setCenter(position);
    }
  }

  resizeMap() {
    if (this.map) {
      this.map.getViewPort().resize();
    }
  }

  setZoom(zoom) {
    this.map.getViewModel().setLookAtData({
      zoom: zoom,
    });
  }

  getZoom() {
    return this.map.getViewModel().getLookAtData().zoom;
  }

  zoomSingleLocation(location) {
    const { geofence } = location;

    if (isFenceValid(geofence)) {
      const { hereMaps } = this.props;
      const bounds = getBoundingBox(geofence);
      this.setMapViewBounds(new hereMaps.geo.Rect(...bounds));
      const centerPos = getCenter(geofence);
      this.setMapCenter(centerPos);
    } else {
      this.setZoom(11);
      this.setMapCenter({ lat: location.latitude, lng: location.longitude });
    }
  }

  zoomLocations(locations) {
    const points = locations.map((item) => [item.longitude, item.latitude]);

    const poly = new this.shapes.api.map.Polyline(
      this.shapes.lineString(points),
    );
    this.setMapViewBounds(poly.getBoundingBox());
  }

  updatePolygon(target, newPos, polygon) {
    const { hereMaps } = this.props;

    let newLatLngArray = [];
    let geometry = polygon.getGeometry();
    const latLngArray = geometry.getExterior().getLatLngAltArray();

    // If a control point is being dragged, update that point. Otherwise move the entire polygon
    if (target instanceof hereMaps.map.Polygon) {
      const movementLat = this.dragLat - newPos.lat;
      const movementLng = this.dragLng - newPos.lng;

      newLatLngArray = latLngArray.map((l, i) => {
        if (i % 3 === 0) {
          return l - movementLat;
        } else if (i % 3 === 1) {
          return l - movementLng;
        } else {
          return l;
        }
      });
    } else {
      const coords = _.uniqBy(convertLatLngArrayToCoords(latLngArray), (item) =>
        JSON.stringify(item),
      );
      const index = getControlPointIndex(coords, newPos.lat, newPos.lng);
      coords[index] = [newPos.lng, newPos.lat];
      newLatLngArray = convertCoordsToArray(coords);
    }

    geometry
      .getExterior()
      .spliceLatLngAlts(0, latLngArray.length, newLatLngArray);
    polygon.setGeometry(geometry);
  }

  mergeBounds(bounds1, bounds2) {
    return bounds1.mergeRect(bounds2);
  }

  removeMapObject(name) {
    this.map.removeObject(this.mapObjects[name]);
  }

  captureScreenshot() {
    return new Promise((resolve, reject) => {
      this.map.capture(
        function (canvas) {
          if (canvas) {
            resolve(canvas);
          } else {
            // Failed. For example, when map is in Panorama mode.
            console.warn("Could not capture screenshot.");
            reject("Could not capture screenshot.");
          }
        },
        [this.hereMapsUI],
        0,
        0,
        400,
        400,
      );
    });
  }

  // Helpers
  isMapViewBoundsContainPoint(pos) {
    const bounds = this.map.getViewModel().getLookAtData().bounds;
    return bounds.getBoundingBox().containsPoint(pos);
  }

  isMapViewBoundsContainLatLng(lat, lng) {
    const bounds = this.map.getViewModel().getLookAtData().bounds;
    // Default to false if bounds is undefined.
    return bounds?.getBoundingBox()?.containsLatLng(lat, lng) ?? false;
  }

  getBoundsRect(pos, boundsBuffer = 0.01) {
    const { hereMaps } = this.props;

    return new hereMaps.geo.Rect(
      pos.lat - boundsBuffer,
      pos.lng - boundsBuffer,
      pos.lat + boundsBuffer,
      pos.lng + boundsBuffer,
    );
  }
}

const convertLatLngArrayToCoords = (arr) => {
  let coords = [];
  arr.forEach((l, i) => {
    if (i % 3 === 0) {
      coords.push([arr[i + 1], l]);
    }
  });

  return coords;
};

const convertCoordsToArray = (coords) => {
  let latLngArray = [];
  coords.forEach((c) => {
    latLngArray.push(c[1]);
    latLngArray.push(c[0]);
    latLngArray.push(100);
  });

  return latLngArray;
};

const getControlPointIndex = (coords, lat, lng) => {
  let index = 0;
  let distance = 9999;
  coords.forEach((c, i) => {
    const dist = Math.sqrt(Math.pow(c[0] - lng, 2) + Math.pow(c[1] - lat, 2));
    if (dist <= distance) {
      index = i;
      distance = dist;
    }
  });

  return index;
};

export default HereMapPlatform;
