/** @jsxImportSource @emotion/react */
import Colors from "../../styles/colors";

const ContentSectionOverlay = () => {
  return (
    <div
      css={{
        position: "absolute",
        // Expecting 100% of ContentSection's height
        height: "100%",
        // Arbitrary z-index
        // Needs to be greater than the z-index's in ContentSection children
        zIndex: 98,
        backgroundColor: Colors.background.GRAY_OVERLAY,
        width: "100%",
      }}
    >
      <div
        style={{
          // Positioning
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // Size
          width: "20em",
          height: "2em",
          // Font Styling
          color: "white",
          fontSize: "1.4em",
          textAlign: "center",
          fontWeight: 600,
          // Styling
          backgroundColor: "rgb(38, 133, 168)",
          border: "1px solid rgb(33, 100, 126)",
          borderRadius: "5px",
          // Children layout
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Select a filter to view data
      </div>
    </div>
  );
};

export default ContentSectionOverlay;
