/** @jsxImportSource @emotion/react */
import { Tabs } from "components/molecules/Tabs.molecule";
import PropTypes from "prop-types";
import Colors from "styles/colors";

export const Dashboard = ({
  SearchBarContainer,
  FiltersContainer,
  showFilters,
  toggleShowFilters,
  children,
}) => {
  return (
    <div css={{ padding: "1em" }}>
      {/* Separate div for the search and container.
       *
       * This prevented an issue with the dashboard children where it would "ignore"
       * the search bar and display on top of or under it at some screen sizes in Safari.
       */}
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {SearchBarContainer ? (
          <SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() => toggleShowFilters(!showFilters)}
          />
        ) : null}
        {FiltersContainer ? (
          <FiltersContainer
            show={showFilters}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ paddingRight: "8em" }}
          />
        ) : null}
      </div>
      {children}
    </div>
  );
};

Dashboard.propTypes = {
  SearchBarContainer: PropTypes.elementType,
  FiltersContainer: PropTypes.elementType,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func,
  children: PropTypes.any,
};

const DashboardTabs = (props) => <Tabs fullWidthTabs {...props} />;
Dashboard.Tabs = DashboardTabs;

const DashboardTabList = (props) => <Tabs.TabList {...props} />;
DashboardTabList.tabsRole = "TabList";
Dashboard.TabList = DashboardTabList;

const DashboardTab = (props) => <Tabs.Tab {...props} />;
DashboardTab.tabsRole = "Tab";
Dashboard.Tab = DashboardTab;

const DashboardTabPanel = ({ children, styles = {}, ...props }) => {
  return (
    <Tabs.TabPanel {...props}>
      <div css={{ padding: "1em", ...styles }}>{children}</div>
    </Tabs.TabPanel>
  );
};
DashboardTabPanel.propTypes = {
  styles: PropTypes.object,
  children: PropTypes.any,
};
DashboardTabPanel.tabsRole = "TabPanel";
Dashboard.TabPanel = DashboardTabPanel;
