import { connect } from "react-redux";

import { LocationsView } from "./LocationsView";
import LocationSearchBarState from "../location-search/LocationSearchBarState";

import { getAuthorization } from "../auth/AuthorizationSelectors";
import LadsState from "../lads/LadsState";
import LocationMatchingState from "../location-resolution-edit/LocationMatchingState";
import {
  getSolutionId,
  getActiveOrganization,
} from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities,
    getDefaultSortColumn,
    getDefaultReverseSort,
    getSortColumn,
    getReverseSort,
    getSearchFilters,
    getExportName,
    getExportIdentifier,
    getIsExporting,
    getExportFailed,
  } = LocationSearchBarState.selectors;

  return {
    lads: LadsState.selectors.getLadsList(state),
    locationSearchResults: getSearchResults(state),
    isLoading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    totalLocations: getTotalEntities(state),
    defaultSortColumn: getDefaultSortColumn(state),
    defaultReverseSort: getDefaultReverseSort(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    solutionId: getSolutionId(state),
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    searchFilters: getSearchFilters(state),
    routerLocation: state.location,
    exportIdentifier: getExportIdentifier(state),
    exportName: getExportName(state),
    isExporting: getIsExporting(state),
    exportFailed: getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { setShipmentWithUnresolvedLocation } =
    LocationMatchingState.actionCreators;

  return {
    pushCreateLocationScreen: (locID) => dispatch({ type: "CREATE_LOCATION" }),
    pushEditLocationScreen: (locID) =>
      dispatch({ type: "EDIT_LOCATION", payload: { location_id: locID } }),
    pushLocationMatchingView: (locID) =>
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID },
      }),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        LocationSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        LocationSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse,
        ),
      ),
    setSearchFilter: (key, value) =>
      dispatch(
        LocationSearchBarState.actionCreators.setSearchFilter(key, value),
      ),
    clearSearchText: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchText()),
    clearSearchFilters: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilters()),
    clearSearchFilter: (key) =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilter(key)),
    searchLocations: (solutionId) =>
      dispatch(
        LocationSearchBarState.actionCreators.searchEntities(solutionId),
      ),
    setShipmentWithUnresolvedLocation: (shipment) =>
      dispatch(setShipmentWithUnresolvedLocation(shipment)),
    exportLocations: () => {
      dispatch(LocationSearchBarState.actionCreators.exportSearch());
    },
    resetExport: () => {
      dispatch(LocationSearchBarState.actionCreators.resetExport());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsView);
