/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import {
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FixedSizeList as List } from "react-window";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import googleMapsIcon from "../../assets/icons/googlemaps.png";
import hereMapsIcon from "../../assets/icons/heremaps.png";

import { OrganizationType } from "shared/constants/organization.const";
import { useIsExtraSmall } from "components/responsive";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import { FlexColDiv, FlexDiv } from "styles/container-elements";
import Colors from "styles/colors";

const OrgSearchBar = React.forwardRef(
  ({ placeholderText, searchText, onSearchInputChange, style }, inputRef) => {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1em",
          ...style,
        }}
      >
        <InputGroup
          style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            borderRadius: "3px",
            flex: 1,
          }}
        >
          <FaSearch
            style={{
              color: Colors.background.DARK_BLUE,
              position: "absolute",
              left: "0.8em",
              top: "0.85em",
              zIndex: 1,
            }}
          />
          <FormControl
            ref={inputRef}
            type="text"
            onChange={onSearchInputChange}
            placeholder={placeholderText}
            value={searchText}
            style={{
              backgroundColor: "white",
              border: "none",
              boxShadow: "none",
              flex: 1,
              height: "2.8em",
              paddingLeft: "2.4em",
              borderRadius: "3px",
              zIndex: 0,
            }}
          />
        </InputGroup>
      </div>
    );
  },
);

OrgSearchBar.propTypes = {
  placeholderText: PropTypes.string,
  searchText: PropTypes.string,
  onSearchInputChange: PropTypes.func,
  style: PropTypes.object,
};

const OrgItem = ({
  index,
  org,
  active,
  isFvAdmin,
  onClick,
  mapType,
  mapTypeOverride,
  style,
}) => {
  const { t } = useTranslation("map");

  const isExtraSmall = useIsExtraSmall();

  let backgroundColor = "white";
  let orgNameTextColor = Colors.bannerText;
  let idAndTypeTextColor = Colors.text.GRAY;

  if (active === true) {
    backgroundColor = Colors.background.DARK_TEAL;
    orgNameTextColor = "white";
    idAndTypeTextColor = Colors.background.LIGHT_GRAY_BLUE;
  } else if (index % 2 === 0) {
    backgroundColor = Colors.tableHeadingText;
  }

  const orgId =
    org.org_type === OrganizationType.CARRIER ? org.scac : org.fv_id;

  return (
    <Dropdown.Item
      eventKey={index}
      className="select-item"
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: backgroundColor,
        ":hover": {
          backgroundColor: active
            ? Colors.background.AQUA
            : Colors.highlight.LIGHT_GRAY,
        },
        ...style,
      }}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          css={{
            color: orgNameTextColor,
            fontSize: "1.1em",
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "0.5em",
          }}
        >
          <OverlayTrigger
            // On extraSmall, there isn't enough horizontal space.
            // Moving placement to the bottom allows the tooltip to be seen.
            placement={isExtraSmall ? "bottom" : "left"}
            delay={{ show: 333 }}
            overlay={<Tooltip className="me-1">{org.org_name}</Tooltip>}
          >
            <span>{org.org_name}</span>
          </OverlayTrigger>
        </div>

        <img
          src={mapType === "Here" ? hereMapsIcon : googleMapsIcon}
          width={14}
          height={14}
          css={{ opacity: !mapTypeOverride ? "1" : "0.4" }}
          alt={t("map:Map Type")}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div css={{ color: idAndTypeTextColor }}>{orgId}</div>
        {isFvAdmin && (
          <div css={{ color: idAndTypeTextColor }}>{org.org_type}</div>
        )}
      </div>
    </Dropdown.Item>
  );
};

OrgItem.propTypes = {
  index: PropTypes.number,
  org: PropTypes.shape({
    org_name: PropTypes.string,
    org_type: PropTypes.string,
    scac: PropTypes.string,
    fv_id: PropTypes.string,
  }),
  active: PropTypes.bool,
  isFvAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  mapType: PropTypes.string,
  mapTypeOverride: PropTypes.string,
  style: PropTypes.object,
};

export const OrganizationSelect = React.forwardRef(
  (
    {
      t,
      fvAdmin,
      currentOrganizationId,
      mapTypeOverride,
      organizations,
      searchText,
      setCurrentOrganization,
      setActiveOrganization,
      setMapTypeOverride,
      setSearchText,
    },
    inputRef,
  ) => {
    return (
      <FlexColDiv
        css={{
          minHeight: "12em",
          // Min width for when this is rendered in a drop down.
          minWidth: "24em",
          // Default width so it expands if the parent has a width.
          width: "100%",
        }}
      >
        <FlexColDiv style={{ padding: "1em", position: "relative" }}>
          <FlexDiv
            css={{
              color: Colors.formHeadingColor,
              fontWeight: 450,
              marginBottom: ".25em",
            }}
          >
            {t("header-bar:Organizations")}
            {fvAdmin && (
              <div css={{ position: "absolute", right: "1em" }}>
                {t("header-bar:Map Override")}:
                <img
                  src={hereMapsIcon}
                  width={14}
                  height={14}
                  css={{
                    opacity: mapTypeOverride === "Here" ? "1" : "0.4",
                    cursor: "pointer",
                    margin: "0 5px",
                    ":hover": {
                      opacity: "0.6",
                    },
                  }}
                  alt={t("map:HERE Maps")}
                  title={t("map:HERE Maps")}
                  onClick={() => {
                    if (mapTypeOverride === "Here") {
                      setMapTypeOverride("");
                    } else {
                      setMapTypeOverride("Here");
                    }
                  }}
                />
                <img
                  src={googleMapsIcon}
                  width={14}
                  height={14}
                  css={{
                    opacity: mapTypeOverride === "Google" ? "1" : "0.4",
                    cursor: "pointer",
                    ":hover": {
                      opacity: "0.6",
                    },
                  }}
                  alt={t("map:Google Maps")}
                  title={t("map:Google Maps")}
                  onClick={() => {
                    if (mapTypeOverride === "Google") {
                      setMapTypeOverride("");
                    } else {
                      setMapTypeOverride("Google");
                    }
                  }}
                />
              </div>
            )}
          </FlexDiv>
          <FlexDiv css={{ flex: 1 }}>
            <OrgSearchBar
              ref={inputRef}
              style={{ flex: 1, marginBottom: 0 }}
              placeholderText={t("header-bar:Search Organizations")}
              searchText={searchText}
              onSearchInputChange={(e) => setSearchText(e.target.value)}
            />
          </FlexDiv>
        </FlexColDiv>
        <List
          height={450}
          itemSize={56.5}
          itemCount={organizations?.length ?? 0}
        >
          {({ index, style }) => {
            const org = organizations[index];
            const isActive = org.organization_id === currentOrganizationId;
            const mapType = org.map_type;
            return (
              <OrgItem
                index={index}
                org={org}
                active={isActive}
                isFvAdmin={fvAdmin}
                onClick={() => {
                  setCurrentOrganization(org.organization_id);
                  setActiveOrganization(org);
                }}
                mapType={mapType}
                mapTypeOverride={mapTypeOverride}
                style={style}
              />
            );
          }}
        </List>
      </FlexColDiv>
    );
  },
);

OrganizationSelect.propTypes = {
  t: PropTypes.func,
  fvAdmin: PropTypes.bool,
  currentOrganizationId: PropTypes.number,
  mapTypeOverride: PropTypes.string,
  organizations: PropTypes.array,
  searchText: PropTypes.string,
  setCurrentOrganization: PropTypes.func,
  setActiveOrganization: PropTypes.func,
  setMapTypeOverride: PropTypes.func,
  setSearchText: PropTypes.func,
};
