import { connect } from "react-redux";

import {
  freezeEtas,
  clearActionStatus,
  pushFreezeEtasView,
  getActionStatus,
} from "../redux/FreezeETAState";
import FreezeETA from "./FreezeETA.page";

function mapStateToProps(state) {
  return {
    actionStatus: getActionStatus(state),
  };
}

export default connect(mapStateToProps, {
  freezeEtas,
  clearActionStatus,
  pushFreezeEtasView,
})(FreezeETA);
