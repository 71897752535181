/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import { StringValue } from "react-values";
import styled from "@emotion/styled";

import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";

// FIXME, this is duplicated from GeofenceEditForm, can we make that a shared component
export const FlexRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  ({ styleOverrides }) => {
    return styleOverrides;
  },
);

export const ButtonTab = styled.div(
  {
    backgroundColor: "#eee",
    color: "black",
    fontWeight: "bold",
    padding: "0.5em 2em",
    borderRadius: "1px",
    cursor: "pointer",
  },
  ({ tabKey, selectedKey, selectedColor }) => {
    const isSelected = tabKey === selectedKey;
    if (isSelected) {
      return {
        backgroundColor: selectedColor,
        color: "white",
      };
    }
  },
);

const ButtonTabLabel = styled.p({
  marginLeft: "0.0em",
  marginRight: "1.5em",
  fontWeight: "bold",
});

const UnderReviewToggleBar = ({ value, onChange }) => {
  const { t } = useTranslation("shipment-details");
  return (
    <StringValue onChange={onChange}>
      {({ set }) => (
        <FlexRow styleOverrides={{ marginBottom: "2em" }}>
          <ButtonTabLabel>Review Status:</ButtonTabLabel>
          <ButtonTab
            tabKey={"under_review"}
            selectedKey={value}
            selectedColor={Colors.highlight.RED}
            backgroundColor={Colors.highlight.DARK_BLUE}
            onClick={() => set("under_review")}
          >
            {t("shipment-details:Under Review")}
          </ButtonTab>
          <ButtonTab
            tabKey={"ok"}
            selectedKey={value}
            selectedColor={Colors.highlight.GREEN}
            onClick={() => set("ok")}
          >
            {t("shipment-details:OK")}
          </ButtonTab>
        </FlexRow>
      )}
    </StringValue>
  );
};

UnderReviewToggleBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export const UnderReviewModal = ({
  show,
  hide,
  updateReviewStatus,
  shipment,
  reviewStatus,
}) => {
  const { t } = useTranslation("shipment-details");
  const [underReview, setUnderReview] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [initialData, setInitialData] = useState({
    underReview: underReview,
    noteValue: noteValue,
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  let userChangedData = false;

  const handleChange = (e) => {
    setUnderReview(e === "under_review");
  };

  const handleOnChangeNote = (event) => {
    setNoteValue(event.target.value);
  };

  const handleOnEnter = () => {
    setUnderReview(reviewStatus);
    setNoteValue("");
    setInitialData({ underReview: reviewStatus, noteValue: "" });
  };

  const data = {
    noteValue: noteValue,
    underReview: underReview,
  };
  userChangedData = !_.isEqual(initialData, data);

  return (
    <React.Fragment>
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={handleOnEnter}
      >
        <Modal.Header
          closeButton
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            color: Colors.background.DARK_BLUE,
            padding: "0.5rem 0.75rem 0.5rem 0.5rem",
          }}
          title={t("Modify Review Status")}
        />

        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <UnderReviewToggleBar
              value={underReview ? "under_review" : "ok"}
              onChange={handleChange}
            />
            <FormLabel>{`${"Notes"}:`}</FormLabel>
            <FormControl
              as="textarea"
              placeholder={t("Enter notes about the change here")}
              value={noteValue}
              onChange={handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em",
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>

          <Button
            disabled={!userChangedData}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300",
            }}
            onClick={() => {
              setShowConfirmationDialog(true);
            }}
          >
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
      <DialogModal
        show={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
        title={t("shipment-details:Confirm Review Status Change")}
        cancelButtonText={t("shipment-details:Cancel")}
        submitButtonText={t("shipment-details:Modify Status")}
        submitButtonVariant="success"
        onSubmit={() => {
          updateReviewStatus(shipment.id, underReview, noteValue);
          setShowConfirmationDialog(false);
          hide();
        }}
      >
        <Text block style={{ marginBottom: 10 }}>
          {t("shipment-details:Are you sure you wish to make this change?")}
        </Text>

        {underReview === true ? (
          <React.Fragment>
            <Text bold>{t("shipment-details:OK")}</Text>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              {String.fromCharCode(8594)}
            </Text>
            <Text bold>{t("shipment-details:Under Review")}</Text>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text bold>{t("shipment-details:Under Review")}</Text>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              {String.fromCharCode(8594)}
            </Text>
            <Text bold>{t("shipment-details:OK")}</Text>
          </React.Fragment>
        )}
      </DialogModal>
    </React.Fragment>
  );
};

UnderReviewModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  updateReviewStatus: PropTypes.func,
  shipment: PropTypes.object,
  reviewStatus: PropTypes.bool,
};
