import axios from "axios";
import apiUrl from "api-url";
import moment from "moment";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { isArray } from "util";
import qs from "qs";
import _ from "lodash";

import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "inventoryviewWatchedVins";

// Actions
const REQUEST_WATCHED_VINS = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_VINS`;
const RECEIVE_WATCHED_VINS = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_VINS`;
const REQUEST_LOCATION = `${STORE_MOUNT_POINT}/REQUEST_LOCATION`;
const RECEIVE_LOCATION = `${STORE_MOUNT_POINT}/RECEIVE_LOCATION`;
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

// Action Creators
const fetchWatchedVins = (
  queryString = null,
  solutionId,
  duck,
  dispatch,
  state,
) => {
  const params = { watched: true };
  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=fin_inventoryview",
    },
    params,
  };

  const url = apiUrl(
    `/entity/solution/${solutionId}/entity?invDetailsSearch=watchedVins&lifeCycleState=Active&${queryString}`,
  );
  // Fetch the search
  dispatch(duck.fetch(url, config));

  // Fetch the count
  dispatch(fetchTotalCount(solutionId, queryString, config));
};

const setWatchEntity = (solutionId, entityId, internal_id, watch = true) => {
  const url = solutionId
    ? apiUrl(`/entity/solution/${solutionId}/entity/${entityId}`)
    : apiUrl(`/entity/internal/${internal_id}`);
  return (dispatch) => {
    return Promise.all([axios.patch(url, { watch })])
      .then((responses) => {
        dispatch(SearchBarState.actionCreators.searchEntities(solutionId));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
};

const fetchLocation = (locationId) => {
  const qs = "?lifeCycleState=Active";
  return async (dispatch, getState) => {
    const state = getState();
    const solutionId = getSolutionId(state);
    const url = apiUrl(
      `/entity/solution/${solutionId}/entity-location/${locationId}${qs}`,
    );
    dispatch({
      type: REQUEST_LOCATION,
    });
    try {
      const response = await axios.get(url);
      dispatch({
        type: RECEIVE_LOCATION,
        payload: {
          location: response?.data ?? {},
        },
      });
    } catch (e) {
      dispatch({
        type: RECEIVE_LOCATION,
        payload: {
          location: {},
        },
      });
    }
  };
};

const formatQueryStringForCount = (queryString) => {
  const params = qs.parse(queryString);
  params.lifeCycleState = "Active";
  params.pageNumber = undefined;
  params.pageSize = undefined;

  return qs.stringify(params);
};

const entitiesUrlForCount = (solutionId, qs) => {
  const formattedQueryString = formatQueryStringForCount(qs);
  return apiUrl(
    `/entity/solution/${solutionId}/entity?${formattedQueryString}`,
  );
};

const fetchTotalCount = (solutionId, queryString, config, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();
    const url = entitiesUrlForCount(solutionId, queryString);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      ...config,
      method: "GET",
      url,
      data: undefined,
      headers: {
        ...config.headers,
        Accept: "application/json;version=count",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Selectors
const getLocation = (state) => {
  const location = state[STORE_MOUNT_POINT].location;
  return isArray(location) ? null : location;
};
const getIsLocationLoading = (state) => {
  return state[STORE_MOUNT_POINT].isLocationLoading ?? false;
};

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

// Initial state
const initialState = {
  watchedVins: [],
  isWatchedVinsLoading: false,
  watchedVinsPageIndex: 0,
  totalPageCountForSearch: 0,
  isLocationLoading: false,
  location: {},
};

const watchedVinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WATCHED_VINS:
      return {
        ...state,
        isWatchedVinsLoading: true,
      };
    case RECEIVE_WATCHED_VINS:
      return {
        ...state,
        watchedVins: action.payload.watchedVins,
        isWatchedVinsLoading: false,
      };

    case REQUEST_LOCATION:
      return {
        ...state,
        isLocationLoading: true,
      };

    case RECEIVE_LOCATION:
      return {
        ...state,
        location: action.payload.location,
        isLocationLoading: false,
      };

    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: state.totalCountForSearch ? false : true,
      };

    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalPageCountForSearch: action.totalPages,
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  [],
  fetchWatchedVins,
  [watchedVinsReducer],
  {},
  10,
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getLocation,
  getIsLocationLoading,
  getTotalPageCountForSearch,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  fetchLocation,
  setWatchEntity,
};

export default SearchBarState;
