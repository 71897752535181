import { connect } from "react-redux";

import { LocationManagementView } from "./LocationManagement.page";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
  };
}

export default connect(mapStateToProps)(LocationManagementView);
