import { connect } from "react-redux";
import { SetCurrentPlannedTripLegPage } from "./SetCurrentPlannedTriplegTool.page";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state),
  };
}

export default connect(mapStateToProps, null)(SetCurrentPlannedTripLegPage);
