import apiUrl from "api-url";
import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import ShipmentsSearchBarState from "./ShipmentsSearchBarState";

const STORE_MOUNT_POINT = "shipmentSavedSearch";

const SavedSearchState = buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "SHIPMENT",
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters:
      ShipmentsSearchBarState.actionCreators.clearSearchFilters,
    setSearchCategory: ShipmentsSearchBarState.actionCreators.setSearchCategory,
    setSearchText: ShipmentsSearchBarState.actionCreators.setSearchText,
    setSearchFilter: ShipmentsSearchBarState.actionCreators.setSearchFilter,
  },
});

export default SavedSearchState;
