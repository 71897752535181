/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { DateTime } from "components/atoms/DateTime.atom";

export const DateTimeCell = ({ dateTime, stack, localize, dataQa }) => {
  if (!dateTime) {
    return null;
  }

  return (
    <DateTime plain dateTime={dateTime} stack={stack} localize={localize} />
  );
};

DateTimeCell.propTypes = {
  dateTime: PropTypes.string,
  stack: PropTypes.bool,
  localize: PropTypes.bool,
};
