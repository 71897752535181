/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { Text } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";

const useColumns = ({
  showPlannedColumn,
  showActualColumn,
  customFieldsColumns,
}) => {
  const { t } = useTranslation("partview-details");

  const columns = [
    {
      Header: t("partview-details:Part Number"),
      accessor: "partNumber",
      Cell: (cell) => {
        return <Text>{cell.value}</Text>;
      },
    },
    {
      Header: t("partview-details:Name"),
      accessor: "name",
      Cell: (cell) => {
        return <Text>{cell.value}</Text>;
      },
    },
  ];

  if (showPlannedColumn) {
    columns.push({
      Header: t("partview-details:Planned"),
      accessor: "plannedQuantity",
      width: 90,
      Cell: (cell) => {
        return <Text>{cell.value}</Text>;
      },
    });
  }

  if (showActualColumn) {
    columns.push({
      Header: t("partview-details:Actual"),
      accessor: "actualQuantity",
      width: 90,
      Cell: (cell) => {
        return <Text>{cell.value}</Text>;
      },
    });
  }

  columns.push({
    Header: t("partview-details:Update Timestamp"),
    accessor: "updateTimestamp",
    width: 90,
    Cell: (cell) => {
      return (
        <DateTime
          dateTime={cell.value}
          localize
          plain
          className="ms-1"
          fallback={<Text className="ms-1">{t("partview-search:N/A")}</Text>}
        />
      );
    },
  });

  // columns.push({
  //   Header: t("partview-details:Status"),
  //   accessor: "lifecycleState",
  //   width: 90,
  //   Cell: cell => {
  //     return <Text>{cell.value}</Text>;
  //   }
  // });

  customFieldsColumns.forEach((field) => {
    if (field.showColumn) {
      columns.push({
        // TODO: No dynamic translations.
        // Need to explicitly translate each possible field.
        Header: t(`partview-details:${field.customFieldDetail.name}`),
        accessor: `customFields.${field.key}`,
        width: 90,
        Cell: (cell) => {
          return <Text>{cell.value}</Text>;
        },
      });
    }
  });
  return columns;
};

export const PartsTable = ({ parts = [], meta = {} }) => {
  const fieldsInCustomFields = meta?.customFields || {};
  const filteredParts = _.filter(parts, function (p) {
    if (!p?.actualQuantity) {
      p.actualQuantity = 0;
    }
    if (!p?.plannedQuantity) {
      p.plannedQuantity = 0;
    }
    return (p) => p.plannedQuantity > 0 || p.actualQuantity > 0;
  });

  const showPlanned =
    filteredParts.reduce(function (prev, cur) {
      return cur?.plannedQuantity ? prev + cur.plannedQuantity : prev;
    }, 0) > 0;

  const showActual =
    filteredParts.reduce(function (prev, cur) {
      return cur?.actualQuantity ? prev + cur.actualQuantity : prev;
    }, 0) > 0;

  const sortedCustomFieldsPosition = Object.keys(fieldsInCustomFields).sort(
    (a, b) =>
      fieldsInCustomFields[a].position - fieldsInCustomFields[b].position,
  );

  const customFieldsColumns = sortedCustomFieldsPosition.map((key) => ({
    key: key,
    customFieldDetail: fieldsInCustomFields[key],
    showColumn: parts.some((part) => part.customFields[key]),
  }));
  let columns = useColumns({
    showPlannedColumn: showPlanned,
    showActualColumn: showActual,
    customFieldsColumns,
  });

  return (
    <BaseTable
      data-qa="parts-table"
      theme={Themes.LIGHT}
      columns={columns}
      data={filteredParts}
      disablePagination
    />
  );
};

PartsTable.propTypes = {
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      partNumber: PropTypes.string,
      name: PropTypes.string,
      plannedQuantity: PropTypes.number,
      actualQuantity: PropTypes.number,
    }),
  ),
  meta: PropTypes.object,
};
