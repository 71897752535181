/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { Radio } from "components/atoms/Radio.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faSearch, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import Colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const List = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <ul
      css={{
        // Remove default styles.
        margin: 0,
        padding: 0,
        listStyle: "none",
        // Option layout.
        display: "flex",
        flexDirection: "column",
        // Spacing between each option.
        gap: "0.125em",
      }}
    >
      {children}
    </ul>
  );
};

type OptionProps = {
  multi?: boolean;
  selected?: boolean;
  disabled?: boolean;
  strikethrough?: boolean;
  isDefault?: boolean;
  onClick?: React.MouseEventHandler;
  tooltip?: string;
  children: ReactNode;
};

const Option = ({
  multi,
  selected,
  disabled,
  strikethrough,
  // 'default' was giving issues in TS. Probably because it is a reserved keyword
  isDefault,
  onClick,
  tooltip,
  children,
}: OptionProps) => {
  const { t } = useTranslation("components");

  const option = (
    <li
      css={{
        // Remove default styles.
        margin: 0,
        // Some spacing around the checkbox and label.
        padding: "0.25em 0.5em",
        // Checkbox and label center-aligned in a row.
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        // Selected style.
        borderRadius: "0.25em",
        background:
          selected && !disabled
            ? Colors.background.LIGHTER_GRAY_BLUE
            : undefined,
        // Hover.
        cursor: disabled ? "default" : "pointer",
        ":hover": {
          background:
            !selected && !disabled ? Colors.background.LIGHT_GRAY : undefined,
        },
      }}
      onClick={onClick && !disabled ? onClick : undefined}
    >
      {multi ? (
        <Checkbox
          checked={selected}
          disabled={disabled}
          color={Colors.filters.CHECK_DEFAULT_COLOR}
          checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
          disabledColor={Colors.text.DISABLED}
          css={{ paddingTop: 2 }}
        />
      ) : (
        <Radio
          checked={selected}
          disabled={disabled}
          color={Colors.filters.CHECK_DEFAULT_COLOR}
          checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
          disabledColor={Colors.text.DISABLED}
          css={{ paddingTop: 3 }}
        />
      )}
      <Text
        color={disabled ? Colors.text.DISABLED : Colors.text.BLUE_BLACK}
        css={{
          textDecoration: strikethrough ? "line-through" : "none",
          wordBreak: "break-word",
          // Prevent overflow in the parent list.
          overflow: "hidden",
        }}
      >
        {children}
      </Text>
      {isDefault ? (
        <Text
          size={FontSize.size12}
          color={Colors.text.LIGHTER_GRAY}
          className="ms-1 align-self-center"
        >
          ({t("components:default")})
        </Text>
      ) : null}
    </li>
  );
  return tooltip ? (
    <Tooltip tooltipChildren={tooltip}>{option}</Tooltip>
  ) : (
    option
  );
};

type FuzzyOptionLabelProps = {
  name: string;
  value: string;
  numberOfMatches?: number;
};

const FuzzyOptionLabel = ({
  name,
  value,
  numberOfMatches,
}: FuzzyOptionLabelProps): JSX.Element => {
  const { t } = useTranslation("components");

  return (
    <div className="d-flex flex-column">
      <Text>
        {t("components:[[[filterName]]] matching", { filterName: name })}:
      </Text>
      <div>
        <Text italic color={Colors.text.DISABLED} className="me-1">
          {`${value}`}
        </Text>
        {typeof numberOfMatches === "number" ? (
          <Text size={FontSize.size12} color={Colors.text.DISABLED}>
            ({t("components:[[[count]]] value", { count: numberOfMatches })})
          </Text>
        ) : null}
      </div>
    </div>
  );
};

type Option = {
  label: string;
  value: any;
  static?: boolean;
  fuzzy?: boolean;
};

export const SELECT_ALL_OPTION_VALUE = "__SELECT_ALL__";
export const SELECT_EMPTY_OPTION_VALUE = "__SELECT_EMPTY_VALUES__";

export const useSelectOptions = () => {
  const { t } = useTranslation("components");

  const SELECT_ALL_OPTION: Option = useMemo(() => {
    return {
      label: t("components:Select all"),
      value: SELECT_ALL_OPTION_VALUE,
      static: true,
    };
  }, [t]);

  const SELECT_EMPTY_OPTION: Option = useMemo(() => {
    return {
      label: t("components:Select empty values"),
      value: SELECT_EMPTY_OPTION_VALUE,
      static: true,
    };
  }, [t]);

  return {
    SELECT_ALL_OPTION,
    SELECT_EMPTY_OPTION,
  };
};

export const FUZZY_PREFIX = "__FUZZY__";

export const buildFuzzyOption = (fuzzyValue: string): Option => ({
  label: fuzzyValue,
  value: `${FUZZY_PREFIX}${fuzzyValue}`,
  fuzzy: true,
});

export enum SelectListSize {
  REGULAR = "12.5em",
  LARGE = "17em",
}

type BaseSelectProps = {
  options?: Option[];
  defaultValue?: any;
  multi?: boolean;
  async?: boolean;
  required?: boolean;
  hideSelectEmpty?: boolean;
  onTextChange?: (text: string) => void;
  hasMore?: boolean;
  fetchNext?: () => any;
  /**
   * This is a map of search strings to the count from that search
   * for use with the fuzzy search options.
   */
  totalCountsForFuzzySearch?: { [query: string]: number };
  isLoading?: boolean;
  /**
   * Affects the overall width of the filter - either Regular or Large
   */
  selectListSize?: SelectListSize;
};

type SingleSelectProps = {
  multi?: false;
  selectedOption?: Option;
  onChange?: (option: Option | null) => void;
};

type MultiSelectProps = {
  multi: true;
  name?: string;
  defaultValue?: any[];
  hideSelectAll?: boolean;
  maxFuzzySearch?: number;
  selectedOptions?: Option[];
  onChange?: (options: Option[]) => void;
};

// SelectInput's prop types will accept different props depending on what is passed in.
// SelectInputProps will always have `options`.
// If `multi` is true, it will also accept `selectedValue`.
// If `multi` is false (or undefined), it will also accept `selectedValues`.
export type SearchableSelectListProps = BaseSelectProps &
  (SingleSelectProps | MultiSelectProps);

const useAsyncSelectListHelper = <T extends HTMLElement>(
  props: SearchableSelectListProps,
  scrollableRef: React.RefObject<T>,
) => {
  const { options, async, fetchNext, isLoading, hasMore, onTextChange } = props;

  // Fetch the intial data.
  // This is to give InfiniteScroll some initial data
  // so it can continue to fetch additional pages.
  useEffect(
    () => {
      // Only fetch if we don't have options yet.
      // This will fetch the first page giving InifiniteScroll
      // some intial data to work with.
      // Other pages will be fetched by the component.
      if (async && fetchNext && options?.length === 0 && !isLoading) {
        fetchNext();
      }

      // Clear the text when this component unmounts.
      return () => {
        if (onTextChange) {
          onTextChange("");
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dataLength = options?.length ?? 0;

  if (async) {
    // This will calculate before the next list options are rendered.
    // If this component updates with new options and it is still scrollable,
    // it will display the end message.
    const scrollHeight = scrollableRef.current?.scrollHeight ?? 0;
    const clientHeight = scrollableRef.current?.clientHeight ?? 0;
    // If our scroll height is larger than the visible box
    // we can assume we are showing a scrollbar.
    const canScrollNow = scrollHeight > clientHeight;

    return {
      dataLength,
      hasMore: hasMore ?? false,
      fetchNext: fetchNext ?? (() => {}),
      isLoading: isLoading && options?.length === 0,
      showEndMessage: canScrollNow,
    };
  }

  return {
    dataLength,
    hasMore: false,
    fetchNext: () => {},
    isLoading: false,
    // We never want to show the end message if it's not async.
    showEndMessage: false,
  };
};

/**
 * Hook to get an array of options corresponding to the defaultValue.
 *
 * @param props
 * @returns
 */
const useDefaultOptions = (props: SearchableSelectListProps) => {
  if (props.async) {
    // The `defaultValue` prop is not supported for async SearchableSelectList.
    // See the console.warn in one of component's useEffects.
    return [];
  }

  if (props.multi) {
    return (
      props.options?.filter((option) => {
        return props.defaultValue?.includes(option.value);
      }) ?? []
    );
  }

  const defaultOption = props.options?.find(
    (option) => option.value === props.defaultValue,
  );

  if (defaultOption) {
    return [defaultOption];
  }

  return [];
};

export const SearchableSelectList = (props: SearchableSelectListProps) => {
  const {
    options = [],
    selectListSize = SelectListSize.REGULAR,
    hideSelectEmpty,
    onTextChange,
    required = false,
  } = props;

  const scrollableTarget = useRef<HTMLDivElement>(null);
  const asyncHelper = useAsyncSelectListHelper(props, scrollableTarget);

  const { t } = useTranslation("components");
  const { SELECT_ALL_OPTION, SELECT_EMPTY_OPTION } = useSelectOptions();

  // The text input value for filtering the options.
  const [searchText, setSearchText] = useState("");

  let hideSelectAll = false;
  let maxFuzzySearch = 0;
  if (props.multi) {
    hideSelectAll = props.hideSelectAll ?? false;
    maxFuzzySearch = props.maxFuzzySearch ?? 0;
  }

  // Holds the selected state of the options for highlighting.
  const [selectedOptions, setSelectedOptions] = useState(() => {
    // Normalizing passed in options as an array.
    // If multi, we will be given an array.
    // If single, we will be given a single value. Need to turn this into an array.
    if (props.multi) {
      return props.selectedOptions ? [...props.selectedOptions] : [];
    } else {
      return props.selectedOption ? [props.selectedOption] : [];
    }
  });

  // Initialize this ref with whatever selectedOptions is initially.
  // Options are not grouped by selected state; they are grouped by what is passed in as props first.
  // This prevents moving selections to the selected section until we "remount" the component.
  const initialSelectedOptionsRef = useRef(selectedOptions);

  const defaultOptions = useDefaultOptions(props);

  // Effect on mount when we have a default value.
  // Updates the parent and local state with whatever "defaultValue" is
  // so that state above knows the selected option and
  // this component can render the default selected options correctly.
  useEffect(
    () => {
      // Early exit from this effect if we don't have a default value to apply.
      if (defaultOptions.length === 0) {
        return;
      }

      // For async, do we need to worry about the defaultValue actually being
      // available in the options list?
      // If it needs to be in there, then there isn't an easy way for the UI to
      // know if an option will come back from the API.
      // For FIN-4597, we didn't need the defaultValue prop for async selects.
      // We implement later if needed.
      if (props.async) {
        console.warn(
          "The `defaultValue` prop is not supported for async SearchableSelectList.",
        );
        return;
      }

      if (selectedOptions.length === 0 && defaultOptions?.length > 0) {
        // Tell the parent that the default option was selected.
        if (!_.isNil(props.onChange)) {
          if (props.multi) {
            props.onChange(defaultOptions);
          } else {
            props.onChange(defaultOptions[0]);
          }
        }
        // Update this component's state so it will render the value correctly.
        setSelectedOptions(defaultOptions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isSearching = searchText.length > 0;

  const isInitiallySelectingAll = initialSelectedOptionsRef.current
    .map((option) => option.value)
    .includes(SELECT_ALL_OPTION.value);
  const isSelectingAll = selectedOptions
    .map((option) => option.value)
    .includes(SELECT_ALL_OPTION.value);

  const isInitiallySelectingEmpty = initialSelectedOptionsRef.current
    .map((option) => option.value)
    .includes(SELECT_EMPTY_OPTION.value);
  const isSelectingEmpty = selectedOptions
    .map((option) => option.value)
    .includes(SELECT_EMPTY_OPTION.value);

  const selectedFuzzyOptions = selectedOptions.filter((option) => option.fuzzy);
  const isAtMaxFuzzyOptions = selectedFuzzyOptions.length >= maxFuzzySearch;
  const canRenderFuzzyOption =
    isSearching && !isAtMaxFuzzyOptions && !isSelectingAll;

  const isOptionDefault = (option: Option) => {
    return defaultOptions?.map((o) => o.value).includes(option.value) ?? false;
  };

  const isOptionSelected = (option: Option) =>
    selectedOptions.map(({ value }) => value).includes(option.value);

  const isOptionSelectedByFuzzy = (option: Option) =>
    selectedFuzzyOptions
      .map((option) => option.value.substring(FUZZY_PREFIX.length))
      .some(
        (value) => option.label?.toLowerCase().includes(value.toLowerCase()),
      );

  const getNumberOfOptionsForFuzzy = (value: string) => {
    if (props.async) {
      return props.totalCountsForFuzzySearch?.[value] ?? undefined;
    }

    return options.filter(
      ({ label }) => label?.toLowerCase().includes(value.toLowerCase()),
    ).length;
  };

  // All other options to display.
  let remainingOptions = options.filter(({ label, value }) => {
    // If we have search text, then `remainingOptions` are all options that contain that text.
    // (In the render, we won't show the selected values section.)
    if (isSearching) {
      // Disable client side filtering for async.
      if (props.async) {
        return true;
      }

      return (
        label?.length > 0 &&
        label.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Otherwise, `remainingOptions` are only the unselected options.
    return !initialSelectedOptionsRef.current
      .map((option) => option.value)
      .includes(value);
  });

  const handleOptionClick = (option: Option, selected: boolean) => {
    if (props.multi) {
      let nextSelectedOptions: Option[] = [];
      // Multi select
      if (selected) {
        // Remove the option.
        nextSelectedOptions = selectedOptions.filter(
          ({ value }) => value !== option.value,
        );

        // Prevent removing the last option if required.
        if (required && nextSelectedOptions.length === 0) {
          // If select all, we want the user to be able to deselect it.
          if (option.value === SELECT_ALL_OPTION.value) {
            // Fallback to the default options.
            // If there are none, this array will be empty.
            nextSelectedOptions = defaultOptions;
          } else {
            nextSelectedOptions = selectedOptions;
          }
        }
      } else {
        // Add the option.
        nextSelectedOptions = [...selectedOptions, option];

        // for select all, we purge all other selected options
        if (option.value === SELECT_ALL_OPTION.value) {
          nextSelectedOptions = [option];
        }
        // for selecting a fuzzy search, we purge matching options
        if (option.fuzzy) {
          const fuzzyValue = option.value.substring(FUZZY_PREFIX.length);
          nextSelectedOptions = nextSelectedOptions.filter(
            (option) =>
              !option.label.toLowerCase().includes(fuzzyValue.toLowerCase()) ||
              option.fuzzy, // ignore selected fuzzy options
          );
        }
      }

      setSelectedOptions(nextSelectedOptions);

      if (props.onChange) {
        props.onChange(nextSelectedOptions);
      }
    } else {
      let nextSelectedOption: Option | null = null;

      // Single select
      if (selected) {
        // We clicked on the only selected option.
        // Toggle it's state.
        //...unless it is required; This prevents deselecting options.
        nextSelectedOption = required ? option : null;
      } else {
        // We clicked on an unselected option.
        // Set it as the selected value.
        nextSelectedOption = option;
      }

      setSelectedOptions(() => {
        if (nextSelectedOption === null) {
          return [];
        } else {
          return [nextSelectedOption];
        }
      });

      if (props.onChange) {
        props.onChange(nextSelectedOption);
      }
    }
  };

  const handleClearClick = () => {
    setSelectedOptions([]);
    if (props.onChange) {
      if (props.multi) {
        props.onChange([]);
      } else {
        props.onChange(null);
      }
    }
  };

  const renderSelectAll = () => {
    if (!props.multi || hideSelectAll || isSearching) {
      return null;
    }
    const message = t(
      "components:(Select all) cannot be selected with (Select empty values)",
    );
    return (
      <Option
        key={SELECT_ALL_OPTION.value}
        multi={props.multi}
        selected={isSelectingAll}
        disabled={isSelectingEmpty}
        strikethrough={isSelectingEmpty}
        onClick={() => handleOptionClick(SELECT_ALL_OPTION, isSelectingAll)}
        tooltip={isSelectingEmpty ? message : undefined}
      >
        {`(${SELECT_ALL_OPTION.label})`}
      </Option>
    );
  };

  const renderSelectEmpty = () => {
    if (hideSelectEmpty || isSearching) {
      return null;
    }
    const message = t(
      "components:(Select empty values) cannot be selected with (Select all)",
    );
    return (
      <Option
        key={SELECT_EMPTY_OPTION.value}
        multi={props.multi}
        selected={isSelectingEmpty}
        disabled={isSelectingAll}
        strikethrough={isSelectingAll}
        onClick={() => handleOptionClick(SELECT_EMPTY_OPTION, isSelectingEmpty)}
        tooltip={isSelectingAll ? message : undefined}
      >
        {`(${SELECT_EMPTY_OPTION.label})`}
      </Option>
    );
  };

  const renderFuzzyOption = () => {
    if (!props.multi || !canRenderFuzzyOption) {
      return null;
    }
    const option = buildFuzzyOption(searchText);
    const isSelectedByFuzzy = isOptionSelectedByFuzzy(option);
    const numberOfOptions = getNumberOfOptionsForFuzzy(searchText);
    return (
      <Option
        key={option.value}
        multi={props.multi}
        selected={isSelectedByFuzzy}
        onClick={() => handleOptionClick(option, isSelectedByFuzzy)}
      >
        <FuzzyOptionLabel
          name={props.name ?? t("components:Values")}
          value={searchText}
          numberOfMatches={numberOfOptions}
        />
      </Option>
    );
  };

  const renderSelectedFuzzyOption = (value: string) => {
    if (!props.multi) {
      return null;
    }
    const fuzzyValue = value.substring(FUZZY_PREFIX.length);
    const numberOfOptions = getNumberOfOptionsForFuzzy(fuzzyValue);
    const option = buildFuzzyOption(fuzzyValue);
    return (
      <Option
        key={option.value}
        multi={props.multi}
        selected={true}
        onClick={() => handleOptionClick(option, true)}
      >
        <FuzzyOptionLabel
          name={props.name ?? t("components:Values")}
          value={fuzzyValue}
          numberOfMatches={numberOfOptions}
        />
      </Option>
    );
  };

  const renderDivider = (color: string) => {
    return (
      <div
        css={{
          borderBottom: `1px solid ${color}`,
          margin: "0.125em 0.25em",
        }}
      />
    );
  };

  const infiniteScrollId = `select-scrollable-${uuidv4()}`;

  const NoOptionMessage = () => {
    return (
      <div
        css={{
          display: "inline-flex",
          justifyContent: "center",
          width: "93%",
          padding: "0.5em",
        }}
      >
        <Text color={Colors.text.LIGHT_GRAY}>
          {t("components:No options available")}
        </Text>
      </div>
    );
  };

  let hasOptions = remainingOptions?.length > 0;

  const disableInputField = options.length === 0 && !isSearching;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      <TextInput
        icon={faSearch}
        placeholder={t("components:Search")}
        value={searchText}
        onChange={(value) => {
          setSearchText(value);
          // Force scroll to top when text changes
          // This forces InfiniteScroll to be at the top
          // and doesnt request pages out of order.
          if (scrollableTarget.current) {
            scrollableTarget.current.scrollTo({ top: 0 });
          }

          if (onTextChange) {
            onTextChange(value);
          }
        }}
        disabled={disableInputField}
        showClearButton={true}
        css={{ minWidth: selectListSize }}
      />
      <div
        css={{
          position: "relative",
          overflow: "auto",
          height: 200,
          width: "100%",
          borderRadius: "0.25em",
          background: "#fff",
          padding: "0.25em",
        }}
      >
        <SimpleBar
          autoHide={false}
          css={{ width: "100%", height: "100%" }}
          scrollableNodeProps={{
            id: infiniteScrollId,
            ref: scrollableTarget,
          }}
        >
          {asyncHelper.isLoading ? (
            <div
              css={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Icon src={faSpinner} size={FontSize.size24} spin />
            </div>
          ) : (
            <InfiniteScroll
              scrollableTarget={infiniteScrollId}
              dataLength={asyncHelper.dataLength}
              hasMore={asyncHelper.hasMore}
              next={asyncHelper.fetchNext}
              loader={
                <div
                  css={{
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Icon src={faSpinner} spin />
                </div>
              }
              endMessage={
                asyncHelper.showEndMessage ? (
                  <div
                    css={{
                      display: "inline-flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "0.5em",
                    }}
                  >
                    <Text color={Colors.text.LIGHT_GRAY}>
                      {t("components:No more options")}
                    </Text>
                  </div>
                ) : null
              }
            >
              {/* Selected Values */}
              {!isSearching &&
              (initialSelectedOptionsRef.current.length > 0 ||
                selectedFuzzyOptions.length > 0) ? (
                <Fragment>
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // Pushes "Selected Values" and the "Clear" button inward a little.
                      padding: "0 0.5em",
                    }}
                  >
                    <Text color={Colors.text.GRAY} size={FontSize.size12}>
                      {props.multi
                        ? t("components:Selected values")
                        : t("components:Selected value")}
                      :
                    </Text>
                    <Button
                      variant="link"
                      css={{
                        color: Colors.background.AQUA,
                        // If required, don't allow users to clear all values.
                        display: required ? "none" : "flex",
                        marginRight: 5,
                        // Remove bootstrap styles.
                        padding: 0,
                        textDecoration: "none",
                        ":hover": { textDecoration: "none" },
                        ":focus": { textDecoration: "none" },
                      }}
                      onClick={handleClearClick}
                    >
                      <Text size={FontSize.size12}>
                        {t("components:Clear")}
                      </Text>
                    </Button>
                  </div>
                  <List>
                    {isInitiallySelectingEmpty ? renderSelectEmpty() : null}
                    {isInitiallySelectingAll ? renderSelectAll() : null}
                    {
                      /* Display selected fuzzy search options */
                      selectedFuzzyOptions.map((option) => {
                        return renderSelectedFuzzyOption(option.value);
                      })
                    }
                    {initialSelectedOptionsRef.current
                      .filter((option) => !option.static && !option.fuzzy)
                      .map((option) => {
                        const isSelected = isOptionSelected(option);
                        const isDefault = isOptionDefault(option);

                        return (
                          <Option
                            key={option.value}
                            multi={props.multi}
                            selected={isSelected || isSelectingAll}
                            disabled={isSelectingAll}
                            isDefault={isDefault}
                            onClick={() =>
                              handleOptionClick(option, isSelected)
                            }
                          >
                            {option.label}
                          </Option>
                        );
                      })}
                  </List>
                  {renderDivider(Colors.filters.SELECTED_OPTIONS_DIVIDER_COLOR)}
                </Fragment>
              ) : null}
              {canRenderFuzzyOption ? (
                <Fragment>
                  {renderFuzzyOption()}
                  {renderDivider(Colors.filters.SELECT_EMPTY_DIVIDER_COLOR)}
                </Fragment>
              ) : null}
              <Fragment>
                {hasOptions ? (
                  <Text
                    color={Colors.text.GRAY}
                    size={FontSize.size12}
                    css={{ padding: "0 0.5em" }}
                  >
                    {props.multi
                      ? t("components:Select any")
                      : t("components:Select one")}
                    {props.required ? (
                      <span> ({t("components:required")})</span>
                    ) : null}
                    :
                  </Text>
                ) : null}
                {!hideSelectEmpty &&
                !isInitiallySelectingEmpty &&
                options.length > 0 ? (
                  <List>{renderSelectEmpty()}</List>
                ) : null}

                {!isInitiallySelectingEmpty &&
                !hideSelectEmpty &&
                !isSearching &&
                options.length > 0
                  ? renderDivider(Colors.filters.SELECT_EMPTY_DIVIDER_COLOR)
                  : null}

                {!hideSelectAll && !isInitiallySelectingAll ? (
                  <List>{renderSelectAll()}</List>
                ) : null}

                {!hasOptions ? <NoOptionMessage /> : null}

                {/* The remaining options.
                 * This is either the unselected options or
                 * the list of filtered options (if the user updates `searchText`)  */}

                {remainingOptions?.length > 0 ? (
                  <List>
                    {remainingOptions
                      .filter((option) => !option.static && !option.fuzzy)
                      .map((option, index) => {
                        const isSelected = isOptionSelected(option);
                        const isSelectedByFuzzy =
                          isOptionSelectedByFuzzy(option);
                        const isDefault = isOptionDefault(option);

                        return (
                          <Option
                            // Using index because we cannot guarantee not receiving duplicates from the API
                            key={index}
                            multi={props.multi}
                            selected={
                              isSelected || isSelectingAll || isSelectedByFuzzy
                            }
                            disabled={isSelectingAll || isSelectedByFuzzy}
                            isDefault={isDefault}
                            onClick={() =>
                              handleOptionClick(option, isSelected)
                            }
                          >
                            {option.label}
                          </Option>
                        );
                      })}
                  </List>
                ) : null}
              </Fragment>
            </InfiniteScroll>
          )}
        </SimpleBar>
      </div>
    </div>
  );
};
