import {
  getBasicQueryString,
  getApiLogsDateRangeQueryString,
} from "components/search-bar/search-filter-query-strings";
import {
  SelectFilterButton,
  TextFilterButton,
  DateRangeFilterButton,
} from "components/search-bar/FilterButtons";
import { OrganizationType } from "shared/constants/organization.const";

const typeFilterOptions = [
  {
    label: "Completed and Failed",
    value: "event-error",
  },
  {
    label: "Failed Only",
    value: "error",
  },
];

const eventTypeFilterOptions = () => {
  const vals = [
    "Create/Replace Shipment",
    "Cancel Shipment",
    "Supplemental Data",
    "Shipment Status",
    "Shipment Patch",
    "Create/Replace/Delete Shipment (EDI)",
    "Other",
  ];
  return vals.map((val) => ({ label: val, value: val }));
};

export const FILTERS = [
  {
    queryKey: "type",
    label: (t) => t("documentation:Status Type"),
    Component: SelectFilterButton,
    optionsGetter: () => typeFilterOptions,
    queryBuilder: getBasicQueryString,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ts",
    label: (t) => t("documentation:Date Range"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getApiLogsDateRangeQueryString,
  },
  {
    queryKey: "requestId",
    label: (t) => t("documentation:Request ID"),
    Component: TextFilterButton,
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "shipmentId",
    label: (t) => t("documentation:Shipment ID"),
    Component: TextFilterButton,
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "orgName",
    label: (t) => t("documentation:Shipper"),
    Component: SelectFilterButton,
    optionsGetter: (props) => props.shipperFilterOptions,
    queryBuilder: getBasicQueryString,
    requiredOrgTypes: [OrganizationType.CARRIER],
    hideSelectEmpty: true,
  },
  {
    queryKey: "orgName",
    label: (t) => t("documentation:Carrier"),
    Component: SelectFilterButton,
    optionsGetter: (props) => props.carrierFilterOptions,
    queryBuilder: getBasicQueryString,
    requiredOrgTypes: [OrganizationType.SHIPPER],
    hideSelectEmpty: true,
  },
  {
    queryKey: "eventType",
    label: (t) => t("documentation:Event Type"),
    Component: SelectFilterButton,
    optionsGetter: () => eventTypeFilterOptions(),
    queryBuilder: getBasicQueryString,
    hideSelectEmpty: true,
  },
];
