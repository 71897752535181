import { connect } from "react-redux";
import FederatedUserPreferenceModalState from "modules/auth/FederatedUserPreferenceModalState";
import { FederatedUserEmailUpdateModal } from "modules/auth/FederatedUserPreferenceUpdateModal";
import { ProfileState } from "modules/profile/ProfileState";

function mapStateToProps(state) {
  return {
    show: FederatedUserPreferenceModalState.selectors.getPopupShow(state),
    userPreferences: ProfileState.selectors.getUserPreferences(state),
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...stateProps, ...dispatchProps, ...ownProps };
  return props;
};

const actionCreators = {
  setShow: (show) => (dispatch) =>
    dispatch(
      FederatedUserPreferenceModalState.actionCreators.setPopupShow(show),
    ),
  setUserPreferences: (preferences) => (dispatch) =>
    dispatch(ProfileState.actionCreators.setPreferences(preferences)),
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FederatedUserEmailUpdateModal);
