//External hooks or supporting code from React
import { connect } from "react-redux";

//Components
import { CreateShipment } from "./CreateShipment.page";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import SearchBarState from "pages/shipments/redux/ShipmentStopsSearchBarState";
import CreateShipmentState from "pages/shipments/redux/CreateShipmentState";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  const { getSearchResults } = SearchBarState.selectors;

  return {
    activeOrganization: getActiveOrganization(state),
    createStatus: state.createShipment.createStatus,
    shipperLocations: getSearchResults(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setDescription: (description) =>
      dispatch(TitleState.actionCreators.setDescription(description)),
    createShipment: (data) =>
      dispatch(CreateShipmentState.actionCreators.createShipment(data)),
    resetCreateForm: () =>
      dispatch(CreateShipmentState.actionCreators.resetCreateForm()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateShipment);
