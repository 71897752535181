import apiUrl from "api-url";

export const partViewOriginUrl = (fvOrgId) => {
  let url = `/partview/app/list/origin`;
  if (fvOrgId) {
    url += `?fvOrgId=${fvOrgId}`;
  }
  return apiUrl(url);
};

export const partViewDestinationUrl = (fvOrgId) => {
  let url = `/partview/app/list/destination`;
  if (fvOrgId) {
    url += `?fvOrgId=${fvOrgId}`;
  }
  return apiUrl(url);
};

export const partViePartNumberUrl = (fvOrgId) => {
  let url = `/partview/app/list/parts`;
  if (fvOrgId) {
    url += `?fvOrgId=${fvOrgId}`;
  }
  return apiUrl(url);
};

export const partViewPackageStatusUrl = (fvOrgId) => {
  let url = `/partview/app/list/lifecycle-state`;
  if (fvOrgId) {
    url += `?fvOrgId=${fvOrgId}`;
  }
  return apiUrl(url);
};

export const partViewPriorityUrl = (fvOrgId) => {
  let url = `/partview/app/order-priority-list`;
  if (fvOrgId) {
    url += `?fvOrgId=${fvOrgId}`;
  }
  return apiUrl(url);
};
