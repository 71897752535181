export const getReferenceByName = (references = [], name = "") => {
  if (references && Array.isArray(references)) {
    return (
      references.find(
        (reference) =>
          reference.qualifier?.toLowerCase() === name?.toLowerCase(),
      ) ?? null
    );
  } else {
    return null;
  }
};

export const getReferenceValueByName = (references = [], name = "") => {
  return getReferenceByName(references, name)?.value ?? null;
};
