/**
 * Defines and controls state for the whole organizations screen.
 */
import axios from "axios";
import _ from "lodash";
import AuthenticationUtils from "modules/auth/authentication";
import AppConfigurationState from "shared/redux/AppConfigurationState";
import { BrowserStorage } from "utils/browser-storage.utils";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import MapState from "modules/map/MapState";

/**
 * @typedef {import('shared/constants/organization.const').Organization} Organization
 */

// URLS
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

// Actions
const SET_CURRENT_ORGANIZATION_ID = "Organizations/SET_CURRENT_ORGANIZATION_ID";
const SET_ACTIVE_ORGANIZATION = "Organizations/SET_ACTIVE_ORGANIZATION";
const SET_ORGANIZATION_FILTERS = "Organizations/SET_ORGANIZATION_FILTERS";
const CLEAR_ACTION_STATUS = "Organizations/CLEAR_ACTION_STATUS";
const SET_IS_FINISHED_VEHICLE_ACTIVE =
  "Organizations/SET_IS_FINISHED_VEHICLE_ACTIVE";

const FETCH_ORGANIZATIONS = "Organizations/FETCH_ORGANIZATIONS";
const RECEIVE_ORGANIZATIONS = "Organizations/RECEIVE_ORGANIZATIONS";
const FETCH_ORGANIZATIONS_ERROR = "Organizations/FETCH_ORGANIZATIONS_ERROR";

const RECEIVE_ORGANIZATION_IMAGES = "Organizations/RECEIVE_ORGANIZATION_IMAGES";
const RECEIVE_FEDERATION_DATA = "Organizations/RECEIVE_FEDERATION_DATA";
const RECEIVE_FEDERATION_DATA_ERROR =
  "Organizations/RECEIVE_FEDERATION_DATA_ERROR";
const RECEIVE_ORGANIZATION_TYPES = "Organizations/RECEIVE_ORGANIZATION_TYPES";

const FETCH_FEDERATED_ENTITY_INTERNAL_ID =
  "Organizations/FETCH_FEDERATED_ENTITY_INTERNAL_ID";
const RECEIVE_FEDERATED_ENTITY_INTERNAL_ID =
  "Organizations/RECEIVE_FEDERATED_ENTITY_INTERNAL_ID";
const RECEIVE_FEDERATED_ENTITY_INTERNAL_ID_ERROR =
  "Organizations/RECEIVE_FEDERATED_ENTITY_INTERNAL_ID_ERROR";

const ADD_ORGANIZATION = "Organizations/ADD_ORGANIZATION";
const ADD_ORGANIZATION_SUCCEEDED = "Organizations/ADD_ORGANIZATION_SUCCEEDED";
const ADD_ORGANIZATION_FAILED = "Organizations/ADD_ORGANIZATION_FAILED";

const UPDATE_ORGANIZATION = "Organizations/UPDATE_ORGANIZATION";
const UPDATE_ORGANIZATION_SUCCEEDED =
  "Organizations/UPDATE_ORGANIZATION_SUCCEEDED";
const UPDATE_ORGANIZATION_FAILED = "Organizations/UPDATE_ORGANIZATION_FAILED";

const DELETE_ORGANIZATION = "Organizations/DELETE_ORGANIZATION";
const DELETE_ORGANIZATION_SUCCEEDED =
  "Organizations/DELETE_ORGANIZATION_SUCCEEDED";
const DELETE_ORGANIZATION_FAILED = "Organizations/DELETE_ORGANIZATION_FAILED";

const FETCH_FEATURE_DATA = "Organizations/FETCH_FEATURE_DATA";
const RECEIVE_FEATURE_DATA = "Organizations/RECEIVE_FEATURE_DATA";
const SET_SOLUTION_ID = "Organizations/SET_SOLUTION_ID";

const FETCH_ENTITY_SYSTEM_CONFIG_OPTIONS =
  "Organizations/FETCH_ENTITY_SYSTEM_CONFIG_OPTIONS";
const RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS =
  "Organizations/RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS";
const CLEAR_ENTITY_SYSTEM_CONFIG_OPTIONS =
  "Organizations/CLEAR_ENTITY_SYSTEM_CONFIG_OPTIONS";

const FETCH_EXTERNAL_CODE_DEFINITION =
  "Organizations/FETCH_EXTERNAL_CODE_DEFINITION";
const RECEIVE_EXTERNAL_CODE_DEFINITION =
  "Organizations/RECEIVE_EXTERNAL_CODE_DEFINITION";
const CLEAR_EXTERNAL_CODE_DEFINITION =
  "Organizations/CLEAR_EXTERNAL_CODE_DEFINITION";

const FETCH_BRANDING_OPTIONS = "Organizations/FETCH_BRANDING_OPTIONS";
const RECEIVE_BRANDING_OPTIONS = "Organizations/RECEIVE_BRANDING_OPTIONS";
const CLEAR_BRANDING_OPTIONS = "Organizations/CLEAR_BRANDING_OPTIONS";

/**
 *
 * @param {{ ids?: number[], fvIds?: string[] }} args
 * @returns {ThunkAction}
 */
export function fetchOrganizations(args = {}) {
  const { ids = [], fvIds = [] } = args;

  return async (dispatch, getState) => {
    // Removing null/undefined values and ensuring correct type
    let idsToRequest = ids.filter((id) => id).map(Number);
    let fvIdsToRequest = fvIds.filter((id) => id).map(String);

    if (_.size(idsToRequest) <= 0 && _.size(fvIdsToRequest) <= 0) {
      // Either  DB ID(s) or FV ID(s) is required
      console.error(
        "fetchOrganizations: An array of `ids` or `fvIds` is required.",
      );
      return;
    }

    const state = getState();
    const organizations = getOrganizations(state);

    // Determine which orgs have not been requested.
    const loadingOrgIds = getLoadingOrgIds(state);
    const loadingOrgFvIds = getLoadingOrgFvIds(state);
    const existingIds = Object.keys(organizations).map(Number);
    const existingFvIds = Object.values(organizations).map((org) => org.fv_id);
    idsToRequest = _.difference(idsToRequest, [
      ...existingIds,
      ...loadingOrgIds,
    ]);
    fvIdsToRequest = _.difference(fvIdsToRequest, [
      ...existingFvIds,
      ...loadingOrgFvIds,
    ]);

    // If there are orgs that have not been fetched, make a request for orgs
    // by DB ID and orgs by FV ID.

    if (_.size(idsToRequest)) {
      dispatch({
        type: FETCH_ORGANIZATIONS,
        payload: {
          ids: idsToRequest,
        },
      });

      try {
        const res = await axios.get(ORGANIZATIONS_URL, {
          params: { organization_ids: JSON.stringify(idsToRequest) },
        });

        dispatch({
          type: RECEIVE_ORGANIZATIONS,
          payload: {
            ids: idsToRequest,
            data: res?.data?.response ?? [],
          },
        });
      } catch (error) {
        dispatch({
          type: FETCH_ORGANIZATIONS_ERROR,
          payload: {
            ids: idsToRequest,
            error,
          },
        });
      }
    }

    if (_.size(fvIdsToRequest)) {
      dispatch({
        type: FETCH_ORGANIZATIONS,
        payload: {
          fvIds: fvIdsToRequest,
        },
      });

      try {
        const res = await axios.get(ORGANIZATIONS_URL, {
          params: { organization_fv_id: JSON.stringify(fvIdsToRequest) },
        });

        dispatch({
          type: RECEIVE_ORGANIZATIONS,
          payload: {
            fvIds: fvIdsToRequest,
            data: res?.data?.response ?? [],
          },
        });
      } catch (error) {
        dispatch({
          type: FETCH_ORGANIZATIONS_ERROR,
          payload: {
            fvIds: fvIdsToRequest,
            error,
          },
        });
      }
    }
  };
}

const orgDetailsDuck = buildFetchDuck("organizations", "organizationDetails");

export function fetchOrganizationDetails(organizationId) {
  if (_.isNil(organizationId)) {
    console.error(
      "fetchOrganizaitonDetail: `organizationId` must be an number.",
    );
    return;
  }

  return (dispatch) => {
    const url = `${ORGANIZATIONS_URL}/${organizationId}`;
    return dispatch(
      orgDetailsDuck.fetch(url, undefined, (data) => {
        return data.response;
      }),
    );
  };
}

export function fetchFederationData() {
  const url = apiUrl("/iam/federation");

  return (dispatch) => {
    return axios
      .get(`${url}`)
      .then((response) => {
        BrowserStorage.federationData = JSON.stringify(response.data);
        dispatch({
          type: RECEIVE_FEDERATION_DATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEIVE_FEDERATION_DATA_ERROR,
          errorMessage: err.message,
        });
      });
  };
}

/**
 * Lookup internal entity ID from a given external ID.
 *
 * This is use when federated users pass a `state` param while logging in.
 * For Dealers, their redirect URL uses the internal ID, e.g. /vinview/details/:internalId
 * but they will send us the VIN.
 */
export function fetchFederatedEntityInternalId() {
  return (dispatch) => {
    const { solution, target, resource } =
      AuthenticationUtils.getFederatedRedirectOverrideState();

    if (target !== "vinview") {
      return;
    }

    if (!solution || !resource) {
      return;
    }

    dispatch({ type: FETCH_FEDERATED_ENTITY_INTERNAL_ID });

    const url = apiUrl(`/entity/solution/${solution}/entity/${resource}`);
    // Special version for a smaller payload that only has `internal_id` field.
    const headers = { Accept: "application/json;version=internalId" };

    return axios
      .get(url, { headers })
      .then((response) => {
        dispatch({
          type: RECEIVE_FEDERATED_ENTITY_INTERNAL_ID,
          payload: response.data?.internal_id,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEIVE_FEDERATED_ENTITY_INTERNAL_ID_ERROR,
        });
      });
  };
}

export function fetchOrganizationTypes() {
  const url = apiUrl("/iam/organization_profiles");

  return (dispatch) => {
    return axios
      .get(`${url}`)
      .then((response) => {
        dispatch({
          type: RECEIVE_ORGANIZATION_TYPES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RECEIVE_ORGANIZATION_TYPES,
          payload: [],
        });
        console.log(err);
      });
  };
}

export function fetchFeatureData() {
  const url = apiUrl("/iam/feature");

  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_FEATURE_DATA,
      });

      const response = await axios.get(`${url}`);
      const featureData = response.data;

      dispatch({
        type: RECEIVE_FEATURE_DATA,
        payload: featureData,
      });

      dispatch(extractAndSetSolutionId());
    } catch (err) {
      dispatch({
        type: RECEIVE_FEATURE_DATA,
        payload: [],
      });
    }
  };
}

function fetchOrganizationFilters() {
  const url = apiUrl(`/iam/organizations/filters`);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${url}`);
      const filtersData = response.data;
      dispatch({
        type: SET_ORGANIZATION_FILTERS,
        filters: filtersData.org_filters,
      });
    } catch (err) {
      dispatch({
        type: SET_ORGANIZATION_FILTERS,
        filters: [],
      });
    }
  };
}

export function fetchExternalCodeDefinition(orgId) {
  const url = apiUrl(`/iam/organizations/${orgId}/external-code-definition`);
  return async (dispatch) => {
    dispatch({ type: CLEAR_EXTERNAL_CODE_DEFINITION });

    try {
      dispatch({
        type: FETCH_EXTERNAL_CODE_DEFINITION,
      });

      const response = await axios.get(`${url}`);
      dispatch({
        type: RECEIVE_EXTERNAL_CODE_DEFINITION,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: RECEIVE_EXTERNAL_CODE_DEFINITION,
        payload: [],
      });
    }
  };
}

export function fetchBrandingOptions(shipperOrgId) {
  const url = apiUrl(`/iam/organizations/${shipperOrgId}/branding`);
  return async (dispatch) => {
    dispatch({ type: CLEAR_BRANDING_OPTIONS });

    try {
      dispatch({
        type: FETCH_BRANDING_OPTIONS,
      });

      const response = await axios.get(`${url}`);
      dispatch({
        type: RECEIVE_BRANDING_OPTIONS,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: RECEIVE_BRANDING_OPTIONS,
        payload: [],
      });
    }
  };
}

function fetchEntitySystemConfigOptions(solutionId) {
  return async (dispatch, getState) => {
    // SH-9858: Temporarily use org FV ID as a fallback to retrieve entity configs.
    // This will be replaced with a generic system config similar to AppConfiguration.
    let systemConfigKey = solutionId;
    if (!systemConfigKey) {
      const state = getState();
      const orgId = getCurrentOrganizationId(state);
      const org = getOrganizations(state)[orgId];
      systemConfigKey = org?.fv_id ?? null;
    }

    dispatch({ type: CLEAR_ENTITY_SYSTEM_CONFIG_OPTIONS });

    if (systemConfigKey) {
      const url = apiUrl(
        `/entity/solution/${systemConfigKey}/system-configuration`,
      );
      try {
        dispatch({
          type: FETCH_ENTITY_SYSTEM_CONFIG_OPTIONS,
        });

        const response = await axios.get(`${url}`);
        const entitySystemConfigOptionsData = response.data;

        dispatch({
          type: RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS,
          payload: entitySystemConfigOptionsData,
        });
      } catch (err) {
        dispatch({
          type: RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS,
          payload: [],
        });
      }
    } else {
      dispatch({
        type: RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS,
        payload: [],
      });
    }
  };
}

export function fetchOrganizationImages() {
  const url = `${ORGANIZATIONS_URL}/images`;

  return (dispatch) => {
    return axios
      .get(`${url}`)
      .then((response) => {
        let payload = {
          logoPath: response.data?.logoUrl,
          iconPath: response.data?.iconUrl,
        };
        dispatch({
          type: RECEIVE_ORGANIZATION_IMAGES,
          payload: payload,
        });
      })
      .catch((err) => {});
  };
}

function extractAndSetSolutionId() {
  return (dispatch, getState) => {
    const state = getState();
    const featureData = getFeatureData(state);

    const finishedVehicleData = _.find(featureData, {
      feature_name: "Finished Vehicle",
    });

    const solutionId = finishedVehicleData?.solution
      ? finishedVehicleData.solution
      : null;

    BrowserStorage.solutionId = solutionId;

    dispatch({
      type: SET_SOLUTION_ID,
      solutionId: solutionId,
    });

    dispatch(fetchEntitySystemConfigOptions(solutionId));
    dispatch(fetchOrganizationFilters());
  };
}

export function setCurrentOrganization(organizationId) {
  const id = parseInt(organizationId, 10);
  BrowserStorage.currentOrganization = organizationId;

  return async (dispatch) => {
    // Fetch the org details
    await dispatch(fetchOrganizations({ ids: [id] }));

    // Fetch feature data which also sets the solution ID
    dispatch(fetchFeatureData());

    dispatch({
      type: SET_CURRENT_ORGANIZATION_ID,
      currentOrganizationId: id,
    });
  };
}

export function setActiveOrganization(org) {
  return (dispatch) => {
    dispatch(
      AppConfigurationState.actionCreators.fetchGlobalAppConfig(org.fv_id),
    );
    dispatch(MapState.actionCreators.setMapPlatform(org.map_type));
    dispatch(MapState.actionCreators.setDefaultCenter(org.map_coordinates));
    dispatch({
      type: SET_ACTIVE_ORGANIZATION,
      org: org,
    });
  };
}

export function addOrganization(payload) {
  return function (dispatch) {
    if (payload.external_codes) {
      payload.external_codes = formatExternalCodesForApi(
        payload.external_codes,
      );
    }
    dispatch({
      type: ADD_ORGANIZATION,
      payload,
    });
    const url = ORGANIZATIONS_URL;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({
          type: ADD_ORGANIZATION_SUCCEEDED,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_ORGANIZATION_FAILED,
          error,
        });
      });
  };
}

export function updateOrganization(organizationId, payload) {
  return function (dispatch) {
    if (payload.external_codes) {
      payload.external_codes = formatExternalCodesForApi(
        payload.external_codes,
      );
    }
    dispatch({
      type: UPDATE_ORGANIZATION,
      organizationId,
      payload,
    });
    const url = ORGANIZATIONS_URL + `/${organizationId}`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({
          type: UPDATE_ORGANIZATION_SUCCEEDED,
          organizationId,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ORGANIZATION_FAILED,
          organizationId,
          error,
        });
      });
  };
}

const formatExternalCodesForApi = (externalCodes) => {
  let formattedExternalCodes = [];
  for (const externalCode in externalCodes) {
    if (!_.isNil(externalCodes[externalCode])) {
      const formattedExternalCode = [
        ...externalCodes[externalCode].map((externalCodeObj) => {
          return {
            qualifier: externalCode,
            value: externalCodeObj.value,
          };
        }),
      ];
      formattedExternalCodes = formattedExternalCodes.concat(
        formattedExternalCode,
      );
    }
  }
  return formattedExternalCodes;
};

export function deleteOrganization(organizationId) {
  return function (dispatch) {
    dispatch({
      type: DELETE_ORGANIZATION,
      organizationId,
    });
    const url = ORGANIZATIONS_URL + `/${organizationId}`;
    return axios
      .delete(url)
      .then((resp) => {
        dispatch({
          type: DELETE_ORGANIZATION_SUCCEEDED,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ORGANIZATION_FAILED,
          organizationId,
          error,
        });
      });
  };
}

export function clearExternalCodeDefinition() {
  return (dispatch) => {
    dispatch({ type: CLEAR_EXTERNAL_CODE_DEFINITION });
  };
}

export function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS,
  };
}

export const getCurrentOrganizationId = (state) =>
  state?.organizations?.currentOrganizationId;

export const getOrganizations = (state) => state.organizations.data ?? {};

export const getLoadingOrgIds = (state) => state.organizations.loadingOrgIds;

export const getLoadingOrgFvIds = (state) =>
  state.organizations.loadingOrgFvIds;

export const getIsOrganizationsLoading = (state) =>
  state.organizations.isOrganizationsLoading;

export const getOrganizationByDbId = (ids) => (state) => {
  const organizations = getOrganizations(state);

  const orgs = ids
    .map((id) => organizations?.[id] ?? null)
    .filter((org) => org !== null);

  const isLoading =
    getLoadingOrgIds(state).filter((id) => ids.includes(id)).length > 0;

  return {
    /** @type {boolean} */
    isLoading: isLoading,
    /** @type {Organization[]} */
    organizations: isLoading ? [] : orgs,
  };
};

export const getOrganizationByFvId = (fvIds) => (state) => {
  const organizations = getOrganizations(state);

  const orgs = _.filter(Object.values(organizations), ({ fv_id }) =>
    _.includes(fvIds, fv_id),
  );

  const isLoading =
    getLoadingOrgFvIds(state).filter((fvId) => fvIds.includes(fvId)).length > 0;

  return {
    /** @type {boolean} */
    isLoading: isLoading,
    /** @type {Organization[]} */
    organizations: isLoading ? [] : orgs,
  };
};

export const getOrganizationsLoadingError = (state) => {
  return state.organizations.organizationsLoadingError ?? null;
};

export const getOrganizationDetailsRequestData = (state) => {
  return orgDetailsDuck.selectors.getData(state) ?? {};
};

export const getOrganizationTypes = (state) =>
  state.organizations.organizationTypes ?? [];

export const getActiveOrganization = (state) =>
  state.organizations.activeOrganization;

export const getOrganizationFilters = (state) =>
  state.organizations.organizationFilters;

export const getOrganizationImageConfig = (state) =>
  state.organizations.organizationImageConfig;

export const getIsFinishedVehicleActive = (state) =>
  state.organizations.isFinishedVehicleActive;

export const getSolutionId = (state) =>
  state?.organizations?.solutionId ?? BrowserStorage.solutionId;

export const getFeatureData = (state) => state.organizations.featureData;
export const getFeatureDataLoading = (state) =>
  state.organizations.featureDataLoading;

export const getFederationData = (state) => state.organizations.federationData;
export const getFederationDataErrorMessage = (state) =>
  state.organizations.federationDataErrorMessage;

export const getFederatedEntityInternalId = (state) =>
  state.organizations.federatedEntityInternalId;
export const getFederatedEntityInternalIdIsLoading = (state) =>
  state.organizations.federatedEntityInternalIdIsLoading;

export const getEntitySystemConfigOptions = (state) =>
  state.organizations.entitySystemConfigOptions;
export const getEntitySystemConfigOptionsLoading = (state) =>
  state.organizations.entitySystemConfigOptionsLoading;

export const getExternalCodeDefinitions = (state) =>
  state.organizations.externalCodeDefinitions;
export const getExternalCodeDefinitionsLoading = (state) =>
  state.organizations.externalCodeDefinitionsLoading;

export const getBrandingOptions = (state) =>
  state.organizations.brandingOptions;
export const getBrandingOptionsLoading = (state) =>
  state.organizations.brandingOptionsLoading;

// TODO H1-4682: Use consistent naming for loading booleans.
const initialState = {
  actionStatus: null,
  /**
   * @type {Object.<number, Organization>}
   * @example
   * {
   *   [928]: {
   *      organization_id: 928,
   *      org_name: "FreightVerify Inc.",
   *      org_type: "FreightVerify",
   *      fv_id: "FV001",
   *      map_type: "Here"
   *   },
   *   [1004]: {
   *      organization_id: 1004,
   *      org_name: "TEST-Shipper",
   *      org_type: "Shipper",
   *      fv_id: "FV3221778A",
   *      map_type: "Here"
   *   },
   *   // ...other organizations
   * }
   */
  data: {},
  loadingOrgIds: [],
  loadingOrgFvIds: [],
  isOrganizationsLoading: false,
  organizationImageConfig: {},
  organizationFilters: [],
  activeOrganization: {},
  currentOrganizationId: null,
  isFinishedVehicleActive: false,
  featureData: [],
  featureDataLoading: true,
  entitySystemConfigOptions: [],
  entitySystemConfigOptionsLoading: false,
  externalCodeDefinitions: [],
  externalCodeDefinitionsLoading: false,
  brandingOptions: [],
  brandingOptionsLoading: false,
  solutionId: null,
  federationDataErrorMessage: null,
  federatedEntityInternalId: null,
  federatedEntityInternalIdIsLoading: false,
};

function OrganizationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        activeOrganization: action.org,
      };
    case SET_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        currentOrganizationId: action.currentOrganizationId,
      };

    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        isOrganizationsLoading: true,
        loadingOrgIds: [...state.loadingOrgIds, ...(action.payload.ids ?? [])],
        loadingOrgFvIds: [
          ...state.loadingOrgFvIds,
          ...(action.payload.fvIds ?? []),
        ],
      };
    case RECEIVE_ORGANIZATIONS: {
      const newData = {};
      action.payload.data.forEach((org) => {
        newData[org.organization_id] = { ...org };
      });

      return {
        ...state,
        data: { ...state.data, ...newData },
        isOrganizationsLoading: false,
        loadingOrgIds: _.difference(
          state.loadingOrgIds,
          action.payload.ids ?? [],
        ),
        loadingOrgFvIds: _.difference(
          state.loadingOrgFvIds,
          action.payload.fvIds ?? [],
        ),
      };
    }
    case FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        organizationsLoadingError: action.payload.error,
        isOrganizationsLoading: false,
        loadingOrgIds: _.difference(
          state.loadingOrgIds,
          action.payload.ids ?? [],
        ),
        loadingOrgFvIds: _.difference(
          state.loadingOrgFvIds,
          action.payload.fvIds ?? [],
        ),
      };

    case RECEIVE_FEDERATION_DATA:
      return {
        ...state,
        federationData: action.payload,
        federationDataErrorMessage: null,
      };
    case RECEIVE_FEDERATION_DATA_ERROR: {
      return {
        ...state,
        federationDataErrorMessage: action.errorMessage ?? "Error",
      };
    }
    case FETCH_FEDERATED_ENTITY_INTERNAL_ID:
      return { ...state, federatedEntityInternalIdIsLoading: true };
    case RECEIVE_FEDERATED_ENTITY_INTERNAL_ID:
      return {
        ...state,
        federatedEntityInternalId: action.payload,
        federatedEntityInternalIdIsLoading: false,
      };
    case RECEIVE_FEDERATED_ENTITY_INTERNAL_ID_ERROR:
      return { ...state, federatedEntityInternalIdIsLoading: false };
    case SET_IS_FINISHED_VEHICLE_ACTIVE:
      return {
        ...state,
        isFinishedVehicleActive: action.isFinishedVehicleActive,
      };
    case RECEIVE_ORGANIZATION_TYPES:
      return {
        ...state,
        organizationTypes: action.payload.response,
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
      };
    case ADD_ORGANIZATION_SUCCEEDED:
      return {
        ...state,
        actionStatus: "ORGANIZATION_ADDED",
      };
    case UPDATE_ORGANIZATION_SUCCEEDED:
      return {
        ...state,
        actionStatus: "ORGANIZATION_UPDATED",
      };
    case ADD_ORGANIZATION_FAILED:
    case UPDATE_ORGANIZATION_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          (action.error.response.status === 409
            ? "Organization_Exists"
            : "Organization_Add_Error"),
      };
    case FETCH_FEATURE_DATA:
      return {
        ...state,
        featureDataLoading: true,
      };
    case RECEIVE_FEATURE_DATA:
      return {
        ...state,
        featureData: action.payload,
        featureDataLoading: false,
      };
    case FETCH_ENTITY_SYSTEM_CONFIG_OPTIONS:
      return {
        ...state,
        entitySystemConfigOptionsLoading: true,
      };
    case RECEIVE_ENTITY_SYSTEM_CONFIG_OPTIONS:
      return {
        ...state,
        entitySystemConfigOptions: action.payload,
        entitySystemConfigOptionsLoading: false,
      };
    case CLEAR_ENTITY_SYSTEM_CONFIG_OPTIONS:
      return {
        ...state,
        entitySystemConfigOptions: [],
      };
    case FETCH_EXTERNAL_CODE_DEFINITION:
      return {
        ...state,
        externalCodeDefinitionsLoading: true,
      };
    case RECEIVE_EXTERNAL_CODE_DEFINITION:
      return {
        ...state,
        externalCodeDefinitions: action.payload,
        externalCodeDefinitionsLoading: false,
      };
    case CLEAR_EXTERNAL_CODE_DEFINITION:
      return {
        ...state,
        externalCodeDefinitions: initialState.externalCodeDefinitions,
      };
    case FETCH_BRANDING_OPTIONS:
      return {
        ...state,
        brandingOptionsLoading: true,
      };
    case RECEIVE_BRANDING_OPTIONS:
      return {
        ...state,
        brandingOptions: action.payload,
        brandingOptionsLoading: false,
      };
    case CLEAR_BRANDING_OPTIONS:
      return {
        ...state,
        brandingOptions: initialState.brandingOptions,
      };
    case RECEIVE_ORGANIZATION_IMAGES:
      return {
        ...state,
        organizationImageConfig: action.payload,
      };
    case SET_SOLUTION_ID:
      return {
        ...state,
        solutionId: action.solutionId,
      };
    case SET_ORGANIZATION_FILTERS:
      return {
        ...state,
        organizationFilters: action.filters,
      };
    default:
      return state;
  }
}

export default chainReducers([OrganizationsReducer, orgDetailsDuck.reducer]);
