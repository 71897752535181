/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

const ValueCell = (props) => {
  const { value } = props.value;

  if (!value) {
    return (
      <Icon
        src={faMinus}
        color={Colors.tables.tableHeadingText}
        css={{ display: "block", margin: "auto" }}
      />
    );
  }

  return <Text>{value}</Text>;
};

ValueCell.propTypes = {
  // Regarding the name of the prop:
  // react-table puts the data from "accessor" in the "value" field
  // and this table shows multiple types of data in the Value column
  // which is why the name "value" in the row was chosen.
  value: PropTypes.shape({ value: PropTypes.any }),
};

export const useExpandedDetailColumns = () => {
  const { t } = useTranslation("shipment-eta-validator");
  return [
    { Header: t("shipment-eta-validator:Name"), accessor: "name" },
    {
      Header: t("shipment-eta-validator:Value"),
      accessor: (row) => ({ value: row.value, type: row.type }),
      Cell: ValueCell,
    },
  ];
};
