/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useUncontrolled } from "uncontrollable";
import { CustomCheckboxContainer, CustomCheckboxInput } from "@reach/checkbox";
import "@reach/checkbox/styles.css";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontSize, Icon } from "components/atoms/Icon.atom";

/**
 * A checkbox. Can be controlled or uncontrolled.
 */
export const Checkbox = (props) => {
  // Controls the `checked` prop.
  // This allows it to not be passed in but still maintain state in useUncontrolled.
  const {
    id,
    className,
    "data-qa": dataQa,
    onChange,
    // Need to set a sensible default.
    checked = false,
    disabled = false,
    // Display for unchecked/default
    color,
    icon = faSquare,
    iconSize = FontSize.size16,
    // Display for checked
    checkedColor,
    checkedIcon = faCheckSquare,
    // Display for disabled
    disabledColor,
  } = useUncontrolled(props, {
    // Since we told useUncontrolled that `onChange` is the handler for the checked state,
    // we don't have to null check it before calling it.
    // useUncontrolled gives us a default onChange
    checked: "onChange",
  });

  const handleOnChange = (event) => {
    onChange(event.target.checked, event);
  };

  return (
    <CustomCheckboxContainer
      checked={checked}
      disabled={disabled}
      onChange={(event) => handleOnChange(event)}
      css={{
        display: "inline-flex",
        alignItems: "center",
        outline: "none !important",
        width: "auto",
        height: "auto",
        boxShadow: "none !important",
        cursor: "pointer",
      }}
      className={className}
    >
      {/* Reach's internal checkbox for accessibilty */}
      <CustomCheckboxInput id={id} data-qa={dataQa} />

      {/* The displayed "checkbox" */}
      <Icon
        aria-hidden
        src={checked ? checkedIcon : icon}
        color={disabled ? disabledColor : checked ? checkedColor : color}
        size={iconSize}
      />
    </CustomCheckboxContainer>
  );
};

Checkbox.propTypes = {
  /**
   * The id of the checkbox.
   *
   * Useful for labeling this checkbox with a label's htmlFor prop.
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A string for defining one or more classes that will be added to the checkbox.
   */
  className: PropTypes.string,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
  /**
   * A handler for the onChange event.
   *
   * This function is passed two parameters; the new checked value and the event object.
   *
   * e.g. (checked, event) => void
   */
  onChange: PropTypes.func,
  /**
   * The value of the checkbox.
   */
  checked: PropTypes.bool,
  /**
   * Disables checkbox, preventing it from being clicked
   */
  disabled: PropTypes.bool,
  /**
   * The default color of the checkbox.
   */
  color: PropTypes.string,
  /**
   * The icon of the checkbox when unchecked. This is passed as `src` to `<Icon />`
   * and must be a Font Awesome icon.
   *
   * Default: faSquare (from @fortawesome/pro-regular-svg-icons)
   */
  icon: PropTypes.any,
  /**
   * The size of the checkbox.
   */
  iconSize: PropTypes.any,
  /**
   * The color of the checkbox when checked.
   */
  checkedColor: PropTypes.string,
  /**
   * The icon of the checkbox when checked. This is passed as `src` to `<Icon />`
   * and must be a Font Awesome icon.
   *
   * Default: faCheckSquare (from @fortawesome/pro-solid-svg-icons)
   */
  checkedIcon: PropTypes.any,
  /**
   * The color of the checkbox when disabled.
   */
  disabledColor: PropTypes.string,
};
