/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import Loader from "react-loader";
import styled from "@emotion/styled";
import { Alert } from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { getColumns } from "pages/reports/search/Reports.columns";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { FontSize } from "components/atoms/enums";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1em",
});

export const ReportBuilder = ({
  fetchReports,
  pushReportDetailScreen,
  reports,
  isLoading,
}) => {
  const { t } = useTranslation("reports");
  useSetTitleOnMount(t("reports:Report Builder"));
  useSetDescriptionOnMount(
    t(
      "reports:The Report Builder allows you to create your own report from a pre-defined data set. Share your reports with your organization using our shared reports feature.",
    ),
  );
  useTrackWithMixpanelOnce("Viewed Page: BI / Report Builder / Dashboard");

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = getColumns(t, "REPORT_BUILDER", false, false);

  const reportBuilderReport = reports.filter(
    (r) => r.groupName === "Report Builder",
  );

  return (
    <Loader loaded={!isLoading}>
      <Section>
        {reportBuilderReport.length === 0 && (
          <Alert variant="warning">
            {t("reports:No Report Builders Found")}
          </Alert>
        )}
        {reportBuilderReport && reportBuilderReport.length > 0 && (
          <PanelGroup collapsible={true} style={{ margin: "1em" }}>
            <PanelGroup.Header
              titleStyle={{ minHeight: "34px" }}
              title={t("reports:Report Builder")}
              titleSize={FontSize.size18}
              iconSize={FontSize.size16}
              tooltipPosition={"bottom"}
              postTitleIcon={faInfoCircle}
              postTitleIconTooltip={t(
                "reports:The Report Builder allows you to create your own report from a pre-defined data set. Share your reports with your organization using our shared reports feature.",
              )}
            />
            <PanelGroup.Content style={{ padding: 0 }}>
              <BaseTable
                theme={Themes.LIGHT}
                data={reportBuilderReport}
                columns={columns}
                pageSize={reportBuilderReport.length}
                rowClickHandler={(row, cell) => {
                  const reportId = row.original.reportId;
                  pushReportDetailScreen(reportId);
                }}
              />
            </PanelGroup.Content>
          </PanelGroup>
        )}
      </Section>
    </Loader>
  );
};

ReportBuilder.propTypes = {
  fetchReports: PropTypes.func.isRequired,
  pushReportDetailScreen: PropTypes.func.isRequired,
  reports: PropTypes.array,
  isLoading: PropTypes.bool,
};
