/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerQuestion,
  faMapMarkerExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  timeFormatterNonUtc,
  dateFormatterNonUtc,
} from "../../../utils/date-time";
import DetailsTable from "components-old/DetailsTable";
import { BoldText } from "../../../styles/container-elements";
import LocationCell from "components/organisms/bootstrap-table/Cell/LocationCell";
import { NaPlaceholder } from "../../../components/no-data-placeholders";

const EventCell = ({ event, isMissed, isUnplanned, t }) => {
  return (
    <td style={{ width: 60, textAlign: "center" }}>
      <div>{event}</div>
      {isMissed ? (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="alert-tooltip">
              <div
                css={{
                  padding: ".75em",
                  textAlign: "bottom",
                }}
              >
                {t("Missed Event")}
              </div>
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faMapMarkerExclamation} />
        </OverlayTrigger>
      ) : null}
      {isUnplanned ? (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="alert-tooltip">
              <div
                css={{
                  padding: ".75em",
                  textAlign: "bottom",
                }}
              >
                {t("Unplanned Event")}
              </div>
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faMapMarkerQuestion} />
        </OverlayTrigger>
      ) : null}
    </td>
  );
};

EventCell.propTypes = {
  event: PropTypes.string,
  isMissed: PropTypes.bool,
  isUnplanned: PropTypes.bool,
  t: PropTypes.func,
};

const DateTimeCell = ({ time, timezone }) => (
  <div>
    <div>{timeFormatterNonUtc(time, timezone)}</div>
    <BoldText>{dateFormatterNonUtc(time, timezone)}</BoldText>
  </div>
);
DateTimeCell.propTypes = {
  time: PropTypes.string,
  timezone: PropTypes.string,
};

function getRows(tripPlan, t) {
  return tripPlan.tripPlan.map((tp, i) => {
    return (
      <tr key={i} data-qa="rows-stops-table-multi">
        <EventCell
          event={tp.event}
          isMissed={tp.isMissed}
          isUnplanned={tp.isUnplanned}
          t={t}
        />
        <LocationCell location={tp.location} showAddress={true} />
        <td>
          {tp.scheduled ? (
            <DateTimeCell time={tp.scheduled} timezone={tp.timezone} />
          ) : null}
        </td>
        <td>
          {tp.estimated ? (
            <DateTimeCell time={tp.estimated} timezone={tp.timezone} />
          ) : null}
        </td>
        <td>
          {tp.actual ? (
            <DateTimeCell time={tp.actual} timezone={tp.timezone} />
          ) : (
            <NaPlaceholder />
          )}
        </td>
      </tr>
    );
  });
}

const TripPlanTable = ({ tripPlan, tripPlanIsLoading }) => {
  const { t } = useTranslation("shipment-details");

  if (!tripPlan) {
    return null;
  }

  let rows = getRows(tripPlan, t);

  let headers = [
    { title: t("Event"), centered: true },
    t("Location"),
    t("Scheduled"),
    t("Estimated"),
    t("Actual"),
  ];

  return <DetailsTable headers={headers} rows={rows} />;
};

TripPlanTable.propTypes = {
  tripPlan: PropTypes.object,
  tripPlanIsLoading: PropTypes.bool,
};

export default TripPlanTable;
