/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { DealerPartViewSavedSearch } from "pages/partview/dashboard/components/organisms/DealerPartView.SavedSearch.organism";
import DealerPartViewEditSavedSearchModalContainer from "pages/partview/components/search/DealerPartView.EditSavedSearchModal.container";

import DealerPartViewSearchBarState from "pages/partview/redux/DealerPartViewSearchBarState";
import DealerPartViewSavedSearchState from "pages/partview/redux/DealerPartViewSavedSearchState";

import Colors from "styles/colors";

export const DealerPartViewSavedSearchesPanel = ({
  isDealerOrg = true,
  selectedFvOrgId = null,
  isPartSeller = false,
}) => {
  const savedSearches = useSelector(
    DealerPartViewSavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    DealerPartViewSavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(() => {
    if (isDealerOrg || (!isDealerOrg && selectedFvOrgId)) {
      dispatch(
        DealerPartViewSavedSearchState.actionCreators.fetchSavedSearches(),
      );
    }
  }, [dispatch, isDealerOrg, selectedFvOrgId]);

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={DealerPartViewSavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                DealerPartViewSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
            isPartSeller,
          };
        }}
        contentBackgroundColor={Colors.background.WHITE}
      />
      <DealerPartViewEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            DealerPartViewSearchBarState.actionCreators.resetSearchAndFilters(
              true,
            ),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(
            DealerPartViewSavedSearchState.actionCreators.deleteSearch(id),
          );
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};

DealerPartViewSavedSearchesPanel.propTypes = {
  isPartSeller: PropTypes.bool,
  isDealerOrg: PropTypes.bool,
  selectedFvOrgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
