/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "@emotion/styled";
import { FlexColDiv, FlexDiv } from "../../../styles/container-elements";
import LinkButton from "../../../vendor/signal-widgets/components/link-button";

import {
  LadChicletCSS as Chiclet,
  colorsForLad,
} from "../../../components/chiclets";

const formattedStartCase = (str) => (str ? _.startCase(_.toLower(str)) : "");

const nameCss = {
  color: "#151724",
  fontSize: "larger",
  fontWeight: "bold",
  marginLeft: "1em",
  lineHeight: "1.5em",
  padding: 0,
  textAlign: "left",
  width: "70%",
};

const Addr = styled.div({
  color: "#a1a1a1",
  lineHeight: "1em",
  marginTop: ".5em",
});

export const LocationInfoPopup = ({ data, lad, eventHandler }) => {
  let col = colorsForLad(lad);
  const formattedName = formattedStartCase(data.name);
  const nameComponent = eventHandler ? (
    <LinkButton
      css={nameCss}
      onClick={() => eventHandler(data, "LOCATION_LINK")}
    >
      {formattedName}
    </LinkButton>
  ) : (
    <div css={nameCss}>{formattedName}</div>
  );

  return (
    <FlexColDiv
      css={{
        backgroundColor: "white",
        border: `4px solid ${col.primary}`,
        borderRadius: 4,
        padding: ".5em",
        fontSize: "x-small",
        width: "18em",
      }}
    >
      <FlexDiv
        css={{
          display: "inline-flex",
        }}
      >
        <Chiclet height={20} width={20} lad={lad} />
        {nameComponent}
      </FlexDiv>
      <Addr>{formattedStartCase(data.address)}</Addr>
      <Addr>{`${formattedStartCase(data.city)}, ${data.state}`}</Addr>
      <div
        className="locinfowindow-tip"
        css={{
          borderLeft: "12px solid transparent",
          borderRight: "12px solid transparent",
          borderTop: `12px solid ${col.primary}`,
          bottom: -10,
          height: 0,
          position: "absolute",
          right: 11,
          width: 0,
        }}
      />
    </FlexColDiv>
  );
};

LocationInfoPopup.propTypes = {
  data: PropTypes.object.isRequired,
  lad: PropTypes.object.isRequired,
  eventHandler: PropTypes.func,
};
