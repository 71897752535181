import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const STORE_MOUNT_POINT = "partViewDealerOnboardingToolState";
const url = apiUrl(
  "/partview-visibility/api/partview-dealer-onboarding-upload",
);

// Helpers
const csvUploadDuck = buildFetchDuck(STORE_MOUNT_POINT, "csvUpload");

const onCsvUpload = (payload) => (dispatch) => {
  dispatch(csvUploadDuck.fetch(url, { method: "post", data: payload }));
};

export const PartViewDealerOnboardingToolState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    onCsvUpload,
  },
  selectors: {
    getCsvUploadResponseData: csvUploadDuck.selectors.getData,
  },
  reducer: csvUploadDuck.reducer,
};
