/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import { MdAdd } from "react-icons/md";
import Loader from "react-loader";

import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import AddUserModal from "./components/AddUserModal";
import ImportUsersModal from "./components/ImportUsersModal";
import UserActiveModal from "./components/UserActiveModal";
import EditUserModal from "./components/EditUserModal";
import DeleteUserModal from "./components/DeleteUserModal";
import DetailsUserModal from "./components/DetailsUserModal";
import UserPasswordModal from "./components/UserPasswordModal";
import UsersTable from "./components/UsersTable";
import SearchBarContainer from "./UsersSearchBarContainer";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const ModalMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  BLOCK: "BLOCK",
  UNBLOCK: "UNBLOCK",
  RESET: "RESET",
  IMPORT: "IMPORT",
  DETAILS: "DETAILS",
};

const UsersView = ({
  currentOrganizationId,
  fetchRoles,
  fetchOrganizationMembers,
  isOrganizationMembersLoaded,
  organizationMembers,
  roles,
  page,
  pageSize,
  totalPages,
  totalCount,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
  setMembersSort,
  setMembersPagination,
  clearOrganizationMemberDetails,
  exportEntities,
  isExporting,
  exportIdentifier,
  exportName,
  exportFailed,
  clearExportErrors,
  resetExport,
}) => {
  const { t } = useTranslation("users");

  useSetTitleOnMount(t("users:User Management"));
  useSetDescriptionOnMount(
    t(
      "users:Add, delete, or update permissions for users in your organization",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: User Management");

  const [showModal, setShowModal] = useState({ modalMode: null, user: null });

  useEffect(() => {
    fetchRoles();
    fetchOrganizationMembers();
  }, [fetchOrganizationMembers, fetchRoles]);

  const closeModal = () => {
    setShowModal({ modalMode: null, user: null });
  };

  const onPageChange = useCallback(
    (newPage) => {
      setMembersPagination(currentOrganizationId, newPage, pageSize);
    },
    [setMembersPagination, currentOrganizationId, pageSize],
  );

  const onPageSizeChange = useCallback(
    (newPageSize) => {
      setMembersPagination(currentOrganizationId, page, newPageSize);
    },
    [setMembersPagination, currentOrganizationId, page],
  );

  const onPageSort = useCallback(
    (column, isReverse) => {
      setMembersSort(currentOrganizationId, column, isReverse);
    },
    [setMembersSort, currentOrganizationId],
  );

  const rowActionHandler = (action, val) => {
    setShowModal({ modalMode: ModalMode[action], user: val });
  };

  // filter bad data until it cleaned up
  let filteredUsers = organizationMembers
    ? _.filter(organizationMembers, function (o) {
        return o.name !== "<User Not Found>";
      })
    : [];

  return (
    <div className="p-3">
      <div className="d-flex flex-column flex-sm-row">
        <Loader loaded={!isExporting} />
        <ExportAlert
          exportFailed={exportFailed}
          onDismiss={clearExportErrors}
          className="my-2"
        />
        <div className="flex-grow-1 mb-2 mb-sm-0">
          <SearchBarContainer
            isShowingAdvancedSearchButton={false}
            showSearchOptions={false}
            isShowingFilters={false}
          />
        </div>

        <Button
          variant="success"
          className="mb-2 mb-sm-0 ms-sm-2"
          onClick={() =>
            setShowModal({ modalMode: ModalMode.CREATE, user: null })
          }
        >
          <MdAdd /> {t("users:Add New User")}
        </Button>

        <Button
          variant="success"
          className="ms-sm-2"
          onClick={() =>
            setShowModal({ modalMode: ModalMode.IMPORT, user: null })
          }
        >
          {t("users:Import Users")}
        </Button>
      </div>

      <div className="d-flex py-3">
        <DownloadDataButton
          onClick={() => exportEntities()}
          className="ms-auto pe-0"
          isDisabled={filteredUsers.length === 0}
          isExporting={isExporting}
          data-qa="button-search-results-export"
          style={{
            display: exportEntities === null ? "none" : "inline",
          }}
        />
        <ExportModal
          exportIdentifier={exportIdentifier}
          exportName={exportName}
          resetExport={resetExport}
        />
      </div>
      <div>
        <UsersTable
          data={filteredUsers}
          roles={roles}
          actionHandler={rowActionHandler}
          isLoaded={!isOrganizationMembersLoaded}
          pageIndex={page}
          pageSize={pageSize}
          pageCount={totalPages}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onPageSort={onPageSort}
          sortColumn={sortColumn}
          reverseSort={reverseSort}
          defaultSortColumn={defaultSortColumn}
          defaultReverseSort={defaultReverseSort}
        />
      </div>
      <AddUserModal
        show={showModal.modalMode === ModalMode.CREATE}
        hide={() => closeModal()}
      />
      <ImportUsersModal
        show={showModal.modalMode === ModalMode.IMPORT}
        hide={() => closeModal()}
      />
      <UserActiveModal
        show={
          showModal.modalMode === ModalMode.BLOCK ||
          showModal.modalMode === ModalMode.UNBLOCK
        }
        hide={() => closeModal()}
        user={showModal.user}
      />
      <EditUserModal
        show={showModal.modalMode === ModalMode.UPDATE}
        hide={() => closeModal()}
        user={showModal.user}
      />
      <DeleteUserModal
        show={showModal.modalMode === ModalMode.DELETE}
        hide={() => closeModal()}
        user={showModal.user}
      />
      <UserPasswordModal
        show={showModal.modalMode === ModalMode.RESET}
        hide={() => closeModal()}
        user={showModal.user}
      />
      <DetailsUserModal
        show={showModal.modalMode === ModalMode.DETAILS}
        hide={() => {
          closeModal();
          clearOrganizationMemberDetails();
        }}
        user={showModal.user}
      />
    </div>
  );
};

UsersView.propTypes = {
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchOrganizationMembers: PropTypes.func.isRequired,
  isOrganizationMembersLoaded: PropTypes.bool.isRequired,
  organizationMembers: PropTypes.array,
  roles: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  setMembersPagination: PropTypes.func.isRequired,
  clearOrganizationMemberDetails: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  setMembersSort: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func.isRequired,
  resetExport: PropTypes.func.isRequired,
};

export default UsersView;
