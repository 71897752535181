import moment from "moment";
import {
  getBasicQueryString,
  getEntityDateRangeQueryString,
} from "../../components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  SelectFilterButton,
} from "../../components/search-bar/FilterButtons";
import { equalsFilter } from "../../components/search-bar/filters";

export const FILTERS = [
  {
    queryKey: "rackLocation",
    label: (t) => t("mt-search:Current Location"),
    optionsGetter: (props) => props.locationsFilterOptions,
    queryBuilder: getBasicQueryString,
    applyFilter: equalsFilter,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "cspcId",
    label: (t) => t("mt-search:CSPC"),
    optionsGetter: (props) => props.cspcsFilterOptions,
    queryBuilder: getBasicQueryString,
    applyFilter: equalsFilter,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "description",
    label: (t) => t("mt-search:Rack Type"),
    optionsGetter: (props) => props.descriptionsFilterOptions,
    queryBuilder: getBasicQueryString,
    applyFilter: equalsFilter,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "useByDate",
    label: (t) => t("mt-search:Use By Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString, // TODO
    applyFilter: (item, key, filterValue) => {
      // Filter by dates from/to. Notice that one or another may not be present
      const from = filterValue["from"] ? moment(filterValue["from"]) : null;
      const to = filterValue["to"] ? moment(filterValue["to"]) : null;
      const date = moment(item[key]);
      if (from && from.isAfter(date)) {
        return false;
      }
      if (to && to.isBefore(date)) {
        return false;
      }
      return true;
    },
  },
  {
    queryKey: "maxQuantity",
    label: (t) => t("mt-search:Max Quantity"),
    optionsGetter: (props) => props.maxQuantitiesFilterOptions,
    queryBuilder: getBasicQueryString,
    Component: SelectFilterButton,
    applyFilter: (item, key, filterValue) => {
      return parseInt(item[key], 10) <= parseInt(filterValue, 10);
    },
    hideSelectEmpty: true,
  },
  {
    queryKey: "warning",
    label: (t) => t("mt-search:Warnings"),
    optionsGetter: (props) => [
      { label: "< 24 hours", value: "<24 hours" },
      { label: "< 7 days", value: "<7 days" },
      { label: "< 7-14 days", value: "<7-14 days" },
      { label: "15+ days", value: "15+ days" },
    ],
    queryBuilder: getBasicQueryString,
    applyFilter: equalsFilter,
    Component: SelectFilterButton,
    hideSelectEmpty: true,
  },
];
