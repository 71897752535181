/** @jsxImportSource @emotion/react */
import Select from "react-select";
import { PhoneNumberInput } from "components/molecules/PhoneInput.molecule";
import { TextInput } from "components/atoms/TextInput.atom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const RecipientInput = (props) => {
  const { recipient, setRecipient } = props;
  const { t } = useTranslation("notification-management");

  const translateRecipientType = (type) => {
    if (type === "Email") {
      return t("notification-management:Email");
    } else if (type === "SMS") {
      return t("notification-management:SMS");
    }
  };

  return (
    <div css={{ display: "flex" }}>
      <Select
        css={{ width: "25%" }}
        value={{
          value: recipient.type,
          label: translateRecipientType(recipient.type),
        }}
        placeholder={t("notification-management:Type")}
        onChange={(newValue) => {
          setRecipient({
            ...recipient,
            value: recipient.type === newValue.value ? recipient.value : "",
            type: newValue.value,
          });
        }}
        data-qa="notification-management-select-recipient-type"
        isSearchable={false}
        options={[
          { value: "Email", label: t("notification-management:Email") },
          { value: "SMS", label: t("notification-management:SMS") },
        ]}
      ></Select>
      {recipient.type === "Email" ? (
        <TextInput
          css={{ width: "75%", marginLeft: "1em" }}
          placeholder={t("notification-management:Email")}
          value={recipient.value}
          onChange={(newValue) => {
            setRecipient({
              ...recipient,
              value: newValue,
            });
          }}
          data-qa="notification-management-input-profile-email"
        ></TextInput>
      ) : (
        <PhoneNumberInput
          css={{ width: "75%", marginLeft: "1em" }}
          defaultCountry={"US"}
          data-qa="notification-management-input-profile-phone-number"
          placeholder={t("notification-management:Phone Number")}
          value={recipient.value}
          onChange={(newValue) => {
            setRecipient({
              ...recipient,
              value: newValue ?? "",
            });
          }}
        ></PhoneNumberInput>
      )}
    </div>
  );
};

RecipientInput.propTypes = {
  recipient: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    unsubscribeId: PropTypes.string,
  }),
  setRecipient: PropTypes.func,
};
