/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import DwellTimeCell from "components/organisms/base-table/Cell/DwellTimeCell";
import { useTranslation } from "react-i18next";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "components/atoms/Icon.atom";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";

const VinCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { id, productType, isShippable } = props.value;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {id}
      </Text>
      <Text block size={FontSize.size14}>
        {productType}
      </Text>
      <Text
        bold
        block
        size={FontSize.size14}
        color={isShippable ? Colors.highlight.GREEN : Colors.highlight.RED}
      >
        {isShippable
          ? t("inventory-view:Shippable")
          : t("inventory-view:Non-Shippable")}
      </Text>
    </div>
  );
};

const CurrentLocationCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { locationData, geofenceName, id, setVinDetails, lastPosition } =
    props.value;
  const onClick = (e) => {
    e.stopPropagation();
    setVinDetails({ id, lastPosition });
  };
  return (
    <div>
      {!_.isEmpty(locationData) && (locationData.name || locationData.code) ? (
        <div>
          <div>
            {t("inventory-view:At Location")}
            <div css={{ display: "inline-block", paddingLeft: "10px" }}>
              <Icon
                src={faMapMarkedAlt}
                style={{ color: Colors.highlight.LIGHT_BLUE }}
                onClick={onClick}
              />
            </div>
          </div>
          <div>
            {locationData.name} {locationData.code ? " - " : ""}
            {locationData.code}
          </div>
          <div> {geofenceName}</div>
        </div>
      ) : null}
    </div>
  );
};

const PickUpCell = (props) => {
  const { dateRange, carrierName } = props.value;
  const { t } = useTranslation("inventory-view");

  if (!dateRange) {
    return null;
  }

  return (
    <div>
      <span>
        {dateRange.start && (
          <span>
            {dateRange.start.date} {dateRange.start.time}
          </span>
        )}
        {dateRange.start && dateRange.end && (
          <Text
            style={{
              fontSize: "1em",
              whiteSpace: "pre",
              margin: "0 0.1em",
            }}
          >
            {" "}
            {t("inventory-view:to")}{" "}
          </Text>
        )}
        {dateRange.end && (
          <span>
            {dateRange.end.date} {dateRange.end.time}
          </span>
        )}
      </span>

      {carrierName ? (
        <div css={{ marginTop: "8px" }}>
          <div>{t("inventory-view:Assigned Carrier")}: </div>
          <div>{carrierName}</div>
        </div>
      ) : null}
    </div>
  );
};

const DestinationCell = (props) => {
  const { destinationDealer } = props.value;
  return (
    <div>
      <Text size={FontSize.size14} bold block>{`${
        destinationDealer.name ?? ""
      } ${destinationDealer.code ? "- " + destinationDealer.code : ""}`}</Text>
      <LocationAddressComplex
        address1={destinationDealer.address}
        city={destinationDealer.city}
        state={destinationDealer.state}
        country={destinationDealer.country}
        defaultTextColor={Colors.text.DARK_GRAY}
        addressColor={Colors.text.DARK_GRAY}
      />
    </div>
  );
};

const DwellTimerCell = (props) => {
  const { dwellTimer, currentAge } = props.value;
  const { t } = useTranslation("inventory-view");
  const { dwellTimeSeconds } = dwellTimer;
  const isWarning = currentAge && currentAge > 0 ? true : false;
  const textColor = dwellTimeSeconds
    ? isWarning
      ? Colors.holds.RED
      : Colors.holds.LIGHT_GREEN
    : Colors.text.LIGHT_GRAY;
  return (
    <div>
      <Text block size={FontSize.size16} color={textColor}>
        {dwellTimeSeconds
          ? isWarning
            ? t("inventory-view:Aging")
            : t("inventory-view:Dwelling")
          : null}
      </Text>
      <DwellTimeCell currentDwell={dwellTimer} currentAge={currentAge} />
    </div>
  );
};

export const searchResultsColumns = (
  t,
  setVinDetails,
  setOnsiteVinsWatchEntity,
  solutionId,
) => {
  const columns = [
    {
      Header: t("inventory-view:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const vin = cellInfo.row.original.id;
        const internal_id = cellInfo.row.original.internal_id;

        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={vin}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              setOnsiteVinsWatchEntity(
                solutionId,
                vin,
                internal_id,
                newWatchValue,
              );
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("inventory-view:VIN"),
      accessor: (obj) => {
        return {
          id: obj?.id ?? "",
          productType: obj?.productType,
          isShippable: obj?.shippable ?? false,
        };
      },
      width: 200,
      Cell: VinCell,
    },
    {
      Header: t("inventory-view:Current Location Geofence"),
      accessor: (obj) => {
        return {
          locationData: obj?.locationData?.current ?? {},
          geofenceName: obj?.geofenceName ?? "",
          id: obj?.id ?? "",
          lastPosition: obj?.lastPositionUpdate ?? null,
          setVinDetails,
        };
      },
      width: 150,
      Cell: CurrentLocationCell,
    },
    {
      Header: t("inventory-view:Forecasted Pickup"),
      accessor: (obj) => {
        return {
          dateRange: obj?.availableForPickup ?? [],
          carrierName: obj?.carrierName ?? "",
        };
      },
      width: 200,
      Cell: PickUpCell,
    },
    {
      Header: t("inventory-view:Destination Dealer"),
      accessor: (obj) => {
        return {
          destinationDealer: obj?.destinationDealer ?? {},
        };
      },
      width: 200,
      Cell: DestinationCell,
    },
    {
      Header: t("inventory-view:Dwell Timer"),
      accessor: (obj) => {
        return {
          dwellTimer: obj?.dwellTimer ?? null,
          currentAge: obj?.currentAge ?? null,
        };
      },
      width: 200,
      Cell: DwellTimerCell,
    },
  ];

  return columns;
};
