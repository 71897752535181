import axios from "axios";
import apiUrl from "api-url";
import _ from "lodash";
import { mapReport } from "pages/reports/utils/report.utils";

// URLS
const REPORTS_URL = apiUrl("/powerbi/pb_token");
const REPORT_URL = apiUrl("/powerbi/report");

const FETCH_REPORTS = "reports/FETCH_REPORTS";
const RECEIVE_REPORTS = "reports/RECEIVE_REPORTS";
const FETCH_REPORT = "reports/FETCH_REPORT";
const RECEIVE_REPORT = "reports/RECEIVE_REPORT";
const REPORT_SAVING = "reports/REPORT_SAVING";
const REPORT_SAVED = "reports/REPORT_SAVED";
const REPORT_UPDATED = "reports/REPORT_UPDATED";
const REPORT_UPDATING = "reports/REPORT_UPDATING";
const REPORT_DELETING = "reports/REPORT_DELETING";
const REPORT_DELETED = "reports/REPORT_DELETED";

const STORE_MOUNT_POINT = "reports";

function fetchReports() {
  return (dispatch) => {
    dispatch({ type: FETCH_REPORTS });
    return axios
      .get(`${REPORTS_URL}`)
      .then((responses) => {
        dispatch({ type: RECEIVE_REPORTS, data: responses.data });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchReport(reportId) {
  return (dispatch) => {
    dispatch({ type: FETCH_REPORT });
    return axios
      .get(`${REPORTS_URL}/${reportId}`)
      .then((responses) => {
        dispatch({ type: RECEIVE_REPORT, data: responses.data });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchFilteredReport(reportId, filterSetId) {
  return (dispatch) => {
    dispatch({ type: FETCH_REPORT });
    return axios
      .get(`${REPORTS_URL}/${reportId}/${filterSetId}`)
      .then((responses) => {
        dispatch({ type: RECEIVE_REPORT, data: responses.data });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function saveReport(payload) {
  return (dispatch) => {
    dispatch({ type: REPORT_SAVING });
    return axios
      .post(`${REPORT_URL}`, payload)
      .then((responses) => {
        dispatch({ type: REPORT_SAVED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function saveFilteredReport(reportId, payload) {
  return (dispatch) => {
    dispatch({ type: REPORT_SAVING });
    return axios
      .post(`${REPORT_URL}/${reportId}`, payload)
      .then((responses) => {
        dispatch({ type: REPORT_SAVED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function updateReport(reportId, payload) {
  return (dispatch) => {
    dispatch({ type: REPORT_UPDATING });

    return axios
      .patch(`${REPORT_URL}/${reportId}`, payload)
      .then((responses) => {
        dispatch({ type: REPORT_UPDATED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function updateFilteredReport(reportId, filterSetId, payload) {
  return (dispatch) => {
    dispatch({ type: REPORT_UPDATING });

    return axios
      .patch(`${REPORT_URL}/${reportId}/${filterSetId}`, payload)
      .then((responses) => {
        dispatch({ type: REPORT_UPDATED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function deleteReport(reportId) {
  return (dispatch) => {
    dispatch({ type: REPORT_DELETING });
    return axios
      .delete(`${REPORT_URL}/${reportId}`)
      .then((responses) => {
        dispatch({ type: REPORT_DELETED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function deleteFilteredReport(reportId, filterSetId) {
  return (dispatch) => {
    dispatch({ type: REPORT_DELETING });
    return axios
      .delete(`${REPORT_URL}/${reportId}/${filterSetId}`)
      .then((responses) => {
        dispatch({ type: REPORT_DELETED });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// initial state
const initialState = {
  reports: [],
  reportsSorting: {},
  report: null,
  isLoading: false,
  isSaving: false,
  isEditing: false,
  isDeleting: false,
};

// selectors
const getReports = (state) => state.reports.reports;
const getReportsSorting = (state) => state.reports.reportsSorting;
const getReport = (state) => state.reports.report;
const getIsLoading = (state) => state.reports.isLoading;
const getIsSaving = (state) => state.reports.isSaving;
const getIsEditing = (state) => state.reports.isEditing;
const getIsDeleting = (state) => state.reports.isDeleting;

const getSelectedReportId = (state) => {
  const reportId = _.get(state, "location.payload.reportId", null);
  return reportId;
};

const getSelectedFilterSetId = (state) => {
  const reportId = _.get(state, "location.payload.filterSetId", null);
  return reportId;
};

// reducer
function ReportsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case RECEIVE_REPORTS:
      const reports = action.data.map((report, i) => {
        return mapReport(report, i);
      });
      return {
        ...state,
        reports: reports,
        isLoading: false,
      };

    case FETCH_REPORT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case RECEIVE_REPORT:
      const report = action.data.map((report, i) => {
        return mapReport(report, i);
      });
      return {
        ...state,
        report: report && report.length > 0 ? report[0] : null,
        isLoading: false,
      };

    case REPORT_SAVING: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case REPORT_SAVED: {
      return {
        ...state,
        isSaving: false,
      };
    }

    case REPORT_UPDATING: {
      return {
        ...state,
        isEditing: true,
      };
    }

    case REPORT_UPDATED: {
      return {
        ...state,
        isEditing: false,
      };
    }

    case REPORT_DELETING: {
      return {
        ...state,
        isDeleting: true,
      };
    }

    case REPORT_DELETED: {
      return {
        ...state,
        isDeleting: false,
      };
    }

    default:
      return state;
  }
}

// interface
const ReportsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchReports,
    fetchReport,
    fetchFilteredReport,
    saveReport,
    saveFilteredReport,
    updateReport,
    updateFilteredReport,
    deleteReport,
    deleteFilteredReport,
  },
  selectors: {
    getIsLoading,
    getIsSaving,
    getIsEditing,
    getIsDeleting,
    getReports,
    getReportsSorting,
    getReport,
    getSelectedReportId,
    getSelectedFilterSetId,
  },
  reducer: ReportsReducer,
};
export default ReportsState;
