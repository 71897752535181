/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { faSearchPlus } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import Colors from "styles/colors";

export const SaveThisSearchButton = ({ onClick = () => {} }) => {
  const { t } = useTranslation("components");

  return (
    <Button
      variant="link"
      css={{
        color: Colors.text.GRAY,
        ":hover": {
          color: Colors.background.DARK_BLUE,
        },
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
      data-qa="button-save-this-search"
    >
      <Text italic underline>
        {t("components:Save this search")}
      </Text>
      <Icon src={faSearchPlus} style={{ marginLeft: "0.3em" }} />
    </Button>
  );
};

SaveThisSearchButton.propTypes = {
  onClick: PropTypes.func,
};
