import { DPUStatus } from "./enums.utils";

/**
 * This is the utility function to allow generating translated deny reasons
 * Deny reasons are used in deny reason dropdown list and
 * in the tooltip in the status column in the DPU search table
 */
export const translateDenyReason = (t, denyReason) => {
  if (denyReason) {
    switch (denyReason.toLowerCase()) {
      case "inoperable (meaning - battery, flat tire, not in condition to handoff)":
        return t(
          "driveaway-search:Inoperable (meaning - battery, flat tire, not in condition to handoff)",
        );
      case "damaged (meaning damage found - dings, dents, scratches - during the pod inspection, i.e., the dealer / dealer agent refuses during the pod inspection process)":
        return t(
          "driveaway-search:Damaged (meaning damage found - dings, dents, scratches - during the POD inspection, i.e., the dealer / dealer agent refuses during the POD inspection process)",
        );
      case "inoperable":
        return t("driveaway-search:Inoperable");
      case "damaged":
        return t("driveaway-search:Damaged");
      case "not onsite/not found":
        return t("driveaway-search:Not onsite/Not found");
      case "dealer/dealer agent missed window, no show":
        return t("driveaway-search:Dealer/dealer agent missed window, no show");
      case "built into preferred carrier load within next 48 hours":
        return t(
          "driveaway-search:Built into preferred carrier load within next 48 hours",
        );
      case "other":
        return t("driveaway-search:Other");
      default:
        return denyReason;
    }
  }
};

/**
 * This is the utility function to allow generating
 * translated deny reasons explanation
 */
export const translateReasonExplanation = (t, reasonExplanation) => {
  if (reasonExplanation) {
    switch (reasonExplanation.toLowerCase()) {
      case "meaning - battery, flat tire, not in condition to handoff":
        return t(
          "driveaway-search:meaning - battery, flat tire, not in condition to handoff",
        );
      case "meaning damage found - dings, dents, scratches - during the pod inspection, i.e., the dealer / dealer agent refuses during the pod inspection process":
        return t(
          "driveaway-search:meaning damage found - dings, dents, scratches - during the POD inspection, i.e., the dealer / dealer agent refuses during the POD inspection process",
        );
      default:
        return reasonExplanation;
    }
  }
};

/**
 * This is the utility function to transform deny codes to
 * a translated, readable format.
 * It is used in the status column in the DPU search table
 */
export const denyCodeToReadableFormat = (t, denyCode) => {
  if (denyCode) {
    switch (denyCode) {
      case "NO_SHOW":
        return t("driveaway-search:No Show");
      case "DAMAGED":
        return t("driveaway-search:Damaged");
      case "CARRIER_ASSIGNED":
        return t("driveaway-search:Carrier Assigned");
      case "INOPERABLE":
        return t("driveaway-search:Inoperable");
      case "NOT_FOUND":
        return t("driveaway-search:Not Found");
      case "OTHER":
        return t("driveaway-search:Other");
      case "VIN_SHIPPED":
        return t("driveaway-search:VIN Shipped");
      default:
        return denyCode;
    }
  }
};

export const translateStatusLabel = (t, label) => {
  switch (label) {
    case DPUStatus.APPROVED:
      return t("driveaway-search:Approved");
    case DPUStatus.AVAILABLE:
      return t("driveaway-search:Available");
    case DPUStatus.DELIVERED:
      return t("driveaway-search:Delivered");
    case DPUStatus.DENIED:
      return t("driveaway-search:Denied");
    case DPUStatus.SUBMITTED:
      return t("driveaway-search:Submitted");
    default:
      return label;
  }
};

export const isNumberOnly = (input) => {
  const re = new RegExp(/^[0-9]*$/g);
  return input.length === 0 || re.test(input);
};

/**
 * @description Formats a phone number into the format +X (XXX) XXX-XXXX if the phone number can't be formatted return empty string
 * @param {string} phoneNumberString If a phone number such as 5555555555 is fed to this function it will return (555) 555-5555
 * if a country code is provided such as +15555555555 this function will return +1(555) 555-5555
 * @returns {string} formatted string such as (555) 555-5555, +1(555) 555-5555 or ""
 */
export const formatIntlPhoneNum = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};
