/** @jsxImportSource @emotion/react */
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Translation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import _ from "lodash";
import { MdClose } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { FlexRowDiv, FlexColDiv, FlexDiv } from "styles/container-elements";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import StandardInput from "components-old/forms/inputs/StandardInput";
import SelectInput from "components-old/forms/inputs/SelectInput";
import ShipperLocationSelect from "./ShipperLocationSelect";
import { isRequired } from "../MilestoneFormFieldDefs";
import { isDisplayed, getMilestoneEventValue } from "../util/field-helper";
import {
  DependencyType,
  EventTypeOptions,
  FieldKey,
  FieldType,
} from "../const";

import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";

const getFormElems = (
  data,
  milestoneEvent,
  formFields,
  partnerType,
  selectedShipper,
  timezone,
  updateField,
  csvFields,
  eventType,
) => {
  if (!formFields) {
    return null;
  }

  let elems = [];
  const { code, vmacsCode } = getMilestoneEventValue(milestoneEvent.value);

  formFields.forEach((item) => {
    let requiredAsterisk =
      (item.key !== FieldKey.VEHICLE_RECEIVED ||
        item.key !== FieldKey.PLANT_MILESTONE_UPLOAD ||
        item.key !== FieldKey.CSV_UPLOAD) &&
      isRequired(item, {
        partnerType,
        milestoneEvent,
      }) === true ? (
        <sup>*</sup>
      ) : null;

    if (item.type === FieldType.INPUT) {
      const valueFieldName = item?.valueFieldName
        ? item?.valueFieldName
        : "value";

      let inputVal =
        item.dependency && data[item.dependency]
          ? data[item.dependency]["value"]
          : data[item.key];

      if (item.key === FieldKey.COMPOUND_CODE) {
        inputVal = data[item.dependency]
          ? data[item.dependency][valueFieldName] ??
            data[item.dependency][valueFieldName]
          : "";
      }

      if (
        item.key === FieldKey.VMACS_CODE ||
        item.key === FieldKey.MILESTONE_STATUS_CODE
      ) {
        inputVal = valueFieldName === "code" ? code : vmacsCode;
      }

      elems.push(
        <FlexDiv
          key={item.key}
          css={{
            width: item.widthOverride ?? "31%",
            marginRight: "1em",
          }}
        >
          <StandardInput
            label={
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            }
            value={inputVal ?? ""}
            onChange={
              !_.isNil(item.editable) && item.editable === false
                ? () => null
                : (value) => updateField(item.key, value)
            }
            isDisabled={!_.isNil(item.editable) ? !item.editable : false}
          />
        </FlexDiv>,
      );
    }

    if (item.type === FieldType.SELECT) {
      if (item.dependency) {
        const dependentVal =
          item.dependency === DependencyType.PARTNER_TYPE
            ? partnerType
            : data[item.dependency].value;

        if (dependentVal && dependentVal !== "" && item.options[dependentVal]) {
          elems.push(
            <FlexDiv
              key={item.key}
              css={{
                width: item.widthOverride ?? "31%",
                marginRight: "1em",
              }}
            >
              <SelectInput
                label={
                  <span>
                    {item.label}
                    {requiredAsterisk}
                  </span>
                }
                options={_.sortBy(item.options[dependentVal], "label")}
                value={data[item.key]?.value || ""}
                onChange={(value) => {
                  let selected = item.options[dependentVal].find(
                    (item) => item.value === value,
                  );
                  updateField(item.key, selected);
                }}
              />
            </FlexDiv>,
          );
        }
      } else {
        elems.push(
          <FlexDiv
            key={item.key}
            css={{
              width: item.widthOverride ?? "31%",
              marginRight: "1em",
            }}
          >
            <SelectInput
              label={
                <span>
                  {item.label}
                  {requiredAsterisk}
                </span>
              }
              options={_.sortBy(item.options, "label")}
              value={data[item.key]?.value || ""}
              onChange={(value) => {
                let selected = item.options.find(
                  (item) => item.value === value,
                );
                updateField(item.key, selected);
              }}
            />
          </FlexDiv>,
        );
      }
    }

    if (item.type === FieldType.DATETIME) {
      /* Min = now rounded to next half hour */
      const now = moment();
      const remainder = 30 - (now.minute() % 30);
      let min = now.clone().add(remainder, "minutes");
      const dateFormat = getDateFormatFromUserPreferences();
      const timeFormat = getTimeFormatFromUserPreferences();
      const dateTimeFormat = dateFormat + " " + timeFormat;

      /* Max is min + 60 days */
      let max = min.clone().add(60, "days");

      elems.push(
        <FlexDiv
          key={item.key}
          css={{
            width: item.widthOverride ?? "31%",
            marginRight: "1em",
          }}
        >
          <FlexColDiv css={{ padding: "0 .5em", width: "100%" }}>
            <FlexDiv
              css={{
                color: "rgb(39, 48, 66)",
                marginBottom: ".25em",
              }}
              className="timeLabel"
            >
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FlexDiv>
            <DateTimePicker
              css={{ width: "100%" }}
              defaultValue={null}
              value={data[item.key]}
              onChange={(value) => updateField(item.key, value)}
              format={dateTimeFormat}
              max={max.toDate()}
            />
            {timezone ? (
              <FlexDiv
                css={{
                  flex: 1,
                  height: "3em",
                  fontSize: "9pt",
                  marginTop: ".5em",
                }}
              >
                {`${timezone} (GMT${moment.tz(timezone).format("Z z")})`}
              </FlexDiv>
            ) : null}
          </FlexColDiv>
        </FlexDiv>,
      );
    }

    if (item.type === FieldType.TEXTAREA) {
      elems.push(
        <FlexDiv key={item.key} css={{ width: "95%" }}>
          <FlexColDiv css={{ flex: 1, paddingLeft: ".5em" }}>
            <FormGroup css={{ marginBottom: "0.3em", marginTop: "1rem" }}>
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FormGroup>
            <InputGroup css={{ width: "100%" }}>
              <FormControl
                as="textarea"
                value={data[item.key] ?? ""}
                onChange={(value) => updateField(item.key, value.target.value)}
                css={{ resize: "none" }}
              />
            </InputGroup>
          </FlexColDiv>
        </FlexDiv>,
      );
    }

    if (item.type === FieldType.SHIPPER_LOCATION_SELECT) {
      elems.push(
        <FlexDiv
          key={item.key}
          css={{
            width: item.widthOverride ?? "31%",
            marginRight: "1em",
          }}
        >
          <FlexColDiv css={{ flex: 1, paddingLeft: ".5em" }}>
            <FormGroup css={{ marginBottom: ".3em" }}>
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FormGroup>
            <InputGroup css={{ width: "100%" }}>
              <ShipperLocationSelect
                onChange={(data) => updateField(item.key, data)}
                value={data[item.key] ?? ""}
                selectedShipper={selectedShipper}
              />
            </InputGroup>
          </FlexColDiv>
        </FlexDiv>,
      );
    }

    if (item.type === FieldType.UPLOAD) {
      const currentFileName = data[item.key]?.name ?? "";
      let isDisabled = false;
      let csvSampleData = [];

      const csvHeaders = csvFields
        ? csvFields
            .filter((item) =>
              isDisplayed({ item, partnerType, milestoneEvent }),
            )
            .filter((item) => item.csvHeader)
            .map((item) => {
              const key = item.csvHeader;
              const fieldKey = item.key;
              const label =
                eventType === EventTypeOptions.MULTIPLE
                  ? item.csvHeader
                  : isRequired(item, { partnerType, milestoneEvent })
                  ? `${item.csvHeader}*`
                  : item.csvHeader;
              const sampleCsvData = item?.sampleCsvData ?? [];
              return { key, fieldKey, label, sampleCsvData };
            })
        : [];

      // populate CSV sample data
      csvHeaders.forEach((item) => {
        let csvRow = {};
        const sampleCsvData = item.sampleCsvData;

        sampleCsvData.forEach((csvSample, index) => {
          if (item.fieldKey === FieldKey.VMACS_CODE) {
            csvRow[item.key] = vmacsCode;
          } else if (item.fieldKey === FieldKey.MILESTONE_STATUS_CODE) {
            csvRow[item.key] = code;
          } else {
            csvRow[item.key] = csvSample;
          }

          csvSampleData[index] = { ...csvSampleData[index], ...csvRow };
        });
      });

      elems.push(
        <FlexDiv key={item.key} css={{ width: "70%", marginLeft: "0.5em" }}>
          <Translation ns="create-milestone">
            {(t) => (
              <FlexColDiv css={{ flex: 1 }}>
                <FormGroup css={{ marginBottom: ".3em" }}>
                  <span>
                    {item.label}
                    {requiredAsterisk}
                  </span>
                </FormGroup>

                <InputGroup>
                  <label
                    className="input-group-btn"
                    css={{ paddingRight: "0.375rem" }}
                  >
                    <span
                      className={classNames("btn btn-primary")}
                      css={{ cursor: "pointer" }}
                    >
                      {`${t("create-milestone:Browse")}`}
                      <input
                        id="files"
                        key={currentFileName}
                        type="file"
                        accept=".csv"
                        disabled={isDisabled}
                        title={null}
                        onChange={(e) => {
                          updateField(item.key, e.target.files[0]);
                        }}
                        data-qa="input-file-batch-modal"
                        hidden
                      />
                    </span>
                  </label>

                  <FormControl
                    type="type"
                    value={currentFileName}
                    placeholder={item.uploadInputPlaceholder}
                    htmlFor="files"
                    disabled={isDisabled}
                    css={{ zIndex: 0 }}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      if (
                        e.dataTransfer.items &&
                        e.dataTransfer.items.length === 1
                      ) {
                        updateField(
                          item.key,
                          e.dataTransfer.items[0].getAsFile(),
                        );
                      }
                    }}
                    readOnly
                  />
                  {data[item.key] && (
                    <span className="input-group-btn">
                      <Button
                        variant="light"
                        onClick={() => {
                          updateField(item.key, null);
                        }}
                        data-qa="button-remove-file-batch-modal"
                        css={{ marginLeft: "0.25rem" }}
                      >
                        <MdClose />
                      </Button>
                    </span>
                  )}
                </InputGroup>

                {!isDisabled ? (
                  <FormGroup
                    css={{
                      color: Colors.text.LIGHT_GRAY,
                      marginBottom: "1rem",
                      paddingLeft: 75,
                    }}
                  >
                    <div>
                      ({t("create-milestone:Expected columns")}:{" "}
                      {item.csvExpectedColumnsOverride
                        ? item.csvExpectedColumnsOverride
                        : csvHeaders.map((header) => header.label).join(", ")}
                      )
                    </div>
                    {item.csvTemplateOverride ? (
                      <Button
                        variant="link"
                        css={{ paddingLeft: 0 }}
                        href={item.csvTemplateOverride}
                      >
                        {t("create-milestone:Download template (.csv)")}
                        <FontAwesomeIcon icon={faDownload} className="ms-2" />
                      </Button>
                    ) : csvSampleData?.length > 0 && csvHeaders?.length > 0 ? (
                      <DownloadCsvLink
                        data={csvSampleData}
                        headers={csvHeaders}
                        label={t("create-milestone:Download template (.csv)")}
                        filename={"template-milestone-data.csv"}
                        css={{ padding: "0.375rem 0", display: "block" }}
                      />
                    ) : null}
                    <div
                      css={{ borderTop: "1px solid #ced4da" }}
                      className="py-2"
                    >
                      <span css={{ fontWeight: "bold" }}>
                        {t("create-milestone:NOTE")}:
                      </span>{" "}
                      {t("create-milestone:Maximum Limit")}:{" "}
                      {t("create-milestone:5,000 rows per csv file.")}
                    </div>
                  </FormGroup>
                ) : null}
              </FlexColDiv>
            )}
          </Translation>
        </FlexDiv>,
      );
    }
  });

  return elems;
};

const getFormList = (data, formFields, updateField) => {
  if (!formFields) {
    return null;
  }

  const item = formFields.find((obj) => obj.type === FieldType.LIST);

  return item ? (
    <FlexDiv key={item.key} css={{ width: "95%" }}>
      <FlexColDiv css={{ flex: 1, paddingLeft: ".5em" }}>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <span>
            {item.label}
            {item.required === true ? <sup>*</sup> : null}
          </span>
        </FormGroup>
        <InputGroup css={{ width: "100%" }}>
          <FormControl
            as="textarea"
            value={data[item.key]}
            onChange={(value) => updateField(item.key, value.target.value)}
            css={{ resize: "none" }}
            rows={15}
          />
        </InputGroup>
      </FlexColDiv>
    </FlexDiv>
  ) : null;
};

const CreateMilestoneForm = ({
  data,
  milestoneEvent,
  isBatchUpload,
  formFields,
  partnerType,
  selectedShipper,
  timezone,
  updateField,
  csvFields,
  eventType,
}) => {
  const formElems = getFormElems(
    data,
    milestoneEvent,
    formFields,
    partnerType,
    selectedShipper,
    timezone,
    updateField,
    csvFields,
    eventType,
  );

  const listElem = getFormList(data, formFields, updateField);

  let showFullWidth = isBatchUpload;

  return (
    <FlexRowDiv css={{ padding: "1em" }}>
      <FlexDiv css={{ width: showFullWidth ? "0" : "25%" }}>{listElem}</FlexDiv>
      <FlexDiv
        css={{
          marginLeft: showFullWidth ? 0 : "1em",
          width: showFullWidth ? "100%" : "75%",
        }}
      >
        <FlexRowDiv
          css={{ flexWrap: "wrap", width: showFullWidth ? "100%" : "auto" }}
        >
          {formElems}
        </FlexRowDiv>
      </FlexDiv>
    </FlexRowDiv>
  );
};

const optionsArrayPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
);

const formFieldsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    dependency: PropTypes.string,
    options: PropTypes.oneOfType([
      optionsArrayPropType,
      PropTypes.objectOf(optionsArrayPropType),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        default: PropTypes.bool,
        dependency: PropTypes.string,
        [PropTypes.string]: PropTypes.string,
      }),
    ]),
    editable: PropTypes.bool,
  }),
);

CreateMilestoneForm.propTypes = {
  data: PropTypes.shape({
    // String will be the "empty" state for that part of data
    // while the object is the value when the user selects it.
    // TODO: Change the implementation of CreateMilestoneView
    //       to initialize values with a more appropriate default
    MILESTONE_EVENT: PropTypes.oneOfType([
      PropTypes.shape({ value: PropTypes.string }),
      PropTypes.string,
    ]),
  }),
  milestoneEvent: PropTypes.string,
  isBatchUpload: PropTypes.bool,
  formFields: formFieldsPropType,
  partnerType: PropTypes.string,
  selectedShipper: PropTypes.object,
  // Moment object
  timezone: PropTypes.string,
  updateField: PropTypes.func,
  csvFields: formFieldsPropType,
};

export default CreateMilestoneForm;
