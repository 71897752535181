/** @jsxImportSource @emotion/react */
import _ from "lodash";

import { FlexDiv } from "../../../styles/container-elements";
import colors from "../../../styles/colors";

const formattedStartCase = (str) => (str ? _.startCase(_.toLower(str)) : "");

const LocItem = ({ loc, ind, eventHandler }) => {
  return (
    <FlexDiv
      css={{
        alignItems: "center",
        backgroundColor:
          ind % 2 === 0
            ? colors.highlight.DARK_GRAY
            : colors.background.DARK_GRAY,
        color: "white",
        fontSize: "small",
        minHeight: "2.5em",
        padding: ".25em",
        ":hover": {
          backgroundColor: colors.background.DARK_TEAL,
          cursor: "pointer",
        },
      }}
      onClick={() => eventHandler(loc, "LOCATION_LINK")}
    >
      <FlexDiv
        css={{
          flex: 0.6,
          fontWeight: 600,
          marginLeft: ".5em",
          textDecoration: "underline",
        }}
      >
        {formattedStartCase(loc.name)}
      </FlexDiv>
      <FlexDiv
        css={{
          color: colors.text.LIGHT_GRAY,
          flex: 0.4,
          marginLeft: ".75em",
          marginRight: ".5em",
        }}
      >
        {`${formattedStartCase(loc.city)}, ${loc.state}`}
      </FlexDiv>
      <FlexDiv
        css={{
          color: colors.text.LIGHT_GRAY,
          flex: 0.4,
          marginLeft: ".75em",
          marginRight: ".5em",
        }}
      >
        {`${loc.code}`}
      </FlexDiv>
    </FlexDiv>
  );
};

export default LocItem;
