/** @jsxImportSource @emotion/react */

export const convertLineBreaksToHtml = (text) => {
  if (!text || !text.includes("\n")) {
    return text;
  }

  return text.split("\n").map((line, key) => {
    return <div key={key}>{line}</div>;
  });
};

/**
 * Number Formatting with Thousands Separators
 * @param - Count is a number
 * @returns Locale string(en-US) version of count - refer test cases
 */
export const convertCountToLocaleString = (count) =>
  count.toLocaleString("en-US");
