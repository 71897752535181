/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import _ from "lodash";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useSetTitleOnMount,
  useSetSubTitleOnMount,
} from "components/hooks/useSetTitle";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import { getDefaultUnclassifiedLad } from "modules/lads/LadsState";
import SimpleMap from "modules/map/components/SimpleMap";
import SearchBarContainer from "modules/location-resolution-edit/LocationMatchingViewSearchBarContainer";
import AvailableLocationsPanel from "./AvailableLocationsPanel";
import CurrentLocationPanel from "./CurrentLocationPanel";
import LocationMatchingControls from "./LocationMatchingControls";
import { buildLinkPayload } from "./LocationMatchingState";

const MapSection = styled.section({
  display: "flex",
  flex: 0.35,
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "white",
});

const PanelSection = styled.section({
  display: "flex",
  flex: 0.65,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 7.5em)",
  backgroundColor: "white",
  position: "relative",
});

export const LocationMatchingView = ({
  locationId,
  fetchUnresolvedLocationDetails,
  setReturnToPreviousScreen,
  locationLinking = false,
  resetSearchBar,
  clearSearchFilters,
  searchLocations,
  solutionId,
  locationSearchResults,
  unresolvedLocation,
  setSearchFilter,
  shipment,
  pushShipmentDetailView,
  pushLocationsScreen,
  returnToPreviousScreen,
  heatmapUid,
  heatmapData,
  fetchHeatMapData,
  fetchLocationDetails,
  linkLocation,
  isUnresolvedLocationLoading,
  lads,
  heatmapIsLoading,
  isLoading,
  page,
  pageSize,
  totalPages,
  setPagination,
  pushCreateLocationScreen,
  clearUnresolvedLocation,
}) => {
  const { t } = useTranslation(["location-matching"]);

  useSetTitleOnMount(
    !locationLinking
      ? t("location-matching:Resolve Location")
      : t("location-matching:Location Linking"),
  );

  useSetSubTitleOnMount(unresolvedLocation?.name);

  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [aggregatedHeatmap, setAggregatedHeatmap] = useState([]);
  const [lastHeatmapProcessed, setLastHeatmapProcessed] = useState(null);
  const [heatmapIdBeingFetched, setHeatmapIdBeingFetched] = useState(0);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [updateHeatmap, setUpdateHeatmap] = useState(true);
  const [deselected, setDeselected] = useState(false);

  const unresolvedLocationId = unresolvedLocation?.id ?? null;
  const shipmentId = shipment?.id ?? null;

  const handleHeatmapData = useCallback(() => {
    // If we have never received any heatmap data, there is nothing to do
    if (_.isNil(heatmapUid)) {
      return;
    }

    // If a heatmap was being fetched and the last heatmap processed ID
    // has changed, indicating the reducer has received heatmap data,
    // process it
    if (lastHeatmapProcessed !== heatmapUid && heatmapIdBeingFetched !== null) {
      // Add this heatmap to our list of aggregated data,
      // Update the last processed state value, clear
      // our flag, and set this to be processed
      setAggregatedHeatmap((prevState) => ({
        ...prevState.aggregatedHeatmap,
        [heatmapIdBeingFetched]: heatmapData,
      }));
      setLastHeatmapProcessed(heatmapUid);
      setHeatmapIdBeingFetched(null);
      setUpdateHeatmap(true);
    }
    // Updates heatmap on deselection
    else if (deselected) {
      setUpdateHeatmap(true);
      setDeselected(false);
    }
  }, [
    deselected,
    heatmapData,
    heatmapIdBeingFetched,
    heatmapUid,
    lastHeatmapProcessed,
  ]);

  const addLocationHeatMapData = (locationId) => {
    // This method invokes the fetch for the heatmap
    // data of a specific location.  Before doing the fetch
    // it needs to prep our state so we know to process the
    // heatmap data that is returned.  We store the
    // ID of the location we are fetching and set
    // the last heatmap processed to the current heatmap id
    // this ensures that when a new heatmap UID is generated
    // it will trigger us to process it
    //
    // NOTE: THIS ALL ASSUMES NO NEW HEATMAP REQUESTS COME
    // BEFORE WE GET A RESPONSE.
    // If the user clicks on multiple locations quickly, the
    // wires will get crossed.
    // If this becomes an issue we could further refactor
    // and store heatmaps per location ID in redux
    //
    // Setting updateHeatmap to false will allow for it be
    // redrawn when the component updates

    setHeatmapIdBeingFetched(locationId);
    setLastHeatmapProcessed(heatmapUid);
    setUpdateHeatmap(false);

    // Fetches heatmap data for location
    fetchHeatMapData(locationId);
  };

  const addLocationIdToSelected = (locationId) => {
    let newIds = selectedLocationIds.slice();
    newIds.push(locationId);

    // Add location to selected IDs
    setSelectedLocationIds(newIds);
    // Fetches selected location details
    fetchLocationDetails(locationId);
    // Add selected location heatmap data
    addLocationHeatMapData(locationId);

    return true;
  };

  const removeLocationIdFromSelected = (locationId) => {
    let newIds = selectedLocationIds.slice();
    newIds.splice(newIds.indexOf(locationId), 1);

    // Removes location heatmap from deselected location
    delete aggregatedHeatmap[locationId];

    // Updates state
    setAggregatedHeatmap(aggregatedHeatmap);
    setSelectedLocationIds(newIds);
    setUpdateHeatmap(false);
    setDeselected(true);

    return false;
  };

  useEffect(() => {
    if (locationId) {
      fetchUnresolvedLocationDetails(locationId);
    }

    // Clear the flag while redirecting to previous screen
    return () => {
      setReturnToPreviousScreen(false);
      resetSearchBar();
    };
  }, [
    locationId,
    setReturnToPreviousScreen,
    resetSearchBar,
    fetchUnresolvedLocationDetails,
  ]);

  useEffect(() => {
    if (unresolvedLocationId) {
      // Set the unresolved location ID filter and fetch search results
      clearSearchFilters();
      setSearchFilter("near_location", unresolvedLocationId);
      searchLocations(solutionId);

      // If the location we're looking at has a perfect match
      // in our location list, auto select it
      const selected = locationSearchResults.find(
        (l) => Number(l.id) === Number(unresolvedLocationId),
      );

      if (selected) {
        addLocationIdToSelected(selected.id);
      } else {
        addLocationHeatMapData(unresolvedLocationId);
      }
    }
    // eslint-disable-next-line
  }, [
    unresolvedLocationId,
    solutionId,
    clearSearchFilters,
    setSearchFilter,
    searchLocations,
  ]);

  useEffect(() => {
    // If the return to previous screen becomes active,
    // act upon it.  This is deferred so we can wait
    // for the response the backend on an location link
    // so we then do not return to a shipment details
    // view before the link has completed.
    // Note: If the user refreshes the page before going back, then
    // they will go back to the Location Management view instead,
    // since we do not track the "previous screen" via URL, only
    // via the state.
    if (returnToPreviousScreen) {
      if (shipmentId) {
        pushShipmentDetailView(shipmentId);
      } else {
        pushLocationsScreen();
      }
    }
  }, [
    shipmentId,
    returnToPreviousScreen,
    pushShipmentDetailView,
    pushLocationsScreen,
  ]);

  useEffect(() => {
    handleHeatmapData();
  }, [unresolvedLocationId, heatmapUid, handleHeatmapData]);

  /*
   * Matchs two locations
   */
  const assignLocationMatch = (selectedLocationId) => {
    const data = buildLinkPayload(
      unresolvedLocation,
      selectedLocationId,
      shipment,
    );

    linkLocation(data.id, data.payload);
  };

  const toggleSelectLocation = (locationId) => {
    // Check if we need to remove or add the selected location.
    const foundLocation = selectedLocationIds.find((id) => id === locationId);

    return foundLocation
      ? removeLocationIdFromSelected(locationId)
      : addLocationIdToSelected(locationId);
  };

  /*
   * Handles events of selecting and deselecting locations
   */
  const eventHandler = (val, type) => {
    switch (type) {
      case "SELECT_LOCATION":
      case "DISMISS_LOCATION":
        return toggleSelectLocation(val);
      default:
        break;
    }
  };

  const getSelectedLocations = () => {
    return locationSearchResults.filter((loc) =>
      selectedLocationIds.includes(loc.id),
    );
  };

  const selectedLocations = getSelectedLocations();

  if (_.isEmpty(unresolvedLocation)) {
    return null;
  }

  let selectedLad = _.get(unresolvedLocation, "lad");
  if (selectedLad === undefined) {
    selectedLad = lads.find((l) => l.default_name === "Unclassified");

    // If selected LAD is still undefined populate it with place holder data
    if (selectedLad === undefined) {
      selectedLad = getDefaultUnclassifiedLad();
    }
  }

  if (selectedLad && selectedLad.id && lads && selectedLad.name) {
    const ladType = lads.find((l) => Number(l.id) === Number(selectedLad.id));

    if (ladType) {
      selectedLad = { ...selectedLad, default_name: ladType.default_name };
    }
  }

  const mapLocations = selectedLocations.slice();
  mapLocations.push(unresolvedLocation);

  // Transform heatmap data object into array of heatmap points
  let heatmapCoords = Object.values(aggregatedHeatmap)
    .flat()
    .filter(function (el) {
      return el !== null;
    });

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ display: "flex", flexDirection: "row" }}>
        <MapSection>
          {!isUnresolvedLocationLoading && (
            <SimpleMap
              showHeatmap={showHeatmap && updateHeatmap}
              heatmapCoords={heatmapCoords}
              selectedLocation={unresolvedLocation}
              mapLocations={mapLocations}
              lads={lads}
              selectedLad={selectedLad}
              drawAllGeofences
              useBoxChiclets
            />
          )}
        </MapSection>
        {!isUnresolvedLocationLoading && (
          <PanelSection>
            {heatmapCoords?.length > 0 ? (
              <HeatMapButton
                isToggled={showHeatmap}
                onToggle={() => setShowHeatmap(!showHeatmap)}
                isLoading={heatmapIsLoading}
                css={{
                  position: "absolute",
                  top: 5,
                  marginLeft: -40,
                }}
              />
            ) : null}
            <CurrentLocationPanel
              currentLocation={unresolvedLocation}
              selectedLocations={selectedLocations}
              lads={lads}
              eventHandler={eventHandler}
            />
            <div css={{ padding: "0 1rem 0.25rem" }}>
              <SearchBarContainer isShowingAdvancedSearchButton={false} />
            </div>
            <AvailableLocationsPanel
              isLoading={isLoading}
              locations={locationSearchResults}
              lads={lads}
              selectedLocations={selectedLocations}
              eventHandler={eventHandler}
              page={page}
              totalPages={totalPages}
              pageSize={pageSize}
              setPagination={setPagination}
              locationId={locationId}
            />
          </PanelSection>
        )}
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {!isUnresolvedLocationLoading && (
          <LocationMatchingControls
            locationLinking={locationLinking}
            onCreateLocationClick={() => {
              pushCreateLocationScreen(unresolvedLocation);
            }}
            enableUpdate={selectedLocations.length === 1}
            onUpdateClick={() => {
              assignLocationMatch(selectedLocations[0].id);
            }}
            onCancelClick={() => {
              clearUnresolvedLocation();
              setReturnToPreviousScreen(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

LocationMatchingView.propTypes = {
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resetSearchBar: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  clearUnresolvedLocation: PropTypes.func.isRequired,
  fetchHeatMapData: PropTypes.func.isRequired,
  fetchLocationDetails: PropTypes.func.isRequired,
  fetchUnresolvedLocationDetails: PropTypes.func.isRequired,
  heatmapData: PropTypes.any,
  heatmapIsLoading: PropTypes.any,
  heatmapUid: PropTypes.any,
  isUnresolvedLocationLoading: PropTypes.any,
  lads: PropTypes.any,
  linkLocation: PropTypes.any,
  locationSearchResults: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pushCreateLocationScreen: PropTypes.any,
  pushLocationsScreen: PropTypes.any,
  pushShipmentDetailView: PropTypes.any,
  returnToPreviousScreen: PropTypes.any,
  searchLocations: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  setReturnToPreviousScreen: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  shipment: PropTypes.any,
  solutionId: PropTypes.any,
  totalPages: PropTypes.number,
  unresolvedLocation: PropTypes.any,
  locationLinking: PropTypes.bool,
};
