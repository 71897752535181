/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import PhoneInputWithCountrySelect, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { TextInput } from "components/atoms/TextInput.atom";
import "react-phone-number-input/style.css";

// Export validation utilities here.
// It is likely we will only use these when using PhoneInput.
export { isPossiblePhoneNumber, isValidPhoneNumber };

export const PhoneNumberInput = ({
  value = "",
  onChange = null,
  disabled = false,
  placeholder = "",
  style = {},
  withCountrySelect = true,
  defaultCountry,
  "data-qa": dataQa = "",
  ...restProps
}) => {
  if (withCountrySelect) {
    return (
      <PhoneInputWithCountrySelect
        international={true}
        countryCallingCodeEditable={false}
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={style}
        data-qa={dataQa}
        inputComponent={TextInput}
        countrySelectProps={{
          style: { opacity: 0 },
        }}
        {...restProps}
      />
    );
  }

  return (
    <PhoneInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      style={style}
      data-qa={dataQa}
      inputComponent={TextInput}
      {...restProps}
    />
  );
};

PhoneNumberInput.propTypes = {
  /**
   * The value of the phone number input.
   */
  value: PropTypes.string,
  /**
   * A handler for the onChange event.
   */
  onChange: PropTypes.func,
  /**
   * Disables input, preventing it from being clicked
   */
  disabled: PropTypes.bool,
  /**
   * placeholder for the phone number input
   */
  placeholder: PropTypes.string,
  /**
   * An object that will be used as the element styles for the PhoneInput.
   */
  style: PropTypes.object,
  /**
   * Flag to enable the country select prepended to the input.
   * Default: true.
   */
  withCountrySelect: PropTypes.bool,
  /**
   * Set the initially selected country.
   */
  defaultCountry: PropTypes.string,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
};
