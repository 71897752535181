/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useTranslation } from "react-i18next";
import { useWatchedSubmissionColumns } from "./DamageView.Dashboard.columns";

export const WatchedSubmissionsWidget = ({
  fetchWatchedSubmissionsData,
  fetchMySubmissionsData,
  solutionId,
  isLoading,
  watchedSubmissionsData,
  watchedSubmissionsPageIndex,
  watchedSubmissionsPageSize,
  watchedSubmissionsPageCount,
  setWatchedSubmissionsPageIndex,
  exportSubmissonData,
  userPreferences,
  pushDamageViewDetailsPage,
}) => {
  const { t } = useTranslation("damage-view");
  const [unwatchedSubmissions, setUnwatchedSubmissions] = useState([]);

  useEffect(() => {
    fetchWatchedSubmissionsData(solutionId);
  }, []);

  let unwatchTimers = {};

  const toggleWatch = (submission) => {
    let newUnwatchedSubmissions = [...unwatchedSubmissions];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[submission]) {
      clearTimeout(unwatchTimers[submission]);
    }

    if (newUnwatchedSubmissions.includes(submission)) {
      // submissions checkbox has already been unchecked - re-watch it
      newUnwatchedSubmissions = newUnwatchedSubmissions.filter(
        (v) => v !== submission,
      );
    } else {
      // submissions checkbox is checked - unwatch it
      newUnwatchedSubmissions.push(submission);
    }

    setUnwatchedSubmissions(newUnwatchedSubmissions);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[submission] = setTimeout(() => {
      fetchWatchedSubmissionsData(solutionId);
      fetchMySubmissionsData(solutionId);
      setUnwatchedSubmissions([]);
    }, 2000);
  };

  return (
    <div>
      <PanelGroup>
        <PanelGroup.Header title={t("damage-view:Watched Submissions")} />
        <PanelGroup.Content style={{ padding: 0 }}>
          <BaseTable
            data={watchedSubmissionsData}
            columns={useWatchedSubmissionColumns(
              solutionId,
              exportSubmissonData,
              userPreferences,
              toggleWatch,
            )}
            theme={Themes.LIGHT}
            isLoading={isLoading}
            showPagination={true}
            showPageSizeDropdown={false}
            isManualPagination={true}
            pageIndex={watchedSubmissionsPageIndex}
            pageSize={watchedSubmissionsPageSize}
            pageCount={watchedSubmissionsPageCount}
            onPageChange={(newPage) => {
              setWatchedSubmissionsPageIndex(
                solutionId,
                newPage,
                watchedSubmissionsPageSize,
              );
            }}
            rowClickHandler={(row, cell) => {
              // Prevent redirects from clicking within watch column
              if (cell.column.id === "watch") {
                return;
              }

              if (!row.original.id) {
                return;
              }

              pushDamageViewDetailsPage(row.original.id);
            }}
            rowDisabledHandler={(row) => {
              const submissionId = row.original.id;
              return unwatchedSubmissions.includes(submissionId);
            }}
          />
        </PanelGroup.Content>
      </PanelGroup>
    </div>
  );
};

WatchedSubmissionsWidget.propTypes = {
  solutionId: PropTypes.string,
  isLoading: PropTypes.bool,
  watchedSubmissionsData: PropTypes.array,
  fetchWatchedSubmissionsData: PropTypes.func,
  fetchMySubmissionsData: PropTypes.func,
  watchedSubmissionsPageIndex: PropTypes.number,
  watchedSubmissionsPageSize: PropTypes.number,
  watchedSubmissionsPageCount: PropTypes.number,
  setWatchedSubmissionsPageIndex: PropTypes.func,
  exportSubmissonData: PropTypes.func,
  userPreferences: PropTypes.object,
  pushDamageViewDetailsPage: PropTypes.func,
};
