import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import OrganizationsSearchBarState from "./OrganizationsSearchBarState";
import { FILTERS } from "./OrganizationSearch.searchOptions";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  searchFilters: OrganizationsSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    OrganizationsSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  auth: getAuthorization(state),
});

const { setSearchFilter, clearSearchFilter, clearSavedSearch, searchEntities } =
  OrganizationsSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  clearSavedSearch,
  searchEntities,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
