import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("container-tracking:Search Everything"),
    placeholder: (t) =>
      t("container-tracking:Search Container ID, Container Type, Tag Id"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "id",
    label: (t) => t("container-tracking:Container ID"),
    placeholder: (t) => t("container-tracking:Search Container ID"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "type:contains",
    label: (t) => t("container-tracking:Container Type"),
    placeholder: (t) => t("container-tracking:Search Container Type"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "tag-id",
    label: (t) => t("container-tracking:Tag ID"),
    placeholder: (t) => t("container-tracking:Search Tag ID"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
];
