/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // We can format and log the exact error here.
    console.error(error);
  }

  render() {
    // If there is an error, render the fallback UI.
    if (this.state.hasError) {
      return this.props.fallback;
    }

    // If there isn't an error, render children as normal.
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  /**
   * The usual UI to render.
   *
   * This UI may have the possibility of throwing an error during render.
   */
  children: PropTypes.node.isRequired,
  /**
   * The fallback UI to render if there is an error in `children`.
   */
  fallback: PropTypes.node.isRequired,
};
