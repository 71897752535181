import _ from "lodash";
import moment from "moment";
import axios from "axios";
import qs from "qs";

import {
  SEARCH_CATEGORIES,
  SEARCH_FILTERS,
} from "../components/search/DamageView.searchOptions";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { searchVins, vinsReducer } from "./VinSearchStateExtensions";
import chainReducers from "vendor/signal-utils/chain-reducers.js";
import apiUrl from "api-url";

const STORE_MOUNT_POINT = "damageViewSearch";

const APPLICATION_BASE_URL = apiUrl("/damageview/");

const entitiesUrl = (queryString) => {
  const url = apiUrl(`/damageview/submission`);

  const payload = qs.parse(queryString);
  payload.searchVersion = "allSubmissions";

  return `${url}?${qs.stringify(payload)}`;
};

const watchUrl = (id) => APPLICATION_BASE_URL + `submission/${id}/watch/`;
// Actions
const FETCH_ALL_SUBMISSION_IDS = `${STORE_MOUNT_POINT}/FETCH_ALL_SUBMISSION_IDS`;
const RECEIVE_ALL_SUBMISSION_IDS = `${STORE_MOUNT_POINT}/RECEIVE_ALL_SUBMISSION_IDS`;

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  // Normal search GET
  const url = entitiesUrl(queryString);
  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      accept: "application/json",
    },
  };

  // Fetch the search
  dispatch(duck.fetch(url, config));

  // Redirect to damage view search page
  dispatch({ type: "DAMAGE_VIEW_SEARCH" });

  // fetch all submission ids
  dispatch(fetchAllSubmissionIds(20000, queryString));
};

const watchActions = (solutionId, id, watch = true) => {
  const url = watchUrl(id);
  return async (dispatch) => {
    try {
      watch ? await axios.post(url) : await axios.delete(url);
      dispatch(SearchBarState.actionCreators.searchEntities(solutionId));
    } catch (err) {
      console.log("Error in DamageViewSearchBarState: watchActions");
    }
  };
};

const fetchAllSubmissionIds = (totalCount = 20000, queryString) => {
  let qs =
    queryString
      ?.split("&")
      .filter((ele) => {
        return !ele.includes("pageNumber") && !ele.includes("pageSize");
      })
      .join("&") ?? "";

  let url = entitiesUrl() + `&pageSize=${totalCount}`;
  if (qs) {
    url += `&${qs}`;
  }

  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_SUBMISSION_IDS,
    });
    axios.get(url).then((response) => {
      const allSubmissionIds = response?.data?.data.map((obj) => {
        return obj?.id;
      });
      dispatch({
        type: RECEIVE_ALL_SUBMISSION_IDS,
        allSubmissionIds: allSubmissionIds,
      });
    });
  };
};

const saveBatchAssigned = (selectedAssignee, selectedIds) => {
  return axios.post(apiUrl("/damageview/submission/batch"), {
    assignee: selectedAssignee,
    submissions: selectedIds,
  });
};

// Selectors
const getAllSubmissionIds = (state) => {
  return state[STORE_MOUNT_POINT].allSubmissionIds ?? [];
};

const getAllSubmissionIdsIsLoading = (state) => {
  return state[STORE_MOUNT_POINT].allSubmissionIdsIsLoading ?? false;
};

// Additional selectors for async search results
const getVins = (state) => state[STORE_MOUNT_POINT].vins || [];
const getVinsLoading = (state) => state[STORE_MOUNT_POINT].vinsLoading || false;

// Reducer

const damageViewSearchBarReducer = (
  state = {
    allSubmissionIds: [],
    allSubmissionIdsIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_ALL_SUBMISSION_IDS:
      return {
        ...state,
        allSubmissionIdsIsLoading: true,
      };
    case RECEIVE_ALL_SUBMISSION_IDS:
      return {
        ...state,
        allSubmissionIds: action.allSubmissionIds,
        allSubmissionIdsIsLoading: false,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  SEARCH_FILTERS,
  fetchSearch,
  [damageViewSearchBarReducer],
  {
    defaultSort: "submission_date",
    reverseSort: false,
  },
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  searchVins,
  saveBatchAssigned,
  watchActions,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    (_ignored_solutionId, queryString) => entitiesUrl(queryString),
    null,
    { headers: { Accept: "text/csv;" } },
    "damage-view-search-results",
  ),
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getVins,
  getVinsLoading,
  getAllSubmissionIds,
  getAllSubmissionIdsIsLoading,
};

SearchBarState.reducer = chainReducers([SearchBarState.reducer, vinsReducer]);

export default SearchBarState;
