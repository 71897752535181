/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { isValidEmail } from "utils/validation-utils";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { isPossiblePhoneNumber } from "components/molecules/PhoneInput.molecule";
import { Text } from "components/atoms/Text.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { InteractiveList } from "../../molecules/InteractiveList.molecule";
import { RecipientInput } from "../../molecules/RecipientInput.molecule";

const assignAndMapUnsubscribeIdToDeliveryActions = (
  recipientList,
  unsubscribeIdMap,
) => {
  return recipientList.map((recipientList) => {
    return {
      ...recipientList,
      unsubscribeId: unsubscribeIdMap[recipientList.value] ?? uuidv4(),
    };
  });
};

export const GeneralInformationStep = (props) => {
  const {
    isEditing,
    setIsNextButtonDisabled,
    notificationRuleName,
    setDeliveryActions,
    deliveryActions,
    userDefaultCommunication,
    setNotificationRuleName,
    unsubscribeIdMap,
  } = props;

  const { email: userDefaultEmail, phone: userDefaultPhoneNumber } = {
    ...userDefaultCommunication,
  };

  const [recipientList, setRecipientList] = useState(deliveryActions);

  const { t } = useTranslation("notification-management");

  const { handleStep } = useWizard();

  const [notificationNameLocal, setNotificationNameLocal] =
    useState(notificationRuleName);

  handleStep(() => {
    const recipientListWithUnsubscribeId =
      assignAndMapUnsubscribeIdToDeliveryActions(
        recipientList,
        unsubscribeIdMap,
      );
    setDeliveryActions(recipientListWithUnsubscribeId);
    setNotificationRuleName(notificationNameLocal);
  });

  useEffect(() => {
    const cleanedRecipientList = recipientList.filter((recipient) => {
      return recipient.value !== "" && recipient.value !== "+1";
    });

    const isRecipientListValid = cleanedRecipientList.every((recipient) => {
      if (recipient.type === "Email") {
        return isValidEmail(recipient.value);
      } else if (recipient.type === "SMS") {
        return isPossiblePhoneNumber(recipient.value);
      }
    });

    const isRecipientListUnique =
      _.uniqBy(cleanedRecipientList, "value").length ===
      cleanedRecipientList.length;

    setIsNextButtonDisabled(
      !isRecipientListValid ||
        !isRecipientListUnique ||
        !notificationNameLocal ||
        cleanedRecipientList.length === 0,
    );
  }, [recipientList, setIsNextButtonDisabled, notificationNameLocal]);

  useEffect(() => {
    if (recipientList.length === 0 && !isEditing) {
      const newRecipientList = [
        {
          type: "Email",
          value:
            userDefaultEmail && userDefaultEmail !== "" ? userDefaultEmail : "",
          unsubscribeId: null,
        },
        {
          type: "SMS",
          value:
            userDefaultPhoneNumber && userDefaultPhoneNumber !== ""
              ? userDefaultPhoneNumber
              : "",
          unsubscribeId: null,
        },
      ];
      setRecipientList(newRecipientList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRecipient = (idx) => {
    return (recipientObject) => {
      setRecipientList((prev) => {
        const newArr = [...prev];
        newArr[idx] = recipientObject;
        return newArr;
      });
    };
  };

  const addRecipientHandler = () => {
    setRecipientList((prev) => [
      ...prev,
      {
        type: "Email",
        value: "",
        unsubscribeId: null,
      },
    ]);
  };

  const removeRecipientHandler = (recipientToRemoveIdx) => {
    recipientList.splice(recipientToRemoveIdx, 1);
    setRecipientList([...recipientList]);
  };

  return (
    <>
      <Text bold block css={{ marginBottom: "0.5rem" }}>
        {t("notification-management:General Information")}
      </Text>
      <Text block css={{ marginTop: "0.5rem" }}>
        {t("notification-management:What is the name of this notification?")}
      </Text>
      <TextInput
        css={{ marginTop: "0.2rem", marginBottom: "1rem" }}
        placeholder={t("Enter Name")}
        value={notificationNameLocal}
        onChange={setNotificationNameLocal}
        data-qa="notification-management-name-input"
      ></TextInput>
      <Text>
        {t(
          "notification-management:By selecting the options below, you will receive notifications for the selected criteria, and agree to receive this communication by email or text message. Set a default recipient email and phone number on your profile page.",
        )}
      </Text>
      <div
        css={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <InteractiveList
          addButtonText={t("notification-management:Add Recipient")}
          addButtonHandler={addRecipientHandler}
          removeButtonHandler={removeRecipientHandler}
        >
          {recipientList.map((recipient, idx) => (
            <RecipientInput
              key={idx}
              recipient={recipient}
              setRecipient={setRecipient(idx)}
              data-qa={`notification-management-recipient-input-${idx}`}
            ></RecipientInput>
          ))}
        </InteractiveList>
      </div>
    </>
  );
};

GeneralInformationStep.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setDeliveryActions: PropTypes.func.isRequired,
  deliveryActions: PropTypes.array.isRequired,
  setIsNextButtonDisabled: PropTypes.func.isRequired,
  userDefaultCommunication: PropTypes.object,
  notificationRuleName: PropTypes.string.isRequired,
  setNotificationRuleName: PropTypes.func.isRequired,
  unsubscribeIdMap: PropTypes.object.isRequired,
};
