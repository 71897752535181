import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  PartViewExceptionType,
  PartViewDamagedCategory,
} from "pages/partview/utils/exceptions.utils";

/**
 * Returns object with 3 functions for translating exceptions:
 * - `getTranslatedExceptionNameByCode` - Given an exception code, return its translated name
 * - `getTranslatedDamageCategoryNameByCode` - Given an exception category code for "Damage", return its translated name
 * - `getFullTranslatedNameForException` - Given the full exception object, return the full label of the exception
 */
export const usePartViewExceptionLabel = () => {
  const { t } = useTranslation("partview-exception");

  const getTranslatedExceptionNameByCode = useCallback(
    (code) => {
      switch (code) {
        case PartViewExceptionType.SHORT:
          return t("partview-exception:Short");
        case PartViewExceptionType.MISSING:
          return t("partview-exception:Missing");
        case PartViewExceptionType.HELD:
          return t("partview-exception:Held");
        case PartViewExceptionType.DAMAGED:
          return t("partview-exception:Damaged");
        case PartViewExceptionType.MISROUTED:
          return t("partview-exception:Misrouted");
        case PartViewExceptionType.RECYCLED:
          return t("partview-exception:Recycled");
        case PartViewExceptionType.LOST:
          return t("partview-exception:Lost");
        case PartViewExceptionType.BEHIND_SCHEDULE:
          return t("partview-exception:Behind Schedule");
        case PartViewExceptionType.BACK_ORDER:
          return t("partview-exception:Back Order");
        case PartViewExceptionType.DELAYED:
          return t("partview-exception:Delayed");
        default:
          return null;
      }
    },
    [t],
  );

  const getTranslatedDamageCategoryNameByCode = useCallback(
    (damageCategoryCode) => {
      switch (damageCategoryCode) {
        case PartViewDamagedCategory.PACKAGE_CRUSHED:
          return t("partview-exception:Package Crushed");
        case PartViewDamagedCategory.PACAKGE_PUNCTURED:
          return t("partview-exception:Package Punctured/Torn");
        case PartViewDamagedCategory.PACKAGE_WET:
          return t("partview-exception:Package Wet/Leaking/Soiled");
        case PartViewDamagedCategory.PART_BROKEN:
          return t("partview-exception:Part Broken");
        case PartViewDamagedCategory.PART_BENT:
          return t("partview-exception:Part Bent");
        case PartViewDamagedCategory.PART_SCRATCHED:
          return t("partview-exception:Part Scratched");
        case PartViewDamagedCategory.NONE:
          return t("partview-exception:No Damage");
        default:
          return null;
      }
    },
    [t],
  );

  const getFullTranslatedNameForException = useCallback(
    (exception) => {
      const exceptionLabel = getTranslatedExceptionNameByCode(
        exception.reasonCode,
      );

      let categoryLabel = null;
      if (exception.reasonCode === "DM") {
        categoryLabel = getTranslatedDamageCategoryNameByCode(
          exception.categoryCode,
        );
      }

      if (categoryLabel) {
        return `${exceptionLabel} - ${categoryLabel}`;
      }

      return exceptionLabel;
    },
    [getTranslatedExceptionNameByCode, getTranslatedDamageCategoryNameByCode],
  );

  return {
    getTranslatedExceptionNameByCode,
    getTranslatedDamageCategoryNameByCode,
    getFullTranslatedNameForException,
  };
};
