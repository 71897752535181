/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Loader from "react-loader";
import { faSearch, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { FontSize } from "components/atoms/enums";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import Colors from "styles/colors";

export const SavedSearchPanel = ({
  children,
  isLoading,
  savedSearch,
  isDeleting,
  onSearchClick,
  onEditClick,
  //Alert Me
  onSubscriptionClick = null,
  onRefreshClick = null,
}) => {
  const leftContent = (
    <Button variant="outline-light" onClick={onSearchClick}>
      <Icon src={faSearch} />
    </Button>
  );

  const rightContent = (
    <div css={{ display: "flex", gap: "1em" }}>
      {onRefreshClick ? (
        <Button variant="outline-light" onClick={onRefreshClick}>
          <Icon src={faSyncAlt} />
        </Button>
      ) : null}
      {onSubscriptionClick ? (
        <Button variant="outline-light" onClick={onSubscriptionClick}>
          <Icon
            src={faBellSolid}
            color={onRefreshClick ? Colors.highlight.GREEN : null}
            size={FontSize.size18}
          />
        </Button>
      ) : null}

      <Button variant="outline-light" onClick={onEditClick}>
        <Icon src={faPencil} />
      </Button>
    </div>
  );

  let deletingStyles = {};
  if (isDeleting) {
    deletingStyles = { opacity: 0.5, pointerEvents: "none" };
  }

  return (
    <PanelGroup style={{ height: "100%", ...deletingStyles }}>
      <PanelGroup.Header
        title={savedSearch.name}
        titleSize={FontSize.size18}
        titleTooltipText={savedSearch.name}
        leftContent={leftContent}
        rightContent={rightContent}
        dataQa="panel-group-saved-search-header"
      />
      <PanelGroup.Content
        style={{ position: "relative", minHeight: 200 }}
        dataQa="panel-group-saved-search-content"
      >
        <Loader loaded={!isLoading}>{children}</Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

SavedSearchPanel.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  savedSearch: PropTypes.object,
  isDeleting: PropTypes.bool,
  onSearchClick: PropTypes.func,
  onEditClick: PropTypes.func,
  //Alert Me
  onSubscriptionClick: PropTypes.func,
  onRefreshClick: PropTypes.func,
};
