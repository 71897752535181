/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";

const ResultsSection = (props) => {
  const { children, isMobile, isExpanded, collapsedWidth } = props;

  // Will take up more of the flex parent when on desktop
  const expandedFlex = isMobile ? 1 : 1.5;

  return (
    <div
      data-qa="section-vin-list"
      css={{
        backgroundColor: Colors.background.DARK_GRAY,
        display: "flex",
        flex: isExpanded ? expandedFlex : null,
        overflowY: "auto",
        // Minimum width if collapsed on desktop
        minWidth: collapsedWidth,
        // Minimum height if collapsed on mobile
        minHeight: collapsedWidth,
      }}
    >
      {children}
    </div>
  );
};

ResultsSection.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  collapsedWidth: PropTypes.number.isRequired,
};

export default ResultsSection;
