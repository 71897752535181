import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "partViewBackOrderDashboard";

//Ducks
const backOrderedPartDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "backOrderedPart",
  {},
);

// Actions
const getScopedActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;

const SET_BACKORDER_TABLE_PAGE_INDEX = getScopedActionName(
  "SET_BACKORDER_TABLE_PAGE_INDEX",
);

// Action Creators
function setBackOrderTablePageIndex(index) {
  return { type: SET_BACKORDER_TABLE_PAGE_INDEX, payload: { index } };
}

function fetchBackOrderedParts() {
  return (dispatch, getState) => {
    const state = getState();

    const url = apiUrl("/partview/app/backorder-parts");
    const config = {
      params: {
        pageNumber: getBackOrderTablePageIndex(state),
        pageSize: getBackOrderTablePageSize(state),
      },
    };
    return dispatch(backOrderedPartDuck.fetch(url, config));
  };
}

// Selectors
const getBackOrderTableIsLoading = (state) =>
  backOrderedPartDuck.selectors.getData(state)?.isLoading ?? false;
const geBackOrderTableResults = (state) =>
  backOrderedPartDuck.selectors.getData(state)?.data?.data ?? [];
const getBackOrderTablePageIndex = (state) =>
  state[STORE_MOUNT_POINT].backOrderTablePageIndex ?? 0;
const getBackOrderTablePageSize = (state) => 20;
const getBackOrderTablePageCount = (state) =>
  backOrderedPartDuck.selectors.getData(state)?.data?.meta?.totalPages ?? 0;

// Reducer
const partViewBackOrderDashboardReducer = (
  state = { backOrderTablePageIndex: 0 },
  action,
) => {
  switch (action.type) {
    case SET_BACKORDER_TABLE_PAGE_INDEX:
      return { ...state, backOrderTablePageIndex: action.payload.index };
    default:
      return state;
  }
};

const PartViewBackOrderDashboardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchBackOrderedParts,
    setBackOrderTablePageIndex,
  },
  selectors: {
    getBackOrderTableIsLoading,
    geBackOrderTableResults,
    getBackOrderTablePageIndex,
    getBackOrderTablePageSize,
    getBackOrderTablePageCount,
  },
  reducer: chainReducers([
    backOrderedPartDuck.reducer,
    partViewBackOrderDashboardReducer,
  ]),
};

export default PartViewBackOrderDashboardState;
