import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./FinVehicleSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import finVehicleDomainData from "../domain-data/FinVehicleDomainData";

import { selectLocationOptions } from "./FinVehicleSearchCategorySelectors";
import { getAuthorization } from "../auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
  } = SearchBarState.selectors;

  return {
    auth: getAuthorization(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    canUserSearch: getCanUserSearch(state),
    solutionId: getSolutionId(state),
    vins: finVehicleDomainData.selectors.getVINs(state),
    shipments: finVehicleDomainData.selectors.getShipments(state),
    locations: selectLocationOptions(state),
    productTypes: finVehicleDomainData.selectors.getProductTypes(state),
    orderNumbers: finVehicleDomainData.selectors.getOrderNumbers(state),
    exceptionTypes: finVehicleDomainData.selectors.getExceptionTypes(state),
    routeIds: finVehicleDomainData.selectors.getRouteIds(state),
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
} = SearchBarState.actionCreators;
const { fetchDomainData } = finVehicleDomainData.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
  fetchDomainData,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
