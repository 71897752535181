import { connect } from "react-redux";

import {
  getActiveOrganization,
  getCurrentOrganizationId,
  setCurrentOrganization,
  setActiveOrganization,
  getOrganizationImageConfig,
} from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import MapState from "modules/map/MapState";
import TitleState from "shared/redux/TitleState";
import HeaderSearchBarState from "./HeaderSearchBarState";
import HeaderBar from "./HeaderBarView";

function mapStateToProps(state) {
  return {
    title: TitleState.selectors.getTitle(state),
    subTitle: TitleState.selectors.getSubTitle(state),
    description: TitleState.selectors.getDescription(state),
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    currentOrganizationId: getCurrentOrganizationId(state),
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    organizationImageConfig: getOrganizationImageConfig(state),
    organizations: HeaderSearchBarState.selectors.getSearchResults(state),
  };
}

// Dispatch to props
function mapDispatchToProps(dispatch) {
  return {
    setCurrentOrganization: (id) => dispatch(setCurrentOrganization(id)),
    setActiveOrganization: (org) => dispatch(setActiveOrganization(org)),
    setMapTypeOverride: (mapType) =>
      dispatch(MapState.actionCreators.setMapTypeOverride(mapType)),
    fetchOrganizations: () =>
      dispatch(HeaderSearchBarState.actionCreators.searchEntities()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);
