/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormLabel } from "react-bootstrap";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { UsersActionStatus } from "modules/users/UsersState";
import { useUsersTranslation } from "modules/users/utils/users.utils";
import { Modal } from "components/molecules/Modal.molecule";
import {
  FormButton,
  ReadOnlyInput,
  TextInput,
  SelectInput,
} from "components-old/modal-elems";
import { DamageViewLocationsSelect } from "./DamageViewLocationsSelect";
import Colors from "../../../styles/colors";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import RolesState from "modules/roles/RolesState";
import UsersState from "modules/users/UsersState";

import { filterRoleTypes } from "./modal-utils";
import { FlexRowDiv, FlexColDiv } from "../../../styles/container-elements";
import { isValidEmail } from "../../../utils/validation-utils";

const isValidForm = ({ email, confirmEmail, selectedRoleTypes }) => {
  return isValidEmail(email) &&
    email === confirmEmail &&
    selectedRoleTypes &&
    selectedRoleTypes.length > 0
    ? true
    : false;
};

const initUser = {
  email: "",
  confirmEmail: "",
  selectedRoleTypes: null,
};

const AddUserModal = ({
  addUser,
  authorization,
  activeOrganization,
  actionStatus,
  clearActionStatus,
  hide,
  roles,
  show,
}) => {
  const { t } = useTranslation("users");

  const [user, setUser] = useState(initUser);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const { getTranslatedFriendlyName } = useUsersTranslation();

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization],
  );

  const roleTypes = useMemo(() => {
    return roles ? filterRoleTypes(roles, authorization.isFvAdmin()) : [];
  }, [authorization, roles]);

  const inputHandler = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }
    setUser((prevState) => {
      return { ...prevState, ...value };
    });
  };

  const clearForm = () => {
    setUser(initUser);
    setSelectedLocations([]);
  };

  const getIsDamageViewSelected = () => {
    return (
      user.selectedRoleTypes?.filter((data) => {
        return data.label === "Damageview User";
      }).length > 0
    );
  };

  const shouldShowFailureAlert = (actionStatus) => {
    return (
      actionStatus && [UsersActionStatus.ADD_USER_FAILED].includes(actionStatus)
    );
  };

  const shouldShowSuccessAlert = (actionStatus) => {
    return (
      actionStatus &&
      [UsersActionStatus.ADD_USER_SUCCEEDED].includes(actionStatus)
    );
  };

  const getAlertMessage = (actionStatus) => {
    switch (actionStatus) {
      case UsersActionStatus.ADD_USER_SUCCEEDED:
        return t(
          "users:An account with email [[[userEmail]]] has been created",
          { userEmail: user.email },
        );
      case UsersActionStatus.DUPLICATE_USER:
        return t("users:An account with email [[[userEmail]]] already exists", {
          userEmail: user.email,
        });
      case UsersActionStatus.UNKNOWN_USER_ERROR:
        return t("users:Error occurred while attempting to add user");
      default:
        return "";
    }
  };

  const onHide = () => {
    clearForm();
    hide();
  };

  return (
    <Modal backdrop={"static"} show={show} onHide={onHide}>
      <Modal.Header title={t("users:Add New User")} />
      <Modal.Body>
        <FlexRowDiv>
          <ReadOnlyInput
            style={{ marginTop: 0 }}
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            changeHandler={inputHandler}
            label={t("users:Email")}
            prop="email"
            value={user.email}
          />
          <TextInput
            style={{ marginLeft: ".25em" }}
            changeHandler={inputHandler}
            label={t("users:Confirm Email")}
            prop="confirmEmail"
            value={user.confirmEmail}
          />
        </FlexRowDiv>

        <FlexRowDiv style={{ marginTop: "1em" }}>
          <SelectInput
            label={t("users:User Roles")}
            placeholder={t("users:Select...")}
            options={roleTypes.map((r) => {
              return {
                value: r.id,
                label: getTranslatedFriendlyName(r.name),
              };
            })}
            changeHandler={inputHandler}
            prop="selectedRoleTypes"
            value={user.selectedRoleTypes}
          />
        </FlexRowDiv>

        {getIsDamageViewSelected() ? (
          <FlexColDiv style={{ marginTop: "1em" }}>
            <div css={{ width: "50%" }}>
              <FormLabel
                style={{ fontWeight: "normal", color: Colors.text.HIT_GRAY }}
              >
                {t("user:Reporting Location")}
              </FormLabel>
              <DamageViewLocationsSelect
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
              />
            </div>
            <div css={{ marginTop: "1em", color: Colors.text.GRAY }}>
              <span css={{ marginRight: "5px" }}>{t("user:Note")}:</span>
              <span>
                {t(
                  "user:Greyed out locations have already been assigned to a user. You may choose to override and reassign the location to the new user but it will remove the location assignment from the original user",
                )}
              </span>
            </div>
          </FlexColDiv>
        ) : null}
        <Alert
          variant={
            shouldShowFailureAlert(actionStatus)
              ? AlertVariant.Danger
              : AlertVariant.Success
          }
          show={
            shouldShowSuccessAlert(actionStatus) ||
            shouldShowFailureAlert(actionStatus)
          }
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {getAlertMessage(actionStatus, user.email, t)}
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-end" }}>
        <div>
          <FormButton
            variant="default"
            style={{
              marginRight: ".5em",
              border: "1px solid #ccc",
            }}
            label={
              actionStatus === UsersActionStatus.ADD_USER_SUCCEEDED
                ? t("users:Close")
                : t("users:Cancel")
            }
            clickHandler={onHide}
          />
          <FormButton
            disabled={
              !isValidForm(user) ||
              actionStatus === UsersActionStatus.ADD_USER_IN_PROGRESS ||
              actionStatus === UsersActionStatus.ADD_USER_SUCCEEDED
            }
            actionType="ACTION"
            label={
              actionStatus === UsersActionStatus.ADD_USER_IN_PROGRESS ? (
                <Icon src={faSpinner} spin />
              ) : (
                t("users:Create")
              )
            }
            variant="success"
            style={{ border: "1px solid #ccc" }}
            clickHandler={() => {
              const roleIds = user.selectedRoleTypes.map((r) => r.value);
              const addedLocations = selectedLocations.map((location) => {
                return location.value;
              });
              const payload = {
                email: user.email,
                role_ids: roleIds,
                damageLocations: addedLocations,
              };
              addUser(payload);
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AddUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  authorization: PropTypes.object.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  actionStatus: PropTypes.string,
  roles: PropTypes.array.isRequired,
  addUser: PropTypes.func.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
    roles: RolesState.selectors.getRoles(state),
  };
}

const mapDispatchToProps = {
  addUser: UsersState.actionCreators.addUser,
  clearActionStatus: UsersState.actionCreators.clearActionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
