/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontSize } from "components/atoms/enums";

// Components
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  FlexRowDiv,
  FlexColDiv,
  FlexDiv,
} from "../../../styles/container-elements";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { TextInput } from "components/atoms/TextInput.atom";
import { MediaQueries } from "components/responsive";

// Hooks
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useNotAllSuccessfulColumns } from "pages/driveaway/components/search/DriveAway.NotAllSuccessful.columns";
import { useOverrideTripPlanMutation } from "../react-queries/OverrideCurrentTripPlan";
import Colors from "styles/colors";

export const SetCurrentPlannedTripLegPage = ({ solutionId }) => {
  const initialState = {
    vin: "",
    originLocationCode: "",
    destinationLocationCode: "",
    importFileCsvData: "",
    bulkActionSubmitBtnDisabled: true,
    showResultModal: false,
  };

  const uploadMutation = useOverrideTripPlanMutation();

  const columns = useNotAllSuccessfulColumns();

  const { t } = useTranslation("internal-tools");

  useSetTitleOnMount(t("internal-tools:Set Current Planned Trip Leg Tool"));

  const csvTooltip = (
    <Fragment>
      <Text>{`${t("internal-tools:The file must contain")}:`}</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "nowrap",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:External Id")}</li>
        <li>{t("internal-tools:Planned Origin Location Code")}</li>
        <li>{t("internal-tools:Planned Destination Location Code")}</li>
      </ul>
    </Fragment>
  );

  const csvTemplate = {
    fileName: `template-current-planned-trip-leg-${Date.now()}.csv`,
    headers: [
      "External Id",
      "Planned Origin Location Code",
      "Planned Destination Location Code",
    ],
    data: [
      ["VIN1", "OriginLocation1", "DestinationLocation1"],
      ["VIN2", "OriginLocation2", "DestinationLocation2"],
      ["VIN3", "OriginLocation3", "DestinationLocation3"],
    ],
  };

  const requestResults = uploadMutation?.data?.failedVins ?? []; // provides the list of failed VIN's and reasons

  const vinRegex = new RegExp(`[A-Z0-9]{17}`);
  // State
  const [vin, setVin] = useState(initialState.vin);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [originLocationCode, setOriginLocationCode] = useState(
    initialState.originLocationCode,
  );
  const [destinationLocationCode, setDestinationLocationCode] = useState(
    initialState.destinationLocationCode,
  );
  const [importFileCsvData, setImportFileCsvData] = useState(
    initialState.importFileCsvData,
  );
  const [importFile, setImportFile] = useState(null);
  const [showResultModal, setShowResultModal] = useState(
    initialState.showResultModal,
  );

  // Handlers
  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  // csv file upload handler
  const onBulkSubmitHandler = () => {
    setIsBulkUpload(true);
    setShowResultModal(true);
    const body = importFileCsvData ? importFileCsvData.split("\n") : [];
    uploadMutation.mutate({ solutionId, payload: body });
  };

  // single VIN update handler
  const onSingleSubmitHandler = () => {
    setIsBulkUpload(false);
    setShowResultModal(true);
    const body = [
      "External Id,Planned Origin Location Code,Planned Destination Location Code",
      `${vin},${originLocationCode},${destinationLocationCode}`,
    ];
    uploadMutation.mutate({ solutionId, payload: body });
  };

  let isBulkSubmitDisabled = true;
  if (importFileCsvData?.length > 0) {
    isBulkSubmitDisabled = false;
  }

  let isSubmitDisabled = true;
  if (
    vinRegex.test(vin) &&
    !_.isEmpty(originLocationCode.trim()) &&
    !_.isEmpty(destinationLocationCode.trim())
  ) {
    isSubmitDisabled = false;
  }

  return (
    <Fragment>
      <div css={{ padding: "1em", flexDirection: "column" }}>
        <FlexColDiv
          css={{
            padding: "1em",
          }}
        >
          <Text size={FontSize.size18} bold>
            {t("internal-tools:Bulk Action")}
          </Text>
          <Text block={true} style={{ marginBottom: "1em" }}>
            {t(
              "internal-tools:Apply different actions to different VINs using a csv file",
            )}
            {":"}
          </Text>
          <FlexRowDiv css={{ marginBottom: "1em" }}>
            <div style={{ marginRight: "1em" }}>
              <Text>{t("internal-tools:Select a CSV file to import")}.</Text>{" "}
              <Tooltip placement="top" tooltipChildren={csvTooltip}>
                <Icon size={FontSize.size16} src={faQuestionCircle} />
              </Tooltip>
            </div>

            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
            />
          </FlexRowDiv>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-light">
                  {t("internal-tools:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onFileLoad;
                      setImportFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importFile?.name ?? ""}
                placeholder={t("internal-tools:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>
          <Button
            variant="primary"
            disabled={isBulkSubmitDisabled}
            onClick={onBulkSubmitHandler}
            style={{ width: "fit-content" }}
          >
            {t("internal-tools:Upload")}
          </Button>
        </FlexColDiv>
        <hr />
        <div id="singleAction">
          <FlexColDiv
            css={{
              padding: "1em",
            }}
          >
            <Text size={FontSize.size18} bold>
              {t("internal-tools:Single Action")}
            </Text>
            <Text block={true} style={{ marginBottom: "1em" }}>
              {t(
                "internal-tools:Override the Active Planned Trip Leg on a VIN",
              )}
              :
            </Text>

            <FlexDiv
              css={{
                marginTop: "1em",
                flexDirection: "row",
                flexGrow: "1",
                [MediaQueries.smallAndDown]: {
                  flexDirection: "column",
                },
              }}
            >
              <FlexColDiv
                css={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  minWidth: "20em",
                }}
              >
                <Text>{t("internal-tools:VIN")}</Text>
                <TextInput onChange={setVin} maxLength={17}></TextInput>
              </FlexColDiv>
              <FlexColDiv
                css={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  minWidth: "20em",
                }}
              >
                <Text>{t("internal-tools:Planned Origin Location Code")}</Text>
                <TextInput onChange={setOriginLocationCode}></TextInput>
              </FlexColDiv>
              <FlexColDiv
                css={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  minWidth: "20em",
                }}
              >
                <Text>
                  {t("internal-tools:Planned Destination Location Code")}
                </Text>
                <TextInput onChange={setDestinationLocationCode}></TextInput>
              </FlexColDiv>
            </FlexDiv>
            <Button
              variant="primary"
              disabled={isSubmitDisabled}
              onClick={onSingleSubmitHandler}
              style={{ width: "fit-content", marginTop: "1em" }}
            >
              {t("internal-tools:Submit")}
            </Button>
          </FlexColDiv>
          <div css={{ color: Colors.text.DIM_GRAY }}>
            <Text size={FontSize.size18} block css={{ marginBottom: "0.5em" }}>
              {t("internal-tools:Important Notes")}:
            </Text>
            <Text size={FontSize.size16} block>
              *{" "}
              {t(
                "internal-tools:This will only apply to Customer Provided Planned Trip Legs.",
              )}
            </Text>
            <Text size={FontSize.size16} block>
              *{" "}
              {t(
                "internal-tools:This will not process if the trip plan contains two planned legs with same Origin and Destination Codes.",
              )}
            </Text>
          </div>
        </div>
      </div>
      <DialogModal
        style={{ width: "50%" }}
        show={showResultModal}
        onHide={() => {
          setShowResultModal(false);
          setIsBulkUpload(false);
          uploadMutation.reset();
        }}
        isLoading={uploadMutation?.isPending}
        loadStatus={"success"}
        title={
          isBulkUpload
            ? t("internal-tools:Bulk Action")
            : t("internal-tools:Single Action")
        }
        okButtonText={t("internal-tools:Close")}
        submitButtonVariant="primary"
        size="md"
        submitButtonDisabled={true}
        showCloseButton={false}
      >
        {uploadMutation?.isPending ? <UploadInProgressModalBody t={t} /> : null}

        {uploadMutation?.isError ? <UploadErrorModalBody t={t} /> : null}

        {uploadMutation?.isSuccess ? (
          requestResults.length === 0 ? (
            <UploadAllSuccessModalBody />
          ) : (
            <UploadNotAllSuccessModalBody
              columns={columns}
              requestResults={requestResults}
            />
          )
        ) : null}
      </DialogModal>
    </Fragment>
  );
};

SetCurrentPlannedTripLegPage.propTypes = {
  solutionId: PropTypes.string,
};

const UploadAllSuccessModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faCheckCircle} color="green" size={FontSize.size18} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t(
              "internal-tools:Action(s) successfully completed for all VIN(s)",
            )}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

UploadAllSuccessModalBody.propTypes = {
  t: PropTypes.func,
};

const UploadErrorModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={FontSize.size18} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("internal-tools:There was an error. Please try again later.")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadNotAllSuccessModalBody = (props) => {
  const { columns, requestResults } = props;
  const { t } = useTranslation("internal-tools");
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={FontSize.size18} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        <Text size={FontSize.size16}>
          {t("internal-tools:Action(s) was not successful for all VIN(s).")}
        </Text>
        <Text css={{ marginTop: "1em" }}>
          {t(
            "internal-tools:Please see the below list for VIN(s) that were not successful and the reasons why",
          )}
          {":"}
        </Text>
        <BaseTable
          theme={Themes.LIGHT}
          columns={columns}
          data={requestResults}
        />
      </div>
    </Fragment>
  );
};

UploadNotAllSuccessModalBody.propTypes = {
  requestResults: PropTypes.array,
  columns: PropTypes.array,
};

const UploadInProgressModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "2.5em 0" }}>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faSpinner} spin size={FontSize.size18} />
      </div>
      <Text
        css={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
        size={FontSize.size16}
      >
        {t("internal-tools:Processing request...")}
      </Text>
    </div>
  );
};
