import _ from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { parse } from "utils/json-utils";
import { getReferenceByName } from "utils/response-utils";
import { EventCode, ExceptionEventSubcode } from "api/consts";

/**
 * The key for the leg index populated by `assignStopIndexToEvents`
 * and used by `useEntityTripSummary`.
 */
const LEG_INDEX_EVENT_KEY = "__LEG_INDEX__";

/**
 * Adds an index field to the event so we can add it to the appropriate
 * stop later. Whenever an event with code "ActualTripLegCompleted" is seen,
 * the index is incremented for subsequent events. See FIN-663.
 *
 * Notes:
 * - If there are no "ActualTripLegCompleted" events, all events will be
 *   associated to the first leg.
 * - For each "ActualTripLegCompleted" event, increment the stop index by 1 to
 *   place subsequent events in the next leg.
 * - Any events that happen after the last "ActualTripLegCompleted" event stay
 *   in the last leg.
 *
 * @param {*} event The event that needs a leg index assigned
 * @param {*} eventIndex The index of the event from `array`
 * @param {*} array The original events array
 * @returns
 */
function assignLegIndexToEvent(event, eventIndex, array) {
  // Count the amount of "ActualTripLegCompleted" events until the given event.
  const legIndex = array.reduce((count, event, index) => {
    if (index > eventIndex) {
      return count;
    }

    if (event.code === "ActualTripLegCompleted") {
      count = count + 1;
    }

    return count;
  }, 0);

  return { ...event, [LEG_INDEX_EVENT_KEY]: legIndex };
}

const EXCLUDED_EVENT_CODES = new Set([
  EventCode.ACTUAL_TRIP_LEG_COMPLETED,
  EventCode.ACTUAL_TRIP_LEG_CREATED,
  EventCode.ACTUAL_TRIP_LEG_UPDATED,
  EventCode.ENTITY_LIFE_CYCLE_UPDATE,
  EventCode.ENTITY_UPDATED,
  EventCode.PLANNED_TRIP_LEG_CREATED,
  EventCode.PLANNED_TRIP_LEG_UPDATED,
  EventCode.POSITION_UPDATED,
  EventCode.PROGRESS_UPDATED,
]);

const EXCLUDED_EVENT_WITH_SUBCODES = new Set([
  `${EventCode.EXCEPTION_CREATED}:${ExceptionEventSubcode.T}`,
  `${EventCode.EXCEPTION_CLEARED}:${ExceptionEventSubcode.T}`,
]);

/**
 * Checks if an event should be shown.
 *
 * @param {*} event
 * @returns
 */
function isIncludedEvent(event) {
  return (
    !EXCLUDED_EVENT_CODES.has(event.code) &&
    !EXCLUDED_EVENT_WITH_SUBCODES.has(`${event.code}:${event.subcode}`)
  );
}

/**
 * Transforms an event from the API to be an "update" used for the TripSummary tables.
 *
 * @param {*} event
 * @returns
 */
function transformEventToUpdate(event) {
  let comments = event.comments ?? "";

  // FIN-1060: Change comments for trip plan completed event.
  if (event.code === "TripPlanCompleted") {
    comments = "Arrival at Ultimate Destination Reported";
  }

  return {
    id: event.id,
    entityId: event.entityId,
    eventTs: event.eventTs,
    ts: event.ts,
    update: getFriendlyEventName(event),
    code: event.code,
    codeDescription: event.codeDescription,
    subcode: event.subcode,
    subcodeDescription: event.subcodeDescription,
    comments: comments,
    showComments: event?.showComments ?? false,
    isHold: event.isHold ? event.isHold : false,
    statusUpdate: event.statusUpdate,
    duplicateMilestoneGroup: event?.duplicateMilestoneGroup ?? false,
    [LEG_INDEX_EVENT_KEY]: event[LEG_INDEX_EVENT_KEY],
  };
}

const getFriendlyEventName = (event) => {
  const {
    code = "",
    codeDescription = "",
    subcode = "",
    subcodeDescription = "",
  } = event;

  switch (code) {
    case "EntityCreated":
      return "VIN Registered";
    case "HoldCreated":
    case "HoldCleared":
    case "StatusUpdated":
      return `${codeDescription} - ${subcodeDescription} (${subcode})`;
    case "ExceptionCreated":
    case "ExceptionCleared":
      return `${codeDescription} - ${subcodeDescription}`;
    case "ActualTripLegArrivedOrigin":
      return `Arrived for Pickup - ${subcodeDescription} (${subcode})`;
    case "ActualTripLegDepartedOrigin":
      return `Departed Pickup - ${subcodeDescription} (${subcode})`;
    case "ActualTripLegArrivedDestination":
      return `Arrived for Delivery - ${subcodeDescription} (${subcode})`;
    default:
      return event.codeDescription ?? event.code;
  }
};

const useTriplegReferences = () => {
  const { t } = useTranslation("components");

  // FIN-2775: Include "carrierName", "carrierScac", and "transportMode" in
  //   references for planned triplegs if not already there.
  //   No translations for these fields at this time.
  // FIN-2231: `segmentRouteCode` reference should be displayed and translated as "Route ID".
  const getTriplegReferences = useCallback(
    (leg) => {
      const references = _.cloneDeep(leg?.references ?? []);

      const addRef = (qualifier, value) => {
        if (
          !_.isNil(value) &&
          _.isNil(getReferenceByName(references, qualifier))
        ) {
          references.push({ qualifier: qualifier, value: value });
        }
      };

      addRef("carrierName", leg?.carrierInfo?.carrierName);
      addRef("carrierScac", leg?.carrierInfo?.carrierScac);
      addRef("transportMode", leg?.transportMode);

      return references.map((reference) => {
        if (reference.qualifier === "segmentRouteCode") {
          return { ...reference, qualifier: t("components:Route ID") };
        }

        return reference;
      });
    },
    [t],
  );

  return { getTriplegReferences };
};

/**
 * Creates stops and updates arrays for the TripSummary tab. Each stop contains:
 * - Location (name, code, address, and LAD).
 * - Transport mode.
 * - Scheduled pickup window.
 * - Scheduled delivery window.
 * - Actual delivery datetime.
 * - Progress to the next destination (except ultimate origin).
 * - Events for the leg **going to the stop**. Transformed to updates.
 *
 * Example:
 *
 * Given legs `[leg1, leg2, leg3]`, stops would be:
 * ```
 * [
 *   leg1.origin,
 *   leg1.destination,
 *   leg2.destination,
 *   leg3.destination
 * ]
 * ```
 *
 * @param {*} combinedLegs Array of objects  containing the planned and actual legs
 * @param {*} events Array of events for the entity, vin, etc.
 * @returns
 */
export const useEntityTripSummary = (combinedLegs = [], events = []) => {
  const { getTriplegReferences } = useTriplegReferences();

  const updates = _.chain(events)
    .map(assignLegIndexToEvent)
    .filter(isIncludedEvent)
    .map(transformEventToUpdate)
    .orderBy(["eventTs"], ["asc"])
    .value();

  // Create stops from triplegs
  const stops = [];
  combinedLegs.forEach((leg, index, originalCombinedLegs) => {
    const actualLeg = leg.actual ?? null;
    const plannedLeg = leg.planned ?? null;
    const nextPlannedLeg = originalCombinedLegs[index + 1]?.planned ?? null;
    let lastLegIndex = combinedLegs.length - 1;

    if (index === 0) {
      stops.push({
        name: plannedLeg?.origin?.name,
        code: plannedLeg?.origin?.code,
        address: plannedLeg?.origin?.address,
        city: plannedLeg?.origin?.city,
        state: plannedLeg?.origin?.state,
        country: plannedLeg?.origin?.country,
        lad: plannedLeg?.origin?.lad,
        // Ultimate Origin never has a delivery date.
        actualDeliveryToStop: null,
        scheduled_delivery_window: null,
        scheduled_pickup_window: parse(
          plannedLeg?.origin?.scheduledArrivalWindow,
        ),
        // Ultimate Origin doesn't have events.
        updates: [],
        mode: plannedLeg?.transportMode,
        actualCreatorShipmentId: actualLeg?.id,
        // No progress to Ultimate Origin.
        progress: null,
        // Only in-between legs can be generated.
        isPlannedLegFvGenerated: false,
      });
    }

    stops.push({
      name: plannedLeg?.dest?.name,
      code: plannedLeg?.dest?.code,
      address: plannedLeg?.dest?.address,
      city: plannedLeg?.dest?.city,
      state: plannedLeg?.dest?.state,
      country: plannedLeg?.dest?.country,
      lad: plannedLeg?.dest?.lad,
      actualDeliveryToStop: actualLeg?.dest?.arrived,
      scheduled_delivery_window: parse(
        plannedLeg?.dest?.scheduledArrivalWindow,
      ),
      scheduled_pickup_window: parse(
        nextPlannedLeg?.origin?.scheduledArrivalWindow,
      ),
      updates:
        updates?.filter((update) => {
          const isUpdateForLeg = update[LEG_INDEX_EVENT_KEY] === index;
          const isLastLeg = index === lastLegIndex;
          const isUpdateAfterThisLegCompleted =
            update[LEG_INDEX_EVENT_KEY] > index;

          return isUpdateForLeg || (isLastLeg && isUpdateAfterThisLegCompleted);
        }) ?? [],
      progress: actualLeg?.progress ?? 0,
      mode: plannedLeg?.transportMode,
      actualCreatorShipmentId: actualLeg?.id,
      // FIN-2221, FIN-2625:
      // - If this leg is generated, we want to display the FV Logomark
      //   on the timeline going to the next destination.
      // - If the legDataType is "placeholder", treat it like a generated leg
      isPlannedLegFvGenerated:
        plannedLeg?.id === "fvGenerated" ||
        plannedLeg?.internalMetadata?.legDataType === "placeholder",
      plannedTripLegReferences: getTriplegReferences(plannedLeg),
    });
  });

  return { stops, updates };
};
