import { connect } from "react-redux";
import EditShipmentDwellNotificationState from "../../redux/EditShipmentDwellNotificationState";
import ShipmentDwellNotificationSearchBarState from "../../redux/ShipmentDwellNotificationSearchBarState";
import { ShipmentDwellNotificationDeleteModal } from "./ShipmentDwellNotification.DeleteModal.organism";

const {
  getLocation,
  getRecipients,
  getIsPrefillDataLoading,
  getIsModifyDwellNotificationLoading,
} = EditShipmentDwellNotificationState.selectors;

const mapStateToProps = (state) => {
  return {
    location: getLocation(state),
    recipients: getRecipients(state),
    isPrefillDataLoading: getIsPrefillDataLoading(state),
    isModifyDwellNotificationLoading:
      getIsModifyDwellNotificationLoading(state),
  };
};

const { modifyDwellNotification, fetchPrefillData } =
  EditShipmentDwellNotificationState.actionCreators;

const { searchEntities } =
  ShipmentDwellNotificationSearchBarState.actionCreators;

const actionCreators = {
  modifyDwellNotification,
  fetchPrefillData,
  searchEntities,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(ShipmentDwellNotificationDeleteModal);
