/** @jsxImportSource @emotion/react */
import { PropTypes } from "prop-types";
import { useRef } from "react";
import Select from "react-select";

export const PriorityFilterDropdown = ({
  field,
  options,
  stateValue,
  setFieldData,
}) => {
  const ref = useRef(null);

  let selectedOption = null;

  if (Array.isArray(options) && stateValue != null) {
    selectedOption = options.filter(({ value }) => value === stateValue);
  }

  return (
    <Select
      ref={ref}
      placeholder={""}
      value={selectedOption}
      onChange={(data) => {
        setFieldData(field.fieldName, data.value);
      }}
      isSearchable={true}
      onInputChange={(value, actionMeta) => {
        if (actionMeta.action === "input-change") {
          // Focus the option where the label starts with the same text as the input value
          const focusedOption =
            options.find((option) => option.value.includes(value)) ??
            options[0];
          ref.current.state.focusedOption = focusedOption;
        }
      }}
      options={options}
    />
  );
};

PriorityFilterDropdown.propTypes = {
  field: PropTypes.object,
  options: PropTypes.array,
  stateValue: PropTypes.string,
  setFieldData: PropTypes.func,
};
