export const getLifeCycleStateTranslatedKeys = (lifecycleType, t) => {
  let lifecycleStateName;

  if (lifecycleType === "created") {
    lifecycleStateName = t("partview-dashboard:Created");
  }

  if (lifecycleType === "in_route") {
    lifecycleStateName = t("partview-dashboard:In Route");
  }
  if (lifecycleType === "delayed") {
    lifecycleStateName = t("partview-dashboard:Delayed");
  }

  if (lifecycleType === "delivered") {
    lifecycleStateName = t("partview-dashboard:Delivered");
  }

  if (lifecycleType === "available_for_pickup") {
    lifecycleStateName = t("partview-dashboard:Available for Pickup");
  }

  return lifecycleStateName ? lifecycleStateName : null;
};

export const getFormattedLifecycleObject = (lifecycleObject, t) => {
  let result;
  if (!lifecycleObject) {
    return [];
  } else {
    result = Object.entries(lifecycleObject).map((lifecycleCount) => {
      return {
        type: getLifeCycleStateTranslatedKeys(lifecycleCount[0], t),
        count: lifecycleCount[1],
      };
    });
  }

  return result ? result.filter((keys) => keys.type !== null) : null;
};
