/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text } from "components/atoms/Text.atom";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";

const CheckboxOption = (props) => (
  <li
    ref={props.innerProps.ref}
    {...props.innerProps}
    className={props.selectProps.className}
    css={{
      fontSize: "0.8em",
      padding: "0.25em 0.5em;",
      backgroundColor: props.isDisabled
        ? Colors.background.LIGHT_GRAY
        : props.isSelected
        ? "#EBF3FF"
        : props.isFocused
        ? "#DEEBFF"
        : "#fff",
      color: props.isDisabled
        ? "#aaa"
        : props.isSelected
        ? "#333"
        : props.isFocused
        ? "#333"
        : "#6d6d6d",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      cursor: "pointer",
    }}
  >
    <Checkbox
      id={props.label}
      checked={props.isSelected}
      css={{
        padding: "0.3em",
      }}
    />
    <Text
      as="label"
      htmlFor={props.label}
      style={{ cursor: "pointer", marginBottom: 0 }}
    >
      {props.label}
    </Text>
  </li>
);

export const RouteManagementAsyncSelect = ({
  loadOptions,
  onInputChange,
  values,
  onChange,
  inputValue,
  isDisabled,
  closeMenuOnSelect,
  menuPosition,
}) => {
  return (
    <AsyncPaginate
      isDisabled={isDisabled}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: CheckboxOption,
      }}
      isMulti={true}
      value={values}
      loadOptions={async (currentVal, options, additionalOptions) => {
        const data = await loadOptions(
          currentVal,
          additionalOptions && currentVal === additionalOptions?.prevValue
            ? additionalOptions.pageNumber + 1
            : 0,
        );
        return data;
      }}
      onChange={onChange}
      closeMenuOnSelect={closeMenuOnSelect}
      menuPosition={menuPosition}
      onInputChange={onInputChange}
      cacheUniqs={[inputValue]}
      hideSelectedOptions={false}
    />
  );
};

RouteManagementAsyncSelect.propTypes = {
  loadOptions: PropTypes.func,
  onInputChange: PropTypes.func,
  values: PropTypes.array,
  onChange: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool,
  menuPosition: PropTypes.oneOf(["fixed", "absolute"]),
};
