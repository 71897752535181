import apiUrl from "api-url";
import moment from "moment";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "damageViewDashboardMySubmissionWidget";

//urls
const APPLICATION_BASE_URL = apiUrl("/damageview/");

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

const fetchMySubmissionsSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
) => {
  const url =
    APPLICATION_BASE_URL +
    `submission?searchVersion=mySubmissions&${queryString}`;
  const config = axiosConfig();
  dispatch(duck.fetch(url, config));
};

const DamageViewMySubmissionsWidgetState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  [],
  fetchMySubmissionsSearch,
  [],
  {
    defaultSort: "submission_date",
    reverseSort: false,
  },
  10,
);

export default DamageViewMySubmissionsWidgetState;
