import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
import {
  getBasicQueryString,
  getMultiSelectQueryString,
} from "components/search-bar/search-filter-query-strings";
import { vinEtaValidatorVinOptionsState } from "../redux/VinEtaValidatorFilterLoaders";
import { SelectListSize } from "components/molecules/SearchableSelectList.molecule";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: (t) => t("fv-vin-search:VIN"),
    placeholder: (t) => t("fv-vin-search:Search VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
  },
];

export const FILTERS = [
  {
    queryKey: "entityId",
    Component: AsyncSelectFilterButton,
    isMulti: false,
    label: (t) => t("vin-eta-validator:VIN"),
    optionsState: vinEtaValidatorVinOptionsState,
    queryBuilder: getMultiSelectQueryString,
    selectListSize: SelectListSize.LARGE,
    hideSelectAll: true,
    hideSelectEmpty: true,
    hideFuzzySearch: true,
    prerequisiteForSearchAndFilters: true,
  },
];
