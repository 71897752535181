/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import PageHeader from "./documentation-styled-components/PageHeader";
import URLs from "./documentation-styled-components/URLs";
import SampleNav from "./documentation-styled-components/SampleNav";
import SampleParameters from "./documentation-styled-components/SampleParameters";
import SampleBody from "./documentation-styled-components/SampleBody";
import SampleRequests from "./documentation-styled-components/SampleRequests";
import SampleResponses from "./documentation-styled-components/SampleResponses";
import SampleNotes from "./documentation-styled-components/SampleNotes";
import SampleLinks from "./documentation-styled-components/SampleLinks";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

const apiSampleCss = {
  padding: "1em 200px 1em 1em",
};

export const ApiSampleView = ({
  isLoading,
  apiSample,
  filename,
  fetchApiSample,
}) => {
  const { t } = useTranslation(["documentation", "documentation-remote"]);

  useSetTitleOnMount(t("documentation:Training & Resources"));

  useTrackWithMixpanelOnce("Viewed Page: Docs / API Sample");

  const api = apiSample?.Api?.[0] ?? null;

  useEffect(() => {
    fetchApiSample(filename);
  }, [fetchApiSample, filename]);

  return (
    <div css={apiSampleCss}>
      <Loader loaded={!isLoading}>
        {api && (
          <React.Fragment>
            <SampleNav documentation={api.Documentation} />
            <PageHeader
              title={
                t("documentation:API Reference") +
                " - " +
                t(`documentation-remote:${api.ApiName || ""}`)
              }
              description={t(
                `documentation-remote:${api.Documentation.Description}`,
              )}
            />
            <URLs
              title={t("documentation:URLs")}
              servers={api.Server}
              method={api.Method}
              path={api.Path}
            />
            <SampleParameters
              id="headers"
              title={t("documentation:Request Headers")}
              params={api.Documentation["Header Parameters"]}
            />
            <SampleParameters
              id="parameters"
              title={t("documentation:Request Parameters")}
              params={api.Documentation.Parameters}
            />
            {api.Documentation["Request Body"] && (
              <SampleBody body={api.Documentation["Request Body"]} />
            )}
            {api.Documentation["Request Sample"] && (
              <SampleRequests sample={api.Documentation["Request Sample"]} />
            )}
            {api.Documentation["Response Sample"] && (
              <SampleResponses sample={api.Documentation["Response Sample"]} />
            )}
            {Object.keys(api.Documentation["Usage Notes"]).length > 0 && (
              <SampleNotes notes={api.Documentation["Usage Notes"]} />
            )}
            {Object.keys(api.Documentation["Extranl Docs"]).length > 0 && (
              <SampleLinks links={api.Documentation["Extranl Docs"]} />
            )}
          </React.Fragment>
        )}
      </Loader>
    </div>
  );
};

ApiSampleView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  apiSample: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      Api: PropTypes.array,
    }),
  ]).isRequired,
  filename: PropTypes.string.isRequired,
  fetchApiSample: PropTypes.func.isRequired,
};
