import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import apiUrl from "api-url";

const PartViewSubscriptionState = buildSubscriptionState({
  topic: "PartView",
  systemType: "partview",
  getSubscribeeId: (subscribee) => subscribee.TrackingNumber,
  getUrl: (id) => apiUrl(`/partview/app/package-container/${id}`),
  getAdditionalRequestConfig: (requestType, subscribee) => {
    const config = {};

    if (requestType !== "FETCH_SUBSCRIPTION") {
      config.data = { owner_solution_id: subscribee?.OwnerSolutionId };
    }

    return config;
  },
});

export default PartViewSubscriptionState;
