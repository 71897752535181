import axios from "axios";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";

import RolesState from "modules/roles/RolesState";
import UsersSearchBarState from "./UsersSearchBarState";

const STORE_MOUNT_POINT = "users";

// URLS
const USERS_URL = apiUrl("/iam/users");
const getUserDetailsUrl = (orgId, userId) => {
  return apiUrl(`/iam/organizations/${orgId}/members/${userId}`);
};

// Actions

export const UsersActionStatus = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_ACTION_STATUS: "CLEAR_ACTION_STATUS",
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCEEDED: "ADD_USER_SUCCEEDED",
  ADD_USER_FAILED: "ADD_USER_FAILED",
  ADD_USER_IN_PROGRESS: "ADD_USER_IN_PROGRESS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCEEDED: "UPDATE_USER_SUCCEEDED",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  UPDATE_USER_IN_PROGRESS: "UPDATE_USER_IN_PROGRESS",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCEEDED: "DELETE_USER_SUCCEEDED",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  DELETE_USER_IN_PROGRESS: "DELETE_USER_IN_PROGRESS",
  RESET_PASSWORD_USER: "RESET_PASSWORD_USER",
  RESET_PASSWORD_USER_SUCCEEDED: "RESET_PASSWORD_USER_SUCCEEDED",
  RESET_PASSWORD_USER_FAILED: "RESET_PASSWORD_USER_FAILED",
  SET_PASSWORD_USER: "SET_PASSWORD_USER",
  SET_PASSWORD_USER_SUCCEEDED: "SET_PASSWORD_USER_SUCCEEDED",
  SET_PASSWORD_USER_FAILED: "SET_PASSWORD_USER_FAILED",
  SET_PASSWORD_USER_IN_PROGRESS: "SET_PASSWORD_USER_IN_PROGRESS",
  IMPORT_USERS: "IMPORT_USERS",
  IMPORT_USERS_SUCCEEDED: "IMPORT_USERS_SUCCEEDED",
  IMPORT_USERS_FAILED: "IMPORT_USERS_FAILED",
  USER_PASSWORD_RESET_FAILED: "USER_PASSWORD_RESET_FAILED",
  USER_PASSWORD_SET_FAILED: "USER_PASSWORD_SET_FAILED",
  USER_PASSWORD_SET_FAILED_INVALID: "USER_PASSWORD_SET_FAILED_INVALID",
  USER_PASSWORD_SET: "USER_PASSWORD_SET",
  USER_PASSWORD_RESET: "USER_PASSWORD_RESET",
  USER_PASSWORD_RESET_FAILED_INVALID: "USER_PASSWORD_RESET_FAILED_INVALID",
  RESET_PASSWORD_USER_IN_PROGRESS: "RESET_PASSWORD_USER_IN_PROGRESS",
  DUPLICATE_USER: "DUPLICATE_USER",
  UNKNOWN_USER_ERROR: "UNKNOWN_USER_ERROR",
  USERS_IMPORTED: "USERS_IMPORTED",
  PERMISSION_ERROR: "PERMISSION_ERROR",
  IMPORT_ERROR: "IMPORT_ERROR",
};

const userDetailsDuck = buildFetchDuck(STORE_MOUNT_POINT, "userDetails");
const damageViewLocationsDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "damageViewLocations",
);

// Action creators
function fetchUserDetails(orgId, userId) {
  return (dispatch) => {
    const url = getUserDetailsUrl(orgId, userId);
    dispatch(userDetailsDuck.fetch(url));
  };
}

function fetchDamageViewLocations(userEmail) {
  return (dispatch) => {
    const url = apiUrl(
      `/damageview/locations/assignee?email=${encodeURIComponent(userEmail)}`,
    );
    dispatch(damageViewLocationsDuck.fetch(url));
  };
}

function clearOrganizationMemberDetails() {
  return (dispatch) => {
    dispatch(userDetailsDuck.clear());
  };
}

function setCurrentUser(user) {
  return (dispatch) => {
    return dispatch({
      type: UsersActionStatus.SET_CURRENT_USER,
      currentUser: user,
    });
  };
}

function clearActionStatus() {
  return {
    type: UsersActionStatus.CLEAR_ACTION_STATUS,
  };
}

function addUser(payload) {
  return (dispatch) => {
    dispatch({ type: UsersActionStatus.ADD_USER, payload });
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({ type: UsersActionStatus.ADD_USER_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.ADD_USER_FAILED, error });
      });
  };
}

function updateUser(userId, payload) {
  return (dispatch) => {
    dispatch({ type: UsersActionStatus.UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({
          type: UsersActionStatus.UPDATE_USER_SUCCEEDED,
          userId,
          payload,
        });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.UPDATE_USER_FAILED, userId, error });
      });
  };
}

function deleteUser(user, organizationId) {
  return (dispatch) => {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId,
    };

    dispatch({ type: UsersActionStatus.DELETE_USER, userId, payload });
    const url = USERS_URL + `/${userId}`;
    return axios
      .delete(url, { data: payload })
      .then((resp) => {
        dispatch({ type: UsersActionStatus.DELETE_USER_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.DELETE_USER_FAILED, userId, error });
      });
  };
}

function blockUser(user, organizationId) {
  return (dispatch) => {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId,
    };
    dispatch({ type: UsersActionStatus.UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}/block-user`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({
          type: UsersActionStatus.UPDATE_USER_SUCCEEDED,
          userId,
          payload,
        });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.UPDATE_USER_FAILED, userId, error });
      });
  };
}

function unblockUser(user, organizationId) {
  const userId = user.user_id;
  return (dispatch) => {
    const payload = {
      organization_id: organizationId,
    };
    dispatch({ type: UsersActionStatus.UPDATE_USER, userId, payload });
    const url = USERS_URL + `/${userId}/unblock-user`;
    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({
          type: UsersActionStatus.UPDATE_USER_SUCCEEDED,
          userId,
          payload,
        });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.UPDATE_USER_FAILED, userId, error });
      });
  };
}

function resetPassword(user) {
  const userId = user.user_id;
  return (dispatch) => {
    const payload = {
      email: user.email,
    };
    dispatch({ type: UsersActionStatus.RESET_PASSWORD_USER, userId, payload });
    const url = apiUrl("/iam") + `/password-change`;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({
          type: UsersActionStatus.RESET_PASSWORD_USER_SUCCEEDED,
          payload,
        });
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.RESET_PASSWORD_USER_FAILED, error });
      });
  };
}

function setUserPassword(userId, newPassword) {
  return (dispatch) => {
    const payload = { password: newPassword };
    dispatch({ type: UsersActionStatus.SET_PASSWORD_USER, payload });

    const url = `${apiUrl("/iam/users")}/${userId}/change-password`;

    return axios
      .patch(url, payload)
      .then((resp) => {
        dispatch({ type: UsersActionStatus.SET_PASSWORD_USER_SUCCEEDED });
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.SET_PASSWORD_USER_FAILED, error });
      });
  };
}

function importUsers(payload) {
  return (dispatch) => {
    dispatch({ type: UsersActionStatus.IMPORT_USERS, payload });
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then((resp) => {
        dispatch({ type: UsersActionStatus.IMPORT_USERS_SUCCEEDED });
        dispatch(
          UsersSearchBarState.actionCreators.searchEntities(null, false),
        );
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch((error) => {
        dispatch({ type: UsersActionStatus.IMPORT_USERS_FAILED, error });
      });
  };
}

// Selectors

// This selector is exported to avoid a circular dependency when importing this file.
// This file imports UsersSearchBarState which imports SeachBarStateBuilder -> AuthorizationSelectors -> UsersState.
// TODO: Can we find some way to remove the export?
export function getCurrentUser(state) {
  return state.users.currentUser;
}

function getUserDetailsRequestData(state) {
  return userDetailsDuck.selectors.getData(state);
}

function getDamageViewLocations(state) {
  return damageViewLocationsDuck.selectors.getData(state)?.data ?? [];
}

function getDamageViewLocationsLoading(state) {
  return damageViewLocationsDuck.selectors.getData(state)?.isLoading ?? false;
}

const initialState = {
  actionStatus: null,
  currentUser: null,
};

function UsersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UsersActionStatus.SET_PASSWORD_USER:
      return {
        ...state,
        actionStatus: UsersActionStatus.SET_PASSWORD_USER_IN_PROGRESS,
      };

    case UsersActionStatus.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };

    case UsersActionStatus.ADD_USER:
      return {
        ...state,
        actionStatus: UsersActionStatus.ADD_USER_IN_PROGRESS,
      };
    case UsersActionStatus.ADD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.ADD_USER_SUCCEEDED,
      };
    case UsersActionStatus.ADD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 409
            ? UsersActionStatus.DUPLICATE_USER
            : UsersActionStatus.UNKNOWN_USER_ERROR,
      };
    case UsersActionStatus.DELETE_USER:
      return {
        ...state,
        actionStatus: UsersActionStatus.DELETE_USER_IN_PROGRESS,
      };

    case UsersActionStatus.DELETE_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.DELETE_USER_SUCCEEDED,
      };
    case UsersActionStatus.DELETE_USER_FAILED:
      return {
        ...state,
        actionStatus: UsersActionStatus.DELETE_USER_FAILED,
      };
    case UsersActionStatus.UPDATE_USER:
      return {
        ...state,
        actionStatus: UsersActionStatus.UPDATE_USER_IN_PROGRESS,
      };
    case UsersActionStatus.UPDATE_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.UPDATE_USER_SUCCEEDED,
      };
    case UsersActionStatus.UPDATE_USER_FAILED:
      return {
        ...state,
        actionStatus: UsersActionStatus.UPDATE_USER_FAILED,
      };
    case UsersActionStatus.IMPORT_USERS_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.USERS_IMPORTED,
      };
    case UsersActionStatus.IMPORT_USERS_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          (action.error.response.status === 400 ||
            action.error.response.status === 403 ||
            action.error.response.status === 500)
            ? action.error.response.status === 403
              ? UsersActionStatus.PERMISSION_ERROR
              : UsersActionStatus.IMPORT_ERROR
            : null,
      };
    case UsersActionStatus.CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
      };

    case UsersActionStatus.RESET_PASSWORD_USER:
      return {
        ...state,
        actionStatus: UsersActionStatus.RESET_PASSWORD_USER_IN_PROGRESS,
      };

    case UsersActionStatus.SET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.USER_PASSWORD_SET,
      };
    case UsersActionStatus.RESET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: UsersActionStatus.USER_PASSWORD_RESET,
      };
    case UsersActionStatus.RESET_PASSWORD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error?.response?.status === 401
            ? UsersActionStatus.PERMISSION_ERROR
            : action.error?.response?.status === 400
            ? UsersActionStatus.USER_PASSWORD_RESET_FAILED_INVALID
            : UsersActionStatus.USER_PASSWORD_RESET_FAILED,
      };
    case UsersActionStatus.SET_PASSWORD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error?.response?.status === 401
            ? UsersActionStatus.PERMISSION_ERROR
            : UsersActionStatus.USER_PASSWORD_SET_FAILED_INVALID,
      };
    default:
      return state;
  }
}

const UsersState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchUserDetails,
    fetchDamageViewLocations,
    clearOrganizationMemberDetails,
    setCurrentUser,
    clearActionStatus,
    addUser,
    updateUser,
    deleteUser,
    blockUser,
    unblockUser,
    resetPassword,
    setUserPassword,
    importUsers,
  },
  selectors: {
    getCurrentUser,
    getUserDetailsRequestData,
    getDamageViewLocations,
    getDamageViewLocationsLoading,
  },
  reducer: chainReducers([
    UsersReducer,
    userDetailsDuck.reducer,
    damageViewLocationsDuck.reducer,
  ]),
};

export default UsersState;
