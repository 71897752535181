/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, FormControl, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import styled from "@emotion/styled";

import Colors from "styles/colors";
import { FlexColDiv } from "styles/container-elements";

const FormRow = styled.div(
  {
    display: "flex",
    flexFlow: "row wrap",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  ({ divider }) => ({
    borderBottom: divider ? `1px solid ${"#ddd"}` : "none",
    marginBottom: divider ? `1em` : "none",
  }),
);

export const EditShipmentRow = ({
  label,
  value,
  valueKey,
  isTime,
  systemValue,
  updateField,
  isSubmitLoading = false,
}) => {
  let dateValue = value;

  // String is an invalid type to pass the date time picker
  // null it out, the only string we might see is an empty
  // string.
  if (typeof value === "string") {
    dateValue = null;
  }

  return (
    <FormRow>
      <div
        css={{
          height: "2.5em",
          margin: "0.2em",
          marginTop: "0.5em",
          width: "8em",
        }}
      >
        {label}
      </div>
      {systemValue && (
        <div
          css={{
            height: "2.5em",
            margin: "0.2em",
            marginTop: "0.5em",
            width: "18em",
            fontWeight: "bold",
          }}
        >
          {systemValue}
        </div>
      )}
      {!systemValue && !isTime && (
        <FormControl
          style={{
            backgroundColor: "white",
            width: "18em",
            height: "2.5em",
            boxShadow: "none",
            margin: "0.2em",
          }}
          type="text"
          value={value}
          placeholder=""
          onChange={(evt) => updateField(valueKey, evt.target.value)}
        />
      )}
      {!systemValue && isTime && (
        <DateTimePicker
          disabled={isSubmitLoading}
          style={{ width: "18em", margin: "0.2em" }}
          onChange={(value) => updateField(valueKey, value)}
          value={dateValue}
        />
      )}
    </FormRow>
  );
};

EditShipmentRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  valueKey: PropTypes.string.isRequired,
  isTime: PropTypes.bool,
  systemValue: PropTypes.string,
  updateField: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
};

export const AddAssetModal = ({
  show,
  hide,
  shipment,
  assignAsset,
  assignTrailer,
  organization,
}) => {
  const { t } = useTranslation("shipment-details");

  const [data, setData] = useState({
    assetID: "",
    trailerNumber: "",
    effectiveDate: "",
  });

  const [noteValue, setNoteValue] = useState("");

  const initialData = useRef(data);

  useEffect(() => {
    setData({
      assetID: "",
      trailerNumber: "",
      effectiveDate: "",
    });
    setNoteValue("");
  }, [show]);

  const handleOnEnter = () => {
    setData(data);
    initialData.current = data;
  };

  const handleOnChangeNote = (evt) => {
    setNoteValue(evt.target.value);
  };

  const updateField = (field, val) => {
    setData({
      ...data,
      [field]: val,
    });
  };
  const userChangedData = !_.isEqual(initialData, data);

  return (
    <Modal show={show} onHide={() => hide()} onEnter={handleOnEnter}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("shipment-details:Assign Asset/Trailer")}
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <FlexColDiv
          css={{
            padding: "1em",
          }}
        >
          <EditShipmentRow
            label={t("shipment-details:Asset ID")}
            value={data.assetID}
            valueKey="assetID"
            updateField={updateField}
            isTime={false}
            systemValue={null}
          />
          <EditShipmentRow
            label={t("shipment-details:Effective Date")}
            value={data.effectiveDate}
            valueKey="effectiveDate"
            updateField={updateField}
            isTime={true}
            systemValue={null}
          />
          <EditShipmentRow
            label={t("shipment-details:Trailer Number")}
            value={data.trailerNumber}
            valueKey="trailerNumber"
            updateField={updateField}
            isTime={false}
            systemValue={null}
          />
        </FlexColDiv>
        <FlexColDiv>
          <FormLabel>{`${t("shipment-details:Notes")}:`}</FormLabel>
          <FormControl
            as="textarea"
            placeholder={t(
              "shipment-details:Enter notes about the change here",
            )}
            value={noteValue}
            onChange={handleOnChangeNote}
            style={{ resize: "none" }}
          />
        </FlexColDiv>
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => hide()}
        >
          {t("shipment-details:Cancel")}
        </Button>
        <Button
          disabled={!userChangedData}
          style={{
            backgroundColor: Colors.highlight.GREEN,
            color: "white",
            fontWeight: "300",
          }}
          onClick={() => {
            // If user assigned a new asset, call that endpoint
            // DEV-1407 Don't call endpoint when asset_id is blank
            if (data.assetID && data.assetID !== "") {
              assignAsset(
                shipment,
                organization,
                data.assetID,
                data.effectiveDate,
                noteValue,
              );
            }
            if (data.trailerNumber) {
              assignTrailer(shipment, data.trailerNumber);
            }
            hide();
          }}
        >
          {t("shipment-details:Submit")}
        </Button>{" "}
      </Modal.Footer>
    </Modal>
  );
};

AddAssetModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  shipment: PropTypes.object,
  assignAsset: PropTypes.func,
  assignTrailer: PropTypes.func,
  organization: PropTypes.object,
};
