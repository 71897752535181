const STORE_MOUNT_POINT = "federatedPreferenceModal";

// Actions
const UPDATE_POPUP_SHOW = `${STORE_MOUNT_POINT}/UPDATE_POPUP_SHOW`;

// Action Creators
function setPopupShow(show) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_POPUP_SHOW,
      payload: { show },
    });
  };
}

// Selectors
const getPopupShow = (state) =>
  state.federatedPreferenceModal?.popupShow ?? false;

// Reducer
const initialState = {
  popupShow: false,
};

function PreferenceModalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_POPUP_SHOW:
      return {
        ...state,
        popupShow: action.payload.show,
      };
    default:
      return state;
  }
}

const FederatedUserPreferenceModalState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { setPopupShow },
  selectors: { getPopupShow },
  reducer: PreferenceModalReducer,
};

export default FederatedUserPreferenceModalState;
