import { connect } from "react-redux";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { PartViewDashboard } from "./PartView.Dashboard.page";
import PartViewDashboardState from "../redux/PartViewDashboardState";
import PartViewSearchBarState from "../redux/PartViewSearchBarState";

function mapStateToProps(state) {
  const {
    getExceptionsListRequestData,
    getExceptionCountsRequestData,
    getWatchedTableIsLoading,
    getWatchedTableResults,
    getWatchedTablePageIndex,
    getWatchedTablePageSize,
    getWatchedTablePageCount,
  } = PartViewDashboardState.selectors;

  return {
    exceptionsListRequestData: getExceptionsListRequestData(state),
    exceptionOrPackageStatusCountsRequestData:
      getExceptionCountsRequestData(state),
    watchedTableIsLoading: getWatchedTableIsLoading(state),
    watchedTableResults: getWatchedTableResults(state),
    watchedTablePageIndex: getWatchedTablePageIndex(state),
    watchedTablePageSize: getWatchedTablePageSize(state),
    watchedTablePageCount: getWatchedTablePageCount(state),
    includeAPU: getAuthorization(state).validateEntitySystemConfigValue([
      { key: "AVAILABLE_FOR_PICKUP", value: "true" },
    ]),
  };
}

const {
  fetchExceptionList,
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  setWatchedTablePageIndex,
} = PartViewDashboardState.actionCreators;
const { searchEntities, setSearchFilter, clearSearchFilters, resetSearchBar } =
  PartViewSearchBarState.actionCreators;

const actionCreators = {
  fetchExceptionList,
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  setWatchedTablePageIndex,
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
};

export default connect(mapStateToProps, actionCreators)(PartViewDashboard);
