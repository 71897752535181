/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { MediaQueries } from "components/responsive";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { TextInput } from "components/atoms/TextInput.atom";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useNotAllSuccessfulColumns } from "pages/driveaway/components/search/DriveAway.NotAllSuccessful.columns";
import { VinHistorySearch } from "pages/driveaway/dashboard/components/vin-history-search/DriveAway.VinHistory.Search.js";
import VinHistorySearchBarContainer from "./components/DPU.VinHistory.SearchBar.container";
import { useColumns as useVinHistoryColumns } from "./components/DPU.VinHistory.columns";

const style = {
  display: "flex",
  flexDirection: "column",
  marginRight: "1em",
  marginBottom: "1em",
  minWidth: "20em",
};

const adminActionOptions = [
  {
    name: "Cancel DPU Request",
    code: "CANCEL_REQUEST",
    description: "Cancel a submitted or an approved VIN",
  },
  {
    name: "Override a VIN / Make available",
    code: "OVERRIDE_VIN",
    description: "Make a VIN available for pick up on DPU at any time",
  },
];

const csvTemplate = {
  fileName: `template-dpu-action-${Date.now()}.csv`,
  headers: ["External Id", "Action", "Location Code", "Carrier SCAC"],
  data: [
    ["VIN1", "CANCEL_REQUEST"],
    ["VIN2", "OVERRIDE_VIN", "Location1", "SCAC1"],
    ["VIN3", "OVERRIDE_VIN", "Location2", "SCAC2"],
    ["VIN4", "OVERRIDE_VIN", "Location3", "SCAC3"],
  ],
};

const vinRegex = new RegExp(`[A-Z0-9]{17}`);

const initialState = {
  vin: "",
  locationCode: "",
  scac: "",
  selectedAction: null,
  importFileCsvData: "",
  showActionDropdown: false,
  singleActionSubmitBtnDisabled: true,
  bulkActionSubmitBtnDisabled: true,
  showResultModal: false,
};

export const DPUAdminTool = ({
  actionStatus,
  clearActionStatus,
  submitAction,
  requestResults,
  submitBulkAction,
  solutionId,
  dpuVinHistorySearchResults,
  isVinHistoryLoading,
  isVinHistoryLoadingError,
  vinHistoryLoadingError,
  resetVinHistorySearch,
  clearVinHistoryEntities,
}) => {
  const [vin, setVin] = useState(initialState.vin);
  const [locationCode, setLocationCode] = useState(initialState.locationCode);
  const [scac, setScac] = useState(initialState.scac);
  const [selectedAction, setSelectedAction] = useState(
    initialState.selectedAction,
  );
  const [singleActionSubmitBtnDisabled, setSingleActionSubmitBtnDisabled] =
    useState(initialState.singleActionSubmitBtnDisabled);
  const [importFile, setImportFile] = useState(null);
  const [importFileCsvData, setImportFileCsvData] = useState(
    initialState.importFileCsvData,
  );
  const [bulkActionSubmitBtnDisabled, setBulkActionSubmitBtnDisabled] =
    useState(initialState.bulkActionSubmitBtnDisabled);
  const [showActionDropdown, setShowActionDropdown] = useState(
    initialState.showActionDropdown,
  );
  const [showResultModal, setshowResultModal] = useState(
    initialState.showResultModal,
  );

  const columns = useNotAllSuccessfulColumns();

  const vinHistoryColumns = useVinHistoryColumns();

  useEffect(() => {
    return () => {
      resetVinHistorySearch(true);
      clearVinHistoryEntities();
    };
  }, []);

  useEffect(() => {
    if (importFileCsvData?.length > 0) {
      setBulkActionSubmitBtnDisabled(false);
    } else {
      setBulkActionSubmitBtnDisabled(true);
    }
  }, [importFileCsvData]);

  useEffect(() => {
    setScac(initialState.scac);
    setLocationCode(initialState.locationCode);
  }, [selectedAction]);

  useEffect(() => {
    let isSubmitDisabled = true;
    if (vinRegex.test(vin) && !_.isNil(selectedAction)) {
      if (selectedAction.code === "OVERRIDE_VIN") {
        if (!_.isEmpty(locationCode.trim()) && !_.isEmpty(scac.trim())) {
          isSubmitDisabled = false;
        }
      } else {
        isSubmitDisabled = false;
      }
    }
    setSingleActionSubmitBtnDisabled(isSubmitDisabled);
  }, [vin, locationCode, scac, selectedAction]);

  const { t } = useTranslation("internal-tools");
  useSetTitleOnMount(t("internal-tools:DPU Admin Tool"));

  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  const onBulkSubmitHandler = () => {
    setshowResultModal(true);
    const body = {
      csvData: importFileCsvData,
    };
    submitBulkAction(solutionId, body);
  };

  const onSingleSubmitHandler = () => {
    setshowResultModal(true);
    const body = {
      action: selectedAction.code,
      externalId: vin,
      locationCode: locationCode,
      scac: scac,
    };
    submitAction(solutionId, body);
  };

  const csvTooltip = (
    <Fragment>
      <Text>{`${t("internal-tools:The file must contain")}:`}</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "nowrap",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:External Id")}</li>
        <li>{t("internal-tools:Action")}</li>
        <li>{t("internal-tools:Location Code")}</li>
        <li>{t("internal-tools:Carrier SCAC")}</li>
      </ul>
    </Fragment>
  );

  return (
    <Fragment>
      <div css={{ padding: "1em" }}>
        <div css={{ display: "flex", flexDirection: "column", padding: "1em" }}>
          <Text size={FontSize.size18} bold>
            {t("internal-tools:Bulk Action")}
          </Text>
          <Text block={true} style={{ marginBottom: "1em" }}>
            {t(
              "internal-tools:Apply different actions to different VINs using a csv file",
            )}
            {":"}
          </Text>
          <div css={{ display: "flex", marginBottom: "1em" }}>
            <div style={{ marginRight: "1em" }}>
              <Text>{t("internal-tools:Select a CSV file to import")}.</Text>{" "}
              <Tooltip placement="top" tooltipChildren={csvTooltip}>
                <Icon src={faQuestionCircle} />
              </Tooltip>
            </div>

            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
            />
          </div>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-light">
                  {t("internal-tools:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onFileLoad;
                      setImportFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                type="type"
                value={importFile?.name ?? ""}
                placeholder={t("internal-tools:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>
          <Button
            variant="primary"
            disabled={bulkActionSubmitBtnDisabled}
            onClick={onBulkSubmitHandler}
            style={{ width: "fit-content" }}
          >
            {t("internal-tools:Upload")}
          </Button>
        </div>
        <hr />
        <div id="singleAction">
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              padding: "1em",
            }}
          >
            <Text size={FontSize.size18} bold>
              {t("internal-tools:Single Action")}
            </Text>
            <Text block={true} style={{ marginBottom: "1em" }}>
              {t(
                "internal-tools:Select an action from the drop down menu to apply it to one VIN",
              )}
              {":"}
            </Text>
            <Text>{t("internal-tools:Action")}</Text>
            <Dropdown
              responsiveModalMenu
              show={showActionDropdown}
              variant="outline-secondary"
              text={
                !_.isNil(selectedAction?.name)
                  ? selectedAction.name
                  : t("internal-tools:Select an action")
              }
              onToggle={(isShown) => {
                setShowActionDropdown(isShown);
              }}
              alignMenuRight={false}
            >
              {adminActionOptions?.map((action) => (
                <Dropdown.Item
                  key={`key.${action.code}`}
                  onClick={() => {
                    setSelectedAction(action);
                  }}
                >
                  <Text>{action.name}</Text>
                </Dropdown.Item>
              ))}
            </Dropdown>
            <div
              css={{
                display: "flex",
                marginTop: "1em",
                flexGrow: "1",
                [MediaQueries.smallAndDown]: {
                  flexDirection: "column",
                },
              }}
            >
              <div css={style}>
                <Text>{t("internal-tools:VIN")}</Text>
                <TextInput onChange={setVin} maxLength={17}></TextInput>
              </div>
              {selectedAction?.code === "OVERRIDE_VIN" ? (
                <Fragment>
                  <div css={style}>
                    <Text>{t("internal-tools:Location Code")}</Text>
                    <TextInput onChange={setLocationCode}></TextInput>
                  </div>
                  <div css={style}>
                    <Text>{t("internal-tools:SCAC")}</Text>
                    <TextInput onChange={setScac} maxLength={10}></TextInput>
                  </div>
                </Fragment>
              ) : null}
            </div>
            <Button
              variant="primary"
              disabled={singleActionSubmitBtnDisabled}
              onClick={onSingleSubmitHandler}
              style={{ width: "fit-content", marginTop: "1em" }}
            >
              {t("internal-tools:Submit")}
            </Button>
          </div>
        </div>
        <hr />
        <div>
          <div css={{ padding: "1em" }}>
            <Text block bold size={FontSize.size18}>
              {t("internal-tools:DPU VIN History - Beta")}
            </Text>
            <Text block>
              {t(
                "internal-tools:Search a VIN to see the DPU lifecycle updates for a VIN.",
              )}
            </Text>
            <Text block>
              {t(
                "internal-tools:These tables are currently being refined so the information displayed may differ from what is seen in the Dealer Pickup portal",
              )}
              :
            </Text>
          </div>
          <VinHistorySearch
            isLoading={isVinHistoryLoading}
            showError={isVinHistoryLoadingError}
            showErrorMessage={
              vinHistoryLoadingError?.response?.data?.error?.message
            }
            searchResults={dpuVinHistorySearchResults}
            SearchBarContainer={VinHistorySearchBarContainer}
            tableProps={{
              data: dpuVinHistorySearchResults,
              columns: vinHistoryColumns,
              isLoading: isVinHistoryLoading,
              showPagination: false,
            }}
          />
        </div>
      </div>
      <DialogModal
        style={{ width: "50%" }}
        show={showResultModal}
        onHide={() => {
          setshowResultModal(false);
          clearActionStatus();
        }}
        isLoading={actionStatus === "IN_PROGRESS"}
        loadStatus={"success"}
        title={t("driveaway-search:Bulk Action")}
        okButtonText={t("Close")}
        submitButtonVariant="primary"
        size="md"
        submitButtonDisabled={true}
        showCloseButton={false}
      >
        {actionStatus === "IN_PROGRESS" ? (
          <UploadInProgressModalBody t={t} />
        ) : null}
        {actionStatus === "SUCCESS" ? (
          <UploadAllSuccessModalBody t={t} />
        ) : null}
        {actionStatus === "NOT_ALL_SUCCESS" ? (
          <UploadNotAllSuccessModalBody
            t={t}
            columns={columns}
            requestResults={requestResults}
          />
        ) : null}
        {actionStatus === "FAILED" ? <UploadErrorModalBody t={t} /> : null}
      </DialogModal>
    </Fragment>
  );
};

const UploadAllSuccessModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faCheckCircle} color="green" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t(
              "internal-tools:Action(s) successfully completed for all VIN(s)",
            )}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

UploadAllSuccessModalBody.propTypes = {
  t: PropTypes.func,
};

const UploadErrorModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("internal-tools:There was an error. Please try again later.")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

UploadErrorModalBody.propTypes = {
  t: PropTypes.func,
};

const UploadNotAllSuccessModalBody = (props) => {
  const { t, columns, requestResults } = props;

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        <Text size={FontSize.size16}>
          {t("internal-tools:Action(s) was not successful for all VIN(s).")}
        </Text>
        <Text css={{ marginTop: "1em" }}>
          {t(
            "internal-tools:Please see the below list for VIN(s) that were not successful and the reasons why",
          )}
          {":"}
        </Text>
        <BaseTable
          theme={Themes.LIGHT}
          columns={columns}
          data={requestResults}
        />
      </div>
    </Fragment>
  );
};

UploadNotAllSuccessModalBody.propTypes = {
  t: PropTypes.func,
  columns: PropTypes.array,
  requestResults: PropTypes.array,
};

const UploadInProgressModalBody = (props) => {
  const { t } = props;

  return (
    <div css={{ margin: "2.5em 0" }}>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faSpinner} spin size={3} />
      </div>
      <Text
        css={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
        size={FontSize.size16}
      >
        {t("internal-tools:Processing request...")}
      </Text>
    </div>
  );
};

UploadInProgressModalBody.propTypes = {
  t: PropTypes.func,
};

DPUAdminTool.propTypes = {
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  requestResults: PropTypes.array,
  submitBulkAction: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  dpuVinHistorySearchResults: PropTypes.array.isRequired,
  isVinHistoryLoading: PropTypes.bool.isRequired,
  isVinHistoryLoadingError: PropTypes.bool,
  vinHistoryLoadingError: PropTypes.any,
  resetVinHistorySearch: PropTypes.func.isRequired,
  clearVinHistoryEntities: PropTypes.func.isRequired,
};

export default DPUAdminTool;
