import { useMutation as react_query_useMutation } from "@tanstack/react-query";

/**
 * A configuration object for `createMutationRequestHook`.
 *
 * @template TData The response body of a successful request.
 * @template TVariables An object passed to the `fetch` function.
 */
interface MutationRequestConfig<TData, TVariables> {
  fetch(variables: TVariables): Promise<TData>;
}

/**
 * The `createMutationRequest` function will return a React hook that manages
 * state of the request made with the provided `fetch` function.
 *
 * This uses `useMutation` from `@tanstack/react-query` under the hood.
 * Please refer [their documentation](https://tanstack.com/query/v5/docs/framework/react/reference/useMutation)
 * for more details.
 *
 * @template TData The response body of a successful request.
 * @template TError The response body of a failed request.
 * @template TVariables The variable object passed to the fetch function.
 */

export function createMutationRequestHook<TData, TError, TVariables>(
  config: MutationRequestConfig<TData, TVariables>,
) {
  return function useMutation() {
    return react_query_useMutation<TData, TError, TVariables>({
      mutationFn: config.fetch,
    });
  };
}
