import { connect } from "react-redux";

import { CarrierViewDashboard } from "./CarrierView.Dashboard.page";
import CarrierViewEntitiesState from "../redux/CarrierViewEntitiesState";
import CarrierViewSearchBarState from "../redux/CarrierViewSearchBarState";
import CarrierViewDomainDataState from "modules/domain-data/CarrierViewDomainData";

function mapStateToProps(state) {
  return {
    exceptionTypes:
      CarrierViewDomainDataState.selectors.getExceptionTypes(state),
    entityCount: CarrierViewEntitiesState.selectors.getEntityCount(state),
    isEntityCountLoading:
      CarrierViewEntitiesState.selectors.getEntityCountLoading(state),
    entityDeliveredCount:
      CarrierViewEntitiesState.selectors.getEntityDeliveredCount(state),
    isEntityDeliveredCountLoading:
      CarrierViewEntitiesState.selectors.getEntityCountDeliveredLoading(state),
    watchedVins: CarrierViewEntitiesState.selectors.getWatchedVins(state),
    isWatchedVinsLoading:
      CarrierViewEntitiesState.selectors.getWatchedVinsLoading(state),
    watchedVinsPageIndex:
      CarrierViewEntitiesState.selectors.getWatchedVinsPageIndex(state),
    watchedVinsPageSize:
      CarrierViewEntitiesState.selectors.getWatchedVinsPageSize(state),
    watchedVinsPageCount:
      CarrierViewEntitiesState.selectors.getWatchedVinsPageCount(state),
    showFilters:
      CarrierViewSearchBarState.selectors.getShowAdvancedSearch(state),
  };
}

const {
  fetchEntityCount,
  fetchEntityDeliveredCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  setWatchedVinsPageIndex,
} = CarrierViewEntitiesState.actionCreators;
const {
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
} = CarrierViewSearchBarState.actionCreators;

const actionCreators = {
  fetchEntityCount,
  fetchEntityDeliveredCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  setWatchedVinsPageIndex,
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
};

export default connect(mapStateToProps, actionCreators)(CarrierViewDashboard);
