/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import Loader from "react-loader";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { TabHeight, Tabs } from "components/molecules/Tabs.molecule";
import { MediaQueries } from "components/responsive";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { FlexRowDiv, FlexColDiv, FlexDiv } from "styles/container-elements";
import DetailItem from "modules/shipment-detail/shipment-detail-styled-components/DetailItem";
import Colors from "styles/colors";

const alertStyle = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  margin: "1em",
  "> p": {
    margin: 0,
  },
};

export const HealthcareDetailsPanel = (props) => {
  const {
    solutionId,
    isLoadingAssetDetails,
    assetDetails,
    assetDetailsError,
    isLoadingAssetCoordinates,
    assetCoordinates,
    assetCoordinatesError,
    addCoordinate,
    clearCoordinatesByType,
    selectedMapCoordinate,
    updateEntityPositionUpdate,
    clearUpdateEntityPositionUpdateError,
    isLoadingCoordinateUpdate,
    coordinateUpdateError,
  } = props;

  const { t } = useTranslation("healthcare");

  const { id, description } = assetDetails || {};

  return (
    <div
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        width: "100%",
        position: "relative",
        [MediaQueries.mediumAndUp]: {
          overflow: "auto",
        },
      }}
    >
      <Loader loaded={!isLoadingAssetDetails}>
        {assetDetails ? (
          <React.Fragment>
            <PanelGroup collapsible={true} style={{ margin: "1em" }}>
              <PanelGroup.Header title={t("healthcare:Details")} />
              <PanelGroup.Content>
                <FlexDiv
                  css={{
                    flexDirection: "column",
                    [MediaQueries.largeAndUp]: {
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    },
                  }}
                >
                  <FlexColDiv css={{ flex: "1 1 0", marginRight: "1em" }}>
                    <FlexRowDiv>
                      <DetailItem
                        label={t("healthcare:Asset Number")}
                        data-qa="text-healthcare-assetnumber"
                      >
                        {id}
                      </DetailItem>
                      <DetailItem
                        label={t("healthcare:Asset Description")}
                        data-qa="text-healthcare-assetdescription"
                      >
                        {description}
                      </DetailItem>
                    </FlexRowDiv>
                  </FlexColDiv>
                </FlexDiv>
              </PanelGroup.Content>
            </PanelGroup>
            <Loader loaded={!isLoadingAssetCoordinates}>
              <Tabs tabHeight={TabHeight.SHORT} css={{ padding: "1em" }}>
                <Tabs.TabList>
                  <Tabs.Tab data-qa="tab-button-coordinates">
                    {t("Coordinates")}
                  </Tabs.Tab>
                </Tabs.TabList>

                <Tabs.TabPanel data-qa="panel-coordinates">
                  {assetCoordinates && assetCoordinates.length > 0 ? (
                    <CoordinatesTable
                      coords={assetCoordinates}
                      addCoordinate={addCoordinate}
                      clearCoordinatesByType={clearCoordinatesByType}
                      selectedMapCoordinate={selectedMapCoordinate}
                      showIconColumn={true}
                      showReceivedTimeColumn={false}
                      showCityStateColumn={false}
                      showFloorColumn={true}
                      showVerifyColumn={true}
                      verifyColumnCellProps={{
                        updateEntityPositionUpdate,
                        clearUpdateEntityPositionUpdateError,
                        solutionId,
                        entityId: id,
                        isLoading: isLoadingCoordinateUpdate,
                        error: coordinateUpdateError,
                      }}
                      data-qa="healthcare"
                    />
                  ) : assetCoordinatesError ? (
                    <Alert variant="danger" css={alertStyle}>
                      <Alert.Heading>
                        {t(
                          `healthcare:An error occurred attempting to retrieve Asset Coordinates.`,
                        )}
                      </Alert.Heading>
                      <p data-qa="text-alert-error-coordinates">
                        {assetCoordinatesError}
                      </p>
                    </Alert>
                  ) : (
                    <Alert variant="warning" css={alertStyle}>
                      <p data-qa="text-alert-coordinates">
                        {t(`healthcare:No Asset Coordinates available.`)}
                      </p>
                    </Alert>
                  )}
                </Tabs.TabPanel>
              </Tabs>
            </Loader>
          </React.Fragment>
        ) : assetDetailsError ? (
          <Alert variant="danger" css={alertStyle}>
            <Alert.Heading>
              {t(
                `healthcare:An error occurred attempting to retrieve Asset Details.`,
              )}
            </Alert.Heading>
            <p data-qa="text-alert-error-details">{assetDetailsError}</p>
          </Alert>
        ) : (
          <Alert variant="warning" css={alertStyle}>
            <p data-qa="text-alert-details">
              {t(`healthcare:Please perform a search for an asset above.`)}
            </p>
          </Alert>
        )}
      </Loader>
    </div>
  );
};

HealthcareDetailsPanel.propTypes = {
  solutionId: PropTypes.any.isRequired,
  isLoadingAssetDetails: PropTypes.bool.isRequired,
  assetDetails: PropTypes.object,
  assetDetailsError: PropTypes.object,
  isLoadingAssetCoordinates: PropTypes.bool.isRequired,
  assetCoordinates: PropTypes.array,
  assetCoordinatesError: PropTypes.object,
  updateEntityPositionUpdate: PropTypes.func.isRequired,
  clearUpdateEntityPositionUpdateError: PropTypes.func.isRequired,
  isLoadingCoordinateUpdate: PropTypes.bool,
  coordinateUpdateError: PropTypes.object,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
};
