import _ from "lodash";

const Colors = {
  highlight: {
    GREEN: "#46b630",
    RED: "#d5473f",
    YELLOW: "#fcd800",
    ALERT_YELLOW: "#fbd20e",
    LIGHT_BLUE: "#2077a8",
    BRIGHT_BLUE: "#67B5FF",
    ORANGE: "#ffb500",
    LIGHT_GRAY: "#e9e9e9",
    DARK_GRAY: "#252525",
    DARK_TEAL: "#1a6886",
    LIGHT_YELLOW: "#ffec92",
    MEDIUM_LIGHT_GRAY: "#9B9B9B",
    MODERATE_YELLOW: "#504D2F",
  },
  icon: {
    YELLOW: "#dbb800",
    RED: "#d54e43",
    ORANGE: "#ff9334",
    GEOFENCE: "#f8d00d",
  },
  nav: {
    NAVY: "#2c3948",
  },
  notifications: {
    BACKGROUND: "#F2F2F2",
    BORDER: "#E9E9E9",
    BORDER_LEFT: "#EAEAEA",
    HAS_NOTIFICATIONS: "#E24420",
    PRIMARY_COLOR: "#546374",
    PRIMARY_COLOR_HOVER: "#21262d",
    SECONDARY_COLOR: "#B4B4B4",
    SEPARATOR: "#E0E0E0",
    ACTION_BLUE: "#007BFF",
    ACTION_RED: "#E24420",
    ERROR: "#CC4A4C",
  },
  text: {
    DARK_BLUE: "#081422",
    GRAY: "#8b8b8b",
    YELLOW: "#dbb800",
    LIGHT_GRAY: "#bac5cb",
    DIM_GRAY: "#6d6d6d",
    HIT_GRAY: "#acb5be",
    DARK_GRAY: "#333",
    DARKER_GRAY: "#222",
    LIGHTER_GRAY: "rgba(82, 82, 82, 0.5)",
    HEADER_ORANGE: "#de910b",
    BLUE_BLACK: "#273141",
    BLUE: "#0000FF",
    GREEN: "#46b630",
    RED: "#d5473f",
    VERY_LIGHT_GRAY: "#efefef",
    DISABLED: "#a2a2a2",
    WHITE: "white",
    BLACK: "black",
  },
  holds: {
    LIGHT_BLUE: "#67b5ff",
    DARK_BLUE: "#2D3848",
    LIGHT_GREEN: "#45B139",
    BROWN_GRAY: "#525252",
    RED: "#d54e43",
    YELLOW: "#fbd20e",
    VIVID_PURPLE: "#4520E2",
    BRIGHT_RED: "#E24420",
    VIVID_BLUE: "#20BEE2",
    DEEP_BLUE: "#063844",
    DARK_RED: "#d54e43",
  },
  packageStatus: {
    LIGHT_BLUE: "#67b5ff",
    LIGHT_GREEN: "#45B139",
    RED: "#d54e43",
    BROWN_GRAY: "#525252",
  },

  finishedVehicles: {
    LIGHT_BLUE: "#67b5ff",
  },
  background: {
    DARK_RED: "#8b181b",
    DARK_BLUE: "#273042",
    GRAY_BLUE: "#354251",
    LIGHT_GRAY_BLUE: "#a4c9d9",
    LIGHTER_GRAY_BLUE: "#ECF9FB",
    LIGHT_GRAY: "#f2f2f2",
    LIGHT_MOUSEOVER_GRAY: "#efefef",
    MID_GRAY: "#c2c2c2",
    MID_GRAY_MOUSEOVER: "#b2b2b2",
    GRAY: "#e0e0e0",
    DARK_GRAY: "#2d2d2d",
    DARK_MOUSEOVER_GRAY: "#3a3a3a",
    DARK_HIGHLIGHT_GRAY: "#444444",
    WARM_GRAY: "#8A8A8A",
    AQUA: "#52C6DA",
    LIGHT_TEAL: "#51A0AE",
    DARK_TEAL: "#2785A9",
    DARKER_BLUE: "#143E7D",
    LIGHTER_BLUE: "#1150AE",
    YELLOW: "#fbd20e",
    DISABLED: "#f3f3f3",
    DARKER_YELLOW: "#e2bc05",
    YELLOW_MOUSEOVER: "#fcfcc7",
    DARK_YELLOW_MOUSEOVER: "#505029",
    GRAY_OVERLAY: "rgba(51, 51, 51, 0.75)",
    WHITE: "white",
    DISABLED_INPUT: "#e9ecef",
  },
  locationType: {
    DARK_RED: "#960000",
    MEDIUM_RED: "#c90000",
    OLIVE_GREEN: "#7A8B45",
    CORAL_GREEN: "#80CAAB",
    GREEN_YELLOW: "#FFFFFF",
    PURPLE: "#5F3F8C",
  },
  border: {
    INNER_GRAY_BORDER: "#f3f3f3",
    BOTTOM_AXIS_BORDER: "#ccc",
    CHART_AXIS_BORDER: "#e0e0e0",
    CYAN_BLUE: "#ced4da",
    ALICE_BLUE: "#EBF3FF",
    BLUE: "#DEEBFF",
    GRAY: "#dee2e6",
  },
  freightVerify: {
    DARK_BLUE: "#2c3948",
  },
  tabs: {
    BACKGROUND: "#ddd",
    BORDER: "#aaa",
    BACKGROUND_HOVER: "#e3e3e3",
    BACKGROUND_SELECTED: "#fff",
    BACKGROUND_SELECTED_HOVER: "#f8f8f8",
  },
  filters: {
    CHECK_DEFAULT_COLOR: "#585858",
    CHECK_SELECTED_COLOR: "#52C5D9",
    SELECTED_OPTIONS_DIVIDER_COLOR: "#c3c3c3",
    SELECT_EMPTY_DIVIDER_COLOR: "#e3e3e3",
    VALUE_CLEAR_BUTTON_COLOR: "#495057",
    VALUE_CLEAR_BUTTON_HOVER_COLOR: "#31363b",
  },
  dialogs: {
    HEADER_BACKGROUND: "#e9e9e9",
    HEADER_TEXT_COLOR: "#273042",
    FOOTER_BACKGROUND: "#e2e2e2",
  },
  tables: {
    default: {
      highlighted: {
        BACKGROUND: "rgba(38, 133, 168, 1)",
        BACKGROUND_HOVER: "rgba(38, 133, 168, 0.8)",
        ALT_BACKGROUND: "rgba(33, 100, 126, 1)",
        ALT_BACKGROUND_HOVER: "rgba(33, 100, 126, 0.8)",
      },
      disabled: {
        BACKGROUND: "#ccc",
        ALT_BACKGROUND: "#ccc",
      },
    },
    dark: {
      highlighted: {
        BACKGROUND: "rgba(38, 133, 168, 1)",
        BACKGROUND_HOVER: "rgba(38, 133, 168, 0.8)",
        ALT_BACKGROUND: "rgba(33, 100, 126, 1)",
        ALT_BACKGROUND_HOVER: "rgba(33, 100, 126, 0.8)",
      },
      disabled: {
        BACKGROUND: "#1f1f1f",
        ALT_BACKGROUND: "#1f1f1f",
      },
    },
  },
  bannerText: "#384655",
  bannerLinkText: "white",
  authInfoText: "#384655",
  tableHeadingBackground: "#2D2D2D", // Dark gray
  tableHeadingText: "#f2f2f2",
  formHeadingColor: "#969696",
  editLadsLabelText: "#aaa",
  linkText: "#2785A9", // dark teal
  buttonBarBorder: "#aaa",
  geofence: {
    fillColor: "rgba(252, 216, 0, 0.2)",
    strokeColor: "rgba(252, 216, 0, 1.0)",
  },
  maps: {
    LIGHT_BLUE: "#0070A0",
    DARK_BLUE: "#48BCD6",
    LIGHT_ORANGE: "#FFAB40",
    DARK_ORANGE: "#E35333",
    LIGHT_RED: "#C73347",
  },
  comments: {
    unreadCommentIcon: "#007BFF",
    sharedIcon: "#007BFF",
    lightText: "#A1A5A9",
    avatar: "#E2E2E2",
    commentBackground: "#ECECEC",
    footerBackground: "#E2E2E2",
  },
  exceptions: {
    DEFAULT: "#000000",
    BEHIND_SCHEDULE: "#ffc400",
    EXCESSIVE_DWELL: "#d5473f",
    MISSING: "#d5473f",
    IN_TRANSIT_STOP_SHIP: "#ff9334",
    ON_HOLD: "#ff9334",
    IN_HOLD: "#ff9334",
    NO_EXCEPTION: "#46b630",
    SPOT_BUY: "#7D6F2B",
    // Shipment exceptions
    BACKORDER: "#ff9444",
  },
  donuts: {
    BLUE: "#007BFF",
    YELLOW: "#dbb800",
    GREEN: "#46b630",
    RED: "#d5473f",
    PURPLE: "#5F3F8C",
    ORANGE: "#ff9334",
  },
  etaTimeCategory: {
    OK: "#46b630",
    WARNING: "#fcd800",
    CRITICAL: "#d5473f",
    DEFAULT: "#A1A5A9",
  },
  dwell: {
    AD: "#46b630",
    MD: "#dbb800",
    ED: "#d5473f",
  },
  arrivalStatus: {
    E: "#46b630",
    OT: "#dbb800",
    L: "#d5473f",
  },
};

export default Colors;

export const shadeColor = (color, percent) => {
  if (_.isNil(color) || _.isNil(percent)) {
    console.error("shadeColor requires `color` and `percent` parameters.");
    return undefined;
  }

  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

export const addAlphaToColor = (color, opacityPercentage) => {
  const opacityHex = Math.floor((opacityPercentage / 100) * 255)
    .toString(16)
    .padStart(2, "0");
  return `${color}${opacityHex}`;
};
