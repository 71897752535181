/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import {
  BaseTable,
  Themes,
  BaseTablePropTypes,
} from "components/organisms/base-table/BaseTable.organism";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";

export const VinHistorySearch = ({
  isLoading,
  showError,
  showErrorMessage,
  searchResults,
  tableProps,
  SearchBarContainer,
}) => {
  let data = showError ? [] : searchResults;

  // Hide results if results are loading.
  if (isLoading) {
    data = [];
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1em 1em 1em 1em",
        }}
      >
        <SearchBarContainer
          isShowingAdvancedSearchButton={false}
          showSearchOptions={false}
          isShowingFilters={false}
          toggleShowFilters={null}
        />
      </div>
      {showError && showErrorMessage ? (
        <Alert
          variant={AlertVariant.Danger}
          show
          style={{ margin: "0 1em 1em 1em" }}
        >
          {showErrorMessage}
        </Alert>
      ) : null}

      <div
        css={{
          padding: "0 1em 1em 1em",
        }}
      >
        {/* Override the data prop to use the limited results. */}
        <BaseTable theme={Themes.LIGHT} {...tableProps} data={data} />
      </div>
    </div>
  );
};

VinHistorySearch.propTypes = {
  isLoading: PropTypes.bool,
  showError: PropTypes.bool,
  showErrorMessage: PropTypes.string,
  searchResults: PropTypes.array,
  tableProps: PropTypes.exact(BaseTablePropTypes),
  SearchBarContainer: PropTypes.elementType,
};
