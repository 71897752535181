import { createSelector } from "reselect";

import CarrierViewDomainDataState from "modules/domain-data/CarrierViewDomainData";
import { createOptionsMapper } from "utils/selectors-utils";

// Helpers
const valuesMapper = createOptionsMapper({ sorted: false });

// Selectors
export const selectShipperFilterOptions = createSelector(
  [CarrierViewDomainDataState.selectors.getShippers],
  createOptionsMapper({
    labelKey: "org_name",
    valueKey: "id",
  }),
);

export const selectExceptionTypeFilterOptions = createSelector(
  [CarrierViewDomainDataState.selectors.selectExceptionTypesAlphabetically],
  createOptionsMapper({ labelKey: "name", valueKey: "id" }),
);

export const selectProductTypeFilterOptions = createSelector(
  [CarrierViewDomainDataState.selectors.getProductTypes],
  valuesMapper,
);

export const selectLifeCycleStateFilterOptions = createSelector(
  [CarrierViewDomainDataState.selectors.getLifeCycleStates],
  valuesMapper,
);
export const selectCurrentPositionTypes = createSelector(
  [CarrierViewDomainDataState.selectors.getCurrentPositionTypes],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);

export const selectOrderTypeFilterOptions = createSelector(
  [CarrierViewDomainDataState.selectors.getOrderTypes],
  valuesMapper,
);
