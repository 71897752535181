import moment from "moment";
import _ from "lodash";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { FILTERS } from "./ApiLogsFilterDefs";
import { isShipper } from "shared/utils/organizations.utils";

const STORE_MOUNT_POINT = "documentationApiLogs";
const SEARCH_CATEGORIES = [];

const logsUrl = (queryString) => {
  return apiUrl(`/support/get_api_logs?${queryString}`);
};

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
) => {
  const { searchFilters } = state[STORE_MOUNT_POINT];

  const requiredKeys = ["type"];
  const hasRequiredKeys = requiredKeys.some(
    (f) => !_.isNil(searchFilters[f]) && !_.isEmpty(searchFilters[f]),
  );

  const dateRange = searchFilters["ts"] || {};
  const { from, to } = dateRange;
  let hasValidDateRange = !from && !to;
  let error = null;

  if (from && to) {
    const days = moment(to).diff(moment(from), "days");
    hasValidDateRange = days <= 14;
  }

  let hasMinimumKeys = false;
  if (isShipper(state.organizations.activeOrganization)) {
    // H1-3755: Shippers have different rules for minimum API searching requirements
    // At least one of "requestId" or "shipmentId"
    const atLeastOneShipperKeys = ["requestId", "shipmentId"];
    const hasAtLeastOneKeys = atLeastOneShipperKeys.some(
      (f) => !_.isNil(searchFilters[f]) && !_.isEmpty(searchFilters[f]),
    );

    // Or both of "ts" and "orgName"
    const orBothKeys =
      !_.isNil(searchFilters["ts"]) &&
      !_.isEmpty(searchFilters["ts"]) &&
      // orgName is a number, so check against _.isNumber
      !_.isNil(searchFilters["orgName"]) &&
      _.isNumber(searchFilters["orgName"]);

    hasMinimumKeys = hasAtLeastOneKeys || orBothKeys;
  } else {
    // Non-shippers only need one of "ts", "requestId", or "shipmentId"
    const atLeastOneKeys = ["ts", "requestId", "shipmentId"];
    hasMinimumKeys = atLeastOneKeys.some(
      (f) => !_.isNil(searchFilters[f]) && !_.isEmpty(searchFilters[f]),
    );
  }

  const isActiveOrgShipper = isShipper(state.organizations.activeOrganization);

  if (!hasRequiredKeys) {
    error = "Please specify a Status Type";
  } else if (isActiveOrgShipper && !hasMinimumKeys) {
    error =
      "Please specify a Request ID, Shipment ID, or a combination of Date Range and Carrier";
  } else if (!isActiveOrgShipper && !hasMinimumKeys) {
    error = "Please specify a Date Range, Request ID, or Shipment ID";
  } else if (!hasValidDateRange) {
    error = "Please specify a Date Range of 14 days or less";
  }

  if (error) {
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      error,
    });
  } else {
    const url = logsUrl(queryString);
    dispatch(duck.fetch(url));
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
);

export default SearchBarState;
