import axios from "axios";
import apiUrl from "../../api-url";
import { createSelector } from "reselect";

const STORE_MOUNT_POINT = "healthcareDomainData";
const CCE = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const RECEIVE_HEALTHCARE_DOMAIN_DATA = CCE("RECEIVE_HEALTHCARE_DOMAIN_DATA");

/**
 * Fetch all domain data needed for Healthcare
 */
export function fetchDomainData(solutionId) {
  const url = apiUrl(`/entity/solution/${solutionId}/entity`);

  const config = {
    headers: {
      Accept: "application/json",
    },
    params: {
      pageNumber: 0,
      pageSize: 1000,
    },
  };
  return (dispatch) => {
    axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: RECEIVE_HEALTHCARE_DOMAIN_DATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Selectors

const convertToOptions = (items) => {
  return items.map((item) => ({
    label: item,
    value: item,
  }));
};

const getAssets = (state) => state[STORE_MOUNT_POINT].assets;
const getAssetsOptions = createSelector(getAssets, convertToOptions);

// Reducers

const initialState = {
  assets: [],
};

const HealthcareDomainDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_HEALTHCARE_DOMAIN_DATA:
      const data = action.payload || {};
      const assets = data || [];

      return {
        ...state,
        assets: assets.data.sort(),
      };

    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getAssets,
    getAssetsOptions,
  },
  reducer: HealthcareDomainDataReducer,
};
