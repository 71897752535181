/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";
import { ResponsiveSection } from "styles/container-elements";
import { Button } from "components/atoms/Button.atom";
import { MdAdd } from "react-icons/md";

import { DwellingVinsWidget } from "./components/DwellingVinsWidget";
import { CapacityMeterWidget } from "./components/CapacityMeterWidget";
import { AddLocationModal } from "./components/AddLocation.modal";
import InventoryViewWatchedLocations from "./components/InventoryView.WatchedLocations";
import InventoryViewWatchedVins from "./components/InventoryView.WatchedVins.container";
import { MediaQueries } from "components/responsive";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const InventoryViewDashboard = ({
  watchedLocations,
  allWatchedLocations,
  pushDetailsView,
  fetchWatchedLocations,
  fetchAllWatchedLocations,
  isWatchedLocationsLoading,
  setDwellFilterValue,
  saveWatchedLocations,
  isAllWatchedLocationsLoading,
  dwellingVinsWidgetPageIndex,
  capacityMeterWidgetPageIndex,
  setCapacityMeterWidgetPageIndex,
  setDwellingVinsWidgetPageIndex,
  resetDetailsPageSearchAndFilters,
  setSelectedTabIndex,
}) => {
  const { t } = useTranslation("inventory-view");
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Dashboard");

  const addLocationModalHideHandler = () => {
    setShowAddLocationModal(false);
  };

  useEffect(() => {
    resetDetailsPageSearchAndFilters();
    setSelectedTabIndex(0);
  }, []);

  useEffect(() => {
    fetchWatchedLocations();
    fetchAllWatchedLocations();
  }, [fetchWatchedLocations, fetchAllWatchedLocations]);

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="success"
            onClick={() => {
              setShowAddLocationModal(true);
            }}
          >
            <MdAdd /> {t("inventory-view:Add Location")}
          </Button>
          <AddLocationModal
            show={showAddLocationModal}
            onHide={() => {
              addLocationModalHideHandler();
            }}
            watchedLocations={allWatchedLocations}
            saveWatchedLocations={saveWatchedLocations}
            onSave={() => {
              fetchWatchedLocations();
              fetchAllWatchedLocations();
              setDwellingVinsWidgetPageIndex(0);
              setCapacityMeterWidgetPageIndex(0);
            }}
          ></AddLocationModal>
        </div>

        <div
          className="my-2"
          css={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridColumnGap: "20px",
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
            }}
          >
            <DwellingVinsWidget
              watchedLocations={allWatchedLocations}
              pushView={pushDetailsView}
              setDwellFilterValue={setDwellFilterValue}
              isLoading={isAllWatchedLocationsLoading}
              currentPage={dwellingVinsWidgetPageIndex}
              setCurrentPage={setDwellingVinsWidgetPageIndex}
            />
          </div>
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
            }}
          >
            <CapacityMeterWidget
              watchedLocations={allWatchedLocations}
              pushView={pushDetailsView}
              isLoading={isAllWatchedLocationsLoading}
              currentPage={capacityMeterWidgetPageIndex}
              setCurrentPage={setCapacityMeterWidgetPageIndex}
            />
          </div>
        </div>

        <div
          css={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(12, 1fr)",
              gridTemplateRows: "1fr",
              gridAutoFlow: "row",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "1 / span 6",
              },
            }}
          >
            <InventoryViewWatchedLocations
              watchedLocations={watchedLocations}
              watchedLocationsIsLoading={isWatchedLocationsLoading}
              saveWatchedLocations={saveWatchedLocations}
              fetchWatchedLocations={() => fetchWatchedLocations()}
              onSave={() => {
                fetchWatchedLocations();
                fetchAllWatchedLocations();
                setDwellingVinsWidgetPageIndex(0);
                setCapacityMeterWidgetPageIndex(0);
              }}
            />
          </div>
          <div
            css={{
              marginBottom: "20px",
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumnStart: 7,
                gridColumnEnd: -1, // Span until the end
              },
            }}
          >
            <InventoryViewWatchedVins />
          </div>
        </div>
      </div>
    </ResponsiveSection>
  );
};

InventoryViewDashboard.propTypes = {
  watchedLocations: PropTypes.array,
  allWatchedLocations: PropTypes.array,
  pushDetailsView: PropTypes.func,
  fetchWatchedLocations: PropTypes.func,
  fetchAllWatchedLocations: PropTypes.func,
  isWatchedLocationsLoading: PropTypes.bool,
  isAllWatchedLocationsLoading: PropTypes.bool,
  saveWatchedLocations: PropTypes.func,
  dwellingVinsWidgetPageIndex: PropTypes.number,
  capacityMeterWidgetPageIndex: PropTypes.number,
  setCapacityMeterWidgetPageIndex: PropTypes.func,
  setDwellingVinsWidgetPageIndex: PropTypes.func,
  resetDetailsPageSearchAndFilters: PropTypes.func,
  setSelectedTabIndex: PropTypes.func,
};

export default InventoryViewDashboard;
