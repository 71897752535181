import { combineReducers } from "redux";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import apiUrl from "api-url";
import { alfBy } from "components/selectors";
import { createSelector } from "reselect";

const STORE_MOUNT_POINT = "domainData";

const SHIPMENT_MODES_URL = apiUrl("/shipping-ng/shipment_modes");
const CARRIERS_URL = apiUrl("/shipping-ng/carriers");
const SHIPPERS_URL = apiUrl("/shipping-ng/shippers");
const COUNTRIES_URL = apiUrl("/shipping-ng/countries");

const shipmentModeDuck = buildFetchDuck("shipmentModes");
const carrierDuck = buildFetchDuck("carriers");
const countriesDuck = buildFetchDuck("countries");
const shippersDuck = buildFetchDuck("shippers");

export function fetchDomainData() {
  return (dispatch) => {
    dispatch(shipmentModeDuck.fetch(SHIPMENT_MODES_URL));
    dispatch(carrierDuck.fetch(CARRIERS_URL));
    dispatch(fetchCountries());
  };
}

export function fetchCountries(url = COUNTRIES_URL) {
  return countriesDuck.fetch(url);
}

// Fetch domain data from URLs that vary by organization type
export function fetchOrganizationTypeDomainData(isCarrier = false) {
  return (dispatch) => {
    // H1-2301: Add Carrier-only querystring to shippers fetch URL
    dispatch(
      shippersDuck.fetch(`${SHIPPERS_URL}${isCarrier ? "?active=f" : ""}`),
    );
  };
}

// selectors
const getShipmentModes = (state) => {
  // If we have valid mode data, otherwise return hard coded defaults
  // some race condition on the details page, results in blank data here
  if (
    state.domainData.shipmentModes.data &&
    state.domainData.shipmentModes.data.length > 1
  ) {
    return state.domainData.shipmentModes.data;
  } else {
    return [
      { id: 1, name: "Truck" },
      { id: 2, name: "Rail" },
      { id: 3, name: "LTL" },
      { id: 4, name: "Intermodal" },
      { id: 5, name: "Ocean" },
    ];
  }
};
const getTruckMode = createSelector(
  getShipmentModes,
  (shipmentModes) => shipmentModes.find((m) => m.name === "Truck").id,
);
const getCarriersData = (state) => {
  return state.domainData.carriers;
};
const getCarriers = (state) => {
  return state.domainData.carriers.data;
};
const selectCarriersAlphabetically = alfBy(getCarriers, "name");
const getCountries = (state) => {
  return state.domainData.countries.data?.data ?? [];
};
const getShippers = (state) => {
  return state.domainData.shippers.data;
};
const getRegions = (state) => {
  return [
    { id: "NA", name: "North America" },
    { id: "EU", name: "Europe" },
    { id: "AS", name: "Asia" },
    { id: "OC", name: "Australia" },
    { id: "AF", name: "Africa" },
    { id: "SA", name: "South America" },
  ];
};
const selectRegionsAlphabetically = alfBy(getRegions, "name");

const selectRegionFilterOptions = createSelector(
  [selectRegionsAlphabetically],
  (regions) =>
    regions.map((region) => ({
      value: region.id,
      label: region.name,
    })),
);

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
    fetchCountries,
    fetchOrganizationTypeDomainData,
  },
  selectors: {
    getShipmentModes,
    getTruckMode,
    getCarriersData,
    getCarriers,
    selectCarriersAlphabetically,
    getShippers,
    getCountries,
    selectRegionFilterOptions,
  },
  reducer: combineReducers({
    shipmentModes: shipmentModeDuck.reducer,
    carriers: carrierDuck.reducer,
    countries: countriesDuck.reducer,
    shippers: shippersDuck.reducer,
  }),
};
