export enum PackageStatus {
  CREATED_OR_PACKAGED = "CREATED/PACKAGED",
  IN_ROUTE = "IN ROUTE",
  DELIVERED = "DELIVERED",
  DELAYED = "DELAYED",
  AVAILABLE_FOR_PICKUP = "AVAILABLE FOR PICKUP",
}

export enum CurrentLocationStatus {
  AT_LOCATION = "At Location",
}
