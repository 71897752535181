import axios from "axios";
import apiUrl from "api-url";
import _ from "lodash";

const DRIVE_AWAY_URL = apiUrl(`/dda`);
const STORE_MOUNT_POINT = "requestDriveAway";

// Actions
const DRIVE_AWAY_REQUESTED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_REQUESTED`;
const DRIVE_AWAY_CANCELED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_CANCELED`;
const DRIVE_AWAY_SUCCEEDED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_SUCCEEDED`;
const DRIVE_AWAY_FAILED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_FAILED`;
const RESET_DRIVE_AWAY_STATUS = `${STORE_MOUNT_POINT}/RESET_DRIVE_AWAY_STATUS`;

// Action creators
function requestDriveAway(internalId, phoneNumber, phoneNumberExt, email) {
  const phone = phoneNumber + "#" + phoneNumberExt;
  let requestBody = {
    dealerPhoneNumber: phone,
  };

  if (!_.isEmpty(email)) {
    requestBody = {
      ...requestBody,
      dealerEmail: email,
    };
  }

  return (dispatch) => {
    dispatch({ type: DRIVE_AWAY_REQUESTED });
    return Promise.all([
      axios.post(
        `${DRIVE_AWAY_URL}/internal/${internalId}/drive-away`,
        JSON.stringify(requestBody),
      ),
    ])
      .then((responses) => {
        dispatch({ type: DRIVE_AWAY_SUCCEEDED });
      })
      .catch((err) => {
        dispatch({ type: DRIVE_AWAY_FAILED, error: err });
      });
  };
}
function cancelDriveAway(id, requestBody) {
  return (dispatch) => {
    dispatch({ type: DRIVE_AWAY_REQUESTED });
    return Promise.all([
      axios.patch(
        `${DRIVE_AWAY_URL}/dda/drive-away/${id} `,
        JSON.stringify(requestBody),
      ),
    ])
      .then((responses) => {
        dispatch({ type: DRIVE_AWAY_SUCCEEDED });
      })
      .catch((err) => {
        dispatch({ type: DRIVE_AWAY_FAILED, error: err });
      });
  };
}

function confirmPickUpRequest(internalId) {
  return (dispatch) => {
    dispatch({ type: DRIVE_AWAY_REQUESTED });
    return Promise.all([
      axios.patch(`${DRIVE_AWAY_URL}/dda/pickup-confirmation/${internalId} `),
    ])
      .then((responses) => {
        dispatch({ type: DRIVE_AWAY_SUCCEEDED });
      })
      .catch((err) => {
        dispatch({ type: DRIVE_AWAY_FAILED, error: err });
      });
  };
}

function resetDriveAwayRequestStatus() {
  return (dispatch) => {
    dispatch({ type: RESET_DRIVE_AWAY_STATUS });
  };
}

// Initial state
export const initialState = {
  createStatus: null,
};

// Reducer
function RequestDriveAwayReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_DRIVE_AWAY_STATUS:
      return Object.assign({}, state, {
        createStatus: null,
      });
    case DRIVE_AWAY_REQUESTED:
      return Object.assign({}, state, {
        createStatus: "IN_PROGRESS",
      });
    case DRIVE_AWAY_CANCELED:
      return Object.assign({}, state, {
        createStatus: "CANCEL",
      });
    case DRIVE_AWAY_SUCCEEDED:
      return Object.assign({}, state, {
        createStatus: "SUCCESS",
      });
    case DRIVE_AWAY_FAILED:
      return Object.assign({}, state, {
        createStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 400
            ? "DUPLICATE"
            : "ERROR",
      });
    default:
      return state;
  }
}

// selector
const getRequestDriveAwayStatus = (state) => {
  return state[STORE_MOUNT_POINT].createStatus;
};

// interface
const RequestDriveAwayState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    requestDriveAway,
    resetDriveAwayRequestStatus,
    cancelDriveAway,
    confirmPickUpRequest,
  },
  selectors: {
    getRequestDriveAwayStatus,
  },
  reducer: RequestDriveAwayReducer,
};
export default RequestDriveAwayState;
