/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

import AuthenticationUtils from "../auth/authentication";
import { Icon } from "components/atoms/Icon.atom";
import { FontSize, Text } from "components/atoms/Text.atom";

export const CallbackView = (props) => {
  const { authCode, error, authType, orgId } = props;
  const { t } = useTranslation(["appnav"]);

  useSetTitleOnMount(t("appnav:Please Wait"));

  if (error) {
    window.location.href = "/accessForbiddenError";
    return;
  }

  if (authType === "CALLBACK" && authCode) {
    AuthenticationUtils.handleAuthentication(authCode, orgId);
    return true;
  } else if (authType === "SAML_CALLBACK") {
    AuthenticationUtils.handleSamlAuthentication();
    return true;
  }

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "1em",
        }}
      >
        <Text size={FontSize.size16} align="center">
          <Icon spin src={faSpinner} style={{ marginRight: 10 }} />
          {t("appnav:You are being logged in to the platform. Please wait...")}
        </Text>
      </div>
    </div>
  );
};

CallbackView.propTypes = {
  authCode: PropTypes.string,
  error: PropTypes.string,
  authType: PropTypes.string,
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
