/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { produce } from "immer";
import _ from "lodash";

import PageHeader from "./documentation-styled-components/PageHeader";
import ApiSelect from "./documentation-styled-components/ApiSelect";
import ApiRequest from "./documentation-styled-components/ApiRequest";
import ApiResponse from "./documentation-styled-components/ApiResponse";
import FormRow from "../../components-old/forms/FormRow";
import SectionHeader from "./documentation-styled-components/SectionHeader";
import StandardInput from "components-old/forms/inputs/StandardInput";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

/**
 * Accepts a username and password from the user
 */
const AuthorizationCredentials = ({
  username,
  password,
  onUsernameChange,
  onPasswordChange,
}) => {
  const { t } = useTranslation(["documentation"]);
  return (
    <React.Fragment>
      <div css={{ padding: "1em 1em 0 1em" }}>
        <SectionHeader title={t("documentation:Authorization Credentials")} />
      </div>
      <FormRow
        style={{
          borderBottom: "1px solid #ddd",
          padding: "0 0.5em 0.5em 0.5em",
        }}
      >
        <StandardInput
          label={t("documentation:User Name")}
          value={username}
          onChange={onUsernameChange}
        />
        <StandardInput
          type="password"
          label={t("documentation:Password")}
          value={password}
          onChange={onPasswordChange}
        />
      </FormRow>
    </React.Fragment>
  );
};

AuthorizationCredentials.defaultProps = {
  username: "",
  password: "",
};

AuthorizationCredentials.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onUsernameChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
};

const tryApiCss = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5em",
  paddingTop: "1.25em",
  paddingRight: "0.5em",
  paddingBottom: "1.25em",
};

export const TryApiView = ({
  apiGroup,
  apiSample,
  fetchApiGroup,
  fetchApiSample,
  callTryApi,
  content,
  isLoading,
  tryResponse,
}) => {
  const { t } = useTranslation(["documentation-remote"]);

  const [apiNameOptions, setApiNameOptions] = useState([]);
  const [apiTypeOptions, setApiTypeOptions] = useState([]);
  const [requestSampleOptions, setRequestSampleOptions] = useState([]);
  const [data, setData] = useState({ headers: {}, parameters: {}, body: {} });

  const [selectedApiName, setSelectedApiName] = useState(null);
  const [selectedApiType, setSelectedApiType] = useState(null);
  const [selectedRequestSample, setSelectedRequestSample] = useState(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useSetTitleOnMount(t("documentation:Training & Resources"));

  useTrackWithMixpanelOnce("Viewing Page: Docs / Try API");

  useEffect(() => {
    fetchApiGroup("carrierApiSamples.json");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const constructTryApi = (sample) => {
    if (!sample || !sample.Api) {
      return;
    }

    const { Documentation = {} } = sample.Api[0];
    // Renaming parameter objects and setting default values.
    // This helps later on so we can skip checking if one of these is undefined.
    const {
      "Header Parameters": headerParameters = [],
      Parameters: parameters = [],
      "Request Sample": requestSample = {},
    } = Documentation;

    setData(
      produce((draft) => {
        // Clear previous header fields
        // This removes text in the existing header fields
        draft.headers = {};

        // Set up the headers for the request
        headerParameters
          .filter((param) => {
            // Ignore Authorization Header parameter
            // Will be generated from username and password
            return param.name !== "Authorization";
          })
          .forEach((prop) => {
            draft.headers[prop.name] = {
              name: prop.name,
              value: "",
              required: prop.required,
              ...prop.schema,
            };
          });

        // Set up the path parameters for the request
        parameters
          .filter((param) => {
            return param.in === "path";
          })
          .forEach((prop) => {
            draft.parameters[prop.name] = {
              name: prop.name,
              value: "",
              required: prop.required,
              in: prop.in,
              pathVariableName: prop.pathVariableName,
              ...prop.schema,
            };
          });

        // Set up the qs parameters for the request
        parameters
          // Process non-path params. Assume remaining are QSPs.
          .filter((param) => {
            return param.in !== "path";
          })
          .forEach((prop) => {
            draft.parameters[prop.name] = {
              name: prop.name,
              value: "",
              required: prop.required,
              ...prop.schema,
            };
          });
      }),
    );
    // If there isn't a request sample, we don't need to do anything

    if (!_.isEmpty(requestSample)) {
      setRequestSampleOptions(
        // Set the request sample options environment options
        Object.getOwnPropertyNames(requestSample).map((key, index) => {
          const value = requestSample[key];
          return {
            value: index,
            label: t(`documentation-remote:${value.summary}`),
            sample: value.value,
          };
        }),
      );
    }
  };

  const constructSelectors = (group) => {
    if (!group || !group.groups) {
      return;
    }

    // Get the available API Types
    let options = group.groups.map((g, i) => {
      return {
        value: i,
        label: t(`documentation-remote:${g.name}`),
        samples: g.samples.map((s, j) => {
          return {
            value: j,
            label: t(`documentation-remote:${s.name}`),
            file: s.file,
          };
        }),
      };
    });

    // Update state with the available Api Types and Names
    // Preselect index 1 of Api Types
    setApiTypeOptions(options);
    setSelectedApiType(1);
    setApiNameOptions(options[1].samples);
  };

  useEffect(() => {
    // Groups will populate the API Type field
    if (apiGroup) {
      constructSelectors(apiGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiGroup]);

  useEffect(() => {
    // Groups will populate the API Type field
    if (apiSample) {
      constructTryApi(apiSample);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSample]);

  /** Set up request related information and available sample options
   * @param {object} sample - An object containing the API reference
   */

  /** Respond to one of the drop downs' change event
   * @param {string} type - The event type; Some string constant that refers to a drop down
   * @param {number} val - The selected index for the drop down
   */
  const apiSelectHandler = (type, val) => {
    if (type === "API_NAME") {
      // Fetch the request samples for the selected API
      fetchApiSample(apiNameOptions[val].file);
    }

    // Clear the Request Body if we are changing API Type or Name
    if (type === "API_TYPE" || type === "API_NAME") {
      setData(
        produce((draft) => {
          draft.body = {};
        }),
      );
    }

    switch (type) {
      // If the API Type selection is being changed
      case "API_TYPE":
        // Set selected index of API Type
        setSelectedApiType(val);
        // Clear the API Name and Request Sample field
        setSelectedApiName(null);
        setSelectedRequestSample(null);
        // Update the Api Name drop down options
        setApiNameOptions(apiTypeOptions[val].samples);
        break;
      // If the API Name selection is being changed
      case "API_NAME":
        // Set the selected index of API Name
        setSelectedApiName(val);
        // Clear the selected Request Sample and options
        setSelectedRequestSample(null);
        setRequestSampleOptions([]);
        break;
      case "REQUEST_SAMPLE":
        // Set the selected index of Request Sample
        setSelectedRequestSample(val);
        // Set the code editor to the selected sample
        setData(
          produce((draft) => {
            draft.body = requestSampleOptions[val].sample;
          }),
        );
        break;
      default:
        break;
    }
  };

  const requestUpdateHandler = (section, name, val) => {
    setData(
      produce((draft) => {
        if (section === "body") {
          draft.body = val;
        } else {
          draft[section][name].value = val;
        }
      }),
    );
  };

  const tryApiHandler = () => {
    const { Method, Path, Server } = apiSample.Api[0];

    // Find the test URL from the Servers array
    // This isn't great because the description text and urls could change
    // But the description for this is treated like an ID in the backend.
    // We can revist this implementaiton later
    const testServer = Server.find(
      (value) => value.description === "Test Environment",
    );
    if (testServer) {
      callTryApi(data, Method, Path, testServer.url, { username, password });
    }
  };

  const onUsernameChange = (value) => {
    setUsername(value);
  };

  const onPasswordChange = (value) => {
    setPassword(value);
  };

  const title = content.title || "";
  const description = content.Description || "";

  return (
    <div css={{ padding: "1em" }}>
      <PageHeader
        title={t(`documentation-remote:${title}`)}
        description={t(`documentation-remote:${description}`)}
      />
      <div style={tryApiCss}>
        <ApiSelect
          apiNameOptions={apiNameOptions}
          apiTypeOptions={apiTypeOptions}
          requestSampleOptions={requestSampleOptions}
          selectedApiName={selectedApiName}
          selectedApiType={selectedApiType}
          selectedRequestSample={selectedRequestSample}
          eventHandler={apiSelectHandler}
        />
        <AuthorizationCredentials
          username={username}
          password={password}
          onUsernameChange={onUsernameChange}
          onPasswordChange={onPasswordChange}
        />
        <ApiRequest
          data={data}
          eventHandler={requestUpdateHandler}
          submitHandler={tryApiHandler}
          isLoading={isLoading}
        />
      </div>
      <ApiResponse tryResponse={tryResponse} />
    </div>
  );
};

TryApiView.propTypes = {
  fetchApiGroup: PropTypes.func.isRequired,
  fetchApiSample: PropTypes.func.isRequired,
  apiSample: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  apiGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  callTryApi: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  tryResponse: PropTypes.object,
};
