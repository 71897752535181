import _ from "lodash";
import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { StatesUS } from "modules/location-edit/LocationEditData";

const LOCATIONS_URL = apiUrl("/location/locations");
const ORIGIN_DESTINATION_URL = apiUrl("/shipping-ng/filter");

const transformLocation = (location) => {
  const state = _.find(StatesUS, { value: location.state });

  return {
    label: location.code
      ? `${location.name} (${location.code})`
      : location.name,
    value: location.id,
    id: location.id,
    code: location.code,
    name: location.name,
    address: location.address,
    state: location.state,
    stateName: state ? state.label : "",
    city: location.city,
    country: location.country,
  };
};

export const buildOriginAsyncFilterOptionsState = ({
  topic,
  additionalParams,
  additionalQueryOnlyParams,
  additionalQueryOnlyHeaders,
}) => {
  return buildAsyncFilterOptionsState({
    topic,
    url: ORIGIN_DESTINATION_URL,
    queryParam: "query",
    additionalParams,
    additionalQueryOnlyParams,
    additionalQueryOnlyHeaders,
    pageSize: 20,
    getResponseData: (data) => data.origin_location,
    transformResult: transformLocation,
  });
};

export const buildDestinationAsyncFilterOptionsState = ({
  topic,
  additionalParams,
  additionalQueryOnlyParams,
  additionalQueryOnlyHeaders,
}) => {
  return buildAsyncFilterOptionsState({
    topic,
    url: ORIGIN_DESTINATION_URL,
    queryParam: "query",
    additionalParams,
    additionalQueryOnlyParams,
    additionalQueryOnlyHeaders,
    pageSize: 20,
    getResponseData: (data) => data.destination_location,
    transformResult: transformLocation,
  });
};

export const buildLocationAsyncFilterOptionsState = ({
  topic,
  additionalParams,
  additionalQueryOnlyParams,
  additionalQueryOnlyHeaders,
}) => {
  return buildAsyncFilterOptionsState({
    topic,
    url: LOCATIONS_URL,
    queryParam: "everything",
    additionalParams,
    additionalQueryOnlyParams,
    additionalQueryOnlyHeaders,
    pageSize: 20,
    getResponseData: (data) => data.data,
    transformResult: transformLocation,
  });
};
