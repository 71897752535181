import moment from "moment";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import { FILTERS } from "pages/shipments/components/search/Shipments.searchOptions";

const STORE_MOUNT_POINT = "shipmentWatchSearch";

const fetchSearch = (queryString, solutionId, duck, dispatch) => {
  const url = apiUrl(`/shipping-ng/shipments?${queryString}&watched=1`);

  dispatch(
    duck.fetch(url, {
      headers: {
        "x-time-zone": moment.tz.guess(),
      },
    }),
  );
};

const ShipmentsWatchSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "destination_earliest_arrival",
  },
  10,
);

export default ShipmentsWatchSearchBarState;
