/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useUncontrolled } from "uncontrollable";
import { CustomCheckboxContainer, CustomCheckboxInput } from "@reach/checkbox";
import "@reach/checkbox/styles.css";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "components/atoms/Icon.atom";

/**
 * A radio button. Can be controlled or uncontrolled.
 */
export const Radio = (props) => {
  // Controls the `checked` prop.
  // This allows it to not be passed in but still maintain state in useUncontrolled.
  const {
    id,
    // Need to set a sensible default.
    checked = false,
    disabled = false,
    color,
    checkedColor,
    disabledColor,
    onChange,
    className,
    "data-qa": dataQa,
  } = useUncontrolled(props, {
    // Since we told useUncontrolled that `onChange` is the handler for the checked state,
    // we don't have to null check it before calling it.
    // useUncontrolled gives us a default onChange
    checked: "onChange",
  });

  const handleOnChange = (event) => {
    onChange(event.target.checked, event);
  };

  return (
    <CustomCheckboxContainer
      checked={checked}
      disabled={disabled}
      onChange={(event) => handleOnChange(event)}
      css={{
        display: "flex",
        outline: "none !important",
        width: "auto",
        height: "auto",
        boxShadow: "none !important",
      }}
      className={className}
    >
      <CustomCheckboxInput id={id} data-qa={dataQa} css={{ outline: "none" }} />
      {checked ? (
        <Icon
          aria-hidden
          src={faCheckCircle}
          color={disabled ? disabledColor : checkedColor}
        />
      ) : (
        <Icon
          aria-hidden
          src={faCircle}
          color={disabled ? disabledColor : color}
        />
      )}
    </CustomCheckboxContainer>
  );
};

Radio.propTypes = {
  /**
   * The id of the radio button.
   *
   * Useful for labeling this radio button with a label's htmlFor prop.
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
  /**
   * The value of the radio button.
   */
  checked: PropTypes.bool,
  /**
   * Disables radio button, preventing it from being clicked
   */
  disabled: PropTypes.bool,
  /**
   * The default color of the radio button.
   */
  color: PropTypes.string,
  /**
   * The color of the radio button when checked.
   */
  checkedColor: PropTypes.string,
  /**
   * The color of the radio button when disabled.
   */
  disabledColor: PropTypes.string,
  /**
   * A handler for the onChange event.
   *
   * This function is passed two parameters; the new checked value and the event object.
   *
   * e.g. (checked, event) => void
   */
  onChange: PropTypes.func,
  /**
   * A string for defining one or more classes that will be added to the checkbox.
   */
  className: PropTypes.string,
};
