//External packages
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import debouncePromise from "debounce-promise";

//External hooks or supporting code from React
import { useEffect, useRef } from "react";
import { Translation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";

//Components
import FormCell from "components-old/forms/FormCell";

//Styles
import Colors from "styles/colors";

export const SearchBar = ({
  shipperLocations,
  label,
  ind,
  shipperLocationsLoading,
  eventHandler,
  stop,
  setSearchText,
  searchLocations,
  selectedShipper,
}) => {
  const location = useRef(() => shipperLocations);
  useEffect(() => {
    if (location.current !== shipperLocations) {
      location.current = shipperLocations;
    }
  }, [shipperLocations]);

  const getLocations = (ind) => {
    const locations = location.current;
    return locations && locations[ind] ? locations[ind] : [];
  };

  const getLocationTimeZone = (ind, locationId) => {
    const locations = getLocations(ind);
    const location = locations.find((l) => l.id === locationId);
    return location ? location.time_zone : null;
  };

  // todo incorporate this into SelectField to remove duplicate code
  const customStyles = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      borderRadius: "5px",
      borderWidth: "2px",
      borderColor: "#ced4da",
      height: "3em",
      backgroundColor: isDisabled ? Colors.background.LIGHT_GRAY : "#fff",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  // H2-453: Use react-select to search locations asynchronously
  return (
    <FormCell label={label}>
      <Translation ns="create-shipment">
        {(t) => (
          <AsyncPaginate
            styles={customStyles}
            isMulti={false}
            cacheOptions={false}
            key={selectedShipper ? selectedShipper.organization_id : 0}
            label={label}
            value={stop.location}
            placeholder={
              stop.location && stop.location_name
                ? stop.location_name
                : `${t("create-shipment:Select")}...`
            }
            isDisabled={!selectedShipper}
            isLoading={shipperLocationsLoading}
            onChange={(newOption) => {
              eventHandler(ind, "location", newOption.value);
              eventHandler(ind, "location_name", newOption.label);
              if (newOption && newOption.value) {
                eventHandler(
                  ind,
                  "timezone",
                  getLocationTimeZone(ind, newOption.value),
                );
              }
            }}
            loadOptions={debouncePromise((input) => {
              if (input.length < 2) {
                return {
                  options: [],
                  hasMore: false,
                };
              }

              setSearchText(input, ind);
              return searchLocations(selectedShipper.organization_id, ind).then(
                () => {
                  const location = getLocations(ind);
                  const locationOptions = location.map((l) => {
                    return { value: l.id, label: `${l.name} (${l.code})` };
                  });
                  return { options: locationOptions };
                },
              );
            }, 500)}
          />
        )}
      </Translation>
    </FormCell>
  );
};

SearchBar.propTypes = {
  shipperLocations: PropTypes.object,
  label: PropTypes.object,
  ind: PropTypes.number,
  shipperLocationsLoading: PropTypes.bool,
  eventHandler: PropTypes.func,
  stop: PropTypes.object,
  setSearchText: PropTypes.func,
  searchLocations: PropTypes.func,
  selectedShipper: PropTypes.object,
};
