/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { MediaQueries } from "components/responsive";
import NotificationsHeader from "modules/notifications/NotificationsHeader";
import { OrganizationSelect } from "modules/organizations/OrganizationSwitcher";
import ApplicationConfig from "application-config";
import Colors from "styles/colors";
import logoSm from "assets/logos/fv_mark_light.svg";
import AuthenticationUtils from "modules/auth/authentication";

// Get the list of user organizations to show in the switcher dropdown
const authorizedOrganizations = (organizations = [], authorization) => {
  if (_.isEmpty(organizations)) {
    return [];
  }

  const isFvAdmin = authorization.isFvAdmin();
  const userOrgIds = authorization.organizationIds;

  return organizations.filter((organization) => {
    const deletedAt = organization?.deleted_at ?? null;
    const orgId = organization?.organization_id ?? null;

    // Don't display deleted orgs
    if (deletedAt !== null) {
      return false;
    }

    // FV Admins can see all orgs
    if (isFvAdmin) {
      return true;
    }

    return userOrgIds.includes(orgId);
  });
};

// Filter the organization list based on the user's search query
const filterOrganizations = (query = "", organizations = []) => {
  if (_.isNil(query) || _.isNil(organizations)) {
    return [];
  }

  const searchStr = query.toLowerCase();

  const matches = (value) => {
    return String(value ?? "")
      .toLowerCase()
      .includes(searchStr);
  };

  return organizations.filter((organization) => {
    const fvId = organization?.fv_id ?? null;
    const scac = organization?.scac ?? null;
    const name = organization?.org_name ?? null;
    const federatedOrgIds = organization?.federated_org_ids ?? [];

    // DEV-1641: Matches SCAC, FV_ID
    // SH-7818: Check fields in order; FV ID, scac, name, federated org IDs
    return (
      matches(fvId) ||
      matches(scac) ||
      matches(name) ||
      federatedOrgIds?.some(matches)
    );
  });
};

const sortOrganizations = (query = "", organizations = []) => {
  // Returns 2 for exact match, 1 for contains match, and 0 for everything else.
  const getMatchRankForField = (value) => {
    if (!value) {
      return 0;
    }

    const cleanedQuery = String(query ?? "").toLowerCase();
    const cleanedValue = String(value ?? "").toLowerCase();

    if (cleanedValue === cleanedQuery) {
      return 2;
    }

    if (cleanedValue.includes(cleanedQuery)) {
      return 1;
    }

    return 0;
  };

  // Returns a sorting rank for the given org.
  // For each field calculate how much a match contributes to the final ranking.
  // Each field is given a weight to give a priority order; the higher in the list, the higher the weight.
  // - fv_id
  // - scac
  // - org_name
  // - federated_org_ids
  const getMatchRankForOrg = (org) => {
    const {
      fv_id: fvId = null,
      scac = null,
      org_name: name = null,
      federated_org_ids: federatedOrgIds = [],
    } = org ?? {};

    return (
      4 * getMatchRankForField(fvId) +
      3 * getMatchRankForField(scac) +
      2 * getMatchRankForField(name) +
      1 * federatedOrgIds?.reduce(getMatchRankForField, 0)
    );
  };

  // Sort orgs descending by "rank":
  // - If `a` is larger, return < 0 (order `a` before `b`)
  // - If `a` is smaller, return > 0 (order `a` after `b`)
  // - If equal, return 0 (no change)
  const descending = (a, b) => b - a;
  return organizations.sort((org, nextOrg) => {
    const rank = getMatchRankForOrg(org);
    const nextRank = getMatchRankForOrg(nextOrg);

    return descending(rank, nextRank);
  });
};

const HeaderBar = (props) => {
  const {
    t,
    title,
    subTitle,
    description,
    authorization,
    activeOrganization,
    currentOrganizationId,
    mapTypeOverride,
    organizationImageConfig,
    organizations,
    setCurrentOrganization,
    setActiveOrganization,
    setMapTypeOverride,
    fetchOrganizations,
    showOrganization,
    showNotifications,
    style,
  } = props;

  const [orgSearchText, setOrgSearchText] = useState("");

  const isFvAdmin = authorization.isFvAdmin();

  // Get a list of orgs that this user has access to
  const userOrganizations = authorizedOrganizations(
    organizations,
    authorization,
  );

  // Filter and sort the list based on what they type in the input
  const filteredOrganizations = filterOrganizations(
    orgSearchText,
    userOrganizations,
  );
  const displayedOrganizations = sortOrganizations(
    orgSearchText,
    filteredOrganizations,
  );

  const showDropdown = isFvAdmin || userOrganizations.length > 1;

  const [showOrganizationSelect, setShowOrganizationSelect] = useState(false);
  const organizationSearchInputRef = useRef(null);

  useEffect(() => {
    if (!showOrganization) {
      return;
    }

    //fetching organizations
    //1. on page load
    //2. when changing the organization in dropdown
    fetchOrganizations();
  }, [currentOrganizationId, showOrganization, fetchOrganizations]);

  // focusing in useEffect so that the element is already shown in the DOM
  // Can't focus in the toggle because it is not focusable at that time
  useEffect(() => {
    // With the custom Dropdown, we show the OrganizationSelect in a modal.
    // For some reason, our ref isnt set by the time showOrganizationSelect goes to true.
    // Making sure we have the ref prevents any errros but I wonder how we would be able to
    // focus the org search when it appears as a modal.
    if (showOrganizationSelect === true && organizationSearchInputRef.current) {
      organizationSearchInputRef.current.focus();
    }

    // When we hide the select, clear the input text.
    if (!showOrganizationSelect) {
      setOrgSearchText("");
    }
  }, [showOrganizationSelect]);

  let headerTitle = title;
  // Add the sub-title if it exists
  if (subTitle) {
    headerTitle += ": " + subTitle;
  }

  let backgroundColor = "#2c3948";
  let iconSrc = logoSm;
  if (organizationImageConfig?.logoPath) {
    backgroundColor = null;
    iconSrc = organizationImageConfig.iconPath;
  }

  return (
    <header
      style={style}
      css={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        [MediaQueries.smallAndUp]: {
          flexDirection: "row",
          alignItems: "center",
        },
      }}
    >
      {/* Page Title */}
      <div
        css={{
          display: "flex",
          maxWidth: "100%",
          alignSelf: "center",
          [MediaQueries.smallAndUp]: {
            alignSelf: "initial",
          },
        }}
      >
        <Text
          size={FontSize.size20}
          color={Colors.background.GRAY_BLUE}
          truncate
          style={{ flex: "1 1" }}
        >
          {!_.isNil(headerTitle) && !_.isEmpty(headerTitle) ? (
            headerTitle
          ) : ApplicationConfig.isEnvironment("dev", "dev2") ? (
            <div
              style={{ background: "red", color: "white", padding: "0 10px" }}
            >
              MISSING TITLE - Search project for setTitle for examples.
            </div>
          ) : (
            "FreightVerify"
          )}
        </Text>
        {description ? (
          <Text size={FontSize.size18}>
            <Tooltip tooltipChildren={description} placement="bottom">
              <sup className="m-1">
                <small>
                  <Icon src={faQuestionCircle} />
                </small>
              </sup>
            </Tooltip>
          </Text>
        ) : null}
      </div>
      <div
        css={{
          flex: "1 1 0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          flexWrap: "wrap-reverse",
          maxWidth: "100%",
        }}
      >
        {/* Notifications */}
        {showNotifications ? <NotificationsHeader /> : null}

        {!showOrganization ? null : !showDropdown ? (
          // Organization Text and Name (for non-multi org users)
          <Text
            size={FontSize.size16}
            color={Colors.formHeadingColor}
            css={{
              marginRight: "1em",
              whiteSpace: "nowrap",
            }}
            truncate
          >
            {activeOrganization?.org_name ?? ""}
          </Text>
        ) : (
          // Organization Dropdown (for multi org users)
          <Dropdown
            id="organization-selector"
            responsiveModalMenu
            alignMenuRight
            show={showOrganizationSelect}
            text={
              activeOrganization && activeOrganization.org_name
                ? activeOrganization.org_name
                : t("header-bar:Organization")
            }
            textStyle={{ color: Colors.formHeadingColor }}
            icon={
              <div
                style={{
                  backgroundColor: backgroundColor,
                  borderRadius: 32,
                  textAlign: "center",
                  flex: "1 0",
                }}
              >
                <Icon
                  type={IconType.LocalImage}
                  src={iconSrc}
                  style={{ height: 32, width: 32 }}
                />
              </div>
            }
            onToggle={(isShown) => {
              setShowOrganizationSelect(isShown);
            }}
          >
            <OrganizationSelect
              ref={organizationSearchInputRef}
              t={t}
              fvAdmin={isFvAdmin}
              currentOrganizationId={parseInt(currentOrganizationId, 10)}
              mapTypeOverride={mapTypeOverride}
              organizations={displayedOrganizations}
              searchText={orgSearchText}
              setCurrentOrganization={setCurrentOrganization}
              setActiveOrganization={setActiveOrganization}
              setMapTypeOverride={setMapTypeOverride}
              setSearchText={setOrgSearchText}
            />
          </Dropdown>
        )}
      </div>
    </header>
  );
};

HeaderBar.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  authorization: PropTypes.object,
  activeOrganization: PropTypes.object,
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  mapTypeOverride: PropTypes.string,
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
    iconPath: PropTypes.string,
  }),
  organizations: PropTypes.array,
  setCurrentOrganization: PropTypes.func,
  setActiveOrganization: PropTypes.func,
  setMapTypeOverride: PropTypes.func,
  fetchOrganizations: PropTypes.func,
  showOrganization: PropTypes.bool,
  showNotifications: PropTypes.bool,
  style: PropTypes.object,
};

export default withTranslation(["header-bar", "map"])(HeaderBar);
