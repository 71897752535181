/** @jsxImportSource @emotion/react */
import { ReactNode, ComponentType } from "react";
import {
  AsyncPaginate as AsyncPaginateLib,
  AsyncPaginateProps as AsyncPaginatePropsLib,
  LoadOptions,
} from "react-select-async-paginate";
import Option, {
  SelectComponentsConfig,
  OptionProps,
  Options,
  GroupBase,
} from "react-select";

import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";

import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { useTranslation } from "react-i18next";

/**
 * A wrapper for AsyncPaginate that allows for the display of checkboxes in the dropdown
 */
const SelectAsync = ({
  loadOptions,
  onChange,
  libProps,
  isMulti,
  showCheckbox,
  closeMenuOnSelect,
  hideSelectedOptions,
  value,
  styles,
  placeholder,
  isDisabled,
  isClearable,
  isSearchable,
  cacheUniqs,
  debounceTimeout,
  loadingMessage,
  noOptionsMessage,
  checkboxSelectedColor = Colors.filters.CHECK_SELECTED_COLOR,
  components = {},
}: SelectAsyncProps) => {
  const { t } = useTranslation("components");

  loadingMessage ??= () => t("components: Loading...");
  noOptionsMessage ??= () => t("components: No Options");

  if (showCheckbox && !isMulti) {
    throw new Error(
      "showCheckbox can only be used when isMulti is set to true",
    );
  }
  if (showCheckbox && isMulti) {
    components.Option = CheckboxOptionWrapper(checkboxSelectedColor);
  }
  return (
    <AsyncPaginateLib
      {...libProps}
      isMulti={isMulti}
      components={components}
      loadOptions={loadOptions as LoadOptions<Option, any, any>}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      value={value}
      onChange={onChange}
      styles={styles}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      cacheUniqs={cacheUniqs}
      debounceTimeout={debounceTimeout}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

const CheckboxOptionWrapper =
  (
    color: string,
  ): ComponentType<OptionProps<Option, boolean, GroupBase<Option>>> =>
  (props: OptionProps<Option, boolean, GroupBase<Option>>) =>
    CheckboxOption({
      ...props,
      checkboxSelectedColor: color,
    } as CheckboxOptionProps);

const CheckboxOption = (props: CheckboxOptionProps) => {
  const {
    innerProps,
    label,
    isSelected = false,
    isFocused,
    checkboxSelectedColor,
  } = props;

  return (
    <li
      {...innerProps}
      css={{
        fontSize: "0.8em",
        padding: "0.3em 0.5em;",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        cursor: "pointer",
        backgroundColor: isSelected
          ? Colors.border.ALICE_BLUE
          : isFocused
          ? Colors.border.BLUE
          : Colors.tabs.BACKGROUND_SELECTED,
      }}
    >
      <Icon
        css={{ padding: "0.2em 0.3em" }}
        aria-hidden
        src={isSelected ? faCheckSquare : faSquare}
        size={FontSize.size16}
        data-qa="checkbox-icon"
        color={
          isSelected
            ? checkboxSelectedColor
            : Colors.filters.CHECK_DEFAULT_COLOR
        }
      />
      <Text as="label" style={{ cursor: "pointer", marginBottom: 0 }}>
        {label}
      </Text>
    </li>
  );
};

type CheckboxOptionProps = {
  innerProps: React.HTMLAttributes<HTMLLIElement>;
  label: string;
  isSelected: boolean;
  isFocused: boolean;
  checkboxSelectedColor: string;
};

type SelectAsyncProps = {
  /**
   * Function that returns a promise, which has the set of options to be used once the promise resolves.
   */
  loadOptions?: (inputValue: string) => Promise<{
    options: Options<Object>;
    hasMore: boolean;
    additional?: any;
  }>;
  /**
   * Function that handle onChange events
   */
  onChange?: (values: any) => void;
  /**
   * Text to display when there are no options
   */
  loadingMessage?: () => ReactNode;
  /**
   * Text to display when there are no options
   */
  noOptionsMessage?: () => ReactNode;
  /**
   * Whether the select should allow multiple selections
   */
  isMulti?: boolean;
  /**
   * Whether to show checkboxes in the dropdown, only works when isMulti is true
   */
  showCheckbox?: boolean;
  /**
   * Whether to close the menu when an option is selected
   */
  closeMenuOnSelect?: boolean;
  /**
   * Whether to hide selected options from the dropdown
   */
  hideSelectedOptions?: boolean;
  /**
   * Props to pass to the AsyncPaginate component
   */
  libProps?: AsyncPaginatePropsLib<Option, GroupBase<Option>, any, boolean>;
  /**
   * The selected value(s)
   */
  value?: Array<Option>;
  /**
   * Custom styles to apply to the select
   */
  styles?: Object;
  /**
   * Placeholder text to display when no value is selected
   */
  placeholder?: string;
  /**
   * Whether the select is disabled
   */
  isDisabled?: boolean;
  /**
   * Whether the select is clearable
   */
  isClearable?: boolean;
  /**
   * Whether to enable search functionality
   */
  isSearchable?: boolean;
  /**
   * Works as 2nd argument of useEffect hook. When one of items changed, AsyncPaginate cleans all cached options.
   */
  cacheUniqs?: Array<Object>;
  /**
   * Debounce timeout for loadOptions calls. 0 by default.
   */
  debounceTimeout?: number;
  /**
   * This complex object includes all the compositional components that are used in react-select.
   * If you wish to overwrite a component, pass in an object with the appropriate namespace.
   * For more information please see: https://react-select.com/props#components
   */
  components?: SelectComponentsConfig<Option, boolean, GroupBase<Option>>;
  /**
   * The color to use for the checkbox when selected
   */
  checkboxSelectedColor?: string;
};

export { SelectAsync, CheckboxOption };
export type { CheckboxOptionProps, SelectAsyncProps };
