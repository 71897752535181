/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faCar } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import {
  MediaQueries,
  useIsExtraSmall,
  useIsLarge,
  useIsSmallAndUp,
} from "components/responsive";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { PhotoPlaceholder } from "components/no-data-placeholders";
import { Image, ImageType } from "components/atoms/Image.atom";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { ExceptionsBox } from "shared/components/molecules/ExceptionsBox.molecule";
import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
import ShareModal from "shared/components/modals/Share.modal";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { DetailPanelGroupItem } from "pages/components/molecules/DetailPanelGroupItem.molecule";
import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { Dropdown } from "components/molecules/Dropdown.molecule";

import { getVinDetails, getVinPopUpDetails } from "shared/utils/vin.utils";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

export const VinDetailsPanelGroup = ({
  entityId,
  details,
  media,
  holds,
  exceptions,
  currentMapCoordinate,
  addCoordinate,
  clearCoordinatesByType,
  hasTransitTimerFeature,
  transitDuration,
  currentPositionDetails,
  canDisplaySource,
  showShareAction,
  shouldBuildExceptionsBySeverity,
  style = {},
  // Subscription
  vinSubscription,
  isVinSubscriptionLoading,
  vinSubscriptionRequestError,
  subscribe,
  updateSubscription,
  unsubscribe,
  isVinSubscriptionUpdating,
  vinSubscriptionUpdateSuccess,
  vinSubscriptionUpdateError,
  // Watch Toggle
  watch,
  toggleWatchEntity,
  watchToggleTooltipText,
  // Sharing
  clearShareVin,
  shareVin,
  isSharingVin,
  isShareVinSuccessful,
  isShareVinFailed,
  canShareEta,
  showOrderNumber = false,
}) => {
  const { t } = useTranslation("fv-vin-details");
  const isSmallAndUp = useIsSmallAndUp();
  const isLarge = useIsLarge();
  const isExtraSmall = useIsExtraSmall();

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showAlertMeModal, setShowAlertMeModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const vinDetails = getVinDetails(details);

  // Flag to control showing the input elements in the "Details" header or as a SubHeader
  const showDetailsInputsInHeader = isSmallAndUp && !isLarge;
  const detailsHeaderElements = (
    <Fragment>
      <Dropdown
        responsiveModalMenu
        alignMenuRight
        show={showActionsDropdown}
        variant="primary"
        text={t("fv-vin-details:Actions")}
        onToggle={(isShown, event) => {
          event && event.originalEvent.stopPropagation();
          setShowActionsDropdown(isShown);
        }}
      >
        <Dropdown.Item
          disabled={isVinSubscriptionLoading}
          onClick={() => {
            setShowAlertMeModal(true);
          }}
        >
          {isVinSubscriptionLoading ? (
            <Icon src={faSpinner} size={FontSize.size20} spin />
          ) : (
            <div css={{ display: "flex", flexDirection: "row" }}>
              <Text css={{ flex: 1 }}>{t("fv-vin-details:Alert Me")}</Text>
              {vinSubscription ? (
                <Text
                  italic
                  size={FontSize.size12}
                  color={Colors.highlight.GREEN}
                >
                  ({t("components:Active")})
                </Text>
              ) : null}
            </div>
          )}
        </Dropdown.Item>
        {showShareAction ? (
          <Dropdown.Item
            onClick={() => {
              setShowShareModal(true);
            }}
          >
            <Text>{t("fv-vin-details:Share")}</Text>
          </Dropdown.Item>
        ) : null}
      </Dropdown>

      <WatchToggle
        id={entityId}
        checked={watch}
        onChange={() => {
          toggleWatchEntity();
        }}
        onClick={(e) => {
          //stop the event propagation else detail panel is also toggled since it is collapsible
          e.stopPropagation();
        }}
        labelText={
          isExtraSmall
            ? t("fv-vin-details:Watch")
            : t("fv-vin-details:Watch this VIN")
        }
        labelTextSize={FontSize.size14}
        tooltipText={watchToggleTooltipText}
        tooltipTextSize={FontSize.size12}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <PanelGroup collapsible style={style}>
        <PanelGroup.Header
          title={t("Details")}
          rightContent={
            showDetailsInputsInHeader ? (
              <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
                {detailsHeaderElements}
              </div>
            ) : null
          }
        />
        {!showDetailsInputsInHeader ? (
          <PanelGroup.SubHeader
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0.5em",
              background: Colors.background.GRAY,
              borderBottom: `1px solid ${Colors.background.WARM_GRAY}`,
            }}
          >
            {detailsHeaderElements}
          </PanelGroup.SubHeader>
        ) : null}
        <PanelGroup.Content>
          <FlexRowDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
            }}
          >
            <FlexColDiv css={{ flex: "1 1", marginRight: "1em" }}>
              <DetailPanelGroupItem
                data-qa="text-vin"
                label={t("fv-vin-details:VIN")}
              >
                {vinDetails.status === "Prebuilt" ? "TBD" : vinDetails.id}
              </DetailPanelGroupItem>
              <DetailPanelGroupItem
                data-qa="text-vin-type"
                label={t("fv-vin-details:Type")}
              >
                {vinDetails.type}
              </DetailPanelGroupItem>
              {showOrderNumber ? (
                <DetailPanelGroupItem
                  data-qa="text-vin-order-number"
                  label={t("fv-vin-details:Order Number")}
                >
                  {vinDetails.orderNumber}
                </DetailPanelGroupItem>
              ) : null}
              <DetailPanelGroupItem
                data-qa="text-vin-status"
                label={t("fv-vin-details:Status")}
              >
                {vinDetails.status}
              </DetailPanelGroupItem>
              {hasTransitTimerFeature ? (
                <FlexRowDiv
                  css={{
                    "> div": { flexGrow: "1" },
                    flexWrap: "wrap",
                  }}
                >
                  <DetailPanelGroupItem
                    data-qa="text-vin-dwell"
                    label={t("fv-vin-details:Dwell")}
                  >
                    <span css={{ fontSize: ".7em" }}>
                      <FontAwesomeIcon
                        icon={faClock}
                        css={{ marginRight: ".7em" }}
                      />
                      {vinDetails.dwell}
                    </span>
                  </DetailPanelGroupItem>
                  <DetailPanelGroupItem
                    data-qa="text-vin-total-transit"
                    label={t("fv-vin-details:Total Transit")}
                    labelStyle={{ width: "6.1em" }}
                  >
                    <span css={{ fontSize: ".7em" }}>
                      <FontAwesomeIcon
                        icon={faClock}
                        css={{ marginRight: ".7em" }}
                      />
                      {transitDuration}
                    </span>
                  </DetailPanelGroupItem>
                </FlexRowDiv>
              ) : null}
              {currentPositionDetails?.currentPositionName ||
              currentPositionDetails?.currentPosition ||
              currentPositionDetails?.latitude ||
              currentPositionDetails?.longitude ? (
                <FlexRowDiv
                  css={{
                    "> div": { flexGrow: "2" },
                    flexWrap: "wrap",
                  }}
                >
                  <DetailPanelGroupItem
                    label={t("fv-vin-details:Current Location")}
                  >
                    <div style={{ flex: 1, flexDirection: "column" }}>
                      <div style={{ flex: 1, flexDirection: "row" }}>
                        <div style={{ flex: 1, flexDirection: "row" }}>
                          <Text style={{ marginLeft: ".5em" }}>
                            {currentPositionDetails.currentPositionType
                              ? currentPositionDetails.currentPositionType
                              : null}
                          </Text>

                          {currentPositionDetails.latitude &&
                          currentPositionDetails.longitude ? (
                            <Icon
                              onClick={() => {
                                // This means we already have a current location
                                // and it is the one from this icon click.
                                if (currentMapCoordinate) {
                                  clearCoordinatesByType([
                                    MapCoordinateType.CURRENT_LOCATION,
                                  ]);
                                } else {
                                  addCoordinate(
                                    MapCoordinateType.CURRENT_LOCATION,
                                    currentPositionDetails.latitude,
                                    currentPositionDetails.longitude,
                                    currentPositionDetails.datetime,
                                    currentPositionDetails.ind,
                                    {
                                      PopUpDetails: {
                                        name: currentPositionDetails.currentPositionName,
                                        ...getVinPopUpDetails(
                                          details,
                                          "currentLocation",
                                        ),
                                      },
                                    },
                                  );
                                }
                              }}
                              src={faCar}
                              size={FontSize.size20}
                              // Change the color if the current map coord is being dispalyed.
                              // This is so the user knows its been toggled on or off.
                              color={
                                currentMapCoordinate
                                  ? Colors.highlight.LIGHT_BLUE
                                  : null
                              }
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            />
                          ) : null}
                        </div>

                        {currentPositionDetails.currentPositionName ? (
                          <Text style={{ marginLeft: ".5em" }}>
                            {currentPositionDetails.currentPositionName}
                          </Text>
                        ) : null}
                      </div>
                    </div>
                  </DetailPanelGroupItem>

                  <DetailPanelGroupItem label={t("fv-vin-details:Source")}>
                    {canDisplaySource && currentPositionDetails?.source ? (
                      <Text>{currentPositionDetails.source}</Text>
                    ) : null}
                  </DetailPanelGroupItem>
                </FlexRowDiv>
              ) : null}
            </FlexColDiv>

            <FlexRowDiv
              css={{
                alignSelf: "flex-start",
                flexDirection: "column-reverse",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "row",
                },
              }}
            >
              <Image
                style={{
                  alignSelf: "center",
                  [MediaQueries.mediumAndDown]: {
                    marginRight: "1em",
                  },
                  [MediaQueries.largeAndUp]: {
                    marginTop: "1em",
                  },
                }}
                type={ImageType.Default}
                media={media}
                placeholder={<PhotoPlaceholder />}
                altText={t("fv-vin-details:Car")}
                data-qa={"image-shipper"}
              />
              <ExceptionsBox
                holds={holds}
                exceptions={exceptions}
                shouldBuildExceptionsBySeverity={
                  shouldBuildExceptionsBySeverity
                }
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </PanelGroup.Content>
      </PanelGroup>
      <AlertMeModal
        subscribeeDetails={details}
        subscriptionDetails={vinSubscription}
        isSubscriptionLoading={isVinSubscriptionLoading}
        subscriptionRequestError={vinSubscriptionRequestError}
        updateSubscription={updateSubscription}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
        isSubscriptionUpdating={isVinSubscriptionUpdating}
        subscriptionUpdateSuccess={vinSubscriptionUpdateSuccess}
        subscriptionUpdateError={vinSubscriptionUpdateError}
        show={showAlertMeModal}
        hide={() => setShowAlertMeModal(false)}
      />
      <ShareModal
        show={showShareModal}
        hide={() => setShowShareModal(false)}
        clearShareVin={clearShareVin}
        shareVin={shareVin}
        isSharingVin={isSharingVin}
        isShareVinSuccessful={isShareVinSuccessful}
        isShareVinFailed={isShareVinFailed}
        canShareEta={canShareEta}
      />
    </Fragment>
  );
};

VinDetailsPanelGroup.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.object,
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  holds: PropTypes.array,
  exceptions: PropTypes.array,
  hasTransitTimerFeature: PropTypes.bool,
  transitDuration: PropTypes.string,
  currentPositionDetails: PropTypes.object,
  canDisplaySource: PropTypes.bool,
  showShareAction: PropTypes.bool,
  showOrderNumber: PropTypes.bool,
  shouldBuildExceptionsBySeverity: PropTypes.bool,
  style: PropTypes.object,
  // Subscription
  vinSubscription: PropTypes.object,
  isVinSubscriptionLoading: PropTypes.bool,
  vinSubscriptionRequestError: PropTypes.bool,
  subscribe: PropTypes.func,
  updateSubscription: PropTypes.func,
  unsubscribe: PropTypes.func,
  isVinSubscriptionUpdating: PropTypes.bool,
  vinSubscriptionUpdateSuccess: PropTypes.bool,
  vinSubscriptionUpdateError: PropTypes.bool,
  // Watch Toggle
  watch: PropTypes.bool,
  toggleWatchEntity: PropTypes.func,
  watchToggleTooltipText: PropTypes.string,
  // Sharing
  clearShareVin: PropTypes.func.isRequired,
  shareVin: PropTypes.func.isRequired,
  isSharingVin: PropTypes.bool,
  isShareVinSuccessful: PropTypes.bool,
  isShareVinFailed: PropTypes.bool,
  canShareEta: PropTypes.bool,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  currentMapCoordinate: PropTypes.object,
};
