/** @jsxImportSource @emotion/react */
import moment from "moment";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import Colors from "styles/colors";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";

export const DeliverPackageModal = ({
  show,
  hide,
  deliveryData,
  setPackageDeliveryEvent,
  isPackageDeliveryEventLoading,
  packageDeliveryEventRequestError,
  packageDeliveryEventRequestSuccess,
}) => {
  const { t } = useTranslation("partview-details");

  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;

  /* Max is min + 60 days */
  const now = moment();
  const remainder = 30 - (now.minute() % 30);
  let min = now.clone().add(remainder, "minutes");
  let max = min.clone().add(60, "days");

  const [newDeliveryDateTime, setDeliveryDateTime] = useState(new Date());

  //In order to dismiss the success or error alerts
  const [showPackageDeliveryRequestAlert, setShowPackageDeliveryRequestAlert] =
    useState(false);

  //In order to dismiss the error alert on package delivery Request Failure
  useEffect(() => {
    if (packageDeliveryEventRequestError) {
      setShowPackageDeliveryRequestAlert(true);
    } else {
      setShowPackageDeliveryRequestAlert(false);
    }
  }, [packageDeliveryEventRequestError]);

  const onSave = () => {
    deliveryData = {
      ...deliveryData,
      deliveryDateTime: newDeliveryDateTime,
    };
    // save package delivery
    setPackageDeliveryEvent(deliveryData);
  };

  const onModalClose = () => {
    hide();
    setDeliveryDateTime(null);
    setShowPackageDeliveryRequestAlert(false);
  };

  const onNewDeliveryDateChange = (dateTime) => {
    setDeliveryDateTime(dateTime);
  };

  if (packageDeliveryEventRequestSuccess) {
    hide();
  }

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        onModalClose();
      }}
    >
      <Modal.Header
        // Prevent closing modal while saving
        closeButton={!isPackageDeliveryEventLoading}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={
          <div data-qa="title-modal">
            {t("partview-details:Deliver Package")}
            {deliveryData ? ": " : null}
            {deliveryData?.trackingNumber}
            {isPackageDeliveryEventLoading ? (
              <Icon src={faSpinner} spin style={{ marginLeft: ".5em" }} />
            ) : null}
          </div>
        }
      />

      <Modal.Body>
        <div
          css={{
            pointerEvents:
              isPackageDeliveryEventLoading || packageDeliveryEventRequestError
                ? "none"
                : "default",
            opacity:
              isPackageDeliveryEventLoading || packageDeliveryEventRequestError
                ? 0.5
                : 1,
          }}
        >
          <div>
            {t(
              "partview-details:Select the date and time this package was delivered.",
              {
                service: t("partview-details:event"),
                // At this time, any AlertMe modal displayed "event" regardless of product.
                // Leaving commented code so we can look into it later.
                //
                // service: id
                //   ? t("partview-details:event")
                //   : t("partview-details:milestone")
              },
            )}{" "}
          </div>

          <div
            css={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "1em",
              padding: "1em",
            }}
          >
            {/* Delivery Date */}
            <div>
              <Form.Label htmlFor="checkbox-vin-subscription-email">
                <Text size={FontSize.size18}>
                  {t("partview-details:Delivery Date")}
                </Text>
                <DateTimePicker
                  css={{ width: "100%" }}
                  defaultCurrentDate={moment().startOf("day").toDate()}
                  value={newDeliveryDateTime}
                  onChange={onNewDeliveryDateChange}
                  format={dateTimeFormat}
                  max={max.toDate()}
                />
              </Form.Label>
            </div>
          </div>
        </div>

        <Alert
          show={
            showPackageDeliveryRequestAlert && packageDeliveryEventRequestError
          }
          variant={AlertVariant.Danger}
          onClose={() => setShowPackageDeliveryRequestAlert(false)}
          dismissible
          css={{ margin: 0 }}
        >
          <Text>
            {t(
              "partview-details:There was an error while setting package to delivered. Please try again later and contact FreightVerify.",
            )}
          </Text>
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Button
          variant="outline-secondary"
          style={{
            marginRight: "0.5em",
          }}
          disabled={isPackageDeliveryEventLoading}
          onClick={() => {
            onModalClose();
          }}
          data-qa="button-cancel-vin-subscription-modal"
        >
          {t("partview-details:Cancel")}
        </Button>
        <Button
          disabled={!newDeliveryDateTime || isPackageDeliveryEventLoading}
          variant="success"
          onClick={() => onSave()}
          data-qa="button-save-vin-subscription-modal"
        >
          {isPackageDeliveryEventLoading ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("partview-details:Save")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeliverPackageModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  deliveryData: PropTypes.object,
  setPackageDeliveryEvent: PropTypes.func,
  isPackageDeliveryEventLoading: PropTypes.bool,
  packageDeliveryEventRequestError: PropTypes.bool,
  packageDeliveryEventRequestSuccess: PropTypes.bool,
};
