import { connect } from "react-redux";
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import DriveAwaySearchBarState from "../../redux/DriveAwaySearchBarState";
import DriveAwaySavedSearchState from "../../redux/DriveAwaySavedSearchState";
import SearchBarContainer from "./DriveAway.SearchBar.container";
import FilterSectionContainer from "./DriveAway.SearchFilters.container";

const mapStateToProps = (state) => {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    DriveAwaySearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { saveSearch, editSearch } = DriveAwaySavedSearchState.actionCreators;

  return {
    saveSearch: (name, search) => dispatch(saveSearch(name, search)),
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSavedSearchModal);
