import _ from "lodash";
import { IoMdTimer } from "react-icons/io";
import { faTruckContainer } from "@fortawesome/pro-light-svg-icons";
import { faBan, faTirePressureWarning } from "@fortawesome/pro-solid-svg-icons";
import {
  faCarBattery,
  faGasPump,
  faUsdCircle,
} from "@fortawesome/pro-regular-svg-icons";

import hold from "assets/icons/hold.svg";
import dwell from "assets/icons/dwell.svg";
import { IconType } from "components/atoms/enums";
import { sortEntityExceptions } from "shared/utils/entity.utils";
import Colors from "styles/colors";

export const getExceptionData = (
  exceptions = [],
  exceptionTypes = [],
  entityCount = 0,
  isTypeRequired = false,
) => {
  // H2-2534: "Delayed" exception not displayed on FV homepage widgets
  const updatedExceptions = exceptionTypes
    .filter((exceptionType) => exceptionType.reasonCode !== "DL")
    .map((type) => {
      const exception = _.find(exceptions, (exc) => {
        // Default hasType to true (so that if isTypeRequired is set to false, this is effectively ignored)
        let hasType = true;
        // If we require a type to be specified
        if (isTypeRequired) {
          // Do a check against it
          hasType = exc.type === "ENTITY_EXCEPTION";
        }

        return exc.reason_code === type.reasonCode && hasType;
      });
      let count = exception?.count ?? 0;

      return {
        ...type,
        name: type.name === "In Hold" ? "On Hold" : type.name,
        count,
        percentage:
          entityCount === 0 ? 0 : ((count / entityCount) * 100).toFixed(1),
      };
    });

  return sortEntityExceptions(updatedExceptions, "name");
};

export const getExceptionChartColor = (exceptionName) => {
  switch (exceptionName) {
    case "Behind Schedule":
      return Colors.exceptions.BEHIND_SCHEDULE;
    case "Excessive Dwell":
      return Colors.exceptions.EXCESSIVE_DWELL;
    case "In Transit Stop Ship":
      return Colors.exceptions.IN_TRANSIT_STOP_SHIP;
    case "On Hold":
      return Colors.exceptions.ON_HOLD;
    case "In Hold":
      return Colors.exceptions.IN_HOLD;
    case "No Exception":
      return Colors.exceptions.NO_EXCEPTION;
    case "Spot Buy":
      return Colors.exceptions.SPOT_BUY;
    case "Tire Pressure":
    case "Fuel Level":
    case "Battery Level":
    default:
      return Colors.exceptions.DEFAULT;
  }
};

export const getIconData = (exceptionName) => {
  const iconColor = getExceptionChartColor(exceptionName);

  switch (exceptionName) {
    case "Behind Schedule":
      return {
        type: IconType.ReactIcons,
        src: IoMdTimer,
        color: iconColor,
      };
    case "On Hold":
    case "In Hold":
      return {
        type: IconType.LocalImage,
        src: hold,
        altText: exceptionName,
        color: iconColor,
      };
    case "Excessive Dwell":
      return {
        type: IconType.LocalImage,
        src: dwell,
        altText: exceptionName,
        color: iconColor,
      };
    case "Delivered":
      return {
        type: IconType.FontAwesome,
        src: faTruckContainer,
        color: iconColor,
      };
    case "In Transit Stop Ship":
      return {
        type: IconType.FontAwesome,
        src: faBan,
        color: iconColor,
        style: {
          width: 16,
          height: 16,
        },
      };
    case "Tire Pressure":
      return {
        type: IconType.FontAwesome,
        src: faTirePressureWarning,
        color: iconColor,
      };
    case "Fuel Level":
      return {
        type: IconType.FontAwesome,
        src: faGasPump,
        color: iconColor,
      };
    case "Battery Level":
      return {
        type: IconType.FontAwesome,
        src: faCarBattery,
        color: iconColor,
      };
    case "Spot Buy":
      return {
        type: IconType.FontAwesome,
        src: faUsdCircle,
        color: iconColor,
      };
    default:
      return null;
  }
};

export const translateExceptionName = (name, t) => {
  switch (name) {
    case "Behind Schedule":
      return t("exceptions:Behind Schedule");
    case "On Hold":
      return t("exceptions:On Hold");
    case "Excessive Dwell":
      return t("exceptions:Excessive Dwell");
    case "Aging VIN":
      return t("exceptions:Aging VIN");
    case "In Transit Stop Ship":
      return t("exceptions:In Transit Stop Ship");
    case "Spot Buy":
      return t("exceptions:Spot Buy");
    case "Tire Pressure":
      return t("exceptions:Tire Pressure");
    case "Fuel Level":
      return t("exceptions:Fuel Level");
    case "Battery Level":
      return t("exceptions:Battery Level");
    case "Delayed":
      return t("exceptions:Delayed");
    default:
      return name;
  }
};
