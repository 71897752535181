import axios from "axios";
import debouncePromsie from "debounce-promise";

/**
 * Create a redux state for async requests for use with SearchBar categories.
 *
 * @param {Object} config The configuration object to customize the paginated request.
 * @param {string} createPaginatedRequestConfig.topic - The key where data will be stored in redux state.
 * @param {string} createPaginatedRequestConfig.url - The request url
 * @param {string} createPaginatedRequestConfig.userQueryParam - The query parameter for the user string
 * @param {function(*): Array} createPaginatedRequestConfig.getResponseData - A function, given the response data, will return the list of results to use as options.
 * @param {function(*): *} createPaginatedRequestConfig.transformResult - A function, given a single result, will return the resulting option.
 * @param {number} createPaginatedRequestConfig.debounceWaitTimeInMs - If provided, the request promise will wait this amount of miliseconds before requesting again.
 * @returns {function(string, Array)} A function, given the user query and currently loaded transformed options, will request the next page of data.
 */
export function buildAsyncCategoryOptionsState({
  topic,
  url,
  queryParam = "query",
  getResponseData = (data) => data,
  transformResult = (data) => data,
  debounceWaitTimeInMs = 0,
}) {
  // Validating configuration inputs. topic and url are required.
  if (!topic) {
    throw new Error(
      "Missing required parameter `topic` in buildCategoryOptionsState",
    );
  }

  if (!url) {
    throw new Error(
      "Missing required parameter `url` in buildCategoryOptionsState",
    );
  }

  // Debouncing the axios request if configured to do so.
  let axiosGet = axios.get;
  if (debounceWaitTimeInMs > 0) {
    axiosGet = debouncePromsie(axios.get, debounceWaitTimeInMs);
  }

  // Action types

  const FETCH = `${topic}/FETCH_CATEGORY_OPTIONS`;
  const RECEIVE = `${topic}/RECEIVE_CATEGORY_OPTIONS`;
  const FETCH_ERROR = `${topic}/FETCH_ERROR_CATEGORY_OPTIONS`;

  // Actions

  /**
   * The fetch function will request the data and apply the transforms as configured.
   *
   * Note: pageSize comes from the SearchBar component. We limit the results with
   *       SearchBar's maxTypeaheadResults prop. We will ever only have that many results.
   *       See the callLazyLoadPropertyFetchIfItExists function in SearchBar.js.
   *
   * Note: We will never go beyond the first page.
   *       There is no infinite scroll for SearchBar's typeahead.
   *
   * @param {string} query The queryParam value.
   * @param {number} pageSize The page size for the request.
   * @returns
   */
  const fetch = (query = "", pageSize = 5) => {
    return (dispatch) => {
      dispatch({ type: FETCH });

      // Set up pagination parameters.
      const config = {
        params: {
          // Since our category typeahead isn't scrollable,
          // we will only ever see the first page of results.
          pageNumber: 0,
          pageSize,
        },
      };

      // If the user has a query, add the QSP for the request.
      // If we add it above, it will always be in the url.
      if (query?.length > 0) {
        config.params[queryParam] = query;
      }

      return axiosGet(url, config)
        .then((response) => {
          // Using getResponseData to get an array of results.
          const results = getResponseData(response.data) ?? [];

          dispatch({
            type: RECEIVE,
            // Using transformResult to get the desired format of the options.
            payload: results.map((result) => transformResult(result)),
          });
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: FETCH_ERROR });
        });
    };
  };

  // Selectors

  const getResults = (state) => {
    return state[topic].results ?? [];
  };

  const getIsLoading = (state) => {
    return state[topic].isLoading;
  };

  // Reducer

  const initialState = { results: [], isLoading: false };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH:
        return {
          results: [],
          isLoading: true,
        };
      case RECEIVE:
        return {
          results: action.payload,
          isLoading: false,
        };
      case FETCH_ERROR:
        return {
          results: [],
          isLoading: false,
        };
      default:
        return state;
    }
  };

  return {
    mountPoint: topic,
    actionCreators: { fetch },
    selectors: { getResults, getIsLoading },
    reducer,
  };
}
