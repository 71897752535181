/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DetailsTable from "components-old/DetailsTable";
import { DateTime } from "components/atoms/DateTime.atom";

const RowDetails = ({ row }) => {
  return (
    <tr>
      <td
        style={{
          textAlign: "left",
          fontWeight: "bold",
          verticalAlign: "middle",
          width: "30%",
        }}
      >
        <DateTime stack plain localize dateTime={row?.activatedAt}>
          <DateTime.Time bold />
          <DateTime.Timezone bold />
        </DateTime>
      </td>
      <td
        style={{
          textAlign: "left",
          verticalAlign: "middle",
          width: "30%",
        }}
      >
        {row?.name}
      </td>
      <td style={{ textAlign: "left", verticalAlign: "middle", width: "40%" }}>
        {row?.comments}
      </td>
    </tr>
  );
};

RowDetails.propTypes = {
  row: PropTypes.object,
};

export const SurgicalToteTrackingExceptionsTab = ({ sttActiveExceptions }) => {
  const { t } = useTranslation("surgical-tote-tracking");

  let headers = [
    { title: t("surgical-tote-tracking:Time"), centered: false },
    { title: t("surgical-tote-tracking:Active Exception"), centered: false },
    { title: t("surgical-tote-tracking:Exception Comments"), centered: false },
  ];

  const rows = sttActiveExceptions.map((row, index) => {
    return <RowDetails key={index} row={row} />;
  });

  return <DetailsTable headers={headers} rows={rows} />;
};

SurgicalToteTrackingExceptionsTab.propTypes = {
  sttActiveExceptions: PropTypes.array,
};
