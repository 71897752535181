/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { Suspense } from "react";
import SimpleBar from "simplebar-react";
import Link from "redux-first-router-link";
import { FlexColDiv, FlexDiv } from "../../styles/container-elements";
import LinkButton from "../../vendor/signal-widgets/components/link-button";
import { NavClickable } from "./components/NavClickable";
import PrimaryNav from "./components/PrimaryNav";
import { Icon, IconType } from "components/atoms/Icon.atom";
import logoHealthcare from "assets/logos/fv_systems_healthcare_logo.svg";
import logoPoweredBy from "assets/logos/fv_powered_light.svg";
import logoSm from "assets/logos/fv_mark_light.svg";
import logo from "assets/logos/fv_logo_light.svg";

import { useIsMediumAndDown } from "../../components/responsive";
import { OrganizationType } from "shared/constants/organization.const";
import AuthenticationUtils from "../auth/authentication";

import { MdAccountCircle } from "react-icons/md";

import Colors from "../../styles/colors";
import "simplebar/dist/simplebar.min.css";
import CustomerSupportContainer from "./components/CustomerSupport/CustomerSupportLink.container";

const ProfileLinkContainer = styled(FlexDiv)(
  {
    margin: "1em 0px 1em 0.75em",
  },
  (props) => {
    if (props.sidebarMinimize) {
      return { justifyContent: "center", margin: "1em 0 1em 0" };
    }
  },
);

const ProfileIconContainer = styled(FlexColDiv)(
  {
    justifyContent: "center",
  },
  (props) => {
    if (props.sidebarMinimize) {
      return { margin: 0 };
    } else {
      return { margin: "0 0.5em 0 0.25em" };
    }
  },
);

const BarFixed = styled(FlexDiv)({
  color: Colors.text.HIT_GRAY,
  backgroundColor: Colors.background.DARK_GRAY,
});

const SidebarFooterCol = styled(FlexColDiv)({
  justifyContent: "center",
  backgroundColor: Colors.highlight.DARK_GRAY,
});

const ProfileName = styled.div({
  color: Colors.text.HIT_GRAY,
  ":hover": { color: "white" },
  ":hover > .nav-rollover": { visibility: "visible" },
});

const SidebarProfileLink = ({ sidebarMinimize }) => {
  const { t } = useTranslation();

  const mediumAndDown = useIsMediumAndDown();
  const faderClass = classNames({
    fader: !sidebarMinimize && !mediumAndDown,
    "fader-hide": sidebarMinimize || mediumAndDown,
  });

  let userName = "";
  if (AuthenticationUtils.isAuthenticated()) {
    userName = AuthenticationUtils.userUsername;
  }

  return (
    <ProfileLinkContainer sidebarMinimize={sidebarMinimize}>
      <ProfileIconContainer sidebarMinimize={sidebarMinimize}>
        <Link to={{ type: "PROFILE" }} data-qa="link-profile-page">
          <span
            style={{
              color: Colors.background.DARK_TEAL,
            }}
          >
            <MdAccountCircle
              style={{
                height: "1.75em",
                width: "1.75em",
                ":hover": {
                  color: Colors.bannerLinkText,
                },
              }}
            />
          </span>
        </Link>
      </ProfileIconContainer>

      <div className={faderClass}>
        <FlexColDiv css={{ marginLeft: ".5em" }}>
          <FlexDiv>
            <Link to={{ type: "PROFILE" }} style={{ textDecoration: "none" }}>
              <ProfileName>{userName}</ProfileName>
            </Link>
          </FlexDiv>
          <FlexDiv>
            <LinkButton
              style={{
                color: Colors.linkText,
                fontSize: ".75em",
                textDecoration: "none",
                ":hover": {
                  color: Colors.bannerLinkText,
                  cursor: "pointer",
                  textDecoration: "none",
                },
              }}
              // Sending true to logout so so that, for federated users, we redirect to Session Ended page
              onClick={() => AuthenticationUtils.logout(true)}
              data-qa="link-logout"
            >
              {t("Log Out")}
            </LinkButton>
          </FlexDiv>
        </FlexColDiv>
      </div>
    </ProfileLinkContainer>
  );
};

SidebarProfileLink.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired,
};

const SidebarFooter = ({ sidebarMinimize }) => {
  return (
    <FlexColDiv className="sidebar-footer-col-div w-100">
      <SidebarFooterCol>
        <SidebarProfileLink sidebarMinimize={sidebarMinimize} />
      </SidebarFooterCol>
    </FlexColDiv>
  );
};

SidebarFooter.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired,
};

/**
 *
 * @param sidebarMinimize
 * @return {*}
 * @constructor
 */
const LogoContainer = ({
  sidebarMinimize,
  activeOrganization,
  organizationImageConfig,
}) => {
  const mediumAndDown = useIsMediumAndDown();

  const fade = classNames({
    fader: !sidebarMinimize && !mediumAndDown,
    "fader-hide": sidebarMinimize || mediumAndDown,
  });

  const fadeMobile = classNames({
    "fader-mobile": sidebarMinimize || mediumAndDown,
    "fader-mobile-hide": !sidebarMinimize || !mediumAndDown,
  });

  let logoSrc = logo;
  let iconSrc = logoSm;
  if (activeOrganization?.org_type === OrganizationType.HEALTHCAREPROVIDER) {
    logoSrc = logoHealthcare;
  } else if (organizationImageConfig?.logoPath) {
    logoSrc = organizationImageConfig.logoPath;
    iconSrc = organizationImageConfig.iconPath;
  }

  return (
    <div
      css={{
        backgroundColor: Colors.highlight.DARK_GRAY,
        cursor: !sidebarMinimize ? "pointer" : "auto",
        position: "relative",
        textAlign: "center",
        height: "3.3em",
        width: "100%",
      }}
    >
      <Icon
        type={IconType.LocalImage}
        src={logoSrc}
        className={fade}
        style={{ height: 42, width: 186, margin: "0 auto" }}
      />
      <Icon
        type={IconType.LocalImage}
        src={iconSrc}
        className={fadeMobile}
        style={{
          height: 42,
          width: 37,
          margin: "2px auto",
          borderRadius: "50%",
        }}
      />
    </div>
  );
};

LogoContainer.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired,
  activeOrganization: PropTypes.shape({
    org_type: PropTypes.string,
  }),
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
    iconPath: PropTypes.string,
  }),
};

const PoweredByFVContainer = ({ sidebarMinimize }) => {
  const mediumAndDown = useIsMediumAndDown();

  const fade = classNames({
    fader: !sidebarMinimize && !mediumAndDown,
    "fader-hide": sidebarMinimize || mediumAndDown,
  });

  const fadeMobile = classNames({
    "fader-mobile": sidebarMinimize || mediumAndDown,
    "fader-mobile-hide": !sidebarMinimize || !mediumAndDown,
  });

  return (
    <div>
      <Icon
        type={IconType.LocalImage}
        src={logoPoweredBy}
        className={fade}
        style={{ height: 35, width: 165, margin: "0 auto" }}
      />
      <Icon
        type={IconType.LocalImage}
        src={logoSm}
        className={fadeMobile}
        style={{ height: 42, margin: "0 auto" }}
      />
    </div>
  );
};

PoweredByFVContainer.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired,
};

export const Sidebar = ({
  activeOrganization,
  isLoginLoading,
  location,
  sidebarMinimize,
  setSidebarMinimize,
  organizationImageConfig,
}) => {
  let currentLocation = location.type || "";
  let isBranded = !!organizationImageConfig?.logoPath;

  return (
    <aside
      className={classNames("sidebar", {
        "sidebar-minimized": sidebarMinimize,
        "sidebar-normal": !sidebarMinimize,
      })}
      css={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
      }}
    >
      <BarFixed>
        <LogoContainer
          sidebarMinimize={sidebarMinimize}
          activeOrganization={activeOrganization}
          organizationImageConfig={organizationImageConfig}
        />

        <NavClickable
          sidebarMinimize={sidebarMinimize}
          setSidebarMinimize={setSidebarMinimize}
          iconClass={"sidebar-fa-show-chevron"}
        />
      </BarFixed>

      {/* Some overflow value is required on the parent of SimpleBar */}
      <BarFixed css={{ flexGrow: 1, overflow: "auto" }}>
        <SimpleBar
          autoHide={false}
          css={{
            width: "100%",
            height: "100%",
            // Raises the bar above it's content
            ".simplebar-track.simplebar-vertical": { zIndex: 1000 },
          }}
        >
          {!isLoginLoading ? (
            <PrimaryNav
              currentLocation={currentLocation}
              sidebarMinimize={sidebarMinimize}
            />
          ) : null}
          <Suspense fallback="">
            <CustomerSupportContainer sidebarMinimize={sidebarMinimize} />
          </Suspense>
        </SimpleBar>
      </BarFixed>

      <BarFixed css={{ overflow: "auto" }}>
        <SimpleBar
          autoHide={false}
          css={{
            width: "100%",
            height: "100%",
            ".simplebar-track.simplebar-vertical": { zIndex: 1000 },
          }}
        >
          {isBranded ? (
            <PoweredByFVContainer sidebarMinimize={sidebarMinimize} />
          ) : null}
        </SimpleBar>
      </BarFixed>

      <BarFixed>
        {!isLoginLoading ? (
          <Suspense fallback="">
            <SidebarFooter sidebarMinimize={sidebarMinimize} />
          </Suspense>
        ) : null}
      </BarFixed>
    </aside>
  );
};

Sidebar.propTypes = {
  activeOrganization: PropTypes.shape({
    org_type: PropTypes.string,
  }),
  isLoginLoading: PropTypes.bool,
  location: PropTypes.object,
  sidebarMinimize: PropTypes.bool,
  setSidebarMinimize: PropTypes.func,
  organizationImageConfig: PropTypes.shape({
    logoPath: PropTypes.string,
    iconPath: PropTypes.string,
  }),
};
