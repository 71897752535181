/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "components/atoms/Button.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { useTranslation } from "react-i18next";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";

const LocatioInformation = (props) => {
  const { t } = useTranslation("inventory-view");
  const { location, showForcastedColumn, iconColumnClick } = props;

  return (
    <div
      css={{
        padding: "1em",
        width: showForcastedColumn ? "100%" : "80%",
      }}
    >
      <div
        css={{
          paddingBottom: "1em",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            css={{
              display: "flex",
              paddingBottom: "1.25em",
              flexDirection: "column",
            }}
          >
            <Text bold size={FontSize.size16}>
              {t("inventory-view:Location Details")}
            </Text>
          </div>
          <div>
            <Text bold size={FontSize.size14}>
              {`${location.name}-${location.code}`}
            </Text>
            <LocationAddressComplex
              address1={location?.address}
              address2={location?.address1}
              city={location?.city}
              state={location?.state}
              country={location?.country}
              postalCode={location?.postal_code}
              defaultTextColor={Colors.text.DARK_GRAY}
              addressColor={Colors.text.DARK_GRAY}
            />
          </div>
        </div>

        <div>
          <div
            css={{
              display: "flex",
              paddingBottom: "1.25em",
              flexDirection: "column",
            }}
          >
            <Text bold size={FontSize.size16} color={Colors.text.DARK_GRAY}>
              {t("inventory-view:Capacity Stats")}
            </Text>
          </div>
          <div>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {`${t("inventory-view:Allocated Capacity")}: ${
                location.capacity?.totalAllocated ?? 0
              }`}
            </Text>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {`${t("inventory-view:Total Onsite")}: ${
                location.capacity?.onSite ?? 0
              }`}
            </Text>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {`${t("inventory-view:Available Capacity")}: ${
                location.capacity?.available ?? 0
              }`}
            </Text>
          </div>
          <div
            css={{
              textAlign: "center",
              marginTop: "1em",
              padding: ".75em",
              color: Colors.text.WHITE,
              background:
                (location.capacity?.totalAllocated ?? 0) -
                  (location.capacity?.onSite ?? 0) >=
                0
                  ? Colors.highlight.GREEN
                  : Colors.highlight.RED,
            }}
          >
            {(location.capacity?.totalAllocated ?? 0) -
              (location.capacity?.onSite ?? 0) >=
            0
              ? t("inventory-view:Under Capacity")
              : t("inventory-view:Over Capacity")}
          </div>
        </div>

        <div>
          <div
            css={{
              display: "flex",
              paddingBottom: "1.25em",
              flexDirection: "column",
            }}
          >
            <div>
              <Text bold size={FontSize.size16}>
                {t("inventory-view:Dwell Stats")}
              </Text>
              <Tooltip
                placement={"bottom"}
                tooltipChildren={
                  <Text>
                    <span>
                      {t("inventory-view:Aging")}
                      {" : "}
                      {t(
                        "inventory-view:VINs dwelling at a location past their acceptable time",
                      )}
                      <br />
                      {t("inventory-view:Dwelling")}
                      {" : "}
                      {t(
                        "inventory-view:VINs dwelling at a location within their acceptable time",
                      )}
                    </span>
                  </Text>
                }
              >
                <Icon
                  type={IconType.FontAwesome}
                  size={FontSize.size14}
                  src={faQuestionCircle}
                  css={{ marginLeft: 10 }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {`${t("inventory-view:Total Dwelling")}: ${
                location.dwell?.dwelling ?? 0
              }`}
            </Text>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {`${t("inventory-view:Total Aging")}: ${
                location.dwell?.aging ?? 0
              }`}
            </Text>
          </div>
        </div>

        <div>
          <div
            css={{
              display: "flex",
              paddingBottom: "1.25em",
              flexDirection: "column",
            }}
          >
            <div>
              <Text bold size={FontSize.size16}>
                {t("inventory-view:Total Non-Shippable")}
              </Text>
              <Tooltip
                placement={"bottom"}
                tooltipChildren={
                  <Text>
                    <span>
                      {t(
                        "inventory-view:The total non-shippable count describes the total number of onsite VINs that are not approved to be shipped to the final destination",
                      )}
                    </span>
                  </Text>
                }
              >
                <Icon
                  type={IconType.FontAwesome}
                  size={FontSize.size14}
                  src={faQuestionCircle}
                  css={{ marginLeft: 10 }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <Text block size={FontSize.size14} color={Colors.text.DARK_GRAY}>
              {location.nonShippable ?? 0}
            </Text>
          </div>
        </div>

        {showForcastedColumn ? (
          <div>
            <div
              css={{
                display: "flex",
                paddingBottom: "1.25em",
                flexDirection: "column",
              }}
            >
              <Text bold size={FontSize.size16}>
                {t("inventory-view:Inventory Forecast")}
              </Text>
            </div>
            <div>
              <div css={{ textAlign: "center" }}>
                <Button variant="primary" onClick={iconColumnClick}>
                  <Icon src={faAnalytics} size={2} />
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

LocatioInformation.propTypes = {
  location: PropTypes.object,
  showForcastedColumn: PropTypes.bool,
  iconColumnClick: PropTypes.func,
};

export default LocatioInformation;
