//External packages
/** @jsxImportSource @emotion/react */
import "moment-timezone";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

//External hooks or supporting code from React
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

//Components
import { Icon } from "components/atoms/Icon.atom";
import FormRow from "components-old/forms/FormRow";
import ValidationMessage from "components-old/forms/ValidationMessage";
import { getType } from "modules/geofence-edit/geofence-types";
import { checkValidation } from "pages/shipments/redux/CreateShipmentState";

//Styles
import { FlexColDiv } from "styles/container-elements";
import Colors from "styles/colors";

export const ShipmentFormControls = ({
  checkStopValidation,
  createShipment,
  createStatus,
  data,
  locations,
  organization,
  resetForm,
  validation,
}) => {
  const { t } = useTranslation("create-shipment");

  const getLocation = (id, locations) => {
    let loc;
    for (let key in locations) {
      // check if the property/key is defined in the object itself, not in parent
      if (locations.hasOwnProperty(key)) {
        loc = _.head(locations[key].filter((l) => l.id === id));
        if (loc) {
          return loc;
        }
      }
    }

    return null;
  };

  const getLocationDetails = (id) => {
    const loc = getLocation(id, locations);
    let locDetails = {
      locationId: {
        qualifier: "duns",
        value: loc.code ? loc.code : "",
      },
      name: loc.name ? loc.name : "",
      address1: loc.address ? loc.address : "",
      address2: "",
      city: loc.city ? loc.city : "",
      state: loc.state ? loc.state : "",
      postalCode: loc.postal_code ? loc.postal_code : "",
      country: loc.country ? loc.country : "",
    };

    // If there is a valid geofence for this location, add
    // the center coordinate into our object
    if (loc.geofence) {
      const fenceType = getType(loc.geofence);
      if (fenceType.isValid(loc.geofence)) {
        locDetails["latitude"] = loc.geofence.properties.center.latitude;
        locDetails["longitude"] = loc.geofence.properties.center.longitude;
      }
    }

    return locDetails;
  };

  const getStopDetails = (stop, i) => {
    return {
      stopSequence: i,
      stopType: stop.stopType,
      stopRole: stop.stopRole,
      earlyArrival: moment(stop.earlyArrival).tz(stop.timezone).format(),
      lateArrival: moment(stop.lateArrival).tz(stop.timezone).format(),
      location: getLocationDetails(stop.location),
    };
  };

  const getShipmentPayload = (data) => {
    const { destination, origin, stops } = data;

    let stopLocations = [];

    stopLocations.push(getStopDetails(origin, 1));

    if (stops.length > 0) {
      stops.forEach((stop, i) => {
        stopLocations.push(getStopDetails(stop, i + 2));
      });
    }
    stopLocations.push(getStopDetails(destination, stops.length + 2));

    let shipmentReferences = [];

    if (data.routeID !== "") {
      shipmentReferences.push({
        qualifier: "route_id",
        description: "",
        value: data.routeID,
      });
    }

    if (data.trailerID !== "") {
      shipmentReferences.push({
        qualifier: "trailer_number",
        description: "",
        value: data.trailerID,
      });
    }

    return {
      carrier: {
        fvCarrierId:
          organization && organization.fv_id ? organization.fv_id : "",
        scac: organization && organization.scac ? organization.scac : "",
      },
      telematics: {
        provider: "peoplenet",
        trackingAssetId: data.assetID !== "" ? data.assetID : "NOTAVAILABLE",
      },
      customer: {
        fvCustomerId: data.customer,
      },
      shipmentIdentification: {
        shipmentId: data.shipmentID,
      },
      shipmentReferences: shipmentReferences,
      stops: stopLocations,
    };
  };

  return (
    <FlexColDiv
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        color: Colors.text.DARK_BLUE,
        padding: "1em",
      }}
    >
      <FormRow
        css={{
          alignItems: "flex-start",
          display: "flex",
          paddingTop: ".5em",
        }}
      >
        <FlexColDiv css={{ alignItems: "flex-start", flex: 0.5 }}>
          <ValidationMessage
            isValid={validation.info ? validation.info.isValidCustomer : false}
            msg={t("create-shipment:Must have valid Customer/Shipper")}
          />
          <ValidationMessage
            isValid={
              validation.info ? validation.info.isValidShipmentId : false
            }
            msg={t("create-shipment:Must have valid Shipment ID")}
          />
        </FlexColDiv>
        <FlexColDiv css={{ alignItems: "flex-start", flex: 0.5 }}>
          <ValidationMessage
            isValid={
              validation.origin ? checkValidation(validation.origin) : false
            }
            msg={t("create-shipment:Must have valid Origin")}
          />
          <ValidationMessage
            isValid={
              validation.destination
                ? checkValidation(validation.destination)
                : false
            }
            msg={t("create-shipment:Must have valid Destination")}
          />
          {validation.stops && validation.stops.length > 0 ? (
            <ValidationMessage
              isValid={
                validation.stops ? checkStopValidation(validation.stops) : false
              }
              msg={t("create-shipment:Must have valid Stops")}
            />
          ) : null}
        </FlexColDiv>
      </FormRow>
      <FormRow
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Button variant="danger" onClick={() => resetForm()}>
          {t("create-shipment:Reset")}
        </Button>
        <Button
          variant="success"
          disabled={
            !(
              checkValidation(validation.origin) &&
              checkValidation(validation.destination) &&
              checkStopValidation(validation.stops)
            ) || createStatus === "IN_PROGRESS"
          }
          onClick={() => createShipment(getShipmentPayload(data))}
        >
          {t("create-shipment:Submit")}
          {createStatus === "IN_PROGRESS" ? (
            <Icon src={faSpinner} css={{ marginLeft: 5 }} spin={true} />
          ) : null}
        </Button>
      </FormRow>
    </FlexColDiv>
  );
};

ShipmentFormControls.propTypes = {
  checkStopValidation: PropTypes.func,
  createShipment: PropTypes.func,
  createStatus: PropTypes.string,
  data: PropTypes.object,
  locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  organization: PropTypes.object,
  resetForm: PropTypes.func.isRequired,
  validation: PropTypes.any,
};
