/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { ImageType } from "./enums";
import { useState } from "react";

/**
 * Displays an image, with a placeholder if no data is provided
 **/
export const Image = ({
  type = ImageType.Default,
  media,
  placeholder,
  altText,
  style = {},
  "data-qa": dataQa,
}) => {
  const vehicleImageStyles = {
    container: {
      backgroundColor: "#d0cfcf",
      border: "2px solid #d0cfcf",
      borderRadius: 4,
      maxWidth: 160,
      overflow: "hidden",
    },
    placeholderContainer: { padding: 10 },
    image: {
      width: "100%",
      objectFit: "contain",
    },
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);

  /* Keep the image hidden when it is loading or failed to load */
  const showPlaceholder = !media || isLoading || isErrored;
  const src =
    type === ImageType.Default ? media : `data:image/png;base64, ${media}`;

  const onError = (event) => {
    setIsErrored(true);
    setIsLoading(false);
  };

  const onLoad = (event) => {
    setIsLoading(false);
  };

  return (
    <div css={[vehicleImageStyles.container, style]} data-qa={dataQa}>
      {showPlaceholder ? (
        <div css={vehicleImageStyles.placeholderContainer}>{placeholder}</div>
      ) : null}
      <img
        hidden={showPlaceholder}
        css={vehicleImageStyles.image}
        src={src}
        alt={altText}
        onError={onError}
        onLoad={onLoad}
      />
    </div>
  );
};

Image.propTypes = {
  /**
   * The type of the image.
   */
  type: PropTypes.string,
  /**
   * The media of the image.
   */
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * placeholder of the image
   */
  placeholder: PropTypes.element,
  /**
   * alt of the image
   */
  altText: PropTypes.string,
  /**
   * style of the image
   */
  style: PropTypes.object,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
};

export { ImageType };
