/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";

export const NoChartData = ({ size = FontSize.size24, label }) => {
  return (
    <Text
      block
      size={size}
      color={Colors.background.GRAY_BLUE}
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        cursor: "default",
      }}
    >
      {label}
    </Text>
  );
};

NoChartData.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string.isRequired,
};
