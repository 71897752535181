import { connect } from "react-redux";
import SurgicalToteTrackingMyWatchedTotesState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingMyWatchedTotesState";
import { SurgicalToteTrackingMyWatchedTotes } from "./SurgicalToteTrackingMyWatchedTotes";

const mapStateToProps = (state) => {
  const {
    getWatchedTotes,
    getWatchedTotesLoading,
    getWatchedTotesPageIndex,
    getWatchedTotesPageSize,
    getWatchedTotesPageCount,
  } = SurgicalToteTrackingMyWatchedTotesState.selectors;

  return {
    watchedToteArray: getWatchedTotes(state),
    watchedTotesLoading: getWatchedTotesLoading(state),
    watchedTotesPageIndex: getWatchedTotesPageIndex(state),
    watchedTotesPageSize: getWatchedTotesPageSize(state),
    watchedTotesPageCount: getWatchedTotesPageCount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchWatchedTotes, setWatchedTotesPageIndex } =
    SurgicalToteTrackingMyWatchedTotesState.actionCreators;

  return {
    fetchWatchedTotes: () => dispatch(fetchWatchedTotes()),
    setWatchedTotesPageIndex: (pageIndex) =>
      dispatch(setWatchedTotesPageIndex(pageIndex)),
    setSelectedToteId: (uuid) =>
      dispatch({
        type: "SURGICAL_TOTE_TRACKING_DETAILS",
        payload: { id: uuid },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurgicalToteTrackingMyWatchedTotes);
