import axios from "axios";

import apiUrl from "api-url";
import moment from "moment";

// URLS
const STORE_MOUNT_POINT = "vinViewEntities";

// Actions

const VINVIEW_MP = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const REQUEST_ENTITY_COUNT = VINVIEW_MP("REQUEST_ENTITY_COUNT");
const RECEIVE_ENTITY_COUNT = VINVIEW_MP("RECEIVE_ENTITY_COUNT");
const REQUEST_DELIVERED_ENTITY_COUNT = VINVIEW_MP(
  "REQUEST_DELIVERED_ENTITY_COUNT",
);
const RECEIVE_DELIVERED_ENTITY_COUNT = VINVIEW_MP(
  "RECEIVE_DELIVERED_ENTITY_COUNT",
);
const REQUEST_NEW_ENTITY_COUNT = VINVIEW_MP("REQUEST_NEW_ENTITY_COUNT");
const RECEIVE_NEW_ENTITY_COUNT = VINVIEW_MP("RECEIVE_NEW_ENTITY_COUNT");
const REQUEST_WATCHED_ENTITIES = VINVIEW_MP("REQUEST_WATCHED_ENTITIES");
const RECEIVE_WATCHED_ENTITIES = VINVIEW_MP("RECEIVE_WATCHED_ENTITIES");
const REQUEST_WATCHED_ENTITIES_COUNT = VINVIEW_MP(
  "REQUEST_WATCHED_ENTITIES_COUNT",
);
const RECEIVE_WATCHED_ENTITIES_COUNT = VINVIEW_MP(
  "RECEIVE_WATCHED_ENTITIES_COUNT",
);
const SET_WATCHED_ENTITIES_PAGE_INDEX = VINVIEW_MP(
  "SET_WATCHED_ENTITIES_PAGE_INDEX",
);
const RECEIVE_WATCHED_ENTITIES_ERROR = VINVIEW_MP(
  "RECEIVE_WATCHED_ENTITIES_ERROR",
);
const RECEIVE_WATCHED_ENTITIES_COUNT_ERROR = VINVIEW_MP(
  "RECEIVE_WATCHED_ENTITIES_COUNT_ERROR",
);

// Action creators
function fetchEntityCount(isDealerOrg = true, dealerOrgId = null) {
  const countUrl = apiUrl(`/entity/internal`);

  const config = {
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
    params: {
      lifeCycleState: "Active,Prebuilt",
    },
  };

  if (!isDealerOrg) {
    config.params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    dispatch({
      type: REQUEST_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchNewEntityCount(isDealerOrg = true, dealerOrgId = null) {
  const countUrl = apiUrl(`/entity/internal`);

  const config = {
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
    params: {
      lifeCycleState: "Active",
      isNew: "t",
    },
  };

  if (!isDealerOrg) {
    config.params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    dispatch({
      type: REQUEST_NEW_ENTITY_COUNT,
    });

    return axios
      .get(`${countUrl}`, config)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_ENTITY_COUNT,
          payload: response.data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchWatchedVins(isDealerOrg = true, dealerOrgId = null) {
  const url = apiUrl(`/entity/internal`);
  const params = {
    watched: 1,
  };

  if (!isDealerOrg) {
    params.dealerOrgId = dealerOrgId;
  }

  return async (dispatch, getState) => {
    const state = getState();
    params.pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
    params.pageNumber = state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;

    dispatch({
      type: REQUEST_WATCHED_ENTITIES,
    });

    try {
      const response = await axios.get(url, { params });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES,
        payload: { watchedEntities: response.data.data },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_ERROR, error });
    }
  };
}

function fetchWatchedEntitiesTotalPages(
  isDealerOrg = true,
  dealerOrgId = null,
) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_WATCHED_ENTITIES_COUNT });

    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;

    const url = apiUrl(`/entity/internal`);
    const params = { watched: 1, pageNumber: 0, pageSize };
    const headers = { Accept: "application/json;version=count" };

    if (!isDealerOrg) {
      params.dealerOrgId = dealerOrgId;
    }

    try {
      const response = await axios.get(url, { params, headers });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES_COUNT,
        payload: { totalPages: response.data?.meta?.totalPages },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_COUNT_ERROR, error });
    }
  };
}

function setWatchedVinsPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_ENTITIES_PAGE_INDEX,
    payload: { pageIndex },
  };
}

function fetchEntityDeliveredCount(isDealerOrg = true, dealerOrgId = null) {
  const countUrl = apiUrl(`/entity/internal`);

  const config = {
    params: {
      lifeCycleState: "Delivered",
    },
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
  };

  if (!isDealerOrg) {
    config.params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    dispatch({
      type: REQUEST_DELIVERED_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_DELIVERED_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Asynchronously return location search results for <Async> select component
function searchVINs(query) {
  if (query.length < 2) {
    return {
      options: [],
      hasMore: false,
    };
  }

  const url = apiUrl(
    `/entity/internal?entityId=${query}&pageNumber=0&pageSize=20&lifeCycleState=Active,Delivered&watched=1`,
  );

  return axios
    .get(url)
    .then((response) => {
      if (
        !response ||
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        return { options: [], hasMore: false };
      } else {
        const vinOptions = buildSearchVinOptions(response.data.data);
        return {
          options: vinOptions,
          hasMore: false,
        };
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

// Convert location search response data into options for <Async> select and typeahead components
export function buildSearchVinOptions(data) {
  return data.map((l) => {
    try {
      return {
        value: l.id,
        label: l.id,
        id: l.id,
      };
    } catch (e) {
      console.error(e);
      return { options: [], hasMore: false };
    }
  });
}

// Selectors
const getEntityCount = (state) => state[STORE_MOUNT_POINT].entityCount ?? 0;
const getEntityCountLoading = (state) =>
  state[STORE_MOUNT_POINT].entityCountLoading;

const getNewEntityCount = (state) =>
  state[STORE_MOUNT_POINT].newEntityCount ?? 0;
const getNewEntityCountLoading = (state) =>
  state[STORE_MOUNT_POINT].newEntityCountLoading;

const getEntityDeliveredCount = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCount ?? 0;
const getEntityCountDeliveredLoading = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCountLoading;

const getWatchedVins = (state) => state[STORE_MOUNT_POINT].watchedEntities;
const getWatchedVinsLoading = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesLoading ||
  state[STORE_MOUNT_POINT].watchedEntitiesCountLoading;

const getWatchedVinsPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;
const getWatchedVinsPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
const getWatchedVinsPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageCount;

// Initial state
const initialState = {
  entityCount: 0,
  entityCountLoading: false,
  newEntityCount: 0,
  newEntityCountLoading: false,
  watchedEntities: [],
  watchedEntitiesLoading: false,
  watchedEntitiesCountLoading: false,
  watchedEntitiesPageIndex: 0,
  watchedEntitiesPageSize: 10,
  watchedEntitiesPageCount: 0,
  entityDeliveredCount: 0,
};

const VinViewEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_COUNT:
      return {
        ...state,
        entityCountLoading: true,
      };

    case RECEIVE_ENTITY_COUNT:
      return {
        ...state,
        entityCount: action.payload,
        entityCountLoading: false,
      };

    case REQUEST_NEW_ENTITY_COUNT:
      return {
        ...state,
        newEntityCountLoading: true,
      };

    case RECEIVE_NEW_ENTITY_COUNT:
      return {
        ...state,
        newEntityCount: action.payload,
        newEntityCountLoading: false,
      };

    case REQUEST_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCountLoading: true,
      };

    case RECEIVE_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCount: action.payload,
        entityDeliveredCountLoading: false,
      };

    case REQUEST_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntitiesLoading: true,
      };

    case REQUEST_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesCountLoading: true,
      };

    case RECEIVE_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntities: action.payload.watchedEntities,
        watchedEntitiesLoading: false,
      };

    case RECEIVE_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesPageCount: action.payload.totalPages,
        watchedEntitiesCountLoading: false,
      };

    case SET_WATCHED_ENTITIES_PAGE_INDEX:
      return {
        ...state,
        watchedEntitiesPageIndex: action.payload.pageIndex,
      };

    default:
      return state;
  }
};

// interface
const VinViewEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityCount,
    fetchNewEntityCount,
    fetchEntityDeliveredCount,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    setWatchedVinsPageIndex,
    searchVINs,
  },
  selectors: {
    getEntityCount,
    getEntityCountLoading,
    getNewEntityCount,
    getNewEntityCountLoading,
    getEntityDeliveredCount,
    getEntityCountDeliveredLoading,
    getWatchedVins,
    getWatchedVinsLoading,
    getWatchedVinsPageIndex,
    getWatchedVinsPageSize,
    getWatchedVinsPageCount,
  },
  reducer: VinViewEntityReducer,
};
export default VinViewEntitiesState;
