/** @jsxImportSource @emotion/react */
//External packages
import PropTypes from "prop-types";

//External hooks or supporting code from React
import { useTranslation } from "react-i18next";

//Components
import FormRow from "components-old/forms/FormRow";
import StandardInput from "components-old/forms/inputs/StandardInput";
import SelectInput from "components-old/forms/inputs/SelectInput";
import { SHIPPERS } from "pages/shipments/redux/CreateShipmentState";
import { FlexColDiv, FlexDiv } from "styles/container-elements";

export const ShipmentInfoForm = ({ data, updateField }) => {
  const { t } = useTranslation("create-shipment");

  return (
    <FlexColDiv style={{ marginTop: "1em", marginBottom: "1em" }}>
      <FormRow>
        <SelectInput
          label={
            <span>
              {t("create-shipment:Customer/Shipper")}
              <sup>*</sup>
            </span>
          }
          options={SHIPPERS}
          value={data.customer}
          onChange={(value) => updateField("customer", value)}
          borderColor="#ced4da"
        />

        <StandardInput
          label={
            <span>
              {t("create-shipment:Shipment ID")}
              <sup>*</sup>
            </span>
          }
          value={data.shipmentID}
          onChange={(value) => updateField("shipmentID", value)}
        />
        <StandardInput
          label={t("create-shipment:Asset ID")}
          value={data.assetID}
          onChange={(value) => updateField("assetID", value)}
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("create-shipment:Trailer ID")}
          value={data.trailerID}
          onChange={(value) => updateField("trailerID", value)}
        />
        <StandardInput
          label={t("create-shipment:Route ID")}
          value={data.routeID}
          onChange={(value) => updateField("routeID", value)}
        />
        <FlexDiv css={{ flex: 1, marginLeft: "1em" }} />
      </FormRow>
      <FormRow
        style={{ marginLeft: "1em", marginRight: "1em", marginTop: "1em" }}
        divider
      />
    </FlexColDiv>
  );
};

ShipmentInfoForm.propTypes = {
  data: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};
