import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { buildLocationAsyncFilterOptionsState } from "pages/administration/location-management/utils/buildLocationAsyncFilterOptionsState";
import {
  currentPositionCodesUrl,
  productTypesUrl,
  lastMilestoneUrl,
  soldToUrl,
  finCodeUrl,
  endUserFinCodeUrl,
  dealerRegionUrl,
  dealerZoneUrl,
  dealerDistrictUrl,
  partnersUrl,
} from "modules/domain-data/FinVehicleDomainData";

export const originOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "originShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const destinationOptionsState = buildLocationAsyncFilterOptionsState({
  topic: "destinationShipmentFilter",
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvCurrentPositionCodeFilter",
  getUrl: currentPositionCodesUrl,
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvProductTypeFilter",
  getUrl: productTypesUrl,
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const lastMilestoneOptionsState = buildAsyncFilterOptionsState({
  topic: "lastMilestone",
  getUrl: lastMilestoneUrl,
  getResponseData: (data) => data?.lastMilestone ?? [],
  transformResult: (option) => ({
    label: `${option.codeDescription} (${option.code})`,
    value: `${option.codeDescription} (${option.code})`,
  }),
});

export const soldToOptionsState = buildAsyncFilterOptionsState({
  topic: "fvSoldToFilter",
  getUrl: soldToUrl,
  getResponseData: (data) => {
    return data?.["ref:soldToDealer"] ?? [];
  },
  transformResult: (result) => {
    const label = `(${result.sold_to_code}) ${
      result.sold_to_name ? result.sold_to_name : ""
    }`;
    return { label: label, value: result.sold_to_code };
  },
});

export const finCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvFinCodeFilter",
  getUrl: finCodeUrl,
  getResponseData: (data) => {
    return data?.["ref:FinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const endUserFinCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "fvEndUserFinCodeFilter",
  getUrl: endUserFinCodeUrl,
  getResponseData: (data) => {
    return data?.["ref:EndUserFinCode"] ?? [];
  },
  transformResult: (result) => {
    const label = `${result.name ? result.name : ""} (${result.code})`;
    return { label: label, value: result.code };
  },
});

export const dealerRegionOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerRegionFilter",
  getUrl: dealerRegionUrl,
  getResponseData: (data) => data?.dealerRegion ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const dealerZoneOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerZoneFilter",
  getUrl: dealerZoneUrl,
  getResponseData: (data) => data?.dealerZone ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const dealerDistrictOptionsState = buildAsyncFilterOptionsState({
  topic: "fvDealerDistrictFilter",
  getUrl: dealerDistrictUrl,
  getResponseData: (data) => data?.dealerDistrict ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const partnersOptionsState = buildAsyncFilterOptionsState({
  topic: "fvPartnersFilter",
  getUrl: partnersUrl,
  getResponseData: (data) => data?.partners ?? [],
  transformResult: (option) => ({
    label: option.org_name,
    value: option.fv_id,
  }),
});
