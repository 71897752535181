import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import PartViewSearchBarState from "pages/partview/redux/PartViewSearchBarState";

import { FILTERS } from "./PartView.searchOptions";

function mapStateToProps(state) {
  return {
    searchFilters: PartViewSearchBarState.selectors.getSearchFilters(state),
    hasSearchCriteriaChanged:
      PartViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
    areAllPrerequisiteFiltersSelected:
      PartViewSearchBarState.selectors.getAreAllPrerequisiteFiltersSelected(
        state,
      ),
    canUserSearch: PartViewSearchBarState.selectors.getCanUserSearch(state),
  };
}

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = PartViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    filtersMetadata: FILTERS,
  };
}

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
