/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { Alert as BootstrapAlert } from "react-bootstrap";

import { AlertVariant } from "./enums";

export const Alert = ({
  show = false,
  variant = AlertVariant.Primary,
  dismissible = false,
  onDismiss = _.noop,
  style = {},
  className = "",
  "data-qa": dataQa = "",
  children,
  ...restProps
}) => {
  return (
    <BootstrapAlert
      show={show}
      variant={variant}
      dismissible={dismissible}
      onClose={onDismiss}
      style={style}
      className={className}
      data-qa={dataQa}
      {...restProps}
    >
      {children}
    </BootstrapAlert>
  );
};

Alert.propTypes = {
  /**
   * The current visibility of the Alert.
   */
  show: PropTypes.bool,
  /**
   * The Alert variant, which defines it's style.
   *
   * Use the `AlertVariant` enum for this value.
   */
  variant: PropTypes.string,
  /**
   * Allows the user to dismiss the alert.
   *
   * Note: Be sure to implement the onDismiss function to set the show value to false.
   */
  dismissible: PropTypes.bool,
  /**
   * Event callback for when the Alert is dismissed.
   */
  onDismiss: PropTypes.func,
  /**
   * An object that will be used as the element styles for the Alert.
   */
  style: PropTypes.object,
  /**
   * A string for defining one or more classes that will be added to the Alert.
   */
  className: PropTypes.string,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
  /**
   * The content inside the Alert.
   */
  children: PropTypes.any.isRequired,
};

export { AlertVariant };
