import { connect } from "react-redux";
import RouteManagementSearch from "./ContainerManagement.page.js";
import ContainerTrackingRouteManagementSearchBarState from "../redux/RouteManagementSearchBarState";
import ContainerTrackingManagementSearchBarState from "../redux/ContainerManagementSearchBarState";

function mapStateToProps(state) {
  return {
    routeSearchResults:
      ContainerTrackingRouteManagementSearchBarState.selectors.getSearchResults(
        state,
      ),
    searchResults:
      ContainerTrackingManagementSearchBarState.selectors.getSearchResults(
        state,
      ),
    routeIsLoading:
      ContainerTrackingRouteManagementSearchBarState.selectors.getIsLoading(
        state,
      ),
    isLoading:
      ContainerTrackingManagementSearchBarState.selectors.getIsLoading(state),
    routePage:
      ContainerTrackingRouteManagementSearchBarState.selectors.getPage(state),
    page: ContainerTrackingManagementSearchBarState.selectors.getPage(state),
    routePageSize:
      ContainerTrackingRouteManagementSearchBarState.selectors.getPageSize(
        state,
      ),
    pageSize:
      ContainerTrackingManagementSearchBarState.selectors.getPageSize(state),
    routeTotalPages:
      ContainerTrackingRouteManagementSearchBarState.selectors.getTotalPages(
        state,
      ),
    totalPages:
      ContainerTrackingManagementSearchBarState.selectors.getTotalPages(state),
    savedStatus:
      ContainerTrackingRouteManagementSearchBarState.selectors.getSavedState(
        state,
      ),
    isSaving:
      ContainerTrackingRouteManagementSearchBarState.selectors.getIsSaving(
        state,
      ),
    batchUploadResult:
      ContainerTrackingRouteManagementSearchBarState.selectors.getBatchUploadResult(
        state,
      ),
    batchUploadError:
      ContainerTrackingRouteManagementSearchBarState.selectors.getBatchUploadError(
        state,
      ),
    batchUploadLoading:
      ContainerTrackingRouteManagementSearchBarState.selectors.getBatchUploadLoading(
        state,
      ),
    savedCases:
      ContainerTrackingRouteManagementSearchBarState.selectors.getSavedCases(
        state,
      ),
    failure:
      ContainerTrackingRouteManagementSearchBarState.selectors.getFailedCases(
        state,
      ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRoutePagination: (page, pageSize) =>
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setContainerPagination: (page, pageSize) =>
      dispatch(
        ContainerTrackingManagementSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    resetSavedCases: () => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.resetSavedCases(),
      );
    },
    resetFailedCases: () => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.resetFailedCases(),
      );
    },
    resetSavedStatus: () =>
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.resetSavedStatus(),
      ),
    batchUploadAction: (payload) => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.batchUploadAction(
          payload,
        ),
      );
    },
    clearBatchUpload: () => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.clearBatchUpload(),
      );
    },
    searchRouteEntities: () => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.searchEntities(),
      );
    },
    searchContainerEntities: () => {
      dispatch(
        ContainerTrackingManagementSearchBarState.actionCreators.searchEntities(),
      );
    },
    updateData: (
      newBucketName,
      bucketName,
      addedLocations,
      deletedLocations,
      addedContainers,
      deletedContainers,
      isNew,
    ) => {
      dispatch(
        ContainerTrackingRouteManagementSearchBarState.actionCreators.updateData(
          newBucketName,
          bucketName,
          addedLocations,
          deletedLocations,
          addedContainers,
          deletedContainers,
          isNew,
        ),
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteManagementSearch);
