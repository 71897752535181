import jwtDecode from "jwt-decode";

/**
 * Attempts to decode a JSON Web Token string. If it fails, this will return `null`.
 *
 * @param {string} jwtString The token string to decode.
 * @returns The decoded token
 */
export function decodeJsonWebToken(jwtString) {
  let decoded;
  try {
    decoded = jwtDecode(jwtString);
  } catch {
    // Decoding failed; set some default value.
    decoded = null;
  }
  return decoded;
}
