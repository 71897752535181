/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LocationMatchingControls = ({
  onCreateLocationClick,
  onCancelClick,
  onUpdateClick,
  enableUpdate,
  locationLinking = false,
}) => {
  const { t } = useTranslation("location-matching");

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      {!locationLinking ? (
        <Button
          variant="success"
          style={{
            margin: "0.5em",
            padding: "0.75em",
            paddingLeft: "1.0em",
            paddingRight: "1.0em",
          }}
          onClick={onCreateLocationClick}
        >
          {t("location-matching:Create New Location")}
        </Button>
      ) : null}

      <Button
        variant="outline-primary"
        style={{
          margin: "0.5em",
          padding: "0.75em",
          paddingLeft: "3.0em",
          paddingRight: "3.0em",
        }}
        onClick={onCancelClick}
      >
        {t("location-matching:Cancel")}
      </Button>

      <Button
        variant="primary"
        disabled={!enableUpdate}
        style={{
          margin: "0.5em",
          leftPadding: "2.0em",
          paddingLeft: "1.0em",
          paddingRight: "1.0em",
        }}
        onClick={onUpdateClick}
      >
        {!locationLinking
          ? t("location-matching:Update Location")
          : t("location-matching:Link Location")}
      </Button>
    </div>
  );
};

LocationMatchingControls.propTypes = {
  onCreateLocationClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  locationLinking: PropTypes.bool,
};

export default LocationMatchingControls;
