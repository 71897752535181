/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { useTranslation } from "react-i18next";
import {
  useSetSubTitle,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import RoutingMap from "modules/map/components/RoutingMap";
import { PublicShipmentContentPanel } from "./components/PublicShipmentContentPanel";
import {
  PublicMultimodalShipmentContentPanel,
  PublicMultimodalShipmentEndToEndTabPanel,
} from "./components/PublicMultimodalShipmentContentPanel";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import { isCarrier } from "shared/utils/organizations.utils";
import { getRouteShipments } from "modules/shipment-detail/ShipmentUtils";

export const PublicShipmentDetailPage = (props) => {
  const {
    shipmentId,
    shipment,
    fetchShipmentDetails,
    childShipments,
    routeHeatmap,
    activeOrganization,
    isLoaded,
  } = props;

  const { t } = useTranslation("shipment-details");
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [selectedLegId, setSelectedLegId] = useState(null);

  useSetTitleOnMount(t("shipment-details:Shipment Detail"));
  useSetSubTitle(shipment?.creator_shipment_id, shipment?.creator_shipment_id);
  useTrackWithMixpanelOnce("Viewed Page: Shipments / Details");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");

    fetchShipmentDetails({
      shipmentID: shipmentId,
      token: token,
    });
  }, [fetchShipmentDetails, shipmentId]);

  let routeShipments = getRouteShipments(
    shipment,
    childShipments,
    selectedLegId,
    isLoaded,
  );

  const isMultiLeg = shipment?.shipment_details?.is_multileg ?? false;

  const heatmapCoords =
    routeHeatmap?.length > 0
      ? routeHeatmap
      : _.get(shipment, "current_location.updates");

  let detailsChildren = null;
  if (isMultiLeg) {
    if (isCarrier(activeOrganization)) {
      detailsChildren = (
        <PublicMultimodalShipmentEndToEndTabPanel
          parentShipment={shipment}
          childShipments={childShipments}
          organization={activeOrganization}
        />
      );
    } else {
      detailsChildren = (
        <PublicMultimodalShipmentContentPanel
          parentShipment={shipment}
          childShipments={childShipments}
          organization={activeOrganization}
          setSelectedLegId={setSelectedLegId}
        />
      );
    }
  } else {
    detailsChildren = (
      <PublicShipmentContentPanel
        shipment={shipment}
        organization={activeOrganization}
      />
    );
  }

  return (
    <DetailWithMap
      mapChildren={
        <Fragment>
          <RoutingMap
            shipments={routeShipments}
            showBreadCrumbs={true}
            showStopSequence={true}
            showHeatmap={showHeatmap}
            heatmapCoords={heatmapCoords}
            isMultiLeg={isMultiLeg}
            activeShipment={
              isMultiLeg
                ? shipment && shipment.active_shipment
                  ? shipment.active_shipment.toString()
                  : null
                : null
            }
          />
          {heatmapCoords?.length > 0 ? (
            <HeatMapButton
              isToggled={showHeatmap}
              onToggle={() => setShowHeatmap(!showHeatmap)}
              css={{
                position: "absolute",
                top: 5,
                right: 0,
                zIndex: 2,
              }}
            />
          ) : null}
        </Fragment>
      }
      detailsChildren={<Loader loaded={isLoaded}>{detailsChildren}</Loader>}
    />
  );
};

PublicShipmentDetailPage.propTypes = {
  shipmentId: PropTypes.string,
  shipment: PropTypes.object,
  fetchShipmentDetails: PropTypes.func.isRequired,
  childShipments: PropTypes.object,
  routeHeatmap: PropTypes.array,
  activeOrganization: PropTypes.object,
  isLoaded: PropTypes.bool.isRequired,
};
