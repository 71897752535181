import {
  SelectFilterButton,
  BatchFilterButton,
  DateRangeFilterButton,
  AsyncSelectFilterButton,
  MultiSelectFilterButton,
} from "../../components/search-bar/FilterButtons";
import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getLocationQueryString,
  getMultiSelectQueryString,
  getEmptyQueryString,
  getEntityDateRangeQueryString,
} from "../../components/search-bar/search-filter-query-strings";
import {
  originOptionsState,
  destinationOptionsState,
  productTypeOptionsState,
} from "./ConnectedCarFilterLoaders";
import {
  batchCsvVinExample,
  batchCsvProductTypeExample,
  batchCsvOrderNumberExample,
  batchCsvLast8ofVinExample,
} from "components/search-bar/batch-comment-csv-data";

export const FILTERS = [
  {
    queryKey: "originLocation",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("connected-car:Origin"),
    optionsState: originOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("originId", filterValue, { valueKey: "code" }),
  },
  {
    queryKey: "destinationLocation",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("connected-car:Destination"),
    optionsState: destinationOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destinationId", filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("connected-car:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "lifeCycleState",
    label: (t) => t("connected-car:VIN Status"),
    optionsGetter: (props) => props.getLifeCycleOptions,
    Component: SelectFilterButton,
    queryBuilder: getBasicQueryString,
    hideSelectEmpty: true,
  },
  /*
  // H2-3467: Remove city filter due to performance issues
  // Below are all imports and the city filter definition for easy reimplementation later on
  import { AsyncSelectFilterButton } from "../../components/search-bar/FilterButtons";
  import { getMultiSelectQueryString } from "../../components/search-bar/search-filter-query-strings";
  import { connectedCarCityOptionsState } from "./ConnectedCarFilterLoaders";
  {
    queryKey: "positionUpdateCity",
    label: t => t("connected-car:City"),
    optionsState: connectedCarCityOptionsState,
    Component: AsyncSelectFilterButton,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue)
  },
  */
  {
    queryKey: "positionUpdateState",
    label: (t) => t("connected-car:State"),
    optionsGetter: (props) => props.statesFilterOptions,
    Component: SelectFilterButton,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString(
        queryParameter,
        filterValue ? [filterValue] : [],
      ),
  },
  {
    queryKey: "positionUpdate",
    label: (t) => t("connected-car:Timestamp"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    dateTypeOptions: () => [],
    queryBuilder: getEntityDateRangeQueryString,
  },
  //NGVAS Status Code filter will change after API update
  {
    queryKey: "ngavsStatusCode",
    label: (t) => t("connected-car:NGAVS Status Code"),
    optionsGetter: (props) => props.getNgavsStatusCodeOptions,
    Component: MultiSelectFilterButton,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    requiredEntitySystemConfig: [{ key: "NGAVS_STATUS_CODE", value: "true" }],
  },
  {
    queryKey: "batch",
    label: (t) => t("connected-car:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("connected-car:VIN"),
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
        {
          label: t("connected-car:Product Type"),
          value: "description",
          batchCsvExample: batchCsvProductTypeExample,
        },
        {
          label: t("connected-car:Last 8 of VIN"),
          value: "last_8_of_vin",
          batchCsvExample: batchCsvLast8ofVinExample,
        },
        {
          label: t("connected-car:Order Number"),
          value: "OrderNumber",
          batchCsvExample: batchCsvOrderNumberExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
];
