import axios from "axios";
import apiUrl from "api-url";
import moment from "moment";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { isShipper } from "shared/utils/organizations.utils";

const STORE_MOUNT_POINT = "driveAwayEntities";

// URLS
const getEntityInternalUrl = () =>
  apiUrl(
    `/entity/internal?includeConfigurations=true&pageNumber=0&pageSize=20&ddaFilter=1`,
  );
const getEntitySolutionUrl = (solutionId) =>
  apiUrl(
    `/entity/solution/${solutionId}/entity?includeConfigurations=true&pageNumber=0&pageSize=20&ddaFilter=1`,
  );

// Actions
const getDriveAwayActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;

const REQUEST_AVAILABLE_ENTITY_COUNT = getDriveAwayActionName(
  "REQUEST_AVAILABLE_ENTITY_COUNT",
);

const RECEIVE_AVAILABLE_ENTITY_COUNT = getDriveAwayActionName(
  "RECEIVE_AVAILABLE_ENTITY_COUNT",
);

const REQUEST_SUBMITTED_ENTITY_COUNT = getDriveAwayActionName(
  "REQUEST_SUBMITTED_ENTITY_COUNT",
);

const RECEIVE_SUBMITTED_ENTITY_COUNT = getDriveAwayActionName(
  "RECEIVE_SUBMITTED_ENTITY_COUNT",
);

const REQUEST_APPROVED_ENTITY_COUNT = getDriveAwayActionName(
  "REQUEST_APPROVED_ENTITY_COUNT",
);

const RECEIVE_APPROVED_ENTITY_COUNT = getDriveAwayActionName(
  "RECEIVE_APPROVED_ENTITY_COUNT",
);

const REQUEST_DENIED_ENTITY_COUNT = getDriveAwayActionName(
  "REQUEST_DENIED_ENTITY_COUNT",
);

const RECEIVE_DENIED_ENTITY_COUNT = getDriveAwayActionName(
  "RECEIVE_DENIED_ENTITY_COUNT",
);

const REQUEST_DELIVERED_ENTITY_COUNT = getDriveAwayActionName(
  "REQUEST_DELIVERED_ENTITY_COUNT",
);

const RECEIVE_DELIVERED_ENTITY_COUNT = getDriveAwayActionName(
  "RECEIVE_DELIVERED_ENTITY_COUNT",
);

// Action creators
function fetchEntityCountForEachStatus() {
  const config = {
    headers: {
      Accept: "application/json;version=dda_count",
      "x-time-zone": moment.tz.guess(),
    },
  };

  return (dispatch, getState) => {
    const state = getState();
    const solutionId = getSolutionId(state);
    const isShipperRole = isShipper(state.organizations.activeOrganization);

    let countUrl = getEntityInternalUrl();
    if (solutionId && isShipperRole) {
      countUrl = getEntitySolutionUrl(solutionId);
    }

    dispatch({
      type: REQUEST_AVAILABLE_ENTITY_COUNT,
    });

    dispatch({
      type: REQUEST_SUBMITTED_ENTITY_COUNT,
    });

    dispatch({
      type: REQUEST_APPROVED_ENTITY_COUNT,
    });

    dispatch({
      type: REQUEST_DENIED_ENTITY_COUNT,
    });

    dispatch({
      type: REQUEST_DELIVERED_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_AVAILABLE_ENTITY_COUNT,
          payload: responses[0].data.data.Available,
        });

        dispatch({
          type: RECEIVE_SUBMITTED_ENTITY_COUNT,
          payload: responses[0].data.data.Submitted,
        });

        dispatch({
          type: RECEIVE_APPROVED_ENTITY_COUNT,
          payload: responses[0].data.data.Approved,
        });

        dispatch({
          type: RECEIVE_DENIED_ENTITY_COUNT,
          payload: responses[0].data.data.Denied,
        });

        dispatch({
          type: RECEIVE_DELIVERED_ENTITY_COUNT,
          payload: responses[0].data.data.Delivered,
        });
      })
      .catch((err) => {
        // throw new Error(err);
        console.log(err);
      });
  };
}

// Selectors
const getEntityAvailableCount = (state) =>
  state[STORE_MOUNT_POINT].entityAvailableCount ?? 0;
const getEntityCountAvailableLoading = (state) =>
  state[STORE_MOUNT_POINT].entityAvailableCountLoading;
const getEntitySubmittedCount = (state) =>
  state[STORE_MOUNT_POINT].entitySubmittedCount ?? 0;
const getEntityCountSubmittedLoading = (state) =>
  state[STORE_MOUNT_POINT].entitySubmittedCountLoading;
const getEntityApprovedCount = (state) =>
  state[STORE_MOUNT_POINT].entityApprovedCount ?? 0;
const getEntityCountApprovedLoading = (state) =>
  state[STORE_MOUNT_POINT].entityApprovedCountLoading;
const getEntityDeniedCount = (state) =>
  state[STORE_MOUNT_POINT].entityDeniedCount ?? 0;
const getEntityCountDeniedLoading = (state) =>
  state[STORE_MOUNT_POINT].entityDeniedCountLoading;

// Initial state
const initialState = {
  entityAvailableCount: 0,
  entityAvailableCountLoading: false,
  entitySubmittedCount: 0,
  entitySubmittedCountLoading: false,
  entityApprovedCount: 0,
  entityApprovedCountLoading: false,
  entityDeniedCount: 0,
  entityDeniedCountLoading: false,
  entityDeliveredCount: 0,
};

const DriveAwayEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AVAILABLE_ENTITY_COUNT:
      return {
        ...state,
        entityAvailableCountLoading: true,
      };

    case RECEIVE_AVAILABLE_ENTITY_COUNT:
      return {
        ...state,
        entityAvailableCount: action.payload,
        entityAvailableCountLoading: false,
      };

    case REQUEST_SUBMITTED_ENTITY_COUNT:
      return {
        ...state,
        entitySubmittedCountLoading: true,
      };

    case RECEIVE_SUBMITTED_ENTITY_COUNT:
      return {
        ...state,
        entitySubmittedCount: action.payload,
        entitySubmittedCountLoading: false,
      };

    case REQUEST_APPROVED_ENTITY_COUNT:
      return {
        ...state,
        entityApprovedCountLoading: true,
      };

    case RECEIVE_APPROVED_ENTITY_COUNT:
      return {
        ...state,
        entityApprovedCount: action.payload,
        entityApprovedCountLoading: false,
      };

    case REQUEST_DENIED_ENTITY_COUNT:
      return {
        ...state,
        entityDeniedCountLoading: true,
      };

    case RECEIVE_DENIED_ENTITY_COUNT:
      return {
        ...state,
        entityDeniedCount: action.payload,
        entityDeniedCountLoading: false,
      };

    case REQUEST_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCountLoading: true,
      };

    case RECEIVE_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCount: action.payload,
        entityDeliveredCountLoading: false,
      };
    default:
      return state;
  }
};

// interface
const DriveAwayEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityCountForEachStatus,
  },
  selectors: {
    getEntityAvailableCount,
    getEntityCountAvailableLoading,
    getEntitySubmittedCount,
    getEntityCountSubmittedLoading,
    getEntityApprovedCount,
    getEntityCountApprovedLoading,
    getEntityDeniedCount,
    getEntityCountDeniedLoading,
  },
  reducer: DriveAwayEntityReducer,
};
export default DriveAwayEntitiesState;
