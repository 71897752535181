/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useState, useRef, useEffect } from "react";
import { Popover, Overlay, PopoverHeader, PopoverBody } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { MdClose } from "react-icons/md";

import { Text } from "components/atoms/Text.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { Button } from "components/atoms/Button.atom";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";
import Colors from "styles/colors";

const DateRangeInput = ({
  startDate,
  endDate,
  saveClickHandler,
  minRange,
  maxRange,
  autoSelectOnInvalidValue,
  autoSelectRange,
}) => {
  const { t } = useTranslation("inventory-view");
  const zone = moment.tz.guess();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const dateTimeFormat = dateFormat + " " + timeFormat;

  useEffect(() => {
    setUpdatedInputValue();
  }, []);

  const handleClick = () => {
    setShow(!show);
  };

  const setUpdatedInputValue = () => {
    setValue(
      `${moment(selectedStartDate).format(dateFormat)} ${moment(
        selectedStartDate,
      ).format(` ${timeFormat} `)} ${moment().tz(zone).format("z ")} - ${moment(
        selectedEndDate,
      ).format(dateFormat)} ${moment(selectedEndDate).format(
        ` ${timeFormat} `,
      )} ${moment().tz(zone).format("z")}`,
    );
  };
  return (
    <div ref={containerRef}>
      <TextInput
        ref={targetRef}
        css={{
          ".form-control": {
            fontSize: ".8rem",
            fontWeight: "bold",
            backgroundColor: "transparent",
          },
        }}
        readOnly={true}
        onClick={handleClick}
        value={value}
        showClearButton
        onChange={(e) => {
          if (_.isEmpty(e)) {
            setValue("");
            setSelectedStartDate(null);
            setSelectedEndDate(null);
            setShow(true);
          }
        }}
      />

      <Overlay
        show={show}
        target={targetRef}
        placement="bottom"
        container={containerRef}
        containerPadding={20}
      >
        <Popover
          id="popover-contained"
          style={{
            marginTop: "0",
            minWidth: "300px",
          }}
          css={{
            ".arrow": {
              display: "none",
            },
          }}
        >
          <PopoverHeader
            style={{
              color: "white",
              backgroundColor: Colors.background.DARK_GRAY,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("inventory-view:Forecasted Capacity")}
            <MdClose
              css={{ cursor: "pointer" }}
              onClick={() => {
                setShow(false);
              }}
            />
          </PopoverHeader>
          <PopoverBody
            css={{
              backgroundColor: Colors.background.LIGHT_GRAY,
            }}
          >
            <div css={{ marginBottom: "1em" }}>
              <div css={{ marginBottom: "1em" }}>
                <Text>{t("inventory-view:From")}:</Text>
                <DateTimePicker
                  min={minRange}
                  value={selectedStartDate}
                  max={maxRange}
                  format={dateTimeFormat}
                  timeFormat={timeFormat}
                  onChange={(date) => {
                    setSelectedStartDate(date);
                    if (
                      autoSelectOnInvalidValue &&
                      selectedEndDate &&
                      moment(date).isAfter(moment(selectedEndDate))
                    ) {
                      const updatedEndDate = moment(date)
                        .add(autoSelectRange, "days")
                        .startOf("day")
                        .toDate();
                      setSelectedEndDate(
                        moment(updatedEndDate).isAfter(moment(maxRange))
                          ? maxRange
                          : updatedEndDate,
                      );
                    }
                  }}
                />
              </div>
              <div>
                <Text>{t("inventory-view:To")}:</Text>
                <DateTimePicker
                  min={selectedStartDate ? selectedStartDate : minRange}
                  max={maxRange}
                  value={selectedEndDate}
                  format={dateTimeFormat}
                  timeFormat={timeFormat}
                  onChange={(date) => {
                    setSelectedEndDate(date);
                  }}
                />
              </div>
            </div>
            <div css={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="dark"
                disabled={
                  !_.isDate(selectedStartDate) || !_.isDate(selectedEndDate)
                }
                onClick={() => {
                  setShow(false);
                  setUpdatedInputValue();
                  if (saveClickHandler) {
                    saveClickHandler(selectedStartDate, selectedEndDate);
                  }
                }}
              >
                {t("inventory-view:Save")}
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </Overlay>
    </div>
  );
};

DateRangeInput.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  saveClickHandler: PropTypes.func,
  minRange: PropTypes.object,
  maxRange: PropTypes.object,
  autoSelectOnInvalidValue: PropTypes.bool,
  autoSelectRange: PropTypes.number,
};

export default DateRangeInput;
