import _ from "lodash";

export const DEFAULT_GEOCODING = {
  City: "-",
  Country: "-",
  County: "-",
  District: "-",
  Label: "-",
  PostalCode: "-",
  State: "-",
};

export function geocode(
  mapPlatform,
  mapTypeOverride,
  hereMapsPlatform,
  googleMaps,
  params,
  resultCallback,
  errorCallback,
) {
  const mapType =
    mapTypeOverride?.toUpperCase() ?? mapPlatform?.toUpperCase() ?? null;

  const address = `${params.address}, ${params.city}, ${params.state} ${params.postal_code}, ${params.country}`;

  if (mapType === "HERE") {
    const geocoder = hereMapsPlatform.getSearchService();
    // HERE Geocoding and Search API v7 API Reference
    // https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html
    geocoder.geocode(
      { q: address, limit: 1 },
      (result) => {
        const geoPoint = _.get(result, "items[0].position");
        if (!_.isUndefined(geoPoint)) {
          resultCallback({ lat: geoPoint.lat, lng: geoPoint.lng });
        } else {
          resultCallback({});
        }
      },
      (err) => errorCallback(err),
    );
  } else if (mapType === "GOOGLE") {
    let geocoder = new googleMaps.Geocoder();
    geocoder.geocode({ address: address }, function (results, status) {
      if (status === "OK") {
        if (results && results.length > 0) {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          resultCallback({ lat: lat, lng: lng });
        } else {
          resultCallback({});
        }
      } else {
        errorCallback(results);
      }
    });
  }
}

export function reverseGeocode(
  mapPlatform,
  mapTypeOverride,
  hereMapsPlatform,
  googleMaps,
  lat,
  lng,
  resultCallback,
  errorCallback,
) {
  const mapType =
    mapTypeOverride?.toUpperCase() ?? mapPlatform?.toUpperCase() ?? null;

  if (mapType === "HERE") {
    let geocoder = hereMapsPlatform.getSearchService();
    // HERE Geocoding and Search API v7 API Reference
    // https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html
    geocoder.reverseGeocode(
      {
        in: `circle:${lat},${lng};r=150`,
        limit: 1,
      },
      (response) => {
        const address = response?.items[0]?.address;

        if (!address) {
          resultCallback(null);
          return;
        }

        resultCallback({
          City: address.city,
          Country: address.countryName,
          County: address.county,
          District: address.district,
          Label: address.label,
          PostalCode: address.postalCode,
          State: address.state,
        });
      },
      errorCallback,
    );
  } else if (mapType === "GOOGLE") {
    let latLng = new googleMaps.LatLng(lat, lng);

    let geocoder = new googleMaps.Geocoder();
    geocoder.geocode({ location: latLng }, function (response, status) {
      if (status !== "OK") {
        errorCallback(response);
        return;
      }

      const result = response[0];
      if (!result) {
        resultCallback(null);
        return;
      }

      const addressCombined = result.address_components.reduce(
        (address, addressComponent) => {
          const type = addressComponent.types[0];
          const name = addressComponent.long_name;
          switch (type) {
            case "locality":
              return { ...address, City: name };
            case "country":
              return { ...address, Country: name };
            case "administrative_area_level_2":
              return { ...address, County: name };
            case "street_number":
              return { ...address, HouseNumber: name };
            case "postal_code":
              return { ...address, PostalCode: name };
            case "administrative_area_level_1":
              return { ...address, State: name };
            case "route":
              return { ...address, Street: name };
            default:
              return address;
          }
        },
        {
          ...DEFAULT_GEOCODING,
          Label: result.formatted_address,
        },
      );

      resultCallback(addressCombined);
    });
  }
}
