import _ from "lodash";
import moment from "moment";
import qs from "qs";

export const csvHeaders = ({ tz }) => {
  if (_.isNil(tz)) {
    tz = moment.tz.guess();
  }
  return {
    Accept: "text/csv",
    // H1-551 Send the timezone for the export request in the headers.
    "x-time-zone": tz,
  };
};

// add pagination query params to url (a string). If params already present,
// return unchanged.
export const ensurePagination = (
  url,
  pagination = { pageNumber: 0, pageSize: 1000 },
) => {
  const newUrl = new URL(url);
  const { searchParams } = newUrl;
  if (searchParams.has("pageNumber") && searchParams.has("pageSize")) {
    return url;
  }
  const { pageNumber, pageSize } = pagination;
  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", pageNumber);
  }
  if (!searchParams.has("pageSize")) {
    searchParams.set("pageSize", pageSize);
  }
  return newUrl.href;
};

// remove pagination params from the queryString; return a string
export const withoutPagination = (queryString) => {
  const { pageNumber, pageSize, ...qsParams } = qs.parse(queryString);
  return qs.stringify(qsParams);
};

// add asyncExport=true to the queryString; return a string
export const withAsyncExport = (queryString, fileName) => {
  const { ...qsParams } = qs.parse(queryString);
  return qs.stringify({ asyncExport: "true", ...qsParams, filename: fileName });
};
