export const PackageStatusOption = {
  CREATED_OR_PACKAGED: { label: "Created/Packaged", value: "Created/Packaged" },
  IN_ROUTE: { label: "In Route", value: "In Route" },
  DELIVERED: { label: "Delivered", value: "Delivered" },
  DELAYED: { label: "Delayed", value: "Delayed" },
  AVAILABLE_FOR_PICKUP: {
    label: "Available for Pickup",
    value: "Available for Pickup",
  },
};
