import { connect } from "react-redux";
import InboundArrivalStatusWidgetState from "pages/shipments/redux/InboundArrivalStatusWidgetState";
import { InboundArrivalStatusWidget } from "./organisms/InboundArrivalStatusWidget.organism";

function mapStateToProps(state) {
  return {
    countData: InboundArrivalStatusWidgetState.selectors.getCountData(state),
    shipmentResults:
      InboundArrivalStatusWidgetState.selectors.getSearchResults(state),
    isShipmentsLoading:
      InboundArrivalStatusWidgetState.selectors.getIsLoading(state),
    page: InboundArrivalStatusWidgetState.selectors.getPage(state),
    pageSize: InboundArrivalStatusWidgetState.selectors.getPageSize(state),
    totalPages: InboundArrivalStatusWidgetState.selectors.getTotalPages(state),
    sortColumn: InboundArrivalStatusWidgetState.selectors.getSortColumn(state),
    reverseSort:
      InboundArrivalStatusWidgetState.selectors.getReverseSort(state),
    exportName: InboundArrivalStatusWidgetState.selectors.getExportName(state),
    isExporting:
      InboundArrivalStatusWidgetState.selectors.getIsExporting(state),
    exportFailed:
      InboundArrivalStatusWidgetState.selectors.getExportFailed(state),
    exportIdentifier:
      InboundArrivalStatusWidgetState.selectors.getExportIdentifier(state),
  };
}

const actionCreators = {
  fetchCount: InboundArrivalStatusWidgetState.actionCreators.fetchCount,
  fetchShipments: InboundArrivalStatusWidgetState.actionCreators.searchEntities,
  setSearchFilter:
    InboundArrivalStatusWidgetState.actionCreators.setSearchFilter,
  setPagination: InboundArrivalStatusWidgetState.actionCreators.setPagination,
  setSort: InboundArrivalStatusWidgetState.actionCreators.setSort,
  pushShipmentDetailView: (id) => ({
    type: "SHIPMENT_DETAIL",
    payload: { shipment_id: id },
  }),
  exportSearch: InboundArrivalStatusWidgetState.actionCreators.exportSearch,
  resetExport: InboundArrivalStatusWidgetState.actionCreators.resetExport,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(InboundArrivalStatusWidget);
