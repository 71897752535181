import { connect } from "react-redux";

import OrganizationsView from "./OrganizationsView";
import OrganizationsSearchBarState from "./OrganizationsSearchBarState";
import {
  fetchOrganizationTypes,
  getOrganizationTypes,
} from "./OrganizationsState";

function mapStateToProps(state) {
  return {
    searchResults:
      OrganizationsSearchBarState.selectors.getSearchResults(state),
    isLoading: OrganizationsSearchBarState.selectors.getIsLoading(state),
    searchFilters:
      OrganizationsSearchBarState.selectors.getSearchFilters(state),
    sortColumn: OrganizationsSearchBarState.selectors.getSortColumn(state),
    reverseSort: OrganizationsSearchBarState.selectors.getReverseSort(state),
    defaultSortColumn:
      OrganizationsSearchBarState.selectors.getDefaultSortColumn(state),
    defaultReverseSort:
      OrganizationsSearchBarState.selectors.getDefaultReverseSort(state),
    page: OrganizationsSearchBarState.selectors.getPage(state),
    pageSize: OrganizationsSearchBarState.selectors.getPageSize(state),
    totalPages: OrganizationsSearchBarState.selectors.getTotalPages(state),
    totalCount: OrganizationsSearchBarState.selectors.getTotalEntities(state),
    isShowingFilters:
      OrganizationsSearchBarState.selectors.getShowAdvancedSearch(state),
    hasSearchCriteriaChanged:
      OrganizationsSearchBarState.selectors.getHasSearchCriteriaChanged(state),
    organizationTypes: getOrganizationTypes(state),
  };
}

let {
  searchEntities,
  setSearchFilter,
  clearSearchFilter,
  setPagination,
  setSort,
  toggleShowFilters,
} = OrganizationsSearchBarState.actionCreators;

let actionCreators = {
  searchOrganizations: searchEntities,
  setSearchFilter,
  clearSearchFilter,
  setPagination,
  setSort,
  fetchOrganizationTypes,
  toggleShowFilters,
};

export default connect(mapStateToProps, actionCreators)(OrganizationsView);
