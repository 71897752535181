import { connect } from "react-redux";

import { UnsubscribeV2 } from "./UnsubscribeV2.page";
import UnsubscribeV2State from "./redux/UnsubscribeV2State";

function mapStateToProps(state) {
  return {
    ruleUuid: state.location.payload.ruleUuid,
    unsubscribeUuid: state.location.payload.unsubscribeUuid,
    isUnsubscribing: UnsubscribeV2State.selectors.getIsUnsubscribing(state),
    isUnsubscribeComplete:
      UnsubscribeV2State.selectors.getIsUnsubscribeComplete(state),
    isUnsubscribeError:
      UnsubscribeV2State.selectors.getIsUnsubscribeError(state),
    unsubscribeErrorMessage:
      UnsubscribeV2State.selectors.getUnsubscribeErrorMessage(state),
  };
}

const { resetUnsubscribe, unsubscribe } = UnsubscribeV2State.actionCreators;

const actionCreators = {
  resetUnsubscribe,
  unsubscribe,
};

export default connect(mapStateToProps, actionCreators)(UnsubscribeV2);
