import moment from "moment";

export const translateRailStatusCode = (code, t) => {
  const lookup = {
    3000: t("rail-status-code:Boundary Crossing"),
    4040: t("rail-status-code:Interchange delivery FROM RCV'G ROAD"),
    4041: t("rail-status-code:Interchange delivery NOT FROM RCV'G ROAD"),
    4042: t("rail-status-code:Interchange delivery ONE ROAD (NOT OT28)"),
    4043: t("rail-status-code:Interchange delivery FOR OTHR FM RCVG RD"),
    4044: t("rail-status-code:Interchange receipt ONE ROAD (NOT OT28)"),
    4045: t("rail-status-code:Interchange delivery OTHR NOT FM RCVG RD"),
    4046: t("rail-status-code:Interchange delivery ONE ROAD BY OTHER"),
    4048: t("rail-status-code:Interchange receipt FROM OT28 ROAD"),
    4049: t("rail-status-code:Interchange delivery TO OT28 ROAD"),
    "404X": t("rail-status-code:Interchange"),
    4050: t("rail-status-code:Interchange receipt FROM SND'G ROAD"),
    4051: t("rail-status-code:Interchange receipt FOR OTHR FM SND'G RD"),
    4053: t("rail-status-code:Interchange receipt NOT FROM SND'G ROAD"),
    4055: t("rail-status-code:Interchange receipt OTHR NOT FM SND'G RD"),
    4059: t("rail-status-code:Interchange receipt - LIABILITY ACCEPT"),
    "405X": t("rail-status-code:Interchange"),
    6001: t("rail-status-code:Release Load"),
    6002: t("rail-status-code:Pull from Patron Siding"),
    6003: t("rail-status-code:Release Empty"),
    6004: t("rail-status-code:Release unit stored under 49CFR1039.14"),
    6005: t("rail-status-code:Arrival at FINAL DESTINATION"),
    6006: t("rail-status-code:Arrival INTRANSIT"),
    6007: t("rail-status-code:Placement - ACTUAL"),
    6008: t("rail-status-code:Arrived not ordered placed by DEST PTY"),
    6009: t("rail-status-code:Placement - CONSTRUCTIVE"),
    6010: t("rail-status-code:Bad order w/ HOURS TO REPAIR"),
    6011: t("rail-status-code:Bad order - REPAIR GREATER THAN 20 HRS"),
    6012: t("rail-status-code:Placed on leased track - TO"),
    6013: t("rail-status-code:Bad order - REPAIR 20 HRS OR LESS"),
    6014: t("rail-status-code:Bad order - HOURS TO REPAIR UNKNOWN"),
    6015: t("rail-status-code:Unit released from bad order"),
    6016: t("rail-status-code:Departed from location"),
    6017: t("rail-status-code:Stored for prospective load"),
    6018: t("rail-status-code:Empty stored under PROV 49CFR1039.14"),
    6019: t("rail-status-code:Stored Servicable Surplus"),
    6020: t("rail-status-code:Empty meets criteria PROV 49CFR1039.14"),
    6021: t("rail-status-code:Stored unservicable"),
    6022: t("rail-status-code:Placed on leased track - FROM"),
    6023: t("rail-status-code:Stored for seasonal use"),
    6024: t("rail-status-code:Held for billing or disposition"),
    6025: t("rail-status-code:Unit released from hold or misc"),
    6026: t("rail-status-code:Unit held - NO BILLING OR CAR MVMT INSTRUC"),
    6027: t("rail-status-code:Release - LEASED TRACK"),
    6028: t("rail-status-code:AAR inventory move"),
    6029: t("rail-status-code:Pull - LEASED TRACK"),
    6030: t("rail-status-code:Estimated time to interchange or DEST"),
    6031: t("rail-status-code:Travelling to OWNER PER HIS INSTRUC"),
    6032: t("rail-status-code:Estimated time of arrival at INTERCHG"),
    6033: t("rail-status-code:Travelling to POOL OPER PER HIS INSTRUC"),
    6034: t("rail-status-code:Unable to schedule"),
    6035: t("rail-status-code:Travelling per AAR/ICC DIRECTIVE"),
    6036: t("rail-status-code:Arrival at initial RAILROAD FACILITY"),
    6037: t("rail-status-code:Departure - enroute to NON-RR FACILITY"),
    6038: t("rail-status-code:Intermodal ingate"),
    6039: t("rail-status-code:Intermodal outgate"),
    6042: t("rail-status-code:Ingate from rail INTERCHANGE"),
    6043: t("rail-status-code:Ingate from rail TERMINAL"),
    6044: t("rail-status-code:Notified destination party"),
    6045: t("rail-status-code:Unit rejected by shipper for loading"),
    6046: t("rail-status-code:Car grade inspection"),
    6047: t("rail-status-code:Car grade by waybill"),
    6048: t("rail-status-code:Outgate to rail interchange"),
    6049: t("rail-status-code:Outgate to rail terminal or satellite"),
    6050: t("rail-status-code:Unit rebilled, reconsigned, or respotted"),
    6051: t('rail-status-code:Start SCO90 "T" coding'),
    6052: t('rail-status-code:Stop SCO90 "T" coding'),
    6053: t('rail-status-code:Start SCO90 "B" coding'),
    6054: t('rail-status-code:Stop SCO90 "B" coding'),
    6055: t('rail-status-code:Start SCO90 "D" coding'),
    6056: t('rail-status-code:Stop SCO90 "D" coding'),
    6057: t('rail-status-code:Start SCO90 "E" coding'),
    6058: t('rail-status-code:Stop SCO90 "E" coding'),
    6059: t("rail-status-code:Flat car to bad order"),
    6060: t("rail-status-code:Flat car from bad order"),
    6061: t("rail-status-code:Lading transfer to"),
    6062: t("rail-status-code:Lading transfer from"),
    6063: t("rail-status-code:Place at joint industry"),
    6064: t("rail-status-code:TOFC/COFC onto RR-CONTROLLED truck"),
    6065: t("rail-status-code:Railroad controlled arrival on motor"),
    6066: t("rail-status-code:Pull from joint industry"),
    6067: t("rail-status-code:Load of a container or trailer on car"),
    6068: t("rail-status-code:Unload of container or trailer from car"),
    6074: t("rail-status-code:Hold for embargo conditions"),
    6075: t("rail-status-code:Release from embargo hold"),
    6076: t("rail-status-code:Equipment ordered in"),
    6077: t("rail-status-code:Container loaded on chassis"),
    6078: t("rail-status-code:Container removed from chassis"),
    6079: t("rail-status-code:Container swapped between 2 chassis"),
    6080: t("rail-status-code:Car hire rule 5 EXP RECOV-TERMINAL SWTCH"),
    6081: t("rail-status-code:Car hire rule 5 EXP RECOV-INTERMED SWTCH"),
    6082: t("rail-status-code:Car hire rule 15 TOL TO DELINQUENT ROAD"),
    6083: t("rail-status-code:Car hire rule 15 TOL TO HOLDING ROAD"),
    6084: t("rail-status-code:Car hire rule 5 TOL-INTERMED SW FOLLOW INT"),
    6085: t("rail-status-code:Car hire rule 5 TOL-TERMINAL SW FOLLOW INT"),
    6086: t("rail-status-code:Car hire rule 15 ACCEPTANCE"),
    6089: t("rail-status-code:Car hire rule 15 DENIAL"),
    6091: t("rail-status-code:DDCT - rule 107 events"),
    6092: t("rail-status-code:DDCT - rule 108 events"),
    6093: t("rail-status-code:DDCT - rule 1 events"),
    6094: t("rail-status-code:DDCT - Car removed from DDCT"),
    AF: t("rail-status-code:Departed Pickup"),
    AR: t("rail-status-code:Train Arrive Final Destination"),
    behind_schedule: t("rail-status-code:Shipment Behind Schedule"),
    behind_schedule_clear: t(
      "rail-status-code:Shipment Behind Schedule Cleared",
    ),
    CA: t("rail-status-code:Shipment Canceled"),
    I1: t("rail-status-code:In-gate"),
    OA: t("rail-status-code:Out-gate"),
    RL: t("rail-status-code:Train Depart Origin"),
    RP: t("rail-status-code:Supplemental Data Added"),
    SU: t("rail-status-code:Shipment Updated"),
    XB: t("rail-status-code:Asset Assigned"),
    XX: t("rail-status-code:Asset Resigned"),
  };
  return lookup[code];
};

export const getDayOfWeek = (date, t) => {
  const momentDate = moment(date);
  const dayOfWeekIndex = momentDate.format("e");
  const shortDate = momentDate.format("M/DD");

  // Need to convert index to number because moment.format returns a string.
  let dayOfWeek = getDayOfWeekFromIndex(Number(dayOfWeekIndex), t);
  if (dayOfWeek === null) {
    dayOfWeek = t("Unknown");
  }

  return `${dayOfWeek} (${shortDate})`;
};

export const getDayOfWeekFromIndex = (dayOfWeekIndex, t) => {
  switch (dayOfWeekIndex) {
    case 0:
      return t("Sunday");
    case 1:
      return t("Monday");
    case 2:
      return t("Tuesday");
    case 3:
      return t("Wednesday");
    case 4:
      return t("Thursday");
    case 5:
      return t("Friday");
    case 6:
      return t("Saturday");
    default:
      return null;
  }
};
