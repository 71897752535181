import Colors from "../../../../../styles/colors";

const Theme = {
  container: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  textColor: Colors.text.DARKER_GRAY,
  header: {
    backgroundColor: Colors.background.DARK_GRAY,
    textColor: Colors.tableHeadingText,
    fontWeight: "normal",
    borderBottom: "0",
    filters: {
      backgroundColor: Colors.background.LIGHT_GRAY,
      inputBorderColor: "rgba(0, 0, 0, 0.1)",
      inputBackgroundColor: "#fff",
    },
    sorting: {
      selectedColor: Colors.background.DARK_TEAL,
      hover: {
        backgroundColor: Colors.background.DARK_MOUSEOVER_GRAY,
      },
    },
    resizing: {
      hoverBorderRightColor: Colors.background.DARK_HIGHLIGHT_GRAY,
    },
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
  },
  body: {
    loading: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      spinnerColor: "#000",
    },
    row: {
      defaultBackgroundColor: Colors.background.LIGHT_GRAY,
      alternateBackgroundColor: "#fff",
      hover: {
        defaultBackgroundColor: Colors.background.LIGHT_MOUSEOVER_GRAY,
        alternateBackgroundColor: "#fafafa",
      },
      clickableHover: {
        backgroundColor: Colors.background.YELLOW_MOUSEOVER,
      },
      highlighted: {
        backgroundColor: Colors.tables.default.highlighted.BACKGROUND,
        alternateBackgroundColor:
          Colors.tables.default.highlighted.ALT_BACKGROUND,
        hover: {
          backgroundColor: Colors.tables.default.highlighted.BACKGROUND_HOVER,
          alternateBackgroundColor:
            Colors.tables.default.highlighted.ALT_BACKGROUND_HOVER,
        },
      },
      disabled: {
        backgroundColor: Colors.tables.default.disabled.BACKGROUND,
        alternateBackgroundColor: Colors.tables.default.disabled.ALT_BACKGROUND,
      },
      borderBottomColor: "rgba(0, 0, 0, 0.05)",
    },
    cell: {
      borderRightColor: "rgba(0, 0, 0, 0.02)",
    },
  },
  cell: {
    resizing: {
      borderRightColor: Colors.background.DARK_TEAL,
    },
  },
  pagination: {
    backgroundColor: "#fff",
    textColor: "rgba(0,0,0,0.6)",
    boxShadowColor: "rgba(0, 0, 0, 0.1)",
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    input: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      button: {
        textColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
};

export default Theme;
