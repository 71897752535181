import apiUrl from "api-url";
import axios from "axios";
import { createMutationRequestHook } from "shared/hooks/createMutationRequestHook";
import { BaseErrorResponse } from "api/responses";
import { z } from "zod";

const vinUpdateToolResponseSchema = z.object({
  status: z.string(),
  failedVins: z.array(
    z.object({
      vin: z.string(),
      reason: z.string(),
    }),
  ),
});
type OverrideCurrentTripPlanResponse = z.infer<
  typeof vinUpdateToolResponseSchema
>;

type CurrentTripPlanOverrideMutationVariables = {
  solutionId: string;
  payload: string[];
  isBulkUpload: boolean;
};

export const useOverrideTripPlanMutation = createMutationRequestHook<
  OverrideCurrentTripPlanResponse,
  BaseErrorResponse,
  CurrentTripPlanOverrideMutationVariables
>({
  fetch: async ({ solutionId, payload }) => {
    const url = apiUrl(
      `/trip-leg/solution/${solutionId}/override-current-planned-trip-leg`,
    );
    return await axios.post(url, payload).then((res) => res.data);
  },
});
