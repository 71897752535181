import { connect } from "react-redux";
import { SearchBar } from "../../../../components/search-bar/SearchBar";
import SearchBarState from "../../redux/InventoryViewDetailsSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
  } = SearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    showSearchOptions: false,
    canUserSearch: getCanUserSearch(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text) => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
