/** @jsxImportSource @emotion/react */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { useGetLatest } from "components/hooks/useGetLatest";
import AuthenticationUtils from "modules/auth/authentication";
import AuthenticationState from "modules/auth/AuthenticationState";
import UsersState from "modules/users/UsersState";
import Authorization from "modules/auth/Authorization";
import { UserAuthorizationNamespace } from "modules/auth/Authorization";
import * as OrganizationsActions from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Icon } from "components/atoms/Icon.atom";
import { FontSize, Text } from "components/atoms/Text.atom";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import {
  getDealerRedirect,
  getHealthCareRedirect,
  getPartnerRedirect,
} from "pages/loginloading/redirect-helper";
import {
  isDealer,
  isHealthcareProvider,
  isPartner,
} from "shared/utils/organizations.utils";
import { BrowserStorage } from "utils/browser-storage.utils";
import { authPartView } from "pages/partview/utils/auth.util";

export const LoginLoading = () => {
  const { t } = useTranslation("appnav");

  useSetTitleOnMount(t("appnav:Please Wait"));

  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const federationData = useSelector(OrganizationsActions.getFederationData);
  const federationDataErrorMessage = useSelector(
    OrganizationsActions.getFederationDataErrorMessage,
  );
  const activeOrganization = useSelector(
    OrganizationsActions.getActiveOrganization,
  );
  const authorization = useSelector(getAuthorization);
  const currentUser = useSelector(UsersState.selectors.getCurrentUser);
  const featureData = useSelector(OrganizationsActions.getFeatureData);
  const featureDataLoading = useSelector(
    OrganizationsActions.getFeatureDataLoading,
  );
  const federatedEntityInternalId = useSelector(
    OrganizationsActions.getFederatedEntityInternalId,
  );
  const federatedEntityInternalIdIsLoading = useSelector(
    OrganizationsActions.getFederatedEntityInternalIdIsLoading,
  );
  const currentOrganizationId = useSelector(
    OrganizationsActions.getCurrentOrganizationId,
  );
  const organizationsLoadingError = useSelector(
    OrganizationsActions.getOrganizationsLoadingError,
  );

  const getIsSilentAuthentication = useGetLatest(
    AuthenticationUtils.getIsSilentAuthentication(),
  );

  // Retrieve query string parameters
  const params = new URLSearchParams(location?.query);

  // Check for a redirect URL
  let redirectUrl = "/"; // Default to Shipments dashboard
  if (params.has("redirectUrl")) {
    redirectUrl = decodeURIComponent(params.get("redirectUrl"));
  }

  // Remove from query string parameters so we can reuse params for the orgId later
  params.delete("redirectUrl");

  // We want this to happen just once, on page load
  useEffect(
    () => {
      // Set isLoginLoading to true so other parts of the app don't do things until this is done
      dispatch(AuthenticationState.actionCreators.setIsLoginLoading(true));

      if (getIsSilentAuthentication()) {
        dispatch(OrganizationsActions.fetchFederationData());
      }
    },
    // We want this effect only to run once, so ignore eslint warnings
    // eslint-disable-next-line
    [],
  );

  // For regular logins, we wait for organizations, then set the currentOrgId
  useEffect(() => {
    if (!getIsSilentAuthentication() && !currentOrganizationId) {
      let organizationId = null;

      // Retrieve data from token
      const claims = AuthenticationUtils.getDecodedToken();

      // An orgId has been set in the URL, so parse it out and set that as the current org
      if (location?.query?.orgId) {
        const parsedOrgId = parseInt(location.query.orgId, 10);
        if (authorization.hasOrganizationAccess(parsedOrgId)) {
          organizationId = parsedOrgId;

          // Remove orgId from URL
          let url = new URL(window.location.href);
          url.searchParams.delete("orgId");
          window.history.replaceState({}, document.title, url.toString());
        } else {
          // Logged-in user doesn't have access to this org
          dispatch(AuthenticationState.actionCreators.setIsLoginLoading(false));

          // Redirect to 403 error page
          window.location.href = "/accessForbiddenError?orgId=" + parsedOrgId;
          return;
        }
      }

      if (
        organizationId === null &&
        claims?.hasOwnProperty(UserAuthorizationNamespace)
      ) {
        // No orgId specified, so retreive one from storage or the token
        organizationId = BrowserStorage.currentOrganization
          ? BrowserStorage.currentOrganization
          : claims[UserAuthorizationNamespace].organization_id;
      }

      // Set the current orgId
      dispatch(OrganizationsActions.setCurrentOrganization(organizationId));
    }
  }, [
    getIsSilentAuthentication,
    currentOrganizationId,
    authorization,
    location,
    dispatch,
  ]);

  // For federated logins, we wait for the federationData, then set the currentOrgId
  useEffect(() => {
    if (
      getIsSilentAuthentication() &&
      federationData &&
      !currentOrganizationId
    ) {
      // Set the current orgId
      dispatch(
        OrganizationsActions.setCurrentOrganization(
          federationData.organization_id,
        ),
      );
    }
  }, [
    getIsSilentAuthentication,
    currentOrganizationId,
    dispatch,
    federationData,
  ]);

  // Request the internal ID for federated users with a redirect override.
  // Doing this after setting the org, because we need x-active-org set for the request to succeed.
  useEffect(() => {
    if (getIsSilentAuthentication() && currentOrganizationId) {
      dispatch(OrganizationsActions.fetchFederatedEntityInternalId());
    }
  }, [getIsSilentAuthentication, currentOrganizationId, dispatch]);

  // For both normal and federated logins, we wait for the activeOrganization,
  // then redirect to the proper page
  useEffect(() => {
    if (activeOrganization && !_.isEmpty(activeOrganization)) {
      if (
        featureDataLoading === true ||
        // Need to wait until the internal ID lookup returns.
        // This will remain false if the user doesn't need the internal ID.
        // See `OrganizationsActions.fetchFederatedEntityInternalId()`.
        federatedEntityInternalIdIsLoading === true
      ) {
        return;
      }

      // Disable isLoginLoading so other parts of the app can start doing their thing
      dispatch(AuthenticationState.actionCreators.setIsLoginLoading(false));

      const authorization = new Authorization(
        currentUser,
        activeOrganization,
        federationData,
        featureData,
      );

      let currentRedirectUrl = redirectUrl;

      // FIN-5569: Handle federated redirect override.
      if (getIsSilentAuthentication()) {
        // If we have some redirect override value:
        // - Ensure we have all information we need
        //   We need strings in: solution, target, subtarget, and resource.
        // - Set the redirectUrl accordingly.
        const { solution, target, subtarget, resource } =
          AuthenticationUtils.getFederatedRedirectOverrideState();

        const { isPartUser } = authPartView(authorization);

        if (solution) {
          if (target === "finishedvehicle" && subtarget === "details") {
            currentRedirectUrl = "/vindetails/" + resource;
          } else if (target === "vinview" && subtarget === "details") {
            currentRedirectUrl =
              "/vinview/details/" + federatedEntityInternalId;
          }
        }
        // redirect to package detail page for partview
        if (target === "partview" && subtarget === "details") {
          if (!isPartUser) {
            currentRedirectUrl = "/partview-seller/details/" + resource;
          } else {
            currentRedirectUrl = "/partview/details/" + resource;
          }
        }
      }

      // TODO: Centralize redirect logic for special restrictions below (H1-928, H2-720)

      // H1-928: Redirect PAT-only users to Planet Asset Dashboard if attempting to access non-PAT routes
      const patOnlyPath = "/plant-asset";
      if (
        authorization.featureAuthorization.plantAssetTrackingOnly() &&
        !currentRedirectUrl?.includes(patOnlyPath)
      ) {
        window.location.href = patOnlyPath;
        return;
      }

      // Partner Redirects
      if (isPartner(activeOrganization)) {
        const partnerRedirectUrl = getPartnerRedirect(currentRedirectUrl);

        if (partnerRedirectUrl) {
          window.location.href = partnerRedirectUrl;
          return;
        }
      }

      // Healthcare Redirects
      if (isHealthcareProvider(activeOrganization)) {
        const healthcareRedirectUrl = getHealthCareRedirect(currentRedirectUrl);

        if (healthcareRedirectUrl) {
          window.location.href = healthcareRedirectUrl;
          return;
        }
      }

      // Dealer Redirects
      if (isDealer(activeOrganization)) {
        const dealerRedirectUrl = getDealerRedirect(currentRedirectUrl);

        if (dealerRedirectUrl) {
          window.location.href = dealerRedirectUrl;
          return;
        }
      }

      // All is well, redirect to the specified redirectUrl now
      // Note: AuthorizedComponentWithRedirect will re-check some of the above,
      // as well as check if the user is authorized. We elected not to do it here
      // because we could not get the privileges/features/orgTypes from routing using
      // just the URL (a routing type is necessary, eg: "VINVIEW_DASHBOARD").
      window.location.href = currentRedirectUrl;
    }
  }, [
    dispatch,
    currentUser,
    federationData,
    featureData,
    featureDataLoading,
    activeOrganization,
    redirectUrl,
    getIsSilentAuthentication,
    federatedEntityInternalId,
    federatedEntityInternalIdIsLoading,
  ]);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "1em",
        }}
      >
        {federationDataErrorMessage || organizationsLoadingError ? (
          <Text size={FontSize.size16} align="center">
            {t(
              "appnav:Your organization does not currently have access to FreightVerify.",
            )}
          </Text>
        ) : (
          <React.Fragment>
            <Text size={FontSize.size16} align="center">
              <Icon spin src={faSpinner} style={{ marginRight: 10 }} />
              {t("appnav:Loading your data...")}
            </Text>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
