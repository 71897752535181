import React from "react";
import PropTypes from "prop-types";

// The purpose of this context is to allow us to pass down RoutingMap props from a parent
// page, down to a child of the page (that may be several levels deep). This is to prevent
// prop drilling, and having to pass down map information (which is large) through several
// components to get somewhere we need it.
// One particular use case is in the FinishedVehicle.Details.page/VinView.Details.page,
// which passes the RoutingMap props to the VinShareModal.

const RoutingMapContext = React.createContext();

function RoutingMapContextProvider(props) {
  return (
    <RoutingMapContext.Provider value={props}>
      {props.children}
    </RoutingMapContext.Provider>
  );
}

RoutingMapContextProvider.propTypes = {
  children: PropTypes.any,
};

export { RoutingMapContext, RoutingMapContextProvider };
