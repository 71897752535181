/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { MediaQueries } from "components/responsive";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";

const PAGE_SIZE = 6;

const PaginationControls = ({ page, totalPages, onPrevClick, onNextClick }) => {
  const { t } = useTranslation("saved-search");

  const currentPageLabel = totalPages === 0 ? 0 : page;
  const totalPagesLabel = totalPages;

  return (
    <div className="d-flex align-items-center">
      <Button
        data-qa="saved-search-pagination-button-previous"
        variant="light"
        size="sm"
        className="me-2"
        onClick={onPrevClick}
        disabled={currentPageLabel <= 1}
      >
        <Icon src={faChevronLeft} />
      </Button>
      <Text color={Colors.text.WHITE} data-qa="saved-search-pagination-text">
        {t("saved-search:Page")} {currentPageLabel} / {totalPagesLabel}
      </Text>
      <Button
        data-qa="saved-search-pagination-button-next"
        variant="light"
        size="sm"
        className="ms-2"
        onClick={onNextClick}
        disabled={currentPageLabel === totalPagesLabel}
      >
        <Icon src={faChevronRight} />
      </Button>
    </div>
  );
};

PaginationControls.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export const SavedSearchesPanel = ({
  savedSearches = [],
  isLoading,
  onAddSavedSearch,
  CardComponent,
  getCardProps = () => ({}),
  contentBackgroundColor = Colors.background.LIGHT_GRAY,
}) => {
  const { t } = useTranslation("saved-search");

  const [page, setPage] = useState(1);

  const totalPages = _.ceil(savedSearches.length / PAGE_SIZE);

  const startIndex = (page - 1) * PAGE_SIZE;
  const pagedSavedSearches = savedSearches.slice(
    startIndex,
    startIndex + PAGE_SIZE,
  );

  const handlePrevClick = () => {
    if (page <= 1) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page >= totalPages) {
      setPage(totalPages);
    } else {
      setPage(page + 1);
    }
  };

  const paginationControlsElement = (
    <PaginationControls
      page={page}
      totalPages={totalPages}
      onPrevClick={handlePrevClick}
      onNextClick={handleNextClick}
    />
  );

  return (
    <div className="d-flex flex-column">
      <PanelGroup collapsible>
        <PanelGroup.Header
          title={t("saved-search:My Saved Searches")}
          rightContent={
            <Button
              data-qa="button-add-saved-search"
              variant="light"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onAddSavedSearch(e);
              }}
            >
              <Icon src={faPlus} />
            </Button>
          }
        />
        <PanelGroup.SubHeader
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            [MediaQueries.smallAndUp]: {
              flexDirection: "row",
              justifyContent: "space-between",
            },
            padding: "0.5em 1em",
            background: Colors.background.WARM_GRAY,
          }}
        >
          <Text bold color={Colors.text.WHITE} className="mb-1 mb-sm-0">
            {t("saved-search:There is [[[count]]] total saved search", {
              count: savedSearches?.length ?? 0,
            })}
          </Text>
          {paginationControlsElement}
        </PanelGroup.SubHeader>
        <PanelGroup.Content
          style={{
            padding: 0,
            backgroundColor: contentBackgroundColor,
          }}
        >
          <div css={{ minHeight: 200, position: "relative" }}>
            <Loader loaded={!isLoading}>
              {pagedSavedSearches.length ? (
                <div
                  css={{
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                    [MediaQueries.mediumAndUp]: {
                      display: "grid",
                      gridGap: "1em",
                      // If we have 4+ saved searches on this page, allow for 2 rows.
                      // If we have 3 or less, allow for 1 row.
                      gridTemplateRows: `repeat(${
                        pagedSavedSearches.length > 3 ? 2 : 1
                      }, 1fr)`,
                      // https://css-tricks.com/equal-width-columns-in-css-grid-are-kinda-weird/
                      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    },
                  }}
                >
                  {pagedSavedSearches.map((savedSearch) => (
                    <div key={savedSearch.id} className="mb-3 mb-md-0">
                      <CardComponent {...getCardProps(savedSearch)} />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  css={{
                    minHeight: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: Colors.text.GRAY,
                    padding: "2em",
                    textAlign: "center",
                  }}
                >
                  <h3>
                    {t(
                      "saved-search:No saved searches. Click + to add a new saved search.",
                    )}
                  </h3>
                </div>
              )}
            </Loader>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              [MediaQueries.smallAndUp]: {
                flexDirection: "row",
                justifyContent: "flex-end",
              },
              padding: "0.5em 1em",
              background: Colors.background.WARM_GRAY,
            }}
          >
            {paginationControlsElement}
          </div>
        </PanelGroup.Content>
      </PanelGroup>
    </div>
  );
};

SavedSearchesPanel.propTypes = {
  savedSearches: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  onAddSavedSearch: PropTypes.func,
  fetchDataAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  CardComponent: PropTypes.any.isRequired,
  getCardProps: PropTypes.func,
  contentBackgroundColor: PropTypes.string,
};
