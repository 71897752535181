/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { MouseEventHandler, ReactNode } from "react";
import { RemoveListItemButton } from "../atoms/RemoveListItemButton.atom";
import { AddListItemButton } from "../atoms/AddListItemButton.atom";

export const InteractiveList = (props: InteractiveListProps) => {
  const {
    children,
    addButtonText,
    addButtonHandler,
    removeButtonHandler,
    maxItems,
  } = props;
  if (_.isNil(children)) {
    return <></>;
  } else {
    return (
      <div css={{ width: "100%" }}>
        {children.map((child, idx) => {
          return (
            <div
              key={`interactive-list-item-${idx}`}
              css={{
                marginTop: idx === 0 ? "0.5rem" : "1rem",
                display: "flex",
              }}
            >
              <div css={{ width: children.length > 1 ? "93%" : "100%" }}>
                {child}
              </div>
              {children.length > 1 ? (
                <div css={{ width: "7%", alignContent: "center" }}>
                  <RemoveListItemButton
                    onClick={() => removeButtonHandler(idx)}
                    data-qa={`button-remove-item-${idx}`}
                  ></RemoveListItemButton>
                </div>
              ) : null}
            </div>
          );
        })}
        {_.isNil(maxItems) || children.length < maxItems ? (
          <AddListItemButton
            text={addButtonText}
            onClick={addButtonHandler}
            data-qa="button-add-item"
          ></AddListItemButton>
        ) : null}
      </div>
    );
  }
};

export type InteractiveListProps = {
  /**
   * The items inside the list
   */
  children: Array<ReactNode>;
  /**
   * The text in the add button next to the plus icon
   */
  addButtonText: string;
  /**
   * onClick function for add item
   */
  addButtonHandler: MouseEventHandler<HTMLButtonElement>;
  /**
   * onClick function for remove item
   */
  removeButtonHandler: Function;
  /**
   * Add button will be hidden when maxItems is reached
   */
  maxItems: number;
};
