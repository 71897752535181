import moment from "moment";

import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import apiUrl from "api-url";

const STORE_MOUNT_POINT = "damageViewDetailsSearch";

const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const submissionId = state.location.payload.submission_id;
  // Normal search GET
  const url = apiUrl(
    `/damageview/submission/${submissionId}/vins?${queryString}`,
  );
  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      accept: "application/json",
    },
  };

  // Fetch the search
  dispatch(duck.fetch(url, config));
};

const SearchState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  null,
  fetchSearch,
  [],
  {},
  5,
);

export default SearchState;
