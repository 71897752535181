import { MadChicletCSS as Chiclet } from "components/chiclets";
import Colors from "styles/colors";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import { Icon } from "components/atoms/Icon.atom";
import PropTypes from "prop-types";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { ViewExpandedDetailsButton } from "./ShipmentEtaValidator.ExpandedDetails.organism";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { parseDateTime } from "utils/date-time";
import { useTranslation } from "react-i18next";

const FilterInput = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      value={filterValue ?? ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely.
        setFilter(e.target.value ?? undefined);
      }}
    />
  );
};

FilterInput.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
  }),
};

const TriggerCell = ({ value: data }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block>{data.triggerType}</Text>
      <Text block>{data.etaCalculationPointOfInterest}</Text>
      {data.etaCalculationCity &&
      data.etaCalculationStateOrProvince &&
      data.etaCalculationCountry ? (
        <Text block>
          {data.etaCalculationCity}, {data.etaCalculationStateOrProvince},{" "}
          {data.etaCalculationCountry}
        </Text>
      ) : null}

      {data.triggerMode ? (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <div css={{ marginLeft: "0.25em" }}>
            <Chiclet
              shipmentMode={data.triggerMode}
              stopMode={data.triggerMode}
              activeException={null}
              width={25}
              height={25}
            />
          </div>
          <div
            css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}
          >
            {data.triggerMode}
          </div>
        </div>
      ) : null}
    </div>
  );
};

TriggerCell.propTypes = {};

const ScheduledDeliveryWindow = ({ value: entity }) => {
  return (
    <DateTimeRange
      plain
      localize
      from={entity.scheduledDeliveryWindow[0]}
      to={entity.scheduledDeliveryWindow[1]}
    />
  );
};

const DestinationCell = ({ value: entity }) => {
  const { t } = useTranslation("shipment-eta-validator");
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block bold>
        {entity.shipmentUltimateDestinationCode}
      </Text>
      <Text block>{entity.shipmentUltimateDestinationName}</Text>
      {entity.destinationCity && entity.destinationState ? (
        <Text>{`${entity.destinationCity}, ${entity.destinationState}`}</Text>
      ) : null}
      <Fragment>
        {(entity.lifeCycleState?.toLowerCase() === "in_transit" ||
          entity.lifeCycleState?.toLowerCase() === "pending") &&
        entity.scheduledDeliveryWindow ? (
          <Text block bold underline>
            {t("shipment-eta-validator:Scheduled Delivery")}
          </Text>
        ) : null}
        {(entity.lifeCycleState?.toLowerCase() === "arrived" ||
          entity.lifeCycleState?.toLowerCase() === "archived") &&
        entity.shipmentActualArrivalTime ? (
          <Text block bold underline>
            {t("shipment-eta-validator:Actual Delivery")}
          </Text>
        ) : null}
        {entity.shipmentActualArrivalTime ? (
          <DateTimeCell dateTime={entity.shipmentActualArrivalTime} localize />
        ) : (
          <ScheduledDeliveryWindow value={entity} />
        )}
      </Fragment>
    </div>
  );
};

DestinationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

const CalculationMessageCell = ({ value: data }) => {
  const { t } = useTranslation("shipment-eta-validator");
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block>{data.etaModelName}</Text>
      {data.numberOfDaysInHistory ? (
        <Text block>
          {t("shipment-eta-validator:[[[numOfDays]]] days history", {
            numOfDays: data.numberOfDaysInHistory,
          })}
        </Text>
      ) : null}
      {data.percentileUsedInCalculation ? (
        <Text block>
          {data.percentileUsedInCalculation}
          {t("shipment-eta-validator:th percentile")}
        </Text>
      ) : null}
      {data.etaModelVersion ? (
        <Text block>
          {t("shipment-eta-validator:Version ID - ")}
          {data.etaModelVersion}
        </Text>
      ) : null}
    </div>
  );
};

CalculationMessageCell.propTypes = {};

const DataSignifier = () => {
  return (
    <Icon
      src={faMinus}
      color={Colors.tables.tableHeadingText}
      css={{ display: "block", margin: "auto" }}
    />
  );
};

export const useColumns = () => {
  const { t } = useTranslation("shipment-eta-validator");
  return [
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:ETA Calculation Time")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t("The date/time used in the calculation of an ETA")}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "calcTime",
      width: 195,
      accessor: (row) => row.etaUTC,
      Cell: (cellProps) =>
        cellProps.value ? (
          <DateTimeCell dateTime={cellProps.value} localize />
        ) : (
          <DataSignifier />
        ),
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          if (rowValue) {
            const { date, time, timezone } = parseDateTime(rowValue, true);
            return (time + " " + timezone + " " + date)
              .toLowerCase()
              .includes(lowerFilterValue);
          } else {
            return "N/A".toLowerCase().includes(lowerFilterValue);
          }
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:ETA Destination Time")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("The ETA date/time that was calculated")}</Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "calcDestTime",
      width: 193,
      accessor: (row) => row.etaDestinationTime,
      Cell: (cellProps) =>
        cellProps.value ? (
          <DateTimeCell dateTime={cellProps.value} localize />
        ) : (
          <DataSignifier />
        ),
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          if (rowValue) {
            const { date, time, timezone } = parseDateTime(rowValue, true);
            return (time + " " + timezone + " " + date)
              .toLowerCase()
              .includes(lowerFilterValue);
          } else {
            return "N/A".toLowerCase().includes(lowerFilterValue);
          }
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:ETA Difference")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "If delivered, this is the delta of the ETA Destination Time and the actual delivered date/time of the Shipment",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "etaDiff",
      sortType: (rowA, rowB) => {
        if (
          !rowA.original.entityTripPlanCompleteTime ||
          !rowA.original.etaDate
        ) {
          return -1;
        }
        const rowAActualDate = moment(rowA.original.entityTripPlanCompleteTime);
        const rowAEtaDate = moment(rowA.original.etaDate);
        const rowADiff = rowAEtaDate.diff(rowAActualDate, "hours", true);
        const rowBActualDate = moment(rowB.original.entityTripPlanCompleteTime);
        const rowBEtaDate = moment(rowB.original.etaDate);
        const rowBDiff = rowBEtaDate.diff(rowBActualDate, "hours", true);
        return rowADiff > rowBDiff ? 1 : -1;
      },
      accessor: (row) => {
        return t("shipment-eta-validator:[[[count]]] hour(s)", {
          count: (() => {
            if (row.shipmentActualArrivalTime && row.etaDestinationTime) {
              const actualDate = moment(row.shipmentActualArrivalTime);
              const etaDate = moment(row.etaDestinationTime);
              return etaDate.diff(actualDate, "hours", true).toFixed(1);
            } else {
              return "---";
            }
          })(),
        });
      },
      width: 190,
      Filter: FilterInput,
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:Trigger")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t("The ETA trigger name and location/mode if applicable")}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "trigger",
      accessor: (row) => row,
      width: 200,
      Cell: (cellProps) =>
        cellProps?.value?.triggerType ? (
          <TriggerCell value={cellProps.value} />
        ) : (
          <DataSignifier />
        ),
      sortType: (rowA, rowB) => {
        if (
          !rowA?.values?.Trigger?.triggerType ||
          !rowB?.values?.Trigger?.triggerType
        ) {
          return -1;
        }
        return rowA.values.Trigger.triggerType > rowB.values.Trigger.triggerType
          ? 1
          : -1;
      },
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.triggerType
            ? rowValue.triggerType.toLowerCase()
            : "";
          const line2 = rowValue.etaCalculationPointOfInterest
            ? rowValue.etaCalculationPointOfInterest.toLowerCase()
            : "";
          const line3 =
            rowValue.etaCalculationCity &&
            rowValue.etaCalculationStateOrProvince &&
            rowValue.etaCalculationCountry
              ? (
                  rowValue.etaCalculationCity +
                  ", " +
                  rowValue.etaCalculationStateOrProvince +
                  ", " +
                  rowValue.etaCalculationCountry
                ).toLowerCase()
              : "";
          const line4 = rowValue.triggerMode
            ? rowValue.triggerMode.toLowerCase()
            : "";
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:Destination")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t("This is the Ultimate destination of the Shipment")}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      Cell: DestinationCell,
      id: "destination",
      accessor: (row) => row,
      disableSortBy: true,
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.shipmentUltimateDestinationCode
            ? rowValue.shipmentUltimateDestinationCode.toLowerCase()
            : "";

          const line2 = rowValue.shipmentUltimateDestinationName
            ? rowValue.shipmentUltimateDestinationName.toLowerCase()
            : "";

          const line3 =
            rowValue.destinationCity && rowValue.destinationState
              ? `${rowValue.destinationCity}, ${rowValue.destinationState}`.toLowerCase()
              : "";

          const line4 =
            rowValue.lifeCycleState.toLowerCase() === "in_transit"
              ? t("shipment-eta-validator:Scheduled Delivery").toLowerCase()
              : "";

          const line5 =
            rowValue.lifeCycleState.toLowerCase() === "arrived"
              ? t("shipment-eta-validator:Actual Delivery").toLowerCase()
              : "";

          let line6 = "n/a";
          if (rowValue.shipmentActualArrivalTime) {
            const { date, time, timezone } = parseDateTime(
              rowValue.shipmentActualArrivalTime,
              true,
            );
            line6 = (time + " " + timezone + " " + date).toLowerCase();
          }
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue) ||
            line5.includes(lowerFilterValue) ||
            line6.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:ETA Algorithm")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t("shipment-eta-validator:Which FV ETA was used")}{" "}
                  {t(
                    "shipment-eta-validator:Level 0 - Carrier Provided/Delivery Schedule",
                  )}{" "}
                  {t("shipment-eta-validator:Level 1 - FV Statistical ETA")}{" "}
                  {t("shipment-eta-validator:Level 2 - FV AI ETA")}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      Cell: (cellProps) =>
        cellProps.value.etaAlgorithm ? (
          cellProps.value.etaAlgorithm
        ) : (
          <DataSignifier />
        ),
      id: "etaAlgorithm",
      accessor: (row) => (row.etaAlgorithm ? row.etaAlgorithm : "N/A"),
      width: 160,
      Filter: FilterInput,
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:Model Data Information")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "Contains ETA model name, percentile, and ID for an ETA calculation",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "modelDataInformation",
      accessor: (row) => row,
      Cell: (cellProps) => {
        if (
          !cellProps?.value?.etaCalculationMessage ||
          cellProps?.values?.etaModelName === null
        ) {
          return <DataSignifier />;
        }
        return cellProps?.value?.etaCalculationMessage ? (
          "Legacy"
        ) : (
          <CalculationMessageCell value={cellProps.values} />
        );
      },
      Filter: FilterInput,
      sortType: (rowA, rowB) =>
        rowA.original.etaModelName > rowB.original.etaModelName ? 1 : -1,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.etaModelName
            ? rowValue.etaModelName.toLowerCase()
            : "";
          const line2 = rowValue.numberOfDaysInHistory
            ? t("shipment-eta-validator:[[[numOfDays]]] days history", {
                numOfDays: rowValue.numberOfDaysInHistory,
              }).toLowerCase()
            : "";
          const line3 = rowValue.percentileUsedInCalculation
            ? (
                rowValue.percentileUsedInCalculation +
                t("shipment-eta-validator:th percentile")
              ).toLowerCase()
            : "";
          const line4 = rowValue.etaModelVersion
            ? (
                t("shipment-eta-validator:Version ID - ") +
                rowValue.etaModelVersion
              ).toLowerCase()
            : "";
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("shipment-eta-validator:Expanded Details")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "Additional data pertaining to the event and calculation of an ETA",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "expandedDetails",
      accessor: (row) => row,
      disableSortBy: true,
      Cell: (row) => {
        return <ViewExpandedDetailsButton etaTrigger={row.value} />;
      },
      disableFilters: true,
      width: 190,
    },
  ];
};
