import {
  filteredCombinedExceptions,
  getDiagnosticsData,
  getOrderDetails,
  getVinEquipment,
} from "../../../shared/utils/entity.utils";

const vinTabsArray = (
  t,
  vinTabsData,
  connectedCarCoords,
  showDiagnosticDataTab,
  showConnectedCarTab,
  hasTripPlan,
) => {
  const alwaysShow = true;
  const tabs = [];
  const addTab = (condition, tab) => {
    if (condition) {
      tabs.push(tab);
    }
  };

  addTab(hasTripPlan, {
    title: t("fv-vin-details:Trip Plan"),
    accessor: "updates",
  });
  addTab(alwaysShow, {
    title: t("fv-vin-details:Updates"),
    accessor: "statusUpdates",
  });
  addTab(alwaysShow, {
    title: t("fv-vin-details:Active Exceptions"),
    accessor: "combinedExceptions",
  });
  addTab(alwaysShow, {
    title: t("fv-vin-details:Coordinates"),
    accessor: "current_location",
  });
  addTab(vinTabsData.orderDetails?.length > 0, {
    title: t("fv-vin-details:Order Details"),
  });
  addTab(alwaysShow, {
    title: t("fv-vin-details:VIN Details"),
    accessor: "references",
  });
  addTab(showConnectedCarTab && connectedCarCoords.length > 0, {
    title: t("fv-vin-details:Connected Car"),
  });
  addTab(showDiagnosticDataTab && vinTabsData.diagnosticsData?.length > 0, {
    title: t("fv-vin-details:Diagnostics Data"),
  });

  return tabs;
};

export const getVinTabs = ({
  t,
  combinedExceptions,
  currentLocation,
  details,
  connectedCarCoords,
  showDiagnosticDataTab,
  showConnectedCarTab,
  shouldFilterExceptions = false,
  getDiagnosticLabel,
  getColorByState,
  hasTripPlan,
}) => {
  const filterException = filteredCombinedExceptions(
    combinedExceptions,
    shouldFilterExceptions,
  );
  const orderDetails = getOrderDetails(t, details);
  const vinEquipment = getVinEquipment(details);
  const diagnosticsData = getDiagnosticsData(
    t,
    details,
    showDiagnosticDataTab,
    getDiagnosticLabel,
    getColorByState,
  );
  const data = {
    combinedExceptions: filterException,
    currentLocation,
    orderDetails,
    vinEquipment,
    diagnosticsData,
  };

  return {
    array: vinTabsArray(
      t,
      data,
      connectedCarCoords,
      showDiagnosticDataTab,
      showConnectedCarTab,
      hasTripPlan,
    ),
    data,
  };
};
