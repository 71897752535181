/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import SelectField from "components-old/forms/fields/SelectField";
import FormCell from "components-old/forms/FormCell";
import { MediaQueries } from "components/responsive";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import Colors from "styles/colors";
import {
  NameCellColumn,
  HalfPieTableWidget,
} from "pages/shipments/dashboard/components/organisms/HalfPieTableWidget.organism";
import { ShipmentDestinationLocationSelect } from "./Shipments.DestinationLocationSelect.organism";
import { ShipmentResultsModal } from "./Shipments.ResultsModal.organism";

const updateData = (t, data) => {
  return data.map((d) => {
    let translatedName = "";
    let modalName = "";
    let tooltipContent = "";
    const name = d.name?.toUpperCase() ?? "";

    if (name === "EARLY") {
      translatedName = t("shipment-status:Early");
      modalName = t("shipment-status:Early");
      tooltipContent = t(
        "shipment-status:Arrival to destination more than 15 minutes before the lower bound of scheduled destination arrival window",
      );
    } else if (name === "ONTIME") {
      translatedName = t("shipment-status:On-time");
      modalName = t("shipment-status:On-time");
      tooltipContent = t(
        "shipment-status:Arrival to destination 15 minutes before or after the lower bound of scheduled destination arrival window",
      );
    } else if (name === "LATE") {
      translatedName = t("shipment-status:Late");
      modalName = t("shipment-status:Late");
      tooltipContent = t(
        "shipment-status:Arrival to destination more than 15 minutes after the lower bound of scheduled destination arrival window",
      );
    }
    return {
      ...d,
      translatedName,
      modalName,
      tooltipContent,
      // Generating the full display text here to be used wherever it is needed
      fullDescription: translatedName,
      fill: Colors.arrivalStatus[d.code],
    };
  });
};

export const InboundArrivalStatusWidget = ({
  fetchCount,
  countData = {},
  fetchShipments,
  setSearchFilter,
  isShipmentsLoading,
  shipmentResults,
  setPagination,
  page,
  pageSize,
  totalPages,
  setSort,
  sortColumn,
  reverseSort,
  pushShipmentDetailView,
  exportSearch,
  isExporting,
  exportFailed,
  exportIdentifier,
  exportName,
  resetExport,
}) => {
  const { t } = useTranslation("shipment-status");
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [location, setLocation] = useState(null);
  const [chosenStatus, setChosenStatus] = useState(null);

  useEffect(() => {
    fetchCount(status, location?.code);
  }, [status, location, fetchCount]);

  const loaded = !countData?.isLoading;
  let data = countData?.data ? updateData(t, countData.data) : [];

  const columns = [
    {
      Header: t("shipment-status:Status"),
      accessor: "name",
      Cell: (cellInfo) => {
        return <NameCellColumn cellInfo={cellInfo} />;
      },
    },
    {
      Header: t("shipment-status:Shipments"),
      width: 70,
      accessor: "count",
    },
    {
      Header: <Icon src={faSearch} size={FontSize.size14} />,
      width: 20,
      minWidth: 20,
      id: "search",
      Cell: () => {
        return (
          <div
            css={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Icon src={faChevronRight} size={FontSize.size14} />
          </div>
        );
      },
    },
  ];

  const rowClickHandler = (row) => {
    const statusType = status ? [status] : [];
    const locationCode = location?.code ? [location.code] : [];
    const arrivalType = row?.original?.name ? [row.original.name] : ["All"];
    const modalName = row?.original?.modalName ?? null;

    setSearchFilter("unloadType", statusType);
    setSearchFilter("destCode", locationCode);
    setSearchFilter("arrivalType", arrivalType);

    setChosenStatus(modalName);
    setShowModal(true);

    fetchShipments();
  };

  const placeholderText = t("shipment-status:Select...");

  return (
    <React.Fragment>
      <HalfPieTableWidget
        title={t("shipment-status:Inbound Arrival")}
        postTitleIcon={faQuestionCircle}
        postTitleIconTooltip={t(
          "shipment-status:This widget displays arrival status of inbound shipments that have arrived at destination. Shipments will be removed from the widget once the shipment is 12 hours past the lower bound of the destination scheduled arrival window.",
        )}
        columns={columns}
        loaded={loaded}
        chartData={data}
        rowClickHandler={rowClickHandler}
        tooltipLabel={t("shipment-status:SHIPMENTS")}
        tooltipLabelSingular={t("shipment-status:SHIPMENT")}
        subHeaderContent={
          <div
            css={{
              display: "flex",
              padding: "0.5em 1em",
              gap: 10,
              [MediaQueries.smallAndDown]: {
                flexDirection: "column",
              },
            }}
            data-qa="select-arrival-status"
          >
            <FormCell label={t("shipment-status:Live/Drop Status")}>
              <SelectField
                options={[
                  { label: t("shipment-status:Live"), value: "LIVE" },
                  { label: t("shipment-status:Drop"), value: "DROP" },
                ]}
                stateValue={status}
                onChange={(status) => setStatus(status?.value ?? null)}
                placeholder={placeholderText}
                isClearable={true}
                borderWidth="2px"
                cursor="pointer"
                minWidth="10em"
              />
            </FormCell>

            <FormCell label={t("shipment-status:Destination Location")}>
              <ShipmentDestinationLocationSelect
                placeholder={placeholderText}
                loadingText={t("shipment-status:Loading...")}
                value={location}
                onChange={(location) => setLocation(location)}
              />
            </FormCell>
          </div>
        }
      />
      <ShipmentResultsModal
        show={showModal}
        hide={() => setShowModal(false)}
        title={t("shipment-status:Inbound Arrival Shipments")}
        chosenStatus={chosenStatus}
        selectedFields={
          <React.Fragment>
            <div css={{ display: "flex" }}>
              <FormCell
                label={t("shipment-status:Status") + ":"}
                style={{ paddingLeft: 0 }}
              >
                <Text bold>{status ?? t("shipment-status:Not Selected")}</Text>
              </FormCell>
              <FormCell
                label={t("shipment-status:Destination Location") + ":"}
                style={{ paddingLeft: 0 }}
              >
                <Text bold>
                  {location?.label ?? t("shipment-status:Not Selected")}
                </Text>
              </FormCell>
              <DownloadDataButton
                onClick={() => {
                  exportSearch();
                }}
                isExporting={isExporting}
                isDisabled={shipmentResults.length === 0}
                data-qa="arrival-status-search-results-export"
              />
            </div>
            <ExportAlert exportFailed={exportFailed} className="my-2" />
          </React.Fragment>
        }
        searchEntities={fetchShipments}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        shipments={shipmentResults}
        isLoading={isShipmentsLoading}
        setPagination={setPagination}
        setSort={setSort}
        pushShipmentDetailView={pushShipmentDetailView}
      />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
    </React.Fragment>
  );
};

InboundArrivalStatusWidget.propTypes = {
  fetchCount: PropTypes.func,
  countData: PropTypes.object,
  fetchShipments: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isShipmentsLoading: PropTypes.bool,
  shipmentResults: PropTypes.array,
  setPagination: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setSort: PropTypes.func,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  pushShipmentDetailView: PropTypes.func,
};
