/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// UI Libraries
import { Text } from "components/atoms/Text.atom";

const DpuLocationCell = (props) => {
  const { city, code, name, state, country } = props.value;
  const { t } = useTranslation("driveaway-search");
  return (
    <div>
      <Text block>
        {name} ({code})
      </Text>
      <Text block>
        {city}, {state}
      </Text>
      <Text block>{country}</Text>
    </div>
  );
};

export { DpuLocationCell };

DpuLocationCell.propTypes = {
  value: PropTypes.shape({
    city: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
};
