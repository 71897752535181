/** @jsxImportSource @emotion/react */
import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useShipmentDwellNotificationSearchColumns } from "./ShipmentDwellNotificationSearch.columns";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import SearchFiltersContainer from "./components/search/ShipmentDwellNotification.SearchFilters.container";
import ShipmentDwellNotificationEditModalContainer from "./components/organisms/ShipmentDwellNotification.EditModal.organism.container";
import ShipmentDwellNotificationDeleteModalContainer from "./components/organisms/ShipmentDwellNotification.DeleteModal.organism.container";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";

// Definitions
export const ShipmentDwellModalMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const ShipmentDwellNotificationSearch = ({
  isLoading,
  searchResults = [],
  totalCount,
  page,
  pageSize,
  totalPages,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
  canUserSearch,
  hasSearchCriteriaChanged,
  searchEntities,
  setPagination,
  setSort,
}) => {
  const { t } = useTranslation("shipment-dwell");

  useSetTitleOnMount(
    t("shipment-dwell:Shipment Dwell Notification Management"),
  );
  useSetDescriptionOnMount(
    t(
      "shipment-dwell:The Shipment Dwell Notification sends a SMS message to individuals if a shipment has dwelled at its destination location for too long after arrival.",
    ),
  );

  useTrackWithMixpanelOnce(
    "Viewed Page: Shipment Dwell Notification Management",
  );

  const [modal, setModal] = useState({ mode: null, data: null });

  useEffect(() => {
    searchEntities();
  }, [searchEntities]);

  const columns = useShipmentDwellNotificationSearchColumns((mode, data) => {
    setModal({ mode: ShipmentDwellModalMode[mode], data: data });
  });

  const closeModal = useCallback(() => {
    setModal({ mode: null, data: null });
  }, [setModal]);

  const onPageChange = useCallback(
    (newPage) => {
      setPagination(null, newPage, pageSize);
    },
    [setPagination, pageSize],
  );

  const onPageSizeChange = useCallback(
    (newPageSize) => {
      setPagination(null, page, newPageSize);
    },
    [setPagination, page],
  );

  const onPageSort = useCallback(
    (column, isReverse) => {
      setSort(null, column, isReverse);
    },
    [setSort],
  );

  return (
    <div
      css={{
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <div css={{ display: "flex", gap: "1em" }}>
        <div css={{ flexGrow: 1 }}>
          <SearchFiltersContainer
            show={true}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ margin: 0, borderRadius: 3 }}
          />
        </div>
        {/* Search */}
        <Button
          variant={
            hasSearchCriteriaChanged && canUserSearch ? "primary" : "dark"
          }
          css={{ padding: "0 2em" }}
          pulse={hasSearchCriteriaChanged && canUserSearch}
          onClick={() => {
            searchEntities();
          }}
          disabled={!canUserSearch}
          data-qa="button-search"
        >
          {t("shipment-dwell:Search")}
        </Button>
        {/* Add Dwell Notification button */}
        <Button
          variant="success"
          onClick={() =>
            setModal({ mode: ShipmentDwellModalMode.CREATE, data: null })
          }
        >
          <Icon src={faPlus} /> {t("shipment-dwell:Add Dwell Notification")}
        </Button>
      </div>

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          [MediaQueries.smallAndUp]: {
            flexDirection: "row",
            width: "unset",
          },
        }}
      >
        <div
          style={{
            flex: "1 0",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text
            size={FontSize.size16}
            bold
            css={{
              position: "relative",
              ".loadedContent": { display: "inline" },
            }}
          >
            {totalCount.toLocaleString()}
          </Text>
          <Text
            size={FontSize.size16}
            css={{
              marginLeft: 4,
              whiteSpace: "nowrap",
            }}
          >
            Locations
          </Text>
        </div>
      </div>

      <BaseTable
        columns={columns}
        data={searchResults}
        totalCount={totalCount}
        isLoading={isLoading}
        showPagination={true}
        fixPaginationToBottom={true}
        isManualPagination={true}
        pageIndex={page}
        pageSize={pageSize}
        pageCount={totalPages}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isManualSorting={true}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        defaultSortColumn={defaultSortColumn}
        defaultReverseSort={defaultReverseSort}
        onPageSort={onPageSort}
      />
      <ShipmentDwellNotificationEditModalContainer
        mode={modal.mode}
        show={
          modal.mode === ShipmentDwellModalMode.UPDATE ||
          modal.mode === ShipmentDwellModalMode.CREATE
        }
        hide={closeModal}
        rowData={modal.data}
      />
      <ShipmentDwellNotificationDeleteModalContainer
        show={modal.mode === ShipmentDwellModalMode.DELETE}
        hide={closeModal}
        rowData={modal.data}
      />
    </div>
  );
};

ShipmentDwellNotificationSearch.propTypes = {
  isLoading: PropTypes.bool,
  searchResults: PropTypes.array,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  canUserSearch: PropTypes.bool,
  hasSearchCriteriaChanged: PropTypes.bool,
  searchEntities: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
};
