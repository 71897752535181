/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { Tabs } from "components/molecules/Tabs.molecule";
import { LobsType, useLadsTranslation } from "modules/lads/utils/lads.utils";
import LadTable from "./components/LadTable";

export const LocationLadsListView = ({ ladsGrouped, updateLadLabel }) => {
  const { getTranslatedLobName } = useLadsTranslation();

  return (
    <Tabs style={{ padding: "1rem" }}>
      <Tabs.TabList>
        <Tabs.Tab>{getTranslatedLobName(LobsType.ASSEMBLY)}</Tabs.Tab>
        <Tabs.Tab>{getTranslatedLobName(LobsType.FINISHED_PRODUCT)}</Tabs.Tab>
        <Tabs.Tab>{getTranslatedLobName(LobsType.AFTERMARKET_PARTS)}</Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanel>
        <LadTable
          data={ladsGrouped[LobsType.ASSEMBLY]}
          updateLadLabel={updateLadLabel}
        />
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        <LadTable
          data={ladsGrouped[LobsType.FINISHED_PRODUCT]}
          updateLadLabel={updateLadLabel}
        />
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        <LadTable
          data={ladsGrouped[LobsType.AFTERMARKET_PARTS]}
          updateLadLabel={updateLadLabel}
        />
      </Tabs.TabPanel>
    </Tabs>
  );
};

LocationLadsListView.propTypes = {
  ladsGrouped: PropTypes.object,
  updateLadLabel: PropTypes.func.isRequired,
};
