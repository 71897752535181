/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "./colors";

export const LinkButton = ({ clickHandler, children, style = {} }) => (
  <button
    style={{
      textDecoration: "underline",
      color: Colors.text.HIT_GRAY,
      fontSize: 12,
      background: "none",
      border: "none",
      padding: 0,
      ...style,
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.color = Colors.highlight.BRIGHT_BLUE;
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.color = Colors.text.HIT_GRAY;
    }}
    onClick={clickHandler}
  >
    {children}
  </button>
);

LinkButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export const markerStyle = { fontSize: 18, margin: "0 20 0 2" };
export const actionButtonStyle = {
  fontWeight: 400,
  fontStyle: "italic",
  whiteSpace: "nowrap",
};
