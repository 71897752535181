import { connect } from "react-redux";
import { ReviewRequestModal } from "./DriveAway.ReviewRequestModal";
import DriveAwayReviewRequestState from "../../redux/DriveAwayReviewRequest";

const mapStateToProps = (state) => {
  const { getRequestDriveAwayStatus, getDenyReasons, isDenyReasonsLoading } =
    DriveAwayReviewRequestState.selectors;

  return {
    requestDriveAwayStatus: getRequestDriveAwayStatus(state),
    driveAwayDenyReasons: getDenyReasons(state),
    isDenyReasonsLoading: isDenyReasonsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { submitDriveAwayRequestReview, resetDriveAwayRequestReviewStatus } =
    DriveAwayReviewRequestState.actionCreators;

  return {
    submitDriveAwayRequestReview: (daId, requestBody) =>
      dispatch(submitDriveAwayRequestReview(daId, requestBody)),
    resetDriveAwayRequestReviewStatus: () =>
      dispatch(resetDriveAwayRequestReviewStatus),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewRequestModal);
