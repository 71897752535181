/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";

const linksCss = {
  padding: "1em 0",
};

const SampleLinks = (props) => {
  const { t, links } = props;

  if (!links) {
    return null;
  }

  return (
    <div id="links" css={linksCss}>
      <SectionHeader title={t("documentation:Related Links")} />
      <ul>
        <li>
          <a href={links.url} target="_blank" rel="noopener noreferrer">
            {links.content}
          </a>
        </li>
      </ul>
    </div>
  );
};

SampleLinks.propTypes = {
  links: PropTypes.object,
};
export default withTranslation(["documentation"])(SampleLinks);
