import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { logWarningForMissingTranslation } from "utils/log-warning.utils";
import {
  Organization,
  OrganizationType,
} from "shared/constants/organization.const";

export const useOrganizationsTranslation = () => {
  const { t } = useTranslation("organizations");

  const getTranslatedOrganizationType = useCallback(
    (type: string) => {
      switch (type) {
        case OrganizationType.SHIPPER:
          return t("organizations:Shipper");
        case OrganizationType.CARRIER:
          return t("organizations:Carrier");
        case OrganizationType.PARTNER:
          return t("organizations:Partner");
        case OrganizationType.HEALTHCAREPROVIDER:
          return t("organizations:Healthcare Provider");
        case OrganizationType.DEALER:
          return t("organizations:Dealer");
        case OrganizationType.DISTRIBUTION_CENTER:
          return t("organizations:Distribution Center");
        case OrganizationType.FREIGHTVERIFY:
          return t("organizations:FreightVerify");
        case OrganizationType.SUPPLIER:
          return t("organizations:Supplier");
        case OrganizationType.THIRDPARTY:
          return t("organizations:Third Party");
        default: {
          logWarningForMissingTranslation(type);
          return type;
        }
      }
    },
    [t],
  );

  return {
    getTranslatedOrganizationType,
  };
};

export const useExternalCodeDefinitionTranslation = () => {
  const { t } = useTranslation("organizations");

  const getExternalCodeDefinitionTranslation = useCallback(
    (type: string) => {
      switch (type) {
        case "BAC Code": {
          return t("organizations:BAC Code");
        }
        case "Fleet Code": {
          return t("organizations:Fleet Code");
        }
        case "End User Fleet Code": {
          return t("organizations:End User Fleet Code");
        }
        case "P&A Code": {
          return t("organizations:P&A Code");
        }
        default:
          return type;
      }
    },
    [t],
  );

  return {
    getExternalCodeDefinitionTranslation,
  };
};

export const isShipper = (organization?: Organization) => {
  return Boolean(
    organization &&
      [OrganizationType.SHIPPER, OrganizationType.FREIGHTVERIFY].includes(
        organization.org_type,
      ),
  );
};

export const isCarrier = (organization?: Organization) => {
  return Boolean(
    organization &&
      [OrganizationType.CARRIER, OrganizationType.FREIGHTVERIFY].includes(
        organization.org_type,
      ),
  );
};

export const isPartner = (organization?: Organization) => {
  return Boolean(
    organization && [OrganizationType.PARTNER].includes(organization.org_type),
  );
};

export const isHealthcareProvider = (organization?: Organization) => {
  return Boolean(
    organization &&
      [OrganizationType.HEALTHCAREPROVIDER].includes(organization.org_type),
  );
};

export const isDealer = (organization?: Organization) => {
  return Boolean(
    organization && [OrganizationType.DEALER].includes(organization.org_type),
  );
};
