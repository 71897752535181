/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { MetalTrackingDashboard } from "./MetalTrackingDashboard";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

export const MetalTrackingDashboardView = ({
  solutionId,
  fetchMetalTrackingEntities,
  authorization,
  metalTrackingEntities,
  toggleWatchedRackFlag,
  setSearchFilter,
  chooseLocation,
}) => {
  const { t } = useTranslation("mt-dashboard");

  useSetTitleOnMount(t("mt-dashboard:Plant Asset Tracking"));

  useSetDescriptionOnMount(
    t(
      "mt-dashboard:A dashboard to assist with metal rack expiration and inventory management",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: Metal / Dashboard");

  useEffect(() => {
    fetchMetalTrackingEntities(solutionId);
  }, [fetchMetalTrackingEntities, solutionId]);

  return (
    <MetalTrackingDashboard
      metalTrackingEntities={metalTrackingEntities}
      solutionId={solutionId}
      authorization={authorization}
      toggleWatchedRackFlag={toggleWatchedRackFlag}
      setSearchFilter={setSearchFilter}
      chooseLocation={chooseLocation}
    />
  );
};

MetalTrackingDashboardView.propTypes = {
  authorization: PropTypes.object.isRequired,
  chooseLocation: PropTypes.func,
  fetchMetalTrackingEntities: PropTypes.any,
  metalTrackingEntities: PropTypes.array.isRequired,
  setSearchFilter: PropTypes.func,
  solutionId: PropTypes.string,
  toggleWatchedRackFlag: PropTypes.func,
};
