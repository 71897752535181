import { ItemAttribute, DependencyType } from "../const";

/**
 * This retrieves a default value for an input based on the field's configuration,
 * current partner type, and current milestone event.  See the README in this
 * folder for examples of how to configure the fields.
 */
export const getDefaultValue = ({ item, partnerType, milestoneEvent }) => {
  const attribute = ItemAttribute.DEFAULT_VALUE;
  const value = lookupValue({ item, attribute, partnerType, milestoneEvent });
  return typeof value === "undefined" ? null : value;
};

/**
 * This supports returning a boolean to determine whether to display an input or not
 * based on the field's configuration, current partner type, and current milestone event.
 * See the README in this folder for examples of how to configure the fields.
 */
export const isDisplayed = ({ item, partnerType, milestoneEvent }) => {
  const attribute = ItemAttribute.DISPLAYED;
  const value = lookupValue({ item, attribute, partnerType, milestoneEvent });
  return typeof value === "undefined" ? true : value;
};

export const getMilestoneEventValue = (inputVal) => {
  if (inputVal) {
    const [code, vmacsCode] = inputVal.split("_");
    return { code, vmacsCode };
  }
  return { code: null, vmacsCode: null };
};

export const hasDisableUntilValue = ({ item, partnerType, milestoneEvent }) => {
  const attribute = ItemAttribute.DISABLE_UNTIL_VALUE;
  const value = lookupValue({ item, attribute, partnerType, milestoneEvent });
  return typeof value === "undefined" ? null : value;
};

const lookupValue = ({ item, attribute, partnerType, milestoneEvent }) => {
  if (
    milestoneEvent &&
    hasKey(item[attribute], partnerType) &&
    hasKey(item[attribute][partnerType], milestoneEvent)
  ) {
    return item[attribute][partnerType][milestoneEvent];
  }
  if (hasMilestoneDependency({ item, attribute, partnerType })) {
    return item[attribute].default; // fallback
  }
  if (partnerType && hasKey(item[attribute], partnerType)) {
    return item[attribute][partnerType];
  }

  return item[attribute];
};

/**
 * This is a null safe way to see if an object has a key
 */
const hasKey = (object, key) =>
  object ? Object.keys(object).includes(key) : false;

const hasMilestoneDependency = ({ item, attribute, partnerType }) =>
  item[attribute]?.[partnerType]?.dependency === DependencyType.MILESTONE_EVENT;
