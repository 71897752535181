import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";
import { shipmentDwellNotificationLocationOptionsState } from "../../redux/ShipmentDwellNotificationSearchFilterLoaders";

export const FILTERS = [
  {
    queryKey: "location",
    label: (t) => t("shipment-dwell:Location"),
    Component: AsyncSelectFilterButton,
    isMulti: false,
    optionsState: shipmentDwellNotificationLocationOptionsState,
    queryBuilder: (queryParameter, value) => {
      const option = value[0] ?? null; // Single select
      const name = option?.name;
      const code = option?.code;

      if (!name || !code) {
        return "";
      }

      return (
        getBasicQueryString("locationName", name) +
        getBasicQueryString("locationCode", code)
      );
    },
    hideSelectEmpty: true,
  },
];
