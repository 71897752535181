import axios from "axios";
import _ from "lodash";

import apiUrl from "api-url";
import { SEARCH_CATEGORIES } from "../components/search/CarrierView.searchOptions";
import { FILTERS } from "../components/search/CarrierView.searchOptions";
import moment from "moment";

const MOUNT_POINT = "carrierViewSavedSearchCards";

const FETCH_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/FETCH_SAVED_SEARCH_CARD_DATA`;
const RECEIVE_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/RECIEVE_SAVED_SEARCH_CARD_DATA`;

const formatResponse = (response) => {
  return {
    exceptions: response.data?.data[0]?.exceptions || [],
    deliveredCount: response.data?.data[0]?.deliveredCount || 0,
    activeCount: response.data?.data[0]?.activeCount || 0,
  };
};

// Using search categories and filters, get the query strings from the queryBuilder
const getSavedSearchQueryString = (savedSearch) => {
  const searchObj = savedSearch.search;

  let category = null;
  let categoryValue = null;
  for (let categoryDef of SEARCH_CATEGORIES) {
    if (
      searchObj[categoryDef.queryKey] !== null &&
      typeof searchObj[categoryDef.queryKey] === "string"
    ) {
      category = categoryDef;
      categoryValue = searchObj[category.queryKey];
    }
  }

  let filterValues = searchObj;
  if (!_.isNil(category)) {
    filterValues = _.omit(filterValues, category.queryKey);
  }

  let qs = "";

  if (category && categoryValue) {
    qs += category.queryBuilder(category.queryKey, categoryValue);
  }
  const filterValueKeys = Object.keys(filterValues);
  for (let filterDef of FILTERS) {
    // Handle an array of QSPs for NFilterButton.
    if (
      Array.isArray(filterDef.queryKey) &&
      filterValueKeys.some((r) => filterDef.queryKey.includes(r))
    ) {
      qs += filterDef.queryBuilder(filterDef.queryKey, filterValues);
    }

    // Handle usual filter value.
    if (!_.isNil(filterValues[filterDef.queryKey])) {
      qs += filterDef.queryBuilder(
        filterDef.queryKey,
        filterValues[filterDef.queryKey],
      );
    }
  }

  return qs;
};

// Object that will hold cancel callbacks for the requests made in the fetch
const tokens = {};
// Allows us to know if the error is from a cancelation in the catch of the promise
const cancelMessage = "CANCELED";

const fetchSavedSearchCardData = (savedSearch) => {
  // If we saved tokens from a previous request, cancel those requests
  if (tokens[savedSearch.id]) {
    tokens[savedSearch.id].cancelRequest(cancelMessage);
  }

  // Reset tokens for this saved search
  tokens[savedSearch.id] = {};

  return (dispatch) => {
    dispatch({ type: FETCH_SAVED_SEARCH_CARD_DATA, id: savedSearch.id });

    // Clone the saved search object (to not affect the passed in one)
    // and override the lifeCycleState parameter
    let clonedSavedSearch = _.cloneDeep(savedSearch);

    const isBatch = !_.isNil(clonedSavedSearch.search.batch);

    // Build the parameters and add batch if applicable
    const params = new URLSearchParams(
      getSavedSearchQueryString(clonedSavedSearch),
    );

    if (isBatch) {
      params.append("batchType", clonedSavedSearch.search.batch.batch_type);
    }

    // Batch searches use POST with data in the body and query string parameters
    // Regular searches use GET with query string parameters
    const request = axios({
      method: isBatch ? "POST" : "GET",
      url: apiUrl(
        `/entity/internal${
          isBatch ? "/batch-search" : ""
        }?${params.toString()}`,
      ),
      data: isBatch
        ? { batch_list: clonedSavedSearch.search.batch.batch_list }
        : undefined,
      headers: {
        Accept: "application/json;version=savedsearch",
        "x-time-zone": moment.tz.guess(),
      },
      // Create a cancel token for this request and save it in the tokens object
      cancelToken: new axios.CancelToken((cancel) => {
        tokens[savedSearch.id].cancelRequest = cancel;
      }),
    });

    return request
      .then((response) => {
        dispatch({
          type: RECEIVE_SAVED_SEARCH_CARD_DATA,
          id: savedSearch.id,
          data: formatResponse(response),
        });
      })
      .catch((error) => {
        if (error.message !== cancelMessage) {
          console.log(error);
        }
      });
  };
};

const getSavedSearchCardData = (id) => (state) => state[MOUNT_POINT][id];

const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: [],
          isLoading: true,
        },
      };
    case RECEIVE_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: action.data,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default {
  mountPoint: MOUNT_POINT,
  actionCreators: { fetchSavedSearchCardData },
  selectors: { getSavedSearchCardData },
  reducer: reducer,
};
