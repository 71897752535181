/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";

import FormCell from "../FormCell";
import Colors from "styles/colors";

const StandardInput = ({
  label,
  type,
  value,
  placeholder,
  onChange,
  isDisabled,
  isReadOnly,
  asTextArea = false,
}) => {
  return (
    <FormCell label={label}>
      <FormControl
        as={asTextArea ? "textarea" : "input"}
        rows={4} // This will only take effect when asTextArea is true
        style={{
          backgroundColor: isReadOnly ? Colors.background.LIGHT_GRAY : "white",
          height: asTextArea ? "" : "3em",
          boxShadow: "none",
          borderRadius: "5px",
          borderWidth: 2,
          width: "100%",
        }}
        type={type}
        value={value || ""}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        readOnly={isReadOnly}
        disabled={isDisabled}
      />
    </FormCell>
  );
};

StandardInput.propTypes = {
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
};

StandardInput.defaultProps = {
  type: "text",
};

export default StandardInput;
