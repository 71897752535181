import {
  getBasicQueryString,
  getApiLogsDateRangeQueryString,
  getMultiSelectQueryString,
} from "components/search-bar/search-filter-query-strings";
import {
  SelectFilterButton,
  DateRangeFilterButton,
  AsyncSelectFilterButton,
  TextFilterButton,
} from "components/search-bar/FilterButtons";
import { milestoneLogsLocationsOptionsState } from "./MilestoneLogsFilterLoaders";

export const SEARCH_CATEGORIES = [];

const typeFilterOptions = [
  {
    label: "Completed and Failed",
    value: "event-error",
  },
  {
    label: "Failed Only",
    value: "error",
  },
];

export const FILTERS = [
  {
    queryKey: "type",
    label: (t) => t("documentation:Status Type"),
    Component: SelectFilterButton,
    optionsGetter: () => typeFilterOptions,
    queryBuilder: getBasicQueryString,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ts",
    label: (t) => t("documentation:Date Range"),
    Component: DateRangeFilterButton,
    queryBuilder: getApiLogsDateRangeQueryString,
  },
  {
    queryKey: "entityId",
    Component: TextFilterButton,
    isMulti: false,
    label: (t) => t("documentation:VIN"),
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "milestone_location_code",
    Component: AsyncSelectFilterButton,
    isMulti: false,
    label: (t) => t("milestone-search:Location"),
    optionsState: milestoneLogsLocationsOptionsState,
    queryBuilder: (queryParameters, filterValue) =>
      getMultiSelectQueryString(queryParameters, filterValue),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "milestone_description",
    label: (t) => t("milestone-search:Milestone Event"),
    Component: TextFilterButton,
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "milestone_status_code",
    label: (t) => t("milestone-search:Milestone Status Code"),
    Component: TextFilterButton,
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "milestone_status_ts",
    label: (t) => t("milestone-search:Milestone Event Times"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getApiLogsDateRangeQueryString,
  },
];
