import apiUrl from "api-url";
import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import { buildFVShimentIdHeaders } from "./EditShipmentState";

const ShipmentSubscriptionState = buildSubscriptionState({
  topic: "Shipments",
  systemType: "shipment",
  getSubscribeeId: (subscribee) => subscribee.creator_shipment_id,
  getUrl: (id) => apiUrl(`/preferences-ng/shipments/${id}`),
  getAdditionalRequestConfig: async (
    requestType,
    subscribee,
    _ignored_state,
    dispatch,
  ) => {
    const config = {
      // Include header for all requests
      headers: await dispatch(buildFVShimentIdHeaders(subscribee)),
    };

    if (requestType === "SUBSCRIBE") {
      config.data = {};
      config.data.subscribing_product = "Shipment View";
    }

    return config;
  },
});

export default ShipmentSubscriptionState;
