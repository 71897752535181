//External packages
import { produce } from "immer";
import _ from "lodash";
import axios from "axios";

import apiUrl from "api-url";
import { SEARCH_CATEGORIES } from "pages/shipments/create-shipment/components/search/ShipmentStopsSearchFilterCategoryDefs";

const STORE_MOUNT_POINT = "fvShipperLocations";

const FVS = (actionName) => `Locations/Search/${actionName}`;
const SET_SEARCH_TEXT = FVS("SET_SEARCH_TEXT");
const CLEAR_SEARCH_TEXT = FVS("CLEAR_SEARCH_TEXT");
const SET_CATEGORY = FVS("SET_SEARCH_CATEGORY");
const RESET_SEARCH_BAR = FVS("RESET_SEARCH_BAR");
const SHIPPER_LOCATION_SEARCH = FVS("SHIPPER_LOCATION_SEARCH");
const SHIPPER_LOCATION_LOADING = FVS("SHIPPER_LOCATION_LOADING");
const SHIPPER_LOCATION_LOADED = FVS("SHIPPER_LOCATION_LOADED");

const searchLocations = (shipperId, ind, verbose = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const searchText = getSearchText(state);
    const searchCategory = getSearchCategory(state);

    // No search text entered; do not perform a fetch
    if (!searchText || !searchText[ind]) {
      return Promise.all([
        dispatch({
          type: SHIPPER_LOCATION_LOADED,
        }),
      ]);
    }

    let qs = buildSearchQueryString(searchText[ind], searchCategory);
    if (qs.startsWith("&")) {
      qs = qs.slice(1);
    }
    let searchLocationsUrl = apiUrl(`/location/locations`);
    searchLocationsUrl += verbose ? "?verbose=true" : "?verbose=false";
    const url = `${searchLocationsUrl}&owner_id=${shipperId}&${qs}`;

    dispatch({
      type: SHIPPER_LOCATION_LOADING,
    });

    return Promise.all([axios.get(url)])
      .then((responses) => {
        dispatch({
          type: SHIPPER_LOCATION_SEARCH,
          data: responses[0].data,
          ind: ind,
        });

        dispatch({
          type: SHIPPER_LOCATION_LOADED,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const setSearchText = (searchText, ind) => ({
  type: SET_SEARCH_TEXT,
  searchText: searchText,
  ind: ind,
});

const clearSearchText = (ind) => ({
  type: CLEAR_SEARCH_TEXT,
  ind: ind,
});

const setSearchCategory = (category) => ({
  type: SET_CATEGORY,
  category,
});

const setSearchCategoryForKey = (catKey) =>
  setSearchCategory(SEARCH_CATEGORIES.byKey(catKey));

const resetSearchBar = (ind) => ({
  type: RESET_SEARCH_BAR,
  ind: ind,
});

// helpers

export const buildSearchQueryString = (searchText, searchCategory) => {
  let queryString = "";
  const searchQs =
    _.isEmpty(searchText) || !searchCategory
      ? ""
      : `${searchCategory.queryKey}=${encodeURIComponent(searchText)}`;

  if (searchQs) {
    queryString += `${searchQs}`;
  }

  return queryString;
};

// selectors
const getSearchCategory = (state) => state[STORE_MOUNT_POINT].searchCategory;
const getSearchText = (state) => state[STORE_MOUNT_POINT].searchText;
const getSearchResults = (state) => {
  const val = state[STORE_MOUNT_POINT].searchResults
    ? state[STORE_MOUNT_POINT].searchResults
    : [];
  return val;
};

const getIsLoading = (state) => state[STORE_MOUNT_POINT].isLoading || false;

// state

const initialState = {
  searchText: {},
  searchCategory: SEARCH_CATEGORIES[0], // default to everything category
  searchResults: {},
};

const searchFiltersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAR_SEARCH_TEXT:
        draft.searchText[action.ind] = "";
        return;

      case RESET_SEARCH_BAR:
        draft.searchText[action.ind] = "";
        draft.searchCategory = initialState.searchCategory;
        return;

      case SET_SEARCH_TEXT:
        draft.searchText[action.ind] = action.searchText;
        return;

      case SET_CATEGORY:
        draft.searchCategory = action.category || initialState.searchCategory;
        return;

      case SHIPPER_LOCATION_SEARCH:
        draft.searchResults[action.ind] = action.data;
        return;

      case SHIPPER_LOCATION_LOADING:
        draft.isLoading = true;
        return;

      case SHIPPER_LOCATION_LOADED:
        draft.isLoading = false;
        return;

      default:
        return;
    }
  });

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    setSearchText,
    clearSearchText,
    setSearchCategory,
    setSearchCategoryForKey,
    resetSearchBar,
    searchLocations,
  },
  selectors: {
    getSearchText,
    getSearchCategory,
    getSearchResults,
    getIsLoading,
  },
  reducer: searchFiltersReducer,
};
