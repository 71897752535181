/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  faCaretLeft,
  faCaretRight,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { NoChartData } from "components/molecules/NoChartData.molecule";
import { ListGroup } from "components/atoms/ListGroup.atom";
import { Bubble } from "components/atoms/Bubble.atom";
import Loader from "react-loader";

import { useIsMediumAndDown, MediaQueries } from "components/responsive";

export const CapacityMeterWidget = ({
  watchedLocations,
  pushView,
  isLoading = true,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation("inventory-view");
  const isMobile = useIsMediumAndDown();

  let data = watchedLocations.map((location) => {
    return location;
  });

  // Pagination
  const pageSize = isMobile ? 3 : 8;
  const totalPages = _.ceil(data.length / pageSize);

  const pagedData = data.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize,
  );

  return (
    <PanelGroup>
      <PanelGroup.Header
        title={t("inventory-view:Capacity Meter")}
        postTitleIcon={faQuestionCircle}
        tooltipPosition="bottom"
        postTitleIconTooltip={t(
          `inventory-view:The capacity meter describes each location's available capacity by comparing onsite VIN inventory and that location's allocated space`,
        )}
      />
      <PanelGroup.Content style={{ position: "relative", minHeight: 200 }}>
        <Loader loaded={!isLoading}>
          {watchedLocations?.length ? (
            <div
              css={{
                maxHeight: "25rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {totalPages > 1 && !(currentPage <= 0) && (
                <Button
                  size="sm"
                  variant="transparent"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <Icon src={faCaretLeft} size={FontSize.size28} />
                </Button>
              )}
              <div
                className="my-2"
                css={{
                  display: "grid",
                  gridTemplateRows: "repeat(4, 1fr)",
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridAutoFlow: "column",
                  width: "100%",
                  [MediaQueries.largeAndUp]: {
                    gridTemplateColumns: "repeat(2, 1fr)",
                  },
                }}
              >
                {pagedData.map((location) => {
                  let percentage = location.capacity?.percentFill
                    ? Math.round(location.capacity?.percentFill)
                    : 0;
                  return (
                    <ListGroup.Item
                      key={location?.locationId}
                      onClick={() => {
                        pushView(location?.locationId);
                      }}
                      styles={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "start",
                        border: "none",
                        fontFamily: "inherit",
                        columnGap: "0.75rem",
                        padding: "0.75rem 1.25rem",
                      }}
                    >
                      <Bubble
                        size={FontSize.size15}
                        color={Colors.text.WHITE}
                        background={
                          percentage >= 100
                            ? Colors.highlight.RED
                            : Colors.highlight.GREEN
                        }
                        css={{
                          marginLeft: 7,
                          minWidth: 52,
                          padding: "2px 8px",
                          display: "block",
                          height: "fit-content",
                        }}
                      >
                        {`${percentage} %`}
                      </Bubble>
                      <div className="pl-3">
                        <div>
                          {location?.name} - {location?.code}
                        </div>
                        {location?.capacity?.onSite} /
                        {location?.capacity?.totalAllocated}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </div>
              {totalPages > 1 && !(currentPage >= totalPages - 1) && (
                <Button
                  size="sm"
                  variant="transparent"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <Icon src={faCaretRight} size={FontSize.size28} />
                </Button>
              )}
            </div>
          ) : (
            <div
              css={{
                minHeight: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: Colors.text.GRAY,
                padding: "2em",
                textAlign: "center",
              }}
            >
              <NoChartData
                size={FontSize.size20}
                label={t("inventory-view:Please select your locations")}
              />
            </div>
          )}
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

CapacityMeterWidget.propTypes = {
  isLoading: PropTypes.bool,
  pushView: PropTypes.func,
  watchedLocations: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
