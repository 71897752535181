import { useSelector, useDispatch } from "react-redux";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { useCallback } from "react";

// Hook for interacting with a search store created with SearchBarStateBuilder
export const useSearchBarState = (searchBarState) => {
  const dispatch = useDispatch();

  const { selectors, actionCreators } = searchBarState;
  const solutionId = useSelector(getSolutionId);

  return {
    dispatch,
    solutionId,
    searchText: useSelector(selectors.getSearchText),
    searchValue: useSelector(selectors.getSearchValue),
    typeaheadOptionsMetadata: useSelector(
      selectors.getTypeaheadOptionsMetadata,
    ),
    searchFilters: useSelector(selectors.getSearchFilters),
    searchCategory: useSelector(selectors.getSearchCategory),
    hasSearchCriteriaChanged: useSelector(
      selectors.getHasSearchCriteriaChanged,
    ),
    selectedSavedSearch: useSelector(selectors.getSelectedSavedSearch),
    entities: useSelector(selectors.getEntities),
    searchResults: useSelector(selectors.getSearchResults),
    showAdvancedSearch: useSelector(selectors.getShowAdvancedSearch),
    isLoading: useSelector(selectors.getIsLoading),
    isLoadingError: useSelector(selectors.getIsLoadingError),
    loadingError: useSelector(selectors.getLoadingError),
    page: useSelector(selectors.getPage),
    pageSize: useSelector(selectors.getPageSize),
    totalPages: useSelector(selectors.getTotalPages),
    totalEntities: useSelector(selectors.getTotalEntities),
    defaultSortColumn: useSelector(selectors.getDefaultSortColumn),
    defaultReverseSort: useSelector(selectors.getDefaultReverseSort),
    sortColumn: useSelector(selectors.getSortColumn),
    reverseSort: useSelector(selectors.getReverseSort),
    isExporting: useSelector(selectors.getIsExporting),
    exportFailed: useSelector(selectors.getExportFailed),
    searchQueryString: useSelector(selectors.selectSearchQueryString),
    setSearchText: useCallback(
      (searchText) => dispatch(actionCreators.setSearchText(searchText)),
      [dispatch, actionCreators],
    ),
    setSearchValue: useCallback(
      (searchValue) => dispatch(actionCreators.setSearchValue(searchValue)),
      [dispatch, actionCreators],
    ),
    clearSearchText: useCallback(
      () => dispatch(actionCreators.clearSearchText()),
      [dispatch, actionCreators],
    ),
    setSearchCategory: useCallback(
      (category) => dispatch(actionCreators.setSearchCategory(category)),
      [dispatch, actionCreators],
    ),
    setSearchCategoryForKey: useCallback(
      (catKey) => dispatch(actionCreators.setSearchCategoryForKey(catKey)),
      [dispatch, actionCreators],
    ),
    setSearchFilter: useCallback(
      (key, value) => dispatch(actionCreators.setSearchFilter(key, value)),
      [dispatch, actionCreators],
    ),
    clearSearchFilter: useCallback(
      (key) => dispatch(actionCreators.clearSearchFilter(key)),
      [dispatch, actionCreators],
    ),
    clearSearchFilters: useCallback(
      () => dispatch(actionCreators.clearSearchFilters()),
      [dispatch, actionCreators],
    ),
    resetSearchBar: useCallback(
      () => dispatch(actionCreators.resetSearchBar()),
      [dispatch, actionCreators],
    ),
    resetSearchAndFilters: useCallback(
      () => dispatch(actionCreators.resetSearchAndFilters()),
      [dispatch, actionCreators],
    ),
    selectSavedSearch: useCallback(
      (item) => dispatch(actionCreators.selectSavedSearch(item)),
      [dispatch, actionCreators],
    ),
    resetSavedSearch: useCallback(
      () => dispatch(actionCreators.resetSavedSearch()),
      [dispatch, actionCreators],
    ),
    searchEntities: useCallback(
      (solutionId, resetPagination) =>
        dispatch(actionCreators.searchEntities(solutionId, resetPagination)),
      [dispatch, actionCreators],
    ),
    clearEntities: useCallback(
      () => dispatch(actionCreators.clearEntities()),
      [dispatch, actionCreators],
    ),
    exportEntities: useCallback(
      () => dispatch(actionCreators.exportEntities()),
      [dispatch, actionCreators],
    ),
    toggleShowFilters: useCallback(
      (showFilters) => dispatch(actionCreators.toggleShowFilters(showFilters)),
      [dispatch, actionCreators],
    ),
    setPagination: useCallback(
      (solutionId, page, pageSize) =>
        dispatch(actionCreators.setPagination(solutionId, page, pageSize)),
      [dispatch, actionCreators],
    ),
    setSort: useCallback(
      (solutionId, sortColumn, reverseSort) =>
        dispatch(actionCreators.setSort(solutionId, sortColumn, reverseSort)),
      [dispatch, actionCreators],
    ),
  };
};

export default useSearchBarState;
