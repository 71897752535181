/** @jsxImportSource @emotion/react */

import { Colors } from "../styles/variables";
import { Button } from "react-bootstrap";

const LinkButton = ({ children, style, ...props }) => {
  return (
    <Button
      variant="link"
      className="p-0 m-0"
      css={{
        color: Colors.text.HIT_GRAY,
        fontSize: "larger",
        textDecoration: "underline",
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
