/** @jsxImportSource @emotion/react */
import Select from "react-select";

/**
 * Filter <Select> dropdown for use in Results Table filter row
 * @param props
 * @return {*}
 * @constructor
 */
const SelectFilter = ({ isClearable = true, ...remainingProps }) => {
  return (
    <Select
      isClearable={isClearable}
      {...remainingProps}
      styles={{
        container: (provided) => ({ ...provided, height: "2.3em" }),
        control: (provided) => ({
          ...provided,
          height: "2.3em",
          minHeight: "unset",
          border: "1px solid rgba(0,0,0,0.1)",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "2.3em",
          cursor: "pointer",
        }),
      }}
    />
  );
};

SelectFilter.propTypes = Select.propTypes;

export default SelectFilter;
