import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import ApiLogsSearchBarState from "./MilestoneLogsSearchBarState";
import { FILTERS } from "./MilestoneLogs.searchOptions";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import MilestoneDomainDataState from "modules/milestone-dashboard/MilestoneDomainDataState";

const { getEventsOptions, getStatusCodesOptions } =
  MilestoneDomainDataState.selectors;

const mapStateToProps = (state) => ({
  searchFilters: ApiLogsSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    ApiLogsSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  auth: getAuthorization(state),
  eventsFilterOptions: getEventsOptions(state),
  statusCodesFilterOptions: getStatusCodesOptions(state),
});

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchFilter,
    clearSearchFilter,
    clearSavedSearch,
    searchEntities,
  } = ApiLogsSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: (key) => dispatch(clearSearchFilter(key)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId, true)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilterSection);
