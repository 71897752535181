/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";

import { Text } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useIsSmallAndDown } from "components/responsive";
import PageHeader from "modules/documentation/documentation-styled-components/PageHeader";
import { FlexDiv, FlexRowDiv, FlexColDiv } from "styles/container-elements";

const csvTemplate = {
  fileName: `template-freeze-eta-${Date.now()}.csv`,
  headers: ["VIN", "ETA", "Freeze Until"],
  data: [
    ["VIN1", "1/1/21", "11/11/21"],
    ["VIN2", "2/5/21", "12/31/21"],
  ],
};

const initVins = {
  importCsv: null,
  importFile: null,
  autoCloseWindowTimer: null,
};

const Instructions = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div>
      <Text block={true} style={{ marginBottom: "1em" }}>
        {t(
          "internal-tools:The following rules apply to the freeze or unfreeze VIN ETAs",
        )}
        {":"}
      </Text>
      <ol>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:You must be logged into the Organization for which you are freezing VINs.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:No more than 5000 VINs may be updated at a time.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t("internal-tools:Only valid VINs will be updated.")}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:VIN, ETA, and Freeze Until columns must all be provided.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true}>
            {t(
              "internal-tools:To freeze a VIN ETA, the New VIN ETA must be in the future.",
            )}
          </Text>
        </li>
        <li>
          <Text block={true} style={{ marginBottom: "3em" }}>
            {t(
              "internal-tools:To Unfreeze a VIN ETA, set the Freeze Until date to be in the past.",
            )}
          </Text>
        </li>
      </ol>
    </div>
  );
};

export const FreezeETA = ({ actionStatus, clearActionStatus, freezeEtas }) => {
  const [vins, setVins] = useState(initVins);
  const isMobile = useIsSmallAndDown();
  const { t } = useTranslation("internal-tools");
  const title = t("internal-tools:Freeze ETA Tool");
  useSetTitleOnMount(t("internal-tools:Internal Tools"));

  const fileSelectHandler = (newFile) => {
    const reader = new FileReader();
    reader.readAsText(newFile);
    reader.onload = onFileLoad;
    setVins((prevState) => {
      return { ...prevState, importFile: newFile };
    });
  };

  const onFileLoad = (file) => {
    const csv = file.target.result.trim();
    const lines = csv.split(/\r\n|\n/);
    if (lines.length > 1) {
      if (actionStatus) {
        clearActionStatus();
      }
      setVins((prevState) => {
        return { ...prevState, importCsv: csv };
      });
    }
  };

  const fileDropHandler = (newItems) => {
    if (newItems && newItems.length === 1) {
      const file = newItems[0].getAsFile();
      // Read the schedule name
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = onFileLoad;
      setVins((prevState) => {
        return { ...prevState, importFile: file };
      });
    }
  };

  const replaceColumnHeaders = (csv) => {
    csv = csv.split("\n");
    csv.shift();
    csv.unshift("VIN,ETA,Freeze until\r");
    csv = csv.join("\n");
    return csv;
  };

  const csvTooltip = (
    <Tooltip id="freeze-eta-csv-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "left",
        }}
      >
        {t("internal-tools:The file must contain")}:
        <ul css={{ paddingLeft: "1.25em" }}>
          <li>{t("internal-tools:VIN")}</li>
          <li>{t("internal-tools:New ETA")}</li>
          <li>{t("internal-tools:Freeze Until")}</li>
        </ul>
      </div>
    </Tooltip>
  );

  const getAlertMessage = (success) => {
    return success
      ? t("internal-tools:Upload was successful, and ETAs have been updated.")
      : t("internal-tools:Unable to upload file. Try again later.");
  };

  const getAlertProps = (actionStatus) => {
    const alertProps = {
      show: false,
      variant: null,
      message: "",
    };

    if (
      actionStatus &&
      ["FREEZE_ETAS_SUCCEEDED", "FREEZE_ETAS_FAILED"].includes(actionStatus)
    ) {
      alertProps.show = true;
      alertProps.variant =
        actionStatus === "FREEZE_ETAS_SUCCEEDED"
          ? AlertVariant.Success
          : AlertVariant.Danger;
      alertProps.message = getAlertMessage(
        actionStatus === "FREEZE_ETAS_SUCCEEDED",
      );
    }
    return alertProps;
  };

  const alertProps = getAlertProps(actionStatus);

  return (
    <div css={{ padding: "1em" }}>
      <PageHeader title={title} />

      <FlexColDiv>
        <Instructions />

        <FlexRowDiv css={{ marginBottom: "1rem" }}>
          <span>{t("internal-tools:Select a CSV file to import")}</span>
          {". "}
          <OverlayTrigger placement="right" overlay={csvTooltip}>
            <div css={{ display: "flex" }}>
              <FaQuestionCircle />
            </div>
          </OverlayTrigger>
          <FlexDiv
            css={{
              marginLeft: "1rem",
            }}
          >
            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
              style={{ marginLeft: "1em" }}
            />
          </FlexDiv>
        </FlexRowDiv>
        <FormGroup
          css={{ marginBottom: "1rem", width: isMobile ? "auto" : "50%" }}
        >
          <InputGroup>
            <label
              className="input-group-btn"
              css={{ paddingRight: "0.375rem" }}
            >
              <span className="btn btn-light">
                {t("internal-tools:Browse")}&hellip;{" "}
                <input
                  id="files"
                  type="file"
                  accept=".csv"
                  title={null}
                  onChange={(e) => {
                    fileSelectHandler(e.target.files[0]);
                  }}
                  hidden
                />
              </span>
            </label>
            <FormControl
              type="type"
              value={vins.importFile ? vins.importFile.name : ""}
              placeholder={t("internal-tools:Select file")}
              htmlFor="files"
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fileDropHandler(e.dataTransfer.items);
              }}
              readOnly
            />
          </InputGroup>
        </FormGroup>
        <Alert
          variant={alertProps.variant}
          show={alertProps.show}
          css={{ marginBottom: "1em" }}
        >
          {alertProps.message}
        </Alert>
        <Button
          variant="primary"
          disabled={!vins?.importFile || actionStatus === "FREEZE_ETAS"}
          onClick={() => {
            let csv = replaceColumnHeaders(vins.importCsv);
            freezeEtas(csv);
          }}
          style={{ width: "fit-content" }}
        >
          {t("internal-tools:Upload")}
        </Button>
      </FlexColDiv>
    </div>
  );
};

FreezeETA.propTypes = {
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  freezeEtas: PropTypes.func.isRequired,
};

export default FreezeETA;
