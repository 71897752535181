/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";

export default function DataHeader(props) {
  const { total, title, complement } = props;

  return (
    <span style={{ fontSize: 16 }}>
      <span style={{ fontWeight: 600 }}>{total.toLocaleString()}</span>&nbsp;
      {title}
      {complement ? (
        <span style={{ fontSize: 14, color: Colors.text.GRAY }}>
          &nbsp;{complement}
        </span>
      ) : null}
    </span>
  );
}

DataHeader.propTypes = {
  total: PropTypes.number,
};
