import _ from "lodash";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";

// Exported URLs
export const currentPositionCodesUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionCodes=1`,
  );
};

const STORE_MOUNT_POINT = "inventoryViewDomainData";

const inventoryViewPositionTypesDuck = buildFetchDuck(
  "inventoryViewPositionTypesDuck",
);

export function fetchFcVinsDomainData(solutionId) {
  const urlPositionTypes = apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionTypes=1`,
  );

  return (dispatch) => {
    dispatch(inventoryViewPositionTypesDuck.fetch(urlPositionTypes));
  };
}

const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchFcVinsDomainData,
  },
  selectors: {
    getCurrentPositionTypes,
  },
  reducer: combineReducers({
    currentPositionTypes: inventoryViewPositionTypesDuck.reducer,
  }),
};
