/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import ExportModal from "modules/exports/ExportModal";
import Loader from "react-loader";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import { getContainerTypeColumns } from "./ContainerTrackingContainerTypeTableColumns";
import FiltersContainer from "pages/containertracking/dashboard/components/summaryview/ContainerTypeSearchFilterContainer";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import Colors from "styles/colors";

export const ContainerTrackingContainerTypeTable = ({
  isContainerTypeTableLoading,
  containerTypeTableResults,
  page,
  pageSize,
  totalPages,
  setPagination,
  setSort,
  defaultSortColumn,
  defaultReverseSort,
  sortColumn,
  reverseSort,
  searchFilters,
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  clearSearchText,
  fetchContainerType,
  exportIdentifier,
  exportName,
  isExporting,
  exportContainerTypes,
  resetExport,
}) => {
  const { t } = useTranslation("container-tracking");

  useEffect(() => {
    clearSearchFilters();
    clearSearchText();
    fetchContainerType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Loader loaded={!isExporting} />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
      <PanelGroup style={{ padding: "1.5em 0px 0.25em" }}>
        <PanelGroup.Header title={t("container-tracking:Container Types")} />
        <PanelGroup.SubHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: Colors.background.GRAY,
              flexDirection: "row",
              justifyContent: "space-between",
              boxPack: "justify",
            }}
          >
            <FiltersContainer
              standalone={true}
              show={true}
              hideSearchButton={true}
              backgroundColor="none"
            />
            <div>
              <DownloadDataButton
                onClick={() => {
                  exportContainerTypes();
                }}
                isExporting={false}
                isDisabled={containerTypeTableResults.length === 0}
                data-qa="container-type-export"
              />
            </div>
          </div>
        </PanelGroup.SubHeader>
        <PanelGroup.Content style={{ padding: 0, minHeight: "320px" }}>
          <BaseTable
            theme={Themes.LIGHT}
            columns={getContainerTypeColumns(
              t,
              searchFilters,
              searchEntities,
              setSearchFilter,
              clearSearchFilters,
            )}
            isLoading={isContainerTypeTableLoading}
            data={containerTypeTableResults}
            showPagination={true}
            showPageSizeDropdown={true}
            fixPaginationToBottom={true}
            isManualPagination={true}
            pageIndex={page}
            pageCount={totalPages}
            pageSize={pageSize}
            onPageChange={(newPage) => {
              setPagination(null, newPage, pageSize);
            }}
            onPageSizeChange={(newPageSize) => {
              setPagination(null, 0, newPageSize);
            }}
            isManualSorting={true}
            defaultSortColumn={defaultSortColumn}
            defaultReverseSort={defaultReverseSort}
            sortColumn={sortColumn}
            reverseSort={reverseSort}
            onPageSort={useCallback(
              (column, isReverse) => {
                setSort(null, column, isReverse);
              },
              [setSort],
            )}
          />
        </PanelGroup.Content>
      </PanelGroup>
    </Fragment>
  );
};

ContainerTrackingContainerTypeTable.propTypes = {
  isContainerTypeTableLoading: PropTypes.bool,
  containerTypeTableResults: PropTypes.array.isRequired,
  fetchContainerType: PropTypes.func.isRequired,
  searchEntities: PropTypes.func,
  setSearchFilter: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setSort: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  searchFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  clearSearchText: PropTypes.func.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  exportContainerTypes: PropTypes.func.isRequired,
  resetExport: PropTypes.func,
};
