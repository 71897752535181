import _ from "lodash";
import { useSelector } from "react-redux";
import DomainDataState from "modules/domain-data/DomainDataState";

export const useGetCountryNameByCountryCode = (countryCode) => {
  const countries = useSelector(DomainDataState.selectors.getCountries);
  const countryLookup = _.find(countries, { code: countryCode });
  return countryLookup ? countryLookup.name : countryCode;
};

/**
 * Displays the Shipper Name for a location's Shipper ID
 */
export const useGetShipperNameById = (id) => {
  const shippers = useSelector(DomainDataState.selectors.getShippers);
  const shipper = _.find(shippers, { shipper_organization_id: id });
  return shipper ? shipper.shipper_name : "";
};
