/**
 * Implements MapPlatformInterface for Google Maps.
 */
import _ from "lodash";

import MapPlatformInterface from "../MapPlatformInterface";
import GeofenceType, {
  getBoundingBox,
  getType,
  getCenter,
  isFenceValid,
} from "../../../geofence-edit/geofence-types";
import createHTMLMapMarker from "../../widgets/HTMLMapMarker";
import GoogleMapsShapes from "./GoogleMapsShapes";
import Colors from "styles/colors";

class GoogleMapPlatform extends MapPlatformInterface {
  static get name() {
    return "GOOGLE";
  }

  constructor(baseMap) {
    super(baseMap);

    const { googleMaps } = this.props;
    this.shapes = new GoogleMapsShapes(googleMaps);

    this.googleMapsInfoWindow = null;
    this.dragStartLat = 0;
    this.dragLat = 0;
    this.dragStartLng = 0;
    this.dragLng = 0;

    this.onRadialDragStarted = this.onRadialDragStarted.bind(this);
    this.onRadialDragged = this.onRadialDragged.bind(this);
    this.onRadialDragEnded = this.onRadialDragEnded.bind(this);
    this.onPolygonDragStarted = this.onPolygonDragStarted.bind(this);
    this.onPolygonDragged = this.onPolygonDragged.bind(this);
    this.onPolygonDragEnded = this.onPolygonDragEnded.bind(this);
    this.addPolygonPathListeners = this.addPolygonPathListeners.bind(this);
    this.onPolygonPathInserted = this.onPolygonPathInserted.bind(this);
    this.onPolygonPathRemoved = this.onPolygonPathRemoved.bind(this);
    this.onPolygonPathModified = this.onPolygonPathModified.bind(this);
  }

  // Specific polygon and radial stuff for Google
  onRadialDragStarted(e, object) {
    const { enableGeofenceBuilder, enableDraggingGeofence } = this.props;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      this.dragStartLat = e.latLng.lat();
      this.dragLat = e.latLng.lat();
      this.dragStartLng = e.latLng.lng();
      this.dragLng = e.latLng.lng();
    }
  }

  onRadialDragged(e, object) {
    const { enableGeofenceBuilder, isTracing, enableDraggingGeofence } =
      this.props;

    if (!isTracing && enableGeofenceBuilder && enableDraggingGeofence) {
      this.dragLat = e.latLng.lat();
      this.dragLng = e.latLng.lng();
    }
  }

  onRadialDragEnded(e, object) {
    const {
      onDragRadialGeofence,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      onDragRadialGeofence(
        this.dragStartLat - this.dragLat,
        this.dragStartLng - this.dragLng,
      );
    }
  }

  addPolygonPathListeners(path, object) {
    const { googleMaps } = this.props;

    googleMaps.event.addListener(path, "insert_at", (e) =>
      this.onPolygonPathInserted(e, object),
    );
    googleMaps.event.addListener(path, "remove_at", (e) =>
      this.onPolygonPathRemoved(e, object),
    );
    googleMaps.event.addListener(path, "set_at", (e) =>
      this.onPolygonPathModified(e, object),
    );
  }

  onPolygonDragStarted(e, object) {
    const { enableGeofenceBuilder, enableDraggingGeofence } = this.props;

    object.data.isDragging = true;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      this.dragStartLat = e.latLng.lat();
      this.dragLat = e.latLng.lat();
      this.dragStartLng = e.latLng.lng();
      this.dragLng = e.latLng.lng();
    }
  }

  onPolygonDragged(e, object) {
    const { enableGeofenceBuilder, isTracing, enableDraggingGeofence } =
      this.props;

    if (!isTracing && enableGeofenceBuilder && enableDraggingGeofence) {
      this.dragLat = e.latLng.lat();
      this.dragLng = e.latLng.lng();
    }
  }

  onPolygonDragEnded(e, object) {
    const {
      onDragPolygonalGeofence,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    object.data.isDragging = false;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      onDragPolygonalGeofence(
        Number(object.data.polygonIndex),
        this.dragStartLat - this.dragLat,
        this.dragStartLng - this.dragLng,
      );
    }
  }

  onPolygonPathInserted(e, object) {
    const {
      updatePolygonPoints,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      let newPoints = [];
      object.getPath().forEach((path) => {
        newPoints.push([path.lng(), path.lat()]);
      });

      updatePolygonPoints(object.data.polygonIndex, newPoints);
    }
  }

  onPolygonPathRemoved(e, object) {
    const {
      updatePolygonPoints,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    if (enableGeofenceBuilder && enableDraggingGeofence) {
      let newPoints = [];
      object.getPath().forEach((path) => {
        newPoints.push([path.lng(), path.lat()]);
      });

      updatePolygonPoints(object.data.polygonIndex, newPoints);
    }
  }

  onPolygonPathModified(e, object) {
    const {
      updatePolygonPoints,
      enableGeofenceBuilder,
      enableDraggingGeofence,
    } = this.props;

    if (!object.data.isDragging) {
      if (enableGeofenceBuilder && enableDraggingGeofence) {
        let newPoints = [];
        object.getPath().forEach((path) => {
          newPoints.push([path.lng(), path.lat()]);
        });

        updatePolygonPoints(object.data.polygonIndex, newPoints);
      }
    }
  }

  // Initialization
  initMap(defaultCenter) {
    const { googleMaps } = this.props;

    this.map = new googleMaps.Map(this.mapDiv, {
      center: defaultCenter,
      zoom: 5,
      streetViewControl: false,
      clickableIcons: false,
      fullscreenControl: false,
    });

    // Add dark theme for heatmap mode
    let darkStyleType = getDarkStyleType(googleMaps);
    this.map.mapTypes.set("dark", darkStyleType);

    // Add map listeners
    this.map.addListener("mousemove", this.onMouseMove);
    this.map.addListener("click", this.onTap);
  }

  initHeatMap(coords) {
    const { googleMaps } = this.props;

    // If we have coordinates, display them
    let data = [];
    if (coords && coords.length > 0) {
      // Convert coords to googleMaps.LatLng data
      for (let i = 0; i < coords.length; i++) {
        data.push(
          new googleMaps.LatLng(
            parseFloat(coords[i].latitude),
            parseFloat(coords[i].longitude),
          ),
        );
      }
    }

    let layer = new googleMaps.visualization.HeatmapLayer({
      map: this.map,
      data: data,
    });

    this.mapLayers["heatmap_layer"] = layer;

    // Set map theme to custom Dark theme
    this.map.setMapTypeId("dark");
  }

  deinitHeatMap() {
    if (this.mapLayers["heatmap_layer"]) {
      this.mapLayers["heatmap_layer"].setMap(null);
      this.mapLayers["heatmap_layer"] = null;
    }

    // Reset map theme to default roadmap
    this.map.setMapTypeId("roadmap");
  }

  // Events
  onTap(e) {
    const { isTracing, addPointToTrace } = this.props;

    if (isTracing) {
      // Add a point here.
      // For Google Maps, the markers themselves handle clicking on them,
      // so that will handle completing the polygon.
      let latLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      addPointToTrace(latLng);
    }
  }

  onMouseMove(e) {
    const { isTracing } = this.props;

    if (isTracing) {
      const pos = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.setState({ mouseGeoCoords: pos });
    }
  }

  // Events Related
  addMapEventListener(eventName, callback) {
    const googleEventsTable = {
      mapviewchange: "bounds_changed",
    };
    this.map.addListener(googleEventsTable[eventName], callback);
  }

  addMapMarkerEventListener(marker, eventName, callback) {
    const googleEventsTable = {
      click: "click",
      mouseenter: "mouseover",
      mouseout: "mouseout",
    };
    marker.addListener(googleEventsTable[eventName], callback);
  }

  // Drawing
  addUIButton(label, clickCallback) {
    const { googleMaps } = this.props;

    // Create a custom wrapper
    let recenterButton = document.createElement("div");

    // Create a custom `a` element to allow for click events
    let controlUI = document.createElement("a");

    // Set class for `a` element to look like a button
    controlUI.className = "googlemaps-custombutton";
    controlUI.title = label;

    // Create and set label
    let controlText = document.createElement("span");
    controlText.textContent = label;
    controlUI.appendChild(controlText);

    // Add Listener
    controlUI.addEventListener("click", clickCallback);

    // Add `a` element to wrapper
    recenterButton.appendChild(controlUI);

    // Add button to map at specified position
    this.map.controls[googleMaps.ControlPosition.BOTTOM_CENTER].push(
      recenterButton,
    );
  }

  createAndAddMapMarker(
    name,
    pos,
    zIndex,
    data,
    isClickable,
    iconSvg,
    iconHeight,
    iconWidth,
    iconDefaultHeight,
    iconDefaultWidth,
    iconXOffsetForGoogle,
    iconYOffsetForGoogle,
    iconXAnchorForHERE,
    iconYAnchorForHERE,
  ) {
    const { googleMaps } = this.props;

    // Convert position to a Google Maps LatLng object
    let googlePos = new googleMaps.LatLng(pos.lat, pos.lng);

    // Calculate the scale of the marker
    let scaleX = 1.0;
    let scaleY = 1.0;
    if (iconWidth && iconDefaultWidth) {
      scaleX = iconWidth / iconDefaultWidth;
    }
    if (iconHeight && iconDefaultHeight) {
      scaleY = iconHeight / iconDefaultHeight;
    }

    // Create an HTMLMapMarker
    const marker = createHTMLMapMarker({
      latlng: googlePos,
      map: this.map,
      html: iconSvg,
      data: data,
      isClickable: isClickable,
      scaleX: scaleX,
      scaleY: scaleY,
      offsetX: iconXOffsetForGoogle,
      offsetY: iconYOffsetForGoogle,
    });

    this.mapObjects[name] = marker;

    return marker;
  }

  createAndAddRadialForCoordinate(pos, radius = 0) {
    const { googleMaps } = this.props;

    // Outer circle - the true radius.
    const markerOuterCircle = new googleMaps.Circle({
      strokeWeight: 0,
      fillColor: Colors.highlight.RED,
      fillOpacity: 0.5,
      center: pos,
      radius: radius,
      map: this.map,
    });

    // Visual inner circle for aesthetic.
    const markerInnerCircle = new googleMaps.Circle({
      strokeWeight: 0,
      fillColor: Colors.highlight.RED,
      fillOpacity: 0.5,
      center: pos,
      // Reduce the inner circle radius by 75%.
      // This makes it appear inside the actual radius and always proportional to that radius.
      radius: radius * 0.75,
      map: this.map,
    });

    // Prefix with `selectedCoordinate` so it gets cleared with the marker.
    this.mapObjects["selectedCoordinateRadius-outer"] = markerOuterCircle;
    this.mapObjects["selectedCoordinateRadius-inner"] = markerInnerCircle;
  }

  createAndAddMapPolyline(
    name,
    linestring,
    zIndex,
    lineWidth,
    lineJoin,
    hasArrows,
  ) {
    const { googleMaps } = this.props;

    let routeLine = new googleMaps.Polyline({
      path: linestring,
      zIndex: zIndex,
      strokeColor: "#4165b6",
      strokeWeight: lineWidth,
      strokeOpacity: 0.8,
      clickable: false,
    });

    if (hasArrows === true) {
      let lineSymbol = {
        path: googleMaps.SymbolPath.FORWARD_CLOSED_ARROW,
        fillColor: "#d8e2eb",
        fillOpacity: 0.8,
        strokeColor: "#d8e2eb",
        strokeWeight: 1,
        scale: 2.5,
      };

      routeLine.setOptions({
        icons: [{ icon: lineSymbol, offset: "100%", repeat: "12px" }],
      });
    }

    routeLine.setMap(this.map);
    this.mapObjects[name] = routeLine;

    return routeLine;
  }

  createMapLineString() {
    const { googleMaps } = this.props;

    return new googleMaps.MVCArray();
  }

  createMapMultiLineString(lineStrings) {
    throw Error("createMapMultiLineString not implemented");
  }

  createAndAddMapInfoBubble(pos, content) {
    const { googleMaps } = this.props;

    if (this.googleMapsInfoWindow) {
      this.googleMapsInfoWindow.close();
    }

    this.googleMapsInfoWindow = new googleMaps.InfoWindow({
      content: content,
      pixelOffset: new googleMaps.Size(0, -40),
    });

    let gPos = new googleMaps.LatLng(pos.lat, pos.lng);

    this.googleMapsInfoWindow.setPosition(gPos);
    this.googleMapsInfoWindow.open(this.map);
  }

  addMapLineStringLatLong(linestring, lat, lng) {
    const { googleMaps } = this.props;

    const elem = new googleMaps.LatLng(lat, lng);
    linestring.push(elem);
  }

  convertFlexiblePolylineToLineString(flexiblePolyline) {
    throw Error("convertFlexiblePolylineToLineString not implemented");
  }

  drawGeofence(location, geofenceGroup, geofenceMapId, lad) {
    if (getType(location.geofence) === GeofenceType.RADIAL) {
      if (geofenceGroup) {
        geofenceGroup.objects.forEach((object) => {
          object.setMap(this.map);
          object.addListener("dragstart", (e) =>
            this.onRadialDragStarted(e, object),
          );
          object.addListener("drag", (e) => this.onRadialDragged(e, object));
          object.addListener("dragend", (e) =>
            this.onRadialDragEnded(e, object),
          );
        });
        this.mapObjects[geofenceMapId] = geofenceGroup;
      }
    } else {
      const { googleMaps } = this.props;

      geofenceGroup.forEach((group) => {
        group.objects.forEach((object) => {
          object.setMap(this.map);

          if (object instanceof googleMaps.Polygon) {
            // Add listeners for the polygon itself
            object.addListener("dragstart", (e) =>
              this.onPolygonDragStarted(e, object),
            );
            object.addListener("drag", (e) => this.onPolygonDragged(e, object));
            object.addListener("dragend", (e) =>
              this.onPolygonDragEnded(e, object),
            );

            // Add listeners for all paths in the polygon (to detect changes)
            this.addPolygonPathListeners(object.getPath(), object);
          }
        });
        this.mapObjects[group.data.groupId] = group;
      });
    }
  }
  addTraceGroup(traceGroup) {
    traceGroup.objects.forEach((object) => {
      object.setMap(this.map);

      this.mapObjects[traceGroup.data.groupId] = traceGroup;
    });
  }

  // Clearing
  clearInfoBubbles() {
    if (this.googleMapsInfoWindow) {
      this.googleMapsInfoWindow.close();
      this.googleMapsInfoWindow = null;
    }
  }

  clearMap(excludeKeyPrefixes = []) {
    // Clear objects
    if (!_.isNil(this.mapObjects) && !_.isEmpty(this.mapObjects)) {
      _.forOwn(this.mapObjects, (val, key) => {
        if (
          excludeKeyPrefixes.length === 0 ||
          !_.some(
            _.map(excludeKeyPrefixes, (w) => key.includes(w)),
            Boolean,
          )
        ) {
          try {
            // For polygons, remove the sub-objects
            if (val.objects) {
              val.objects.forEach((object) => {
                object.setMap(null);
              });
            } else {
              val.setMap(null);
            }
            val = null;
          } catch (ex) {}
        }
      });

      this.mapObjects = {};
    }

    this.mapObjects = {};

    // Clear layers
    if (!_.isNil(this.mapLayers) && !_.isEmpty(this.mapLayers)) {
      _.forOwn(this.mapLayers, (val, key) => {
        val.setMap(null);
        val = null;
      });
      this.mapLayers = {};
    }

    this.mapLayers = {};
  }

  clearMapMarkers(prefix) {
    let delList = [];
    for (let key in this.mapObjects) {
      if (key.includes(prefix) && this.mapObjects[key]) {
        if (this.mapObjects[key].objects) {
          this.mapObjects[key].objects.forEach((object) => {
            object.setMap(null);
          });
        } else {
          this.mapObjects[key].setMap(null);
        }
        delList.push(key);
      }
    }
    delList.forEach((value) => {
      this.mapObjects[value] = null;
    });
  }

  // Actions
  setMapViewBounds(boundsRect) {
    this.map.fitBounds(boundsRect);
  }

  setMapCenter(position) {
    const { googleMaps } = this.props;
    this.map.setCenter(new googleMaps.LatLng(position.lat, position.lng));
  }

  resizeMap() {
    // Google Maps automatically resizes, no need for implementation
  }

  setZoom(zoom) {
    this.map.setZoom(zoom);
  }

  getZoom() {
    return this.map.getZoom();
  }

  zoomSingleLocation(location) {
    const { geofence } = location;
    if (isFenceValid(geofence)) {
      const bounds = getBoundingBox(geofence);
      const { googleMaps } = this.props;

      this.setMapViewBounds(
        new googleMaps.LatLngBounds(
          new googleMaps.LatLng(bounds[0], bounds[1]),
          new googleMaps.LatLng(bounds[2], bounds[3]),
        ),
      );
      const centerPos = getCenter(geofence);
      this.setMapCenter(centerPos);
    } else {
      this.setMapCenter({ lat: location.latitude, lng: location.longitude });
      this.setZoom(14);
    }
  }

  zoomLocations(locations) {
    const { googleMaps } = this.props;

    let bounds = new googleMaps.LatLngBounds();

    for (let i = 0; i < locations.length; i++) {
      let location = locations[i];
      let position = new googleMaps.LatLng(
        location.latitude,
        location.longitude,
      );
      bounds.extend(position);
    }

    this.setMapViewBounds(bounds);
  }

  mergeBounds(bounds1, bounds2) {
    return bounds1.union(bounds2);
  }

  removeMapObject(name) {
    // For polygons, remove the sub-objects
    if (this.mapObjects[name].objects) {
      this.mapObjects[name].objects.forEach((object) => {
        object.setMap(null);
      });
    } else {
      this.mapObjects[name].setMap(null);
    }
    this.mapObjects[name] = null;
  }

  // Helpers
  isMapViewBoundsContainPoint(pos) {
    const bounds = this.map.getBounds();
    return bounds.contains(pos);
  }

  isMapViewBoundsContainLatLng(lat, lng) {
    const { googleMaps } = this.props;

    const bounds = this.map.getBounds();
    const pos = new googleMaps.LatLng(lat, lng);
    // Default to false if bounds is undefined.
    return bounds?.contains(pos) ?? false;
  }

  getBoundsRect(pos, boundsBuffer = 0.01) {
    const { googleMaps } = this.props;

    return new googleMaps.LatLngBounds(
      new googleMaps.LatLng(pos.lat - boundsBuffer, pos.lng - boundsBuffer),
      new googleMaps.LatLng(pos.lat + boundsBuffer, pos.lng + boundsBuffer),
    );
  }
}

const getDarkStyleType = (googleMaps) => {
  return new googleMaps.StyledMapType(
    [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#242f3e",
          },
        ],
      },
      {
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#746855",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#242f3e",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#d59563",
          },
        ],
      },
      {
        featureType: "administrative.neighborhood",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#d59563",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#263c3f",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#6b9a76",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#38414e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#212a37",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9ca5b3",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#746855",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#1f2835",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#f3d19c",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#2f3948",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#d59563",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#17263c",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#515c6d",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#17263c",
          },
        ],
      },
    ],
    { name: "Dark Map" },
  );
};

export default GoogleMapPlatform;
