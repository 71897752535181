/** @jsxImportSource @emotion/react */
import { faSearchPlus } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiPencil, TiTrash } from "react-icons/ti";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import SelectField from "components-old/forms/fields/SelectField";
import { MediaQueries, useIsMediumAndDown } from "components/responsive";
import Colors from "styles/colors";

const EditSavedSearchButtons = ({ disabled, onEdit, onDelete }) => {
  const { t } = useTranslation("components");

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div
        css={{
          fontSize: 12.5,
          fontStyle: "italic",
          marginRight: 10,
          textDecoration: "underline",
          color: Colors.text.LIGHTER_GRAY,
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        disabled={disabled}
        onClick={() => onEdit()}
      >
        {t("mt-shared:Edit")}{" "}
        <TiPencil style={{ fontSize: "1.9em", marginLeft: "0.1em" }} />
      </div>
      <div
        css={{
          fontSize: 12.5,
          fontStyle: "italic",
          marginRight: 10,
          textDecoration: "underline",
          color: Colors.text.LIGHTER_GRAY,
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        disabled={disabled}
        onClick={() => onDelete()}
      >
        {t("mt-shared:Delete")}{" "}
        <TiTrash style={{ fontSize: "1.9em", marginLeft: "0.1em" }} />
      </div>
    </div>
  );
};

EditSavedSearchButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const SaveCurrentSearch = ({
  changeHandler,
  deleteConfirmHandler,
  savedSearch,
  editHandler,
  savedSearchOptions,
}) => {
  const { t } = useTranslation("components");
  const isMobile = useIsMediumAndDown();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        maxWidth: 500,
        alignItems: "center",
        padding: isMobile ? "5px 0 5px 10px" : "10px 0 10px 15px",
        borderRadius: "3px",
        width: isMobile ? "100%" : null,
      }}
    >
      {!savedSearch ? (
        <Button
          variant="link"
          onClick={() => editHandler()}
          css={{
            color: Colors.text.GRAY,
            ":hover": {
              color: Colors.background.DARK_BLUE,
            },
            marginRight: isMobile ? null : "1em",
            marginBottom: isMobile ? "1em" : null,
            whiteSpace: "nowrap",
          }}
        >
          <Text italic underline>
            {t("components:Save Current Search")}
          </Text>
          <Icon src={faSearchPlus} style={{ marginLeft: "0.3em" }} />
        </Button>
      ) : (
        <EditSavedSearchButtons
          disabled={!savedSearch}
          onEdit={editHandler}
          onDelete={deleteConfirmHandler}
        />
      )}
      <div style={{ width: isMobile ? "100%" : 300, marginRight: "1em" }}>
        <SelectField
          options={savedSearchOptions}
          stateValue={savedSearch ? savedSearch.id : null}
          onChange={changeHandler}
          placeholder={t("components:Saved Searches")}
          placeholderFontStyle="italic"
          boxShadow="0px 3px 14px 0.5px rgba(0,0,0,0.05)"
          borderWidth="0.5px"
          borderColor="#f2f2f2"
          fontSize={12.5}
        />
      </div>
    </div>
  );
};

SaveCurrentSearch.propTypes = {
  changeHandler: PropTypes.func,
  deleteConfirmHandler: PropTypes.func,
  editHandler: PropTypes.func,
  savedSearch: PropTypes.object,
  savedSearchOptions: PropTypes.array,
};

export const SavedSearchSelect = ({
  readOnly,
  savedSearch,
  savedSearches,
  selectSavedSearch,
  solutionId,
  exportSearch,
  resetSavedSearch,
  deleteSearch,
  fetchSavedSearches,
  loadSavedSearch,
  EditModal,
  DeleteModal,
  onChange,
}) => {
  const { t } = useTranslation("components");

  const [showEditSearchModal, setShowEditSearchModal] = useState(false);
  const [showDeleteSearchModal, setShowDeleteSearchModal] = useState(false);
  const [savedSearchInState, setSavedSearchInState] = useState(null);

  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches]);

  useEffect(() => {
    if (showEditSearchModal) {
      return;
    }

    if (!savedSearch && savedSearchInState) {
      // reset to initial values
      setShowEditSearchModal(false);
      setShowDeleteSearchModal(false);
      setSavedSearchInState(null);
    }

    if (savedSearch && savedSearch !== savedSearchInState) {
      setSavedSearchInState(savedSearch);
      loadSavedSearch(savedSearch);
    }
  }, [savedSearch, savedSearchInState, showEditSearchModal, loadSavedSearch]);

  const getSavedSearchOptions = () => {
    return (
      savedSearches &&
      savedSearches.map((savedSearch) => ({
        label: savedSearch.name,
        value: savedSearch.id,
      }))
    );
  };

  const selectedSavedSearch = (selectedId) => {
    const selectedSavedSearch = savedSearches.find(
      (savedSearch) => savedSearch.id === selectedId,
    );

    selectSavedSearch(selectedSavedSearch);
  };

  const confirmDelete = () => {
    deleteSearch(savedSearch.id);
    resetSavedSearch();
    setShowDeleteSearchModal(false);
  };

  const onSavedSearchChange = (selected) => {
    // When selected it null it means that button 'x' button
    // was clicked or backspace (or delete) was pressed and
    // data was cleaned, so we may just reset
    if (selected === null) {
      resetSavedSearch();
    }

    if (onChange) {
      onChange(selected.value);
    }

    return selectedSavedSearch(selected.value);
  };

  return (
    <div
      css={{
        display: "flex",
        alignItems: "flex-end",
        [MediaQueries.smallAndDown]: {
          justifyContent: "center",
          width: "100%",
        },
        [MediaQueries.mediumAndUp]: {
          justifyContent: "flex-end",
          width: "unset",
        },
      }}
    >
      {!readOnly ? (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            [MediaQueries.smallAndDown]: {
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row",
            },
          }}
        >
          <DownloadDataButton
            onClick={() => exportSearch(solutionId)}
            data-qa="button-search-results-export"
          />
          <SaveCurrentSearch
            changeHandler={onSavedSearchChange}
            deleteConfirmHandler={() => setShowDeleteSearchModal(true)}
            savedSearch={savedSearchInState}
            editHandler={() => setShowEditSearchModal(true)}
            savedSearchOptions={getSavedSearchOptions()}
          />
        </div>
      ) : (
        <div
          css={{
            width: 300,
            [MediaQueries.mediumAndUp]: { marginLeft: "auto" },
          }}
        >
          <SelectField
            options={getSavedSearchOptions()}
            stateValue={savedSearchInState ? savedSearchInState.id : null}
            onChange={onSavedSearchChange}
            placeholder={t("components:Saved Searches")}
            placeholderFontStyle="italic"
            boxShadow="0px 3px 14px 0.5px rgba(0,0,0,0.05)"
            borderWidth="0.5px"
            borderColor="#f2f2f2"
            fontSize={12.5}
          />
        </div>
      )}

      <EditModal
        show={showEditSearchModal}
        hide={() => setShowEditSearchModal(false)}
        savedSearch={savedSearchInState}
      />

      <DeleteModal
        show={showDeleteSearchModal}
        hide={() => setShowDeleteSearchModal(false)}
        handleConfirm={confirmDelete}
      />
    </div>
  );
};

SavedSearchSelect.propTypes = {
  deleteSearch: PropTypes.func.isRequired,
  exportSearch: PropTypes.func,
  fetchSavedSearches: PropTypes.func.isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  resetSavedSearch: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  savedSearches: PropTypes.array,
  selectSavedSearch: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  EditModal: PropTypes.object.isRequired,
  DeleteModal: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};
