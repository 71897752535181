import apiUrl from "../../../api-url";
import buildFetchDuck from "../../../vendor/signal-utils/build-fetch-duck";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const MOUNT_POINT = "freezePartViewEtas";

// Fetch ducks
const duck = buildFetchDuck(MOUNT_POINT);

const getFreezePartViewEtasUrl = (solutionId) =>
  apiUrl(`/eta-shipments/override/partview/solution/${solutionId}`);

export const freezePartViewEtas = (payload) => async (dispatch, getState) => {
  const solutionId = getSolutionId(getState());
  const url = getFreezePartViewEtasUrl(solutionId);
  const config = {
    data: payload,
    method: "POST",
    headers: {
      Accept: "application/json;version=packages",
    },
  };
  dispatch(duck.fetch(url, config));
};

function requestDataClear() {
  return (dispatch) => {
    dispatch(duck.clear());
  };
}

// Reducer
const freezePartViewEtasReducer = duck.reducer;

// Complete State
const FreezePartViewETAState = {
  mountPoint: MOUNT_POINT,
  reducer: freezePartViewEtasReducer,
  actionCreators: {
    freezePartViewEtas,
    requestDataClear,
  },
  selectors: { getRequestData: duck.selectors.getData },
};

// Export
export default FreezePartViewETAState;
