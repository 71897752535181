/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Separator } from "components/atoms/Separator.atom";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import { getIconData } from "pages/finishedvehicle/utils/exceptions.utils";
import { getFontSize } from "pages/containertracking/utils/ContainerTrackingUtils";

export const ContainerTrackingCountsWidget = ({
  containerTotals,
  isContainerTotalsLoading,
  searchEntities,
  setSearchFilter,
}) => {
  const { t } = useTranslation("container-tracking");
  const unaccountedText = t("container-tracking:Unaccounted");
  const excessiveDwellText = t("container-tracking:Excessive Dwell");
  const noUpdateThirtyDaysText = t("container-tracking:No Updates 30+ days");
  const offRouteText = t("container-tracking:Off Route");
  const batteryLevelText = t("container-tracking:Battery Level");

  const {
    total,
    onsiteAtAssembly,
    onsiteAtSupplier,
    onsiteAtODC,
    onsiteAtUndefined,
    inTransit,
  } = containerTotals;

  const fontSize = getFontSize([
    onsiteAtAssembly?.count,
    onsiteAtSupplier?.count,
    onsiteAtODC?.count,
    onsiteAtUndefined?.count,
  ]);

  const getPercentage = (count, totalCount) => {
    return isContainerTotalsLoading || !totalCount
      ? 0
      : Math.round((count / totalCount) * 100);
  };

  const getExceptionGroups = (
    unaccountedCount = 0,
    excessiveDwellCount = 0,
    noUpdateThirtyDaysCount = 0,
    offRouteCount = 0,
    batteryLevelCount = 0,
  ) => {
    return [
      {
        includeInDonutChart: true,
        exceptions: [
          {
            id: 1,
            name: unaccountedText,
            count: unaccountedCount ?? 0,
            tooltipText: t(
              "container-tracking:Sitting for over 48 hours with no updates",
            ),
          },
          {
            id: 2,
            name: excessiveDwellText,
            count: excessiveDwellCount ?? 0,
            tooltipText: t(
              "container-tracking:Sitting for over 36 hours with pings",
            ),
            icon: {
              style: { height: "1.875rem", width: "1.875rem" },
              ...getIconData("Excessive Dwell"),
            },
          },
          {
            id: 3,
            name: noUpdateThirtyDaysText,
            count: noUpdateThirtyDaysCount ?? 0,
            tooltipText: t(
              "container-tracking:Sitting for 30+ days no updates",
            ),
            icon: {
              color: Colors.highlight.ORANGE,
            },
          },
          {
            id: 4,
            name: offRouteText,
            count: offRouteCount ?? 0,
            tooltipText: t(
              "container-tracking:Container not found at an assigned location",
            ),
            icon: {
              color: Colors.highlight.YELLOW,
            },
          },
          {
            id: 5,
            name: batteryLevelText,
            count: batteryLevelCount ?? 0,
            tooltipText: t("container-tracking:Battery level of 25% or less"),
            icon: {
              ...getIconData("Battery Level"),
            },
            percentage:
              isContainerTotalsLoading || !total?.count
                ? 0
                : ((batteryLevelCount / total?.count) * 100).toFixed(1),
          },
        ],
      },
    ];
  };

  const handleClickException = ({ name }) => {
    if (name === unaccountedText) {
      setSearchFilter("activeExceptions", ["Unaccounted"]);
      searchEntities();
    } else if (name === excessiveDwellText) {
      setSearchFilter("activeExceptions", ["Excessive Dwell"]);
      searchEntities();
    } else if (name === noUpdateThirtyDaysText) {
      setSearchFilter("activeExceptions", ["No Update 30+ Days"]);
      searchEntities();
    } else if (name === offRouteText) {
      setSearchFilter("activeExceptions", ["Off Route"]);
      searchEntities();
    } else if (name === batteryLevelText) {
      setSearchFilter("activeExceptions", ["Battery Level"]);
      searchEntities();
    }
  };

  const totalExceptionGroups = getExceptionGroups(
    total?.totalUnaccountedLatest,
    total?.totalExcessiveDwellLatest,
    total?.totalNoUpdateThirtyDaysLatest,
    total?.totalOffRouteLatest,
    total?.totalBatteryLevelLatest,
  );

  const totalContainerChart = {
    chartLabel: t("container-tracking:Total Containers"),
    count: total?.count,
    exceptionGroups: [
      {
        title: t("container-tracking:Exceptions"),
        style: { paddingTop: "2.5%" },
        countStyles: { flexBasis: "auto" },
        isLoading: isContainerTotalsLoading,
        ...totalExceptionGroups?.[0],
      },
    ],
    onClick: () => {
      searchEntities();
    },
  };

  const onsiteContainerCharts = [
    {
      chartLabel: t("container-tracking:Onsite at Assembly"),
      count: onsiteAtAssembly?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtAssembly?.totalUnaccounted,
        onsiteAtAssembly?.totalExcessiveDwell,
        onsiteAtAssembly?.totalNoUpdateThirtyDays,
        onsiteAtAssembly?.totalOffRoute,
        onsiteAtAssembly?.totalBatteryLevel,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Assembly"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtAssembly?.count, total?.count),
    },
    {
      chartLabel: t("container-tracking:Onsite at Supplier"),
      count: onsiteAtSupplier?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtSupplier?.totalUnaccounted,
        onsiteAtSupplier?.totalExcessiveDwell,
        onsiteAtSupplier?.totalNoUpdateThirtyDays,
        onsiteAtSupplier?.totalOffRoute,
        onsiteAtSupplier?.totalBatteryLevel,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Supplier"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtSupplier?.count, total?.count),
    },
    {
      chartLabel: t("container-tracking:Onsite at ODC"),
      count: onsiteAtODC?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtODC?.totalUnaccounted,
        onsiteAtODC?.totalExcessiveDwell,
        onsiteAtODC?.totalNoUpdateThirtyDays,
        onsiteAtODC?.totalOffRoute,
        onsiteAtODC?.totalBatteryLevel,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Distribution"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtODC?.count, total?.count),
    },
    {
      chartLabel: t("container-tracking:Onsite at Undefined Location"),
      count: onsiteAtUndefined?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtUndefined?.totalUnaccounted,
        onsiteAtUndefined?.totalExcessiveDwell,
        onsiteAtUndefined?.totalNoUpdateThirtyDays,
        onsiteAtUndefined?.totalOffRoute,
        onsiteAtUndefined?.totalBatteryLevel,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Undefined"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtUndefined?.count, total?.count),
    },
    {
      chartLabel: t("container-tracking:In Transit"),
      count: inTransit?.count,
      exceptionGroups: [
        {
          includeInDonutChart: true,
          exceptions: [
            {
              id: 3,
              name: noUpdateThirtyDaysText,
              count: inTransit?.totalNoUpdateThirtyDays ?? 0,
              icon: {
                color: Colors.highlight.ORANGE,
              },
            },
            {
              id: 4,
              name: offRouteText,
              count: inTransit?.totalOffRoute ?? 0,
              icon: {
                color: Colors.highlight.YELLOW,
              },
            },
          ],
        },
      ],
      onClick: () => {
        setSearchFilter("status", ["In Transit"]);
        searchEntities();
      },
      percentage: getPercentage(inTransit?.count, total?.count),
    },
  ];

  const TotalContainerChart = () => (
    <ExceptionsPanel.Chart
      exceptionGroups={totalContainerChart.exceptionGroups}
      count={totalContainerChart.count}
      countIsLoading={isContainerTotalsLoading}
      chartLabel={totalContainerChart.chartLabel}
      onClick={totalContainerChart.onClick}
      percentage={totalContainerChart.percentage}
      styles={{ width: "12em", justifyContent: "flex-start" }}
      showNumberSeparator={true}
    />
  );

  const totalCountChartElement = (
    <Fragment>
      <div
        className="d-flex flex-wrap justify-content-center"
        css={{ columnGap: "3em", rowGap: "1em", paddingBottom: "1.5em" }}
      >
        <TotalContainerChart />

        <div
          className="d-flex flex-wrap justify-content-center"
          css={{ paddingTop: "20px" }}
        >
          {onsiteContainerCharts.map((group) => (
            <Fragment key={group.chartLabel}>
              <ExceptionsPanel.Chart
                exceptionGroups={group.exceptionGroups}
                count={group.count}
                countIsLoading={isContainerTotalsLoading}
                chartLabel={group.chartLabel}
                onClick={group.onClick}
                percentage={group.percentage}
                chartLabelAlign={"center"}
                fontSize={fontSize}
                styles={{
                  minWidth: "9em",
                  width: "9em",
                  justifyContent: "flex-start",
                }}
                showNumberSeparator={true}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <Separator
        css={{
          [MediaQueries.largeAndDown]: { display: "none" },
          [MediaQueries.extraLarge]: { margin: "2.5em 2em 5em !important" },
        }}
      />
    </Fragment>
  );

  const exceptionGroups = getExceptionGroups(
    total?.totalUnaccounted,
    total?.totalExcessiveDwell,
    total?.totalNoUpdateThirtyDays,
    total?.totalOffRoute,
    total?.totalBatteryLevel,
  );

  return (
    <ExceptionsPanel
      title={t("container-tracking:Container Tracking")}
      exceptionGroups={exceptionGroups}
      handleClickException={handleClickException}
      totalCountChartElement={totalCountChartElement}
      outerDivStyles={{ justifyContent: "flex-start !important" }}
      innerDivStyles={{ alignSelf: "flex-start !important" }}
      showNumberSeparator={true}
    ></ExceptionsPanel>
  );
};

ContainerTrackingCountsWidget.propTypes = {
  containerTotals: PropTypes.object,
  isContainerTotalsLoading: PropTypes.bool,
  searchEntities: PropTypes.func,
  setSearchFilter: PropTypes.func,
};
