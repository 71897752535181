import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import { INVENTORY_VIEW_DETAILS_FILTERS } from "../search/InvertoryView.Details.Search.Options";
import InventoryViewDetailsSearchBarState from "../../redux/InventoryViewDetailsSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  searchFilters:
    InventoryViewDetailsSearchBarState.selectors.getSearchFilters(state),
  solutionId: getSolutionId(state),
  hasSearchCriteriaChanged:
    InventoryViewDetailsSearchBarState.selectors.getHasSearchCriteriaChanged(
      state,
    ),
  canUserSearch:
    InventoryViewDetailsSearchBarState.selectors.getCanUserSearch(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = InventoryViewDetailsSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: INVENTORY_VIEW_DETAILS_FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
