import { connect } from "react-redux";

import { LocationResolutionListPage } from "./View";
import UnresolvedLocationSearchBarState from "modules/unresolved-location-search/UnresolvedLocationSearchBarState";
import LocationMatchingState from "../location-resolution-edit/LocationMatchingState";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import { getActiveOrganization } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities,
    getSearchFilters,
    getSearchResults,
    getSortColumn,
    getReverseSort,
    getIsLoading,
    getExportName,
    getExportIdentifier,
    getIsExporting,
    getExportFailed,
  } = UnresolvedLocationSearchBarState.selectors;

  return {
    activeOrganization: getActiveOrganization(state),
    authorization: getAuthorization(state),
    unresolvedLocations: getSearchResults(state),
    isLoading: getIsLoading(state),
    totalUnresolvedLocations: getTotalEntities(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    searchFilters: getSearchFilters(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    location: state.location,
    exportIdentifier: getExportIdentifier(state),
    exportName: getExportName(state),
    isExporting: getIsExporting(state),
    exportFailed: getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { setShipmentWithUnresolvedLocation } =
    LocationMatchingState.actionCreators;
  const {
    setPagination,
    setSort,
    setSearchFilter,
    clearSearchFilters,
    searchEntities,
    exportSearch,
    resetExport,
  } = UnresolvedLocationSearchBarState.actionCreators;

  return {
    pushLocationMatchingView: (locID) =>
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID },
      }),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(setPagination(solutionId, page, pageSize)),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(setSort(solutionId, sortColumn, reverse)),
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilters: () => dispatch(clearSearchFilters()),
    fetchUnresolvedLocationsSummary: (
      solutionId,
      resetPagination = true,
      preventRedirect = false,
    ) => dispatch(searchEntities(solutionId, resetPagination, preventRedirect)),
    setShipmentWithUnresolvedLocation: (shipment) =>
      dispatch(setShipmentWithUnresolvedLocation(shipment)),
    exportLocations: () => {
      dispatch(exportSearch());
    },
    resetExport: () => {
      dispatch(resetExport());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationResolutionListPage);
