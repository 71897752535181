import { connect } from "react-redux";

import MetalTrackingSearchResultsView from "./MetalTrackingLocationResultsView";
import MetalTrackingSearchBarState, {
  toggleWatchedRackFlag,
} from "../mt-search/MetalTrackingSearchBarState";
import TitleState from "shared/redux/TitleState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    rackLocation: state.location.payload.rackLocation,
    searchResults:
      MetalTrackingSearchBarState.selectors.getSearchResults(state),
    isLoading: MetalTrackingSearchBarState.selectors.getIsLoading(state),
    page: MetalTrackingSearchBarState.selectors.getPage(state),
    pageSize: MetalTrackingSearchBarState.selectors.getPageSize(state),
    totalPages: MetalTrackingSearchBarState.selectors.getTotalPages(state),
    totalEntities:
      MetalTrackingSearchBarState.selectors.getTotalEntities(state),
    solutionId: getSolutionId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    pushRackDetailView: (entityId) => {
      return; // TODO: enable it after creating RACK_DETAIL view
      // eslint-disable-next-line
      dispatch({
        type: "METAL_TRACKING_RACK_DETAIL",
        payload: { entity_id: entityId },
      });
    },
    toggleShowFilters: (showFilters) =>
      dispatch(
        MetalTrackingSearchBarState.actionCreators.toggleShowFilters(
          showFilters,
        ),
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        MetalTrackingSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    toggleWatchedRackFlag: (solutionId, entity) =>
      dispatch(toggleWatchedRackFlag(solutionId, entity)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MetalTrackingSearchResultsView);
