/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import Colors from "styles/colors";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";

export const DialogModal = ({
  show = false,
  onHide,
  title = null,
  showCloseButton = true,
  closeOnBlur = true,
  cancelButtonText,
  cancelButtonVariant = "light",
  submitButtonText,
  submitButtonVariant = "success",
  okButtonText,
  okButtonVariant = "light",
  onSubmit,
  isSubmitting = false,
  children,
  isLoading,
  loadStatus,
  size = "sm",
  submitButtonDisabled = false,
  centered = false,
  footerContent = null,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      backdrop={closeOnBlur ? true : "static"}
      size={size}
      centered={centered}
    >
      {title ? (
        <Modal.Header
          closeButton={showCloseButton}
          css={{
            backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
            color: Colors.dialogs.HEADER_TEXT_COLOR,
            padding: "0.5rem 0.75rem 0.5rem 0.5rem",
          }}
          title={title}
        />
      ) : null}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer css={{ backgroundColor: Colors.dialogs.FOOTER_BACKGROUND }}>
        {footerContent ? (
          footerContent
        ) : loadStatus === "success" || loadStatus === "failed" ? (
          <Button variant={okButtonVariant} onClick={() => onHide()} size="sm">
            {okButtonText}
          </Button>
        ) : !isLoading || isLoading === false ? (
          <React.Fragment>
            <Button
              variant={cancelButtonVariant}
              css={{ marginRight: "0.5em" }}
              onClick={() => onHide()}
              size="sm"
            >
              {cancelButtonText}
            </Button>

            <Button
              variant={submitButtonVariant}
              onClick={() => onSubmit()}
              disabled={submitButtonDisabled}
              size="sm"
            >
              {isSubmitting ? <Icon src={faSpinner} spin /> : submitButtonText}
            </Button>
          </React.Fragment>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

DialogModal.propTypes = {
  /**
   * Controlled value that sets whether the `DialogModal` is displayed or not.
   */
  show: PropTypes.bool,
  /**
   * Callback function for when the `DialogModal` is closed in any of these 3 ways:
   *
   * User clicks the Cancel button.
   * User clicks the background (if `closeOnBlur` is enabled).
   * User click the X in the header (if a `title` is set and `showCloseButton` is enabled).
   */
  onHide: PropTypes.func.isRequired,
  /**
   * Displays a header and (optional) close button in the `DialogModal`, with the specified title.
   */
  title: PropTypes.string,
  /**
   * Hide or show the close button (small X in the top-right corner of the `DialogModal`).
   *
   * Disabling this alongside `closeOnBlur` can force the user to interact with the `DialogModal` to close it.
   *
   * Note: This is only displayed if a `title` is also set.
   */
  showCloseButton: PropTypes.bool,
  /**
   * Enable or disable clicking on the background to close the `DialogModal`.
   *
   * Disabling this alongside `showCloseButton` can force the user to interact with the `DialogModal` to close it.
   */
  closeOnBlur: PropTypes.bool,
  /**
   * The text displayed on the Cancel button.
   */
  cancelButtonText: PropTypes.string,
  /**
   * The button variant to be used for the Cancel button.
   */
  cancelButtonVariant: PropTypes.string,
  /**
   * The text displayed on the Submit button.
   */
  okButtonText: PropTypes.string,
  /**
   * The button variant to be used for the Ok button.
   */
  okButtonVariant: PropTypes.string,
  /**
   * The text displayed on the Submit button.
   */
  submitButtonText: PropTypes.string,
  /**
   * The button variant to be used for the Submit button.
   */
  submitButtonVariant: PropTypes.string,
  /**
   * Callback function for when the user clicks on the Submit button.
   */
  onSubmit: PropTypes.func,
  /**
   * Indicates api progress when the user clicks on the Submit button.
   */
  isSubmitting: PropTypes.bool,
  /**
   * Check for DriveAway Request Modal state
   */
  isLoading: PropTypes.bool,
  /**
   * The content of the `DialogModal`, displayed beneath the header and above the footer.
   */
  children: PropTypes.any,
  /**
   * Load Status can be "success" or "failed"
   */
  loadStatus: PropTypes.string,
  /**
   * The size of the modal, availble options are "lg", "md", and "sm"
   */
  size: PropTypes.string,
  /**
   * Disable or enable the submit button
   */
  submitButtonDisabled: PropTypes.bool,
  /**
   * Used to center the modal
   */
  centered: PropTypes.bool,
  /**
   * Custom content for the footer
   */
  footerContent: PropTypes.object,
};
