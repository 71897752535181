/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "components/atoms/Alert.atom";
import { AlertVariant } from "components/atoms/enums";

export const ExportAlert = ({
  exportFailed,
  onDismiss = () => {},
  className,
}: ExportAlertProps) => {
  const { t } = useTranslation("exports");

  const [isAlertVisible, setAlertVisible] = useState(false);
  useEffect(() => setAlertVisible(exportFailed), [exportFailed]);

  return (
    <Alert
      variant={AlertVariant.Danger}
      show={isAlertVisible}
      className={className}
      onClose={() => {
        setAlertVisible(false);
        onDismiss();
      }}
      dismissible
      data-qa="export-alert"
    >
      {t("exports:An error occurred while exporting the data.")}
    </Alert>
  );
};

type ExportAlertProps = {
  /**
   * boolean flag that triggers the display of the Alert
   */
  exportFailed: boolean;
  /**
   * function to run when the ExportAlert is dismissed/closed
   */
  onDismiss?: any;
  /**
   * A string for defining one or more classes that will be added to the Alert.
   */
  className?: string;
};
