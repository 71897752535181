/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import DocumentationNav from "../../documentation/DocumentationNav";
import { NavLink } from "./NavLink";
import { NavGroup } from "./NavGroup";

import { getActiveOrganization } from "../../../modules/organizations/OrganizationsState";
import { getAuthorization } from "../../auth/AuthorizationSelectors";
import { privateRouteGroupsMap } from "../../../routes";
import { groupData } from "./NavRouteAuthorization";
import { getClosestVisibleRouteMapEntry } from "../../../route-selectors";
import { getFeatureData } from "../../organizations/OrganizationsState";
import {
  getNavBarOpenGroupsFromStorage,
  setNavBarOpenGroupsInStorage,
} from "utils/nav-utils";

const DOCUMENTATION_GROUP_KEY = "DOCUMENTATION";

const getNavLinks = (links, groupKey, activeRoute) => {
  return links.map(({ key, route }) => {
    const isActiveRoute = activeRoute.path === route.path;
    const compositeKey = _.kebabCase(`${groupKey}-${key}`);
    return (
      <NavLink
        key={compositeKey}
        actionType={key}
        isActiveScreen={isActiveRoute}
      />
    );
  });
};

const getShouldSectionBeOpenByDefault = (
  defaultOpenConditions = [],
  authorization,
) => {
  // Returns one boolean saying if the group should be open or not.
  // We need to check each set of conditions. If any are true, then this will return true.
  return defaultOpenConditions?.reduce((shouldBeOpenByDefault, condition) => {
    return (
      // Checking reduced value first so the OR will terminate early if it is already true.
      shouldBeOpenByDefault ||
      authorization.isAuthorized(
        condition.privileges,
        condition.features,
        condition.organizationTypes,
      )
    );
  }, false);
};

const PrimaryNav = ({ sidebarMinimize, currentLocation }) => {
  const authorization = useSelector(getAuthorization);
  const featureData = useSelector(getFeatureData);
  const activeOrganization = useSelector(getActiveOrganization);
  const closestRouteMapEntry = useSelector(getClosestVisibleRouteMapEntry);

  const [openGroups, setOpenGroups] = useState([]);

  useEffect(() => {
    const openGroupsFromStorage = getNavBarOpenGroupsFromStorage();

    let openGroupsByDefault = [];
    openGroupsByDefault = Object.entries(privateRouteGroupsMap)
      .filter(([groupKey, navGroup]) => {
        return getShouldSectionBeOpenByDefault(
          navGroup.defaultOpen,
          authorization,
        );
      })
      .map(([groupKey]) => groupKey);

    const openGroupsByCurrentLocation = Object.entries(privateRouteGroupsMap)
      .filter(([groupKey, navGroup]) => {
        return _.keys(navGroup.routes).includes(currentLocation);
      })
      .map(([groupKey]) => groupKey);

    setOpenGroups(
      _.uniq([
        ...openGroupsFromStorage,
        ...openGroupsByDefault,
        ...openGroupsByCurrentLocation,
      ]),
    );
    // Dependent on `featureData` because it is used in authorization.
    // There were instances when switching orgs, the request wouldn't have finished
    // so the `defaultOpen` conditions would fail if it involved a feature check.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureData]);

  const toggleOpenGroup = (groupKey) => {
    let newOpenGroups = [...openGroups];
    if (newOpenGroups.includes(groupKey)) {
      newOpenGroups = newOpenGroups.filter((g) => g !== groupKey);
    } else {
      newOpenGroups.push(groupKey);
    }
    setOpenGroups(newOpenGroups);
    setNavBarOpenGroupsInStorage(newOpenGroups);
  };

  const groups = Object.entries(privateRouteGroupsMap).map((entry) => {
    const groupKey = entry[0];
    const navGroup = entry[1];
    const isOpen = openGroups.includes(groupKey);
    if (navGroup.hidden) {
      return null;
    }

    const { links, privileges, features, organizationTypes, globalAppConfigs } =
      groupData(authorization, activeOrganization, navGroup);
    const linkElems = getNavLinks(links, groupKey, closestRouteMapEntry);

    const isAuthorized = authorization.isAuthorized(
      privileges,
      features,
      organizationTypes,
      globalAppConfigs,
      true,
    );

    if (
      !links ||
      !links.length ||
      !isAuthorized ||
      !activeOrganization ||
      _.isEmpty(activeOrganization)
    ) {
      return null;
    }

    return (
      <NavGroup
        key={groupKey}
        groupKey={groupKey}
        isOpen={isOpen}
        toggleOpenGroup={toggleOpenGroup}
        sidebarMinimize={sidebarMinimize}
      >
        {linkElems}
      </NavGroup>
    );
  });

  return (
    <Suspense fallback="">
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {groups}
        <DocumentationNav
          isOpen={openGroups.includes(DOCUMENTATION_GROUP_KEY)}
          toggleOpenGroup={toggleOpenGroup}
          sidebarMinimize={sidebarMinimize}
        />
      </div>
    </Suspense>
  );
};

PrimaryNav.propTypes = {
  sidebarMinimize: PropTypes.bool,
  currentLocation: PropTypes.string,
};

export default PrimaryNav;
