import { connect } from "react-redux";

import { DriveAwayDashboard } from "./DriveAway.Dashboard.page";
import DriveAwayEntitiesState from "../redux/DriveAwayEntitiesState";
import DriveAwaySearchBarState from "../redux/DriveAwaySearchBarState";
import DriveAwayVinHistorySearchBarState from "../redux/DriveAwayVinHistorySearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    showFilters: DriveAwaySearchBarState.selectors.getShowAdvancedSearch(state),
    entityAvailableCount:
      DriveAwayEntitiesState.selectors.getEntityAvailableCount(state),
    entitySubmittedCount:
      DriveAwayEntitiesState.selectors.getEntitySubmittedCount(state),
    entityApprovedCount:
      DriveAwayEntitiesState.selectors.getEntityApprovedCount(state),
    entityDeniedCount:
      DriveAwayEntitiesState.selectors.getEntityDeniedCount(state),
    isEntityAvailableCountLoading:
      DriveAwayEntitiesState.selectors.getEntityCountAvailableLoading(state),
    isEntitySubmittedCountLoading:
      DriveAwayEntitiesState.selectors.getEntityCountSubmittedLoading(state),
    isEntityApprovedCountLoading:
      DriveAwayEntitiesState.selectors.getEntityCountApprovedLoading(state),
    isEntityDeniedCountLoading:
      DriveAwayEntitiesState.selectors.getEntityCountDeniedLoading(state),
    solutionId: getSolutionId(state),
    driveAwayVinHistorySearchResults:
      DriveAwayVinHistorySearchBarState.selectors.getVinHistorySearchResults(
        state,
      ),
    isVinHistoryLoading:
      DriveAwayVinHistorySearchBarState.selectors.getIsLoading(state),
    isVinHistoryLoadingError:
      DriveAwayVinHistorySearchBarState.selectors.getIsLoadingError(state),
    vinHistoryLoadingError:
      DriveAwayVinHistorySearchBarState.selectors.getLoadingError(state),
  };
}

const { fetchEntityCountForEachStatus } = DriveAwayEntitiesState.actionCreators;

const {
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
} = DriveAwaySearchBarState.actionCreators;

const actionCreators = {
  fetchEntityCountForEachStatus,
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
  resetVinHistorySearch:
    DriveAwayVinHistorySearchBarState.actionCreators.resetSearchBar,
  clearVinHistoryEntities:
    DriveAwayVinHistorySearchBarState.actionCreators.clearVinHistoryEntities,
};

export default connect(mapStateToProps, actionCreators)(DriveAwayDashboard);
