/** @jsxImportSource @emotion/react */
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback } from "react";
import SearchBarContainer from "./search/InventoryView.Details.SearchBarContainer";
import FcVinsSearchBarContainer from "./search/InventoryView.Details.FcVinsSearch.SearchBar.container";
import FcVinsSearchFiltersContainer from "./search/InventoryView.Details.FcVinsSearch.SearchFilters.container";
import SearchFiltersContainer from "./search/InventoryView.Details.FilterSectionContainer";
import LocationInformation from "./components/LocationInformation";
import { useTranslation } from "react-i18next";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Tabs } from "components/molecules/Tabs.molecule";
import { searchResultsColumns } from "./search/InvertoryView.Details.Search.Columns";
import { forecastedVinsColumns } from "./search/InventoryView.ForecastedVins.Columns";
import ExportModal from "modules/exports/ExportModal";
import { Search } from "components/templates/Search.template";
import { Modal } from "components/molecules/Modal.molecule";
import GeofenceBuilderMap from "modules/map/components/GeofenceBuilderMap";
import Colors from "styles/colors";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import DateRangeInput from "components/molecules/DateRangeInput.molecule";
import InventoryForecastChart from "./components/InventoryForecastChart";

export const InventoryViewDetails = ({
  fetchLocation,
  fetchVinSearch,
  fcVinSearch,
  solutionId,
  locationId,
  location,
  page,
  pageSize,
  setSearchFilter,
  setDwellFilterValue,
  dwellFilterValue,
  totalPages,
  setDetailsSearchPagination,
  addCoordinate,
  selectedMapCoordinate,
  isLoading,
  searchResults,
  exportSearch,
  exportName,
  exportIdentifier,
  isExporting,
  onsiteVinsResetSearch,
  setOnsiteVinsWatchEntity,
  setForecastedVinsWatchEntity,
  resetExport,
  totalCount,
  exportFailed,
  clearExportErrors,
  showFilters,
  toggleShowFilters,
  sortColumn,
  reverseSort,
  setDetailsSearchSort,
  isFcVinsSearchLoading,
  fcVinsSearchResults,
  fcVinsTotalEntities,
  fcVinsShowFilters,
  fcVinsPage,
  fcVinsPageSize,
  fcVinsTotalPages,
  fcVinsResetSearch,
  fcVinsToggleShowFilters,
  fcVinsSetPagination,
  fcVinsExportSearch,
  fcVinsClearExportErrors,
  fcVinsResetExport,
  fcVinsExportIdentifier,
  fcVinsExportName,
  fcVinsIsExporting,
  fcVinsExportFailed,
  isFcVinsTotalCountForSearchLoading,
  isOnsiteVinsCountLoading,
  fcVinsSortColumn,
  fcVinsReverseSort,
  fcVinsSetSort,
  selectedTabIndex,
  setSelectedTabIndex,
  setForecastDateRange,
  forecastData,
  forecastDateRange,
  isForecastDataLoading,
  fetchForecastData,
  redirectToVinDetails,
  canRedirectToVinDetails,
}) => {
  const { t } = useTranslation("inventory-view");
  const [vinDetails, setVinDetails] = useState({});
  const [showForcastedPopup, setShowForcastedPopup] = useState(false);
  const onTabChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  useSetTitleOnMount(t("inventory-view:InventoryView Details"));

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Details");

  useEffect(() => {
    setSearchFilter("dwell", dwellFilterValue);
    fetchLocation(solutionId, locationId);
    fetchVinSearch(solutionId, locationId);
    fetchForecastData(solutionId, locationId);
    fcVinSearch(solutionId);
    return () => {
      setDwellFilterValue(null);
    };
  }, [
    solutionId,
    locationId,
    fetchLocation,
    fetchVinSearch,
    dwellFilterValue,
    fcVinSearch,
  ]);

  useEffect(() => {
    if (!_.isEmpty(vinDetails?.lastPosition)) {
      const { latitude, longitude, datetime } = vinDetails?.lastPosition;
      addCoordinate(
        MapCoordinateType.CURRENT_LOCATION,
        latitude,
        longitude,
        datetime,
        "0_0",
        location,
        null,
        0,
      );
    }
  }, [vinDetails, location]);

  // Disable row click when we can't redirect
  let rowClickHandler = null;
  if (canRedirectToVinDetails) {
    rowClickHandler = (rowInfo, cell) => {
      // Prevent navigation if clicking in "watch" checkbox cell.
      if (cell.column.id === "watch") {
        return;
      }

      redirectToVinDetails(rowInfo.original);
    };
  }

  if (!location) {
    return null;
  }

  return (
    <div css={{ margin: "0 1em" }}>
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
      <Tabs selectedIndex={selectedTabIndex} onSelect={onTabChange}>
        <Tabs.TabList>
          <Tabs.Tab style={{ width: "25%", paddingLeft: "1em" }}>
            {t("inventory-view:Onsite VINs")}
          </Tabs.Tab>
          <Tabs.Tab style={{ width: "25%", paddingLeft: "1em" }}>
            {t("inventory-view:Forecasted VINs")}
          </Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanel>
          <div css={{ padding: "1em" }}>
            <LocationInformation location={location} />
          </div>
          <Search
            isLoading={isLoading}
            searchResults={searchResults}
            totalCount={totalCount}
            totalCountIsLoading={isOnsiteVinsCountLoading}
            totalCountTitle={t("inventory-view:Total VINs")}
            resetSearch={() => onsiteVinsResetSearch(solutionId)}
            showFilters={showFilters}
            hideSavedSearch={true}
            toggleShowFilters={toggleShowFilters}
            SearchBarContainer={SearchBarContainer}
            FiltersContainer={SearchFiltersContainer}
            exportProps={{
              exportEntities: exportSearch,
              exportIdentifier: exportIdentifier,
              exportName: exportName,
              isExporting: isExporting,
              exportFailed: exportFailed,
              clearExportErrors: clearExportErrors,
              resetExport: resetExport,
            }}
            tableProps={{
              data: searchResults,
              columns: searchResultsColumns(
                t,
                setVinDetails,
                setOnsiteVinsWatchEntity,
                solutionId,
              ),
              isLoading: isLoading,
              showPagination: true,
              fixPaginationToBottom: true,
              isManualPagination: true,
              isManualSorting: true,
              sortColumn: sortColumn,
              reverseSort: reverseSort,
              pageIndex: page,
              pageSize: pageSize,
              pageCount: totalPages,
              onPageChange: (newPage) => {
                setDetailsSearchPagination(solutionId, newPage, pageSize);
              },
              onPageSizeChange: (newPageSize) => {
                setDetailsSearchPagination(solutionId, 0, newPageSize);
              },
              onPageSort: (column, isReverse) => {
                setDetailsSearchSort(solutionId, column, isReverse);
              },
              rowClickHandler: rowClickHandler,
            }}
          />
          <Modal
            show={vinDetails?.id != null}
            onHide={() => {
              setVinDetails(null);
            }}
            backdrop="static"
          >
            <Modal.Header
              closeButton={true}
              css={{
                backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
                borderBottom: `1px solid ${Colors.border.BOTTOM_AXIS_BORDER}`,
                color: Colors.dialogs.HEADER_TEXT_COLOR,
              }}
            >
              VIN: {vinDetails?.id}
            </Modal.Header>
            <Modal.Body
              css={{
                padding: "0.5em",
                backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
              }}
            >
              <div css={{ height: "500px" }}>
                <GeofenceBuilderMap
                  selectedLocation={{ ...location }}
                  mapLocations={location ? [location] : []}
                  selectedLad={location?.lad}
                  selectedMapCoordinate={selectedMapCoordinate}
                  useBoxChiclets
                  enableGeofenceBuilder
                />
              </div>
            </Modal.Body>
          </Modal>
        </Tabs.TabPanel>
        <Tabs.TabPanel>
          <div css={{ overflow: "auto", padding: "1em" }}>
            <LocationInformation
              location={location}
              showForcastedColumn={location.is_manager ?? true}
              iconColumnClick={() => {
                setShowForcastedPopup(true);
              }}
            />
          </div>
          <Search
            isLoading={isFcVinsSearchLoading}
            searchResults={fcVinsSearchResults}
            totalCount={fcVinsTotalEntities}
            totalCountIsLoading={isFcVinsTotalCountForSearchLoading}
            totalCountTitle={t("inventory-view:Total VINs")}
            resetSearch={() => fcVinsResetSearch(solutionId)}
            showFilters={fcVinsShowFilters}
            hideSavedSearch={true}
            toggleShowFilters={fcVinsToggleShowFilters}
            SearchBarContainer={FcVinsSearchBarContainer}
            FiltersContainer={FcVinsSearchFiltersContainer}
            tableProps={{
              data: fcVinsSearchResults,
              columns: forecastedVinsColumns(
                t,
                setForecastedVinsWatchEntity,
                solutionId,
              ),
              isLoading: isFcVinsSearchLoading,
              showPagination: true,
              fixPaginationToBottom: true,
              isManualPagination: true,
              isManualSorting: true,
              sortColumn: fcVinsSortColumn,
              reverseSort: fcVinsReverseSort,
              pageIndex: fcVinsPage,
              pageSize: fcVinsPageSize,
              pageCount: fcVinsTotalPages,
              onPageChange: (newPage) => {
                fcVinsSetPagination(solutionId, newPage, pageSize);
              },
              onPageSizeChange: (newPageSize) => {
                fcVinsSetPagination(solutionId, 0, newPageSize);
              },
              onPageSort: (column, isReverse) => {
                fcVinsSetSort(solutionId, column, isReverse);
              },
              rowClickHandler: rowClickHandler,
            }}
          />

          <Modal
            show={showForcastedPopup}
            onHide={() => {
              setShowForcastedPopup(false);
            }}
            backdrop="static"
          >
            <Modal.Header
              closeButton={true}
              css={{
                color: Colors.dialogs.HEADER_TEXT_COLOR,
                backgroundColor: Colors.dialogs.HEADER_BACKGROUND,
                borderBottom: `1px solid ${Colors.border.BOTTOM_AXIS_BORDER}`,
                padding: ".75rem",
              }}
            >
              <Text size={FontSize.size20}>
                {t("inventory-view:Inventory Forecast")}
              </Text>

              <Tooltip
                placement={"bottom"}
                tooltipChildren={
                  <Text>
                    {t(
                      "inventory-view:Predicts inventory capacity for up to 90 days based on scheduled arrival and scheduled pickup",
                    )}
                  </Text>
                }
              >
                <Icon
                  type={IconType.FontAwesome}
                  size={FontSize.size14}
                  src={faQuestionCircle}
                  css={{ marginLeft: 10 }}
                />
              </Tooltip>
            </Modal.Header>
            <Modal.Body
              css={{
                padding: "0.5em",
              }}
            >
              <div
                css={{
                  textAlign: "center",
                }}
              >
                <Text>
                  {t(
                    "inventory-view:Please select the dates you want to see on the forecast",
                  )}
                </Text>
                <div
                  style={{
                    width: "70%",
                    margin: "0 auto",
                  }}
                >
                  <DateRangeInput
                    startDate={forecastDateRange.startDate}
                    endDate={forecastDateRange.endDate}
                    minRange={moment().add(1, "days").startOf("day").toDate()}
                    maxRange={moment().add(90, "days").startOf("day").toDate()}
                    saveClickHandler={(selectedStartDate, selectedEndDate) => {
                      setForecastDateRange(selectedStartDate, selectedEndDate);
                      fetchForecastData(solutionId, locationId);
                    }}
                    autoSelectOnInvalidValue={true}
                    autoSelectRange={6}
                  />
                </div>
                <div>
                  <InventoryForecastChart
                    maxCapacity={location.capacity?.totalAllocated ?? 0}
                    isLoading={isForecastDataLoading}
                    forecastData={forecastData}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer css={{ justifyContent: "flex-start", border: "0" }}>
              <Text>
                {t("inventory-view:Total Allocated Capacity")}:{" "}
                {location.capacity?.totalAllocated ?? 0}
              </Text>
            </Modal.Footer>
          </Modal>
        </Tabs.TabPanel>
      </Tabs>
    </div>
  );
};

InventoryViewDetails.propTypes = {
  fetchLocation: PropTypes.func.isRequired,
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  solutionId: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  showFilters: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  onsiteVinsResetSearch: PropTypes.func.isRequired,
  setDetailsSearchSort: PropTypes.func.isRequired,
  setOnsiteVinsWatchEntity: PropTypes.func,
  setForecastedVinsWatchEntity: PropTypes.func,
  toggleShowFilters: PropTypes.func,
  fcVinSearch: PropTypes.func,
  isFcVinsSearchLoading: PropTypes.bool,
  fcVinsSearchResults: PropTypes.array,
  fcVinsTotalEntities: PropTypes.number,
  fcVinsShowFilters: PropTypes.bool,
  fcVinsTotalPages: PropTypes.number,
  fcVinsPage: PropTypes.number,
  fcVinsPageSize: PropTypes.number,
  fcVinsResetSearch: PropTypes.func,
  fcVinsToggleShowFilters: PropTypes.func,
  fcVinsSetPagination: PropTypes.func,
  fcVinsExportSearch: PropTypes.func.isRequired,
  fcVinsIsExporting: PropTypes.bool.isRequired,
  fcVinsExportIdentifier: PropTypes.string,
  fcVinsExportName: PropTypes.string,
  fcVinsExportFailed: PropTypes.bool,
  fcVinsClearExportErrors: PropTypes.func,
  fcVinsResetExport: PropTypes.func,
  fcVinsSortColumn: PropTypes.string,
  fcVinsReverseSort: PropTypes.bool,
  fcVinsSetSort: PropTypes.func.isRequired,
  setForecastDateRange: PropTypes.func,
  forecastData: PropTypes.array,
  forecastDateRange: PropTypes.object,
  fetchForecastData: PropTypes.func.isRequired,
  redirectToVinDetails: PropTypes.func.isRequired,
  canRedirectToVinDetails: PropTypes.bool.isRequired,
};

export default InventoryViewDetails;
