/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

// Components
import { CommentFeed } from "components/organisms/CommentFeed.organism";

export const CommentFeedWidget = ({
  sharableId,
  customizedSharableOrgText,
  submissionId,
  fetchComments,
  addComment,
  markCommentsRead,
  updateComment,
  cancelUpdateComment,
  cancelAddComment,
  comments,
  isFetchingComments,
  fetchNotification,
}) => {
  return (
    <CommentFeed
      fetchComments={(pageNumber, pageSize) => {
        return fetchComments(submissionId, pageNumber, pageSize);
      }}
      commentShareableWithOrgId={sharableId}
      customizedSharableOrgText={customizedSharableOrgText}
      isFetchingComments={isFetchingComments}
      comments={comments}
      showBatchUpload={false}
      addComment={(data) => {
        return addComment(submissionId, data);
      }}
      cancelAddComment={cancelAddComment}
      updateComment={(commentId, updatedData) => {
        return updateComment(submissionId, commentId, updatedData);
      }}
      cancelUpdateComment={cancelUpdateComment}
      markCommentsRead={(dateTime) => {
        return markCommentsRead(submissionId, dateTime);
      }}
      fetchNotification={fetchNotification}
      style={{ marginBottom: "5rem" }}
    />
  );
};

CommentFeedWidget.propTypes = {
  submissionId: PropTypes.string,
  sharableId: PropTypes.number,
  customizedSharableOrgText: PropTypes.string,
  fetchComments: PropTypes.func.isRequired,
  isFetchingComments: PropTypes.bool.isRequired,
  comments: PropTypes.shape({
    currentPage: PropTypes.number,
    data: PropTypes.array,
    totalCount: PropTypes.number,
    totalCountRead: PropTypes.number,
    totalCountUnread: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  addComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  fetchNotification: PropTypes.func.isRequired,
};
