/** @jsxImportSource @emotion/react */

import { LadChicletCSS as Chiclet } from "components/chiclets";
import SimpleAddressCell from "components/organisms/base-table/Cell/SimpleAddressCell";
import { getType } from "modules/geofence-edit/geofence-types";

const NameCellRenderer = (props) => {
  const { lad, name } = props.value;

  if (lad == null) {
    return <div css={{ height: "100%" }} />;
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div>
        <Chiclet height={25} width={25} lad={lad} />
      </div>
      <div
        css={{
          marginLeft: "0.5em",
          marginRight: "0.5em",
          width: "100%",
          fontWeight: "bold",
        }}
      >
        {name}
      </div>
    </div>
  );
};

const GeofenceCellRenderer = (props) => {
  if (props.value) {
    const fenceType = getType(props.value);

    return (
      <div>
        <div>{fenceType.name}</div>
      </div>
    );
  }

  return null;
};

export const getColumns = (t, lads) => {
  const getLad = (location) => {
    let lad = lads.find((l) =>
      location.lad ? l.id === Number(location.lad.id) : false,
    );
    if (lad) {
      return lad;
    }

    if (location.name) {
      // Return the unassigned lad
      return {
        id: 0,
        code: "u",
        description: t("location-matching:unclassified"),
      };
    }

    return null;
  };

  let columns = [];

  columns.push({
    Header: t("location-matching:Name"),
    id: "name",
    accessor: (d) => {
      return {
        name: d.name,
        lad: getLad(d),
      };
    },
    Cell: NameCellRenderer,
  });

  columns.push({
    Header: t("location-matching:Address"),
    id: "address",
    accessor: (d) => {
      return {
        address: d.address,
        address2: d.address2,
        city: d.city,
        state: d.state,
        zip: d.postal_code,
        country: d.country,
      };
    },
    Cell: SimpleAddressCell,
  });

  columns.push({
    Header: t("location-matching:Location Code"),
    accessor: "code",
    minWidth: 50,
  });

  columns.push({
    Header: t("location-matching:Geofence"),
    accessor: "geofence",
    minWidth: 50,
    Cell: GeofenceCellRenderer,
  });

  return columns;
};
