/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FlexDiv } from "styles/container-elements";
import {
  LinkButton,
  markerStyle,
  actionButtonStyle,
} from "styles/table-action-styles";
import { TiPencil, TiTrash } from "react-icons/ti";

export const ActionCell = (props) => {
  const {
    actionHandler,
    row: { index, original },
  } = props;

  const { t } = useTranslation("organizations");

  return (
    <FlexDiv css={{ "button:last-child svg": { marginRight: "0 !important" } }}>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("UPDATE", {
            ...original,
            rowID: index,
          })
        }
      >
        {t("organizations:Edit")} <TiPencil style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("DELETE", {
            ...original,
            rowID: index,
          })
        }
      >
        {t("organizations:Delete")} <TiTrash style={markerStyle} />
      </LinkButton>
    </FlexDiv>
  );
};

ActionCell.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};
