/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { Text, FontSize } from "components/atoms/Text.atom";

const useColumns = () => {
  const { t } = useTranslation("partview-details");

  return [
    {
      Header: t("partview-details:Name"),
      accessor: "name",
      Cell: (cellInfo) => {
        return (
          <div css={{ height: "100%", display: "flex", alignItems: "start" }}>
            <Text block size={FontSize.size12}>
              {cellInfo.value}
            </Text>
          </div>
        );
      },
    },
    {
      Header: t("partview-details:Value"),
      accessor: "value",
      Cell: (cellInfo) => {
        return (
          <div
            css={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {Array.isArray(cellInfo.value)
              ? cellInfo.value.map((item) => (
                  <Text block size={FontSize.size12}>
                    {item}
                  </Text>
                ))
              : null}
            {!Array.isArray(cellInfo.value) ? (
              <Text block size={FontSize.size12}>
                {cellInfo.value}
              </Text>
            ) : null}
          </div>
        );
      },
    },
  ];
};

export const PackageDetailTable = ({
  orderPriority = "",
  commodityCode = "",
  shipmentNumberAndLoadCode = [],
  references = [],
}) => {
  const { t } = useTranslation("partview-details");
  const columns = useColumns();

  const packageDetailsList = [
    {
      name: t("partview-details:Order Priority"),
      value: orderPriority ? `${orderPriority}` : null,
    },
    {
      name: t("partview-details:Commodity Code"),
      value: commodityCode ? commodityCode : null,
    },
    {
      name: t("partview-details:Shipment Number"),
      value: shipmentNumberAndLoadCode[0]?.ShipmentNumber ?? null,
    },
    {
      name: t("partview-details:Load Code"),
      value: shipmentNumberAndLoadCode[0]?.LoadCode ?? null,
    },
  ];

  if (references?.length > 0) {
    references.forEach((reference) => {
      for (const key in reference) {
        packageDetailsList.push({ name: key, value: reference[key] });
      }
    });
  }

  return (
    <BaseTable
      data-qa="package-details-tab"
      columns={columns}
      theme={Themes.LIGHT}
      data={packageDetailsList}
      disablePagination
    />
  );
};

PackageDetailTable.propTypes = {
  orderPriority: PropTypes.string,
  commodityCode: PropTypes.string,
  shipmentNumberAndLoadCode: PropTypes.array,
  references: PropTypes.array,
};
