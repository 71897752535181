import { useEffect } from "react";
import { useDispatch } from "react-redux";

import TitleState from "shared/redux/TitleState";

// Sets the title in the header (+ page title) on mount and whenever the title changes.
export const useSetTitleOnMount = (title: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setTitle(title));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};

// Sets the sub-title in the header on mount and whenever the sub-title changes.
export const useSetSubTitleOnMount = (subTitle?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setSubTitle(subTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTitle]);
};

// Sets the description in the header on mount and whenever the description changes.
export const useSetDescriptionOnMount = (description?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setDescription(description));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);
};

// Sets the title in the header (+ page title) once.
export const useSetTitle = (title: string, dependency: any = null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setTitle(title));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);
};

// Sets the sub-title in the header once.
export const useSetSubTitle = (subTitle?: string, dependency: any = null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setSubTitle(subTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);
};

// Sets the description in the header once.
export const useSetDescription = (
  description?: string,
  dependency: any = null,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TitleState.actionCreators.setDescription(description));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);
};

export const clearTitles = () => {
  return TitleState.actionCreators.clearTitles();
};
