/** @jsxImportSource @emotion/react */
import axios from "axios";
import apiUrl from "api-url";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { Text } from "components/atoms/Text.atom";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";
import { Button } from "components/atoms/Button.atom";

export const DamageViewLocationsSelect = ({
  user,
  selectedLocations,
  setSelectedLocations,
  disabled = false,
}) => {
  const { t } = useTranslation("users");
  const [locationInputValue, setLocationInputValue] = useState("");
  const [overrideLocations, setOverrideLocations] = useState([]);
  const [overrideLocation, setOverrideLocation] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const previousUser = overrideLocation?.assignee;
  const overrideLocationName = overrideLocation?.label;

  const overrideIds = overrideLocations.map((data) => {
    return data.value;
  });

  const hideHandler = () => {
    setShow(false);
  };

  const onChangeAccept = () => {
    if (overrideLocation) {
      setOverrideLocations([...overrideLocations, overrideLocation]);
      setSelectedLocations([...selectedLocations, overrideLocation]);
      setOverrideLocation(null);
    }
  };

  const CheckboxOption = (props) => {
    return (
      <li
        ref={props.innerProps.ref}
        {...props.innerProps}
        className={props.selectProps.className}
        css={{
          fontSize: "0.8em",
          padding: "0.25em 0.5em;",
          backgroundColor:
            props.data.assignee !== null &&
            props.data.assignee !== undefined &&
            props.data.assignee?.toLowerCase() !== user &&
            !overrideIds.includes(props.data.value)
              ? Colors.background.LIGHT_GRAY
              : props.isSelected
              ? "#EBF3FF"
              : props.isFocused
              ? "#DEEBFF"
              : "#fff",
          color:
            props.data.assignee !== null &&
            props.data.assignee !== undefined &&
            props.data.assignee?.toLowerCase() !== user &&
            !overrideIds.includes(props.data.value)
              ? "#aaa"
              : props.isSelected
              ? "#333"
              : props.isFocused
              ? "#333"
              : "#6d6d6d",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          cursor: "pointer",
        }}
      >
        <Checkbox
          id={props.label}
          checked={props.isSelected}
          css={{
            padding: "0.3em",
          }}
        />
        <Text
          as="label"
          htmlFor={props.label}
          style={{ cursor: "pointer", marginBottom: 0 }}
        >
          {`${props.label} ${
            props.data &&
            props.data.assignee &&
            props.data.assignee?.toLowerCase() !== user &&
            !overrideIds.includes(props.data.value)
              ? "(" + props.data.assignee + ")"
              : ""
          }`}
          {props.data.assignee !== null &&
          props.data.assignee !== undefined &&
          props.data.assignee?.toLowerCase() !== user &&
          overrideIds.indexOf(props.data.value) < 0 ? (
            <Button
              variant="link"
              onClick={() => {
                setShow(true);
                setOverrideLocation(props.data);
              }}
            >
              {t("users:Override")}
            </Button>
          ) : null}
        </Text>
      </li>
    );
  };

  CheckboxOption.propTypes = {
    props: PropTypes.object,
  };

  const loadLocations = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/damageview/submission/filters?filterType=locations&includeAssignee=t&query=${locationInputValue}&pageSize=20&pageNumber=${pageNumber}`,
    );
    const response = await axios.get(url).catch((err) => {
      console.log(err);
    });

    data = response?.data?.data?.map((val) => {
      return {
        label: val.name,
        value: val.id,
        assignee: val.assignee,
      };
    });
    metaData = response?.data?.meta;

    return {
      options: data,
      hasMore: metaData ? metaData.currentPage < metaData.totalPages : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
  };

  const onMenuOpen = useCallback(() => {
    setMenuIsOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    // When the override confirmation modal is open, keep the dropdown menu open
    if (show) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [show]);

  return (
    <div>
      <AsyncPaginate
        id="location"
        placeholder={""}
        isDisabled={disabled}
        value={selectedLocations}
        isMulti={true}
        isClearable={false}
        isAsync={true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onInputChange={(val) => {
          setLocationInputValue(val);
        }}
        components={{
          Option: CheckboxOption,
        }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        loadOptions={async (currentVal, options, additionalOptions) => {
          const data = await loadLocations(
            currentVal,
            additionalOptions && currentVal === additionalOptions?.prevValue
              ? additionalOptions.pageNumber + 1
              : 0,
          );
          return data;
        }}
        onChange={(data) => {
          const selectedData = data.filter((location) => {
            return (
              location.assignee === null ||
              location.assignee === undefined ||
              location.assignee?.toLowerCase() === user ||
              overrideIds.includes(location.value)
            );
          });
          setSelectedLocations(selectedData);
        }}
      />
      <Modal
        show={show}
        css={{
          marginTop: "6rem",
          ".modal-dialog": {
            boxShadow: "3px 4px 29px 2px rgba(0,0,0,0.75)",
          },
        }}
      >
        <ModalBody>
          <div>
            {t(
              "users:Are you sure you want to override the location assignment?",
            )}
          </div>
          <div>
            {user
              ? t(
                  "users:This will grant [[[overrideLocationName]]] to [[[user]]] and remove the location assignment from [[[previousUser]]]",
                  { overrideLocationName, previousUser, user },
                )
              : t(
                  "users:This will grant [[[overrideLocationName]]] to the new user and remove the location assignment from [[[previousUser]]]",
                  { overrideLocationName, previousUser },
                )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="success"
            onClick={() => {
              onChangeAccept();
              hideHandler();
            }}
          >
            {t("users:Accept")}
          </Button>
          <Button
            variant="transparent"
            onClick={() => {
              hideHandler();
            }}
          >
            {t("users:Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

DamageViewLocationsSelect.propTypes = {
  user: PropTypes.string,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
