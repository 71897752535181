import { IoMdTimer } from "react-icons/io";
import {
  faArrowDown,
  faExclamationSquare,
  faBoneBreak,
  faRoute,
  faRecycle,
  faCompass,
  faSendBack,
} from "@fortawesome/pro-solid-svg-icons";
import { faTruckContainer } from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingBox } from "@fortawesome/pro-regular-svg-icons";
import { IconType } from "components/atoms/enums";
import Colors from "styles/colors";

export const PartViewExceptionType = {
  SHORT: "SH",
  MISSING: "MS",
  HELD: "HE",
  DAMAGED: "DM",
  MISROUTED: "MR",
  RECYCLED: "RE",
  LOST: "LS",
  BEHIND_SCHEDULE: "BS",
  BACK_ORDER: "BO",
  DELAYED: "DELAYED",
};

export const PartViewDamagedCategory = {
  PACKAGE_CRUSHED: "C",
  PACAKGE_PUNCTURED: "P",
  PACKAGE_WET: "S",
  PART_BROKEN: "B",
  PART_BENT: "N",
  PART_SCRATCHED: "X",
  NONE: "Z",
};

export const getExceptionChartColor = (code) => {
  switch (code) {
    case PartViewExceptionType.BEHIND_SCHEDULE:
      return Colors.exceptions.BEHIND_SCHEDULE;
    case PartViewExceptionType.MISSING:
      return Colors.exceptions.MISSING;
    default:
      return Colors.exceptions.DEFAULT;
  }
};

export const getIconData = (code) => {
  const iconColor = getExceptionChartColor(code);

  switch (code) {
    case PartViewExceptionType.SHORT:
      return {
        type: IconType.FontAwesome,
        src: faArrowDown,
        color: iconColor,
      };
    case PartViewExceptionType.MISSING:
      return {
        type: IconType.FontAwesome,
        src: faExclamationSquare,
        color: iconColor,
      };
    case PartViewExceptionType.HELD:
      return {
        type: IconType.FontAwesome,
        src: faHandHoldingBox,
        color: iconColor,
      };
    case PartViewExceptionType.DAMAGED:
      return {
        type: IconType.FontAwesome,
        src: faBoneBreak,
        color: iconColor,
      };
    case PartViewExceptionType.MISROUTED:
      return {
        type: IconType.FontAwesome,
        src: faRoute,
        color: iconColor,
      };
    case PartViewExceptionType.RECYCLED:
      return {
        type: IconType.FontAwesome,
        src: faRecycle,
        color: iconColor,
      };
    case PartViewExceptionType.LOST:
      return {
        type: IconType.FontAwesome,
        src: faCompass,
        color: iconColor,
      };
    case PartViewExceptionType.BEHIND_SCHEDULE:
      return {
        type: IconType.ReactIcons,
        src: IoMdTimer,
        color: iconColor,
      };
    case PartViewExceptionType.BACK_ORDER:
      return {
        type: IconType.FontAwesome,
        src: faSendBack,
        color: iconColor,
      };
    case "Delivered":
      return {
        type: IconType.FontAwesome,
        src: faTruckContainer,
        color: iconColor,
      };
    default:
      return null;
  }
};

/**
 *  Returns a list for PartView's ExceptionsPanel dashboard component
 *
 * e.g.
 * ```
 * <ExceptionsPanel
 *  exceptionGroups={[
 *    {
 *      title: "Exceptions",
 *      exceptions: [ ... ] // <- return of this function goes here
 *      isLoading: isLoading
 *    }
 *  ]}
 *  ...
 * />
 * ```
 *
 * @param {Array<{label: string, value: string}>} exceptions List of exception types
 * @param {{ active?: number, delivered?: number, exceptions?: { [reasonCode: string]: number }}} counts
 * Map of reasonCode (value) to count for each exception type (and "active" or "delivered")
 * @param {Function} t Translation function from i18next
 * @returns
 */
export const getExceptionsDataForWidget = (exceptions, counts = {}) => {
  if (!exceptions || !counts) {
    return [];
  }

  const widgetData = exceptions.map((exception) => {
    const reasonCode = exception.value;

    const count = counts.exceptions?.[reasonCode] ?? 0;
    const total = counts.active ?? 0;
    let percentage = 0;

    if (total > 0) {
      percentage = count / total;
      percentage *= 100;
    }

    return {
      name: exception.name,
      count: count,
      percentage: percentage.toFixed(2),
      icon: getIconData(reasonCode),
      reasonCode: reasonCode,
    };
  });

  return widgetData ?? [];
};

export const getDeliveredDataForWidget = (totalActive, deliveredCount) => {
  const totalDeliveredCounts = deliveredCount ?? 0;
  const total = totalActive ?? 0;
  let percentage = 0;

  if (total > 0) {
    percentage = totalDeliveredCounts / total;
    percentage *= 100;
  }
  return percentage.toFixed(2);
};
