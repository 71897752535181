import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import ShipmentDwellNotificationSearchBarState from "../../redux/ShipmentDwellNotificationSearchBarState";
import { FILTERS } from "./ShipmentDwellNotification.searchOptions";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const {
  getSearchFilters,
  getHasSearchCriteriaChanged,
  getAreAllPrerequisiteFiltersSelected,
} = ShipmentDwellNotificationSearchBarState.selectors;

const mapStateToProps = (state) => {
  return {
    auth: getAuthorization(state),
    searchFilters: getSearchFilters(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    areAllPrerequisiteFiltersSelected:
      getAreAllPrerequisiteFiltersSelected(state),
  };
};

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = ShipmentDwellNotificationSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
