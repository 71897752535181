/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Colors from "styles/colors";
import { FlexRow } from "./GeofenceEditForm";

export const GeofenceEditPolygonalForm = ({ points, className }) => {
  const { t } = useTranslation("geofence-edit");
  const numberOfPoints = points?.length ? points.length - 1 : 0;

  return (
    <FlexRow className={className}>
      <div
        css={{
          color: Colors.text.LIGHT_GRAY,
          fontSize: "0.9em",
          whiteSpace: "nowrap",
        }}
      >
        <span
          css={{
            fontWeight: "bold",
            fontSize: "1.5em",
            padding: "0 .2em",
          }}
        >
          {numberOfPoints}
        </span>
        {t("geofence-edit:Points")}
      </div>
    </FlexRow>
  );
};

GeofenceEditPolygonalForm.propTypes = {
  points: PropTypes.array,
  className: PropTypes.string,
};
