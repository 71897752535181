import { useTranslation } from "react-i18next";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { ButtonVariant } from "components/atoms/enums";
import { MouseEventHandler, ReactNode } from "react";

const WizardFooterButton = ({
  children,
  variant = "primary",
  disabled = false,
  onClick = () => {},
  ...restProps
}: WizardFooterButtonProps) => {
  return (
    <Button
      css={{ minWidth: "5rem" }}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </Button>
  );
};

type WizardFooterButtonProps = ButtonProps & {
  /**
   * The button variant, which defines it's style.
   *
   * Use the `ButtonVariant` type for this value.
   */
  variant?: ButtonVariant;

  /**
   * The content inside the button.
   */
  children: ReactNode;
};

const BackButton = ({ disabled, onClick }: BackButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      variant="success"
      disabled={disabled}
      onClick={onClick}
      data-qa="notification-management-back-button"
    >
      <Text>{t("notification-management:Back")}</Text>
    </WizardFooterButton>
  );
};

type BackButtonProps = ButtonProps;

const CancelButton = ({ onClick }: CancelButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      variant="secondary"
      onClick={onClick}
      data-qa="notification-management-cancel-button"
    >
      <Text>{t("notification-management:Cancel")}</Text>
    </WizardFooterButton>
  );
};

type CancelButtonProps = Omit<ButtonProps, "disabled">;

const CreateButton = ({ disabled, onClick }: CreateButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      disabled={disabled}
      variant="success"
      onClick={onClick}
      data-qa="notification-management-create-button"
    >
      <Text>{t("notification-management:Create")}</Text>
    </WizardFooterButton>
  );
};

type CreateButtonProps = ButtonProps;

const DoneButton = ({ disabled, onClick }: DoneButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      variant="success"
      disabled={disabled}
      onClick={onClick}
      data-qa="notification-management-done-button"
    >
      <Text>{t("notification-management:Done")}</Text>
    </WizardFooterButton>
  );
};

type DoneButtonProps = ButtonProps;

const NextButton = ({ disabled, onClick }: NextButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      disabled={disabled}
      variant="success"
      onClick={onClick}
      data-qa="notification-management-next-button"
    >
      <Text>{t("notification-management:Next")}</Text>
    </WizardFooterButton>
  );
};

type NextButtonProps = ButtonProps;

const RetryButton = ({ onClick }: RetryButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      variant="primary"
      onClick={onClick}
      data-qa="notification-management-retry-button"
    >
      {t("notification-management:Retry")}
    </WizardFooterButton>
  );
};

type RetryButtonProps = Omit<ButtonProps, "disabled">;

const UpdateButton = ({ disabled, onClick }: UpdateButtonProps) => {
  const { t } = useTranslation("notification-management");
  return (
    <WizardFooterButton
      disabled={disabled}
      variant="success"
      onClick={onClick}
      data-qa="notification-management-update-button"
    >
      <Text>{t("notification-management:Update")}</Text>
    </WizardFooterButton>
  );
};

type UpdateButtonProps = ButtonProps;

type ButtonProps = {
  /**
   * The event fired when the user clicks the button.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Disable the button.
   */
  disabled?: boolean;
};

export {
  BackButton,
  CancelButton,
  CreateButton,
  DoneButton,
  NextButton,
  RetryButton,
  UpdateButton,
};

export type {
  BackButtonProps,
  CancelButtonProps,
  CreateButtonProps,
  DoneButtonProps,
  NextButtonProps,
  RetryButtonProps,
  UpdateButtonProps,
};
