/** @jsxImportSource @emotion/react */
import { PinChicletSVG } from "components/chiclets";
import Colors from "styles/colors";
import { faCar, faBox } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";

export const MapCoordinateType = {
  SELECTED_COORDINATE: "selectedCoordinate",
  CURRENT_LOCATION: "currentLocation",
  CURRENT_CONNECTED_CAR: "currentConnectedCar",
  CURRENT_LOCATION_PACKAGE: "currentLocationPackage",
};

const defaultMarkerSVG = PinChicletSVG({
  codeLetter: "",
  backgroundColor: Colors.highlight.RED,
  textColor: "black",
  height: 32,
  width: 32,
});

const MapCoordinateDefinition = {
  // default definition if no icon defined
  default: {
    icon: defaultMarkerSVG,
    iconHeight: 32,
    iconWidth: 32,
    defaultHeight: 32,
    defaultWidth: 32,
  },
  [MapCoordinateType.SELECTED_COORDINATE]: {
    icon: defaultMarkerSVG,
    iconHeight: 32,
    iconWidth: 32,
    defaultHeight: 32,
    defaultWidth: 32,
  },
  [MapCoordinateType.CURRENT_LOCATION]: {
    icon: renderToString(
      <svg
        width={faCar.icon[0]}
        height={faCar.icon[1]}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fill: "#000",
          stroke: "#fff",
          strokeWidth: 5,
        }}
      >
        <path d={faCar.icon[4]}></path>
      </svg>,
    ),
    iconHeight: 34,
    iconWidth: 39,
    iconDefaultHeight: 36,
    iconDefaultWidth: 26,
  },
  [MapCoordinateType.CURRENT_CONNECTED_CAR]: {
    icon: "/map-icon-connectedcar.svg",
    iconHeight: 54,
    iconWidth: 39,
    iconDefaultHeight: 36,
    iconDefaultWidth: 26,
  },
  [MapCoordinateType.CURRENT_LOCATION_PACKAGE]: {
    icon: renderToString(
      <svg
        width={faBox.icon[0]}
        height={faBox.icon[1]}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fill: "#2785a9",
          stroke: "#000",
          strokeWidth: 5,
        }}
      >
        <path d={faBox.icon[4]}></path>
      </svg>,
    ),
    iconHeight: 24,
    iconWidth: 24,
    iconDefaultHeight: 36,
    iconDefaultWidth: 26,
  },
};

export const getMapCoordinateDefinition = (type) => {
  return MapCoordinateDefinition[type]
    ? MapCoordinateDefinition[type]
    : MapCoordinateDefinition.default;
};
