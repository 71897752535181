/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

import { isMediumAndDown, useIsMediumAndDown } from "components/responsive";
import { toggleNavBarStateFromStorage } from "utils/nav-utils";
import Colors from "styles/colors";

export const NavClickable = ({
  sidebarMinimize,
  setSidebarMinimize,
  iconClass,
}) => {
  useIsMediumAndDown();
  const meduimAndDown = isMediumAndDown();
  return (
    <div className={iconClass}>
      <span className="faNavClickableCircle">
        <FontAwesomeIcon
          transform={{ rotate: !sidebarMinimize && !meduimAndDown ? 90 : 270 }}
          size="sm"
          className={"fa-nav"}
          style={{ cursor: "pointer", alignSelf: "center", flex: "1" }}
          icon={faChevronDown}
          color={Colors.text.HIT_GRAY}
          onClick={() => {
            setSidebarMinimize(!sidebarMinimize);
            toggleNavBarStateFromStorage(!sidebarMinimize);
          }}
        />
      </span>
    </div>
  );
};
NavClickable.propTypes = {
  sidebarMinimize: PropTypes.bool,
  setSidebarMinimize: PropTypes.func,
  iconClass: PropTypes.string,
};
