/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text, FontSize } from "components/atoms/Text.atom";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";

export const LinkPanels = ({ title, children }) => {
  return (
    <div css={{ padding: "1em" }}>
      {title ? (
        <div css={{ marginBottom: "2em", borderBottom: "1px solid #ddd" }}>
          {/* Title */}
          <Text
            block
            size={FontSize.size24}
            color={Colors.background.GRAY_BLUE}
            css={{ marginBottom: "1em" }}
          >
            {title}
          </Text>
        </div>
      ) : null}
      <div
        css={{
          display: "grid",
          gap: "1em",
          [MediaQueries.mediumAndUp]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          [MediaQueries.medium]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [MediaQueries.smallAndDown]: {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {children}
      </div>
    </div>
  );
};

LinkPanels.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};
