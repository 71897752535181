import { connect } from "react-redux";
import { EditSavedSearchModal } from "../../../components/saved-search/EditSavedSearchModal";
import ContainerTrackingSearchBarState from "../redux/ContainerTrackingSearchBarState";
import ContainerTrackingSavedSearchState from "../redux/ContainerTrackingSavedSearchState";
import SearchBarContainer from "../search/ContainerTrackingSearchBarContainer";
import FilterSectionContainer from "../search/ContainerTrackingSearchFiltersContainer";
import { getSolutionId } from "../utils/ContainerTrackingUtils";
import { getFeatureData } from "modules/organizations/OrganizationsState";

const mapStateToProps = (state) => {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    ContainerTrackingSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { saveSearch, editSearch } =
    ContainerTrackingSavedSearchState.actionCreators;

  function saveSearchDispatch(name, search) {
    return (dispatch, getState) => {
      let state = getState();
      let featureData = getFeatureData(state);
      const solution_id = getSolutionId(featureData);
      dispatch(saveSearch(name, search, solution_id));
    };
  }

  return {
    saveSearch: (name, search) => {
      return dispatch(saveSearchDispatch(name, search));
    },
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSavedSearchModal);
