/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "react-loader";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import Colors from "styles/colors";
import { getActiveOrganization } from "../organizations/OrganizationsState";
import MilestoneDashboardSearchBarState from "./MilestoneDashboardSearchBarState";
import MilestoneEventsTable from "./MilestoneEventsTable";
import MilestoneDashboardSearchBarContainer from "./MilestoneDashboardSearchBarContainer";
import FilterSectionContainer from "./MilestoneEventFilterSectionContainer";
import * as Styles from "./MilestoneDashboardStyles";

import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

const DataHeader = ({ total = 0, isLoading = false }) => {
  const { t } = useTranslation("milestone-dashboard");

  return (
    <div css={[Styles.flexRow, Styles.centeredRow]}>
      <span css={{ fontSize: 16 }}>
        <span
          css={{
            fontWeight: 600,
            position: "relative",
            ".loadedContent": { display: "inline" },
          }}
        >
          <Loader loaded={!isLoading} left="0.5em" scale={0.35}>
            {total.toLocaleString(undefined)}
          </Loader>
        </span>{" "}
        <span css={{ marginLeft: isLoading ? "1.2em" : null }}>
          {t("milestone-dashboard:Total VINs")}
        </span>
      </span>
    </div>
  );
};

DataHeader.propTypes = {
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
};

const MilestoneDashboard = ({ isPartner }) => {
  const { t } = useTranslation("milestone-dashboard");
  const activeOrganization = useSelector(getActiveOrganization);

  useSetTitleOnMount(t("milestone-dashboard:Milestone Dashboard"));
  useSetDescriptionOnMount(
    isPartner
      ? t("milestone-dashboard:All milestones for a Partner organization")
      : t("milestone-dashboard:All milestones for a Carrier organization"),
  );

  useTrackWithMixpanelOnce("Viewed Page: Milestone / Dashboard");

  const [showFilters, setShowFilters] = useState(true);

  const totalEntities = useSelector(
    MilestoneDashboardSearchBarState.selectors.getTotalEntities,
  );

  const totalCount = useSelector(
    MilestoneDashboardSearchBarState.selectors.getTotalCount,
  );
  const totalCountIsLoading = useSelector(
    MilestoneDashboardSearchBarState.selectors.getTotalCountIsLoading,
  );

  const exportIdentifier = useSelector(
    MilestoneDashboardSearchBarState.selectors.getExportIdentifier,
  );

  const exportName = useSelector(
    MilestoneDashboardSearchBarState.selectors.getExportName,
  );

  const isExporting = useSelector(
    MilestoneDashboardSearchBarState.selectors.getIsExporting,
  );

  const exportFailed = useSelector(
    MilestoneDashboardSearchBarState.selectors.getExportFailed,
  );

  const dispatch = useDispatch();

  // clear filters when switching between organizations
  useEffect(() => {
    dispatch(
      MilestoneDashboardSearchBarState.actionCreators.resetSearchAndFilters(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  // Reset the search bar on mount, and add a default date range
  useEffect(
    () => {
      dispatch(
        MilestoneDashboardSearchBarState.actionCreators.resetSearchBar(),
      );

      // Set a default date range of the past hour,
      // since the API cannot handle returning more data than this at the moment.
      // H2-2021 -> Originally set to 24 hours.
      // H2-3795 -> Now set to 1 hour.
      let newDateRange = {
        from: moment().subtract(1, "hours"),
        to: moment(),
      };

      dispatch(
        MilestoneDashboardSearchBarState.actionCreators.setSearchFilter(
          "unused",
          newDateRange,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(MilestoneDashboardSearchBarState.actionCreators.searchEntities());
  }, [dispatch]);

  return (
    <div
      css={[
        Styles.flexCol,
        Styles.overflowWrapper,
        { padding: "20px 20px 0 20px" },
      ]}
    >
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
      />

      <MilestoneDashboardSearchBarContainer
        isShowingFilters={showFilters}
        toggleShowFilters={() => {
          setShowFilters(!showFilters);
        }}
      />
      <FilterSectionContainer
        show={showFilters}
        backgroundColor={Colors.background.GRAY}
        sectionStyle={{ paddingRight: "8em" }}
      />
      <ExportAlert exportFailed={exportFailed} className="my-1" />
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DataHeader total={totalCount} isLoading={totalCountIsLoading} />

        <DownloadDataButton
          onClick={() => {
            dispatch(
              MilestoneDashboardSearchBarState.actionCreators.exportSearch(),
            );
          }}
          isExporting={isExporting}
          isDisabled={totalEntities === 0}
          data-qa="button-search-results-export"
        />
      </div>
      <div>
        <MilestoneEventsTable />
      </div>
    </div>
  );
};

MilestoneDashboard.propTypes = {
  isPartner: PropTypes.bool,
};

export default MilestoneDashboard;
