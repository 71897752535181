import { connect } from "react-redux";

import { ShipmentReportArrivalDeparture } from "./ShipmentReportArrivalDeparture.page";
import ShipmentReportArrivalDepartureState from "pages/administration/redux/ShipmentReportArrivalDepartureState";

function mapStateToProps(state) {
  return {
    shipmentReportArrivalDepartureData:
      ShipmentReportArrivalDepartureState.selectors.getShipmentReportArrivalDepartureData(
        state,
      ),
  };
}

const { reportArrivalDeparture, clearReportArrivalDeparture } =
  ShipmentReportArrivalDepartureState.actionCreators;

const actionCreators = {
  reportArrivalDeparture,
  clearReportArrivalDeparture,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(ShipmentReportArrivalDeparture);
