/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectFilter from "components/organisms/base-table/Filter/SelectFilter";

const SourceFilter = ({ onChange, value }) => {
  const { t } = useTranslation("reports");

  const reportSourceOptions = [
    { value: "core_report", label: t("reports:Core Reports") },
    { value: "report_builder", label: t("reports:Report Builder") },
  ];

  return (
    <SelectFilter
      placeholder={null}
      value={reportSourceOptions.find((option) => option.value === value)}
      isClearable={true}
      options={reportSourceOptions}
      onChange={(option) => {
        onChange(option?.value);
      }}
    />
  );
};

SourceFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SourceFilter;
