/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { Alert, Image, ListGroup } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { Text, FontSize } from "components/atoms/Text.atom";
import { useEffect } from "react";
import Loader from "react-loader";
import Colors from "styles/colors";

export const PhotoViewer = ({
  fields,
  isLoading,
  submissionId,
  show,
  data,
  vinId,
  fetchPhotoData,
  setShow,
}) => {
  const { t } = useTranslation("damage-view");
  const [categoryIndex, setcategoryIndex] = useState(0);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [imageList, setImageList] = useState([]);

  const photoCategories = useMemo(() => {
    return fields && fields.imageFields
      ? fields.imageFields.map((tag) => {
          return {
            name: tag.fieldLabel,
            category: tag.fieldName,
          };
        })
      : [];
  }, [fields]);

  const availableKeys = useMemo(() => {
    return _.keys(data);
  }, [data]);

  useEffect(() => {
    if (show) {
      fetchPhotoData(vinId, submissionId);
    }
  }, [vinId, submissionId, fetchPhotoData, show]);

  useEffect(() => {
    const internalKeys = [];
    photoCategories.forEach((tag) => {
      if (
        availableKeys?.includes(tag.category) &&
        !internalKeys.includes(tag.category)
      ) {
        internalKeys.push(tag.category);
      }
    });
    setCategoryKeys(internalKeys);
  }, [photoCategories, availableKeys]);

  useEffect(() => {
    if (categoryKeys.length > 0) {
      setImageList(data[categoryKeys[0]]);
    }
  }, [categoryKeys, data]);

  const hideHandler = () => {
    setImageList([]);
    setcategoryIndex(0);
    setShow(false);
  };

  return (
    <Modal size="xl" show={show} backdrop="static" onHide={hideHandler}>
      <Modal.Header
        closeButton={true}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
        }}
        title={
          <div data-qa="title-modal" size={FontSize.size20}>
            {t(
              "damage-view:Photo Viewer (Step [[[categoryIndex]]]/[[[totalCount]]])",
              {
                categoryIndex:
                  categoryKeys.length > 0 ? categoryIndex + 1 : categoryIndex,
                totalCount: categoryKeys.length,
              },
            )}
          </div>
        }
      />

      <Modal.Body>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <Loader loaded={!isLoading} />
          <div
            css={{
              padding: "5px",
              borderBottom: `2px solid ${Colors.border.INNER_GRAY_BORDER}`,
            }}
          >
            <Text size={FontSize.size16}>
              {t("damage-view:VIN")} - {vinId}
            </Text>
          </div>
          {!isLoading && (!data || _.isEmpty(data)) ? (
            <Alert style={{ marginBottom: 0 }} variant={"warning"}>
              {t("damage-view:No Photos found.")}
            </Alert>
          ) : (
            <div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  css={{
                    width: "25%",
                    padding: "10px",
                    border: `3px solid ${Colors.border.INNER_GRAY_BORDER}`,
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ListGroup as="ul">
                      {categoryKeys.map((key, index) => {
                        return (
                          <ListGroup.Item
                            action
                            key={key}
                            as="li"
                            onClick={() => {
                              setcategoryIndex(index);
                              setImageList(data[categoryKeys[index]]);
                            }}
                            active={index === categoryIndex}
                          >
                            {
                              photoCategories.filter((data) => {
                                return data.category === key;
                              })[0]?.name
                            }
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                </div>
                <div
                  css={{
                    width: "75%",
                    padding: "10px",
                    border: `3px solid ${Colors.border.INNER_GRAY_BORDER}`,
                  }}
                >
                  <SimpleBar
                    autoHide={false}
                    css={{
                      ".simplebar-scrollbar::before": {
                        backgroundColor: Colors.text.DARK_GRAY,
                      },
                      maxHeight: "70vh",
                      padding: "10px",
                    }}
                  >
                    <ol>
                      {imageList?.map((image) => {
                        return (
                          <li key={image.url}>
                            <Image
                              src={image.url}
                              alt={categoryKeys[categoryIndex]}
                              thumbnail
                            />
                          </li>
                        );
                      })}
                    </ol>
                  </SimpleBar>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

PhotoViewer.propTypes = {
  fields: PropTypes.object,
  fetchPhotoData: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  submissionId: PropTypes.any,
  show: PropTypes.bool,
  vinId: PropTypes.string,
  setShow: PropTypes.func,
};
