import axios from "axios";
import moment from "moment";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";
import { mapReport } from "pages/reports/utils/report.utils";

const STORE_MOUNT_POINT = "reportsShared";

const FILTERS = [
  {
    queryKey: "description",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "name",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "createdBy",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "reportSource",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
];

const fetchSearch = (queryString = "", _ignored_solutionId, duck, dispatch) => {
  const url = apiUrl(
    `/powerbi/pb_token?directoryCategory=shared_reports&${queryString}`,
  );

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=reports",
    },
  };

  dispatch({
    type: duck.actions.REQUEST,
  });

  return axios
    .get(url, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: {
          ...response.data,
          data: response.data.map((report, i) => {
            return mapReport(report, i);
          }),
        },
      });
    })
    .catch((err) => {
      console.log({ err });
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });
};

const ReportsSharedSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [], // No categories.
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "name",
  },
);

//Selectors
ReportsSharedSearchBarState.selectors = {
  ...ReportsSharedSearchBarState.selectors,
};

export default ReportsSharedSearchBarState;
