import { connect } from "react-redux";

import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";
import { EventInformationStep } from "./EventInformationStep.organism";

const mapStateToProps = (state) => {
  const {
    getNotificationDetailsObj,
    getProduct,
    getEditNotificationRuleApiStatus,
  } = NotificationManagementWizardState.selectors;

  return {
    notificationDetailsObj: getNotificationDetailsObj(state),
    product: getProduct(state),
    getEditNotificationRuleApiStatus: getEditNotificationRuleApiStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setNotificationDetailsObj,
    setIsNextButtonDisabled,
    setProduct,
    clearFilterCriteria,
  } = NotificationManagementWizardState.actionCreators;

  return {
    setProduct: (product) => {
      dispatch(setProduct(product));
    },
    setNotificationDetailsObj: (notificationDetailsObj) => {
      dispatch(setNotificationDetailsObj(notificationDetailsObj));
    },
    setIsNextButtonDisabled: (isNextButtonDisabled) => {
      dispatch(setIsNextButtonDisabled(isNextButtonDisabled));
    },
    clearFilterCriteria: () => {
      dispatch(clearFilterCriteria());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventInformationStep);
