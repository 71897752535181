import convert from "convert-units";
import { BrowserStorage } from "./browser-storage.utils";

export const getMeasurementUnitsFromUserPreferences = () => {
  let measurementUnit = JSON.parse(BrowserStorage.measurementUnit);
  if (!measurementUnit) {
    measurementUnit = DEFAULT_MEASUREMENT_UNIT;
  }
  return measurementUnit;
};

export const setMeasurementUnits = (unitsOfMeasurement) => {
  BrowserStorage.measurementUnit = JSON.stringify(unitsOfMeasurement);
};

const DEFAULT_MEASUREMENT_UNIT = {
  system: "Imperial",
  mass: "lb",
  pressure: "psi",
  volume: "gal",
  distance: "mi",
  temperature: "F",
};

//standard units for conversion
const STANDARD_UNIT = {
  KPA: "kPa", //standard unit for KILOPASCAL
  PSI: "psi",
  LB: "lb",
  KG: "kg",
  GAL: "gal",
  L: "l",
  KM: "km",
  MI: "mi",
  C: "C",
  F: "F",
};

const getMeasurementType = (unit) => {
  const map = {
    lb: "mass",
    kg: "mass",
    kPa: "pressure",
    psi: "pressure",
    gal: "volume",
    l: "volume",
    km: "distance",
    mi: "distance",
    C: "temperature",
    F: "temperature",
  };
  return map[unit];
};

export const convertMeasurementUnits = ({ unit, value }) => {
  let currentUnit = STANDARD_UNIT[unit.toUpperCase()] ?? unit;

  const currentValue = value;

  const measurement = getMeasurementType(currentUnit);
  if (measurement === undefined) {
    return { preferredUnit: unit, convertedValue: value };
  }
  const preferences = getMeasurementUnitsFromUserPreferences();
  let preferredUnit = preferences[measurement];
  preferredUnit = STANDARD_UNIT[preferredUnit.toUpperCase()];
  let convertedValue = convert(currentValue)
    .from(currentUnit)
    .to(preferredUnit);

  if (convertedValue) {
    let decimalValue = Number.parseFloat(convertedValue);
    convertedValue = hasDecimalPlaces(decimalValue)
      ? decimalValue.toFixed(2)
      : convertedValue;
  }

  return { preferredUnit, convertedValue };
};

const hasDecimalPlaces = (num) => {
  return num % 1 !== 0;
};
