/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import { Text } from "components/atoms/Text.atom";
import LogoMark from "assets/logos/fv_mark.svg";
import Colors from "styles/colors";

export const TimelineMad = ({
  progress,
  mode,
  hideBorder,
  showTooltip,
  isPlannedLegFvGenerated,
}) => {
  const { t } = useTranslation("fv-vin-details");

  const MAD_HEIGHT = 22;

  // Show fake leg's "progress" in the middle of the its timeline.
  if (isPlannedLegFvGenerated) {
    progress = 50;
  }

  const positionStyles = {
    position: "absolute",
  };

  // Determine the offset from the leg's origin to the next destination.
  if (progress < 100) {
    // Case: Leg has not reached its destination.
    // - MAD will appear directly below the origin LAD.

    // Calculate top value based on progress.
    positionStyles.top = `${progress}%`;
  } else if (progress === 100) {
    // Case: Leg has reached its destination (i.e. completed leg).
    // - MAD will appear directly above the destination LAD.
    positionStyles.bottom = 0;
  }

  if (progress > 0 && progress < 100) {
    // Case: Leg has started but is not complete.
    // - MAD will appear somewhere between the origin and destination.

    // For progresses that have started but have not made it to the destination yet,
    // move up half the height of the MAD so it's perfectly centered.
    // Note: We have already applied the `top` rule in if statement above; i.e. `if (progress < 100)`
    positionStyles.transform = "translateY(-50%)";
  }

  if (isPlannedLegFvGenerated) {
    const tooltipText = t(
      "fv-vin-details:This trip leg is generated by FreightVerify.",
    );

    return (
      <Tooltip
        tooltipChildren={<Text>{tooltipText}</Text>}
        style={{
          borderRadius: "50%",
          background: Colors.background.LIGHT_GRAY,
          border: "3px solid grey",
          ...positionStyles,
        }}
      >
        <Icon
          src={LogoMark}
          type={IconType.LocalImage}
          style={{ height: MAD_HEIGHT, width: MAD_HEIGHT }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      tooltipChildren={showTooltip ? <Text>{mode}</Text> : null}
      placement="top"
      style={positionStyles}
    >
      <MadChiclet
        shipmentMode={mode}
        stopMode={mode}
        activeException={null}
        hideBorder={hideBorder}
        height={MAD_HEIGHT}
        width={22}
        style={showTooltip ? undefined : positionStyles}
      />
    </Tooltip>
  );
};

TimelineMad.propTypes = {
  progress: PropTypes.number,
  mode: PropTypes.string,
  hideBorder: PropTypes.bool,
  showTooltip: PropTypes.bool,
  isPlannedLegFvGenerated: PropTypes.bool,
};
