/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "components/molecules/Modal.molecule";

import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import Colors from "styles/colors";

const useMilestoneReferencesColumns = () => {
  const { t } = useTranslation("documentation");

  return [
    {
      Header: t("documentation:Name"),
      Cell: (cell) => {
        return <Text block>{cell.row.original.qualifier}</Text>;
      },
    },
    {
      Header: t("documentation:Value"),
      disableResizing: true,
      Cell: (cell) => {
        return <Text block>{cell.row.original.value}</Text>;
      },
    },
  ];
};

export const MilestoneReferencesButton = ({ vin, references = [] }) => {
  const { t } = useTranslation("documentation");
  const [showModal, setShowModal] = useState(false);

  const columns = useMilestoneReferencesColumns();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Button
        variant="link"
        data-qa="show-milestone-references"
        onClick={() => setShowModal(true)}
        style={{
          color: "black",
          fontWeight: 1600,
          textDecoration: "underline",
          padding: 0,
        }}
      >
        {t("documentation:[[[count]]] References", {
          count: references?.length ?? 0,
        })}
      </Button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header
          closeButton
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            color: Colors.background.DARK_BLUE,
            padding: "0.5rem 0.75rem 0.5rem 0.5rem",
          }}
          title={t("fv-vin-details:Milestone References for [[[vin]]]", {
            vin,
          })}
        />
        <Modal.Body>
          <BaseTable
            data-qa="name-value-table"
            theme={Themes.LIGHT}
            data={references}
            columns={columns}
            pageSize={references?.length > 20 ? references.length : 20}
            disableSortBy={true}
            defaultSortColumn="label"
            defaultReverseSort={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

MilestoneReferencesButton.propTypes = {
  vin: PropTypes.string,
  references: PropTypes.array,
};
