/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { documentationApiUrl } from "api-url";
import ReactHtmlParser from "react-html-parser";

const htmlCss = {
  width: "100%",
  height: "100%",
};

const ContentHtml = (props) => {
  const { content } = props;
  const documentationUrl = documentationApiUrl("");
  if (!content) {
    return null;
  }

  /*The S3 bucket URL must be passed to all HTML files via a string replace.
  This must be done before parsing the string into HTML below.
  */

  const updatedContent = _.replace(
    content,
    "{{documentationUrl}}",
    documentationUrl,
  );
  return <div css={htmlCss}>{ReactHtmlParser(updatedContent)}</div>;
};

ContentHtml.propTypes = {
  content: PropTypes.string,
};
export default ContentHtml;
