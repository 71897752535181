// Displays a table of Watched VINs, and allows user to watch or unwatch each VIN

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import { useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";
import Colors from "styles/colors";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import VinViewEntityDetailsState from "../../../redux/VinViewEntityDetailsState";
import { localizedDateFormatter } from "utils/date-time";

export const WatchedVins = (props) => {
  const {
    watchedVins,
    isWatchedVinsLoading,
    watchedVinsPageIndex,
    watchedVinsPageSize,
    watchedVinsPageCount,
    setWatchedVinsPageIndex,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    isDealerOrg,
    selectedDealerOrgId,
  } = props;
  const { t } = useTranslation("fv-dashboard");

  const [unwatchedVins, setUnwatchedVins] = useState([]);
  let unwatchTimers = {};

  const toggleWatch = (vin) => {
    let newUnwatchedVins = [...unwatchedVins];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[vin]) {
      clearTimeout(unwatchTimers[vin]);
    }

    if (newUnwatchedVins.includes(vin)) {
      // VIN checkbox has already been unchecked - re-watch it
      newUnwatchedVins = newUnwatchedVins.filter((v) => v !== vin);
    } else {
      // VIN checkbox is checked - unwatch it
      newUnwatchedVins.push(vin);
    }

    setUnwatchedVins(newUnwatchedVins);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[vin] = setTimeout(() => {
      fetchWatchedVins(isDealerOrg, selectedDealerOrgId);
      fetchWatchedEntitiesTotalPages(isDealerOrg, selectedDealerOrgId);
    }, 2000);
  };

  // Render unchecked VIN rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  const CellRenderer = (cellInfo) => {
    const dim = unwatchedVins.includes(cellInfo.row.original.id);
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = (sortKey, sortDir) => {
    return [
      {
        Header: t("fv-dashboard:Watch"),
        accessor: "watch",
        width: 50,
        disableSortBy: true,
        disableResizing: true,
        centerAligned: true,
        Cell: (cellInfo) => {
          const dispatch = useDispatch();
          const vin = cellInfo.row.original.id;
          const internalId = cellInfo.row.original.internal_id;
          // API returns boolean in a string
          const watched = cellInfo.value === "true";
          return (
            <WatchToggle
              key={vin}
              checked={watched ?? false}
              onChange={(newWatchValue) => {
                dispatch(
                  VinViewEntityDetailsState.actionCreators.setWatchEntity(
                    null, // setWatchEntity needs solutionId as first parameter until VinViewDetails is fully completed.
                    internalId,
                    newWatchValue,
                    selectedDealerOrgId,
                  ),
                );
                toggleWatch(vin);
              }}
              iconSize={FontSize.size24}
              color={Colors.nav.NAVY}
              checkedColor={Colors.highlight.YELLOW}
            />
          );
        },
      },
      {
        Header: t("fv-dashboard:VIN"),
        accessor: "id",
        Cell: (cellInfo) => {
          const dim = unwatchedVins.includes(cellInfo.row.original.id);

          return (
            <div
              style={{
                opacity: cellOpacity(dim),
              }}
            >
              {cellInfo.row.original.commentsCount &&
              cellInfo.row.original.commentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>{t("fv-vin-search:This VIN contains comments")}</Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    color={Colors.comments.unreadCommentIcon}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
              ) : null}
              <Text bold>{cellInfo.row.original.id}</Text>
            </div>
          );
        },
      },
      {
        Header: t("fv-dashboard:Product Type"),
        accessor: "description",
        Cell: CellRenderer,
      },
      {
        Header: t("fv-dashboard:ETA"),
        accessor: "eta",
        Cell: (cellInfo) => {
          const { getEtaTranslation, isEtaName } = useEtaTranslations();
          const lastEntityProgressUpdate =
            cellInfo.row.original.lastEntityProgressUpdate;

          let eta = lastEntityProgressUpdate?.destinationEta;
          let etaTranslated = false;
          if (eta === "Pending Dispatch") {
            let manualEtaRangeStart =
              cellInfo.row.original?.manualEtaRangeStart;
            let manualEtaRangeEnd = cellInfo.row.original?.manualEtaRangeEnd;
            etaTranslated = cellInfo.row.original?.manualEtaRangeStart
              ? t(
                  "fv-dashboard:Pending Dispatch - Estimated Delivery [[[fromMonth]]] - [[[toMonthYear]]]",
                  {
                    fromMonth: moment.utc(manualEtaRangeStart).format("MMM"),
                    toMonthYear: moment
                      .utc(manualEtaRangeEnd)
                      .format("MMM YYYY"),
                  },
                )
              : null;
          }

          const status = cellInfo.row.original.lifeCycleState;

          if (status === "Delivered" && cellInfo.row.original.inTransit) {
            return t("fv-dashboard:Delivered");
          } else if (etaTranslated) {
            return etaTranslated;
          } else if (!eta) {
            return null;
          }

          if (isEtaName(eta)) {
            return getEtaTranslation(eta);
          } else if (
            lastEntityProgressUpdate?.destinationEtaWindow &&
            lastEntityProgressUpdate?.destinationEta !==
              lastEntityProgressUpdate?.destinationEtaEnd
          ) {
            const parsedEta = JSON.parse(
              lastEntityProgressUpdate?.destinationEtaWindow,
            );
            const etaElement = `${localizedDateFormatter(parsedEta[0])} -
                ${localizedDateFormatter(parsedEta[1])}`;
            return etaElement;
          }

          return <DateTimeCell dateTime={eta} stack localize />;
        },
      },
    ];
  };

  const rowClickHandler = (row, cell) => {
    const { pushVinViewDetailsPage } = props;

    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    pushVinViewDetailsPage(row.original.internal_id, selectedDealerOrgId);
  };

  return (
    <div css={{ position: "relative", minHeight: 54 }}>
      <BaseTable
        data={watchedVins}
        columns={tableColumns()}
        isLoading={isWatchedVinsLoading}
        rowClickHandler={rowClickHandler}
        showPagination={true}
        showPageSizeDropdown={false}
        isManualPagination={true}
        pageCount={watchedVinsPageCount}
        pageSize={watchedVinsPageSize}
        pageIndex={watchedVinsPageIndex}
        onPageChange={(newPage) => {
          setWatchedVinsPageIndex(newPage);
        }}
      />
    </div>
  );
};

WatchedVins.propTypes = {
  watchedVins: PropTypes.array,
  isWatchedVinsLoading: PropTypes.bool,
  fetchWatchedVins: PropTypes.func.isRequired,
  fetchWatchedEntitiesTotalPages: PropTypes.func.isRequired,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  pushVinViewDetailsPage: PropTypes.func.isRequired,
  isDealerOrg: PropTypes.bool,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushVinViewDetailsPage: (internalId, dealerOrgId = null) => {
      if (dealerOrgId) {
        dispatch({
          type: "VINVIEW_SHIPPER_DETAILS",
          payload: { internal_id: internalId, dealerOrgId },
        });
      } else {
        dispatch({
          type: "VINVIEW_DETAILS",
          payload: { internal_id: internalId },
        });
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(WatchedVins);
