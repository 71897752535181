import {
  getEverythingQueryString,
  getBasicQueryString,
  getMultiSelectQueryString,
  getEntityDateRangeQueryString,
  getNQueryStringFilterValuePriority,
} from "components/search-bar/search-filter-query-strings";

import {
  DateRangeFilterButton,
  AsyncSelectFilterButton,
  NFilterButton,
  SelectFilterButton,
} from "components/search-bar/FilterButtons";

import {
  productTypeOptionsState,
  destinationDealerOptionsState,
  currentLocationOptionsState,
  currentPositionsCodeOptionsState,
} from "./InventoryView.Details.SearchFilterLoaders";

import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";

export const INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: () => null,
    placeholder: (t) => t("inventory-view:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const INVENTORY_VIEW_DETAILS_FILTERS = [
  {
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "shippability",
    label: (t) => t("inventory-view:Shippability"),
    Component: SelectFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Shippable"),
          value: "shippable",
        },
        {
          label: t("inventory-view:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "forecastedPickupDate",
    label: (t) => t("inventory-view:Forecasted Pickup"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    dateTypeOptions: () => [],
    queryBuilder: getEntityDateRangeQueryString,
  },
  {
    queryKey: "destinationId",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Destination Dealer"),
    optionsState: destinationDealerOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "currentGeofence",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Current Location Geofence"),
    optionsState: currentLocationOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "dwell",
    label: (t) => t("inventory-view:Dwell"),
    Component: SelectFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Aging"),
          value: "aging",
        },
        {
          label: t("inventory-view:Dwelling"),
          value: "dwell",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      filterValue ? getBasicQueryString(filterValue, "0") : "",
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
];

export const FORECASTED_VINS_FILTERS = [
  {
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "shippability",
    label: (t) => t("inventory-view:Shippability"),
    Component: SelectFilterButton,
    hideSelectEmpty: true,
    hideFuzzySearch: true,
    hideSelectAll: true,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Shippable"),
          value: "shippable",
        },
        {
          label: t("inventory-view:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "forecastedArrivalDate",
    label: (t) => t("inventory-view:Forecasted Arrival"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString,
    dateTypeOptions: (t) => [
      { label: t("inventory-view:Scheduled"), value: "Scheduled" },
      {
        label: t("inventory-view:ETA"),
        value: "ETA",
      },
    ],
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "forecastedPickupDate",
    label: (t) => t("inventory-view:Forecasted Pickup"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    dateTypeOptions: () => [],
    queryBuilder: getEntityDateRangeQueryString,
  },
  {
    queryKey: "destinationId",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Destination Dealer"),
    optionsState: destinationDealerOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("fv-vin-search:Current Location"),
    Component: NFilterButton,
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: true,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        currentPositionTypes: props.currentPositionTypes ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
];
