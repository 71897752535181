/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { useApiLogs } from "./ApiLogsHooks";
import { getColumns } from "./ApiLogsTableColumns";
import { getExpandedRow } from "./ApiLogsTableExpandedRow";

const tableCss = {
  ".rt-td.rt-expandable": {
    paddingLeft: "5px !important",
  },
};

// Search count and results table displayed on API Logs view
const ApiLogsSearchResults = ({ pushShipmentDetailView }) => {
  const {
    t,
    auth,
    solutionId,
    searchResults,
    isLoading,
    page,
    pageSize,
    totalPages,
    defaultSortColumn,
    defaultReverseSort,
    sortColumn,
    reverseSort,
    setPagination,
    setSort,
  } = useApiLogs();

  const columns = getColumns(t, auth, pushShipmentDetailView);
  const expandedRow = (row) => getExpandedRow(row, t);

  return (
    //H1-2810 specifying class name here to hide Pagination for api logs. workaround until react table 7
    <div className="api-logs" css={tableCss}>
      <BaseTable
        isLoading={isLoading}
        data={searchResults}
        columns={columns}
        showPagination={true}
        fixPaginationToBottom={true}
        isManualPagination={true}
        isManualSorting={true}
        pageIndex={page}
        showPageSizeDropdown={true}
        pageSize={pageSize}
        pageCount={totalPages}
        defaultSortColumn={defaultSortColumn}
        defaultReverseSort={defaultReverseSort}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        onPageChange={(newPage) => {
          setPagination(solutionId, newPage, pageSize);
        }}
        onPageSizeChange={(newPageSize) => {
          setPagination(solutionId, 0, newPageSize);
        }}
        onPageSort={(column, isReverse) => {
          setSort(solutionId, column, isReverse);
        }}
        hideTotalPages={true}
        subComponent={expandedRow}
      />
    </div>
  );
};

ApiLogsSearchResults.propTypes = {
  pushShipmentDetailView: PropTypes.func,
};

export default ApiLogsSearchResults;
