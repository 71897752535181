/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MediaQueries } from "components/responsive";
import { getIconData } from "../utils/exceptions.utils";
import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "../components/search/VinView.SearchBar.container";
import FiltersContainer from "../components/search/VinView.SearchFilters.container";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import WatchedVins from "./components/organisms/VinView.WatchedVins.organism";
import { VinViewSavedSearchesPanel } from "./components/organisms/VinView.SavedSearchesPanel.organism";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const VinViewDashboard = ({
  entityCount,
  isEntityCountLoading,
  newEntityCount,
  isNewEntityCountLoading,
  entityDeliveredCount,
  isEntityDeliveredCountLoading,
  watchedVins,
  isWatchedVinsLoading,
  watchedVinsPageIndex,
  watchedVinsPageSize,
  watchedVinsPageCount,
  setWatchedVinsPageIndex,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  exceptionTypes,
  resetSearchBar,
  clearSearchFilters,
  fetchEntityCount,
  fetchNewEntityCount,
  fetchEntityDeliveredCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  resetSavedSearch,
  isDealerOrg,
  selectedDealerOrgId,
}) => {
  const { t } = useTranslation("vinview-dashboard");

  useSetTitleOnMount(t("vinview-dashboard:VINView"));
  useSetDescriptionOnMount(
    t("vinview-dashboard:All VINs for your organization and their related ETA"),
  );

  useTrackWithMixpanelOnce("Viewed Page: VINView / Dashboard");

  // If we aren't a dealer, show the filters by defualt.
  const [showFilters, setShowFilters] = useState(!isDealerOrg);

  // Keep track of if we had a first render yet or not (calling this "mounted").
  // This is used to prevent data fetching before it's needed.
  const [didMount, setDidMount] = useState(false);

  // We can fetch data if:
  // - the user is logged in as a dealer, or
  // - this component already mounted and has a dealer org selected
  const canFetchData =
    isDealerOrg || (didMount && !isDealerOrg && !_.isNil(selectedDealerOrgId));

  useEffect(
    () => {
      resetSearchBar();
      clearSearchFilters();
      resetSavedSearch();

      // Marking this component as "mounted". Now we can request data for non-dealer users.
      setDidMount(true);

      // Fetch data for dealer users on mount.
      if (canFetchData) {
        fetchEntityCount();
        fetchNewEntityCount();
        fetchEntityDeliveredCount();
        fetchWatchedVins();
        fetchWatchedEntitiesTotalPages();
      }
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  // Fetch data for corporate users once an org is selected.
  useEffect(() => {
    // Note: Only for non-dealer users:
    // - These fetches should only happen after we have mounted and selected a dealer org from the filter.
    // - The effect above clears the search filters but that doesn't immediately update in the same render.
    //   This forces us to wait until the next render to get the cleared filters.
    // - If we had a dealer org selected already (selected dealer org, go to another screen and come back to this dashboard),
    //   we would fetch data based on that value. But instead, we want the user to reselect the dealer.
    if (canFetchData) {
      fetchEntityCount(isDealerOrg, selectedDealerOrgId);
      fetchNewEntityCount(isDealerOrg, selectedDealerOrgId);
      fetchEntityDeliveredCount(isDealerOrg, selectedDealerOrgId);
      fetchWatchedVins(isDealerOrg, selectedDealerOrgId);
      fetchWatchedEntitiesTotalPages(isDealerOrg, selectedDealerOrgId);
    }
  }, [
    canFetchData,
    isDealerOrg,
    selectedDealerOrgId,
    fetchEntityCount,
    fetchNewEntityCount,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    fetchEntityDeliveredCount,
  ]);

  useEffect(() => {
    if (canFetchData) {
      fetchWatchedVins(isDealerOrg, selectedDealerOrgId);
      fetchWatchedEntitiesTotalPages(isDealerOrg, selectedDealerOrgId);
    }
  }, [
    watchedVinsPageIndex,
    isDealerOrg,
    selectedDealerOrgId,
    canFetchData,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
  ]);

  const handleClickException = (e) => {
    let searchableValue = [];
    if (e && e.name === t("vinview-dashboard:Delivered")) {
      clearSearchFilter();
      setSearchFilter("lifeCycleState", ["Delivered"]);
      searchEntities();
    } else {
      if (e && e.reason_code) {
        searchableValue.push(
          _.find(exceptionTypes, { reasonCode: e.reason_code }).id,
        );
      } else {
        exceptionTypes.forEach((exceptionType) => {
          searchableValue.push(exceptionType.id);
        });
      }

      clearSearchFilter();
      setSearchFilter("exception", searchableValue);
      setSearchFilter("lifeCycleState", ["Active"]);
      setSearchFilter("isNew", "all_active");
      searchEntities();
    }
  };

  const handleActiveChartClick = () => {
    clearSearchFilter();
    setSearchFilter("lifeCycleState", ["Active"]);
    setSearchFilter("isNew", "all_active");
    searchEntities();
  };

  const handleNewActiveChartClick = () => {
    clearSearchFilter();
    setSearchFilter("lifeCycleState", ["Active"]);
    setSearchFilter("isNew", "new_active");
    searchEntities();
  };

  const exceptionGroups = [
    {
      title: t("vinview-dashboard:Delivered Last 30 Days"),
      includeInDonutChart: false,
      exceptions: [
        {
          name: t("vinview-dashboard:Delivered"),
          count: entityDeliveredCount ?? 0,
          icon: {
            ...getIconData("Delivered"),
          },
        },
      ],
      isLoading: isEntityDeliveredCountLoading,
    },
  ];

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      {!isDealerOrg && _.isNil(selectedDealerOrgId) ? (
        <Alert variant={AlertVariant.Warning} show>
          {t(
            "vinview-dashboard:Select a Dealer Organization from the filter above to continue.",
          )}
        </Alert>
      ) : null}
      {/* If we can fetch data, it will be fetched by the effects.
       * We need to then show the UI for that data (dashboard widgets).
       */}
      {canFetchData ? (
        <div
          css={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(12, 1fr)",
              gridTemplateRows: "1fr",
              gridAutoFlow: "row",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "1 / span 5",
              },
            }}
          >
            <ExceptionsPanel
              title={t("vinview-dashboard:Finished Vehicle VINs")}
              exceptionGroups={exceptionGroups}
              handleClickException={handleClickException}
              totalCountChartElement={
                <Fragment>
                  {/* Regular Active VINs chart. */}
                  <ExceptionsPanel.Chart
                    exceptionGroups={exceptionGroups}
                    count={entityCount}
                    countIsLoading={isEntityCountLoading}
                    countLabel={t("vinview-dashboard:Active")}
                    onClick={handleActiveChartClick}
                  />
                  {/* New Active VINs chart. */}
                  <ExceptionsPanel.Chart
                    count={newEntityCount}
                    countIsLoading={isNewEntityCountLoading}
                    countLabel={t("vinview-dashboard:New Active")}
                    onClick={handleNewActiveChartClick}
                  />
                </Fragment>
              }
            />
          </div>
          <div
            css={{
              marginBottom: "20px",
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumnStart: 6,
                gridColumnEnd: -1, // Span until the end
              },
            }}
          >
            <WatchedVins
              fetchWatchedVins={fetchWatchedVins}
              fetchWatchedEntitiesTotalPages={fetchWatchedEntitiesTotalPages}
              watchedVins={watchedVins}
              watchedVinsPageIndex={watchedVinsPageIndex}
              watchedVinsPageSize={watchedVinsPageSize}
              watchedVinsPageCount={watchedVinsPageCount}
              isWatchedVinsLoading={isWatchedVinsLoading}
              setWatchedVinsPageIndex={setWatchedVinsPageIndex}
              isDealerOrg={isDealerOrg}
              selectedDealerOrgId={selectedDealerOrgId}
            />
          </div>
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 1,
                gridColumnEnd: -1,
              },
            }}
          >
            <VinViewSavedSearchesPanel
              isDealerOrg={isDealerOrg}
              selectedDealerOrgId={selectedDealerOrgId}
            />
          </div>
        </div>
      ) : null}
    </Dashboard>
  );
};

VinViewDashboard.propTypes = {
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  entityCount: PropTypes.number,
  isEntityCountLoading: PropTypes.bool,
  newEntityCount: PropTypes.number,
  isNewEntityCountLoading: PropTypes.bool,
  fetchWatchedVins: PropTypes.func.isRequired,
  fetchWatchedEntitiesTotalPages: PropTypes.func.isRequired,
  watchedVins: PropTypes.array,
  isWatchedVinsLoading: PropTypes.bool,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  entityDeliveredCount: PropTypes.number,
  exceptionTypes: PropTypes.array,
  fetchEntityCount: PropTypes.func.isRequired,
  fetchNewEntityCount: PropTypes.func.isRequired,
  fetchEntityDeliveredCount: PropTypes.func.isRequired,
  isEntityDeliveredCountLoading: PropTypes.bool,
  holdTypes: PropTypes.any,
  resetSavedSearch: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  searchEntities: PropTypes.any,
  setSearchFilter: PropTypes.func.isRequired,
  solutionId: PropTypes.any,
  isDealerOrg: PropTypes.bool,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
