import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import apiUrl from "api-url";

const VinViewSubscriptionState = buildSubscriptionState({
  topic: "VinView",
  systemType: "entity",
  getSubscribeeId: (subscribee) => subscribee.internal_id,
  getUrl: (id) => apiUrl(`/entity/internal/${id}`),
  getAdditionalRequestConfig: (requestType, subscribee, state) => {
    const config = {};

    const dealerOrgId = state.location.payload.dealerOrgId;
    if (dealerOrgId) {
      config.params = {};
      config.params.dealerOrgId = dealerOrgId;
    }

    return config;
  },
});

export default VinViewSubscriptionState;
