/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { DonutChart } from "components/molecules/DonutChart.molecule";

// Utils
import { getExceptionChartData } from "components/utils/exceptions.utils";
import Colors from "styles/colors";

// State
import { getSolutionId } from "modules/organizations/OrganizationsState";
import DriveAwaySearchBarState from "../../../redux/DriveAwaySearchBarState";
import DriveAwaySavedSearchState from "../../../redux/DriveAwaySavedSearchState";
import DriveAwaySavedSearchCardsState from "../../../redux/DriveAwaySavedSearchCardsState";
import { DPUStatus } from "pages/driveaway/utils/enums.utils";

const { searchEntities, setSearchFilter } =
  DriveAwaySearchBarState.actionCreators;
const { loadSavedSearch } = DriveAwaySavedSearchState.actionCreators;

export const DriveAwaySavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
}) => {
  const { t } = useTranslation("driveaway-dashboard");
  const dispatch = useDispatch();

  const solutionId = useSelector(getSolutionId);
  const savedSearchData = useSelector(
    DriveAwaySavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    dispatch(
      DriveAwaySavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
      ),
    );
  }, [dispatch, savedSearch]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities(solutionId));
  };

  let loadAvailableSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("ddaStatus", [DPUStatus.AVAILABLE]));
    dispatch(searchEntities(solutionId));
  };

  let loadSubmittedSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("ddaStatus", [DPUStatus.SUBMITTED]));
    dispatch(searchEntities(solutionId));
  };

  let loadApprovedSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("ddaStatus", [DPUStatus.APPROVED]));
    dispatch(searchEntities(solutionId));
  };

  let loadDeniedSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("ddaStatus", [DPUStatus.DENIED]));
    dispatch(searchEntities(solutionId));
  };

  if (
    savedSearch.search.ddaStatus &&
    savedSearch.search.ddaStatus?.length !== 0
  ) {
    // Prevent Available search when the user does not set status to Available
    if (!savedSearch.search.ddaStatus?.includes(DPUStatus.AVAILABLE)) {
      loadAvailableSavedSearch = null;
    }

    // Prevent Submitted search when the user does not set status to Submitted
    if (!savedSearch.search.ddaStatus?.includes(DPUStatus.SUBMITTED)) {
      loadSubmittedSavedSearch = null;
    }

    // Prevent Approved search when the user does not set status to Approved
    if (!savedSearch.search.ddaStatus?.includes(DPUStatus.APPROVED)) {
      loadApprovedSavedSearch = null;
    }

    // Prevent Denied search when the user does not set status to Denied
    if (!savedSearch.search.ddaStatus?.includes(DPUStatus.DENIED)) {
      loadDeniedSavedSearch = null;
    }
  }

  const availableChartData = getExceptionChartData([], data?.availableCount, 0);
  const submittedChartData = getExceptionChartData([], data?.submittedCount, 0);
  const approvedChartData = getExceptionChartData([], data?.approvedCount, 0);
  const deniedChartData = getExceptionChartData([], data?.deniedCount, 0);

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "flex-start",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        <DonutChart
          data={availableChartData}
          handler={loadAvailableSavedSearch}
          totalLabel={t("driveaway-dashboard:Available")}
          total={data?.availableCount}
          fillColor={data?.availableCount > 0 ? Colors.donuts.BLUE : null}
        />
        <DonutChart
          data={submittedChartData}
          handler={loadSubmittedSavedSearch}
          totalLabel={t("driveaway-dashboard:Submitted")}
          total={data?.submittedCount}
          fillColor={data?.submittedCount > 0 ? Colors.donuts.YELLOW : null}
        />
        <DonutChart
          data={approvedChartData}
          handler={loadApprovedSavedSearch}
          totalLabel={t("driveaway-dashboard:Approved")}
          total={data?.approvedCount}
          fillColor={data?.approvedCount > 0 ? Colors.donuts.GREEN : null}
        />
        <DonutChart
          data={deniedChartData}
          handler={loadDeniedSavedSearch}
          totalLabel={t("driveaway-dashboard:Denied")}
          total={data?.deniedCount}
          fillColor={data?.deniedCount > 0 ? Colors.donuts.RED : null}
        />
      </div>
    </SavedSearchPanel>
  );
};

DriveAwaySavedSearch.propTypes = {
  // These come from the getCardProps in FinishedVehicleSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};
