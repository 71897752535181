import { connect } from "react-redux";

import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "../../../redux/DriveAwayVinHistorySearchBarState";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getVins,
    getVinsLoading,
  } = SearchBarState.selectors;

  return {
    auth: getAuthorization(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    solutionId: getSolutionId(state),
    vins: getVins(state),
    vinsLoading: getVinsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchEntities,
    clearSavedSearch,
    searchVins,
  } = SearchBarState.actionCreators;

  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) =>
      dispatch(setSearchText(text, ignoreValueChange)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: (solutionId, resetPagination, preventRedirect) =>
      dispatch(searchEntities(solutionId, resetPagination, preventRedirect)),
    searchVins: (query) => dispatch(searchVins(query)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  isShowingAdvancedSearchButton: false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SearchBar);
