import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getEverythingQueryString,
  getEntityDateRangeQueryString,
  getEmptyQueryString,
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
} from "components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  BatchFilterButton,
  NFilterButton,
  AsyncSelectFilterButton,
} from "components/search-bar/FilterButtons";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  currentPositionsCodeOptionsState,
  productTypeOptionsState,
} from "pages/carrierview/redux/CarrierViewSearchFilterLoaders";
import {
  batchCsvVinExample,
  batchCsvProductTypeExample,
} from "components/search-bar/batch-comment-csv-data";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("carrierview-search:Search Everything"),
    placeholder: (t) => {
      return t("carrierview-search:Search VIN, Order #, or Product Type");
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "entityId",
    label: (t) => t("carrierview-search:VIN"),
    placeholder: (t) => t("carrierview-search:Search VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
  },
  {
    queryKey: "ref:OrderNumber:1",
    label: (t) => t("carrierview-search:Order #"),
    placeholder: (t) => t("carrierview-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    property: "orderNumbers",
  },
  {
    // H2-305 productType is now descriptionSearch for the search bar
    queryKey: "descriptionSearch",
    label: (t) => t("carrierview-search:Product Type"),
    placeholder: (t) => t("carrierview-search:Search Product Type"),
    queryBuilder: getBasicQueryString,
    property: "productTypes",
  },
  {
    queryKey: "altKey",
    label: (t) => t("carrierview-search:Last 8 of VIN"),
    placeholder: (t) => t("carrierview-search:Search Last 8 of VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
    minCharacters: 8,
    maxCharacters: 8,
  },
];

export const FILTERS = [
  {
    queryKey: "ref:OrderType:0",
    label: (t) => t("carrierview-search:Order Type"),
    optionsGetter: (props) => props.orderTypeFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "shipper",
    label: (t) => t("carrierview-search:Shipper"),
    optionsGetter: (props) => props.shipperFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  // TODO: re-add internal space to the "XxType" filter labels once it won't cause
  // a line break in the widget
  // (this already seems to work in the shipment filters)
  {
    // H2-305 for the filters producttype is now called description
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("carrierview-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "lifeCycleState",
    label: (t) => t("carrierview-search:VIN Status"),
    optionsGetter: (props) => props.lifeCycleStateFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "exceptionType",
    label: (t) => t("carrierview-search:VIN Exception"),
    optionsGetter: (props, t) => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionTypeFilterOptions.map((item) => {
        item.label = t(`exceptions:${item.label}`);
        return item;
      });
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicWithStaticOptionsQueryString("exception", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("carrierview-search:Current Location"),
    Component: NFilterButton,
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        currentPositionTypes: props.currentPositionTypes ?? "",
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("carrierview-search:Delivery Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString,
    dateTypeOptions: (t) => [
      {
        label: t("carrierview-search:Actual"),
        value: "Actual",
        default: true,
      },
    ],
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "batch",
    label: (t) => t("carrierview-search:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("carrierview-search:VIN"),
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
        {
          label: t("carrierview-search:Product Type"),
          value: "description",
          batchCsvExample: batchCsvProductTypeExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
];
