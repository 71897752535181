import { connect } from "react-redux";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import { ShipmentsDashboard } from "./Shipments.Dashboard.page";

function mapStateToProps(state) {
  return {
    currentOrganizationId: state.organizations.currentOrganizationId,
    exceptionTotals: ShipmentsStatusState.selectors.getExceptionTotals(state),
    isExceptionTotalsLoaded:
      ShipmentsStatusState.selectors.getExceptionTotalsIsLoaded(state),
    exceptionTypes: ShipmentsDomainDataState.selectors.getExceptionTypes(state),
    showShipmentLiveDwellWidget: getAuthorization(
      state,
    ).validateEntitySystemConfigValue([
      { key: "SHOW_DWELL_WIDGET", value: "true" },
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchInboundShipmentTotals: () =>
      dispatch(
        ShipmentsStatusState.actionCreators.fetchInboundShipmentTotals(),
      ),
    resetSearch: () =>
      dispatch(ShipmentsSearchBarState.actionCreators.resetSearchBar()),
    clearSearchFilters: () =>
      dispatch(ShipmentsSearchBarState.actionCreators.clearSearchFilters()),
    setSearchFilter: (filterKey, value) =>
      dispatch(
        ShipmentsSearchBarState.actionCreators.setSearchFilter(
          filterKey,
          value,
        ),
      ),
    searchEntities: () =>
      dispatch(ShipmentsSearchBarState.actionCreators.searchEntities()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsDashboard);
