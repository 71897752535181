/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import {
  PhoneNumberInput,
  isPossiblePhoneNumber,
} from "components/molecules/PhoneInput.molecule";
import AuthenticationUtils from "modules/auth/authentication";
import { ProfileState } from "modules/profile/ProfileState";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { MediaQueries } from "components/responsive";
import { isValidEmail } from "utils/validation-utils";
import Colors from "styles/colors";
import { MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH } from "shared/redux/SubscriptionStateBuilder";

const MODES = {
  SAVE: "save",
  UPDATE: "update",
};

export const AlertMeForm = ({
  name,
  totalCount,
  subscribeeDetails,
  fetchSubscription,
  isSubscriptionLoading = false,
  subscriptionDetails,
  subscriptionRequestError = false,
  subscribe,
  updateSubscription,
  unsubscribe,
  deleteSubscription,
  context,
  isSubscriptionUpdating = false,
  subscriptionUpdateSuccess = false,
  subscriptionUpdateInProgress = false,
  subscriptionUpdateError = false,
  onCancel = null,
}) => {
  const { t } = useTranslation("alert-me-form");

  //check for Alert me preferences
  //pre-populate if preferences are present
  const userPreferences = useSelector(
    ProfileState.selectors.getUserPreferences,
  );
  const preferredEmail = userPreferences?.defaultCommunication?.email ?? "";
  const preferredMobileNumber =
    userPreferences?.defaultCommunication?.phone ?? "";

  const [mode, setMode] = useState(null);
  const [subscriptionLocal, setSubscriptionLocal] = useState(null);

  //In order to dismiss the success or error alerts
  const [showAlert, setShowAlert] = useState(false);
  const [showSubscriptionRequestAlert, setShowSubscriptionRequestAlert] =
    useState(false);
  const [showModal, setShowModal] = useState({
    showCreateSubscriptionModal: false,
    showUpdateSubscriptionModal: false,
    showDeleteSubscriptionModal: false,
    isDeletingSubscription: false,
    isUnsubscribeAndDelete: false,
  });

  useEffect(() => {
    fetchSubscription(subscribeeDetails);
    // eslint-disable-next-line
  }, []);

  // When the tab opens, sync details to local state.
  useEffect(() => {
    const mode = subscriptionDetails ? MODES.UPDATE : MODES.SAVE;
    setMode(mode);

    const recipientEmail = subscriptionDetails?.recipient_email
      ? subscriptionDetails.recipient_email
      : preferredEmail
      ? preferredEmail
      : AuthenticationUtils.userEmail;

    const mobileNumber = subscriptionDetails?.mobile_number
      ? subscriptionDetails.mobile_number
      : preferredMobileNumber;

    // Save - create a new subscription.
    // Update - modifying an existing subscription.
    if (mode === MODES.SAVE) {
      setSubscriptionLocal({
        enable_email: false,
        enable_sms: false,
        enable_platform: false,
        recipient_email: recipientEmail,
        mobile_number: mobileNumber,
      });
    } else if (mode === MODES.UPDATE) {
      setSubscriptionLocal({
        ...subscriptionDetails,
        recipient_email: recipientEmail,
        mobile_number: mobileNumber,
      });
    }
  }, [subscriptionDetails, preferredEmail, preferredMobileNumber]);

  //In order to dismiss the error alert on subscription Request Failure
  useEffect(() => {
    if (subscriptionRequestError) {
      setShowSubscriptionRequestAlert(true);
    } else {
      setShowSubscriptionRequestAlert(false);
    }
  }, [subscriptionRequestError]);

  useEffect(() => {
    if (subscriptionUpdateSuccess || subscriptionUpdateError) {
      setShowModal((showModal) => ({
        ...showModal,
        showCreateSubscriptionModal: false,
        showUpdateSubscriptionModal: false,
        showDeleteSubscriptionModal: false,
      }));
    }
  }, [subscriptionUpdateSuccess, subscriptionUpdateError]);

  const hasVinSubscriptionChanged = () => {
    // when no subscription is set at all any checkbox will do
    if (!subscriptionDetails) {
      return (
        subscriptionLocal?.enable_email ||
        subscriptionLocal?.enable_sms ||
        subscriptionLocal?.enable_platform
      );
    }
    // when subscription is set, compare data for changes
    return (
      subscriptionLocal?.enable_email !== subscriptionDetails.enable_email ||
      (subscriptionLocal?.enable_email &&
        subscriptionLocal?.recipient_email !==
          subscriptionDetails.recipient_email) ||
      subscriptionLocal?.enable_sms !== subscriptionDetails.enable_sms ||
      (subscriptionLocal?.enable_sms &&
        subscriptionLocal?.mobile_number !==
          subscriptionDetails.mobile_number) ||
      subscriptionLocal?.enable_platform !== subscriptionDetails.enable_platform
    );
  };

  const isEmailSubscriptionInvalid =
    subscriptionLocal?.enable_email &&
    !(
      subscriptionLocal?.recipient_email &&
      isValidEmail(subscriptionLocal.recipient_email)
    );

  const isMobileSubscriptionInvalid =
    subscriptionLocal?.enable_sms &&
    !(
      subscriptionLocal?.mobile_number &&
      isPossiblePhoneNumber(subscriptionLocal.mobile_number)
    );

  const isUnsubscription =
    mode === MODES.UPDATE &&
    !subscriptionLocal.enable_email &&
    !subscriptionLocal.enable_sms &&
    !subscriptionLocal.enable_platform;

  const onRequestFinished = () => setShowAlert(true);

  const onSave = () => {
    setShowModal({
      ...showModal,
      isDeletingSubscription: false,
      isUnsubscribeAndDelete: false,
    });

    if (context) {
      subscriptionLocal.context = context;
    }
    if (mode === MODES.SAVE) {
      // save subscription
      subscribe(subscribeeDetails, subscriptionLocal).then(onRequestFinished);
    } else {
      // update subscription
      updateSubscription(subscribeeDetails, subscriptionLocal).then(
        onRequestFinished,
      );
    }
  };

  const onModalClose = () => {
    setShowAlert(false);
    setShowSubscriptionRequestAlert(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onAlertClose = () => setShowAlert(false);

  return (
    <Fragment>
      <div
        css={{
          padding: "1rem",
          pointerEvents:
            isSubscriptionLoading || subscriptionRequestError
              ? "none"
              : "default",
          opacity: isSubscriptionLoading || subscriptionRequestError ? 0.5 : 1,
        }}
      >
        {t(
          "alert-me-form:By selecting the options below, you will receive all major event updates and agree to receive this communication by email or text message for all active shipments that meet your saved search criteria.",
        )}
        <div css={{ paddingTop: "0.5em" }}>
          {t(
            "alert-me-form:Click the Saved Search Configuration tab to review your saved search criteria.",
          )}
        </div>

        <div
          css={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "1rem",
            padding: "1rem 1rem 0",
            [MediaQueries.largeAndUp]: {
              padding: "1rem 15rem 0 1rem",
            },
          }}
        >
          {/* Email */}
          <div>
            <Form.Check
              label={true}
              type="checkbox"
              checked={subscriptionLocal?.enable_email ?? false}
              onChange={() => {
                const { enable_email, recipient_email } = subscriptionLocal;
                setSubscriptionLocal({
                  ...subscriptionLocal,
                  enable_email: !enable_email,
                  recipient_email: isEmailSubscriptionInvalid
                    ? ""
                    : recipient_email,
                });
              }}
              id="checkbox-vin-subscription-email"
              data-qa="checkbox-vin-subscription-email"
              style={{ display: "inline-block" }}
            />
            <Form.Label htmlFor="checkbox-vin-subscription-email">
              <Text size={FontSize.size18}>{t("alert-me-form:Email")}</Text>
            </Form.Label>
          </div>
          <Form.Group css={{ marginBottom: 0 }}>
            <Form.Control
              disabled={!subscriptionLocal?.enable_email ?? true}
              type="text"
              value={
                subscriptionLocal?.enable_email
                  ? subscriptionLocal?.recipient_email
                  : ""
              }
              placeholder={t("alert-me-form:Email")}
              onChange={(e) => {
                setSubscriptionLocal({
                  ...subscriptionLocal,
                  recipient_email: e.target.value,
                });
              }}
              data-qa="input-subscription-email"
            />
          </Form.Group>

          {/* Text Message */}
          <div>
            <Form.Check
              label={true}
              type="checkbox"
              checked={subscriptionLocal ? subscriptionLocal.enable_sms : false}
              onChange={() => {
                const { enable_sms, mobile_number } = subscriptionLocal;
                setSubscriptionLocal({
                  ...subscriptionLocal,
                  enable_sms: !enable_sms,
                  mobile_number: isMobileSubscriptionInvalid
                    ? ""
                    : mobile_number,
                });
              }}
              id="checkbox-vin-subscription-text-msg"
              data-qa="checkbox-vin-subscription-text-msg"
              style={{ display: "inline-block" }}
            />
            <Form.Label htmlFor="checkbox-vin-subscription-text-msg">
              <Text size={FontSize.size18}>
                {t("alert-me-form:Text Message")}
              </Text>
            </Form.Label>
          </div>

          <Form.Group css={{ marginBottom: 0, marginTop: "0.5em" }}>
            <PhoneNumberInput
              placeholder={t("alert-me-form:Mobile Number")}
              value={
                subscriptionLocal?.enable_sms
                  ? subscriptionLocal?.mobile_number
                  : ""
              }
              onChange={(value) => {
                setSubscriptionLocal({
                  ...subscriptionLocal,
                  mobile_number: value,
                });
              }}
              disabled={!subscriptionLocal?.enable_sms ?? true}
              data-qa="input-subscription-text-msg"
              aria-describedby="mobile-number-help-text"
            />
            <Form.Text id="mobile-number-help-text" muted>
              {t(
                "alert-me-form:Choose country code from the drop-down or type the country code along with your mobile phone number.",
              )}
            </Form.Text>
          </Form.Group>

          {/* Platform */}
          <div css={{ gridColumn: "1 / -1" }}>
            <Form.Check
              label={true}
              type="checkbox"
              checked={
                subscriptionLocal ? subscriptionLocal.enable_platform : false
              }
              onChange={() => {
                setSubscriptionLocal({
                  ...subscriptionLocal,
                  enable_platform: !subscriptionLocal.enable_platform,
                });
              }}
              id="checkbox-vin-subscription-notification"
              data-qa="checkbox-vin-subscription-notification"
              style={{ display: "inline-block" }}
            />
            <Form.Label
              htmlFor="checkbox-vin-subscription-notification"
              css={{ marginBottom: 0 }}
            >
              <Text size={FontSize.size18}>
                {t("alert-me-form:FreightVerify Platform Notification")}
              </Text>
            </Form.Label>
          </div>
        </div>
      </div>

      <Alert
        show={showSubscriptionRequestAlert && subscriptionRequestError}
        variant={AlertVariant.Danger}
        onClose={() => setShowSubscriptionRequestAlert(false)}
        dismissible
        css={{ margin: "0 1rem 1rem" }}
      >
        <Text>
          {t(
            "alert-me-form:There was an error when fetching your alert preferences. Please try again later.",
          )}
        </Text>
      </Alert>

      <Alert
        show={showAlert && subscriptionUpdateSuccess}
        variant={AlertVariant.Success}
        onClose={onAlertClose}
        dismissible
        css={{ margin: "0 1rem 1rem" }}
      >
        <Text>
          {showModal.isDeletingSubscription
            ? t("alert-me-form:Your alert preferences have been deleted.")
            : showModal.isUnsubscribeAndDelete
            ? t(
                "alert-me-form:Your alert preferences have been deleted and you have been unsubscribed from related shipments.",
              )
            : t("alert-me-form:Your alert preferences have been updated.")}
        </Text>
      </Alert>

      <Alert
        show={showAlert && subscriptionUpdateInProgress}
        variant={AlertVariant.Warning}
        onClose={onAlertClose}
        dismissible
        css={{ margin: "0 1rem 1rem" }}
      >
        <Text>
          {t(
            "alert-me-form:Your alert preferences update is processing. Please review the shipments after a few minutes.",
          )}
        </Text>
      </Alert>

      <Alert
        show={
          showAlert && subscriptionUpdateError && !subscriptionUpdateInProgress
        }
        variant={AlertVariant.Danger}
        onClose={onAlertClose}
        dismissible
        css={{ margin: "0 1rem 1rem" }}
      >
        <Text>
          {showModal.isDeletingSubscription
            ? t(
                "alert-me-form:There was an error when deleting your alert preferences.",
              )
            : showModal.isUnsubscribeAndDelete
            ? t(
                "alert-me-form:There was an error when unsubscribing and deleting your alert preferences.",
              )
            : t(
                "alert-me-form:There was an error when updating your alert preferences.",
              )}{" "}
          {t("alert-me-form:Please try again later.")}
        </Text>
      </Alert>

      <div
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          display: "flex",
          justifyContent: subscriptionDetails ? "space-between" : "flex-end",
          padding: "1em 2em",
        }}
      >
        {subscriptionDetails ? (
          <div
            css={{
              display: "flex",
              [MediaQueries.smallAndDown]: {
                flexDirection: "column-reverse",
                gap: "1em",
              },
            }}
          >
            <Button
              variant="danger"
              onClick={() => {
                setShowModal({
                  ...showModal,
                  showDeleteSubscriptionModal: true,
                });
              }}
              data-qa="button-delete-vin-subscription"
              style={{
                marginRight: "1em",
              }}
            >
              {t("alert-me-form:Delete")}
            </Button>
          </div>
        ) : null}
        <div
          css={{
            display: "flex",
            [MediaQueries.smallAndDown]: {
              flexDirection: subscriptionDetails ? "column-reverse" : "row",
              gap: subscriptionDetails ? "1em" : 0,
            },
          }}
        >
          <Button
            variant="outline-secondary"
            disabled={isSubscriptionUpdating}
            onClick={() => {
              onModalClose();
            }}
            data-qa="button-cancel-vin-subscription"
            style={{
              marginRight: "1em",
            }}
          >
            {t("alert-me-form:Cancel")}
          </Button>
          <Button
            disabled={
              !hasVinSubscriptionChanged() ||
              isSubscriptionUpdating ||
              isEmailSubscriptionInvalid ||
              isMobileSubscriptionInvalid ||
              isUnsubscription
            }
            variant="primary"
            onClick={() => {
              if (subscriptionDetails) {
                setShowModal({
                  ...showModal,
                  showUpdateSubscriptionModal: true,
                });
              } else {
                setShowModal({
                  ...showModal,
                  showCreateSubscriptionModal: true,
                });
              }
            }}
            data-qa="button-save-vin-subscription"
          >
            {subscriptionDetails
              ? t("alert-me-form:Update Alert Subscriptions")
              : t("alert-me-form:Create Alert Subscriptions")}
          </Button>
        </div>
      </div>
      <DialogModal
        show={showModal.showCreateSubscriptionModal}
        onHide={() =>
          setShowModal({ ...showModal, showCreateSubscriptionModal: false })
        }
        title={t("alert-me-form:Confirm Subscription")}
        closeOnBlur={false}
        cancelButtonText={t("alert-me-form:Cancel")}
        submitButtonText={t("alert-me-form:Confirm Subscription")}
        submitButtonVariant="primary"
        onSubmit={onSave}
        isSubmitting={isSubscriptionUpdating}
        submitButtonDisabled={
          totalCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH
        }
      >
        {totalCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH
          ? t(
              "alert-me-form:Your saved search contains [[[count]]] shipments, which is more than the maximum allowed of [[[maxCount]]] shipments. Please create a smaller saved search and try again.",
              {
                count: totalCount,
                maxCount: MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH,
              },
            )
          : t(
              "alert-me-form:Are you sure you want to create a saved search alert and subscribe to [[[count]]] shipments?",
              {
                count: totalCount,
              },
            )}
      </DialogModal>
      <DialogModal
        show={showModal.showUpdateSubscriptionModal}
        onHide={() =>
          setShowModal({ ...showModal, showUpdateSubscriptionModal: false })
        }
        title={t("alert-me-form:Confirm Update Subscription")}
        closeOnBlur={false}
        cancelButtonText={t("alert-me-form:Cancel")}
        submitButtonText={t("alert-me-form:Update Alert Subscriptions")}
        submitButtonVariant="primary"
        onSubmit={onSave}
        isSubmitting={isSubscriptionUpdating}
        submitButtonDisabled={
          totalCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH
        }
      >
        {totalCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH
          ? t(
              "alert-me-form:Your saved search contains [[[count]]] shipments, which is more than the maximum allowed of [[[maxCount]]] shipments. Please create a smaller saved search and try again.",
              {
                count: totalCount,
                maxCount: MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH,
              },
            )
          : t(
              "alert-me-form:Are you sure you want to update your saved search alert and subscribe to [[[count]]] shipments?",
              {
                count: totalCount,
              },
            )}
      </DialogModal>
      <DialogModal
        show={showModal.showDeleteSubscriptionModal}
        onHide={() =>
          setShowModal({ ...showModal, showDeleteSubscriptionModal: false })
        }
        title={t("alert-me-form:Confirm Delete Subscription")}
        closeOnBlur={false}
        size="md"
        footerContent={
          <Fragment>
            <Button
              variant="light"
              css={{ marginRight: "0.5em" }}
              onClick={() =>
                setShowModal({
                  ...showModal,
                  showDeleteSubscriptionModal: false,
                })
              }
              size="sm"
            >
              {t("alert-me-form:Cancel")}
            </Button>

            <Button
              variant="danger"
              onClick={() => {
                setShowModal({ ...showModal, isDeletingSubscription: true });
                deleteSubscription(subscribeeDetails).then(onRequestFinished);
              }}
              size="sm"
            >
              {showModal.isDeletingSubscription ? (
                <Icon src={faSpinner} spin />
              ) : (
                t("alert-me-form:Delete")
              )}
            </Button>

            <Button
              variant="danger"
              onClick={() => {
                setShowModal({ ...showModal, isUnsubscribeAndDelete: true });
                unsubscribe(subscribeeDetails).then(onRequestFinished);
              }}
              size="sm"
            >
              {showModal.isUnsubscribeAndDelete ? (
                <Icon src={faSpinner} spin />
              ) : (
                t("alert-me-form:Unsubscribe and Delete")
              )}
            </Button>
          </Fragment>
        }
      >
        <Trans t={t} count={totalCount}>
          Are you sure you want to save this change? Click the Delete button to
          delete your saved search alert subscription for <b>{name}</b>. Click
          the Unsubscribe and Delete button to unsubscribe from [[[count]]]
          shipments that match this saved search and delete your saved search
          alert subscription for <b>{name}</b>.
        </Trans>
      </DialogModal>
    </Fragment>
  );
};

AlertMeForm.propTypes = {
  name: PropTypes.string,
  totalCount: PropTypes.number,
  /** The object of the thing being subscribed to. */
  subscribeeDetails: PropTypes.object,
  fetchSubscription: PropTypes.func,
  isSubscriptionLoading: PropTypes.bool,
  subscriptionDetails: PropTypes.object,
  subscriptionRequestError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  deleteSubscription: PropTypes.func,
  context: PropTypes.object,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateInProgress: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  onCancel: PropTypes.func,
};
