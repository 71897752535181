import _ from "lodash";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";

const STORE_MOUNT_POINT = "InboundArrivalStatusWidgetState";

// URLs
const INBOUND_ARRIVAL_STATUS_URL = apiUrl(
  `/shipping-ng/destination-arrival-status`,
);
const getInboundArrivalStatusUrl = (solutionId, queryString) => {
  return `${INBOUND_ARRIVAL_STATUS_URL}?${queryString}`;
};

// Count Duck
const duckInboundArrivalStatusCount = buildFetchDuck(
  STORE_MOUNT_POINT,
  "count",
);

const transformArrivalStatusDetailsData = (data) => {
  return _.sortBy(
    data
      .map((d) => {
        if (d?.hasOwnProperty("early_arrival_count")) {
          return {
            id: 1,
            name: "Early",
            code: "E",
            count: d.early_arrival_count,
          };
        } else if (d?.hasOwnProperty("ontime_arrival_count")) {
          return {
            id: 2,
            name: "Ontime",
            code: "OT",
            count: d.ontime_arrival_count,
          };
        } else if (d?.hasOwnProperty("late_arrival_count")) {
          return {
            id: 3,
            name: "Late",
            code: "L",
            count: d.late_arrival_count,
          };
        }

        return null;
      })
      .filter((el) => el !== null),
    "id",
  );
};

const fetchCount = (status, locationCode) => {
  let url = INBOUND_ARRIVAL_STATUS_URL;
  let params = {};

  if (status) {
    params.unloadType = status;
  }

  if (locationCode) {
    params.destCode = locationCode;
  }

  const config = {
    headers: { Accept: "application/json;version=COUNT" },
    params,
  };

  return (dispatch) => {
    dispatch(
      duckInboundArrivalStatusCount.fetch(
        url,
        config,
        transformArrivalStatusDetailsData,
      ),
    );
  };
};

const fetchShipments = (
  queryString = "",
  _solutionId_ignored,
  duck,
  dispatch,
) => {
  const url = getInboundArrivalStatusUrl(null, queryString);

  const config = {
    headers: {
      Accept: "application/json;version=DETAIL",
    },
  };

  dispatch(duck.fetch(url, config));
};

// Interface
const InboundArrivalStatusWidgetState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [], // No categories
  [
    { queryKey: "unloadType", queryBuilder: getBasicQueryString },
    { queryKey: "destCode", queryBuilder: getBasicQueryString },
    { queryKey: "arrivalType", queryBuilder: getBasicQueryString },
  ],
  fetchShipments,
  [duckInboundArrivalStatusCount.reducer],
  {
    defaultSort: "destination_earliest_arrival",
  },
);

// Add count actions and selectors to the search state.
InboundArrivalStatusWidgetState.actionCreators = {
  ...InboundArrivalStatusWidgetState.actionCreators,
  fetchCount: fetchCount,
  exportSearch: _.partial(
    InboundArrivalStatusWidgetState.actionCreators.exportEntities,
    getInboundArrivalStatusUrl,
    null,
    { headers: { Accept: "text/csv;version=DETAIL" } },
    "arrival-status-search-results",
  ),
};

InboundArrivalStatusWidgetState.selectors = {
  ...InboundArrivalStatusWidgetState.selectors,
  getCountData: duckInboundArrivalStatusCount.selectors.getData,
};

export default InboundArrivalStatusWidgetState;
