import { connect } from "react-redux";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import ReportSchedulesState from "pages/reports/redux/ReportSchedulesState";
import ReportFolderModalState from "pages/reports/redux/ReportFolderModalState";
import ReportSubFolderState from "pages/reports/redux/ReportsUnderFolderState";
import ReportsSharedSearchBarState from "pages/reports/redux/ReportsSharedState";
import ReportsCoreSearchBarState from "pages/reports/redux/ReportsCoreState";
import ReportsMineSearchBarState from "pages/reports/redux/ReportsMineState";
import ReportsState from "pages/reports/redux/ReportsState";
import Reports from "./Reports.page";

function mapStateToProps(state) {
  const { getSchedules } = ReportSchedulesState.selectors;

  //Historical Data
  const { getSubFolderByQualifier } = ReportSubFolderState.selectors;

  //Shared Reports
  const {
    getSearchResults: getSharedReportsResults,
    getIsLoading: getSharedReportsIsLoading,
    getPage: getSharedPage,
    getPageSize: getSharedPageSize,
    getTotalPages: getSharedTotalPages,
    getDefaultSortColumn: getSharedReportsDefaultSortColumn,
    getDefaultReverseSort: getSharedReportsDefaultReverseSort,
    getSortColumn: getSharedReportsSortColumn,
    getReverseSort: getSharedReportsReverseSort,
    getSearchFilters: getSharedReportsSearchFilters,
  } = ReportsSharedSearchBarState.selectors;

  //Core Reports
  const {
    getSearchResults: getCoreReportsResults,
    getIsLoading: getCoreReportsIsLoading,
    getPage: getCorePage,
    getPageSize: getCorePageSize,
    getTotalPages: getCoreTotalPages,
    getDefaultSortColumn: getCoreReportsDefaultSortColumn,
    getDefaultReverseSort: getCoreReportsDefaultReverseSort,
    getSortColumn: getCoreReportsSortColumn,
    getReverseSort: getCoreReportsReverseSort,
    getSearchFilters: getCoreReportsSearchFilters,
  } = ReportsCoreSearchBarState.selectors;

  //My Reports
  const {
    getSearchResults: getMineReportsResults,
    getIsLoading: getMineReportsIsLoading,
    getPage: getMinePage,
    getPageSize: getMinePageSize,
    getTotalPages: getMineTotalPages,
    getDefaultSortColumn: getMineReportsDefaultSortColumn,
    getDefaultReverseSort: getMineReportsDefaultReverseSort,
    getSortColumn: getMineReportsSortColumn,
    getReverseSort: getMineReportsReverseSort,
    getSearchFilters: getMineReportsSearchFilters,
  } = ReportsMineSearchBarState.selectors;

  return {
    reportSchedules: getSchedules(state),
    solutionId: getSolutionId(state),
    authorization: getAuthorization(state),

    // Historical Data
    getSubFolderByQualifier,

    //Shared Reports
    sharedReportsResults: getSharedReportsResults(state),
    sharedReportsIsLoading: getSharedReportsIsLoading(state),
    sharedPage: getSharedPage(state),
    sharedPageSize: getSharedPageSize(state),
    sharedTotalPages: getSharedTotalPages(state),
    sharedReportsDefaultSortColumn: getSharedReportsDefaultSortColumn(state),
    sharedReportsDefaultReverseSort: getSharedReportsDefaultReverseSort(state),
    sharedReportsSortColumn: getSharedReportsSortColumn(state),
    sharedReportsReverseSort: getSharedReportsReverseSort(state),
    sharedReportsSearchFilters: getSharedReportsSearchFilters(state),

    //Core Reports
    coreReportsResults: getCoreReportsResults(state),
    coreReportsIsLoading: getCoreReportsIsLoading(state),
    corePage: getCorePage(state),
    corePageSize: getCorePageSize(state),
    coreTotalPages: getCoreTotalPages(state),
    coreReportsDefaultSortColumn: getCoreReportsDefaultSortColumn(state),
    coreReportsDefaultReverseSort: getCoreReportsDefaultReverseSort(state),
    coreReportsSortColumn: getCoreReportsSortColumn(state),
    coreReportsReverseSort: getCoreReportsReverseSort(state),
    coreReportsSearchFilters: getCoreReportsSearchFilters(state),

    //My Reports
    mineReportsResults: getMineReportsResults(state),
    mineReportsIsLoading: getMineReportsIsLoading(state),
    minePage: getMinePage(state),
    minePageSize: getMinePageSize(state),
    mineTotalPages: getMineTotalPages(state),
    mineReportsDefaultSortColumn: getMineReportsDefaultSortColumn(state),
    mineReportsDefaultReverseSort: getMineReportsDefaultReverseSort(state),
    mineReportsSortColumn: getMineReportsSortColumn(state),
    mineReportsReverseSort: getMineReportsReverseSort(state),
    mineReportsSearchFilters: getMineReportsSearchFilters(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { deleteReport, deleteFilteredReport } = ReportsState.actionCreators;
  const { fetchSchedule } = ReportSchedulesState.actionCreators;
  const { fetchCategory, fetchFolder, deleteFolderModal } =
    ReportFolderModalState.actionCreators;

  //SubFolder - fetch action
  const { fetchSubFolderData } = ReportSubFolderState.actionCreators;

  //Shared Reports
  const {
    setPagination: setSharedPagination,
    setSort: setSharedSort,
    setSearchFilter: setSharedSearchFilter,
    clearSearchText: clearSharedSearchText,
    clearSearchFilters: clearSharedSearchFilters,
    clearSearchFilter: clearSharedSearchFilter,
    searchEntities: searchSharedEntities,
  } = ReportsSharedSearchBarState.actionCreators;

  //Core Reports
  const {
    setPagination: setCorePagination,
    setSort: setCoreSort,
    setSearchFilter: setCoreSearchFilter,
    clearSearchText: clearCoreSearchText,
    clearSearchFilters: clearCoreSearchFilters,
    clearSearchFilter: clearCoreSearchFilter,
    searchEntities: searchCoreEntities,
  } = ReportsCoreSearchBarState.actionCreators;

  //My Reports
  const {
    setPagination: setMinePagination,
    setSort: setMineSort,
    setSearchFilter: setMineSearchFilter,
    clearSearchText: clearMineSearchText,
    clearSearchFilters: clearMineSearchFilters,
    clearSearchFilter: clearMineSearchFilter,
    searchEntities: searchMineEntities,
  } = ReportsMineSearchBarState.actionCreators;

  return {
    deleteReport: (reportId) => dispatch(deleteReport(reportId)),
    deleteFilteredReport: (reportId, filterSetId) =>
      dispatch(deleteFilteredReport(reportId, filterSetId)),

    pushReportDetailScreen: (reportId) =>
      dispatch({
        type: "REPORT_DETAIL",
        payload: { reportId: reportId },
      }),
    pushFilteredReportDetailScreen: (reportId, filterSetId) =>
      dispatch({
        type: "FILTERED_REPORT_DETAIL",
        payload: { reportId: reportId, filterSetId: filterSetId },
      }),

    fetchSchedule: (report) => dispatch(fetchSchedule(report)),
    fetchCategory: () => dispatch(fetchCategory()),
    fetchFolder: (reportType) => dispatch(fetchFolder(reportType)),
    deleteFolderModal: (reportId) => dispatch(deleteFolderModal(reportId)),

    //Fetch sub-folder reports data
    fetchSubFolderData: (
      solutionId,
      entityId,
      qualifier,
      sortColumn,
      reverseSort,
    ) =>
      dispatch(
        fetchSubFolderData(
          solutionId,
          entityId,
          qualifier,
          sortColumn,
          reverseSort,
        ),
      ),

    //Shared Reports
    setSharedPagination: (solutionId, page, pageSize) =>
      dispatch(setSharedPagination(solutionId, page, pageSize)),
    setSharedSort: (solutionId, sortColumn, reverse) =>
      dispatch(setSharedSort(solutionId, sortColumn, reverse)),
    setSharedSearchFilter: (key, value) =>
      dispatch(setSharedSearchFilter(key, value)),
    clearSharedSearchText: () => dispatch(clearSharedSearchText()),
    clearSharedSearchFilters: () => dispatch(clearSharedSearchFilters()),
    clearSharedSearchFilter: (key) => dispatch(clearSharedSearchFilter(key)),
    searchSharedReports: (solutionId) =>
      dispatch(searchSharedEntities(solutionId)),

    //Core Reports
    setCorePagination: (solutionId, page, pageSize) =>
      dispatch(setCorePagination(solutionId, page, pageSize)),
    setCoreSort: (solutionId, sortColumn, reverse) =>
      dispatch(setCoreSort(solutionId, sortColumn, reverse)),
    setCoreSearchFilter: (key, value) =>
      dispatch(setCoreSearchFilter(key, value)),
    clearCoreSearchText: () => dispatch(clearCoreSearchText()),
    clearCoreSearchFilters: () => dispatch(clearCoreSearchFilters()),
    clearCoreSearchFilter: (key) => dispatch(clearCoreSearchFilter(key)),
    searchCoreReports: (solutionId) => dispatch(searchCoreEntities(solutionId)),

    //My Reports
    setMinePagination: (solutionId, page, pageSize) =>
      dispatch(setMinePagination(solutionId, page, pageSize)),
    setMineSort: (solutionId, sortColumn, reverse) =>
      dispatch(setMineSort(solutionId, sortColumn, reverse)),
    setMineSearchFilter: (key, value) =>
      dispatch(setMineSearchFilter(key, value)),
    clearMineSearchText: () => dispatch(clearMineSearchText()),
    clearMineSearchFilters: () => dispatch(clearMineSearchFilters()),
    clearMineSearchFilter: (key) => dispatch(clearMineSearchFilter(key)),
    searchMineReports: (solutionId) => dispatch(searchMineEntities(solutionId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
