import { connect } from "react-redux";
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import SearchBarContainer from "./DealerPartView.SearchBar.container";
import FilterSectionContainer from "./DealerPartView.SearchFilters.container";
import DealerPartViewSearchBarState from "../../redux/DealerPartViewSearchBarState";
import DealerPartViewSavedSearchState from "../../redux/DealerPartViewSavedSearchState";

function mapStateToProps(state) {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    DealerPartViewSearchBarState.selectors;
  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
}

const { saveSearch, editSearch } =
  DealerPartViewSavedSearchState.actionCreators;

const actionCreators = { saveSearch, editSearch };

export default connect(mapStateToProps, actionCreators)(EditSavedSearchModal);
