/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import Loader from "react-loader";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { MediaQueries } from "components/responsive";
import SelectField from "components-old/forms/fields/SelectField";
import { useCarrierDelayedReasonCode } from "pages/shipments/utils/exception.utils";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors from "styles/colors";

/* Used display grid & gap to align the Alert(s) and button at the end of Form(s) second column*/
const alignmentStyle = {
  display: "grid",
  gap: "1em",
  [MediaQueries.smallAndUp]: {
    gridTemplateColumns: "2fr",
  },
  [MediaQueries.largeAndUp]: {
    gridTemplateColumns: "2fr 1fr",
  },
};

export const ShipmentETAOverride = ({
  activeOrganization,
  carriers,
  carriersData,
  delayOptionsData,
  reportDelayData,
  fetchDelayOptions,
  reportDelay,
  clearReportDelayInState,
}) => {
  const { t } = useTranslation("shipment-eta-override");

  useSetTitleOnMount(t("shipment-eta-override:Shipment ETA Override"));

  useTrackWithMixpanelOnce("Viewed Page: Shipment ETA Override");

  const reportDelayOptionsList = delayOptionsData?.data;
  const isSubmitLoading = reportDelayData?.isLoading;
  const responseStatusCode = reportDelayData?.status;
  const errorResponse = reportDelayData?.loadingError?.response?.data;
  const { getOptionLabelFromCode } = useCarrierDelayedReasonCode();

  // State for form fields.
  const [carrier, setCarrier] = useState(null);
  const [reasonCode, setReasonCode] = useState(null);
  const [newEta, setNewEta] = useState();
  const [comments, setComments] = useState("");
  const [shipmentIDs, setShipmentIDs] = useState({
    importText: "",
    importShipmentIDs: "",
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    fetchDelayOptions();

    return () => clearReportDelayInState();
  }, [fetchDelayOptions, clearReportDelayInState]);

  useEffect(() => {
    if (responseStatusCode) {
      setShowAlert(true);
    }
  }, [responseStatusCode]);

  useEffect(() => {
    //SH-7752 - When the yellow/ green banner message is displayed,
    //all modal inputs are cleared and the submit button is disabled
    if (responseStatusCode === 201 || responseStatusCode === 408) {
      resetStopData();
    }
  }, [responseStatusCode]);

  function resetStopData() {
    setCarrier(null);
    setReasonCode(null);
    setNewEta();
    setComments("");
    setShipmentIDs({
      importText: "",
      importShipmentIDs: "",
    });
  }

  const carriersList = useMemo(() => {
    return carriers?.map((carrierObj) => {
      return { label: carrierObj.name, value: carrierObj };
    });
  }, [carriers]);

  const delayOptionsList = useMemo(() => {
    return reportDelayOptionsList?.map((code) => {
      return { label: getOptionLabelFromCode(code.code), value: code.code };
    });
  }, [getOptionLabelFromCode, reportDelayOptionsList]);

  // Event handlers.
  const onCarrierChange = ({ value }) => {
    setCarrier(value);
  };

  const onReasonChange = ({ value }) => {
    setReasonCode(value);
  };

  const onNewEtaChange = (dateTime) => {
    setNewEta(dateTime);
  };

  const onCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const canSubmit =
    !isSubmitLoading &&
    !_.isNil(carrier) &&
    !_.isEmpty(shipmentIDs?.importShipmentIDs) &&
    !_.isNil(reasonCode) &&
    !_.isNil(newEta);

  const onSubmitClick = () => {
    setShowAlert(false);
    const { importShipmentIDs } = shipmentIDs;

    const shipment = {
      //sending active orgs org id to get the respective fv_id to reuse the existing logic
      created_by_org_id: activeOrganization?.organization_id,
      carrier_fv_id: carrier?.fv_id,
      carrier_scac: carrier?.scac,
      creator_shipment_id: importShipmentIDs?.[0],
    };

    if (canSubmit) {
      reportDelay(
        shipment,
        activeOrganization,
        reasonCode,
        newEta,
        comments,
        importShipmentIDs,
        resetStopData,
      );
    }
  };

  const textChangeHandler = (e) => {
    const batchText = e && e.target && e.target.value ? e.target.value : "";
    const lines = batchText.split(/\r\n|\n/);
    const batchLines = lines.map((x) => x.trimStart()).join("\r\n");

    setShipmentIDs({ importText: batchLines, importShipmentIDs: lines });
  };

  return (
    <Form
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        padding: "1.5em  1em",
      }}
    >
      <h4>
        {t("shipment-eta-override:Report Destination Arrival Estimate")}
        <span
          css={{
            position: "relative",
            ".loadedContent": { display: "inline" },
          }}
        >
          <Loader
            style={{ display: "inline-block" }}
            loaded={!carriersData?.isLoading && !delayOptionsData?.isLoading}
            left="1em"
            scale={0.5}
          />
        </span>
      </h4>
      <Form.Group css={{ marginBottom: "1rem", paddingBottom: "0.5em" }}>
        <Form.Text id="comments-help-text">
          <Text block color={Colors.text.GRAY} size={FontSize.size14}>
            {t(
              "shipment-eta-override:Use this form to report a delay for Truck and Rail shipments. Shipments of other modes will not be updated with a delay.",
            )}
          </Text>
          <Text
            block
            color={Colors.text.GRAY}
            size={FontSize.size14}
            css={{ paddingTop: "0.25em" }}
          >
            {t(
              "shipment-eta-override:The same reason, ETA, and comments will be applied to all shipments.",
            )}
          </Text>
        </Form.Text>
      </Form.Group>
      <div
        css={{
          display: "grid",
          gap: "1em",
          [MediaQueries.smallAndUp]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [MediaQueries.largeAndUp]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        }}
      >
        <div>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="carriers-for-shipment">
              <Text>{t("shipment-eta-override:Carrier")}</Text>
            </Form.Label>
            <SelectField
              id="carriers-for-shipment"
              placeholder={t(
                "shipment-eta-override:Select the carrier of the shipments",
              )}
              isLoading={carriersData?.isLoading}
              options={carriersList}
              stateValue={carrier}
              onChange={onCarrierChange}
            />
          </Form.Group>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label>
              {`${t("shipment-eta-override:Enter Shipment IDs")} (${t(
                "shipment-eta-override:Line Separated",
              )})`}
            </Form.Label>
            <Form.Control
              as="textarea"
              id="delay-shipment-ids"
              aria-describedby="shipment-ids-to-report-delay"
              value={shipmentIDs.importText ?? ""}
              onChange={textChangeHandler}
              css={{ height: "15.6em" }}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="reason-for-delay">
              <Text>{t("shipment-eta-override:Reason")}</Text>
            </Form.Label>
            <SelectField
              id="reason-for-delay"
              placeholder={t(
                "shipment-eta-override:Select the reason for delay",
              )}
              isLoading={delayOptionsData?.isLoading}
              options={delayOptionsList}
              stateValue={reasonCode}
              onChange={onReasonChange}
            />
          </Form.Group>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="new-eta-datetime">
              <Text>{t("shipment-eta-override:New ETA for Delivery")}</Text>
            </Form.Label>
            <DateTimePicker
              id="new-eta-datetime"
              data-qa="eta-datetime"
              value={newEta}
              onChange={onNewEtaChange}
              min={moment().toDate()}
              // SH-8735: Format requested by customer
              format="MMM DD, YYYY HH:mm"
              timeFormat="HH:mm"
            />
          </Form.Group>
          <Form.Group css={{ marginBottom: "1rem" }}>
            <Form.Label htmlFor="delay-comments">
              <Text block>
                {t("shipment-eta-override:Comments (Optional)")}
              </Text>
            </Form.Label>
            <Form.Control
              as="textarea"
              id="delay-comments"
              aria-describedby="comments-help-text"
              value={comments}
              onChange={onCommentsChange}
              css={{ height: "10em" }}
            />
          </Form.Group>
        </div>
      </div>

      <div css={alignmentStyle}>
        <Alert
          show={
            carriersData?.isLoadingError || delayOptionsData?.isLoadingError
          }
          variant={AlertVariant.Danger}
        >
          <Text block size={FontSize.size16}>
            {t(
              "shipment-eta-override:Something went wrong. Please try again later.",
            )}
          </Text>
        </Alert>
        <Alert
          show={showAlert}
          variant={
            responseStatusCode === 201
              ? AlertVariant.Success
              : responseStatusCode === 408
              ? AlertVariant.Warning
              : AlertVariant.Danger
          }
          dismissible
          onDismiss={() => setShowAlert(false)}
        >
          {responseStatusCode === 201 ? (
            <Text block size={FontSize.size14}>
              {t("shipment-eta-override:Successfully reported delay(s).")}
            </Text>
          ) : responseStatusCode === 408 ? (
            <Text block size={FontSize.size14}>
              {t(
                "shipment-eta-override:Batch ETA update is processing. Please review the shipments after a few minutes.",
              )}
            </Text>
          ) : (
            <Text block size={FontSize.size14}>
              {/* Get error message(s) from `errorResponse`. If none, fallback to generic message */}
              {errorResponse?.errors ??
                t("shipment-eta-override:Something went wrong.")}
            </Text>
          )}
        </Alert>
      </div>

      <div css={alignmentStyle}>
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="success"
            onClick={onSubmitClick}
            disabled={!canSubmit}
            data-qa="button-submit-report-delay"
          >
            {isSubmitLoading ? (
              <Icon src={faSpinner} spin />
            ) : (
              t("shipment-eta-override:Submit")
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

ShipmentETAOverride.propTypes = {
  activeOrganization: PropTypes.object,
  carriers: PropTypes.array,
  carriersData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  delayOptionsData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  reportDelayData: PropTypes.shape({
    status: PropTypes.number,
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  fetchDelayOptions: PropTypes.func,
  reportDelay: PropTypes.func,
  clearReportDelayInState: PropTypes.func,
};
