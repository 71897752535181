import { Privileges } from "./Authorization";

// Perform authorization checks based on user's privileges
// Used to authorize most of the app modules
export default class PrivilegeAuthorization {
  constructor(userAuth, userOrgPermissions) {
    this.privileges = userAuth ? userAuth.privileges || [] : [];

    // Check if the user has organization-level permissions
    if (userOrgPermissions) {
      this.privileges = userOrgPermissions.privileges;
    }

    // Always include the ANY privilege for all users
    this.privileges.push("ANY");
  }

  // Does the user have any of the privileges specified?
  hasAnyPrivilege(privilegeNames) {
    return this.privilegesWithNames(privilegeNames).length > 0;
  }

  // Filter the full list of user privileges by specific names
  privilegesWithNames(privilegeNames) {
    let namedPrivileges = this.privileges;

    // If names are specified, only return privileges with matching names
    if (privilegeNames && privilegeNames.length) {
      namedPrivileges = namedPrivileges.filter((p) => {
        return privilegeNames.includes(p);
      });
    }

    return namedPrivileges;
  }

  // Is the user a FreightVerify Admin?
  isFvAdmin() {
    return (
      this.privilegesWithNames([Privileges.CHANGE_ACTIVE_ORGANIZATION]).length >
      0
    );
  }
}
