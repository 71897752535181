import _ from "lodash";

/**
 * Logs a console warning if translation not found for the label.
 *
 * Using _.memoize to prevent spamming the console. Displays message only once per label in case of many rerenders
 */
export const logWarningForMissingTranslation = _.memoize((label: string) => {
  console.warn(`Translation not found: "${label}" could not be translated.`);
});
