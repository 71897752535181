/** @jsxImportSource @emotion/react */
// External
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";

// Components
import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { FinishedVehicleSavedSearch } from "./FinishedVehicle.SavedSearch.organism";
import FinVehicleEditSavedSearchModalContainer from "modules/fv-search/FinVehicleEditSavedSearchModalContainer";

// State
import FinVehicleSearchBarState from "modules/fv-search/FinVehicleSearchBarState";
import FinVehicleSavedSearchState from "modules/fv-search/FinVehicleSavedSearchState";

export const FinishedVehicleSavedSearchesPanel = () => {
  const savedSearches = useSelector(
    FinVehicleSavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    FinVehicleSavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(
    () => {
      dispatch(FinVehicleSavedSearchState.actionCreators.fetchSavedSearches());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={FinishedVehicleSavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                FinVehicleSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
          };
        }}
      />
      <FinVehicleEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            FinVehicleSearchBarState.actionCreators.resetSearchAndFilters(true),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(FinVehicleSavedSearchState.actionCreators.deleteSearch(id));
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};
