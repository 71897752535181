//External hooks or supporting code from React
import { connect } from "react-redux";

import TitleState from "shared/redux/TitleState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";

//Components
import { ShipmentsSearchView } from "./Shipments.Search.page";

function mapStateToProps(state) {
  return {
    shipments: ShipmentsSearchBarState.selectors.getSearchResults(state),
    exportIdentifier:
      ShipmentsSearchBarState.selectors.getExportIdentifier(state),
    exportName: ShipmentsSearchBarState.selectors.getExportName(state),
    isLoading: ShipmentsSearchBarState.selectors.getIsLoading(state),
    isExporting: ShipmentsSearchBarState.selectors.getIsExporting(state),
    exportFailed: ShipmentsSearchBarState.selectors.getExportFailed(state),
    searchFilters: ShipmentsSearchBarState.selectors.getSearchFilters(state),
    page: ShipmentsSearchBarState.selectors.getPage(state),
    pageSize: ShipmentsSearchBarState.selectors.getPageSize(state),
    totalPages: ShipmentsSearchBarState.selectors.getTotalPages(state),
    sortColumn: ShipmentsSearchBarState.selectors.getSortColumn(state),
    reverseSort: ShipmentsSearchBarState.selectors.getReverseSort(state),
    totalShipments: ShipmentsSearchBarState.selectors.getTotalEntities(state),
    showFilters: ShipmentsSearchBarState.selectors.getShowAdvancedSearch(state),
  };
}

const actionCreators = {
  setTitle: TitleState.actionCreators.setTitle,
  searchEntities: ShipmentsSearchBarState.actionCreators.searchEntities,
  setPagination: ShipmentsSearchBarState.actionCreators.setPagination,
  setSort: ShipmentsSearchBarState.actionCreators.setSort,
  resetSearch: () => (dispatch) => {
    dispatch(ShipmentsSearchBarState.actionCreators.resetSearchAndFilters());
    dispatch(ShipmentsSearchBarState.actionCreators.searchEntities());
  },
  pushShipmentDetailView: (id) => ({
    type: "SHIPMENT_DETAIL",
    payload: { shipment_id: id },
  }),
  exportAllShipments: ShipmentsSearchBarState.actionCreators.exportSearch,
  toggleShowFilters: ShipmentsSearchBarState.actionCreators.toggleShowFilters,
  resetExport: ShipmentsSearchBarState.actionCreators.resetExport,
};

export default connect(mapStateToProps, actionCreators)(ShipmentsSearchView);
