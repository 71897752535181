import moment from "moment-timezone";
import _ from "lodash";
import { produce } from "immer";

import { SEARCH_CATEGORIES } from "../components/search/Healthcare.searchOptions";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "healthcareSearch";
const CLICKED_SEARCH_OR_ENTER = `${STORE_MOUNT_POINT}/CLICKED_SEARCH_OR_ENTER`;

// Helpers

const entitiesUrl = (solutionId, queryString) => {
  return apiUrl(`/entity/solution/${solutionId}/entity?${queryString}`);
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json",
    },
  };
};

const translateEntity = (entity) => {
  return {
    id: entity.id,
    description: entity.description,
  };
};

// Actions

const fetchSearch = (qs = "", solutionId, duck, dispatch, state) => {
  // TODO: improve this avoiding direct access to the state, that is, use
  // selectors instead
  const hasSearchCriteria = (state) => {
    const ccState = state[STORE_MOUNT_POINT];
    const hasSearchText = ccState.searchText;
    const hasFilter = Object.keys(ccState.searchFilters).find(
      (key) => !_.isNil(ccState.searchFilters[key]),
    );
    return hasSearchText || hasFilter;
  };

  if (!hasSearchCriteria(state)) {
    // Error should be shown if user has not applied any search criteria
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      err: "Search text or filter(s) must be specified",
    });
  } else {
    // Normal search GET

    const url = entitiesUrl(solutionId, qs);
    const config = axiosConfig();
    dispatch(
      duck.fetch(url, config, (response) => {
        return {
          ...response,
          data: response.data.map((entity) => translateEntity(entity)),
        };
      }),
    );
  }
};

// H1-2160: There is a very special need for this case that is related to
// showing a message when there is no filter and user tries to filter
// something. The point is that, sometimes, user filter and remove all filters.
// When removing the filters a message was being showed. We then created this
// special property just to mark if it was the user clicking on search or enter
// or something related to filters. With this info on the state we have the
// capacity to define when to show/not show the message considering also this
// very special case.
const clickedSearchOrEnter = (value) => {
  return (dispatch) => {
    dispatch({
      type: CLICKED_SEARCH_OR_ENTER,
      payload: value,
    });
  };
};

const getClickedSearchOrEnter = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.clickedSearchOrEnter`, false);

const initialState = { clickedSearchOrEnter: false };

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLICKED_SEARCH_OR_ENTER:
        draft.clickedSearchOrEnter = action.payload;
        break;

      default:
        return draft;
    }
  });

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  null,
  fetchSearch,
  [reducer],
);

SearchBarState.actionCreators.clickedSearchOrEnter = clickedSearchOrEnter;
SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  null,
  { headers: { Accept: "text/csv;version=connected" } },
  "healthcare-search-results",
);

SearchBarState.selectors.getClickedSearchOrEnter = getClickedSearchOrEnter;

export default SearchBarState;
