import { connect } from "react-redux";
import { getSolutionId } from "modules/organizations/OrganizationsState";

import { InventoryViewWatchedVins } from "./InventoryView.WatchedVins";
import WatchedVinsState from "../../redux/InventoryViewWatchedVinsState";
import InventoryViewDashboardState from "pages/inventoryview/redux/InventoryViewDashboardState";
import MapState from "modules/map/MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { OrganizationType } from "shared/constants/organization.const";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const {
    getLocation,
    getIsLocationLoading,
    getIsLoading,
    getSearchResults,
    getPage,
    getPageSize,
    getTotalPageCountForSearch,
  } = WatchedVinsState.selectors;

  const { getMapCoordinate } = MapState.selectors;

  return {
    solutionId: getSolutionId(state),
    watchedVinsArray: getSearchResults(state),
    isWatchedVinsLoading: getIsLoading(state),
    watchedVinsPageIndex: getPage(state),
    watchedVinsPageSize: getPageSize(state),
    watchedVinsPageCount: getTotalPageCountForSearch(state),
    location: getLocation(state),
    isLocationLoading: getIsLocationLoading(state),
    selectedMapCoordinate: getMapCoordinate(MapCoordinateType.CURRENT_LOCATION)(
      state,
    ),
    canRedirectToVinDetails: getAuthorization(state).hasOrganizationTypes([
      OrganizationType.SHIPPER,
      OrganizationType.CARRIER,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchLocation, setWatchEntity, searchEntities, setPagination } =
    WatchedVinsState.actionCreators;

  return {
    fetchWatchedVins: (solutionId) => dispatch(searchEntities(solutionId)),
    fetchLocation: (locationId) => dispatch(fetchLocation(locationId)),
    setWatchedVinsPageIndex: (solutionId, page, pageSize) =>
      dispatch(setPagination(solutionId, page, pageSize)),
    setWatchEntity: (solutionId, vin, internal_id, newWatchValue) =>
      dispatch(setWatchEntity(solutionId, vin, internal_id, newWatchValue)),
    redirectToVinDetails: (vin) =>
      dispatch(
        InventoryViewDashboardState.actionCreators.redirectToVinDetails(vin),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryViewWatchedVins);
