/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DamageViewImageUploader } from "./DamageView.ImageUploader";
import Colors from "styles/colors";
import { useDamageViewFormLabelTranslation } from "pages/damageview/utils/translation.utils";

const CustomLabel = styled.span`
  &:after {
    content: " *";
    color: ${Colors.text.RED};
  }
`;

export const ImageUploaderContainer = ({
  data,
  imagesData,
  category,
  formData,
  setFormData,
  className,
}) => {
  const [files, setFiles] = useState(imagesData[category].files);
  const [commentData, setCommentData] = useState(imagesData[category].comments);
  const { getTranslatedFormLabel } = useDamageViewFormLabelTranslation();

  useEffect(() => {
    setFiles(imagesData[category].files);
    setCommentData(imagesData[category].comments);
    // Disabling line so that we only update local state on mount.
    // This happens when the user navigates to the form.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li className={className}>
      {data.isRequired ? (
        <CustomLabel>{getTranslatedFormLabel(data?.name)}:</CustomLabel>
      ) : (
        <span>{data.name}:</span>
      )}
      <DamageViewImageUploader
        style={{
          marginTop: "5px",
        }}
        files={files}
        setFiles={setFiles}
        setCommentData={setCommentData}
        commentData={commentData}
        imagesData={imagesData}
        name={data.category}
        onChange={(name, val) => {
          if (!_.isEqual(formData.uploadPhotos[name], val)) {
            setFormData({
              ...formData.uploadPhotos,
              [name]: { ...val },
            });
          }
        }}
      />
    </li>
  );
};

ImageUploaderContainer.propTypes = {
  data: PropTypes.object,
  imagesData: PropTypes.object,
  category: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  className: PropTypes.string,
};
