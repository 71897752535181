/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Separator } from "components/atoms/Separator.atom";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import { MediaQueries } from "components/responsive";
import { getIconData } from "pages/finishedvehicle/utils/exceptions.utils";
import { getFontSize } from "pages/containertracking/utils/ContainerTrackingUtils";

export const SurgicalToteTrackingCountsWidget = ({
  totesTotals,
  isTotesTotalsLoading,
  searchEntities,
  setSearchFilter,
}) => {
  const { t } = useTranslation("surgical-tote-tracking");
  const unaccountedText = t("surgical-tote-tracking:Unaccounted");
  const excessiveDwellText = t("surgical-tote-tracking:Excessive Dwell");

  const { total, onsiteAtODC, inTransit, onsiteAtHospital, outOfNetwork } =
    totesTotals;

  const fontSize = getFontSize([
    onsiteAtODC?.count,
    inTransit?.count,
    onsiteAtHospital?.count,
    outOfNetwork?.count,
  ]);

  const getPercentage = (count, totalCount) => {
    return isTotesTotalsLoading || !totalCount
      ? 0
      : Math.round((count / totalCount) * 100);
  };

  const getExceptionGroups = (unaccountedCount, excessiveDwellCount) => {
    return [
      {
        includeInDonutChart: true,
        exceptions: [
          {
            id: 1,
            name: unaccountedText,
            count: unaccountedCount ?? 0,
            tooltipText: "No pings received for more than 48 hours",
          },
          {
            id: 2,
            name: excessiveDwellText,
            count: excessiveDwellCount ?? 0,
            icon: {
              style: { height: "30px", width: "30px" },
              ...getIconData("Excessive Dwell"),
            },
            tooltipText:
              "Tote's sitting at Hospital/Surgical Center for 36 hours",
          },
        ],
      },
    ];
  };

  const handleClickException = (exception) => {
    if (exception.name === unaccountedText) {
      setSearchFilter("activeExceptions", ["Unaccounted"]);
      searchEntities();
    } else if (exception.name === excessiveDwellText) {
      setSearchFilter("activeExceptions", ["Excessive Dwell"]);
      searchEntities();
    }
  };

  const totalExceptionGroups = getExceptionGroups(
    total?.totalUnaccounted,
    total?.totalExcessiveDwell,
  );

  const totalTotesChart = {
    chartLabel: t("surgical-tote-tracking:Total Totes"),
    count: total?.count,
    exceptionGroups: [
      {
        title: t("surgical-tote-tracking:Exceptions"),
        style: { paddingTop: "2.5%" },
        countStyles: { flexBasis: "auto" },
        isLoading: isTotesTotalsLoading,
        ...totalExceptionGroups?.[0],
      },
    ],
    onClick: () => {
      searchEntities();
    },
  };

  const onsiteTotesCharts = [
    {
      chartLabel: t("surgical-tote-tracking:Onsite - Distribution Center"),
      count: onsiteAtODC?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtODC?.totalUnaccounted,
        onsiteAtODC?.totalExcessiveDwell,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Distribution"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtODC?.count, total?.count),
    },
    {
      chartLabel: t("surgical-tote-tracking:In Transit"),
      count: inTransit?.count,
      onClick: () => {
        setSearchFilter("status", ["In Transit"]);
        searchEntities();
      },
      percentage: getPercentage(inTransit?.count, total?.count),
    },
    {
      chartLabel: t("surgical-tote-tracking:Onsite - Hospital/Surgical Center"),
      count: onsiteAtHospital?.count,
      exceptionGroups: getExceptionGroups(
        onsiteAtHospital?.totalUnaccounted,
        onsiteAtHospital?.totalExcessiveDwell,
      ),
      onClick: () => {
        setSearchFilter("lad", ["Hospital"]);
        searchEntities();
      },
      percentage: getPercentage(onsiteAtHospital?.count, total?.count),
    },
  ];

  const TotalTotesChart = () => (
    <ExceptionsPanel.Chart
      exceptionGroups={totalTotesChart.exceptionGroups}
      count={totalTotesChart.count}
      countIsLoading={isTotesTotalsLoading}
      chartLabel={totalTotesChart.chartLabel}
      onClick={totalTotesChart.onClick}
      percentage={totalTotesChart.percentage}
      styles={{ width: "12em", justifyContent: "flex-start" }}
    />
  );

  const totalCountChartElement = (
    <Fragment>
      <div
        className="d-flex flex-wrap justify-content-center"
        css={{ columnGap: "2em", rowGap: "1em", paddingBottom: "1.5em" }}
      >
        <TotalTotesChart />

        <div
          className="d-flex flex-wrap justify-content-center"
          css={{ paddingTop: "20px" }}
        >
          {onsiteTotesCharts.map((group) => (
            <Fragment key={group.chartLabel}>
              <ExceptionsPanel.Chart
                exceptionGroups={group.exceptionGroups}
                count={group.count}
                countIsLoading={isTotesTotalsLoading}
                chartLabel={group.chartLabel}
                onClick={group.onClick}
                percentage={group.percentage}
                chartLabelAlign={"center"}
                fontSize={fontSize}
                styles={{
                  minWidth: "9em",
                  width: "9em",
                  justifyContent: "flex-start",
                }}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <Separator
        css={{
          [MediaQueries.largeAndDown]: { display: "none" },
          [MediaQueries.extraLarge]: { margin: "2.5em 2em 5em !important" },
        }}
      />
    </Fragment>
  );

  return (
    <ExceptionsPanel
      title={t("surgical-tote-tracking:Healthcare Container Tracking")}
      exceptionGroups={totalTotesChart.exceptionGroups}
      handleClickException={handleClickException}
      totalCountChartElement={totalCountChartElement}
      outerDivStyles={{ justifyContent: "flex-start !important" }}
      innerDivStyles={{ alignSelf: "flex-start !important" }}
    ></ExceptionsPanel>
  );
};

SurgicalToteTrackingCountsWidget.propTypes = {
  totesTotals: PropTypes.object,
  isTotesTotalsLoading: PropTypes.bool,
  searchEntities: PropTypes.func,
  setSearchFilter: PropTypes.func,
};
