import _ from "lodash";
import axios from "axios";
import apiUrl from "api-url";
import { combineReducers } from "redux";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const STORE_MOUNT_POINT = "damageViewFormSubmisson";

// Fetch ducks
const fieldsDuck = buildFetchDuck("fields", null, {});
const shippersDuck = buildFetchDuck("shipper", null);

//urls
const APPLICATION_BASE_URL = apiUrl("/damageview/submission");

const getImagesFormData = (data) => {
  const imagesData = [];
  Object.keys(data.uploadPhotos).forEach((key) => {
    data.uploadPhotos[key].files.forEach((file) => {
      imagesData.push({
        fileName: file.name,
        tagName: key,
        comments: data.uploadPhotos[key].comments,
      });
    });
  });
  return imagesData;
};

const updateFormData = (formData, fields) => {
  const initialData = {
    customFields: [],
  };

  fields.coreFields.forEach((field) => {
    initialData[field.fieldName] =
      field.isAsync &&
      field.valueType === "dropdown" &&
      formData[field.fieldName]
        ? formData[field.fieldName].value
        : formData[field.fieldName];
  });
  fields.submissionFields.forEach((field) => {
    initialData.customFields.push({
      fieldName: field.fieldName,
      value:
        field.isAsync &&
        field.valueType === "dropdown" &&
        formData[field.fieldName]
          ? formData[field.fieldName].value
          : formData[field.fieldName],
    });
  });

  const updatedVinData = formData.vinData.map((data) => {
    const customFields = [];
    fields.vinFields.forEach((field) => {
      customFields.push({
        fieldName: field.fieldName,
        value: data[field.fieldName],
      });
    });
    return {
      vin: data.vin.value,
      imageDetails: getImagesFormData(data),
      customFields: customFields,
    };
  });

  return {
    ...initialData,
    vinDetails: updatedVinData,
  };
};

const getPresignedUrl = (vinDetails, submission_id) => {
  const url = APPLICATION_BASE_URL + `/${submission_id}/presigned_url`;
  const imageData = {};
  const fileData = {};
  let vinValue = null;
  vinDetails.map((data) => {
    vinValue = data.vin.value;
    imageData[vinValue] = {};
    fileData[vinValue] = {};
    Object.keys(data.uploadPhotos).map((key) => {
      if (data.uploadPhotos[key].files.length > 0) {
        imageData[vinValue][key] = [];
        fileData[vinValue][key] = [];
        data.uploadPhotos[key].files.map((file) => {
          const imageProps = {
            fileName: file.name,
          };
          imageData[vinValue][key].push(imageProps);
          fileData[vinValue][key].push({ ...imageProps, file: file });
        });
      }
    });
    if (Object.keys(imageData[vinValue]).length === 0) {
      delete imageData[vinValue];
    }
  });
  if (Object.keys(imageData).length > 0) {
    axios.put(url, imageData).then(async (response) => {
      uploadPhotos(response?.data, fileData);
    });
  }
};

const uploadPhotos = (imagesUrlData, fileData) => {
  Object.keys(imagesUrlData).forEach((key) => {
    Object.keys(imagesUrlData[key]).forEach((category) => {
      imagesUrlData[key][category].forEach((data, index) => {
        const url = imagesUrlData[key][category][index].presignedUrl;
        const file = fileData[key][category][index].file;
        fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        }).catch((err) => {
          console.log(err);
        });
      });
    });
  });
};

// Action Creators

const submitDamageForm = (formData, fields, shipper) => {
  const updatedFormData = updateFormData(formData, fields);
  return (dispatch, getState) => {
    const state = getState();
    const solutionId = shipper ? shipper : getSolutionId(state);
    const url = APPLICATION_BASE_URL + `/solution/${solutionId}`;
    return axios
      .post(url, updatedFormData)
      .then((response) => {
        const submissionIds = response?.data?.submission_ids;
        submissionIds.map((data, index) => {
          getPresignedUrl([formData.vinData[index]], data);
        });

        return submissionIds ?? null;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };
};

function fetchFields(ShipperSolutionId, submissionId = "") {
  return (dispatch, getState) => {
    const state = getState();
    const solutionId = ShipperSolutionId
      ? ShipperSolutionId
      : getSolutionId(state);
    const url = solutionId
      ? apiUrl("/damageview") + `/solution_id/${solutionId}/fields`
      : apiUrl(`/damageview/submission/${submissionId}/fields`);
    dispatch(fieldsDuck.clear());
    dispatch(
      fieldsDuck.fetch(
        submissionId ? url : url + "?fieldsVersion=submissionForm",
      ),
    );
  };
}

function fetchShippers() {
  return (dispatch) => {
    const url = apiUrl("/entity-search/get_shippers");
    dispatch(shippersDuck.clear());
    dispatch(shippersDuck.fetch(url));
  };
}

// Selectors

const getFields = (state) => {
  return state[STORE_MOUNT_POINT].fields?.data ?? {};
};

const getFieldsLoading = (state) => {
  return state[STORE_MOUNT_POINT].fields?.isLoading ?? false;
};

const getShippers = (state) => {
  return state[STORE_MOUNT_POINT].shipper?.data ?? [];
};

const DamageViewDamageFormState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    submitDamageForm,
    fetchFields,
    fetchShippers,
  },
  selectors: {
    getFields,
    getFieldsLoading,
    getShippers,
  },
  reducer: combineReducers({
    fields: fieldsDuck.reducer,
    shipper: shippersDuck.reducer,
  }),
};
export default DamageViewDamageFormState;
