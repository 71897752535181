import apiUrl from "api-url";
import { buildReportsUnderFolder } from "pages/reports/redux/ReportsUnderFolderDirectoryState";

const STORE_MOUNT_POINT = "reportUnderFolder";

const getBasicQueryStringForFilters = (appliedFilters) => {
  let qs = "";
  appliedFilters.forEach((filter) => {
    qs += `&${filter["id"]}=${filter["value"]}`;
  });

  return qs;
};

const getReportsUrl = (
  directoryId,
  reportType,
  appliedFilters,
  sortColumn,
  reverseSort,
) => {
  let filtersQSP = appliedFilters
    ? getBasicQueryStringForFilters(appliedFilters)
    : "";

  filtersQSP += `&sortColumn=${sortColumn}&reverseSort=${reverseSort ? 1 : 0}`;

  return apiUrl(
    `/powerbi/pb_token?directoryCategory=${reportType.toLowerCase()}&directoryId=${directoryId}${filtersQSP}`,
  );
};

const ReportSubFolderState = buildReportsUnderFolder(
  STORE_MOUNT_POINT,
  getReportsUrl,
);
export default ReportSubFolderState;
