import axios from "axios";
import apiUrl from "api-url";
import moment from "moment";

const STORE_MOUNT_POINT = "carrierViewEntities";

// URLS
const getEntityInternalUrl = () => apiUrl(`/entity/internal`);

// Actions
const getCarrierViewActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;
const REQUEST_ENTITY_COUNT = getCarrierViewActionName("REQUEST_ENTITY_COUNT");
const RECEIVE_ENTITY_COUNT = getCarrierViewActionName("RECEIVE_ENTITY_COUNT");
const REQUEST_DELIVERED_ENTITY_COUNT = getCarrierViewActionName(
  "REQUEST_DELIVERED_ENTITY_COUNT",
);
const RECEIVE_DELIVERED_ENTITY_COUNT = getCarrierViewActionName(
  "RECEIVE_DELIVERED_ENTITY_COUNT",
);
const REQUEST_WATCHED_ENTITIES = getCarrierViewActionName(
  "REQUEST_WATCHED_ENTITIES",
);
const RECEIVE_WATCHED_ENTITIES = getCarrierViewActionName(
  "RECEIVE_WATCHED_ENTITIES",
);
const REQUEST_WATCHED_ENTITIES_COUNT = getCarrierViewActionName(
  "REQUEST_WATCHED_ENTITIES_COUNT",
);
const RECEIVE_WATCHED_ENTITIES_COUNT = getCarrierViewActionName(
  "RECEIVE_WATCHED_ENTITIES_COUNT",
);
const SET_WATCHED_ENTITIES_PAGE_INDEX = getCarrierViewActionName(
  "SET_WATCHED_ENTITIES_PAGE_INDEX",
);
const RECEIVE_WATCHED_ENTITIES_ERROR = getCarrierViewActionName(
  "RECEIVE_WATCHED_ENTITIES_ERROR",
);
const RECEIVE_WATCHED_ENTITIES_COUNT_ERROR = getCarrierViewActionName(
  "RECEIVE_WATCHED_ENTITIES_COUNT_ERROR",
);

// Action creators
function fetchEntityCount() {
  const countUrl = getEntityInternalUrl();

  const config = {
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
    params: {
      lifeCycleState: "Active",
    },
  };

  return (dispatch) => {
    dispatch({
      type: REQUEST_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchWatchedVins() {
  const url = getEntityInternalUrl();
  const params = {
    watched: 1,
    lifeCycleState: "Active,Delivered",
  };

  return async (dispatch, getState) => {
    const state = getState();
    params.pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
    params.pageNumber = state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;

    dispatch({
      type: REQUEST_WATCHED_ENTITIES,
    });

    try {
      const response = await axios.get(url, { params });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES,
        payload: { watchedEntities: response.data.data },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_ERROR, error });
    }
  };
}

function fetchWatchedEntitiesTotalPages() {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_WATCHED_ENTITIES_COUNT });

    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedEntitiesPageSize;

    const url = getEntityInternalUrl();
    const params = { watched: 1, pageNumber: 0, pageSize };
    const headers = { Accept: "application/json;version=count" };

    try {
      const response = await axios.get(url, { params, headers });
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES_COUNT,
        payload: { totalPages: response.data?.meta?.totalPages },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_COUNT_ERROR, error });
    }
  };
}

function setWatchedVinsPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_ENTITIES_PAGE_INDEX,
    payload: { pageIndex },
  };
}

function fetchEntityDeliveredCount() {
  const countUrl = getEntityInternalUrl();

  const config = {
    params: {
      lifeCycleState: "Delivered",
    },
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
  };

  return (dispatch) => {
    dispatch({
      type: REQUEST_DELIVERED_ENTITY_COUNT,
    });

    return Promise.all([axios.get(`${countUrl}`, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_DELIVERED_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Asynchronously return location search results for <Async> select component
function searchVINs(query) {
  if (query.length < 2) {
    return {
      options: [],
      hasMore: false,
    };
  }

  const url = getEntityInternalUrl();

  const params = {
    entityId: query,
    pageNumber: 0,
    pageSize: 20,
    lifeCycleState: "Active,Delivered",
    watched: 1,
  };

  return axios
    .get(url, { params })
    .then((response) => {
      if (
        !response ||
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        return { options: [], hasMore: false };
      } else {
        const vinOptions = buildSearchVinOptions(response.data.data);
        return {
          options: vinOptions,
          hasMore: false,
        };
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

// Convert location search response data into options for <Async> select and typeahead components
export function buildSearchVinOptions(data) {
  return data.map((l) => {
    try {
      return {
        value: l.id,
        label: l.id,
        id: l.id,
      };
    } catch (e) {
      console.error(e);
      return { options: [], hasMore: false };
    }
  });
}

// Selectors
const getEntityCount = (state) => state[STORE_MOUNT_POINT].entityCount ?? 0;
const getEntityCountLoading = (state) =>
  state[STORE_MOUNT_POINT].entityCountLoading;

const getEntityDeliveredCount = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCount ?? 0;
const getEntityCountDeliveredLoading = (state) =>
  state[STORE_MOUNT_POINT].entityDeliveredCountLoading;

const getWatchedVins = (state) => state[STORE_MOUNT_POINT].watchedEntities;
const getWatchedVinsLoading = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesLoading ||
  state[STORE_MOUNT_POINT].watchedEntitiesCountLoading;

const getWatchedVinsPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageIndex;
const getWatchedVinsPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageSize;
const getWatchedVinsPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedEntitiesPageCount;

// Initial state
const initialState = {
  entityCount: 0,
  entityCountLoading: false,
  watchedEntities: [],
  watchedEntitiesLoading: false,
  watchedEntitiesCountLoading: false,
  watchedEntitiesPageIndex: 0,
  watchedEntitiesPageSize: 10,
  watchedEntitiesPageCount: 0,
  entityDeliveredCount: 0,
};

const CarrierViewEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_COUNT:
      return {
        ...state,
        entityCountLoading: true,
      };

    case RECEIVE_ENTITY_COUNT:
      return {
        ...state,
        entityCount: action.payload,
        entityCountLoading: false,
      };

    case REQUEST_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCountLoading: true,
      };

    case RECEIVE_DELIVERED_ENTITY_COUNT:
      return {
        ...state,
        entityDeliveredCount: action.payload,
        entityDeliveredCountLoading: false,
      };

    case REQUEST_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntitiesLoading: true,
      };

    case REQUEST_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesCountLoading: true,
      };

    case RECEIVE_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntities: action.payload.watchedEntities,
        watchedEntitiesLoading: false,
      };

    case RECEIVE_WATCHED_ENTITIES_COUNT:
      return {
        ...state,
        watchedEntitiesPageCount: action.payload.totalPages,
        watchedEntitiesCountLoading: false,
      };

    case SET_WATCHED_ENTITIES_PAGE_INDEX:
      return {
        ...state,
        watchedEntitiesPageIndex: action.payload.pageIndex,
      };

    default:
      return state;
  }
};

// interface
const CarrierViewEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityCount,
    fetchEntityDeliveredCount,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    setWatchedVinsPageIndex,
    searchVINs,
  },
  selectors: {
    getEntityCount,
    getEntityCountLoading,
    getEntityDeliveredCount,
    getEntityCountDeliveredLoading,
    getWatchedVins,
    getWatchedVinsLoading,
    getWatchedVinsPageIndex,
    getWatchedVinsPageSize,
    getWatchedVinsPageCount,
  },
  reducer: CarrierViewEntityReducer,
};
export default CarrierViewEntitiesState;
