/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import * as pbi from "powerbi-client";
import ReportEditDetails from "./ReportEditDetailsComponent";
import { Privileges } from "modules/auth/Authorization";
import { getNowInUtc } from "utils/date-time";

// The pbi code is modeled after the code in the
// react-powerbi package, however the react
// component which that generated did not
// work directly, so instead, lifted the relavent
// pieces from there
const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

class ReportsComponent extends React.Component {
  static propTypes = {
    size: PropTypes.object.isRequired,
    report: PropTypes.object,
    setReport: PropTypes.func.isRequired,
    saveReport: PropTypes.func,
    isSaving: PropTypes.bool,
    updateReport: PropTypes.func,
    isEditing: PropTypes.bool,
    authorization: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.component = null;
    this.rootElement = null;
    this.state = {
      config: null,
      showSaveLocationModal: false,
      reportData: null,
      isEditMode: false,
    };
    this.reportEmbedded = false;

    this.onRetrievedFilters = this.onRetrievedFilters.bind(this);
  }

  componentDidMount() {
    const { report } = this.props;

    this.updateConfig(report);
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props;
    // Only embed once
    if (this.reportEmbedded === false) {
      if (this.validateConfig(this.state.config)) {
        this.reportEmbedded = true;
        this.embed(this.state.config);
      }
    }

    // If isEditing changes from true to false, hide the saveLocationModal.
    // Note: For isSaving, we redirect back to the BI Dashboard so it isn't neccessary
    // to hide the modal.
    if (isEditing === false && isEditing !== prevProps.isEditing) {
      this.setState({ showSaveLocationModal: false });
    }
  }

  componentWillUnmount() {
    powerbi.reset(this.rootElement);
    this.component = null;
  }

  embed(config) {
    const { setReport } = this.props;

    this.component = powerbi.embed(this.rootElement, config);

    // Remove events if they already exist, so we don't duplicate
    this.component.off("loaded");
    this.component.off("saved");

    // Add events
    this.component.on("loaded", this.onLoadedTriggered);
    this.component.on("saved", this.onSavedTriggered);

    setReport(this.component);

    return this.component;
  }

  onLoadedTriggered = () => {
    const { report } = this.props;
    const { groupName } = report;

    if (groupName === "Report Builder" || groupName === "Saved") {
      this.component
        .getPages()
        .then(function (pages) {
          pages[0].setActive().catch(function (errors) {
            console.error("Error setting first page", errors);
          });
        })
        .catch(function (errors) {
          console.error("Error getting report pages", errors);
        });
    }

    // For filtered reports only
    if (report.filterSet && report.filtersDetail) {
      // Parse returned filters JSON
      let filtersDetail = report.filtersDetail;
      if (typeof report.filtersDetail === "string") {
        filtersDetail = JSON.parse(report.filtersDetail);
      }

      // Filters need to be arrays being passed to PowerBI
      if (!Array.isArray(filtersDetail)) {
        filtersDetail = [filtersDetail];
      }

      if (filtersDetail.length > 0) {
        // Get all existing filters
        this.component
          .getFilters()
          .then((existingFilters) =>
            this.onRetrievedFilters(existingFilters, filtersDetail),
          )
          .catch(function (errors) {
            console.error("Error getting existing filters", errors);
          });
      }
    }
  };

  onRetrievedFilters = (existingFilters, specifiedFilters) => {
    // Apply existing filters concatenated with specified filters.
    // This is so that we still see all existing filters and not just
    // ones the user has specified.

    // Remove any specified filters from existing filters so we don't have duplicates.
    const existingFiltersWithoutSpecifiedFilters = existingFilters.filter(
      function (obj) {
        return !specifiedFilters.some(function (obj2) {
          // Match against the target's table and column.
          // If they match, ignore it from the existingFilters.
          return (
            obj.target.table === obj2.target.table &&
            obj.target.column === obj2.target.column
          );
        });
      },
    );

    // Append specified filters to existing filters.
    const concatFilters = specifiedFilters.concat(
      existingFiltersWithoutSpecifiedFilters,
    );

    // Apply full filter set.
    this.component.setFilters(concatFilters).catch(function (errors) {
      console.error("Error setting report filters", errors);
    });
  };

  onSavedTriggered = (event) => {
    if (!event || !event.detail) {
      return;
    }

    const { report } = this.props;

    if (event.detail.saveAs === true) {
      // Create a copy of a report
      const { workspaceId, groupName, description, name } = report;

      // If we're saving from a previously Saved report,
      // set desc to the previous report's description.
      // Otherwise set to null.
      let currentDescription = null;
      if (groupName === "Saved") {
        currentDescription = description;
      }

      let data = {
        workspace_number: workspaceId,
        report_number: event.detail.reportObjectId,
        report_name: event.detail.reportName,
        report_description: currentDescription,
        group_name: "Saved",
        source: "report_builder",
        original_report_name: name,
        report_mode: "Edit",
      };

      this.setState({
        reportData: data,
        showSaveLocationModal: true,
        isEditMode: false,
      });
    } else {
      // Update the report
      const { reportId, description } = report;

      let data = {
        report_number: reportId,
        report_description: description,
        updated_at: getNowInUtc("YYYY-MM-DDTHH:mm:ss"),
      };

      this.setState({
        reportData: data,
        showSaveLocationModal: true,
        isEditMode: true,
      });
    }
  };

  updateConfig(report) {
    if (!report) {
      return;
    }

    const { authorization } = this.props;
    const { accessToken, reportId, groupName, embedUrl } = report;

    // Users with the Report Builder role can update or copy any report under My Reports or Shared Reports
    // As well as make copies of the Report Builder
    const canCreateOrUpdateReport = authorization.hasPrivileges([
      Privileges.BUILD_REPORT,
    ]);

    let viewMode = null;
    let permissions = null;
    switch (groupName) {
      case "Report Builder": {
        if (canCreateOrUpdateReport === true) {
          viewMode = pbi.models.ViewMode.Edit;
          permissions = pbi.models.Permissions.Copy;
        } else {
          viewMode = pbi.models.ViewMode.View;
          permissions = pbi.models.Permissions.Read;
        }
        break;
      }
      case "Saved": {
        if (canCreateOrUpdateReport === true) {
          viewMode = pbi.models.ViewMode.Edit;
          permissions = pbi.models.Permissions.All;
        } else {
          viewMode = pbi.models.ViewMode.View;
          permissions = pbi.models.Permissions.Read;
        }
        break;
      }
      default: {
        viewMode = pbi.models.ViewMode.View;
        permissions = pbi.models.Permissions.Read;
      }
    }

    const config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: reportId,
      permissions: permissions,
      viewMode: viewMode,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        layoutType: undefined,
      },
    };

    this.setState({ config: config });
  }

  validateConfig(config) {
    if (config === null) {
      return false;
    }

    if (config.accessToken === undefined) {
      return false;
    }

    const errors = pbi.models.validateReportLoad(config);

    return errors === undefined;
  }

  render() {
    const { size, saveReport, updateReport, isSaving, isEditing } = this.props;
    const { showSaveLocationModal, reportData, isEditMode } = this.state;

    // Subtract 40 pixels to account for the
    // height of the print icon
    const dimensions = {
      width: size.width,
      height: size.height - 40,
    };

    if (_.isNil(dimensions.width)) {
      return <div />;
    }

    return (
      <>
        <ReportEditDetails
          show={showSaveLocationModal}
          onHide={() => {
            this.setState({ showSaveLocationModal: false });
          }}
          reportData={reportData}
          isEditMode={isEditMode}
          saveReport={saveReport}
          updateReport={updateReport}
          isSaving={isSaving}
          isEditing={isEditing}
        />
        <div
          className="powerbi-frame"
          ref={(el) => {
            this.rootElement = el;
          }}
          style={dimensions}
        />
      </>
    );
  }
}

export default ReportsComponent;
