/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Loader from "react-loader";
import { Table } from "react-bootstrap";

import StatusUpdateDetailState from "./StatusUpdateDetailState";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import Colors from "../../styles/colors";
import { IdBox } from "components/multimodal-components/InfoBoxes";
const RevealedDetailsTable = ({ original, detailsData }) => {
  // note: per Craig, we are ignoring translation until requested by a customer
  // (as of Jan 2020)
  const partnerData = _.get(original, "partnerRef", []);
  const partnerRows = partnerData.map((partnerRef) => ({
    label: "Partner Name",
    value: partnerRef.org_name,
  }));

  const statusRefData = _.get(detailsData, "statusRef", []);
  const statusRefRows = statusRefData.map((refRow) => {
    const { qualifier, value } = refRow;

    const isLocationField = ["Ship To", "Ship From"].includes(qualifier);

    return {
      label: qualifier,
      value,
      // We want the additional info from this statusRef if it is a location
      // refRow's for locations contain
      // { qualifier, value, name, code, address, address2, city, state, postal_code, country }
      ...(isLocationField ? refRow : {}),
      isLocationField,
    };
  });

  // Add ITSS IDs row if data is available
  // Note: ITSS IDs are an instance of a group-category
  const categoryIdsData = _.get(detailsData, "entityGroupCategories");
  let categoryIdsRow = null;
  if (!_.isNil(categoryIdsData) && categoryIdsData.length > 0) {
    categoryIdsRow = {
      label: "ITSS IDs",
      value: categoryIdsData.join("\n"),
    };
  }

  const rows = [...partnerRows, ...statusRefRows];

  if (!_.isNil(categoryIdsRow)) {
    rows.push(categoryIdsRow);
  }

  const rowElems = rows.map((row) => {
    return (
      <tr key={`detail-${original.id}-${row.label}`}>
        <td
          css={{
            verticalAlign: "top",
          }}
        >
          {row.label}
        </td>
        <td
          css={{
            // This is so that the newline characters actually wrap to the next line
            // By default, newline characters show as a space
            // Used for the ITSS IDs value
            whiteSpace: "pre-line",
          }}
        >
          {row.isLocationField
            ? [
                <IdBox
                  css={{
                    fontWeight: "normal !important",
                    fontSize: "1em !important",
                  }}
                  data-qa="text-name-location"
                >
                  {row.name}
                </IdBox>,
                <LocationAddressComplex
                  address1={row.address1}
                  address2={row.address2}
                  city={row.city}
                  state={row.state}
                  postalCode={row.postal_code}
                  country={row.country}
                  addressColor={Colors.text.GRAY}
                />,
              ]
            : row.value}
        </td>
      </tr>
    );
  });

  return (
    <Table css={{ minWidth: "40em", marginTop: 10 }}>
      <tbody>{rowElems}</tbody>
    </Table>
  );
};

RevealedDetailsTable.propTypes = {
  original: PropTypes.shape({
    id: PropTypes.any,
    partnerRef: PropTypes.arrayOf(
      PropTypes.shape({
        org_name: PropTypes.string,
      }),
    ),
  }),
  detailsData: PropTypes.shape({
    statusRef: PropTypes.arrayOf(
      PropTypes.shape({
        qualifier: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
    entityGroupCategories: PropTypes.arrayOf(PropTypes.string),
  }),
};

const { fetchStatusUpdateDetails } = StatusUpdateDetailState.actionCreators;
const { getStatusUpdateDetailsForId } = StatusUpdateDetailState.selectors;

const useStatusUpdateDetails = (statusUpdateId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatusUpdateDetails(statusUpdateId));
  }, [statusUpdateId, dispatch]);

  return useSelector(getStatusUpdateDetailsForId(statusUpdateId));
};

export const MilestoneDashboardExpandedRow = (props) => {
  const { t } = useTranslation("milestone-search");
  const { row } = props;
  const { original } = row;
  const statusUpdateId = original.id;
  const { data, loading } = useStatusUpdateDetails(statusUpdateId);
  return (
    <div
      css={{
        padding: "1em 2em",
        borderBottom: "1px solid rgba(0,0,0,0.05)",
      }}
    >
      <div className="d-inline position-relative">
        <h3 className="d-inline">{t("milestone-search:Details")}</h3>
        <Loader loaded={!loading} left="calc(100% + 1em)" scale={0.5} />
      </div>
      <RevealedDetailsTable original={original} detailsData={data} />
    </div>
  );
};

MilestoneDashboardExpandedRow.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};
