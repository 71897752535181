import _ from "lodash";
import { getDayOfWeekFromIndex } from "utils/translation-utils";

export const convertEtaTriggerToTableRows = (etaTrigger = {}, t) => {
  if (_.isNil(etaTrigger)) {
    return [];
  }

  const rows = [];

  // etaCalculationEntityStatusCode
  rows.push({
    name: t("vin-eta-validator:ETA Calculation Entity Status Code"),
    value: displayNAIfNull(etaTrigger.etaCalculationEntityStatusCode, t),
  });

  rows.push({
    name: t("vin-eta-validator:Point of Interest"),
    value: displayNAIfNull(etaTrigger.etaCalculationPointOfInterest, t),
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Latitude"),
    value: displayNAIfNull(etaTrigger.etaCalculationLatitude, t),
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Longitude"),
    value: displayNAIfNull(etaTrigger.etaCalculationLongitude, t),
  });

  rows.push({
    name: t("shipment-eta-validator:Number of Observations in Model"),
    value: displayNAIfNull(etaTrigger.numberOfObservationsInModel, t),
  });

  rows.push({
    name: t("shipment-eta-validator:Shipment ID"),
    value: displayNAIfNull(etaTrigger.shipmentId, t),
  });

  rows.push({
    name: t("shipment-eta-validator:Shipment Status ID"),
    value: displayNAIfNull(etaTrigger.shipmentStatusId, t),
  });

  rows.push({
    name: t("shipment-eta-validator:Expired Calculation"),
    value: displayNAIfNull(etaTrigger.expiredCalculation, t),
  });

  rows.push({
    name: t("shipment-eta-validator:Carrier Organization ID"),
    value: displayNAIfNull(etaTrigger.carrierOrganizationId, t),
  });

  rows.push({
    name: t("vin-eta-validator:ETA Calculation Name"),
    value: displayNAIfNull(etaTrigger.etaCalculationName, t),
  });

  rows.push({
    name: t("vin-eta-validator:Scheduled Delivery"),
    value: displayNAIfNull(etaTrigger.scheduledDeliveryWindow, t),
  });

  rows.push({
    name: t("vin-eta-validator:Carrier"),
    value: {
      // TODO: Verify source of these values.
      name: etaTrigger.carrierOrganizationName,
      scac: etaTrigger.carrierOrganizationScac,
    },
    type: "organization",
  });

  const dayOfWeek = getDayOfWeekFromIndex(etaTrigger.dayOfWeek, t);
  rows.push({
    name: t("vin-eta-validator:Day of Week"),
    value: displayNAIfNull(dayOfWeek, t),
  });

  rows.push({
    name: t("vin-eta-validator:Next Planned Location"),
    value: displayNAIfNull(etaTrigger.nextPlannedLocationId, t),
    // Used to know how to render this value in the modal columns.
    type: "location",
  });

  rows.push({
    name: t("vin-eta-validator:State"),
    value: displayNAIfNull(etaTrigger.state, t),
  });

  rows.push({
    name: t("vin-eta-validator:Average"),
    value: getAverageName(etaTrigger.average, t),
  });

  rows.push({
    name: t("vin-eta-validator:Shipments Count"),
    value: displayNAIfNull(etaTrigger.shipmentsCount, t),
  });

  return rows;
};

const displayNAIfNull = (value, t) => {
  return value !== null ? value : t("vin-validator-tool:N/A");
};

export const getAverageName = (average, t) => {
  if (_.isNil(average)) {
    return t("vin-validator-tool:N/A");
  }

  switch (average) {
    case "GT3":
      return t("vin-validator-tool:Greater than 3");
    case "LT3":
      return t("vin-validator-tool:Less than 3");
    default:
      return average;
  }
};

/**
 * Format a result from the API for use in the search results table.
 *
 * PLAT-43: Successful triggers and errors come as different objects.
 * We want each object used in the rows to be the same shape so the table
 * doesn't have to do too much work in the columns.
 *
 * @param {object} result
 * @param {object} entity
 * @returns object
 */
export const formatResult = (result = {}, entity) => {
  if (result.error) {
    return {
      etaCalculationEntityStatusCode: null,
      etaCalculationPointOfInterest: null,
      etaCalculationLatitude: null,
      etaCalculationLongitude: null,
      etaCalculationCity: null,
      etaCalculationState: null,
      etaCalculationCountry: null,
      etaModelName: null,
      numberOfDaysInHistory: null,
      percentileUsedInCalculation: null,
      etaModelVersion: null,
      numberOfObservationsInModel: null,
      shipmentId: null,
      shipmentStatusId: null,
      expiredCalculation: null,
      carrierOrganizationId: null,
      etaCalculation: null,
      etaDate: null,
      entityTripPlanCompleteTime: null,
      entityUltimateDestinationCode: null,
      entityUltimateDestinationName: null,
      destinationCity: null,
      destinationState: null,
      etaDifference: null,
      etaCalculationTime: null,
      carrier: null,
      dayOfWeek: null,
      nextPlannedLocationId: null,
      state: null,
      average: null,
      shipmentsCount: null,
      triggerType: null,
      triggerMode: null,
      triggerLocationName: null,
      lifeCycleState: null,
      etaCalculationName: null,
      scheduledDeliveryWindow: null,
    };
  }

  return {
    etaCalculationEntityStatusCode: result.etaCalculationEntityStatusCode,
    etaCalculationPointOfInterest: result.etaCalculationPointOfInterest,
    etaCalculationLatitude: result.etaCalculationLatitude,
    etaCalculationLongitude: result.etaCalculationLongitude,
    etaCalculationCity: result.etaCalculationCity,
    etaCalculationStateOrProvince: result.etaCalculationState,
    etaCalculationCountry: result.etaCalculationCountry,
    etaModelName: result.etaModelName,
    numberOfDaysInHistory: result.numberOfDaysInHistory,
    percentileUsedInCalculation: result.percentileUsedInCalculation,
    etaModelVersion: result.etaModelVersion,
    numberOfObservationsInModel: result.numberOfObservationsInModel,
    shipmentId: result.shipmentId,
    shipmentStatusId: result.shipmentStatusId,
    expiredCalculation: result.expiredCalculation,
    carrierOrganizationId: result.carrierOrganizationId,
    etaCalculation: result.etaCalculation,
    etaDate: result.etaDate,
    entityTripPlanCompleteTime: result.entityTripPlanCompleteTime,
    entityUltimateDestinationCode: result.entityUltimateDestinationCode,
    entityUltimateDestinationName: result.entityUltimateDestinationName,
    destinationCity: result.destinationCity,
    destinationState: result.destinationState,
    etaDifference: result.etaDifference,
    etaCalculationTime: result.etaCalculationTime,
    carrier: result.carrier,
    dayOfWeek: result.dayOfWeek,
    nextPlannedLocationId: result.nextPlannedLocationId,
    state: result.state,
    average: result.average,
    shipmentsCount: result.shipmentsCount,
    triggerType: result.triggerType,
    triggerMode: result.triggerMode,
    triggerLocationName: result.triggerLocationName,
    lifeCycleState: result.lifeCycleState,
    etaCalculationName: result.etaCalculationName,
    scheduledDeliveryWindow: result.scheduledDeliveryWindow,
  };
};
