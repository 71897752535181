import axios from "axios";
import apiUrl from "api-url";

import { BulkActionType } from "../utils/enums.utils";

const DRIVE_AWAY_BULK_URL = apiUrl(`/dda/internal/bulk/drive-away`);
const DRIVE_AWAY_BULK_APPROVE_DENY_URL = apiUrl(`/dda/dda/drive-away/bulk`);

const STORE_MOUNT_POINT = "driveAwayBulkAction";

// Actions
const BULK_ACTION_SUBMITTED = `${STORE_MOUNT_POINT}/BULK_ACTION_SUBMITTED`;
const BULK_ACTION_CANCELED = `${STORE_MOUNT_POINT}/BULK_ACTION_CANCELED`;
const BULK_ACTION_SUCCESS = `${STORE_MOUNT_POINT}/BULK_ACTION_SUCCESS`;
const BULK_ACTION_ERROR = `${STORE_MOUNT_POINT}/BULK_ACTION_ERROR`;
const BULK_ACTION_NOT_ALL_SUCCESS = `${STORE_MOUNT_POINT}/BULK_ACTION_NOT_ALL_SUCCESS`;
const RESET_BULK_ACTION_STATUS = `${STORE_MOUNT_POINT}/RESET_BULK_ACTION_STATUS`;

// Initial state
export const initialState = {
  csvData: null,
  requesterPhoneNum: null,
  bulkActionStatus: null,
  showBulkActionModal: false,
  results: null,
};

// Selector
const getBulkActionStatus = (state) => {
  return state[STORE_MOUNT_POINT].bulkActionStatus;
};

const getRequestResults = (state) => {
  return state[STORE_MOUNT_POINT].requestResults;
};

// Action creators
function bulkActionSubmit(
  bulkActionType,
  csvData,
  requesterPhoneNum,
  requesterPhoneNumberExt,
) {
  const url =
    bulkActionType == BulkActionType.BULK_SUBMIT
      ? DRIVE_AWAY_BULK_URL
      : DRIVE_AWAY_BULK_APPROVE_DENY_URL;

  const headers = {
    Accept: "text/csv;version=dda_mass_submit",
    "content-type": "text/csv",
  };

  if (bulkActionType === BulkActionType.BULK_APPROVE) {
    headers.Accept = "text/csv;version=dda_mass_approve";
  }
  if (bulkActionType === BulkActionType.BULK_DENY) {
    headers.Accept = "text/csv;version=dda_mass_deny";
  }

  const postData = {
    csvData: csvData,
  };

  if (requesterPhoneNum) {
    if (requesterPhoneNumberExt) {
      postData.dealerPhoneNumber = `${requesterPhoneNum}#${requesterPhoneNumberExt}`;
    } else {
      postData.dealerPhoneNumber = requesterPhoneNum;
    }
  }

  const axiosRequestConfig = {
    url: url,
    method: bulkActionType === BulkActionType.BULK_SUBMIT ? "post" : "patch",
    headers: headers,
    data: postData,
  };

  return (dispatch) => {
    dispatch({
      type: BULK_ACTION_SUBMITTED,
      payload: postData,
    });
    return axios
      .request(axiosRequestConfig)
      .then((response) => {
        if (!response.data.status) {
          throw new Error("Missing data");
        }
        if (response.data.status === "FAILED") {
          if (!response.data.vins_and_reasons) {
            throw new Error("Missing data");
          }
          dispatch({
            type: BULK_ACTION_NOT_ALL_SUCCESS,
            payload: response.data.vins_and_reasons,
          });
        } else {
          dispatch({
            type: BULK_ACTION_SUCCESS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: BULK_ACTION_ERROR,
        });
      });
  };
}

function resetBulkActionStatus() {
  return (dispatch) => {
    dispatch({ type: RESET_BULK_ACTION_STATUS });
  };
}

// Reducer
function RequestDriveAwayReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_BULK_ACTION_STATUS:
      return Object.assign({}, state, {
        csvData: null,
        requesterPhoneNum: null,
        bulkActionStatus: null,
        showBulkActionModal: false,
        requestResults: null,
      });
    case BULK_ACTION_SUBMITTED:
      return Object.assign({}, state, {
        bulkActionStatus: "IN_PROGRESS",
      });
    case BULK_ACTION_CANCELED:
      return Object.assign({}, state, {
        bulkActionStatus: "CANCEL",
      });
    case BULK_ACTION_SUCCESS:
      return Object.assign({}, state, {
        bulkActionStatus: "SUCCESS",
      });
    case BULK_ACTION_NOT_ALL_SUCCESS:
      return Object.assign({}, state, {
        bulkActionStatus: "NOT_ALL_SUCCESS",
        requestResults: action.payload,
      });
    case BULK_ACTION_ERROR:
      return Object.assign({}, state, {
        bulkActionStatus: "ERROR",
      });
    default:
      return state;
  }
}

// interface
const DriveAwayBulkActionState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    bulkActionSubmit,
    resetBulkActionStatus,
  },
  selectors: {
    getBulkActionStatus,
    getRequestResults,
  },
  reducer: RequestDriveAwayReducer,
};

export default DriveAwayBulkActionState;
