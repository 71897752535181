/** @jsxImportSource @emotion/react */
/**
 * It was created just to avoid complex prop drilling on this
 * component, once we need `lads` inside it.
 *
 */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { FlexColDiv } from "styles/container-elements";

function getBay(ref) {
  let comp = _.find(ref.references, { qualifier: "bayLocation" });
  return comp;
}

function getCompound(ref) {
  let comp = _.find(ref.references, { qualifier: "compoundCode" });
  return comp;
}

function getIsHold(ref) {
  // H2-3840: 4D signifies VTIMS code.
  // https://freightverify.atlassian.net/wiki/spaces/DE/pages/1447133185/VTIMS+Codes
  return ref?.isHold && ref?.holdLabel;
}

export const VinMilestoneCell = ({ lastMilestone }) => {
  const { t } = useTranslation("components");

  if (_.isNil(lastMilestone) || _.isEmpty(lastMilestone)) {
    return <div />;
  }

  if (getIsHold(lastMilestone)) {
    return (
      <FlexColDiv>
        <Text>{lastMilestone?.holdLabel}</Text>
      </FlexColDiv>
    );
  }

  const bay = getBay(lastMilestone);
  const compound = getCompound(lastMilestone);

  return (
    <FlexColDiv>
      <Text>
        {lastMilestone.codeDescription}{" "}
        {lastMilestone.code ? `(${lastMilestone.code})` : ""}
      </Text>
      {compound && (
        <div className="d-flex flex-wrap">
          <Text bold>{t("components:Compound")}:</Text>
          <Text className="ms-1">{compound.value}</Text>
        </div>
      )}
      {bay && (
        <div className="d-flex flex-wrap">
          <Text bold>{t("components:Bayed At")}:</Text>
          <Text className="ms-1">{bay.value}</Text>
        </div>
      )}
      <div className="d-flex flex-wrap">
        <Text bold>{t("components:Event Time")}:</Text>
        <DateTime
          dateTime={lastMilestone.eventTs}
          localize
          plain
          className="ms-1"
        />
      </div>
    </FlexColDiv>
  );
};

VinMilestoneCell.propTypes = {
  lastMilestone: PropTypes.object,
};
