import Colors from "styles/colors";

export const getExceptionChartData = (
  exceptions,
  totalCount,
  totalExceptionsCount,
) => {
  if (!exceptions || totalCount === 0) {
    return [{ x: "none", y: 1, fill: Colors.background.GRAY }];
  }

  return [
    ...exceptions,
    {
      name: "No Exception",
      count: totalCount - totalExceptionsCount || 0,
      icon: { color: Colors.highlight.GREEN },
    },
  ].map((exception) => {
    return {
      x: exception.name,
      y:
        totalCount && exception.count > 0
          ? (exception.count / totalCount) * 100
          : 0,
      fill: exception.icon?.color,
    };
  });
};
