/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";

import Form from "react-bootstrap/Form";
import { SelectAsync } from "components/atoms/SelectAsync.atom";
import Select from "react-select";

import { useNotificationManagementTranslations } from "pages/administration/notification-management/hooks/useNotificationManagementTranslations";
import { FilterTypesEnum } from "../../notificationmanagement.const";
import { useFilterOptionListLoadOptions } from "../../hooks/useFilterOptionListLoadOptions";

export const FilterCriteriaSelector = (props) => {
  const {
    availableFilters,
    filterCriterion,
    filterCriteriaSelectHandler,
    eventType,
  } = props;

  const { t } = useTranslation("notification-management");
  const { filterTypeValueToTranslatedLabel } =
    useNotificationManagementTranslations();
  const { getFilterOptionListLoadOptions } = useFilterOptionListLoadOptions();

  const selectedFilterType = filterCriterion.filterType;
  const selectedFilterOption = filterCriterion.filterOptions;
  const filterOptionsValid = filterCriterion.filterOptionsValid;

  return (
    <>
      <div
        css={{
          maxWidth: "auto",
          marginRight: "0.2em",
        }}
        data-qa="notification-management-filter-criteria-selector-div"
      >
        <Select
          css={{ width: "12rem" }}
          value={
            selectedFilterType
              ? {
                  value: selectedFilterType,
                  label: filterTypeValueToTranslatedLabel(selectedFilterType),
                }
              : null
          }
          onChange={(selectedOption) =>
            filterCriteriaSelectHandler(
              availableFilters.find(
                (filter) => filter.value === selectedOption.value,
              ),
              null,
            )
          }
          options={availableFilters.map((availableFilter) => ({
            value: availableFilter.value,
            label: filterTypeValueToTranslatedLabel(availableFilter.value),
          }))}
          isSearchable={false}
          placeholder={t("notification-management:Filter")}
          noOptionsMessage={() =>
            t("notification-management:No Available Filters")
          }
          data-qa="notification-management-filter-criteria-selector-select"
        />
      </div>
      <div
        css={{
          pointerEvents: selectedFilterType ? "auto" : "none",
          opacity: selectedFilterType ? "100%" : "40%",
          width: "100%",
        }}
        data-qa="notification-management-filter-criteria-selector-div2"
      >
        {selectedFilterType === FilterTypesEnum.MILES_OUT ? (
          <>
            <Form.Control
              placeholder={t("notification-management:Enter Miles Out")}
              value={selectedFilterOption}
              onChange={(e) => {
                filterCriteriaSelectHandler(null, [e.target.value]);
              }}
              isInvalid={!filterOptionsValid}
              css={{
                borderRadius: "0.25rem !important",
                minHeight: "38px",
              }}
              data-qa="notification-management-filter-criteria-selector-form-control"
            ></Form.Control>
          </>
        ) : (
          <SelectAsync
            isMulti={filterCriterion?.allowMultiSelect}
            showCheckbox={filterCriterion?.allowMultiSelect}
            closeMenuOnSelect={!filterCriterion?.allowMultiSelect}
            hideSelectedOptions={false}
            placeholder={t("notification-management:Select Option")}
            value={selectedFilterOption}
            styles={{
              valueContainer: (provided) => ({
                ...provided,
                padding: "2px 4px",
              }),
              multiValue: (provided) => ({
                ...provided,
                maxWidth: "140px",
              }),
            }}
            loadOptions={(query, loadedOptions) =>
              getFilterOptionListLoadOptions(
                selectedFilterType,
                query,
                loadedOptions,
                eventType,
              )
            }
            onChange={(values) => filterCriteriaSelectHandler(null, values)}
            data-qa="notification-management-filter-criteria-selector-async-paginate"
          />
        )}
      </div>
    </>
  );
};

FilterCriteriaSelector.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  filterCriterion: PropTypes.object.isRequired,
  filterCriteriaSelectHandler: PropTypes.func.isRequired,
  eventType: PropTypes.string,
};
