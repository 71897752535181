/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { LinkPanels } from "components/templates/LinkPanels.template";
import { LinkPanel } from "components/molecules/LinkPanel.molecule";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { FontSize, Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";

const CellRenderer = (props) => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>{props.value}</div>
  );
};

CellRenderer.propTypes = {
  value: PropTypes.string,
};

const LinkRenderer = (props) => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>
      <a href={`${props.value}`} target="_blank" rel="noreferrer">
        {props.value}
      </a>
    </div>
  );
};

LinkRenderer.propTypes = {
  value: PropTypes.string,
};

export const externalLinksColumns = (columns) => {
  return columns.map((column) => {
    if (column.type === "string") {
      return {
        Header: column.name,
        accessor: column.accessor,
        width: 200,
        Cell: CellRenderer,
      };
    } else if (column.type === "hyperlink") {
      return {
        Header: column.name,
        accessor: column.accessor,
        width: 200,
        Cell: LinkRenderer,
      };
    }
  });
};

const ContentJson = ({ content, pushDocumentView }) => {
  const { t } = useTranslation("documentation-remote");

  if (!content) {
    return null;
  }

  return (
    <Fragment>
      {content.Widgets ? (
        <LinkPanels title={t(`documentation-remote:${content.title}`)}>
          {_.values(content.Widgets).map((widget, i) => {
            return (
              <LinkPanel
                key={uuidv4()}
                title={t(`documentation-remote:${widget.Title}`)}
                description={t(`documentation-remote:${widget.Description}`)}
                onClick={() =>
                  pushDocumentView(
                    widget.file,
                    widget?.baseUrl?.toLowerCase().startsWith("http")
                      ? "DOCUMENTATION_URL"
                      : widget.type,
                    widget.baseUrl,
                  )
                }
              />
            );
          })}
        </LinkPanels>
      ) : (
        <div css={{ padding: "1em" }}>
          {content.Tables ? (
            <Fragment>
              <div
                css={{ marginBottom: "2em", borderBottom: "1px solid #ddd" }}
              >
                {/* Title */}
                <Text
                  block
                  size={FontSize.size24}
                  color={Colors.background.GRAY_BLUE}
                  css={{ marginBottom: "1em" }}
                >
                  {content.title}
                </Text>
              </div>
              {_.values(content.Tables).map((table) => {
                return (
                  <BaseTable
                    data={table.rows}
                    isLoading={false}
                    columns={externalLinksColumns(table.columns)}
                    showPagination={false}
                    disableSortBy={true}
                    fixPaginationToBottom={false}
                    isManualPagination={true}
                    key={uuidv4()}
                  />
                );
              })}
            </Fragment>
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

ContentJson.propTypes = {
  content: PropTypes.object,
  pushDocumentView: PropTypes.func,
};
export default ContentJson;
