/** @jsxImportSource @emotion/react */
import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FormLabel } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

import { useExternalCodeDefinitionTranslation } from "shared/utils/organizations.utils";

const createOption = (label) => ({
  label,
  value: label,
});

export const ExternalCodeField = (props) => {
  const { externalCodeName, style, onChange, prefilledValues } = props;

  const { getExternalCodeDefinitionTranslation } =
    useExternalCodeDefinitionTranslation();

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const initValues = useCallback(() => {
    setValue(prefilledValues);
    onChange(externalCodeName, prefilledValues);
  }, [prefilledValues, onChange, externalCodeName]);

  useEffect(() => {
    initValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => {
          const hasDuplicatedValue = prev?.some((previousInput) => {
            return previousInput.value === inputValue.toUpperCase();
          });
          if (hasDuplicatedValue) {
            setInputValue("");
            event.preventDefault();
            return [...(prev ? prev : [])];
          }
          const newVal = [
            ...(prev ? prev : []),
            createOption(inputValue.toUpperCase()),
          ];
          onChange(externalCodeName, newVal);
          return newVal;
        });
        setInputValue("");
        event.preventDefault();
      default:
        return;
    }
  };

  const handleOnBlur = () => {
    if (inputValue?.length !== 0) {
      setValue((prev) => {
        const hasDuplicatedValue = prev?.some((previousInput) => {
          return previousInput.value === inputValue.toUpperCase();
        });
        if (hasDuplicatedValue) {
          setInputValue("");
          return [...(prev ? prev : [])];
        }
        const newVal = [
          ...(prev ? prev : []),
          createOption(inputValue.toUpperCase()),
        ];
        onChange(externalCodeName, newVal);
        return newVal;
      });
      setInputValue("");
    }
  };

  const handleOnChange = (newVal) => {
    setValue(newVal);
    onChange(externalCodeName, newVal);
  };

  const creatableSelectStyles = {
    multiValue: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "1em",
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "0 50% 50% 0",
    }),
    container: (baseStyles) => ({
      ...baseStyles,
      minHeight: "2.5em",
    }),
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        "&:hover": { borderColor: "rgb(206, 212, 218)" },
        minHeight: "2.5em",
        borderColor: "rgb(206, 212, 218)",
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
          : "",
      };
    },
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: "0 8px",
    }),
  };

  return (
    <div style={style}>
      <FormLabel style={{ fontWeight: "normal" }}>
        {getExternalCodeDefinitionTranslation(externalCodeName)}
      </FormLabel>
      <CreatableSelect
        components={{
          DropdownIndicator: null,
        }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onBlur={handleOnBlur}
        onChange={(newValue) => handleOnChange(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder=""
        value={value}
        styles={creatableSelectStyles}
      />
    </div>
  );
};

ExternalCodeField.propTypes = {
  externalCodeName: PropTypes.string.isRequired,
  style: PropTypes.object,
  onChange: PropTypes.func,
  prefilledValues: PropTypes.array,
};
