/** @jsxImportSource @emotion/react */

// External
// import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { DriveAwaySavedSearch } from "./DriveAway.SavedSearch.organism";
import DriveAwayEditSavedSearchModalContainer from "../../../components/search/DriveAway.EditSavedSearchModal.container";

// State
import DriveAwaySearchBarState from "../../../redux/DriveAwaySearchBarState";
import DriveAwaySavedSearchState from "../../../redux/DriveAwaySavedSearchState";

export const DriveAwaySavedSearchesPanel = () => {
  const savedSearches = useSelector(
    DriveAwaySavedSearchState.selectors.getSavedSearches,
  );

  const getIsSavedSearchLoading = useSelector(
    DriveAwaySavedSearchState.selectors.getIsLoading,
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);

  useEffect(() => {
    dispatch(DriveAwaySavedSearchState.actionCreators.fetchSavedSearches());
  }, [dispatch]);

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={getIsSavedSearchLoading}
        onAddSavedSearch={() => {
          setShowModal(true);
        }}
        CardComponent={DriveAwaySavedSearch}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                DriveAwaySavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal(true);
            },
          };
        }}
      />
      <DriveAwayEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal}
        hide={() => {
          dispatch(
            DriveAwaySearchBarState.actionCreators.resetSearchAndFilters(true),
          );
          setShowModal(false);
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          dispatch(DriveAwaySavedSearchState.actionCreators.deleteSearch(id));
          setDeletingSavedSearchId(id);
        }}
      />
    </Fragment>
  );
};

DriveAwaySavedSearchesPanel.propTypes = {};
