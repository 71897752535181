import axios from "axios";
import apiUrl from "../../api-url";
import { createSelector } from "reselect";
import _ from "lodash";
const STORE_MOUNT_POINT = "connectedCarDomainData";
const CCE = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const RECEIVE_CONNECTED_CAR_DOMAIN_DATA = CCE("RECEIVE_CONNECTED_DOMAIN_DATA");

// Exported URLs
export const positionUpdateCitiesUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?positionUpdateCity=t`,
  );
};

export const productTypesUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?productType=1`);
};

/**
 * Fetch all domain data needed for ConnectedCar
 */
export function fetchDomainData(solutionId) {
  const fieldsToGetFromEndpoint = [
    "lifecycleState",
    "positionUpdateState",
    "ngavsStatusCode",
  ];

  const queryString = fieldsToGetFromEndpoint
    .map((item) => `${item}=t`)
    .join("&");

  const url = apiUrl(
    `/entity-search/solution/${solutionId}/list?${queryString}`,
  );

  const config = {
    headers: {
      Accept: "application/json;version=filter",
    },
  };
  return (dispatch) => {
    axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONNECTED_CAR_DOMAIN_DATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Selectors

const convertToOptions = (items) => {
  return items.map((item) => ({
    label: item,
    value: item,
  }));
};

const convertToOptionsList = (items) => {
  // Transform the data so that the label contains the milestone.
  return items.map((item) => ({
    label: item.name + " (" + item.milestone + ")",
    value: item.milestone,
  }));
};

const getStates = (state) => state[STORE_MOUNT_POINT].states;
const getLifeCycle = (state) => state[STORE_MOUNT_POINT].lifecycleState;
const getNgavsStatusCode = (state) =>
  state[STORE_MOUNT_POINT].ngavsStatusCodeState;
const getLifeCycleOptions = createSelector(getLifeCycle, convertToOptions);
const getNgavsStatusCodeOptions = createSelector(
  getNgavsStatusCode,
  convertToOptionsList,
);
const getStatesOptions = createSelector(getStates, convertToOptions);

const initialState = {
  states: [],
  lifecycleState: [],
  ngavsStatusCodeState: [],
};

const ConnectedCarDomainDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CONNECTED_CAR_DOMAIN_DATA:
      const data = action.payload || {};
      const states = data["positionUpdateState"] || [];
      const lifecycleState = data["lifecycleState"] || [];
      let ngavsStatusCodesState = data["ngavsStatusCodes"] || [];

      ngavsStatusCodesState = ngavsStatusCodesState.map((ngavsStatusCode) => {
        return {
          name: ngavsStatusCode.description,
          milestone: ngavsStatusCode.status_code,
        };
      });

      return {
        ...state,
        states: states.sort(),
        lifecycleState: lifecycleState.sort(),
        ngavsStatusCodeState: _.sortBy(ngavsStatusCodesState, "description"),
      };

    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getStates,
    getLifeCycle,
    getLifeCycleOptions,
    getNgavsStatusCode,
    getNgavsStatusCodeOptions,
    getStatesOptions,
  },
  reducer: ConnectedCarDomainDataReducer,
};
