/** @jsxImportSource @emotion/react */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import { useSelector } from "react-redux";

import apiUrl from "api-url";
import ExportsState from "../ExportsState";
import { getSolutionId } from "../../organizations/OrganizationsState";

const { getExport } = ExportsState.selectors;

const ExportContext = React.createContext(null);

export const ExportProvider = ({
  children,
  url,
  getUrl,
  config = {},
  fileName: baseFileName = "results",
}) => {
  const [exportId, setExportId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [exportFailed, setExportFailed] = useState(false);

  if (_.isNil(url) && _.isNil(getUrl)) {
    throw new Error(
      "Export Provider requires either the 'url' or the `getUrl` prop",
    );
  }

  // Default to url
  let exportUrl = url;
  // If getUrl is provided, use that to get the url
  if (getUrl) {
    exportUrl = getUrl(getSolutionId());
  }

  return (
    <ExportContext.Provider
      value={{
        url: exportUrl,
        exportId,
        setExportId,
        baseFileName,
        fileName,
        setFileName,
        config,
        exportFailed,
        setExportFailed,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};

ExportProvider.propTypes = {
  children: PropTypes.any.isRequired,
  url: PropTypes.string,
  getUrl: PropTypes.func,
  config: PropTypes.shape({
    headers: PropTypes.object,
    params: PropTypes.object,
  }),
  fileName: PropTypes.string,
};

export const useExport = () => {
  const context = useContext(ExportContext);

  if (context === null) {
    throw new Error("useExport must be used within a ExportProvider");
  }

  const [isExporting, setIsExporting] = useState(false);

  const requestExport = (params = {}) => {
    context.setExportFailed(false);
    setIsExporting(true);

    // Generate the file name
    const exportFileName = `${context.baseFileName}-${Date.now()}.csv`;

    // Add `asyncExport` and passed in `params` to the final params
    const config = {
      ...context.config,
      headers: {
        Accept: "text/csv",
        "x-time-zone": moment.tz.guess(),
        ...context.config.headers,
      },
      params: {
        ...context.config.params,
        ...params,
        asyncExport: true,
      },
    };

    // Request the async export
    return axios
      .get(apiUrl(context.url), config)
      .then((response) => {
        setIsExporting(false);

        // Receive the export id from the response
        const identifier = response.data.csvIdentifier;

        // Set the exportId and exportFileName on the provider
        context.setExportId(identifier);
        context.setFileName(exportFileName);
      })
      .catch((error) => {
        setIsExporting(false);
        context.setExportFailed(true);
        console.error(error);
      });
  };

  const currentExportsState = useSelector(getExport(context.exportId)) ?? {};

  return {
    exportIdentifier: context.exportId,
    fileName: context.fileName,
    requestExport,
    // Either the initial request failed or polling/downloading failed
    exportFailed: context.exportFailed || currentExportsState.exportFailed,
    // Either exporting ExportsState or from initial export
    isExporting: currentExportsState.isExporting || isExporting,
  };
};
