import {
  getBasicQueryString,
  getEverythingQueryString,
} from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("container-tracking:Search Everything"),
    placeholder: (t) =>
      t("container-tracking:Search Container Type, Tag ID, Container ID"), // Shipment ID",
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "type:contains",
    label: (t) => t("container-tracking:Container Type"),
    placeholder: (t) => t("container-tracking:Search Container Type"),
    queryBuilder: getBasicQueryString,
    property: "containerTypes",
  },
  {
    queryKey: "tag-id",
    label: (t) => t("container-tracking:Tag ID"),
    placeholder: (t) => t("container-tracking:Search Tag ID"),
    queryBuilder: getBasicQueryString,
    property: "tagIds",
  },
  {
    queryKey: "id",
    label: (t) => t("container-tracking:Container ID"),
    placeholder: (t) => t("container-tracking:Search Container ID"),
    queryBuilder: getBasicQueryString,
    property: "containerIds",
  },
  // {
  //     queryKey: "shipmentId",
  //     label: t => "Shipment ID",
  //     placeholder: t => "Search Shipment ID",
  //     queryBuilder: getBasicQueryString,
  //     property: "shipments"
  // },
];
