/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { Dashboard } from "components/templates/Dashboard.template";
import { SearchButtonContainer } from "components/saved-search/ButtonSeparator";
import { RouteManagementPopup } from "./components/route-management/RouteManagement.modal";
import {
  getRouteManagementColumns,
  getManagementColumns,
} from "./components/ContainerTrackingManagement.columns";
import { Icon } from "components/atoms/Icon.atom";
import { faUpload, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import ContainerTrackingRouteManagementSearchBarContainer from "./components/route-management/RouteManagement.SearchBar.container";
import ContainerTrackingManagementSearchBarContainer from "./components/tag-management/ContainerTracking.Management.SearchBar.container";
import Colors from "styles/colors";
import { BatchUploadPopup } from "./components/route-management/BatchUpload.modal";

function ContainerTrackingManagement({
  searchResults,
  isLoading,
  page,
  pageSize,
  totalPages,
  setRoutePagination,
  routeSearchResults,
  routeIsLoading,
  routePage,
  routePageSize,
  routeTotalPages,
  setContainerPagination,
  savedStatus,
  updateData,
  resetFailedCases,
  batchUploadAction,
  clearBatchUpload,
  batchUploadResult,
  batchUploadError,
  batchUploadLoading,
  resetSavedCases,
  resetSavedStatus,
  savedCases,
  isSaving,
  failure,
  searchRouteEntities,
  searchContainerEntities,
}) {
  const { t } = useTranslation("container-tracking");
  const [showNewRoutePopup, setShowNewRoutePopup] = useState(false);
  const [showBatchUploadPopup, setShowBatchUploadPopup] = useState(false);
  const [showEditRoutePopup, setShowEditRoutePopup] = useState(false);
  const [popupData, setPopupData] = useState({
    locations: [],
    containers: [],
    defaultBucketName: "",
  });

  const onNewRouteClick = () => {
    setShowNewRoutePopup(true);
  };

  const onBatchUploadClick = () => {
    setShowBatchUploadPopup(true);
  };

  const editRouteHandler = (locations, containers, defaultBucketName) => {
    setPopupData({ locations, containers, defaultBucketName });
    setShowEditRoutePopup(true);
  };
  useSetTitleOnMount(t("container-tracking:Container Management"));
  useSetDescriptionOnMount(
    t("container-tracking:Route management for containers"),
  );

  useEffect(() => {
    searchContainerEntities();
    searchRouteEntities();
  }, []);

  return (
    <Dashboard showFilters={false} toggleShowFilters={null}>
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>
            {t("container-tracking:Container Management")}
          </Dashboard.Tab>
          <Dashboard.Tab>
            {t("container-tracking:Route Management")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel>
          <ContainerTrackingManagementSearchBarContainer
            isShowingFilters={false}
            toggleShowFilters={null}
            isShowingAdvancedSearchButton={false}
          />
          <div
            css={{
              paddingTop: "1em",
            }}
          >
            <BaseTable
              columns={getManagementColumns(t)}
              isLoading={isLoading}
              data={searchResults}
              fixHeaderToTop={true}
              height="90vh"
              showPagination={true}
              showPageSizeDropdown={true}
              fixPaginationToBottom={true}
              isManualPagination={true}
              pageIndex={page}
              pageCount={totalPages}
              pageSize={pageSize}
              disableSortBy={true}
              onPageChange={(newPage) => {
                setContainerPagination(newPage, pageSize);
              }}
              onPageSizeChange={(newPageSize) => {
                setContainerPagination(0, newPageSize);
              }}
            ></BaseTable>
          </div>
        </Dashboard.TabPanel>
        <Dashboard.TabPanel>
          <ContainerTrackingRouteManagementSearchBarContainer
            isShowingFilters={false}
            toggleShowFilters={null}
            isShowingAdvancedSearchButton={false}
          />
          <div
            css={{
              padding: "5px",
            }}
          >
            <SearchButtonContainer>
              <Button
                variant="link"
                css={{
                  color: Colors.text.GRAY,
                  ":hover": {
                    color: Colors.background.DARK_BLUE,
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={onNewRouteClick}
                data-qa="button-new-route"
              >
                <Text italic underline>
                  {t("components:New Route")}
                </Text>
                <Icon css={{ marginLeft: "0.5em" }} src={faPlusCircle} />
              </Button>
              <Button
                variant="link"
                css={{
                  color: Colors.text.GRAY,
                  ":hover": {
                    color: Colors.background.DARK_BLUE,
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={onBatchUploadClick}
                data-qa="button-batch-upload"
              >
                <Text italic underline>
                  {t("components:Batch Upload")}
                </Text>
                <Icon css={{ marginLeft: "0.5em" }} src={faUpload} />
              </Button>
            </SearchButtonContainer>
          </div>
          <BaseTable
            columns={getRouteManagementColumns(t, editRouteHandler)}
            isLoading={routeIsLoading}
            data={routeSearchResults}
            showPagination={true}
            showPageSizeDropdown={true}
            fixPaginationToBottom={true}
            isManualPagination={true}
            pageIndex={routePage}
            pageCount={routeTotalPages}
            pageSize={routePageSize}
            disableSortBy={true}
            onPageChange={(newPage) => {
              setRoutePagination(newPage, routePageSize);
            }}
            onPageSizeChange={(newPageSize) => {
              setRoutePagination(0, newPageSize);
            }}
          ></BaseTable>
          <RouteManagementPopup
            locations={popupData.locations}
            searchRouteEntities={searchRouteEntities}
            containers={popupData.containers}
            defaultBucketName={popupData.defaultBucketName}
            show={showNewRoutePopup || showEditRoutePopup}
            isEdit={showEditRoutePopup}
            hide={() => {
              setShowEditRoutePopup(false);
              setShowNewRoutePopup(false);
            }}
            savedStatus={savedStatus}
            updateData={updateData}
            resetFailedCases={resetFailedCases}
            isSaving={isSaving}
            resetSavedCases={resetSavedCases}
            resetSavedStatus={resetSavedStatus}
            savedCases={savedCases}
            failure={failure}
            resetPopupData={() => {
              setPopupData({
                locations: [],
                containers: [],
                defaultBucketName: "",
              });
            }}
          />
          <BatchUploadPopup
            show={showBatchUploadPopup}
            hide={() => {
              setShowBatchUploadPopup(false);
            }}
            batchUploadAction={batchUploadAction}
            clearBatchUpload={clearBatchUpload}
            batchUploadResult={batchUploadResult}
            batchUploadError={batchUploadError}
            batchUploadLoading={batchUploadLoading}
          />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
}

ContainerTrackingManagement.propTypes = {
  searchResults: PropTypes.array,
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setRoutePagination: PropTypes.func,
  routeSearchResults: PropTypes.array,
  routeIsLoading: PropTypes.bool,
  routePage: PropTypes.number,
  routePageSize: PropTypes.number,
  routeTotalPages: PropTypes.number,
  setContainerPagination: PropTypes.func,
  savedStatus: PropTypes.bool,
  updateData: PropTypes.func,
  resetFailedCases: PropTypes.func,
  isSaving: PropTypes.bool,
  batchUploadAction: PropTypes.func,
  clearBatchUpload: PropTypes.func,
  batchUploadResult: PropTypes.object,
  batchUploadError: PropTypes.object,
  batchUploadLoading: PropTypes.bool,
  resetSavedCases: PropTypes.func,
  resetSavedStatus: PropTypes.func,
  savedCases: PropTypes.array,
  failure: PropTypes.bool,
  searchRouteEntities: PropTypes.func,
  searchContainerEntities: PropTypes.func,
};

export default ContainerTrackingManagement;
