/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";

export const SearchButtonContainer = ({ children }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        [MediaQueries.smallAndUp]: {
          justifyContent: "flex-end",
        },
      }}
    >
      {children}
    </div>
  );
};

SearchButtonContainer.propTypes = {
  children: PropTypes.any,
};

export const ButtonSeparator = () => {
  return (
    <div
      css={{
        width: "1px",
        height: "2em",
        backgroundColor: Colors.background.LIGHT_GRAY,
      }}
    />
  );
};
