import { connect } from "react-redux";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import ReportFolderModalState from "pages/reports/redux/ReportFolderModalState";
import ReportsSharedSearchBarState from "pages/reports/redux/ReportsSharedState";
import ReportsCoreSearchBarState from "pages/reports/redux/ReportsCoreState";
import ReportsMineSearchBarState from "pages/reports/redux/ReportsMineState";
import { CreateOrEditFolderModal } from "./CreateOrEditFolder.modal";

const { getCreateFolderModalData, getEditFolderModalData } =
  ReportFolderModalState.selectors;
const { createFolderModal, editFolderModal } =
  ReportFolderModalState.actionCreators;

const mapStateToProps = (state) => {
  return {
    solutionId: getSolutionId(state),
    createFolderData: getCreateFolderModalData(state),
    editFolderData: getEditFolderModalData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { searchEntities: searchSharedEntities } =
    ReportsSharedSearchBarState.actionCreators;
  const { searchEntities: searchCoreEntities } =
    ReportsCoreSearchBarState.actionCreators;
  const { searchEntities: searchMineEntities } =
    ReportsMineSearchBarState.actionCreators;

  return {
    createFolderModal: (folderCategory, folderName, folderDesc) =>
      dispatch(createFolderModal(folderCategory, folderName, folderDesc)),
    editFolderModal: (report, folderCategory, folderName, folderDesc) =>
      dispatch(editFolderModal(report, folderCategory, folderName, folderDesc)),
    searchCoreReports: (solutionId) => dispatch(searchCoreEntities(solutionId)),
    searchSharedReports: (solutionId) =>
      dispatch(searchSharedEntities(solutionId)),
    searchMineReports: (solutionId) => dispatch(searchMineEntities(solutionId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOrEditFolderModal);
