//External Packages
import _ from "lodash";
import { createSelector } from "reselect";

import DomainDataState from "modules/domain-data/DomainDataState";
import ShipmentsDomainDataState, {
  SHIPMENT_EVENTS_FILTER_OPTION_NAMES,
} from "modules/domain-data/ShipmentsDomainDataState";
import { loadedStatusCodesEN } from "modules/shipment-detail/ShipmentUtils";

const mapIdsAndNames = (values) =>
  values.map((v) => ({
    value: v.id,
    label: v.name,
  }));

export const selectOriginFilterOptions = [];

export const selectDestinationFilterOptions = [];

export const selectCarrierFilterOptions = createSelector(
  [DomainDataState.selectors.selectCarriersAlphabetically],
  (carriers) =>
    carriers.map((carrier) => ({
      value: carrier.id,
      label: `${carrier.name} (${carrier.scac})`,
    })),
);

export const selectModeFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.selectShipmentModesAlphabetically],
  (modes) =>
    modes.map((mode) => ({
      value: mode.id,
      label: mode.name,
    })),
);

export const selectStatusFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.selectStatusTypesAlphabetically],
  (statusTypes) =>
    statusTypes
      .filter((st) =>
        // DEV-1070 Don't add the status grouped by the shipment_event filters.
        {
          return !SHIPMENT_EVENTS_FILTER_OPTION_NAMES.includes(st.name);
        },
      )
      .map((st) => ({
        value: st.id,
        label: st.name,
      })),
);

export const selectShipmentEventFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.selectShipmentEventsAlphabetically],
  (shipmentEvents) => {
    let options = shipmentEvents
      .filter((se) => {
        return !(se.name in SHIPMENT_EVENT_NAME_GROUP_MAP);
      })
      .map((se) => {
        return { value: se.id, label: se.name };
      });

    // DEV-1070 Add the shipment events that will group some status filters.
    options.push(...getShipmentEventsGroups(shipmentEvents));

    return _.sortBy(options, "label");
  },
);

export const selectExceptionTypeFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.selectExceptionTypesAlphabetically],
  mapIdsAndNames,
);

export const selectShipmentTypeFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getShipmentTypes],
  mapIdsAndNames,
);

export const selectShipperFilterOptions = createSelector(
  [DomainDataState.selectors.getShippers],
  (shippers) =>
    shippers.map((s) => ({
      value: s.shipper_organization_id,
      label: s.shipper_name,
    })),
);

export const selectServiceCodeFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getServiceCodes],
  (serviceCode) =>
    serviceCode.map((n) => ({ value: n, label: n })).filter((x) => x.value),
);

export const selectTripPlanNumberFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getTripPlanNumbers],
  (tripPlanNumbers) =>
    tripPlanNumbers.sort().map((t) => ({ value: t, label: t })),
);

export const selectLineOfBusinessFilterOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getLineOfBusiness],
  mapIdsAndNames,
);

// H1-2101: this looks like a selector but there's no DomainData for it so we
// fake it for now with a constant
export const getLoadedStatusFilterOptions = (state) =>
  _.map(loadedStatusCodesEN, (label, code) => ({ value: code, label }));

// Helper functions

// DEV-1070 : Maps the consolidated status into these two filter names to act as filter
// groups.
const SHIPMENT_EVENT_NAME_GROUP_MAP = {
  "Arrived at Drop-Off": "Arrived at Stop",
  "Arrived at Pickup": "Arrived at Stop",
  "Departed Drop-Off": "Departed Stop",
  "Departed Pickup": "Departed Stop",
};

// DEV-1070 : Dummy ids used to identify the filter groups and replace the status each
// one of them groups.
const FILTER_GROUP_ID_MAP = {
  "Arrived at Stop": -1,
  "Departed Stop": -2,
};

// DEV-1070 : Returns a list of filters for the shipment events that will group some
// of the status ids.
// In the form:
//   { id: dummyId, label: "the label", groupsIds: [list-of-ids-this-filter-groups] }
const getShipmentEventsGroups = (shipmentEvents) => {
  let groups = [];
  shipmentEvents.forEach((st) => {
    if (st.name in SHIPMENT_EVENT_NAME_GROUP_MAP) {
      const shipmentEventName = SHIPMENT_EVENT_NAME_GROUP_MAP[st.name];
      const id = FILTER_GROUP_ID_MAP[shipmentEventName];
      let group = groups.find((o) => o.label === shipmentEventName);
      group
        ? group.groupsIds.push(st.id)
        : groups.push({
            value: id,
            label: shipmentEventName,
            groupsIds: [st.id],
          });
    }
  });
  return groups;
};
