import React from "react";

/**
 * This hook wraps the `value` in a function callback to be called and returned later.
 *
 * This is useful for accessing values in effects but effectively having the effect ignore changes in that value.
 * Certain use cases may be:
 * - Need to call a function with a configuration object but only care about that configuration's latest value.
 * - Callback functions that are passed to a component and used in an effect.
 *   The component may only want to call it at a certain time and doesnt care about it updating.
 * - Any other situation where you know updates to a value can be safely ignored by the effect.
 *
 * Note the last point above. If used on values that should update an effect, it may lead to other issues.
 * This has the same concerns as ignoring the react-hooks/exhaustive-deps eslint rule.
 * See related: https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
 *
 * @param {any} value - The value returned by the callback.
 * @returns A callback "getter" that returns the latest value provided to the hook.
 */
// Source: https://github.com/tannerlinsley/react-table/blob/master/src/publicUtils.js#L140
export function useGetLatest<T = unknown>(value: T) {
  // Create a ref to hold the value.
  const ref = React.useRef<T>();
  // Every render, update the current value of the ref to be the latest value.
  ref.current = value;

  // Create a callback that returns the ref's current value.
  // Note: Casting as T because this ref isn't mutated elsewhere,
  // i.e. it can never be undefined unless that is whats passed in.
  return React.useCallback(() => ref.current as T, []);
}
