/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import styled from "@emotion/styled";

import AuthenticationUtils from "modules/auth/authentication";
import {
  doesPassContainEmailOrUsername,
  isPassLengthValid,
  isPassMatchValid,
  isPassRegexValid,
} from "utils/password-utils";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { PasswordChecks } from "components/PasswordChecks";
import { Button } from "components/atoms/Button.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { FlexDiv } from "styles/container-elements";

const FormItem = styled(FlexDiv)({ flex: "0 0 50%", marginRight: ".5em" });
const FormInput = ({
  type,
  handler,
  label,
  placeholder,
  controlType = "text",
  value,
}) => {
  return (
    <FormGroup css={{ marginBottom: "1rem" }}>
      <FormLabel
        css={{
          color: Colors.text.GRAY,
          fontSize: "small",
          fontWeight: "normal",
        }}
      >
        {label}
      </FormLabel>
      <FormControl
        onChange={(e) => handler(e.currentTarget.value, type)}
        placeholder={placeholder}
        type={controlType}
        value={value}
        autoComplete="new-password"
      />
    </FormGroup>
  );
};
FormInput.propTypes = {
  value: PropTypes.string,
  controlType: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  handler: PropTypes.func,
  type: PropTypes.string,
};

export function PasswordChangeForm({
  passwordStatus,
  resetPasswordForm,
  changePassword,
}) {
  const { t } = useTranslation("profile");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    setNewPassword("");
    setConfirmPassword("");
    setTimeout(resetPasswordForm, 3000);
  }, [passwordStatus, resetPasswordForm]);

  const eventHandler = (value, type) => {
    switch (type) {
      case "INPUT_NPASS":
        setNewPassword(value);
        break;
      case "INPUT_CPASS":
        setConfirmPassword(value);
        break;
      case "CHANGE_PASS":
        changePassword({ password: value }, AuthenticationUtils.userId);
        break;
      default:
        break;
    }
  };

  const isNewPasswordLengthValid = isPassLengthValid(newPassword);
  const isNewPasswordRegexValid = isPassRegexValid(newPassword);
  const isPasswordsMatching = isPassMatchValid(newPassword, confirmPassword);
  const doesPasswordContainEmailOrUsername = doesPassContainEmailOrUsername(
    newPassword,
    AuthenticationUtils.userEmail,
  );

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.background.LIGHT_GRAY,
        border: "1px solid white",
        borderRadius: 8,
        padding: "1.25em",
      }}
    >
      <Text css={{ fontWeight: 400, marginBottom: "1em" }}>
        {t("profile:Change Your Password")}
      </Text>
      <Form>
        <div css={{ display: "flex" }}>
          <FormItem>
            <FormInput
              label={t("profile:New Password")}
              placeholder={t("profile:New Password")}
              value={newPassword}
              type="INPUT_NPASS"
              handler={eventHandler}
              controlType="password"
            />
          </FormItem>
          <FormItem>
            <FormInput
              label={t("profile:Confirm Password")}
              placeholder={t("profile:Confirm Password")}
              value={confirmPassword}
              type="INPUT_CPASS"
              handler={eventHandler}
              controlType="password"
            />
          </FormItem>
        </div>
      </Form>
      <PasswordChecks
        isLengthValid={isNewPasswordLengthValid}
        isPasswordsMatch={isPasswordsMatching}
        isRegexValid={isNewPasswordRegexValid}
        doesPassContainEmailOrUsername={doesPasswordContainEmailOrUsername}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!passwordStatus ? (
          <Button
            variant="primary"
            disabled={
              !isNewPasswordLengthValid ||
              !isNewPasswordRegexValid ||
              !isPasswordsMatching ||
              doesPasswordContainEmailOrUsername
            }
            onClick={() => eventHandler(confirmPassword, "CHANGE_PASS")}
          >
            {t("profile:Change My Password")}
          </Button>
        ) : null}
        <Alert
          show={passwordStatus && passwordStatus === "CHANGED"}
          variant={AlertVariant.Success}
          css={{ display: "flex", flex: 1 }}
        >
          {t("profile:Your password has been changed")}
        </Alert>
        <Alert
          show={passwordStatus && passwordStatus === "ERROR"}
          variant={AlertVariant.Danger}
          css={{ display: "flex", flex: 1 }}
        >
          {t("profile:Change password failed")}
        </Alert>
      </div>
    </div>
  );
}
