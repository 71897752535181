/** @jsxImportSource @emotion/react */
import { CSSProperties, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner, faDownload } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";

export const DownloadDataButton = ({
  onClick,
  isExporting = false,
  isDisabled = false,
  style,
  className,
  "data-qa": dataQa,
}: DownloadDataButtonProps) => {
  const { t } = useTranslation("components");

  return (
    <Button
      variant="link"
      className={className}
      disabled={isDisabled}
      data-qa={dataQa}
      css={{
        color: Colors.text.GRAY,
        ":hover": {
          color: Colors.background.DARK_BLUE,
        },
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
      style={style}
      onClick={isExporting ? undefined : onClick}
    >
      <Text italic underline className="me-1">
        {t("components:Download Data")} (.csv)
      </Text>
      {isExporting ? <Icon src={faSpinner} spin /> : <Icon src={faDownload} />}
    </Button>
  );
};

export type DownloadDataButtonProps = {
  /**
   * The function to run when the button is clicked
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * `isExporting` represents the state where the async download has started
   * and is not yet complete.  Molecule displays a spinner.
   */
  isExporting?: boolean;
  /**
   * `isDisabled` represents the state where the button cannot be clicked
   */
  isDisabled?: boolean;
  /**
   * An object that will be used as the element styles for the button.
   */
  style?: CSSProperties;
  /**
   * A string for defining one or more classes that will be added to the button.
   */
  className?: string;
  /**
   * The data attribute for QA testing.
   */
  "data-qa"?: string;
};
