import _ from "lodash";
import qs from "qs";
import apiUrl from "api-url";
import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import { FILTERS } from "pages/shipments/components/search/Shipments.searchOptions";
import AuthenticationUtils from "modules/auth/authentication";

const getSearchObj = (subscribee, state) => {
  let searchObj = {};

  Object.keys(subscribee?.search).forEach((f) => {
    const value = subscribee.search[f];
    const searchFilter = _.find(FILTERS, {
      queryKey: f,
    });

    if (f !== "batch" && searchFilter && _.size(value) > 0) {
      // handle building QS for filters
      searchObj = {
        ...searchObj,
        ...qs.parse(searchFilter.queryBuilder(f, value)),
      };
    } else {
      // handle building QS for categories
      const searchCategory = _.find(SEARCH_CATEGORIES, {
        queryKey: f,
      });
      if (searchCategory && _.size(value) > 0) {
        searchObj = {
          ...searchObj,
          ...qs.parse(searchCategory.queryBuilder(f, value, state)),
        };
      }
    }
  });

  return searchObj;
};

export const ShipmentsSavedSearchSubscriptionState = buildSubscriptionState({
  topic: "ShipmentsSavedSearch",
  systemType: "shipment",
  getSubscribeeId: (subscribee) => subscribee.id,
  getUrl: (id) => apiUrl(`/preferences-ng/saved_search/${id}`),
  getAdditionalRequestConfig: (requestType, subscribee, state) => {
    const config = {};

    if (
      requestType === "SUBSCRIBE" ||
      requestType === "UPDATE_SUBSCRIPTION" ||
      requestType === "UNSUBSCRIBE"
    ) {
      config.data = {};
      config.data.subscribing_product = "Shipment View";

      if (requestType === "UPDATE_SUBSCRIPTION") {
        config.data.email = AuthenticationUtils.userEmail;
      }

      const search = getSearchObj(subscribee, state);
      if (Object.keys(search).length > 0) {
        config.data.search = search;
      }

      if (subscribee?.search?.batch) {
        config.data.batchType = subscribee.search.batch.batch_type;
        config.data.batch_list = subscribee.search.batch.batch_list;
      }
    }

    return config;
  },
  onChange: () =>
    ShipmentsSavedSearchSubscriptionState.actionCreators.fetchUserSubscriptions(),
});
