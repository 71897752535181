import apiUrl from "api-url";
import { buildEntityHistoricalSlice } from "shared/redux/EntityHistoricalSlice";

const STORE_MOUNT_POINT = "carrierViewEntityHistory";

const getHistoryUrl = (solutionId, entityId, qualifier) => {
  let url = `/entity/internal/${entityId}/reference-history?qualifier=${qualifier}&`;
  return apiUrl(url);
};

const CarrierViewEntityHistoricalState = buildEntityHistoricalSlice(
  STORE_MOUNT_POINT,
  getHistoryUrl,
);

export default CarrierViewEntityHistoricalState;
