import moment from "moment";
import _ from "lodash";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES, FILTERS } from "./MilestoneLogs.searchOptions";

const STORE_MOUNT_POINT = "documentationMilestoneLogs";

const logsUrl = (queryString) => {
  return apiUrl(`/support/get_api_logs?${queryString}`);
};

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const { searchFilters } = state[STORE_MOUNT_POINT];

  const requiredKeys = ["type"];
  const hasRequiredKeys = requiredKeys.some(
    (f) => !_.isNil(searchFilters[f]) && !_.isEmpty(searchFilters[f]),
  );

  const dateRange = searchFilters["ts"] ?? {};
  const { from, to } = dateRange;
  let hasValidDateRange = !from && !to;

  if (from && to) {
    const hours = moment(to).diff(moment(from), "hours");
    hasValidDateRange = hours <= 24;
  }

  const atLeastOneKeys = ["ts", "entityId", "milestone_status_code"];
  let hasMinimumKeys = atLeastOneKeys.some(
    (f) => !_.isNil(searchFilters[f]) && !_.isEmpty(searchFilters[f]),
  );

  let error = null;

  if (!hasRequiredKeys) {
    error = "Please specify a Status Type";
  } else if (!hasMinimumKeys) {
    error = "Please specify a VIN, Milestone Status Code, or Date Range";
  } else if (!hasValidDateRange) {
    error = "Please specify a Date Range of 24 hours or less";
  }

  if (error) {
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      error,
    });
  } else {
    const url = logsUrl(queryString);
    dispatch(
      duck.fetch(url, {
        headers: { Accept: "application/json;version=milestone" },
      }),
    );
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
);

export default SearchBarState;
