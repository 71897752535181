import { connect } from "react-redux";

import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "../../redux/VinViewSearchBarState";
import VinViewDomainDataState from "modules/domain-data/VinViewDomainData";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getAreAllPrerequisiteFiltersSelected,
    getCanUserSearch,
  } = SearchBarState.selectors;

  return {
    auth: getAuthorization(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    vins: VinViewDomainDataState.selectors.getVINs(state),
    productTypes: VinViewDomainDataState.selectors.getProductTypes(state),
    orderNumbers: VinViewDomainDataState.selectors.getOrderNumbers(state),
    exceptionTypes: VinViewDomainDataState.selectors.getExceptionTypes(state),
    domainDataArgs: [SearchBarState.selectors.getSelectedDealerOrgId(state)],
    areAllPrerequisiteFiltersSelected:
      getAreAllPrerequisiteFiltersSelected(state),
    canUserSearch: getCanUserSearch(state),
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
} = SearchBarState.actionCreators;
const { fetchDomainData } = VinViewDomainDataState.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
  fetchDomainData,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
