import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

import NotificationsState, { NotificationType } from "../NotificationsState";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  BatchNotification,
  CommentNotification,
  ExportNotification,
  UpdateNotification,
  BatchUploadNotification,
} from "./Notification.organism";

const {
  getNotificationList,
  getNotificationMeta,
  getUnreadFilter,
  getNotificationUnreadCount,
} = NotificationsState.selectors;
const { fetchNotification } = NotificationsState.actionCreators;

export const NotificationList = () => {
  const notificationList = useSelector(getNotificationList);
  const notificationMeta = useSelector(getNotificationMeta);
  const showUnreadOnly = useSelector(getUnreadFilter);
  const unreadCount = useSelector(getNotificationUnreadCount);

  const { t } = useTranslation("header-bar");

  const dispatch = useDispatch();

  const buildNotifications = (notificationList) => {
    return notificationList.map((notification, index) => {
      switch (notification.type.toUpperCase()) {
        case NotificationType.EXPORT:
          return (
            <ExportNotification
              key={index}
              childIndex={index}
              id={notification.id}
            />
          );
        case NotificationType.UPDATE:
          return (
            <UpdateNotification
              key={index}
              childIndex={index}
              id={notification.id}
            />
          );
        case NotificationType.COMMENT:
          return (
            <CommentNotification
              key={index}
              childIndex={index}
              id={notification.id}
            />
          );
        case NotificationType.BATCH_COMMENT:
          return (
            <BatchNotification
              key={index}
              childIndex={index}
              id={notification.id}
            />
          );
        case NotificationType.BATCH_UPLOAD:
          return (
            <BatchUploadNotification
              key={index}
              childIndex={index}
              id={notification.id}
            />
          );
        default:
          return null;
      }
    });
  };

  let hasMore = notificationMeta.totalCount > notificationList.length;
  if (showUnreadOnly) {
    hasMore = unreadCount > notificationList.length;
  }

  return (
    <InfiniteScroll
      dataLength={notificationList.length}
      height={400}
      hasMore={hasMore}
      next={() =>
        dispatch(
          fetchNotification({
            pageSize: 10,
            page: notificationMeta.currentPage + 1,
          }),
        )
      }
      loader={
        <div style={{ textAlign: "center" }}>
          <Icon src={faSpinner} spin />
        </div>
      }
      scrollableTarget="scrollableDiv"
      endMessage={
        <Text block align="center">
          {t("No More Notifications")}
        </Text>
      }
    >
      {buildNotifications(notificationList)}
    </InfiniteScroll>
  );
};
