/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { IoMdTimer } from "react-icons/io";
import { formatDuration } from "utils/date-time";
/**
 *
 * @param currentDwell
 * @return {*}
 * @constructor
 */
const DwellTimeCell = ({ currentDwell, currentAge }) => {
  const { dwellTimeSeconds } = currentDwell;

  let formattedTime = formatDuration(
    dwellTimeSeconds < 0 ? 0 : dwellTimeSeconds,
  );

  const isWarning = currentAge && currentAge > 0 ? true : false;
  const clockColor = dwellTimeSeconds
    ? isWarning
      ? Colors.holds.RED
      : Colors.holds.LIGHT_GREEN
    : Colors.text.LIGHT_GRAY;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IoMdTimer
        style={{
          fontSize: 20,
          marginRight: 15,
          color: clockColor,
        }}
      />
      <span
        style={{
          fontWeight: isWarning ? 600 : 400,
        }}
      >
        {formattedTime}
      </span>
    </div>
  );
};

DwellTimeCell.propTypes = {
  currentDwell: PropTypes.object.isRequired,
  currentAge: PropTypes.number,
};

export default DwellTimeCell;
