import moment from "moment";
import axios from "axios";
import qs from "qs";

import apiUrl from "api-url";
import ExportsState from "modules/exports/ExportsState";

const STORE_MOUNT_POINT = "driveAwaySearchExportPDF";

// Actions
const EXPORT_IDENTIFIER_RECEIVED = `${STORE_MOUNT_POINT}/EXPORT_PDF_IDENTIFIER_RECEIVED`;
const EXPORT_SEARCH_SUCCEEDED = `${STORE_MOUNT_POINT}/EXPORT_PDF_SEARCH_SUCCEEDED`;
const EXPORT_SEARCH_FAILED = `${STORE_MOUNT_POINT}/EXPORT_PDF_SEARCH_FAILED`;
const CLEAR_EXPORT_ERRORS = `${STORE_MOUNT_POINT}/CLEAR_EXPORT_PDF_ERRORS`;
const EXPORT_REQUEST = `${STORE_MOUNT_POINT}/EXPORT_PDF_REQUEST`;
const RESET_EXPORT = `${STORE_MOUNT_POINT}/RESET_PDF_EXPORT`;

// Action creators
function exportAsyncPickUpForm(
  approvalId,
  solutionId,
  locale = "en",
  fileName = "pick-up-form",
) {
  const baseUrl = solutionId
    ? apiUrl(`/entity/solution/${solutionId}/entity?`)
    : apiUrl("/entity/internal?");
  const exportFileName = `${fileName}-${Date.now()}.pdf`;

  const queryParams = {
    asyncExport: "true",
    fileName: exportFileName,
    approvalId: approvalId,
    dda_filter: 1,
    locale: locale,
  };

  const url = `${baseUrl}${qs.stringify(queryParams)}`;

  const axiosConfig = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      accept: "application/pdf;version=dda_pickup_form",
    },
  };

  const fetchRequest = axios.get(url, axiosConfig);

  return (dispatch) => {
    dispatch({ type: EXPORT_REQUEST });

    return fetchRequest
      .then((response) => {
        const identifier = response.data.pdfIdentifier;
        dispatch({
          type: EXPORT_IDENTIFIER_RECEIVED,
          identifier,
          exportName: exportFileName,
        });
      })
      .then(() => {
        dispatch({
          type: EXPORT_SEARCH_SUCCEEDED,
        });
      })
      .catch((error) => {
        dispatch({
          type: EXPORT_SEARCH_FAILED,
        });
      });
  };
}

function clearExportErrors() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EXPORT_ERRORS,
    });
  };
}

function resetExport() {
  return (dispatch) => {
    dispatch({ type: RESET_EXPORT });
  };
}

// Initial State
const initialState = {
  isExporting: false,
  exportFailed: false,
  exportIdentifier: "",
  exportName: "",
};

// Reducer
function ExportFormReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_EXPORT:
      return {
        ...state,
        isExporting: initialState.isExporting,
        exportFailed: initialState.isExporting,
        exportIdentifier: initialState.exportIdentifier,
        exportName: initialState.exportName,
      };
    case EXPORT_IDENTIFIER_RECEIVED:
      return {
        ...state,
        exportIdentifier: action.identifier,
        exportName: action.exportName,
        isExporting: false /*This is counter intuative but the isExporting loader is controlled with this var  */,
      };
    case EXPORT_REQUEST:
      return {
        ...state,
        isExporting: true,
        exportFailed: false,
      };
    case EXPORT_SEARCH_FAILED:
      return {
        ...state,
        isExporting: false,
        exportIdentifier: "",
        exportName: "",
        exportFailed: true,
      };
    case CLEAR_EXPORT_ERRORS:
      return {
        ...state,
        exportFailed: false,
      };
    default:
      return state;
  }
}

// selectors
const getExportIdentifier = (state) =>
  state[STORE_MOUNT_POINT].exportIdentifier || null;
const getExportName = (state) => state[STORE_MOUNT_POINT].exportName || null;
const getIsExporting = (state) => state[STORE_MOUNT_POINT].isExporting || false;
const getExportFailed = (state) => {
  const exportIdentifier = getExportIdentifier(state);
  if (exportIdentifier) {
    return ExportsState.selectors.getExport(exportIdentifier)(state)
      .exportFailed;
  }
  return state[STORE_MOUNT_POINT].exportFailed || false;
};

// interface
const DriveAwayExportPickUpFormState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    exportAsyncPickUpForm,
    clearExportErrors,
    resetExport,
  },
  selectors: {
    getExportIdentifier,
    getExportName,
    getIsExporting,
    getExportFailed,
  },
  reducer: ExportFormReducer,
};

export default DriveAwayExportPickUpFormState;
