import React from "react";

// Navigation Category Icons
import {
  MdLocalShipping,
  MdDirectionsCar,
  MdLocationOn,
  MdLightbulbOutline,
  MdContentPaste,
  MdExplore,
} from "react-icons/md";
import {
  faHeartbeat,
  faBoxAlt,
  faHandsHelping,
} from "@fortawesome/pro-solid-svg-icons";

import { Icon, IconType, FontSize } from "components/atoms/Icon.atom";

export const navCategoryIconLookup = (value: string) => {
  const lookup: { [key: string]: JSX.Element } = {
    SHIPMENTS: <Icon type={IconType.ReactIcons} src={MdLocalShipping} />,
    PART_VIEW: (
      <Icon type={IconType.FontAwesome} src={faBoxAlt} size={FontSize.size20} />
    ),
    FINISHED_VEHICLE: <Icon type={IconType.ReactIcons} src={MdDirectionsCar} />,
    ASSET_TRACKING: <Icon type={IconType.ReactIcons} src={MdLocationOn} />,
    HEALTHCARE: <Icon type={IconType.FontAwesome} src={faHeartbeat} />,
    BUSINESS_INTELLIGENCE: (
      <Icon type={IconType.ReactIcons} src={MdLightbulbOutline} />
    ),
    PARTNERS: (
      <Icon
        type={IconType.FontAwesome}
        src={faHandsHelping}
        size={FontSize.size20}
      />
    ),
    ADMINISTRATION: <Icon type={IconType.ReactIcons} src={MdContentPaste} />,
    DOCUMENTATION: <Icon type={IconType.ReactIcons} src={MdExplore} />,
  };
  return lookup[value] ?? value;
};

// H1-2423: translation script wouldnt be able to translate these values
// dynamically from routes.js so using a lookup object here
export const translateNavCategoryLabelLookup = (t: Function, value: string) => {
  const lookup: { [key: string]: string } = {
    SHIPMENTS: t("appnav:Shipments"),
    PART_VIEW: t("appnav:PartView"),
    FINISHED_VEHICLE: t("appnav:Finished Vehicle"),
    ASSET_TRACKING: t("appnav:Asset Tracking"),
    HEALTHCARE: t("appnav:Healthcare"),
    BUSINESS_INTELLIGENCE: t("appnav:Business Intelligence"),
    PARTNERS: t("appnav:Partners"),
    ADMINISTRATION: t("appnav:Administration"),
    DOCUMENTATION: t("appnav:Training & Resources"),
  };
  return lookup[value] ?? value;
};

export const translateNavLabelLookup = (t: Function, value: string) => {
  const lookup: { [key: string]: string } = {
    ROOT: t("translation:Shipment Exceptions"),
    CREATE_SHIPMENT: t("translation:Create Shipment"),
    FINISHED_VEHICLE: t("translation:Finished Vehicle Dashboard"),
    CONNECTED_CAR: t("translation:Connected Car Dashboard"),
    INV_DASHBOARD: t("translation:InventoryView"),
    INVENTORY_VIEW_DASHBOARD: t("translation:InventoryView"),
    DAMAGE_VIEW_DASHBOARD: t("translation:DamageView"),
    MILESTONE_DASHBOARD: t("translation:Milestone Dashboard"),
    MILESTONE_CREATE_MILESTONE_EVENT: t("translation:Create Milestone Event"),
    PARTVIEW_DASHBOARD: t("translation:PartView Dashboard"),
    PARTVIEW_BACKORDER_DASHBOARD: t("translation:Back Order Dashboard"),
    DEALER_PARTVIEW_DASHBOARD: t("translation:Dealer PartView Dashboard"),
    VINVIEW_DASHBOARD: t("translation:VINView"),
    VINVIEW_SHIPPER_DASHBOARD: t("translation:VINView"),
    DRIVE_AWAY: t("translation:Dealer Pick Up"),
    METAL_TRACKING_DASHBOARD: t("translation:Plant Asset Tracking"),
    HEALTHCARE_DASHBOARD: t("translation:Asset Details"),
    REPORTS: t("translation:BI Dashboard"),
    REPORT_BUILDER: t("translation:Report Builder"),
    END_TO_END: t("translation:Locations"),
    PARTNER_DASHBOARD: t("translation:Milestone Dashboard"),
    PARTNER_CREATE_MILESTONE_EVENT: t("translation:Create Milestone Event"),
    LOCATION_MANAGEMENT: t("translation:Location Management"),
    USER_MANAGEMENT: t("translation:User Management"),
    ORGANIZATION_MANAGEMENT: t("translation:Organization Management"),
    CARRIERVIEW_DASHBOARD: t("translation:CarrierView"),
    CONTAINER_TRACKING_DASHBOARD: t("translation:Container Tracking"),
    CONTAINER_TRACKING_MANAGEMENT: t("translation:Container Management"),
    CONTAINER_TRACKING_DETAILS: t("translation:Container Tracking Details"),
    SURGICAL_TOTE_TRACKING_DASHBOARD: t(
      "translation:Healthcare Container Tracking",
    ),
    SURGICAL_TOTE_TRACKING_DETAILS: t(
      "translation:Healthcare Container Tracking Details",
    ),
    ADMINISTRATIVE_TOOLS: t("translation:Administrative Tools"),
    INTERNAL_TOOLS: t("translation:Internal Tools"),
    FREEZE_ETA: t("translation:Freeze ETA"),
    NOTIFICATION_MANAGEMENT: t("translation:Notification Management"),
  };
  return lookup[value] ?? value;
};
