import axios from "axios";
import apiUrl from "api-url";
import _ from "lodash";

// URLS
const STORE_MOUNT_POINT = "healthcare";

// Helpers
const translatePositionUpdates = (item) => {
  return {
    id: item.id,
    time: item.datetime,
    db_time: item.receivedDatetime,
    latitude: item.latitude,
    longitude: item.longitude,
    city: item.city,
    state: item.state,
    country: item.country,
    references: item.references,
  };
};

// Actions
const REQUEST_ENTITY_DETAILS = `${STORE_MOUNT_POINT}/REQUEST_ENTITY_DETAILS`;
const RECEIVE_ENTITY_DETAILS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_DETAILS`;
const RECEIVE_ENTITY_DETAILS_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_DETAILS_ERROR`;
const REQUEST_ENTITY_POSITION_UPDATES = `${STORE_MOUNT_POINT}/REQUEST_ENTITY_POSITION_UPDATES`;
const RECEIVE_ENTITY_POSITION_UPDATES = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_POSITION_UPDATES`;
const RECEIVE_ENTITY_POSITION_UPDATES_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_POSITION_UPDATES_ERROR`;
const REQUEST_UPDATE_POSITION_UPDATE = `${STORE_MOUNT_POINT}/REQUEST_UPDATE_POSITION_UPDATE`;
const RECEIVE_UPDATE_POSITION_UPDATE = `${STORE_MOUNT_POINT}/RECEIVE_UPDATE_POSITION_UPDATE`;
const RECEIVE_UPDATE_POSITION_UPDATE_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_UPDATE_POSITION_UPDATE_ERROR`;
const CLEAR_UPDATE_POSITION_UPDATE_ERROR = `${STORE_MOUNT_POINT}/CLEAR_UPDATE_POSITION_UPDATE_ERROR`;
const CLEAR_ENTITY = `${STORE_MOUNT_POINT}/CLEAR_ENTITY`;

const entityUrl = (solutionId, entityId) =>
  apiUrl(`/entity/solution/${solutionId}/entity/${entityId}`);

// Action creators
function fetchEntityDetails(solutionId, entityId) {
  const url = entityUrl(solutionId, entityId);

  return (dispatch) => {
    dispatch({
      type: REQUEST_ENTITY_DETAILS,
    });

    return Promise.all([axios.get(url)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_DETAILS,
          payload: { entityDetails: responses[0].data },
        });
      })
      .catch((err) => {
        console.log("ERROR retreiving entity details");
        console.log(err);

        dispatch({
          type: RECEIVE_ENTITY_DETAILS_ERROR,
          payload: err,
        });
      });
  };
}

function fetchEntityPositionUpdates(solutionId, entityId) {
  const url = entityUrl(solutionId, entityId) + "/position-update";

  return (dispatch) => {
    dispatch({
      type: REQUEST_ENTITY_POSITION_UPDATES,
    });

    // Connected Car and trip legs comes together in the same position-update
    // method and are only diferentiated by the "type" attribute. When it is
    // from connected car, it starts with "entity", when it is not, it starts
    // with "trip_leg_id"
    return axios
      .get(url, {
        params: {
          paginate: true,
          pageNumber: 0,
          pageSize: 5000,
        },
      })
      .then((response) => {
        dispatch({
          type: RECEIVE_ENTITY_POSITION_UPDATES,
          payload: {
            entityPositionUpdates: response.data?.data,
          },
        });
      })
      .catch((err) => {
        console.error("fetchEntityPositionUpdates error:", err);

        dispatch({
          type: RECEIVE_ENTITY_POSITION_UPDATES_ERROR,
        });
      });
  };
}

function updateEntityPositionUpdate(
  solutionId,
  entityId,
  positionUpdateId,
  data,
) {
  const url =
    entityUrl(solutionId, entityId) + `/position-update/${positionUpdateId}`;

  const patchReferences = [
    {
      qualifier: "verified_state",
      value: data.isAssetLocationExpected ? "accepted" : "rejected",
    },
  ];

  if (!_.isNil(data.distanceOff)) {
    patchReferences.push({
      qualifier: "verified_distance_off",
      value: data.distanceOff,
    });
  }

  return (dispatch) => {
    dispatch({ type: REQUEST_UPDATE_POSITION_UPDATE });

    return axios
      .patch(url, {
        references: patchReferences,
      })
      .then((response) => {
        dispatch({ type: RECEIVE_UPDATE_POSITION_UPDATE });
        // Refetch position updates
        dispatch(fetchEntityPositionUpdates(solutionId, entityId));
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_UPDATE_POSITION_UPDATE_ERROR,
          payload: error,
        });
        console.error("updateEntityPositionUpdate error: ", error);
      });
  };
}

function clearUpdateEntityPositionUpdateError() {
  return { type: CLEAR_UPDATE_POSITION_UPDATE_ERROR };
}

function clearEntity() {
  return (dispatch) => {
    dispatch({ type: CLEAR_ENTITY });
  };
}

// Selectors
const getIsLoadingEntityDetails = (state) =>
  state[STORE_MOUNT_POINT].isLoadingEntityDetails;
const getEntityDetails = (state) => state[STORE_MOUNT_POINT].entityDetails;
const getEntityDetailsError = (state) =>
  state[STORE_MOUNT_POINT].entityDetailsError;

const getIsLoadingEntityPositionUpdates = (state) =>
  state[STORE_MOUNT_POINT].isLoadingEntityPositionUpdates;
const getEntityPositionUpdates = (state) => {
  if (state[STORE_MOUNT_POINT].entityPositionUpdates.entityPositionUpdates) {
    return state[
      STORE_MOUNT_POINT
    ].entityPositionUpdates.entityPositionUpdates.map(translatePositionUpdates);
  }
  return [];
};
const getEntityPositionUpdatesError = (state) =>
  state[STORE_MOUNT_POINT].entityPositionUpdatesError;

const getIsUpdatePositionUpdateIsLoading = (state) =>
  state[STORE_MOUNT_POINT].isUpdatePositionUpdateIsLoading;
const getUpdatePositionUpdateError = (state) =>
  state[STORE_MOUNT_POINT].updatePositionUpdateError;

// Initial state
const initialState = {
  isLoadingEntityDetails: false,
  entityDetails: null,
  entityDetailsError: null,
  isLoadingEntityPositionUpdates: false,
  entityPositionUpdates: [],
  entityPositionUpdatesError: null,
  isUpdatePositionUpdateIsLoading: false,
  updatePositionUpdateError: null,
};

const HealthcareReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_DETAILS:
      return {
        ...state,
        isLoadingEntityDetails: true,
      };

    case RECEIVE_ENTITY_DETAILS:
      return {
        ...state,
        entityDetails: action.payload.entityDetails,
        isLoadingEntityDetails: false,
      };

    case RECEIVE_ENTITY_DETAILS_ERROR:
      return {
        ...state,
        entityDetailsError: action.payload,
        isLoadingEntityDetails: false,
      };

    case REQUEST_ENTITY_POSITION_UPDATES:
      return {
        ...state,
        isLoadingEntityPositionUpdates: true,
      };

    case RECEIVE_ENTITY_POSITION_UPDATES:
      return {
        ...state,
        entityPositionUpdates: action.payload,
        isLoadingEntityPositionUpdates: false,
      };

    case RECEIVE_ENTITY_POSITION_UPDATES_ERROR:
      return {
        ...state,
        entityPositionUpdatesError: action.payload,
        isLoadingEntityPositionUpdates: false,
      };

    case REQUEST_UPDATE_POSITION_UPDATE:
      return {
        ...state,
        isUpdatePositionUpdateIsLoading: true,
        updatePositionUpdateError: null,
      };

    case RECEIVE_UPDATE_POSITION_UPDATE:
      return {
        ...state,
        isUpdatePositionUpdateIsLoading: false,
        updatePositionUpdateError: null,
      };

    case RECEIVE_UPDATE_POSITION_UPDATE_ERROR:
      return {
        ...state,
        isUpdatePositionUpdateIsLoading: false,
        updatePositionUpdateError: action.payload,
      };

    case CLEAR_UPDATE_POSITION_UPDATE_ERROR:
      return {
        ...state,
        updatePositionUpdateError: null,
      };

    case CLEAR_ENTITY:
      return {
        ...state,
        entityDetails: null,
        entityPositionUpdates: [],
      };

    default:
      return state;
  }
};

// interface
const HealthcareState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityDetails,
    fetchEntityPositionUpdates,
    updateEntityPositionUpdate,
    clearUpdateEntityPositionUpdateError,
    clearEntity,
  },
  selectors: {
    getIsLoadingEntityDetails,
    getEntityDetails,
    getEntityDetailsError,
    getIsLoadingEntityPositionUpdates,
    getEntityPositionUpdates,
    getEntityPositionUpdatesError,
    getIsUpdatePositionUpdateIsLoading,
    getUpdatePositionUpdateError,
  },
  reducer: HealthcareReducer,
};
export default HealthcareState;
