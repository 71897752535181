/** @jsxImportSource @emotion/react */
import debouncePromise from "debounce-promise";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { asyncSearchLocations } from "modules/location-search/LocationSearchStateExtensions";
import { IdBox } from "components/multimodal-components/InfoBoxes";
import apiUrl from "api-url";
import Colors from "styles/colors";

// todo incorporate this into SelectField to remove duplicate code
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: Colors.tabs.BACKGROUND_SELECTED,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.border.CYAN_BLUE,
    cursor: "pointer",
    height: "3em",
    fontSize: "1em",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const LocationOption = ({
  label,
  innerProps,
  selectProps,
  data,
  isDisabled,
  isFocused,
  isSelected,
}) => {
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: ".8em",
        paddingLeft: 10,
        paddingTop: 3,
        paddingBottom: 3,
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? Colors.border.ALICE_BLUE
          : isFocused
          ? Colors.border.BLUE
          : Colors.tabs.BACKGROUND_SELECTED,
        color: isDisabled
          ? Colors.tabs.BORDER
          : isSelected
          ? Colors.text.DARK_GRAY
          : isFocused
          ? Colors.text.DARK_GRAY
          : Colors.text.DIM_GRAY,
      }}
    >
      <IdBox css={{ fontWeight: "bold" }} data-qa="text-name-location">
        {label}
      </IdBox>
      <LocationAddressComplex
        addressColor={Colors.text.GRAY}
        address1={data.address}
        address2={data.address2}
        city={data.city}
        state={data.state}
        postalCode={data.postal_code}
        country={data.country}
      />
    </div>
  );
};

LocationOption.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

const asyncSearchShipperLocations = debouncePromise((query, loadedOptions) => {
  // Calculate page number by checking if the currently loaded results
  // are divisble by the pageSize
  let page = 0;
  const pageSize = 20;
  if (loadedOptions.length % pageSize === 0) {
    page = loadedOptions.length / pageSize;
  }

  return asyncSearchLocations(
    query,
    true,
    page,
    pageSize,
    (q) => {
      let queryParam = "";
      if (q) {
        queryParam = `&query=${q}`;
      }
      return apiUrl(
        `/shipping-ng/filter?destination_location=true${queryParam}`,
      );
    },
    "ShipperLocation",
  );
}, 500);

export const ShipmentDestinationLocationSelect = ({
  placeholder,
  loadingText,
  value,
  onChange,
}) => {
  return (
    <AsyncPaginate
      name="async-select-destination-locations-filter"
      placeholder={placeholder}
      isMulti={false}
      cacheOptions={false}
      loadingMessage={() => loadingText}
      loadOptions={(query, loadedOptions) =>
        asyncSearchShipperLocations(query, loadedOptions)
      }
      styles={customStyles}
      isClearable={true}
      value={value}
      onChange={onChange}
      components={{
        Option: LocationOption,
      }}
    />
  );
};

ShipmentDestinationLocationSelect.propTypes = {
  placeholder: PropTypes.string,
  loadingText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
};
