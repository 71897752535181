/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import FormCell from "components-old/forms/FormCell";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import Colors from "styles/colors";
import {
  NameCellColumn,
  HalfPieTableWidget,
} from "pages/shipments/dashboard/components/organisms/HalfPieTableWidget.organism";
import { ShipmentDestinationLocationSelect } from "./Shipments.DestinationLocationSelect.organism";
import { ShipmentResultsModal } from "./Shipments.ResultsModal.organism";

const updateData = (t, data) => {
  return data.map((d) => {
    let translatedName = "";
    let modalName = "";
    let tooltipContent = "";
    const name = d.name?.toUpperCase() ?? "";

    if (name === "ACCEPTABLE") {
      translatedName = t("shipment-status:Acceptable dwell");
      modalName = t("shipment-status:Acceptable");
      tooltipContent = t(
        "shipment-status:Less than 15 minutes after the schedule delivery window close",
      );
    } else if (name === "MODERATE") {
      translatedName = t("shipment-status:Moderate dwell");
      modalName = t("shipment-status:Moderate");
      tooltipContent = t(
        "shipment-status:Between 15 minutes and 30 minutes after the schedule delivery window close",
      );
    } else if (name === "EXCESSIVE") {
      translatedName = t("shipment-status:Excessive dwell");
      modalName = t("shipment-status:Excessive");
      tooltipContent = t(
        "shipment-status:More than 30 minutes after the schedule delivery window close",
      );
    }
    return {
      ...d,
      translatedName,
      modalName,
      tooltipContent,
      // Generating the full display text here to be used wherever it is needed
      fullDescription: translatedName,
      fill: Colors.dwell[d.code],
    };
  });
};

export const InboundLiveDwellWidget = ({
  fetchCount,
  countData = {},
  fetchShipments,
  setSearchFilter,
  isShipmentsLoading,
  shipmentResults,
  setPagination,
  page,
  pageSize,
  totalPages,
  setSort,
  sortColumn,
  reverseSort,
  pushShipmentDetailView,
  exportSearch,
  isExporting,
  exportFailed,
  exportIdentifier,
  exportName,
  resetExport,
}) => {
  const { t } = useTranslation("shipment-status");
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState(null);
  const [chosenStatus, setChosenStatus] = useState(null);

  useEffect(() => {
    fetchCount(location?.code);
  }, [location, fetchCount]);

  const loaded = !countData?.isLoading;
  let data = countData?.data ? updateData(t, countData.data) : [];

  const columns = [
    {
      Header: t("shipment-status:Status"),
      accessor: "name",
      Cell: (cellInfo) => {
        return <NameCellColumn cellInfo={cellInfo} />;
      },
    },
    {
      Header: t("shipment-status:Shipments"),
      width: 70,
      accessor: "count",
    },
    {
      Header: <Icon src={faSearch} size={FontSize.size14} />,
      width: 20,
      minWidth: 20,
      id: "search",
      Cell: () => {
        return (
          <div
            css={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Icon src={faChevronRight} size={FontSize.size14} />
          </div>
        );
      },
    },
  ];

  const rowClickHandler = (row) => {
    const locationCode = location?.code ? [location.code] : [];
    const dwellType = row?.original?.name ? [row.original.name] : ["All"];
    const modalName = row?.original?.modalName ?? null;

    setSearchFilter("unloadType", ["LIVE"]);
    setSearchFilter("destCode", locationCode);
    setSearchFilter("dwellType", dwellType);

    setChosenStatus(modalName);
    setShowModal(true);

    fetchShipments();
  };

  return (
    <React.Fragment>
      <HalfPieTableWidget
        title={t("shipment-status:Inbound Live Dwell")}
        postTitleIcon={faQuestionCircle}
        postTitleIconTooltip={t(
          "shipment-status:This widget displays inbound live shipments that are actively dwelling.",
        )}
        columns={columns}
        loaded={loaded}
        chartData={data}
        rowClickHandler={rowClickHandler}
        tooltipLabel={t("shipment-status:SHIPMENTS")}
        tooltipLabelSingular={t("shipment-status:SHIPMENT")}
        subHeaderContent={
          <div css={{ padding: "0.5em 1em" }} data-qa="select-dwell-location">
            <FormCell label={t("shipment-status:Destination Location")}>
              <ShipmentDestinationLocationSelect
                placeholder={t("shipment-status:Select...")}
                loadingText={t("shipment-status:Loading...")}
                value={location}
                onChange={(location) => setLocation(location)}
              />
            </FormCell>
          </div>
        }
      />
      <ShipmentResultsModal
        show={showModal}
        hide={() => setShowModal(false)}
        title={t("shipment-status:Dwelling Shipments")}
        chosenStatus={chosenStatus}
        selectedFields={
          <React.Fragment>
            <div css={{ display: "flex" }}>
              <FormCell
                label={t("shipment-status:Destination Location") + ":"}
                style={{ paddingLeft: 0 }}
              >
                <Text bold>
                  {location?.label ?? t("shipment-status:Not Selected")}
                </Text>
              </FormCell>

              <DownloadDataButton
                onClick={() => {
                  exportSearch();
                }}
                isExporting={isExporting}
                isDisabled={shipmentResults.length === 0}
                data-qa="live-dwell-search-results-export"
              />
            </div>
            <ExportAlert exportFailed={exportFailed} className="my-2" />
          </React.Fragment>
        }
        location={location}
        searchEntities={fetchShipments}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        shipments={shipmentResults}
        isLoading={isShipmentsLoading}
        setPagination={setPagination}
        setSort={setSort}
        pushShipmentDetailView={pushShipmentDetailView}
      />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
    </React.Fragment>
  );
};

InboundLiveDwellWidget.propTypes = {
  fetchCount: PropTypes.func,
  countData: PropTypes.object,
  fetchShipments: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isShipmentsLoading: PropTypes.bool,
  shipmentResults: PropTypes.array,
  setPagination: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setSort: PropTypes.func,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  pushShipmentDetailView: PropTypes.func,
};
