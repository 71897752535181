import { Features } from "./Authorization";

// Perform authorization checks based on user's available solutions and features
// Example: used to authorize the Finished Vehicle and Plant Asset Tracking modules
export default class FeatureAuthorization {
  constructor(userAuth, userOrgPermissions, features, isFvAdmin = false) {
    this.solutions = userAuth ? userAuth.solutions || [] : [];
    this.features = features || [];
    this.isFvAdmin = isFvAdmin || false;

    // Check if the user has organization-level permissions
    if (userOrgPermissions) {
      this.solutions = userOrgPermissions.solutions;
    }
  }

  // Does the user have any of the features specified?
  hasAnyFeature(featureNames) {
    // Check if the given features are available for the current organization
    // - `this.features` comes from `iam/features`
    // - and that endpoint filters out features that the user is not allowed to access
    // - We can use that response as the source of truth for which features we can display
    return this.featuresWithNames(featureNames).length > 0;
  }

  // Get the features in the user's solutions
  solutionFeatures(featureNames) {
    const namedFeatures = this.featuresWithNames(featureNames);

    // Check if the user has the correct solution ID for the specified features
    return namedFeatures.filter((f) => {
      return this.solutions.includes(f.solution);
    });
  }

  // Filter the full list of features by specific names
  featuresWithNames(featureNames) {
    let namedFeatures = this.features;

    // If names are specified, only return features with matching names
    if (featureNames && featureNames.length) {
      namedFeatures = namedFeatures.filter((f) => {
        return featureNames.includes(f.feature_name);
      });
    }

    // Only return active features
    return namedFeatures.filter((f) => {
      return f.is_active === true;
    });
  }

  // Check if the user only has the Plant Asset Tracking feature
  plantAssetTrackingOnly() {
    const allSolutionFeatures = this.solutionFeatures();

    // User has no active features for the solution
    if (!allSolutionFeatures || !allSolutionFeatures.length) {
      return false;
    }

    // Check if the user only has the Plant Asset Tracking feature
    return allSolutionFeatures.every(
      (f) => f.feature_name === Features.PLANT_ASSET_TRACKING,
    );
  }
}
