import React from "react";

/**
 * Apply a subcomponent element's props from children to the default element of the same type.
 *
 * This is useful if we would like to expose subcomponents and if provided,
 * allow custom prop values to override the default ones.
 *
 * Note: This currently won't work if children are surrounded by a fragment.
 * The fragment is consider a single child, so we would have to look at its children instead.
 *
 * @param {React.ReactNode} children The array of children from the parent.
 * @param {React.ReactNode} defaultChild The default element to override.
 */
export const useSubcomponentOverride = (children, defaultChild) => {
  // If we have children, we want to attempt to override
  // the default versions of our subcomponents.
  if (children) {
    // Find the corresponding child element.
    // Using `React.Children.toArray` utility method to ensure we have an array.
    // Reference: https://reactjs.org/docs/react-api.html#reactchildren
    const passedInChild = React.Children.toArray(children)?.find(
      (child) => child.type === defaultChild.type,
    );

    // If there was a custom version of a subcomponent,
    // we want to use their props instead.
    if (passedInChild) {
      const defaultProps = defaultChild.props ?? {};
      const passedInProps = passedInChild.props ?? {};

      // Return a cloned element and apply default props and
      // override those with the passed in child's props.
      return React.cloneElement(defaultChild, {
        ...defaultProps,
        ...passedInProps,
      });
    }
  }

  return defaultChild;
};
