/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";

export const RotatingExpanderCell = (props) => {
  const { row } = props;
  const { t } = props.value;

  return (
    <span
      {...row.getToggleRowExpandedProps()}
      style={{
        display: "flex",
        margin: "0 auto",
        cursor: "pointer",
        transition: "all .3s cubic-bezier(.175,.885,.32,1.275)",
        transform: row.isExpanded ? "rotate(90deg)" : "none",
        userSelect: "none",
      }}
    >
      <FontAwesomeIcon icon={faCaretRight} alt={t("Expand/Collapse Row")} />
    </span>
  );
};

RotatingExpanderCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.object,
};
