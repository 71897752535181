/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MediaQueries } from "components/responsive";
import AuthenticationUtils from "../auth/authentication";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";

import { PasswordChangeForm } from "./components/organisms/PasswordChangeForm.organism";
import { UserPreferenceForm } from "./components/organisms/UserPreferenceForm.organism";
import { AlertMePreferenceForm } from "./components/organisms/AlertMePreferenceForm.organism";

export const ProfileView = ({
  passwordStatus,
  resetPasswordForm,
  changePassword,
  userPreferences,
  setPreferences,
  hasDealerPickUpFeature,
}) => {
  const { t } = useTranslation("profile");
  useSetTitleOnMount(t("profile:My Profile"));
  useTrackWithMixpanelOnce("Viewed Page: User / Profile");

  const isSilentAuthentication =
    AuthenticationUtils.getIsSilentAuthentication();

  return (
    <div
      css={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        gap: "1em",
        padding: "1em",
        [MediaQueries.mediumAndUp]: { flexDirection: "row" },
      }}
    >
      {!isSilentAuthentication ? (
        <PasswordChangeForm
          passwordStatus={passwordStatus}
          resetPasswordForm={resetPasswordForm}
          changePassword={changePassword}
        />
      ) : null}

      <UserPreferenceForm
        userPreferences={userPreferences}
        setPreferences={setPreferences}
      />

      {/* Alert Me Preference Form*/}
      <AlertMePreferenceForm
        userPreferences={userPreferences}
        setPreferences={setPreferences}
        hasDealerPickUpFeature={hasDealerPickUpFeature}
      />
    </div>
  );
};

ProfileView.propTypes = {
  changePassword: PropTypes.func.isRequired,
  resetPasswordForm: PropTypes.func.isRequired,
  setPreferences: PropTypes.func.isRequired,
  passwordStatus: PropTypes.string,
  userPreferences: PropTypes.object,
  hasDealerPickUpFeature: PropTypes.bool,
};
