import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";

export const shipmentEtaValidatorVinOptionsState = buildAsyncFilterOptionsState(
  {
    topic: "shipmentEtaValidatorVinFilter",
    getUrl: (solutionId) => apiUrl(`/entity/solution/${solutionId}/entity`),
    queryParam: "entityId",
    pageSize: 20,
    getResponseData: (data) => data?.data ?? [],
    transformResult: (entity) => ({
      label: entity.id,
      value: entity.id,
      entity,
    }),
  },
);
