//H2-1406 translation script wouldnt be able to translate these values dymaically so using a lookup object here
export const translateCodeLabelLookup = (t, value) => {
  const lookup = {
    manually_completed: t("filters:Shipment manually completed/closed"),
    1300: t("filters:No asset assigned - shipment should be in transit"),
    missed_pickup_exceptions: t("filters:Missed Pickup"),
    missed_delivery_exceptions: t("filters:Missed Drop-Off"),
    behind_schedule: t("filters:Behind Schedule"),
    1380: t("filters:Tracking asset past destination delivery window."),
    bad_order_exceptions: t("filters:Bad Order"),
    in_hold_exceptions: t("filters:In Hold"),
    conflicting_etas: t(
      "filters:ETA conflict between shipment and last stop records",
    ),
    idle_train_exceptions: t("filters:Idle Train"),
    missed_delivery: t("filters:Shipment Missed Delivery"),
    2070: t(
      "filters:Shipment arrived at ultimate destination, but no delivery event",
    ),
    1070: t("filters:Dweller Shipment"),
    under_review: t("filters:Under Review"),
    1000: t("filters:Shipment has conflicting asset IDs"),
    1100: t("filters:Invalid missed pickup exception"),
    1200: t("filters:Completed shipment not showing properly"),
    1350: t("filters:Missed Asset assignment for in-transit shipment"),
    "Excessive Dwell": t("filters:Aging Vin"),
  };
  return lookup[value] ? lookup[value] : value;
};

export const displayedFilters = () => {
  return [
    "missed_pickup_exceptions",
    "missed_delivery_exceptions",
    "behind_schedule",
    "under_review",
    "bad_order_exceptions",
    "in_hold_exceptions",
    "idle_train_exceptions",
  ];
};
