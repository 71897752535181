/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Icon } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { PartsCell } from "pages/partview/components/molecules/PartsCell.molecule";
import { usePartViewExceptionLabel } from "pages/partview/components/hooks/usePartViewExceptionLabel";
import PartViewEntityDetailsState from "pages/partview/redux/PartViewEntityDetailsState";
import PartViewSearchBarState from "pages/partview/redux/PartViewSearchBarState";
import DealerPartViewSearchBarState from "pages/partview/redux/DealerPartViewSearchBarState";
import { getIconData } from "pages/partview/utils/exceptions.utils";
import { transformLocation } from "pages/partview/utils/location.utils";
import { useOrderPriorityNameTranslation } from "pages/partview/utils/useOrderPriorityNameTranslation";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { usePartViewMilestone } from "pages/partview/utils/milestone.utils";
import { PackageStatus } from "pages/partview/utils/const";
import { getDifferenceBetweenTimestamps } from "utils/date-time";
import Colors from "styles/colors";

const PackageCell = (props) => {
  const { t } = useTranslation("partview-search");
  const { id, trailerEquipmentNumber } = props.value;
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {id ? (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0.5rem",
          }}
        >
          <Text bold>{t("partview-search:Package Details")}</Text>
          <Text>{id}</Text>
        </div>
      ) : null}

      {trailerEquipmentNumber ? (
        <>
          <Text bold>{t("partview-search:Trailer Equipment")} #</Text>
          <Text>{trailerEquipmentNumber}</Text>
        </>
      ) : null}
    </div>
  );
};

PackageCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    trailerEquipmentNumber: PropTypes.string,
  }),
};

const DestinationEtaCell = ({
  destinationEta,
  lifecycleState,
  heldExceptionDateTime,
}) => {
  const { t } = useTranslation("partview-search");
  const { getEtaTranslation } = useEtaTranslations();

  let displayedEta;
  let status = lifecycleState?.toUpperCase();

  if (status === PackageStatus.DELIVERED) {
    displayedEta = <Text>{t("partview-search:Delivered")}</Text>;
  } else if (heldExceptionDateTime) {
    displayedEta = (
      <Text>
        {t("partview-search:TBD until")}{" "}
        <DateTime
          plain
          localize
          dateTime={heldExceptionDateTime}
          fallback={t("partview-search:N/A")}
        >
          <DateTime.Time style={{ display: "none" }} />
          <DateTime.Timezone style={{ display: "none" }} />
        </DateTime>
      </Text>
    );
  } else if (
    destinationEta?.toLowerCase() === EtaName.HOLD ||
    destinationEta?.toLowerCase() === EtaName.TBD
  ) {
    displayedEta = <Text>{getEtaTranslation(EtaName.TBD)}</Text>;
  } else if (destinationEta?.toLowerCase() === EtaName.PENDING_DISPATCH) {
    displayedEta = <Text>{getEtaTranslation(EtaName.PENDING_DISPATCH)}</Text>;
  } else {
    displayedEta = (
      <DateTime
        plain
        localize
        dateTime={destinationEta}
        fallback={t("partview-search:N/A")}
      >
        <DateTime.Time style={{ display: "none" }} />
        <DateTime.Timezone style={{ display: "none" }} />
      </DateTime>
    );
  }

  return (
    <div css={{ display: "flex", width: "100%" }}>
      <Text bold>
        {t("partview-search:ETA")}: {displayedEta}
      </Text>
    </div>
  );
};

DestinationEtaCell.propTypes = {
  destinationEta: PropTypes.string,
  lifecycleState: PropTypes.string,
  heldExceptionDateTime: PropTypes.string,
};

const PackageMilestoneCell = (props) => {
  const { t } = useTranslation("partview-search");
  const { code, location, dateTime, status } = props.value;
  const milestone = usePartViewMilestone(code);

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {status ? (
        <div className="d-flex flex-wrap">
          <Text bold>
            {t("partview-search:Status")}: <Text>{status}</Text>
          </Text>
        </div>
      ) : null}
      {milestone?.description ? (
        <Text bold>{milestone.description}</Text>
      ) : null}
      {location?.name ? <Text>{location.name}</Text> : null}
      {dateTime ? (
        <div className="d-flex flex-wrap">
          <Text bold>{t("partview-search:Event Time")}: </Text>
          <DateTime
            dateTime={dateTime}
            localize
            plain
            className="ms-1"
            fallback={<Text className="ms-1">{t("partview-search:N/A")}</Text>}
          />
        </div>
      ) : null}
    </div>
  );
};

PackageMilestoneCell.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    location: PropTypes.object,
    dateTime: PropTypes.string,
    status: PropTypes.string,
  }),
};

const OrderNumbersCell = ({ value }) => {
  const { t } = useTranslation("partview-search");
  const { getTranslatedOrderPriorityName } = useOrderPriorityNameTranslation();

  const { orders, trackingNumber, orderPriority } = value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {orderPriority ? (
        <>
          <Text bold css={{ marginRight: 3 }}>
            {t("partview-search:Order Priority")}:
          </Text>
          <Text>{getTranslatedOrderPriorityName(orderPriority)}</Text>
        </>
      ) : null}
      <div css={{ marginTop: "8px" }}></div>
      {orders ? (
        <Text bold css={{ marginRight: 3 }}>
          {t("partview-search:Order Number")}:
        </Text>
      ) : null}
      <ShowMoreList
        title={`${t("partview-search:Order Number")}: ${trackingNumber}`}
        list={_.chain(orders)
          .orderBy(["OrderTimestamp", "OrderNumber"], ["desc"])
          .uniqBy("OrderNumber")
          .map((order) => order.OrderNumber)
          .value()}
        visibleItemCount={3}
      />
    </div>
  );
};

OrderNumbersCell.propTypes = {
  value: PropTypes.shape({
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        OrderNumber: PropTypes.string,
        OrderTimestamp: PropTypes.string,
      }),
    ),
    trackingNumber: PropTypes.string,
    orderPriority: PropTypes.string,
  }),
};

const LocationCell = (props) => {
  const { location, type, isPartSeller = false } = props.value;
  const { destinationEta, lifecycleState, heldExceptionDateTime } = props.value;

  const { t } = useTranslation("partview-search");

  if (_.isNil(location) || !type) {
    return null;
  }

  const {
    code,
    name,
    scheduledPickupWindow,
    scheduledDeliveryWindow,
    actualDepartureDateTime,
    actualArrivalDateTime,
  } = location;

  let scheduledOrActualLabel = null;
  let scheduledOrActualTime = null;

  // For both origin and destination, check if we have an actual.
  // Otherwise, fallback to the upper limit of scheduled window.
  // For part seller, only show actual.
  if (type === "origin") {
    if (isPartSeller) {
      scheduledOrActualLabel = t("partview-search:Actual Pickup");
      scheduledOrActualTime = actualDepartureDateTime;
    } else {
      scheduledOrActualLabel = actualDepartureDateTime
        ? t("partview-search:Actual Pickup")
        : t("partview-search:Scheduled Pickup");
      scheduledOrActualTime =
        actualDepartureDateTime ?? scheduledPickupWindow?.[1];
    }
  } else if (type === "destination") {
    if (isPartSeller) {
      scheduledOrActualLabel = t("partview-search:Actual Delivery");
      scheduledOrActualTime = actualArrivalDateTime;
    } else {
      scheduledOrActualLabel = actualArrivalDateTime
        ? t("partview-search:Actual Delivery")
        : t("partview-search:Scheduled Delivery");
      scheduledOrActualTime =
        actualArrivalDateTime ?? scheduledDeliveryWindow?.[1];
    }
  }
  let content = null;
  if (type === "destination") {
    content = (
      <DestinationEtaCell
        destinationEta={destinationEta}
        lifecycleState={lifecycleState}
        heldExceptionDateTime={heldExceptionDateTime}
      />
    );
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block bold>
        {code}
      </Text>
      <Text block>{name}</Text>
      <Text block>
        <Text block bold underline>
          {scheduledOrActualLabel}
        </Text>
        <DateTime
          plain
          localize
          dateTime={scheduledOrActualTime}
          fallback={t("partview-search:N/A")}
        />
      </Text>
      {content}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

const ExceptionCell = (props) => {
  const { exceptions = [] } = props.value;
  const { getFullTranslatedNameForException } = usePartViewExceptionLabel();
  const exceptionDataTransformed = exceptions?.map((exception) => {
    return {
      name: getFullTranslatedNameForException(exception),
      icon: getIconData(exception.reasonCode),
    };
  });

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {exceptionDataTransformed.map((exception, i) => {
        return (
          <div key={i} css={{ display: "flex", flexDirection: "row" }}>
            <div css={{ display: "flex", justifyContent: "center" }}>
              <span
                css={{
                  marginRight: 5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {exception.icon ? (
                  <Icon
                    type={exception.icon.type}
                    src={exception.icon.src}
                    color={exception.icon.color}
                    altText={exception.icon.altText}
                    css={{
                      width: 20,
                      height: 20,
                      ...exception.icon.style,
                    }}
                  />
                ) : null}
              </span>
            </div>
            <Text bold>{exception.name}</Text>
          </div>
        );
      })}
    </div>
  );
};

ExceptionCell.propTypes = {
  value: PropTypes.shape({
    exceptions: PropTypes.array,
  }),
};

const BackOrderCell = ({ value }) => {
  const { t } = useTranslation("partview-search");

  const { backorderPartList = [] } = value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {backorderPartList.length > 0 ? (
        <ShowMoreList
          title={t("partview-search:Back Order")}
          list={backorderPartList}
          visibleItemCount={3}
        />
      ) : null}
    </div>
  );
};

BackOrderCell.propTypes = {
  value: PropTypes.shape({
    backorderPartList: PropTypes.array,
  }),
};

export const WATCH_COLUMN_ID = "watch";

export const useColumns = ({ isPartSeller }) => {
  const { t } = useTranslation("partview-search");
  const dispatch = useDispatch();
  let column = [];

  column.push({
    Header: t("partview-search:Watch"),
    id: WATCH_COLUMN_ID,
    accessor: "Watched",
    width: 50,
    disableSortBy: true,
    disableResizing: true,
    centerAligned: true,
    Cell: (cellInfo) => {
      const id = cellInfo.row.original.id;
      const trackingNumber = cellInfo.row.original.TrackingNumber;
      const watched = cellInfo.value === true;

      const setWatchAction = (newWatchValue) => {
        return PartViewEntityDetailsState.actionCreators.setWatchPackage(
          trackingNumber,
          newWatchValue,
          () => {
            // if partseller then watch the package and call the search api to get updated watched package in search results of dealer dashboard
            if (isPartSeller) {
              const { searchEntities } =
                DealerPartViewSearchBarState.actionCreators;
              dispatch(searchEntities(null, false, true));
            } else {
              const { searchEntities } = PartViewSearchBarState.actionCreators;
              dispatch(searchEntities(null, false, true));
            }
          },
        );
      };

      return (
        <WatchToggle
          key={id}
          checked={watched ?? false}
          onChange={(newWatchValue) => {
            dispatch(setWatchAction(newWatchValue));
          }}
          iconSize={FontSize.size24}
          color={Colors.nav.NAVY}
          checkedColor={Colors.highlight.YELLOW}
        />
      );
    },
  });

  column.push({
    Header: t("partview-search:Package"),
    id: "package",
    disableSortBy: true,
    Cell: PackageCell,
    accessor: (d) => {
      return {
        id: d.TrackingNumber,
        trailerEquipmentNumber: d.TrailerEquipmentNumber,
      };
    },
    minWidth: 180,
  });

  column.push({
    Header: t("partview-search:Part Details"),
    id: "partDetails",
    minWidth: 200,
    disableSortBy: true,
    Cell: PartsCell,
    accessor: (d) => {
      return {
        partList: d?.PartList ?? [],
        trackingNumber: d.TrackingNumber,
      };
    },
  });

  column.push({
    Header: t("partview-search:Order"),
    id: "orderNumbers",
    disableSortBy: true,
    Cell: OrderNumbersCell,
    accessor: (d) => {
      return {
        orders: d.Orders,
        trackingNumber: d.TrackingNumber,
        orderPriority: d.OrderPriority,
      };
    },
  });

  column.push({
    Header: t("partview-search:Last Update"),
    id: "lastMilestone",
    minWidth: 125,
    disableSortBy: true,
    Cell: PackageMilestoneCell,
    accessor: (d) => {
      let latestUpdate;
      // Returns time difference in days
      const timeDifferenceInDays = getDifferenceBetweenTimestamps(
        d.LastMilestone?.datetime,
        d.LastUpdate?.datetime,
      );

      // if timeDifferenceInDays is null which means dateTime is not present in either LastMilestone nor LastUpdate
      // if timeDifferenceInDays is null and dateTime or location is present in LastMilestone then use LastMilestone
      // if timeDifferenceInDays is null and dateTime or location is not present in LastMilestone then use LastUpdate
      // if timeDifferenceInDays > 0 then LastMilestone has the latest Update
      // because LastMilestone dateTime is sent as the first parameter in the above getDifferenceBetweenTimestamps function
      if (
        (!timeDifferenceInDays &&
          (d.LastMilestone?.datetime || d.LastMilestone?.location)) ||
        timeDifferenceInDays > 0
      ) {
        latestUpdate = {
          code: d.LastMilestone?.eventCode,
          location: d.LastMilestone?.location,
          dateTime: d.LastMilestone?.datetime,
          status: d.LifecycleState,
        };
      } else {
        // if LastUpdate is also null then return null
        latestUpdate = {
          code: d.LastUpdate?.eventCode,
          location: d.LastUpdate?.location,
          dateTime: d.LastUpdate?.datetime,
          status: d.LifecycleState,
        };
      }

      return latestUpdate;
    },
  });

  column.push({
    Header: t("partview-search:Ultimate Origin"),
    id: "origin",
    minWidth: 135,
    disableSortBy: true,
    Cell: LocationCell,
    accessor: (d) => {
      const type = "origin";
      const location = transformLocation(d.OriginDetail, type, d);

      return { location, type, isPartSeller };
    },
  });

  column.push({
    Header: t("partview-search:Final Mile Origin"),
    id: "finalMileOrigin",
    minWidth: 180,
    disableSortBy: true,
    accessor: "FinalMileOrigin",
    Cell: (cellInfo) => {
      let finalMileOrigin = cellInfo.value ?? null;

      return <Text>{finalMileOrigin}</Text>;
    },
  });

  column.push({
    Header: t("partview-search:Ultimate Destination"),
    id: "destination",
    minWidth: 135,
    disableSortBy: true,
    Cell: LocationCell,
    accessor: (d) => {
      const heldExceptionDateTime = null;
      const destinationEta = d?.DestinationEta;
      const lifecycleState = d?.LifecycleState;

      const type = "destination";
      const location = transformLocation(d.DestinationDetail, type, d);

      return {
        location,
        type,
        isPartSeller,
        destinationEta,
        lifecycleState,
        heldExceptionDateTime,
      };
    },
  });

  if (!isPartSeller) {
    column.push({
      Header: t("partview-search:Active Exceptions"),
      id: "packageExceptions",
      disableSortBy: true,
      minWidth: 125,
      Cell: ExceptionCell,
      accessor: (d) => {
        let exceptions = d.ActiveExceptionList;

        if (!Array.isArray(exceptions)) {
          exceptions = [];
        }

        return { exceptions };
      },
    });
  }

  return column;
};
