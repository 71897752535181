/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, FormCheck, FormGroup } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { groupBy, map, mapValues } from "lodash";

import LadsState from "modules/lads/LadsState";
import {
  LobsType,
  useLadsTranslation,
  getLadName,
} from "modules/lads/utils/lads.utils";
import { Modal } from "components/molecules/Modal.molecule";
import { FlexColDiv, FlexRowDiv, BoldText } from "styles/container-elements";
import { useOrganizations } from "shared/hooks/useOrganizations";

const getLadKey = (lad) => {
  if (_.isEmpty(lad)) {
    return LobsType.UNCLASSIFIED;
  }
  return `${getLadName(lad)} (${lad.lob_name})`;
};

/**
 *
 * @param currentFilters
 * @param filter
 * @param checked
 * @return {*|*[]}
 */
const setFilterValue = (currentFilters, filter, checked) => {
  let filterOption = filter.toLowerCase();
  const filters = currentFilters || [];

  let optionIndex = filters.indexOf(filterOption);

  if (checked) {
    optionIndex === -1
      ? filters.push(filterOption)
      : console.log("Filter option already set");
  } else {
    optionIndex !== -1
      ? filters.splice(optionIndex, 1)
      : console.log("Filter option not set");
  }

  return filters;
};

/**
 *
 * @param lad
 * @param currentFilters
 * @param changeHandler
 * @param getTranslatedLadName
 * @return {*}
 */
const getCheckboxFilter = (
  lad,
  currentFilters,
  changeHandler,
  getTranslatedLadName,
) => {
  const ladKey = getLadKey(lad);
  const ladLabel = getTranslatedLadName(getLadName(lad));

  const isChecked =
    ladKey && currentFilters
      ? currentFilters.indexOf(ladKey.toLowerCase()) !== -1
      : false;

  return (
    <FormGroup
      key={lad.id}
      controlId={lad.id}
      className="m-0"
      css={{ marginBottom: "1rem" }}
    >
      <FormCheck
        type="checkbox"
        name={ladKey}
        label={ladLabel}
        checked={isChecked}
        onChange={(evt) => {
          let val = setFilterValue(currentFilters, ladKey, evt.target.checked);
          changeHandler(val);
        }}
        data-qa="checkbox-lad-filter"
      />
    </FormGroup>
  );
};

/**
 * Shipper Orgs
 *
 * @param {object} props
 * @param {Function} props.changeHandler
 * @param {object | Array} props.lads
 * @param {object | Array} props.value
 * @returns
 */
const ShipperLocationTypeFilterWidget = ({ changeHandler, lads, value }) => {
  const { getTranslatedLobName, getTranslatedLadName } = useLadsTranslation();

  const groupedLads = useMemo(() => groupBy(lads, "lob_name"), [lads]);

  const groupBoxes = mapValues(groupedLads, (lad) =>
    lad.map((l) =>
      getCheckboxFilter(l, value, changeHandler, getTranslatedLadName),
    ),
  );

  const filterColumns = map(groupBoxes, (boxes, groupName) => {
    return (
      <FlexColDiv key={groupName} style={{ flex: 1 }}>
        <BoldText>{getTranslatedLobName(groupName)}</BoldText>
        {boxes}
      </FlexColDiv>
    );
  });

  return (
    <FlexRowDiv style={{ justifyContent: "space-around" }}>
      {filterColumns}
    </FlexRowDiv>
  );
};

ShipperLocationTypeFilterWidget.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

/**
 *
 * @param lad
 * @param currentFilters
 * @param changeHandler
 * @param orgId
 * @param getTranslatedLadName
 * @returns
 */
const getCarrierCheckboxFilter = (
  lad,
  currentFilters,
  changeHandler,
  orgId,
  getTranslatedLadName,
) => {
  const ladKey = getLadKey(lad);
  const ladLabel = getTranslatedLadName(getLadName(lad));

  const isChecked =
    ladKey && currentFilters
      ? currentFilters.indexOf(`${orgId}_${ladKey}`.toLowerCase()) !== -1
      : false;

  return (
    <FormGroup
      key={lad.org_lad_id}
      controlId={lad.org_lad_id}
      className="m-0"
      css={{ marginBottom: "1rem" }}
    >
      <FormCheck
        type="checkbox"
        key={lad.org_lad_id}
        name={ladKey}
        label={ladLabel}
        checked={isChecked}
        onChange={(evt) => {
          let val = setFilterValue(
            currentFilters,
            `${orgId}_${ladKey}`,
            evt.target.checked,
          );
          changeHandler(val);
        }}
        data-qa="checkbox-carrier-lad-filter"
      />
    </FormGroup>
  );
};

/**
 * Carrier Orgs
 *
 * @param {object} props
 * @param {Function} changeHandler
 * @param {object | Array} lads
 * @param {Array} value
 * @returns
 */
const CarrierLocationTypeFilterWidget = ({ changeHandler, lads, value }) => {
  const { t } = useTranslation("locations");
  const { getTranslatedLobName, getTranslatedLadName } = useLadsTranslation();

  const [shipper, setShipper] = useState(null);

  // The keys of `lads` are org IDs.
  const { getOrganizationByDbId } = useOrganizations({
    ids: Object.keys(lads),
  });

  const shipperOptions = useMemo(() => {
    const orgIds = Object.keys(lads);
    return orgIds.map((id) => {
      const org = getOrganizationByDbId(id);
      return { value: id, label: org?.org_name ?? "" };
    });
  }, [lads, getOrganizationByDbId]);

  const groupedLads = shipper ? groupBy(lads[shipper.value], "lob_name") : {};

  const groupBoxes = mapValues(groupedLads, (lad) =>
    lad.map((l) =>
      getCarrierCheckboxFilter(
        l,
        value,
        changeHandler,
        shipper.value,
        getTranslatedLadName,
      ),
    ),
  );

  const filteredColumns = map(groupBoxes, (boxes, groupName) => {
    return (
      <FlexColDiv key={groupName} style={{ flex: 1 }}>
        <BoldText>{getTranslatedLobName(groupName)}</BoldText>
        {boxes}
      </FlexColDiv>
    );
  });

  return (
    <div className="d-flex flex-column">
      <div className="mb-4">
        <div className=" mx-auto w-50">
          <div css={{ fontSize: "75%", fontWeight: "normal" }}>
            {t("locations:Shipper")}
          </div>
          <Select
            name="shipper-state"
            multi={false}
            style={{ width: "100%" }}
            options={shipperOptions}
            value={shipper}
            clearable={false}
            onChange={(selections) => setShipper(selections)}
            data-qa="select-shipper"
          />
        </div>
      </div>

      {shipper ? (
        <FlexRowDiv style={{ justifyContent: "space-around" }}>
          {filteredColumns}
        </FlexRowDiv>
      ) : null}
    </div>
  );
};

CarrierLocationTypeFilterWidget.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.array,
};

/**
 *
 * @param {object} props
 * @param {boolean} props.show
 * @param {Function} props.hide
 * @param {Array} props.value
 * @param {Function} props.changeHandler
 * @param {object | Array} props.lads
 * @param {boolean} props.isCarrierOrg
 * @returns
 */
const LocationTypeSelectFilterModal = ({
  show,
  hide,
  value,
  changeHandler,
  lads,
  isCarrierOrg,
}) => {
  const { t } = useTranslation("locations");

  return (
    <Modal backdrop={"static"} show={show} onHide={() => hide()} size="lg">
      <Modal.Header
        title={
          <Button
            variant="secondary"
            onClick={() => changeHandler([])}
            data-qa="button-clear-location-type-filters"
          >
            {t("locations:Clear Filters")}
          </Button>
        }
      />
      <Modal.Body>
        {isCarrierOrg ? (
          <CarrierLocationTypeFilterWidget
            lads={lads}
            changeHandler={changeHandler}
            value={value}
          />
        ) : (
          <ShipperLocationTypeFilterWidget
            lads={lads}
            changeHandler={changeHandler}
            value={value}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

LocationTypeSelectFilterModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  value: PropTypes.array,
  changeHandler: PropTypes.func,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isCarrierOrg: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    lads: LadsState.selectors.getLadsList(state),
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationTypeSelectFilterModal);
