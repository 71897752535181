import PrivilegeAuthorization from "./PrivilegeAuthorization";
import FeatureAuthorization from "./FeatureAuthorization";
import OrganizationTypeAuthorization from "./OrganizationTypeAuthorization";
import AppConfigAuthorization from "./AppConfigAuthorization";
import AuthenticationUtils from "./authentication";
import { decodeJsonWebToken } from "./auth-utils";
import { BrowserStorage } from "utils/browser-storage.utils";
import OrganizationFiltersAuthorization from "./OrganizationFiltersAuthorization";

export const UserAuthorizationNamespace =
  "http://www.freightverify.com/user_authorization";

export const FederatedUserEmailTrail = ".sso.freightverify.com";

export const Privileges = {
  ANY: "ANY",
  SUPER_PRIVILEGE: "SUPER_PRIVILEGE",
  CHANGE_ACTIVE_ORGANIZATION: "CHANGE_ACTIVE_ORGANIZATION",
  MANAGE_CARRIER_LOCATIONS: "MANAGE_CARRIER_LOCATIONS",
  MANAGE_LOCATIONS: "MANAGE_LOCATIONS",
  MANAGE_ORGANIZATIONS: "MANAGE_ORGANIZATIONS",
  MANAGE_SHIPMENTS: "MANAGE_SHIPMENTS",
  MANAGE_SHIPPER_LOCATIONS: "MANAGE_SHIPPER_LOCATIONS",
  MANAGE_USERS: "MANAGE_USERS",
  VIEW_LOCATIONS: "VIEW_LOCATIONS",
  VIEW_SHIPMENTS: "VIEW_SHIPMENTS",
  CHANGE_SHIPMENT_REVIEW_STATUS: "CHANGE_SHIPMENT_REVIEW_STATUS",
  CHANGE_SHIPMENT_EVENTS: "CHANGE_SHIPMENT_EVENTS",
  CANCEL_SHIPMENT: "CANCEL_SHIPMENT",
  VIEW_REPORTS: "VIEW_REPORTS",
  BUILD_REPORT: "BUILD_REPORT",
  MANAGE_ENTITY: "MANAGE_ENTITY",
  VIEW_ENTITY: "VIEW_ENTITY",
  INV_DASHBOARD: "INV_DASHBOARD",
  CHANGE_USER_PASSWORDS: "CHANGE_USER_PASSWORDS",
  MANAGE_SHARED_REPORTS: "MANAGE_SHARED_REPORTS",
  MANAGE_GEOFENCE_NAME: "MANAGE_GEOFENCE_NAME",
  MANAGE_REPORT_DIRECTORY: "MANAGE_REPORT_DIRECTORY",
  VIEW_PARTVIEW: "VIEW_PARTVIEW",
  ADMIN_TOOL: "ADMIN_TOOL",
  FV_ADMIN_TOOL: "FV_ADMIN_TOOL",
  SHIPMENT_ETA_VALIDATOR: "SHIPMENT_ETA_VALIDATOR",
  VIN_ETA_VALIDATOR: "VIN_ETA_VALIDATOR",
  VIEW_ADMINISTRATIVE_TOOLS: "VIEW_ADMINISTRATIVE_TOOLS",
  MANAGE_NOTIFICATION_MANAGEMENT: "MANAGE_NOTIFICATION_MANAGEMENT",
  FREEZE_ETA: "FREEZE_ETA",
  VIEW_PARTVIEW_SELLER: "VIEW_PARTVIEW_SELLER",
  DPU_ADMIN_TOOL: "DPU_ADMIN_TOOL",
  DEBUG_ETA_TOOL: "DEBUG_ETA_TOOL",
  FREEZE_PARTVIEW_ETA_TOOL: "FREEZE_PARTVIEW_ETA_TOOL",
  VIEW_DAMAGEVIEW_DASHBOARD: "VIEW_DAMAGEVIEW_DASHBOARD",
  MANAGE_SHIPMENT_DWELL_NOTIFICATIONS: "MANAGE_SHIPMENT_DWELL_NOTIFICATIONS",
};

export const Roles = {
  CARRIER_SYSTEM: "CARRIER_SYSTEM",
  CONTAINER_TRACKING_ADMIN: "CONTAINER_TRACKING_ADMIN",
  CONTAINER_TRACKING_USER: "CONTAINER_TRACKING_USER",
  EQUIPMENT_USER: "EQUIPMENT_USER",
  FINISHED_VEHICLE_INV: "FINISHED_VEHICLE_INV",
  FINISHED_VEHICLE_SYSTEM: "FINISHED_VEHICLE_SYSTEM",
  FINISHED_VEHICLE_USER: "FINISHED_VEHICLE_USER",
  FREIGHTVERIFY_ADMIN: "FREIGHTVERIFY_ADMIN",
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  ORGANIZATION_REPORTING_USER: "ORGANIZATION_REPORTING_USER",
  ORGANIZATION_USER: "ORGANIZATION_USER",
  PARTVIEW_SELLER: "PARTVIEW_SELLER",
  PARTVIEW_USER: "PARTVIEW_USER",
  PLANT_ASSET_TRACKING_USER: "PLANT_ASSET_TRACKING_USER",
  REPORT_BUILDER_USER: "REPORT_BUILDER_USER",
  SCAC_ADMIN: "SCAC_ADMIN",
  THIRD_PARTY_SYSTEM: "THIRD_PARTY_SYSTEM",
  DAMAGE_VIEW_USER: "DAMAGEVIEW_USER",
};

export const Features = {
  FINISHED_VEHICLE: "Finished Vehicle",
  CONNECTED_CAR: "Connected Car",
  PLANT_ASSET_TRACKING: "Plant Asset Tracking",
  INVENTORY_VIEW: "Inventory View",
  DAMAGE_VIEW: "Damage View",
  TRANSIT_TIMER: "Transit Timer",
  MILESTONE: "Milestone",
  ITSS: "ITSS",
  DIAGNOSTIC_DATA: "Diagnostic Data",
  SPOT_BUY: "SB",
  VIN_VIEW: "VIN View",
  PART_VIEW: "PartView",
  CONTAINER_TRACKING: "Container Tracking",
  DEALER_DRIVE_AWAY: "Dealer Drive Away",
  ADMINISTRATIVE_TOOLS: "Administrative Tools",
  NOTIFICATION_MANAGEMENT: "Notification Management",
  //TODO: Delete this once shipments team has renamed this feature.
  NOTIFICATION_MANAGEMENT_RENAME_ME: "Notification Management RENAME_ME",
  SAVED_SEARCH_NOTIFICATION_SUBSCRIPTION:
    "Saved Search Notification Subscription",
  CUSTOMER_SUPPORT: "Customer Support",
  FINISHED_VEHICLE_SCHEDULED_WINDOW: "Finished Vehicle Scheduled Window",
  SHIPMENT_DWELL_NOTIFICATION_MANAGEMENT:
    "Shipment Dwell Notification Management",
};

export default class Authorization {
  constructor(
    currentUser = null, // currentUser object from AuthenticationState
    activeOrganization = null, // activeOrganization object from OrganizationsState
    federationData = null, // federationData object from OrganizationsState
    features = [], // featureData object from OrganizationsState
    globalAppConfig = {}, // globalAppConfig object from AppConfigurationState
    entitySystemConfigOptions = [], // entitySystemConfigOptions object from OrganizationsState
    organizationFilters = [], // organizationFilters object from OrganizationsState
  ) {
    // Check the provided auth object for the silent flag - or fall back to session storage
    const isSilentAuthentication =
      AuthenticationUtils.getIsSilentAuthentication();

    // Use the provided federationData object - or fall back to session storage
    let authFederationData =
      federationData ?? BrowserStorage.federationData
        ? JSON.parse(BrowserStorage.federationData)
        : null;

    // TODO: Extract userAuth token logic to a separate class

    // Use the provided user's claims - or fall back to session storage
    let userAuth = null;

    if (currentUser) {
      userAuth = currentUser[UserAuthorizationNamespace];
    } else {
      const accessTokenFromStorage = BrowserStorage.accessToken;
      if (accessTokenFromStorage) {
        const decodedToken = decodeJsonWebToken(accessTokenFromStorage);
        userAuth = decodedToken?.[UserAuthorizationNamespace];
      }
    }

    // Check if federation data should be used instead of the user's claims
    if (isSilentAuthentication && authFederationData) {
      userAuth = authFederationData;

      // Convert JSON strings to objects
      if (
        userAuth?.privileges_by_organization &&
        typeof userAuth?.privileges_by_organization === "string"
      ) {
        userAuth.privileges_by_organization = JSON.parse(
          userAuth.privileges_by_organization,
        );
      }

      if (userAuth?.privileges && typeof userAuth?.privileges === "string") {
        userAuth.privileges = JSON.parse(userAuth.privileges);
      }

      if (userAuth?.solutions && typeof userAuth?.solutions === "string") {
        userAuth.solutions = JSON.parse(userAuth.solutions);
      }
    }

    // Check if the user has multiple organization permissions
    const orgPermissions =
      userAuth?.privileges_by_organization &&
      userAuth?.privileges_by_organization.length
        ? userAuth?.privileges_by_organization
        : [];

    // Check for user permissions for the active organization
    let userOrgPermissions = null;
    if (orgPermissions.length && activeOrganization) {
      userOrgPermissions = orgPermissions.find(
        (p) => p.organization_id === activeOrganization.organization_id,
      );
    }

    // Create an array containng all the user's organization IDs
    this.organizationIds = userAuth ? [userAuth.organization_id] : [];
    if (orgPermissions.length) {
      this.organizationIds = orgPermissions.map((p) => p.organization_id);
    }

    // Use permission data to instantiate privilege checks
    this.privilegeAuthorization = new PrivilegeAuthorization(
      userAuth,
      userOrgPermissions,
    );

    // Use permission and feature data to instantiate feature checks
    this.featureAuthorization = new FeatureAuthorization(
      userAuth,
      userOrgPermissions,
      features,
      this.isFvAdmin(),
    );

    this.appConfigAuthorization = new AppConfigAuthorization(
      globalAppConfig,
      entitySystemConfigOptions,
    );

    // Use the active organization to instantiate organization type checks
    this.organizationTypeAuthorization = new OrganizationTypeAuthorization(
      activeOrganization,
    );

    this.organizationFiltersAuthorzation = new OrganizationFiltersAuthorization(
      organizationFilters,
    );
  }

  // Does the user have a privilege (or one of the privileges in an array)?
  hasPrivileges(names) {
    return this.privilegeAuthorization.hasAnyPrivilege(names);
  }

  // Does the user have a feature (or one of the features in an array)?
  hasFeatures(names) {
    return this.featureAuthorization.hasAnyFeature(names);
  }

  validateGlobalAppConfig(requiredConfig) {
    return this.appConfigAuthorization.hasAnyGlobalAppConfig(requiredConfig);
  }

  validateEntitySystemConfigValue(keyValuePairs) {
    return this.appConfigAuthorization.hasAnyEntitySystemConfig(keyValuePairs);
  }

  getEntitySystemConfig(keyValuePairs) {
    return this.appConfigAuthorization.getEntitySystemConfig(keyValuePairs);
  }

  validateOrganizationFilters(filter) {
    return this.organizationFiltersAuthorzation.hasOrganizationFilter(filter);
  }

  // Does the organization have a type (or one of the types in an array)?
  hasOrganizationTypes(name) {
    const orgTypes = Array.isArray(name) ? name : [name];
    return this.organizationTypeAuthorization.hasAnyOrganizationType(orgTypes);
  }

  hasOrganizationAccess(orgId) {
    if (orgId) {
      const returnValue =
        this.isFvAdmin() || this.organizationIds?.includes(orgId);
      return returnValue;
    }

    return false;
  }

  // Is the user authorized based on any of the criteria provided?
  isAuthorized(
    allowedPrivileges = [],
    allowedFeatures = [],
    allowedOrganizationTypes = [],
    allowedGlobalAppConfigs = [],
    forGroupingPurposes = false,
  ) {
    const arePrivilegesSpecified = allowedPrivileges.length > 0;
    const areFeaturesSpecified = allowedFeatures.length > 0;
    const areOrganizationTypesSpecified = allowedOrganizationTypes.length > 0;
    const areGlobalAppConfigsSpecified = allowedGlobalAppConfigs.length > 0;

    // If no requirements are specified, all users are authorized
    if (
      !arePrivilegesSpecified &&
      !areFeaturesSpecified &&
      !areOrganizationTypesSpecified &&
      !areGlobalAppConfigsSpecified
    ) {
      return true;
    }

    // If privileges are specified, check if the user has any of them
    const hasAnyPrivilege = arePrivilegesSpecified
      ? this.hasPrivileges(allowedPrivileges)
      : true;

    // If features are specified, check if the user has any of them
    const hasAnyFeature = areFeaturesSpecified
      ? this.hasFeatures(allowedFeatures)
      : true;

    // If organization types are specified, check if the organization has any of them
    const hasAnyOrganizationType = areOrganizationTypesSpecified
      ? this.hasOrganizationTypes(allowedOrganizationTypes)
      : true;

    const hasAnyGlobalAppConfigs = areGlobalAppConfigsSpecified
      ? allowedGlobalAppConfigs.reduce((allConfigsValid, config) => {
          return allConfigsValid && this.validateGlobalAppConfig(config);
        }, true)
      : true;

    // Override: for users who only have the Plant Asset Tracking feature, deny non-PAT routes
    const isPatOnlyUser = this.featureAuthorization.plantAssetTrackingOnly();

    if (isPatOnlyUser && areFeaturesSpecified) {
      const isPatRequired = allowedFeatures.includes(
        Features.PLANT_ASSET_TRACKING,
      );
      if (!isPatRequired) {
        return false;
      }
    }

    // This is mostly used for defining NavGroups, so that if any child NavLink has
    // a required privilege, feature, or org type, then we show the NavGroup.
    if (forGroupingPurposes) {
      if (
        arePrivilegesSpecified ||
        areFeaturesSpecified ||
        areOrganizationTypesSpecified ||
        areGlobalAppConfigsSpecified
      ) {
        return (
          hasAnyPrivilege ||
          hasAnyFeature ||
          hasAnyOrganizationType ||
          hasAnyGlobalAppConfigs
        );
      }
    }

    let isAuthorized = true;

    // If privileges are specified - only authorize users with matching privileges.
    if (arePrivilegesSpecified) {
      isAuthorized = isAuthorized && hasAnyPrivilege;
    }

    // If features are specified - only authorize users with matching features.
    if (areFeaturesSpecified) {
      isAuthorized = isAuthorized && hasAnyFeature;
    }

    // If organization types are specified - only authorize users with matching organization types.
    if (areOrganizationTypesSpecified) {
      isAuthorized = isAuthorized && hasAnyOrganizationType;
    }

    // If global app configs are specified - only authorize users with matching global app configs.
    if (areGlobalAppConfigsSpecified) {
      isAuthorized = isAuthorized && hasAnyGlobalAppConfigs;
    }

    return isAuthorized;
  }

  // Is the user a FreightVerify admin? (common privilege check)
  isFvAdmin() {
    return this.privilegeAuthorization.isFvAdmin();
  }
}
