import apiUrl from "api-url";

export const Products = {
  SHIPMENTS: "Shipments",
};

export enum NotificationEventTypes {
  ETA_CHANGE = "BEHIND_SCHEDULE",
  EARLY_ARRIVAL = "EARLY_ARRIVAL",
  CARRIER_DELAY = "CARRIER_DELAY",
  EXCESSIVE_DWELL = "EXCESSIVE_DWELL",
  CARRIER_DELAY_CLEARED = "CARRIER_DELAY_CLEARED",
  OFF_ROUTE_RAIL = "OFF_ROUTE_RAIL",
}

export const eventDefinitionMapping = {
  [NotificationEventTypes.ETA_CHANGE]: "behindScheduleV1",
  [NotificationEventTypes.EARLY_ARRIVAL]: "earlyArrivalV1",
  [NotificationEventTypes.CARRIER_DELAY]: "carrierDelayV1",
  [NotificationEventTypes.EXCESSIVE_DWELL]: "excessiveDwellV1",
  [NotificationEventTypes.CARRIER_DELAY_CLEARED]: "carrierDelayClearedV1",
  [NotificationEventTypes.OFF_ROUTE_RAIL]: "offRouteRailV1",
};

export const DeliveryWindowTrigger = {
  AFTER_DELIVERY_WINDOW_OPEN: "After Delivery Window Open",
  AFTER_DELIVERY_WINDOW_CLOSE: "After Delivery Window Close",
};

export const ApiOptions = {
  pageSize: 20,
  pageNumber: 0,
  verbose: false,
};

export const ApiRequestStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export enum FilterTypesEnum {
  ORIGIN = "originCodes",
  DESTINATION = "destinationCodes",
  PARTS_NUMBER = "partNumbers",
  SCHEDULED_DELIVERY = "scheduledArrival",
  CARRIER = "carrierFvIds",
  MILES_OUT = "withinRangeOfDestination",
  MODE = "mode",
}

export enum ScheduleDeliveryOptionsEnum {
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
}

export enum ModeOptionsEnum {
  TRUCK = "Truck",
  OCEAN = "Ocean",
  LTL = "LTL",
  PARCEL = "Parcel",
  RAIL = "Rail",
  AIR = "Air",
  INTERMODAL = "Intermodal",
  MULTIMODAL = "Multimodal",
}

export const defaultNotificationEventInformation = {
  [NotificationEventTypes.ETA_CHANGE]: {
    notificationEventType: NotificationEventTypes.ETA_CHANGE,
    notificationTriggerTimeObj: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    notificationTrigger: DeliveryWindowTrigger.AFTER_DELIVERY_WINDOW_OPEN,
    requiresInputValidation: true,
  },
  [NotificationEventTypes.EARLY_ARRIVAL]: {
    notificationEventType: NotificationEventTypes.EARLY_ARRIVAL,
    notificationTriggerTimeObj: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    requiresInputValidation: true,
  },
  [NotificationEventTypes.CARRIER_DELAY]: {
    notificationEventType: NotificationEventTypes.CARRIER_DELAY,
    requiresInputValidation: false,
  },
  [NotificationEventTypes.EXCESSIVE_DWELL]: {
    notificationEventType: NotificationEventTypes.EXCESSIVE_DWELL,
    requiresInputValidation: false,
  },
  [NotificationEventTypes.CARRIER_DELAY_CLEARED]: {
    notificationEventType: NotificationEventTypes.CARRIER_DELAY_CLEARED,
    requiresInputValidation: false,
  },
  [NotificationEventTypes.OFF_ROUTE_RAIL]: {
    notificationEventType: NotificationEventTypes.OFF_ROUTE_RAIL,
    requiresInputValidation: false,
  },
};

export const eventTypeSupportedFilters = {
  [NotificationEventTypes.ETA_CHANGE]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
    FilterTypesEnum.MODE,
  ],
  [NotificationEventTypes.EARLY_ARRIVAL]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
    FilterTypesEnum.MODE,
  ],
  [NotificationEventTypes.CARRIER_DELAY]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
    FilterTypesEnum.MODE,
  ],
  [NotificationEventTypes.EXCESSIVE_DWELL]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
    FilterTypesEnum.MODE,
  ],
  [NotificationEventTypes.CARRIER_DELAY_CLEARED]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
    FilterTypesEnum.MODE,
  ],
  [NotificationEventTypes.OFF_ROUTE_RAIL]: [
    FilterTypesEnum.ORIGIN,
    FilterTypesEnum.DESTINATION,
    FilterTypesEnum.SCHEDULED_DELIVERY,
    FilterTypesEnum.PARTS_NUMBER,
    FilterTypesEnum.CARRIER,
    FilterTypesEnum.MILES_OUT,
  ],
};

export const filterTypeOptions = [
  {
    key: "ORIGIN",
    label: "Origin",
    value: FilterTypesEnum.ORIGIN,
    allowMultiSelect: true,
  },
  {
    key: "DESTINATION",
    label: "Destination",
    value: FilterTypesEnum.DESTINATION,
    allowMultiSelect: true,
  },
  {
    key: "SCHEDULED_DELIVERY",
    label: "Scheduled Delivery",
    value: FilterTypesEnum.SCHEDULED_DELIVERY,
    allowMultiSelect: false,
  },
  {
    key: "PARTS_NUMBER",
    label: "Part Number",
    value: FilterTypesEnum.PARTS_NUMBER,
    allowMultiSelect: true,
  },
  {
    key: "CARRIER",
    label: "Carrier",
    value: FilterTypesEnum.CARRIER,
    allowMultiSelect: true,
  },
  {
    key: "MILES_OUT",
    label: "Miles Out",
    value: FilterTypesEnum.MILES_OUT,
    allowMultiSelect: false,
  },
  {
    key: "MODE",
    label: "Mode",
    value: FilterTypesEnum.MODE,
    allowMultiSelect: true,
  },
];

export interface FilterOptionUrlLookupTableType {
  [key: string]: string;
  originCodes: string;
  destinationCodes: string;
  partNumbers: string;
  carrierFvIds: string;
}

export const filterOptionUrlLookupTable: FilterOptionUrlLookupTableType = {
  [FilterTypesEnum.ORIGIN]: apiUrl("/location/locations"),
  [FilterTypesEnum.DESTINATION]: apiUrl("/location/locations"),
  [FilterTypesEnum.PARTS_NUMBER]: apiUrl("/partview/app/list/parts"),
  [FilterTypesEnum.CARRIER]: apiUrl("/shipping-ng/carriers"),
};
