import { fallBackLanguage } from "i18n";

export const SupportedLanguages = {
  "Chinese (Simplified)": "zh",
  Dutch: "nl",
  English: "en",
  French: "fr",
  "French (Canadian)": "fr-CA",
  German: "de",
  Italian: "it",
  Polish: "pl",
  Russian: "ru",
  Spanish: "es",
  Portugese: "pt",
};

export const findSupportedLanguage = (locale) => {
  if (!locale) {
    return false;
  }

  let supportedLanguage = Object.values(SupportedLanguages).find(
    (lang) => lang === locale,
  );

  if (supportedLanguage) {
    return supportedLanguage;
  }

  //   Support for fallback language, eg: `en-US` is supported via `en`.
  const browserLanguage = locale.split("-");
  if (!supportedLanguage) {
    supportedLanguage = Object.values(SupportedLanguages).find(
      (lang) => lang === browserLanguage[0],
    );
  }

  return supportedLanguage ? supportedLanguage : fallBackLanguage;
};
