import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import HealthcareSearchBarState from "../../redux/HealthcareSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import HealthcareDomainData from "modules/domain-data/HealthcareDomainData";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchValue,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
  } = HealthcareSearchBarState.selectors;

  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchValue: getSearchValue(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    solutionId: getSolutionId(state),
    assets: HealthcareDomainData.selectors.getAssets(state),
    // Each search category "property" field must be unique.
    // In order to search the same dataset, had to duplicate assets.
    // See HealthcareSearchBarCategoryDefs.
    assetsId: HealthcareDomainData.selectors.getAssets(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    setSearchValue,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
    clickedSearchOrEnter,
  } = HealthcareSearchBarState.actionCreators;
  const { fetchDomainData } = HealthcareDomainData.actionCreators;

  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) => {
      dispatch(setSearchText(text, ignoreValueChange, true));
    },
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => {
      dispatch(clickedSearchOrEnter(true));
      dispatch(searchEntities(solutionId, false));
    },
    fetchDomainData: (solutionId) => dispatch(fetchDomainData(solutionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
