import { MadChicletCSS as Chiclet } from "components/chiclets";
import Colors from "styles/colors";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import { Icon } from "components/atoms/Icon.atom";
import PropTypes from "prop-types";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { ViewExpandedDetailsButton } from "./VinEtaValidator.ExpandedDetails.organism";
import _ from "lodash";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { parseDateTime } from "utils/date-time";
import { useTranslation } from "react-i18next";

const FilterInput = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      value={filterValue ?? ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely.
        setFilter(e.target.value ?? undefined);
      }}
    />
  );
};

FilterInput.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
  }),
};

const TriggerCell = ({ value: data }) => {
  const { t } = useTranslation("vin-eta-validator");
  const triggerMessage = () => {
    const nullTriggerType = _.isNil(data.triggerType);
    if (nullTriggerType) {
      return "";
    }
    if (data.triggerType === "TripLeg") {
      return t("vin-eta-validator:Haulaway ETA");
    }
    if (data.triggerType.toLowerCase() === "mode_segment_eta_update") {
      return t("vin-eta-validator:Segment ETA");
    }
    if (data.triggerType.toLowerCase() === "entity_status_update") {
      return t("vin-eta-validator:Milestone ETA");
    }
    if (data.triggerType.toLowerCase() === "mode_2_update") {
      return t("vin-eta-validator:Rail ETA");
    }
    if (data.triggerType.toLowerCase() === "mode_5_update") {
      return t("vin-eta-validator:Ocean ETA");
    }
    if (data.triggerType === "overrideEta") {
      return t("vin-eta-validator:FV Admin ETA");
    }
  };
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block>{triggerMessage()}</Text>
      <Text block>{data.etaCalculationPointOfInterest}</Text>
      {data.etaCalculationCity &&
      data.etaCalculationStateOrProvince &&
      data.etaCalculationCountry ? (
        <Text block>
          {data.etaCalculationCity}, {data.etaCalculationStateOrProvince},{" "}
          {data.etaCalculationCountry}
        </Text>
      ) : null}

      {data.triggerMode ? (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {data.triggerType === "mode_segment_eta_update" ||
          data.triggerType === "entity_status_update" ? null : (
            <div css={{ marginLeft: "0.25em" }}>
              <Chiclet
                shipmentMode={data.triggerMode}
                stopMode={data.triggerMode}
                activeException={null}
                width={25}
                height={25}
              />
            </div>
          )}
          <div
            css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}
          >
            {data.triggerMode}
          </div>
        </div>
      ) : null}
    </div>
  );
};

TriggerCell.propTypes = {
  value: PropTypes.object,
};

const ScheduledDeliveryWindow = ({ value: entity }) => {
  return (
    <DateTimeRange
      plain
      localize
      from={entity.scheduledDeliveryWindow[0]}
      to={entity.scheduledDeliveryWindow[1]}
    />
  );
};
const DestinationCell = ({ value: entity }) => {
  const { t } = useTranslation("vin-eta-validator");

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block bold>
        {entity.entityUltimateDestinationCode}
      </Text>
      <Text block>{entity.entityUltimateDestinationName}</Text>
      {entity.destinationCity && entity.destinationState ? (
        <Text>{`${entity.destinationCity}, ${entity.destinationState}`}</Text>
      ) : null}
      <Fragment>
        {(entity.lifeCycleState === "Active" &&
          entity.scheduledDeliveryWindow) ||
        !entity.entityTripPlanCompleteTime ? (
          <Text block bold underline>
            {t("vin-eta-validator:Scheduled Delivery")}
          </Text>
        ) : null}
        {(entity.lifeCycleState === "Delivered" ||
          entity.lifeCycleState?.toLowerCase() === "archived") &&
        entity.entityTripPlanCompleteTime ? (
          <Text block bold underline>
            {t("vin-eta-validator:Actual Delivery")}
          </Text>
        ) : null}
        <Text block>
          {entity.entityTripPlanCompleteTime ? (
            <DateTimeCell
              dateTime={entity.entityTripPlanCompleteTime}
              localize
            />
          ) : (
            <ScheduledDeliveryWindow value={entity} />
          )}
        </Text>
      </Fragment>
    </div>
  );
};

DestinationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

const CalculationMessageCell = ({ value: data }) => {
  const { t } = useTranslation("vin-eta-validator");
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text block>{data.etaModelName}</Text>
      {data.numberOfDaysInHistory ? (
        <Text block>
          {t("vin-eta-validator:[[[numOfDays]]] days history", {
            numOfDays: data.numberOfDaysInHistory,
          })}
        </Text>
      ) : null}
      {data.percentileUsedInCalculation ? (
        <Text block>
          {data.percentileUsedInCalculation}
          {t("vin-eta-validator:th percentile")}
        </Text>
      ) : null}
      {data.etaModelVersion ? (
        <Text block>
          {t("vin-eta-validator:Version ID - ")}
          {data.etaModelVersion}
        </Text>
      ) : null}
    </div>
  );
};

CalculationMessageCell.propTypes = {
  value: PropTypes.object,
};

const DataSignifier = () => {
  return (
    <Icon
      src={faMinus}
      color={Colors.tables.tableHeadingText}
      css={{ display: "block", margin: "auto" }}
    />
  );
};

export const useColumns = () => {
  const { t } = useTranslation("vin-eta-validator");
  return [
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:ETA Update Time")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("The date/time that the ETA was updated")}</Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "calcTime",
      width: 160,
      accessor: (row) => {
        return row.etaCalculationTime;
      },
      Cell: (cellProps) =>
        cellProps.value ? (
          <DateTimeCell dateTime={cellProps.value} localize />
        ) : (
          <DataSignifier />
        ),
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          if (rowValue) {
            const { date, time, timezone } = parseDateTime(rowValue, true);
            return (time + " " + timezone + " " + date)
              .toLowerCase()
              .includes(lowerFilterValue);
          } else {
            return "N/A".toLowerCase().includes(lowerFilterValue);
          }
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:ETA Destination Time")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("The ETA date/time that was calculated")}</Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "destTime",
      width: 160,
      accessor: (row) => row.etaDate,
      Cell: (cellProps) =>
        cellProps.value ? (
          <DateTimeCell dateTime={cellProps.value} localize />
        ) : (
          <DataSignifier />
        ),
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          if (rowValue) {
            const { date, time, timezone } = parseDateTime(rowValue, true);
            return (time + " " + timezone + " " + date)
              .toLowerCase()
              .includes(lowerFilterValue);
          } else {
            return "N/A".toLowerCase().includes(lowerFilterValue);
          }
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:ETA Difference")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "If delivered, this is the delta of the ETA Destination Time and the actual delivered date/time of the VIN",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "etaDiff",
      width: 180,
      sortType: (rowA, rowB) => {
        if (
          !rowA.original.entityTripPlanCompleteTime ||
          !rowA.original.etaDate
        ) {
          return -1;
        }
        const rowAActualDate = moment(rowA.original.entityTripPlanCompleteTime);
        const rowAEtaDate = moment(rowA.original.etaDate);
        const rowADiff = rowAEtaDate.diff(rowAActualDate, "hours", true);
        const rowBActualDate = moment(rowB.original.entityTripPlanCompleteTime);
        const rowBEtaDate = moment(rowB.original.etaDate);
        const rowBDiff = rowBEtaDate.diff(rowBActualDate, "hours", true);
        return rowADiff > rowBDiff ? 1 : -1;
      },
      accessor: (row) => {
        if (!row.etaDifference) {
          return t("vin-eta-validator:N/A");
        }
        return t("vin-eta-validator:[[[count]]] hour(s)", {
          count: (() => {
            if (row.entityTripPlanCompleteTime && row.etaDate) {
              let actualDate = moment(row.entityTripPlanCompleteTime);
              let etaDate = moment(row.etaDate);
              return etaDate.diff(actualDate, "hours", true).toFixed(1);
            }
            return "---";
          })(),
        });
      },
      Filter: FilterInput,
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:Trigger")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t("The ETA trigger name and location/mode if applicable")}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "trigger",
      accessor: (row) => row,
      width: 200,
      Cell: (cellProps) =>
        cellProps?.value?.triggerType ? (
          <TriggerCell value={cellProps.value} />
        ) : (
          <DataSignifier />
        ),
      sortType: (rowA, rowB) => {
        if (
          !rowA?.values?.Trigger?.triggerType ||
          !rowB?.values?.Trigger?.triggerType
        ) {
          return -1;
        }
        return rowA.values.Trigger.triggerType > rowB.values.Trigger.triggerType
          ? 1
          : -1;
      },
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.triggerType
            ? rowValue.triggerType.toLowerCase()
            : "";
          const line2 = rowValue.etaCalculationPointOfInterest
            ? rowValue.etaCalculationPointOfInterest.toLowerCase()
            : "";
          const line3 =
            rowValue.etaCalculationCity &&
            rowValue.etaCalculationStateOrProvince &&
            rowValue.etaCalculationCountry
              ? (
                  rowValue.etaCalculationCity +
                  ", " +
                  rowValue.etaCalculationStateOrProvince +
                  ", " +
                  rowValue.etaCalculationCountry
                ).toLowerCase()
              : "";
          const line4 = rowValue.triggerMode
            ? rowValue.triggerMode.toLowerCase()
            : "";
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:Destination")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("This is the ultimate destination of the VIN")}</Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      Cell: DestinationCell,
      id: "destination",
      accessor: (row) => row,
      disableSortBy: true,
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.entityUltimateDestinationCode
            ? rowValue.entityUltimateDestinationCode?.toLowerCase()
            : "";

          const line2 = rowValue.entityUltimateDestinationName
            ? rowValue.entityUltimateDestinationName.toLowerCase()
            : "";

          const line3 =
            rowValue.destinationCity && rowValue.destinationState
              ? `${rowValue.destinationCity}, ${rowValue.destinationState}`.toLowerCase()
              : "";

          const line4 =
            (rowValue.lifeCycleState === "Active" ||
              rowValue.lifeCycleState?.toLowerCase() === "scheduled") &&
            rowValue.scheduledDeliveryWindow
              ? t("vin-eta-validator:Scheduled Delivery").toLowerCase()
              : "";

          const line5 =
            (rowValue.lifeCycleState === "Delivered" ||
              rowValue.lifeCycleState?.toLowerCase() === "archived") &&
            rowValue.entityTripPlanCompleteTime
              ? t("vin-eta-validator:Actual Delivery").toLowerCase()
              : "";

          let line6 = "n/a";
          if (rowValue.entityTripPlanCompleteTime) {
            const { date, time, timezone } = parseDateTime(
              rowValue.entityTripPlanCompleteTime,
              true,
            );
            line6 = (time + " " + timezone + " " + date).toLowerCase();
          }
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue) ||
            line5.includes(lowerFilterValue) ||
            line6.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:Model Data Information")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "Contains ETA model name, percentile, and ID for an ETA calculation",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "modelDataInformation",
      accessor: (row) => row,
      Cell: (cellProps) => {
        if (
          !cellProps?.value?.etaCalculationName &&
          cellProps?.values?.etaModelName !==
            _.isNil(cellProps?.values?.etaModelName)
        ) {
          return <CalculationMessageCell value={cellProps.value} />;
        }
        if (!cellProps?.value?.etaCalculationName) {
          return <DataSignifier />;
        }

        return cellProps?.value?.etaCalculationName ? (
          "Legacy"
        ) : (
          <CalculationMessageCell value={cellProps.value} />
        );
      },
      width: 165,
      sortType: (rowA, rowB) =>
        rowA.original.etaModelName > rowB.original.etaModelName ? 1 : -1,
      Filter: FilterInput,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          const lowerFilterValue = filterValue.toLowerCase();
          const line1 = rowValue.etaModelName.toLowerCase();
          const line2 = rowValue.numberOfDaysInHistory
            ? t("vin-eta-validator:[[[numOfDays]]] days history", {
                numOfDays: rowValue.numberOfDaysInHistory,
              }).toLowerCase()
            : "";
          const line3 = rowValue.percentileUsedInCalculation
            ? (
                rowValue.percentileUsedInCalculation +
                t("vin-eta-validator:th percentile")
              ).toLowerCase()
            : "";
          const line4 = rowValue.etaModelVersion
            ? (
                t("vin-eta-validator:Version ID - ") + rowValue.etaModelVersion
              ).toLowerCase()
            : "";
          return (
            line1.includes(lowerFilterValue) ||
            line2.includes(lowerFilterValue) ||
            line3.includes(lowerFilterValue) ||
            line4.includes(lowerFilterValue)
          );
        });
      },
    },
    {
      Header: (headerProps) =>
        headerProps ? (
          <Fragment>
            {t("vin-eta-validator:Expanded Details")}
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>
                  {t(
                    "Additional data pertaining to the event and calculation of an ETA",
                  )}
                </Text>
              }
            >
              <Icon
                src={faInfoCircle}
                color={Colors.tables.tableHeadingText}
                css={{ marginLeft: 8 }}
              />
            </Tooltip>
          </Fragment>
        ) : null,
      id: "expandedDetails",
      accessor: (row) => row,
      Cell: (row) => {
        return <ViewExpandedDetailsButton etaTrigger={row.value} />;
      },
      disableFilters: true,
      disableSortBy: true,
      width: 190,
    },
  ];
};
