import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum OrderPriorityName {
  DRO = "Dealer Replenishment Order",
  CSO = "Customer Service Order",
  QPO = "Qualified Production Order",
  OVN = "Overnight (FedEx)",
  RIM = "Retail Inventory Management",
  SLP = "Service Lane Part",
  BORA = "Back Order Release",
  HAZD = "HAZ/DG",
  HAZR = "HAZ/DG",
  BOR = "Back Order Release",
  VIP = "ACDelco CSO",
  OEM = "OEM",
  INTL = "International",
  AMP = "AMP",
  STK = "DRO",
  XTRF = "Carload - Not Dealer Order",
  CLDR = "CLDR",
  CLDE = "CLDE",
  TRK = "Truckload",
  PAD = "Non DDS",
  OTD = "Non DDS",
  INT = "International",
  ALST = "ALST",
}

export const useOrderPriorityNameTranslation = () => {
  const { t } = useTranslation("partview-search");
  const getTranslatedOrderPriorityName = useCallback(
    (orderPriorityNameCode: string) => {
      let orderPriorityCodeIndex = orderPriorityNameCode?.indexOf("(");
      let orderPriorityName = orderPriorityNameCode?.substring(
        0,
        orderPriorityCodeIndex,
      );
      let orderPriorityCode = orderPriorityNameCode?.substring(
        orderPriorityCodeIndex,
        orderPriorityNameCode.length,
      );

      switch (orderPriorityName) {
        case OrderPriorityName.DRO:
          return `${t(
            "partview-search:Dealer Replenishment Order",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.CSO:
          return `${t(
            "partview-search:Customer Service Order",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.QPO:
          return `${t(
            "partview-search:Qualified Production Order",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.OVN:
          return `${t(
            "partview-search:Overnight (FedEx)",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.RIM:
          return `${t(
            "partview-search:Retail Inventory Management",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.BORA:
          return `${t(
            "partview-search:Back Order Release",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.HAZD:
          return `${t("partview-search:HAZ/DG")} ${orderPriorityCode}`;
        case OrderPriorityName.HAZR:
          return `${t("partview-search:HAZ/DG")} ${orderPriorityCode}`;
        case OrderPriorityName.BOR:
          return `${t(
            "partview-search:Back Order Release",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.VIP:
          return `${t("partview-search:ACDelco CSO")} ${orderPriorityCode}`;
        case OrderPriorityName.OEM:
          return `${t("partview-search:OEM")} ${orderPriorityCode}`;
        case OrderPriorityName.INTL:
          return `${t("partview-search:International")} ${orderPriorityCode}`;
        case OrderPriorityName.AMP:
          return `${t("partview-search:AMO")} ${orderPriorityCode}`;
        case OrderPriorityName.STK:
          return `${t("partview-search:DRO")} ${orderPriorityCode}`;
        case OrderPriorityName.XTRF:
          return `${t(
            "partview-search:Carload - Not Dealer Order",
          )} ${orderPriorityCode}`;
        case OrderPriorityName.CLDR:
          return `${t("partview-search:CLDR")} ${orderPriorityCode}`;
        case OrderPriorityName.CLDE:
          return `${t("partview-search:CLDE")} ${orderPriorityCode}`;
        case OrderPriorityName.TRK:
          return `${t("partview-search:Truckload ?")} ${orderPriorityCode}`;
        case OrderPriorityName.PAD:
          return `${t("partview-search:Non DDS")} ${orderPriorityCode}`;
        case OrderPriorityName.OTD:
          return `${t("partview-search:Non DDS")} ${orderPriorityCode}`;
        case OrderPriorityName.INT:
          return `${t("partview-search:INT")} ${orderPriorityCode}`;
        case OrderPriorityName.ALST:
          return `${t("partview-search:ALST")} ${orderPriorityCode}`;
        default:
          return orderPriorityNameCode;
      }
    },
    [t],
  );
  return { getTranslatedOrderPriorityName };
};
