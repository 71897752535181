import React from "react";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { isShipper } from "shared/utils/organizations.utils";
import { useTranslation } from "react-i18next";

export const DealerPickUpFormButton = ({
  activeOrganization,
  exportPDFEntities,
  approvalId,
  solutionId,
  userPreference,
  isExportingPDF,
}) => {
  const { t } = useTranslation("driveaway-search");
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        const exportSolutionId = isShipper(activeOrganization)
          ? solutionId
          : null;
        exportPDFEntities(approvalId, exportSolutionId, userPreference?.locale);
      }}
    >
      {t("driveaway-search:Dealer Pick-Up Form")}
      {isExportingPDF ? (
        <Icon style={{ marginLeft: "0.5rem" }} src={faSpinner} spin />
      ) : null}
    </Button>
  );
};
