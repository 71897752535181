import { connect } from "react-redux";

import { Unsubscribe } from "./Unsubscribe.page";
import UnsubscribeState from "./redux/UnsubscribeState";

function mapStateToProps(state) {
  return {
    guid: state.location.payload.guid,
    category: state.location.payload.category,
    subscription: UnsubscribeState.selectors.getSubscription(state),
    organizationImageConfig:
      UnsubscribeState.selectors.getOrganizationImageConfig(state),
    isFetchingSubscription:
      UnsubscribeState.selectors.getIsFetchingSubscription(state),
    isFetchSubscriptionError:
      UnsubscribeState.selectors.getIsFetchSubscriptionError(state),
    subscriptionErrorMessage:
      UnsubscribeState.selectors.getFetchSubscriptionErrorMessage(state),
    isUnsubscribeComplete:
      UnsubscribeState.selectors.getIsUnsubscribeComplete(state),
    isUnsubscribing: UnsubscribeState.selectors.getIsUnsubscribing(state),
    isUnsubscribeError: UnsubscribeState.selectors.getIsUnsubscribeError(state),
    unsubscribeErrorMessage:
      UnsubscribeState.selectors.getUnsubscribeErrorMessage(state),
  };
}

const {
  resetUnsubscribe,
  fetchSubscription,
  unsubscribe,
  fetchOrganizationImages,
} = UnsubscribeState.actionCreators;

const actionCreators = {
  resetUnsubscribe,
  fetchSubscription,
  unsubscribe,
  fetchOrganizationImages,
};

export default connect(mapStateToProps, actionCreators)(Unsubscribe);
