/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  FormControl,
  InputGroup,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import SelectField from "components-old/forms/fields/SelectField";
import Colors from "styles/colors";
import { FlexDiv, FlexRowDiv } from "styles/container-elements";

// Modal for applying a batch search filter (allows user to upload CSV or enter line-separated filter values)
export const BatchFilterModal = ({
  batchType,
  batchList,
  searchTypeOptions,
  show,
  hide,
  onSubmit,
}) => {
  const { t } = useTranslation(["components"]);

  const [selectedSearchType, setSelectedSearchType] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [importText, setImportText] = useState("");

  const textChangeHandler = useCallback((e) => {
    const batchText = e && e.target && e.target.value ? e.target.value : "";
    const batchLines = batchText.split(/\r\n|\n/);
    setImportText(batchLines.toString());
  }, []);

  useEffect(() => {
    // If the batchType was cleared by the user (via redux action).
    if (_.isNil(batchType)) {
      setSelectedSearchType(null);
    } else {
      setSelectedSearchType(batchType);
    }
  }, [batchType, show]);

  useEffect(() => {
    // If the batchList was cleared by the user (via redux action).
    if (_.isNil(batchList)) {
      setImportFile(null);
      setImportText("");
    } else {
      textChangeHandler({
        target: { value: batchList.split(",").join("\n") },
      });
    }
  }, [batchList, show, textChangeHandler]);

  const fileSelectHandler = (e) => {
    let newFile = e.target.files[0];
    setImportFile(newFile);
  };

  const fileResetHandler = (e) => {
    setImportFile(null);
  };

  const fileDropHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
      const file = e.dataTransfer.items[0].getAsFile();
      setImportFile(file);
    }
  };

  const isBatchSearchValid = () => importFile || importText;

  const disabled = !isBatchSearchValid();

  const onModalClose = () => {
    if (importFile) {
      setImportFile(null);
    }
    hide();
  };

  const convertLinesToCsv = (lines) => {
    const parsedLines = lines.map((l) =>
      l.toString().replace(/"([^"]+(?="))"/g, "$1"),
    );

    // Replaces 0 or more commas at the end of the string
    // This is when user adds one or more empty lines in the text Area
    return parsedLines.join(",").replace(/,*$/, "");
  };

  const fetchSearch = (batchLines) => {
    const payload = {
      batch_type: selectedSearchType,
      batch_list: batchLines ? convertLinesToCsv(batchLines) : "",
    };
    onSubmit(payload);
    onModalClose();
  };

  const onSearchClick = () => {
    let batchLines = importText.split(",");

    if (importFile) {
      const reader = new FileReader();
      reader.readAsText(importFile);
      reader.onload = (file) => {
        const batchText = file.target.result.trim();
        batchLines = batchText.split(/\r\n|\n/);
        if (batchLines.length > 1) {
          batchLines.splice(0, 1); // Remove the header row
          fetchSearch(batchLines);
        }
      };
    } else {
      fetchSearch(batchLines);
    }
  };

  const selectedSearchTypeOption = _.find(
    searchTypeOptions,
    (o) => o.value === selectedSearchType,
  );

  const selectedSearchTypeLabel = selectedSearchTypeOption
    ? selectedSearchTypeOption.label
    : "";

  const csvTooltip = (
    <Tooltip id="csv-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "left",
        }}
      >
        {`${t("components:The file must contain")}:`}
        <ul css={{ paddingLeft: "1.25em" }}>
          <li>{selectedSearchTypeLabel} 1</li>
          <li>{selectedSearchTypeLabel} 2</li>
          <li>{selectedSearchTypeLabel} 3</li>
        </ul>
      </div>
    </Tooltip>
  );

  // Find the `batchCsvExample` object using the currently
  // selected batchType in state.
  const csvSample =
    _.find(searchTypeOptions, (o) => o.value === selectedSearchType)
      ?.batchCsvExample ?? null;

  return (
    <Modal
      // H1-314: User is selecting the email field via dragging towards the outside of
      // the modal, which closes it. This is apparently a bug with our version of
      // react-bootstrap https://github.com/reactjs/react-modal/issues/633 which
      // closes the modal when the backdrop detects an onMouseUp event.
      // For now just disable closing the modal when the user clicks outside of it,
      // if the users complain we will need to look for alternative ways of handling
      // this (maybe increasing the padding between the modal's border and its
      // contents).
      backdrop={"static"}
      show={show}
      onShow={() => {}}
      onHide={() => {
        onModalClose();
      }}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("components:Batch Search")}
      />
      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <div>
          <div
            css={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginTop: "0em",
            }}
          />
          <div
            css={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginTop: "1em",
            }}
          />
        </div>
        <div />
        <div>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <SelectField
              placeholder={t("components:Select search criteria")}
              stateValue={selectedSearchType}
              options={searchTypeOptions}
              onChange={(val) => setSelectedSearchType(val.value)}
            />
          </FormGroup>

          {selectedSearchType ? (
            <div>
              <FlexRowDiv css={{ marginBottom: "0.5rem" }}>
                <span>{t("components:Select a CSV file to import")}</span>
                {". "}
                <OverlayTrigger placement="right" overlay={csvTooltip}>
                  <div css={{ display: "flex" }}>
                    <FaQuestionCircle />
                  </div>
                </OverlayTrigger>
                <FlexDiv
                  css={{
                    marginLeft: "1em",
                  }}
                >
                  <DownloadCsvLink
                    data={csvSample?.data}
                    headers={csvSample?.headers}
                    label={t("components:Download template (.csv)")}
                    filename={
                      csvSample?.headers[0].key +
                      "-template-" +
                      Date.now() +
                      ".csv"
                    }
                  />
                </FlexDiv>
              </FlexRowDiv>

              <FormGroup css={{ marginBottom: "1rem" }}>
                <InputGroup>
                  <label
                    className="input-group-btn"
                    css={{ paddingRight: "0.375rem" }}
                  >
                    <span
                      className={`btn btn-light ${
                        importText ? "disabled" : ""
                      }`}
                    >
                      {`${t("components:Browse")}`}
                      <input
                        id="files"
                        type="file"
                        accept=".csv"
                        title={null}
                        //empty the value so that onchange will be invoked when uploading the same file
                        //https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-612959113
                        onClick={(e) => (e.target.value = null)}
                        onChange={fileSelectHandler}
                        disabled={importText ? true : false}
                        data-qa="input-file-batch-modal"
                        hidden
                      />
                    </span>
                  </label>

                  <FormControl
                    type="type"
                    disabled={importText ? true : false}
                    value={importFile ? importFile.name : ""}
                    placeholder={t("components:Select file")}
                    htmlFor="files"
                    style={{ zIndex: 0, borderRadius: "0.375rem" }}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onDrop={fileDropHandler}
                    readOnly
                  />
                  {importFile && (
                    <span className="input-group-btn">
                      <Button
                        variant="light"
                        onClick={fileResetHandler}
                        data-qa="button-remove-file-batch-modal"
                        css={{ marginLeft: "0.25rem" }}
                      >
                        <MdClose />
                      </Button>
                    </span>
                  )}
                </InputGroup>
              </FormGroup>

              <FormGroup css={{ marginBottom: "1rem" }}>
                <label css={{ marginBottom: "0.5rem" }}>
                  {selectedSearchTypeOption?.description
                    ? `${selectedSearchTypeOption.description}`
                    : `${t("Enter")} ${selectedSearchTypeLabel}s (${t(
                        "components:Line Separated",
                      )})`}
                </label>
                <textarea
                  className="form-control"
                  rows={10}
                  value={
                    !importFile && importText
                      ? importText
                          .split(",")
                          .map((x) => x.trimStart())
                          .join("\r\n")
                      : ""
                  }
                  onChange={textChangeHandler}
                  disabled={importFile ? true : false}
                  data-qa="textarea-batch-modal"
                ></textarea>
              </FormGroup>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          variant="light"
          css={{
            marginRight: "0.5em",
          }}
          onClick={() => {
            onModalClose();
          }}
          data-qa="button-cancel-batch-modal"
        >
          {t("components:Cancel")}
        </Button>
        <Button
          variant={disabled ? "dark" : "primary"}
          disabled={disabled}
          pulse={!disabled}
          onClick={onSearchClick}
          data-qa="button-search-batch-modal"
        >
          {t("components:Search")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BatchFilterModal.propTypes = {
  batchType: PropTypes.any,
  batchList: PropTypes.any,
  searchTypeOptions: PropTypes.array,
  show: PropTypes.bool,
  hide: PropTypes.func,
  onSubmit: PropTypes.func,
};
