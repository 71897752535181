/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import VisibilitySensor from "react-visibility-sensor";
import ReactMarkdown from "react-markdown";
import { InputGroup } from "react-bootstrap";
import {
  faUserCircle,
  faEdit,
  faCaretLeft,
  faSave,
  faPaperPlane,
  faCommentAlt,
  faCaretDown,
  faCaretUp,
  faSpinner,
  faShareSquare,
  faUpload,
  faArrowSquareUp,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import { isSafari } from "react-device-detect";

import Colors from "styles/colors";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { BatchCommentModal } from "components/search-bar/BatchCommentModal";
import { OrganizationType } from "shared/constants/organization.const";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import AuthenticationUtils from "modules/auth/authentication";
import { useOrganizations } from "shared/hooks/useOrganizations";

const MAX_CHARACTERS_PER_COMMENT = 2000;

const UnreadLine = ({ isAbove = false }) => {
  const { t } = useTranslation("components");

  return (
    <div
      css={{
        position: "relative",
        marginLeft: "4.2em",
        marginRight: "1em",
        marginBottom: "-1em", // To offset the Comment's marginTop and remove extra spacing
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <hr css={{ width: "100%", borderTop: "1px solid red" }} />
      <Icon
        src={isAbove ? faCaretUp : faCaretDown}
        color="red"
        style={{
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          marginTop: isAbove ? -2 : 2,
        }}
      />
      <Text
        size={FontSize.size12}
        color="red"
        css={{ margin: "0 1em", whiteSpace: "nowrap" }}
      >
        {isAbove
          ? t("components:More unread on next page")
          : t("components:Unread")}
      </Text>
      <hr
        css={{
          width: "100%",
          borderTop: "1px solid red",
        }}
      />
      <Icon
        src={isAbove ? faCaretUp : faCaretDown}
        color="red"
        style={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          marginTop: isAbove ? -2 : 2,
        }}
      />
    </div>
  );
};

UnreadLine.propTypes = {
  isAbove: PropTypes.bool,
};

const CommentForm = ({
  commentText,
  setCommentText,
  disableCommentInput = false,
  // Sharing with a Shipper/Dealer
  sharedWithOrgChecked,
  onSharedWithOrgChange,
  commentShareableWithOrg,
  commentShareableWithOrgText,
  commentShareableWithOrgTooltip,
  // FIN-5585: Sharing with Carriers
  commentShareableWithCarrierOrgIds,
  sharedWithCarriersChecked,
  onSharedWithCarriersChange,
  commentShareableWithCarriersText,
  commentShareableWithCarriersTooltip,
  showSubmit = true,
  onSubmit = _.noop,
}) => {
  const { t } = useTranslation("components");

  return (
    <div
      css={{
        // This is a grid that has 2 columns and 1 row.
        // The columns will be:
        // 1fr - one fraction of the available space (the text input).
        //       In this case, this happens to be the remaining space after
        //       the auto column has what it needs
        // auto - however much space the content needs (submit button)
        display: "grid",
        gridTemplateColumns: "1fr auto",
        // and the row will be however much space is available.
        gridTemplateRows: "1fr",
        // If we have more content to add (like the conditional "Share" checkbox)
        // the grid will automatically create new rows.
        gridAutoFlow: "row",
        // Spacing between columns and rows.
        // This prevents us from needed to apply a right margin to the input
        // so its spaced away from the submit button.
        gap: "1em",
        // Prevents items from stretching the entire grid cell
        // The submit button was the offender.
        alignItems: "start",
      }}
    >
      <div
        css={{
          flex: 1,
          position: "relative",
          // Unfortunately, because the input's help text is aboslutely positioned,
          // we need to add extra space below when the share checkbox is not visible.
          marginBottom: commentShareableWithOrg ? 0 : 15,
        }}
      >
        <Tooltip
          placement="top"
          tooltipChildren={
            disableCommentInput ? (
              <Text>
                {t(
                  "components:You can no longer edit a comment when there are newer comments. You can, however, change whether or not the comment is shared.",
                )}
              </Text>
            ) : null
          }
        >
          <TextInput
            value={commentText}
            onChange={(value) => {
              setCommentText(value);
            }}
            onPressEnter={onSubmit}
            disabled={disableCommentInput}
            placeholder={t("components:Enter your message here")}
            maxLength={MAX_CHARACTERS_PER_COMMENT}
          />
        </Tooltip>
        <div css={{ position: "absolute", right: 0, bottom: "-1.25em" }}>
          <Text
            size={FontSize.size12}
            color={Colors.comments.lightText}
            css={{ marginRight: 10 }}
          >
            <a
              href="https://commonmark.org/help/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("components:Formatting Help")}
            </a>
          </Text>
          <Text size={FontSize.size12} color={Colors.comments.lightText}>
            {commentText.length}/{MAX_CHARACTERS_PER_COMMENT}
          </Text>
        </div>
      </div>
      <div
        css={{
          width: "fit-content",
          // Force the element to start on the second row.
          gridRowStart: 2,
          // Force this element to span 2 columns.
          // Since our grid is 2 columns, it will be the full width.
          // Question: Is there a value for this that allows it to span all columns
          // without specifying how many it will span?
          gridColumn: "1 / span 2",
        }}
      >
        {commentShareableWithOrg ? (
          <InputGroup
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              id="shared-with-org-checkbox"
              checked={sharedWithOrgChecked}
              onChange={onSharedWithOrgChange}
            />
            <Text
              as="label"
              htmlFor="shared-with-org-checkbox"
              style={{ cursor: "pointer", marginBottom: 0 }}
            >
              {commentShareableWithOrgText}
            </Text>
            <Tooltip
              tooltipChildren={
                <Text style={{ padding: "0.75em", textAlign: "bottom" }}>
                  {commentShareableWithOrgTooltip}
                </Text>
              }
            >
              <Icon
                src={faQuestionCircle}
                style={{
                  marginLeft: "0.2em",
                  alignSelf: "center",
                  cursor: "help",
                }}
              />
            </Tooltip>
          </InputGroup>
        ) : null}
        {commentShareableWithCarrierOrgIds?.length > 0 ? (
          <InputGroup
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              id="shared-with-carriers-checkbox"
              checked={sharedWithCarriersChecked}
              onChange={onSharedWithCarriersChange}
            />
            <Text
              as="label"
              htmlFor="shared-with-carriers-checkbox"
              style={{ cursor: "pointer", marginBottom: 0 }}
            >
              {commentShareableWithCarriersText}
            </Text>
            <Tooltip
              tooltipChildren={
                <Text style={{ padding: "0.75em", textAlign: "bottom" }}>
                  {commentShareableWithCarriersTooltip}
                </Text>
              }
            >
              <Icon
                src={faQuestionCircle}
                style={{
                  marginLeft: "0.2em",
                  alignSelf: "center",
                  cursor: "help",
                }}
              />
            </Tooltip>
          </InputGroup>
        ) : null}
      </div>
      {showSubmit ? (
        <Button
          variant="success"
          onClick={onSubmit}
          disabled={commentText.trim().length === 0}
        >
          {t("components:Submit")}
          <Icon
            src={faPaperPlane}
            size={FontSize.size12}
            style={{ marginLeft: 7 }}
          />
        </Button>
      ) : null}
    </div>
  );
};

CommentForm.propTypes = {
  commentText: PropTypes.string.isRequired,
  setCommentText: PropTypes.func.isRequired,
  disableCommentInput: PropTypes.bool,
  commentShareableWithOrg: PropTypes.object,
  sharedWithOrgChecked: PropTypes.bool,
  onSharedWithOrgChange: PropTypes.func,
  commentShareableWithOrgText: PropTypes.string.isRequired,
  commentShareableWithOrgTooltip: PropTypes.string.isRequired,
  commentShareableWithCarrierOrgIds: PropTypes.arrayOf(PropTypes.number),
  sharedWithCarriersChecked: PropTypes.bool,
  onSharedWithCarriersChange: PropTypes.func,
  commentShareableWithCarriersText: PropTypes.string.isRequired,
  commentShareableWithCarriersTooltip: PropTypes.string.isRequired,
  showSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const Comment = ({
  comment,
  isLatestComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  commentShareableWithOrg,
  commentShareableWithOrgText,
  commentShareableWithOrgTooltip,
  commentShareableWithCarrierOrgIds = [],
  commentShareableWithCarriersText,
  commentShareableWithCarriersTooltip,
}) => {
  const { t } = useTranslation("components");

  const [showUpdating, setShowUpdating] = useState(false);
  const [commentText, setCommentText] = useState(comment.text);
  const [sharedWithOrgChecked, setSharedWithOrgChecked] = useState(false);
  const [sharedWithCarriersChecked, setSharedWithCarriersChecked] =
    useState(false);
  const [currentTextHeight, setCurrentTextHeight] = useState(0);
  const [highestTextHeight, setHighestTextHeight] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const isLoggedInUser =
    AuthenticationUtils.userEmail?.toLowerCase() ===
    comment.email?.toLowerCase();

  const commentSharedWith = comment?.shared_with ?? [];
  // Get the org that this comment was shared with, if any
  const { getOrganizationByDbId } = useOrganizations({
    ids: commentSharedWith,
  });
  const commentSharedWithOrg =
    getOrganizationByDbId(comment?.shared_with?.[0]) ?? null;

  const activeOrg = useSelector(getActiveOrganization);

  const getIsSharedWithOrg = (sharedWithList = []) => {
    if (!Array.isArray(sharedWithList)) {
      return false;
    }

    const types = sharedWithList
      .map((orgId) => getOrganizationByDbId(orgId))
      .map((org) => org?.org_type ?? null);

    return !_.isNil(
      types.find((orgType) =>
        [OrganizationType.DEALER, OrganizationType.SHIPPER].includes(orgType),
      ),
    );
  };

  const getIsSharedWithCarriers = (sharedWithList = []) => {
    if (!Array.isArray(sharedWithList)) {
      return false;
    }

    const types = sharedWithList
      .map((orgId) => getOrganizationByDbId(orgId))
      .map((org) => org?.org_type ?? null);

    return !_.isNil(
      types.find((orgType) => orgType === OrganizationType.CARRIER),
    );
  };

  const commentIsSharedWithOrg = getIsSharedWithOrg(comment?.shared_with);
  const commentIsSharedWithCarriers = getIsSharedWithCarriers(
    comment?.shared_with,
  );

  const isShareable =
    commentShareableWithOrg || commentShareableWithCarrierOrgIds.length > 0;
  // Only show the Edit button if:
  const canEditComment =
    // We aren't updating the comment AND...
    !showUpdating &&
    // This is the logged-in user AND...
    isLoggedInUser &&
    // It's not a comment from another org (shared with us) AND...
    activeOrg.organization_id === comment.owner_id &&
    // This is the latest comment OR (its not the latest AND can be shared) AND...
    (isLatestComment || (!isLatestComment && isShareable)) &&
    // This is not a batch comment
    !comment.batch_flag;

  // Update the checkboxes if the comment changes.
  useEffect(() => {
    setSharedWithOrgChecked(commentIsSharedWithOrg);
  }, [commentIsSharedWithOrg]);

  useEffect(() => {
    setSharedWithCarriersChecked(commentIsSharedWithCarriers);
  }, [commentIsSharedWithCarriers]);

  const beginUpdateComment = (isLatestComment) => {
    let updatedData;

    // Update the shared_with value.
    // Any editable comment can modify who it's shared with.
    let updatedSharedWith = [];

    if (sharedWithOrgChecked) {
      updatedSharedWith.push(commentShareableWithOrg?.organization_id);
    }

    if (sharedWithCarriersChecked) {
      updatedSharedWith = [
        ...updatedSharedWith,
        ...commentShareableWithCarrierOrgIds,
      ];
    }

    if (!isLatestComment) {
      // Editing non-latest comment, so can only change the shared_with value.
      updatedData = {
        shared_with: updatedSharedWith,
      };
    } else {
      // Editing the latest comment, so can update the text and shared_with values.
      updatedData = {
        ...comment,
        text: commentText,
        shared_with: updatedSharedWith,
        // Set the "modified" value so that it shows the correct edited datetime while
        // waiting for the API to respond.
        modified: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      };
    }

    updateComment(comment.id, updatedData);
  };

  // Have a reference to the comment's text for evaluating the height
  let textRef = useRef(null);

  // Get the current height of the text when we have a reference
  useEffect(() => {
    setCurrentTextHeight(textRef?.current?.clientHeight);
  }, [textRef]);

  // THREE_LINES_TEXT_HEIGHT is a hard-coded value representing 3 lines of <Text> height,
  //  which we check against to define whether or not to display the "Show More..." button
  // and to clamp the text to 3 lines.
  // Calculated by taking the default body line-height (1.5), multiplying it by the comment
  // text's rem value (size14, aka 0.875rem), and multiplying it by the default font-size (16px),
  // which gives us 21px for each line. Finally, multiply this by 3 to get 3 lines' height (63px).
  const BODY_LINE_HEIGHT = 1.5;
  const BODY_DEFAULT_FONT_SIZE = 16;
  const COMMENT_TEXT_FONT_SIZE = FontSize.size14;
  const THREE_LINES_TEXT_HEIGHT =
    BODY_LINE_HEIGHT * COMMENT_TEXT_FONT_SIZE * BODY_DEFAULT_FONT_SIZE * 3;

  useEffect(() => {
    // If the comment's text height is larger than what's in state, replace it.
    // We want the highest value only, so that when we clamp it to 3 lines,
    // the lower value doesn't cause constant re-evaluations of whether to clamp or not.
    if (currentTextHeight > highestTextHeight) {
      setHighestTextHeight(currentTextHeight);
    }
  }, [currentTextHeight, highestTextHeight]);

  useEffect(() => {
    // If the comment changes (which happens when we add a new comment),
    // reset the highest text height to 0 so it can be recalculated.
    setHighestTextHeight(0);
  }, [comment]);

  const handleOnSave = () => {
    if (
      !commentText ||
      commentText.length === 0 ||
      commentText.trim().length === 0
    ) {
      return;
    }

    beginUpdateComment(isLatestComment);
    setShowUpdating(false);
  };

  const getSharedWithTooltip = () => {
    const sharedWithUs = comment.shared_with?.includes(
      activeOrg.organization_id,
    );

    if (sharedWithUs && commentIsSharedWithCarriers) {
      // This case should only happen when a Shipper shares with carriers and dealers.
      // Dealers do not have the ability to share with carriers.
      return t(
        "components:This comment was shared with your organization and all carriers responsible for moving this vehicle.",
      );
    } else if (sharedWithUs) {
      return t("components:This comment was shared with your organization.");
    } else if (commentIsSharedWithCarriers && commentIsSharedWithOrg) {
      return t(
        "components:This comment has been shared with [[[organizationName]]] ([[[organizationType]]]) and all carriers responsible for moving this vehicle.",
        {
          organizationName: commentSharedWithOrg?.org_name,
          organizationType: commentSharedWithOrg?.org_type,
        },
      );
    } else if (commentIsSharedWithOrg) {
      return t(
        "components:This comment has been shared with [[[organizationName]]] ([[[organizationType]]])",
        {
          organizationName: commentSharedWithOrg?.org_name,
          organizationType: commentSharedWithOrg?.org_type,
        },
      );
    } else if (commentIsSharedWithCarriers) {
      return t(
        "components:This comment has been shared with all carriers responsible for moving this vehicle.",
      );
    }

    return null;
  };

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        marginTop: "1em",
      }}
    >
      {comment.isAdding || comment.isUpdating ? (
        <div
          className="stripe stripe-visible"
          css={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            zIndex: 2,
          }}
        />
      ) : null}
      {!comment.isUpdating && comment.isUpdatingFailed ? (
        <div
          css={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            overflow: "hidden",
            backgroundColor: "rgba(255, 0, 0, 0.75)",
            color: "#fff",
            zIndex: 2,
          }}
        >
          <Text size={FontSize.size18} align="center">
            {t("components:Something went wrong. Unable to save your changes.")}
          </Text>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="link"
              css={{
                color: "#fff",
                paddingTop: 0,
                paddingBottom: 0,
                ":hover": { color: "#eee" },
              }}
              onClick={() => {
                // Revert form back to the original comment's data (saved in 'original' by the state)
                setCommentText(comment.original.text);

                setSharedWithOrgChecked(
                  getIsSharedWithOrg(comment.original?.shared_with),
                );
                setSharedWithCarriersChecked(
                  getIsSharedWithCarriers(comment.original?.shared_with),
                );

                // Remove the error and loading flags, and revert data back to the original
                cancelUpdateComment(comment.id);
              }}
            >
              <Text>{t("components:Cancel")}</Text>
            </Button>
            <span>|</span>
            <Button
              variant="link"
              css={{
                color: "#fff",
                paddingTop: 0,
                paddingBottom: 0,
                ":hover": { color: "#eee" },
              }}
              onClick={() => {
                beginUpdateComment(isLatestComment);
              }}
            >
              <Text>{t("components:Retry")}</Text>
            </Button>
          </div>
        </div>
      ) : null}
      {!comment.isAdding && comment.isAddingFailed ? (
        <div
          css={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            backgroundColor: "rgba(255, 0, 0, 0.75)",
            color: "#fff",
            zIndex: 2,
          }}
        >
          <Text size={FontSize.size18} align="center">
            {t("components:Something went wrong. Unable to add your comment.")}
          </Text>
          <Button
            variant="link"
            css={{ color: "#fff", paddingTop: 0, ":hover": { color: "#eee" } }}
            onClick={() => {
              cancelAddComment(comment.id);
            }}
          >
            <Text>{t("components:Cancel")}</Text>
          </Button>
        </div>
      ) : null}
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          marginRight: "1em",
          marginLeft: "4.2em",
          paddingBottom: 2,
        }}
      >
        <div css={{ flex: 1 }}>
          <Text color={Colors.comments.lightText}>{comment.email}</Text>
          {comment.shared_with?.length > 0 && activeOrg ? (
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{getSharedWithTooltip()}</Text>}
            >
              <Icon
                src={faShareSquare}
                color={Colors.comments.sharedIcon}
                size={FontSize.size12}
                style={{ marginLeft: 5 }}
                flip={
                  // If it's shared to our org, flip to the left
                  // otherwise, point to the right.
                  comment.shared_with?.includes(activeOrg.organization_id)
                    ? "horizontal"
                    : null
                }
              />
            </Tooltip>
          ) : null}

          {comment.batch_flag ? (
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("components:This is a mass upload comment.")}</Text>
              }
            >
              <Icon
                src={faArrowSquareUp}
                color={Colors.comments.sharedIcon}
                size={FontSize.size12}
                style={{ marginLeft: 5 }}
              />
            </Tooltip>
          ) : null}
        </div>
        {canEditComment ? (
          <Button
            size="sm"
            variant="link"
            css={{ padding: 0 }}
            onClick={() => {
              // Set the text/shared value to the comment's current values
              setCommentText(comment.text);
              setSharedWithOrgChecked(getIsSharedWithOrg(comment.shared_with));
              setSharedWithCarriersChecked(
                getIsSharedWithCarriers(comment.shared_with),
              );
              // Show the updating form in place of the comment text
              setShowUpdating(true);
            }}
          >
            <Icon src={faEdit} /> {t("components:Edit")}
          </Button>
        ) : null}
        {showUpdating ? (
          <div>
            <Button
              size="sm"
              variant="link"
              css={{ padding: 0, marginRight: 10 }}
              onClick={() => {
                setShowUpdating(false);
                setCommentText(comment.text);
                setSharedWithOrgChecked(
                  getIsSharedWithOrg(comment.shared_with),
                );
                setSharedWithCarriersChecked(
                  getIsSharedWithCarriers(comment.shared_with),
                );
              }}
            >
              <Icon src={faCaretLeft} /> {t("components:Cancel")}
            </Button>
            <Button
              size="sm"
              variant="link"
              disabled={commentText.trim().length === 0}
              css={{ padding: 0 }}
              onClick={handleOnSave}
            >
              <Icon src={faSave} /> {t("components:Save")}
            </Button>
          </div>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        <div
          css={{
            width: "4em",
            paddingTop: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Icon
            color={Colors.comments.avatar}
            size={FontSize.size36}
            src={faUserCircle}
          />
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: "1em",
          }}
        >
          <div
            css={{
              position: "relative",
              display: !isSafari ? "flex" : null,
              flexDirection: !isSafari ? "column" : null,
              backgroundColor: Colors.comments.commentBackground,
              borderRadius: 4,
              padding: "10px",
              width: showUpdating ? "100%" : "fit-content",
            }}
          >
            {!showUpdating ? (
              <React.Fragment>
                <Text
                  ref={textRef}
                  size={COMMENT_TEXT_FONT_SIZE}
                  css={
                    !isSafari
                      ? {
                          display:
                            !showMore &&
                            highestTextHeight > THREE_LINES_TEXT_HEIGHT
                              ? "-webkit-box"
                              : "block",
                          WebkitLineClamp:
                            !showMore &&
                            highestTextHeight > THREE_LINES_TEXT_HEIGHT
                              ? 3
                              : "unset",
                          WebkitBoxOrient:
                            !showMore &&
                            highestTextHeight > THREE_LINES_TEXT_HEIGHT
                              ? "vertical"
                              : "unset",
                          overflow:
                            !showMore &&
                            highestTextHeight > THREE_LINES_TEXT_HEIGHT
                              ? "hidden"
                              : "unset",
                        }
                      : null
                  }
                >
                  <ReactMarkdown
                    children={comment.text}
                    css={{
                      p: {
                        margin: 0,
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      },
                    }}
                  />
                </Text>
                {!isSafari && highestTextHeight > THREE_LINES_TEXT_HEIGHT ? (
                  <Button
                    variant="link"
                    size="sm"
                    css={{ padding: 0, alignSelf: "flex-end" }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    <Text>
                      {showMore
                        ? t("components:Show Less...")
                        : t("components:Show More...")}
                    </Text>
                  </Button>
                ) : null}
              </React.Fragment>
            ) : (
              <CommentForm
                commentText={commentText}
                setCommentText={setCommentText}
                disableCommentInput={!isLatestComment}
                commentShareableWithOrg={commentShareableWithOrg}
                sharedWithOrgChecked={sharedWithOrgChecked}
                onSharedWithOrgChange={(checked) =>
                  setSharedWithOrgChecked(checked)
                }
                commentShareableWithOrgText={commentShareableWithOrgText}
                commentShareableWithOrgTooltip={commentShareableWithOrgTooltip}
                commentShareableWithCarrierOrgIds={
                  commentShareableWithCarrierOrgIds
                }
                sharedWithCarriersChecked={sharedWithCarriersChecked}
                onSharedWithCarriersChange={(checked) =>
                  setSharedWithCarriersChecked(checked)
                }
                commentShareableWithCarriersText={
                  commentShareableWithCarriersText
                }
                commentShareableWithCarriersTooltip={
                  commentShareableWithCarriersTooltip
                }
                showSubmit={false}
                onSubmit={handleOnSave}
              />
            )}
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              color={Colors.comments.lightText}
              css={{
                "::first-letter": { textTransform: "capitalize" },
              }}
            >
              <Tooltip
                tooltipChildren={
                  <React.Fragment>
                    <Text block bold align="center">
                      {t("components:Submitted")}
                    </Text>
                    <Text block align="center">
                      {moment
                        .utc(comment.ts)
                        .local()
                        .format("MMMM Do, YYYY HH:mm")}
                    </Text>
                  </React.Fragment>
                }
              >
                {moment.utc(comment.ts).local().fromNow()}
              </Tooltip>
            </Text>
            {comment.modified ? (
              <Text color={Colors.comments.lightText} css={{ marginLeft: 4 }}>
                <Tooltip
                  tooltipChildren={
                    <React.Fragment>
                      <Text block bold align="center">
                        {t("components:Edited")}
                      </Text>
                      <Text block align="center">
                        {moment
                          .utc(comment.modified)
                          .local()
                          .format("MMMM Do, YYYY HH:mm")}
                      </Text>
                    </React.Fragment>
                  }
                >
                  | {t("Edited")}{" "}
                  {moment.utc(comment.modified).local().fromNow()}
                </Tooltip>
              </Text>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  isLatestComment: PropTypes.bool,
  cancelAddComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  commentShareableWithOrg: PropTypes.object,
  commentShareableWithOrgText: PropTypes.string.isRequired,
  commentShareableWithOrgTooltip: PropTypes.string.isRequired,
  commentShareableWithCarrierOrgIds: PropTypes.arrayOf(PropTypes.number),
  commentShareableWithCarriersText: PropTypes.string.isRequired,
  commentShareableWithCarriersTooltip: PropTypes.string.isRequired,
};

export const CommentFeed = ({
  commentShareableWithOrgId,
  customizedSharableOrgText,
  commentShareableWithCarrierFvIds = [],
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  showBatchUpload = true,
  addBatchComments,
  clearBatchComments,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  batchCsvExample,
  batchImportLabel,
  shippers,
  shipperOrgId,
  style,
  fetchNotification,
}) => {
  const { t } = useTranslation("components");

  // Get the org that comments might be shareable to
  const { getOrganizationByDbId, getOrganizationByFvId } = useOrganizations({
    ids: [commentShareableWithOrgId],
    fvIds: commentShareableWithCarrierFvIds,
  });

  // Look up the orgId from organizations.
  const commentShareableWithCarrierOrgIds =
    commentShareableWithCarrierFvIds.map(
      (fvId) => getOrganizationByFvId(fvId)?.organization_id ?? null,
    );

  const commentShareableWithOrg =
    getOrganizationByDbId(commentShareableWithOrgId) ?? null;

  // Text for sharing with dealers or shippers. Can use customizedSharableOrgText to see customized text
  const commentShareableWithOrgText = customizedSharableOrgText
    ? customizedSharableOrgText
    : t("components:Share with [[[organizationType]]]", {
        organizationType: commentShareableWithOrg?.org_type,
      });

  const commentShareableWithOrgTooltip = t(
    "components:Share this comment with [[[organizationName]]] ([[[organizationType]]]).",
    {
      organizationName: commentShareableWithOrg?.org_name,
      organizationType: commentShareableWithOrg?.org_type,
    },
  );

  // Text for sharing with all carriers
  const commentShareableWithCarriersText = t("components:Share with Carriers");

  const commentShareableWithCarriersTooltip = t(
    "components:Share this comment with all carriers responsible for moving this vehicle",
  );

  // Get the logged-in user's email
  const loggedInUserEmail = AuthenticationUtils.userEmail?.toLowerCase();

  const [collapsed, setCollapsed] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [sharedWithOrgChecked, setSharedWithOrgChecked] = useState(false);
  const [sharedWithCarriersChecked, setSharedWithCarriersChecked] =
    useState(false);
  const [readDatetime, setReadDatetime] = useState(moment());
  const [allCommentsUnread, setAllCommentsUnread] = useState(false);
  const [hasUnreadOnNextPage, setHasUnreadOnNextPage] = useState(false);
  const [
    isUnreadLineVisibilityCheckActive,
    setIsUnreadLineVisibilityCheckActive,
  ] = useState(true);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);

  // For keeping track of parent of comments list, for reference by the VisibilitySensor
  let commentsRef = useRef(null);

  useEffect(() => {
    // Automatically fetch the first page of comments
    fetchComments(0, 20);

    // Set the read datetime now that we've started loading the first page
    setReadDatetime(moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When the comments update, verify if we've hit the final page
    setHasMore(currentPage + 1 < comments.totalPages);

    // If we are not on the last page
    if (currentPage + 1 !== comments.totalPages) {
      // Look for at least 1 comment marked as read
      // If none are found, that means this page has all unread comments
      let foundReadComment = comments?.data?.find((c) => c.read === true);
      setHasUnreadOnNextPage(!foundReadComment);
    } else {
      setHasUnreadOnNextPage(false);
    }

    // If we've loaded all pages
    if (currentPage + 1 === comments.totalPages) {
      // And there have been no read comments
      if (comments.data.filter((c) => c.read).length === 0) {
        // Display the last unread line
        setAllCommentsUnread(true);
      }
    }
  }, [comments, currentPage]);

  const nextComments = () => {
    // Get the next page of comments (state will append new page data to comments.comments array)
    setCurrentPage(currentPage + 1);
    fetchComments(currentPage + 1, 20);
  };

  const onRefresh = () => {
    setCurrentPage(0);
    fetchComments(0, 20);

    // Reset the read datetime now that we're refreshing all comments
    setReadDatetime(moment());

    // Reset the "all comments unread" line on refresh, since we might have seen all
    // the comments. If we haven't, it'll show the line again in the useEffect above.
    setAllCommentsUnread(false);

    // Re-enable the unread line visibility check in case there are new unread comments after refreshing
    setIsUnreadLineVisibilityCheckActive(true);
  };

  const onSubmit = () => {
    if (
      !commentText ||
      commentText.length === 0 ||
      commentText.trim().length === 0
    ) {
      return;
    }

    const data = {
      email: loggedInUserEmail,
      text: commentText,
      shared_with: [],
      ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      modified: null,
      read: true,
    };

    if (sharedWithOrgChecked) {
      data.shared_with.push(commentShareableWithOrg?.organization_id);
    }

    if (sharedWithCarriersChecked) {
      data.shared_with = [
        ...data.shared_with,
        ...commentShareableWithCarrierOrgIds,
      ];
    }

    addComment(data).then(() => {
      // Mark comments as read in case there are new comments prior to this one.
      // We'd end up in a weird state where there are read comments followed by
      // unread comments followed by read comments. So mark everything as read
      // to prevent this.
      //TODO: Note: We might want to consider refreshing the data after adding
      // the comment so they appear.
      markCommentsRead(moment());
    });

    // Clear form
    setCommentText("");
    setSharedWithOrgChecked(false);
    setSharedWithCarriersChecked(false);
  };

  const onUnreadLineVisibilityChange = (isVisible) => {
    // If the unread line is visible
    if (isVisible) {
      // We only want to mark comments read once,
      // so disable the visibility check if it's currently visible
      setIsUnreadLineVisibilityCheckActive(false);

      // Mark all comments as read, but don't actually change their state values
      // so that the Unread line doesn't disappear. Also pass in the datetime of
      // when the page was initially loaded/first page of comments were fetched/refetched.
      // We do it this way so that in case any new comments come in (that aren't shown),
      // we don't accidentally mark them as read.
      markCommentsRead(readDatetime);
    }
  };

  return (
    <PanelGroup
      collapsible
      style={style}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
    >
      <PanelGroup.Header
        title={t("components:Comment Feed")}
        rightContent={
          comments.totalCountUnread ? (
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{t("components:Number of unread comments.")}</Text>
              }
            >
              <div css={{ position: "relative" }}>
                <Icon
                  src={faCommentAlt}
                  size={FontSize.size28}
                  color={Colors.comments.unreadCommentIcon}
                />
                <Text
                  color="#fff"
                  css={{
                    position: "absolute",
                    left: "50%",
                    top: "40%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {comments.totalCountUnread <= 99
                    ? comments.totalCountUnread
                    : "99+"}
                </Text>
              </div>
            </Tooltip>
          ) : null
        }
      />
      <PanelGroup.SubHeader
        css={{
          background: Colors.background.WARM_GRAY,
          padding: "0.5em 1em",
          color: Colors.text.WHITE,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {isFetchingComments ? (
          <div css={{ flex: 1 }}>
            <Icon src={faSpinner} spin={true} />
          </div>
        ) : (
          <Text css={{ flex: 1, fontWeight: "bold" }}>
            {t("components:There is [[[count]]] total comment", {
              count: comments?.totalCount ? comments.totalCount : 0,
            })}
          </Text>
        )}
        {showBatchUpload ? (
          <Button
            variant="link"
            onClick={() => setIsBatchModalOpen(true)}
            css={{
              padding: 0,
              marginRight: "1em",
              color: "#fff",
              ":hover": { color: "#eee" },
            }}
          >
            <Icon css={{ marginRight: "0.5em" }} src={faUpload} />
            <Text>{t("components:Batch Upload")}</Text>
          </Button>
        ) : null}
        <Button
          variant="link"
          disabled={isFetchingComments}
          onClick={onRefresh}
          css={{ padding: 0, color: "#fff", ":hover": { color: "#eee" } }}
        >
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("components:Click to refresh all comments.")}</Text>
            }
          >
            <Icon
              css={{ marginRight: "0.5em" }}
              src={faSyncAlt}
              spin={isFetchingComments}
            />
            <Text>{t("components:Refresh")}</Text>
          </Tooltip>
        </Button>
      </PanelGroup.SubHeader>
      <PanelGroup.Content style={{ padding: 0 }}>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <div
            id="commentsDiv"
            css={{
              height: 300,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
            ref={commentsRef}
          >
            {comments?.data?.length > 0 ? (
              <InfiniteScroll
                dataLength={comments.data.length}
                next={nextComments}
                hasMore={hasMore}
                scrollableTarget="commentsDiv"
                inverse={true}
                height={300}
                css={{
                  display: "flex",
                  flexDirection: "column-reverse", // To put endMessage and loader at the top.
                  paddingBottom: "1em",
                  // This fixes issue with Safari not being able to scroll. Not entirely sure why this is the case.
                  // Remove this line and see if Safari works in the future.
                  // TODO: Review if this rule is still needed by on 3/31/2021.
                  overflow: isSafari ? "unset !important" : null,
                }}
                loader={
                  <div
                    css={{
                      marginLeft: "4.2em",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "0.5em",
                    }}
                  >
                    <Icon
                      src={faSpinner}
                      spin
                      color={Colors.comments.lightText}
                    />
                    <Text
                      css={{ marginLeft: 5 }}
                      color={Colors.comments.lightText}
                    >
                      {t("components:Loading more comments...")}
                    </Text>
                  </div>
                }
                endMessage={
                  <Text
                    color={Colors.comments.lightText}
                    css={{ flex: 1, textAlign: "center", paddingTop: "0.5em" }}
                  >
                    {t("components:No more comments available.")}
                  </Text>
                }
              >
                {comments.data.map((comment, index) => {
                  let showUnreadLine = false;
                  if (
                    comment.read === true &&
                    comments.data[index - 1]?.read === false
                  ) {
                    showUnreadLine = true;
                  }

                  // If no comments have been read
                  let showFinalUnreadLine = false;
                  if (
                    allCommentsUnread &&
                    currentPage + 1 === comments.totalPages
                  ) {
                    // So show an unread line above the first comment made
                    if (index + 1 === comments.data.length) {
                      showFinalUnreadLine = true;
                    }
                  }

                  //NOTE: Only display the unread line if the panel is not collapsed
                  // so that it doesn't automatically get marked as read on page load
                  // due to behavior from VisibilitySensor.
                  return (
                    <React.Fragment key={comment.id}>
                      {!collapsed && commentsRef && showUnreadLine ? (
                        <VisibilitySensor
                          containment={commentsRef.current}
                          onChange={onUnreadLineVisibilityChange}
                          active={isUnreadLineVisibilityCheckActive}
                        >
                          <UnreadLine />
                        </VisibilitySensor>
                      ) : null}
                      <Comment
                        comment={comment}
                        isLatestComment={index === 0}
                        cancelAddComment={cancelAddComment}
                        updateComment={updateComment}
                        cancelUpdateComment={cancelUpdateComment}
                        commentShareableWithOrg={commentShareableWithOrg}
                        commentShareableWithOrgText={
                          commentShareableWithOrgText
                        }
                        commentShareableWithOrgTooltip={
                          commentShareableWithOrgTooltip
                        }
                        commentShareableWithCarrierOrgIds={
                          commentShareableWithCarrierOrgIds
                        }
                        commentShareableWithCarriersText={
                          commentShareableWithCarriersText
                        }
                        commentShareableWithCarriersTooltip={
                          commentShareableWithCarriersTooltip
                        }
                      />
                      {!collapsed && commentsRef && showFinalUnreadLine ? (
                        <VisibilitySensor
                          containment={commentsRef.current}
                          onChange={onUnreadLineVisibilityChange}
                          active={isUnreadLineVisibilityCheckActive}
                        >
                          <UnreadLine />
                        </VisibilitySensor>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                {hasUnreadOnNextPage ? <UnreadLine isAbove={true} /> : null}
              </InfiniteScroll>
            ) : (
              <div
                css={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isFetchingComments ? (
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      src={faSpinner}
                      spin
                      color={Colors.comments.lightText}
                    />
                    <Text
                      color={Colors.comments.lightText}
                      css={{ marginLeft: "0.5em" }}
                    >
                      {t("components:Loading comments...")}
                    </Text>
                  </div>
                ) : (
                  <Text color={Colors.comments.lightText}>
                    {t("components:No comments available.")}
                  </Text>
                )}
              </div>
            )}
          </div>
          <div
            css={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: Colors.comments.footerBackground,
              padding: "1em",
            }}
          >
            <CommentForm
              commentText={commentText}
              setCommentText={setCommentText}
              // Sharing with Shipper/Dealer
              commentShareableWithOrg={commentShareableWithOrg}
              sharedWithOrgChecked={sharedWithOrgChecked}
              onSharedWithOrgChange={(checked) =>
                setSharedWithOrgChecked(checked)
              }
              commentShareableWithOrgText={commentShareableWithOrgText}
              commentShareableWithOrgTooltip={commentShareableWithOrgTooltip}
              // FIN-5585: Sharing with Carriers
              commentShareableWithCarrierOrgIds={
                commentShareableWithCarrierOrgIds
              }
              sharedWithCarriersChecked={sharedWithCarriersChecked}
              onSharedWithCarriersChange={(checked) =>
                setSharedWithCarriersChecked(checked)
              }
              commentShareableWithCarriersText={
                commentShareableWithCarriersText
              }
              commentShareableWithCarriersTooltip={
                commentShareableWithCarriersTooltip
              }
              onSubmit={onSubmit}
            />
          </div>
          {showBatchUpload ? (
            <BatchCommentModal
              show={isBatchModalOpen}
              hide={() => {
                setIsBatchModalOpen(false);
                clearBatchComments();
                fetchNotification({ pageSize: 10, refresh: false, page: 0 });
                onRefresh(); // refresh comment feed
              }}
              addBatchComments={addBatchComments}
              clearBatchComments={clearBatchComments}
              shareableOrg={commentShareableWithOrg}
              shareableText={commentShareableWithOrgText}
              shareableTooltipText={commentShareableWithOrgTooltip}
              isBatchCommentInProgress={isBatchCommentInProgress}
              isBatchCommentSuccessful={isBatchCommentSuccessful}
              isBatchCommentFailed={isBatchCommentFailed}
              batchCsvExample={batchCsvExample}
              batchImportLabel={batchImportLabel}
              shippers={shippers}
              shipperOrgId={shipperOrgId}
            />
          ) : null}
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

CommentFeed.propTypes = {
  commentShareableWithOrgId: PropTypes.number,
  customizedSharableOrgText: PropTypes.string,
  commentShareableWithCarrierFvIds: PropTypes.arrayOf(PropTypes.string),
  fetchComments: PropTypes.func.isRequired,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
    totalCountUnread: PropTypes.number,
    data: PropTypes.array,
  }),
  addComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  showBatchUpload: PropTypes.bool,
  addBatchComments: PropTypes.func,
  clearBatchComments: PropTypes.func,
  isBatchCommentInProgress: PropTypes.bool,
  isBatchCommentSuccessful: PropTypes.bool,
  isBatchCommentFailed: PropTypes.bool,
  batchCsvExample: PropTypes.shape({
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
  }),
  batchImportLabel: PropTypes.string,
  shippers: PropTypes.arrayOf(
    PropTypes.shape({
      shipper_organization_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      shipper_name: PropTypes.string,
      shipper_fv_id: PropTypes.string,
    }),
  ),
  shipperOrgId: PropTypes.number,
  style: PropTypes.object,
  fetchNotification: PropTypes.func.isRequired,
};
