import { connect } from "react-redux";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";
import { CustomerSupport } from "./CustomerSupportLink";
import CustomerSupportState from "../../redux/CustomerSupportState";

function mapStateToProps(state) {
  return {
    ticketResponseData:
      CustomerSupportState.selectors.getTicketResponseData(state),
    isCustomerSupportEnabled: getAuthorization(state).hasFeatures([
      Features.CUSTOMER_SUPPORT,
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitSupportTicket: (payload) =>
      dispatch(
        CustomerSupportState.actionCreators.createCustomerSupportTicket(
          payload,
        ),
      ),
    resetTicketResponseData: () =>
      dispatch(CustomerSupportState.actionCreators.resetTicketResponseData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
