import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./MetalTrackingSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import MetalTrackingDomainDataState from "../domain-data/MetalTrackingDomainData";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
  } = SearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    solutionId: getSolutionId(state),
    serialNumbers:
      MetalTrackingDomainDataState.selectors.getSerialNumbers(state),
    locations: MetalTrackingDomainDataState.selectors.getLocations(state),
    descriptions: MetalTrackingDomainDataState.selectors.getDescriptions(state),
    tagIds: MetalTrackingDomainDataState.selectors.getTagIds(state),
    cspcs: MetalTrackingDomainDataState.selectors.getCspcs(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ignoreValueChange) => {
      dispatch(setSearchText(text, ignoreValueChange, true));
    },
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId)),
    fetchDomainData: (solutionId) =>
      dispatch(
        MetalTrackingDomainDataState.actionCreators.fetchDomainData(solutionId),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
