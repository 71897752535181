/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import Colors from "styles/colors";
import { getColumns } from "./LocationMatchingColumns";

const CurrentLocationPanel = ({
  currentLocation,
  lads,
  selectedLocations,
  eventHandler,
}) => {
  const { t } = useTranslation("location-matching");

  const currentColumns = getColumns(t, lads, null, []);
  const selectedColumns = getColumns(t, lads, "dismiss", selectedLocations);

  const displayedLocations = selectedLocations.length ? selectedLocations : [];

  return (
    <div
      css={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        padding: "1em",
      }}
    >
      <div
        css={{
          display: "flex",
          minHeight: "2em",
          flexDirection: "column",
          backgroundColor: Colors.highlight.LIGHT_BLUE,
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t("location-matching:Current Location Information")}
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BaseTable
          data={[currentLocation]}
          columns={currentColumns}
          hideHeader={true}
          pageSize={1}
        />
      </div>

      <div
        css={{
          display: "flex",
          minHeight: "2em",
          flexDirection: "column",
          backgroundColor: Colors.background.GRAY,
          color: "black",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t("location-matching:Replace with")}
      </div>

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "10rem",
          overflow: "auto",
        }}
      >
        <BaseTable
          data={displayedLocations}
          columns={selectedColumns}
          hideHeader={true}
          pageSize={400}
          rowClickHandler={(row, _ignored_cell) => {
            const locID = row.original.id;
            eventHandler(locID, "DISMISS_LOCATION");
          }}
        />
      </div>
    </div>
  );
};

export default CurrentLocationPanel;
