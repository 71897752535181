/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import PropTypes from "prop-types";

import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import { useUpdatesTable } from "shared/hooks/columns/useUpdatesTable.columns";

export const UpdatesTable = ({ updates, noUpdatesText }) => {
  const { columns } = useUpdatesTable(false, true);

  return (
    <Fragment>
      {updates.length > 0 ? (
        <BaseTable
          data-qa="partview-updates"
          theme={Themes.LIGHT}
          data={updates}
          columns={columns.slice(1)} // remove the group events column
          disablePagination={true}
          defaultSortColumn={"eventTs"}
          defaultReverseSort={false}
        />
      ) : (
        <TabPanelPlaceholder
          data-qa="partview-no-updates"
          text={noUpdatesText}
        />
      )}
    </Fragment>
  );
};

UpdatesTable.propTypes = {
  updates: PropTypes.array,
  noUpdatesText: PropTypes.string,
};
