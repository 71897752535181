/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { useTranslation } from "react-i18next";

import { RackCountWidget } from "./RackCountWidget";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { getRackCountsByKey } from "./RacksByType";

export const RacksByLocation = ({ metalTrackingEntities, setSearchFilter }) => {
  const racksByLocation = getRackCountsByKey(
    metalTrackingEntities,
    "rackLocation",
  );

  const { t } = useTranslation("mt-dashboard");

  return (
    <PanelGroup
      style={{
        height: "100%",
      }}
    >
      <PanelGroup.Header title={t("mt-dashboard:Racks By Location")} />
      <PanelGroup.Content>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: 10,
            marginRight: 10,
            padding: 10,
          }}
        >
          {racksByLocation.map((obj, i) => (
            <RackCountWidget
              key={i}
              count={obj.count}
              label={obj.label}
              color={Colors.highlight.BRIGHT_BLUE}
              handler={() => {
                setSearchFilter("rackLocation", [obj.label]);
              }}
            />
          ))}
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

RacksByLocation.propTypes = {
  metalTrackingEntities: PropTypes.array,
  setSearchFilter: PropTypes.func,
};
