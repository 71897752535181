import { connect } from "react-redux";
import { FilterSection } from "../../components/search-bar/FilterSection";
import { FILTERS } from "./MilestoneEventFilterDefs";
import MilestoneDashboardSearchBarState from "./MilestoneDashboardSearchBarState";
import MilestoneDomainDataState from "./MilestoneDomainDataState";

import { getSolutionId } from "../organizations/OrganizationsState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const { getEventsOptions, getStatusCodesOptions, getShippersOptions } =
    MilestoneDomainDataState.selectors;
  return {
    shippersFilterOptions: getShippersOptions(state),
    eventsFilterOptions: getEventsOptions(state),
    statusCodesFilterOptions: getStatusCodesOptions(state),
    searchFilters:
      MilestoneDashboardSearchBarState.selectors.getSearchFilters(state),
    hasSearchCriteriaChanged:
      MilestoneDashboardSearchBarState.selectors.getHasSearchCriteriaChanged(
        state,
      ),
    solutionId: getSolutionId(state),
    auth: getAuthorization(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchFilter,
    clearSearchFilter,
    clearSavedSearch,
    searchEntities,
  } = MilestoneDashboardSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: (key) => dispatch(clearSearchFilter(key)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilterSection);
