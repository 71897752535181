/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "modules/fv-search/FinVehicleSearchBarContainer";
import FiltersContainer from "modules/fv-search/FinVehicleSearchFiltersContainer";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import { HoldTypesPanel } from "./components/organisms/FinishedVehicle.HoldTypesPanel.organism";
import { VinCategoryChartPanel } from "./components/organisms/FinishedVehicle.VinCategoryChartPanel.organism";
import WatchedVins from "./components/organisms/FinishedVehicle.WatchedVins.organism";
import { FinishedVehicleSavedSearchesPanel } from "./components/organisms/FinishedVehicle.SavedSearchesPanel.organism";

import {
  getExceptionData,
  getIconData,
  translateExceptionName,
} from "../utils/exceptions.utils";

import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";

function FinVehicleDashboard(props) {
  const {
    entityCount,
    entityCountLoading,
    entityDeliveredCount,
    entityDeliveredCountLoading,
    exceptions,
    exceptionsLoading,
    vinHolds,
    vinHoldsLoading,
    setSearchFilter,
    clearSearchFilter,
    searchEntities,
    solutionId,
    exceptionTypes,
    resetSearchBar,
    clearSearchFilters,
    fetchExceptions,
    fetchEntityCount,
    fetchEntityDeliveredCount,
    fetchHoldCounts,
    resetSavedSearch,
    fetchItssCount,
    itssCountData,
    itssCountDataIsLoading,
    showItssWidget,
    fetchSpotBuyCount,
    spotBuyCountData,
    spotBuyCountDataIsLoading,
    showSpotyBuyWidget,
  } = props;
  const { t } = useTranslation(["fv-dashboard", "labels", "exceptions"]);
  const [showFilters, setShowFilters] = useState(false);

  useTrackWithMixpanelOnce("Viewed Page: Finished Vehicle / Dashboard");

  useSetTitleOnMount(t("fv-dashboard:Finished Vehicle Dashboard"));

  useSetDescriptionOnMount(
    t(
      "fv-dashboard:All VINs for your organization and their related exceptions and holds",
    ),
  );

  useEffect(
    () => {
      resetSearchBar();
      clearSearchFilters();
      fetchExceptions(solutionId);
      fetchEntityCount(solutionId);
      fetchEntityDeliveredCount(solutionId);
      fetchHoldCounts(solutionId);
      fetchItssCount(solutionId);
      fetchSpotBuyCount(solutionId);
      resetSavedSearch();
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  const handleClickException = (e) => {
    let searchableValue = [];
    if (e && e.name === t("fv-dashboard:Delivered")) {
      clearSearchFilter();
      setSearchFilter("lifeCycleState", ["Delivered"]);
      searchEntities(solutionId);
    } else {
      if (e && e.reasonCode) {
        searchableValue.push(
          _.find(exceptionTypes, { reasonCode: e.reasonCode }).id,
        );
      } else {
        exceptionTypes.forEach((exceptionType) => {
          searchableValue.push(exceptionType.id);
        });
      }

      clearSearchFilter();
      setSearchFilter("exception", searchableValue);
      setSearchFilter("lifeCycleState", ["Active"]);
      searchEntities(solutionId);
    }
  };

  const handleClickGraph = () => {
    clearSearchFilter();
    setSearchFilter("lifeCycleState", ["Active", "Prebuilt"]);
    searchEntities(solutionId);
  };

  const data = useMemo(
    () => getExceptionData(exceptions, exceptionTypes, entityCount, true),
    [exceptions, exceptionTypes, entityCount],
  );

  const exceptionGroups = [
    {
      title: t("labels:Exceptions"),
      includeInDonutChart: true,
      style: { flexGrow: 1 },
      exceptions: data.map((exception, i) => {
        return {
          ...exception,
          name: translateExceptionName(exception.name, t),
          icon: {
            ...getIconData(exception.name),
          },
        };
      }),
      isLoading: exceptionsLoading,
    },
    {
      title: t("labels:Other"),
      includeInDonutChart: false,
      exceptions: [
        {
          name: t("fv-dashboard:Delivered"),
          count: entityDeliveredCount || 0,
          icon: {
            ...getIconData("Delivered"),
          },
        },
      ],
      isLoading: entityDeliveredCountLoading,
    },
  ];

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>{t("fv-dashboard:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("fv-dashboard:My Finished Vehicle Homepage")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel
          styles={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(6, 1fr)",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "1 / span 3",
                gridRow: "1 / span 2",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "1 / span 4",
                gridRow: "1 / span 1",
              },
            }}
          >
            <ExceptionsPanel
              title={t("fv-dashboard:Finished Vehicle VINs")}
              exceptionGroups={exceptionGroups}
              totalCount={entityCount}
              totalCountIsLoading={entityCountLoading}
              totalCountLabel={t("fv-dashboard:Active")}
              handleClickException={handleClickException}
              handleClickGraph={handleClickGraph}
            />
          </div>
          <div
            css={{
              marginBottom: "20px",
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "4 / span 3",
                gridRow: "1 / span 2",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "5 / span 2",
                gridRow: "1 / span 2",
              },
            }}
          >
            <HoldTypesPanel
              vinHolds={vinHolds}
              vinHoldsLoading={vinHoldsLoading}
              title={t("fv-dashboard:Top Hold Types")}
              holdsLabel={t("fv-dashboard:Holds")}
              tableHeaderHoldTypeLabel={t("fv-dashboard:Hold Type")}
              tableHeaderVinsLabel={t("fv-dashboard:VINs")}
            />
          </div>
          {showItssWidget ? (
            <div
              css={{
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "4 / auto",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 4",
                  gridRow: "2 / auto",
                },
              }}
            >
              <VinCategoryChartPanel
                data={itssCountData}
                isLoading={itssCountDataIsLoading}
                onBarClick={(datum) => {
                  resetSearchBar();
                  setSearchFilter("lifeCycleState", ["Active"]);
                  setSearchFilter("groupCategory:ITSS", [datum.id]);
                  searchEntities(solutionId);
                }}
                title={t("fv-dashboard:VINs In Transit Stop Ship")}
                noDataLabel={t("fv-dashboard:No Data Available")}
                chartXAxisLabel={t("fv-dashboard:ITSS ID")}
                chartYAxisLabel={t("fv-dashboard:VIN Count")}
                chartTooltipSingularLabel={t("fv-dashboard:VIN")}
                chartTooltipPluralLabel={t("fv-dashboard:VINs")}
              />
            </div>
          ) : null}
          {showSpotyBuyWidget ? (
            <div
              css={{
                [MediaQueries.smallAndDown]: {
                  marginBottom: "20px",
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "5 / auto",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 4",
                  gridRow: "3 / auto",
                },
              }}
            >
              <VinCategoryChartPanel
                data={spotBuyCountData}
                isLoading={spotBuyCountDataIsLoading}
                onBarClick={(datum) => {
                  resetSearchBar();
                  setSearchFilter("lifeCycleState", ["Active"]);
                  setSearchFilter("groupCategory:SB", [datum.id]);
                  searchEntities(solutionId);
                }}
                title={t("fv-dashboard:Spot Buy")}
                noDataLabel={t("fv-dashboard:No Data Available")}
                chartColor={Colors.exceptions.SPOT_BUY}
                chartXAxisLabel={t("fv-dashboard:Authorization Code")}
                chartYAxisLabel={t("fv-dashboard:VIN Count")}
                chartTooltipSingularLabel={t("fv-dashboard:VIN")}
                chartTooltipPluralLabel={t("fv-dashboard:VINs")}
              />
            </div>
          ) : null}
        </Dashboard.TabPanel>
        <Dashboard.TabPanel>
          <FinishedVehicleSavedSearchesPanel />
          <WatchedVins />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
}

FinVehicleDashboard.propTypes = {
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  entityCount: PropTypes.number,
  entityCountLoading: PropTypes.bool,
  entityDeliveredCount: PropTypes.number,
  entityDeliveredCountLoading: PropTypes.bool,
  exceptionTypes: PropTypes.array,
  exceptions: PropTypes.array,
  exceptionsLoading: PropTypes.bool,
  fetchEntityCount: PropTypes.func.isRequired,
  fetchEntityDeliveredCount: PropTypes.func.isRequired,
  fetchExceptions: PropTypes.func.isRequired,
  fetchHoldCounts: PropTypes.func.isRequired,
  holdTypes: PropTypes.any,
  resetSavedSearch: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  searchEntities: PropTypes.any,
  setSearchFilter: PropTypes.func.isRequired,
  solutionId: PropTypes.any,
  vinHolds: PropTypes.array,
  vinHoldsLoading: PropTypes.any,
  fetchItssCount: PropTypes.func,
  itssCountData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  itssCountDataIsLoading: PropTypes.bool,
  showItssWidget: PropTypes.bool,
  fetchSpotBuyCount: PropTypes.func,
  spotBuyCountData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  spotBuyCountDataIsLoading: PropTypes.bool,
  showSpotyBuyWidget: PropTypes.bool,
};

export default FinVehicleDashboard;
