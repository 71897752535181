/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { VictoryPie, VictoryLabel, VictoryTooltip } from "victory";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { MediaQueries } from "components/responsive";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";

const vinHoldsFill = (data) => {
  const colors = Object.keys(Colors.holds);
  return data.map((d, i) => {
    return {
      ...d,
      // H2-940: Add Hold Code next to Description in Parentheses
      // Generating the full display text here to be used wherever it is needed
      fullDescription: `${d.type} (${d.reason_code})`,
      fill: Colors.holds[colors[i]],
    };
  });
};

export const HoldTypesPanel = ({
  vinHolds,
  vinHoldsLoading,
  title,
  holdsLabel,
  tableHeaderHoldTypeLabel,
  tableHeaderVinsLabel,
}) => {
  const data = vinHoldsFill(vinHolds.slice(0, 6));

  const vinHoldsColumns = [
    {
      Header: tableHeaderHoldTypeLabel,
      accessor: "type",
      Cell: (cellInfo) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              src={faCircle}
              color={cellInfo.row.original.fill}
              style={{
                marginRight: 8,
                fontSize: 10,
              }}
            />
            <Text>{cellInfo.row.original.fullDescription}</Text>
          </div>
        );
      },
    },
    {
      Header: tableHeaderVinsLabel,
      width: 100,
      accessor: "vins_associated",
    },
  ];

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content>
        <div
          css={{
            position: "relative",
            backgroundColor: "white",
            minHeight: 290,
            margin: -16,
            [MediaQueries.mediumAndUp]: {
              margin: "unset",
            },
          }}
        >
          <Loader loaded={!vinHoldsLoading}>
            <div
              css={{
                position: "relative",
              }}
            >
              <VinHoldCount
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "25%",
                  width: "25%",
                }}
                count={_.sumBy(data, "vins_associated")}
                holdsLabel={holdsLabel}
              />
              <div
                css={{
                  position: "relative",
                }}
              >
                <VictoryPie
                  height={185}
                  origin={{ y: 185 }}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                  }}
                  x="type"
                  y="vins_associated"
                  startAngle={-90}
                  endAngle={90}
                  radius={165}
                  innerRadius={100}
                  data={data}
                  labels={({ datum }) => datum.type}
                  labelComponent={<VinHoldsTooltip x={200} y={300} />}
                />
              </div>
            </div>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 10px",
                backgroundColor: "white",
                marginTop: -5,
              }}
            >
              <BaseTable
                theme={Themes.LIGHT}
                data={data}
                columns={vinHoldsColumns}
                pageSize={data?.length}
                disableSortBy={true}
              />
            </div>
          </Loader>
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

HoldTypesPanel.propTypes = {
  vinHolds: PropTypes.array,
  vinHoldsLoading: PropTypes.bool,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  searchEntities: PropTypes.func,
  title: PropTypes.string,
  holdsLabel: PropTypes.string,
  tableHeaderHoldTypeLabel: PropTypes.string,
  tableHeaderVinsLabel: PropTypes.string,
};

const VinHoldCount = ({ style, count, holdsLabel }) => {
  return (
    <svg css={style} viewBox="0 0 50 50">
      <text x="0" y="25" fontSize="24" fontWeight="bold" textAnchor="start">
        {count}
      </text>
      <text x="0" y="40" fontSize="16" textAnchor="start">
        {holdsLabel}
      </text>
    </svg>
  );
};
VinHoldCount.propTypes = {
  style: PropTypes.object,
  count: PropTypes.number,
  holdsLabel: PropTypes.string,
};

const VinHoldsTooltip = (props) => {
  const { active, datum, x, y } = props;

  if (!active) {
    return null;
  }

  return (
    <g>
      <VictoryLabel
        x={x}
        y={y - 170}
        text={`${datum.vins_associated} VINs`}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 32,
          fontWeight: 600,
          fill: Colors.holds.DARK_BLUE,
        }}
      />
      <VictoryLabel
        x={x}
        y={y - 135}
        /* H2-940: Add Hold Code next to Description in Parentheses
         * Truncate the text because it can be too long to fit in the inner part of the pie graph
         * Keep in mind that this is user submitted data, the user can use mixed casing or all caps.
         * Because of this, we are forcing all caps because the capital letters in the font are
         *   generally the same width with a few exceptions (e.g. "I")
         *
         * Note: Unfortunately, this is SVG text, it is unaffected by CSS's text-overflow: ellipsis
         */
        text={_.truncate(datum.fullDescription.toUpperCase(), {
          length: 22,
        })}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 15,
          fill: `${datum.fill}`,
        }}
      />
    </g>
  );
};

VinHoldsTooltip.defaultEvents = VictoryTooltip.defaultEvents;

VinHoldsTooltip.propTypes = {
  active: PropTypes.bool,
  datum: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
};
