/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import Colors from "styles/colors";

const titleCss = {
  fontSize: "1.25em",
  fontWeight: "300",
  color: Colors.background.GRAY_BLUE,
  marginBottom: "1em",
};

const descriptionCss = {
  marginBottom: "2em",
};

const SectionHeader = (props) => {
  const { title, description } = props;

  return (
    <div>
      <div css={titleCss}>{title}</div>
      {description && (
        <div css={descriptionCss}>
          <ReactMarkdown children={description} />
        </div>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SectionHeader;
