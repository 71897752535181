/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { getColumns } from "./LocationMatchingColumns";
import { withTranslation } from "react-i18next";

const AvailableLocationPanel = ({
  t,
  isLoading,
  locations,
  lads,
  selectedLocations,
  eventHandler,
  page,
  pageSize,
  totalPages,
  setPagination,
  locationId,
}) => {
  const columns = getColumns(t, lads, "check", selectedLocations);

  return (
    <div
      className="availablelocations"
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        margin: "1em",
        overflow: "auto",
        backgroundColor: "white",
      }}
    >
      <BaseTable
        data={locations}
        columns={columns}
        isLoading={isLoading}
        showPagination={true}
        isManualPagination={true}
        pageIndex={page}
        pageSize={pageSize}
        pageCount={totalPages}
        disableSortBy={true}
        onPageChange={(newPage) => {
          setPagination(locationId, newPage, pageSize);
        }}
        onPageSizeChange={(newPageSize) => {
          setPagination(locationId, 0, newPageSize);
        }}
        rowClickHandler={(row) => {
          const locID = row.original.id;
          eventHandler(locID, "SELECT_LOCATION");
        }}
      />
    </div>
  );
};

AvailableLocationPanel.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  locations: PropTypes.array,
  lads: PropTypes.array,
  selectedLocations: PropTypes.array,
  eventHandler: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setPagination: PropTypes.func,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withTranslation(["location-matching"])(AvailableLocationPanel);
