import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DocumentationState from "./DocumentationState";
import { TryApiView } from "./TryApiView";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  // const { getTryApi } = DocumentationState.selectors;
  const { getApiSample, getApiGroup, getTryResponse, getTryStatus } =
    DocumentationState.selectors;

  return {
    // content: getTryApi(state),
    content: {
      title: "Try an Api",
      Description: "",
    },
    apiSample: getApiSample(state),
    apiGroup: getApiGroup(state),
    isLoading: getTryStatus(state),
    tryResponse: getTryResponse(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { callTryApi, fetchApiGroup, fetchApiSample } =
    DocumentationState.actionCreators;

  const { setTitle } = TitleState.actionCreators;

  return bindActionCreators(
    {
      callTryApi,
      fetchApiSample,
      fetchApiGroup,
      setTitle,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TryApiView);
