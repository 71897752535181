/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { ListGroup as BSListGroup } from "react-bootstrap";

const ListGroup = ({ children, className }) => {
  return <BSListGroup className={className}>{children}</BSListGroup>;
};

ListGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

const ListGroupItem = ({ onClick: clickHandler, styles, children }) => {
  return (
    <BSListGroup.Item
      onClick={clickHandler}
      css={styles}
      action={clickHandler ? true : false}
    >
      {children}
    </BSListGroup.Item>
  );
};

ListGroupItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  styles: PropTypes.object,
};

ListGroup.Item = ListGroupItem;

export { ListGroup };
