/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";

const DetailItem = (props) => {
  const { label, style, tooltipText, "data-qa": dataQa } = props;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        flexGrow: 1,
        ...style,
      }}
    >
      {label ? (
        <span css={{ color: "#999", marginBottom: "5px", fontSize: "normal" }}>
          {label}
          {tooltipText ? (
            <span>
              {" "}
              <Tooltip tooltipChildren={<Text>{tooltipText}</Text>}>
                <Icon src={faQuestionCircle} />
              </Tooltip>
            </span>
          ) : null}
        </span>
      ) : null}
      <span
        css={{ color: Colors.background.DARK_BLUE, fontSize: "1.25em" }}
        data-qa={dataQa}
      >
        {props.children}
      </span>
    </div>
  );
};

DetailItem.propTypes = {
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  "data-qa": PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};
export default DetailItem;
