import axios from "axios";
import apiUrl from "../../../api-url";
import buildFetchDuck from "../../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../../vendor/signal-utils/chain-reducers";
import moment from "moment-timezone";

const STORE_MOUNT_POINT = "reportSchedules";

const sendEmailDuck = buildFetchDuck(STORE_MOUNT_POINT, "SEND_EMAIL");
const createDuck = buildFetchDuck(STORE_MOUNT_POINT, "CREATE");
const updateDuck = buildFetchDuck(STORE_MOUNT_POINT, "UPDATE");
const deleteDuck = buildFetchDuck(STORE_MOUNT_POINT, "DELETE");

const URL_POWERBI_REPORT = apiUrl("/powerbi/report");

const getScheduleUrl = (report) => {
  let url = `${URL_POWERBI_REPORT}/${report.reportId}`;

  const filterSetId = report.filterSet?.filterSetId;
  if (filterSetId) {
    url += `/${filterSetId}`;
  }

  url += "/schedule";

  return url;
};

const FETCH_REPORT_SCHEDULES = "FETCH_REPORT_SCHEDULES";
const RECEIVE_REPORT_SCHEDULES = "RECEIVE_REPORT_SCHEDULES";

function fetchSchedule(report) {
  return (dispatch) => {
    const reportId = report.reportId;
    const filterSetId = report.filterSet?.filterSetId;

    dispatch({ type: FETCH_REPORT_SCHEDULES, reportId, filterSetId });

    const url = getScheduleUrl(report);

    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_REPORT_SCHEDULES,
          reportId,
          filterSetId,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          dispatch({
            type: RECEIVE_REPORT_SCHEDULES,
            reportId,
            filterSetId,
            data: [],
          });
        }
      });
  };
}

function sendEmail(report, email, body) {
  const url = getScheduleUrl(report);
  return (dispatch) => {
    return dispatch(
      sendEmailDuck.fetch(url, {
        method: "post",
        data: {
          patternDetails: {
            pattern: "now",
          },
          email,
          emailBody: body,
        },
      }),
    );
  };
}

function createSchedule(
  report,
  { name, email, emailBody, patternDetails } = {},
) {
  const url = getScheduleUrl(report);
  return (dispatch) =>
    dispatch(
      createDuck.fetch(url, {
        method: "post",
        data: {
          name,
          email,
          emailBody,
          patternDetails: { ...patternDetails, timezone: moment.tz.guess() },
        },
      }),
    );
}

function updateSchedule(
  report,
  schedule,
  { name, email, emailBody, patternDetails, enabled } = {},
) {
  let url = getScheduleUrl(report);
  url += `/${schedule.creatorId}/${schedule.name}`;

  return (dispatch) =>
    dispatch(
      updateDuck.fetch(url, {
        method: "patch",
        data: {
          name,
          email,
          emailBody,
          patternDetails: {
            ...patternDetails,
            timezone: schedule.patternDetails.timezone,
          },
          enabled,
        },
      }),
    );
}

function deleteSchedule(report, schedule) {
  let url = getScheduleUrl(report);
  url += `/${schedule.creatorId}/${schedule.name}`;

  return (dispatch) => dispatch(deleteDuck.fetch(url, { method: "delete" }));
}

const getIsLoading = (reportId) => {
  if (!reportId) {
    throw new Error("getIsLoading requires a reportId");
  }
  return (state) => state[STORE_MOUNT_POINT][reportId].isLoading;
};

const getSchedules = (state) => state[STORE_MOUNT_POINT];

// Map of reportIds with their schedules
const initialState = {
  // Example:
  //
  // [id]: {
  //   schedules: [],
  //   isLoading: false,
  // }
};

export const getSchedulesKey = (reportId, filterSetId) => {
  return `${reportId}/${filterSetId}`;
};

function reportSchedulesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORT_SCHEDULES:
      return {
        ...state,
        [getSchedulesKey(action.reportId, action.filterSetId)]: {
          schedules: [],
          isLoading: true,
        },
      };
    case RECEIVE_REPORT_SCHEDULES:
      return {
        ...state,
        [getSchedulesKey(action.reportId, action.filterSetId)]: {
          ...state[action.reportId],
          schedules: action.data,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchSchedule,
    sendEmail,
    createSchedule,
    updateSchedule,
    deleteSchedule,
  },
  selectors: {
    getIsLoading,
    getSchedules,
    getCreateScheduleData: createDuck.selectors.getData,
    getUpdateScheduleData: updateDuck.selectors.getData,
    getDeleteScheduleData: deleteDuck.selectors.getData,
    getSendEmailData: sendEmailDuck.selectors.getData,
  },
  helpers: { getSchedulesKey },
  reducer: chainReducers([
    reportSchedulesReducer,
    createDuck.reducer,
    updateDuck.reducer,
    deleteDuck.reducer,
    sendEmailDuck.reducer,
  ]),
};
