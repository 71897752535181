import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import apiUrl from "./api-url";
import AuthenticationUtils from "./modules/auth/authentication";

// Specify namespaces that should be loaded from remote URLs
const remoteResources = [
  {
    namespace: "documentation-remote",
    url: apiUrl("/support/get_language_templates"),
  },
];

export const fallBackLanguage = "en";

export const options = {
  fallbackLng: fallBackLanguage,
  debug: false,
  lowerCaseLng: true,
  keySeparator: false,
  nsSeparator: ":",
  userLanguage: window.navigator.userLanguage || window.navigator.language,
  backend: {
    loadPath: (languages, namespaces) => {
      // Default local resource path
      // This path uses interpolation like the translations strings.
      // Thus, need to use `[[[ ]]]` for templating the language and namespace.
      let path = `/locales/[[[lng]]]/[[[ns]]].json`;

      // If a remote namespace is being requested, load its external URL
      remoteResources.forEach((resource) => {
        if (namespaces[0] === resource.namespace) {
          path = resource.url;
        }
      });

      return path;
    },

    crossDomain: true,

    customHeaders: () => {
      const authHeaders = AuthenticationUtils.getAuthHeaders();
      const language = i18n.language ? i18n.language : "en";
      authHeaders["Accept-Language"] = language;

      return authHeaders;
    },
  },

  // Use ONLY the user's browser settings to detect the language
  detection: {
    order: ["navigator"],
    excludeCacheFor: ["navigator"],
  },

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    prefix: "[[[",
    suffix: "]]]",
  },

  compatibilityJSON: "v3",

  ns: [
    "appnav",
    "components",
    "create-shipment",
    "documentation",
    "documentation-remote",
    "domain",
    "end-to-end",
    "errors",
    "exceptions",
    "fv-dashboard",
    "fv-vin-details",
    "fv-vin-search",
    "geofence-edit",
    "header-bar",
    "lads",
    "location-edit",
    "location-matching",
    "location-search",
    "locations",
    "map",
    "organizations",
    "profile",
    "reports",
    "shipment-search",
    "shipment-status",
    "translation",
    "shipment-details",
    "users",
    "filters",
  ],
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

export default i18n;
