import { connect } from "react-redux";
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import CarrierViewSearchBarState from "../../redux/CarrierViewSearchBarState";
import CarrierViewSavedSearchState from "../../redux/CarrierViewSavedSearchState";
import SearchBarContainer from "./CarrierView.SearchBar.container";
import FilterSectionContainer from "./CarrierView.SearchFilters.container";

const mapStateToProps = (state) => {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    CarrierViewSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { saveSearch, editSearch } = CarrierViewSavedSearchState.actionCreators;

  return {
    saveSearch: (name, search) => dispatch(saveSearch(name, search)),
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSavedSearchModal);
