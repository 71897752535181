/** @jsxImportSource @emotion/react */
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import Loader from "react-loader";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryLabel,
} from "victory";
import { formatTickMetric } from "shared/utils/chart.utils";
import Colors from "styles/colors";

const InventoryForecastChart = ({ forecastData, maxCapacity, isLoading }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  const axisStyle = {
    axis: { stroke: Colors.border.BOTTOM_AXIS_BORDER },
  };

  const crossAxisStyle = {
    axis: { stroke: "transparent" },
    axisLabel: { fontSize: 20, padding: 30 },
    grid: { stroke: Colors.border.INNER_GRAY_BORDER },
    tickLabels: { fontSize: 12, padding: 5, fontFamily: "inherit" },
  };

  const pageSize = 7;
  const totalPages = _.ceil(forecastData.length / pageSize);

  useEffect(() => {
    const pagedData = forecastData.slice(
      currentPage * pageSize,
      currentPage * pageSize + pageSize,
    );

    const updatedChartData = pagedData.map((data) => {
      return { ...data, date: moment(data.date).format("MM/DD/YYYY") };
    });

    const updatedLineData = pagedData.map((data) => {
      return {
        date: moment(data.date).format("MM/DD/YYYY"),
        count: maxCapacity,
      };
    });
    setBarChartData(updatedChartData);
    setLineChartData(updatedLineData);
  }, [maxCapacity, forecastData, currentPage, pageSize]);
  return (
    <div>
      <Loader loaded={!isLoading} />

      {forecastData ? (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div css={{ display: "flex" }}>
            <Button
              size="sm"
              variant="transparent"
              disabled={totalPages <= 1 || (totalPages > 0 && currentPage <= 0)}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <Icon src={faCaretLeft} size={FontSize.size28} />
            </Button>
          </div>
          <VictoryChart domainPadding={20} height={300}>
            <VictoryAxis
              style={axisStyle}
              tickLabelComponent={
                <VictoryLabel
                  dx={0}
                  dy={2}
                  angle={30}
                  textAnchor="start"
                  style={{ fontSize: 10 }}
                />
              }
            />
            <VictoryAxis
              style={crossAxisStyle}
              dependentAxis
              tickFormat={formatTickMetric}
            />
            <VictoryLine
              data={lineChartData}
              x={"date"}
              y={"count"}
              style={{
                data: { stroke: Colors.highlight.BRIGHT_BLUE, strokeWidth: 3 },
              }}
            />
            <VictoryBar
              data={barChartData}
              x={"date"}
              y={"count"}
              labels={({ datum }) => `${datum.count}`}
              style={{
                data: {
                  opacity: 0.75,
                  width: 35,
                  fill: ({ datum }) =>
                    datum.count > maxCapacity
                      ? Colors.highlight.RED
                      : Colors.highlight.GREEN,
                },
              }}
            ></VictoryBar>
          </VictoryChart>
          <div css={{ display: "flex" }}>
            <Button
              size="sm"
              variant="transparent"
              disabled={totalPages <= 1 || currentPage + 1 >= totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <Icon src={faCaretRight} size={FontSize.size28} />
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

InventoryForecastChart.propTypes = {
  forecastData: PropTypes.array,
  maxCapacity: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default InventoryForecastChart;
