/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text } from "components/atoms/Text.atom";
import { MilestoneReferencesButton } from "./MilestoneReferencesButton";
import { RotatingExpanderCell } from "components/organisms/base-table/Cell/RotatingExpanderCell";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import Colors from "styles/colors";

const CellRenderer = (props) => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>{props.value}</div>
  );
};

CellRenderer.propTypes = {
  value: PropTypes.string,
};

const MilestoneCommentsAndReferencesCell = ({ value }) => {
  return (
    <div
      css={{
        textOverflow: "auto",
        overflow: "none",
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
      }}
    >
      {value.comments ? <Text block>{value.comments}</Text> : null}
      <MilestoneReferencesButton
        vin={value.vin}
        references={value.references}
      />
    </div>
  );
};

MilestoneCommentsAndReferencesCell.propTypes = {
  value: PropTypes.shape({
    vin: PropTypes.string,
    comments: PropTypes.string,
    references: PropTypes.array,
  }),
};

const StatusCellRenderer = (props) => {
  return (
    <div
      css={{
        textOverflow: "auto",
        overflow: "none",
        color: props.value === "Failed" ? Colors.highlight.RED : "inherit",
      }}
    >
      {props.value}
    </div>
  );
};

StatusCellRenderer.propTypes = {
  value: PropTypes.string,
};

// Columns to display in API Logs search results table
export const getColumns = (t) => {
  let columns = [];

  columns.push({
    Header: () => null,
    id: "expander",
    Cell: RotatingExpanderCell,
    width: 30,
    maxWidth: 30,
    disableSortBy: true,
    disableResizing: true,
    accessor: () => {
      return {
        t: t,
      };
    },
  });

  columns.push({
    Header: t("documentation:VIN"),
    accessor: "vin",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Milestone Event"),
    accessor: "milestoneCodeDescription",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Milestone Status Code"),
    accessor: "milestoneCode",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Location"),
    accessor: "locationCode",
    Cell: CellRenderer,
  });

  columns.push({
    Header: t("documentation:Timestamp"),
    accessor: "statusUpdateTs",
    Cell: (cellProps) => <DateTimeCell dateTime={cellProps.value} localize />,
  });

  columns.push({
    Header: t("documentation:Milestone Comments and References"),
    accessor: ({
      vin,
      milestoneComments: comments,
      milestoneReferences: references,
    }) => {
      return { vin, comments, references };
    },
    Cell: MilestoneCommentsAndReferencesCell,
  });

  columns.push({
    Header: t("documentation:Status"),
    accessor: "status",
    Cell: StatusCellRenderer,
  });

  return columns;
};
