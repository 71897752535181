import { connect } from "react-redux";
import PublicShipmentDetailState from "pages/shipments/redux/PublicShipmentDetailState";
import { PublicShipmentDetailPage } from "./PublicShipmentDetail.page";

function mapStateToProps(state) {
  const {
    getShipmentDetails,
    getRouteHeatmap,
    getChildShipmentDetails,
    getIsLoaded,
    getActiveOrganization,
  } = PublicShipmentDetailState.selectors;

  return {
    shipmentId: state.location.query.id,
    shipment: getShipmentDetails(state),
    childShipments: getChildShipmentDetails(state),
    routeHeatmap: getRouteHeatmap(state),
    activeOrganization: getActiveOrganization(state),
    isLoaded: getIsLoaded(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchShipmentDetails } = PublicShipmentDetailState.actionCreators;

  return {
    fetchShipmentDetails: (shipmentID) =>
      dispatch(fetchShipmentDetails(shipmentID)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicShipmentDetailPage);
