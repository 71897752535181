import { connect } from "react-redux";
import { DebugETATool } from "./DebugETATool.page";
import DebugEtaToolState from "../redux/DebugETAToolState";

function mapStateToProps(state) {
  return {
    requestData: DebugEtaToolState.selectors.getDebugEtaToolData(state),
  };
}

const actionCreators = {
  debugEta: DebugEtaToolState.actions.debugEta,
  goToDebugEtaTool: DebugEtaToolState.actions.goToDebugEtaTool,
};

export default connect(mapStateToProps, actionCreators)(DebugETATool);
