/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { FlexColDiv } from "styles/container-elements";
import Colors from "styles/colors";
import FormRow from "components-old/forms/FormRow";

const CreateMilestoneFormControls = ({
  isValid,
  resetHandler,
  submitHandler,
  isSubmitting,
  t,
}) => {
  return (
    <FlexColDiv
      style={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        color: Colors.text.DARK_BLUE,
        padding: "1em",
      }}
    >
      <FormRow
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Button
          className="me-2"
          variant="danger"
          onClick={() => resetHandler()}
          disabled={isSubmitting}
        >
          {t("create-milestone:Reset")}
        </Button>
        <Button
          variant="success"
          disabled={!isValid || isSubmitting}
          onClick={() => submitHandler()}
        >
          {t("create-milestone:Submit")}
        </Button>
      </FormRow>
    </FlexColDiv>
  );
};

export default withTranslation(["create-milestone"])(
  CreateMilestoneFormControls,
);

CreateMilestoneFormControls.propTypes = {
  isValid: PropTypes.any,
  resetHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
