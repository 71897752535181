/* Adding the Contact Us form fields as a configuration,
to enable organizations to integrate with external support platform of their choice. */

export const fieldConfiguration = [
  {
    sequence: 1,
    type: "text",
    key: "firstName",
    label: "First Name",
    required: true,
    default: "",
    width: "50%",
  },
  {
    sequence: 2,
    type: "text",
    key: "lastName",
    label: "Last Name",
    required: false,
    default: "",
    width: "50%",
  },
  {
    sequence: 3,
    type: "tel",
    key: "phoneNumber",
    label: "Phone Number",
    required: true,
    default: "",
    width: "50%",
  },
  {
    sequence: 4,
    type: "email",
    key: "email",
    label: "Email",
    required: true,
    default: "",
    width: "50%",
  },
  {
    sequence: 5,
    type: "text",
    key: "subject",
    label: "Subject",
    required: true,
    default: "",
    width: "100%",
  },
  {
    sequence: 6,
    type: "textarea",
    key: "description",
    label: "Description",
    required: true,
    default: "",
    width: "100%",
  },
];
