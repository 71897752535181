import moment from "moment";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "headerSearchBar";

// URLS
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  let config = {
    headers: {
      Accept: "application/json;version=condensed",
      "x-time-zone": moment.tz.guess(),
    },
  };

  let transformResponse = (res) => {
    return {
      meta: res.meta,
      data: res.response,
    };
  };

  dispatch(duck.fetch(ORGANIZATIONS_URL, config, transformResponse));
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  [], // No Categories
  null, // No Filters
  fetchSearch,
);
