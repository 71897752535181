const ranges = [
  {
    value: 1e6,
    suffix: "M",
  },
  {
    value: 1e3,
    suffix: "k",
  },
];

/**
 * This formats the tickNumber to an abbreviated value suffixed with the metric denomination.
 * It uses two significant digits to avoid collisions in the naming.
 *
 * (1100 => 1.1k, 11000 => 11k, 100000 => 100k, 1100000 => 1.1M)
 *
 * @param tickNumber value of tick mark
 * @returns formatted value
 */
export const formatTickMetric = (tickNumber) => {
  for (let i = 0; i < ranges.length; i++) {
    const { value, suffix } = ranges[i];
    if (tickNumber >= value) {
      return `${Number(tickNumber.toPrecision(2)) / value}${suffix}`;
    }
  }
  return tickNumber.toString();
};
