import Axios, { AxiosHeaders } from "axios";
import _ from "lodash";
import apiUrl from "../../api-url";
import { UserAuthorizationNamespace } from "./Authorization";
import MixpanelUtils from "../../trackers/mixpanel";
import ApplicationConfig from "application-config";
import { decodeJsonWebToken } from "./auth-utils";
import { BrowserStorage } from "utils/browser-storage.utils";

const AUDIENCE = "urn:fv-api";
//TODO: To run portal-t-local, we need to update the below to http and add :3000 before the /
// Let's find a way to automate this.
const REDIRECT_URI = "https://" + window.location.hostname + "/callback";

class Authentication {
  constructor() {
    this.setIsLoggingIn(false);
  }

  /**
   *
   * @param code
   */
  async handleAuthentication(code, orgId) {
    if (!code) {
      return false;
    }

    this.setIsLoggingIn(true);

    // Request auth token
    try {
      const url = this.getEnvAuth0BaseUri() + `/oauth/token`;

      const params = {
        grant_type: "authorization_code",
        scope: "openid profile",
        client_id: this.getEnvAuth0ClientId(),
        code: code,
        redirect_uri: this.getEnvRedirectUri(orgId),
      };

      const resp = await Axios.post(url, params);

      this.setSession(resp.data);
      MixpanelUtils.identify(this.userId, this.userMixpanelPeopleProperties);
    } catch (error) {
      window.location.assign("/verifyUserError");
      return;
    }

    // Send the access_token to new login audit endpoint.
    // Does not block login.
    Axios.post(apiUrl("/iam/login-audit"));

    const url = this.getLastRequestedUrl();
    this.setLastRequestedUrl("");

    // If not silent authentication
    if (!this.getIsSilentAuthentication()) {
      //TODO: (Bara: 11/3/2020) The below redirects the user to the login-loading screen.
      // However, this was taking too long and slowed the login process done.
      // Craig wasn't happy about it, so, for non-federated logins, we just redirect
      // directly to where the user needs to be and let AuthorizedComponentWithRedirect
      // handle it from here. Leaving this code here for now in case we want to
      // re-enable this in a more elegant way in the future.
      // Suggestion: merge CallbackView and LoginLoading into one screen with 1 spinner.
      // const params = new URLSearchParams();

      // if (orgId) {
      //   params.set("orgId", orgId);
      // }

      // if (url) {
      //   params.set("redirectUrl", encodeURIComponent(url));
      // }

      // window.location.assign(
      //   "/login-loading" + (params ? "?" : "") + params.toString()
      // );

      let qs = this.getOrgIdQs(orgId);

      if (url) {
        window.location.assign(url + qs);
      } else {
        window.location.assign("/" + qs);
      }
    } else {
      // If silent auth, redirect to login-loading
      // to finish authentication process.
      window.location.assign("/login-loading");
    }
  }

  handleSamlAuthentication() {
    this.logout();
    this.setIsSilentAuthentication(true);

    // FIN-3383: Save the state QSP value into session storage for later retrieval.
    // We are using this for an object that defines where the user will initially land.
    // e.g. { solution: "TEST", target: "finishedvehicle", subtarget: "details", resource: "VIN"}
    //      Will redirect the user to VIN Details in FinishedVehicle.

    // `window.location.search` is the search string, e.g. "?state=abc"
    const queryParams = new URLSearchParams(window.location.search);
    const federatedStateString = queryParams.get("state");
    if (federatedStateString) {
      BrowserStorage.federatedState = federatedStateString;
    }

    // Redirect to authorize with Auth0.
    const qs = this.getAuthorizeQueryString({ prompt: "none" });
    window.location.assign(`${this.getEnvAuth0BaseUri()}/authorize${qs}`);
  }

  isAuthenticated() {
    if (ApplicationConfig.IS_PUBLIC) {
      return false;
    }

    let expiresAt = JSON.parse(BrowserStorage.expiresAt);
    return new Date().getTime() < expiresAt;
    //return expiresAt > 0;
  }

  isLoggingIn() {
    return BrowserStorage.isLoggingIn;
  }

  login(orgId) {
    this.setIsSilentAuthentication(false);
    const qs = this.getAuthorizeQueryString(undefined, orgId);
    window.location.assign(`${this.getEnvAuth0BaseUri()}/authorize${qs}`);
  }

  // isManual flag controls whether this logout was intentional by the user (eg: clicking a Logout link)
  // or automated via other processes (eg: session timeout).
  logout(isManual = false) {
    // Retrieve isSilentAuthentication value for later use
    const isSilentAuthentication = this.getIsSilentAuthentication();

    // Clear access token and ID token from local storage
    BrowserStorage.accessToken = null;
    BrowserStorage.idToken = null;
    BrowserStorage.expiresAt = null;
    BrowserStorage.isLoggingIn = null;
    BrowserStorage.currentOrganization = null;
    BrowserStorage.isSilentAuthentication = null;
    BrowserStorage.solutionId = null;
    BrowserStorage.federatedState = null;
    BrowserStorage.federationData = null;
    // used to maintain the federated user popup modal state for user preference update.
    BrowserStorage.hasRequestedFederatedUserEmail = false;

    // If this is a "manual" logout rather than an automated one, and this is a federated user:
    if (isManual && isSilentAuthentication) {
      // Redirect user to the Session Ended screen, so that they don't see the normal login
      // (and possibly get confused, since they don't usually use the normal login)
      window.location.assign("/session-ended");
    } else {
      window.location.assign("/");
    }
  }

  setSession(authResult) {
    let expiresAt = JSON.stringify(
      authResult.expires_in * 1000 + new Date().getTime(),
    );
    BrowserStorage.accessToken = authResult.access_token;
    BrowserStorage.idToken = authResult.id_token;
    BrowserStorage.expiresAt = expiresAt;
    BrowserStorage.isLoggingIn = false;
  }

  setLastRequestedUrl(url) {
    BrowserStorage.lastRequestedUrl = url;
  }

  setIsLoggingIn(flag) {
    BrowserStorage.isLoggingIn = flag;
  }

  setIsSilentAuthentication(flag) {
    BrowserStorage.isSilentAuthentication = flag;
  }

  setHasRequestedFederatedUserEmail(flag) {
    BrowserStorage.hasRequestedFederatedUserEmail = flag;
  }

  getIsSilentAuthentication() {
    return JSON.parse(BrowserStorage.isSilentAuthentication);
  }

  getLastRequestedUrl() {
    return BrowserStorage.lastRequestedUrl;
  }

  getIdToken() {
    return BrowserStorage.idToken;
  }

  getAccessToken() {
    return BrowserStorage.accessToken;
  }

  getExpiresAt() {
    return BrowserStorage.expiresAt;
  }

  getHasRequestedFederatedUserEmail() {
    return JSON.parse(BrowserStorage.hasRequestedFederatedUserEmail);
  }

  getDecodedToken() {
    return decodeJsonWebToken(this.getAccessToken());
  }

  getDecodedIdToken() {
    return decodeJsonWebToken(this.getIdToken());
  }

  getEnvAuth0BaseUri() {
    return ApplicationConfig.ENVIRONMENT.auth.loginUrl;
  }

  getEnvAuth0ClientId() {
    return ApplicationConfig.ENVIRONMENT.auth.clientId;
  }

  getEnvRedirectUri(orgId = null) {
    let orgIdQs = this.getOrgIdQs(orgId);
    if (window.location.hostname.indexOf("localhost") >= 0) {
      return `http://${window.location.hostname}:${window.location.port}/callback${orgIdQs}`;
    } else {
      return `${REDIRECT_URI}${orgIdQs}`;
    }
  }

  getAuthHeaders() {
    const token = this.getAccessToken();

    // User is not logged in so that there is no headers to be returned
    if (_.isEmpty(token)) {
      return {};
    }

    const headers = {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Active-Org": BrowserStorage.currentOrganization ?? "",
    };

    const activeRoleHeader = BrowserStorage.userRoleOverride;
    const invalidRoles = [null, undefined, "null", "None"];
    if (!invalidRoles.includes(activeRoleHeader)) {
      headers["X-Active-Role"] = activeRoleHeader;
    }
    return headers;
  }

  getOrgIdQs(orgId) {
    if (orgId) {
      return `?orgId=${orgId}`;
    }

    return "";
  }

  getAuthorizeQueryString({ state, prompt } = {}, orgId) {
    const params = new URLSearchParams();

    params.set("scope", "openid profile");
    params.set("response_type", "code");
    params.set("client_id", this.getEnvAuth0ClientId());
    params.set("audience", AUDIENCE);
    params.set("redirect_uri", this.getEnvRedirectUri(orgId));

    if (state) {
      params.set("state", state);
    }

    if (prompt) {
      params.set("prompt", prompt);
    }

    return `?${params.toString()}`;
  }

  installAuthInterceptor() {
    if (ApplicationConfig.IS_PUBLIC) {
      return;
    }

    Axios.interceptors.request.use((config) => {
      // Apply incoming headers to defaults
      const authHeaders = new AxiosHeaders(this.getAuthHeaders());
      config.headers = authHeaders.concat(config.headers);
      return config;
    });
  }

  /**
   * TODO:
   * Rename this, auth0 has it's own version of a profile and this might
   * end up being confusing.
   */
  get userProfile() {
    const token = this.getDecodedIdToken();
    return token[UserAuthorizationNamespace];
  }

  /**
   * This is a global unique id for a given user, provided by auth0.
   *
   * Example: auth0|asdfasdfq345345.
   */
  get userId() {
    return this.userProfile.user_id;
  }

  /**
   *
   * @return {*}
   */
  get userEmail() {
    return this.userProfile.email;
  }

  /**
   * Username is just the first part of the email address
   *
   * @return {*|string}
   */
  get userUsername() {
    const email = this.userEmail;
    return email.split("@")[0];
  }

  get userPicture() {
    const token = this.getDecodedIdToken();
    return token.picture;
  }

  /**
   *
   */
  get userMixpanelPeopleProperties() {
    const profile = this.userProfile;

    return {
      $email: profile.email,
      $name: profile.email,
      "User Privileges": profile.privileges,
      "User Solutions": profile.solutions,
      "User Org Profiles": profile.org_profiles,
    };
  }

  // DO NOT ACCESS DIRECTLY - Use this.getFederatedRedirectOverrideState().
  _federatedRedirectOverrideState = {};

  /**
   * Read redirect override for federated users from session storage.
   * This object defines the first page the user loads.
   *
   * @returns {{ solution?: string, target?: string, subtarget?: string, resource?: string}}
   */
  getFederatedRedirectOverrideState() {
    // Return the value if we already parsed the session value.
    // This lets us call this function multiple times but only parse the JSON once.
    if (!_.isEmpty(this._federatedRedirectOverrideState)) {
      return this._federatedRedirectOverrideState;
    }

    try {
      this._federatedRedirectOverrideState =
        JSON.parse(BrowserStorage.federatedState) ?? {};
    } catch (e) {
      this._federatedRedirectOverrideState = {};
    }

    return this._federatedRedirectOverrideState;
  }
}

const AuthenticationUtils = new Authentication();

export default AuthenticationUtils;
