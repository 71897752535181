import _ from "lodash";
import axios from "axios";

/**
 * Just return the data inside an object with a property named with the value
 * inside `property`. If property is null, ignore this and return data exactly
 * as it was received.
 */
const asProperty = (data, property = null) => {
  if (property === null) return data;
  const obj = {};
  obj[property] = data;
  return obj;
};

/**
 * Return data from the property if property is not null
 */
const fromProperty = (data, property = null) => {
  if (property === null) return data;
  return data[property];
};

/**
 * Build a fetcher function that simplifies fetch process, adding the dispatchs
 * when it starts, is loading and when it finishes.
 *
 * @param {string} topic
 *        The topic you are written into the state.
 * @param {string | null} property
 *        Allows you to use several ducks in the same topic, adding a property
 *        where the duch will store in, instead of storing directly into the
 *        topic data itself.
 * @param {*} initialData
 *        The initial data of this state.
 * @returns
 */
const buildFetchDuck = (topic, property = null, initialData = []) => {
  const cancelTokenSources = {};

  function actionNamer(phase, options = { addError: false }) {
    // eg, ('schedule', 'request') => 'Schedule/REQUEST_ROUTE_SCHEDULE'
    const errAddendum = options?.addError ? "_ERROR" : "";
    const propertyAddendum = property ? `_${property.toUpperCase()}` : "";

    return `${_.capitalize(
      topic,
    )}/${phase.toUpperCase()}_${topic.toUpperCase()}${propertyAddendum}${errAddendum}`;
  }
  const actions = {
    REQUEST: actionNamer("request"),
    RECEIVE: actionNamer("receive"),
    REQUEST_ERROR: actionNamer("request", { addError: true }),
    CLEAR: actionNamer("clear"),
  };
  const initialState = asProperty(
    {
      data: initialData,
      url: "",
      isLoading: false,
      isLoadingError: false,
      loadingError: null,
      status: null,
    },
    property,
  );

  /**
   * Same as fetchWithoutCancelingSimilar but cancel other similar requests if
   * they are using the same URI.
   *
   * @param {String} url Url where you want to get data from.
   * @param {Object} config Allows you to pass parameters (e.g. headers) to
   *    the request.
   * @param {Function} transform Function that will be executed and transform
   * the data as soon as data is returned from fetch execution. It avoids
   * needing a reducer for simple transformations of the response.
   *
   * It is useful for frequent requests when you change just a part of it, for
   * example, requests of input texts changing its value.
   *
   * @param {Function} onSuccess A callback that will be run on a successful request.
   *
   */
  function fetch(
    url,
    config = {},
    transform = (data) => data,
    onSuccess = () => {},
  ) {
    const urlObj = new URL(url);
    const urlKey = urlObj.origin + urlObj.pathname;
    const cancelTokenSource = cancelTokenSources[urlKey];

    if (cancelTokenSource) {
      cancelTokenSource.cancel("Cancelled manually");
    }
    cancelTokenSources[urlKey] = axios.CancelToken.source();
    config.cancelToken = cancelTokenSources[urlKey].token;

    // Default to "get" if no method is specified in the config.
    if (!config.method) {
      config.method = "get";
    }

    config.url = url;

    return (dispatch) => {
      dispatch({ type: actions.REQUEST, url });
      return axios(config)
        .then((resp) => {
          dispatch({
            type: actions.RECEIVE,
            payload: transform(resp.data),
            status: resp.status,
          });
          onSuccess();
        })
        .catch((error) => {
          // When it is cancelled intentionally, we do not need to dispatch an
          // error
          if (error.message !== "Cancelled manually") {
            dispatch({
              type: actions.REQUEST_ERROR,
              error,
              status: error?.response?.status,
            });
            // Throw the error only when updating state.
            throw error;
          }
        });
    };
  }

  /**
   * Allows you to fetch data from a given url. This method cancels any other
   * currently running fetch with the same url/params
   *
   * @param {String} url Url where you want to get data from.
   * @param {Object} config Allows you to pass parameters (e.g. headers) to
   *    the request.
   * @param {Function} transform Function that will be executed and transform
   * the data as soon as data is returned from fetch execution. It avoids
   * needing a reducer for simple transformations of the response.
   */
  function fetchWithoutCancelingSimilar(
    url,
    config = null,
    transform = (data) => data,
    onSuccess = () => {},
  ) {
    // Default to "get" if no method is specified in the config.
    if (!config.method) {
      config.method = "get";
    }
    config.url = url;
    return (dispatch) => {
      dispatch({ type: actions.REQUEST, url });
      return axios(config)
        .then((resp) => {
          dispatch({
            type: actions.RECEIVE,
            payload: transform(resp.data),
            status: resp.status,
          });
          onSuccess();
        })
        .catch((error) =>
          dispatch({
            type: actions.REQUEST_ERROR,
            error,
            status: error?.response?.status,
          }),
        );
    };
  }

  function fetchSummary(url) {
    return (dispatch) => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, {
          headers: { Accept: "application/json;version=summary" },
        })
        .then((resp) => dispatch({ type: actions.RECEIVE, payload: resp.data }))
        .catch((error) => dispatch({ type: actions.REQUEST_ERROR, error }));
    };
  }

  function fetchEntity(url) {
    return (dispatch) => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, {
          headers: { Accept: "application/json;version=entity" },
        })
        .then((resp) => dispatch({ type: actions.RECEIVE, payload: resp.data }))
        .catch((error) => dispatch({ type: actions.REQUEST_ERROR, error }));
    };
  }

  function clear() {
    return (dispatch) => {
      dispatch({ type: actions.CLEAR });
    };
  }

  return {
    actions,
    fetch,
    fetchSummary,
    fetchEntity,
    fetchWithoutCancelingSimilar,
    clear,
    initialState,

    selectors: {
      getData: (state) => {
        return fromProperty(state[topic], property);
      },
    },
    reducer: (state = initialState, action = {}) => {
      switch (action.type) {
        case actions.REQUEST:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: true,
                url: action.url,
              },
              property,
            ),
          );

        case actions.RECEIVE:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: false,
                isLoadingError: false,
                loadingError: null,
                data: action.payload,
                status: action.status,
              },
              property,
            ),
          );

        case actions.REQUEST_ERROR:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: false,
                isLoadingError: true,
                loadingError: action.error,
                status: action.status,
              },
              property,
            ),
          );

        case actions.CLEAR:
          return Object.assign({}, state, initialState);

        default:
          return state;
      }
    },
  };
};

export default buildFetchDuck;
