/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";

const notesCss = {
  padding: "1em 0",
};

const SampleNotes = (props) => {
  const { t, notes } = props;

  if (!notes) {
    return null;
  }

  const description = notes.description || "";

  return (
    <div id="notes" css={notesCss}>
      <SectionHeader
        title={t("documentation:Usage Notes")}
        description={t(`documentation-remote:${description}`)}
      />
    </div>
  );
};

SampleNotes.propTypes = {
  notes: PropTypes.object,
};
export default withTranslation(["documentation", "documentation-remote"])(
  SampleNotes,
);
