/*
  Combine a series of reducers by chaining them into a single reducer
  function.

  For an incoming action, the combined reducer calls each original
  reducer in order. If any reducer returns a new state (identity-wise),
  return that new state as the result of the combined reducer. Return
  the original state if none of the chained reducers updated anything.
*/
export default function chainReducers(reducers, initialState) {
  return function (state = initialState, action = {}) {
    for (let reducer of reducers) {
      const newState = reducer(state, action);
      if (newState !== state) {
        return newState;
      }
    }
    return state;
  };
}
