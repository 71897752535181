import { connect } from "react-redux";
import DPUAdminTool from "./DPUAdminTool.page";
import DPUAdminToolState, {
  getActionStatus,
  getRequestResults,
} from "../redux/DPUAdminToolState";
import DPUVinHistorySearchBarState from "../redux/DPUVinHistorySearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    actionStatus: getActionStatus(state),
    requestResults: getRequestResults(state),
    solutionId: getSolutionId(state),
    dpuVinHistorySearchResults:
      DPUVinHistorySearchBarState.selectors.getVinHistorySearchResults(state),
    isVinHistoryLoading:
      DPUVinHistorySearchBarState.selectors.getIsLoading(state),
    isVinHistoryLoadingError:
      DPUVinHistorySearchBarState.selectors.getIsLoadingError(state),
    vinHistoryLoadingError:
      DPUVinHistorySearchBarState.selectors.getLoadingError(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { clearActionStatus, submitAction, submitBulkAction } =
    DPUAdminToolState.actionCreators;

  return {
    clearActionStatus: () => {
      dispatch(clearActionStatus());
    },
    submitAction: (solutionId, payload) => {
      dispatch(submitAction(solutionId, payload));
    },
    submitBulkAction: (solutionId, payload) => {
      dispatch(submitBulkAction(solutionId, payload));
    },
    resetVinHistorySearch: () =>
      dispatch(DPUVinHistorySearchBarState.actionCreators.resetSearchBar()),
    clearVinHistoryEntities: () =>
      dispatch(
        DPUVinHistorySearchBarState.actionCreators.clearVinHistoryEntities(),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DPUAdminTool);
