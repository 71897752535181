import { SelectFilterButton } from "components/search-bar/FilterButtons";
import {
  getEverythingQueryString,
  getBasicQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    // The categories aren't displayed for this search.
    // No need for a label.
    label: () => null,
    placeholder: (t) => {
      return t("organizations:Search for organization");
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const FILTERS = [
  {
    queryKey: "orgType",
    label: (t) => t("fv-vin-search:Organization Type"),
    optionsGetter: (props) => props.organizationTypeOptions,
    queryBuilder: getBasicQueryString,
    Component: SelectFilterButton,
    hideSelectEmpty: true,
  },
];
