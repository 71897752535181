import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import DriveAwaySearchBarState from "../../redux/DriveAwaySearchBarState";
import { FILTERS } from "./DriveAway.searchOptions";
import {
  selectStatusStateFilterOptions,
  selectCarrierFilterOptions,
} from "./DriveAway.SearchFilterSelectors";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  solutionId: getSolutionId(state),
  searchFilters: DriveAwaySearchBarState.selectors.getSearchFilters(state),
  statusStateFilterOptions: selectStatusStateFilterOptions(state),
  carrierFilterOptions: selectCarrierFilterOptions(state),
  canUserSearch: DriveAwaySearchBarState.selectors.getCanUserSearch(state),
  hasSearchCriteriaChanged:
    DriveAwaySearchBarState.selectors.getHasSearchCriteriaChanged(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = DriveAwaySearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
