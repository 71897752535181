/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faBox } from "@fortawesome/pro-solid-svg-icons";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import { MediaQueries } from "components/responsive";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { DetailPanelGroupItem } from "pages/components/molecules/DetailPanelGroupItem.molecule";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { ExceptionsBox } from "shared/components/molecules/ExceptionsBox.molecule";
import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
import { DeliverPackageModal } from "shared/components/modals/DeliverPackage.modal";
import { usePartViewExceptionLabel } from "pages/partview/components/hooks/usePartViewExceptionLabel";
import { CurrentLocationStatus } from "pages/partview/utils/const";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import Colors from "styles/colors";

const detailLabelStyle = { width: "5em" };

const translateStatus = (status, t) => {
  if (status?.toLowerCase() === "created/packaged") {
    return t("partview-details:Created/Packaged");
  }

  if (status?.toLowerCase() === "delivered") {
    return t("partview-details:Delivered");
  }

  if (status?.toLowerCase() === "in route") {
    return t("partview-details:In Route");
  }

  if (status?.toLowerCase() === "delayed") {
    return t("partview-details:Delayed");
  }

  return status;
};

export const PackageDetailsPanelGroup = ({
  trackingNumber,
  type,
  trailerEquipmentNumber,
  status,
  weight,
  weightUnits,
  exceptions = [],
  watch,
  isSetWatchPackageLoading,
  onWatchChange,
  subscribeeDetails,
  subscription,
  subscriptionRequestError,
  isSubscriptionLoading,
  updateSubscription,
  subscribe,
  unsubscribe,
  isSubscriptionUpdating,
  subscriptionUpdateSuccess,
  subscriptionUpdateError,
  subscriptionContext,

  deliveryData,
  fetchPackageDetails,
  setPackageDeliveryEvent,
  resetSetPackageDeliveryEvent,
  isPackageDeliveryEventLoading,
  packageDeliveryEventRequestError,
  packageDeliveryEventRequestSuccess,

  currentPositionDetails,
  addCoordinate,
  currentMapCoordinate,
  clearCoordinatesByType,
  isPartSeller = false,
}) => {
  const { t } = useTranslation("partview-details");
  const { getTranslatedExceptionNameByCode } = usePartViewExceptionLabel();

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showAlertMeModal, setShowAlertMeModal] = useState(false);
  const [showDeliverPackageModal, setShowDeliverPackageModal] = useState(false);

  useEffect(() => {
    if (currentPositionDetails) {
      addCoordinate(
        MapCoordinateType.CURRENT_LOCATION_PACKAGE,
        currentPositionDetails?.latitude,
        currentPositionDetails?.longitude,
        currentPositionDetails?.datetime,
        currentPositionDetails?.ind,
        {
          PopUpDetails: {
            name: currentPositionDetails?.currentLocationName,
            id: trackingNumber,
            type: MapCoordinateType.CURRENT_LOCATION_PACKAGE,
            City: currentPositionDetails?.city,
            State: currentPositionDetails?.state,
            Country: currentPositionDetails?.country,
          },
        },
      );
    }
  }, [currentPositionDetails, trackingNumber, addCoordinate]);

  // Translate exception name and format the exception object
  // to what ExceptionsBox expects
  const formattedExceptions = exceptions?.map((exception) => {
    //For Dealer View show "Misrouted" for misroute exception
    //show "Delayed" for short, missing, held and lost
    let exceptionMapperForDealer = {
      SH: "DELAYED", //Short
      MS: "DELAYED", //Missing
      HE: "DELAYED", //Held
      DM: "DM", //Damaged
      MR: "MR", //Misrouted
      RE: "RE", //Recycled
      LS: "DELAYED", //Lost
      BS: "BS", //Behind Schedule
      BO: "BO", //Back Order
    };
    return {
      id: exception.id,
      type: exception.reasonCode,
      typeName: isPartSeller
        ? getTranslatedExceptionNameByCode(
            exceptionMapperForDealer[exception.reasonCode],
          )
        : getTranslatedExceptionNameByCode(exception.reasonCode),
    };
  });

  //For Dealer view hide damage, recycle and back order exceptions
  const exceptionsForPartViewUserAndDealerView = isPartSeller
    ? formattedExceptions.filter(
        (exception) =>
          exception.type !== "DM" &&
          exception.type !== "RE" &&
          exception.type !== "BO",
      )
    : formattedExceptions;

  return (
    <React.Fragment>
      <PanelGroup collapsible>
        <PanelGroup.Header
          title={t("partview-details:Details")}
          rightContent={
            <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
              <Dropdown
                alignMenuRight
                variant="primary"
                show={showActionsDropdown}
                text={t("partview-details:Actions")}
                onToggle={(isShown, event) => {
                  event && event.originalEvent.stopPropagation();
                  setShowActionsDropdown(isShown);
                }}
                css={{
                  marginRight: 20,
                }}
              >
                <Dropdown.Item
                  onClick={() => setShowAlertMeModal(true)}
                  disabled={isSubscriptionLoading}
                >
                  <div css={{ display: "flex", flexDirection: "row" }}>
                    <Text css={{ flex: 1 }}>
                      {t("partview-details:Alert Me")}
                    </Text>
                    {isSubscriptionLoading ? (
                      <Icon src={faSpinner} size={FontSize.size20} spin />
                    ) : subscription ? (
                      <Text
                        italic
                        size={FontSize.size12}
                        color={Colors.highlight.GREEN}
                      >
                        ({t("partview-details:Active")})
                      </Text>
                    ) : null}
                  </div>
                </Dropdown.Item>

                {deliveryData ? (
                  <Dropdown.Item
                    onClick={() => setShowDeliverPackageModal(true)}
                    disabled={isPackageDeliveryEventLoading}
                  >
                    <div css={{ display: "flex", flexDirection: "row" }}>
                      <Text css={{ flex: 1 }}>
                        {t("partview-details:Package Delivered")}
                      </Text>
                    </div>
                  </Dropdown.Item>
                ) : null}
              </Dropdown>
              <WatchToggle
                id="watch"
                checked={watch ?? false}
                onChange={onWatchChange}
                onClick={(e) => {
                  //stop the event propagation else detail panel is also toggled since it is collapsible
                  e.stopPropagation();
                }}
                disabled={isSetWatchPackageLoading}
                labelText={t("partview-details:Watch this package")}
                tooltipText={t(
                  "partview-details:Add this package to your Watched Packages list on the PartView Homepage and receive notifications for new comments",
                )}
              />
            </div>
          }
        />
        <PanelGroup.Content>
          <FlexRowDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
            }}
          >
            <FlexColDiv css={{ flex: "1 1", marginRight: "1em" }}>
              <DetailPanelGroupItem
                data-qa="text-package"
                label={t("partview-details:Package #")}
                labelStyle={detailLabelStyle}
              >
                {trackingNumber}
              </DetailPanelGroupItem>
              <DetailPanelGroupItem
                data-qa="text-package-type"
                label={t("partview-details:Type")}
                labelStyle={detailLabelStyle}
              >
                {type}
              </DetailPanelGroupItem>
              <DetailPanelGroupItem
                data-qa="text-package-trailer-number"
                label={t("partview-details:Trailer Equipment #")}
                labelStyle={detailLabelStyle}
              >
                {trailerEquipmentNumber}
              </DetailPanelGroupItem>
              <DetailPanelGroupItem
                data-qa="text-package-status"
                label={t("partview-details:Status")}
                labelStyle={detailLabelStyle}
              >
                {translateStatus(status, t)}
              </DetailPanelGroupItem>
              <DetailPanelGroupItem
                data-qa="text-package-weight"
                label={t("partview-details:Weight")}
                labelStyle={detailLabelStyle}
              >
                {weight}
                {_.isNumber(weight) && !_.isNil(weightUnits) ? " " : null}
                {weightUnits}
              </DetailPanelGroupItem>
              {currentPositionDetails?.currentLocationName ||
              currentPositionDetails?.longitude ||
              currentPositionDetails?.longitude ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "2",
                    flexWrap: "wrap",
                  }}
                >
                  <DetailPanelGroupItem
                    data-qa="text-package-location"
                    label={t("partview-details:Current Location")}
                    rowStyle={{ display: "flex", flexDirection: "col" }}
                  >
                    <div>
                      <Text style={{ marginLeft: ".5em" }}>
                        {currentPositionDetails?.status}
                      </Text>
                      <Icon
                        onClick={() => {
                          if (currentMapCoordinate) {
                            clearCoordinatesByType([
                              MapCoordinateType.CURRENT_LOCATION_PACKAGE,
                            ]);
                          } else {
                            addCoordinate(
                              MapCoordinateType.CURRENT_LOCATION_PACKAGE,
                              currentPositionDetails.latitude,
                              currentPositionDetails.longitude,
                              currentPositionDetails.datetime,
                              currentPositionDetails.ind,
                              {
                                PopUpDetails: {
                                  name: currentPositionDetails.currentLocationName,
                                  id: trackingNumber,
                                  type: MapCoordinateType.CURRENT_LOCATION_PACKAGE,
                                  City: currentPositionDetails.city,
                                  State: currentPositionDetails.state,
                                  Country: currentPositionDetails.country,
                                },
                              },
                            );
                          }
                        }}
                        src={faBox}
                        size={FontSize.size20}
                        // Change the color if the current map coord is being dispalyed.
                        // This is so the user knows its been toggled on or off.
                        color={
                          currentMapCoordinate
                            ? Colors.highlight.LIGHT_BLUE
                            : null
                        }
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    {currentPositionDetails?.status ===
                    CurrentLocationStatus.AT_LOCATION ? (
                      <Text style={{ marginLeft: ".5em" }}>
                        {currentPositionDetails?.currentLocationName}
                      </Text>
                    ) : null}
                  </DetailPanelGroupItem>

                  {currentPositionDetails?.source ? (
                    <DetailPanelGroupItem
                      data-qa="text-package-source"
                      label={t("partview-details:Source")}
                    >
                      {currentPositionDetails.source}
                    </DetailPanelGroupItem>
                  ) : null}
                </div>
              ) : null}
            </FlexColDiv>

            <FlexRowDiv
              css={{
                alignSelf: "flex-start",
                flexDirection: "column-reverse",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "row",
                },
              }}
            >
              <ExceptionsBox
                exceptions={exceptionsForPartViewUserAndDealerView}
                showCount={!isPartSeller}
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </PanelGroup.Content>
      </PanelGroup>
      <AlertMeModal
        show={showAlertMeModal}
        hide={() => setShowAlertMeModal(false)}
        subscribeeDetails={subscribeeDetails}
        isSubscriptionLoading={isSubscriptionLoading}
        subscriptionDetails={subscription}
        subscriptionRequestError={subscriptionRequestError}
        updateSubscription={updateSubscription}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
        isSubscriptionUpdating={isSubscriptionUpdating}
        subscriptionUpdateSuccess={subscriptionUpdateSuccess}
        subscriptionUpdateError={subscriptionUpdateError}
        context={subscriptionContext}
      />
      <DeliverPackageModal
        show={showDeliverPackageModal}
        hide={() => {
          setShowDeliverPackageModal(false);
          resetSetPackageDeliveryEvent();
          fetchPackageDetails(trackingNumber);
        }}
        deliveryData={deliveryData}
        setPackageDeliveryEvent={setPackageDeliveryEvent}
        isPackageDeliveryEventLoading={isPackageDeliveryEventLoading}
        packageDeliveryEventRequestError={packageDeliveryEventRequestError}
        packageDeliveryEventRequestSuccess={packageDeliveryEventRequestSuccess}
      />
    </React.Fragment>
  );
};

PackageDetailsPanelGroup.propTypes = {
  trackingNumber: PropTypes.string,
  type: PropTypes.string,
  trailerEquipmentNumber: PropTypes.string,
  status: PropTypes.string,
  weight: PropTypes.number,
  weightUnits: PropTypes.string,
  exceptions: PropTypes.array,
  watch: PropTypes.bool,
  isSetWatchPackageLoading: PropTypes.bool,
  onWatchChange: PropTypes.func,
  subscribeeDetails: PropTypes.object,
  subscription: PropTypes.object,
  isSubscriptionLoading: PropTypes.bool,
  subscriptionRequestError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  subscriptionContext: PropTypes.shape({
    parts: PropTypes.arrayOf(PropTypes.string),
    package_type: PropTypes.string,
  }),

  //package delivery
  deliveryData: PropTypes.object,
  fetchPackageDetails: PropTypes.func,
  setPackageDeliveryEvent: PropTypes.func,
  resetSetPackageDeliveryEvent: PropTypes.func,
  isPackageDeliveryEventLoading: PropTypes.bool,
  packageDeliveryEventRequestError: PropTypes.bool,
  packageDeliveryEventRequestSuccess: PropTypes.bool,
  // Map
  currentPositionDetails: PropTypes.object,
  addCoordinate: PropTypes.func.isRequired,
  currentMapCoordinate: PropTypes.shape({
    coordinateType: PropTypes.string,
    data: PropTypes.object,
    floor: PropTypes.string,
    ind: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    radius: PropTypes.string,
    time: PropTypes.string,
  }),
  clearCoordinatesByType: PropTypes.func.isRequired,
  isPartSeller: PropTypes.bool,
};
