import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrganizations,
  getOrganizationByDbId,
  getOrganizationByFvId,
} from "modules/organizations/OrganizationsState";
import { useGetLatest } from "components/hooks/useGetLatest";

type UseOrganizationsProps = {
  ids?: number[];
  fvIds?: string[];
};

/**
 * A hook to fetch organizations by DB or FV IDs.
 *
 * @example
 * const { getOrganizationByDbId } = useOrganizations({ ids: [shipment.creator_org_id] });
 * const org = getOrganizationByDbId(shipment.creator_org_id);
 *
 * @param props
 * @returns
 */
export const useOrganizations = (props: UseOrganizationsProps = {}) => {
  const ids = props.ids ?? [];
  const fvIds = props.fvIds ?? [];

  const dispatch = useDispatch();

  // Create functions to get the lastest value of each array.
  // The function references do not change between renders and will not cause an effect to run.
  const getLatestIds = useGetLatest(ids.filter((id) => id).map(Number));
  const getLatestFvIds = useGetLatest(fvIds.filter((id) => id).map(String));

  // String versions of each array.
  // Used as an effect dependency for it to run when the lists change.
  // See Option 3: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  // - In this example, we know our data structure is relatively shallow, doesn't have cycles, and
  //   is easily serializable (e.g. because we plan to send that data structure over the network as
  //   a request). It doesn't have functions or weird objects like Dates or deeply nested. In these
  //   rare cases it's acceptable to pass [JSON.stringify(variables)] as a dependency.
  const stringifiedIds = JSON.stringify(ids);
  const stringifiedFvIds = JSON.stringify(fvIds);

  useEffect(() => {
    const ids = getLatestIds();
    const fvIds = getLatestFvIds();
    if (ids.length || fvIds.length) {
      dispatch(fetchOrganizations({ ids, fvIds }));
    }
  }, [
    dispatch,
    getLatestIds,
    getLatestFvIds,
    stringifiedIds,
    stringifiedFvIds,
  ]);

  const orgsByDbId = useSelector(getOrganizationByDbId(getLatestIds()));
  const orgsByFvId = useSelector(getOrganizationByFvId(getLatestFvIds()));

  return {
    getOrganizationByDbId: useCallback(
      (id: number) => {
        return orgsByDbId.organizations.find(
          (org) => org.organization_id === Number(id),
        );
      },
      [orgsByDbId.organizations],
    ),
    getOrganizationByFvId: useCallback(
      (id: string) => {
        return orgsByFvId.organizations.find((org) => org.fv_id === String(id));
      },
      [orgsByFvId.organizations],
    ),
    isLoading: orgsByDbId.isLoading || orgsByFvId.isLoading,
  };
};
