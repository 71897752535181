/**
 * We needed to wire another SearchBar container because one specific thing:
 * for this screen, just the results that are showed on the screen can be shown
 * as search typeahead options.
 */
import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "../mt-search/MetalTrackingSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import MetalTrackingSearchBarState from "../mt-search/MetalTrackingSearchBarState";
import MetalTrackingDomainDataState from "../domain-data/MetalTrackingDomainData";

const buildRackLocationFilteredOptionsSelector = (property) => {
  return (state) => {
    let results = MetalTrackingSearchBarState.selectors.getSearchResults(state);
    results = results.map((item) => {
      return { label: item[property], value: item[property] };
    });
    return results;
  };
};

// Special selectors filtering by just rackLocation selected
const getSerialNumbers = buildRackLocationFilteredOptionsSelector("id");
const getLocations = buildRackLocationFilteredOptionsSelector("rackLocation");
const getTagIds = buildRackLocationFilteredOptionsSelector("tagId");
const getCspcs = buildRackLocationFilteredOptionsSelector("cspcId");
const getDescriptions = buildRackLocationFilteredOptionsSelector("description");

const mapStateToProps = (state) => {
  const { getSearchText, getSearchCategory, getTypeaheadOptionsMetadata } =
    SearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state),
    serialNumbers: getSerialNumbers(state),
    locations: getLocations(state),
    descriptions: getDescriptions(state),
    tagIds: getTagIds(state),
    cspcs: getCspcs(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text) => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => dispatch(searchEntities(solutionId)),
    fetchDomainData: (solutionId) =>
      dispatch(
        MetalTrackingDomainDataState.actionCreators.fetchDomainData(solutionId),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
