/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import logo from "assets/logos/fv_logo.svg";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

export const SessionEnded = () => {
  const { t } = useTranslation("appnav");
  useSetTitleOnMount(t("appnav:Session Ended"));
  useTrackWithMixpanelOnce("Viewed Page: Session Ended");

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Icon type={IconType.LocalImage} src={logo} style={{ height: "6em" }} />
      <div
        css={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ddd",
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 20,
        }}
      >
        <Text bold size={FontSize.size28}>
          {t("appnav:Session Ended")}
        </Text>
        <Text>
          {t("appnav:You have been logged out. Please close this browser tab.")}
        </Text>
      </div>
    </div>
  );
};

SessionEnded.propTypes = {};
