/**
 * Google Maps Shapes Implementation
 */
import React from "react";
import polylabel from "polylabel";
import { renderToString } from "react-dom/server";

import ShapesPlatformInterface, {
  MARKER_PARAMS,
} from "../ShapesPlatformInterface";

import MouseMarkerIcon from "../../../../assets/geofence-mouse-marker.svg";
import FirstMarkerIcon from "../../../../assets/geofence-marker-0.svg";
import MarkerIcon from "../../../../assets/geofence-marker.svg";
import createHTMLMapMarker from "../../widgets/HTMLMapMarker";

import Colors from "../../../../styles/colors";

const { fillColor, strokeColor } = Colors.geofence;

class GoogleMapsShapes extends ShapesPlatformInterface {
  createGroup(objects = [], id = null) {
    const group = {};
    if (objects) {
      group.objects = objects;
    }
    if (id) {
      group.data = { groupId: id };
    }
    return group;
  }

  createCircle(
    latitude,
    longitude,
    fillColor,
    strokeColor,
    radiusMeters,
    draggable,
    selected,
  ) {
    return new this.api.Circle({
      fillColor: fillColor,
      fillOpacity: 1,
      strokeColor: strokeColor,
      radius: radiusMeters,
      center: new this.api.LatLng(latitude, longitude),
      draggable: draggable,
      clickable: draggable,
    });
  }

  createPolygonDrawingDraft(
    locationId,
    polygonIndex,
    mouseGeoCoords,
    tracePoints,
    onPolygonDrawEnd,
  ) {
    if (!mouseGeoCoords) {
      return null;
    }

    const groupId = `tracing:geofence:${locationId}:polyline`;
    const objects = this._generateDrawingMarkersFromTracePoints(
      tracePoints,
      groupId,
      polygonIndex,
      onPolygonDrawEnd,
    );

    // Draw a line connecting all the points in the trace
    if (tracePoints.length > 1) {
      let googleCoords = [];
      tracePoints.forEach((tracePoint) => {
        googleCoords.push(new this.api.LatLng(tracePoint[1], tracePoint[0]));
      });
      objects.push(
        new this.api.Polyline({
          path: googleCoords,
          strokeColor: strokeColor,
          fillColor: fillColor,
          clickable: false,
        }),
      );

      // Draw a transparent polygon signaling the area that will be covered by
      // the current trace.
      objects.push(
        new this.api.Polygon({
          path: tracePoints.map(
            (item) => new this.api.LatLng(item[1], item[0]),
          ),
          strokeColor: strokeColor,
          fillColor: fillColor,
          fillOpacity: 1,
          clickable: false,
        }),
      );
    }

    return this.createGroup(objects, groupId);
  }

  createTracingDraft(locationId, mouseGeoCoords, tracePoints) {
    if (!mouseGeoCoords) {
      return null;
    }

    const groupId = `tracing:geofence:${locationId}:tracing`;
    const objects = [];

    // Add the mouse marker position.
    const mouseMarker = createHTMLMapMarker({
      latlng: new this.api.LatLng(mouseGeoCoords.lat, mouseGeoCoords.lng),
      html: renderToString(<img alt="Mouse Marker" src={MouseMarkerIcon} />),
      data: { bnewtId: `${groupId}:mouseMarker` },
      isClickable: false,
      scaleX: 1.0,
      scaleY: 1.0,
      offsetX: MARKER_PARAMS.w / 2,
      offsetY: MARKER_PARAMS.h / 2,
    });
    objects.push(mouseMarker);

    // Draw a line between the current mouse position and the last point in
    // the trace.
    if (tracePoints.length > 0) {
      const mouseCursorPolyLine = [
        {
          lat: tracePoints[tracePoints.length - 1][1],
          lng: tracePoints[tracePoints.length - 1][0],
        },
        {
          lat: mouseGeoCoords.lat,
          lng: mouseGeoCoords.lng,
        },
      ];

      // To create a dashed line
      let lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };

      objects.push(
        new this.api.Polyline({
          path: mouseCursorPolyLine,
          strokeColor: strokeColor,
          strokeOpacity: 0,
          fillColor: fillColor,
          //strokeWeight: 4,
          clickable: false,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
        }),
      );
    }
    return this.createGroup(objects, groupId);
  }

  createPolygon(
    points,
    locationId,
    index,
    draggable,
    selected,
    isShowingLabel,
    svg = null,
  ) {
    const groupId = `geofence:${locationId}:group:${index}`;
    const polygonId = `${groupId}:poly`;

    const objects = [];
    const coords = points;

    // Convert paths to google lat/lng
    const googlePaths = coords.map((path) => {
      return {
        lat: path[1],
        lng: path[0],
      };
    });

    const geofencePoly = new this.api.Polygon({
      paths: googlePaths,
      draggable: draggable,
      editable: draggable,
      clickable: draggable,
      strokeColor: selected ? null : strokeColor,
      fillColor: selected ? null : fillColor,
      fillOpacity: 1,
    });

    geofencePoly.data = {
      groupId: groupId,
      bnewtId: polygonId,
      polygonIndex: index,
    };

    objects.push(geofencePoly);

    if (isShowingLabel) {
      const [labelLng, labelLat] = polylabel([coords]);
      objects.push(this.createMarker(labelLat, labelLng, groupId, index, svg));
    }
    return this.createGroup(objects, groupId);
  }

  createDrawingMarker(
    latitude,
    longitude,
    id,
    polygonIndex,
    index,
    draggable = true,
    onPolygonDrawEnd = null,
  ) {
    let iconSize, iconSrc;
    // If tracing the first point will be clicked to complete the polygon, make
    // it bigger to increase its hitbox size.
    if (id.includes("tracing") && index === 0) {
      iconSrc = FirstMarkerIcon;
      iconSize = { h: MARKER_PARAMS.h * 1.5, w: MARKER_PARAMS.w * 1.5 };
    } else {
      iconSrc = MarkerIcon;
      iconSize = { h: MARKER_PARAMS.h, w: MARKER_PARAMS.w };
    }

    const marker = createHTMLMapMarker({
      latlng: new this.api.LatLng(latitude, longitude),
      html: renderToString(<img alt="Control Point Marker" src={iconSrc} />),
      data: {
        groupId: id,
        // Global identifier for the control marker in the map objects
        // dictionary.
        bnewtId: `${id}:${index}`,
        // These two represent the indeces in
        // geofence.geometry.coordinates[polygonIndex][pointIndex]
        // to quickly identify which point the user is interacting with.
        // It's duplicate information since we already store this in the
        // bnewtId but dict retrieval is faster than getting the indeces from
        // the id.
        polygonIndex: polygonIndex,
        pointIndex: index,
      },
      isClickable: index === 0 ? true : false,
      scaleX: 1.0,
      scaleY: 1.0,
      offsetX: iconSize.w / 2,
      offsetY: iconSize.h / 2,
    });

    marker.addListener("click", (event, googleMapsMarker) => {
      const m = googleMapsMarker ? googleMapsMarker : event.target;
      const data = m.getData();
      if (data.pointIndex === 0) {
        if (onPolygonDrawEnd) {
          onPolygonDrawEnd();
        }
      }
    });

    return marker;
  }

  createMarker(
    latitude,
    longitude,
    id,
    index,
    svg = null,
    width = 48,
    height = 48,
  ) {
    return createHTMLMapMarker({
      latlng: new this.api.LatLng(latitude, longitude),
      html: svg,
      data: {
        groupId: id,
        // Global identifier for the marker in the map objects dictionary.
        bnewtId: `${id}:${index}:label`,
        polygonIndex: index,
      },
      isClickable: false,
      scaleX: 1.0,
      scaleY: 1.0,
      offsetX: width / 2,
      offsetY: height / 2,
    });
  }
}

export default GoogleMapsShapes;
