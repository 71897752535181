/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "components/atoms/Icon.atom";
import DwellTimeCell from "components/organisms/base-table/Cell/DwellTimeCell";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";

export const useTableColumns = (
  setVinDetails,
  fetchLocation,
  setWatchEntity,
  toggleWatch,
  solutionId,
  fetchWatchedVins,
  unwatchedVins,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      Header: t("inventory-view:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const vin = cellInfo.row.original.id;
        const internal_id = cellInfo.row.original.internal_id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={vin}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              setWatchEntity(solutionId, vin, internal_id, newWatchValue);
              toggleWatch(vin, fetchWatchedVins);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("inventory-view:VIN"),
      accessor: (obj) => {
        return {
          id: obj?.id ?? "",
          productType: obj?.description ?? "",
          isShippable: obj?.shippable ?? false,
          unwatchedVins,
        };
      },
      width: 200,
      Cell: VinCell,
    },
    {
      Header: t("inventory-view:Current Location Geofence"),
      accessor: (obj) => {
        return {
          locationData: obj?.locationData?.current ?? {},
          geofenceName: obj?.geofenceName ?? "",
          id: obj?.id ?? "",
          lastPosition: obj?.lastPositionUpdate ?? null,
          setVinDetails,
          fetchLocation,
        };
      },
      width: 150,
      Cell: CurrentLocationCell,
    },
    {
      Header: t("inventory-view:Dwell Timer"),
      accessor: (obj) => {
        return {
          dwellTimer: obj?.currentDwell ?? {},
          currentAge: obj?.currentAge ?? null,
        };
      },
      width: 200,
      Cell: DwellTimerCell,
    },
  ];
};

const VinCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { id, productType, isShippable, unwatchedVins } = props.value;
  const dim = unwatchedVins.includes(props.row.original.id);
  // Render unchecked VIN rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);

  return (
    <div
      style={{
        opacity: cellOpacity(dim),
      }}
    >
      <Text block size={FontSize.size14}>
        {id}
      </Text>
      <Text block size={FontSize.size14}>
        {productType}
      </Text>
      <Text
        bold
        block
        size={FontSize.size14}
        color={isShippable ? Colors.highlight.GREEN : Colors.highlight.RED}
      >
        {isShippable
          ? t("inventory-view:Shippable")
          : t("inventory-view:Non-Shippable")}
      </Text>
    </div>
  );
};

const CurrentLocationCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const {
    locationData,
    geofenceName,
    id,
    setVinDetails,
    lastPosition,
    fetchLocation,
  } = props.value;
  const onClick = (e) => {
    e.stopPropagation();
    setVinDetails({ id, lastPosition });
    if (locationData.id) {
      fetchLocation(locationData.id);
    }
  };

  return (
    <div>
      {!_.isEmpty(locationData) && (locationData.name || locationData.code) ? (
        <div>
          <div>
            {t("inventory-view:At Location")}
            <div css={{ display: "inline-block", paddingLeft: "10px" }}>
              <Icon
                src={faMapMarkedAlt}
                style={{ color: Colors.highlight.LIGHT_BLUE }}
                onClick={onClick}
              />
            </div>
          </div>
          <div>
            {locationData.name} {locationData.code ? " - " : ""}
            {locationData.code}
          </div>
          <div> {geofenceName}</div>
        </div>
      ) : null}
    </div>
  );
};

const DwellTimerCell = (props) => {
  const { dwellTimer, currentAge } = props.value;
  const { t } = useTranslation("inventory-view");
  const { dwellTimeSeconds } = dwellTimer;
  const isWarning = currentAge && currentAge > 0 ? true : false;
  const textColor = dwellTimeSeconds
    ? isWarning
      ? Colors.holds.RED
      : Colors.holds.LIGHT_GREEN
    : Colors.text.LIGHT_GRAY;
  return (
    <div>
      <Text block size={FontSize.size16} color={textColor}>
        {dwellTimeSeconds
          ? isWarning
            ? t("inventory-view:Aging")
            : t("inventory-view:Dwelling")
          : null}
      </Text>
      <DwellTimeCell currentDwell={dwellTimer} currentAge={currentAge} />
    </div>
  );
};
