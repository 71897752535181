/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { ReportSubFolderDataTable } from "pages/reports/bi-dashboard/components/ReportSubFolderDataTable";

export const ExpanderTreeSubcomponent = (
  row,
  appliedFilters,
  sortColumn,
  reverseSort,
  getSubFolderByQualifier,
  fetchSubFolderData,
  pushReportDetailScreen,
  pushFilteredReportDetailScreen,
) => {
  const rowRef = row.row;
  const directoryId = rowRef.original.directory?.directoryId;
  const reportType = rowRef.values["user-actions"].reportType;
  const hasManageSharedReportsPrivilege =
    rowRef.values["user-actions"].hasManageSharedReportsPrivilege;
  const hasReportBuilderPrivilege =
    rowRef.values["user-actions"].hasReportBuilderPrivilege;
  const hasManageReportDirectory =
    rowRef.values["user-actions"].hasManageReportDirectory;
  const actionHandler = rowRef.values["user-actions"].actionHandler;
  const isFVAdmin = rowRef.values["user-actions"].isFVAdmin;

  return (
    <ReportSubFolderDataTable
      directoryId={directoryId}
      fetchSubFolderData={fetchSubFolderData}
      getSubFolderByQualifier={getSubFolderByQualifier}
      reportType={reportType}
      pushReportDetailScreen={pushReportDetailScreen}
      pushFilteredReportDetailScreen={pushFilteredReportDetailScreen}
      hasManageSharedReportsPrivilege={hasManageSharedReportsPrivilege}
      hasReportBuilderPrivilege={hasReportBuilderPrivilege}
      hasManageReportDirectory={hasManageReportDirectory}
      actionHandler={actionHandler}
      isFVAdmin={isFVAdmin}
      appliedFilters={appliedFilters.current}
      sortColumn={sortColumn}
      reverseSort={reverseSort}
    />
  );
};

ExpanderTreeSubcomponent.propTypes = {
  row: PropTypes.object,
  appliedFilters: PropTypes.object,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  getSubFolderByQualifier: PropTypes.func,
  fetchSubFolderData: PropTypes.func,
  pushReportDetailScreen: PropTypes.func,
  pushFilteredReportDetailScreen: PropTypes.func,
};
