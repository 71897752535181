/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { convertCountToLocaleString } from "utils/html-utils";
import Colors from "styles/colors";

const TotalsRow = ({ count, rowClickHandler }) => {
  const { t } = useTranslation("components");

  const cellStyle = {
    boxSizing: "border-box",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: ".5rem",
    overflow: "hidden",
  };

  return (
    <div
      css={{
        display: "flex",
        flex: "1 0 auto",
        ":hover": {
          cursor: "pointer",
          background: Colors.background.YELLOW_MOUSEOVER,
        },
      }}
      onClick={() => rowClickHandler()}
    >
      <div
        css={{
          ...cellStyle,
          paddingLeft: "1.7rem",
          flex: "150 0 auto",
          width: 150,
        }}
      >
        {t("components:Total")}
      </div>
      <div css={{ ...cellStyle, flex: "70 0 auto", width: 70 }}>
        {convertCountToLocaleString(count)}
      </div>
    </div>
  );
};

TotalsRow.propTypes = {
  count: PropTypes.number,
  rowClickHandler: PropTypes.func,
};

export const PartViewEtaTableWidget = ({
  title,
  postTitleIcon,
  postTitleIconTooltip,
  subHeaderContent,
  columns,
  loaded,
  chartData,
  rowClickHandler,
  className,
}) => {
  return (
    <PanelGroup className={className}>
      <PanelGroup.Header
        title={title}
        postTitleIcon={postTitleIcon}
        postTitleIconTooltip={postTitleIconTooltip}
      />
      {subHeaderContent ? (
        <PanelGroup.SubHeader>{subHeaderContent}</PanelGroup.SubHeader>
      ) : null}
      <PanelGroup.Content
        style={{
          position: "relative",
          paddingTop: 0,
          minHeight: 290,
        }}
      >
        <Loader loaded={loaded}>
          <BaseTable
            theme={Themes.LIGHT}
            data={chartData}
            columns={columns}
            pageSize={chartData?.length}
            disableSortBy={true}
            rowClickHandler={rowClickHandler}
          />
          <TotalsRow
            count={_.sumBy(chartData, "count")}
            rowClickHandler={rowClickHandler}
          />
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const EtaTableWidgetPropTypes = {
  //title: PropTypes.string,
  postTitleIcon: PropTypes.object,
  postTitleIconTooltip: PropTypes.string,
  subHeaderContent: PropTypes.node,
  columns: PropTypes.array,
  loaded: PropTypes.bool,
  chartData: PropTypes.array,
  rowClickHandler: PropTypes.func,
  className: PropTypes.string,
  //

  /** Title for the exception panel. */
  title: PropTypes.string.isRequired,
};

PartViewEtaTableWidget.propTypes = EtaTableWidgetPropTypes;
