import ApplicationConfig from "application-config";
import mixpanelLib from "mixpanel-browser";
import { useEffect } from "react";

let mixpanel = null;

/**
 * Mixpanel needs to be Initialized before it can be used.
 * @return {boolean}
 */
export function initMixpanel() {
  if (!ApplicationConfig.MIXPANEL_TOKEN) {
    return false;
  }

  mixpanelLib.init(ApplicationConfig.MIXPANEL_TOKEN, {
    debug: false,
    track_pageview: false,
    loaded: function (loadedMixpanel) {
      mixpanel = loadedMixpanel;
    },
  });
}

/**
 *
 */
export default class MixpanelUtils {
  /**
   *
   */
  static isMixpanelEnabled() {
    if (!mixpanel) {
      return false;
    }

    return true;
  }

  /**
   *
   * @param values
   * @returns {boolean}
   * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#section-mixpanel-register
   */
  static register(values = {}) {
    if (!mixpanel) {
      return false;
    }

    mixpanel.register(values);
  }

  /**
   *
   * @param event
   * @param properties
   */
  static track(event, properties = {}) {
    if (!this.isMixpanelEnabled()) {
      return false;
    }

    mixpanel.track(event, properties);
  }

  /**
   *
   * @param id
   * @param properties
   * @returns {boolean}
   * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#section-mixpanel-identify
   */
  static identify(id, properties) {
    if (!this.isMixpanelEnabled()) {
      return false;
    }

    mixpanel.identify(id);
    mixpanel.people.set(properties);
  }

  /**
   *
   * @param id
   * @param properties
   * @returns {boolean}
   * @see https://developer.mixpanel.com/docs/javascript-full-api-reference#section-mixpanel-identify
   */
  static alias(id, properties) {
    if (!this.isMixpanelEnabled()) {
      return false;
    }

    mixpanel.alias(id);
    mixpanel.people.set(properties);
  }
}

/**
 * Hooks
 */
export const useTrackWithMixpanelOnce = (event, properties = {}) => {
  return useEffect(() => {
    MixpanelUtils.track(event, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
