import moment from "moment";
import apiUrl from "api-url";
import axios from "axios";
import { createMutationRequestHook } from "shared/hooks/createMutationRequestHook";
import { BaseErrorResponse } from "api/responses";
import { z } from "zod";

const vinUpdateToolResponseSchema = z.object({
  status: z.string(),
  vins_and_reasons: z.array(
    z.object({
      vin: z.string(),
      reason: z.string(),
    }),
  ),
});
type OverrideCurrentLocationResponse = z.infer<
  typeof vinUpdateToolResponseSchema
>;

const currentLocationOverridePayloadSchema = z.object({
  vin: z.string().optional(),
  locationCode: z.string().optional(),
  csvData: z.string().array().optional(),
});
type CurrentLocationOverridePayloadSchema = z.infer<
  typeof currentLocationOverridePayloadSchema
>;

type CurrentLocationOverrideMutationVariables = {
  solutionId: string;
  payload: CurrentLocationOverridePayloadSchema;
  isBulkUpload: boolean;
};

export const useOverrideLocationMutation = createMutationRequestHook<
  OverrideCurrentLocationResponse,
  BaseErrorResponse,
  CurrentLocationOverrideMutationVariables
>({
  fetch: async ({ solutionId, payload, isBulkUpload }) => {
    const headers = {
      "x-time-zone": moment.tz.guess(),
      "Content-Type": isBulkUpload ? "text/csv" : "application/json",
    };

    const config = { headers };

    const url = apiUrl(
      `/entity/solution/${solutionId}/current-location-override`,
    );

    return await axios.post(url, payload, config).then((res) => res.data);
  },
});
