/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormGroup, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";

import { Button } from "components/atoms/Button.atom";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import SelectInput from "components-old/forms/inputs/SelectInput";

import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { Separator } from "components/atoms/Separator.atom";
import { SeparatorOrientation } from "components/atoms/enums";

export const BatchCommentModal = ({
  show,
  hide,
  addBatchComments,
  clearBatchComments,
  shareableOrg,
  shareableText,
  shareableTooltipText,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  batchCsvExample,
  batchImportLabel,
  shippers,
  shipperOrgId,
}) => {
  const { t } = useTranslation("components");

  const [importFile, setImportFile] = useState(null);
  const [importFileCsvData, setImportFileCsvData] = useState("");
  const [comment, setComment] = useState("");
  const [importIds, setImportIds] = useState("");
  const [isSharing, setIsSharing] = useState(false);

  // shippers pass in shipperOrgId, carriers set it using the shippers dropdown
  const [importShipperOrgId, setImportShipperOrgId] = useState(shipperOrgId);

  // for carriers only
  const shipperOptions =
    shippers?.map(({ shipper_organization_id, shipper_name }) => ({
      value: shipper_organization_id,
      label: shipper_name,
    })) ?? [];

  // Reset modal inputs when show changes
  useEffect(() => {
    setComment("");
    setImportIds("");
    setImportFile(null);
    setImportFileCsvData("");
    setIsSharing(false);
    setImportShipperOrgId(shipperOrgId);
  }, [show, shipperOrgId]);

  // Reset modal inputs when successful
  useEffect(() => {
    if (!isBatchCommentInProgress && isBatchCommentSuccessful) {
      setComment("");
      setImportIds("");
      setImportFile(null);
      setImportFileCsvData("");
      setIsSharing(false);
      setImportShipperOrgId(shipperOrgId);
    }
  }, [isBatchCommentInProgress, isBatchCommentSuccessful, shipperOrgId]);

  // take CSV example and display rows as tooltip
  const getTooltipDisplay = () => {
    const csvRows = batchCsvExample.data.map((data) =>
      Object.values(data).join(", "),
    );
    return (
      <Fragment>
        <Text>{`${t("components:The file must contain")}:`}</Text>
        <ul style={{ padding: 0, margin: 0, whiteSpace: "nowrap" }}>
          {csvRows.map((row, index) => (
            <li key={index}>{row}</li>
          ))}
        </ul>
      </Fragment>
    );
  };

  const canShare = shareableOrg ?? false;
  const canUpload =
    (importFile || (importIds?.length > 0 && comment?.length > 0)) &&
    (shipperOptions.length === 0 || importShipperOrgId);
  const canUseImportFile = importIds?.length === 0 && comment?.length === 0;
  const canUseImportTextAreas = !importFile;

  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  const getBatchData = () => {
    if (importFile) {
      return {
        csv: importFileCsvData,
        shareableOrg: shareableOrg?.organization_id,
        shipperOrgId: importShipperOrgId,
      };
    }
    return {
      ids: importIds.split(/\r\n|\n/),
      text: comment,
      sharedWith:
        isSharing && shareableOrg ? [shareableOrg.organization_id] : [],
      shipperOrgId: importShipperOrgId,
    };
  };

  return (
    <Modal backdrop={"static"} show={show} onHide={hide}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("components:Comments Batch Upload")}
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        {shipperOptions.length > 0 ? (
          <SelectInput
            label={t("components:Select Shipper")}
            options={shipperOptions}
            value={importShipperOrgId}
            onChange={(value) => setImportShipperOrgId(value)}
            css={{ paddingLeft: 0 }}
          />
        ) : null}

        <FlexRowDiv css={{ marginBottom: "1em" }}>
          <Text css={{ marginRight: 2 }}>
            {t("components:Select a CSV file to import")}.
          </Text>
          <Tooltip
            css={{ marginRight: 10 }}
            placement="bottom"
            tooltipChildren={getTooltipDisplay()}
          >
            <FaQuestionCircle css={{ marginBottom: 5 }} />
          </Tooltip>
          <DownloadCsvLink
            data={batchCsvExample.data}
            headers={batchCsvExample.headers}
            label={t("components:Download template (.csv)")}
            filename={"template-comment-data.csv"}
          />
        </FlexRowDiv>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <InputGroup>
            <label
              className="input-group-btn"
              css={{ paddingRight: "0.375rem" }}
            >
              <span
                className={`btn btn-light ${
                  !canUseImportFile ? "disabled" : ""
                }`}
              >
                {`${t("components:Browse")}`}
                <input
                  id="files"
                  type="file"
                  accept=".csv"
                  onChange={(event) => {
                    const reader = new FileReader();
                    reader.readAsText(event?.target?.files[0]);
                    reader.onload = onFileLoad;
                    setImportFile(event?.target?.files[0]);
                  }}
                  disabled={!canUseImportFile}
                  onClick={(event) => (event.target.value = "")}
                  data-qa="input-file-batch-modal"
                  hidden
                />
              </span>
            </label>
            <FormControl
              type="type"
              value={importFile?.name ?? ""}
              disabled={!canUseImportFile}
              placeholder={t("components:Select file")}
              htmlFor="files"
              style={{ zIndex: 0, borderRadius: "0.375rem" }}
              readOnly
            />
            {importFile ? (
              <span className="input-group-btn">
                <Button
                  variant="light"
                  onClick={() => {
                    setImportFile(null);
                    setImportFileCsvData("");
                  }}
                  data-qa="button-remove-file-batch-modal"
                  css={{ marginLeft: "0.25rem" }}
                >
                  <MdClose />
                </Button>
              </span>
            ) : null}
          </InputGroup>
        </FormGroup>

        <Separator
          orientation={SeparatorOrientation.HORIZONTAL}
          color={Colors.background.MID_GRAY}
          text={t("components:OR")}
        />

        <FormGroup css={{ marginBottom: "1rem" }}>
          <Text>
            {t("components:Enter a comment that needs to be mass uploaded")}:
          </Text>
          <FormControl
            as="textarea"
            value={comment}
            rows={5}
            onChange={(event) => setComment(event?.target?.value ?? "")}
            disabled={!canUseImportTextAreas}
            style={{ marginTop: "0.25rem" }}
          />
        </FormGroup>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <Text>
            {t("components:Enter [[[batchImportLabel]]]s (Line Separated)", {
              batchImportLabel,
            })}
            :
          </Text>
          <FormControl
            as="textarea"
            value={importIds}
            rows={5}
            onChange={(event) => setImportIds(event?.target?.value ?? "")}
            disabled={!canUseImportTextAreas}
            style={{ marginTop: "0.25rem" }}
          />
        </FormGroup>

        {canShare ? (
          <FlexRowDiv css={{ alignItems: "center" }}>
            <Checkbox
              checked={isSharing}
              onChange={(event) => setIsSharing(event ?? false)}
              disabled={!canUseImportTextAreas}
              disabledColor={Colors.text.DISABLED}
            />
            <Text
              onClick={() => {
                if (canUseImportTextAreas) {
                  return setIsSharing(!isSharing);
                }
              }}
              css={{
                marginRight: 3,
                ":hover": { cursor: "pointer" },
              }}
              color={
                !canUseImportTextAreas ? Colors.text.GRAY : Colors.text.BLACK
              }
            >
              {shareableText}
            </Text>
            <Tooltip placement="bottom" tooltipChildren={shareableTooltipText}>
              <FaQuestionCircle css={{ marginBottom: 3 }} />
            </Tooltip>
          </FlexRowDiv>
        ) : null}
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Alert
          css={{ width: "100%" }}
          variant={AlertVariant.Danger}
          show={!isBatchCommentInProgress && isBatchCommentFailed}
          dismissible
          onDismiss={() => clearBatchComments()}
        >
          {t("components:Unable to upload batch comments. Try again later.")}
        </Alert>
        <Alert
          css={{ width: "100%" }}
          variant={AlertVariant.Success}
          show={!isBatchCommentInProgress && isBatchCommentSuccessful}
          dismissible
          onDismiss={() => clearBatchComments()}
        >
          {t(
            "components:A notification will be sent when all comments have been processed.",
          )}
        </Alert>
        <FlexRowDiv>
          <Button
            variant="light"
            css={{ marginRight: "0.5em" }}
            onClick={hide}
            data-qa="button-cancel-batch-comment-modal"
          >
            {t("components:Close")}
          </Button>
          <Button
            variant="primary"
            disabled={!canUpload || isBatchCommentInProgress}
            pulse={canUpload && !isBatchCommentInProgress}
            onClick={() => {
              addBatchComments(getBatchData(), importFile ? true : false);
            }}
            data-qa="button-search-batch-comment-modal"
          >
            {t("components:Upload")}
            {isBatchCommentInProgress ? (
              <Icon css={{ marginLeft: "0.5em" }} src={faSpinner} spin />
            ) : null}
          </Button>
        </FlexRowDiv>
      </Modal.Footer>
    </Modal>
  );
};

BatchCommentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  shareableOrg: PropTypes.object,
  shareableText: PropTypes.string,
  shareableTooltipText: PropTypes.string,
  isBatchCommentInProgress: PropTypes.bool.isRequired,
  isBatchCommentSuccessful: PropTypes.bool.isRequired,
  isBatchCommentFailed: PropTypes.bool.isRequired,
  batchCsvExample: PropTypes.shape({
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
  }).isRequired,
  batchImportLabel: PropTypes.string,
  shippers: PropTypes.arrayOf(
    PropTypes.shape({
      shipper_organization_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      shipper_name: PropTypes.string,
      shipper_fv_id: PropTypes.string,
    }),
  ),
  shipperOrgId: PropTypes.number,
};
