/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { FaRegCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { FlexDiv } from "styles/container-elements";
import { FontSize, Text } from "components/atoms/Text.atom";

const FlexRow = styled(FlexDiv)({ width: "100%" });

export const PasswordChecks = ({
  isLengthValid,
  isPasswordsMatch,
  isRegexValid,
  doesPassContainEmailOrUsername,
}) => {
  const { t } = useTranslation("components");

  return (
    <div>
      <FlexRow style={{ marginBottom: ".25em" }}>
        <FaRegCheckCircle
          css={{
            color: isLengthValid ? Colors.highlight.GREEN : "#e0e0e0",
            fontSize: "large",
          }}
        />
        <Text fontSize={FontSize.size12} css={{ marginLeft: ".5em" }}>
          {t("components:Must be at least 8 characters long")}
        </Text>
      </FlexRow>
      <FlexRow>
        <FaRegCheckCircle
          css={{
            color: isRegexValid ? Colors.highlight.GREEN : "#e0e0e0",
            fontSize: "large",
          }}
        />
        <Text
          fontSize={FontSize.size12}
          css={{ marginLeft: ".5em", marginBottom: "-.6em" }}
        >
          {t(
            "components:Contains at least 3 of the following 4 types of characters",
          )}
          <ul>
            <li>{t("components:Lower case letters (a-z)")}</li>
            <li>{t("components:Upper case letters (A-Z)")}</li>
            <li>{t("components:Numbers (i.e. 0-9)")}</li>
            <li>{t("components:Special characters (e.g. !@#$%^&*)")}</li>
          </ul>
        </Text>
      </FlexRow>
      <FlexRow css={{ marginBottom: ".25em" }}>
        <FaRegCheckCircle
          css={{
            color: !doesPassContainEmailOrUsername
              ? Colors.highlight.GREEN
              : "#e0e0e0",
            fontSize: "large",
          }}
        />
        <Text fontSize={FontSize.size12} css={{ marginLeft: ".5em" }}>
          {t("components:Password does not contain email or username")}
        </Text>
      </FlexRow>
      <FlexRow css={{ marginBottom: "1em" }}>
        <FaRegCheckCircle
          css={{
            color: isPasswordsMatch ? Colors.highlight.GREEN : "#e0e0e0",
            fontSize: "large",
          }}
        />
        <Text fontSize={FontSize.size12} css={{ marginLeft: ".5em" }}>
          {t("components:Passwords match")}
        </Text>
      </FlexRow>
    </div>
  );
};

PasswordChecks.propTypes = {
  isLengthValid: PropTypes.bool,
  isPasswordsMatch: PropTypes.bool,
  isRegexValid: PropTypes.bool,
  doesPassContainEmailOrUsername: PropTypes.bool,
};
