import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const MOUNT_POINT = "partViewDeliveryStatusUpdate";

// Fetch ducks
const duck = buildFetchDuck(MOUNT_POINT);

export const fetchPVDeliveryStatusUpdate =
  (formData, filename) => async (dispatch) => {
    const url = apiUrl("/partview/api/batch-upload-delivered-packages");
    const config = {
      data: {
        data: formData,
        filename,
      },
      method: "post",
      headers: {
        Accept: "application/json;version=packages",
      },
    };
    dispatch(duck.fetch(url, config));
  };

function requestDataClear() {
  return (dispatch) => {
    dispatch(duck.clear());
  };
}

// Reducer
const freezePVDeliveryStatusUpdateReducer = duck.reducer;

// Complete State
export const PartViewDeliveryStatusUpdateState = {
  mountPoint: MOUNT_POINT,
  reducer: freezePVDeliveryStatusUpdateReducer,
  actionCreators: {
    fetchPVDeliveryStatusUpdate,
    requestDataClear,
  },
  selectors: { getRequestData: duck.selectors.getData },
};
