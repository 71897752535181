import _ from "lodash";

/**
 * value - an object of shape:
 * ```
 * {
 *   to: string,
 *   from: string,
 *   dateType: string[] // (possible values: "Scheduled", "Actual", "ETA")
 * }
 * ```
 */
export const isDateRangeValueValid = (value) => {
  // Value is not required.
  if (_.isNil(value)) {
    return true;
  }

  const hasDateType = value.dateType?.length > 0 ?? false;

  const hasDateValues = !_.isNil(value.to) || !_.isNil(value.from);

  if (!hasDateValues || !hasDateType) {
    return false;
  }

  return true;
};
