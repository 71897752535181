import _ from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";

import apiUrl from "api-url";
import ExportsState from "modules/exports/ExportsState";

const MOUNT_POINT = "notifications";

export const notificationUrl = (id) => {
  if (id) {
    return apiUrl(`/notification/${id}`);
  } else {
    return apiUrl(`/notification`);
  }
};

export const NotificationType = {
  EXPORT: "EXPORT",
  COMMENT: "COMMENT",
  UPDATE: "UPDATE",
  BATCH_COMMENT: "BATCH_COMMENT",
  BATCH_UPLOAD: "BATCH_UPLOAD",
};

// Actions
const ADD_NOTIFICATION = "Notifications/ADD_NOTIFICATION";
const UPDATE_NOTIFICATION = "Notifications/UPDATE_NOTIFICATION";
const SET_NOTIFICATION_READ = "Notifications/SET_NOTIFICATION_READ";
const SET_NOTIFICATION_UNREAD_COUNT =
  "Notifications/SET_NOTIFICATION_UNREAD_COUNT";

const START_FETCH_NOTIFICATIONS = "Notifications/START_FETCH_NOTIFICATIONS";
const SET_NOTIFICATION_RESULTS = "Notifications/SET_NOTIFICATION_RESULTS";
const TOGGLE_UNREAD_FILTER = "Notifications/TOGGLE_UNREAD_FILTER";

const { handleExportNotification } = ExportsState.actionCreators;

export const handleExports = (notificationData) => (dispatch) => {
  if (notificationData) {
    return notificationData.map((notification) => {
      if (notification?.type.toUpperCase() === NotificationType.EXPORT) {
        return dispatch(
          handleExportNotification(
            notification.notification_id,
            notification.context,
            notification.context.async_process_completed,
          ),
        );
      }
      return null;
    });
  }
};

const fetchNotification =
  (
    params = {
      pageSize: 10,
      refresh: false,
      page: null,
      id: null,
    },
  ) =>
  (dispatch) => {
    let page = params.page || 0;

    if (params.page) {
      page = params.page;
    }

    let pageSize = params.pageSize || 10;

    const qs = (url) => {
      let qsp = params.id ? "" : `?pageSize=${pageSize}&pageNumber=${page}`;
      return url + qsp;
    };

    dispatch({
      type: START_FETCH_NOTIFICATIONS,
    });

    // Request the async export
    return axios
      .get(qs(notificationUrl(params.id)), {
        headers: { Accept: "application/json;version=full" },
      })
      .then(async (response) => {
        if (response.data) {
          await dispatch(handleExports(response?.data?.data));
          dispatch(
            setNotificationResults(
              response?.data?.data,
              response?.data?.meta,
              params.refresh,
            ),
          );
          dispatch(
            setNotificationUnreadCount(response?.data?.meta?.unreadCount),
          );
        }
      });
  };

const postHasReadNotification =
  (params = { id: null, read: true }) =>
  (dispatch) => {
    const payload = {
      read: params.read,
    };
    return axios
      .patch(`${notificationUrl(params.id)}/read`, payload)
      .then(async (response) => {
        await dispatch(handleExports(response.data.data));
        dispatch(fetchNotification({ pageSize: 10, refresh: true }));
      });
  };

const setNotificationResults = (
  NotificationData,
  NotificationMeta = {},
  refresh = false,
) => {
  return {
    type: SET_NOTIFICATION_RESULTS,
    refresh: refresh,
    payload: {
      totalCount: NotificationMeta.totalCount,
      pageSize: NotificationMeta.pageSize,
      currentPage: NotificationMeta.currentPage,
      data: NotificationData,
      totalPages: NotificationMeta.totalPages,
    },
  };
};

const setNotificationUnreadCount = (unreadCount) => {
  return {
    type: SET_NOTIFICATION_UNREAD_COUNT,
    payload: {
      unreadCount,
    },
  };
};

const fetchFailedBatchCommentsOrUploads =
  (url, filename) => (dispatch, getState) => {
    let fileName = filename ? filename : "failed-batch-comments.csv";
    if (!url) {
      throw new Error("fetchFailedBatchComments requires 'url'");
    }

    const noInterceptorsAxios = axios.create();
    return noInterceptorsAxios
      .get(url)
      .then((resp) => {
        fileDownload(resp.data, fileName);
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Selectors
const getIsLoadingNotifications = (state) => state.notifications.isLoading;

const getNotificationList = (state) => {
  const notificationsMap = state[MOUNT_POINT].data;
  const showUnreadOnly = state[MOUNT_POINT].showUnreadOnly;
  const list = [];
  _.forEach(notificationsMap, (notification, key) => {
    list.push({ ...notification, id: key });
  });
  const filteredList = showUnreadOnly
    ? list.filter((notification) => !notification.read)
    : list;
  // re-sorting list by timestamp and read.
  return _.orderBy(
    _.orderBy(_.uniq(filteredList, "id"), "eventTs", "desc"),
    "read",
    "asc",
  );
};

const getNotification = (id) => (state) => {
  return state[MOUNT_POINT].data[id];
};
const getNotificationUnreadCount = (state) => {
  return state[MOUNT_POINT].unreadCount || 0;
};

const getHasUnreadNotifications = (state) => {
  let hasUnreads = false;
  let unReadCount = getNotificationUnreadCount(state);
  if (unReadCount) {
    hasUnreads = true;
  }
  return hasUnreads;
};

const getNotificationMeta = (state) => {
  return state[MOUNT_POINT]?.meta || {};
};

const getUnreadFilter = (state) => {
  return state[MOUNT_POINT]?.showUnreadOnly ?? false;
};

const toggleUnreadFilter = () => {
  return {
    type: TOGGLE_UNREAD_FILTER,
  };
};

// Reducer

// reducer to handle the state of a single notification
function singleNotificationReducer(state = {}, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...action.payload,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case SET_NOTIFICATION_READ:
      return {
        ...state,
        hasRead: action.payload.hasRead,
      };
    default:
      return state;
  }
}
function uniqueByKey(array, key) {
  return [...new Map(array.map((x) => [x[key], x])).values()];
}

const initialState = {
  showUnreadOnly: false,
};

// reducer to handle the map of all notifications
function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        [action.payload.notificationId]: singleNotificationReducer(
          null,
          action,
        ),
      };
    case START_FETCH_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_NOTIFICATION:
    case SET_NOTIFICATION_RESULTS:
      if (!action.refresh) {
        return {
          ...state,
          isLoading: false,
          data: uniqueByKey(_.union(state.data, action.payload.data), "id"),
          meta: {
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          data: action.payload.data,
          meta: {
            pageSize: action.payload.pageSize,
            totalCount: action.payload.totalCount,
            currentPage: action.payload.currentPage,
          },
        };
      }

    case SET_NOTIFICATION_READ:
      return {
        ...state,
        [action.payload.notificationId]: singleNotificationReducer(
          state[action.payload.notificationId],
          action,
        ),
      };
    case SET_NOTIFICATION_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload.unreadCount || 0,
      };

    case TOGGLE_UNREAD_FILTER:
      return {
        ...state,
        showUnreadOnly: !state.showUnreadOnly,
      };

    default:
      return state;
  }
}

const NotificationsState = {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    setNotificationUnreadCount,
    fetchNotification,
    postHasReadNotification,
    setNotificationResults,
    fetchFailedBatchCommentsOrUploads,
    toggleUnreadFilter,
  },
  selectors: {
    getIsLoadingNotifications,
    getNotificationMeta,
    getNotificationList,
    getNotification,
    getHasUnreadNotifications,
    getNotificationUnreadCount,
    getUnreadFilter,
  },
  reducer: notificationsReducer,
};

export default NotificationsState;
