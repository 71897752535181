/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import debouncePromise from "debounce-promise";
import React, { useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import {
  ReportTypes,
  getColumns,
  treeStartColumn,
} from "pages/reports/search/Reports.columns";
import { ExpanderTreeSubcomponent } from "pages/reports/bi-dashboard/components/ExpanderTreeSubcomponent";
import { expandedReport } from "pages/reports/utils/report.utils";
import ReportSchedulesState from "pages/reports/redux/ReportSchedulesState";

const { getSchedulesKey } = ReportSchedulesState.helpers;

export const ReportsTable = ({
  reportSchedules,
  solutionId,
  type,
  tableFilterMap,
  filtersRef,
  isFVAdmin,
  actionHandler,
  hasReportBuilderPrivilege,
  hasManageSharedReportsPrivilege,
  hasManageReportDirectory,
  reportsResults,
  reportsIsLoading,
  page,
  pageSize,
  totalPages,
  reportsDefaultSortColumn,
  reportsDefaultReverseSort,
  reportsSearchFilters,
  setSearchFilter,
  clearSearchText,
  clearSearchFilters,
  clearSearchFilter,
  searchReports,
  setSort,
  setPagination,
  reportsSortColumn,
  reportsReverseSort,
  getSubFolderByQualifier,
  fetchSubFolderData,
  pushReportDetailScreen,
  pushFilteredReportDetailScreen,
  closeModal,
  setFolderCategory,
}) => {
  const { t } = useTranslation("reports");

  useEffect(() => {
    searchReports(solutionId);
    clearSearchText();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    closeModal();
    setFolderCategory(null);
    //eslint-disable-next-line
  }, [reportsResults]);

  const applyTableFilters = useCallback(() => {
    const tableFilters = [];

    Object.keys(tableFilterMap).forEach((tableAccessor) => {
      const apiParam = tableFilterMap[tableAccessor];
      let apiValue = reportsSearchFilters[apiParam];

      if (apiValue) {
        tableFilters.push({
          id: tableAccessor,
          value: apiValue,
        });
      }
    });
    filtersRef.current = tableFilters;
  }, [reportsSearchFilters, filtersRef, tableFilterMap]);

  useEffect(() => {
    applyTableFilters();
  }, [reportsSearchFilters, applyTableFilters]);

  const reportsColumns = useMemo(
    () =>
      getColumns(
        t,
        type,
        hasReportBuilderPrivilege,
        hasManageSharedReportsPrivilege,
        hasManageReportDirectory,
        isFVAdmin,
        actionHandler,
      ),
    [
      t,
      type,
      hasReportBuilderPrivilege,
      hasManageSharedReportsPrivilege,
      hasManageReportDirectory,
      isFVAdmin,
      actionHandler,
    ],
  );

  const onFilterChange = (filtered) => {
    clearSearchFilters();
    if (!_.isEmpty(filtered)) {
      // Apply table filters as API search filters
      filtered.forEach((filter) => {
        const tableAccessor = filter.id;
        const apiParam = tableFilterMap[tableAccessor];
        let val = filter.value;

        if (!apiParam) {
          return;
        }
        setSearchFilter(apiParam, val);
      });

      // Clear API search filters that are not applied as a table filter
      Object.keys(tableFilterMap).forEach((tableAccessor) => {
        const apiParam = tableFilterMap[tableAccessor];
        const tableFilter = filtered.find((l) => l.id === tableAccessor);

        if (tableFilter) {
          return;
        }

        clearSearchFilter(apiParam);
      });
    }
    searchReports(solutionId);
  };

  const debouncedFilterReports = useCallback(
    debouncePromise(onFilterChange, 500),
    [],
  );

  const includeScheduleWithReport = (report, index, reportsArray) => {
    const key = getSchedulesKey(report.reportId, report.filterSet?.filterSetId);

    let hasSchedules = reportSchedules[key]?.schedules !== undefined;
    let currentReportSchedules = reportSchedules[key]?.schedules ?? [];

    reportsArray[index].schedules = currentReportSchedules;
    reportsArray[index].emailScheduleCount = hasSchedules
      ? currentReportSchedules?.length
      : report.emailScheduleCount;
  };

  if (type === ReportTypes.SHARED_REPORTS || type === ReportTypes.MY_REPORTS) {
    reportsResults.forEach(includeScheduleWithReport);
  }

  return (
    <BaseTable
      data={reportsResults}
      isLoading={reportsIsLoading}
      columns={[treeStartColumn, ...reportsColumns]}
      isControlledSorting={type === ReportTypes.SHARED_REPORTS ? true : false}
      // sorting
      isManualSorting={true}
      defaultSortColumn={reportsDefaultSortColumn}
      defaultReverseSort={reportsDefaultReverseSort}
      sortColumn={reportsSortColumn}
      reverseSort={reportsReverseSort}
      // pagination
      showPagination={true}
      isManualPagination={false}
      fixPaginationToBottom={true}
      pageIndex={page}
      pageCount={totalPages}
      pageSize={pageSize}
      onPageChange={(newPage) => {
        setPagination(solutionId, newPage, pageSize);
      }}
      onPageSizeChange={(newPageSize) => {
        setPagination(solutionId, 0, newPageSize);
      }}
      onPageSort={useCallback(
        (column, isReverse) => {
          setSort(solutionId, column, isReverse);
        },
        [solutionId, setSort],
      )}
      rowClickHandler={(row, cell) => {
        if (
          cell.column.id === "user-actions" ||
          cell.column.id === "reportId"
        ) {
          return;
        } else {
          const directory = row.original.directory;
          const reportId = row.original.reportId;
          const filterSetId =
            row.original.filterSet && row.original.filterSet.filterSetId
              ? row.original.filterSet.filterSetId
              : null;

          if (directory) {
            return;
          } else if (filterSetId) {
            pushFilteredReportDetailScreen(reportId, filterSetId);
          } else {
            pushReportDetailScreen(reportId);
          }
        }
      }}
      subComponent={(row) => {
        return ExpanderTreeSubcomponent(
          row,
          filtersRef,
          reportsSortColumn,
          reportsReverseSort,
          getSubFolderByQualifier,
          fetchSubFolderData,
          pushReportDetailScreen,
          pushFilteredReportDetailScreen,
        );
      }}
      //filters
      showFilters={true}
      isManualFilterable={true}
      filters={filtersRef.current}
      onFilterChange={debouncedFilterReports}
      expanded={expandedReport(filtersRef, reportsResults) ?? {}}
    />
  );
};

ReportsTable.propTypes = {
  reportSchedules: PropTypes.object,
  solutionId: PropTypes.string,
  type: PropTypes.string,
  tableFilterMap: PropTypes.object,
  filtersRef: PropTypes.object,
  isFVAdmin: PropTypes.bool,
  actionHandler: PropTypes.func,
  hasReportBuilderPrivilege: PropTypes.bool,
  hasManageSharedReportsPrivilege: PropTypes.bool,
  hasManageReportDirectory: PropTypes.bool,
  reportsResults: PropTypes.array,
  reportsIsLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  reportsDefaultSortColumn: PropTypes.string,
  reportsDefaultReverseSort: PropTypes.bool,
  reportsSearchFilters: PropTypes.object,
  setSearchFilter: PropTypes.func,
  clearSearchText: PropTypes.func,
  clearSearchFilters: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  searchReports: PropTypes.func,
  setSort: PropTypes.func,
  setPagination: PropTypes.func,
  reportsSortColumn: PropTypes.string,
  reportsReverseSort: PropTypes.bool,
  getSubFolderByQualifier: PropTypes.func,
  fetchSubFolderData: PropTypes.func,
  pushReportDetailScreen: PropTypes.func,
  pushFilteredReportDetailScreen: PropTypes.func,
  closeModal: PropTypes.func,
  setFolderCategory: PropTypes.func,
};
