import { connect } from "react-redux";
import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";
import { ConfirmationStep } from "./ConfirmationStep.organism";

function mapStateToProps(state) {
  const { getAllUserInputData, getCreateOrUpdateNotificationRuleStatus } =
    NotificationManagementWizardState.selectors;
  return {
    allUserInputData: getAllUserInputData(state),
    createOrUpdateNotificationRuleStatus:
      getCreateOrUpdateNotificationRuleStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { createOrUpdateNotificationRule } =
    NotificationManagementWizardState.actionCreators;

  return {
    createOrUpdateNotificationRule: (userInputData, isEditing) => {
      dispatch(createOrUpdateNotificationRule(userInputData, isEditing));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);
