/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

// Utils
import { getIconData } from "pages/carrierview/utils/exceptions.utils";
import { getExceptionChartData } from "components/utils/exceptions.utils";

// State
import CarrierViewSearchBarState from "../../../redux/CarrierViewSearchBarState";
import CarrierViewSavedSearchState from "../../../redux/CarrierViewSavedSearchState";
import CarrierViewSavedSearchCardsState from "../../../redux/CarrierViewSavedSearchCardsState";

const { searchEntities, setSearchFilter } =
  CarrierViewSearchBarState.actionCreators;
const { loadSavedSearch } = CarrierViewSavedSearchState.actionCreators;

export const CarrierViewSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
}) => {
  const { t } = useTranslation("carrierview-dashboard");
  const dispatch = useDispatch();

  const savedSearchData = useSelector(
    CarrierViewSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    dispatch(
      CarrierViewSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
      ),
    );
  }, [dispatch, savedSearch]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities());
  };

  let loadActiveSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    // The graph displays the Active VIN count
    dispatch(setSearchFilter("lifeCycleState", ["Active"]));
    dispatch(searchEntities());
  };

  let loadDeliveredSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("lifeCycleState", ["Delivered"]));
    dispatch(searchEntities());
  };

  if (savedSearch.search.lifeCycleState?.length === 1) {
    // Prevent Active search when the user set status to Delivered
    if (savedSearch.search.lifeCycleState[0] === "Delivered") {
      loadActiveSavedSearch = null;
    }

    // Prevent Delivered search when the user set status to Active
    if (savedSearch.search.lifeCycleState[0] === "Active") {
      loadDeliveredSavedSearch = null;
    }
  }

  const deliveredData = [
    {
      name: t("carrierview-dashboard:Delivered"),
      count: data?.deliveredCount ?? 0,
      icon: {
        ...getIconData("Delivered"),
      },
    },
  ];

  const chartData = getExceptionChartData([], data?.activeCount, 0);

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        <DonutChart
          data={chartData}
          handler={loadActiveSavedSearch}
          totalLabel={t("carrierview-dashboard:Active")}
          total={data?.activeCount}
        />
        <ExceptionCountGroup
          title={t("labels:Other")}
          exceptions={deliveredData}
          clickHandler={loadDeliveredSavedSearch}
        />
      </div>
    </SavedSearchPanel>
  );
};

CarrierViewSavedSearch.propTypes = {
  // These come from the getCardProps in FinishedVehicleSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};
