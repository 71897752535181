/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import FormCell from "../FormCell";
import SelectField from "../fields/SelectField";

const SelectInput = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  isReadOnly,
  loadOptions,
  isLoading,
  minWidth,
  maxMenuHeight,
  borderColor,
  className,
  style,
}) => {
  return (
    <FormCell label={label} className={className} style={style}>
      {loadOptions ? (
        <SelectField
          isAsync={true}
          isClearable={false}
          stateValue={value}
          onChange={(value) => onChange(value.value)}
          isDisabled={isReadOnly}
          loadOptions={loadOptions}
          defaultOptions={false}
          cacheOptions={false}
          isLoading={isLoading}
          placeholder={placeholder}
          borderWidth="2px"
          borderColor={borderColor}
          minWidth={minWidth}
          maxMenuHeight={maxMenuHeight}
        />
      ) : (
        <SelectField
          options={options}
          stateValue={value}
          onChange={(value) => onChange(value.value)}
          isDisabled={isReadOnly}
          borderWidth="2px"
          borderColor={borderColor}
          minWidth={minWidth}
          maxMenuHeight={maxMenuHeight}
        />
      )}
    </FormCell>
  );
};

SelectInput.propTypes = {
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  minWidth: PropTypes.string,
  maxMenuHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
};

SelectInput.defaultProps = {
  minWidth: "17em",
};

export default SelectInput;
