import _ from "lodash";
import axios from "axios";
import moment from "moment";
import apiUrl from "api-url";
import { v4 as uuidv4 } from "uuid";
import chainReducers from "vendor/signal-utils/chain-reducers";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getBaseFilterUrl } from "pages/damageview/components/search/DamageViewDomainData.js";

const STORE_MOUNT_POINT = "damageViewDetails";

// Duck
const submissionDetailsFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "submissionDetails",
  {},
);

const inProgressStatusOptionsFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "inProgressStatusOptions",
);

//URLs
const BASE_URL = apiUrl("/damageview/");

const commentBaseUrl = (submissionId) =>
  apiUrl(`/damageview/submission/${submissionId}`);

// Actions
const FETCH_COMMENTS = `${STORE_MOUNT_POINT}/FETCH_COMMENTS`;
const FETCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/FETCH_COMMENTS_FAILED`;
const RECEIVE_COMMENTS = `${STORE_MOUNT_POINT}/RECEIVE_COMMENTS`;
const SUBMIT_NEW_COMMENT = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT`;
const RECEIVE_NEW_COMMENT = `${STORE_MOUNT_POINT}/RECEIVE_NEW_COMMENT`;
const SUBMIT_NEW_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT_FAILED`;
const CANCEL_NEW_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_NEW_COMMENT`;
const SET_IS_UPDATING_COMMENT = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT`;
const SET_IS_UPDATING_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT_FAILED`;
const CANCEL_UPDATE_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_UPDATE_COMMENT`;
const FETCH_PHOTO_DATA = `${STORE_MOUNT_POINT}/FETCH_PHOTO_DATA`;
const RECEIVE_PHOTO_DATA = `${STORE_MOUNT_POINT}/RECEIVE_PHOTO_DATA`;
const PHOTO_DATA_ERROR = `${STORE_MOUNT_POINT}/PHOTO_DATA_ERROR`;

// Action Creators

const fetchSubmissionDetails = (submissionId) => {
  return (dispatch, getState) => {
    const state = getState();
    const solutionId = getSolutionId(state);
    const url = solutionId
      ? apiUrl(
          `/damageview/solution_id/${solutionId}/submission/${submissionId}`,
        )
      : apiUrl(`/damageview/submission/${submissionId}`);
    dispatch(submissionDetailsFetchDuck.fetch(url));
  };
};

const fetchInProgressStatusOptions = (solutionId) => {
  return (dispatch) => {
    const url = apiUrl(
      `/entity/solution/${solutionId}/system-configuration?qualifier=DAMAGEVIEW_IN_PROGRESS_STATUS`,
    );
    dispatch(inProgressStatusOptionsFetchDuck.fetch(url));
  };
};

const saveSubmissionChanges = ({
  solutionId,
  submissionId,
  assignee,
  status,
  rejectedComments,
  repairLocation,
  inProgressStatus,
}) => {
  const url = apiUrl(`/damageview/submission/${submissionId}/details`);
  let payload = {
    assignee,
    rejectedComments,
    repairLocation,
    status: _.capitalize(status),
  };

  if (inProgressStatus !== undefined) {
    payload = {
      ...payload,
      inProgressStatus,
    };
  }

  return axios.patch(url, payload);
};

const fetchComments = (submissionId, pageNumber, pageSize) => {
  let url = `${commentBaseUrl(submissionId)}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_COMMENTS,
      });
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_COMMENTS_FAILED,
        });
      });
  };
};

const addComment = (submissionId, data) => {
  let url = `${commentBaseUrl(submissionId)}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
};

const markCommentsRead = (submissionId, dateTime) => {
  let url = `${commentBaseUrl(submissionId)}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (dispatch) => {
    return axios
      .post(url, data)
      .then((response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
const updateComment = (submissionId, commentId, updatedData) => {
  let url = `${commentBaseUrl(submissionId)}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };

    return axios
      .patch(url, requestData)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
};
const cancelUpdateComment = (commentId) => {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId: commentId },
    });
  };
};
const cancelAddComment = (fakeCommentId) => {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
};

const fetchPhotoData = (vinId, submissionId) => {
  const url = BASE_URL + `submission/${submissionId}/vin/${vinId}/photos`;
  return async (dispatch) => {
    dispatch({ type: FETCH_PHOTO_DATA });
    try {
      await axios.get(url).then((resp) => {
        dispatch({
          type: RECEIVE_PHOTO_DATA,
          payload: resp?.data,
        });
      });
    } catch (err) {
      dispatch({
        type: PHOTO_DATA_ERROR,
        payload: err,
      });
    }
  };
};

const fetchStatusOptions = (searchValue) => {
  const statusUrl = getBaseFilterUrl("status");
  return axios.get(statusUrl).then((res) => {
    return res?.data?.data ?? [];
  });
};

// Selectors

const getComments = (state) => state[STORE_MOUNT_POINT].comments ?? {};
const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments ?? false;
const getisPhotoDataLoading = (state) =>
  state[STORE_MOUNT_POINT].isPhotoDataLoading;
const getPhotoData = (state) => state[STORE_MOUNT_POINT].photoData;

const getIsInProgressStatusConfigured = (state) => {
  let data = inProgressStatusOptionsFetchDuck.selectors.getData(state);
  return data?.data?.length > 0 ?? false;
};
const getInProgressStatusOptionsMapping = (state) => {
  let data = inProgressStatusOptionsFetchDuck.selectors.getData(state);
  return data?.data?.[0]?.metadata ?? {};
};

const initialState = {
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  isPhotoDataLoading: false,
  photoData: {},
  photoDataError: {},
};

const damageViewDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHOTO_DATA:
      return {
        ...state,
        isPhotoDataLoading: true,
        photoData: {},
      };

    case RECEIVE_PHOTO_DATA:
      return {
        ...state,
        isPhotoDataLoading: false,
        photoData: action.payload,
      };
    case PHOTO_DATA_ERROR:
      return {
        ...state,
        isPhotoDataLoading: false,
        photoData: {},
        photoDataError: {},
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          // Note: We use unionWith to avoid duplicates in case new comments were added
          // since the last page was loaded.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.unionWith(
                state.comments.data,
                action.payload.comments.data,
                (d1, d2) => d1.id === d2.id,
              ),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let commentList = state.comments.data ? [...state.comments.data] : [];
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        commentList = commentList.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: {
          ...state.comments,
          data: commentList,
        },
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let commentList = state.comments.data ? [...state.comments.data] : [];

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };
    default:
      return state;
  }
};

const DamageViewDetailsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchPhotoData,
    fetchComments,
    addComment,
    markCommentsRead,
    updateComment,
    cancelUpdateComment,
    cancelAddComment,
    fetchSubmissionDetails,
    fetchInProgressStatusOptions,
    saveSubmissionChanges,
    fetchStatusOptions,
  },
  selectors: {
    getisPhotoDataLoading,
    getPhotoData,
    getComments,
    getIsFetchingComments,
    getSubmissionDetailsRequestData:
      submissionDetailsFetchDuck.selectors.getData,
    getIsInProgressStatusConfigured,
    getInProgressStatusOptionsMapping,
  },
  reducer: chainReducers([
    damageViewDetailsReducer,
    submissionDetailsFetchDuck.reducer,
    inProgressStatusOptionsFetchDuck.reducer,
  ]),
};

export default DamageViewDetailsState;
