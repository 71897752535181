import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum ContactUsFormFieldLabel {
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  PHONE_NUMBER = "Phone Number",
  EMAIL = "Email",
  SUBJECT = "Subject",
  DESCRIPTION = "Description",
}

export const useCustomerSupportTranslations = () => {
  const { t } = useTranslation("support");

  const getContactUsFormFieldLabelTranslation = useCallback(
    (fieldLabel: string) => {
      switch (fieldLabel) {
        case ContactUsFormFieldLabel.FIRST_NAME:
          return t("support:First Name");
        case ContactUsFormFieldLabel.LAST_NAME:
          return t("support:Last Name");
        case ContactUsFormFieldLabel.PHONE_NUMBER:
          return t("support:Phone Number");
        case ContactUsFormFieldLabel.SUBJECT:
          return t("support:Subject");
        case ContactUsFormFieldLabel.EMAIL:
          return t("support:Email");
        case ContactUsFormFieldLabel.DESCRIPTION:
          return t("support:Description");
        default:
          return fieldLabel;
      }
    },
    [t],
  );

  return {
    getContactUsFormFieldLabelTranslation,
  };
};
