import { connect } from "react-redux";
import { ShipmentDwellNotificationSearch } from "./ShipmentDwellNotificationSearch.page";
import ShipmentDwellNotificationSearchBarState from "./redux/ShipmentDwellNotificationSearchBarState";

const {
  getIsLoading,
  getSearchResults,
  getTotalEntities,
  getPage,
  getPageSize,
  getTotalPages,
  getSortColumn,
  getReverseSort,
  getDefaultSortColumn,
  getDefaultReverseSort,
  getCanUserSearch,
  getHasSearchCriteriaChanged,
} = ShipmentDwellNotificationSearchBarState.selectors;

function mapStateToProps(state) {
  return {
    isLoading: getIsLoading(state),
    searchResults: getSearchResults(state),
    totalCount: getTotalEntities(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    defaultSortColumn: getDefaultSortColumn(state),
    defaultReverseSort: getDefaultReverseSort(state),
    canUserSearch: getCanUserSearch(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
  };
}

const { searchEntities, setPagination, setSort } =
  ShipmentDwellNotificationSearchBarState.actionCreators;

const actionCreators = {
  searchEntities,
  setPagination,
  setSort,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(ShipmentDwellNotificationSearch);
