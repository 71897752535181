/** @jsxImportSource @emotion/react */
// Third party Libraries
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// UI Libraries
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";

// Utility files
import { denyCodeToReadableFormat } from "pages/driveaway/utils/driveaway.utils";
import { DPUStatus } from "pages/driveaway/utils/enums.utils";

const StatusCell = (props) => {
  const { t } = useTranslation("driveaway-search");
  const { status, denyReasonCode } = props.value;
  switch (status) {
    case DPUStatus.AVAILABLE:
      return (
        <Text bold color={Colors.donuts.BLUE}>
          {t("driveaway-search:Available")}
        </Text>
      );
    case DPUStatus.SUBMITTED:
      return (
        <Text bold color={Colors.text.YELLOW}>
          {t("driveaway-search:Submitted")}
        </Text>
      );
    case DPUStatus.APPROVED:
      return (
        <Text bold color={Colors.text.GREEN}>
          {t("driveaway-search:Approved")}
        </Text>
      );
    case DPUStatus.DENIED:
      return (
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text bold color={Colors.text.RED}>
            {t("driveaway-search:Denied")}
          </Text>

          {!_.isNil(denyReasonCode) ? (
            <Text>{`(${denyCodeToReadableFormat(t, denyReasonCode)})`}</Text>
          ) : null}
        </div>
      );
    case DPUStatus.DELIVERED:
      return <Text bold>{t("driveaway-search:Delivered")}</Text>;
    case DPUStatus.CANCELED:
      return <Text bold>{t("driveaway-search:Canceled")}</Text>;
    case DPUStatus.NOT_AVAILABLE:
      return <Text bold>{t("driveaway-search:Not Available")}</Text>;
    case DPUStatus.Preauthed:
      return <Text bold>{t("driveaway-search:Preauthed")}</Text>;
    default:
      return <Text bold>{status}</Text>;
  }
};

export { StatusCell };

StatusCell.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string,
    denyReasonCode: PropTypes.string,
  }),
};
