import { connect } from "react-redux";
import ReportDetail from "./ReportDetail.page";
import ReportsState from "pages/reports/redux/ReportsState";
import TitleState from "shared/redux/TitleState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

function mapStateToProps(state) {
  const {
    getReport,
    getIsLoading,
    getIsSaving,
    getIsEditing,
    getIsDeleting,
    getSelectedReportId,
    getSelectedFilterSetId,
  } = ReportsState.selectors;

  return {
    report: getReport(state),
    isLoading: getIsLoading(state),
    isSaving: getIsSaving(state),
    isEditing: getIsEditing(state),
    isDeleting: getIsDeleting(state),
    selectedReportId: getSelectedReportId(state),
    selectedFilterSetId: getSelectedFilterSetId(state),
    authorization: getAuthorization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchReport,
    fetchFilteredReport,
    saveReport,
    saveFilteredReport,
    updateReport,
    updateFilteredReport,
    deleteReport,
    deleteFilteredReport,
  } = ReportsState.actionCreators;
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setSubTitle: (subTitle) =>
      dispatch(TitleState.actionCreators.setSubTitle(subTitle)),
    fetchReport: (reportId) => dispatch(fetchReport(reportId)),
    fetchFilteredReport: (reportId, filterSetId) =>
      dispatch(fetchFilteredReport(reportId, filterSetId)),
    saveReport: (data) => dispatch(saveReport(data)),
    saveFilteredReport: (reportId, data) =>
      dispatch(saveFilteredReport(reportId, data)),
    updateReport: (reportId, payload) =>
      dispatch(updateReport(reportId, payload)),
    updateFilteredReport: (reportId, filterSetId, payload) =>
      dispatch(updateFilteredReport(reportId, filterSetId, payload)),
    deleteReport: (reportId) => dispatch(deleteReport(reportId)),
    deleteFilteredReport: (reportId, filterSetId) =>
      dispatch(deleteFilteredReport(reportId, filterSetId)),
    pushBIDashboardScreen: () =>
      dispatch({
        type: "REPORTS",
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
