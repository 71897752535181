/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Text, FontSize } from "components/atoms/Text.atom";
import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
import Colors, { shadeColor } from "styles/colors";

export const OrganizationFilterButton = (props) => {
  const { t } = useTranslation("partview-search");
  return (
    <AsyncSelectFilterButton
      {...props}
      css={{
        backgroundColor: Colors.background.DARK_TEAL,
        color: Colors.text.WHITE,
        borderColor: "#246780",
        ":hover": {
          backgroundColor: shadeColor(Colors.background.DARK_TEAL, -0.15),
        },
      }}
      isMulti={false}
      hideSelectEmpty
      helpText={
        <Fragment>
          <Text block className="mb-1">
            {t("partview-search:Search by name, location code, or BAC.")}
          </Text>
          <Text block>
            <Text size={FontSize.size12}>{t("partview-search:Note")}: </Text>
            <Text size={FontSize.size12}>
              {t(
                "partview-search:Setting this filter will also show counts and watched packages from your selected organization.",
              )}
            </Text>
          </Text>
        </Fragment>
      }
    />
  );
};

OrganizationFilterButton.propTypes = {};
