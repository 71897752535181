/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withResizeDetector } from "components/hooks/resize-detector";
import { SimpleMap } from "./SimpleMap";
import MapState from "../MapState";
import { isFenceValid } from "../../geofence-edit/geofence-types";
import GeofenceType, {
  getType as getGeofenceType,
} from "../../geofence-edit/geofence-types";
import { PinChicletSVG } from "../../../components/chiclets";
import Colors from "../../../styles/colors";

const defaultHeight = 48;
const defaultWidth = 48;

let pinSvg = PinChicletSVG({
  backgroundColor: Colors.holds.RED,
  borderColor: Colors.holds.RED,
  height: defaultHeight,
  width: defaultWidth,
});

class GeofenceBuilderMap extends SimpleMap {
  constructor(props) {
    super(props);

    this.debouncedDrawGeofence = _.debounce(this.drawGeofence, 100);
    this.debouncedDrawTrace = _.debounce(this.drawTrace, 5);

    this.state = {
      mapLocations: [],
      hasUpdatedOnce: false,
      mouseGeoCoords: null,
      displayedTracePoints: [],
    };
  }

  _hasGeofenceChanged(prevProps) {
    const currentGeofence = _.get(this.props, "selectedLocation.geofence");
    const prevGeofence = _.get(prevProps, "selectedLocation.geofence");
    const didChange = !_.isEqual(currentGeofence, prevGeofence);
    return didChange;
  }

  _hasGeofenceTypeChanged(prevProps) {
    const currentGeofence = _.get(this.props, "selectedLocation.geofence");
    const prevGeofence = _.get(prevProps, "selectedLocation.geofence");
    return (
      currentGeofence &&
      prevGeofence &&
      getGeofenceType(currentGeofence) !== getGeofenceType(prevGeofence)
    );
  }

  componentDidUpdate(prevProps) {
    const { hasUpdatedOnce } = this.state;
    const {
      selectedLocation,
      enableGeofenceBuilder,
      enableDraggingGeofence,
      isTracing,
      isNewLocation,
      mapLocations,
      selectedMapCoordinate,
    } = this.props;

    this.handleHeatmapChanges(prevProps);

    if (!isTracing && prevProps.isTracing) {
      // Stopped tracing a geofence, clear the markers
      this.clearMapMarkers(`tracing:geofence:`);
      this.setState({ displayedTracePoints: [] });
    }

    const hasGeofenceChanged = this._hasGeofenceChanged(prevProps);

    const prevGeofence = _.get(prevProps, "selectedLocation.geofence");
    const currentGeofence = _.get(this.props, "selectedLocation.geofence");

    let prevFenceType = null;
    let newFenceType = null;

    if (currentGeofence && prevGeofence) {
      prevFenceType = getGeofenceType(prevGeofence);
      newFenceType = getGeofenceType(currentGeofence);
    }

    // When drawing a geofence, only update the zoom level if receiving the
    // geofence for the first time
    let prevGeofenceCoords = [];
    let newGeofenceCoords = [];

    if (prevFenceType === newFenceType) {
      if (newFenceType === GeofenceType.MULTIPOLYGON) {
        _.forEach(prevProps.selectedLocation.geofence.geometry, (polygon) => {
          prevGeofenceCoords.push(polygon.geometry.coordinates);
        });

        _.forEach(this.props.selectedLocation.geofence.geometry, (polygon) => {
          newGeofenceCoords.push(polygon.geometry.coordinates);
        });
      } else {
        prevGeofenceCoords = _.get(
          prevProps,
          "selectedLocation.geofence.geometry.coordinates",
        );
        newGeofenceCoords = _.get(
          this.props,
          "selectedLocation.geofence.geometry.coordinates",
        );
      }
    }

    const hasGeofenceTypeChanged = prevFenceType !== newFenceType;

    const hasReceivedNewGeofence =
      prevGeofenceCoords &&
      prevGeofenceCoords.length === 0 &&
      newGeofenceCoords &&
      newGeofenceCoords.length > 0;

    const deletedGeofence =
      prevGeofenceCoords &&
      prevGeofenceCoords.length &&
      newGeofenceCoords &&
      !newGeofenceCoords.length;

    const shouldUpdate =
      !enableGeofenceBuilder ||
      hasGeofenceTypeChanged ||
      hasReceivedNewGeofence ||
      deletedGeofence ||
      this._hasGeofenceTypeChanged(prevProps) ||
      !hasUpdatedOnce;

    if (hasGeofenceChanged && shouldUpdate) {
      this.clearMap();
    }
    this.clearInfoBubbles();
    this.loadLocations();

    if (selectedLocation && mapLocations.length === 1) {
      if (shouldUpdate || prevProps.selectedLocation === null) {
        const hasSelectedLocationAValidPosition =
          isFenceValid(selectedLocation.geofence) || selectedLocation.latitude;
        if (hasSelectedLocationAValidPosition) {
          this.zoomSingleLocation(selectedLocation);
        }
      }

      if (enableGeofenceBuilder) {
        this.debouncedDrawGeofence(
          selectedLocation,
          enableDraggingGeofence,
          false,
        );
      }

      if (isTracing) {
        this.debouncedDrawTrace(selectedLocation);
      }

      if (isNewLocation && hasGeofenceChanged) {
        this.clearMapMarkers("newLocation:centerMarker");
        this.addNewLocationMarker(selectedLocation);
      }

      // H1-82: Reposition map if geofence changes due to an updated geocoded
      // address
      this.repositionMapIfGeofenceChangedAndUserIsNotDrawing(
        selectedLocation,
        hasGeofenceChanged,
      );
    }

    // Set the selected coordinates in map
    this.markSelectedCoordinatesOnMap(
      selectedMapCoordinate,
      prevProps.selectedMapCoordinate,
    );

    this.handleMapSizeChanges(prevProps);

    if (this.props.initialZoomLevel) {
      this.setMapZoom(this.props.initialZoomLevel);
    }

    if (!hasUpdatedOnce) {
      this.setState({ hasUpdatedOnce: true });
    }
  }

  markSelectedCoordinatesOnMap(
    selectedMapCoordinate,
    prevselectedMapCoordinate = {},
  ) {
    if (selectedMapCoordinate) {
      this.clearMapMarkers(`geofence-coordinate:`);
      const { lat, long: lng } = selectedMapCoordinate;
      const pos = { lat, lng };
      this.createAndAddMapMarker(
        `geofence-coordinate:${lat}${lng}`,
        pos,
        null,
        { id: `geofence-coordinate:${lat}${lng}` },
        false,
        pinSvg,
      );
      this.setMapCenter(pos);
    }
  }

  drawLocations() {
    const { enableGeofenceBuilder } = this.props;

    // When geofence building is not enabled, it means that we should show the
    // normal locations with its geofences
    if (!enableGeofenceBuilder) {
      SimpleMap.prototype.drawLocations.call(this);
    }
  }

  addNewLocationMarker(location) {
    if (this.hasLocationGeofence(location)) {
      const center = location.geofence.properties.center;
      const position = { lat: center.latitude, lng: center.longitude };
      const id = "newLocation:centerMarker";
      this.createAndAddMapMarker(id, position, null, {
        id: id,
      });
    }
  }

  drawTrace(location) {
    const { tracePoints, onPolygonDrawEnd } = this.props;
    const { mouseGeoCoords } = this.state;

    const fenceType = getGeofenceType(location.geofence);

    let lastPolyIndex;
    if (fenceType === GeofenceType.POLYGONAL) {
      lastPolyIndex = location.geofence.geometry.coordinates.length - 1;
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      lastPolyIndex = location.geofence.geometry.length - 1;
    }

    // Remove any previous mouse marker or dotted line.
    const tracingGroupIdPrefix = `tracing:geofence:${location.id}:tracing`;
    this.clearMapMarkers(tracingGroupIdPrefix);

    // Re-add mouse marker and dotted line.
    const tracingDraft = this.platform.shapes.createTracingDraft(
      location.id,
      mouseGeoCoords,
      tracePoints,
    );

    if (tracingDraft) {
      this.platform.addTraceGroup(tracingDraft);
    }

    // If the tracePoints have changed, re-do the polygon + map markers + lines
    if (!_.isEqual(tracePoints, this.state.displayedTracePoints)) {
      const polygonGroupIdPrefix = `tracing:geofence:${location.id}:polyline`;
      this.clearMapMarkers(polygonGroupIdPrefix);

      const polygonDraft = this.platform.shapes.createPolygonDrawingDraft(
        location.id,
        lastPolyIndex,
        mouseGeoCoords,
        tracePoints,
        onPolygonDrawEnd,
      );

      if (polygonDraft) {
        this.platform.addTraceGroup(polygonDraft);
      }

      // Update tracePoints in state
      this.setState({ displayedTracePoints: _.clone(tracePoints) });
    }
  }

  repositionMapIfGeofenceChangedAndUserIsNotDrawing(
    location,
    hasGeofenceChanged,
  ) {
    const { selectedLocation, enableGeofenceBuilder } = this.props;
    const center = _.get(selectedLocation, "geofence.properties.center");
    if (hasGeofenceChanged && center) {
      const pos = {
        lat: center.latitude,
        lng: center.longitude,
      };
      if (pos.lat && pos.lng && !enableGeofenceBuilder) {
        this.setMapCenter(pos);
      }
    }
  }
}

GeofenceBuilderMap.propTypes = {
  mapLocations: PropTypes.array,
  popupComponent: PropTypes.elementType,
  popupClickHandler: PropTypes.func,
  showHeatmap: PropTypes.bool,
  heatmapCoords: PropTypes.array,
  selectedLad: PropTypes.object,
  selectedLocation: PropTypes.object,
  isNewLocation: PropTypes.bool,
  useBoxChiclets: PropTypes.bool,

  // Props related to geofence building
  tracePoints: PropTypes.array,
  enableDraggingGeofence: PropTypes.bool,
  enableGeofenceBuilder: PropTypes.bool,
  isTracing: PropTypes.bool,

  // Events
  onMarkerMouseEnter: PropTypes.func,
  onMarkerMouseOut: PropTypes.func,
  onDragGeofenceControlPoint: PropTypes.func,
  onDragPolygonalGeofence: PropTypes.func,
  onDragRadialGeofence: PropTypes.func,
  deletePointFromTrace: PropTypes.func,
  updatePolygonPoints: PropTypes.func,
  deletePointFromPolygon: PropTypes.func,
  onPolygonDrawEnd: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    ...state.maps,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const sizeMeHOC = withResizeDetector()(GeofenceBuilderMap);
const GeofenceBuilderMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(sizeMeHOC);
export default GeofenceBuilderMapContainer;
