import _ from "lodash";

const NON_ETA_TRIGGERING_SHIPMENT_STATUSES = ["SC", "XB"];
const EXCLUDED_EXCEPTIONS = [
  "bad_order_exceptions",
  "behind_schedule",
  "clear_under_review",
  "idle_train_exceptions",
  "in_hold_exceptions",
  "manual_carrier_delay",
  "manual_shipper_delay",
  "missed_delivery_exceptions",
  "missed_pickup_exceptions",
  "under_review",
];

export const isExcludedRow = (etaTrigger = {}) => {
  const isExcludedStatusCode = NON_ETA_TRIGGERING_SHIPMENT_STATUSES.includes(
    etaTrigger.etaCalculationShipmentStatusCode,
  );

  const isException =
    etaTrigger.is_exception ||
    EXCLUDED_EXCEPTIONS.includes(etaTrigger.etaCalculationShipmentStatusCode);

  return isExcludedStatusCode || isException;
};

export const convertEtaTriggerToTableRows = (etaTrigger = {}, t) => {
  if (_.isNil(etaTrigger)) {
    return [];
  }

  const rows = [];

  rows.push({
    name: t("shipment-eta-validator:Shipment Status Created Date UTC"),
    value: etaTrigger.shipmentStatusCreatedDateUTC,
  });

  rows.push({
    name: t("shipment-eta-validator:Shipment Status Actual Event Date UTC"),
    value: etaTrigger.shipmentStatusActualEventDateUTC,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Shipment Status Code"),
    value: etaTrigger.etaCalculationShipmentStatusCode,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Point Of Interest"),
    value: etaTrigger.etaCalculationPointOfInterest,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Latitude"),
    value: etaTrigger.etaCalculationLatitude,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Longitude"),
    value: etaTrigger.etaCalculationLongitude,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation City"),
    value: etaTrigger.etaCalculationCity,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation State"),
    value: etaTrigger.etaCalculationStateOrProvince,
  });

  rows.push({
    name: t("shipment-eta-validator:ETA Calculation Country"),
    value: etaTrigger.etaCalculationCountry,
  });

  rows.push({
    name: t("shipment-eta-validator:Number Of Observations In Model"),
    value: etaTrigger.numberOfObservationsInModel,
  });

  rows.push({
    name: t("shipment-eta-validator:Scheduled Delivery"),
    value: etaTrigger.scheduledDeliveryWindow,
  });

  return rows;
};

export const getAverageName = (average, t) => {
  if (_.isNil(average)) {
    return t("vin-validator-tool:N/A");
  }

  switch (average) {
    case "GT3":
      return t("vin-validator-tool:Greater than 3");
    case "LT3":
      return t("vin-validator-tool:Less than 3");
    default:
      return average;
  }
};

/**
 * Format a result from the API for use in the search results table.
 *
 * PLAT-43: Successful triggers and errors come as different objects.
 * We want each object used in the rows to be the same shape so the table
 * doesn't have to do too much work in the columns.
 *
 * @param {object} result
 * @param {object} entity
 * @returns object
 */
export const formatResult = (result = {}, entity) => {
  if (result.error) {
    return {
      shipmentStatusCreatedDateUTC: null,
      shipmentStatusActualEventDateUTC: null,
      etaCalculationShipmentStatusCode: null,
      etaCalculationPointOfInterest: null,
      etaCalculationLatitude: null,
      etaCalculationLongitude: null,
      etaCalculationCity: null,
      etaCalculationStateOrProvince: null,
      etaCalculationCountry: null,
      etaUTC: null,
      etaModelName: null,
      numberOfDaysInHistory: null,
      percentileUsedInCalculation: null,
      etaModelVersion: null,
      numberOfObservationsInModel: null,
      shipmentActualArrivalTime: null,
      shipmentUltimateDestinationCode: null,
      shipmentUltimateDestinationName: null,
      etaDestinationTime: null,
      etaDifference: null,
      triggerType: null,
      triggerMode: null,
      etaAlgorithm: null,
      lifeCycleState: null,
      scheduledDeliveryWindow: null,
      isException: null,
    };
  }

  return {
    shipmentStatusCreatedDateUTC: result.shipmentStatusCreatedDateUTC,
    shipmentStatusActualEventDateUTC: result.shipmentStatusActualEventDateUTC,
    etaCalculationShipmentStatusCode: result.etaCalculationShipmentStatusCode,
    etaCalculationPointOfInterest: result.etaCalculationPointOfInterest,
    etaCalculationLatitude: result.latitude,
    etaCalculationLongitude: result.longitude,
    etaCalculationCity: result.etaCalculationCity,
    etaCalculationStateOrProvince: result.etaCalculationStateOrProvince,
    etaCalculationCountry: result.etaCalculationCountry,
    etaUTC: result.etaUTC,
    etaModelName: result.etaModelName,
    numberOfDaysInHistory: result.numberOfDaysInHistory,
    percentileUsedInCalculation: result.percentileUsedInCalculation,
    etaModelVersion: result.etaModelVersion,
    numberOfObservationsInModel: result.numberOfObservationsInModel,
    shipmentActualArrivalTime: result.shipmentActualArrivalTime,
    shipmentUltimateDestinationCode: result.shipmentUltimateDestinationCode,
    shipmentUltimateDestinationName: result.shipmentUltimateDestinationName,
    etaDestinationTime: result.etaDestinationTime,
    etaDifference: result.etaDifference,
    triggerType: result.triggerType,
    triggerMode: result.triggerMode,
    etaAlgorithm: result.etaAlgorithm,
    lifeCycleState: result.lifeCycleState,
    scheduledDeliveryWindow: result.scheduledDeliveryWindow,
    isException: result.isException,
  };
};
