import moment from "moment";
import axios from "axios";
import _ from "lodash";
import {
  DeliveryWindowTrigger,
  filterOptionUrlLookupTable,
  filterTypeOptions,
  FilterTypesEnum,
  ScheduleDeliveryOptionsEnum,
  eventDefinitionMapping,
} from "./notificationmanagement.const";

export const createStateObj = async (apiResponseData) => {
  const notificationUuid = apiResponseData.id;
  const notificationRuleName = apiResponseData.name;
  const product = apiResponseData.product;
  const eventType = apiResponseData.eventType;
  const eventDefiniton = eventDefinitionMapping[eventType];
  const notificationDetailsObj = getNotificationDetailsObj(apiResponseData);
  const filterCriteria = await getFilterArray(
    apiResponseData.definition[eventDefiniton].filters,
  );

  return {
    notificationId: notificationUuid,
    notificationRuleName,
    product,
    deliveryActions: getDeliveryActions(apiResponseData.deliveryActions),
    notificationDetailsObj,
    filterCriteria,
  };
};

const getNotificationDetailsObj = (apiResponseData) => {
  const eventType = apiResponseData.eventType;
  const eventDefiniton = eventDefinitionMapping[eventType];
  switch (eventDefiniton) {
    case "behindScheduleV1":
      return {
        notificationEventType: apiResponseData.eventType,
        notificationTriggerTimeObj: parseIso8601DurationString(
          apiResponseData.definition.behindScheduleV1.lateBy,
        ),
        notificationTrigger: getNotificationTrigger(
          apiResponseData.definition.behindScheduleV1
            .scheduledArrivalWindowBoundary,
        ),
      };
    case "earlyArrivalV1":
      return {
        notificationEventType: apiResponseData.eventType,
        notificationTriggerTimeObj: parseIso8601DurationString(
          apiResponseData.definition.earlyArrivalV1.earlyBy,
        ),
      };
    case "carrierDelayV1":
    case "excessiveDwellV1":
    case "carrierDelayClearedV1":
    case "offRouteRailV1":
      return {
        notificationEventType: apiResponseData.eventType,
      };
    default:
      throw new Error(
        "getNotificationDetailsObj not yet implemented for event type:",
        apiResponseData.eventType,
      );
  }
};

const parseIso8601DurationString = (iso8601DurationString) => {
  const duration = moment.duration(iso8601DurationString);
  return {
    days: duration.days() || 0,
    hours: duration.hours() || 0,
    minutes: duration.minutes() || 0,
  };
};

const getDeliveryActions = (deliveryActions) => {
  return deliveryActions.map((action) => {
    return {
      type: convertDeliveryActionType(action.method),
      value: action.target,
      unsubscribeId: action.unsubscribeId,
    };
  });
};

const convertDeliveryActionType = (type) => {
  switch (type) {
    case "email":
      return "Email";
    case "sms":
      return "SMS";
    default:
      throw new Error("Invalid delivery action type");
  }
};

const getNotificationTrigger = (boundary) => {
  if (boundary === "OPEN") {
    return DeliveryWindowTrigger.AFTER_DELIVERY_WINDOW_OPEN;
  } else if (boundary === "CLOSE") {
    return DeliveryWindowTrigger.AFTER_DELIVERY_WINDOW_CLOSE;
  } else {
    throw new Error("Invalid boundary");
  }
};

const formatFilterOptions = async (filterType, filterOptions) => {
  switch (filterType) {
    case FilterTypesEnum.MILES_OUT:
      return [filterOptions.value];
    case FilterTypesEnum.SCHEDULED_DELIVERY:
      return [
        {
          label: parseScheduledDeliveryOptionLabel(filterOptions),
          value: filterOptions,
        },
      ];
    case FilterTypesEnum.MODE:
      return filterOptions.map((filterOption) => {
        return {
          label: filterOption,
          value: filterOption,
        };
      });
    case FilterTypesEnum.CARRIER:
      return getValidFormattedCarrierFilterOptions();
    case FilterTypesEnum.PARTS_NUMBER:
      return getValidFormattedPartNumberFilterOptions();
    default:
      return getValidFormattedFilterOptions();
  }

  function findOutdated(filterOptions, filterOptionCodeFromAPI) {
    return filterOptions.filter(
      (filterOption) => !filterOptionCodeFromAPI.has(filterOption),
    );
  }

  async function getValidFormattedFilterOptions() {
    const validFilterOptionsObjects = await fetchFilterOptionsObjects(
      filterType,
      filterOptions,
    );

    const filterOptionCodeFromAPI = new Set(
      validFilterOptionsObjects.map(
        (filterOptionsObjects) => filterOptionsObjects.code,
      ),
    );

    const outdatedFilterOptions = findOutdated(
      filterOptions,
      filterOptionCodeFromAPI,
    );

    const formattedValidFilterOptionsObjects = validFilterOptionsObjects.map(
      (filterOptionsObject) => {
        return {
          label: getLabelFromResponse(
            filterType,
            filterOptionsObject.name,
            filterOptionsObject.code,
          ),
          value: filterOptionsObject.code,
          outdated: false,
        };
      },
    );

    const formattedOutdatedFilterOptions = outdatedFilterOptions.map(
      (outdatedFilterOption) => {
        return {
          label: `(${outdatedFilterOption})`,
          value: outdatedFilterOption,
          outdated: true,
        };
      },
    );

    return [
      ...formattedValidFilterOptionsObjects,
      ...formattedOutdatedFilterOptions,
    ];
  }

  async function getValidFormattedPartNumberFilterOptions() {
    const filterOptionsObjects = await fetchPartNumbersFilterOptionsObjects();

    const validFilterOptionsObjects =
      filterPartNumberObjects(filterOptionsObjects);

    const filterOptionCodeFromAPI = new Set(
      validFilterOptionsObjects.map(
        (filterOptionsObjects) => filterOptionsObjects.value,
      ),
    );

    const outdatedFilterOptions = findOutdated(
      filterOptions,
      filterOptionCodeFromAPI,
    );

    const formattedValidFilterOptionsObjects = validFilterOptionsObjects.map(
      (filterOptionsObject) => {
        return {
          label: getLabelFromResponse(
            filterType,
            filterOptionsObject.label,
            filterOptionsObject.value,
          ),
          value: filterOptionsObject.value,
          outdated: false,
        };
      },
    );

    const formattedOutdatedFilterOptions = outdatedFilterOptions.map(
      (outdatedFilterOption) => {
        return {
          label: `(${outdatedFilterOption})`,
          value: outdatedFilterOption,
          outdated: true,
        };
      },
    );

    return [
      ...formattedValidFilterOptionsObjects,
      ...formattedOutdatedFilterOptions,
    ];
  }

  async function getValidFormattedCarrierFilterOptions() {
    const filterOptionsObjects = await fetchFilterOptionsObjects(
      filterType,
      filterOptions,
    );

    const validFilterOptionsObjects = filterCarriers(filterOptionsObjects);

    const filterOptionCodeFromAPI = new Set(
      validFilterOptionsObjects.map(
        (filterOptionsObjects) => filterOptionsObjects.fv_id,
      ),
    );

    const outdatedFilterOptions = findOutdated(
      filterOptions,
      filterOptionCodeFromAPI,
    );

    const formattedValidFilterOptionsObjects = validFilterOptionsObjects.map(
      (filterOptionsObject) => {
        return {
          label: getLabelFromResponse(
            filterType,
            filterOptionsObject.name,
            filterOptionsObject.fv_id,
          ),
          value: filterOptionsObject.fv_id,
          outdated: false,
        };
      },
    );

    const formattedOutdatedFilterOptions = outdatedFilterOptions.map(
      (outdatedFilterOption) => {
        return {
          label: `(${outdatedFilterOption})`,
          value: outdatedFilterOption,
          outdated: true,
        };
      },
    );

    return [
      ...formattedValidFilterOptionsObjects,
      ...formattedOutdatedFilterOptions,
    ];
  }

  function filterCarriers(filterOptionsObjects) {
    const filterOptionFvIds = new Set(filterOptions);
    return filterOptionsObjects.filter((filterOptionObject) =>
      filterOptionFvIds.has(filterOptionObject.fv_id),
    );
  }

  /**
   * @description When we search for "123", the response could have "123", "1234",
   * "45123" so we have to filter the response
   */
  function filterPartNumberObjects(filterOptionsObjects) {
    const filterOptionCodes = new Set(filterOptions);
    return filterOptionsObjects
      .map((results) => {
        return results.data.filter((data) =>
          filterOptionCodes.has(data.value),
        )[0];
      })
      .filter((filterOptionsObject) => !_.isNil(filterOptionsObject));
  }

  /**
   * @description part number API does not allow searching multiple codes
   * in here we iterate through the filter options and call the API multiple times
   */
  async function fetchPartNumbersFilterOptionsObjects() {
    const filterOptionsObjectsPromises = filterOptions.map((filterOption) =>
      fetchFilterOptionsObjects(filterType, filterOption),
    );
    return await Promise.all(filterOptionsObjectsPromises);
  }
};

const getFilterArray = async (filterObject) => {
  if (_.isNil(filterObject) || Object.keys(filterObject).length === 0) {
    return [
      {
        filterType: null,
        filterOptions: [],
        allowMultiSelect: null,
        filterOptionsValid: true,
      },
    ];
  }

  const filterArray = await Promise.all(
    Object.entries(filterObject).map(async ([filterType, filterOption]) => {
      const formattedOptions =
        filterOption.length === 0
          ? []
          : await formatFilterOptions(filterType, filterOption);

      return {
        filterType: filterType,
        filterOptions: formattedOptions,
        allowMultiSelect: filterTypeOptions.find(
          (option) => option.value === filterType,
        ).allowMultiSelect,
        filterOptionsValid: true,
      };
    }),
  );

  return filterArray.filter((filter) => filter.filterOptions.length > 0);
};

const parseScheduledDeliveryOptionLabel = (value) => {
  let label = value;
  switch (value) {
    case ScheduleDeliveryOptionsEnum.TODAY:
      label = "Today";
      break;
    case ScheduleDeliveryOptionsEnum.TOMORROW:
      label = "Tomorrow";
      break;
    case ScheduleDeliveryOptionsEnum.THIS_WEEK:
      label = "This Week";
      break;
    case ScheduleDeliveryOptionsEnum.THIS_MONTH:
      label = "This Month";
      break;
    default:
      break;
  }
  return label;
};

const getLabelFromResponse = (filterType, name, code) => {
  switch (filterType) {
    case FilterTypesEnum.CARRIER:
    case FilterTypesEnum.PARTS_NUMBER:
      return `${name}`;
    default:
      return `${name} (${code})`;
  }
};

const fetchFilterOptionsObjects = async (filterType, filterOptions) => {
  const requestUrl = filterOptionUrlLookupTable[filterType];
  const requestParams = getRequestParams();

  try {
    const response = await axios.get(requestUrl, { params: requestParams });
    return response.data;
  } catch (err) {
    return null;
  }

  function getRequestParams() {
    if (filterType === FilterTypesEnum.PARTS_NUMBER) {
      return {
        verbose: false,
        pageNumber: 0,
        pageSize: 20,
        query: filterOptions,
      };
    } else {
      return {
        code: JSON.stringify(filterOptions),
      };
    }
  }
};
