import React from "react";
import { useTranslation } from "react-i18next";
import {
  DateTime,
  DateTimeProps,
  FontSize,
} from "components/atoms/DateTime.atom";
import { Text } from "components/atoms/Text.atom";

export const TimeCell = ({
  dateTime,
  localize,
  bold = false,
}: TimeCellProps) => {
  const { t } = useTranslation("components");

  return (
    <DateTime
      dateTime={dateTime}
      size={FontSize.size12}
      stack
      plain
      bold={bold}
      localize={localize}
      fallback={<Text align="center">{t("components:Not Available")}</Text>}
    >
      <DateTime.Time bold />
      <DateTime.Timezone bold />
    </DateTime>
  );
};

export type TimeCellProps = Pick<
  DateTimeProps,
  "dateTime" | "localize" | "bold"
>;
