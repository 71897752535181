import { connect } from "react-redux";
import {
  getActiveOrganization,
  getFeatureData,
} from "modules/organizations/OrganizationsState";
import { getSolutionId } from "../utils/ContainerTrackingUtils";
import { ContainerTrackingDetails } from "./ContainerTracking.Details.page";
import { ContainerTrackingDetailsWidgetState } from "../redux/ContainerTrackingDetailsWidgetState";
import { ContainerTrackingLocationManagementState } from "../redux/ContainerTrackingLocationManagementState";
import EditShipmentState from "modules/shipment-detail/EditShipmentState";
import ShipmentSubscriptionState from "modules/shipment-detail/ShipmentSubscriptionState";
import MapState from "modules/map/MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import DomainDataState from "modules/domain-data/DomainDataState";
import LocationMatchingState from "modules/location-resolution-edit/LocationMatchingState";
import LadsState from "modules/lads/LadsState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import ErrorState from "modules/errors/ErrorState";
import NotificationsState from "modules/notifications/NotificationsState";

const { getMapCoordinate } = MapState.selectors;

function mapStateToProps(state) {
  const id = state.location.payload.id;
  const {
    getIsFetchingComments,
    getComments,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
  } = ShipmentsStatusState.selectors;

  const {
    getCountries,
    getStates,
    getIsErrorOnSave,
    getUpdateLocations,
    getIsBucketListLoading,
    getBucketDetailsLoading,
    getIsValidatingBucketName,
    getIsFetchingBucketsLocations,
    getIsValidNewBucketName,
    getChangesSavedStatus,
    getIsLocationDetailsLoading,
    getFailedLocations,
    getBucketLocations,
    getLocationDetails,
    getBucketDetails,
    getValidationError,
  } = ContainerTrackingLocationManagementState.selectors;

  const {
    getIsFetchingComments: getIsFetchingContainerComments,
    getComments: getContainerComments,
    getIsBatchCommentInProgress: getIsContainerBatchCommentInProgress,
    getIsBatchCommentSuccessful: getIsContainerBatchCommentSuccessful,
    getIsBatchCommentFailed: getIsContainerBatchCommentFailed,
  } = ContainerTrackingDetailsWidgetState.selectors;
  return {
    id,
    solutionId: getSolutionId(getFeatureData(state)),
    detailsError:
      ContainerTrackingDetailsWidgetState.selectors.getContainerDetailsError(
        state,
      ),
    containerDetails:
      ContainerTrackingDetailsWidgetState.selectors.getContainerDetails(state),
    ctActiveExceptions:
      ContainerTrackingDetailsWidgetState.selectors.getCTActiveExceptions(
        state,
      ),
    isContainerDetailsLoading:
      ContainerTrackingDetailsWidgetState.selectors.getContainerDetailsLoadingFlag(
        state,
      ),
    containerMedia:
      ContainerTrackingDetailsWidgetState.selectors.getContainerMedia(state),
    containerPositionUpdates:
      ContainerTrackingDetailsWidgetState.selectors.getContainerPositionUpdates(
        state,
      ),
    containerLocationDetails:
      ContainerTrackingDetailsWidgetState.selectors.getLocationDetails(state),
    iscontainerLocationDetailsLoading:
      ContainerTrackingDetailsWidgetState.selectors.getLocationDetailsLoadingFlag(
        state,
      ),
    containerSupplierLocationDetails:
      ContainerTrackingDetailsWidgetState.selectors.getSupplierLocationDetails(
        state,
      ),
    iscontainerSupplierLocationLoading:
      ContainerTrackingDetailsWidgetState.selectors.getSupplierLocationDetailsLoadingFlag(
        state,
      ),
    selectedMapCoordinate: getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    containerHistory:
      ContainerTrackingDetailsWidgetState.selectors.getContainerHistory(state),
    organization: getActiveOrganization(state),
    parentShipment:
      ContainerTrackingDetailsWidgetState.selectors.getShipmentDetails(state),
    isShipmentDetailsLoading:
      ContainerTrackingDetailsWidgetState.selectors.getShipmentDetailsIsLoadingFlag(
        state,
      ),
    areBothDetailsLoading:
      ContainerTrackingDetailsWidgetState.selectors.getBothDetailsLoadingFlag(
        state,
      ),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    shipmentSubscription:
      ShipmentSubscriptionState.selectors.getSubscription(state),
    isShipmentSubscriptionLoading:
      ShipmentSubscriptionState.selectors.getIsSubscriptionLoading(state),
    subscriptionRequestError:
      ShipmentSubscriptionState.selectors.getSubscriptionRequestError(state),
    isSubscriptionUpdating:
      ShipmentSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    subscriptionUpdateSuccess:
      ShipmentSubscriptionState.selectors.getSubscriptionUpdateSuccess(state),
    subscriptionUpdateError:
      ShipmentSubscriptionState.selectors.getSubscriptionUpdateError(state),
    editShipmentData: EditShipmentState.selectors.getEditShipmentData(state),
    // Report/Clear Carrier Delay
    reportDelayData: EditShipmentState.selectors.getReportDelayData(state),
    clearDelayData: EditShipmentState.selectors.getClearDelayData(state),
    // Fetch report delay options
    delayOptionsData: EditShipmentState.selectors.getDelayOptionsData(state),
    ladsList: LadsState.selectors.getLadsList(state),
    childShipments:
      ContainerTrackingDetailsWidgetState.selectors.getChildShipmentDetails(
        state,
      ),
    routeHeatmap: ShipmentsStatusState.selectors.getRouteHeatmap(state),
    isFetchingComments: getIsFetchingComments(state),
    isFetchingContainerComments: getIsFetchingContainerComments(state),
    comments: getComments(state),
    containerComments: getContainerComments(state),
    isBatchCommentInProgress: getIsBatchCommentInProgress(state),
    isContainerBatchCommentInProgress:
      getIsContainerBatchCommentInProgress(state),
    isBatchCommentSuccessful: getIsBatchCommentSuccessful(state),
    isContainerBatchCommentSuccessful:
      getIsContainerBatchCommentSuccessful(state),
    isBatchCommentFailed: getIsBatchCommentFailed(state),
    isContainerBatchCommentFailed: getIsContainerBatchCommentFailed(state),
    isLocationDetailsLoading: getIsLocationDetailsLoading(state),
    locationDetails: getLocationDetails(state),
    bucketDetails: getBucketDetails(state),
    isValidNewBucketName: getIsValidNewBucketName(state),
    selectedBucketLocations: getBucketLocations(state),
    isBucketListLoading: getIsBucketListLoading(state),
    isBucketDetailsLoading: getBucketDetailsLoading(state),
    isValidatingBucketName: getIsValidatingBucketName(state),
    isFetchingBucketLocations: getIsFetchingBucketsLocations(state),
    locationChangesSavedStatus: getChangesSavedStatus(state),
    failedLocations: getFailedLocations(state),
    isErrorOnSave: getIsErrorOnSave(state),
    updatedLocations: getUpdateLocations(state),
    countryList: getCountries(state),
    stateList: getStates(state),
    validationError: getValidationError(state),
  };
}

const {
  clearContainerMedia,
  fetchContainerDetails,
  fetchContainerActiveExceptions,
  fetchContainerMedia,
  fetchContainerSupplierLocationDetails,
  clearContainerDetails,
  fetchContainerHistory,
  watchContainerActions,
  fetchShipmentDetails,
  fetchComments: fetchContainerComments,
  addComment: addContainerComments,
  addBatchComments: addContainerBatchComments,
  clearBatchComments: clearContainerBatchComments,
  cancelAddComment: cancelContainerAddComment,
  updateComment: updateContainerComment,
  cancelUpdateComment: cancelContainerUpdateComment,
  markCommentsRead: markContainerCommentsRead,
} = ContainerTrackingDetailsWidgetState.actionCreators;

const {
  setWatchShipment,
  updateReviewStatus,
  assignAsset,
  assignTrailer,
  createShipmentEvents,
  clearReportShipmentEvents,
  updateShipment,
  cancelShipment,
  startMobileTracking,
  stopMobileTracking,
  clearDelay,
  clearReportDelayInState,
  reportDelay,
  fetchDelayOptions,
} = EditShipmentState.actionCreators;

const {
  fetchSubscription: fetchShipmentSubscription,
  subscribe,
  unsubscribe,
  updateSubscription,
} = ShipmentSubscriptionState.actionCreators;

const { redirectTo404 } = ErrorState.actionCreators;

const {
  fetchComments,
  addComment,
  addBatchComments,
  clearBatchComments,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
} = ShipmentsStatusState.actionCreators;

const { fetchNotification } = NotificationsState.actionCreators;

const {
  fetchBucketDetailsFromName,
  resetFailedLocationsLists,
  resetSavedChanges,
  resetError,
  resetBucketLoctions,
  resetUpdatedLocations,
  updateLocationsOnFail,
  fetchCountries,
  fetchStates,
  updateBucketLocations,
  updateLocationsData,
  fetchLocation,
  fetchBucket,
  resetValidationError,
} = ContainerTrackingLocationManagementState.actionCreators;

const { addCoordinate, clearCoordinatesByType, clearAllCoordinates } =
  MapState.actionCreators;

const { setShipmentWithUnresolvedLocation } =
  LocationMatchingState.actionCreators;

const actionCreators = {
  clearContainerMedia,
  fetchContainerDetails,
  fetchContainerActiveExceptions,
  fetchContainerMedia,
  fetchContainerSupplierLocationDetails,
  clearContainerDetails,
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  fetchContainerHistory,
  watchContainerActions,
  fetchShipmentDetails,
  setWatchShipment,
  subscribe,
  unsubscribe,
  updateSubscription,
  fetchShipmentSubscription,
  fetchDelayOptions,
  fetchBucketDetailsFromName,
  updateBucketLocations,
  resetFailedLocationsLists,
  resetSavedChanges,
  updateLocationsOnFail,
  resetUpdatedLocations,
  fetchCountries,
  fetchStates,
  fetchLocation,
  fetchBucket,
  resetError,
  resetValidationError,
  resetBucketLoctions,
  updateLocationsData,
  clearReportShipmentEvents, // Clear Report Shipment Event Data In Redux action
  updateReviewStatus: (shipmentID, reviewStatus, notes) => {
    return (dispatch) => {
      dispatch(
        updateReviewStatus(
          shipmentID,
          reviewStatus,
          notes,
          fetchShipmentDetails,
        ),
      );
    };
  },
  assignAsset: (shipment, organization, assetID, assignDate, notes) => {
    return (dispatch) => {
      dispatch(
        assignAsset(
          shipment,
          organization,
          assetID,
          assignDate,
          notes,
          fetchShipmentDetails,
        ),
      );
    };
  },
  assignTrailer: (shipment, trailerNumber) => {
    return (dispatch) => {
      dispatch(assignTrailer(shipment, trailerNumber, fetchShipmentDetails));
    };
  },
  createShipmentEvents: (
    shipment,
    organization,
    eventData,
    notes,
    onSuccess,
  ) => {
    return (dispatch) => {
      dispatch(
        createShipmentEvents(
          shipment,
          organization,
          eventData,
          notes,
          onSuccess,
        ),
      );
    };
  },
  updateShipment: (shipment, payload, fetchShipmentDetails) => {
    return (dispatch) => {
      dispatch(updateShipment(shipment, payload, fetchShipmentDetails));
    };
  },
  cancelShipment: (shipment, notes) => {
    return (dispatch) => {
      dispatch(cancelShipment(shipment, notes, fetchShipmentDetails));
    };
  },
  startMobileTracking: (shipment, organization, phoneNumber, notes) => {
    return (dispatch) => {
      dispatch(
        startMobileTracking(
          shipment,
          organization,
          phoneNumber,
          notes,
          fetchShipmentDetails,
        ),
      );
    };
  },
  stopMobileTracking: (shipment, organization) => {
    return (dispatch) => {
      dispatch(
        stopMobileTracking(shipment, organization, fetchShipmentDetails),
      );
    };
  },
  // Clear Delay actions
  clearDelay: (shipment, organization, onSuccess) => {
    return (dispatch) => {
      dispatch(
        clearDelay(shipment, organization, onSuccess, fetchShipmentDetails),
      );
    };
  },
  // Report Delay actions
  reportDelay: (
    shipment,
    organization,
    reasonCode,
    frozenEta,
    comments,
    importShipmentIDs,
    onSuccess,
  ) => {
    return (dispatch) => {
      dispatch(
        reportDelay(
          shipment,
          organization,
          reasonCode,
          frozenEta,
          comments,
          importShipmentIDs,
          onSuccess,
          fetchShipmentDetails,
        ),
      );
    };
  },

  //Clear Delay Data In Redux action
  clearReportDelayInState: () => {
    return (dispatch) => {
      dispatch(clearReportDelayInState());
    };
  },
  setShipmentWithUnresolvedLocation: (shipment) => {
    return (dispatch) => {
      dispatch(setShipmentWithUnresolvedLocation(shipment));
    };
  },
  pushLocationMatchingView: (locID) => {
    return (dispatch) => {
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID },
      });
    };
  },
  redirectTo404,
  fetchComments,
  addComment,
  addBatchComments,
  clearBatchComments,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchNotification,
  fetchContainerComments,
  addContainerComments,
  addContainerBatchComments,
  clearContainerBatchComments,
  cancelContainerAddComment,
  updateContainerComment,
  cancelContainerUpdateComment,
  markContainerCommentsRead,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return { ...stateProps, ...dispatchProps, ...ownProps };
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(ContainerTrackingDetails);
