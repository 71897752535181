/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";

import {
  getColumns,
  treeMiddleAndEndColumn,
} from "pages/reports/search/Reports.columns";

const tableCss = {
  ".table": {
    overflow: "hidden !important",
  },
};

export const ReportSubFolderDataTable = (props) => {
  const {
    fetchSubFolderData,
    getSubFolderByQualifier,
    directoryId,
    reportType,
    hasManageSharedReportsPrivilege,
    hasReportBuilderPrivilege,
    pushReportDetailScreen,
    pushFilteredReportDetailScreen,
    actionHandler,
    hasManageReportDirectory,
    isFVAdmin,
    appliedFilters,
    sortColumn,
    reverseSort,
  } = props;
  const { t } = useTranslation("reports");

  useEffect(() => {
    if (directoryId !== undefined) {
      fetchSubFolderData(
        directoryId,
        reportType,
        appliedFilters,
        sortColumn,
        reverseSort,
      );
    }
    //eslint-disable-next-line
  }, [reportType, fetchSubFolderData, appliedFilters]);

  const reportList = useSelector(getSubFolderByQualifier(directoryId));
  const reportLisData = reportList?.data ?? [];
  const isLoading = reportList?.isLoading ?? false;

  const mapFilterSet = (report) => {
    return {
      filterSetId: report.filter_set.filter_set_id,
      name: report.filter_set.name,
      description: report.filter_set.description
        ? report.filter_set.description
        : "",
      createdBy: report.filter_set.email,
      lastUpdated: report.filter_set.last_updated_at,
      createdOn: report.filter_set.created_at,
      private: report.filter_set.private,
      filters: report.filter_set.filters,
      isNew: report.filter_set.new,
    };
  };

  const formattedData = reportLisData.map((report, index) => {
    return {
      name: report.name,
      index: index,
      accessToken: report.token,
      embedUrl: report.embedUrl,
      reportId: report.report_id,
      description: report.description ? report.description : "",
      groupName: report.group_name,
      workspaceId: report.workspace_id,
      lastUpdated: report.last_updated_at,
      private: report.private,
      isNew: report.new,
      createdBy: report.email,
      source: report.source,
      originalReportName: report.original_report_name,
      filterSet: report.filter_set ? mapFilterSet(report) : null,
      filtersDetail: report.filters_detail,
      createFilterSet: report.create_filter_set,
      emailScheduleCount: report.email_schedule_count,
      isReport: report.isReport,
      directoryId: directoryId,
    };
  });

  return (
    <div className="folder-reports-wrapper" css={tableCss}>
      <BaseTable
        columns={[
          treeMiddleAndEndColumn,
          ...getColumns(
            t,
            reportType,
            hasReportBuilderPrivilege,
            hasManageSharedReportsPrivilege,
            hasManageReportDirectory,
            isFVAdmin,
            actionHandler,
          ),
        ]}
        data={formattedData}
        isLoading={isLoading}
        theme={Themes.LIGHT}
        // disableSortBy={true}
        hideHeader={true}
        hideBorder={true}
        rowClickHandler={(row, cell) => {
          if (
            cell.column.id === "user-actions" ||
            cell.column.id === "reportId"
          ) {
            return;
          } else {
            const directory = row.original.directory;
            const reportId = row.original.reportId;
            const filterSetId =
              row.original.filterSet && row.original.filterSet.filterSetId
                ? row.original.filterSet.filterSetId
                : null;
            if (directory) {
              return;
            }
            if (filterSetId) {
              pushFilteredReportDetailScreen(reportId, filterSetId);
            } else {
              pushReportDetailScreen(reportId);
            }
          }
        }}
        noDataFoundMessage={t(
          'reports:This folder contains no reports. Click on the "Move Report" button for a report to move it here.',
        )}
      />
    </div>
  );
};

ReportSubFolderDataTable.propTypes = {
  appliedFilters: PropTypes.array,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  directoryId: PropTypes.number,
  fetchSubFolderData: PropTypes.func.isRequired,
  getSubFolderByQualifier: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  hasManageSharedReportsPrivilege: PropTypes.bool.isRequired,
  hasReportBuilderPrivilege: PropTypes.bool.isRequired,
  hasManageReportDirectory: PropTypes.bool.isRequired,
  isFVAdmin: PropTypes.bool.isRequired,
  actionHandler: PropTypes.func.isRequired,
  pushReportDetailScreen: PropTypes.func.isRequired,
  pushFilteredReportDetailScreen: PropTypes.func.isRequired,
};
