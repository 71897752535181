/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import Colors from "styles/colors";

const propTypes = {
  total: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const TableMetaTotalRecords = ({ total, label, className }) => {
  return (
    <div
      style={{
        fontWeight: 600,
        color: Colors.background.GRAY_BLUE,
        whiteSpace: "nowrap",
      }}
      className={className}
    >{`${total} ${label}`}</div>
  );
};

TableMetaTotalRecords.propTypes = propTypes;

export default TableMetaTotalRecords;
