/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import React, { useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { faFolderPlus } from "@fortawesome/pro-regular-svg-icons";

import { Tabs } from "components/molecules/Tabs.molecule";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { Text } from "components/atoms/Text.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Privileges } from "modules/auth/Authorization";
import { SelectScheduleModal } from "./components/EmailReport.modals";
import ReportsFilterModalContainer from "./components/ReportsFilter.modal.container";
import SendEmailModalContainer from "./components/SendEmail.modal.container";
import EmailScheduleModalContainer from "./components/EmailSchedule.modal.container";
import MoveFolderModalContainer from "./components/MoveFolder.modal.container";
import CreateOrEditFolderModalContainer from "./components/CreateOrEditFolder.modal.container";
import ReportSchedulesState from "pages/reports/redux/ReportSchedulesState";
import { ReportsTable } from "pages/reports/bi-dashboard/components/ReportsTable";
import { ReportTypes } from "pages/reports/search/Reports.columns";

const { getSchedulesKey } = ReportSchedulesState.helpers;

// Map each table column accessor with a search filter for the API request
const tableCoreFilterMap = {
  name: "name",
  description: "description",
};

// Map each table column accessor with a search filter for the API request
const tableSharedFilterMap = {
  name: "name",
  description: "description",
  createdBy: "createdBy",
  source: "reportSource",
};

// Map each table column accessor with a search filter for the API request
const tableMineFilterMap = {
  name: "name",
  description: "description",
  source: "reportSource",
};

const Actions = {
  CREATE_FILTERED_REPORT: "CREATE_FILTERED_REPORT",
  UPDATE_FILTERED_REPORT: "UPDATE_FILTERED_REPORT",
  DELETE_FILTERED_REPORT: "DELETE_FILTERED_REPORT",
  SEND_EMAIL: "SEND_EMAIL",
  SELECT_SCHEDULE: "SELECT_SCHEDULE",
  CREATE_SCHEDULE: "CREATE_SCHEDULE",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  EDIT_REPORT: "EDIT_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  CREATE_REPORT_FOLDER: "CREATE_REPORT_FOLDER",
  EDIT_FOLDER_REPORT: "EDIT_FOLDER_REPORT",
  MOVE_FOLDER_REPORT: "MOVE_FOLDER_REPORT",
  FOLDERS_LIST: "FOLDERS_LIST",
  DELETE_FOLDER_REPORT: "DELETE_FOLDER_REPORT",
};

export const ModalMode = {
  FILTERED_REPORT: "FILTERED_REPORT",
  SEND_EMAIL: "SEND_EMAIL",
  SCHEDULE_EMAIL: "SCHEDULE_EMAIL",
  SELECT_SCHEDULE: "SELECT_SCHEDULE",
  MOVE_FOLDER_REPORT: "MOVE_FOLDER_REPORT",
  FOLDER_MODAL: "FOLDER_MODAL",
};

const initialShowModalState = {
  modalMode: null,
  reportType: null,
  report: null,
  schedule: null,
  toggleCreateAndEditModal: null,
};

const ReportTableHeader = ({
  t,
  description,
  showCreateFolderButton,
  isLoading,
  actionHandler,
  data,
}) => {
  return (
    <div
      style={{
        padding: "0 1em 1em",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text>{description}</Text>
      {showCreateFolderButton ? (
        <Button
          variant="success"
          style={{ display: "flex", gap: "0.75em" }}
          // Modal gets closed once results are loaded if Modal opened while results are loading
          // So disabling the button till results are loaded
          disabled={isLoading}
          onClick={() => {
            return actionHandler("CREATE_REPORT_FOLDER", data);
          }}
        >
          <Icon src={faFolderPlus} size={FontSize.size20} />
          <Text>{t("reports:Create folder")}</Text>
        </Button>
      ) : null}
    </div>
  );
};

ReportTableHeader.propTypes = {
  t: PropTypes.func,
  description: PropTypes.string,
  showCreateFolderButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  actionHandler: PropTypes.func,
  data: PropTypes.object,
};

const Reports = (props) => {
  const {
    reportSchedules,
    solutionId,
    authorization,
    getSubFolderByQualifier,
    //Reports SearchBarState - Share
    sharedReportsResults,
    sharedReportsIsLoading,
    sharedPage,
    sharedPageSize,
    sharedTotalPages,
    sharedReportsSortColumn,
    sharedReportsReverseSort,
    sharedReportsDefaultSortColumn,
    sharedReportsDefaultReverseSort,
    sharedReportsSearchFilters,
    setSharedSearchFilter,
    clearSharedSearchText,
    clearSharedSearchFilters,
    clearSharedSearchFilter,
    searchSharedReports,
    setSharedSort,
    setSharedPagination,
    //Reports SearchBarState - Core
    coreReportsResults,
    coreReportsIsLoading,
    corePage,
    corePageSize,
    coreTotalPages,
    coreReportsDefaultSortColumn,
    coreReportsDefaultReverseSort,
    coreReportsSearchFilters,
    setCoreSearchFilter,
    clearCoreSearchText,
    clearCoreSearchFilters,
    clearCoreSearchFilter,
    searchCoreReports,
    setCoreSort,
    setCorePagination,
    coreReportsSortColumn,
    coreReportsReverseSort,
    //Reports SearchBarState - My
    mineReportsResults,
    mineReportsSearchFilters,
    mineReportsIsLoading,
    minePage,
    minePageSize,
    mineTotalPages,
    setMineSearchFilter,
    clearMineSearchText,
    clearMineSearchFilters,
    clearMineSearchFilter,
    searchMineReports,
    mineReportsSortColumn,
    mineReportsReverseSort,
    mineReportsDefaultReverseSort,
    mineReportsDefaultSortColumn,
    setMineSort,
    setMinePagination,

    //dispatch actions
    deleteReport,
    deleteFilteredReport,
    fetchSchedule,
    fetchCategory,
    deleteFolderModal,
    fetchFolder,
    fetchSubFolderData,
    pushReportDetailScreen,
    pushFilteredReportDetailScreen,
  } = props;
  const { t } = useTranslation("reports");

  useSetTitleOnMount(t("reports:BI Dashboard"));
  useSetDescriptionOnMount(
    t(
      "reports:Business intelligence reporting platform for organizational analytics",
    ),
  );
  useTrackWithMixpanelOnce("Viewed Page: BI / Reports / Dashboard");

  let filtersCore = useRef([]);
  let filtersShared = useRef([]);
  let filtersMine = useRef([]);

  const [folderCategory, setFolderCategory] = useState(null);
  const [showModal, setShowModal] = useState(initialShowModalState);
  const [showDeleteReportDialog, setShowDeleteReportDialog] = useState({
    show: false,
    report: null,
    reportType: null,
    onCancel: null,
  });
  const [showDeleteFilteredReportDialog, setShowDeleteFilteredReportDialog] =
    useState({ show: false, report: null, reportType: null, onCancel: null });
  const [showDeleteFolderDialog, setShowDeleteFolderDialog] = useState({
    show: false,
    report: null,
    reportType: null,
    onCancel: null,
  });

  const closeModal = useCallback(() => {
    setShowModal(initialShowModalState);
  }, []);

  const modalReportType = useCallback(
    (reportType) => {
      switch (reportType) {
        case ReportTypes.CORE_REPORTS:
          searchCoreReports(solutionId);
          break;
        case ReportTypes.SHARED_REPORTS:
          searchSharedReports(solutionId);
          break;
        case ReportTypes.MY_REPORTS:
          searchMineReports(solutionId);
          break;
        default:
          return reportType;
      }
    },
    [searchCoreReports, searchMineReports, searchSharedReports, solutionId],
  );

  const deleteReportInFolderHandler = useCallback(
    (reportType, report) => {
      let sortColumn;
      let reverseSort;
      if (reportType === ReportTypes.CORE_REPORTS) {
        sortColumn = coreReportsSortColumn;
        reverseSort = coreReportsReverseSort;
        fetchSubFolderData(
          report.directoryId,
          reportType,
          filtersCore.current,
          sortColumn,
          reverseSort,
        );
      } else if (reportType === ReportTypes.SHARED_REPORTS) {
        sortColumn = sharedReportsSortColumn;
        reverseSort = sharedReportsReverseSort;
        fetchSubFolderData(
          report.directoryId,
          reportType,
          filtersShared.current,
          sortColumn,
          reverseSort,
        );
      } else if (reportType === ReportTypes.MY_REPORTS) {
        sortColumn = mineReportsSortColumn;
        reverseSort = mineReportsReverseSort;
        fetchSubFolderData(
          report.directoryId,
          reportType,
          filtersMine.current,
          sortColumn,
          reverseSort,
        );
      }
    },
    [
      coreReportsReverseSort,
      coreReportsSortColumn,
      fetchSubFolderData,
      mineReportsReverseSort,
      mineReportsSortColumn,
      sharedReportsSortColumn,
      sharedReportsReverseSort,
    ],
  );

  const actionHandler = useCallback(
    (action, data) => {
      switch (action) {
        case Actions.CREATE_FILTERED_REPORT: {
          const { reportType, report } = data;

          // Display ReportsFilterModal
          setShowModal({
            modalMode: ModalMode.FILTERED_REPORT,
            reportType: reportType,
            report: report,
          });
          break;
        }
        case Actions.UPDATE_FILTERED_REPORT: {
          const { reportType, report } = data;

          // Display ReportsFilterModal
          setShowModal({
            modalMode: ModalMode.FILTERED_REPORT,
            reportType: reportType,
            report: report,
          });
          break;
        }
        case Actions.DELETE_FILTERED_REPORT: {
          const { reportType, report, onCancel } = data;

          setShowDeleteFilteredReportDialog({
            show: true,
            report: report,
            reportType: reportType,
            onCancel: onCancel,
          });
          break;
        }
        case Actions.EDIT_REPORT: {
          const { report } = data;

          // Go to the report itself so the user can make changes and save
          pushReportDetailScreen(report.reportId);
          break;
        }
        case Actions.SEND_EMAIL: {
          const { report } = data;

          // Display ReportsEmailModal
          setShowModal({
            modalMode: ModalMode.SEND_EMAIL,
            report: report,
          });
          break;
        }
        case Actions.SELECT_SCHEDULE: {
          const { report } = data;
          fetchSchedule(report);

          setShowModal({
            modalMode: ModalMode.SELECT_SCHEDULE,
            report: report,
          });
          break;
        }
        case Actions.CREATE_SCHEDULE: {
          const { report } = data;

          // Display ReportsScheduleModal
          setShowModal({
            modalMode: ModalMode.SCHEDULE_EMAIL,
            report: report,
          });
          break;
        }
        case Actions.UPDATE_SCHEDULE: {
          const { schedule, report } = data;

          // Display ReportsScheduleModal
          setShowModal({
            modalMode: ModalMode.SCHEDULE_EMAIL,
            schedule: schedule,
            report: report,
          });
          break;
        }
        case Actions.DELETE_REPORT: {
          const { reportType, report, onCancel } = data;

          setShowDeleteReportDialog({
            show: true,
            report: report,
            reportType: reportType,
            onCancel: onCancel,
          });
          break;
        }

        case Actions.CREATE_REPORT_FOLDER: {
          const { folderCategory, report } = data;
          // Display
          setFolderCategory(folderCategory);
          setShowModal({
            modalMode: ModalMode.FOLDER_MODAL,
            report: report,
            toggleCreateAndEditModal: true,
          });
          break;
        }

        case Actions.EDIT_FOLDER_REPORT: {
          const { folderCategory, report } = data;
          // Display
          setFolderCategory(folderCategory);
          // Display ReportsFilterModal
          setShowModal({
            modalMode: ModalMode.FOLDER_MODAL,
            report: report,
            toggleCreateAndEditModal: false,
          });
          break;
        }

        case Actions.MOVE_FOLDER_REPORT: {
          const { reportType, report } = data;
          // Display Move Folder Modal
          setShowModal({
            modalMode: ModalMode.MOVE_FOLDER_REPORT,
            report: report,
            reportType: reportType,
          });

          break;
        }
        case Actions.FOLDERS_LIST: {
          const { categoryType } = data;
          fetchFolder(categoryType);
          break;
        }

        case Actions.DELETE_FOLDER_REPORT: {
          const { reportType, report, onCancel } = data;

          setShowDeleteFolderDialog({
            show: true,
            report: report,
            reportType: reportType,
            onCancel: onCancel,
          });
          break;
        }

        default: {
          console.error("Undefined Action");
        }
      }
    },
    [fetchFolder, fetchSchedule, pushReportDetailScreen],
  );

  let hasReportBuilderPrivilege = authorization.hasPrivileges([
    Privileges.BUILD_REPORT,
  ]);

  let hasManageSharedReportsPrivilege = authorization.hasPrivileges([
    Privileges.MANAGE_SHARED_REPORTS,
  ]);

  let isFVAdmin = authorization.isFvAdmin();

  let hasManageReportDirectory = authorization.hasPrivileges([
    Privileges.MANAGE_REPORT_DIRECTORY,
  ]);

  return (
    <React.Fragment>
      <Tabs fullWidthTabs style={{ marginLeft: "1em", marginRight: "1em" }}>
        <Tabs.TabList>
          <Tabs.Tab>
            <Text>{t("reports:Core Reports")}</Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text>{t("reports:Shared Reports")}</Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text>{t("reports:My Reports")}</Text>
          </Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanel style={{ paddingTop: "1em" }}>
          <ReportTableHeader
            t={t}
            description={t(
              "reports:These reports are provided by the FreightVerify Reporting Team.",
            )}
            showCreateFolderButton={isFVAdmin}
            isLoading={coreReportsIsLoading}
            actionHandler={actionHandler}
            data={{
              report: props, // the report state holds everything in the state
              folderCategory: ReportTypes.CORE_REPORTS,
            }}
          />
          <ReportsTable
            solutionId={solutionId}
            type={ReportTypes.CORE_REPORTS}
            tableFilterMap={tableCoreFilterMap}
            filtersRef={filtersCore}
            isFVAdmin={isFVAdmin}
            actionHandler={actionHandler}
            hasReportBuilderPrivilege={hasReportBuilderPrivilege}
            hasManageSharedReportsPrivilege={hasManageSharedReportsPrivilege}
            hasManageReportDirectory={hasManageReportDirectory}
            reportsResults={coreReportsResults}
            reportsIsLoading={coreReportsIsLoading}
            page={corePage}
            pageSize={corePageSize}
            totalPages={coreTotalPages}
            reportsDefaultSortColumn={coreReportsDefaultSortColumn}
            reportsDefaultReverseSort={coreReportsDefaultReverseSort}
            reportsSearchFilters={coreReportsSearchFilters}
            setSearchFilter={setCoreSearchFilter}
            clearSearchText={clearCoreSearchText}
            clearSearchFilters={clearCoreSearchFilters}
            clearSearchFilter={clearCoreSearchFilter}
            searchReports={searchCoreReports}
            setSort={setCoreSort}
            setPagination={setCorePagination}
            reportsSortColumn={coreReportsSortColumn}
            reportsReverseSort={coreReportsReverseSort}
            getSubFolderByQualifier={getSubFolderByQualifier}
            fetchSubFolderData={fetchSubFolderData}
            pushReportDetailScreen={pushReportDetailScreen}
            pushFilteredReportDetailScreen={pushFilteredReportDetailScreen}
            closeModal={closeModal}
            setFolderCategory={setFolderCategory}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel style={{ paddingTop: "1em" }}>
          <ReportTableHeader
            t={t}
            description={t(
              "reports:These reports have been created as Filtered Reports and are shared across your organization.",
            )}
            showCreateFolderButton={isFVAdmin || hasManageReportDirectory}
            isLoading={sharedReportsIsLoading}
            actionHandler={actionHandler}
            data={{
              report: props, // the report state holds everything in the state
              folderCategory: ReportTypes.SHARED_REPORTS,
            }}
          />
          <ReportsTable
            reportSchedules={reportSchedules}
            solutionId={solutionId}
            type={ReportTypes.SHARED_REPORTS}
            tableFilterMap={tableSharedFilterMap}
            filtersRef={filtersShared}
            isFVAdmin={isFVAdmin}
            actionHandler={actionHandler}
            hasReportBuilderPrivilege={hasReportBuilderPrivilege}
            hasManageSharedReportsPrivilege={hasManageSharedReportsPrivilege}
            hasManageReportDirectory={hasManageReportDirectory}
            reportsResults={sharedReportsResults}
            reportsIsLoading={sharedReportsIsLoading}
            page={sharedPage}
            pageSize={sharedPageSize}
            totalPages={sharedTotalPages}
            reportsSortColumn={sharedReportsSortColumn}
            reportsReverseSort={sharedReportsReverseSort}
            reportsDefaultSortColumn={sharedReportsDefaultSortColumn}
            reportsDefaultReverseSort={sharedReportsDefaultReverseSort}
            reportsSearchFilters={sharedReportsSearchFilters}
            setSearchFilter={setSharedSearchFilter}
            clearSearchText={clearSharedSearchText}
            clearSearchFilters={clearSharedSearchFilters}
            clearSearchFilter={clearSharedSearchFilter}
            searchReports={searchSharedReports}
            setSort={setSharedSort}
            setPagination={setSharedPagination}
            getSubFolderByQualifier={getSubFolderByQualifier}
            fetchSubFolderData={fetchSubFolderData}
            pushReportDetailScreen={pushReportDetailScreen}
            pushFilteredReportDetailScreen={pushFilteredReportDetailScreen}
            closeModal={closeModal}
            setFolderCategory={setFolderCategory}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel style={{ paddingTop: "1em" }}>
          <ReportTableHeader
            t={t}
            description={t(
              "reports:These reports have been created as Filtered Reports and are only visible to you.",
            )}
            showCreateFolderButton={true}
            isLoading={mineReportsIsLoading}
            actionHandler={actionHandler}
            data={{
              report: props, // the report state holds everything in the state
              folderCategory: ReportTypes.MY_REPORTS,
            }}
          />
          <ReportsTable
            reportSchedules={reportSchedules}
            solutionId={solutionId}
            type={ReportTypes.MY_REPORTS}
            tableFilterMap={tableMineFilterMap}
            filtersRef={filtersMine}
            isFVAdmin={isFVAdmin}
            actionHandler={actionHandler}
            hasReportBuilderPrivilege={hasReportBuilderPrivilege}
            hasManageSharedReportsPrivilege={hasManageSharedReportsPrivilege}
            hasManageReportDirectory={hasManageReportDirectory}
            reportsResults={mineReportsResults}
            reportsSearchFilters={mineReportsSearchFilters}
            reportsIsLoading={mineReportsIsLoading}
            page={minePage}
            pageSize={minePageSize}
            totalPages={mineTotalPages}
            setSearchFilter={setMineSearchFilter}
            clearSearchText={clearMineSearchText}
            clearSearchFilters={clearMineSearchFilters}
            clearSearchFilter={clearMineSearchFilter}
            searchReports={searchMineReports}
            reportsSortColumn={mineReportsSortColumn}
            reportsReverseSort={mineReportsReverseSort}
            reportsDefaultReverseSort={mineReportsDefaultReverseSort}
            reportsDefaultSortColumn={mineReportsDefaultSortColumn}
            setSort={setMineSort}
            setPagination={setMinePagination}
            getSubFolderByQualifier={getSubFolderByQualifier}
            fetchSubFolderData={fetchSubFolderData}
            pushReportDetailScreen={pushReportDetailScreen}
            pushFilteredReportDetailScreen={pushFilteredReportDetailScreen}
            closeModal={closeModal}
            setFolderCategory={setFolderCategory}
          />
        </Tabs.TabPanel>
      </Tabs>

      <ReportsFilterModalContainer
        report={showModal.report}
        show={showModal.modalMode === ModalMode.FILTERED_REPORT}
        hide={closeModal}
        reportType={showModal.reportType}
        modalReportType={modalReportType}
      />
      <SendEmailModalContainer
        show={showModal.modalMode === ModalMode.SEND_EMAIL}
        hide={closeModal}
        report={showModal?.report}
      />
      <SelectScheduleModal
        show={showModal.modalMode === ModalMode.SELECT_SCHEDULE}
        hide={closeModal}
        report={showModal.report}
        isLoading={
          reportSchedules?.[
            getSchedulesKey(
              showModal.report?.reportId,
              showModal.report?.filterSet?.filterSetId,
            )
          ]?.isLoading
        }
        showCreateScheduleModal={() =>
          actionHandler(Actions.CREATE_SCHEDULE, { report: showModal.report })
        }
        showUpdateScheduleModal={(schedule) => {
          actionHandler(Actions.UPDATE_SCHEDULE, {
            schedule,
            report: showModal.report,
          });
        }}
        hasManageSharedReportsPrivilege={hasManageSharedReportsPrivilege}
      />
      <EmailScheduleModalContainer
        show={showModal.modalMode === ModalMode.SCHEDULE_EMAIL}
        hide={closeModal}
        report={showModal?.report}
        schedule={showModal?.schedule}
        fetchSchedule={fetchSchedule}
      />

      <CreateOrEditFolderModalContainer
        report={showModal.report}
        show={showModal.modalMode === ModalMode.FOLDER_MODAL}
        hide={closeModal}
        toggleCreateAndEditModal={showModal?.toggleCreateAndEditModal}
        folderCategory={folderCategory}
        modalReportType={modalReportType}
      />

      <MoveFolderModalContainer
        show={showModal.modalMode === ModalMode.MOVE_FOLDER_REPORT}
        hide={closeModal}
        report={showModal.report}
        fetchCategory={fetchCategory}
        reportType={showModal.reportType}
        actionHandler={actionHandler}
        modalReportType={modalReportType}
        isFVAdmin={isFVAdmin}
      />
      <DialogModal
        show={showDeleteReportDialog.show}
        onHide={() => {
          showDeleteReportDialog.onCancel();
          setShowDeleteReportDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
        title={t("reports:Confirm Report Deletion")}
        cancelButtonText={t("reports:Cancel")}
        submitButtonText={t("reports:Delete Report")}
        submitButtonVariant="danger"
        onSubmit={() => {
          deleteReport(showDeleteReportDialog.report.reportId).then(() => {
            if (showDeleteReportDialog.report.directoryId) {
              deleteReportInFolderHandler(
                showDeleteReportDialog.reportType,
                showDeleteReportDialog.report,
              );
            } else {
              modalReportType(showDeleteReportDialog.reportType);
            }
          });
          setShowDeleteReportDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
      >
        <Text block>
          {t("reports:Are you sure you want to delete this report?")}
        </Text>
        <Text block bold style={{ marginTop: 10 }}>
          {showDeleteReportDialog?.report?.name}
        </Text>
      </DialogModal>
      <DialogModal
        show={showDeleteFilteredReportDialog.show}
        onHide={() => {
          showDeleteFilteredReportDialog.onCancel();
          setShowDeleteFilteredReportDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
        title={t("reports:Confirm Report Deletion")}
        cancelButtonText={t("reports:Cancel")}
        submitButtonText={t("reports:Delete Report")}
        submitButtonVariant="danger"
        onSubmit={() => {
          deleteFilteredReport(
            showDeleteFilteredReportDialog.report.reportId,
            showDeleteFilteredReportDialog.report.filterSet.filterSetId,
          ).then(() => {
            if (showDeleteFilteredReportDialog.report.directoryId) {
              deleteReportInFolderHandler(
                showDeleteFilteredReportDialog.reportType,
                showDeleteFilteredReportDialog.report,
              );
            } else {
              modalReportType(showDeleteFilteredReportDialog.reportType);
            }
          });
          setShowDeleteFilteredReportDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
      >
        <Text block>
          {t("reports:Are you sure you want to delete this report?")}
        </Text>
        <Text block bold style={{ marginTop: 10 }}>
          {showDeleteFilteredReportDialog?.report?.name}
        </Text>
      </DialogModal>
      <DialogModal
        show={showDeleteFolderDialog.show}
        onHide={() => {
          showDeleteFolderDialog.onCancel();
          setShowDeleteFolderDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
        title={t("reports:Confirm Folder Deletion")}
        cancelButtonText={t("reports:Cancel")}
        submitButtonText={t("reports:Delete Folder")}
        submitButtonVariant="danger"
        onSubmit={() => {
          deleteFolderModal(showDeleteFolderDialog.report).then(() => {
            modalReportType(showDeleteFolderDialog.reportType);
          });
          setShowDeleteFolderDialog({
            show: false,
            report: null,
            reportType: null,
            onCancel: null,
          });
        }}
      >
        <Text block>
          {t(
            "reports:Deleting this folder will move the reports outside of the deleted folder. Are you sure you want to delete this folder?",
          )}
        </Text>
        <Text block bold style={{ marginTop: 10 }}>
          {showDeleteFolderDialog?.report?.name}
        </Text>
      </DialogModal>
    </React.Fragment>
  );
};

Reports.propTypes = {
  //state
  reportSchedules: PropTypes.object,
  solutionId: PropTypes.string,
  authorization: PropTypes.object.isRequired,
  getSubFolderByQualifier: PropTypes.func,
  fetchSchedule: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  fetchFolder: PropTypes.func.isRequired,
  deleteFolderModal: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  deleteFilteredReport: PropTypes.func.isRequired,
  pushReportDetailScreen: PropTypes.func.isRequired,
  pushFilteredReportDetailScreen: PropTypes.func.isRequired,
  fetchSubFolderData: PropTypes.func,
  //shared reports props
  sharedReportsResults: PropTypes.array,
  sharedReportsIsLoading: PropTypes.bool,
  sharedPage: PropTypes.number,
  sharedPageSize: PropTypes.number,
  sharedTotalPages: PropTypes.number,
  sharedReportsDefaultSortColumn: PropTypes.string,
  sharedReportsDefaultReverseSort: PropTypes.bool,
  sharedReportsSortColumn: PropTypes.string,
  sharedReportsReverseSort: PropTypes.bool,
  sharedReportsSearchFilters: PropTypes.object,
  setSharedPagination: PropTypes.func,
  setSharedSort: PropTypes.func,
  setSharedSearchFilter: PropTypes.func,
  clearSharedSearchText: PropTypes.func,
  clearSharedSearchFilters: PropTypes.func,
  clearSharedSearchFilter: PropTypes.func,
  searchSharedReports: PropTypes.func,
  //core reports props
  coreReportsResults: PropTypes.array,
  coreReportsIsLoading: PropTypes.bool,
  corePage: PropTypes.number,
  corePageSize: PropTypes.number,
  coreTotalPages: PropTypes.number,
  coreReportsDefaultSortColumn: PropTypes.string,
  coreReportsDefaultReverseSort: PropTypes.bool,
  coreReportsSortColumn: PropTypes.string,
  coreReportsReverseSort: PropTypes.bool,
  coreReportsSearchFilters: PropTypes.object,
  setCoreSort: PropTypes.func,
  setCorePagination: PropTypes.func,
  setCoreSearchFilter: PropTypes.func,
  clearCoreSearchText: PropTypes.func,
  clearCoreSearchFilters: PropTypes.func,
  clearCoreSearchFilter: PropTypes.func,
  searchCoreReports: PropTypes.func,
  //my reports props
  mineReportsResults: PropTypes.array,
  mineReportsIsLoading: PropTypes.bool,
  minePage: PropTypes.number,
  minePageSize: PropTypes.number,
  mineTotalPages: PropTypes.number,
  mineReportsDefaultSortColumn: PropTypes.string,
  mineReportsDefaultReverseSort: PropTypes.bool,
  mineReportsSortColumn: PropTypes.string,
  mineReportsReverseSort: PropTypes.bool,
  mineReportsSearchFilters: PropTypes.object,
  setMineSort: PropTypes.func,
  setMinePagination: PropTypes.func,
  setMineSearchFilter: PropTypes.func,
  clearMineSearchText: PropTypes.func,
  clearMineSearchFilters: PropTypes.func,
  clearMineSearchFilter: PropTypes.func,
  searchMineReports: PropTypes.func,
};

export default Reports;
