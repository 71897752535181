/** @jsxImportSource @emotion/react */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IoMdTimer } from "react-icons/io";
import { Text, FontSize } from "components/atoms/Text.atom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { ChicletCSS as Chiclet, colorForLad } from "components/chiclets";
import Colors from "styles/colors";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
  tsToDaysHrsMins,
} from "utils/date-time";
import { ContainerTrackingDetailsWidgetState } from "../redux/ContainerTrackingDetailsWidgetState";

const WatchCellRenderer = ({ row, value }) => {
  const dispatch = useDispatch();
  const { watched, solutionId } = value;
  const watch = watched?.toLowerCase() === "true" ?? false;

  return (
    <WatchToggle
      key={row.original.id}
      checked={watch ?? false}
      onChange={(newWatchValue) => {
        dispatch(
          ContainerTrackingDetailsWidgetState.actionCreators.watchContainerActions(
            row.original.id,
            newWatchValue,
            solutionId,
          ),
        );
      }}
      iconSize={FontSize.size24}
      color={Colors.nav.NAVY}
      checkedColor={Colors.highlight.YELLOW}
    />
  );
};

WatchCellRenderer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  value: PropTypes.bool,
};

const ContainerCell = (props) => {
  const { id, type, program, supplier, tag } = props.value;
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {id ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              ID:
            </Text>
            <Text>{id}</Text>
          </React.Fragment>
        ) : null}
      </div>

      <div css={{ display: "flex", flexDirection: "row" }}>
        {type ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Type:
            </Text>
            <Text>{type}</Text>
          </React.Fragment>
        ) : null}
      </div>

      <div css={{ display: "flex", flexDirection: "row" }}>
        {program ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Program:
            </Text>
            <Text>{program}</Text>
          </React.Fragment>
        ) : null}
      </div>

      <div css={{ display: "flex", flexDirection: "row" }}>
        {supplier ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Supplier:
            </Text>
            <Text>{supplier}</Text>
          </React.Fragment>
        ) : null}
      </div>

      <div css={{ display: "flex", flexDirection: "row" }}>
        {tag ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Tag:
            </Text>
            <Text>{tag}</Text>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

ContainerCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    program: PropTypes.string,
    supplier: PropTypes.string,
    tag: PropTypes.string,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const { lastMilestone } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {lastMilestone?.datetime ? (
        <div className="d-flex flex-wrap">
          <Text className="ms-1">
            {`${localizedTimeFormatter(
              lastMilestone?.datetime,
            )} ${localizedDateFormatter(lastMilestone?.datetime)}`}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastMilestone: PropTypes.object,
  }).isRequired,
};

const letterForLad = ({ name }, status) => {
  if (status === "In Transit") {
    return "T";
  }

  if (name === "Assembly") {
    return "A";
  }

  if (name === "Supplier") {
    return "S";
  }

  if (name === "Distribution") {
    return "D";
  }
  return "";
};

const StatusCell = (props) => {
  const {
    status,
    lad,
    lastUpdate,
    locationCode,
    location,
    shipmentId,
    shipmentEta,
    inspectorDevice,
    batteryCharge,
  } = props.value;
  const locationCodeCheck = locationCode ? `(${locationCode})` : "";
  const ladName = { name: lad };
  const codeLetter = letterForLad(ladName, status);
  const { t } = useTranslation("shipment-details");
  const color =
    status === "In Transit"
      ? Colors.background.DARK_BLUE
      : colorForLad(ladName);
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div className="d-flex align-items-center flex-wrap">
        {codeLetter ? (
          <Chiclet
            codeLetter={codeLetter}
            backgroundColor={color}
            height={24}
            width={24}
            style={{ marginRight: "0.5em" }}
          />
        ) : null}
        <Text>{status}</Text>
      </div>
      {status !== "In Transit" ? (
        <Fragment>
          {locationCodeCheck || location ? (
            <Text bold>{`${locationCodeCheck} ${location}`}</Text>
          ) : null}
          {lastUpdate?.city && lastUpdate?.state ? (
            <Text>{`${lastUpdate.city}, ${lastUpdate.state}`}</Text>
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          {shipmentId ? (
            <Fragment>
              <Text bold style={{ marginRight: 3 }}>
                {t("shipment-details:Shipment ID")} :
              </Text>
              <Text>{shipmentId}</Text>
            </Fragment>
          ) : null}
          {shipmentEta ? (
            <Fragment>
              <Text bold style={{ marginRight: 3 }}>
                {t("shipment-details:ETA")} :
              </Text>
              <Text>{shipmentEta}</Text>
            </Fragment>
          ) : null}
        </Fragment>
      )}
      {inspectorDevice ? (
        <Fragment>
          <Text bold block style={{ marginRight: 3 }}>
            {t("container-tracking:Inspector Device Name")} :
          </Text>
          <Text block>{inspectorDevice}</Text>
        </Fragment>
      ) : null}
      <Fragment>
        <Text bold block style={{ marginRight: 3 }}>
          {t("container-tracking:Battery Level")} :
          <Text css={{ marginLeft: 3 }}>
            {batteryCharge || batteryCharge === 0
              ? `${batteryCharge.toFixed(0)}%`
              : "Null"}
          </Text>
        </Text>
      </Fragment>
    </div>
  );
};

StatusCell.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string,
    lad: PropTypes.string,
    lastUpdate: PropTypes.object,
    locationCode: PropTypes.string,
    location: PropTypes.string,
    shipmentId: PropTypes.string,
    shipmentEta: PropTypes.string,
    inspectorDevice: PropTypes.string,
    batteryCharge: PropTypes.number,
  }).isRequired,
};

const ActiveExceptionsCell = (props) => {
  const { activeExceptions } = props.value;
  const { t } = useTranslation("container-tracking");

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {activeExceptions &&
        activeExceptions.map((activeException, i) => {
          const reasonCode = activeException?.reasonCode?.toUpperCase() ?? "";

          return (
            <React.Fragment key={i}>
              {reasonCode === "ED" && (
                <React.Fragment>
                  <div css={{ display: "flex", flexDirection: "row" }}>
                    <Text css={{ color: "red" }}>
                      {t("container-tracking:Excessive Dwell")}
                    </Text>
                  </div>
                  <div css={{ display: "flex", flexDirection: "row" }}>
                    <IoMdTimer
                      style={{
                        fontSize: 20,
                        marginRight: 10,
                        color: Colors.holds.RED,
                      }}
                    />
                    <Text bold>
                      {tsToDaysHrsMins(activeException?.activatedAt)}
                    </Text>
                  </div>
                </React.Fragment>
              )}
              {reasonCode === "U" && (
                <Text bold>{t("container-tracking:Unaccounted")}</Text>
              )}
              {reasonCode === "NU30" && (
                <Text bold>{t("container-tracking:No Update 30+ Days")}</Text>
              )}
              {reasonCode === "OR" && (
                <Text bold>{t("container-tracking:Off Route")}</Text>
              )}
              {reasonCode === "BL" && (
                <Text bold>{t("container-tracking:Battery Level")}</Text>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

ActiveExceptionsCell.propTypes = {
  value: PropTypes.shape({
    activeExceptions: PropTypes.array,
  }).isRequired,
};

export const columns = (t, solutionId) => {
  const results = [
    {
      Header: t("container-tracking:Watch"),
      id: "watch",
      Cell: (d) => WatchCellRenderer(d, solutionId),
      accessor: (d) => {
        return {
          watched: d?.watched,
          solutionId,
        };
      },
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:Container"),
      id: "container",
      Cell: ContainerCell,
      accessor: (d) => {
        return {
          id: d.containerId,
          type: d.type,
          program: d.programCode,
          supplier: d.supplierCode,
          tag: d.tagId,
        };
      },
      minWidth: 180,
    },
    {
      Header: t("container-tracking:Status"),
      id: "status",
      minWidth: 125,
      Cell: StatusCell,
      accessor: (d) => {
        return {
          status: d?.status ?? "",
          lad: d?.location_type ?? "",
          lastUpdate: d?.lastUpdate ?? {},
          locationCode: d?.lastUpdate?.locationCode ?? "",
          location: d?.lastMilestone?.location ?? "",
          shipmentId: d?.shipmentId ?? "",
          eta: d?.shipmentEta ?? "",
          inspectorDevice: d?.lastUpdate?.details?.device?.deviceName ?? "",
          batteryCharge: d?.batteryCharge ?? "",
        };
      },
    },
    {
      Header: t("container-tracking:Last Update"),
      id: "lastUpdated",
      minWidth: 125,
      Cell: LastUpdateCell,
      accessor: (d) => {
        return {
          lastMilestone: d.lastMilestone,
        };
      },
    },
    {
      Header: t("container-tracking:Active Exception"),
      id: "activeException",
      minWidth: 125,
      Cell: ActiveExceptionsCell,
      accessor: (d) => {
        return {
          activeExceptions: d?.activeExceptions,
        };
      },
    },
  ];

  return results;
};
