import axios from "axios";
import _ from "lodash";
import apiUrl from "../../api-url";
import { EventTypeOptions, mappingKeysWithFields } from "./const";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { axiosConfigHeaders } from "utils/fetch-utils";

const STORE_MOUNT_POINT = "createMilestone";

const getShippersUrl = () => apiUrl(`/entity/status-upload-config/shippers`);
const getPartnerTypesUrl = (solutionId) =>
  apiUrl(`/entity/status-upload-config/partner-types?solutionId=${solutionId}`);
const getMilestoneEventsUrl = (solutionId) =>
  apiUrl(`/entity/status-upload-config/milestones?solutionId=${solutionId}`);
const getMilestoneFieldsUrl = () =>
  apiUrl(`/entity/status-upload-config/fields`);
const getBulkUploadUrl = (solutionId, params) => {
  return apiUrl(
    `/entity/solution/${solutionId}/upload/status-update?${params.toString()}`,
  );
};

export const MilestoneStatus = {
  SUBMITTING: "SUBMITTING",
  CREATED: "CREATED",
  ERROR: "ERROR",
};

export const FormFieldsStatus = {
  FETCHING: "FETCHING",
  RECEIVED: "RECEIVED",
};

export const FetchStatus = {
  FETCHING: "FETCHING",
  RECEIVED: "RECEIVED",
  ERROR: "ERROR",
};

// Actions
const CREATE_MILESTONE_REQUESTED = "createMilestone/CREATE_MILESTONE_REQUESTED";
const CREATE_MILESTONE_SUCCEEDED = "createMilestone/CREATE_MILESTONE_SUCCEEDED";
const CREATE_MILESTONE_FAILED = "createMilestone/CREATE_MILESTONE_FAILED";
const RESET_CREATE_FORM = "createMilestone/RESET_CREATE_FORM";
const RESET_CREATE_STATUS = "createMilestone/RESET_CREATE_STATUS";

const shippersDomainDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "shippersDomainDuck",
);
const milestoneFieldsDomainDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "milestoneFieldsDomainDuck",
);

const uploadBulkDataDomainDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "uploadBulkDataDomainDuck",
);

//Actions - Fetch Partners
const FETCH_PARTNER_TYPES = "createMilestone/FETCH_PARTNER_TYPES";
const RECEIVE_PARTNER_TYPES = "createMilestone/RECEIVE_PARTNER_TYPES";
const FETCH_PARTNER_TYPES_FAILED = "createMilestone/FETCH_PARTNER_TYPES_FAILED";

// Actions - Batch upload process
const BATCH_UPLOAD = "createMilestone/BATCH_UPLOAD";

// Actions - Fetch Milestone Events
const FETCH_MILESTONE_EVENTS_FAILED =
  "createMilestone/FETCH_MILESTONE_EVENTS_FAILED";
// Action creators
function createMilestone(solutionId, data) {
  return (dispatch) => {
    dispatch({ type: CREATE_MILESTONE_REQUESTED });
    dispatch({ type: BATCH_UPLOAD, hasUpload: false });

    let requests = [];

    requests = data.vins.map((vin) => {
      const url = apiUrl(
        `/entity/solution/${solutionId}/entity/${vin}/upload/status-update`,
      );
      return axios.post(url, { ...data.payload });
    });

    return Promise.all(requests)
      .then((responses) => {
        dispatch({ type: CREATE_MILESTONE_SUCCEEDED });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_MILESTONE_FAILED,
          errorMessage: error?.response?.data?.error?.message,
        });
      });
  };
}

function fetchShippers() {
  return (dispatch) => {
    dispatch(shippersDomainDuck.clear());
    dispatch(milestoneFieldsDomainDuck.clear());
    dispatch(shippersDomainDuck.fetch(getShippersUrl()));
  };
}

function fetchPartners(solutionId) {
  return (dispatch) => {
    return axios
      .get(getPartnerTypesUrl(solutionId))
      .then((responses) => {
        const partnerTypeOptions = responses.data.partnerTypes.map((item) => {
          return { label: item, value: item };
        });
        return {
          options: _.sortBy(partnerTypeOptions, ["label"]),
        };
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PARTNER_TYPES_FAILED,
          errorMessage: "Unable to fetch Partner Types. Try again later.",
        });
        return {
          options: [],
        };
      });
  };
}

function fetchMilestoneEvents(solutionId, parnterType) {
  return (dispatch) => {
    return axios
      .get(getMilestoneEventsUrl(solutionId), {
        params: {
          partnerType: parnterType,
        },
      })
      .then((responses) => {
        const milestoneOptions = responses.data.milestones.map((item) => {
          const milestoneValue = item.subcode
            ? item.code + "_" + item.subcode
            : item.code;
          return { label: item.description, value: milestoneValue };
        });
        return {
          options: milestoneOptions,
        };
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MILESTONE_EVENTS_FAILED,
          errorMessage: "Unable to fetch Milestone Events. Try again later.",
        });
        return {
          options: [],
        };
      });
  };
}

function fetchMilestoneFields(
  solutionId,
  eventType,
  partnerType,
  milestoneEventCode,
  vmacsCode,
) {
  return (dispatch) => {
    const queryParams = {
      solutionId: solutionId,
      eventType: eventType.toLowerCase(),
      partnerType: partnerType,
      code: milestoneEventCode,
      subcode: vmacsCode,
    };
    dispatch(
      milestoneFieldsDomainDuck.fetch(getMilestoneFieldsUrl(), {
        params: queryParams,
      }),
    );
  };
}

function uploadBulkData(
  solutionId,
  partnerType = null,
  milestoneCode = null,
  milestoneVMACSCode = "",
  milestoneLabel = null,
  dataArray,
  uploadFile,
  selectedEventType,
) {
  return (dispatch) => {
    dispatch({ type: BATCH_UPLOAD, hasUpload: true });

    const parameters = {
      partnerType: partnerType,
      codeDescription: milestoneLabel,
      milestone: milestoneCode,
      vmacsCode: milestoneVMACSCode,
      event_type: selectedEventType.toLowerCase(),
    };
    const params = new URLSearchParams(parameters);

    let config = axiosConfigHeaders();
    config = {
      ...config,
      method: "post",
    };

    if (selectedEventType === EventTypeOptions.MULTIPLE) {
      let formData = new FormData();
      formData.append("file", uploadFile);
      config = {
        ...config,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      };
    } else {
      if (!dataArray || dataArray.length <= 0) {
        dispatch({
          type: CREATE_MILESTONE_FAILED,
          errorMessage: "No rows in CSV",
        });
        return;
      }

      config = {
        ...config,
        data: dataArray,
        headers: {
          "content-type": "text/csv",
        },
      };
    }
    dispatch(
      uploadBulkDataDomainDuck.fetch(
        getBulkUploadUrl(solutionId, params),
        config,
      ),
    );
  };
}

function resetCreateForm() {
  return (dispatch) => {
    dispatch(uploadBulkDataDomainDuck.clear());
    dispatch({ type: RESET_CREATE_FORM });
  };
}

function resetCreateState() {
  return (dispatch) => {
    dispatch(milestoneFieldsDomainDuck.clear());
    dispatch({ type: RESET_CREATE_STATUS });
  };
}

// Initial state
export const initialState = {
  createStatus: null,
  errorMessage: "",
  hasUpload: false,
  fetchPartnerTypesStatus: null,
  fetchMilestoneEventsStatus: null,
};

// selectors
const getCreateStatus = (state) => {
  const { status, isLoadingError } =
    uploadBulkDataDomainDuck.selectors.getData(state) ?? {};
  if (status === 200) {
    return MilestoneStatus.CREATED;
  } else if (isLoadingError) {
    return MilestoneStatus.ERROR;
  }
  return state[STORE_MOUNT_POINT].createStatus;
};

const getDetailedError = (loadingError) => {
  return loadingError?.response?.data?.error?.message
    ? loadingError.response.data.error.message
    : loadingError?.message
    ? loadingError.message
    : loadingError?.response?.statusText ?? null;
};

const getErrorMessage = (state) => {
  if (getFetchShippersErrorStatus(state)) {
    const { status: shippersStatus, loadingError: shippersLoadingError } =
      shippersDomainDuck.selectors.getData(state) ?? {};
    if (shippersStatus !== 200) {
      return getDetailedError(shippersLoadingError);
    }
  }

  if (getFetchMilestoneFieldsErrorStatus(state)) {
    const { status: milestoneStatus, loadingError: milesontoneLoadingError } =
      milestoneFieldsDomainDuck.selectors.getData(state) ?? {};
    if (milestoneStatus === 403) {
      return getDetailedError(milesontoneLoadingError);
    }
  }

  if (getUploadBulkErrorStatus(state)) {
    const { loadingError: uploadBulkDataLoadingError } =
      uploadBulkDataDomainDuck.selectors.getData(state) ?? {};
    return getDetailedError(uploadBulkDataLoadingError);
  }

  return state[STORE_MOUNT_POINT].errorMessage;
};

const getHasUpload = (state) => state[STORE_MOUNT_POINT].hasUpload;

const getShippers = (state) => {
  const { data = [], status } =
    shippersDomainDuck.selectors.getData(state) ?? {};
  if (status === 200) {
    const shipperOptions = data.shippers.map((shipper) => {
      return {
        label: shipper.name,
        value: shipper.fvId,
        solutionId: shipper.solutionId,
      };
    });
    return shipperOptions;
  }
  return data;
};

const getFormFields = (state) => {
  if (!milestoneFieldsDomainDuck.selectors.getData(state)) {
    return [];
  }
  const { data = [], status } =
    milestoneFieldsDomainDuck.selectors.getData(state) ?? {};

  if (status === 200) {
    return mappingKeysWithFields(data.fields);
  }
};

const getIsLoadingShippers = (state) =>
  shippersDomainDuck.selectors.getData(state)?.isLoading ?? false;

const getFetchShippersErrorStatus = (state) =>
  shippersDomainDuck.selectors.getData(state)?.isLoadingError ?? false;

const getFetchPartnerTypesStatus = (state) =>
  state[STORE_MOUNT_POINT].fetchPartnerTypesStatus;

const getFetchMilestoneEventsStatus = (state) =>
  state[STORE_MOUNT_POINT].fetchMilestoneEventsStatus;

const getIsLoadingFormFields = (state) =>
  milestoneFieldsDomainDuck.selectors.getData(state)?.isLoading ?? false;

const getFetchMilestoneFieldsErrorStatus = (state) =>
  milestoneFieldsDomainDuck.selectors.getData(state)?.isLoadingError ?? false;

const getUploadBulkErrorStatus = (state) => {
  return (
    uploadBulkDataDomainDuck.selectors.getData(state)?.isLoadingError ?? false
  );
};

const getErrorStatus = (state) => {
  const isLoadingErrorShippers = getFetchShippersErrorStatus(state);
  const partnerTypesStatus = getFetchPartnerTypesStatus(state);
  const milestoneEventStatus = getFetchMilestoneEventsStatus(state);
  const isLoadingErrorFormFields = getFetchMilestoneFieldsErrorStatus(state);
  const isLoadingErrorBulkUpload = getUploadBulkErrorStatus(state);

  return (
    isLoadingErrorShippers ||
    isLoadingErrorBulkUpload ||
    isLoadingErrorFormFields ||
    partnerTypesStatus === FetchStatus.ERROR ||
    milestoneEventStatus === FetchStatus.ERROR
  );
};

// Reducer
function CreateMilestoneReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_MILESTONE_REQUESTED:
      return {
        ...state,
        createStatus: MilestoneStatus.SUBMITTING,
      };
    case CREATE_MILESTONE_SUCCEEDED:
      return {
        ...state,
        createStatus: MilestoneStatus.CREATED,
      };
    case CREATE_MILESTONE_FAILED:
      return {
        ...state,
        createStatus: MilestoneStatus.ERROR,
        errorMessage: action.errorMessage,
      };
    case RESET_CREATE_FORM:
      return {
        ...state,
        ...initialState,
      };
    case FETCH_PARTNER_TYPES:
      return {
        ...state,
        fetchPartnerTypesStatus: FetchStatus.FETCHING,
      };
    case RECEIVE_PARTNER_TYPES:
      return {
        ...state,
        fetchPartnerTypesStatus: FetchStatus.RECEIVED,
      };
    case FETCH_PARTNER_TYPES_FAILED:
      return {
        ...state,
        fetchPartnerTypesStatus: FetchStatus.ERROR,
        errorMessage: action.errorMessage,
      };
    case FETCH_MILESTONE_EVENTS_FAILED:
      return {
        ...state,
        fetchMilestoneEventsStatus: FetchStatus.ERROR,
        errorMessage: action.errorMessage,
      };
    case BATCH_UPLOAD:
      return {
        ...state,
        hasUpload: action.hasUpload,
      };
    case RESET_CREATE_STATUS:
      return {
        ...state,
        createStatus: null,
      };
    default:
      return state;
  }
}

// interface
const CreateMilestoneState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    createMilestone,
    uploadBulkData,
    resetCreateForm,
    resetCreateState,
    fetchShippers,
    fetchPartners,
    fetchMilestoneEvents,
    fetchMilestoneFields,
  },
  selectors: {
    getCreateStatus,
    getErrorMessage,
    getHasUpload,
    getShippers,
    getFormFields,
    getIsLoadingShippers,
    getFetchMilestoneEventsStatus,
    getIsLoadingFormFields,
    getErrorStatus,
    getUploadBulkErrorStatus,
  },
  reducer: chainReducers([
    shippersDomainDuck.reducer,
    milestoneFieldsDomainDuck.reducer,
    uploadBulkDataDomainDuck.reducer,
    CreateMilestoneReducer,
  ]),
};
export default CreateMilestoneState;
