import { connect } from "react-redux";

import { CreateMilestoneView } from "./CreateMilestoneView";
import {
  getActiveOrganization,
  getSolutionId,
} from "../organizations/OrganizationsState";
import CreateMilestoneState from "./CreateMilestoneState";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  const {
    getCreateStatus,
    getErrorMessage,
    getHasUpload,
    getIsLoadingShippers,
    getFormFields,
    getIsLoadingFormFields,
    getErrorStatus,
    getShippers,
  } = CreateMilestoneState.selectors;

  return {
    solutionId: getSolutionId(state),
    createStatus: getCreateStatus(state),
    errorMessage: getErrorMessage(state),
    hasUpload: getHasUpload(state),
    activeOrganization: getActiveOrganization(state),
    shippers: getShippers(state),
    formFields: getFormFields(state),
    isLoadingShippers: getIsLoadingShippers(state),
    isLoadingFormFields: getIsLoadingFormFields(state),
    showErrorMessage: getErrorStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    createMilestone,
    uploadBulkData,
    resetCreateForm,
    fetchShippers,
    fetchPartners,
    fetchMilestoneEvents,
    fetchMilestoneFields,
    resetCreateState,
  } = CreateMilestoneState.actionCreators;
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setDescription: (description) =>
      dispatch(TitleState.actionCreators.setDescription(description)),
    createMilestone: (solutionId, data) =>
      dispatch(createMilestone(solutionId, data)),
    uploadBulkData: (
      solutionId,
      partnerType,
      milestoneCode,
      milestoneVMACSCode,
      milestoneLabel,
      dataArray,
      uploadFile,
      eventType,
    ) =>
      dispatch(
        uploadBulkData(
          solutionId,
          partnerType,
          milestoneCode,
          milestoneVMACSCode,
          milestoneLabel,
          dataArray,
          uploadFile,
          eventType,
        ),
      ),
    resetCreateForm: () => dispatch(resetCreateForm()),
    resetCreateState: () => dispatch(resetCreateState()),
    fetchShippers: (solutionId) => dispatch(fetchShippers(solutionId)),
    fetchPartners: (selectedShipper) =>
      dispatch(fetchPartners(selectedShipper)),
    fetchMilestoneEvents: (solutionId, selectedPartnerType) =>
      dispatch(fetchMilestoneEvents(solutionId, selectedPartnerType)),
    fetchMilestoneFields: (
      solutionId,
      eventType,
      selectedPartnerType,
      selectedMilestoneEventCode,
      vmacsCode,
    ) =>
      dispatch(
        fetchMilestoneFields(
          solutionId,
          eventType,
          selectedPartnerType,
          selectedMilestoneEventCode,
          vmacsCode,
        ),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateMilestoneView);
