import { connect } from "react-redux";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { AdministrativeToolsView } from "./AdminTools.page";
import { redirectToRoute } from "redux/utils"; // This is `src/redux/utils`

function mapStateToProps(state) {
  return {
    auth: getAuthorization(state),
  };
}

export default connect(mapStateToProps, { redirectToRoute })(
  AdministrativeToolsView,
);
