/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";

type Parts = {
  partNumber: string;
  name: string;
};

type PartsCellInfo = {
  value: {
    partList: Parts[];
    trackingNumber: string;
  };
};

export const PartsCell = (props: PartsCellInfo) => {
  const { t } = useTranslation("partview-dashboard");

  const { partList, trackingNumber } = props.value;

  const partDetails = partList.map(
    (parts) => `${parts.partNumber} (${parts.name})`,
  );

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {!_.isEmpty(partDetails) ? (
        <ShowMoreList
          title={t("partview-dashboard:Parts in Package [[[trackingNumber]]]", {
            trackingNumber,
          })}
          list={partDetails}
          visibleItemCount={3}
        />
      ) : null}
    </div>
  );
};

PartsCell.propTypes = {
  value: PropTypes.shape({
    partList: PropTypes.arrayOf(
      PropTypes.shape({
        partNumber: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    trackingNumber: PropTypes.string,
  }),
};
