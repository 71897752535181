import { connect } from "react-redux";

import { CallbackView } from "./CallbackView";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  return {
    authCode: state.callback.authCode,
    error: state.callback.error,
    authType: state.callback.authType,
    orgId: state.location?.query?.orgId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackView);
