/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import TableMetaTotalRecords from "components/organisms/base-table/MetaData/TableMetaTotalRecords";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import ImportLocationsModal from "pages/administration/location-management/components/ImportLocationsModal.container";
import { useLadsTranslation } from "modules/lads/utils/lads.utils";
import { isCarrier } from "shared/utils/organizations.utils";
import LocationTable from "./components/LocationTable";
import {
  LocationsSearchBar,
  AddLocationButton,
  ImportLocationsButton,
} from "./components/LocationOperations";

const getLadByLabelName = (name, lads) => {
  const lobBegin = name.lastIndexOf("(");
  const lobEnd = name.lastIndexOf(")");

  const ladName =
    name && lobBegin > -1
      ? name.substring(0, lobBegin - 1).toLowerCase()
      : name;
  const lobName =
    name && lobBegin > -1 && lobEnd > -1
      ? name.substring(lobBegin + 1, lobEnd).toLowerCase()
      : "";

  return lads.find(
    (l) =>
      l.lad_name.toLowerCase() === ladName &&
      l.lob_name.toLowerCase() === lobName,
  );
};

// Map each table column accessor with a search filter for the API request
const tableFilterMap = {
  name: "name_search",
  address: "full_address_search",
  code: "code_search",
  organization: "customer_id",
  type: "organization_lad_id",
  child_count: "isParent",
  geofence_type: "geofenceType",
  parent_code: "parent_code",
};

export const LocationsView = ({
  locationSearchResults,
  clearSearchText,
  searchLocations,
  solutionId,
  routerLocation,
  clearSearchFilters,
  setSearchFilter,
  activeOrganization,
  searchFilters,
  lads,
  clearSearchFilter,
  totalLocations,
  pushCreateLocationScreen,
  pushEditLocationScreen,
  isLoading,
  page,
  pageSize,
  totalPages,
  setPagination,
  defaultSortColumn,
  defaultReverseSort,
  sortColumn,
  reverseSort,
  setSort,
  canUserManageLocations,
  exportIdentifier,
  exportName,
  isExporting,
  exportFailed,
  exportLocations,
  resetExport,
}) => {
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation("locations");
  const { getTranslatedLadLobLabel } = useLadsTranslation();

  useEffect(() => {
    const previousRoutesThatShouldntResetSearch = [
      "LOCATION_MANAGEMENT",
      "EDIT_LOCATION",
      "CREATE_LOCATION",
      "LOCATION_MATCHING",
    ];

    const previousRouteKey = routerLocation.prev?.type;

    // H1-2660:
    //   If we came from the "Edit Location" page,
    //   refetch the search to get the updated result.
    if (previousRouteKey === "EDIT_LOCATION") {
      searchLocations(solutionId);
    } else if (
      // H1-3769: When navigating from routes not included in the array, reset search.
      !previousRoutesThatShouldntResetSearch.includes(previousRouteKey) ||
      // if there are no results, this is likely the first time visiting Location Management
      !locationSearchResults.length
    ) {
      resetSearchFilters();
      clearSearchText();
      searchLocations(solutionId);
    }

    applyTableFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyTableFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const resetSearchFilters = () => {
    clearSearchFilters();

    // H1-1701: Apply additional filters to Carrier location search query
    if (isCarrier(activeOrganization)) {
      setSearchFilter("dereference", "t");
      setSearchFilter("dereference_search", "t");
      setSearchFilter("category", "Shipper");
    }
  };

  // Convert API search filters to their mapped table filter values
  const applyTableFilters = () => {
    const tableFilters = [];

    const isCarrierOrg = isCarrier(activeOrganization);

    Object.keys(tableFilterMap).forEach((tableAccessor) => {
      const apiParam = tableFilterMap[tableAccessor];
      let apiValue = searchFilters[apiParam];

      // Convert LAD IDs to label strings for table filter
      if (tableAccessor === "type" && Array.isArray(apiValue)) {
        const ladLabels = apiValue.map((ladId) => {
          /* carrier org */
          if (isCarrierOrg) {
            let combinedLads = [];
            combinedLads = Object.values(lads)
              .map((orgLads) => orgLads)
              .flat();
            const shipperLad = combinedLads.find((l) => l.org_lad_id === ladId);

            const shipperLadLabel = shipperLad
              ? `${shipperLad.org_id}_${getTranslatedLadLobLabel(shipperLad)}`
              : "";
            return shipperLadLabel.toLowerCase();
          } else {
            /* shipper org */
            const lad = lads.find((l) => l.id === ladId);
            const ladLabel = lad ? getTranslatedLadLobLabel(lad) : "";
            return ladLabel.toLowerCase();
          }
        });

        apiValue = ladLabels;
      }

      if (apiValue) {
        tableFilters.push({
          id: tableAccessor,
          value: apiValue,
        });
      }
    });

    setFilters(tableFilters);
  };

  const filterResolvedLocations = (filtered) => {
    if (!filtered || !filtered.length) {
      resetSearchFilters();
    }

    // Apply table filters as API search filters
    filtered.forEach((filter) => {
      const tableAccessor = filter.id;
      const apiParam = tableFilterMap[tableAccessor];
      let val = filter.value;

      if (!apiParam) {
        return;
      }

      // Only apply valid LADS for the Location Type filter
      if (tableAccessor === "type" && Array.isArray(val)) {
        const ladIds = val.map((ladName) => {
          /* carrier org */
          if (ladName.includes("_")) {
            const [orgId, name] = ladName.split("_");

            const lad = getLadByLabelName(name, lads[orgId]);

            return lad ? lad.org_lad_id : null;
          } else {
            /* shipper org */
            const lad = getLadByLabelName(ladName, lads);

            return lad ? lad.id : null;
          }
        });

        val = ladIds || [];
      }

      setSearchFilter(apiParam, val);
    });

    // Clear API search filters that are not applied as a table filter
    Object.keys(tableFilterMap).forEach((tableAccessor) => {
      const apiParam = tableFilterMap[tableAccessor];
      const tableFilter = filtered.find((l) => l.id === tableAccessor);

      if (tableFilter) {
        return;
      }

      clearSearchFilter(apiParam);
    });

    searchLocations(solutionId);
  };

  const debouncedFilterResolvedLocations = _.debounce(
    filterResolvedLocations,
    400,
  );
  const isCarrierOrg = isCarrier(activeOrganization);

  let parentStyle = {};
  if (canUserManageLocations) {
    parentStyle = {
      backgroundColor: "#fff",
      border: "1px solid #dee2e6",
      borderTop: 0,
      padding: "1rem",
    };
  }

  return (
    <div style={parentStyle}>
      <LocationsSearchBar />
      <ExportAlert exportFailed={exportFailed} className="mt-3" />
      <div className="d-flex flex-column-reverse mb-2 flex-sm-row justify-content-sm-between align-items-sm-center mt-sm-2">
        <TableMetaTotalRecords
          total={totalLocations}
          label={t("locations:Locations")}
          className="me-0 me-sm-auto align-self-center my-1 my-sm-0"
        />
        <DownloadDataButton
          onClick={() => {
            exportLocations();
          }}
          isExporting={isExporting}
          isDisabled={locationSearchResults.length === 0}
          data-qa="locations-export"
        />
        {canUserManageLocations ? (
          <div className="d-flex flex-column flex-sm-row">
            <AddLocationButton
              onAddNewLocation={() => {
                pushCreateLocationScreen();
              }}
              className="mb-2 mb-sm-0 ms-sm-2"
            />
            <ImportLocationsButton
              className="ms-sm-2"
              onImportLocations={() => {
                setShowModal(true);
              }}
            />
          </div>
        ) : null}
      </div>
      <LocationTable
        showCarrierColumns={isCarrierOrg}
        loading={isLoading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        setPagination={setPagination}
        defaultSortColumn={defaultSortColumn}
        defaultReverseSort={defaultReverseSort}
        sortColumn={sortColumn}
        reverseSort={reverseSort}
        setSort={setSort}
        solutionId={solutionId}
        data={locationSearchResults}
        rowClickHandler={(row) => {
          let id = row.original.id;

          if (isCarrierOrg && row.original.child_ids) {
            id = row.original.child_ids[0];
          }

          pushEditLocationScreen(id);
        }}
        onFilterChange={debouncedFilterResolvedLocations}
        filters={filters}
      />
      <ExportModal
        exportIdentifier={exportIdentifier}
        exportName={exportName}
        resetExport={resetExport}
      />
      <ImportLocationsModal show={showModal} hide={() => closeModal()} />
    </div>
  );
};

LocationsView.propTypes = {
  activeOrganization: PropTypes.any,
  authorization: PropTypes.object,
  canUserManageLocations: PropTypes.bool.isRequired,
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  clearSearchText: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  lads: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  locationSearchResults: PropTypes.array,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pushCreateLocationScreen: PropTypes.func.isRequired,
  pushEditLocationScreen: PropTypes.func.isRequired,
  pushLocationMatchingView: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  searchLocations: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  setPagination: PropTypes.func.isRequired,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  setSearchFilter: PropTypes.func.isRequired,
  setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  totalLocations: PropTypes.number,
  totalPages: PropTypes.number,
  routerLocation: PropTypes.object,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  exportLocations: PropTypes.func.isRequired,
  resetExport: PropTypes.func.isRequired,
};
