/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { getColumns } from "pages/shipments/search/Shipments.Search.columns";
import ShipmentsWatchSearchBarState from "pages/shipments/redux/ShipmentsWatchSearchBarState";

const useWatchTableColumns = ({ searchEntities, isLoading }) => {
  const { t } = useTranslation("shipment-status");
  const [pendingShipments, setPendingShipments] = useState([]);

  // Update list in state for the component to know which rows are waiting for
  // a request to finish.
  const onActionClick = (shipment) => {
    setPendingShipments((prev) => {
      return [...prev, shipment.id];
    });
  };

  // Refresh the search once the action is complete.
  const onActionFinished = () => {
    searchEntities(null, false, true);
  };

  // When search results are loading, reset the pending list since the API will
  // give us the latest data.
  useEffect(() => {
    setPendingShipments([]);
  }, [isLoading]);

  return {
    columns: getColumns({ onActionClick, onActionFinished, t }),
    pendingShipments,
  };
};

const ShipmentsWatchTable = (props) => {
  const { t } = useTranslation("shipment-status");

  const {
    searchEntities,
    searchResults,
    isLoading,
    pageIndex,
    pageSize,
    pageCount,
    setPagination,
    pushShipmentDetailPage,
  } = props;

  const { columns, pendingShipments } = useWatchTableColumns({
    searchEntities,
    isLoading,
  });

  useEffect(() => {
    searchEntities();
  }, [searchEntities]);

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in the actions cell
    if (cell.column.id === "ACTIONS_COLUMN") {
      return;
    }

    // Navigate to Shipment Details when clicking row
    pushShipmentDetailPage(row.original.id);
  };

  return (
    <PanelGroup collapsible>
      <PanelGroup.Header
        title={t("shipment-status:My Shipments")}
        postTitleIcon={faQuestionCircle}
        postTitleIconTooltip={t(
          "shipment-status:Includes watched and alerted shipments",
        )}
      />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={searchResults}
          columns={columns}
          theme={Themes.LIGHT}
          isLoading={isLoading}
          rowClickHandler={rowClickHandler}
          showPagination={true}
          isManualPagination={true}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          onPageChange={(newPageIndex) => {
            setPagination(null, newPageIndex, pageSize);
          }}
          onPageSizeChange={(newPageSize) => {
            setPagination(null, pageIndex, newPageSize);
          }}
          rowDisabledHandler={(row) => {
            return pendingShipments.includes(row.original.id);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

ShipmentsWatchTable.propTypes = {
  searchEntities: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  searchResults: PropTypes.array,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPagination: PropTypes.func.isRequired,
  pushShipmentDetailPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    searchResults:
      ShipmentsWatchSearchBarState.selectors.getSearchResults(state),
    isLoading: ShipmentsWatchSearchBarState.selectors.getIsLoading(state),
    pageIndex: ShipmentsWatchSearchBarState.selectors.getPage(state),
    pageSize: ShipmentsWatchSearchBarState.selectors.getPageSize(state),
    pageCount: ShipmentsWatchSearchBarState.selectors.getTotalPages(state),
  };
}

const actionCreators = {
  pushShipmentDetailPage: (id) => ({
    type: "SHIPMENT_DETAIL",
    payload: { shipment_id: id },
  }),
  ...ShipmentsWatchSearchBarState.actionCreators,
};

export default connect(mapStateToProps, actionCreators)(ShipmentsWatchTable);
