import axios from "axios";
import _ from "lodash";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import apiUrl from "api-url";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";

// URLS
const BASE_URL = apiUrl("/shipping-ng");
const SHIPMENTS_URL = BASE_URL + "/shipments";
export const SHIPMENT_TOTALS_URL = BASE_URL + "/shipments/totals";
export const BATCH_SHIPMENT_TOTALS_URL = BASE_URL + "/batch_shipments/totals";
export const SHIPMENT_DETAILS_URL = BASE_URL + "/shipments/";
const CARRIERS_URL = BASE_URL + "/carriers";
const MULTIMODAL_DETAILS_URL = BASE_URL + "/trip/";
const TRIP_PLAN_URL = apiUrl(
  "/shipment-trip-plan/shipment_trip_plan?shipmentId=",
);
const HEAT_MAP_URL = BASE_URL + "/heat_map";

const STORE_MOUNT_POINT = "shipmentsStatus";

// Actions
const FETCH_INBOUND_SHIPMENT_TOTALS = "shipments/FETCH_INBOUND_SHIPMENT_TOTALS";
const RECEIVE_INBOUND_SHIPMENT_TOTALS = "shipments/RECEIVE_SHIPMENT_TOTALS";
const FETCH_SHIPMENT_DETAILS = "shipment/FETCH_SHIPMENT_DETAILS";
const FETCH_MULTIMODAL_DETAILS = "shipments/FETCH_MULTIMODAL_DETAILS";
const RECEIVE_MULTIMODAL_DETAILS = "shipments/RECEIVE_MULTIMODAL_DETAILS";
const RECEIVE_SHIPMENT_DETAILS = "shipments/RECEIVE_SHIPMENT_DETAILS";
const FETCH_CHILD_SHIPMENT_DETAILS = "shipments/FETCH_CHILD_SHIPMENT_DETAILS";
const RECEIVE_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_CHILD_SHIPMENT_DETAILS";
const RECEIVE_ALL_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_ALL_CHILD_SHIPMENT_DETAILS";
const CLEAR_SHIPMENT_DETAILS = "shipments/CLEAR_SHIPMENT_DETAILS";
const CLEAR_CHILD_SHIPMENT_DETAILS = "shipments/CLEAR_CHILD_SHIPMENT_DETAILS";
const FETCH_SHIPMENT_TRIP_PLAN = "shipments/FETCH_SHIPMENT_TRIP_PLAN";
const RECEIVE_SHIPMENT_TRIP_PLAN = "shipments/RECEIVE_SHIPMENT_TRIP_PLAN";
const SHIPMENT_TRIP_PLAN_ERROR = "shipments/SHIPMENT_TRIP_PLAN_ERROR";
const FETCH_ROUTE_HEATMAP = "shipment/FETCH_ROUTE_HEATMAP";
const RECEIVE_ROUTE_HEATMAP = "shipment/RECEIVE_ROUTE_HEATMAP";
const RECEIVE_ROUTE_HEATMAP_ERROR = "shipment/RECEIVE_ROUTE_HEATMAP_ERROR";
const FETCH_SHIPMENT_COMMENTS = "shipment/FETCH_SHIPMENT_COMMENTS";
const FETCH_SHIPMENT_COMMENTS_FAILED =
  "shipment/FETCH_SHIPMENT_COMMENTS_FAILED";
const RECEIVE_SHIPMENT_COMMENTS = "shipment/RECEIVE_SHIPMENT_COMMENTS";
const SUBMIT_NEW_COMMENT = "shipment/SUBMIT_NEW_COMMENT";
const RECEIVE_NEW_COMMENT = "shipment/RECEIVE_NEW_COMMENT";
const SUBMIT_NEW_COMMENT_FAILED = "shipment/SUBMIT_NEW_COMMENT_FAILED";
const CANCEL_NEW_COMMENT = "shipment/CANCEL_NEW_COMMENT";
const SET_IS_UPDATING_COMMENT = "shipment/SET_IS_UPDATING_COMMENT";
const SET_IS_UPDATING_COMMENT_FAILED =
  "shipment/SET_IS_UPDATING_COMMENT_FAILED";
const CANCEL_UPDATE_COMMENT = "shipment/CANCEL_UPDATE_COMMENT";
const SUBMIT_BATCH_COMMENTS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS`;
const SUBMIT_BATCH_COMMENTS_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_SUCCESS`;
const SUBMIT_BATCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_FAILED`;
const SUBMIT_BATCH_COMMENTS_RESET = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_RESET`;

//fetcher helper
// H1-678: only need to pass along the VIN search if it was last used
const urlBuilder = ({
  shipmentID,
  state,
  searchObj = {},
  searchCategory = "everything",
} = {}) => {
  let url = SHIPMENT_DETAILS_URL + shipmentID;

  if (searchObj.value) {
    let category = SEARCH_CATEGORIES[searchCategory];
    if (category === SEARCH_CATEGORIES["vin"]) {
      url += category.queryBuilder(searchObj.value, state);
      // Replace the first query parameter prefix with ?
      url = url.replace("&", "?");
    }
  }

  return url;
};

// Fetchers

// H1-609: Added multimodal shipment fetch to shipment details
//  if regular shipment, multimodal details fetch will return 404
//  if multimodal, regular fetch details will return 404
//  whichever call returns successfully will populate the shipment prop
//  on successfull return of multimodal, fetch child shipment details
const fetchShipmentDetails = (
  shipmentID,
  detailUrl,
  clearShipmentDetails = true,
) => {
  return (dispatch, getState) => {
    // standard
    const state = getState();
    if (clearShipmentDetails) {
      dispatch({
        type: CLEAR_SHIPMENT_DETAILS,
      });
    }

    dispatch({
      type: FETCH_SHIPMENT_DETAILS,
    });

    // H1-678: is this how this is done?  Need the url built here to also reflect
    // the search state in one possible way.
    // If a fetch URL was explicitly passed (from carrier or VIN), use it. Otherwise, build the URL
    let url =
      detailUrl ||
      urlBuilder({
        shipmentID: shipmentID,
        state: state,
        searchObj: state.search.searchObj,
        searchCategory: state.search.searchCategory,
      });
    const config = { headers: { "X-Time-Zone": moment.tz.guess() } };
    Promise.all([axios.get(url, config)])
      .then((responses) => {
        const shipmentDetails = responses[0].data;
        dispatch({
          type: RECEIVE_SHIPMENT_DETAILS,
          data: shipmentDetails,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: RECEIVE_SHIPMENT_DETAILS,
          data: initialState.data,
        });
      });

    // multimodal
    dispatch({
      type: FETCH_MULTIMODAL_DETAILS,
    });

    url = `${MULTIMODAL_DETAILS_URL}${shipmentID}`;
    Promise.all([axios.get(url, config)])
      .then((responses) => {
        let data = responses[0].data;
        dispatch({
          type: RECEIVE_MULTIMODAL_DETAILS,
          data: data,
        });

        // fetch child shipments
        dispatch(fetchChildShipmentDetails(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: RECEIVE_MULTIMODAL_DETAILS,
          data: initialState.parentShipmentDetails,
        });
      });
  };
};

const clearShipmentDetails = () => {
  return {
    type: CLEAR_SHIPMENT_DETAILS,
  };
};

/* H1-1218 Update to support change in api call. Fetch on carriers/TEST/shipments/:creatorShipmentId:
 will only return the shipment_id and not the full details. Once that returns, we call fetchShipmentDetails
 with that shipment_id like a regular shipment to ge the rest of the details */
const fetchShipmentDetailsFromCarrierInfo = (scac, creatorShipmentId) => {
  return (dispatch) => {
    const url = `${CARRIERS_URL}/${scac}/shipments/${creatorShipmentId}`;

    dispatch({
      type: CLEAR_SHIPMENT_DETAILS,
    });

    dispatch({
      type: FETCH_SHIPMENT_DETAILS,
    });

    Promise.all([axios.get(url)])
      .then((responses) => {
        const { shipment_id } = responses[0].data;
        dispatch(fetchShipmentDetails(shipment_id));
      })
      .catch((err) => console.log(err));
  };
};

/* H2-268 Finished Vehicle Shipment Details has a different api call similar to Customer Carrier Info */
const fetchShipmentDetailsFromVin = (scac, creatorShipmentId, vin) => {
  const url = `${CARRIERS_URL}/${scac}/shipments/${creatorShipmentId}?vin_values=${vin}`;
  return (dispatch) => dispatch(fetchShipmentDetails(creatorShipmentId, url));
};

const fetchShipmentTripPlan = (shipmentId) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_SHIPMENT_TRIP_PLAN,
    });

    const url = `${TRIP_PLAN_URL}${shipmentId}`;
    return axios
      .get(url)
      .then((response) => {
        let data = response?.data;
        let formattedData;
        if (response) {
          formattedData = buildTripPlan(data);
        }

        dispatch({
          type: RECEIVE_SHIPMENT_TRIP_PLAN,
          data: formattedData,
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({ type: SHIPMENT_TRIP_PLAN_ERROR });
      });
  };
};

const fetchRouteHeatmap = (routeId) => {
  return (dispatch) => {
    const url = `${HEAT_MAP_URL}?routeId=${encodeURIComponent(routeId)}`;
    dispatch({ type: FETCH_ROUTE_HEATMAP });
    axios
      .get(url)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: RECEIVE_ROUTE_HEATMAP,
          data: data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: RECEIVE_ROUTE_HEATMAP_ERROR });
      });
  };
};

const fetchInboundShipmentTotals = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INBOUND_SHIPMENT_TOTALS });
    return axios
      .get(SHIPMENT_TOTALS_URL)
      .then((response) => {
        dispatch({
          type: RECEIVE_INBOUND_SHIPMENT_TOTALS,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const fetchChildShipmentDetails = (parentShipment) => {
  return (dispatch) => {
    let requests = [];
    let shipmentIdHash = {};

    parentShipment.child_shipments.forEach((childShipment) => {
      const url = `${SHIPMENT_DETAILS_URL}${childShipment.shipment_id}`;
      requests.push(axios.get(url));
      shipmentIdHash[url] = childShipment.shipment_id;
    });

    dispatch({
      type: FETCH_CHILD_SHIPMENT_DETAILS,
    });

    Promise.all(requests)
      .then((responses) => {
        responses.forEach((resp) => {
          const childShipmentId = shipmentIdHash[resp.config.url];
          dispatch({
            type: RECEIVE_CHILD_SHIPMENT_DETAILS,
            id: childShipmentId,
            data: resp.data,
          });
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS,
        });
      });
  };
};

const fetchLegShipmentDetails = (activeLegs) => {
  return (dispatch) => {
    let requests = [];
    let shipmentIdHash = {};
    let vin = activeLegs.id;

    if (activeLegs.tripLegs !== null) {
      activeLegs.tripLegs.forEach((leg) => {
        // H2-2221: Don't request shipment details for "fake" legs.
        if (
          leg.id === "fvGenerated" ||
          leg.internalMetadata?.legDataType === "placeholder"
        ) {
          return;
        }

        /* H1-1261 Add parameter 'format=DETAIL' to use "full details" header for fetch shipment request */
        const url = `${CARRIERS_URL}/${leg.carrierInfo.carrierScac}/shipments/${leg.id}?vin_values=${vin}&format=DETAIL`;
        requests.push(
          axios.get(url).catch((err) => {
            // Catching errors here so that the other promises can get through if one fails
            console.error(err);
          }),
        );
        shipmentIdHash[url] = leg.id;
      });

      dispatch({
        type: FETCH_CHILD_SHIPMENT_DETAILS,
      });

      Promise.all(requests)
        .then((responses) => {
          responses.forEach((resp) => {
            if (resp) {
              const childShipmentId = shipmentIdHash[resp.config.url];
              dispatch({
                type: RECEIVE_CHILD_SHIPMENT_DETAILS,
                id: childShipmentId,
                data: resp.data,
              });
            }
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          dispatch({
            type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS,
          });
        });
    } else {
      dispatch({
        type: CLEAR_CHILD_SHIPMENT_DETAILS,
      });
    }
  };
};

const clearLegShipmentDetails = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CHILD_SHIPMENT_DETAILS,
    });
  };
};

const fetchComments = (shipmentId, pageNumber, pageSize) => {
  let url = `${SHIPMENTS_URL}/${shipmentId}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_SHIPMENT_COMMENTS,
      });
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_SHIPMENT_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_SHIPMENT_COMMENTS_FAILED,
        });
      });
  };
};

function addComment(shipmentId, data) {
  let url = `${SHIPMENTS_URL}/${shipmentId}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
}

function cancelAddComment(fakeCommentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
}

function addBatchComments(data, isCsvFormat) {
  let url = `${BASE_URL}/comment/batch`;
  const { shareableOrg, shipperOrgId } = data;
  let params = {};
  if (isCsvFormat) {
    params.sharedWith = shareableOrg ? `[${shareableOrg}]` : "[]";
    if (shipperOrgId) {
      params.shipperOrgId = shipperOrgId;
    }
  }
  const headers = {
    "content-type": isCsvFormat ? "text/csv" : "application/json",
  };
  const postData = isCsvFormat ? data.csv : data;

  return (dispatch) => {
    dispatch({
      type: SUBMIT_BATCH_COMMENTS,
      payload: { data },
    });
    return axios
      .post(url, postData, { headers, params })
      .then((response) => {
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_SUCCESS,
          payload: { data },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_FAILED,
          payload: { data },
        });
      });
  };
}

function clearBatchComments() {
  return (dispatch) => {
    dispatch({ type: SUBMIT_BATCH_COMMENTS_RESET });
  };
}

function updateComment(shipmentId, commentId, updatedData) {
  let url = `${SHIPMENTS_URL}/${shipmentId}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };

    return axios
      .patch(url, requestData)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
}

function cancelUpdateComment(commentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId },
    });
  };
}

function markCommentsRead(shipmentId, datetime) {
  let url = `${SHIPMENTS_URL}/${shipmentId}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (dispatch) => {
    return axios
      .post(url, data)
      .then((response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

const buildTripPlan = (response) => {
  let tripPlan = response.shipment_trip_plan.map((tp) => {
    return {
      actual: tp.actual,
      estimated: tp.estimated,
      event: tp.event,
      isMissed: tp.is_missed,
      isUnplanned: tp.is_unplanned,
      location: {
        code: tp.location?.code,
        city: tp.location?.city,
        state: tp.location?.state,
        address: tp.location?.address,
        name: tp.location?.name,
        postalCode: tp.location?.postalCode,
        country: tp.location?.country,
      },
      scheduled: tp.scheduled,
      timezone: tp.timezone,
    };
  });

  return {
    currentSchedule: response.current_schedule,
    originalSchedule: response.original_schedule,
    tripPlan: tripPlan,
  };
};

const initialState = {
  exceptionTotals: {
    total_shipments: null,
    details: [],
  },
  shipmentExceptionTotals: {},
  exceptionTotalsIsLoading: false,
  data: {},
  parentShipmentDetails: {},
  routeHeatmap: null,
  isShipmentDetailsLoading: false,
  isParentShipmentDetailsLoading: false,
  isChildShipmentDetailsLoading: false,
  childShipmentDetails: {},
  isLoadingTripPlan: false,
  shipmentTripPlan: null,
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  isBatchCommentInProgress: false,
  isBatchCommentSuccessful: false,
  isBatchCommentFailed: false,
};

function ShipmentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_INBOUND_SHIPMENT_TOTALS:
      return {
        ...state,
        exceptionTotalsIsLoading: true,
      };

    case RECEIVE_INBOUND_SHIPMENT_TOTALS:
      return {
        ...state,
        exceptionTotalsIsLoading: false,
        exceptionTotals: action.payload,
      };

    case CLEAR_SHIPMENT_DETAILS:
      return {
        ...state,
        data: initialState.data,
        parentShipmentDetails: initialState.parentShipmentDetails,
        childShipmentDetails: initialState.childShipmentDetails,
      };

    case FETCH_SHIPMENT_DETAILS:
      return {
        ...state,
        data: initialState.data,
        isShipmentDetailsLoading: true,
      };

    case RECEIVE_SHIPMENT_DETAILS:
      return {
        ...state,
        data: action.data,
        isShipmentDetailsLoading: false,
      };

    case FETCH_MULTIMODAL_DETAILS:
      return {
        ...state,
        parentShipmentDetails: initialState.parentShipmentDetails,
        isParentShipmentDetailsLoading: true,
      };

    case RECEIVE_MULTIMODAL_DETAILS:
      return {
        ...state,
        parentShipmentDetails: action.data,
        isParentShipmentDetailsLoading: false,
      };

    case FETCH_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: initialState.childShipmentDetails,
        isChildShipmentDetailsLoading: true,
      };

    case RECEIVE_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: {
          ...state.childShipmentDetails,
          [action.id]: action.data,
        },
      };

    case CLEAR_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: initialState.childShipmentDetails,
      };

    case RECEIVE_ALL_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isChildShipmentDetailsLoading: false,
      };

    case FETCH_SHIPMENT_TRIP_PLAN:
      return {
        ...state,
        isLoadingTripPlan: true,
        shipmentTripPlan: null,
      };

    case RECEIVE_SHIPMENT_TRIP_PLAN:
      return {
        ...state,
        isLoadingTripPlan: false,
        shipmentTripPlan: action.data,
      };

    case SHIPMENT_TRIP_PLAN_ERROR:
      return {
        ...state,
        isLoadingTripPlan: false,
        shipmentTripPlan: null,
      };

    case RECEIVE_ROUTE_HEATMAP:
      return {
        ...state,
        routeHeatmap: action.data,
      };

    case FETCH_ROUTE_HEATMAP:
    case RECEIVE_ROUTE_HEATMAP_ERROR:
      return {
        ...state,
        routeHeatmap: [],
      };

    case FETCH_SHIPMENT_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_SHIPMENT_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_SHIPMENT_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.union(state.comments.data, action.payload.comments.data),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let comments = state.comments;
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        comments.data = comments.data.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      comments.data = comments.data.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: comments,
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let comments = state.comments;

      comments.data = comments.data.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: comments,
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };

    case SUBMIT_BATCH_COMMENTS:
      return {
        ...state,
        isBatchCommentInProgress: true,
      };

    case SUBMIT_BATCH_COMMENTS_SUCCESS:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: true,
      };

    case SUBMIT_BATCH_COMMENTS_FAILED:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentFailed: true,
      };

    case SUBMIT_BATCH_COMMENTS_RESET:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: false,
        isBatchCommentFailed: false,
      };

    default:
      return state;
  }
}

// selectors

const getShipmentDetails = (state) => {
  let data = state[STORE_MOUNT_POINT].data;

  // Use data from /shipping-ng/trip/:id for multimodal shipments.
  if (data?.shipment_details?.is_multileg) {
    const original = data;
    data = state[STORE_MOUNT_POINT].parentShipmentDetails;

    // Pass along data in the same object. We can't create a new one or the
    // component will have an infinite render loop.
    if (!_.isEmpty(data)) {
      data.eta_time_category = original.eta_time_category;
    }
  }

  return data;
};

const getShipmentTripPlan = (state) => {
  return state[STORE_MOUNT_POINT].shipmentTripPlan || null;
};

const getShipmentTripPlanIsLoading = (state) => {
  return state[STORE_MOUNT_POINT].isLoadingTripPlan;
};

const getRouteHeatmap = (state) => {
  return state[STORE_MOUNT_POINT].routeHeatmap || null;
};

const getExceptionTotals = (state) => state[STORE_MOUNT_POINT].exceptionTotals;

const getExceptionTotalsIsLoaded = (state) =>
  !state[STORE_MOUNT_POINT].exceptionTotalsIsLoading;

const getChildShipmentDetails = createSelector(
  // only recalculate when child shipment details changes
  [(state) => state[STORE_MOUNT_POINT].childShipmentDetails],
  (childShipmentDetails) => childShipmentDetails || {},
);

const getAllShipmentsFlattened = createSelector(
  getShipmentDetails,
  getChildShipmentDetails,
  (parentShipment, childShipments) =>
    [parentShipment, ..._.values(childShipments)].filter(_.negate(_.isEmpty)),
);

const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments;
const getComments = (state) => state[STORE_MOUNT_POINT].comments || [];

const getIsBatchCommentInProgress = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentInProgress;
const getIsBatchCommentSuccessful = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentSuccessful;
const getIsBatchCommentFailed = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentFailed;

const getIsLoaded = (state) => {
  const {
    isShipmentDetailsLoading = false,
    isParentShipmentDetailsLoading = false,
    isChildShipmentDetailsLoading = false,
  } = state[STORE_MOUNT_POINT];

  return (
    !isShipmentDetailsLoading &&
    !isParentShipmentDetailsLoading &&
    !isChildShipmentDetailsLoading
  );
};

// interface
const shipmentsStatusState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchShipmentDetails,
    clearShipmentDetails,
    fetchShipmentDetailsFromCarrierInfo,
    fetchShipmentDetailsFromVin,
    fetchShipmentTripPlan,
    fetchRouteHeatmap,
    fetchInboundShipmentTotals,
    fetchLegShipmentDetails,
    clearLegShipmentDetails,
    fetchComments,
    addComment,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
    addBatchComments,
    clearBatchComments,
  },
  selectors: {
    getShipmentDetails,
    getShipmentTripPlan,
    getShipmentTripPlanIsLoading,
    getRouteHeatmap,
    getExceptionTotals,
    getExceptionTotalsIsLoaded,
    getChildShipmentDetails,
    getAllShipmentsFlattened,
    getIsLoaded,
    getIsFetchingComments,
    getComments,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
  },
  reducer: ShipmentsReducer,
};
export default shipmentsStatusState;
