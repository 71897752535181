/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import Colors from "../../styles/colors";

export const ResetSearchButton = ({ onClick = () => {} }) => {
  const { t } = useTranslation("components");

  return (
    <Button
      variant="link"
      css={{
        color: Colors.text.GRAY,
        ":hover": {
          color: Colors.background.DARK_BLUE,
        },
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
      data-qa="button-search-results-reset"
    >
      <Text italic underline>
        {t("components:Reset")}
      </Text>
      <Icon src={faTimesCircle} style={{ marginLeft: "0.3em" }} />
    </Button>
  );
};

ResetSearchButton.propTypes = {
  onClick: PropTypes.func,
};
