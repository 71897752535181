import axios from "axios";

export const buildReportsUnderFolder = (mountPoint, getReportsUrl) => {
  // Actions
  const FETCH_REPORTS = `${mountPoint}/FETCH_REPORTS`;
  const RECEIVE_REPORTS = `${mountPoint}/RECEIVE_REPORTS`;
  const RECEIVE_REPORTS_ERROR = `${mountPoint}/RECEIVE_REPORTS_ERROR`;

  // Action creators
  const fetchSubFolderData = (
    directoryId,
    reportType,
    appliedFilters,
    sortColumn,
    reverseSort,
  ) => {
    return (dispatch) => {
      dispatch({
        type: FETCH_REPORTS, //initial empty data load
        payload: { directoryId },
      });
      const reportUrl = getReportsUrl(
        directoryId,
        reportType,
        appliedFilters,
        sortColumn,
        reverseSort,
      );
      return (
        axios
          // .get('./dataReportUnderDirectory.json')
          .get(reportUrl)
          .then((response) => {
            dispatch({
              type: RECEIVE_REPORTS,
              payload: {
                directoryId,
                reportData: response.data,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: RECEIVE_REPORTS_ERROR,
              payload: { directoryId },
            });
          })
      );
    };
  };

  // Selectors
  const getSubFolderByQualifier = (directoryId) => (state) =>
    state[mountPoint].reportDatalMap.get(directoryId);

  // Initial state
  const initialState = {
    reportDatalMap: new Map(),
  };

  const reducer = (state = initialState, action) => {
    // be sure to never mutate state with redux
    const newReportDatalMap = new Map(state.reportDatalMap);

    switch (action.type) {
      case FETCH_REPORTS: //call to url
        newReportDatalMap.set(action.payload.directoryId, {
          data: [],
          isLoading: true,
        });
        return {
          ...state,
          reportDatalMap: newReportDatalMap,
        };

      case RECEIVE_REPORTS:
        const { directoryId, reportData } = action.payload;
        newReportDatalMap.set(directoryId, {
          data: reportData,
          isLoading: false,
        });
        return {
          ...state,
          reportDatalMap: newReportDatalMap,
        };

      case RECEIVE_REPORTS_ERROR:
        newReportDatalMap.set(action.payload.directoryId, {
          isLoading: false,
          isError: true,
        });
        return {
          ...state,
          reportDatalMap: newReportDatalMap,
        };

      default:
        return state;
    }
  };

  // interface
  return {
    mountPoint,
    actionCreators: { fetchSubFolderData },
    selectors: { getSubFolderByQualifier },
    reducer,
  };
};
