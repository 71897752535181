/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Dashboard } from "components/templates/Dashboard.template";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { MediaQueries } from "components/responsive";
import SearchBarContainer from "pages/shipments/components/search/Shipments.SearchBar.container";
import FiltersContainer from "pages/shipments/components/search/Shipments.SearchFilters.container";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { ShipmentsExceptionsWidget } from "./components/organisms/Shipments.ExceptionsWidget.organism";
import InboundLiveDwellWidgetContainer from "./components/InboundLiveDwellWidgetContainer";
import InboundArrivalStatusWidgetContainer from "./components/InboundArrivalStatusWidgetContainer";
import ShipmentsWatchTable from "./components/organisms/Shipments.WatchTable.organism";
import { ShipmentSavedSearchCards } from "./components/organisms/Shipments.SavedSearchCards.organism";

export const ShipmentsDashboard = ({
  fetchInboundShipmentTotals,
  resetSearch,
  clearSearchFilters,
  setSearchFilter,
  searchEntities,
  currentOrganizationId,
  exceptionTotals,
  isExceptionTotalsLoaded,
  exceptionTypes,
  showShipmentLiveDwellWidget,
}) => {
  const { t } = useTranslation("shipment-status");

  const [showFilters, setShowFilters] = useState(false);

  useSetTitleOnMount(t("shipment-status:Shipment Exceptions"));
  useSetDescriptionOnMount(
    t(
      "shipment-status:All shipments for your organization and their related shipment exceptions",
    ),
  );
  useTrackWithMixpanelOnce("Viewed Page: Shipments / Dashboard");

  useEffect(() => {
    resetSearch();
    clearSearchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchInboundShipmentTotals();
  }, [fetchInboundShipmentTotals, currentOrganizationId]);

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={() => setShowFilters(!showFilters)}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <ShipmentsExceptionsWidget
          exceptionTotals={exceptionTotals}
          exceptionTotalsIsLoading={!isExceptionTotalsLoaded}
          exceptionTypes={exceptionTypes}
          setSearchFilter={setSearchFilter}
          searchEntities={searchEntities}
        />
        <ShipmentSavedSearchCards />
        {showShipmentLiveDwellWidget ? (
          <div
            css={{
              display: "grid",
              gap: "1em",
              justifyItems: "stretch",
              [MediaQueries.smallAndDown]: {
                display: "flex",
                flexDirection: "column",
              },
              [MediaQueries.mediumAndUp]: {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
              [MediaQueries.extraLarge]: {
                gridTemplateColumns: "repeat(3, 1fr)",
              },
            }}
          >
            <InboundLiveDwellWidgetContainer />
            <InboundArrivalStatusWidgetContainer />
          </div>
        ) : null}
        <ShipmentsWatchTable />
      </div>
    </Dashboard>
  );
};

ShipmentsDashboard.propTypes = {
  activeOrganization: PropTypes.object,
  clearSearchFilters: PropTypes.func.isRequired,
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  exceptionTotals: PropTypes.object,
  exceptionTypes: PropTypes.array,
  setSearchFilter: PropTypes.func,
  searchEntities: PropTypes.func,
  fetchInboundShipmentTotals: PropTypes.func.isRequired,
  isExceptionTotalsLoaded: PropTypes.bool,
  resetSearch: PropTypes.func.isRequired,
  shipmentTypes: PropTypes.array,
  showShipmentLiveDwellWidget: PropTypes.bool,
  fetchLiveDwellCount: PropTypes.func,
  liveDwellCount: PropTypes.object,
  fetchLiveDwellShipments: PropTypes.func,
  liveDwellShipments: PropTypes.object,
  fetchArrivalStatusDetails: PropTypes.func,
  arrivalStatusDetails: PropTypes.object,
};
