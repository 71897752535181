import axios from "axios";
import apiUrl from "api-url";

const STORE_MOUNT_POINT = "containerTrackingCountsWidget";

//URLs
const BASE_URL = apiUrl("/containertracking/api/reuse-trip-container-totals");

//Action Types
const REQUEST_CONTAINER_TOTALS = "REQUEST_CONTAINER_TOTALS";
const RECEIVE_CONTAINER_TOTALS = "RECEIVE_CONTAINER_TOTALS";

//Action Creators
const fetchContainerTotals = () => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_CONTAINER_TOTALS });
    try {
      const response = await axios.get(BASE_URL);
      dispatch({
        type: RECEIVE_CONTAINER_TOTALS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

//Selectors
const getContainerTotals = (state) => state[STORE_MOUNT_POINT].containerTotals;
const getContainerTotalsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isContainerTotalsLoading;

//Intial State
const initialState = {
  containerTotals: {},
  isContainerTotalsLoading: false,
};

//Reducer
const ContainerTrackingCountsWidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CONTAINER_TOTALS:
      return {
        ...state,
        isContainerTotalsLoading: true,
      };

    case RECEIVE_CONTAINER_TOTALS:
      return {
        ...state,
        containerTotals: action.payload,
        isContainerTotalsLoading: false,
      };

    default:
      return state;
  }
};

//interface
export const ContainerTrackingCountsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchContainerTotals,
  },
  selectors: {
    getContainerTotals,
    getContainerTotalsLoadingFlag,
  },
  reducer: ContainerTrackingCountsWidgetReducer,
};
