import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "../components/search/DriveAway.searchOptions";
import apiUrl from "api-url";
import SearchState from "./DriveAwaySearchBarState";

const STORE_MOUNT_POINT = "driveAwaySavedSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "DRIVEAWAY",
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter,
  },
});
