import _ from "lodash";
import { FontSize } from "components/atoms/Text.atom";

export const getSolutionId = (featureData) => {
  return featureData
    ? featureData.find(
        (feature) => feature.feature_name === "Container Tracking",
      )?.solution ?? ""
    : "";
};

export const getFontSize = (array) => {
  const maxValue = _.max(array) ?? 0;

  if (maxValue > 999999) {
    return FontSize.size18;
  } else if (maxValue > 9999) {
    return FontSize.size24;
  } else {
    return FontSize.size32;
  }
};

export const getPercentageWithOneDecimalPoint = (count, totalCount) => {
  return !count || !totalCount ? 0 : ((count / totalCount) * 100).toFixed(1);
};
