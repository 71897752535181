import { connect } from "react-redux";
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import SearchBarContainer from "./PartView.SearchBar.container";
import FilterSectionContainer from "./PartView.SearchFilters.container";
import PartViewSearchBarState from "../../redux/PartViewSearchBarState";
import PartViewSavedSearchState from "../../redux/PartViewSavedSearchState";

function mapStateToProps(state) {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    PartViewSearchBarState.selectors;
  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
}

const { saveSearch, editSearch } = PartViewSavedSearchState.actionCreators;

const actionCreators = { saveSearch, editSearch };

export default connect(mapStateToProps, actionCreators)(EditSavedSearchModal);
