//External Pacakges
import axios from "axios";
import _ from "lodash";

import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import { FILTERS } from "pages/shipments/components/search/Shipments.searchOptions";
import {
  SHIPMENT_TOTALS_URL,
  BATCH_SHIPMENT_TOTALS_URL,
} from "./ShipmentsState";

const MOUNT_POINT = "shipmentSavedSearchCards";

const FETCH_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/FETCH_SAVED_SEARCH_CARD_DATA`;
const RECEIVE_SAVED_SEARCH_CARD_DATA = `${MOUNT_POINT}/RECIEVE_SAVED_SEARCH_CARD_DATA`;
const FETCH_SAVED_SEARCH_CARD_DATA_FAILED = `${MOUNT_POINT}/FETCH_SAVED_SEARCH_CARD_DATA_FAILED`;

const getQueryParams = (savedSearch, state) => {
  let queryParams = "";

  // Use saved search properties to build URL querystring
  Object.keys(savedSearch.search).forEach((f) => {
    const value = savedSearch.search[f];
    const searchFilter = _.find(FILTERS, {
      queryKey: f,
    });

    if (searchFilter && value) {
      // handle building QS for filters
      queryParams += searchFilter.queryBuilder(f, value);
    } else {
      // handle building QS for categories
      const searchCategory = _.find(SEARCH_CATEGORIES, {
        queryKey: f,
      });
      if (searchCategory && value) {
        queryParams += searchCategory.queryBuilder(f, value, state);
      }
    }
  });

  return queryParams;
};

const fetchSavedSearchCardData = (savedSearch) => {
  return (dispatch, getState) => {
    if (savedSearch != null && savedSearch.search != null) {
      dispatch({ type: FETCH_SAVED_SEARCH_CARD_DATA, id: savedSearch.id });
      let url = SHIPMENT_TOTALS_URL;
      let axiosRequest = null;

      url += getQueryParams(savedSearch, getState());

      // Replace the first query parameter prefix with ?
      url = url.replace("&", "?");

      if (savedSearch.search.batch) {
        // H1-1805: Modify totals request if saved search includes batch filter data
        const { batch } = savedSearch.search;
        const separator = url.includes("?") ? "&" : "?";
        const data = {
          batch_list: batch.batch_list,
        };

        url = url.replace(SHIPMENT_TOTALS_URL, BATCH_SHIPMENT_TOTALS_URL);
        url = `${url}${separator}batchType=${batch.batch_type}`;

        axiosRequest = axios.post(url, data);
      } else {
        // Normal saved search totals request
        axiosRequest = axios.get(url);
      }

      return axiosRequest
        .then((response) => {
          dispatch({
            type: RECEIVE_SAVED_SEARCH_CARD_DATA,
            id: savedSearch.id,
            data: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FETCH_SAVED_SEARCH_CARD_DATA_FAILED,
            id: savedSearch.id,
          });
          console.log(error);
        });
    }
  };
};

const getSavedSearchCardData = (id) => (state) =>
  state[MOUNT_POINT][id] ?? {
    data: {},
    isLoading: false,
    isLoadingError: false,
  };

const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          isLoading: true,
        },
      };
    case RECEIVE_SAVED_SEARCH_CARD_DATA:
      return {
        ...state,
        [action.id]: {
          data: action.data,
          isLoading: false,
          isLoadingError: false,
        },
      };
    case FETCH_SAVED_SEARCH_CARD_DATA_FAILED:
      return {
        ...state,
        [action.id]: {
          data: {},
          isLoading: false,
          isLoadingError: true,
        },
      };
    default:
      return state;
  }
};

export default {
  mountPoint: MOUNT_POINT,
  actionCreators: { fetchSavedSearchCardData },
  selectors: { getSavedSearchCardData },
  reducer: reducer,
};
