/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel, FormText } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Modal } from "components/molecules/Modal.molecule";
import { FormButton } from "components-old/modal-elems";
import SelectField from "components-old/forms/fields/SelectField";
import { ReportTypes } from "pages/reports/search/Reports.columns";

// Move Report
const initFilteredReport = {
  name: "",
  saveLocation: null,
  description: "",
};

const options = [
  {
    value: "my",
    label: "My Reports",
  },
  {
    value: "shared",
    label: "Shared Reports",
  },
  {
    value: "core",
    label: "Core Reports",
  },
];

export const MoveReportModal = (props) => {
  const {
    show,
    hide,
    report = {},
    isLoading = false,
    reportType,
    moveFolderReport,
    moveFolderReportModal,
    reportCategoryFolderData,
    clearShipmentSearchFilters,
    clearFinishedVehicleSearchFilters,
    shipmentSearchFilters,
    finishedVehicleSearchFilters,
    containerTrackingSearchFilters,
    clearContainerTrackingSearchFilters,
    fetchLocationDetails,
    actionHandler,
    fetchCategory,
    modalReportType,
    isFVAdmin,
  } = props;

  const { t } = useTranslation("reports");
  const [reportCategory, setReportCategory] = useState(undefined);
  const [reportCategoryID, setReportCategoryID] = useState(undefined);
  const [reportFolder, setReportFolder] = useState("none");
  const [filteredReport, setFilteredReport] = useState(initFilteredReport);

  const [prevReportCategory, setPrevReportCategory] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [showError, setShowError] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    if (show === false) {
      setShowError(false);
      setHasRequested(false);
    }
  }, [show]);

  useEffect(() => {
    fetchCategory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (report) {
      if (report.filterSet) {
        setFilteredReport((prevState) => {
          return {
            ...prevState,
            name: report.filterSet.name,
            description: report.filterSet.description,
            saveLocation: report.filterSet.private ? options[0] : options[1],
          };
        });
      } else {
        setFilteredReport((prevState) => {
          return {
            ...prevState,
            name: report.name,
            description: report.description,
            saveLocation: options[2],
          };
        });
      }
    }
  }, [report, fetchLocationDetails]);

  useEffect(() => {
    if (hasRequested) {
      // After we requeted data
      // Check if there are errors to display
      if (moveFolderReport?.isLoadingError) {
        setErrorText(t("reports:Something went wrong"));
        setShowError(true);
        setHasRequested(false);
        return;
      }
      if (prevReportCategory !== reportCategory) {
        modalReportType(prevReportCategory.toUpperCase());
      }
      modalReportType(reportCategory.toUpperCase());
    }
    //eslint-disable-next-line
  }, [hasRequested, moveFolderReport, prevReportCategory, t]);

  const clearForm = useCallback(() => {
    setFilteredReport(initFilteredReport);
    clearFinishedVehicleSearchFilters();
    clearShipmentSearchFilters();
    clearContainerTrackingSearchFilters();
  }, [
    setFilteredReport,
    clearFinishedVehicleSearchFilters,
    clearShipmentSearchFilters,
    clearContainerTrackingSearchFilters,
  ]);

  const onSave = () => {
    // Define filters
    let filters = {
      shipments: null,
      finished_vehicle: null,
    };

    // Make a deep copy of the filter values so we can edit them (without affecting the filters)
    let shipmentPayload = JSON.parse(JSON.stringify(shipmentSearchFilters));

    // Cleanup some filters values
    if (shipmentPayload.destination) {
      shipmentPayload.destination.forEach((element, index, array) => {
        array[index] = element.id;
      });
    }

    if (shipmentPayload.origin) {
      shipmentPayload.origin.forEach((element, index, array) => {
        array[index] = element.id;
      });
    }

    if (shipmentPayload.service_code) {
      shipmentPayload.service_code.forEach((element, index, array) => {
        array[index] = element.value;
      });
    }

    // Set filters
    if (filters) {
      filters.shipments = shipmentPayload;
      filters.finished_vehicle = finishedVehicleSearchFilters;
      filters.container_tracking = containerTrackingSearchFilters;
    } else {
      filters.shipments = null;
      filters.finished_vehicle = null;
      filters.container_tracking = null;
    }

    let data = {
      workspace_number:
        report?.filterSet?.workspace_number || report.workspaceId,
      report_name: filteredReport.name,
      report_description: filteredReport.description,
      filters: JSON.stringify(filters),
      directory_id: reportFolder === "none" ? reportCategoryID : reportFolder,
    };

    // directory_id: reportFolder === "none" ? categoryId : reportFolder

    // Figure out whether this is a private report or not based on the location value
    if (filteredReport.saveLocation === options[0]) {
      data.private = true;
    } else if (filteredReport.saveLocation === options[1]) {
      data.private = false;
    } else if (filteredReport.saveLocation === options[2]) {
      data.private = false;
    } else {
      console.error("Invalid location specified: ", data.location);
    }

    // Save
    const request = moveFolderReportModal(
      report?.filterSet?.report_number || report.reportId,
      report?.filterSet?.filter_set_id || report?.filterSet?.filterSetId,
      data,
    );

    request.then(() => {
      setHasRequested(true);
    });
  };

  useEffect(() => {
    if (show === false) {
      setReportFolder("none");
      setReportCategory(undefined);
      clearForm();
    }
  }, [show, clearForm]);

  const folderListHandler = useCallback((categoryType) => {
    const payload = { report: props, categoryType };
    return actionHandler("FOLDERS_LIST", payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //on load show the category of the report and call to endpoint to fetch the respective folder under each category
  useEffect(() => {
    const resultCategory = reportCategoryFolderData.categoryList?.filter(
      (category) => reportType === category.type.toUpperCase(),
    );
    // pre filled category option in move report
    if (resultCategory) {
      setReportCategory(resultCategory[0]?.type); // setCategory and this update invokes folderListHandler in one of the below useEffect
      setReportCategoryID(resultCategory[0]?.id); // set Category ID required for "None" option
      setPrevReportCategory(resultCategory[0]?.type);
    }
  }, [reportType, reportCategoryFolderData.categoryList, folderListHandler]);

  const onFolderChange = (option) => {
    setReportFolder(option.value);
  };

  const categoryList = () => {
    if (reportCategoryFolderData.categoryList) {
      // Core Reports option visible only for FV Admin
      if (!isFVAdmin || reportType !== ReportTypes.CORE_REPORTS) {
        return reportCategoryFolderData.categoryList.filter(
          (categoryType) =>
            categoryType.type.toLowerCase() !==
            ReportTypes.CORE_REPORTS.toLowerCase(),
        );
      } else {
        return reportCategoryFolderData.categoryList;
      }
    } else {
      return [];
    }
  };

  const categories = categoryList();

  //after getting the list from API response, set categoryOptions as array of object with label, categoryId and value
  const categoryOptions =
    _.uniqBy(
      categories.map((category) => ({
        label: category.name,
        categoryId: category.id,
        value: category.type,
      })),
      "value",
    ) ?? [];

  const folders = reportCategoryFolderData.folderList
    ? reportCategoryFolderData.folderList
    : [];

  //after getting the list from API response, set categoryOptions as array of object with label, categoryType and value

  const folderOptionsList = (reportCategoryID) => {
    let folderOptions =
      folders.map((folder) => ({
        label: folder.name,
        categoryType: folder.type,
        value: folder.id,
      })) ?? [];

    //push "None" value in the array of object with selected Category ID assigned to the value of "None" option
    folderOptions.unshift({
      label: "None",
      categoryType: null,
      value: "none",
    });

    return folderOptions;
  };

  let folderOptions = folderOptionsList(reportCategoryID);

  // On Category change set Category, pass category type to API endpoint to fetch related folders and set the category ID
  const onReportCategoryChange = (option) => {
    setReportCategory(option.value);
    setReportCategoryID(option.categoryId);
  };

  //on report category change
  useEffect(() => {
    folderListHandler(reportCategory); // pass category type to get the respective folders related to category type
    setReportFolder("none");
  }, [reportCategory, folderListHandler]);

  useEffect(() => {
    //on load show the folder of the report
    if (report && report.directoryId) {
      const resultFolder = reportCategoryFolderData.folderList?.filter(
        (folder) => report.directoryId === folder.id,
      );
      //prefilled folder name if report under directory
      if (resultFolder[0]?.type === reportCategory) {
        setReportFolder(resultFolder[0]?.id);
      } else {
        setReportFolder("none");
      }
    }
    //eslint-disable-next-line
  }, [reportCategoryFolderData.folderList]);

  let isOpenDisabled = _.isNil(reportCategory);

  let saveButtonText;

  saveButtonText = moveFolderReport?.isLoading
    ? t("reports:Saving...")
    : t("reports:Save");

  return (
    <Modal backdrop="static" show={show} onHide={() => hide()}>
      <Modal.Header title={t("reports:Move Report")} isLoading={isLoading} />
      <Modal.Body>
        <Alert
          variant={AlertVariant.Danger}
          show={showError}
          dismissible
          onDismiss={() => setShowError(false)}
        >
          {errorText}
        </Alert>

        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel className="fw-bold">
            {t("reports:Report Category")}:
          </FormLabel>
          <SelectField
            stateValue={reportCategory}
            options={categoryOptions}
            onChange={onReportCategoryChange}
            isSearchable={false}
            isDisabled={true} // SH-6070: Disabling moving categories for all users for now.
          />
        </FormGroup>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel className="fw-bold">
            {t("reports:Select Folder")}:
          </FormLabel>
          <SelectField
            stateValue={reportFolder}
            options={folderOptions}
            onChange={onFolderChange}
            isSearchable={false}
          />
        </FormGroup>
        <FormText muted style={{ fontSize: "80%" }}>
          <span css={{ fontWeight: "bold" }}>{t("reports:Note")}:</span>
          <span css={{ marginLeft: "0.25rem" }}>
            {t(
              "reports:Moving reports into the Shared Reports category will make the report visible across your organization",
            )}
          </span>
        </FormText>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          label={t("reports:Cancel")}
          disabled={isOpenDisabled || moveFolderReport?.isLoading}
          clickHandler={() => {
            hide();
            clearForm();
          }}
        />
        <FormButton
          actionType="ACTION"
          label={saveButtonText}
          disabled={isOpenDisabled || moveFolderReport?.isLoading}
          clickHandler={onSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

MoveReportModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  report: PropTypes.object,
  isLoading: PropTypes.bool,
  reportType: PropTypes.string,
  currentUserEmail: PropTypes.string,
  moveFolderReportModal: PropTypes.func,
  reportCategoryFolderData: PropTypes.object,
  clearShipmentSearchFilters: PropTypes.func,
  clearFinishedVehicleSearchFilters: PropTypes.func,
  clearContainerTrackingSearchFilters: PropTypes.func,
  shipmentSearchFilters: PropTypes.object,
  finishedVehicleSearchFilters: PropTypes.object,
  containerTrackingSearchFilters: PropTypes.object,
  isSaving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  fetchReports: PropTypes.func.isRequired,
  fetchLocationDetails: PropTypes.func,
  actionHandler: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  modalReportType: PropTypes.func.isRequired,
  isFVAdmin: PropTypes.bool.isRequired,
  moveFolderReport: PropTypes.object,
};
