import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";

const baseurl = "/containertracking/api/";

export const locationOptionsState = buildAsyncFilterOptionsState({
  topic: "ctLocationFilter",
  getUrl: () => {
    return apiUrl(`${baseurl}location?filter_name=location_code`);
  },
  pageSize: 5,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: `${item.location_name} (${item.location_code})`,
    value: item.location_code,
  }),
});

export const supplierCodeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctSupplierCodeFilter",
  getUrl: () => {
    return apiUrl(`${baseurl}suppliercode?filter_name=supplier_code`);
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});

export const programCodeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctProgramCodeFilter",
  getUrl: () => {
    return apiUrl(`${baseurl}programcode?filter_name=program_code`);
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});

export const containerTypeFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctContainerTypeFilter",
  getUrl: () => {
    return apiUrl(`${baseurl}containertype?filter_name=type`);
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});

export const sensorFilterOptions = buildAsyncFilterOptionsState({
  topic: "ctSensorFilter",
  getUrl: () => {
    return apiUrl(`${baseurl}sensorname?filter_name=latest_sensor_name`);
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});
