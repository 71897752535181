import { connect } from "react-redux";
import EditShipmentDwellNotificationState from "../../redux/EditShipmentDwellNotificationState";
import ShipmentDwellNotificationSearchBarState from "../../redux/ShipmentDwellNotificationSearchBarState";
import { EditShipmentDwellModal } from "./ShipmentDwellNotification.EditModal.organism";

const {
  getLocation,
  getRecipients,
  getIsPrefillDataLoading,
  getIsModifyDwellNotificationLoading,
  getModifyDwellResponse,
} = EditShipmentDwellNotificationState.selectors;

const mapStateToProps = (state) => {
  return {
    location: getLocation(state),
    recipients: getRecipients(state),
    isPrefillDataLoading: getIsPrefillDataLoading(state),
    isModifyDwellNotificationLoading:
      getIsModifyDwellNotificationLoading(state),
    modifyDwellResponse: getModifyDwellResponse(state),
  };
};

const {
  modifyDwellNotification,
  fetchPrefillData,
  clearPrefillData,
  clearModifyDwellNotification,
} = EditShipmentDwellNotificationState.actionCreators;

const { searchEntities } =
  ShipmentDwellNotificationSearchBarState.actionCreators;

const actionCreators = {
  modifyDwellNotification,
  fetchPrefillData,
  clearPrefillData,
  clearModifyDwellNotification,
  searchEntities,
};

export default connect(mapStateToProps, actionCreators)(EditShipmentDwellModal);
