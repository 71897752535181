import { connect } from "react-redux";
import { FilterSection } from "../../components/search-bar/FilterSection";
import FinVehicleSearchBarState from "./FinVehicleSearchBarState";
import { FILTERS } from "./FinVehicleSearchFilterDefs";
import {
  selectCarrierFilterOptions,
  selectExceptionTypeFilterOptions,
  selectProductTypeFilterOptions,
  selectLifeCycleStateFilterOptions,
  selectShipmentStatusFilterOptions,
  selectShipmentExceptionFilterOptions,
  selectOrderTypeFilterOptions,
  selectItssIdFilterOptions,
  selectSpotBuyFilterOptions,
  selectCurrentPositionTypes,
  selectRouteIdFilterOptions,
} from "./FinVehicleSearchFilterSelectors";
import DomainDataState from "../domain-data/DomainDataState";
import { getSolutionId } from "../organizations/OrganizationsState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  searchFilters: FinVehicleSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    FinVehicleSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  canUserSearch: FinVehicleSearchBarState.selectors.getCanUserSearch(state),
  carrierFilterOptions: selectCarrierFilterOptions(state),
  exceptionTypeFilterOptions: selectExceptionTypeFilterOptions(state),
  productTypeFilterOptions: selectProductTypeFilterOptions(state),
  lifeCycleStateFilterOptions: selectLifeCycleStateFilterOptions(state),
  orderTypeFilterOptions: selectOrderTypeFilterOptions(state),
  shipmentStatusFilterOptions: selectShipmentStatusFilterOptions(state),
  itssFilterOptions: selectItssIdFilterOptions(state),
  spotBuyFilterOptions: selectSpotBuyFilterOptions(state),
  shipmentExceptionFilterOptions: selectShipmentExceptionFilterOptions(state),
  solutionId: getSolutionId(state),
  regionFilterOptions:
    DomainDataState.selectors.selectRegionFilterOptions(state),
  currentPositionTypes: selectCurrentPositionTypes(state),
  routeIdOptions: selectRouteIdFilterOptions(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = FinVehicleSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
