import { connect } from "react-redux";

import UsersView from "./UsersView";
import RolesState from "modules/roles/RolesState";
import UsersState from "modules/users/UsersState";
import UsersSearchBarState from "./UsersSearchBarState";

function mapStateToProps(state) {
  return {
    roles: RolesState.selectors.getRoles(state),
    currentOrganizationId: state.organizations
      ? state.organizations.currentOrganizationId
      : "",
    organizationMembers: UsersSearchBarState.selectors.getSearchResults(state),
    isOrganizationMembersLoaded:
      UsersSearchBarState.selectors.getIsLoading(state),

    sortColumn: UsersSearchBarState.selectors.getSortColumn(state),
    reverseSort: UsersSearchBarState.selectors.getReverseSort(state),
    defaultSortColumn:
      UsersSearchBarState.selectors.getDefaultSortColumn(state),
    defaultReverseSort:
      UsersSearchBarState.selectors.getDefaultReverseSort(state),
    page: UsersSearchBarState.selectors.getPage(state),
    pageSize: UsersSearchBarState.selectors.getPageSize(state),
    totalPages: UsersSearchBarState.selectors.getTotalPages(state),
    totalCount: UsersSearchBarState.selectors.getTotalEntities(state),
    exportIdentifier: UsersSearchBarState.selectors.getExportIdentifier(state),
    exportName: UsersSearchBarState.selectors.getExportName(state),
    isExporting: UsersSearchBarState.selectors.getIsExporting(state),
    exportFailed: UsersSearchBarState.selectors.getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoles: () => dispatch(RolesState.actionCreators.fetchRoles()),
    fetchOrganizationMembers: (solutionId, resetPagination) =>
      dispatch(
        UsersSearchBarState.actionCreators.searchEntities(
          solutionId,
          resetPagination,
        ),
      ),
    setMembersPagination: (organizationId, pageNumber, pageSize) =>
      dispatch(
        UsersSearchBarState.actionCreators.setPagination(
          organizationId,
          pageNumber,
          pageSize,
        ),
      ),
    setMembersSort: (organizationId, sortColumn, reverseSort) =>
      dispatch(
        UsersSearchBarState.actionCreators.setSort(
          organizationId,
          sortColumn,
          reverseSort,
        ),
      ),
    clearOrganizationMemberDetails: () =>
      dispatch(UsersState.actionCreators.clearOrganizationMemberDetails()),
    exportEntities: (solutionId) => {
      dispatch(UsersSearchBarState.actionCreators.exportSearch(solutionId));
    },
    clearExportErrors: () => {
      dispatch(UsersSearchBarState.actionCreators.clearExportErrors());
    },
    resetExport: () => {
      dispatch(UsersSearchBarState.actionCreators.resetExport());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
