/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { ActionNav } from "./ContainerTrackingActionNav";
import { PhotoPlaceholder } from "components/no-data-placeholders";
import { Image, ImageType } from "components/atoms/Image.atom";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { DetailPanelGroupItem } from "pages/components/molecules/DetailPanelGroupItem.molecule";
import { ExceptionsBox } from "shared/components/molecules/ExceptionsBox.molecule";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getIconData } from "pages/finishedvehicle/utils/exceptions.utils";
import { MediaQueries, useIsExtraSmall } from "components/responsive";
import { isShipper } from "shared/utils/organizations.utils";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
} from "utils/date-time";
import Colors from "styles/colors";

export const ContainerTrackingDetailsWidget = ({
  containerDetails,
  containerMedia,
  supplierLocation,
  supplierLocationLoaded,
  ctActiveExceptions,
  watch,
  toggleWatchContainer,
  activeOrganization,
  eventHandler,
}) => {
  const {
    alternateLocationCode,
    containerId,
    eventTs,
    locationName,
    programCode,
    supplierCode,
    status,
    tagId,
    batteryCharge,
    type,
    device,
  } = containerDetails ?? {};

  const { t } = useTranslation("container-tracking");
  const labelStyle = { width: "auto", marginRight: "1em" };

  const locationCode = alternateLocationCode
    ? `(${alternateLocationCode})`
    : "";

  const isExtraSmall = useIsExtraSmall();
  let checkboxLabelText = t("container-tracking:Watch this Container");
  if (isExtraSmall) {
    checkboxLabelText = t("container-tracking:Watch");
  }
  const tooltipText = t(
    "container-tracking:Add this Container to your Watched Container list on the My Container Homepage and receive notifications for new comments",
  );

  const headerRightContent = (
    <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
      <WatchToggle
        id="watch"
        checked={watch ?? false}
        onChange={toggleWatchContainer}
        onClick={(e) => {
          //stop the event propagation else detail panel is also toggled since it is collapsible
          e.stopPropagation();
        }}
        labelText={checkboxLabelText}
        tooltipText={tooltipText}
      />
      {isShipper(activeOrganization) ? (
        <ActionNav eventHandler={eventHandler} />
      ) : null}
    </div>
  );

  const batteryLevelIcon = getIconData("Battery Level");

  return (
    <Fragment>
      <PanelGroup collapsible>
        <PanelGroup.Header
          title={t("container-tracking:Details")}
          rightContent={headerRightContent}
        ></PanelGroup.Header>
        <PanelGroup.Content>
          <FlexRowDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
            }}
          >
            <FlexColDiv css={{ flex: "1 1", marginRight: "1em" }}>
              {containerId ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id"
                  label={t("container-tracking:Container ID")}
                  labelStyle={labelStyle}
                >
                  {containerId}
                </DetailPanelGroupItem>
              ) : null}
              {type ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-type"
                  label={t("container-tracking:Container Type")}
                  labelStyle={labelStyle}
                >
                  {type}
                </DetailPanelGroupItem>
              ) : null}
              {status ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-status"
                  label={t("container-tracking:Status")}
                  labelStyle={labelStyle}
                >
                  {status}
                </DetailPanelGroupItem>
              ) : null}
              {programCode ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-program"
                  label={t("container-tracking:Program")}
                  labelStyle={labelStyle}
                >
                  {programCode}
                </DetailPanelGroupItem>
              ) : null}

              {supplierCode ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-supplier"
                  label={t("container-tracking:Supplier")}
                  labelStyle={labelStyle}
                  rowStyle={{ alignItems: "baseline" }}
                >
                  {supplierCode}
                  {supplierLocationLoaded && supplierLocation?.name && " - "}
                  {supplierLocationLoaded && supplierLocation?.name}
                </DetailPanelGroupItem>
              ) : null}
              {tagId ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-tag-id"
                  label={t("container-tracking:Tag ID")}
                  labelStyle={labelStyle}
                  valueStyle={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.25rem",
                  }}
                  rowStyle={{ alignItems: "baseline" }}
                >
                  {tagId}{" "}
                  <Text
                    bold
                    css={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <Icon
                      type={batteryLevelIcon.type}
                      src={batteryLevelIcon.src}
                      color={batteryLevelIcon.color}
                      css={{
                        width: "1rem",
                        height: "1rem",
                      }}
                    />
                    {t("container-tracking:Battery Level")}
                    {": "}
                    <Text bold color={Colors.text.DARK_BLUE}>
                      {batteryCharge || batteryCharge === 0
                        ? `${batteryCharge.toFixed(1)}%`
                        : "Null"}
                    </Text>
                  </Text>
                </DetailPanelGroupItem>
              ) : null}

              {locationCode || locationName || eventTs ? (
                <DetailPanelGroupItem
                  data-qa="details-container-id-last-update"
                  label={t("container-tracking:Last Update")}
                  labelStyle={labelStyle}
                  rowStyle={{ alignItems: "baseline" }}
                >
                  <FlexColDiv>
                    {status !== "In Transit" &&
                    (locationCode || locationName) ? (
                      <span>
                        <strong>
                          {locationCode} {locationName}
                        </strong>
                      </span>
                    ) : null}
                    {status === "In Transit" ? (
                      <span>
                        <strong>{status}</strong>
                      </span>
                    ) : null}
                    {eventTs ? (
                      <Fragment>
                        <span>{`${localizedDateFormatter(eventTs)}`}</span>
                        <span>{`${localizedTimeFormatter(eventTs)}`}</span>
                      </Fragment>
                    ) : null}
                    {device?.deviceName ? (
                      <Fragment>
                        <span css={{ display: "block" }}>
                          <strong>
                            {t("container-tracking:Inspector Device")}
                          </strong>
                        </span>
                        <span css={{ display: "block" }}>
                          {`${t("container-tracking:Device Name")} :`}
                        </span>
                        <span
                          css={{
                            display: "block",
                            color: Colors.text.RED,
                          }}
                        >
                          {device.deviceName}
                        </span>
                      </Fragment>
                    ) : null}
                  </FlexColDiv>
                </DetailPanelGroupItem>
              ) : null}
            </FlexColDiv>
            <FlexRowDiv
              css={{
                alignSelf: "flex-start",
                flexDirection: "column-reverse",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "row",
                },
              }}
            >
              <Image
                style={{
                  alignSelf: "center",
                  [MediaQueries.mediumAndDown]: {
                    marginRight: "1em",
                  },
                  [MediaQueries.largeAndUp]: {
                    marginTop: "1em",
                  },
                }}
                type={ImageType.Default}
                media={containerMedia}
                placeholder={<PhotoPlaceholder />}
                altText={t("container-tracking:Car")}
                data-qa={"image-shipper"}
              />
              <ExceptionsBox
                holds={[]}
                showAllException={true}
                exceptions={ctActiveExceptions}
                shouldBuildExceptionsBySeverity={true}
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </PanelGroup.Content>
      </PanelGroup>
    </Fragment>
  );
};

ContainerTrackingDetailsWidget.propTypes = {
  containerDetails: PropTypes.object,
  containerMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  eventHandler: PropTypes.func.isRequired,
  supplierLocation: PropTypes.object,
  supplierLocationLoaded: PropTypes.bool,
  ctActiveExceptions: PropTypes.array,
  watch: PropTypes.bool,
  toggleWatchContainer: PropTypes.func,
  activeOrganization: PropTypes.object,
};
