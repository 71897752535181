/** @jsxImportSource @emotion/react */
import { SeparatorOrientation } from "components/atoms/enums";
import PropTypes from "prop-types";
import Colors from "styles/colors";

export const Separator = ({
  color = Colors.background.LIGHT_GRAY,
  orientation = SeparatorOrientation.VERTICAL,
  text,
  style = {},
  className,
}) => {
  // Vertical Separator
  if (orientation === SeparatorOrientation.VERTICAL) {
    return (
      <div
        className={className}
        css={{
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderLeftColor: color,
        }}
        style={style}
      />
    );
  }

  // Horizontal Separator
  return (
    <div
      className={className}
      css={{
        display: "flex",
        lineHeight: "1em",
        color,
        margin: "1em 0",
        "&:before, &:after": {
          content: '""',
          display: "inline-block",
          flexGrow: "1",
          margin: "0.5em 0",
          backgroundColor: color,
          height: "1px",
        },
      }}
      style={style}
    >
      {text ? <div css={{ padding: "0 0.5em" }}>{text}</div> : null}
    </div>
  );
};

Separator.propTypes = {
  /**
   * Sets the Separator color.
   */
  color: PropTypes.string,
  /**
   * Vertical or Horizontal separators
   */
  orientation: PropTypes.oneOf(["VERTICAL", "HORIZONTAL"]),
  /**
   * Display text (for Horizontal separator only)
   */
  text: PropTypes.string,
  /**
   * An object that will be used as the element styles for the Separator.
   */
  style: PropTypes.object,
  /**
   * A string for defining one or more classes that will be added to the Separator.
   */
  className: PropTypes.string,
};
