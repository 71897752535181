/** @jsxImportSource @emotion/react */
import { MouseEventHandler } from "react";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { FontSize } from "components/atoms/enums";

export const RemoveListItemButton = ({
  onClick,
  "data-qa": dataQa,
}: RemoveListItemButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="transparent"
      css={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
      data-qa={dataQa}
    >
      <Icon
        src={faMinusCircle}
        color={Colors.text.RED}
        size={FontSize.size18}
        css={{ verticalAlign: "middle" }}
      />
    </Button>
  );
};

export type RemoveListItemButtonProps = {
  /**
   * A data qa attribute that allows QA automation team to easily locate the element
   */
  "data-qa"?: string;
  /**
   * The event fired when the user clicks the button.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
