/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Colors from "styles/colors";
import { translateNavLabelLookup } from "modules/appnav/nav-utils";

const NavDiv = styled.div(
  {
    padding: "5px 10px 5px 42px",
    position: "relative",
    fontSize: "1.02em",
    color: Colors.text.HIT_GRAY,
    ":hover": { color: "white" },
    ":hover > .nav-rollover": { visibility: "visible" },
  },
  (props) => ({
    backgroundColor: props.isActiveNavItem
      ? Colors.background.DARK_HIGHLIGHT_GRAY
      : "inherit",
  }),
);

export const NavItem = ({
  isActiveNavItem = false,
  sidebarMinimize,
  screen,
}) => {
  const { t } = useTranslation(["translation", "documentation-remote"]);

  let translatedTitle = screen.title
    ? t(screen.title)
    : translateNavLabelLookup(t, screen.actionType);

  let rolloverElem = sidebarMinimize ? (
    <span
      className="nav-rollover"
      css={{
        backgroundColor: "#33444c",
        borderRadius: 16,
        boxShadow: "4px 4px rgba(0, 0, 0, 0.4)",
        color: "white",
        fontSize: "small",
        fontWeight: 300,
        left: "75%",
        padding: "5px 0",
        position: "absolute",
        textAlign: "center",
        top: 6,
        visibility: "hidden",
        width: 230,
        zIndex: 20001,
        "&::after": {
          borderWidth: 5,
          borderStyle: "solid",
          borderColor: "transparent #67b5ff transparent transparent",
          content: `""`,
          marginTop: "-5px",
          position: "absolute",
          right: "100%",
          top: "50%",
        },
      }}
    >
      {translatedTitle}
    </span>
  ) : null;

  return (
    <NavDiv className="nav-item" isActiveNavItem={isActiveNavItem}>
      {!sidebarMinimize ? <span>{translatedTitle}</span> : null}
      {rolloverElem}
    </NavDiv>
  );
};

NavItem.propTypes = {
  isActiveNavItem: PropTypes.bool,
  sidebarMinimize: PropTypes.bool,
  screen: PropTypes.shape({
    title: PropTypes.string,
    actionType: PropTypes.string,
  }),
};
