import { connect } from "react-redux";
import DocumentationState from "./DocumentationState";
import { ContentView } from "./ContentView";

function mapStateToProps(state) {
  const filename = state.location.payload.filename;
  const baseUrl = state.location.payload.baseUrl;

  return {
    filename: filename,
    baseUrl: baseUrl,
    isLoading: DocumentationState.selectors.getContentStatus(state),
    content: DocumentationState.selectors.getContent(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchContent, pushDocumentView } = DocumentationState.actionCreators;
  return {
    fetchContent: (filename) => dispatch(fetchContent(filename)),
    pushDocumentView: (fileName, fileType, baseUrl) =>
      dispatch(pushDocumentView(fileName, fileType, baseUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentView);
