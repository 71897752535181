/** @jsxImportSource @emotion/react */
//External packages
import PropTypes from "prop-types";
import moment from "moment";
import momentTz from "moment-timezone";
import _ from "lodash";
import { produce } from "immer";
import { useState, useEffect, useMemo } from "react";

//External hooks or supporting code from React
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Internal hooks
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

//Components
import { ShipmentInfoForm } from "./components/organisms/ShipmentInfoForm.organism";
import { ShipmentStopsForm } from "./components/organisms/ShipmentStopsForm.organism";
import { ShipmentFormControls } from "./components/organisms/ShipmentFormControls.organism";
import { checkValidation } from "pages/shipments/redux/CreateShipmentState";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { FlexColDiv, Section } from "styles/container-elements";
import { useOrganizations } from "shared/hooks/useOrganizations";
import { SHIPPERS } from "pages/shipments/redux/CreateShipmentState";

const USER_TIMEZONE = momentTz.tz.guess();

export const STOP_INIT = {
  location: null,
  stopType: null,
  stopRole: null,
  earlyArrival: null,
  lateArrival: null,
  timezone: USER_TIMEZONE,
};

export const CreateShipment = ({
  createStatus,
  resetCreateForm,
  shipperLocations,
  activeOrganization,
  createShipment,
}) => {
  const { t } = useTranslation(["create-shipment"]);

  const [data, setData] = useState({
    customer: "",
    assetID: "",
    shipmentID: "",
    trailerID: "",
    routeID: "",
    origin: STOP_INIT,
    destination: STOP_INIT,
    stops: [],
    selectedShipper: null,
  });

  const [tabIndex, setTabIndex] = useState(0);

  let validation = {};

  useSetTitleOnMount(t("create-shipment:Create Shipment"));

  useSetDescriptionOnMount(
    t(
      "create-shipment:A form that allows Carriers to manually create their own shipments",
    ),
  );
  useTrackWithMixpanelOnce("Viewed Page: Shipments / Create");

  const locationOptions = useMemo(() => {
    const sortedLocations = _.sortBy(shipperLocations, "name");

    return sortedLocations.map((l) => {
      return {
        label: `${l.name} (${l.code})\n${l.address}\n${l.city}, ${l.state} ${l.postal_code}`,
        value: l.id,
        category: "location",
        categoryLabel: t("create-shipment:Location"),
        value2: l.time_zone,
      };
    });
  }, [t, shipperLocations]);

  useEffect(() => {
    if (createStatus) {
      if (
        createStatus !== "ERROR" &&
        createStatus !== "DUPLICATE" &&
        createStatus !== "IN_PROGRESS"
      ) {
        resetForm();
      }
      setTimeout(resetCreateForm, 3000);
    }
  }, [createStatus, resetCreateForm]);

  const { getOrganizationByFvId } = useOrganizations({
    fvIds: SHIPPERS.map((org) => org.value),
  });

  const updateField = (field, val) => {
    // special handling for the customer field
    // when a customer is selected, we need to
    /// get associated locations
    if (field === "customer") {
      const org = getOrganizationByFvId(val);
      setData({ ...data, selectedShipper: org });
    }

    setData(
      produce((draft) => {
        draft[field] = val;
      }),
    );
  };

  const updateStop = (n, field, val) => {
    setData(
      produce((draft) => {
        if (n === 101) {
          draft.destination[field] = val;
        } else if (n === 100) {
          draft.origin[field] = val;
        } else {
          draft.stops[n][field] = val;
        }
      }),
    );
  };

  const addStop = () => {
    setTabIndex(
      produce((draft) => {
        if (draft > data.stops.length) {
          draft += 1;
        }
      }),
    );
    setData(
      produce((draft) => {
        draft.stops.push(STOP_INIT);
      }),
    );
  };

  const removeStop = (n) => {
    setTabIndex(
      produce((draft) => {
        if (draft >= n + 1) {
          draft -= 1;
        }
      }),
    );
    setData(
      produce((draft) => {
        draft.stops.splice(n, 1);
      }),
    );
  };

  const reorderStop = (from, to) => {
    setTabIndex(to + 1);

    setData(
      produce((draft) => {
        let stop = draft.stops[from];
        draft.stops.splice(from, 1);
        draft.stops.splice(to, 0, stop);
      }),
    );
  };

  const changeTab = (n) => {
    setTabIndex(n);
  };

  const checkStopValidation = (stops) => {
    let isValid = true;

    stops.forEach((stop) => {
      let v = checkValidation(stop);
      isValid = isValid && v;
    });

    return isValid;
  };

  const resetForm = () => {
    setData({
      customer: "",
      assetID: "",
      shipmentID: "",
      trailerID: "",
      routeID: "",
      origin: STOP_INIT,
      destination: STOP_INIT,
      stops: [],
    });
    setTabIndex(0);
  };

  const isValid = ({ customer, shipmentID, origin, destination, stops }) => {
    let origEarly = moment(origin.earlyArrival).tz(origin.timezone);
    let origLate = moment(origin.lateArrival).tz(origin.timezone);
    let destEarly = moment(destination.earlyArrival).tz(destination.timezone);
    let destLate = moment(destination.lateArrival).tz(destination.timezone);

    let stopsValid = [];

    stops.forEach((stop, i) => {
      let stopEarly = moment(stop.earlyArrival).tz(stop.timezone, true);
      let stopLate = moment(stop.lateArrival).tz(stop.timezone, true);

      stopsValid.push({
        isValidLoc: !_.isNil(stop.location),
        isValidEarly: stopEarly.isValid(),
        isValidLate: stopLate.isValid() && stopEarly.isBefore(stopLate),
        isValidStopType: !_.isNil(stop.stopType),
        isValidStopRole: !_.isNil(stop.stopRole),
        isValidAfterOrigin:
          origEarly.isValid() &&
          stopEarly.isValid() &&
          stopEarly.isAfter(origEarly),
        isValidBeforeDest:
          destEarly.isValid() &&
          stopEarly.isValid() &&
          stopEarly.isBefore(destEarly),
      });
    });

    return {
      info: {
        isValidCustomer: customer !== "",
        isValidShipmentId: shipmentID !== "",
      },
      origin: {
        isValidLoc: !_.isNil(origin.location),
        isValidStopType: !_.isNil(origin.stopType),
        isValidStopRole: !_.isNil(origin.stopRole),
        isValidEarly: origEarly.isValid(),
        isValidLate: origLate.isValid() && origEarly.isBefore(origLate),
      },
      destination: {
        isValidLoc: !_.isNil(destination.location),
        isValidStopType: !_.isNil(destination.stopType),
        isValidStopRole: !_.isNil(destination.stopRole),
        isValidEarly: destEarly && destEarly.isValid(),
        isValidLate:
          destLate &&
          destEarly &&
          destLate.isValid() &&
          destEarly.isBefore(destLate),
        isValidAfterOrigin:
          origEarly.isValid() &&
          destEarly.isValid() &&
          destEarly.isAfter(origEarly),
      },
      stops: stopsValid,
    };
  };

  validation = isValid(data);
  return (
    <Section>
      <FlexColDiv
        css={{
          flex: 1,
          paddingLeft: "1em",
          paddingRight: "1em",
          paddingTop: "1em",
        }}
      >
        {createStatus && createStatus === "CREATED" ? (
          <Alert
            variant="success"
            style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
          >
            New shipment has been created.
          </Alert>
        ) : null}
        {createStatus && createStatus === "ERROR" ? (
          <Alert
            variant="danger"
            style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
          >
            Create shipment failed.
          </Alert>
        ) : null}
        {createStatus && createStatus === "DUPLICATE" ? (
          <Alert
            variant="danger"
            style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
          >
            {`Shipment ${data.shipmentID} already exists.`}
          </Alert>
        ) : null}
        <ShipmentInfoForm data={data} updateField={updateField} />
        <ShipmentStopsForm
          addStop={addStop}
          changeTab={changeTab}
          data={data}
          locationOptions={locationOptions}
          removeStop={removeStop}
          reorderStop={reorderStop}
          tabIndex={tabIndex}
          updateStop={updateStop}
          validation={validation}
          selectedShipper={data.selectedShipper}
        />
      </FlexColDiv>
      <ShipmentFormControls
        data={data}
        checkStopValidation={checkStopValidation}
        createShipment={createShipment}
        createStatus={createStatus}
        locations={shipperLocations}
        resetForm={resetForm}
        validation={validation}
        organization={activeOrganization}
      />
    </Section>
  );
};

CreateShipment.propTypes = {
  activeOrganization: PropTypes.object,
  createShipment: PropTypes.func.isRequired,
  createStatus: PropTypes.string,
  resetCreateForm: PropTypes.func.isRequired,
  shipperLocations: PropTypes.object,
};
