import axios from "axios";
import apiUrl from "api-url";

const DRIVE_AWAY_URL = apiUrl(`/dda/get-history`);
const STORE_MOUNT_POINT = "driveAwayStatusHistory";

// Actions
const STATUS_HISTORY_REQUESTED = `${STORE_MOUNT_POINT}/STATUS_HISTORY_REQUESTED`;
const STATUS_HISTORY_CANCELED = `${STORE_MOUNT_POINT}/STATUS_HISTORY_CANCELED`;
const STATUS_HISTORY_SUCCEEDED = `${STORE_MOUNT_POINT}/STATUS_HISTORY_SUCCEEDED`;
const STATUS_HISTORY_FAILED = `${STORE_MOUNT_POINT}/STATUS_HISTORY_FAILED`;
const RESET_STATUS_HISTORY = `${STORE_MOUNT_POINT}/RESET_STATUS_HISTORY`;

// Action creators
function fetchStatusHistory(internalId, solutionId) {
  return (dispatch) => {
    dispatch({ type: STATUS_HISTORY_REQUESTED });
    return Promise.all([
      axios.get(`${DRIVE_AWAY_URL}`, {
        params: { entityId: internalId, solutionId: solutionId },
      }),
    ])
      .then((response) => {
        dispatch({
          type: STATUS_HISTORY_SUCCEEDED,
          payload: response[0].data ? response[0].data : [],
        });
      })
      .catch((err) => {
        dispatch({ type: STATUS_HISTORY_FAILED, error: err });
      });
  };
}

function resetStatusHistoryStatus() {
  return (dispatch) => {
    dispatch({ type: RESET_STATUS_HISTORY });
  };
}

// Initial state
export const initialState = {
  fetchStatus: null,
  statusHistory: null,
};

// Reducer
function DriveAwayStatusHistoryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_STATUS_HISTORY:
      return {
        ...state,
        fetchStatus: null,
      };
    case STATUS_HISTORY_REQUESTED:
      return {
        ...state,
        fetchStatus: "IN_PROGRESS",
      };
    case STATUS_HISTORY_CANCELED:
      return {
        ...state,
        fetchStatus: "CANCEL",
      };
    case STATUS_HISTORY_SUCCEEDED:
      return {
        ...state,
        fetchStatus: "SUCCESS",
        statusHistory: action.payload,
      };
    case STATUS_HISTORY_FAILED:
      return {
        ...state,
        fetchStatus: "FAILED",
      };
    default:
      return state;
  }
}

// selector
const getStatusHistoryFetchStatus = (state) => {
  return state[STORE_MOUNT_POINT].fetchStatus;
};

const getStatusHistory = (state) => {
  return state[STORE_MOUNT_POINT].statusHistory;
};

// interface
const DriveAwayStatusHistoryState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    fetchStatusHistory,
    resetStatusHistoryStatus,
  },
  selectors: {
    getStatusHistoryFetchStatus,
    getStatusHistory,
  },
  reducer: DriveAwayStatusHistoryReducer,
};
export default DriveAwayStatusHistoryState;
