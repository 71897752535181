import { createSelector } from "reselect";
import i18next from "i18next";

import DriveAwayDomainDataState from "modules/domain-data/DriveAwayDomainData";
import { createOptionsMapper } from "utils/selectors-utils";
import { translateStatusLabel } from "pages/driveaway/utils/driveaway.utils";

// Selectors

// labelKey is what user can see
// valueKey is used as url params when selected
export const selectStatusStateFilterOptions = createSelector(
  [DriveAwayDomainDataState.selectors.getStatusStates],
  createOptionsMapper({
    labelKey: (item) =>
      translateStatusLabel(i18next.getFixedT(null, "driveaway-search"), item),
    valueKey: (item) => item,
    sorted: true,
  }),
);

export const selectCarrierFilterOptions = createSelector(
  [DriveAwayDomainDataState.selectors.getCarriers],
  createOptionsMapper({
    labelKey: (item) => `${item.carrier_name} (${item.carrier_scac})`,
    valueKey: "carrier_id",
  }),
);
