/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import RCTimePicker from "rc-time-picker";
import Colors from "styles/colors";
import { getTimeFormatFromUserPreferences } from "utils/date-time";

export const TimePicker = ({
  onChange,
  name,
  value,
  use12Hours,
  showSecond = false,
  format = "h:mm A",
  allowEmpty = true,
  disabled = false,
  className,
}) => {
  return (
    <RCTimePicker
      className={className}
      css={{
        "input[disabled]": {
          color: Colors.text.DISABLED,
          background: Colors.background.DISABLED_INPUT,
        },
      }}
      showSecond={showSecond}
      value={
        value ? moment(value, getTimeFormatFromUserPreferences()) : undefined
      }
      name={name}
      format={format}
      onChange={(value) => {
        if (value) {
          onChange(moment(value).format(getTimeFormatFromUserPreferences()));
        } else {
          onChange(value);
        }
      }}
      use12Hours={use12Hours}
      allowEmpty={allowEmpty}
      disabled={disabled}
    />
  );
};

TimePicker.propTypes = {
  /**
   * A handler for the onChange event.
   *
   * This function is passed two parameters; the new value and the event object.
   *
   * e.g. (value) => void
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The name of the rendered input.
   */
  name: PropTypes.string,

  /**
   * The value of the TimePicker.
   */
  value: PropTypes.string,

  /**
   * Allow clearing text
   */
  allowEmpty: PropTypes.bool,

  /**
   * Whether show second
   */
  showSecond: PropTypes.bool,

  /**
   * Moment format
   */
  format: PropTypes.string,

  /**
   * 12 hours display mode
   */
  use12Hours: PropTypes.bool,

  /**
   * Disable the TimePicker.
   */
  disabled: PropTypes.bool,

  /**
   * The CSS className to set on the parent element of TimePicker.
   * This allows support for Emotion's `css` prop.
   */
  className: PropTypes.string,
};
