import { connect } from "react-redux";
import InboundLiveDwellWidgetState from "pages/shipments/redux/InboundLiveDwellWidgetState";
import { InboundLiveDwellWidget } from "./organisms/InboundLiveDwellWidget.organism";

function mapStateToProps(state) {
  return {
    countData: InboundLiveDwellWidgetState.selectors.getCountData(state),
    shipmentResults:
      InboundLiveDwellWidgetState.selectors.getSearchResults(state),
    isShipmentsLoading:
      InboundLiveDwellWidgetState.selectors.getIsLoading(state),
    page: InboundLiveDwellWidgetState.selectors.getPage(state),
    pageSize: InboundLiveDwellWidgetState.selectors.getPageSize(state),
    totalPages: InboundLiveDwellWidgetState.selectors.getTotalPages(state),
    sortColumn: InboundLiveDwellWidgetState.selectors.getSortColumn(state),
    reverseSort: InboundLiveDwellWidgetState.selectors.getReverseSort(state),
    exportName: InboundLiveDwellWidgetState.selectors.getExportName(state),
    isExporting: InboundLiveDwellWidgetState.selectors.getIsExporting(state),
    exportFailed: InboundLiveDwellWidgetState.selectors.getExportFailed(state),
    exportIdentifier:
      InboundLiveDwellWidgetState.selectors.getExportIdentifier(state),
  };
}

const actionCreators = {
  fetchCount: InboundLiveDwellWidgetState.actionCreators.fetchCount,
  fetchShipments: InboundLiveDwellWidgetState.actionCreators.searchEntities,
  setSearchFilter: InboundLiveDwellWidgetState.actionCreators.setSearchFilter,
  setPagination: InboundLiveDwellWidgetState.actionCreators.setPagination,
  setSort: InboundLiveDwellWidgetState.actionCreators.setSort,
  pushShipmentDetailView: (id) => ({
    type: "SHIPMENT_DETAIL",
    payload: { shipment_id: id },
  }),
  exportSearch: InboundLiveDwellWidgetState.actionCreators.exportSearch,
  resetExport: InboundLiveDwellWidgetState.actionCreators.resetExport,
};

export default connect(mapStateToProps, actionCreators)(InboundLiveDwellWidget);
