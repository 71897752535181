/** @jsxImportSource @emotion/react */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import {
  faSyncAlt,
  faCheckDouble,
  faFilter,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import NotificationsState from "./NotificationsState";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import PropTypes from "prop-types";

import { NotificationList } from "./components/NotificationList";
import { MediaQueries } from "components/responsive";

import { Dropdown } from "components/molecules/Dropdown.molecule";
import { Form } from "react-bootstrap";

const { getNotificationMeta, getIsLoadingNotifications, getUnreadFilter } =
  NotificationsState.selectors;
const { postHasReadNotification, fetchNotification } =
  NotificationsState.actionCreators;
const { toggleUnreadFilter } = NotificationsState.actionCreators;

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    ref={ref}
    onClick={onClick}
    variant="transparent"
    css={{
      border: "none",
      "&:hover": { border: "none" },
    }}
  >
    <Icon type={IconType.FontAwesome} src={faEllipsis}></Icon>
  </Button>
));

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const NotificationActions = () => {
  const { t } = useTranslation("header-bar");
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingNotifications);
  const unreadFilter = useSelector(getUnreadFilter);

  const dropdownItemStyle = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  };

  return (
    <Dropdown autoClose="outside" as={CustomToggle}>
      <Dropdown.Item
        css={dropdownItemStyle}
        onClick={(event) => dispatch(toggleUnreadFilter())}
        as={"div"}
      >
        <Icon src={faFilter} />
        <Text>{t("header-bar:Only Show Unread")}</Text>

        <Form.Check
          type="switch"
          id="only-show-unread-switch"
          inline
          checked={unreadFilter}
          css={{ margin: 0 }}
        />
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={() =>
          dispatch(postHasReadNotification({ id: null, read: true }))
        }
        css={dropdownItemStyle}
      >
        <Icon src={faCheckDouble} />
        <Text>{t("header-bar:Mark all as read")}</Text>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() =>
          dispatch(fetchNotification({ pageSize: 10, refresh: true, page: 0 }))
        }
        css={dropdownItemStyle}
      >
        <Icon src={faSyncAlt} spin={isLoading} />
        <Text>{t("header-bar:Refresh Notifications")}</Text>
      </Dropdown.Item>
    </Dropdown>
  );
};

const NotificationsDropdown = () => {
  const { t } = useTranslation("header-bar");
  const notificationMeta = useSelector(getNotificationMeta);

  return (
    <div style={{ padding: 10, paddingTop: 0 }}>
      <div
        css={{
          display: "flex",
          flex: 1,
          // Default width so it expands if the parent has a width.
          width: "100%",
          alignContent: "center",
          marginRight: 10,
          [MediaQueries.smallAndUp]: {
            // Min width for when this is rendered in a drop down.
            minWidth: 390,
          },
        }}
      >
        <Text
          size={FontSize.size24}
          style={{ flex: 1, display: "flex", alignItems: "center" }}
        >
          {t("header-bar:Notifications")}
        </Text>
        <NotificationActions />
      </div>
      {notificationMeta.totalCount > 0 ? (
        <div
          id="scrollableDiv"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            scrollbarWidth: "none",
          }}
        >
          <SimpleBar
            autoHide={false}
            css={{
              width: "100%",
              height: "100%",
            }}
          >
            <NotificationList />
          </SimpleBar>
        </div>
      ) : (
        <div
          style={{ alignSelf: "flex-start", display: "flex", margin: "10px" }}
        >
          <Text>{t("No Notifications")}</Text>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;
