/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import logo from "assets/logos/fv_logo.svg";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

export const UnsubscribeV2 = ({
  ruleUuid,
  unsubscribeUuid,
  resetUnsubscribe,
  unsubscribe,
  isUnsubscribeComplete,
  isUnsubscribing,
  isUnsubscribeError,
  unsubscribeErrorMessage,
}) => {
  const { t } = useTranslation("unsubscribe-v2");

  useSetTitleOnMount(t("unsubscribe-v2:Unsubscribe"));

  useTrackWithMixpanelOnce("Viewed Page: Unsubscribe");

  useEffect(() => {
    resetUnsubscribe();
    unsubscribe(ruleUuid, unsubscribeUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Icon
        type={IconType.LocalImage}
        src={logo}
        style={{ "max-height": "87px", "max-width": "383px" }}
      />
      <div
        css={{
          width: "400px",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ddd",
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 20,
        }}
      >
        <Text bold size={FontSize.size28} css={{ textAlign: "center" }}>
          {t("unsubscribe-v2:Unsubscribe from Alerts")}
        </Text>
        {isUnsubscribing && (
          <React.Fragment>
            <Text css={{ marginTop: 10 }}>
              {t(
                "unsubscribe-v2:Unsubscribing you from alerts. Please wait a moment...",
              )}
            </Text>
          </React.Fragment>
        )}
        {isUnsubscribeComplete && (
          <Alert
            css={{ marginBottom: 0 }}
            variant={AlertVariant.Success}
            show={isUnsubscribeComplete}
          >
            <Text bold block>
              {t("unsubscribe-v2:Successfully Unsubscribed")}
            </Text>
            <Text block>
              {t(
                "unsubscribe-v2:You have successfully unsubscribed and will no longer receive alerts for this rule.",
              )}
            </Text>
          </Alert>
        )}
        {isUnsubscribeError && (
          <Alert
            css={{ marginTop: 25, marginBottom: 0 }}
            variant={AlertVariant.Danger}
            show={isUnsubscribeError}
          >
            <Text bold block>
              {t("unsubscribe-v2:Something Went Wrong")}
            </Text>
            <Text block>
              {t(
                "unsubscribe-v2:We could not unsubscribe you at this time. Please try again later. See below for more information.",
              )}
            </Text>
            <Text block italic>
              {unsubscribeErrorMessage}
            </Text>
          </Alert>
        )}
      </div>
    </div>
  );
};

UnsubscribeV2.propTypes = {
  ruleUuid: PropTypes.string.isRequired,
  unsubscribeUuid: PropTypes.string.isRequired,
  resetUnsubscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  isUnsubscribeComplete: PropTypes.bool,
  isUnsubscribing: PropTypes.bool,
  isUnsubscribeError: PropTypes.bool,
  unsubscribeErrorMessage: PropTypes.string,
};
