/*
   color names provided by Name that Color http://chir.ag/projects/name-that-color/
   sections from style guide
   If problematic, we can switch to something that makes more sense.
*/

export const Colors = {
  highlight: {
    MALIBU: "#78d4fa",
    MANTIS: "#81ca4c",
    BUTTERCUP: "#f3a01f",
    FLAMINGO: "#eb5b25",
    PUNCH: "#d53823",
    BOUQUET: "#a864a8",
    RIVER_BED: "#42505c",
    OLD_LACE: "#FEFAF4",
  },
  text: {
    RIVER_BED: "#42505c",
    HIT_GRAY: "#a0abb6",
    EBONY_CLAY: "#252e37",
  },
  background: {
    ATHENS_GRAY: "#f3f5f7",
    EBONY_CLAY: "#252e37",
    GRAY: "#e8ebef",
    GEYSER: "#dadfe3",
  },
};

/*
const MediaQueryBreakPoints = {
  mobile: 0,
  small: 768,
  medium: 992,
  large: 1200
};

const MediaQueries = {
  mobile: `@media (min-width: ${MediaQueryBreakPoints.mobile}px)`,
  small: `@media (min-width: ${MediaQueryBreakPoints.small}px)`,
  medium: `@media (min-width: ${MediaQueryBreakPoints.medium}px)`,
  large: `@media (min-width: ${MediaQueryBreakPoints.large}px)`
};
 */

export const Borders = {
  RADIUS: "0.333em",
};

export const BoxShadow = `5px 5px 20px ${Colors.background.GEYSER}`;
