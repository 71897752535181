import { connect } from "react-redux";

import { ReportBuilder } from "./ReportBuilder.page";
import ReportsState from "pages/reports/redux/ReportsState";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  const { getReports, getIsLoading } = ReportsState.selectors;

  return {
    reports: getReports(state),
    isLoading: getIsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchReports } = ReportsState.actionCreators;
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setDescription: (description) =>
      dispatch(TitleState.actionCreators.setDescription(description)),
    pushReportDetailScreen: (reportId) =>
      dispatch({
        type: "REPORT_DETAIL",
        payload: { reportId: reportId },
      }),
    fetchReports: () => dispatch(fetchReports()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilder);
