export enum OrganizationType {
  CARRIER = "Carrier",
  DEALER = "Dealer",
  DISTRIBUTION_CENTER = "Distribution Center",
  FREIGHTVERIFY = "FreightVerify",
  HEALTHCAREPROVIDER = "Healthcare Provider",
  PARTNER = "Partner",
  SHIPPER = "Shipper",
  SUPPLIER = "Supplier",
  THIRDPARTY = "ThirdParty",
}

export enum MapType {
  HERE = "Here",
  GOOGLE = "Google",
}

export type Organization = {
  organization_id: number;
  org_name: string;
  org_type: OrganizationType;
  fv_id: string;
  map_type: MapType;
};
