/** @jsxImportSource @emotion/react */
// Third party Libraries
import _ from "lodash";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// UI Libraries
import colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";

const CommentCell = (props) => {
  const comment = props.value;
  const maxCommentLength = 70;
  return (
    <Fragment>
      {!_.isNil(comment) ? (
        comment.length < maxCommentLength ? (
          <ShortCommentCell comment={comment}></ShortCommentCell>
        ) : (
          <LongCommentCell
            comment={comment}
            maxCommentLength={maxCommentLength}
          ></LongCommentCell>
        )
      ) : null}
    </Fragment>
  );
};

CommentCell.propTypes = {
  value: PropTypes.string,
};

const LongCommentCell = (props) => {
  const { t } = useTranslation("driveaway-search");
  const { comment, maxCommentLength } = props;
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      {showMore ? (
        <Text css={{ wordBreak: "break-all" }}>{comment}</Text>
      ) : (
        <Text css={{ wordBreak: "break-all" }}>
          {comment.substring(0, maxCommentLength)}
        </Text>
      )}

      <Text
        css={{ marginLeft: "0.5em", color: colors.linkText, cursor: "pointer" }}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? t("components:Show Less...") : t("components:Show More...")}
      </Text>
    </div>
  );
};

LongCommentCell.propTypes = {
  comment: PropTypes.string,
  maxCommentLength: PropTypes.number,
};

const ShortCommentCell = (props) => {
  const { comment } = props;
  return (
    <div>
      <Text css={{ wordBreak: "break-all" }}>{comment}</Text>
    </div>
  );
};

ShortCommentCell.propTypes = {
  comment: PropTypes.string,
};

export { CommentCell };
