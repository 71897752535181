import { getKeyUpdateForShipment } from "modules/shipment-detail/multimodal/EndToEndTabsPanel";

export const processTripProgress = (shipmentDetail, shipmentSummary, lads) => {
  let progress = shipmentDetail.current_location
    ? shipmentDetail.current_location.distance_progress.replace("%", "")
    : 0;
  progress =
    progress === "None" || progress === "0" ? 0 : parseInt(progress, 10);
  return shipmentSummary.shipment_stops.map((shipmentStop, index) => {
    let newLoc = {
      ...shipmentStop.location,
      lad: lads
        ? lads[shipmentStop.location.lad.code]
        : shipmentDetail.shipment_stops[index].location.lad,
    };
    return {
      ...shipmentStop,
      shipmentId: shipmentDetail.id,
      type: shipmentStop.location.lad.code,
      progress: index === 0 ? null : progress,
      updates: [],
      locationId: shipmentStop.location.id,
      name: shipmentStop.location.name,
      city: shipmentStop.location.city,
      state: shipmentStop.location.state,
      country: shipmentStop.location.country,
      location: newLoc,
    };
  });
};

export const flattenShipmentStops = (t, parent, childs, lads) => {
  let flattenedArr = [];
  parent.child_shipments.forEach((childShipment) => {
    let shipment = childs[childShipment.shipment_id];
    if (shipment) {
      processTripProgress(shipment, childShipment, lads).forEach(
        (tripShipment, index) => {
          let activeStatus =
            childShipment.shipment_id === parent.active_shipment ? true : false;
          if (index === 0) {
            flattenedArr.push({
              ...tripShipment,
              active: false,
            });
          } else {
            flattenedArr.push({
              ...tripShipment,
              active: activeStatus,
              updates: getKeyUpdateForShipment(t, shipment),
            });
          }
        },
      );
    }
  });

  let dedupedArr = [];
  flattenedArr.forEach((shipment, index) => {
    if (index === 0) {
      dedupedArr.push(shipment);
    } else if (dedupedArr.slice(-1)[0].locationId === shipment.locationId) {
      let lastStop = dedupedArr.slice(-1)[0];
      let updates = [...lastStop.updates, ...shipment.updates];
      // H1-825 Sort updates by ascending time
      lastStop.updates = updates.sort((a, b) => (a.time > b.time ? 1 : -1));
    } else {
      dedupedArr.push(shipment);
    }
  });

  return dedupedArr.map((shipment) => ({
    ...shipment,
    arrived_at: shipment.arrived,
    departed_at: shipment.departed,
  }));
};

export const getLads = (list) => {
  const codes = {};
  list.forEach((lad) => {
    codes[lad.code] = {
      lad_name: lad.lad_name,
      default_name: lad.default_name,
      code: lad.code,
      description: lad.description,
      id: lad.id,
    };
  });
  return codes;
};
