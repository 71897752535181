/** @jsxImportSource @emotion/react */

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Loader from "react-loader";

import { Modal } from "components/molecules/Modal.molecule";
import { ReadOnlyInput } from "components-old/modal-elems";
import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

import { parseDateTime } from "utils/date-time";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";

const DetailsUserModal = ({
  show,
  hide,
  user,
  activeOrganization,
  fetchUserDetails,
  userDetailsRequestData,
}) => {
  const { t } = useTranslation("users");

  const userEmail = user?.email ?? null;
  const orgName = activeOrganization?.org_name ?? null;

  const userDetails = userDetailsRequestData?.data;
  const isLoading = userDetailsRequestData?.isLoading;
  const userLoginCount = userDetails?.logins_count ?? null;
  const userLastLogin = parseDateTime(userDetails?.last_login, true);

  let displayedLoginCount = `${userLoginCount}` ?? t("users:N/A");
  let displayedLastLogin = userLastLogin.isValid
    ? `${userLastLogin.date} ${userLastLogin.time} ${userLastLogin.timezone}`
    : t("users:N/A");

  if (isLoading) {
    displayedLoginCount = t("users:Loading...");
    displayedLastLogin = t("users:Loading...");
  }

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
      onEntered={() => {
        fetchUserDetails(activeOrganization?.organization_id, user?.user_id);
      }}
    >
      <Modal.Header title={t("users:Additional Details")} />
      <Modal.Body>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={orgName}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={userEmail}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        <Loader loaded={!isLoading} />
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Number of Logins")}
            value={displayedLoginCount}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Last Login")}
            value={displayedLastLogin}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
      </Modal.Body>
    </Modal>
  );
};

DetailsUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  activeOrganization: PropTypes.object.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  userDetailsRequestData: PropTypes.shape({
    data: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    userDetailsRequestData:
      UsersState.selectors.getUserDetailsRequestData(state),
  };
}

const mapDispatchToProps = {
  fetchUserDetails: UsersState.actionCreators.fetchUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsUserModal);
