import { connect } from "react-redux";
import { VinEtaValidatorPage } from "./VinEtaValidator.page";
import VinEtaValidatorSearchBarState from "../redux/VinEtaValidatorSearchBarState";
import { getSelectedEntity } from "../redux/VinEtaValidatorSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const {
  getHasSearchCriteriaChanged,
  getAreAllPrerequisiteFiltersSelected,
  getTotalEntities,
  getSearchResults,
  getIsLoading,
  getIsExporting,
  getExportIdentifier,
  getExportName,
  getExportFailed,
} = VinEtaValidatorSearchBarState.selectors;

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    areAllPrerequisiteFiltersSelected:
      getAreAllPrerequisiteFiltersSelected(state),
    totalCount: getTotalEntities(state),
    selectedVin: getSelectedEntity(state),
    // Table props
    data: getSearchResults(state),
    isLoading: getIsLoading(state),
    isExporting: getIsExporting(state),
    exportIdentifier: getExportIdentifier(state),
    exportName: getExportName(state),
    exportFailed: getExportFailed(state),
  };
}

const { searchEntities, resetSearchAndFilters, exportEntities, resetExport } =
  VinEtaValidatorSearchBarState.actionCreators;

const actionCreators = {
  searchEntities,
  resetSearchAndFilters,
  exportEntities,
  resetExport,
};

export default connect(mapStateToProps, actionCreators)(VinEtaValidatorPage);
