import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import DamageViewSubmissionsByStatusState from "../../redux/DamageViewSubmissionsByStatusState";
import { SubmissionsByStatusFilters } from "../../components/search/DamageView.searchOptions";

const mapStateToProps = (state) => ({
  searchFilters:
    DamageViewSubmissionsByStatusState.selectors.getSearchFilters(state),
});

const {
  setSearchFilter: setSubmissionsByStatusSearchFilter,
  clearSearchFilter: clearSubmissionsByStatusSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = DamageViewSubmissionsByStatusState.actionCreators;

const actionCreators = {
  setSearchFilter: (key, value) => {
    return (dispatch) => {
      dispatch(setSubmissionsByStatusSearchFilter(key, value));
      dispatch(searchEntities());
    };
  },
  clearSearchFilter: (key) => {
    return (dispatch) => {
      dispatch(clearSubmissionsByStatusSearchFilter(key));
      dispatch(searchEntities());
    };
  },
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: SubmissionsByStatusFilters,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
