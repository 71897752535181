/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Alert } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

const csvTemplate = {
  fileName: `template-partview-dealer-onboarding-${Date.now()}.csv`,
  headers: [
    "Dealer Name",
    "Dealer or Location Code",
    "Location Name",
    "Address",
    "City",
    "State",
    "Country",
    "ZIP Code",
    "BAC (Optional)",
    "Action",
  ],
  data: [
    [
      "Dealer Name 1",
      "AADL",
      "Ann Arbor Dealership",
      "2095 W. Stadium Blvd.",
      "Ann Arbor",
      "MI",
      "US",
      "48107",
      "BAC 1",
      "Add",
    ],
    [
      "Dealer Name 2",
      "E14469",
      "THE ROCKMONT MOTOR CO",
      "15301 FREDERICK RD",
      "ROCKVILLE",
      "MD",
      "US",
      "20848",
      "",
      "Add",
    ],
  ],
};

export const PartViewDealerOnboardingTool = ({
  onCsvUpload,
  csvUploadResponseData,
}) => {
  const { t } = useTranslation("internal-tools");
  useSetTitleOnMount(t("internal-tools:Partview Dealer Onboarding Tool"));

  const [importFile, setImportFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const csvUploadHandler = () => {
    const reader = new FileReader();
    reader.readAsText(importFile);
    reader.onload = (file) => {
      const csvData = file.target.result.trim();
      onCsvUpload({
        fileName: importFile.name,
        data: csvData,
      });
      setShowAlert(true);
    };
  };

  const csvTooltip = (
    <Fragment>
      <Text>{t("internal-tools:The file must contain")}:</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "nowrap",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:Dealer Name")}</li>
        <li>{t("internal-tools:Dealer or Location Code")}</li>
        <li>{t("internal-tools:Location Name")}</li>
        <li>{t("internal-tools:Address")}</li>
        <li>{t("internal-tools:City")}</li>
        <li>{t("internal-tools:State")}</li>
        <li>{t("internal-tools:Country")}</li>
        <li>{t("internal-tools:ZIP Code")}</li>
        <li>{t("internal-tools:BAC (Optional)")}</li>
        <li>{t("internal-tools:Action")}</li>
      </ul>
    </Fragment>
  );

  const errors =
    csvUploadResponseData?.loadingError?.response?.data?.error?.message ?? null;

  return (
    <div css={{ padding: "1em" }}>
      <div css={{ display: "flex", flexDirection: "column", padding: "1em" }}>
        <Text size={FontSize.size18} bold>
          {t("internal-tools:Dealer Creation")}
        </Text>
        <Text block={true} css={{ marginBottom: "1em" }}>
          {t(
            "internal-tools:Upload new Dealers to the application using a .csv file",
          )}
          {":"}
        </Text>
        <div css={{ display: "flex", marginBottom: "1em" }}>
          <div style={{ marginRight: "1em" }}>
            <Text>{t("internal-tools:Select a .csv file to import")}.</Text>{" "}
            <Tooltip placement="right" tooltipChildren={csvTooltip}>
              <Icon src={faQuestionCircle} />
            </Tooltip>
          </div>

          <DownloadCsvLink
            data={csvTemplate.data}
            headers={csvTemplate.headers}
            label={t("internal-tools:Download Template (.csv)")}
            filename={csvTemplate.fileName}
          />
        </div>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <InputGroup>
            <label
              className="input-group-btn"
              css={{ paddingRight: "0.375rem" }}
            >
              <Text className="btn btn-light">
                {t("internal-tools:Browse")}&hellip;{" "}
                <input
                  id="files"
                  type="file"
                  accept=".csv"
                  title={null}
                  //empty the value so that onchange will be invoked when uploading the same file
                  //https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-612959113
                  onClick={(e) => {
                    e.target.value = null;
                    setShowAlert(false);
                  }}
                  onChange={(e) => setImportFile(e?.target?.files[0])}
                  hidden
                />
              </Text>
            </label>
            <FormControl
              type="type"
              value={importFile?.name ?? ""}
              placeholder={t("internal-tools:Select file")}
              htmlFor="files"
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              readOnly
            />
          </InputGroup>
        </FormGroup>

        {showAlert &&
        !csvUploadResponseData?.isLoading &&
        csvUploadResponseData?.status === 200 ? (
          <Alert show={true} variant="success">
            <Text>
              {t(
                "internal-tools:File has been successfully uploaded! Notification will be sent once file is processed.",
              )}
            </Text>
          </Alert>
        ) : null}

        {showAlert &&
        !csvUploadResponseData?.isLoading &&
        csvUploadResponseData?.isLoadingError ? (
          <Alert
            show={true}
            variant="danger"
            css={{ maxHeight: "12.5rem", overflowY: "auto" }}
          >
            <div css={{ paddingBottom: "0.25rem" }}>
              {t(
                "internal-tools:File upload failed. Please check your entries and try again.",
              )}
            </div>
            {errors ? (
              <div>
                <Text as="div">
                  {t("internal-tools:Error(s)")}
                  {":"}
                </Text>
                <ul css={{ marginBottom: 0 }}>
                  {errors?.map((row, index) => (
                    <li key={row + index}>{row}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Alert>
        ) : null}

        <Button
          variant="primary"
          disabled={!importFile?.name || csvUploadResponseData?.isLoading}
          onClick={csvUploadHandler}
          css={{ width: "fit-content" }}
        >
          {t("internal-tools:Upload")}
        </Button>
      </div>
    </div>
  );
};

PartViewDealerOnboardingTool.propTypes = {
  onCsvUpload: PropTypes.func,
  csvUploadResponseData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
};
