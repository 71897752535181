import { connect } from "react-redux";
import { ShipmentEtaValidatorPage } from "./ShipmentEtaValidator.page";
import ShipmentEtaValidatorSearchBarState from "../redux/ShipmentEtaValidatorSearchBarState";
import { getSelectedEntity } from "../redux/ShipmentEtaValidatorSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const {
  getHasSearchCriteriaChanged,
  getAreAllPrerequisiteFiltersSelected,
  getTotalEntities,
  getSearchResults,
  getIsLoading,
  getIsExporting,
  getExportIdentifier,
  getExportName,
  getExportFailed,
} = ShipmentEtaValidatorSearchBarState.selectors;

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    areAllPrerequisiteFiltersSelected:
      getAreAllPrerequisiteFiltersSelected(state),
    totalCount: getTotalEntities(state),
    selectedVin: getSelectedEntity(state),
    // Table props
    data: getSearchResults(state),
    isLoading: getIsLoading(state),
    isExporting: getIsExporting(state),
    exportIdentifier: getExportIdentifier(state),
    exportName: getExportName(state),
    exportFailed: getExportFailed(state),
  };
}

const { searchEntities, resetSearchAndFilters, exportEntities, resetExport } =
  ShipmentEtaValidatorSearchBarState.actionCreators;

const actionCreators = {
  searchEntities,
  resetSearchAndFilters,
  exportEntities,
  resetExport,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(ShipmentEtaValidatorPage);
