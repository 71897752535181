const mapFilterSet = (report) => {
  return {
    filterSetId: report.filter_set.filter_set_id,
    name: report.filter_set.name,
    description: report.filter_set.description
      ? report.filter_set.description
      : "",
    createdBy: report.filter_set.email,
    lastUpdated: report.filter_set.last_updated_at,
    createdOn: report.filter_set.created_at,
    private: report.filter_set.private,
    filters: report.filter_set.filters,
    isNew: report.filter_set.new,
  };
};

const mapDirectoryReport = (report) => {
  return {
    directoryId: report.directory.id,
    name: report.directory.name,
    description: report.directory.description
      ? report.directory.description
      : "",
    type: report.directory.type,
    level: report.directory.level,
    isReport: report.isReport,
    parentId: report.directory.parent_id,
    createdAt: report.directory.created_at,
    creatorActorId: report.directory.creator_actor_id,
    creatorOrgId: report.directory.creator_org_id,
    updatedAt: report.directory.updated_at,
  };
};

export const mapReport = (report, index) => {
  return {
    name: report.name,
    index: index,
    accessToken: report.token,
    embedUrl: report.embedUrl,
    reportId: report.report_id,
    description: report.description ? report.description : "",
    groupName: report.group_name,
    workspaceId: report.workspace_id,
    lastUpdated: report.last_updated_at,
    private: report.private,
    isNew: report.new,
    createdBy: report.email,
    source: report.source,
    originalReportName: report.original_report_name,
    filterSet: report.filter_set ? mapFilterSet(report) : null,
    directory: report.directory ? mapDirectoryReport(report) : null,
    filtersDetail: report.filters_detail,
    createFilterSet: report.create_filter_set,
    emailScheduleCount: report.email_schedule_count,
  };
};

export const expandedReport = (filters, reportsResults) => {
  if (filters.current && filters.current.length > 0) {
    let res = {};
    reportsResults.forEach((ele, idx) => {
      if (ele.directory) {
        res[`${idx}`] = true;
      }
    });
    return res;
  }
  return null;
};
