//External hooks or supporting code from React
import { connect } from "react-redux";

//Components
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import ShipmentsSavedSearchState from "pages/shipments/redux/ShipmentsSavedSearchState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentSavedSearchCardDataState from "pages/shipments/redux/ShipmentSavedSearchCardDataState";
import { ShipmentsSavedSearchSubscriptionState } from "pages/shipments/redux/ShipmentsSavedSearchSubscriptionState";
import SearchBarContainer from "./Shipments.SearchBar.container";
import FilterSectionContainer from "./Shipments.SearchFilters.container";

const mapStateToProps = (state) => {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    ShipmentsSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,

    // Alert Me
    isSavedSearchSubscriptionLoading:
      ShipmentsSavedSearchSubscriptionState.selectors.getIsSubscriptionLoading(
        state,
      ),
    savedSearchSubscription:
      ShipmentsSavedSearchSubscriptionState.selectors.getSubscription(state),
    subscriptionRequestError:
      ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionRequestError(
        state,
      ),
    isSubscriptionUpdating:
      ShipmentsSavedSearchSubscriptionState.selectors.getIsSubscriptionUpdating(
        state,
      ),
    subscriptionUpdateSuccess:
      ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionUpdateSuccess(
        state,
      ),
    subscriptionUpdateInProgress:
      ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionUpdateInProgress(
        state,
      ),
    subscriptionUpdateError:
      ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionUpdateError(
        state,
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { saveSearch, editSearch } = ShipmentsSavedSearchState.actionCreators;

  return {
    saveSearch: (name, search) => dispatch(saveSearch(name, search)),
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),

    // fetch the latest count as per filters on Save - Saved Search Update Modal
    fetchSavedSearchCardData: (...params) =>
      dispatch(
        ShipmentSavedSearchCardDataState.actionCreators.fetchSavedSearchCardData(
          ...params,
        ),
      ),

    // Alert Me
    fetchSavedSearchSubscription: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.fetchSubscription(
          ...params,
        ),
      ),
    subscribe: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.subscribe(
          ...params,
        ),
      ),
    updateSubscription: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.updateSubscription(
          ...params,
        ),
      ),
    refreshSubscription: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.refreshSubscription(
          ...params,
        ),
      ),
    unsubscribe: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.unsubscribe(
          ...params,
        ),
      ),
    deleteSubscription: (...params) =>
      dispatch(
        ShipmentsSavedSearchSubscriptionState.actionCreators.deleteSubscription(
          ...params,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSavedSearchModal);
