/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
import Colors, { shadeColor } from "styles/colors";

export const DealerOrgFilterButton = (props) => {
  const { t } = useTranslation("components");

  return (
    <AsyncSelectFilterButton
      {...props}
      css={{
        backgroundColor: Colors.background.DARK_TEAL,
        color: Colors.text.WHITE,
        borderColor: "#246780",
        ":hover": {
          backgroundColor: shadeColor(Colors.background.DARK_TEAL, -0.15),
        },
      }}
      isMulti={false}
      hideSelectEmpty
      helpText={
        <Text>{t("components:Search by name, location code, or BAC.")}</Text>
      }
    />
  );
};

DealerOrgFilterButton.propTypes = {
  values: PropTypes.array,
};
