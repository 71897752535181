/** @jsxImportSource @emotion/react */
/**
 * Standardized Results Table List to be used for MapSearchResults.
 */
import PropTypes from "prop-types";
import _ from "lodash";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import Colors from "../../styles/colors";
import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

const DEFAULT_PAGE_SIZE = 20;

const Results = ({
  entities,
  columns,
  total = 0,
  totalText = "",
  visible = 0,
  visibleText = "",
  isLoading = false,
  onRowClick = _.noop,
  selectedEntitiesIds = [],
  showPagination = true,
  fixPaginationToBottom = false,
  pageSize = DEFAULT_PAGE_SIZE,
  showWarning = false,
  warningMessage = "",
  recenterTooltipText = null,
  setMapViewCentered = null,
  defaultSortColumn,
  defaultReverseSort,
}) => {
  return (
    <FlexColDiv
      className="results-list"
      css={{
        alignItem: "center",
        display: "inline-flex",
        fontSize: "small",
        minHeight: "6.75em",
        width: "100%",
      }}
    >
      <FlexDiv
        data-qa="total-vins"
        css={{
          alignItems: "center",
          justifyContent: "center",
          padding: "1em",
          paddingBottom: "0.25em",
          fontSize: 18,
          fontWeight: 600,
          color: Colors.tableHeadingText,
        }}
      >
        {total.toLocaleString()} {totalText}
      </FlexDiv>
      {visible !== null ? (
        <FlexDiv
          data-qa="visible-vins"
          css={{
            alignItems: "center",
            justifyContent: "center",
            padding: "1em",
            paddingTop: 0,
            paddingBottom: "0.5em",
            fontSize: 14,
            fontWeight: 600,
            color: Colors.tableHeadingText,
          }}
        >
          <Text>
            {visible.toLocaleString()} {visibleText}
          </Text>
          {setMapViewCentered && total !== visible ? (
            <Tooltip
              placement="top"
              tooltipChildren={
                recenterTooltipText ? <Text>{recenterTooltipText}</Text> : null
              }
            >
              <Icon
                src={faTimesCircle}
                onClick={() => setMapViewCentered(true)}
                css={{ cursor: "pointer", marginLeft: 5 }}
              />
            </Tooltip>
          ) : null}
        </FlexDiv>
      ) : null}
      {showWarning && warningMessage && (
        <FlexDiv
          data-qa="total-vins"
          css={{
            alignItems: "center",
            justifyContent: "center",
            padding: "1em",
            paddingTop: 0,
            fontSize: "1.2em",
            color: Colors.tableHeadingText,
          }}
        >
          {warningMessage}
        </FlexDiv>
      )}
      <FlexDiv
        css={{
          flex: "1 0 0",
          marginBottom: ".25em",
          marginTop: "0.5em",
          fontSize: 13,
          overflow: "auto",
        }}
      >
        <BaseTable
          theme={Themes.DARK}
          isLoading={isLoading}
          data={entities}
          columns={columns}
          showPagination={showPagination}
          fixPaginationToBottom={fixPaginationToBottom}
          pageSize={pageSize}
          rowClickHandler={onRowClick}
          rowHighlightHandler={(row) => {
            return selectedEntitiesIds.includes(row.original.id);
          }}
          defaultSortColumn={defaultSortColumn}
          defaultReverseSort={defaultReverseSort}
        />
      </FlexDiv>
    </FlexColDiv>
  );
};

Results.propTypes = {
  entities: PropTypes.array,
  columns: PropTypes.array,
  selectedEntitiesIds: PropTypes.array,
  total: PropTypes.number,
  totalText: PropTypes.string,
  visible: PropTypes.number,
  visibleText: PropTypes.string,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  fixPaginationToBottom: PropTypes.bool,
  pageSize: PropTypes.number,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  recenterTooltipText: PropTypes.string,
  setMapViewCentered: PropTypes.func,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
};

export default Results;
