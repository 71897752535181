/** @jsxImportSource @emotion/react */
import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { DownloadCsvLink } from "components/atoms/DownloadCsvLink.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { FontSize } from "components/atoms/enums";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { getLinesFromCsv } from "shared/utils/csv.utils";
import { ActionStatus } from "../const";

const csvTemplate = {
  fileName: `template-manual_eta_range-${Date.now()}.csv`,
  headers: ["Location Code", "Manual ETA Range Start", "Manual ETA Range End"],
  data: [
    ["code1", "01/20/2024", "11/22/2024"],
    ["code2", "01/20/24", "11/22/24"],
    ["code3", "1/20/24", "11/2/24"],
    ["code4", "01/20/24", "03/31/2025"],
  ],
};

export const useNotAllSuccessfulColumns = () => {
  const { t } = useTranslation("internal-tools");
  return [
    { Header: t("internal-tools:Location Code"), accessor: "locationCode" },
    {
      Header: t("internal-tools:Reason"),
      accessor: "reason",
    },
  ];
};

const initialState = {
  importFileCsvData: "",
  showResultModal: false,
};

export const SetManualETARangeTool = ({
  actionStatus,
  clearActionStatus,
  requestResults,
  submitBulkAction,
  solutionId,
}) => {
  const [importFile, setImportFile] = useState(null);
  const [importFileCsvData, setImportFileCsvData] = useState(
    initialState.importFileCsvData,
  );

  const [showResultModal, setshowResultModal] = useState(
    initialState.showResultModal,
  );

  const columns = useNotAllSuccessfulColumns();

  const { t } = useTranslation("internal-tools");
  useSetTitleOnMount(t("internal-tools:Set Manual ETA Range Tool"));

  const onFileLoad = (file) => {
    const csvData = file.target.result.trim();
    setImportFileCsvData(csvData);
  };

  const onBulkSubmitHandler = () => {
    setshowResultModal(true);
    submitCsvFile(solutionId, importFile);
  };

  async function submitCsvFile(solutionId, importFile) {
    let parsedLines = null;
    try {
      parsedLines = await getLinesFromCsv(importFile);
    } catch (e) {
      console.error(e);
    }
    submitBulkAction(solutionId, parsedLines);
  }

  const bulkActionUploadBtnDisabled = importFileCsvData?.length === 0;

  const csvTooltip = (
    <Fragment>
      <Text>{t("internal-tools:The file must contain")}:</Text>
      <ul
        css={{
          padding: 0,
          margin: 0,
          whiteSpace: "normal",
          textAlign: "left",
          paddingLeft: "1.25em",
        }}
      >
        <li>{t("internal-tools:Location Code")}</li>
        <li>
          {t(
            "internal-tools:Manual ETA Range Start (Date Format 'MM/DD/YYYY' or 'MM/DD/YY')",
          )}
        </li>
        <li>
          {t(
            "internal-tools:Manual ETA Range End (Date Format 'MM/DD/YYYY' or 'MM/DD/YY')",
          )}
        </li>
      </ul>
    </Fragment>
  );

  return (
    <Fragment>
      <div css={{ padding: "1em", flexDirection: "column" }}>
        <FlexColDiv
          css={{
            padding: "1em",
          }}
        >
          <Text size={FontSize.size18} bold>
            {t("internal-tools:Bulk Action")}
          </Text>
          <Text block={true} style={{ marginBottom: "1em" }}>
            {t(
              "internal-tools:Set Manual ETA Start and End Ranges using a csv file",
            )}
            {":"}
          </Text>
          <FlexRowDiv css={{ marginBottom: "1em" }}>
            <div style={{ marginRight: "1em" }}>
              <Text>{t("internal-tools:Select a CSV file to import")}.</Text>{" "}
              <Tooltip placement="right" tooltipChildren={csvTooltip}>
                <Icon src={faQuestionCircle} />
              </Tooltip>
            </div>

            <DownloadCsvLink
              data={csvTemplate.data}
              headers={csvTemplate.headers}
              label={t("internal-tools:Download template (.csv)")}
              filename={csvTemplate.fileName}
            />
          </FlexRowDiv>
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <label
                className="input-group-btn"
                css={{ paddingRight: "0.375rem" }}
              >
                <Text className="btn btn-light">
                  {t("internal-tools:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={(e) => {
                      const reader = new FileReader();
                      reader.readAsText(e?.target?.files[0]);
                      reader.onload = onFileLoad;
                      setImportFile(e?.target?.files[0]);
                    }}
                    hidden
                  />
                </Text>
              </label>
              <FormControl
                value={importFile?.name ?? ""}
                placeholder={t("internal-tools:Select file")}
                htmlFor="files"
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>
          <Button
            variant="primary"
            disabled={bulkActionUploadBtnDisabled}
            onClick={onBulkSubmitHandler}
            style={{ width: "fit-content" }}
          >
            {t("internal-tools:Upload")}
          </Button>
        </FlexColDiv>
      </div>
      <DialogModal
        style={{ width: "50%" }}
        show={showResultModal}
        onHide={() => {
          setshowResultModal(false);
          clearActionStatus();
        }}
        isLoading={actionStatus === ActionStatus.IN_PROGRESS}
        loadStatus={"success"}
        title={t("internal-tools:Bulk Action")}
        okButtonText={t("internal-tools:Close")}
        submitButtonVariant="primary"
        size="md"
        submitButtonDisabled={true}
        showCloseButton={false}
      >
        {actionStatus === ActionStatus.IN_PROGRESS ? (
          <UploadInProgressModalBody />
        ) : null}
        {actionStatus === ActionStatus.SUCCESS ? (
          <UploadAllSuccessModalBody />
        ) : null}
        {actionStatus === ActionStatus.PARTIAL_SUCCESS ? (
          <UploadNotAllSuccessModalBody
            columns={columns}
            requestResults={requestResults}
          />
        ) : null}
        {actionStatus === ActionStatus.FAILED ? <UploadErrorModalBody /> : null}
      </DialogModal>
    </Fragment>
  );
};

SetManualETARangeTool.propTypes = {
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  requestResults: PropTypes.string,
  submitBulkAction: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
};

const UploadAllSuccessModalBody = () => {
  const { t } = useTranslation("internal-tools");

  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faCheckCircle} color="green" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t(
              "internal-tools:Manual ETA ranges have been updated successfully.",
            )}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadErrorModalBody = () => {
  const { t } = useTranslation("internal-tools");
  return (
    <div css={{ margin: "3em 0" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text size={FontSize.size16}>
            {t("internal-tools:There was an error. Please try again later.")}
          </Text>
        </div>
      </Fragment>
    </div>
  );
};

const UploadNotAllSuccessModalBody = ({ columns, requestResults }) => {
  const { t } = useTranslation("internal-tools");
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faTimesCircle} color="red" size={3} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1em",
        }}
      >
        <Text size={FontSize.size16}>
          {t(
            "internal-tools:The Action could not be successfully completed for all manual ETA ranges.",
          )}
        </Text>
        <Text css={{ marginTop: "1em" }}>
          {t(
            "internal-tools:Below is a list of manual ETA ranges that were unsuccessful, along with the reasons for the failures",
          )}
          {":"}
        </Text>
        <BaseTable
          theme={Themes.LIGHT}
          columns={columns}
          data={requestResults}
        />
      </div>
    </Fragment>
  );
};

UploadNotAllSuccessModalBody.propTypes = {
  columns: PropTypes.array,
  requestResults: PropTypes.array,
};

const UploadInProgressModalBody = () => {
  const { t } = useTranslation("internal-tools");
  return (
    <div css={{ margin: "2.5em 0" }}>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Icon src={faSpinner} spin size={3} />
      </div>
      <Text
        css={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
        size={FontSize.size16}
      >
        {t("internal-tools:Processing request...")}
      </Text>
    </div>
  );
};

UploadInProgressModalBody.propTypes = {
  t: PropTypes.func,
};

export default SetManualETARangeTool;
