import React from "react";
import { useTranslation } from "react-i18next";
import { Text, FontSize } from "components/atoms/Text.atom";

export const useOrderDetailsTable = () => {
  const { t } = useTranslation("components");
  let columns = [];
  columns.push({
    Header: t("components:Name"),
    accessor: "qualifier",
    Cell: (cell) => {
      return (
        <Text block size={FontSize.size12}>
          {cell.row.original.qualifier}
        </Text>
      );
    },
  });

  columns.push({
    Header: t("components:Value"),
    accessor: "value",
    Cell: (cell) => {
      return (
        <Text block size={FontSize.size12}>
          {cell.row.original.value}
        </Text>
      );
    },
  });

  return columns;
};
