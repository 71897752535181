/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Modal } from "components/molecules/Modal.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { Button } from "components/atoms/Button.atom";
import Colors from "styles/colors";
import { useExpandedDetailColumns } from "./ShipmentEtaValidator.ExpandedDetails.columns";
import { convertEtaTriggerToTableRows } from "./ShipmentEtaTrigger.utils";

const ShipmentEtaTriggerExpandedDetailsModal = ({
  etaTrigger,
  show = false,
  onHide = _.noop,
}) => {
  const { t } = useTranslation("shipment-eta-validator");

  const columns = useExpandedDetailColumns();
  const data = convertEtaTriggerToTableRows(etaTrigger, t);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("shipment-eta-validator:Expanded Details")}
      />

      <Modal.Body>
        <BaseTable theme={Themes.LIGHT} columns={columns} data={data} />
      </Modal.Body>
    </Modal>
  );
};

ShipmentEtaTriggerExpandedDetailsModal.propTypes = {
  etaTrigger: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export const ViewExpandedDetailsButton = ({ etaTrigger }) => {
  const { t } = useTranslation("shipment-eta-validator");

  const [show, setShow] = useState(false);

  return (
    <Fragment>
      <Button variant="link" onClick={() => setShow(!show)}>
        {t("shipment-eta-validator:View")}
      </Button>
      <ShipmentEtaTriggerExpandedDetailsModal
        etaTrigger={etaTrigger}
        show={show}
        onHide={() => setShow(false)}
      />
    </Fragment>
  );
};

ViewExpandedDetailsButton.propTypes = {
  etaTrigger: PropTypes.object,
};
