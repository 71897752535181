/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FormGroup, InputGroup } from "react-bootstrap";

import Colors from "styles/colors";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const FormCell = (props) => {
  const { label } = props;

  return (
    <div
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "column",
        paddingRight: "0.5em",
        paddingLeft: "0.5em",
        flex: 1,
        ...props.style,
      }}
    >
      <span style={{ color: Colors.background.DARK_BLUE, marginBottom: "5px" }}>
        {label}
      </span>
      <FormGroup css={{ marginBottom: "1rem" }}>
        <InputGroup style={{ width: "100%" }}>{props.children}</InputGroup>
      </FormGroup>
    </div>
  );
};

FormCell.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string,
};

export default FormCell;
