/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  faUsersCrown,
  faTimes,
  faSearch,
  faAngleDown,
} from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { LadChicletCSS as Chiclet } from "components/chiclets";
import { FlexDiv, FlexRowDiv } from "styles/container-elements";
import { useLadsTranslation } from "modules/lads/utils/lads.utils";
import { useGetShipperNameById } from "pages/administration/utils/location-utils";
import SimpleAddressCell from "components/organisms/base-table/Cell/SimpleAddressCell";
import ParentFilter from "modules/location-list/components/ParentFilter";
import GeofenceFilter from "modules/location-list/components/GeofenceFilter";
import ShipperFilter from "modules/location-list/components/ShipperFilter";
import { getDefaultUnclassifiedLad } from "modules/lads/LadsState";
import LocationTypeSelectFilterModal from "modules/location-list/components/LocationTypeSelectFilterModal";
import { sortByAddress } from "components/organisms/base-table/Sort/CommonSorts";
import GeofenceType, { getType } from "modules/geofence-edit/geofence-types";

// TODO: Move these
/**
 *
 * @param {*} props
 * @returns {*}
 * @constructor
 */
const NameCellRenderer = ({ value }) => {
  return <Text>{value}</Text>;
};

NameCellRenderer.propTypes = {
  value: PropTypes.string,
};

const GeofenceCellRenderer = ({ value }) => {
  let fenceType = getType(value);
  return (
    <Tooltip
      placement="top"
      tooltipChildren={
        fenceType === GeofenceType.MULTIPOLYGON
          ? i18n.t("locations:Contains [[[count]]] geofences", {
              count: value?.geometry?.length,
            })
          : null
      }
    >
      <Text>
        {fenceType.displayName}{" "}
        {fenceType === GeofenceType.MULTIPOLYGON
          ? " - " + value?.geometry?.length
          : null}
      </Text>
    </Tooltip>
  );
};

GeofenceCellRenderer.propTypes = {
  value: PropTypes.object,
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const SourceCellRenderer = ({ value }) => {
  if (value.includes("Trip Plan")) {
    value = i18n.t("locations:Trip Plan");
  }
  return <div>{value}</div>;
};

SourceCellRenderer.propTypes = {
  value: PropTypes.string,
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ShipperCellRenderer = (props) => {
  const shipperName = useGetShipperNameById(props.row.original.customer_id);
  return (
    <Text size={FontSize.size18} data-qa="location-shipper">
      {shipperName}
    </Text>
  );
};

ShipperCellRenderer.propTypes = {
  row: PropTypes.object,
};

const LocationTypeCellRenderer = (props) => {
  const { getTranslatedLadLobLabel } = useLadsTranslation();
  const undefinedLad = getDefaultUnclassifiedLad();
  const lad = props.value ? props.value : undefinedLad;
  let label = getTranslatedLadLobLabel(lad);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div css={{ marginLeft: "1em" }}>
        <Chiclet height={25} width={25} lad={lad} />
      </div>
      <div css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}>
        {label}
      </div>
    </div>
  );
};

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.object,
};

/**
 *
 * @param filter
 * @return {null|*}
 * @constructor
 */
const LadFilters = ({ filters }) => {
  if (!filters || filters.length === 0) {
    return null;
  }

  let label = "";

  if (filters[0].includes("_")) {
    label = filters[0].split("_")[1];
  } else {
    label = filters[0];
  }

  label = label.replace(/\w+/g, _.capitalize);

  let allLabels = label;
  if (filters.length > 1) {
    allLabels = _.map(filters, (filter) => {
      let thisFilter = filter;
      if (filter.includes("_")) {
        thisFilter = filter.split("_")[1];
      }

      return thisFilter.replace(/\w+/g, _.capitalize);
    }).join(", ");
  }

  const plusCount = filters.length - 1 === 0 ? "" : `+${filters.length - 1}`;
  return (
    <FlexRowDiv
      style={{
        flex: 1,
        justifyContent: "flex-start",
        marginLeft: ".5em",
        marginRight: "15px",
        whiteSpace: "nowrap",
        overflowX: "hidden",
      }}
    >
      <Tooltip placement="top" tooltipChildren={allLabels}>
        <div
          style={{ color: Colors.highlight.LIGHT_BLUE }}
        >{`${label} ${plusCount}`}</div>
      </Tooltip>
    </FlexRowDiv>
  );
};

LadFilters.propTypes = {
  filters: PropTypes.array,
};

/**
 *
 * @param filterValue
 * @param onChange
 * @param isCarrierOrg
 * @return {*}
 * @constructor
 */
const LadFilterWidget = ({ filterValue, onChange, isCarrierOrg }) => {
  const [showWidget, setShowWidget] = useState(false);

  return (
    <div className="search-title-header">
      <FlexDiv
        style={{
          ":hover": {
            backgroundColor: "#e8e8e8",
          },
          alignItems: "center",
          cursor: "pointer",
          height: "2em",
          padding: "0 .35em",
          position: "relative",
        }}
        onClick={() => setShowWidget(true)}
        data-qa="button-toggle-location-type-filter"
      >
        <div className="per-col-search-widget" css={{ whiteSpace: "nowrap" }}>
          <Icon src={faSearch} />
          <Icon src={faAngleDown} css={{ marginLeft: 5 }} />
        </div>
        <LadFilters filters={filterValue} />
        {filterValue && filterValue.length > 0 ? (
          <div style={{ position: "absolute", zIndex: 10, right: 2 }}>
            <Icon
              src={faTimes}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange([]);
              }}
              data-qa="button-clear-location-type-selection"
            />
          </div>
        ) : null}
      </FlexDiv>

      <LocationTypeSelectFilterModal
        show={showWidget}
        hide={() => {
          setShowWidget(false);
        }}
        value={filterValue}
        changeHandler={(val) => onChange(val)}
        isCarrierOrg={isCarrierOrg}
      />
    </div>
  );
};

LadFilterWidget.propTypes = {
  filterValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isCarrierOrg: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * @param {number} [value=0] - The count of children locations
 *  value > 0: show icon,
 *  value <= 0: hide icon
 */
const ParentIconCell = ({ value = 0 }) => {
  const { t } = useTranslation("locations");

  // Don't display an icon if there aren't any child locations
  if (value <= 0) {
    return null;
  }

  const label = t("[[[count]]] child location(s)", { count: value });

  return (
    <Tooltip
      placement="top"
      tooltipChildren={label}
      css={{ width: "100%", textAlign: "center" }}
    >
      <Icon size={FontSize.size18} src={faUsersCrown} />
    </Tooltip>
  );
};

ParentIconCell.propTypes = {
  value: PropTypes.number,
};

/**
 *
 */
export const LocationParentColumn = {
  Header: i18n.t("locations:Parent"),
  id: "child_count",
  accessor: "child_count",
  Cell: (props) => <ParentIconCell {...props} />,
  width: 90,
  Filter: (props) => {
    const { column } = props;
    const { setFilter } = column;
    return <ParentFilter onChange={setFilter} />;
  },
};

export const LocationGeofenceColumn = {
  Header: i18n.t("locations:Geofence Type"),
  id: "geofence_type",
  accessor: "geofence",
  Cell: (props) => <GeofenceCellRenderer {...props} />,
  Filter: ({ column: { setFilter } }) => {
    return <GeofenceFilter onChange={setFilter} />;
  },
};

GeofenceFilter.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * Location Name
 */
export const LocationNameColumn = {
  id: "name",
  Header: i18n.t("locations:Name"),
  accessor: "name",
  Cell: NameCellRenderer,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return row.values[id].toLowerCase().includes(filterValue.toLowerCase());
    });
  },
};

/**
 *
 */
export const LocationSourceColumn = {
  id: "source",
  Header: i18n.t("locations:Source"),
  accessor: "source",
  Cell: SourceCellRenderer,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return row.values[id].toLowerCase().includes(filterValue.toLowerCase());
    });
  },
};

/**
 *
 */
export const LocationShipperColumn = {
  Header: i18n.t("locations:Shipper"),
  accessor: "organization",
  Cell: ShipperCellRenderer,
  Filter: ({ column: { filterValue, setFilter } }) => {
    return <ShipperFilter value={filterValue} onChange={setFilter} />;
  },
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return row.values[id].toLowerCase().includes(filterValue.toLowerCase());
    });
  },
};

ShipperFilter.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 *
 */
export const LocationCodeColumn = {
  id: "code",
  Header: i18n.t("locations:Location Code"),
  accessor: "code",
  minWidth: 50,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return row.values[id].toLowerCase().includes(filterValue.toLowerCase());
    });
  },
};

/**
 *
 */
export const LocationAddressColumn = {
  Header: i18n.t("locations:Address"),
  id: "address",
  accessor: (row) => {
    return {
      address: row.address,
      address2: row.address2,
      city: row.city,
      state: row.state,
      zip: row.postal_code,
      country: row.country,
      validAddress: row?.valid_address,
    };
  },
  Cell: SimpleAddressCell,
  sortType: sortByAddress,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const addressMash = _.join(
        _.map(
          _.pick(row.values[id], ["address", "city", "state", "zip"]),
          _.lowerCase,
        ),
        " ",
      );
      return addressMash.includes(filterValue.toLowerCase());
    });
  },
};

/**
 *
 */
export const LocationCountColumn = {
  id: "count",
  Header: i18n.t("locations:Count"),
  accessor: "count",
  minWidth: 50,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return parseInt(row.values[id]) > parseInt(filterValue, 10);
    });
  },
};

/**
 * TODO: Refactor this so it's not a function.
 */
export const LocationTypeColumn = (isCarrierOrg) => {
  return {
    Header: i18n.t("locations:Location Type"),
    id: "type",
    disableSortBy: true,
    accessor: "lad",
    Cell: LocationTypeCellRenderer,
    Filter: ({ column: { filterValue, setFilter } }) => {
      return (
        <LadFilterWidget
          filterValue={filterValue}
          onChange={setFilter}
          isCarrierOrg={isCarrierOrg}
        />
      );
    },
  };
};

LadFilterWidget.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
    filterValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Adjust according to what filterValue actually is
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isCarrierOrg: PropTypes.bool.isRequired,
};

export const ParentLocationCodeColumn = {
  id: "parent_code",
  Header: i18n.t("locations:Parent Location Code"),
  accessor: "parent_location_code",
  minWidth: 50,
  filter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      return row.values[id].toLowerCase().includes(filterValue.toLowerCase());
    });
  },
};
