/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";

// UI Libraries
import { Text } from "components/atoms/Text.atom";

const LastMileStoneCell = (props) => {
  const { code, codeDescription } = props.value;
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text>{code}</Text>
      <Text>{codeDescription}</Text>
    </div>
  );
};

export { LastMileStoneCell };

LastMileStoneCell.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    codeDescription: PropTypes.string,
  }),
};
