/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  faArrowCircleDown,
  faChevronCircleRight,
  faTimesCircle,
  faCommentAlt,
  faFileAlt,
  faCloudDownload,
  faCloudUpload,
  faClock,
  faExclamationCircle,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import ExportsState, { ExportStatus } from "modules/exports/ExportsState";
import { Notification } from "modules/notifications/components/Notification.molecule";
import NotificationsState from "modules/notifications/NotificationsState";
import { useBatchUploadNotification } from "modules/notifications/hooks/useBatchUploadNotification";
import { OrganizationType } from "shared/constants/organization.const";
import Colors from "styles/colors";
import { SourceService } from "modules/notifications/utils/const";

const { fetchExportDownload, patchExportStatus } = ExportsState.actionCreators;
const { fetchFailedBatchCommentsOrUploads } = NotificationsState.actionCreators;

const { getExport } = ExportsState.selectors;

const { getNotification } = NotificationsState.selectors;

const getTranslatedSourceServiceName = (sourceService, t) => {
  const source = sourceService?.toUpperCase();

  if (source === SourceService.ENTITY) {
    return t("header-bar:VIN");
  } else if (source === SourceService.SHIPMENT) {
    return t("header-bar:Shipment");
  } else if (source === SourceService.PARTVIEW) {
    return t("header-bar:Package");
  }
};

const handleNavigationAction = (auth, notification) => {
  const source = notification?.sourceService?.toUpperCase();

  // Check if this is a shipment or entity comment
  if (source === SourceService.SHIPMENT) {
    const id = notification?.context?.id ?? null;
    return {
      type: "SHIPMENT_DETAIL",
      payload: { shipment_id: id },
    };
  } else if (source === SourceService.PARTVIEW) {
    const id = notification?.referenceId ?? null;
    if (auth.hasOrganizationTypes([OrganizationType.DEALER])) {
      return {
        type: "DEALER_PARTVIEW_DETAILS",
        payload: { trackingNumber: id },
      };
    } else {
      return {
        type: "PARTVIEW_DETAILS",
        payload: { trackingNumber: id },
      };
    }
  } else if (source === SourceService.ENTITY) {
    // If entity, check if the user's org:
    // - Dealer -> VinView Details
    // - Carrier -> CarrierView Details
    // - Otherwise -> Vin Details
    if (auth.hasOrganizationTypes([OrganizationType.DEALER])) {
      const internalId =
        notification.context.internalEntityId ??
        notification.context.internalReferenceId;
      return {
        type: "VINVIEW_DETAILS",
        payload: { internal_id: internalId },
      };
    } else if (auth.hasOrganizationTypes([OrganizationType.CARRIER])) {
      const internalId =
        notification.context.internalEntityId ??
        notification.context.internalReferenceId;
      return {
        type: "CARRIERVIEW_DETAILS",
        payload: { internal_id: internalId },
      };
    } else {
      return {
        type: "VIN_DETAILS",
        payload: { entity_id: notification.referenceId },
      };
    }
  }
};

export const UpdateNotification = ({ id, index }) => {
  const { t } = useTranslation("header-bar");

  const notification = useSelector(getNotification(id));

  let updateType = getTranslatedSourceServiceName(
    notification.sourceService.toLowerCase(),
    t,
  );

  const auth = useSelector(getAuthorization);

  return (
    <>
      <Notification
        key={index}
        id={notification.id}
        title={t("New [[[updateType]]] Updates", { updateType: updateType })}
        footer={t("[[[updateType]]] [[[ref_id]]] has new updates.", {
          updateType: updateType,
          ref_id: notification.referenceId,
        })}
        icon={faFileAlt}
        notificationAction={handleNavigationAction(auth, notification)}
        actionIcon={faChevronCircleRight}
        actionToolTip={t("Go to [[[updateType]]] ", {
          updateType: updateType,
        })}
        timestamp={notification.eventTs}
        hasRead={notification.read}
      />
    </>
  );
};

UpdateNotification.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
};

export const CommentNotification = ({ id, index }) => {
  const { t } = useTranslation("header-bar");
  const notification = useSelector(getNotification(id));
  const auth = useSelector(getAuthorization);
  let commentType = getTranslatedSourceServiceName(
    notification.sourceService.toLowerCase(),
    t,
  );
  return (
    <>
      <Notification
        key={index}
        id={notification.id}
        title={t("New [[[commentType]]] Comments", {
          commentType: commentType,
        })}
        footer={t(
          "[[[commentType]]] [[[ref_id]]] has [[[newComments]]] new [[[comments]]].",
          {
            commentType: commentType,
            ref_id: notification.referenceId,
            comments: notification.context.newComments ? "comments" : "comment",
            newComments: notification.context.newComments,
          },
        )}
        icon={faCommentAlt}
        notificationAction={handleNavigationAction(auth, notification)}
        actionIcon={faChevronCircleRight}
        actionToolTip={t("Go to comments")}
        timestamp={notification.eventTs}
        hasRead={notification.read}
      />
    </>
  );
};

CommentNotification.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
};

export const ExportNotification = ({ id, index }) => {
  const notification = useSelector(getNotification(id));
  const exportState = useSelector(
    getExport(notification.context.export_id ?? notification.context.exportId),
  );
  const { t } = useTranslation("header-bar");

  let translatedExportSource = null;
  const source = exportState?.source?.toUpperCase();

  if (source === SourceService.SHIPMENT) {
    translatedExportSource = t("shipments");
  } else if (source === SourceService.VIN) {
    translatedExportSource = t("VIN");
  } else if (source === SourceService.TRIP_PLAN) {
    translatedExportSource = t("trip plan");
  } else if (source === SourceService.DRIVE_AWAY) {
    translatedExportSource = t("dealer pick-up form");
  } else if (source === SourceService.PARTVIEW) {
    translatedExportSource = t("package");
  }

  switch (exportState.status) {
    case ExportStatus.EXPIRED:
      return (
        <Notification
          id={notification.id}
          key={index}
          title={t("Exported File Expired")}
          timestamp={exportState.timestamp ?? notification.eventTs}
          footer={t(
            "Your [[[source]]] export has expired, please export it again.",
            { source: translatedExportSource },
          )}
          icon={faClock}
          hasRead={notification.read}
        />
      );
    case ExportStatus.CANCELED:
      return (
        <Notification
          id={notification.id}
          key={index}
          // key={exportState.exportId}
          title={t("Export Cancelled")}
          footer={t(
            "Your [[[source]]] export has been canceled, please export it again.",
            { source: translatedExportSource },
          )}
          icon={faTimesCircle}
          timestamp={exportState.timestamp ?? notification.eventTs}
          hasRead={notification.read}
        />
      );
    case ExportStatus.ERROR:
      return (
        <Notification
          id={notification.id}
          key={index}
          title={t("Exported File Expired")}
          timestamp={exportState.timestamp ?? notification.eventTs}
          footer={t(
            "Your [[[source]]] export has expired, please export it again.",
            { source: translatedExportSource },
          )}
          icon={faClock}
          hasRead={notification.read}
        />
      );
    case ExportStatus.TIMEOUT:
      return (
        <Notification
          id={notification.id}
          key={index}
          title={t("Export Timeout")}
          footer={t(
            "Your [[[source]]] export may have been too large to finish, please try again with a smaller query.",
            { source: translatedExportSource },
          )}
          icon={faExclamationCircle}
          timestamp={exportState.timestamp ?? notification.eventTs}
          hasRead={notification.read}
        />
      );
    case ExportStatus.READY:
      return (
        <Notification
          id={notification.id}
          key={index}
          title={t("Export Available For Download")}
          footer={t("Your [[[source]]] export is available for download.", {
            source: translatedExportSource,
          })}
          actionIcon={faArrowCircleDown}
          notificationAction={fetchExportDownload(exportState.exportId)}
          icon={faCloudDownload}
          actionToolTip={t("Download")}
          hasRead={notification.read}
          timestamp={exportState.timestamp ?? notification.eventTs}
        />
      );
    case ExportStatus.NOT_READY:
      return (
        <Notification
          id={notification.id}
          key={index}
          title={t("Exporting Data..")}
          footer={t("Your [[[source]]] data is exporting, please wait...", {
            source: translatedExportSource,
          })}
          icon={faSpinner}
          timestamp={exportState.timestamp ?? notification.eventTs}
          notificationAction={async () =>
            await patchExportStatus(exportState.exportId, ExportStatus.CANCELED)
          }
          actionToolTip={t("Cancel")}
          actionIconColor={Colors.notifications.ACTION_RED}
          actionIcon={faTimesCircle}
          isLoading={true}
          hasRead={notification.read}
        />
      );
    case ExportStatus.NOT_FOUND:
      return (
        <Notification
          key={index}
          id={notification.id}
          title={t("Export Not Found")}
          footer={t(
            "Your [[[source]]] export was not found, please export it again.",
            { source: translatedExportSource },
          )}
          icon={faExclamationCircle}
          timestamp={exportState.timestamp ?? notification.eventTs}
          hasRead={notification.read}
        />
      );
    case ExportStatus.INITIAL_REQUEST_PENDING:
    default:
      return (
        <Notification
          id={notification.id}
          key={index}
          icon={faSpinner}
          hasRead={notification.read}
          isLoading={true}
          timestamp={notification.eventTs}
          title={t("Loading Export...")}
          footer={t("Checking the status of this export...")}
        />
      );
  }
};

ExportNotification.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
};

export const BatchNotification = ({ id, index }) => {
  const notification = useSelector(getNotification(id));

  const { t } = useTranslation("header-bar");
  const isFailedBatch = notification?.context?.status === "ERROR";
  const isPartialBatch = notification?.context?.status === "PARTIAL"; // Means we have some successes and some failures
  const failedBatchDownloadUrl = notification?.context?.presignedUrl;

  if (isFailedBatch) {
    return (
      <Notification
        id={notification.id}
        key={index}
        title={t("Batch Comment Upload Failed")}
        timestamp={notification.eventTs}
        footer={t("Download the attached CSV file for more details.")}
        icon={faExclamationTriangle}
        iconColor={Colors.notifications.ERROR}
        hasRead={notification.read}
        hasErrorStatus={true}
        actionIcon={faArrowCircleDown}
        actionIconColor={Colors.notifications.ERROR}
        actionToolTip={t("Download")}
        notificationAction={fetchFailedBatchCommentsOrUploads(
          failedBatchDownloadUrl,
        )}
      />
    );
  } else if (isPartialBatch) {
    return (
      <Notification
        id={notification.id}
        key={index}
        title={t("Batch Comment Upload Success")}
        timestamp={notification.eventTs}
        footer={t(
          "Batch upload has been processed, but at least one may have failed. Download the attached CSV file for more details.",
        )}
        icon={faCloudUpload}
        hasRead={notification.read}
        actionIcon={faArrowCircleDown}
        actionIconColor={Colors.notifications.ERROR}
        actionToolTip={t("Download")}
        notificationAction={fetchFailedBatchCommentsOrUploads(
          failedBatchDownloadUrl,
        )}
      />
    );
  }

  // Success
  return (
    <Notification
      id={notification.id}
      key={index}
      title={t("Batch Comment Upload Success")}
      timestamp={notification.eventTs}
      footer={t("All comments have been successfully processed.")}
      icon={faCloudUpload}
      hasRead={notification.read}
    />
  );
};

BatchNotification.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
};

export const BatchUploadNotification = ({ id, index }) => {
  const { t } = useTranslation("header-bar");

  const notification = useSelector(getNotification(id));

  const isPartialBatch = notification?.context?.status === "PARTIAL"; // Means we have some successes and some failures
  const isFailedBatch = notification?.context?.status === "ERROR";
  const failedBatchDownloadUrl = notification?.context?.presignedUrl;
  const filename = notification?.context?.filename ?? null;

  let {
    getSuccessTranslatedHeaderFooter,
    getPartialFailedTranslatedHeaderFooter,
    getFailedTranslatedHeaderFooter,
  } = useBatchUploadNotification(notification);

  let propsWithPresignedUrl = {};
  if (failedBatchDownloadUrl) {
    propsWithPresignedUrl = {
      actionIcon: faArrowCircleDown,
      actionIconColor: Colors.notifications.ERROR,
      actionToolTip: t("header-bar:Download"),
      notificationAction: fetchFailedBatchCommentsOrUploads(
        failedBatchDownloadUrl,
        filename,
      ),
    };
  }

  if (isFailedBatch) {
    return (
      <Notification
        id={notification.id}
        key={index}
        title={getFailedTranslatedHeaderFooter().header}
        timestamp={notification.eventTs}
        footer={getFailedTranslatedHeaderFooter().footer}
        icon={faExclamationTriangle}
        iconColor={Colors.notifications.ERROR}
        hasRead={notification.read}
        hasErrorStatus={true}
        {...propsWithPresignedUrl}
      />
    );
  } else if (isPartialBatch) {
    return (
      <Notification
        id={notification.id}
        key={index}
        title={getPartialFailedTranslatedHeaderFooter().header}
        timestamp={notification.eventTs}
        footer={getPartialFailedTranslatedHeaderFooter().footer}
        icon={faCloudUpload}
        hasRead={notification.read}
        {...propsWithPresignedUrl}
      />
    );
  }

  // Success
  return (
    <Notification
      id={notification.id}
      key={index}
      title={getSuccessTranslatedHeaderFooter().header}
      timestamp={notification.eventTs}
      footer={getSuccessTranslatedHeaderFooter().footer}
      icon={faCloudUpload}
      hasRead={notification.read}
    />
  );
};

BatchUploadNotification.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
};
