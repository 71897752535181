import { connect } from "react-redux";
import { PartViewDeliveryStatusUpdate } from "./PartViewDeliveryStatusUpdate.page";
import { PartViewDeliveryStatusUpdateState } from "pages/internal-tools/redux/PartViewDeliveryStatusUpdateState";

function mapStateToProps(state) {
  return {
    requestData:
      PartViewDeliveryStatusUpdateState.selectors.getRequestData(state),
  };
}

const actionCreators = {
  fetchPVDeliveryStatusUpdate: (formData, fileName) => (dispatch) => {
    dispatch(
      PartViewDeliveryStatusUpdateState.actionCreators.fetchPVDeliveryStatusUpdate(
        formData,
        fileName,
      ),
    );
  },
  requestDataClear:
    PartViewDeliveryStatusUpdateState.actionCreators.requestDataClear,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(PartViewDeliveryStatusUpdate);
