/** @jsxImportSource @emotion/react */
import Colors from "../../styles/colors";
import endToEndImage from "../../assets/icons/end-to-end.png";

const EndToEndIcon = (props) => {
  return (
    <div
      css={{
        minWidth: 20,
        minHeight: 20,
        width: 20,
        height: 20,
        backgroundColor: Colors.background.DARK_GRAY,
        borderRadius: 20,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={endToEndImage} width={14} height={6} alt="End to End" />
    </div>
  );
};

export default EndToEndIcon;
