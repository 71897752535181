/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import React, { useState, useContext, useEffect } from "react";

import { withSize } from "react-sizeme";
import { PanelGroupTheme } from "components/molecules/enum";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";

const contentAnim = {
  duration: 333,
  easing: "ease-in-out",
};

const iconAnim = {
  duration: 150,
  easing: "ease-in",
};

// Used internally to this file to control the collapsible state for the PanelGroup.
const PanelGroupCollapsibleContext = React.createContext({
  collapsed: false,
  setCollapsed: () => {},
});

// TODO: Remove this context in favor of Emotion's ThemeProvider.
// This would require refactoring of all styles under `PanelGroup` and subcomponents.
const PanelGroupThemeContext = React.createContext(null);

export const PanelGroup = ({
  collapsible = false,
  initialCollapsed = false,
  disabled = false,
  style = {},
  className,
  children,
  theme = PanelGroupTheme.Default,
  collapsed: collapsedFromProps,
  setCollapsed: setCollapsedFromProps,
  onClick,
}) => {
  const [collapsedFromState, setCollapsedFromState] =
    useState(initialCollapsed);
  // This is the controlled version of PanelGroup if `setCollapsed` is given via props.
  const isControlled = typeof setCollapsedFromProps === "function";

  let collapsed, setCollapsed;

  // If controlled, the value and setter is from props.
  // Otherwise, use values from `useState`.
  if (isControlled) {
    collapsed = collapsedFromProps;
    setCollapsed = setCollapsedFromProps;
  } else {
    collapsed = collapsedFromState;
    setCollapsed = setCollapsedFromState;
  }

  // Prevent collapse/expand if disabled and collapsible
  // else if collapsible, follow normal behavior
  // else keep it expanded
  let isCollapsed;
  if (collapsible && disabled) {
    isCollapsed = true;
  } else if (collapsible) {
    isCollapsed = collapsed;
  } else {
    isCollapsed = false;
  }

  return (
    // Outer div so that we can position things outside of the `overflow: hidden` container.
    <div
      css={{ position: "relative", cursor: "default", ...style }}
      className={className}
      onClick={onClick}
    >
      <div
        css={{
          backgroundColor: "white",
          boxShadow:
            theme === PanelGroupTheme.Grey
              ? "none"
              : "0 3px 6px rgba(0, 0, 0, 0.1)",
          // Overflow hidden required to show the border radius
          overflow: "hidden",
          borderRadius: theme === PanelGroupTheme.Grey ? "5px" : "4px",
          height: !_.isNil(style.height) ? "inherit" : "auto",
        }}
      >
        <PanelGroupThemeContext.Provider value={theme}>
          <PanelGroupCollapsibleContext.Provider
            value={{
              collapsible,
              // We only care about `disabled` if it is collapsible
              disabled: collapsible && disabled,
              collapsed: isCollapsed,
              setCollapsed: collapsible && !disabled ? setCollapsed : () => {},
            }}
          >
            {children}
          </PanelGroupCollapsibleContext.Provider>
        </PanelGroupThemeContext.Provider>
      </div>
    </div>
  );
};

PanelGroup.propTypes = {
  /**
   * Set the `PanelGroup` as collapsible.
   *
   * This will add an icon that the use can click to collapse or expand the content.
   */
  collapsible: PropTypes.bool,
  /**
   * Render the panel as initially collapsed.
   *
   * Note: The animation for expanding will not work for the initial expansion if this is set to true,
   * since we won't know the height of the content until it's expanded.
   */
  initialCollapsed: PropTypes.bool,
  /**
   * Disables collapsing and expanding of the `PanelGroup`.
   */
  disabled: PropTypes.bool,
  /**
   * Theme value for the panel group.
   */
  theme: PropTypes.string,
  /**
   * A boolean value to control the collapsed state.
   */
  collapsed: PropTypes.bool,
  /**
   * A function that is called with the new collapsed value when it changes.
   */
  setCollapsed: PropTypes.func,
  /**
   * An object that will be used as the element styles for the root element of `PanelGroup`.
   */
  style: PropTypes.object,
  /**
   * The CSS class to set on the parent div of PanelGroup.
   */
  className: PropTypes.string,
  /**
   * The content of the `PanelGroup`.
   *
   * Use `PanelGroup.Header` and `PanelGroup.Content` for the children of `PanelGroup`.
   */
  children: PropTypes.any,
  /**
   * Handler when clicking anywhere within the panel.
   */
  onClick: PropTypes.func,
};

const PanelGroupHeader = ({
  title,
  titleSize = FontSize.size20,
  titleTooltipText,
  titleTruncate = true,
  postTitleIcon,
  postTitleIconTooltip,
  tooltipPosition = "top",
  iconSize = FontSize.size14,
  leftContent,
  rightContent,
  preHeaderContent,
  postHeaderContent,
  style = {},
  titleStyle = {},
  rightContentStyle,
  dataQa,
}) => {
  const { collapsible, disabled, collapsed, setCollapsed } = useContext(
    PanelGroupCollapsibleContext,
  );

  const theme = useContext(PanelGroupThemeContext);

  const textColor = disabled
    ? Colors.text.DISABLED
    : theme === PanelGroupTheme.Light
    ? Colors.text.BLUE_BLACK
    : Colors.text.VERY_LIGHT_GRAY;

  const iconColor = {
    transform: `rotate(${collapsed ? -90 : 0}deg)`,
    transition: `transform ${iconAnim.duration}ms ${iconAnim.easing}`,
  };

  return (
    <header
      css={{
        display: "flex",
        flexDirection: "row",
        transition: `border-radius ${contentAnim.duration}ms ${contentAnim.easing}`,
        background:
          theme === PanelGroupTheme.Light
            ? Colors.background.LIGHT_GRAY
            : Colors.background.DARK_GRAY,
      }}
      style={style}
      onClick={() => setCollapsed(!collapsed)}
      data-qa={dataQa ? dataQa : ""}
    >
      {/* Pre Header Content */}
      <div css={{ minHeight: "100%" }}>{preHeaderContent}</div>

      {/* Header */}
      <div
        css={{
          // Allow this element to grow or shrink because of surrounding content
          flex: "1 1 0",
          // Min Width required to let this item shrink if we have pre or post content to the header
          minWidth: 0,

          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding:
            theme === PanelGroupTheme.Grey
              ? "0.5em 0.25em 0.5em 1.25em"
              : "0.5em 1em",
          color: textColor,
          cursor: collapsible && !disabled ? "pointer" : "inherit",
          backgroundColor:
            theme === PanelGroupTheme.Grey
              ? disabled
                ? Colors.background.DISABLED
                : Colors.background.LIGHT_GRAY
              : theme === PanelGroupTheme.Light
              ? Colors.background.LIGHT_GRAY
              : Colors.background.DARK_GRAY,
        }}
      >
        {/* Collapse/Expand Button */}
        {collapsible ? (
          <button
            disabled={disabled}
            css={{
              marginRight: theme === PanelGroupTheme.Grey ? "0px" : "0.5em",
              border: "none",
              background: "transparent",
              display: "flex",
              ":hover": {
                // TODO
              },
              ":active": {
                // TODO
              },
            }}
          >
            <Icon
              color={textColor}
              size={
                theme === PanelGroupTheme.Grey
                  ? FontSize.size16
                  : FontSize.size18
              }
              src={faChevronDown}
              style={
                theme === PanelGroupTheme.Grey
                  ? { ...iconColor, color: "rgb(172, 181, 190)" }
                  : iconColor
              }
            />
          </button>
        ) : null}

        {/* Left Content */}
        {leftContent ? (
          <span
            css={{
              marginRight: theme === PanelGroupTheme.Grey ? "0px" : "0.5em",
            }}
          >
            {leftContent}
          </span>
        ) : null}

        <span
          css={{
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            ...titleStyle,
          }}
        >
          {/* Header Title */}
          {/* Consider using something other than `margin-right: auto` to push the rightContent to the end */}
          <Text
            size={titleSize}
            style={
              theme === PanelGroupTheme.Grey
                ? {
                    marginLeft: 10,
                    fontSize: 15,
                    fontWeight: 600,
                    textDecoration: "underline",
                    color: disabled ? "#a2a2a2" : "black",
                    flex: 1,
                  }
                : {}
            }
            truncate={titleTruncate}
          >
            <Tooltip
              placement={tooltipPosition}
              tooltipChildren={
                titleTooltipText ? <Text>{titleTooltipText}</Text> : null
              }
            >
              {title}
            </Tooltip>
          </Text>

          {postTitleIcon ? (
            <Tooltip
              placement={tooltipPosition}
              tooltipChildren={
                postTitleIconTooltip ? (
                  <Text>{postTitleIconTooltip}</Text> // TODO: Remove the wrapping Text so the parent controls the content entirely
                ) : null
              }
            >
              <Icon
                type={IconType.FontAwesome}
                size={iconSize}
                src={postTitleIcon}
                css={{ marginLeft: 10 }}
              />
            </Tooltip>
          ) : null}
        </span>

        {/* Right Content */}
        {rightContent ? (
          <span css={{ ...rightContentStyle }}>{rightContent}</span>
        ) : null}
      </div>

      {/* Post Header Content */}
      <div css={{ minHeight: "100%" }}>{postHeaderContent}</div>
    </header>
  );
};

PanelGroupHeader.propTypes = {
  /**
   * The header text.
   */
  title: PropTypes.string,
  /**
   * The size of the title text.
   *
   * Use the `FontSize` enum for this value.
   */
  titleSize: PropTypes.number,
  /**
   * The size of the post title icon.
   *
   * Use the `FontSize` enum for this value.
   */
  iconSize: PropTypes.number,
  /**
   * Text to display in the tooltip when hoving over the title of the header.
   *
   * Omit this prop if you do not want a tooltip.
   */
  titleTooltipText: PropTypes.string,
  /**
   * Defines the position of the tooltip.
   */
  tooltipPosition: PropTypes.string,
  /**
   * Set to true to truncate the title by adding overflow=hidden and an ellipsis.
   */
  titleTruncate: PropTypes.bool,
  /**
   * Icon to display after the title.
   *
   * Only accepts a FontAwesome icon coming from the @fortawesome package.
   *
   * Omit this prop if you do not want an icon.
   */
  postTitleIcon: PropTypes.object,
  /**
   * The text to display in the tooltip when hovering over the postTitleIcon of the header.
   *
   * Omit this prop if you do not want a tooltip.
   */
  postTitleIconTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Additional content to display to the left of the header title.
   *
   * If the `PanelGroup` is collapsibe, this content will display before the button.
   */
  leftContent: PropTypes.any,
  /**
   * Additional content to display to the right of the header title.
   *
   * This content is pushed to the right-most edge within the header.
   */
  rightContent: PropTypes.any,
  /**
   * Adjust the styling for the span wrapping the rightContent of the header.
   */
  rightContentStyle: PropTypes.string,
  /**
   * This is content that will display before the header.
   *
   * __Note__: This is not included as part of the header.
   *
   * i.e. `title`, collapse toggle button, `leftContent` and `rightContent`.
   */
  preHeaderContent: PropTypes.any,
  /**
   * This is content that will display after the header.
   *
   * __Note__: This is not included as part of the header.
   *
   * i.e. `title`, collapse toggle button, `leftContent` and `rightContent`.
   */
  postHeaderContent: PropTypes.any,
  /**
   * An object that will be used as the element styles for the root element of `PanelGroup.Header`.
   */
  style: PropTypes.object,
  /**
   * An object that will be used as the element styles for the title element of `PanelGroup.Header`.
   */
  titleStyle: PropTypes.object,
  /**
   * Set whether the entire `PanelGroup` as collapsible.
   *
   * This will collapse or expand the content on clicking anywhere in the panel group.
   */
  isPanelGroupCollapsible: PropTypes.bool,
  dataQa: PropTypes.string,
};
PanelGroup.Header = PanelGroupHeader;

const PanelGroupSubHeader = ({ children, className, style = {} }) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

PanelGroupSubHeader.propTypes = {
  /**
   * The contents of the subheader.
   */
  children: PropTypes.any,
  /**
   * The CSS class name to apply to the root element of `PanelGroup.SubHeader`.
   */
  className: PropTypes.string,
  /**
   * An object that will be used as the element styles for the root element of `PanelGroup.SubHeader`.
   */
  style: PropTypes.object,
};
PanelGroup.SubHeader = PanelGroupSubHeader;

let PanelGroupContent = ({ size, style = {}, dataQa, children }) => {
  const { collapsed } = useContext(PanelGroupCollapsibleContext);

  const [maxHeight, setMaxHeight] = useState("unset");
  const [preCollapseHeight, setPreCollapseHeight] = useState(0);

  useEffect(() => {
    if (collapsed) {
      // Collapsing
      // If we haven't changed anything in the maxHeight yet
      if (maxHeight === "unset") {
        // Set maxHeight to the current height of the panel
        setMaxHeight(size.height);

        // Wait 50ms to allow the state to update for the maxHeight,
        // which will provide the animation the starting point for the maxHeight to
        // go down to 0.
        // Note: Setting this to 1ms causes a race condition where the state temporarily
        // uses the default value for maxHeight ("unset") and causes some wonkiness.
        setTimeout(() => {
          // Set the preCollapseHeight to the current height of the panel
          // (which should kick off the other useEffect below)
          setPreCollapseHeight(size.height);
        }, 50);
      }
    } else {
      // Expanding
      // Only do calculations if we aren't fully opened (eg: maxHeight is not reset to "unset")
      if (maxHeight !== "unset") {
        // Set the maxHeight to the height of the panel prior to collapsing
        setMaxHeight(preCollapseHeight);

        // Wait 333ms for the animation to complete
        setTimeout(() => {
          // Reset maxHeight to "unset" to take into account any changes in the panel
          // eg: If we added rows to a table in the panel while it was collapsed
          setMaxHeight("unset");

          // Reset preCollapseHeight to 0 so we reset to the current height
          // at the next collapse, in case the panel height changed
          setPreCollapseHeight(0);
        }, 333);
      }
    }
  }, [
    collapsed,
    maxHeight,
    setMaxHeight,
    preCollapseHeight,
    setPreCollapseHeight,
    size,
  ]);

  useEffect(() => {
    // If the preCollapseHeight changes
    // meaning the user has collapsed the panel
    if (preCollapseHeight > 0) {
      // Set the maxHeight to 0 so the panel collapses starting from
      // the current maxHeight which is the total height of the panel
      setMaxHeight(0);
    }
  }, [preCollapseHeight, setMaxHeight]);

  return (
    <div
      css={{
        overflow: "hidden",
        maxHeight: maxHeight,
        transition: `max-height ${contentAnim.duration}ms ${contentAnim.easing}`,
      }}
      data-qa={dataQa ? dataQa : ""}
    >
      <div style={style} css={{ padding: "1em" }}>
        {children}
      </div>
    </div>
  );
};

// Wrap with the HOC because we need it as prop to use in effects.
PanelGroupContent = withSize({ monitorHeight: true })(PanelGroupContent);

PanelGroupContent.propTypes = {
  /**
   * __INTERNAL: DO NOT SUPPLY THIS PROP!__
   *
   * This is provided by the `withSize` HOC from `react-sizeme`.
   */
  size: PropTypes.shape({
    height: PropTypes.number,
  }),
  /**
   * An object that will be used as the element styles for the root element of `PanelGroup.Content`.
   */
  style: PropTypes.object,
  /**
   * The content of the PanelGroup.
   *
   * This section will collapse if this is a collapsible `PanelGroup`.
   */
  children: PropTypes.any,

  dataQa: PropTypes.string,
};
PanelGroup.Content = PanelGroupContent;

// Exports for storybook. Need to export so their props can be seen in the docs tab.
// Do not use these exports! Use `PanelGroup.Header` and `PanelGroup.Content` instead.
export { PanelGroupHeader, PanelGroupSubHeader, PanelGroupContent };
