/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { Text, FontSize } from "components/atoms/Text.atom";
import DetailsTable from "components-old/DetailsTable";
import { getArrivalWindowText, getDeliveryWindowText } from "../ArrivalWindow";
import BoldCell from "components/organisms/bootstrap-table/Cell/BoldCell";
import LocationCell from "components/organisms/bootstrap-table/Cell/LocationCell";
import { NaPlaceholder } from "components/no-data-placeholders";
import { DateTime } from "components/atoms/DateTime.atom";

import { getReferenceValueByName } from "utils/response-utils";
import { dateFormatter } from "utils/date-time";

const getStopOrder = (stops, index) => {
  if (index === 0) {
    return "O";
  } else if (index === stops.length - 1) {
    return "D";
  } else if (
    stops[index].stop_identifier &&
    stops[index].stop_identifier.includes("ETAI")
  ) {
    return "I";
  } else {
    return index.toString();
  }
};

const DateTimeCell = ({ time, isETA }) => {
  const { t } = useTranslation("shipment-details");

  return (
    <div>
      <DateTime
        stack
        dateTime={time}
        localize
        fallback={
          <Text size={FontSize.size12}>{t("shipment-details:N/A")}</Text>
        }
      >
        {/*
         * Display time and timezone in bold. Unless ETA, display in italic.
         * Note: We do not have a bold+italic version of our typeface.
         */}
        <DateTime.Time size={FontSize.size12} bold={!isETA} italic={isETA} />
        <DateTime.Timezone
          size={FontSize.size12}
          bold={!isETA}
          italic={isETA}
          color="inherit"
        />
        <DateTime.Date size={FontSize.size12} bold />
      </DateTime>
    </div>
  );
};

DateTimeCell.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isETA: PropTypes.bool,
};

function getStopRows(filteredStops, mode, shipmentMissedDropOff, showETA, t) {
  return filteredStops.map((s, i) => {
    const hasArrivalTime = s.arrived_at != null;
    const hasDepartedTime = s.departed_at != null;
    const hasETA = s.eta != null;
    const isLTL = mode === "LTL";
    const isParcel = mode === "Parcel";
    /* DEV-1306 - change stop cell display for CLM stops */
    const isCLM = s.stop_identifier && s.stop_identifier.includes("ETAI");
    const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(
      Object.assign({}, s, {
        isDateOnly: isLTL || isParcel,
      }),
    );

    // SH-6368: Grab DeliveryType from stop references
    const deliveryType = getReferenceValueByName(
      s?.shipment_stop_references,
      "DeliveryType",
    );

    return (
      <tr key={i} data-qa="rows-stops-table-single">
        <td>
          <Text bold size={FontSize.size12}>
            {getStopOrder(filteredStops, i)}
          </Text>
        </td>
        <LocationCell
          location={s.location}
          showAddress={!isCLM}
          deliveryType={deliveryType}
        />
        <td css={{ minWidth: "10em" }}>
          <Text bold block size={FontSize.size12}>
            {windowTextLine1}
          </Text>
          <Text block size={FontSize.size12}>
            {windowTextLine2}
          </Text>
        </td>
        {!shipmentMissedDropOff && showETA[i] && hasETA ? (
          <td css={{ minWidth: "10em" }}>
            {isLTL || isParcel ? (
              <Text size={FontSize.size12}>{dateFormatter(s.eta)}</Text>
            ) : (
              <DateTimeCell time={s.eta} isETA={hasETA} />
            )}
          </td>
        ) : (
          <td></td>
        )}
        <td css={{ minWidth: "10em" }}>
          <Text underline block size={FontSize.size12}>
            {t("Arrival")}
          </Text>
          {hasArrivalTime ? (
            <DateTimeCell time={s.arrived_at} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
          <Text underline block size={FontSize.size12} style={{ marginTop: 5 }}>
            {t("Departure")}
          </Text>
          {hasDepartedTime ? (
            <DateTimeCell time={s.departed_at} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
        </td>
      </tr>
    );
  });
}

// H1-1033: Fix to align delivery text to bottom of row and pickup info to top of row
const TimeCellContainer = styled.div({
  minWidth: "10em",
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const HalfCellRow = styled.div({
  alignItems: "flex-start",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "50%",
  minHeight: "100px",
});

function getMultiStopRows(stops, t) {
  return stops.map((s, i) => {
    const hasArrivalTime = s.arrived !== null;
    // const hasScheduledArrival = s.earliest_arrival_datetime !== null;
    const hasDepartedTime = s.departed !== null;
    const hasScheduledDeparted = s.latest_arrival_datetime !== null;
    /* DEV-1306 - change stop cell display for CLM stops */
    const isCLM = s.stop_identifier && s.stop_identifier.includes("ETAI");

    /* H1-1349 get Scheduled Pickup window */
    const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(
      Object.assign({}, s, {
        isDateOnly: false,
      }),
    );

    /* H1-1349 get Scheduled Delivery window */
    const deliveryWindowText = getDeliveryWindowText(s);

    // SH-6368: Grab DeliveryType from stop references
    const deliveryType = getReferenceValueByName(
      s?.shipment_stop_references,
      "DeliveryType",
    );

    /* H1-1349 display Delivery first, then Pickup, updated data for actual pickup and delivery */
    return (
      <tr key={i} data-qa="rows-stops-table-multi">
        <BoldCell>{getStopOrder(stops, i)}</BoldCell>
        <LocationCell
          location={s.location}
          showAddress={!isCLM}
          deliveryType={deliveryType}
        />
        <td>
          <TimeCellContainer>
            {i > 0 ? (
              <HalfCellRow>
                <Text
                  underline
                  block
                  size={FontSize.size12}
                  style={{ marginTop: 5 }}
                >
                  {t("Delivery")}
                </Text>
                <Text bold block size={FontSize.size12}>
                  {deliveryWindowText.windowTextLine1}
                </Text>
                <Text block size={FontSize.size12}>
                  {deliveryWindowText.windowTextLine2}
                </Text>
              </HalfCellRow>
            ) : null}
            {i < stops.length - 1 ? (
              <HalfCellRow>
                <Text underline block size={FontSize.size12}>
                  {t("Pickup")}
                </Text>
                <Text bold block size={FontSize.size12}>
                  {windowTextLine1}
                </Text>
                <Text block size={FontSize.size12}>
                  {windowTextLine2}
                </Text>
              </HalfCellRow>
            ) : null}
          </TimeCellContainer>
        </td>
        <td
          css={{
            minWidth: "10em",
          }}
        >
          <TimeCellContainer>
            {
              /* H1-1034 Don't display estimated delivery times for legs that actual delivery time */
              i > 0 && !hasDepartedTime ? (
                <HalfCellRow>
                  <Text
                    underline
                    block
                    size={FontSize.size12}
                    style={{ marginTop: 5 }}
                  >
                    {t("Delivery")}
                  </Text>
                  {hasScheduledDeparted ? (
                    <DateTimeCell
                      time={s.latest_arrival_datetime}
                      isETA={false}
                    />
                  ) : (
                    <NaPlaceholder />
                  )}
                </HalfCellRow>
              ) : null
            }
            {i < stops.length - 1 ? <HalfCellRow /> : null}
          </TimeCellContainer>
        </td>
        <td>
          <TimeCellContainer>
            {i > 0 ? (
              <HalfCellRow>
                <Text
                  underline
                  block
                  size={FontSize.size12}
                  style={{ marginTop: 5 }}
                >
                  {t("Delivery")}
                </Text>
                {hasDepartedTime ? (
                  <DateTimeCell time={s.delivery} isETA={false} />
                ) : (
                  <NaPlaceholder />
                )}
              </HalfCellRow>
            ) : null}
            {i < stops.length - 1 ? (
              <HalfCellRow>
                <Text underline block size={FontSize.size12}>
                  {t("Pickup")}
                </Text>
                {hasArrivalTime ? (
                  <DateTimeCell time={s.pickup} isETA={false} />
                ) : (
                  <NaPlaceholder />
                )}
              </HalfCellRow>
            ) : null}
          </TimeCellContainer>
        </td>
      </tr>
    );
  });
}

export const StopsTable = ({
  mode,
  isMultileg,
  stops,
  active_exceptions_ng,
}) => {
  const { t } = useTranslation("shipment-details");

  if (!stops) {
    return null;
  }

  // HT-61
  // filter out terminal arrivals from LTL shipments which are now being treated as stops in the b.e
  // H1-2635: also filter out parcel terminal
  // FIXME: this shouldn't be so deep in logic, nor should it use mode_id
  const filteredStops = stops.filter(
    (s) => !((s.mode_id === 3 || s.mode_id === 8) && s.stop_reason === "X4"),
  );

  // DEV-1221:
  // If the shipment has missed the destination (related to DEV-1093),
  // then we prevent the ETA from showing on the stops tab as well
  const shipmentMissedDropOff = active_exceptions_ng === "Missed Drop-Off";

  // There are certain instances where we miss a stop
  // in the sequence, and arrive at a later stop
  // in those cases, we do not want to show ETA for any
  // stop before a stop we have arrived at
  let foundActual = false;
  let showETA = {};
  for (let i = filteredStops.length - 1; i >= 0; i--) {
    if (filteredStops[i].arrived_at != null) {
      foundActual = true;
    }
    showETA[i] = !foundActual;
  }

  /* DEV-1545 LTL shipments displays dates only for no times for Scheduled or Estimated,
  but time for Actual. */
  /* H1-841 Slightly modified display for Multimodal Stops */
  /* SH-4613: Applied same Multimodal logic to Crossborder, by utilizing common "isMultiLeg" value */
  let rows = isMultileg
    ? getMultiStopRows(stops, t)
    : getStopRows(filteredStops, mode, shipmentMissedDropOff, showETA, t);

  let headers = ["", t("Stop"), t("Scheduled"), t("Estimated"), t("Actual")];

  return <DetailsTable headers={headers} rows={rows} />;
};

StopsTable.propTypes = {
  mode: PropTypes.string,
  isMultileg: PropTypes.bool,
  stops: PropTypes.array,
  active_exceptions_ng: PropTypes.string,
};
