import apiUrl from "api-url";
import { buildAsyncCategoryOptionsState } from "components/search-bar/AsyncCategoryOptionsStateBuilder";

const getResponseData = (data) => data?.data ?? [];

const transformResult = ({ label, value }) => ({
  label: label ?? value,
  value: value,
});

const debounceWaitTimeInMs = 500;

export const partViewPartNumberOptionsState = buildAsyncCategoryOptionsState({
  topic: "partViewPartNumberOptions",
  url: apiUrl("/partview/app/list/part-number"),
  getResponseData,
  transformResult,
  debounceWaitTimeInMs,
});

export const partViewOrderNumberOptionsState = buildAsyncCategoryOptionsState({
  topic: "partViewOrderNumberOptions",
  url: apiUrl("/partview/app/list/order-number"),
  getResponseData,
  transformResult,
  debounceWaitTimeInMs,
});

export const partViewShipmentIdOptionsState = buildAsyncCategoryOptionsState({
  topic: "partViewShipmentIdOptions",
  url: apiUrl("/partview/app/list/shipment-id"),
  getResponseData,
  transformResult,
  debounceWaitTimeInMs,
});

export const partViewPackageNumberOptionsState = buildAsyncCategoryOptionsState(
  {
    topic: "partViewPackageNumberOptions",
    url: apiUrl("/partview/app/list/tracking-number"),
    getResponseData,
    transformResult,
    debounceWaitTimeInMs,
  },
);

export const partViewBillOfLadingOptionsState = buildAsyncCategoryOptionsState({
  topic: "partViewBillOfLadingOptions",
  url: apiUrl("/partview/app/list/bill-of-lading"),
  getResponseData,
  transformResult,
  debounceWaitTimeInMs,
});
