type BatchCsvHeader = {
  /**
   * The key used to match up the data in the CSV with the
   * appropriate header
   */
  key: string;
  /**
   * The label to display in the header row of the CSV file
   */
  label: string;
};

type BatchCsvExample = {
  /**
   * Example data that will be transformed into the rows
   * of the CSV file
   */
  data: object[];
  /**
   * The definition for the header row in the CSV file
   */
  headers: BatchCsvHeader[];
};

export const bulkSubmitVinExample: BatchCsvExample = {
  data: [
    {
      vin: "1GCHK29U87E198693",
    },
    {
      vin: "1FTSX21P05EC23578",
    },
    {
      vin: "JH4DA3340HS032394",
    },
  ],
  headers: [{ key: "vin", label: "VIN" }],
};

export const bulkApproveVinExample: BatchCsvExample = {
  data: [
    {
      vin: "1FTSX21P05EC23578",
      date: "11/20/2022",
      from: "09:00",
      to: "15:00",
      comment: "Optional comment",
    },
    {
      vin: "1FTSX21P05EC23578",
      date: "11/21/2022",
      from: "09:00",
      to: "15:00",
      comment: "",
    },
    {
      vin: "1FTSX21P05EC23578",
      date: "11/22/2022",
      from: "09:00",
      to: "15:00",
      comment: "",
    },
    {
      vin: "JH4DA3340HS032394",
      date: "11/01/2022",
      from: "09:00",
      to: "15:00",
      comment: "",
    },
  ],
  headers: [
    { key: "vin", label: "VIN" },
    { key: "date", label: "Pick up date" },
    { key: "from", label: "From window time" },
    { key: "to", label: "To window time" },
    { key: "comment", label: "Comment" },
  ],
};

export const bulkDenyVinExample: BatchCsvExample = {
  data: [
    {
      vin: "1FTSX21P05EC23578",
      reason: "NOT_FOUND",
      comment: "",
    },
    {
      vin: "JH4KA3170LC006787",
      reason: "CARRIER_ASSIGNED",
      comment: "",
    },
    {
      vin: "JH4DA3340HS032394",
      reason: "UPFIT_REQUIRED",
      comment: "",
    },
    {
      vin: "AAASDAY7S37ELJ4IT",
      reason: "INOPERABLE",
      comment: "",
    },
    {
      vin: "AAAXBKRHJU0952KZG",
      reason: "DAMAGED",
      comment: "",
    },
    {
      vin: "1FTRW14W84KC76110",
      reason: "OTHER",
      comment: "Comments are REQUIRED when the deny reason is OTHER",
    },
  ],
  headers: [
    { key: "vin", label: "VIN" },
    { key: "reason", label: "Deny Reason" },
    { key: "comment", label: "Comment" },
  ],
};
