/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";

const ShipmentWatchToggle = ({
  watch,
  toggleWatchShipment,
  isShowingComments,
}) => {
  const { t } = useTranslation("shipment-details");

  let tooltipText = t(
    "shipment-details:Add this shipment to your Watched Shipments list on the Shipment Exceptions Dashboard",
  );

  if (isShowingComments) {
    tooltipText = t(
      "shipment-details:Add this shipment to your Watched Shipments list on the Shipment Exceptions Dashboard and receive notifications for new comments",
    );
  }

  return (
    <WatchToggle
      id="watch"
      checked={watch ?? false}
      onChange={toggleWatchShipment}
      onClick={(e) => {
        //stop the event propagation else detail panel is also toggled since it is collapsible
        e.stopPropagation();
      }}
      labelText={t("shipment-details:Watch this shipment")}
      tooltipText={tooltipText}
    />
  );
};

ShipmentWatchToggle.propTypes = {
  watch: PropTypes.bool.isRequired,
  toggleWatchShipment: PropTypes.func.isRequired,
  isShowingComments: PropTypes.bool,
};

export default ShipmentWatchToggle;
