/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "components/atoms/TextInput.atom";
import { Button } from "components/atoms/Button.atom";
import { isValidEmail } from "utils/validation-utils";
import Colors from "styles/colors";
import AuthenticationUtils from "./authentication";

type UserPreferenceType = {
  dateformat: string;
  defaultCommunication: { email: string; phone: string };
  locale: string;
  timeformat: string;
  timezone: string;
  units_of_measurement: object;
};

type FederatedUserEmailUpdateModalProps = {
  show: boolean;
  setShow: Function;
  isFederatedLogin: boolean;
  userPreferences: UserPreferenceType;
  setUserPreferences: Function;
};

export const FederatedUserEmailUpdateModal = ({
  show,
  setShow,
  userPreferences,
  setUserPreferences,
}: FederatedUserEmailUpdateModalProps) => {
  const { t } = useTranslation("users");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (
      !userPreferences?.defaultCommunication?.email &&
      !show &&
      !AuthenticationUtils.getHasRequestedFederatedUserEmail()
    ) {
      AuthenticationUtils.setHasRequestedFederatedUserEmail(true);
      setShow(true);
    }
  }, [userPreferences, show, setShow]);

  // Popup Hide handler
  const hideHandler = () => {
    setEmail("");
    setShow(false);
  };

  return (
    <Modal show={show} backdrop={"static"} onHide={hideHandler}>
      <ModalHeader
        closeButton
        css={{ backgroundColor: Colors.dialogs.HEADER_BACKGROUND }}
      >
        <Text color={Colors.dialogs.HEADER_TEXT_COLOR} size={FontSize.size18}>
          {t("users:Welcome!")}
        </Text>
      </ModalHeader>
      <ModalBody
        css={{
          backgroundColor: "transparent !important",
          minHeight: "20em",
        }}
      >
        <Text
          block
          css={{
            marginBottom: "1em",
          }}
        >
          {t(
            "users:We noticed that you do not have a valid email address set up for your account.",
          )}
        </Text>
        <Text
          block
          css={{
            marginBottom: "1em",
          }}
        >
          {t("users:Please input your email address below and click Save.")}
        </Text>

        <Text
          block
          css={{
            marginBottom: "1em",
          }}
        >
          {t(
            "users:This will set the default email address for email notifications from our Alert Me feature and Dealer Pick Up submissions.",
          )}
        </Text>

        <div
          css={{
            display: "flex",
            justifyContent: "center",
            margin: "1.5em",
          }}
        >
          <TextInput
            placeholder="Email"
            css={{
              width: "20em",
            }}
            value={email}
            onChange={(value) => {
              setEmail(value);
            }}
          ></TextInput>
        </div>

        <div
          css={{
            bottom: "0",
          }}
        >
          <Trans t={t}>
            You can update your default recipient email and phone number within
            the My Profile page, indicated by the profile icon{" "}
            <span
              style={{
                color: Colors.background.DARK_TEAL,
              }}
            >
              <MdAccountCircle
                style={{
                  height: "1.25em",
                  width: "1.25em",
                }}
              />
            </span>{" "}
            on the lower left of your screen.
          </Trans>
        </div>
      </ModalBody>
      <ModalFooter
        css={{
          backgroundColor: Colors.dialogs.FOOTER_BACKGROUND,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="secondary"
          data-qa="button-cancel-federated-user-preference-modal"
          onClick={() => {
            setShow(false);
          }}
        >
          {t("users:Dismiss")}
        </Button>
        <Button
          variant="success"
          data-qa="button-save-federated-user-preference-modal"
          disabled={!isValidEmail(email)}
          onClick={() => {
            setUserPreferences({
              ...userPreferences,
              defaultCommunication: {
                ...userPreferences.defaultCommunication,
                email: email,
              },
            });
            hideHandler();
          }}
        >
          {t("users:Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
