/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import {
  getExceptionData,
  ShipmentExceptionType,
  getTranslatedNameForExceptionType,
  getIconData,
} from "pages/shipments/utils/exception.utils";

export const ShipmentsExceptionsWidget = (props) => {
  const {
    exceptionTotals,
    exceptionTotalsIsLoading,
    exceptionTypes,
    setSearchFilter,
    searchEntities,
  } = props;
  const { t } = useTranslation("shipment-status");

  const { exceptions, totalShipments } = getExceptionData(
    exceptionTotals,
    exceptionTypes,
  );

  const formattedExceptions = exceptions.map((exception) => {
    return {
      ...exception,
      name: getTranslatedNameForExceptionType(exception.type, t),
      icon: getIconData(exception.type),
    };
  });
  const mainExceptions = formattedExceptions.filter(
    (exception) => exception.type !== ShipmentExceptionType.UNDER_REVIEW,
  );
  const underReviewException = formattedExceptions.find(
    (exception) => exception.type === ShipmentExceptionType.UNDER_REVIEW,
  );

  return (
    <ExceptionsPanel
      title={t("shipment-status:All Shipment Exceptions")}
      chartLabel={t("shipment-status:Total Shipments")}
      totalCount={totalShipments}
      totalCountIsLoading={exceptionTotalsIsLoading}
      exceptionGroups={[
        {
          title: t("shipment-status:Exceptions"),
          includeInDonutChart: true,
          exceptions: mainExceptions,
        },
        {
          title: t("shipment-status:Other"),
          includeInDonutChart: true,
          exceptions: underReviewException ? [underReviewException] : [],
        },
      ]}
      handleClickException={(exception) => {
        setSearchFilter("active_exception", [exception.id]);
        searchEntities();
      }}
      handleClickGraph={() => {
        searchEntities();
      }}
    />
  );
};

ShipmentsExceptionsWidget.propTypes = {
  exceptionTotals: PropTypes.object,
  exceptionTotalsIsLoading: PropTypes.bool,
  exceptionTypes: PropTypes.array,
  setSearchFilter: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
};
