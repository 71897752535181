import {
  getAppConfig,
  getPageConfig,
} from "modules/auth/AppConfigAuthorization";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";

const MOUNT_POINT = "appConfiguration";
const getActionType = (name) => `${MOUNT_POINT}/${name}`;

// Action types
const FETCH_GLOBAL_APP_CONFIG = getActionType("FETCH_GLOBAL_APP_CONFIG");
const RECEIVE_GLOBAL_APP_CONFIG = getActionType("RECEIVE_GLOBAL_APP_CONFIG");
const FETCH_PAGE_CONFIG = getActionType("FETCH_PAGE_CONFIG");
const RECEIVE_PAGE_CONFIG = getActionType("RECEIVE_PAGE_CONFIG");

// Actions
function fetchGlobalAppConfig(fvId) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_GLOBAL_APP_CONFIG,
    });

    // Faking a request until API is set up.
    // Config is hardcoded and conditional by fvId in `getAppConfig`
    try {
      dispatch({
        type: RECEIVE_GLOBAL_APP_CONFIG,
        payload: getAppConfig(fvId),
      });
    } catch (e) {
      dispatch({
        type: RECEIVE_GLOBAL_APP_CONFIG,
        payload: {},
      });
    }
  };
}

function fetchPageConfig() {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_PAGE_CONFIG,
    });

    const state = getState();
    const routeKey = state.location.type;
    const navGroupKey = state.location.routesMap[routeKey].navGroup;

    // Faking a request until API is set up.
    // Config is hardcoded and conditional by fvId in `getPageConfig`
    try {
      const fvId = getActiveOrganization(state)?.fv_id;
      if (!fvId) {
        throw new Error("Missing fv_id");
      }

      dispatch({
        type: RECEIVE_PAGE_CONFIG,
        payload: {
          routeKey,
          navGroupKey,
          config: getPageConfig(fvId, routeKey, state.location.routesMap),
        },
      });
    } catch (e) {
      dispatch({
        type: RECEIVE_PAGE_CONFIG,
        payload: {
          routeKey,
          navGroupKey,
          config: {},
        },
      });
    }
  };
}

// Selectors
const getGlobalAppConfig = (state) => {
  return state[MOUNT_POINT].globalAppConfig;
};
const getGlobalAppConfigLoading = (state) => {
  return state[MOUNT_POINT].globalAppConfigLoading;
};
const getProductConfig = (state) => {
  const routeKey = state.location.type;
  const navGroupKey = state.location.routesMap[routeKey].navGroup;
  return state[MOUNT_POINT].pageConfigs[navGroupKey];
};
const getProductConfigIsLoading = (state) => {
  // The product config comes in the same request.
  // Using same loading variable for the page config.
  return state[MOUNT_POINT].isPageConfigLoading;
};
const getPageConfigForRoute = (state) => {
  const routeKey = state.location.type;
  return state[MOUNT_POINT].pageConfigs[routeKey];
};
const getIsPageConfigLoading = (state) => {
  return state[MOUNT_POINT].isPageConfigLoading;
};

// Reducer
const initialState = {
  globalAppConfig: {},
  globalAppConfigLoading: false,
  pageConfigs: {},
  isPageConfigLoading: false,
};

function configurationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GLOBAL_APP_CONFIG:
      return {
        ...state,
        globalAppConfigLoading: true,
        globalAppConfig: null,
      };
    case RECEIVE_GLOBAL_APP_CONFIG:
      return {
        ...state,
        globalAppConfigLoading: false,
        globalAppConfig: action.payload,
      };
    case FETCH_PAGE_CONFIG:
    case RECEIVE_PAGE_CONFIG:
      return {
        ...state,
        pageConfigs: pageConfigReducer(state.pageConfigs, action),
        isPageConfigLoading: action.type === FETCH_PAGE_CONFIG,
      };
    default:
      return state;
  }
}

function pageConfigReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_PAGE_CONFIG:
      return {
        ...state,
        [action.payload.routeKey]: action.payload.config.page,
        [action.payload.navGroupKey]: action.payload.config.product,
      };
    default:
      return state;
  }
}

const AppConfigurationState = {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    fetchGlobalAppConfig,
    fetchPageConfig,
  },
  selectors: {
    getGlobalAppConfig,
    getGlobalAppConfigLoading,
    getProductConfig,
    getProductConfigIsLoading,
    getPageConfigForRoute,
    getIsPageConfigLoading,
  },
  reducer: configurationsReducer,
};

export default AppConfigurationState;
