/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";

import { Button } from "components/atoms/Button.atom";
import {
  PhoneNumberInput,
  isPossiblePhoneNumber,
} from "components/molecules/PhoneInput.molecule";
import Colors from "styles/colors";

export const MobileTrackingModal = ({
  show,
  hide,
  shipment,
  startMobileTracking,
  stopMobileTracking,
  organization,
  toEnable,
}) => {
  const { t } = useTranslation("shipment-details");

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [noteValue, setNoteValue] = useState("");

  const isPhoneNumberValid = phoneNumber && isPossiblePhoneNumber(phoneNumber);

  const handleOnChangeNote = (event) => {
    setNoteValue(event.target.value);
  };

  const handleOnEnter = () => {
    setPhoneNumber(null);
    setNoteValue("");
  };

  const handleChangePhone = (value) => {
    setPhoneNumber(value);
  };

  let handleSubmitClick = () => {
    if (toEnable) {
      let formattedNumber = phoneNumber.replace(/-|\s/g, "");
      startMobileTracking(shipment, organization, formattedNumber, noteValue);
    } else {
      stopMobileTracking(shipment, organization);
    }
    hide();
  };

  return (
    <Modal
      show={show}
      style={{ ":width": "300px" }}
      onHide={() => hide()}
      onEnter={handleOnEnter}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={
          <div data-qa="title-modal">
            {toEnable
              ? t("Enable Mobile Tracking")
              : t("Disable Mobile Tracking")}
          </div>
        }
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <FormGroup css={{ marginBottom: toEnable ? "0.5rem" : "1rem" }}>
          {toEnable
            ? t(
                "Enter the driver's phone number to enable mobile tracking updates",
              )
            : t("Please confirm desire to disable mobile tracking.")}
        </FormGroup>
        {toEnable ? (
          <FormGroup css={{ marginBottom: "1rem" }}>
            <InputGroup>
              <PhoneNumberInput
                defaultCountry="US"
                countryCallingCodeEditable={true}
                value={phoneNumber}
                onChange={handleChangePhone}
                data-qa="enable-mobile-tracking-mobile-number"
                aria-describedby="mobile-number-help-text"
              />
            </InputGroup>
          </FormGroup>
        ) : null}

        <FormGroup css={{ marginBottom: "0.5rem" }}>{`${t(
          "Comments",
        )}:`}</FormGroup>
        <InputGroup style={{ width: "100%" }}>
          <FormControl
            as="textarea"
            placeholder={t("User may record comments here")}
            value={noteValue}
            onChange={handleOnChangeNote}
            style={{ resize: "none" }}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button variant="outline-secondary" onClick={() => hide()}>
          {t("Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={toEnable && !isPhoneNumberValid}
          onClick={handleSubmitClick}
        >
          {t("Submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

MobileTrackingModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  shipment: PropTypes.object.isRequired,
  startMobileTracking: PropTypes.func,
  stopMobileTracking: PropTypes.func,
  organization: PropTypes.object.isRequired,
  toEnable: PropTypes.bool,
};
