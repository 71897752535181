import { combineReducers } from "redux";
import axios from "axios";
import { createSelector } from "reselect";
import apiUrl, { documentationApiUrl } from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";

// Redux mount point
const STORE_MOUNT_POINT = "documentation";

// Fetch ducks
const contentNavDuck = buildFetchDuck("contentNav");
const contentDuck = buildFetchDuck("content");
const apiGroupDuck = buildFetchDuck("apiGroup");
const apiSampleDuck = buildFetchDuck("apiSample");
const tryApiDuck = buildFetchDuck("tryApi");

// URLs
const CONTENT_NAV_URL = apiUrl("/support/get_content_nav");
const CONTENT_URL = apiUrl("/support/get_content");
const API_GROUP_URL = apiUrl("/support/get_api_group");
const API_SAMPLE_URL = apiUrl("/support/get_sample");

// Actions
const EXECUTE_TRY_API = "documentation/EXECUTE_TRY_API";
const EXECUTE_TRY_API_SUCCEEDED = "documentation/EXECUTE_TRY_API_SUCCESS";
const EXECUTE_TRY_API_FAILED = "documentation/EXECUTE_TRY_API_FAILED";

// Action creators
function fetchContentNav() {
  return (dispatch) => {
    dispatch(contentNavDuck.clear());
    dispatch(contentNavDuck.fetch(`${CONTENT_NAV_URL}?file=carrierMenu.json`));
  };
}

function fetchContent(filename) {
  return (dispatch) => {
    dispatch(contentDuck.fetch(`${CONTENT_URL}?file=${filename}`));
  };
}

function fetchApiGroup(filename) {
  return (dispatch) => {
    dispatch(apiGroupDuck.fetch(`${API_GROUP_URL}?group=${filename}`));
  };
}

function fetchApiSample(filename) {
  return (dispatch) => {
    dispatch(apiSampleDuck.fetch(`${API_SAMPLE_URL}?api-sample=${filename}`));
  };
}

function pushDocumentView(fileName, fileType, baseUrl) {
  let encodedBaseUrl = null;
  if (baseUrl) {
    /*
    If we have a `baseUrl`, then we must prepend the S3 bucket URL to it so that the content can be loaded from the correct S3 bucket.
    */
    encodedBaseUrl = encodeURIComponent(documentationApiUrl(baseUrl));
  }

  let type = "DOCUMENTATION";
  if (fileName === "TryAPI" || fileType === "TRY-API") {
    type = "TRY_API";
  } else if (fileName === "API_GROUP" || fileType === "API-GROUP") {
    type = "API_GROUP";
  } else if (fileName === "API_SAMPLE" || fileType === "API-SAMPLE") {
    type = "API_SAMPLE";
  } else if (fileName === "API_LOGS" || fileType === "api-logs") {
    type = "API_LOGS";
  } else if (fileName === "MILESTONE_LOGS" || fileType === "milestone-logs") {
    type = "MILESTONE_LOGS";
  } else if (fileType === "DOCUMENTATION_URL") {
    type = "DOCUMENTATION_URL";
  }

  let payload = { filename: fileName, baseUrl: encodedBaseUrl };

  return (dispatch) => {
    dispatch({ type, payload });
  };
}

function requestToObject(req) {
  let obj = {};

  Object.values(req).forEach((val) => {
    obj[val.name] = val.value;
  });

  return obj;
}

/* Creating a new axios instance for Try an Api page
 * This is needed because there are interceptors in the default axios instance
 * that overwrites the authorization header parameter with a Bearer token
 */
const tryApiAxios = axios.create();

/**
 * Call the specified API in the Try an API page in documentation
 * @param {object} request - Contains the request body, headers and parameters
 * @param {string} method - The HTTP method to use
 * @param {string} path - The request endpoint without the base URL
 * @param {string} url - The base URL for the request
 * @param {object} auth - Contains `username` and `password` properties for Basic authorization
 */
function callTryApi(request, method, path, url, auth) {
  const { body, headers, parameters } = request;

  let fullUrl = ("https://" + url + path).replace(/([^:])(\/\/+)/g, "$1/");

  // Separate the different types of parameters.
  let qsParams = Object.values(parameters).filter(
    (param) => param.in !== "path",
  );
  let pathParams = Object.values(parameters).filter(
    (param) => param.in === "path",
  );

  let hdrs = requestToObject(headers);
  let params = requestToObject(qsParams);

  // Insert path params in URL.
  Object.values(pathParams).forEach((param) => {
    fullUrl = fullUrl.replace(`:${param.pathVariableName}`, param.value);
  });

  return (dispatch) => {
    dispatch({ type: EXECUTE_TRY_API });

    tryApiAxios({
      method: method,
      url: fullUrl,
      data: body,
      headers: hdrs,
      params: params,
      auth,
    })
      .then((response) => {
        dispatch({ type: EXECUTE_TRY_API_SUCCEEDED, response: response });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: EXECUTE_TRY_API_FAILED, error: error });
      });
  };
}

// Initial state
export const initialState = {
  isLoading: false,
  tryResponse: {},
};

// Reducer
function DocsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXECUTE_TRY_API:
      return {
        ...state,
        isLoading: true,
        tryResponse: {},
      };
    case EXECUTE_TRY_API_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        tryResponse: action.response,
      };
    case EXECUTE_TRY_API_FAILED:
      return {
        ...state,
        isLoading: false,
        tryResponse: action.error.response,
      };
    default:
      return state;
  }
}

// Selectors
const getContentNav = (state) => {
  return state[STORE_MOUNT_POINT].contentNav.data;
};
const getContent = (state) => {
  return state[STORE_MOUNT_POINT].content.data;
};
const getContentStatus = (state) => {
  return state[STORE_MOUNT_POINT].content.isLoading;
};
const getApiGroup = (state) => {
  return state[STORE_MOUNT_POINT].apiGroup.data;
};
const getApiSample = (state) => {
  return state[STORE_MOUNT_POINT].apiSample.data;
};
const getApiSampleStatus = (state) => {
  return state[STORE_MOUNT_POINT].apiSample.isLoading;
};
const getTryApi = (state) => {
  return state[STORE_MOUNT_POINT].tryApi.data;
};
const getTryResponse = (state) => {
  return state[STORE_MOUNT_POINT].docs.tryResponse;
};
const getTryStatus = (state) => {
  return state[STORE_MOUNT_POINT].docs.isLoading;
};
const getCategory = (state) => state[STORE_MOUNT_POINT].category;
const getCategorySlug = createSelector(getCategory, (category) => {
  const cat = category.toLowerCase();
  if (cat.startsWith("apiutils")) {
    return "UTILS";
  }
  if (cat.startsWith("api")) {
    return "API";
  }
  if (cat.startsWith("user")) {
    return "USER";
  }
  if (cat.startsWith("edi")) {
    return "REF";
  }
  return "DOCUMENTATION";
});

// content category reducer

const contentCategoryReducer = (state = "", action = {}) => {
  switch (action.type) {
    case "DOCUMENTATION":
      return action.payload.filename;
    default:
      return state;
  }
};

const DocumentationState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchContentNav,
    fetchContent,
    fetchApiGroup,
    fetchApiSample,
    pushDocumentView,
    callTryApi,
  },
  selectors: {
    getContentNav,
    getContent,
    getContentStatus,
    getApiGroup,
    getApiSample,
    getApiSampleStatus,
    getTryApi,
    getTryResponse,
    getTryStatus,
    getCategory,
    getCategorySlug,
  },
  reducer: combineReducers({
    contentNav: contentNavDuck.reducer,
    content: contentDuck.reducer,
    apiGroup: apiGroupDuck.reducer,
    apiSample: apiSampleDuck.reducer,
    tryApi: tryApiDuck.reducer,
    docs: DocsReducer,
    category: contentCategoryReducer,
  }),
};

export default DocumentationState;
