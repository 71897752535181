import { connect } from "react-redux";
import DriveAwaySearch from "./DriveAway.Search.page";
import DriveAwaySearchBarState from "pages/driveaway/redux/DriveAwaySearchBarState.js";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import RequestDriveAwayState from "../redux/RequestDriveAway";
import DriveAwayReviewRequestState from "../redux/DriveAwayReviewRequest";
import DriveAwayExportPickUpFormState from "../redux/DriveAwayExportPickUpFormState";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import DriveAwayBulkActionState from "../redux/DriveAwayBulkActionModalState";
import DriveAwayStatusHistoryState from "../redux/DriveAwayStatusHistoryState";
import {ProfileState} from "modules/profile/ProfileState";

function mapStateToProps(state) {
  return {
    savedSearch:
      DriveAwaySearchBarState.selectors.getSelectedSavedSearch(state),
    driveAwaySearchResults:
      DriveAwaySearchBarState.selectors.getSearchResults(state),
    showFilters: DriveAwaySearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: DriveAwaySearchBarState.selectors.getIsLoading(state),
    loadingError: DriveAwaySearchBarState.selectors.getLoadingError(state),
    isLoadingError: DriveAwaySearchBarState.selectors.getIsLoadingError(state),
    sortColumn: DriveAwaySearchBarState.selectors.getSortColumn(state),
    reverseSort: DriveAwaySearchBarState.selectors.getReverseSort(state),
    page: DriveAwaySearchBarState.selectors.getPage(state),
    pageSize: DriveAwaySearchBarState.selectors.getPageSize(state),
    totalPages:
      DriveAwaySearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      DriveAwaySearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      DriveAwaySearchBarState.selectors.getTotalCountForSearchIsLoading(state),
    exportIdentifier:
      DriveAwaySearchBarState.selectors.getExportIdentifier(state),
    exportName: DriveAwaySearchBarState.selectors.getExportName(state),
    isExporting: DriveAwaySearchBarState.selectors.getIsExporting(state),
    exportFailed: DriveAwaySearchBarState.selectors.getExportFailed(state),
    isDealerOrg: true,
    requestDriveAwayResult:
      RequestDriveAwayState.selectors.getRequestDriveAwayStatus(state),
    driveAwayReviewRequestResult:
      DriveAwayReviewRequestState.selectors.getRequestDriveAwayStatus(state),
    driveAwayDenyReasons:
      DriveAwayReviewRequestState.selectors.getDenyReasons(state),
    isDenyReasonsLoading:
      DriveAwayReviewRequestState.selectors.isDenyReasonsLoading(state),
    solutionId: getSolutionId(state),
    showReviewRequestModal:
      DriveAwayReviewRequestState.selectors.getShowReviewRequestModal(state),
    showDenyRadioButton:
      DriveAwayReviewRequestState.selectors.getShowDenyRadioButton(state),
    isDenyReasonUpdate:
      DriveAwayReviewRequestState.selectors.getIsDenyReasonUpdate(state),
    deniedReason: DriveAwayReviewRequestState.selectors.getDeniedReason(state),
    deniedComment:
      DriveAwayReviewRequestState.selectors.getDeniedComment(state),
    isModalLoading:
      DriveAwayReviewRequestState.selectors.getModalLoading(state),
    prefilledTimeWindows:
      DriveAwayReviewRequestState.selectors.getPrefilledTimeWindows(state),
    pickUpWindowTzAbbr:
      DriveAwayReviewRequestState.selectors.getPickUpWindowTzAbbr(state),
    pickUpWindowTz:
      DriveAwayReviewRequestState.selectors.getPickUpWindowTz(state),
    selectedDaId: DriveAwayReviewRequestState.selectors.getSelectedDaId(state),
    exportPDFIdentifier:
      DriveAwayExportPickUpFormState.selectors.getExportIdentifier(state),
    exportPDFName:
      DriveAwayExportPickUpFormState.selectors.getExportName(state),
    isExportingPDF:
      DriveAwayExportPickUpFormState.selectors.getIsExporting(state),
    activeOrganization: getActiveOrganization(state),
    bulkActionStatus:
      DriveAwayBulkActionState.selectors.getBulkActionStatus(state),
    ddaComments: DriveAwaySearchBarState.selectors.getDdaComments(state),
    errorFetchingDdaComments:
      DriveAwaySearchBarState.selectors.getErrorFetchingDdaComments(state),
    statusHistory:
      DriveAwayStatusHistoryState.selectors.getStatusHistory(state),
    statusHistoryFetchStatus:
      DriveAwayStatusHistoryState.selectors.getStatusHistoryFetchStatus(state),
    userPreference: ProfileState.selectors.getUserPreferences(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleShowFilters: (showFilters) =>
      dispatch(
        DriveAwaySearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    pushCarrierViewDetailsPage: (internalId) => {
      dispatch({
        type: "CARRIERVIEW_DETAILS",
        payload: { internal_id: internalId },
      });
    },
    pushVinViewDetailsPage: (internalId) => {
      dispatch({
        type: "VINVIEW_DETAILS",
        payload: { internal_id: internalId },
      });
    },
    pushFinishedVehicleDetailsView: (entityId) => {
      dispatch({
        type: "VIN_DETAILS",
        payload: { entity_id: entityId },
      });
    },
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        DriveAwaySearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        DriveAwaySearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: (solutionId) => {
      dispatch(DriveAwaySearchBarState.actionCreators.exportSearch(solutionId));
    },
    clearExportErrors: () => {
      dispatch(DriveAwaySearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: (solutionId) => {
      dispatch(DriveAwaySearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(
        DriveAwaySearchBarState.actionCreators.searchEntities(solutionId),
      );
    },
    resetExport: () => {
      dispatch(DriveAwaySearchBarState.actionCreators.resetExport());
    },
    requestDriveAway: (internalId, phoneNumber, phoneNumberExt, email) => {
      dispatch(
        RequestDriveAwayState.actionCreators.requestDriveAway(
          internalId,
          phoneNumber,
          phoneNumberExt,
          email,
        ),
      );
    },
    cancelDriveAway: (internalId, requestBody) => {
      dispatch(
        RequestDriveAwayState.actionCreators.cancelDriveAway(
          internalId,
          requestBody,
        ),
      );
    },
    confirmPickUpRequest: (internalId) => {
      dispatch(
        RequestDriveAwayState.actionCreators.confirmPickUpRequest(internalId),
      );
    },
    resetDriveAwayRequestStatus: () => {
      dispatch(
        RequestDriveAwayState.actionCreators.resetDriveAwayRequestStatus(),
      );
    },
    driveAwayReviewRequest: (requestBody) => {
      dispatch(
        DriveAwayReviewRequestState.actionCreators.submitDriveAwayRequestReview(
          requestBody,
        ),
      );
    },
    resetDriveAwayRequestReviewStatus: () => {
      dispatch(
        DriveAwayReviewRequestState.actionCreators.resetDriveAwayRequestReviewStatus(),
      );
    },
    searchEntities: () => {
      dispatch(DriveAwaySearchBarState.actionCreators.searchEntities());
    },
    fetchDenyReasons: (solutionId) => {
      dispatch(
        DriveAwayReviewRequestState.actionCreators.fetchDenyReasons(solutionId),
      );
    },
    setShowReviewRequestModal: (
      showModal,
      daId,
      pickUpWindowTzAbbr,
      pickUpWindowTz,
      prefilledTimeWindows,
      setShowReviewRequestModal,
      isDenyReasonUpdateModal,
      deniedReason,
    ) => {
      dispatch(
        DriveAwayReviewRequestState.actionCreators.setShowReviewRequestModal(
          showModal,
          daId,
          pickUpWindowTzAbbr,
          pickUpWindowTz,
          prefilledTimeWindows,
          setShowReviewRequestModal,
          isDenyReasonUpdateModal,
          deniedReason,
        ),
      );
    },
    exportPDFEntities: (approvalId, solutionId, locale) => {
      dispatch(
        DriveAwayExportPickUpFormState.actionCreators.exportAsyncPickUpForm(
          approvalId,
          solutionId,
          locale,
        ),
      );
    },
    resetPDFExports: () =>
      dispatch(DriveAwayExportPickUpFormState.actionCreators.resetExport()),
    fetchComments: (entityId, solutionId) =>
      dispatch(
        DriveAwaySearchBarState.actionCreators.fetchComments(
          entityId,
          solutionId,
        ),
      ),
    fetchStatusHistory: (entityId, solutionId) => {
      dispatch(
        DriveAwayStatusHistoryState.actionCreators.fetchStatusHistory(
          entityId,
          solutionId,
        ),
      );
    },
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DriveAwaySearch);
