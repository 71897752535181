import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { createSelector } from "reselect";
import apiUrl from "api-url";
import ApplicationConfig from "application-config";
import ContainerTrackingSearchBarState from "../redux/ContainerTrackingSearchBarState";

const STORE_MOUNT_POINT = "containerTrackingDetailsWidget";

//URLs
const APPLICATION_BASE_URL = apiUrl("/containertracking/api");
const containerDetailsUrl = (id) =>
  APPLICATION_BASE_URL + `/reuse-trip-container-details/${id}`;

const containerPositionUpdatesUrl = (id) =>
  APPLICATION_BASE_URL + `/${id}/position-updates`;

const containerActiveExceptionsApiUrl = (id) =>
  apiUrl(`/containertracking/api/reuse-trip-container/${id}/exceptions`);

const containerHistoryApiUrl = (id) =>
  APPLICATION_BASE_URL + `/containerhistory/${id}`;

const CONTAINER_IMAGE_BASE_URL = ApplicationConfig.isEnvironment(
  "proda",
  "prod",
)
  ? "https://prod-b-fv-container-tracking-media.s3.us-east-1.amazonaws.com"
  : "https://fv-container-tracking-media.s3.amazonaws.com/FORD_FV";

const watchContainerUrl = (id) => APPLICATION_BASE_URL + `/watch/${id}`;

const SHIPMENTS_BASE_URL = apiUrl("/shipping-ng");

const shipmentDetailsUrl = ({ shipmentID, scac } = {}) =>
  SHIPMENTS_BASE_URL +
  `/carriers/${scac}/shipments/${shipmentID}?format=DETAIL`;

//const MULTIMODAL_DETAILS_URL = SHIPMENTS_BASE_URL + "/trip/";

//Action Types
const REQUEST_CONTAINER_DETAILS = "REQUEST_CONTAINER_DETAILS";
const RECEIVE_CONTAINER_DETAILS = "RECEIVE_CONTAINER_DETAILS";
const RECEIVE_CONTAINER_DETAILS_ERROR = "RECEIVE_CONTAINER_DETAILS_ERROR";
const REQUEST_CONTAINER_ACTIVE_EXCEPTIONS =
  "REQUEST_CONTAINER_ACTIVE_EXCEPTIONS";
const RECEIVE_CONTAINER_ACTIVE_EXCEPTIONS =
  "RECEIVE_CONTAINER_ACTIVE_EXCEPTIONS";
const RECEIVE_CONTAINER_MEDIA = "RECEIVE_CONTAINER_MEDIA";
const CLEAR_CONTAINER_MEDIA = "CLEAR_CONTAINER_MEDIA";
const REQUEST_CONTAINER_POSITION_UPDATES = "REQUEST_CONTAINER_POSITION_UPDATES";
const REQUEST_CONTAINER_LOCATION_DETAILS = "REQUEST_CONTAINER_LOCATION_DETAILS";
const RECEIVE_CONTAINER_OTHER_ALL = "RECEIVE_CONTAINER_OTHER_ALL";
const REQUEST_CONTAINER_SUPPLIER_LOCATION_DETAILS =
  "REQUEST_CONTAINER_SUPPLIER_LOCATION_DETAILS";
const RETRIEVE_CONTAINER_SUPPLIER_LOCATION_DETAILS =
  "RETRIEVE_CONTAINER_SUPPLIER_LOCATION_DETAILS";
const CLEAR_CONTAINER_DETAILS = "CLEAR_CONTAINER_DETAILS";
const REQUEST_CONTAINER_HISTORY = "REQUEST_CONTAINER_HISTORY";
const RETRIEVE_CONTAINER_HISTORY = "RETRIEVE_CONTAINER_HISTORY";
const CONTAINER_UPDATE_SUCCESS = "CONTAINER_UPDATE_SUCCESS";
const FETCH_CONTAINER_COMMENTS = `${STORE_MOUNT_POINT}/FETCH_CONTAINER_COMMENTS`;
const FETCH_CONTAINER_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/FETCH_CONTAINER_COMMENTS_FAILED`;
const RECEIVE_CONTAINER_COMMENTS = `${STORE_MOUNT_POINT}/RECEIVE_CONTAINER_COMMENTS`;
const SUBMIT_NEW_COMMENT = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT`;
const RECEIVE_NEW_COMMENT = `${STORE_MOUNT_POINT}/RECEIVE_NEW_COMMENT`;
const SUBMIT_NEW_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT_FAILED`;
const CANCEL_NEW_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_NEW_COMMENT`;
const SET_IS_UPDATING_COMMENT = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT`;
const SET_IS_UPDATING_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT_FAILED`;
const CANCEL_UPDATE_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_UPDATE_COMMENT`;
const SUBMIT_BATCH_COMMENTS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS`;
const SUBMIT_BATCH_COMMENTS_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_SUCCESS`;
const SUBMIT_BATCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_FAILED`;
const SUBMIT_BATCH_COMMENTS_RESET = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_RESET`;
const FETCH_MULTIMODAL_DETAILS = "shipments/FETCH_MULTIMODAL_DETAILS";
const FETCH_SHIPMENT_DETAILS = "shipment/FETCH_SHIPMENT_DETAILS";
const CLEAR_SHIPMENT_DETAILS = "shipment/CLEAR_SHIPMENT_DETAILS";
const RECEIVE_SHIPMENT_DETAILS = "shipment/RECEIVE_SHIPMENT_DETAILS";
const RECEIVE_MULTIMODAL_DETAILS = "shipments/RECEIVE_MULTIMODAL_DETAILS";
const FETCH_CHILD_SHIPMENT_DETAILS = "shipments/FETCH_CHILD_SHIPMENT_DETAILS";
const RECEIVE_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_CHILD_SHIPMENT_DETAILS";
const RECEIVE_ALL_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_ALL_CHILD_SHIPMENT_DETAILS";
const START_BOTH_DETAILS_LOADING = "START_BOTH_DETAILS_LOADING";
const STOP_BOTH_DETAILS_LOADING = "STOP_BOTH_DETAILS_LOADING";

//Action Creators

//Container tracking comments feed actions

const fetchComments = (containerId, pageNumber, pageSize) => {
  let url = `${APPLICATION_BASE_URL}/${containerId}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_CONTAINER_COMMENTS,
      });
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_CONTAINER_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_CONTAINER_COMMENTS_FAILED,
        });
      });
  };
};

function addComment(containerId, data) {
  let url = `${APPLICATION_BASE_URL}/${containerId}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
}

function cancelAddComment(fakeCommentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
}

function addBatchComments(data, isCsvFormat, solutionId) {
  let url = `${APPLICATION_BASE_URL}/solution/${solutionId}/comment/batch`;
  const { shareableOrg, shipperOrgId } = data;
  let params = {};
  if (isCsvFormat) {
    params.sharedWith = shareableOrg ? `[${shareableOrg}]` : "[]";
    if (shipperOrgId) {
      params.shipperOrgId = shipperOrgId;
    }
  }
  const headers = {
    Accept: isCsvFormat ? "text/csv" : "application/json",
  };
  const postData = isCsvFormat ? data.csv : data;

  return (dispatch) => {
    dispatch({
      type: SUBMIT_BATCH_COMMENTS,
      payload: { data },
    });
    return axios
      .post(url, postData, { headers, params })
      .then((response) => {
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_SUCCESS,
          payload: { data },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_FAILED,
          payload: { data },
        });
      });
  };
}

function clearBatchComments() {
  return (dispatch) => {
    dispatch({ type: SUBMIT_BATCH_COMMENTS_RESET });
  };
}

function updateComment(containerId, commentId, updatedData) {
  let url = `${APPLICATION_BASE_URL}/${containerId}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };

    return axios
      .patch(url, requestData)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
}

function cancelUpdateComment(commentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId },
    });
  };
}

function markCommentsRead(containerId, datetime) {
  let url = `${APPLICATION_BASE_URL}/${containerId}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (dispatch) => {
    return axios
      .post(url, data)
      .then((response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
const fetchContainerDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_CONTAINER_DETAILS });
    dispatch({ type: REQUEST_CONTAINER_LOCATION_DETAILS });
    dispatch({ type: REQUEST_CONTAINER_POSITION_UPDATES });
    dispatch({ type: START_BOTH_DETAILS_LOADING });
    let details = {};
    // Fetch and load details first
    try {
      details = await axios.get(containerDetailsUrl(id));
      dispatch({
        type: RECEIVE_CONTAINER_DETAILS,
        payload: details.data,
      });
      if (!details.data?.status || details.data?.status !== "In Transit") {
        dispatch({ type: STOP_BOTH_DETAILS_LOADING });
      }
    } catch (error) {
      dispatch({ type: RECEIVE_CONTAINER_DETAILS_ERROR, payload: { error } });
      console.log("ERROR retreiving container details");
      console.log(error);
    }
    // Fetch positon updates & location details
    try {
      const promisesArray = [];
      if (details.data && details.data.id) {
        promisesArray.push(
          axios.get(containerPositionUpdatesUrl(details.data.id)),
        );
      } else {
        promisesArray.push(Promise.reject());
      }
      if (details.data && details.data.alternateLocationCode) {
        promisesArray.push(
          axios.get(
            `${apiUrl("/location/locations")}?code=${
              details.data.alternateLocationCode
            }`,
          ),
        );
      } else {
        promisesArray.push(Promise.reject());
      }
      Promise.all([
        promisesArray[0].catch(() => {
          return { data: [] };
        }),
        promisesArray[1].catch(() => {
          return { data: [] };
        }),
      ]).then(([positionUpdatesRep, locationDetailsResp]) => {
        dispatch({
          type: RECEIVE_CONTAINER_OTHER_ALL,
          payload: {
            locationDetails:
              locationDetailsResp.data &&
              locationDetailsResp.data.length &&
              locationDetailsResp.data[0],
            positionUpdates: positionUpdatesRep.data,
          },
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const fetchContainerSupplierLocationDetails = (code) => {
  const url = `${apiUrl("/location/locations")}?code=${code}`;
  return async (dispatch) => {
    dispatch({ type: REQUEST_CONTAINER_SUPPLIER_LOCATION_DETAILS });
    try {
      const response = await axios.get(url);
      dispatch({
        type: RETRIEVE_CONTAINER_SUPPLIER_LOCATION_DETAILS,
        payload: response.data?.[0] ?? {},
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchContainerHistory = (id) => {
  const url = containerHistoryApiUrl(id);
  return async (dispatch) => {
    dispatch({ type: REQUEST_CONTAINER_HISTORY });
    try {
      const response = await axios.get(url);

      dispatch({
        type: RETRIEVE_CONTAINER_HISTORY,
        payload: response.data?.data ?? [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchContainerActiveExceptions = (id) => {
  const url = containerActiveExceptionsApiUrl(id);
  const params = { activeOnly: true };
  return async (dispatch) => {
    dispatch({ type: REQUEST_CONTAINER_ACTIVE_EXCEPTIONS });
    try {
      const response = await axios.get(url, { params });
      dispatch({
        type: RECEIVE_CONTAINER_ACTIVE_EXCEPTIONS,
        payload: response.data ?? [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const clearContainerDetails = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_CONTAINER_DETAILS });
  };
};

function clearContainerMedia() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_CONTAINER_MEDIA,
    });
}

function fetchContainerMedia(rackCode) {
  if (!rackCode) {
    return clearContainerMedia();
  }

  return (dispatch) => {
    dispatch({
      type: RECEIVE_CONTAINER_MEDIA,
      payload: {
        containerMedia: `${CONTAINER_IMAGE_BASE_URL}/${rackCode}.jpg`,
      },
    });
  };
}

const watchContainerActions = (id, watch = true, solutionId) => {
  const url = watchContainerUrl(id);
  return async (dispatch) => {
    try {
      watch ? await axios.post(url) : await axios.delete(url);
      dispatch({ type: CONTAINER_UPDATE_SUCCESS });
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.searchEntities(
          solutionId,
          false,
          true,
        ),
      );
    } catch (err) {
      console.log(
        "Error in ContainerTrackingDetailsWidgetState: watchContainerActions",
      );
      throw new Error(err);
    }
  };
};

const fetchShipmentDetails = (shipmentID, scac) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SHIPMENT_DETAILS,
    });

    dispatch({
      type: FETCH_SHIPMENT_DETAILS,
    });

    let url = shipmentDetailsUrl({
      shipmentID: shipmentID,
      scac: scac,
    });

    axios
      .get(url)
      .then((response) => {
        const shipmentDetails = response?.data;
        if (shipmentDetails?.shipment_details?.is_multileg) {
          return null;
        }

        dispatch({
          type: RECEIVE_SHIPMENT_DETAILS,
          data: shipmentDetails,
        });
        dispatch({ type: STOP_BOTH_DETAILS_LOADING });
      })
      .catch((err) => console.log(err));

    // multimodal (Leaving this for now as we may need it)
    // dispatch({
    //   type: FETCH_MULTIMODAL_DETAILS
    // });

    // url = `${MULTIMODAL_DETAILS_URL}${shipmentID}`;
    // Promise.all([axios.get(url)])
    //   .then(responses => {
    //     let data = responses[0].data;
    //     dispatch({
    //       type: RECEIVE_MULTIMODAL_DETAILS,
    //       data: data
    //     });

    //     // fetch child shipments
    //     dispatch(fetchChildShipmentDetails(data));
    //   })
    //   .catch(err => console.log(err));
  };
};

// const fetchChildShipmentDetails = parentShipment => {
//   console.log("fetchChildShipmentDetails", parentShipment);
//   return dispatch => {
//     let requests = [];
//     let shipmentIdHash = {};

//     parentShipment.child_shipments.forEach(childShipment => {
//       const url = shipmentDetailsUrl({
//         shipmentID: childShipment.shipment_id
//       });
//       requests.push(axios.get(url));
//       shipmentIdHash[url] = childShipment.shipment_id;
//     });

//     dispatch({
//       type: FETCH_CHILD_SHIPMENT_DETAILS
//     });

//     Promise.all(requests)
//       .then(responses => {
//         responses.forEach(resp => {
//           const childShipmentId = shipmentIdHash[resp.config.url];
//           dispatch({
//             type: RECEIVE_CHILD_SHIPMENT_DETAILS,
//             id: childShipmentId,
//             data: resp.data
//           });
//         });
//       })
//       .catch(err => {
//         console.error(err);
//       })
//       .finally(() => {
//         dispatch({
//           type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS
//         });
//       });
//   };
// };

//Selectors
const getContainerDetails = (state) =>
  state[STORE_MOUNT_POINT].containerDetails;

const getContainerDetailsError = (state) =>
  state[STORE_MOUNT_POINT].containerDetailsError;

const getContainerDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isContainerDetailsLoading;

const getContainerMedia = (state) => state[STORE_MOUNT_POINT].containerMedia;

const translatePositionUpdates = (item) => {
  // only recalculate when containerPositionUpdates changes
  return {
    db_time: item.receivedTime,
    city: item.locationCity,
    country: item.locationCountry,
    latitude: item.latitude,
    locationName: item.locationName,
    locationCode: item.locationCode,
    longitude: item.longitude,
    state: item.locationState,
    time: item.ts,
  };
};

const getContainerPositionUpdates = createSelector(
  [(state) => state[STORE_MOUNT_POINT].containerPositionUpdates],
  (containerPositionUpdates) => {
    if (containerPositionUpdates) {
      return _.orderBy(containerPositionUpdates, ["ts"], ["desc"]).map(
        translatePositionUpdates,
      );
    }
    return [];
  },
);

const getLocationDetails = (state) =>
  state[STORE_MOUNT_POINT].containerLocationDetails;

const getLocationDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isContainerLocationDetailsLoading;

const getSupplierLocationDetails = (state) =>
  state[STORE_MOUNT_POINT].containerSupplierLocationDetails;

const getSupplierLocationDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isContainerSupplierLocationDetailsLoading;

const getContainerHistory = (state) =>
  state[STORE_MOUNT_POINT].containerHistory;

const getCTActiveExceptions = (state) =>
  state[STORE_MOUNT_POINT].ctActiveExceptions;

const getShipmentDetails = (state) => {
  return state[STORE_MOUNT_POINT].shipmentDetails ?? {};
};

const getChildShipmentDetails = (state) => {
  return state[STORE_MOUNT_POINT].childShipmentDetails ?? {};
};

const getShipmentDetailsIsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isShipmentDetailsLoading;

const getBothDetailsLoadingFlag = (state) => {
  return state[STORE_MOUNT_POINT].areBothDetailsLoading;
};

const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments;
const getComments = (state) => state[STORE_MOUNT_POINT].comments || [];

const getIsBatchCommentInProgress = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentInProgress;
const getIsBatchCommentSuccessful = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentSuccessful;
const getIsBatchCommentFailed = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentFailed;

//Intial State
const initialState = {
  containerDetails: {},
  containerDetailsError: {},
  isContainerDetailsLoading: false,
  containerMedia: null,
  isContainerPositionUpdatesLoading: false,
  containerPositionUpdates: [],
  isContainerLocationDetailsLoading: false,
  containerLocationDetails: {},
  isContainerSupplierLocationDetailsLoading: false,
  containerSupplierLocationDetails: {},
  isContainerHistoryLoading: false,
  containerHistory: [],
  ctActiveExceptions: [],
  shipmentDetails: {},
  childShipmentDetails: {},
  isShipmentDetailsLoading: false,
  areBothDetailsLoading: false,
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  isBatchCommentInProgress: false,
  isBatchCommentSuccessful: false,
  isBatchCommentFailed: false,
};

//Reducer
const ContainerTrackingDetailsWidgetReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_CONTAINER_DETAILS:
      return {
        ...state,
        isContainerDetailsLoading: true,
      };

    case RECEIVE_CONTAINER_DETAILS:
      return {
        ...state,
        containerDetails: action.payload,
        isContainerDetailsLoading: false,
      };

    case RECEIVE_CONTAINER_DETAILS_ERROR:
      return {
        ...state,
        containerDetails: null,
        isContainerDetailsLoading: false,
        containerDetailsError: action.payload.error,
      };

    case RECEIVE_CONTAINER_MEDIA:
      return {
        ...state,
        containerMedia: action.payload.containerMedia,
      };

    case CLEAR_CONTAINER_MEDIA:
      return {
        ...state,
        containerMedia: null,
      };

    case REQUEST_CONTAINER_SUPPLIER_LOCATION_DETAILS:
      return {
        ...state,
        isContainerSupplierLocationDetailsLoading: true,
      };

    case RETRIEVE_CONTAINER_SUPPLIER_LOCATION_DETAILS:
      return {
        ...state,
        containerSupplierLocationDetails: action.payload,
        isContainerSupplierLocationDetailsLoading: false,
      };

    case REQUEST_CONTAINER_LOCATION_DETAILS:
      return {
        ...state,
        isContainerLocationDetailsLoading: true,
      };

    case REQUEST_CONTAINER_POSITION_UPDATES:
      return {
        ...state,
        isContainerPositionUpdatesLoading: true,
      };

    case RECEIVE_CONTAINER_OTHER_ALL:
      return {
        ...state,
        containerLocationDetails: action.payload.locationDetails ?? {},
        containerPositionUpdates: action.payload.positionUpdates ?? [],
        isContainerPositionUpdatesLoading: false,
        isContainerLocationDetailsLoading: false,
      };

    case REQUEST_CONTAINER_HISTORY:
      return {
        ...state,
        isContainerHistoryLoading: true,
      };

    case RETRIEVE_CONTAINER_HISTORY:
      return {
        ...state,
        containerHistory: action.payload,
      };

    case REQUEST_CONTAINER_ACTIVE_EXCEPTIONS:
      return {
        ...state,
        isContainerDetailsLoading: true,
      };

    case RECEIVE_CONTAINER_ACTIVE_EXCEPTIONS:
      return {
        ...state,
        ctActiveExceptions: action.payload,
        isContainerDetailsLoading: false,
      };

    case CLEAR_CONTAINER_DETAILS:
      return { ...initialState };

    case CLEAR_SHIPMENT_DETAILS:
      return {
        ...state,
        shipmentDetails: {},
      };

    case FETCH_SHIPMENT_DETAILS:
    case FETCH_MULTIMODAL_DETAILS:
    case FETCH_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isShipmentDetailsLoading: true,
      };

    case RECEIVE_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isShipmentDetailsLoading: true,
        childShipmentDetails: {
          ...state.childShipmentDetails,
          [action.id]: action.data,
        },
      };

    case RECEIVE_ALL_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isShipmentDetailsLoading: false,
      };

    case RECEIVE_SHIPMENT_DETAILS:
    case RECEIVE_MULTIMODAL_DETAILS:
      return {
        ...state,
        shipmentDetails: action.data,
        isShipmentDetailsLoading: false,
      };

    case START_BOTH_DETAILS_LOADING:
      return {
        ...state,
        areBothDetailsLoading: true,
      };

    case STOP_BOTH_DETAILS_LOADING:
      return {
        ...state,
        areBothDetailsLoading: false,
      };

    case FETCH_CONTAINER_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_CONTAINER_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_CONTAINER_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.union(state.comments.data, action.payload.comments.data),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let commentList = state.comments.data ? [...state.comments.data] : [];
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        commentList = commentList.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: {
          ...state.comments,
          data: commentList,
        },
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let commentList = state.comments.data ? [...state.comments.data] : [];

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };

    case SUBMIT_BATCH_COMMENTS:
      return {
        ...state,
        isBatchCommentInProgress: true,
      };

    case SUBMIT_BATCH_COMMENTS_SUCCESS:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: true,
      };

    case SUBMIT_BATCH_COMMENTS_FAILED:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentFailed: true,
      };

    case SUBMIT_BATCH_COMMENTS_RESET:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: false,
        isBatchCommentFailed: false,
      };

    default:
      return state;
  }
};

//interface
export const ContainerTrackingDetailsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchShipmentDetails,
    clearContainerMedia,
    fetchContainerDetails,
    fetchContainerActiveExceptions,
    fetchContainerMedia,
    fetchContainerSupplierLocationDetails,
    fetchContainerHistory,
    clearContainerDetails,
    watchContainerActions,
    fetchComments,
    addComment,
    addBatchComments,
    clearBatchComments,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
  },
  selectors: {
    getContainerDetails,
    getContainerDetailsError,
    getContainerDetailsLoadingFlag,
    getContainerMedia,
    getContainerPositionUpdates,
    getChildShipmentDetails,
    getLocationDetailsLoadingFlag,
    getLocationDetails,
    getSupplierLocationDetails,
    getSupplierLocationDetailsLoadingFlag,
    getContainerHistory,
    getCTActiveExceptions,
    getShipmentDetails,
    getShipmentDetailsIsLoadingFlag,
    getBothDetailsLoadingFlag,
    getIsFetchingComments,
    getComments,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
  },
  reducer: ContainerTrackingDetailsWidgetReducer,
};
