import axios from "axios";
import apiUrl from "../../../api-url";
import buildFetchDuck from "../../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../../vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "reportFoldersModal";

const createFolderModalDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "CREATE_REPORT_FOLDER",
);
const editFolderModalDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "EDIT_FOLDER_REPORT",
);

const moveFolderModalDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "MOVE_FOLDER_REPORT",
);

const deleteFolderModalDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "DELETE_FOLDER_REPORT",
);

const URL_POWERBI_REPORT_DIRECTORY = apiUrl("/powerbi/directory");

const URL_POWERBI_REPORT = apiUrl("/powerbi/report");

const getReportsUnderFolderUrl = (dirID) => {
  let url = `${URL_POWERBI_REPORT_DIRECTORY}`;
  if (dirID.directory) {
    url += `/${dirID.directory.directoryId}`;
  }
  return url;
};

const getCategoryAndFolderUrl = (info, reportType) => {
  let url = URL_POWERBI_REPORT_DIRECTORY;
  const level = info;
  if (level === 0) {
    return (url += `/?level=${level}`);
  } else if (level === 1 && reportType) {
    return (url += `/?level=${level}&type=${reportType}`);
  }
};

const FETCH_CATEGORIES = "FETCH_CATEGORIES";
const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";

const FETCH_FOLDERS = "FETCH_FOLDERS";
const RECEIVE_FOLDERS = "RECEIVE_FOLDERS";

function createFolderModal(reportType, name, description) {
  return (dispatch) => {
    return dispatch(
      createFolderModalDuck.fetch(URL_POWERBI_REPORT_DIRECTORY, {
        method: "post",
        data: {
          name,
          type: reportType,
          description,
        },
      }),
    );
  };
}

function editFolderModal(report, reportType, name, description) {
  let url = getReportsUnderFolderUrl(report);

  return (dispatch) =>
    dispatch(
      editFolderModalDuck.fetch(url, {
        method: "patch",
        data: {
          name,
          type: reportType,
          description,
        },
      }),
    );
}

function fetchCategory() {
  return (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES });
    const level = 0;
    const url = getCategoryAndFolderUrl(level);

    axios
      // .get("./dataLevel0.json")
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_CATEGORIES,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          dispatch({
            type: RECEIVE_CATEGORIES,
            data: [],
          });
        }
      });
  };
}

function fetchFolder(reportType) {
  return (dispatch) => {
    dispatch({ type: FETCH_FOLDERS });
    const level = 1;
    const url = getCategoryAndFolderUrl(level, reportType);
    axios
      // .get("dataDirectoryLevel1.json")
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_FOLDERS,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          dispatch({
            type: RECEIVE_FOLDERS,
            data: [],
          });
        }
      });
  };
}

function moveFolderReportModal(reportId, filterSetId, payload) {
  let url;
  if (filterSetId) {
    url = `${URL_POWERBI_REPORT}/${reportId}/${filterSetId}`;
  } else {
    url = `${URL_POWERBI_REPORT}/${reportId}`;
  }
  return (dispatch) =>
    dispatch(
      moveFolderModalDuck.fetch(url, {
        method: "patch",
        data: payload,
      }),
    );
}

function deleteFolderModal(report) {
  let url = getReportsUnderFolderUrl(report);
  return (dispatch) =>
    dispatch(deleteFolderModalDuck.fetch(url, { method: "delete" }));
}

const getIsLoading = (state) => state[STORE_MOUNT_POINT].isLoading;

const getCategoryFolderData = (state) => state[STORE_MOUNT_POINT];

// Map of folderIds with their reports
const initialState = {};

function reportCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        categoryList: [],
        isLoading: true,
      };
    case RECEIVE_CATEGORIES:
      return {
        ...state,
        categoryList: action.data,
        isLoading: false,
      };
    default:
      return state;
  }
}

function reportFoldersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FOLDERS:
      return {
        ...state,
        folderList: [],
        isLoading: true,
      };
    case RECEIVE_FOLDERS:
      return {
        ...state,
        folderList: action.data,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    createFolderModal,
    editFolderModal,
    moveFolderReportModal,
    deleteFolderModal,
    fetchCategory,
    fetchFolder,
  },
  selectors: {
    getIsLoading,
    getCategoryFolderData,
    getCreateFolderModalData: createFolderModalDuck.selectors.getData,
    getEditFolderModalData: editFolderModalDuck.selectors.getData,
    getMoveFolderReportData: moveFolderModalDuck.selectors.getData,
    getDeleteFolderModalData: deleteFolderModalDuck.selectors.getData,
  },
  reducer: chainReducers([
    reportCategoriesReducer,
    reportFoldersReducer,
    createFolderModalDuck.reducer,
    editFolderModalDuck.reducer,
    moveFolderModalDuck.reducer,
    deleteFolderModalDuck.reducer,
  ]),
};
