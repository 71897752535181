import ApplicationConfig from "application-config";

/**
 *
 * @param path
 * @return {string}
 */
export default function apiUrl(path) {
  const url = new URL(path, ApplicationConfig.ENVIRONMENT.api.url);
  return url.toString();
}

/**
 *
 * @param path
 * @return {string}
 */
export function customerApiUrl(path) {
  const url = new URL(path, ApplicationConfig.ENVIRONMENT.api.customerUrl);
  return url.toString();
}

/**
 *
 * @param path
 * @return {string}
 */
export function documentationApiUrl(path) {
  const url = path
    ? new URL(path, ApplicationConfig.ENVIRONMENT.api.documentationUrl)
    : ApplicationConfig.ENVIRONMENT.api.documentationUrl;
  return url.toString();
}
