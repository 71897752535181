import axios from "axios";
import apiUrl from "api-url";
import debouncePromise from "debounce-promise";
import { useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

export const DamageFormAsyncDropdown = ({
  selfPagination = false,
  keyValue,
  id,
  value,
  valueSetter,
  onChange,
  endpoint,
  qsp = "query",
}) => {
  const [inputValue, setInputValue] = useState("");

  const asyncSearchVins = async (pageNumber = 0, url) => {
    let updatedurl = apiUrl(
      (url += `&pageNumber=${pageNumber}&pageSize=20&${qsp}=${inputValue}`),
    );

    return axios
      .get(updatedurl)
      .then((response) => {
        let data = response?.data?.data?.length ? response.data.data : [];
        // To remove the selected VIN in dropdown fot the next VIN data to be added
        const values = valueSetter(response, pageNumber, inputValue);
        let updatedData = values.data;
        return {
          options: updatedData,
          // If we get results that are not divisible by pageSize, then we've hit the last page
          hasMore: data.length > 0 && data.length % 20 === 0,
        };
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const asyncVinsData = debouncePromise((loadedOptions) => {
    // Calculate page number by checking if the currently loaded results
    // are divisble by the pageSize
    let page = 0;
    const pageSize = 20;
    if (loadedOptions.length % pageSize === 0) {
      page = loadedOptions.length / pageSize;
    }

    return asyncSearchVins(page, `${endpoint}`);
  }, 500);

  const loadOptions = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const dataUrl = apiUrl(
      `${endpoint}&${qsp}=${inputValue}&pageSize=20&pageNumber=${pageNumber}`,
    );

    const response = await axios.get(dataUrl).catch((err) => {
      console.log(err);
    });

    const values = valueSetter(response, pageNumber, inputValue);
    data = values.data;
    metaData = values.metaData;

    return {
      options: data,
      hasMore: metaData
        ? metaData.currentPage < metaData.totalPages - 1
        : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
  };
  return (
    <AsyncPaginate
      id={id}
      key={keyValue}
      placeholder={""}
      isMulti={false}
      value={value}
      isAsync={true}
      onInputChange={(val) => {
        setInputValue(val);
      }}
      closeMenuOnSelect={true}
      menuPosition="fixed"
      loadOptions={
        selfPagination
          ? (query, loadedOptions) => asyncVinsData(loadedOptions)
          : async (currentVal, options, additionalOptions) => {
              const data = await loadOptions(
                currentVal,
                additionalOptions && currentVal === additionalOptions?.prevValue
                  ? additionalOptions.pageNumber + 1
                  : 0,
              );
              return data;
            }
      }
      onChange={(data) => {
        onChange(data);
      }}
    />
  );
};

DamageFormAsyncDropdown.propTypes = {
  id: PropTypes.string,
  keyValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  valueSetter: PropTypes.func,
  endpoint: PropTypes.string,
  isCountVersion: PropTypes.bool,
  qsp: PropTypes.string,
  selfPagination: PropTypes.bool,
};
