/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { localizedTimeFormatter } from "utils/date-time";
import BoldCell from "./BoldCell";

const ReportedTimeCell = ({ coord }) => {
  const startFormatted = localizedTimeFormatter(coord.start);
  const endFormatted = localizedTimeFormatter(coord.end);
  const showMulti =
    coord.groupedIds.length > 1 && startFormatted !== endFormatted;
  const timeFormatted = showMulti
    ? `${startFormatted}-${endFormatted}`
    : startFormatted;
  return <BoldCell>{timeFormatted}</BoldCell>;
};

ReportedTimeCell.propTypes = {
  coord: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    groupedIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default ReportedTimeCell;
