/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";

import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { DateTime } from "components/atoms/DateTime.atom";
import { translateCurrentPositionType } from "shared/utils/entity.utils";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";

export const forecastedVinsColumns = (
  t,
  setForecastedVinsWatchEntity,
  solutionId,
) => {
  const columns = [
    {
      Header: t("inventory-view:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const vin = cellInfo.row.original.id;
        const internal_id = cellInfo.row.original.internal_id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={vin}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              setForecastedVinsWatchEntity(
                solutionId,
                vin,
                internal_id,
                newWatchValue,
              );
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("inventory-view:VIN"),
      id: "vin",
      accessor: (obj) => {
        return {
          id: obj?.id ?? "",
          productType: obj?.productType,
          isShippable: obj?.shippable ?? false,
        };
      },
      width: 200,
      Cell: VinCell,
    },
    {
      Header: t("inventory-view:Current Location"),
      id: "currentLocation",
      disableSortBy: true,
      accessor: (obj) => {
        return {
          positionType: obj?.currentLocation?.positionType ?? "",
          city: obj?.currentLocation?.city ?? "",
          state: obj?.currentLocation?.state ?? "",
          sourceType: obj?.currentLocation?.sourceType ?? "",
          eventTs: obj?.currentLocation?.eventTs,
        };
      },
      Cell: CurrentLocationCell,
    },
    {
      Header: t("inventory-view:Forecasted Arrival"),
      id: "forecastedArrival",
      disableSortBy: true,
      accessor: (obj) => {
        return {
          forecastedArrival: obj?.forecastedArrival,
          etaTime: obj?.forecastedEtaTime,
        };
      },
      Cell: ForecastedArrivalCell,
    },
    {
      Header: t("inventory-view:Forecasted Pickup"),
      accessor: (obj) => {
        return {
          dateRange: obj?.forecastedPickup ?? {},
          carrierName: obj?.carrierName ?? "",
        };
      },
      width: 200,
      Cell: ForecastedPickupCell,
    },
    {
      Header: t("inventory-view:Destination Dealer"),
      id: "destinationDealer",
      disableSortBy: true,
      accessor: (obj) => {
        return {
          destinationDealer: obj?.destinationDealer ?? {},
        };
      },
      width: 200,
      Cell: DestinationCell,
    },
  ];

  return columns;
};

// Cells

const VinCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { id, productType, isShippable } = props.value;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {id}
      </Text>
      <Text block size={FontSize.size14}>
        {productType}
      </Text>
      <Text
        bold
        block
        size={FontSize.size14}
        color={isShippable ? Colors.highlight.GREEN : Colors.highlight.RED}
      >
        {isShippable
          ? t("inventory-view:Shippable")
          : t("inventory-view:Non-Shippable")}
      </Text>
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    productType: PropTypes.string,
    isShippable: PropTypes.bool,
  }),
};

const CurrentLocationCell = (props) => {
  const { positionType, city, state, sourceType, eventTs } = props.value;
  const { t } = useTranslation("inventory-view");
  const translatedPositionType = translateCurrentPositionType(t, positionType);
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text>{translatedPositionType}</Text>
      <Text>{(city || state) && `${city}, ${state}`}</Text>
      {!_.isEmpty(sourceType) && (
        <Text>
          <Text bold>{t("inventory-view:Source")}</Text> - {sourceType}
        </Text>
      )}
      {eventTs && (
        <div className="d-flex flex-wrap">
          <DateTime plain localize dateTime={eventTs} />
        </div>
      )}
    </div>
  );
};

CurrentLocationCell.propTypes = {
  value: PropTypes.shape({
    positionType: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    sourceType: PropTypes.string,
    eventTs: PropTypes.string,
  }),
};

const ForecastedArrivalCell = (props) => {
  const { forecastedArrival, etaTime } = props.value;
  const { t } = useTranslation("inventory-view");

  const findDateRange = (item) => {
    let timeWindow = [];

    if (item) {
      timeWindow = item;
    }

    return formatTimeWindow(timeWindow);
  };

  const formatTimeWindow = (timeWindow) => {
    if (!Array.isArray(timeWindow)) {
      return {};
    }

    // We need to check if the different parts of the time window are defined
    // so that we don't pass undefine to the localized date/time formatters

    const startEndRange = {};

    if (timeWindow[0]) {
      startEndRange.start = {
        date: localizedDateFormatter(timeWindow[0]),
        time: localizedTimeFormatter(timeWindow[0]),
      };
    }

    if (timeWindow[1]) {
      startEndRange.end = {
        date: localizedDateFormatter(timeWindow[1]),
        time: localizedTimeFormatter(timeWindow[1]),
      };
    }

    return startEndRange;
  };

  const dateRange = findDateRange([
    forecastedArrival?.start,
    forecastedArrival?.end,
  ]);

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{"Scheduled Arrival:"}</Text>
      <span>
        {dateRange?.start && (
          <span>
            {dateRange.start.date} {dateRange.start.time}
          </span>
        )}
        {dateRange.start && dateRange.end && (
          <Text
            style={{
              fontSize: "1em",
              whiteSpace: "pre",
              margin: "0 0.1em",
            }}
          >
            {" "}
            {t("inventory-view:to")}{" "}
          </Text>
        )}
        {dateRange.end && (
          <span>
            {dateRange.end.date} {dateRange.end.time}
          </span>
        )}
      </span>
      {etaTime && (
        <Fragment>
          <Text css={{ marginTop: "8px" }} bold>
            {"ETA:"}
          </Text>
          <DateTime plain localize dateTime={etaTime} />
        </Fragment>
      )}
    </div>
  );
};

ForecastedArrivalCell.propTypes = {
  value: PropTypes.shape({
    forecastedArrival: PropTypes.object,
    etaTime: PropTypes.string,
  }),
};

const ForecastedPickupCell = (props) => {
  const { dateRange, carrierName } = props.value;
  const { t } = useTranslation("inventory-view");

  if (!dateRange) {
    return null;
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {dateRange.start && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <DateTime.Date size={FontSize.size14} date={dateRange.start.date} />
            <DateTime.Time
              css={{ paddingLeft: "3px" }}
              size={FontSize.size14}
              time={dateRange.start.time}
            />
            <DateTime.Timezone
              css={{ paddingLeft: "3px" }}
              size={FontSize.size14}
              timezone={dateRange.start.timezone}
            />
          </div>
        )}
        {dateRange.start && dateRange.end && (
          <div
            style={{
              margin: "0 10px",
              alignSelf: "center",
            }}
          >
            {t("inventory-view:to")}
          </div>
        )}
        {dateRange.end && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <DateTime.Date size={FontSize.size14} date={dateRange.end.date} />
            <DateTime.Time
              css={{ paddingLeft: "3px" }}
              size={FontSize.size14}
              time={dateRange.end.time}
            />
            <DateTime.Timezone
              css={{ paddingLeft: "3px" }}
              size={FontSize.size14}
              timezone={dateRange.end.timezone}
            />
          </div>
        )}
      </div>

      {carrierName ? (
        <div css={{ marginTop: "1em", textAlign: "center" }}>
          <div>{t("inventory-view:Assigned Carrier")}: </div>
          <div>{carrierName}</div>
        </div>
      ) : null}
    </div>
  );
};

ForecastedPickupCell.propTypes = {
  value: PropTypes.shape({
    dateRange: PropTypes.object,
    carrierName: PropTypes.string,
  }),
};

const DestinationCell = (props) => {
  const { destinationDealer } = props.value;
  return (
    <div>
      <Text size={FontSize.size14} bold block>{`${
        destinationDealer.name ?? ""
      } ${destinationDealer.code ? "- " + destinationDealer.code : ""}`}</Text>
      <LocationAddressComplex
        address1={destinationDealer.address}
        city={destinationDealer.city}
        state={destinationDealer.state}
        country={destinationDealer.country}
        defaultTextColor={Colors.text.DARK_GRAY}
        addressColor={Colors.text.DARK_GRAY}
      />
    </div>
  );
};

DestinationCell.propTypes = {
  value: PropTypes.shape({
    destinationDealer: PropTypes.object,
  }),
};
