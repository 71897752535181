import _ from "lodash";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { getDescriptionFromMilestoneCode } from "pages/partview/utils/milestone.utils";
import { getDifferenceBetweenTimestamps } from "utils/date-time";

/**
 * Returns a selector for the trip legs of the specified type.
 *
 * @param {object[]} triplegs The full array of triplegs from the detail response.
 * @param {"planned" | "actual"} type The value of the `type` field on the triplegs.
 * @returns
 */
export const getFilteredTriplegsByType = (triplegs = [], type) => {
  return triplegs?.filter((leg) => leg?.type?.toLowerCase() === type) ?? [];
};

/**.
 * Returns Trip Plan Updates in the format required for BaseTable
 *
 * @param {object[]} updates The array of updates from the detail response.
 * @returns
 */
const getTripPlanUpdates = (updates = []) => {
  return updates.map((update) => ({
    id: update?.id,
    // Received Time
    ts: update?.datetime,
    // Event Time
    eventTs: update?.eventDatetime,
    // Update
    update: getDescriptionFromMilestoneCode(update?.code, update?.locationName),
    code: update?.code,
    reasonCode: update?.reasonCode,
    locationName: update?.locationName,
    locationCode: update?.locationCode,
    latitude: update?.latitude,
    longitude: update?.longitude,
    // additional partview params
    comments: update?.comments,
    updateDescription: update?.updateDescription,
    eventType: update?.eventType,
  }));
};

// Return the index of the stop which has the locationCode
const findIndex = (stops, locationCode) =>
  stops.findIndex((stop) => stop.code === locationCode);

// Return the Latest Update with the location present in trip leg
// And the index of the planned stop which has the latest update
const getLastProgressDetails = (plannedStops, data) => {
  // Milestone codes
  // ===============
  // DP => Departed
  // AR => Arrived,
  // FD => Final Deliver
  // RO => Reopen
  // APU => Available for pick up

  let latestUpdate;
  // Returns time difference in days
  const timeDifferenceInDays = getDifferenceBetweenTimestamps(
    data?.LastMilestone?.datetime,
    data?.LastUpdate?.datetime,
  );

  // take the latest update from either LastMilestone or LastUpdate based on the latest timestamp
  if (timeDifferenceInDays > 0) {
    latestUpdate = data?.LastMilestone;
  } else {
    latestUpdate = data?.LastUpdate;
  }

  // Get the stop which has latest update to show the MAD progress till that stop
  let lastProgressIndex = findIndex(plannedStops, latestUpdate?.location?.code);

  if (lastProgressIndex === -1) {
    // Get the latest update which has location
    latestUpdate = _.findLast(data?.updates, (update) => update?.locationCode);

    if (!_.isEmpty(latestUpdate)) {
      latestUpdate = { ...latestUpdate, eventCode: latestUpdate.code };
      lastProgressIndex = findIndex(plannedStops, latestUpdate?.locationCode);

      // If any milestone Events (AR, DP, FD, RO, APU) provided for an off location
      // We should update the MAD progress to 100% till final trip leg
      if (lastProgressIndex === -1 && latestUpdate?.locationCode) {
        return [plannedStops.length - 1, 100];
      }
    }
  }

  let lastProgress = 0; // if event is APU - occurs at origin - there will be no MAD progress for that origin location
  if (latestUpdate?.eventCode === "DP") {
    lastProgress = 50;
    // DP occurs in origin
    // And same location in the planned stops except first stop can be origin and destination
    // So put the progress on the destination according to planned stops array for Trip Summary component
    // Don't update progress for locations which are not present on the trip leg but event received
    // And handle edge case which is DP at ultimate Destination
    lastProgressIndex = lastProgressIndex > -1 ? lastProgressIndex + 1 : -1;
  } else if (
    // when index is 0 and AR is received for Ultimate origin for pick Up(X3)
    // No need to update the MAD progress in that scenario - MAD progress will be 0
    (lastProgressIndex > 0 && latestUpdate?.eventCode === "AR") ||
    latestUpdate?.eventCode === "RO"
  ) {
    lastProgress = 100;
  }

  return [lastProgressIndex, lastProgress];
};

// useTripPlanAndUpdates for the Trip Plan and Updates tab
// MAD Icon Progress logic based on the latest update on the stop
export const useTripPlanAndUpdates = (data) => {
  return useMemo(() => {
    if (_.isEmpty(data)) {
      return [];
    }

    const plannedStops = [];
    const updates = getTripPlanUpdates(data?.updates);

    const plannedTripLegs = getFilteredTriplegsByType(
      data?.Triplegs,
      "planned",
    );

    plannedTripLegs?.forEach((leg, index, originalTriplegs) => {
      const nextLeg = originalTriplegs[index + 1];

      // create leg stops
      if (index === 0) {
        plannedStops.push({
          name: leg?.origin?.name,
          code: leg?.origin?.code,
          address: leg?.origin?.address,
          city: leg?.origin?.city,
          state: leg?.origin?.state,
          country: leg?.origin?.country,
          lad: leg?.origin?.lad,
          scheduled_delivery_window: null,
          scheduled_pickup_window: leg?.origin?.scheduledPickupWindow,
          mode: null,
          progress: 0,
          updates: [],
        });
      }

      plannedStops.push({
        name: leg?.destination?.name,
        code: leg?.destination?.code,
        address: leg?.destination?.address,
        city: leg?.destination?.city,
        state: leg?.destination?.state,
        country: leg?.destination?.country,
        lad: leg?.destination?.lad,
        scheduled_delivery_window: leg?.destination?.scheduledDeliveryWindow,
        scheduled_pickup_window: nextLeg?.origin?.scheduledPickupWindow,
        mode: leg?.mode,
        progress: 0,
        updates: [],
      });
    });

    if (!_.isEmpty(plannedStops)) {
      const plannedStopsLength = plannedStops.length;
      let lastProgressIndex = 0;
      let progress = 0;

      if (!_.isEmpty(updates)) {
        const hasFinalDeliver = updates.findLast(
          (update) => update.code === "FD",
        );

        // If FD exist in updates with or without location or off location
        // Keep the MAD icon to 100 for all triplegs
        if (hasFinalDeliver) {
          lastProgressIndex = plannedStopsLength - 1;
          plannedStops[lastProgressIndex].progress = 100;
        } else {
          // Get the stop index which has recent update and progress to show MAD icon in right place
          [lastProgressIndex, progress] = getLastProgressDetails(
            plannedStops,
            data,
          );

          // handle edge cases
          // don't update progress for locations which are not present on the trip leg but event received
          if (
            lastProgressIndex > -1 &&
            lastProgressIndex < plannedStopsLength
          ) {
            plannedStops[lastProgressIndex].progress = progress;
          }
        }
      }

      // Update previous stop progress to 100.
      for (let index = 0; index < plannedStopsLength; index++) {
        if (index < lastProgressIndex) {
          plannedStops[index].progress = 100;
        } else {
          break;
        }
      }
    }

    return [plannedStops, updates];
  }, [data]);
};

// Return the mode id based on the mode name
const getModeID = (modeName) => {
  const mode = modeName?.toLowerCase();

  if (mode === "truck") {
    return 1;
  } else if (mode === "rail") {
    return 2;
  } else if (mode === "ltl") {
    return 3;
  } else if (mode === "intermodal") {
    return 4;
  } else if (mode === "ocean") {
    return 5;
  } else if (mode === "multimodal") {
    return 6;
  } else if (mode === "air") {
    return 7;
  } else if (mode === "parcel") {
    return 8;
  }
};

// CAT-4595 ticket will start sending location id and geofence details for newly created packages
// Having fallback code for existing packages in order to satisfy RoutingMap.js component requirements
// fallback code doesn't display geofence but details page will not throw any error
// in order to display geofence, RoutingMap.js requires geofence.type or geometry.type and properties.buffer values
// this fallback code to be removed once backfill task is picked up / completed for existing packages
const getLocationDetails = (location) => {
  return location?.id && location?.geofence
    ? location
    : {
        ...location,
        geofence: {
          geometry: {
            coordinates: [location?.longitude, location?.latitude],
          },
          properties: {
            center: {
              latitude: location?.latitude,
              longitude: location?.longitude,
            },
          },
        },
      };
};

// Format the data required for shipment from package details api
const createShipmentForMap = (data, tripleg, isActual) => {
  if (
    _.isEmpty(data) ||
    _.isEmpty(tripleg) ||
    _.isEmpty(tripleg?.origin) ||
    _.isEmpty(tripleg?.destination)
  ) {
    return null;
  }

  const updates =
    data?.updates
      ?.filter((update) => {
        if (update?.locationCode === tripleg?.origin?.code) {
          if (update.code === "DP" || update.code === "APU") {
            return true;
          }
        }

        if (update?.locationCode === tripleg?.destination?.code) {
          if (update.code === "AR" || update.code === "FD") {
            return true;
          }
        }

        return false;
      })
      .map((update) => {
        return { ...update, time: update.eventDatetime };
      }) ?? [];

  const updatesLength = updates.length;
  const currentLocationCoord = {
    latitude: tripleg.origin?.latitude,
    longitude: tripleg.origin?.longitude,
  };

  if (updatesLength > 0) {
    const lastUpdate = updates[updatesLength - 1];
    currentLocationCoord.latitude = lastUpdate.latitude;
    currentLocationCoord.longitude = lastUpdate.longitude;
  }

  // To closer match the status from shipments
  let status;
  if (updates.find(({ code }) => code === "FD" || code === "AR")) {
    status = "arrived";
  } else if (updates.find(({ code }) => code === "DP")) {
    status = "active";
  }

  return {
    id: uuidv4(),
    creator_shipment_id: isActual
      ? tripleg?.creatorShipmentId
      : tripleg?.ShipmentNumber,
    active_status_ng: status,
    shipment_stops: [
      { location: getLocationDetails(tripleg.origin) },
      { location: getLocationDetails(tripleg.destination) },
    ],
    has_event_refs: false,
    mode_name: tripleg?.mode,
    mode_id: getModeID(tripleg?.mode),
    current_location: {
      ...currentLocationCoord,
      updates: _.sortBy(updates, "time"), // sorting the updates as per the time key to show the MAD icon on map for the latest coordinates
    },
    isPartViewShipment: true,
  };
};

// When shipment Api fails with 404 - take the required data from package detail api
export const transformActualTripleg = (data, tripleg) => {
  return createShipmentForMap(data, tripleg, true);
};

// useTransformPlannedTripleg is used to display
// MAD and LAD
// and routes between locations from the planned tripleg on MAP
export const useTransformPlannedTripleg = (data) => {
  return useMemo(
    () =>
      getFilteredTriplegsByType(data?.Triplegs, "planned").map((tripleg) => {
        return createShipmentForMap(data, tripleg, false);
      }),
    [data],
  );
};
