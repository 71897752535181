import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";
import apiUrl from "../../api-url";
import { axiosConfigHeaders } from "../../utils/fetch-utils";
import { SEARCH_CATEGORIES } from "./MilestoneEventSearchCategoryDefs";
import { FILTERS } from "./MilestoneEventFilterDefs";

const STORE_MOUNT_POINT = "milestoneSearch";

// helpers

export const milestonesUrl = (
  solutionId,
  queryString,
  state,
  statusUpdateId = null,
) => {
  let url = apiUrl("/entity-search/status-update");

  if (statusUpdateId) {
    url += `/${statusUpdateId}`;
  }

  if (queryString) {
    return `${url}?groupType=ITSS&${queryString || ""}`;
  }

  return url;
};

const totalCountDuck = buildFetchDuck(STORE_MOUNT_POINT, "totalCount");

// action creators

// This is an action creator, but it's intended to be called internally by
// buildSearchBarState.actionCreators.searchEntities(). Component code should
// call searchEntities() rather than this.
const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const { searchFilters } = state[STORE_MOUNT_POINT];
  const dateRange = searchFilters["unused"] || {};
  const { from, to } = dateRange;
  let hasValidDateRange = !from && !to;
  let error = null;

  // H2-2021
  // Prevent searching of more than 24 hours of data,
  // due to performance limitations on the API.
  if (from && to) {
    const hours = moment(to).diff(moment(from), "hours");
    hasValidDateRange = hours <= 24;
  }

  if (!hasValidDateRange) {
    error = "Please specify Milestone Event Times of 24 hours or less.";
  }

  if (error) {
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      error,
    });
  } else {
    let url = milestonesUrl(solutionId, queryString);

    // Fetch the count
    dispatch(totalCountDuck.fetch(url, axiosConfigHeaders("version=count")));

    // Fetch the search results
    dispatch(duck.fetch(url, axiosConfigHeaders()));
  }
};

// selectors

const getMilestoneEventsResponse = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.data`, {});

const getMilestoneEvents = createSelector(getMilestoneEventsResponse, (resp) =>
  _.get(resp, "data", []),
);

// reducers

const searchInitialState = {
  data: { data: [] },
};

// just here to supply initial state
const searchReducer = (state = searchInitialState, action) => state;

// interface

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [searchReducer, totalCountDuck.reducer],
);

export default {
  ...SearchBarState,
  selectors: {
    ...SearchBarState.selectors,
    getTotalPages: (state) =>
      totalCountDuck.selectors.getData(state)?.data?.meta?.totalPages,
    getTotalCount: (state) =>
      totalCountDuck.selectors.getData(state)?.data?.meta?.totalCount,
    getTotalCountIsLoading: (state) =>
      totalCountDuck.selectors.getData(state)?.isLoading,
    getMilestoneEvents,
  },
  actionCreators: {
    ...SearchBarState.actionCreators,
    exportSearch: _.partial(
      SearchBarState.actionCreators.exportEntities,
      milestonesUrl,
      null,
      { headers: { Accept: "text/csv;version=full" } },
      "milestone-search-results",
    ),
  },
};
