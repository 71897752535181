/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Button } from "components/atoms/Button.atom";
import CodeSample from "modules/documentation/documentation-styled-components/CodeSample.js";

export const DebugETAForm = ({ debugEta, requestData }) => {
  useSetTitleOnMount("Debug ETA Tool");

  const [queryStringParameters, setQueryStringParams] = useState(null);

  const onInputChange = (event) => {
    setQueryStringParams(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugEta(queryStringParameters);
  };

  console.log("queryStringParameters", typeof queryStringParameters);

  const exampleQueryStringParams = `{
    "status": "ACTIVE",
    "mode-id": 1,
    "departure": "2023-02-21T17:30:00",
    "eta-source": "ENTITY",
    "dest-loc-id": 288501,
    "orgin-loc-id": 271419,
    "external_entity_id": "AAAKSKTGATLU3NQSO",
    "points-of-interest": "REL",
    "creator_shipment_id": null,
    "points-interest-date": "2023-02-21T17:30:00",
    "nextPlannedLocationId": -1,
    "carrier-organization-id": "-1"
  }`;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Form onSubmit={handleSubmit}>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel>
            Please enter query-string-params (must be a valid JSON object)
          </FormLabel>
          <FormControl
            as="textarea"
            rows="10"
            cols="10"
            value={queryStringParameters || null}
            onChange={onInputChange}
            placeholder={exampleQueryStringParams}
          />
        </FormGroup>
        <Button type="submit" disabled={requestData.isLoading}>
          Debug ETA
        </Button>
      </Form>
      {requestData.data ? (
        <CodeSample code={JSON.stringify(requestData.data, null, 2)} />
      ) : null}
      {requestData.isLoadingError ? (
        <Alert variant={AlertVariant.DANGER} show>
          {JSON.stringify(requestData?.loadingError)}
        </Alert>
      ) : null}
    </div>
  );
};
