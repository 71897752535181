/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  faCaretLeft,
  faCaretRight,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { NoChartData } from "components/molecules/NoChartData.molecule";
import { VerticalStackedBarChart } from "components/molecules/VerticalStackedBarChart.molecule";
import Loader from "react-loader";
import { useIsMediumAndDown } from "components/responsive";
import { withSize } from "react-sizeme";

export const DwellingVinsWidget = ({
  setDwellFilterValue,
  watchedLocations,
  pushView,
  isLoading = true,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation("inventory-view");
  const isMobile = useIsMediumAndDown();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  let data = watchedLocations.map((location) => {
    return {
      locationId: location?.locationId,
      locationCode: location?.code,
      name: location?.name + " -\n" + location?.code,
      dwellCount: location?.dwell?.dwelling,
      agedCount: location?.dwell?.aging,
    };
  });

  // Pagination
  const pageSize = isMobile
    ? 2
    : dimensions.width
    ? Math.floor(dimensions.width / 100)
    : 5;
  const totalPages = _.ceil(data.length / pageSize);

  const pagedData = data.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize,
  );

  // Victory Chart style object
  const barStyle = {
    data: {
      cursor: "pointer",
      opacity: 0.75,
      width: 42,
    },
    labels: { fill: "white" },
  };

  // Victory Chart style object
  const axisStyle = {
    axis: { stroke: Colors.border.BOTTOM_AXIS_BORDER },
  };

  const crossAxisStyle = {
    axis: { stroke: "transparent" },
    axisLabel: { fontSize: 20, padding: 30 },
    grid: { stroke: Colors.border.INNER_GRAY_BORDER },
    tickLabels: { fontSize: 12, padding: 5, fontFamily: "inherit" },
  };

  // Victory Chart style object
  const flyoutStyle = {
    stroke: "none",
    fill: Colors.background.DARK_GRAY,
  };

  const dataTypes = {
    AGING: "agedCount",
    DWELL: "dwellCount",
  };

  const bars = [
    {
      y: dataTypes.AGING,
      showTooltip: true,
      getLabelForData: ({ datum }) =>
        `${datum.agedCount} ${t("inventory-view:Vins Aging")}`,
      legendLabel: t("inventory-view:Aging"),
      color: Colors.highlight.RED,
      onClick: (e, data) => {
        setDwellFilterValue("aging");
        pushView(data.datum.locationId);
      },
    },
    {
      y: dataTypes.DWELL,
      showTooltip: false,
      getLabelForData: ({ datum }) =>
        `${datum.dwellCount} ${t("inventory-view:Vins Dwell")}`,
      legendLabel: t("inventory-view:Dwell"),
      color: Colors.highlight.GREEN,
      onClick: (e, data) => {
        setDwellFilterValue("dwell");
        pushView(data.datum.locationId);
      },
    },
  ];

  const onSize = (size) => {
    setDimensions({
      height: size.height,
      width: size.width,
    });
  };

  return (
    <PanelGroup>
      <PanelGroup.Header
        title={t("inventory-view:Dwelling VINs")}
        postTitleIcon={faQuestionCircle}
        tooltipPosition="bottom"
        postTitleIconTooltip={
          <span>
            {t("inventory-view:Aging")}
            {" : "}
            {t(
              "inventory-view:VINs dwelling at a location past their acceptable time",
            )}
            <br />
            {t("inventory-view:Dwelling")}
            {" : "}
            {t(
              "inventory-view:VINs dwelling at a location within their acceptable time",
            )}
          </span>
        }
      />
      <PanelGroup.Content style={{ position: "relative", minHeight: 200 }}>
        <Loader loaded={!isLoading}>
          {data?.length ? (
            <div
              css={{
                svg: {
                  maxHeight: "23rem",
                },
                display: "flex",
                flexDirection: "row",
              }}
            >
              {totalPages > 1 && !(currentPage <= 0) && (
                <Button
                  size="sm"
                  variant="transparent"
                  disabled={currentPage <= 0}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <Icon src={faCaretLeft} size={FontSize.size28} />
                </Button>
              )}
              <ChartComponent
                pagedData={pagedData}
                pageSize={pageSize}
                bars={bars}
                barStyle={barStyle}
                axisStyle={axisStyle}
                crossAxisStyle={crossAxisStyle}
                flyoutStyle={flyoutStyle}
                pushView={pushView}
                onSize={onSize}
                dimensions={dimensions}
              />
              {totalPages > 1 && !(currentPage >= totalPages - 1) && (
                <Button
                  size="sm"
                  variant="transparent"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <Icon src={faCaretRight} size={FontSize.size28} />
                </Button>
              )}
            </div>
          ) : (
            <div
              css={{
                minHeight: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: Colors.text.GRAY,
                padding: "2em",
                textAlign: "center",
              }}
            >
              <NoChartData
                size={FontSize.size20}
                label={t("inventory-view:Please select your locations")}
              />
            </div>
          )}
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const ChartComponent = withSize()(({
  dimensions,
  pagedData,
  pageSize,
  bars,
  barStyle,
  axisStyle,
  crossAxisStyle,
  flyoutStyle,
  pushView,
}) => {
  return (
    <div css={{ width: "100%" }}>
      <VerticalStackedBarChart
        data={pagedData}
        width={dimensions.width}
        xAccessor="name"
        bars={bars}
        barStyle={barStyle}
        legendStyle={{ labels: { fontFamily: "inherit" } }}
        axisTicksLabelStyle={{ fontFamily: "inherit" }}
        axisStyle={axisStyle}
        crossAxisStyle={crossAxisStyle}
        flyoutStyle={flyoutStyle}
        onClickBar={(e, data) => {
          pushView(data.datum.locationId);
        }}
        onClickLegend={null}
        domain={{ x: [0, pageSize] }}
      />
    </div>
  );
});

DwellingVinsWidget.propTypes = {
  isLoading: PropTypes.bool,
  pushView: PropTypes.func,
  watchedLocations: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
