/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconType as IconTypeReactIcons } from "react-icons";

import { FontSize, IconType } from "./enums";
import { FlipProp, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties, MouseEventHandler } from "react";

interface IconPropsForFontAwesome {
  type?: IconType.FontAwesome;
  src: IconDefinition;
  size?: FontSize;
  spin?: boolean;
  flip?: FlipProp;
  onClick?: MouseEventHandler;
}

interface IconPropsForReactIcon {
  type: IconType.ReactIcons;
  src: IconTypeReactIcons;
}

interface IconPropsForLocalImage {
  type: IconType.LocalImage;
  src: string;
  altText?: string;
}

type IconProps = (
  | IconPropsForFontAwesome
  | IconPropsForReactIcon
  | IconPropsForLocalImage
) & {
  type?: IconType;
  className?: string;
  style?: CSSProperties;
  color?: string;
  "data-qa"?: string;
};

export const Icon = (props: IconProps) => {
  const { type, src, className, style = {}, "data-qa": dataQa, color } = props;

  switch (type) {
    case IconType.ReactIcons: {
      /** @ts-expect-error */
      if (props.size) {
        console.warn("'size' is not supported for ReactIcons type.");
      }
      /** @ts-expect-error */
      if (props.spin) {
        console.warn("'spin' is not supported for ReactIcons type.");
      }
      /** @ts-expect-error */
      if (props.flip) {
        console.warn("'flip' is not supported for ReactIcons type.");
      }
      /** @ts-expect-error */
      if (props.altText) {
        console.warn("'altText' is not supported for ReactIcons type.");
      }
      /** @ts-expect-error */
      if (props.onClick) {
        console.warn("'onClick' is not supported for ReactIcons type.");
      }

      const ReactIcon = src;
      return (
        <ReactIcon
          className={className}
          style={{ color: color ? color : "inherit", ...style }}
        />
      );
    }
    case IconType.LocalImage: {
      /** @ts-expect-error */
      if (props.size) {
        console.warn("'size' is not supported for LocalImage type.");
      }
      /** @ts-expect-error */
      if (props.spin) {
        console.warn("'spin' is not supported for LocalImage type.");
      }
      /** @ts-expect-error */
      if (props.flip) {
        console.warn("'flip' is not supported for LocalImage type.");
      }
      /** @ts-expect-error */
      if (props.onClick) {
        console.warn("'onClick' is not supported for LocalImage type.");
      }

      const { altText } = props;
      return (
        <img
          src={src}
          className={className}
          style={{ color: color ? color : "inherit", ...style }}
          alt={altText}
        />
      );
    }
    default: {
      /** @ts-expect-error */
      if (props.altText) {
        console.warn("'altText' is not supported for FontAwesome type.");
      }

      const { spin, flip, onClick, size } = props;
      return (
        <FontAwesomeIcon
          data-qa={dataQa}
          icon={src}
          spin={spin}
          color={color}
          flip={flip}
          onClick={onClick}
          className={className}
          style={{
            fontSize: size ? `${size}rem` : "inherit",
            ...style,
          }}
        />
      );
    }
  }
};

export const IconPropTypes = {
  /**
   * For type=FontAwesome (or default): Import the icon src from the @fortawesome libraries.
   * For type=ReactIcons: Import the icon from the react-icons library.
   * For type=LocalImage: Import the direct image file.
   */
  src: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  /** The type of the icon */
  type: PropTypes.string,
  /** Define a size using the FontSize enum, or use style to define a custom width/height. */
  size: PropTypes.number,
  /** Spin the icon clockwise. */
  spin: PropTypes.bool,
  /** Sets the icon color. */
  color: PropTypes.string,
  /** Flip the icon in any direction. Accepted values: horizontal, vertical, both. */
  flip: PropTypes.string,
  /** Event fired when clicking the icon. Only support by FontAwesome type. */
  onClick: PropTypes.func,
  /** Add extra styles. */
  style: PropTypes.object,
  /**
   * A string for defining one or more classes that will be added to the icon.
   */
  className: PropTypes.string,
  /** Optional alt text for LocalImage type */
  altText: PropTypes.string,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
};

export { FontSize, IconType };
