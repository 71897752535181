/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Loader from "react-loader";
import _ from "lodash";

import SectionHeader from "./SectionHeader";
import CodeSampleEditor from "./CodeSampleEditor";
import StandardInput from "components-old/forms/inputs/StandardInput";
import Colors from "styles/colors";

const requestsCss = {
  padding: "1em",
};

const inputGroupCss = {
  display: "flex",
  flexWrap: "wrap",
};

const inputCss = {
  flex: "1 1 33%",
};

const buttonCss = {
  backgroundColor: Colors.highlight.GREEN,
  color: "white",
  float: "right",
};

const ParameterSection = ({ title, data = [], onFieldChange }) => {
  if (_.isEmpty(data)) {
    return null;
  }

  return (
    <div>
      <SectionHeader title={title} />
      <div style={inputGroupCss}>
        {data.map((el, i) => (
          <div style={inputCss} key={i}>
            <StandardInput
              label={el.name}
              placeholder={`(${el.type})`}
              value={el.value}
              onChange={(val) => onFieldChange(el.name, val)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ParameterSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  onFieldChange: PropTypes.func.isRequired,
};

const ApiRequest = ({ data, eventHandler, isLoading, submitHandler }) => {
  const { t } = useTranslation("documentation");

  return (
    <div id="requests" css={requestsCss}>
      <Loader loaded={!isLoading} />
      <ParameterSection
        title={t("documentation:Request Headers")}
        data={Object.values(data.headers)}
        onFieldChange={eventHandler.bind(this, "headers")}
      />
      <ParameterSection
        title={t("documentation:URL Path Parameters")}
        data={Object.values(data.parameters).filter(
          (param) => param.in === "path",
        )}
        onFieldChange={eventHandler.bind(this, "parameters")}
      />
      <ParameterSection
        title={t("documentation:Request Parameters")}
        data={Object.values(data.parameters).filter(
          (param) => param.in !== "path",
        )}
        onFieldChange={eventHandler.bind(this, "parameters")}
      />
      <SectionHeader title={t("Request Body")} />
      <CodeSampleEditor code={data.body} eventHandler={eventHandler} />
      <div>
        <Button
          disabled={isLoading}
          style={buttonCss}
          onClick={() => submitHandler()}
        >
          {t("documentation:Execute")}
        </Button>
      </div>
    </div>
  );
};

ApiRequest.propTypes = {
  data: PropTypes.object,
  eventHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  submitHandler: PropTypes.func,
};

export default ApiRequest;
