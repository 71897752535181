import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import CarrierViewSearchBarState from "../../redux/CarrierViewSearchBarState";
import { FILTERS } from "./CarrierView.searchOptions";
import {
  selectShipperFilterOptions,
  selectExceptionTypeFilterOptions,
  selectProductTypeFilterOptions,
  selectLifeCycleStateFilterOptions,
  selectCurrentPositionTypes,
  selectOrderTypeFilterOptions,
} from "./CarrierView.SearchFilterSelectors";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  shipperFilterOptions: selectShipperFilterOptions(state),
  exceptionTypeFilterOptions: selectExceptionTypeFilterOptions(state),
  productTypeFilterOptions: selectProductTypeFilterOptions(state),
  lifeCycleStateFilterOptions: selectLifeCycleStateFilterOptions(state),
  searchFilters: CarrierViewSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    CarrierViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  areAllPrerequisiteFiltersSelected:
    CarrierViewSearchBarState.selectors.getAreAllPrerequisiteFiltersSelected(
      state,
    ),
  solutionId: getSolutionId(state),
  canUserSearch: CarrierViewSearchBarState.selectors.getCanUserSearch(state),
  currentPositionTypes: selectCurrentPositionTypes(state),
  orderTypeFilterOptions: selectOrderTypeFilterOptions(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = CarrierViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
