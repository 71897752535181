import _ from "lodash";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";

const STORE_MOUNT_POINT = "InboundLiveDwellWidgetState";

// URLs
const INBOUND_LIVE_DWELL_URL = apiUrl(`/shipping-ng/dwell-data`);
const getInboundLiveDwellUrl = (solutionId, queryString) => {
  return `${INBOUND_LIVE_DWELL_URL}?${queryString}`;
};

// Count Duck
const duckLiveDwellCount = buildFetchDuck(STORE_MOUNT_POINT, "count");

const transformLiveDwellDetailsData = (data) => {
  return _.sortBy(
    data
      .map((d) => {
        if (d?.hasOwnProperty("acceptable_dwell_count")) {
          return {
            id: 1,
            name: "Acceptable",
            code: "AD",
            count: d.acceptable_dwell_count,
          };
        } else if (d?.hasOwnProperty("moderate_dwell_count")) {
          return {
            id: 2,
            name: "Moderate",
            code: "MD",
            count: d.moderate_dwell_count,
          };
        } else if (d?.hasOwnProperty("excessive_dwell_count")) {
          return {
            id: 3,
            name: "Excessive",
            code: "ED",
            count: d.excessive_dwell_count,
          };
        }

        return null;
      })
      .filter((el) => el !== null),
    "id",
  );
};

const fetchCount = (locationCode) => {
  const url = INBOUND_LIVE_DWELL_URL;
  let params = { unloadType: "LIVE" };

  if (locationCode) {
    params.destCode = locationCode;
  }

  const config = {
    headers: { Accept: "application/json;version=COUNT" },
    params,
  };

  return (dispatch) => {
    dispatch(
      duckLiveDwellCount.fetch(url, config, transformLiveDwellDetailsData),
    );
  };
};

const fetchShipments = (
  queryString = "",
  _solutionId_ignored,
  duck,
  dispatch,
) => {
  const url = getInboundLiveDwellUrl(null, queryString);

  const config = {
    headers: {
      Accept: "application/json;version=DETAIL",
    },
  };

  dispatch(duck.fetch(url, config));
};

// Interface
const InboundLiveDwellWidgetState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [], // No categories
  [
    {
      queryKey: "unloadType",
      // Always send url param `unload_type=LIVE`
      queryBuilder: (queryKey) => getBasicQueryString(queryKey, "LIVE"),
    },
    { queryKey: "destCode", queryBuilder: getBasicQueryString },
    { queryKey: "dwellType", queryBuilder: getBasicQueryString },
  ],
  fetchShipments,
  [duckLiveDwellCount.reducer],
  {
    defaultSort: "destination_earliest_arrival",
  },
);

// Add count actions and selectors to the search state.
InboundLiveDwellWidgetState.actionCreators = {
  ...InboundLiveDwellWidgetState.actionCreators,
  fetchCount: fetchCount,
  exportSearch: _.partial(
    InboundLiveDwellWidgetState.actionCreators.exportEntities,
    getInboundLiveDwellUrl,
    null,
    { headers: { Accept: "text/csv;version=DETAIL" } },
    "live-dwell-search-results",
  ),
};

InboundLiveDwellWidgetState.selectors = {
  ...InboundLiveDwellWidgetState.selectors,
  getCountData: duckLiveDwellCount.selectors.getData,
};

export default InboundLiveDwellWidgetState;
