import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { getAssetId } from "modules/shipment-detail/ShipmentUtils";

import {
  Mode,
  ShipmentStatusCode,
  ShipmentStatus,
  ShipmentStatusName,
  ShipmentEvent,
  ShipmentException,
  ShipmentType,
  LineOfBusiness,
  LoadedStatus,
  ETA,
  StatusUpdate,
  ModeId,
} from "shared/constants/shipments.const";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

export const useShipmentTranslation = () => {
  const { t } = useTranslation("domain-data");
  const activeOrganization = useSelector(getActiveOrganization);

  const getTranslatedMode = useCallback(
    (mode: string) => {
      switch (mode) {
        case Mode.AIR:
          return t("domain-data:Air");
        case Mode.INTERMODAL:
          return t("domain-data:Intermodal");
        case Mode.LTL:
          return t("domain-data:LTL");
        case Mode.MULTIMODAL:
          return t("domain-data:Multimodal");
        case Mode.OCEAN:
          return t("domain-data:Ocean");
        case Mode.PARCEL:
          return t("domain-data:Parcel");
        case Mode.RAIL:
          return t("domain-data:Rail");
        case Mode.TRUCK:
          return t("domain-data:Truck");
        default: {
          logWarningForMissingTranslation(mode);
          return mode;
        }
      }
    },
    [t],
  );

  const getTranslatedStatus = useCallback(
    (status: string) => {
      switch (status) {
        case ShipmentStatus.ACTIVE:
          return t("domain-data:Active");
        case ShipmentStatus.ARRIVED:
          return t("domain-data:Arrived");
        case ShipmentStatus.AVAILABLE_FOR_UNLOAD:
          return t("domain-data:Available for Unload");
        case ShipmentStatus.CANCELED:
          return t("domain-data:Canceled");
        case ShipmentStatus.PENDING_ASSET_ID: {
          // If there is a :, we likely want to split the translated text.
          return `${t("domain-data:Pending")}: ${t("domain-data:Asset ID")}`;
        }
        case ShipmentStatus.RELEASED:
          return t("domain-data:Released");
        case ShipmentStatus.SCHEDULED:
          return t("domain-data:Scheduled");
        case ShipmentStatus.EXPIRED:
          return t("domain-data:Expired");
        default: {
          if (status) {
            logWarningForMissingTranslation(status);
          }

          return status;
        }
      }
    },
    [t],
  );

  const getTranslatedStatusName = useCallback(
    (statusUpdate: StatusUpdate) => {
      const {
        current_city,
        current_state,
        status_code,
        status_name,
        stop_location_name,
        mode_id,
      } = statusUpdate;
      const geofence_name = statusUpdate?.status_details?.geofence_name;

      // For Rail, arrived/departed events from a stop.
      // e.g. "Newark, NJ - Departed from location"
      const prependLocation = (label: string) => {
        if (current_city && current_state) {
          return `${current_city}, ${current_state} - ${label}`;
        }
        return label;
      };

      // For other modes, arrived/departed events from a stop.
      // e.g. "Arrived: Port of London"
      const appendStopLocationName = (label: string) => {
        if (stop_location_name) {
          if (geofence_name) {
            return `${label}: ${stop_location_name} - ${geofence_name}`;
          }
          return `${label}: ${stop_location_name}`;
        }

        return label;
      };

      const appendAssetId = (label: string) => {
        const assetId = getAssetId(activeOrganization, statusUpdate);
        if (assetId) {
          return `${label}: ${assetId}`;
        }
        return label;
      };

      switch (status_code) {
        case ShipmentStatusCode.SHIPMENT_CREATED:
          return t("domain-data:Shipment Created");
        case ShipmentStatusCode.SHIPMENT_UPDATED:
          return t("domain-data:Shipment Updated");
        case ShipmentStatusCode.ASSET_ASSIGNED:
          return appendAssetId(t("domain-data:Asset Assigned"));
        case ShipmentStatusCode.ASSET_UNASSIGNED:
          return t("domain-data:Asset Unassigned");
        case ShipmentStatusCode.ARRIVED_AT_DROP_OFF:
          if (mode_id === ModeId.AIR) {
            return appendStopLocationName(t("domain-data:Arrived"));
          }
          return appendStopLocationName(t("domain-data:Arrived at Drop Off"));
        case ShipmentStatusCode.ARRIVED:
          return appendStopLocationName(t("domain-data:Arrived"));
        case ShipmentStatusCode.DEPARTED_DROP_OFF:
          return appendStopLocationName(t("domain-data:Departed Drop Off"));
        case ShipmentStatusCode.DEPARTED_PICK_UP:
          if (mode_id === ModeId.LTL) {
            return appendStopLocationName(t("domain-data:Departed Origin"));
          }
          if (mode_id === ModeId.AIR) {
            return appendStopLocationName(t("domain-data:Departed"));
          }
          return appendStopLocationName(t("domain-data:Departed Pickup"));
        case ShipmentStatusCode.BEHIND_SCHEDULE:
          return t("domain-data:Shipment Behind Schedule");
        case ShipmentStatusCode.BEHIND_SCHEDULE_CLEARED:
          return t("domain-data:Shipment Behind Schedule Cleared");
        case ShipmentStatusCode.MANUAL_CARRIER_DELAY:
        case ShipmentStatusCode.MANUAL_SHIPPER_DELAY:
          return t("domain-data:Carrier Delay Reported");
        case ShipmentStatusCode.CLEAR_MANUAL_CARRIER_DELAY:
        case ShipmentStatusCode.CLEAR_MANUAL_SHIPPER_DELAY:
          return t("domain-data:Carrier Delay Cleared");
        case ShipmentStatusCode.ESTIMATED_DELIVERY:
          return t("domain-data:Estimated Delivery");
        case ShipmentStatusCode.PICKED_UP:
          return appendStopLocationName(t("domain-data:Picked Up"));
        case ShipmentStatusCode.DELIVERED:
          return appendStopLocationName(t("domain-data:Delivered"));

        // Flags
        case ShipmentStatusCode.BACKORDER:
          return t("domain-data:Shipment has parts that are on backorder");
        case ShipmentStatusCode.CLEAR_BACK_ORDER:
          return t(
            "domain-data:Shipment no longer has parts that are on backorder",
          );
        case ShipmentStatusCode.OFF_ROUTE:
          return t("domain-data:Shipment is Off Route");
        case ShipmentStatusCode.CLEAR_OFF_ROUTE:
          return t("domain-data:Shipment is no longer Off Route");

        // Rail specific statuses
        case ShipmentStatusCode.RAIL_ARRIVED:
          return prependLocation(t("domain-data:Arrival INTRANSIT"));
        case ShipmentStatusCode.RAIL_ARRIVED_AT_DROP_OFF:
          return prependLocation(t("domain-data:Arrival at FINAL DESTINATION"));
        case ShipmentStatusCode.RAIL_DEPARTED_PICK_UP:
          return prependLocation(t("domain-data:Departed from location"));
        case ShipmentStatusCode.RAIL_DEPARTED_DROP_OFF:
          return prependLocation(t("domain-data:Release Empty"));
        case ShipmentStatusCode.RAIL_PLACEMENT_ACTUAL:
          return prependLocation(t("domain-data:Placement - ACTUAL"));
        case ShipmentStatusCode.RAIL_PLACEMENT_CONSTRUCTIVE:
          return prependLocation(t("domain-data:Placement - CONSTRUCTIVE"));
        case ShipmentStatusCode.RAIL_BAD_ORDER:
          return prependLocation(
            t("domain-data:Bad order - HOURS TO REPAIR UNKNOWN"),
          );
        case ShipmentStatusCode.PASSING_LOCATION:
          return prependLocation(t("domain-data:Passing Location"));
        case ShipmentStatusCode.DEPARTED_FROM_ORIGIN_LOCATION:
          return prependLocation(
            t("domain-data:Departed from Origin Location"),
          );
        default: {
          // Fallback on the value from the backend
          return status_name;
        }
      }
    },
    [t, activeOrganization],
  );

  /**
   * Get the translated status name when only the status name is available.
   * Prefer `getTranslatedStatusName`.
   */
  const getTranslatedStatusNameByName = useCallback(
    (name: string) => {
      switch (name) {
        // Get status code from search api - new api change required
        case ShipmentStatusName.BAD_ORDER:
          return t("domain-data:Bad order - HOURS TO REPAIR UNKNOWN");
        case ShipmentStatusName.DEPARTED_FROM_LOCATION:
          return t("domain-data:Departed from location");
        case ShipmentStatusName.PULL_FROM_PATRON_SIDING:
          return t("domain-data:Pull from Patron Siding");
        case ShipmentStatusName.RELEASE_EMPTY:
          return t("domain-data:Release Empty");
        default: {
          // Fallback on the value from the backend
          return name;
        }
      }
    },
    [t],
  );

  const getTranslatedShipmentEvent = useCallback(
    (name: string) => {
      switch (name) {
        case ShipmentEvent.ARRIVED_AT_DESTINATION:
          return t("domain-data:Arrived at Destination");
        case ShipmentEvent.ARRIVED_AT_ORIGIN:
          return t("domain-data:Arrived at Origin");
        case ShipmentEvent.ARRIVED_AT_STOP:
          return t("domain-data:Arrived at Stop");
        case ShipmentEvent.AVAILABLE_FOR_DELIVERY:
          return t("domain-data:Available for Delivery");
        case ShipmentEvent.DEPARTED_DESTINATION:
          return t("domain-data:Departed Destination");
        case ShipmentEvent.DEPARTED_ORIGIN:
          return t("domain-data:Departed Origin");
        case ShipmentEvent.DEPARTED_STOP:
          return t("domain-data:Departed Stop");
        default: {
          logWarningForMissingTranslation(name);
          return name;
        }
      }
    },
    [t],
  );

  const getTranslatedShipmentType = useCallback(
    (type: string) => {
      switch (type) {
        case ShipmentType.INBOUND:
          return t("domain-data:Inbound");
        case ShipmentType.RACK_RETURN:
          return t("domain-data:Rack Return");
        case ShipmentType.MULTI_STOP:
          return t("domain-data:Multi-Stop");
        default: {
          logWarningForMissingTranslation(type);
          return type;
        }
      }
    },
    [t],
  );

  const getTranslatedShipmentLob = useCallback(
    (lob: string) => {
      switch (lob) {
        case LineOfBusiness.AFTERMARKET:
          return t("domain-data:Aftermarket");
        case LineOfBusiness.FINISHED_VEHICLE:
          return t("domain-data:Finished Vehicle");
        case LineOfBusiness.INBOUND:
          return t("domain-data:Inbound");
        default: {
          logWarningForMissingTranslation(lob);
          return lob;
        }
      }
    },
    [t],
  );

  const getTranslatedLoadedStatus = useCallback(
    (loadedStatus: string) => {
      switch (loadedStatus) {
        case LoadedStatus.EMPTY:
          return t("domain-data:Empty");
        case LoadedStatus.LOADED:
          return t("domain-data:Loaded");
        default: {
          logWarningForMissingTranslation(loadedStatus);
          return loadedStatus;
        }
      }
    },
    [t],
  );

  const getTranslatedETA = useCallback(
    (eta: string) => {
      switch (eta) {
        case ETA.DELAYED:
          return t("domain-data:Delayed");
        case ETA.TBD:
          return t("domain-data:TBD");
        default: {
          logWarningForMissingTranslation(eta);
          return eta;
        }
      }
    },
    [t],
  );

  return {
    getTranslatedMode,
    getTranslatedStatus,
    getTranslatedStatusName,
    getTranslatedStatusNameByName,
    getTranslatedShipmentEvent,
    getTranslatedShipmentType,
    getTranslatedShipmentLob,
    getTranslatedLoadedStatus,
    getTranslatedETA,
  };
};

export const useShipmentExceptionTranslation = () => {
  const { t } = useTranslation("exceptions");

  const getTranslatedShipmentException = useCallback(
    (exception: string) => {
      switch (exception) {
        case ShipmentException.BACKORDER:
          return t("exceptions:Backorder");
        case ShipmentException.BAD_ORDER:
          return t("exceptions:Bad Order");
        case ShipmentException.BEHIND_SCHEDULE:
          return t("exceptions:Behind Schedule");
        case ShipmentException.CARRIER_DELAYED:
          return t("exceptions:Carrier Delayed");
        case ShipmentException.EXPIRED:
          return t("exceptions:Expired");
        case ShipmentException.IDLE_TRAIN:
          return t("exceptions:Idle Train");
        case ShipmentException.IN_HOLD:
          return t("exceptions:In Hold");
        case ShipmentException.LOST:
          return t("exceptions:Lost");
        case ShipmentException.MISSED_DROP_OFF:
          return t("exceptions:Missed Drop-Off");
        case ShipmentException.MISSED_PICKUP:
          return t("exceptions:Missed Pickup");
        case ShipmentException.UNDER_REVIEW:
          return t("exceptions:Under Review");
        case ShipmentException.OFF_ROUTE:
          return t("exceptions:Off Route");
        case ShipmentException.NOT_APPLICABLE:
          return t("exceptions:N/A");
        default: {
          if (exception) {
            logWarningForMissingTranslation(exception);
          }
          return exception;
        }
      }
    },
    [t],
  );

  const getTranslatedExceptionDescription = useCallback(
    (description: string) => {
      // An enum was not created for descriptions. We should be comparing with
      // some ID instead of this text. The descriptions are dynamic similar to
      // status updates. See useShipmentTranslation.getTranslatedStatusName
      switch (description) {
        case "Missed pickup window":
          // ShipmentException.MISSED_PICKUP
          return t("exceptions:Missed pickup window");
        case "Missed delivery window":
          // ShipmentException.MISSED_DROP_OFF
          return t("exceptions:Missed delivery window");
        case "Shipment Missed Delivery":
          return t("exceptions:Shipment Missed Delivery");
        case ShipmentException.OFF_ROUTE:
          return t("exceptions:Shipment is Off Route");
        default: {
          // Otherwise, the description is the exception name.
          return getTranslatedShipmentException(description);
        }
      }
    },
    [t, getTranslatedShipmentException],
  );

  return {
    getTranslatedShipmentException,
    getTranslatedExceptionDescription,
  };
};
