import { connect } from "react-redux";
import CarrierViewSearch from "./CarrierView.Search.page";
import CarrierViewSearchBarState from "../redux/CarrierViewSearchBarState";
import CarrierViewSavedSearchState from "../redux/CarrierViewSavedSearchState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";

function mapStateToProps(state) {
  const auth = getAuthorization(state);

  return {
    savedSearch:
      CarrierViewSearchBarState.selectors.getSelectedSavedSearch(state),
    vinSearchResults:
      CarrierViewSearchBarState.selectors.getSearchResults(state),
    showFilters:
      CarrierViewSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: CarrierViewSearchBarState.selectors.getIsLoading(state),
    loadingError: CarrierViewSearchBarState.selectors.getLoadingError(state),
    isLoadingError:
      CarrierViewSearchBarState.selectors.getIsLoadingError(state),
    sortColumn: CarrierViewSearchBarState.selectors.getSortColumn(state),
    reverseSort: CarrierViewSearchBarState.selectors.getReverseSort(state),
    page: CarrierViewSearchBarState.selectors.getPage(state),
    pageSize: CarrierViewSearchBarState.selectors.getPageSize(state),
    totalPages:
      CarrierViewSearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      CarrierViewSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      CarrierViewSearchBarState.selectors.getTotalCountForSearchIsLoading(
        state,
      ),
    exportIdentifier:
      CarrierViewSearchBarState.selectors.getExportIdentifier(state),
    exportName: CarrierViewSearchBarState.selectors.getExportName(state),
    isExporting: CarrierViewSearchBarState.selectors.getIsExporting(state),
    exportFailed: CarrierViewSearchBarState.selectors.getExportFailed(state),
    showItssData: auth.hasFeatures([Features.ITSS]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        CarrierViewSavedSearchState.actionCreators.loadSavedSearch(savedSearch),
      ),
    pushVinViewDetailsPage: (internalId) => {
      dispatch({
        type: "CARRIERVIEW_DETAILS",
        payload: { internal_id: internalId },
      });
    },
    toggleShowFilters: (showFilters) =>
      dispatch(
        CarrierViewSearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    setPagination: (page, pageSize) =>
      dispatch(
        CarrierViewSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        CarrierViewSearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: () => {
      dispatch(CarrierViewSearchBarState.actionCreators.exportSearch(null));
    },
    clearExportErrors: () => {
      dispatch(CarrierViewSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: () => {
      dispatch(
        CarrierViewSearchBarState.actionCreators.resetSearchAndFilters(),
      );
      dispatch(CarrierViewSearchBarState.actionCreators.searchEntities(null));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierViewSearch);
