/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { LinkPanels } from "components/templates/LinkPanels.template";
import { LinkPanel } from "components/molecules/LinkPanel.molecule";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

export const ApiGroupView = ({
  filename,
  fetchApiGroup,
  apiGroup,
  pushDocumentView,
}) => {
  const { t } = useTranslation("fv-vin-details");

  useSetTitleOnMount(t("documentation:Training & Resources"));
  useTrackWithMixpanelOnce("Viewing Page: Docs / API Group");

  useEffect(() => {
    fetchApiGroup(filename);
  }, [fetchApiGroup, filename]);

  const groups = () => {
    const groups = apiGroup && apiGroup.groups ? apiGroup.groups : [];

    return groups.map((group, i) => {
      return (
        <LinkPanels title={group.name} key={i}>
          {group.samples.map((sample, i) => {
            return (
              <LinkPanel
                title={sample.name}
                onClick={() => pushDocumentView(sample.file, "API-SAMPLE")}
              />
            );
          })}
        </LinkPanels>
      );
    });
  };

  return groups();
};

ApiGroupView.propTypes = {
  filename: PropTypes.string,
  fetchApiGroup: PropTypes.func,
  apiGroup: PropTypes.array,
  pushDocumentView: PropTypes.func,
};
