/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useTranslation } from "react-i18next";
import { Text, FontSize } from "components/atoms/Text.atom";
import { TimeCell } from "components/organisms/base-table/Cell/TimeCell";
import { usePartViewExceptionLabel } from "pages/partview/components/hooks/usePartViewExceptionLabel";

const useColumns = () => {
  const { t } = useTranslation("partview-details");
  const { getFullTranslatedNameForException } = usePartViewExceptionLabel();

  return [
    {
      Header: t("partview-details:Type"),
      accessor: (d) => d,
      Cell: (cellInfo) => {
        const exception = cellInfo.value;
        return (
          <Text block size={FontSize.size12}>
            {getFullTranslatedNameForException(exception)}
          </Text>
        );
      },
    },
    {
      Header: t("partview-details:Timestamp Set"),
      width: 150,
      accessor: "eventDatetime",
      Cell: (cellInfo) => {
        return <TimeCell dateTime={cellInfo.value} localize />;
      },
    },
    {
      Header: t("partview-details:Timestamp Cleared"),
      width: 150,
      accessor: "resolvedDateTime",
      Cell: (cellInfo) => {
        if (_.isNil(cellInfo.value) || cellInfo.value === "") {
          return "";
        } else {
          return <TimeCell dateTime={cellInfo.value} localize />;
        }
      },
    },
    {
      Header: t("partview-details:Comments"),
      accessor: "comment",
      Cell: (cellInfo) => {
        return (
          <Text block size={FontSize.size12}>
            {cellInfo.value}
          </Text>
        );
      },
    },
  ];
};

export const ExceptionTable = ({ exceptionList = [] }) => {
  const columns = useColumns();

  return (
    <BaseTable
      data-qa="active-exceptions"
      theme={Themes.LIGHT}
      columns={columns}
      data={exceptionList}
      disablePagination
    />
  );
};

ExceptionTable.propTypes = {
  exceptionList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      reasonCode: PropTypes.string,
      comment: PropTypes.string,
      datetime: PropTypes.string,
      eventDatetime: PropTypes.string,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      city: PropTypes.string,
      state: PropTypes.string,
      categoryCode: PropTypes.string,
      resolvedDateTime: PropTypes.string,
    }),
  ),
};
