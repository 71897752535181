/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { faBell, faBellOn } from "@fortawesome/pro-light-svg-icons";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import NotificationsDropdown from "./NotificationsDropdown";
import NotificationsState from "./NotificationsState";
import Colors from "../../styles/colors";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { getLocation } from "routes";

const { getHasUnreadNotifications, getNotificationUnreadCount } =
  NotificationsState.selectors;
const { fetchNotification } = NotificationsState.actionCreators;

const NotificationsHeader = () => {
  const { t } = useTranslation("header-bar");

  const dispatch = useDispatch();

  const hasUnreadNotifications = useSelector(getHasUnreadNotifications);
  const notificationUnreadCount = useSelector(getNotificationUnreadCount);
  const location = useSelector(getLocation);

  // Fetch notifications whenever we change the route location changes
  useEffect(() => {
    dispatch(fetchNotification({ pageSize: 10, page: 0 }));
  }, [dispatch, location]);

  return (
    <Dropdown
      id="notifications"
      responsiveModalMenu
      alignMenuRight
      autoClose="outside"
      text={t("header-bar:Notifications")}
      textStyle={{ color: Colors.formHeadingColor }}
      icon={
        <span css={{ position: "relative" }}>
          <Icon
            src={!hasUnreadNotifications ? faBell : faBellOn}
            size={
              notificationUnreadCount > 99 ? FontSize.size28 : FontSize.size20
            }
            color={
              hasUnreadNotifications
                ? Colors.notifications.HAS_NOTIFICATIONS
                : Colors.nav.NAVY
            }
          />
          {notificationUnreadCount ? (
            <Text
              color={Colors.notifications.HAS_NOTIFICATIONS}
              css={{
                fontSize: notificationUnreadCount > 99 ? "0.1rem" : "0.625rem",
                position: "absolute",
                top: "38%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {notificationUnreadCount > 99 ? "99+" : notificationUnreadCount}
            </Text>
          ) : null}
        </span>
      }
      css={{ height: "2.65em" }}
    >
      <NotificationsDropdown />
    </Dropdown>
  );
};

export default NotificationsHeader;
