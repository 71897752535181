/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { FontSize } from "components/atoms/enums";

export const LinkPanel = ({ title, description, onClick, className }) => {
  return (
    <PanelGroup
      className={className}
      css={{
        transitionDuration: "150ms",
        ":hover": {
          cursor: "pointer",
          transform: "scale(1.02)",
        },
      }}
      onClick={onClick}
    >
      <PanelGroup.Header
        title={title}
        titleSize={FontSize.size18}
        titleTruncate={false}
        style={{ padding: "0.5em" }}
      />
      <PanelGroup.Content>{description}</PanelGroup.Content>
    </PanelGroup>
  );
};

LinkPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
