import { createSelector } from "reselect";
import Authorization from "./Authorization";
import { getCurrentUser } from "modules/users/UsersState";
import {
  getFederationData,
  getFeatureData,
  getActiveOrganization,
  getEntitySystemConfigOptions,
  getOrganizationFilters,
} from "../organizations/OrganizationsState";
import AppConfigurationState from "shared/redux/AppConfigurationState";

export const getAuthorization = createSelector(
  [
    getCurrentUser,
    getActiveOrganization,
    getFederationData,
    getFeatureData,
    AppConfigurationState.selectors.getGlobalAppConfig,
    getEntitySystemConfigOptions,
    getOrganizationFilters,
  ],
  (
    currentUser,
    activeOrganization,
    federationData,
    featureData,
    globalAppConfig,
    entitySystemConfigOptions,
    organizationFilters,
  ) => {
    return new Authorization(
      currentUser,
      activeOrganization,
      federationData,
      featureData,
      globalAppConfig,
      entitySystemConfigOptions,
      organizationFilters,
    );
  },
);
