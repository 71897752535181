import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  currentPositionCodesUrl,
  productTypesUrl,
} from "modules/domain-data/CarrierViewDomainData";

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "carrierViewCurrentPositionCodeFilter",
  getUrl: (solutionId, state) => currentPositionCodesUrl(),
  pageSize: 20,
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "carrierViewProductTypeFilter",
  getUrl: (solutionId, state) => productTypesUrl(),
  pageSize: 20,
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});
