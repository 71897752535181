import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import apiUrl from "../../../../api-url";

export const locationOptionsState = buildAsyncFilterOptionsState({
  topic: "hsttLocationFilter",
  url: apiUrl("/containertracking/api/location"),
  getAdditionalParams: () => ({
    filter_name: "location_code",
  }),
  pageSize: 5,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: `${item.location_name} (${item.location_code})`,
    value: item.location_code,
  }),
});
// export const caseNumberOptionsState = buildAsyncFilterOptionsState({
//   topic: "hsttCaseNumberFilter",
//   url: apiUrl("/container-tracking-app/containertracking/suppliercode"),
//   getAdditionalParams: state => ({
//     filter_name: "supplier_code"
//   }),
//   pageSize: 20,
//   getResponseData: data => data?.data ?? [],
//   transformResult: item => ({
//     label: item,
//     value: item
//   })
// });
