/** @jsxImportSource @emotion/react */
import CodeSample from "../documentation-styled-components/CodeSample";

const expandedRowCss = {
  padding: 20,
};

// Content to display when an Milestone Logs search result row is expanded
export const getExpandedRow = (row, t) => {
  const { requestBody, requestId, errorMessage } = row.row.original;
  return (
    <div css={expandedRowCss} data-qa="row-expanded">
      <div>
        <strong>{t("documentation:Request ID")}</strong>:{" "}
        <span data-qa="text-request-id">{requestId}</span>
      </div>
      {errorMessage && (
        <div css={{ color: "red" }}>
          <strong>{t("documentation:Error Message")}</strong>:{" "}
          <span data-qa="text-error-message">{errorMessage}</span>
        </div>
      )}
      <div>
        <strong>{t("documentation:Body")}</strong>:
      </div>
      <CodeSample code={requestBody} />
    </div>
  );
};
