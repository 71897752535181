import moment from "moment";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import apiUrl from "api-url";
import { FILTERS } from "../components/search/ShipmentDwellNotification.searchOptions";

const STORE_MOUNT_POINT = "shipmentDwellSearch";

// URLs
export const SHIPMENT_DWELL_URL = apiUrl("/notification/dwell/search");

const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  const url = `${SHIPMENT_DWELL_URL}?prefill=0&${queryString}`;

  const config = {
    headers: { "x-time-zone": moment.tz.guess() },
  };

  const transformResponse = (response) => {
    return {
      meta: response.meta,
      data: response.results,
    };
  };

  dispatch(duck.fetch(url, config, transformResponse));
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "location_name",
    reverseSort: false,
  },
);
