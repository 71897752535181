/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import SectionHeader from "./SectionHeader";
import UrlBuilder from "./UrlBuilder";
import _ from "lodash";

const urlsCss = {
  padding: "1em 0",
};

const paths = (servers, method, path) => {
  return _.values(servers).map((server, i) => {
    return (
      <UrlBuilder
        key={i}
        method={method}
        path={path}
        serverUrl={server.url}
        serverDescription={server.description}
      />
    );
  });
};

const URLs = (props) => {
  const { title, servers, method, path } = props;

  return (
    <div css={urlsCss}>
      <SectionHeader title={title} />
      {paths(servers, method, path)}
    </div>
  );
};

URLs.propTypes = {
  title: PropTypes.string,
  servers: PropTypes.array,
  method: PropTypes.string,
  path: PropTypes.string,
};
export default URLs;
