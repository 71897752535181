import _ from "lodash";
import { generate } from "generate-password";

export function generatePassword() {
  return generate({
    length: 8,
    numbers: true,
    uppercase: true,
    lowercase: true,
    symbols: true,
    strict: true,
  });
}

export function isPassLengthValid(pass) {
  return _.size(pass) >= 8;
}

// Contains at least 3 of the following 4 types of characters:
//   Lower case letters (a-z)
//   Upper case letters (A-Z)
//   Numbers (0-9)
//   Special characters (!@#$%^&*()_+-=[]{};':"\|,.<>/?)
export function isPassRegexValid(pass) {
  /* eslint-disable-next-line */
  const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  let count = 0;

  if (/^.*[0-9].*/.test(pass)) {
    count++;
  }

  if (/^.*[a-z].*/.test(pass)) {
    count++;
  }

  if (/^.*[A-Z].*/.test(pass)) {
    count++;
  }

  if (specialChars.test(pass)) {
    count++;
  }

  return count >= 3;
}

export function isPassMatchValid(pass, cpass) {
  return !_.isEmpty(pass) && !_.isEmpty(cpass) ? pass === cpass : false;
}

export function doesPassContainEmailOrUsername(pass, email) {
  //Prevents the validation checkbox from showing green until
  //the user has inputted text.
  if (_.isEmpty(pass)) {
    return true;
  }

  const userName = email?.split("@")[0];
  return (
    !_.isEmpty(pass) &&
    !_.isEmpty(email) &&
    (pass.includes(email) || pass.includes(userName))
  );
}
