import { connect } from "react-redux";
import { getFeatureData } from "modules/organizations/OrganizationsState";
import { getSolutionId } from "../utils/ContainerTrackingUtils";
import ContainerTrackingSearch from "./ContainerTracking.Search.Page";
import ContainerTrackingSearchBarState from "../redux/ContainerTrackingSearchBarState";
import ContainerTrackingSavedSearchState from "../redux/ContainerTrackingSavedSearchState";

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(getFeatureData(state)),
    savedSearch:
      ContainerTrackingSearchBarState.selectors.getSelectedSavedSearch(state),
    searchResults:
      ContainerTrackingSearchBarState.selectors.getSearchResults(state),
    showFilters:
      ContainerTrackingSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: ContainerTrackingSearchBarState.selectors.getIsLoading(state),
    sortColumn: ContainerTrackingSearchBarState.selectors.getSortColumn(state),
    reverseSort:
      ContainerTrackingSearchBarState.selectors.getReverseSort(state),
    page: ContainerTrackingSearchBarState.selectors.getPage(state),
    pageSize: ContainerTrackingSearchBarState.selectors.getPageSize(state),
    totalPages:
      ContainerTrackingSearchBarState.selectors.getTotalPageCountForSearch(
        state,
      ),
    totalEntities:
      ContainerTrackingSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      ContainerTrackingSearchBarState.selectors.getTotalCountForSearchIsLoading(
        state,
      ),
    exportIdentifier:
      ContainerTrackingSearchBarState.selectors.getExportIdentifier(state),
    exportName: ContainerTrackingSearchBarState.selectors.getExportName(state),
    isExporting:
      ContainerTrackingSearchBarState.selectors.getIsExporting(state),
    exportFailed:
      ContainerTrackingSearchBarState.selectors.getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        ContainerTrackingSavedSearchState.actionCreators.loadSavedSearch(
          savedSearch,
        ),
      ),
    pushDetailsView: (id) =>
      dispatch({
        type: "CONTAINER_TRACKING_DETAILS",
        payload: { id },
      }),
    toggleShowFilters: (showFilters) =>
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.toggleShowFilters(
          showFilters,
        ),
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: (solutionId) => {
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.exportSearch(solutionId),
      );
    },
    clearExportErrors: () => {
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.clearExportErrors(),
      );
    },
    resetSearch: (solutionId) => {
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.resetSearchAndFilters(),
      );
      dispatch(
        ContainerTrackingSearchBarState.actionCreators.searchEntities(
          solutionId,
        ),
      );
    },
    resetExport: () => {
      dispatch(ContainerTrackingSearchBarState.actionCreators.resetExport());
    },
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ContainerTrackingSearch);
