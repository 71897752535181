import _ from "lodash";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "damageViewDashboard";

// Fetch ducks
const countWidgetDuck = buildFetchDuck(STORE_MOUNT_POINT, "countWidget");

//urls
const fetchCountWidgetData = (solutionId) => {
  if (!solutionId) {
    return;
  }
  return async (dispatch) => {
    const url = apiUrl(`/damageview/${solutionId}/counts/vin`);
    dispatch(countWidgetDuck.clear());
    dispatch(countWidgetDuck.fetch(url));
  };
};

// Selectors
const getIsCountWidgetDataLoading = (state) =>
  countWidgetDuck.selectors.getData(state)?.isLoading ?? false;

const getCountWidgetData = (state) => {
  return _.isEmpty(countWidgetDuck.selectors.getData(state)?.data)
    ? {}
    : countWidgetDuck.selectors.getData(state)?.data;
};

const DamageViewDashboardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchCountWidgetData,
  },
  selectors: {
    getCountWidgetData,
    getIsCountWidgetDataLoading,
  },
  reducer: chainReducers([countWidgetDuck.reducer]),
};
export default DamageViewDashboardState;
