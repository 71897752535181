import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { getSolutionId } from "modules/organizations/OrganizationsState";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import { parse } from "utils/json-utils";
import {
  INVENTORY_VIEW_DETAILS_FILTERS,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
} from "../details/search/InvertoryView.Details.Search.Options";

const STORE_MOUNT_POINT = "inventoryViewDetailsSearch";

const UPDATE_CURRENT_LOCATION = `${STORE_MOUNT_POINT}/UPDATE_CURRENT_LOCATION`;
const UPDATE_DWELL_FILTER = `${STORE_MOUNT_POINT}/UPDATE_DWELL_FILTER`;
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;
/**
 * Take an array of two date time strings and return an object of shape
 * {
 *   start: { date: "", time: "" },
 *   end: { date: "", time: "" }
 * }
 * The resulting values in the object will be localized
 * to the user's timezone
 *
 * These values are intended to be displayed to the user
 *
 * Note: Any part of this object can be undefined
 *
 * @param {[string?. string?]} timeWindow
 * @returns {{
 *   start: { date: string, time: string },
 *   end: { date: string, time: string }
 * }}
 */
const formatTimeWindow = (timeWindow) => {
  if (!Array.isArray(timeWindow)) {
    return {};
  }

  // We need to check if the different parts of the time window are defined
  // so that we don't pass undefineds to the localized date/time formatters

  const startEndRange = {};

  if (timeWindow[0]) {
    startEndRange.start = {
      date: localizedDateFormatter(timeWindow[0]),
      time: localizedTimeFormatter(timeWindow[0]),
    };
  }

  if (timeWindow[1]) {
    startEndRange.end = {
      date: localizedDateFormatter(timeWindow[1]),
      time: localizedTimeFormatter(timeWindow[1]),
    };
  }

  return startEndRange;
};

const findEta = (item) => {
  let timeWindow = [];

  // Use eta if it exists
  if (item.locationData?.inbound?.eta) {
    timeWindow = [item.locationData.inbound.eta];
  }
  // If eta does not exist, use the scheduledWindow if it exists
  else if (item.locationData?.inbound?.scheduledWindow) {
    timeWindow = parse(item.locationData.inbound.scheduledWindow);
  }

  return formatTimeWindow(timeWindow);
};

// H2-1081: use shipment exception if there is one
const getStatus = (item) => {
  return _.get(item, "shipmentActiveException", "On Time");
};

const findLastLocation = (item) => {
  if (!item.lastPositionUpdate) {
    return "";
  }

  if (!item.lastPositionUpdate.city || !item.lastPositionUpdate.state) {
    return "";
  }

  return `${item.lastPositionUpdate.city}, ${item.lastPositionUpdate.state}`;
};

const findAvailableForPickup = (item) => {
  let timeWindow = [];

  if (item.activeScheduledPickup) {
    timeWindow = parse(item.activeScheduledPickup);
  }

  return formatTimeWindow(timeWindow);
};

const findNextPlannedDestination = (item) => {
  const value = item.nextPlannedDestination;
  return value && value.name ? `${value.name} (${value.code})` : null;
};

const findScheduledPickup = (item) => {
  let timeWindow = [];

  const scheduledWindow = item.locationData?.outbound?.scheduledWindow;
  if (scheduledWindow) {
    timeWindow = parse(scheduledWindow);
  }

  return formatTimeWindow(timeWindow);
};

/**
 * Transform the returned data into a format easily understadable by our table
 */
const transformEntities = (payload) => {
  payload.data = payload.data.map((item) => {
    return {
      id: item.id,
      internal_id: item.internal_id,
      productType: item.description || "",
      shippable: item?.shippable ?? false,
      lastLocation: findLastLocation(item),
      eta: findEta(item),
      status: getStatus(item),
      destinationDealer: item.ultimateDestination,
      geofenceName: item.geofenceName,
      availableForPickup: findAvailableForPickup(item),
      scheduledPickup: findScheduledPickup(item),
      dwellTimer: item.currentDwell,
      currentAge: item?.currentAge,
      nextPlannedDestination: findNextPlannedDestination(item),
      nextActiveCarrierName: item.nextActiveCarrierName,
      locationData: item.locationData,
      carrierName: item.plannedCarrierName,
      lastPositionUpdate: item.lastPositionUpdate,
      watch: item?.watch ?? false,
    };
  });
  return payload;
};

const formatBaseQueryString = (queryString) => {
  const params = qs.parse(queryString);
  params.lifeCycleState = "Active";
  params.invDetailsSearch = "onsite";

  params.sortColumn = "dwell";
  params.reverseSort = "1";

  return qs.stringify(params);
};

const formatQueryStingForCount = (queryString) => {
  const params = qs.parse(queryString);

  params.pageNumber = undefined;
  params.pageSize = undefined;

  return qs.stringify(params);
};

const inventoryViewDetailsUrl = (solutionId, queryString) => {
  return apiUrl(`/entity/solution/${solutionId}/entity?${queryString}`);
};

const entitiesUrl = (solutionId, qs, state) => {
  const formattedQueryString = formatBaseQueryString(qs);
  return inventoryViewDetailsUrl(
    solutionId,
    `${formattedQueryString}&currentLocation=${state[STORE_MOUNT_POINT].currentLocation}`,
  );
};

const entitiesUrlForCount = (solutionId, qs, state) => {
  const formattedQueryString = formatQueryStingForCount(
    formatBaseQueryString(qs),
  );
  return inventoryViewDetailsUrl(
    solutionId,
    `${formattedQueryString}&currentLocation=${state[STORE_MOUNT_POINT].currentLocation}`,
  );
};

const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const formattedQueryString = formatBaseQueryString(queryString);
  let url = inventoryViewDetailsUrl(
    solutionId,
    `${formattedQueryString}&currentLocation=${state[STORE_MOUNT_POINT].currentLocation}`,
  );

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=fin_inventoryview",
    },
  };
  // Fetch the search
  dispatch(duck.fetch(url, config, transformEntities));

  // Fetch the count
  dispatch(fetchTotalCount(solutionId, queryString, config));
};

const setWatchEntity = (solutionId, entityId, internal_id, watch = true) => {
  const url = solutionId
    ? apiUrl(`/entity/solution/${solutionId}/entity/${entityId}`)
    : apiUrl(`/entity/internal/${internal_id}`);
  return (dispatch) => {
    return Promise.all([axios.patch(url, { watch })])
      .then((responses) => {
        dispatch(SearchBarState.actionCreators.searchEntities(solutionId));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
};

const fetchTotalCount = (solutionId, queryString, config, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();
    const url = entitiesUrlForCount(solutionId, queryString, state);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      ...config,
      method: "GET",
      url,
      data: undefined,
      headers: {
        ...config.headers,
        Accept: "application/json;version=count",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Selectors

const getDwellFilterValue = (state) => {
  return state[STORE_MOUNT_POINT].dwellFilterValue;
};

const setCurrentLocation = (id) => {
  return { type: UPDATE_CURRENT_LOCATION, payload: id };
};

const setDwellFilterValue = (val) => {
  return { type: UPDATE_DWELL_FILTER, payload: val };
};

const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

const detailsSearchState = (
  state = {
    currentLocation: null,
    dwellFilterValue: null,
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case UPDATE_DWELL_FILTER:
      return {
        ...state,
        dwellFilterValue: action.payload,
      };

    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: state.totalCountForSearch ? false : true,
      };

    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };

    default:
      return state;
  }
};

// Selectors

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  INVENTORY_VIEW_DETAILS_FILTERS,
  fetchSearch,
  [detailsSearchState],
);

SearchBarState.actionCreators.exportSearch = () => {
  return (dispatch, getState) => {
    const currentSolutionId = getSolutionId(getState());
    dispatch(
      SearchBarState.actionCreators.exportEntities(
        entitiesUrl,
        null,
        { headers: { Accept: "text/csv;version=fin_inventoryview" } },
        "inventory-view-vin-search-results",
        currentSolutionId,
      ),
    );
  };
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getDwellFilterValue,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  setCurrentLocation,
  setDwellFilterValue,
  setWatchEntity,
};

export default SearchBarState;
