import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useGrantedFeaturesOptions = () => {
  const { t } = useTranslation(["organizations"]);
  return useMemo(() => {
    return [
      {
        label: t("organizations:Enable Dealer Pick Up"),
        value: "Enable Dealer Pick Up",
      },
    ];
  }, [t]);
};
