export class RequiredParameterError extends Error {
  constructor(parameterName, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequiredParameterError);
    }

    this.name = "RequiredParameterError";
    this.message = `'${parameterName}' is missing`;
  }
}

export class InvalidValueError extends Error {
  constructor(parameterName, badValue, allowedValues = [], ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidValueError);
    }

    this.name = "InvalidValueError";
    this.message = `Received \`${badValue}\` for '${parameterName}'`;
    if (allowedValues?.length > 0) {
      this.message += `, allowed values are: ${allowedValues
        .map((v) => JSON.stringify(v))
        .join(" ,")}`;
    }
  }
}
