/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";
import { ExpiringRacks } from "./mt-dashboard-components/ExpiringRacks";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { MediaQueries } from "components/responsive";
import SearchBarContainer from "../mt-search/MetalTrackingSearchBarContainer";
import FilterSectionContainer from "../mt-search/MetalTrackingFilterSectionContainer";
import DataHeader from "./mt-dashboard-components/DataHeader";
import SavedSearchContainer from "../mt-search/MetalTrackingSavedSearchContainer";
import { RacksByLocation } from "./mt-dashboard-components/RacksByLocation";
import { RacksByType } from "./mt-dashboard-components/RacksByType";
import { resultsTableColumns } from "./mt-dashboard-components/results-table-columns";

export const MetalTrackingDashboard = ({
  authorization,
  solutionId,
  metalTrackingEntities,
  toggleWatchedRackFlag,
  setSearchFilter,
  chooseLocation,
  showFilters,
}) => {
  const { t } = useTranslation("mt-dashboard");

  const [showFilter, setShowFilter] = useState(showFilters);

  const watchedRacks = metalTrackingEntities
    ? metalTrackingEntities.filter((obj) => obj.watch === true)
    : [];

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <SearchBarContainer
        isShowingFilters={showFilter}
        toggleShowFilters={() => setShowFilter(!showFilter)}
      />
      <FilterSectionContainer
        show={showFilter}
        backgroundColor={Colors.background.GRAY}
        sectionStyle={{ paddingRight: "8em" }}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          css={{
            [MediaQueries.smallAndUp]: {
              display: "flex",
              flexDirection: "column",
            },
            [MediaQueries.mediumAndUp]: {
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(6, 1fr)",
              display: "grid",
              gridTemplateRows: "420px 1fr auto",
            },
            [MediaQueries.largeAndUp]: {
              gridTemplateRows: "233px auto",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "1 / span 2",
              },
              [MediaQueries.largeAndUp]: {
                gridColumn: "1 / span 2",
                gridRow: "1 / span 1",
              },
            }}
          >
            <ExpiringRacks
              solutionId={solutionId}
              metalTrackingEntities={metalTrackingEntities}
              setSearchFilter={setSearchFilter}
            />
          </div>
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "3 / span 2",
                gridRow: "1 / span 1",
              },
              [MediaQueries.largeAndUp]: {
                gridColumn: "3 / span 2",
                gridRow: "1 / span 1",
              },
            }}
          >
            <RacksByLocation
              solutionId={solutionId}
              metalTrackingEntities={metalTrackingEntities}
              setSearchFilter={setSearchFilter}
            />
          </div>
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                marginBottom: "20px",
              },
              [MediaQueries.mediumAndUp]: {
                marginBottom: 0,
                gridColumn: "5 / span 2",
                gridRow: "1 / span 1",
              },
              [MediaQueries.largeAndUp]: {
                gridColumn: "5 / span 2",
                gridRow: "1 / span 1",
              },
            }}
          >
            <RacksByType
              solutionId={solutionId}
              metalTrackingEntities={metalTrackingEntities}
              setSearchFilter={setSearchFilter}
            />
          </div>
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                gridColumn: "1 / span 6",
                gridRow: "3 / auto",
              },
              [MediaQueries.mediumAndUp]: {
                gridColumn: "1 / span 6",
                gridRow: "2 / auto",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DataHeader
                title={t("mt-dashboard:Watched Racks")}
                total={watchedRacks.length || 0}
              />
              <SavedSearchContainer />
            </div>

            <BaseTable
              data={watchedRacks}
              columns={resultsTableColumns(
                t,
                solutionId,
                authorization,
                toggleWatchedRackFlag,
                chooseLocation,
              )}
              fixPaginationToBottom={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MetalTrackingDashboard.propTypes = {
  authorization: PropTypes.object.isRequired,
  chooseLocation: PropTypes.func,
  metalTrackingEntities: PropTypes.array,
  setSearchFilter: PropTypes.func,
  showFilters: PropTypes.bool,
  solutionId: PropTypes.string,
  toggleWatchedRackFlag: PropTypes.func,
};
