/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isCarrier, isPartner } from "shared/utils/organizations.utils";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Loader from "react-loader";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import { Dashboard } from "components/templates/Dashboard.template";
import { ResponsiveSection } from "styles/container-elements";
import { AllSubmissionWidgets } from "./components/DamageView.AllSubmissionsWidgets";
import { WatchedSubmissionsWidget } from "./components/DamageView.WatchedSubmissionsWidget";
import { MySubmissionsWidget } from "./components/DamageView.MySubmissionsWidget";
import SearchBarContainer from "../components/search/DamageView.SearchBar.container";
import SearchFiltersContainer from "../components/search/DamageView.SearchFilters.container";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import { useEffect } from "react";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import hold from "assets/icons/hold.svg";
import { IconType } from "components/atoms/enums";

const DamageViewDashboard = ({
  isFieldsLoading,
  fetchShippers,
  shipperOptions,
  currentOrganization,
  solutionId,
  showFilters,
  toggleShowFilters,
  setSearchFilter,
  searchEntities,
  fetchSubmissionData,
  isSubmissionDataLoading,
  submissionData,
  fetchWatchedSubmissions,
  isWatchSubmissionsLoading,
  watchSubmissionsData,
  watchSubmissionPageSize,
  watchSubmissionPageCount,
  watchSubmissonPageIndex,
  setWatchedSubmissonsPageIndex,
  fetchMySubmissions,
  isMySubmissionsLoading,
  mySubmissionsData,
  mySubmissonPageIndex,
  mySubmissionPageSize,
  mySubmissionPageCount,
  setMySubmissonsPageIndex,
  exportSubmissonData,
  userPreferences,
  exportIdentifier,
  exportName,
  resetExport,
  clearExportErrors,
  exportFailed,
  isExporting,
  submitDamageForm,
  fetchFields,
  fields,
  pushDamageViewDetailsPage,
  resetSearchBar,
  clearSearchFilters,
  statusConfig,
  mySubmissionsSortColumn,
  mySubmissionsReverseSort,
  mySubmissionsDefaultSortColumn,
  mySubmissionsDefaultReverseSort,
  mySubmissionsSetSort,
  showDamagedVinsCountWidget,
  countWidgetData,
  isCountWidgetDataLoading,
  fetchCountWidgetData,
  currentFiltersForSubmissionData,
}) => {
  const { t } = useTranslation("damage-view");
  useSetTitleOnMount(t("damage-view:DamageView"));
  useSetDescriptionOnMount(
    t(
      "damage-view:DamageView is a simple workflow that allows complete tracking of damaged vehicle submissions.",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: DamageView / Dashboard");

  useEffect(() => {
    resetSearchBar(true);
    clearSearchFilters();
    if (showDamagedVinsCountWidget) {
      fetchCountWidgetData(solutionId);
    }
    // We want this effect only to run once
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isCarrier(currentOrganization) && !isPartner(currentOrganization)) {
      fetchFields();
    }
    fetchShippers();
  }, [fetchShippers, fetchFields, currentOrganization]);

  const totalCount = countWidgetData?.totalDamagedVins ?? 0;
  const withHoldCount = countWidgetData?.damagedVinsWithHold ?? 0;
  const withoutHoldCount = countWidgetData?.damagedVinsWithoutHold ?? 0;

  const exceptionGroups = [
    {
      title: t("damage-view:Count of Unique Open VINs"),
      includeInDonutChart: true,
      style: { flexGrow: 1 },
      exceptions: [
        {
          id: 1,
          name: t("damage-view:Open VINs Without [[[escapeCode]]] Holds", {
            escapeCode: "4D:LD",
          }),
          count: withoutHoldCount,
          icon: {
            color: Colors.exceptions.DEFAULT,
          },
          percentage:
            totalCount === 0
              ? 0
              : ((withoutHoldCount / totalCount) * 100).toFixed(1),
        },
        {
          id: 2,
          name: t("damage-view:Open VINs With [[[escapeCode]]] Holds", {
            escapeCode: "4D:LD",
          }),
          count: withHoldCount,
          icon: {
            type: IconType.LocalImage,
            src: hold,
            color: Colors.exceptions.ON_HOLD,
          },
          percentage:
            totalCount === 0
              ? 0
              : ((withHoldCount / totalCount) * 100).toFixed(1),
        },
      ],
      isLoading: isCountWidgetDataLoading,
    },
  ];

  const handleClickGraph = () => {
    clearSearchFilters();
    searchEntities(solutionId);
  };

  const handleClickException = (e) => {
    if (
      e &&
      e.name ===
        t("damage-view:Open VINs With [[[escapeCode]]] Holds", {
          escapeCode: "4D:LD",
        })
    ) {
      clearSearchFilters();
      setSearchFilter("status", ["Submitted", "Major", "Minor", "Warranty"]);
      setSearchFilter("damageHold", ["true"]);
      searchEntities(solutionId);
    } else if (
      e &&
      e.name ===
        t("damage-view:Open VINs Without [[[escapeCode]]] Holds", {
          escapeCode: "4D:LD",
        })
    ) {
      clearSearchFilters();
      setSearchFilter("status", ["Submitted", "Major", "Minor", "Warranty"]);
      setSearchFilter("damageHold", ["false"]);
      searchEntities(solutionId);
    }
  };

  return (
    <div>
      <Loader loaded={!isExporting} className="spinner" shadow={true} />
      <Dashboard
        SearchBarContainer={SearchBarContainer}
        FiltersContainer={SearchFiltersContainer}
        showFilters={showFilters}
        toggleShowFilters={toggleShowFilters}
      >
        <ExportAlert
          exportFailed={exportFailed}
          onDismiss={clearExportErrors}
          className="my-2"
        />
        <ExportModal
          exportIdentifier={exportIdentifier}
          exportName={exportName}
          resetExport={resetExport}
        />
        <ResponsiveSection
          style={{
            justifyContent: "flex-start",
            background: Colors.background.LIGHT_GRAY,
            height: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div
              className="my-2"
              css={{
                width: "100%",
                display: "flex",
                gap: "1em",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "column",
                },
                [MediaQueries.mediumAndUp]: {
                  flexDirection: "row",
                },
              }}
            >
              <div
                css={{
                  [MediaQueries.smallAndDown]: {
                    marginBottom: "20px",
                  },
                  [MediaQueries.mediumAndUp]: {
                    width: showDamagedVinsCountWidget ? "50%" : "40%",
                  },
                }}
              >
                <AllSubmissionWidgets
                  showShipperOption={
                    isCarrier(currentOrganization) ||
                    isPartner(currentOrganization)
                  }
                  shipperOptions={shipperOptions}
                  isFieldsLoading={isFieldsLoading}
                  fetchFields={fetchFields}
                  submitDamageForm={submitDamageForm}
                  fields={fields}
                  solutionId={solutionId}
                  fetchSubmissionData={fetchSubmissionData}
                  isLoading={isSubmissionDataLoading}
                  submissionData={submissionData}
                  currentFiltersForSubmissionData={
                    currentFiltersForSubmissionData
                  }
                  setSearchFilter={setSearchFilter}
                  searchEntities={searchEntities}
                  statusConfig={statusConfig}
                />
              </div>
              {showDamagedVinsCountWidget ? (
                <div
                  css={{
                    [MediaQueries.smallAndDown]: {
                      marginBottom: "20px",
                    },
                    [MediaQueries.mediumAndUp]: {
                      width: showDamagedVinsCountWidget ? "50%" : "60%",
                    },
                  }}
                >
                  <ExceptionsPanel
                    title={t("damage-view:Unique VINs with Open Damages")}
                    exceptionGroups={exceptionGroups}
                    totalCount={totalCount}
                    totalCountIsLoading={isCountWidgetDataLoading}
                    totalCountLabel={t("damage-view:VINs")}
                    handleClickException={handleClickException}
                    handleClickGraph={handleClickGraph}
                  />
                </div>
              ) : null}
              {!showDamagedVinsCountWidget ? (
                <div
                  css={{
                    [MediaQueries.smallAndDown]: {
                      marginBottom: "20px",
                    },
                    [MediaQueries.mediumAndUp]: {
                      width: "60%",
                    },
                  }}
                >
                  <WatchedSubmissionsWidget
                    solutionId={solutionId}
                    exportSubmissonData={exportSubmissonData}
                    userPreferences={userPreferences}
                    fetchWatchedSubmissionsData={fetchWatchedSubmissions}
                    fetchMySubmissionsData={fetchMySubmissions}
                    isLoading={isWatchSubmissionsLoading}
                    watchedSubmissionsData={watchSubmissionsData}
                    watchedSubmissionsPageIndex={watchSubmissonPageIndex}
                    watchedSubmissionsPageSize={watchSubmissionPageSize}
                    watchedSubmissionsPageCount={watchSubmissionPageCount}
                    setWatchedSubmissionsPageIndex={
                      setWatchedSubmissonsPageIndex
                    }
                    pushDamageViewDetailsPage={pushDamageViewDetailsPage}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {showDamagedVinsCountWidget ? (
            <div
              css={{
                marginBottom: "20px",
              }}
            >
              <WatchedSubmissionsWidget
                solutionId={solutionId}
                exportSubmissonData={exportSubmissonData}
                userPreferences={userPreferences}
                fetchWatchedSubmissionsData={fetchWatchedSubmissions}
                fetchMySubmissionsData={fetchMySubmissions}
                isLoading={isWatchSubmissionsLoading}
                watchedSubmissionsData={watchSubmissionsData}
                watchedSubmissionsPageIndex={watchSubmissonPageIndex}
                watchedSubmissionsPageSize={watchSubmissionPageSize}
                watchedSubmissionsPageCount={watchSubmissionPageCount}
                setWatchedSubmissionsPageIndex={setWatchedSubmissonsPageIndex}
                pushDamageViewDetailsPage={pushDamageViewDetailsPage}
              />
            </div>
          ) : null}
          <div>
            <MySubmissionsWidget
              solutionId={solutionId}
              exportSubmissonData={exportSubmissonData}
              userPreferences={userPreferences}
              isLoading={isMySubmissionsLoading}
              fetchWatchedSubmissionsData={fetchWatchedSubmissions}
              fetchMySubmissionsData={fetchMySubmissions}
              mySubmissionsData={mySubmissionsData}
              mySubmissionsPageCount={mySubmissionPageCount}
              mySubmissionsPageIndex={mySubmissonPageIndex}
              mySubmissionsPageSize={mySubmissionPageSize}
              setMySubmissionsPageIndex={setMySubmissonsPageIndex}
              pushDamageViewDetailsPage={pushDamageViewDetailsPage}
              sortColumn={mySubmissionsSortColumn}
              reverseSort={mySubmissionsReverseSort}
              defaultSortColumn={mySubmissionsDefaultSortColumn}
              defaultReverseSort={mySubmissionsDefaultReverseSort}
              setSort={mySubmissionsSetSort}
            />
          </div>
        </ResponsiveSection>
      </Dashboard>
    </div>
  );
};

DamageViewDashboard.propTypes = {
  currentOrganization: PropTypes.object,
  solutionId: PropTypes.string,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func,
  setSearchFilter: PropTypes.func,
  searchEntities: PropTypes.func,
  isSubmissionDataLoading: PropTypes.bool,
  fetchSubmissionData: PropTypes.func,
  submissionData: PropTypes.array,
  isWatchSubmissionsLoading: PropTypes.bool,
  watchSubmissionsData: PropTypes.array,
  fetchWatchedSubmissions: PropTypes.func,
  watchSubmissonPageIndex: PropTypes.number,
  watchSubmissionPageSize: PropTypes.number,
  watchSubmissionPageCount: PropTypes.number,
  setWatchedSubmissonsPageIndex: PropTypes.func,
  fetchMySubmissions: PropTypes.func,
  isMySubmissionsLoading: PropTypes.bool,
  mySubmissionsData: PropTypes.array,
  mySubmissonPageIndex: PropTypes.number,
  mySubmissionPageSize: PropTypes.number,
  mySubmissionPageCount: PropTypes.number,
  setMySubmissonsPageIndex: PropTypes.func,
  exportSubmissonData: PropTypes.func,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  resetExport: PropTypes.func,
  clearExportErrors: PropTypes.func,
  exportFailed: PropTypes.bool,
  isExporting: PropTypes.bool,
  resetSearchBar: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  statusConfig: PropTypes.array,
  mySubmissionsSortColumn: PropTypes.string,
  mySubmissionsReverseSort: PropTypes.bool,
  mySubmissionsDefaultSortColumn: PropTypes.string,
  mySubmissionsDefaultReverseSort: PropTypes.bool,
  mySubmissionsSetSort: PropTypes.func.isRequired,
  showDamagedVinsCountWidget: PropTypes.bool,
  countWidgetData: PropTypes.object,
  isCountWidgetDataLoading: PropTypes.bool,
  fetchCountWidgetData: PropTypes.func,
  currentFiltersForSubmissionData: PropTypes.object,
};

export default DamageViewDashboard;
