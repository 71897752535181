import apiUrl from "api-url";

import buildSavedSearchState from "components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "pages/partview/components/search/PartView.searchOptions";
import SearchState from "pages/partview/redux/PartViewSearchBarState";

const STORE_MOUNT_POINT = "partViewSavedSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "PARTVIEW",
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter,
  },
});
