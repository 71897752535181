import axios from "axios";
import moment from "moment";
import _ from "lodash";
import apiUrl from "../../api-url";

// URLS
const BASE_URL = apiUrl("/shipping-ng");
const EVENTS_URL = `${BASE_URL}/shipments/exceptions`;

// Actions
const RECEIVE_EVENTS = "events/RECEIVE_EVENTS";
const EXTRACT_EVENTS = "events/EXTRACT_EVENTS";
const CLEAR_EVENTS = "events/CLEAR_EVENTS";

export function clearEvents() {
  return (dispatch) => {
    dispatch({ type: CLEAR_EVENTS, data: null });
  };
}

export function extractEventsFromShipments(shipments) {
  return (dispatch) => {
    dispatch({ type: EXTRACT_EVENTS, data: shipments });
  };
}

export function fetchEvents() {
  return (dispatch) =>
    Promise.all([axios.get(`${EVENTS_URL}?recent=100`)])
      .then((responses) => {
        dispatch({ type: RECEIVE_EVENTS, data: responses[0].data });
      })
      .catch((err) => {
        throw new Error(err);
      });
}

const initialState = {
  data: [],
};

function EventsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_EVENTS:
      return initialState;

    case RECEIVE_EVENTS:
      const createdEvents = [];
      function createEvent(data, index) {
        createdEvents.push({
          shipment_id: data.shipment_id,
          shipment: data.creator_shipment_id,
          type: data.event_name,
        });
      }

      action.data.forEach(createEvent);
      return {
        ...state,
        data: createdEvents,
      };

    case EXTRACT_EVENTS:
      const extractedEvents = [];

      action.data.forEach((shipment) => {
        if (shipment.shipment_statuses) {
          shipment.shipment_statuses.forEach((status) => {
            extractedEvents.push({
              shipment_id: shipment.id,
              shipment: shipment.creator_shipment_id,
              type: status.status_name,
              time: moment.utc(status.actual_created_at),
            });
          });
        }
      });

      return {
        ...state,
        data: _.sortBy(extractedEvents, "time"),
      };

    default:
      return state;
  }
}

export default EventsReducer;
