import { connect } from "react-redux";
import {
  getSolutionId,
  getCurrentOrganizationId,
} from "modules/organizations/OrganizationsState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventoryViewDetails from "./InventoryView.Details.page";
import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import InventoryFcVinsSearchBarState from "../redux/InventoryViewDetailsForecastedVinsSearchBarState";
import InventoryViewDashboardState from "../redux/InventoryViewDashboardState";
import MapState from "modules/map/MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { OrganizationType } from "shared/constants/organization.const";

function mapStateToProps(state) {
  const locationId = state.location.payload.location_id;
  const {
    getLocation,
    getSelectedTabIndex,
    getForcastDateRange,
    getIsForecastDataLoading,
    getForecastData,
  } = InventoryViewDetailsState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getExportName,
    getExportIdentifier,
    getIsExporting,
    getExportFailed,
    getShowAdvancedSearch,
    getSortColumn,
    getReverseSort,
    getTotalPageCountForSearch,
    getTotalCountForSearch,
    getTotalCountForSearchIsLoading,
    getDwellFilterValue,
  } = InventorySearchBarState.selectors;

  const { getMapCoordinate } = MapState.selectors;
  return {
    solutionId: getSolutionId(state),
    organizationId: getCurrentOrganizationId(state),
    locationId: locationId,
    location: getLocation(state),
    selectedTabIndex: getSelectedTabIndex(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPageCountForSearch(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    isLoading: getIsLoading(state),
    isOnsiteVinsCountLoading: getTotalCountForSearchIsLoading(state),
    searchResults: getSearchResults(state),
    exportName: getExportName(state),
    exportIdentifier: getExportIdentifier(state),
    isExporting: getIsExporting(state),
    exportFailed: getExportFailed(state),
    totalCount: getTotalCountForSearch(state),
    showFilters: getShowAdvancedSearch(state),
    isFcVinsSearchLoading:
      InventoryFcVinsSearchBarState.selectors.getIsLoading(state),
    isFcVinsTotalCountForSearchLoading:
      InventoryFcVinsSearchBarState.selectors.getTotalCountForSearchIsLoading(
        state,
      ),
    fcVinsTotalCountForSearch:
      InventoryFcVinsSearchBarState.selectors.getTotalCountForSearch(state),
    fcVinsSearchResults:
      InventoryFcVinsSearchBarState.selectors.getSearchResults(state),
    fcVinsTotalEntities:
      InventoryFcVinsSearchBarState.selectors.getTotalCountForSearch(state),
    fcVinsShowFilters:
      InventoryFcVinsSearchBarState.selectors.getShowAdvancedSearch(state),
    fcVinsTotalPages:
      InventoryFcVinsSearchBarState.selectors.getTotalPageCountForSearch(state),
    fcVinsPage: InventoryFcVinsSearchBarState.selectors.getPage(state),
    fcVinsPageSize: InventoryFcVinsSearchBarState.selectors.getPageSize(state),
    fcVinsExportIdentifier:
      InventoryFcVinsSearchBarState.selectors.getExportIdentifier(state),
    fcVinsExportName:
      InventoryFcVinsSearchBarState.selectors.getExportName(state),
    fcVinsIsExporting:
      InventoryFcVinsSearchBarState.selectors.getIsExporting(state),
    fcVinsExportFailed:
      InventoryFcVinsSearchBarState.selectors.getExportFailed(state),
    fcVinsSortColumn:
      InventoryFcVinsSearchBarState.selectors.getSortColumn(state),
    fcVinsReverseSort:
      InventoryFcVinsSearchBarState.selectors.getReverseSort(state),
    dwellFilterValue: getDwellFilterValue(state),
    selectedMapCoordinate: getMapCoordinate(MapCoordinateType.CURRENT_LOCATION)(
      state,
    ),
    forecastDateRange: getForcastDateRange(state),
    forecastData: getForecastData(state),
    isForecastDataLoading: getIsForecastDataLoading(state),
    canRedirectToVinDetails: getAuthorization(state).hasOrganizationTypes([
      OrganizationType.SHIPPER,
      OrganizationType.CARRIER,
    ]),
  };
}

const {
  fetchLocation,
  setSelectedTabIndex,
  setForecastDateRange,
  fetchForecastData,
} = InventoryViewDetailsState.actionCreators;
const { addCoordinate } = MapState.actionCreators;
const {
  searchEntities,
  setPagination,
  setCurrentLocation,
  resetSearchAndFilters,
  toggleShowFilters,
  setSort,
  clearExportErrors,
  resetExport,
  setSearchFilter,
  setDwellFilterValue,
  setWatchEntity,
} = InventorySearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  setDwellFilterValue,
  addCoordinate,
  resetSearchAndFilters,
  setForecastDateRange,
  fetchForecastData,
  onsiteVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(resetSearchAndFilters());
    dispatch(searchEntities(solutionId));
  },
  setOnsiteVinsWatchEntity:
    (solutionId, vin, internal_id, newWatchValue) => (dispatch) =>
      dispatch(setWatchEntity(solutionId, vin, internal_id, newWatchValue)),
  clearExportErrors,
  fetchLocation: (solutionId, locationId) => (dispatch) =>
    dispatch(fetchLocation(solutionId, locationId)),
  setSelectedTabIndex,
  setDetailsSearchSort: (solutionId, sortColumn, reverse) => (dispatch) =>
    dispatch(setSort(solutionId, sortColumn, reverse)),
  setDetailsSearchPagination: (solutionId, page, pageSize) => (dispatch) =>
    dispatch(setPagination(solutionId, page, pageSize)),
  fetchVinSearch: (solutionId, locationId) => (dispatch) => {
    dispatch(setCurrentLocation(locationId));
    dispatch(searchEntities(solutionId));
  },
  toggleShowFilters: (showFilters) => (dispatch) => {
    dispatch(toggleShowFilters(showFilters));
  },
  exportSearch: () => (dispatch) => {
    dispatch(InventorySearchBarState.actionCreators.exportSearch());
  },
  resetExport,
  fcVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.resetSearchAndFilters(),
    );
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.searchEntities(solutionId),
    );
  },
  fcVinSearch: (solutionId) => (dispatch) => {
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.searchEntities(solutionId),
    );
  },
  fcVinsToggleShowFilters: (showFilters) => (dispatch) => {
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.toggleShowFilters(
        showFilters,
      ),
    );
  },
  fcVinsSetPagination: (solutionId, page, pageSize) => (dispatch) => {
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.setPagination(
        solutionId,
        page,
        pageSize,
      ),
    );
  },
  fcVinsSetSort: (solutionId, sortColumn, reverse) => (dispatch) =>
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.setSort(
        solutionId,
        sortColumn,
        reverse,
      ),
    ),
  fcVinsExportSearch: () => (dispatch) => {
    dispatch(InventoryFcVinsSearchBarState.actionCreators.exportSearch());
  },
  fvVinsClearExportErrors:
    InventoryFcVinsSearchBarState.actionCreators.clearExportErrors(),
  fvVinsResetExport: InventoryFcVinsSearchBarState.actionCreators.resetExport(),
  setForecastedVinsWatchEntity:
    (solutionId, vin, internal_id, newWatchValue) => (dispatch) =>
      dispatch(
        InventoryFcVinsSearchBarState.actionCreators.setWatchEntity(
          solutionId,
          vin,
          internal_id,
          newWatchValue,
        ),
      ),
  redirectToVinDetails:
    InventoryViewDashboardState.actionCreators.redirectToVinDetails,
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDetails);
