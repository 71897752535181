import { useTranslation } from "react-i18next";

export enum PartViewMilestoneCode {
  ARRIVED = "AR",
  DEPARTED = "DP",
  DELIVERED = "FD",
}

// TODO: Combine this function with the usePartViewMilestone hook.
// This is separate because we call this from another utility function
// which does not have access to translations
export const getDescriptionFromMilestoneCode = (
  code: string,
  locationName: string,
) => {
  let description;

  if (code === PartViewMilestoneCode.ARRIVED) {
    description = locationName ? `Arrived at ${locationName}` : "Arrived";
  }

  if (code === PartViewMilestoneCode.DEPARTED) {
    description = locationName ? `Departed ${locationName}` : "Departed";
  }

  if (code === PartViewMilestoneCode.DELIVERED) {
    description = locationName ? `Delivered at ${locationName}` : "Delivered";
  }

  return description ? `${description} (${code})` : null;
};

export const usePartViewMilestone = (code: string) => {
  const { t } = useTranslation("partview-milestone");

  let description;
  switch (code) {
    case PartViewMilestoneCode.ARRIVED:
      description = t("partview-milestone:Arrived at Location");
      break;
    case PartViewMilestoneCode.DEPARTED:
      description = t("partview-milestone:Departed Location");
      break;
    case PartViewMilestoneCode.DELIVERED:
      description = t("partview-milestone:Delivered");
      break;
    default:
      description = null;
  }

  return { description };
};
