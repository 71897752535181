import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Region } from "shared/constants/shipments.const";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

export const useRegionTranslation = () => {
  const { t } = useTranslation("domain-data");

  const getTranslatedRegion = useCallback(
    (region: string) => {
      switch (region) {
        case Region.AFRICA:
          return t("domain-data:Africa");
        case Region.ASIA:
          return t("domain-data:Asia");
        case Region.AUSTRALIA:
          return t("domain-data:Australia");
        case Region.EUROPE:
          return t("domain-data:Europe");
        case Region.NORTH_AMERICA:
          return t("domain-data:North America");
        case Region.SOUTH_AMERICA:
          return t("domain-data:South America");
        default: {
          logWarningForMissingTranslation(region);
          return region;
        }
      }
    },
    [t],
  );

  return { getTranslatedRegion };
};
