import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import _ from "lodash";
import { combineReducers } from "redux";

const STORE_MOUNT_POINT = "sttDomainData";
const sttStatusDuck = buildFetchDuck("sttStatusDuck");
const sttLadDuck = buildFetchDuck("sttLadDuck");
const sttActiveExceptionsDuck = buildFetchDuck("sttActiveExceptionsDuck");

const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getStatus = createSelectorFromProperty("data", "status", false);
const getLad = createSelectorFromProperty("data", "lads", false);
const getActiveExceptions = createSelectorFromProperty(
  "data",
  "activeExceptions",
  false,
);

export function fetchDomainData() {
  return (dispatch) => {
    const baseUrl = apiUrl("/containertracking/api/filtervalues");

    dispatch(
      sttStatusDuck.fetch(baseUrl, {
        params: {
          filter_name: "SURGICAL_TOTE_TRACKING_STATUS",
        },
      }),
    );
    dispatch(
      sttActiveExceptionsDuck.fetch(baseUrl, {
        params: {
          filter_name: "SURGICAL_TOTE_TRACKING_EXCEPTIONS",
        },
      }),
    );
    dispatch(
      sttLadDuck.fetch(baseUrl, {
        params: {
          filter_name: "SURGICAL_TOTE_TRACKING_LAD",
        },
      }),
    );
  };
}

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getStatus,
    getLad,
    getActiveExceptions,
  },
  reducer: combineReducers({
    status: sttStatusDuck.reducer,
    lads: sttLadDuck.reducer,
    activeExceptions: sttActiveExceptionsDuck.reducer,
  }),
};
