/**
 * Interface used to define methods needed to draw shapes on the geofence
 * editing/viewing stuff
 */
import React from "react";
import _ from "lodash";
import { renderToString } from "react-dom/server";
import GeofenceType, { getType } from "../../geofence-edit/geofence-types";
import Colors from "../../../styles/colors";

const { fillColor, strokeColor } = Colors.geofence;

// SVG original dimensions
// W, H: 36, 55
// Anchor: 18, 0
export const MARKER_PARAMS = {
  h: 55 * 0.65,
  w: 36 * 0.65,
  x: 18 * 0.65,
  y: 55 * 0.65,
  mouseMarkerOffsetY: 2,
};

class ShapesPlatformInterface {
  constructor(api) {
    this.api = api;
  }

  // Geofences types implementations

  /**
   * Create a radial shape geofence.
   *
   * @param {Object} geofence Geofence geometry and coordinates data.
   * @param {String} locationId Id of the location in relation to which the
   * radial geofence is related to.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Boolean} selected Mark if the geofence is selected by the user by
   * clicking on it.
   * @param {Function} SVGGenerator Function wich receives one parameter
   * (label) and returns an SVG object to be put inside the geofence.
   */
  _createGeofenceRadial(
    geofence,
    locationId,
    draggable,
    selected,
    SVGGenerator = null,
  ) {
    if (geofence.geometry.coordinates.length > 0) {
      const id = `geofence:${locationId}:group`;
      const [lng, lat] = geofence.geometry.coordinates;
      const radiusMeters = _.max([geofence.properties.buffer, 0.1]);

      const circle = this.createCircle(
        lat,
        lng,
        fillColor,
        strokeColor,
        radiusMeters,
        draggable,
        selected,
      );
      const objects = [circle];
      // If there is SVG marker to the radial, add it to the map as a group
      // together with the circle itself
      const svg = SVGGenerator ? SVGGenerator() : null;
      if (svg) {
        objects.push(this.createMarker(lat, lng, `${id}:marker`, 1, svg));
      }
      return this.createGroup(objects, id);
    }
  }

  /**
   * Create a single polygon as a geofence.
   *
   * @param {Object} geofence Geofence geometry and coordinates data.
   * @param {String} locationId Id of the location in relation to which the
   * radial geofence is related to.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Boolean} selected Mark if the geofence is selected by the user by
   * clicking on it.
   * @param {Function} SVGGenerator Function wich receives one parameter
   * (label) and returns an SVG object to be put inside the geofence.
   */
  _createGeofencePolygonal(
    geofence,
    locationId,
    draggable,
    selected,
    SVGGenerator = null,
  ) {
    return [
      this.createPolygon(
        geofence.geometry.coordinates[0],
        locationId,
        0,
        draggable,
        selected,
        true,
        SVGGenerator ? SVGGenerator() : null,
      ),
    ];
  }

  /**
   * Create several geofences using multiple polygons.
   *
   * @param {Object} geofence Geofence geometry and coordinates data.
   * @param {String} locationId Id of the location in relation to which the
   * radial geofence is related to.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Boolean} selected Mark if the geofence is selected by the user by
   * clicking on it.
   * @param {Function} SVGGenerator Function wich receives one parameter
   * (label) and returns an SVG object to be put inside the geofence.
   */
  _createGeofenceMultiPolygonal(
    geofence,
    locationId,
    draggable,
    selected,
    SVGGenerator = null,
  ) {
    return geofence.geometry.map((polygon, index) => {
      return this.createPolygon(
        polygon.geometry.coordinates[0],
        locationId,
        index,
        draggable,
        selected,
        true,
        SVGGenerator ? SVGGenerator(index + 1) : null,
      );
    });
  }

  /**
   * Create geofence given a location. It works as a selector chosing the
   * geofence depending on the type selected by the location.
   *
   * @param {Object} location Location data with its geofence data inside it.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Boolean} selected Mark if the geofence is selected by the user by
   * clicking on it.
   * @param {Function} SVGGenerator Function wich receives one parameter
   * (label) and returns an SVG object to be put inside the geofence.
   */
  createGeofence(location, draggable, selected, SVGGenerator = null) {
    const { geofence } = location;
    if (!geofence) {
      return this.createGroup();
    }
    const fencetype = getType(geofence);

    switch (fencetype) {
      case GeofenceType.RADIAL:
        return this._createGeofenceRadial(
          geofence,
          location.id,
          draggable,
          selected,
          SVGGenerator,
        );
      case GeofenceType.POLYGONAL:
        return this._createGeofencePolygonal(
          geofence,
          location.id,
          draggable,
          selected,
          SVGGenerator,
        );
      case GeofenceType.MULTIPOLYGON:
        return this._createGeofenceMultiPolygonal(
          geofence,
          location.id,
          draggable,
          selected,
          SVGGenerator,
        );
      default:
        return this.createGroup();
    }
  }

  // Markers/grouping

  /**
   * Create a marker for any object. Adding also the possibility to use an svg
   * on the marker.
   *
   * @param latitude {Number}
   * @param longitude {Number}
   * @param id {Integer} Id used to index the marker.
   * @param index {Integer} Index of the marker. Important to store information
   * about marker index in cases such as multipolygonal in geofences.
   * @param svg {DOM Object} Dom element which represents the SVG.
   * @param width {Integer}
   * @param height {Integer}
   *
   * @return {Object} The map object which represents a marker and can be used
   * to be added on the map.
   */
  createMarker(
    latitude,
    longitude,
    id,
    index,
    svg = null,
    width = 48,
    height = 48,
  ) {
    throw Error("createMarker should be implemented");
  }

  // To be implemented methods

  /**
   * Create a group of objects that can be handled together.
   *
   * @param {Array} objects Array of objects to be put together in the group.
   * If this array is empty, will return an empty group.
   * @param {String} id Id of the group you are creating.
   */
  createGroup(objects = [], id = null) {
    throw Error("group should be implemented");
  }

  // Shapes itself

  /**
   * Create a circle shape
   *
   * @param {Number} latitude
   * @param {Number} longitude
   * @param {String} fillColor Internal color of the circle.
   * @param {String} strokeColor Color of the circle stroke
   * @param {Number} radiusMeters Radius of the circle
   * @param {Boolean} draggable If true circle will be draggable, if false not.
   * @param {Boolean} selected
   * @param {DOM Object} svg SVG to be put into the center of the circle
   *
   */
  createCircle(
    lat,
    lng,
    fillColor,
    strokeColor,
    radiusMeters,
    draggable,
    selected,
    svg,
  ) {
    throw Error("createCircle should be implemented");
  }

  /**
   * Create a polygonal shape given points.
   *
   * @param points {Array} Array with all the points (lat, lng) that composes
   * the polygon.
   * @param {String} locationId Id of the location in relation to which the
   * @param index {Integer} Index of the marker. Important to store information
   * about marker index in cases such as multipolygonal in geofences.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Boolean} selected Mark if the geofence is selected by the user by
   * clicking on it.
   * @param {Boolean} isShowingLabel If true the polygon label will be shown
   * @param svg {DOM Object} Dom element which represents the SVG.
   *
   * @return {Object} The map object which represents a polygon and can be used
   * to be added on the map.
   */
  createPolygon(
    points,
    locationId,
    index,
    draggable,
    selected,
    isShowingLabel,
    svg = null,
  ) {
    throw Error("createPolygon should be implemented");
  }

  // Geofence drawing time (tracing?) methods

  _generateDrawingMarkersFromTracePoints(
    tracePoints,
    groupId,
    polygonIndex,
    onPolygonDrawEnd,
  ) {
    return tracePoints.flatMap(([longitude, latitude], index) =>
      this.createDrawingMarker(
        latitude,
        longitude,
        groupId,
        polygonIndex,
        index,
        true,
        onPolygonDrawEnd,
      ),
    );
  }

  /**
   * Generate the polygon draft with its drawing markers.
   *
   * @param {String} locationId Id of the location in relation to which the
   * radial geofence is related to.
   * @param {Number} polygonIndex Index of the last polygon in the geofence.
   * @param {Object} mouseGeoCoords Coordinates of the mouse position already
   * converted to geo points on the map.
   * @param {Array} tracePoints Array with all the points (lat/lng) already
   * drawn on the map.
   * @param {Function} onPolygonDrawEnd Function that should be called after
   * finishing to draw the polygon.
   *
   * @return {Object} The map object which represents a polygon draft with its
   * drawing markers and polygon, everything in a group that can be used to be
   * added on the map.
   */
  createPolygonDrawingDraft(
    locationId,
    polygonIndex, // should always be the last index in the geofence.
    mouseGeoCoords,
    tracePoints,
    onPolygonDrawEnd,
  ) {
    throw Error("createPolygonDrawingDraft should be implemented");
  }

  /**
   * Generate the tracing draft with its mouse marker and dotted line.
   *
   * @param {String} locationId Id of the location in relation to which the
   * radial geofence is related to.
   * @param {Object} mouseGeoCoords Coordinates of the mouse position already
   * converted to geo points on the map.
   * @param {Array} tracePoints Array with all the points (lat/lng) already
   * drawn on the map.
   *
   * @return {Object} The map object which represents a tracing draft with its
   * mouse marker and dotted line, everything in a group that can be used to be
   * added on the map.
   */
  createTracingDraft(locationId, mouseGeoCoords, tracePoints) {
    throw Error("createTracingDraft should be implemented");
  }

  /**
   * When drawing a geofence we need some markers that temporarily show the
   * geofence points. This function creates this kind of control marker.
   *
   * @param latitude {Number}
   * @param longitude {Number}
   * @param id {Integer} Id used to index the marker.
   * @param polygonIndex {Integer} Index of the polygon we are drawing. In a
   * case where we have more than one polygons (multipolygonal geofence) it is
   * useful.
   * @param index {Integer} Index of the marker in the context of the polygon
   * we are drawing.
   * @param {Boolean} draggable If true geofence will be draggable
   * @param {Function} onPolygonDrawEnd Function that should be called after
   * finishing to draw the polygon.
   *
   * @return {Object} The map object which represents a marker and can be used
   * to be added on the map.
   */
  createDrawingMarker(
    latitude,
    longitude,
    groupId,
    polygonIndex,
    index,
    draggable = true,
    onPolygonDrawEnd = null,
  ) {
    throw Error("createDrawingMarker should be implemented");
  }
}

// Helpers

/**
 * Transform a coordinates array in a points array
 */
export const pointList = (coords, altitude = 100) => {
  return _.flatMap(coords, ([lng, lat]) => {
    return [lat, lng, altitude]; // set altitude to an arbitrary constant
  });
};

export const computeCircleMetrics = (width) => {
  const half = width / 2;
  const circleRadius = half - width / 10;
  const circleX = half;
  const textSize = half;
  const textX = half; // we assume it's x-centered
  const textY = half + textSize / 3;

  return {
    circleRadius,
    circleX,
    circleY: circleX,
    textSize,
    textX,
    textY,
    anchorX: half,
    anchorY: half,
  };
};

/**
 * Create an SVG marker that may be used to mark elements on the map.
 *
 * @param {Integer} index Number that will be shown inside the marker.
 * @param {Integer} width
 * @param {Integer} height
 *
 * @return {DOM Object} DOM object that may be used to create a marker on the
 * map.
 */
export const createSvgCircledMarker = (index, width, height) => {
  const { circleRadius, circleX, circleY, textX, textY, textSize } =
    computeCircleMetrics(width);
  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle
          style={{
            fill: "white",
            stroke: Colors.highlight.ALERT_YELLOW,
            strokeWidth: "3",
          }}
          r={circleRadius}
          cx={circleX}
          cy={circleY}
        />
        <text
          x={textX}
          y={textY}
          fontSize={textSize}
          fontFamily="sans-serif"
          fontWeight={600}
          textAnchor="middle"
          fill={"black"}
        >
          {index + 1}
        </text>
      </g>
    </svg>,
  );
};

export default ShapesPlatformInterface;
