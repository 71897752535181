import moment from "moment";
import _ from "lodash";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const STORE_MOUNT_POINT = "adminTool";

const SHIPMENT_EVENTS_URL = apiUrl("/shipping/post_bulk_shipment_updates");

const reportArrivalDepartureDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "shipmentReportArrivalDeparture",
);

const reportArrivalDeparture = (data, onSuccess) => {
  const payload = {
    comments: data?.comments ?? "",
    event: data.eventCode,
    eventDate: moment(data.eventTime).format(),
    shipmentIds: data.shipmentIds?.toString() ?? "",
    stopCode: data.stopCode,
  };

  return (dispatch) => {
    dispatch(
      reportArrivalDepartureDuck.fetch(
        SHIPMENT_EVENTS_URL,
        {
          method: "POST",
          data: payload,
        },
        _.noop,
        () => {
          onSuccess();
        },
      ),
    );
  };
};

const clearReportArrivalDeparture = () => {
  return (dispatch) => {
    dispatch(reportArrivalDepartureDuck.clear());
  };
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    reportArrivalDeparture,
    clearReportArrivalDeparture,
  },
  selectors: {
    getShipmentReportArrivalDepartureData:
      reportArrivalDepartureDuck.selectors.getData,
  },
  reducer: reportArrivalDepartureDuck.reducer,
};
