/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { useTranslation } from "react-i18next";
import { VictoryPie, VictoryLabel, VictoryTooltip } from "victory";
import { faCircle, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import { Text } from "components/atoms/Text.atom";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import Colors from "styles/colors";

export const NameCellColumn = ({ cellInfo }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {cellInfo.row.original.fill ? (
        <Icon
          src={faCircle}
          color={cellInfo.row.original.fill}
          style={{
            marginRight: 8,
            fontSize: 10,
          }}
        />
      ) : (
        <span css={{ width: 18 }}></span>
      )}
      <Text>
        {cellInfo.row.original.translatedName}
        {cellInfo.row.original.tooltipContent ? (
          <span css={{ color: "#999", fontSize: 14, paddingLeft: 5 }}>
            <Tooltip
              placement="top"
              tooltipChildren={
                <Text>{cellInfo.row.original.tooltipContent}</Text>
              }
            >
              <Icon src={faQuestionCircle} />
            </Tooltip>
          </span>
        ) : null}
      </Text>
    </div>
  );
};

NameCellColumn.propTypes = {
  cellInfo: PropTypes.object,
};

const TotalsRow = ({ count, rowClickHandler }) => {
  const { t } = useTranslation("components");

  const cellStyle = {
    boxSizing: "border-box",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: ".5rem",
    overflow: "hidden",
  };

  return (
    <div
      css={{
        display: "flex",
        flex: "1 0 auto",
        ":hover": {
          cursor: "pointer",
          background: Colors.background.YELLOW_MOUSEOVER,
        },
      }}
      onClick={() => rowClickHandler()}
    >
      <div
        css={{
          ...cellStyle,
          paddingLeft: "1.7rem",
          flex: "150 0 auto",
          width: 150,
        }}
      >
        {t("components:Total")}
      </div>
      <div css={{ ...cellStyle, flex: "70 0 auto", width: 70 }}>{count}</div>
      <div css={{ ...cellStyle, flex: "20 0 auto", width: 20 }}>
        <div css={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Icon src={faChevronRight} size={FontSize.size14} />
        </div>
      </div>
    </div>
  );
};

TotalsRow.propTypes = {
  count: PropTypes.number,
  rowClickHandler: PropTypes.func,
};

const ChartTooltip = (props) => {
  const { active, datum, x, y, tooltipLabel, tooltipLabelSingular } = props;

  if (!active) {
    return null;
  }

  return (
    <g>
      <VictoryLabel
        x={x}
        y={y - 170}
        text={`${datum.count} ${
          datum.count > 1 ? tooltipLabel : tooltipLabelSingular
        }`}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 18,
          fontWeight: 600,
          fill: Colors.holds.DARK_BLUE,
        }}
      />
      <VictoryLabel
        x={x}
        y={y - 135}
        /* H2-940: Add Hold Code next to Description in Parentheses
         * Truncate the text because it can be too long to fit in the inner part of the pie graph
         * Keep in mind that this is user submitted data, the user can use mixed casing or all caps.
         * Because of this, we are forcing all caps because the capital letters in the font are
         *   generally the same width with a few exceptions (e.g. "I")
         *
         * Note: Unfortunately, this is SVG text, it is unaffected by CSS's text-overflow: ellipsis
         */
        text={_.truncate(datum?.fullDescription?.toUpperCase(), {
          length: 22,
        })}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 14,
          fill: `${datum.fill}`,
        }}
      />
    </g>
  );
};

ChartTooltip.defaultEvents = VictoryTooltip.defaultEvents;

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  datum: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  tooltipLabel: PropTypes.string,
  tooltipLabelSingular: PropTypes.string,
};

export const HalfPieTableWidget = ({
  title,
  postTitleIcon,
  postTitleIconTooltip,
  subHeaderContent,
  columns,
  loaded,
  chartData,
  rowClickHandler,
  tooltipLabel,
  tooltipLabelSingular,
  className,
}) => {
  return (
    <PanelGroup className={className}>
      <PanelGroup.Header
        title={title}
        postTitleIcon={postTitleIcon}
        postTitleIconTooltip={postTitleIconTooltip}
      />
      {subHeaderContent ? (
        <PanelGroup.SubHeader>{subHeaderContent}</PanelGroup.SubHeader>
      ) : null}
      <PanelGroup.Content
        style={{
          position: "relative",
          paddingTop: 0,
          minHeight: 290,
        }}
      >
        <Loader loaded={loaded}>
          <div css={{ position: "relative" }}>
            <VictoryPie
              height={185}
              origin={{ y: 185 }}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                },
              }}
              x="name"
              y="count"
              startAngle={-90}
              endAngle={90}
              radius={165}
              innerRadius={100}
              data={chartData}
              labels={({ datum }) => datum.name}
              labelComponent={
                <ChartTooltip
                  x={200}
                  y={300}
                  tooltipLabel={tooltipLabel}
                  tooltipLabelSingular={tooltipLabelSingular}
                />
              }
            />
          </div>
          <BaseTable
            theme={Themes.LIGHT}
            data={chartData}
            columns={columns}
            pageSize={chartData?.length}
            disableSortBy={true}
            rowClickHandler={rowClickHandler}
          />
          <TotalsRow
            count={_.sumBy(chartData, "count")}
            rowClickHandler={rowClickHandler}
          />
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

HalfPieTableWidget.propTypes = {
  title: PropTypes.string,
  postTitleIcon: PropTypes.object,
  postTitleIconTooltip: PropTypes.string,
  subHeaderContent: PropTypes.node,
  columns: PropTypes.array,
  loaded: PropTypes.bool,
  chartData: PropTypes.array,
  rowClickHandler: PropTypes.func,
  tooltipLabel: PropTypes.string,
  tooltipLabelSingular: PropTypes.string,
  className: PropTypes.string,
};
