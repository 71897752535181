import { BrowserStorage } from "utils/browser-storage.utils";

export const getNavBarCollapseStateFromStorage = (mediumAndDown) => {
  if (BrowserStorage.navIsCollapsed !== null) {
    if (!mediumAndDown) {
      return JSON.parse(BrowserStorage.navIsCollapsed);
    }
  } else {
    BrowserStorage.navIsCollapsed = mediumAndDown;
  }

  return mediumAndDown;
};

export const toggleNavBarStateFromStorage = (isCollapsed) => {
  BrowserStorage.navIsCollapsed = isCollapsed;
};

export const getNavBarOpenGroupsFromStorage = () => {
  return BrowserStorage.navOpenGroups?.split(",") ?? [];
};

export const setNavBarOpenGroupsInStorage = (openGroups = []) => {
  BrowserStorage.navOpenGroups = openGroups;
};
