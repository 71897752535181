export const tabsCss = {
  backgroundColor: "#99CDFE",
};

export const tabStyle = {
  paddingLeft: 2,
  paddingRight: 2,
};

export const tabPanelStyle = {
  boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)",
  borderRadius: "5px 0px 0px 0px",
};

export const tabListStyle = { margin: "0 .5em" };
