/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useTranslation } from "react-i18next";
import { useMySubmissionColumns } from "./DamageView.Dashboard.columns";

export const MySubmissionsWidget = ({
  solutionId,
  isLoading,
  mySubmissionsData,
  fetchWatchedSubmissionsData,
  fetchMySubmissionsData,
  mySubmissionsPageIndex,
  mySubmissionsPageSize,
  mySubmissionsPageCount,
  setMySubmissionsPageIndex,
  exportSubmissonData,
  userPreferences,
  pushDamageViewDetailsPage,
  sortColumn,
  reverseSort,
  defaultSortColumn,
  defaultReverseSort,
  setSort,
}) => {
  const { t } = useTranslation("damage-view");
  const [unwatchedSubmissions, setUnwatchedSubmissions] = useState([]);

  useEffect(() => {
    fetchMySubmissionsData(solutionId);
  }, []);

  let unwatchTimers = {};

  const toggleWatch = (submission) => {
    let newUnwatchedSubmissions = [...unwatchedSubmissions];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[submission]) {
      clearTimeout(unwatchTimers[submission]);
    }

    if (newUnwatchedSubmissions.includes(submission)) {
      // submissions checkbox has already been unchecked - re-watch it
      newUnwatchedSubmissions = newUnwatchedSubmissions.filter(
        (v) => v !== submission,
      );
    } else {
      // submissions checkbox is checked - unwatch it
      newUnwatchedSubmissions.push(submission);
    }

    setUnwatchedSubmissions(newUnwatchedSubmissions);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[submission] = setTimeout(() => {
      fetchWatchedSubmissionsData(solutionId);
      fetchMySubmissionsData(solutionId);
      setUnwatchedSubmissions([]);
    }, 2000);
  };

  return (
    <PanelGroup>
      <PanelGroup.Header title={t("damage-view:My Submissions")} />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={mySubmissionsData}
          columns={useMySubmissionColumns(
            solutionId,
            exportSubmissonData,
            userPreferences,
            toggleWatch,
          )}
          theme={Themes.LIGHT}
          isLoading={isLoading}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          isManualSorting={true}
          pageIndex={mySubmissionsPageIndex}
          pageSize={mySubmissionsPageSize}
          pageCount={mySubmissionsPageCount}
          sortColumn={sortColumn}
          reverseSort={reverseSort}
          defaultSortColumn={defaultSortColumn}
          defaultReverseSort={defaultReverseSort}
          onPageChange={(newPage) => {
            setMySubmissionsPageIndex(
              solutionId,
              newPage,
              mySubmissionsPageSize,
            );
          }}
          onPageSort={(column, isReverse) => {
            setSort(column, isReverse);
          }}
          rowClickHandler={(row, cell) => {
            // Prevent redirects from clicking within watch column
            if (cell.column.id === "watch") {
              return;
            }

            if (!row.original.id) {
              return;
            }

            pushDamageViewDetailsPage(row.original.id);
          }}
          rowDisabledHandler={(row) => {
            const submissionId = row.original.id;
            return unwatchedSubmissions.includes(submissionId);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

MySubmissionsWidget.propTypes = {
  solutionId: PropTypes.string,
  isLoading: PropTypes.bool,
  mySubmissionsData: PropTypes.array,
  fetchMySubmissionsData: PropTypes.func,
  mySubmissionsPageIndex: PropTypes.number,
  mySubmissionsPageSize: PropTypes.number,
  mySubmissionsPageCount: PropTypes.number,
  setMySubmissionsPageIndex: PropTypes.func,
  exportSubmissonData: PropTypes.func,
  fetchWatchedSubmissionsData: PropTypes.func,
  userPreferences: PropTypes.object,
  pushDamageViewDetailsPage: PropTypes.func,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
};
