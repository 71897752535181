/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";

export const CancelShipmentModal = ({
  show,
  hide,
  shipment,
  cancelShipment,
}) => {
  const { t } = useTranslation("shipment-details");
  const [noteValue, setNoteValue] = useState("");

  const handleOnChangeNote = (event) => {
    setNoteValue(event.target.value);
  };

  const handleOnEnter = () => {
    setNoteValue("");
  };

  const userChangedData = noteValue.length > 0;

  return (
    <Modal
      show={show}
      style={{ ":width": "300px" }}
      onHide={() => hide()}
      onEnter={handleOnEnter}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("Cancel Shipment")}
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormLabel>{`${t("Reason")}:`}</FormLabel>
          <FormControl
            as="textarea"
            placeholder={t("Enter reason for canceling this shipment")}
            value={noteValue}
            onChange={handleOnChangeNote}
            style={{ resize: "none" }}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => hide()}
        >
          {t("Cancel")}
        </Button>

        <Button
          disabled={!userChangedData}
          style={{
            backgroundColor: Colors.highlight.GREEN,
            color: "white",
            fontWeight: "300",
          }}
          onClick={() => {
            cancelShipment(shipment, noteValue);
            hide();
          }}
        >
          {t("Submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CancelShipmentModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  shipment: PropTypes.object,
  cancelShipment: PropTypes.func,
};
