/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import apiUrl from "api-url";
import axios from "axios";

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { AsyncPaginate } from "react-select-async-paginate";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Icon } from "components/atoms/Icon.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import { UserAuthorizationNamespace } from "modules/auth/Authorization";

export const BatchAssignModal = ({
  show,
  solutionId,
  setShow,
  selectedIds = [],
  searchEntities,
  saveBatchAssigned,
  clearAllSelections,
  currentUser,
}) => {
  const { t } = useTranslation("damageview-search");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentLoadStatus, setCurrentLoadStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const loadAssignees = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/damageview/submission/filters?filterType=assignee&query=${searchText}&pageSize=20&pageNumber=${pageNumber}`,
    );
    await axios
      .get(url)
      .then((res) => {
        data = res?.data?.data?.map((assignee) => {
          return {
            label: assignee,
            value: assignee,
            data: assignee,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });

    const dataValue = {
      options: data ?? [],
      hasMore: metaData
        ? metaData.currentPage + 1 < metaData.totalPages // currentPage start from 0
        : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
    return dataValue;
  };

  const hideHandler = () => {
    setShow(false);
    setCurrentLoadStatus(null);
    setIsSubmitting(false);
    setSelectedAssignee(null);
  };
  const submitHandler = () => {
    setIsSubmitting(true);

    let savePromise = saveBatchAssigned(selectedAssignee?.value, selectedIds);
    savePromise
      .then((response) => {
        if (response) {
          setIsSubmitting(false);
          searchEntities(solutionId);
          clearAllSelections();
          setCurrentLoadStatus("success");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setCurrentLoadStatus("failed");
      });
  };

  return (
    <DialogModal
      size="lg"
      show={show}
      title={t("damageview-search:Batch Assign")}
      cancelButtonText={t("damageview-search:Cancel")}
      submitButtonText={t("damageview-:Submit")}
      submitButtonVariant="success"
      okButtonText={t("damageview-search:Close")}
      onSubmit={submitHandler}
      onHide={hideHandler}
      closeOnBlur={false}
      isLoading={isSubmitting}
      loadStatus={currentLoadStatus}
      submitButtonDisabled={selectedAssignee ? false : true}
    >
      {isSubmitting ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Icon src={faSpinner} spin size={FontSize.size32} />
        </div>
      ) : currentLoadStatus === "success" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Icon src={faCheckCircle} color="green" size={3} />
          <div
            style={{
              marginTop: "1em",
            }}
          >
            <Text>{t("damageview-search:Assigned Successfully")}</Text>
          </div>
        </div>
      ) : currentLoadStatus === "failed" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Icon src={faTimesCircle} color="red" size={3} />
          <div
            style={{
              marginTop: "1em",
            }}
          >
            <Text>
              {t("damageview-search:Something Went Wrong. Please try again.")}
            </Text>
          </div>
        </div>
      ) : (
        <Fragment>
          <Text block bold css={{ marginBottom: "1rem" }}>
            {currentUser[UserAuthorizationNamespace].email}
          </Text>
          <Text block>
            {t(
              "damageview-search:You have selected [[[count]]] submissions from the search results table. To batch assign these submissions to a user, please select the assignee from the drop down below.",
              { count: selectedIds.length },
            )}
          </Text>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Text bold>Assignee:</Text>
            <AsyncPaginate
              css={{ width: "100%" }}
              debounceTimeout={500}
              isMulti={false}
              cacheOptions={false}
              onInputChange={(val) => {
                setSearchText(val);
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={true}
              value={selectedAssignee}
              loadOptions={async (currentVal, options, additionalOptions) => {
                const data = await loadAssignees(
                  currentVal,
                  additionalOptions &&
                    currentVal === additionalOptions?.prevValue
                    ? additionalOptions.pageNumber + 1
                    : 0,
                );
                return data;
              }}
              cacheUniqs={[searchText]}
              onChange={(selectedData) => {
                setSelectedAssignee(selectedData ?? null);
              }}
            />
          </div>
        </Fragment>
      )}
    </DialogModal>
  );
};

BatchAssignModal.propTypes = {
  show: PropTypes.bool,
  solutionId: PropTypes.string,
  setShow: PropTypes.func,
  selectedIds: PropTypes.array,
  saveBatchAssigned: PropTypes.func,
  searchEntities: PropTypes.func,
  clearAllSelections: PropTypes.func,
  currentUser: PropTypes.object,
};
