import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import apiUrl from "api-url";
import { getSolutionId } from "modules/organizations/OrganizationsState";

const FinVehicleSubscriptionState = buildSubscriptionState({
  topic: "FinishedVehicle",
  systemType: "entity",
  getSubscribeeId: (subscribee) => subscribee.id,
  getUrl: (id, state) => {
    const solutionId = getSolutionId(state);
    return apiUrl(`/entity/solution/${solutionId}/entity/${id}`);
  },
});

export default FinVehicleSubscriptionState;
