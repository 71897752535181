import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

import { redirectToRoute } from "redux/utils";

const MOUNT_POINT = "debugEtaTool";

// Fetch ducks
const duck = buildFetchDuck(MOUNT_POINT);

const getDebugEtaUrl = () => apiUrl(`/eta-shipments/eta/debug`);

//Action Creators
function goToDebugEtaTool() {
  return redirectToRoute("DEBUG_ETA");
}

function debugEta(payload) {
  return (dispatch) => {
    let url = getDebugEtaUrl();

    const config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: JSON.parse(payload),
    };

    dispatch(duck.fetch(url, config)).then((response) => {
      console.log("response", response);
      if (response?.error?.message) {
        throw new Error(response?.error?.message);
      }
      return response;
    });
  };
}

const DebugEtaToolState = {
  mountPoint: MOUNT_POINT,
  reducer: duck.reducer,
  actions: {
    debugEta,
    goToDebugEtaTool,
  },
  selectors: {
    getDebugEtaToolData: duck.selectors.getData,
  },
};

export default DebugEtaToolState;
