import axios from "axios";

export const buildEntityHistoricalSlice = (mountPoint, getHistoryUrl) => {
  // Actions
  const FETCH_HISTORICAL_DATA = `${mountPoint}/FETCH_HISTORICAL_DATA`;
  const RECEIVE_HISTORICAL_DATA = `${mountPoint}/RECEIVE_HISTORICAL_DATA`;
  const RECEIVE_HISTORICAL_DATA_ERROR = `${mountPoint}/RECEIVE_HISTORICAL_DATA_ERROR`;

  // Action creators
  const fetchHistoricalData = (
    solutionId,
    entityId,
    qualifier,
    dealerOrgId,
  ) => {
    return (dispatch) => {
      dispatch({
        type: FETCH_HISTORICAL_DATA,
        payload: { qualifier },
      });
      const historyUrl = getHistoryUrl(
        solutionId,
        entityId,
        qualifier,
        dealerOrgId,
      );
      return axios
        .get(historyUrl)
        .then((response) => {
          dispatch({
            type: RECEIVE_HISTORICAL_DATA,
            payload: {
              qualifier,
              historicalData: response.data,
            },
          });
        })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: RECEIVE_HISTORICAL_DATA_ERROR,
            payload: { qualifier },
          });
        });
    };
  };

  // Selectors
  const getHistoryByQualifier = (qualifier) => (state) =>
    state[mountPoint].historicalMap.get(qualifier);

  // Initial state
  const initialState = {
    historicalMap: new Map(),
  };

  const reducer = (state = initialState, action) => {
    // be sure to never mutate state with redux
    const newHistoricalMap = new Map(state.historicalMap);

    switch (action.type) {
      case FETCH_HISTORICAL_DATA:
        newHistoricalMap.set(action.payload.qualifier, {
          data: [],
          isLoading: true,
        });
        return {
          ...state,
          historicalMap: newHistoricalMap,
        };

      case RECEIVE_HISTORICAL_DATA:
        const { qualifier, historicalData } = action.payload;
        newHistoricalMap.set(qualifier, {
          data: historicalData,
          isLoading: false,
        });
        return {
          ...state,
          historicalMap: newHistoricalMap,
        };

      case RECEIVE_HISTORICAL_DATA_ERROR:
        newHistoricalMap.set(action.payload.qualifier, {
          isLoading: false,
          isError: true,
        });
        return {
          ...state,
          historicalMap: newHistoricalMap,
        };

      default:
        return state;
    }
  };

  // interface
  return {
    mountPoint,
    actionCreators: { fetchHistoricalData },
    selectors: { getHistoryByQualifier },
    reducer,
  };
};
