import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";

// Exported URLs
export const currentPositionCodesUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionCodes=1`,
  );
};

export const productTypesUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?productType=1`);
};

export const lastMilestoneUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?lastMilestone=1`);
};

export const soldToUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:soldToDealer=1`,
  );
};

export const finCodeUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?ref:FinCode=1`);
};

export const endUserFinCodeUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:EndUserFinCode=1`,
  );
};

export const dealerRegionUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerRegion=1`);
};

export const dealerZoneUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerZone=1`);
};

export const dealerDistrictUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?dealerDistrict=1`);
};

export const partnersUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?partner=1`);
};

const STORE_MOUNT_POINT = "fvDomainData";
const finVehicleDomainDuck = buildFetchDuck("finVehicleDomainDuck");

const finVehicleProductTypeDuck = buildFetchDuck("finVehicleProductTypeDuck");
const finVehicleLocationDuck = buildFetchDuck("finVehicleLocationDuck");
const finVehicleOrderTypeDuck = buildFetchDuck("finVehicleOrderTypeDuck");
const finVehicleOrderNumberDuck = buildFetchDuck("finVehicleOrderNumberDuck");
const finVehicleCategoriesDuck = buildFetchDuck("finVehicleCategoriesDuck");
const finVehicleShipmentExceptionsDuck = buildFetchDuck(
  "finVehicleShipmentExceptionsDuck",
);
const finVehicleCarrierDuck = buildFetchDuck("finVehicleCarrierDuck");
const finVehicleExceptionTypeDuck = buildFetchDuck(
  "finVehicleExceptionTypeDuck",
);
const finVehiclePositionTypesDuck = buildFetchDuck(
  "finVehiclePositionTypesDuck",
);
const finVehicleRouteIdDuck = buildFetchDuck("finVehicleRouteIdDuck");

export const SHIPMENT_STATUS_FILTER_OPTIONS = [
  {
    value: "in_transit",
    label: "Active",
  },
  {
    value: "arrived",
    label: "Arrived",
  },
  {
    value: "canceled",
    label: "Canceled",
  },
  {
    value: "asset_pending",
    label: "Pending: Asset ID",
  },
  {
    value: "released",
    label: "Released",
  },
  {
    value: "available_for_unload",
    label: "Available For Unload",
  },
];

export function fetchDomainData(solutionId) {
  const fieldsToGetFromEndpoint = [
    // H1-1549: for some reason they use the parameter here as lifecycleState
    // but return this as lifeCycleState, with a capital c, so, this is the only
    // place where whe have this written in this way
    "lifecycleState",
  ];
  const queryString = fieldsToGetFromEndpoint
    .map((item) => `${item}=1`)
    .join("&");
  const url = apiUrl(
    `/entity-search/solution/${solutionId}/list?${queryString}`,
  );

  const urlProductType = apiUrl(
    `/entity-search/solution/${solutionId}/list?productType=1`,
  );
  const urlLocation = apiUrl(
    `/entity-search/solution/${solutionId}/list?location=1`,
  );
  const urlOrderType = apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:OrderType=1`,
  );
  const urlOrderNumber = apiUrl(
    `/entity-search/solution/${solutionId}/list?ref:OrderNumber=1`,
  );
  const urlCategories = apiUrl(
    `/entity-search/solution/${solutionId}/list?categories=1`,
  );
  const urlShipmentExceptions = apiUrl(
    `/entity-search/solution/${solutionId}/list?shipmentExceptions=1`,
  );
  const urlCarrier = apiUrl(
    `/entity-search/solution/${solutionId}/list?carrier=1`,
  );
  const urlExceptionType = apiUrl(
    `/entity-search/solution/${solutionId}/list?exceptionType=1`,
  );
  const urlPositionTypes = apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionTypes=1`,
  );
  const urlRouteId = apiUrl(
    `/entity-search/solution/${solutionId}/list?routeNumber=1`,
  );

  return (dispatch) => {
    dispatch(finVehicleDomainDuck.fetch(url));
    dispatch(finVehicleProductTypeDuck.fetch(urlProductType));
    dispatch(finVehicleLocationDuck.fetch(urlLocation));
    dispatch(finVehicleOrderTypeDuck.fetch(urlOrderType));
    dispatch(finVehicleOrderNumberDuck.fetch(urlOrderNumber));
    dispatch(finVehicleCategoriesDuck.fetch(urlCategories));
    dispatch(finVehicleShipmentExceptionsDuck.fetch(urlShipmentExceptions));
    dispatch(finVehicleCarrierDuck.fetch(urlCarrier));
    dispatch(finVehicleExceptionTypeDuck.fetch(urlExceptionType));
    dispatch(finVehiclePositionTypesDuck.fetch(urlPositionTypes));
    dispatch(finVehicleRouteIdDuck.fetch(urlRouteId));
  };
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};
const getLocations = createSelectorFromProperty("locations", "locations");

const getExceptionTypes = createSelectorFromProperty(
  "exceptionTypes",
  "exceptionTypes",
);
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

// H2-366: Remove VINs from typeahead
const getVINs = (state) => [];

const getShipments = (state) => [];

const getProductTypes = createSelectorFromProperty(
  "productTypes",
  "productTypes",
);

const getLifeCycleStates = createSelectorFromProperty(
  "lifecycleState",
  "domainData",
);

const getCarriers = createSelectorFromProperty(
  "carriers",
  "carriers",
  "carrier_name",
);

const getOrderTypes = createSelectorFromProperty(
  "ref:OrderType",
  "orderType",
  true,
);
const getOrderNumbers = createSelectorFromProperty(
  "ref:OrderNumber",
  "orderNumber",
  true,
);

const getCategories = createSelectorFromProperty("categories", "categories");

const getItssIds = (state) =>
  getCategories(state)?.filter((category) => category.groupType === "ITSS");

const getSpotBuyAuthCodes = (state) =>
  getCategories(state)?.filter((category) => category.groupType === "SB");

const getShipmentExeptions = createSelectorFromProperty(
  "shipmentExceptions",
  "shipmentExceptions",
);

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getRouteIds = createSelectorFromProperty("routeNumber", "routeIds");

const getDomainDataLoading = (state) =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getLocations,
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getShipmentExeptions,
    getItssIds,
    getSpotBuyAuthCodes,
    getLifeCycleStates,
    getVINs,
    getShipments,
    getCarriers,
    getOrderTypes,
    getOrderNumbers,
    getDomainDataLoading,
    getCurrentPositionTypes,
    getRouteIds,
  },
  reducer: combineReducers({
    domainData: finVehicleDomainDuck.reducer,
    productTypes: finVehicleProductTypeDuck.reducer,
    locations: finVehicleLocationDuck.reducer,
    orderType: finVehicleOrderTypeDuck.reducer,
    orderNumber: finVehicleOrderNumberDuck.reducer,
    categories: finVehicleCategoriesDuck.reducer,
    shipmentExceptions: finVehicleShipmentExceptionsDuck.reducer,
    carriers: finVehicleCarrierDuck.reducer,
    exceptionTypes: finVehicleExceptionTypeDuck.reducer,
    currentPositionTypes: finVehiclePositionTypesDuck.reducer,
    routeIds: finVehicleRouteIdDuck.reducer,
  }),
};
