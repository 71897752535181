import { combineReducers } from "redux";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { alfBy } from "components/selectors";
import DomainDataState from "./DomainDataState";
import { getShipmentExceptionEnumFromName } from "pages/shipments/utils/exception.utils";

const STORE_MOUNT_POINT = "shipmentsDomainData";

export const TRAILER_EQUIPMENT_NUMBERS_URL = apiUrl(
  "/shipping-ng/filter?trailer_equipment_number=true",
);
export const SERVICE_CODE_URL = apiUrl(
  "/shipping-ng/order_numbers?reference=service_code",
);
export const STCC_CODE_URL = apiUrl("/shipping-ng/filter?stcc_code=True");
export const PRO_NUMBER_FILTER_URL = apiUrl(
  "/shipping-ng/filter?pro_number=True",
);
export const ROUTE_NUMBER_FILTER_URL = apiUrl(
  "/shipping-ng/filter?route_number=True",
);
export const ORIGIN_CITY_FILTER_URL = apiUrl(
  "/shipping-ng/filter?origin_city=True",
);
export const DESTINATION_CITY_FILTER_URL = apiUrl(
  "/shipping-ng/filter?destination_city=True",
);
export const ORIGIN_STATE_FILTER_URL = apiUrl(
  "/shipping-ng/filter?origin_state=True",
);
export const DESTINATION_STATE_FILTER_URL = apiUrl(
  "/shipping-ng/filter?destination_state=True",
);
export const ORIGIN_COUNTRY_FILTER_URL = apiUrl(
  "/shipping-ng/filter?origin_country=True",
);
export const DESTINATION_COUNTRY_FILTER_URL = apiUrl(
  "/shipping-ng/filter?destination_country=True",
);
export const HOUSE_REFERENCE_NUMBERS_URL = apiUrl(
  "/shipping-ng/filter?house_reference_number=true",
);
export const BILL_OF_LADING_NUMBERS_URL = apiUrl(
  "/shipping-ng/filter?bill_of_lading_number=true",
);
export const SAW_DATA_SOURCE_FILTER_URL = apiUrl(
  "/shipping-ng/filter?saw_data_source=true",
);

export const STOP_LOCATION_FILTER_URL = apiUrl(
  "/shipping-ng/filter?stop_location=true",
);

// DEV-1070
export const SHIPMENT_EVENTS_FILTER_OPTION_NAMES = [
  // These two will be consolidated (grouped) on "Arrived at Stop"
  "Arrived at Drop-Off",
  "Arrived at Pickup",

  "Arrived at Destination",
  "Arrived at Origin",
  "Available for Delivery",
  "Departed Destination",
  "Departed Origin",

  // These two will be consolidated on "Departed Stop"
  "Departed Drop-Off",
  "Departed Pickup",
];

const EXCEPTION_TYPES_URL = apiUrl("/shipping-ng/exception_filters");
const STATUS_TYPES_URL = apiUrl("/shipping-ng/status_filters");
const SHIPMENT_IDS_URL = apiUrl("/shipping-ng/carrier-shipment-ids");
const ROUTE_IDS_URL = apiUrl("/shipping-ng/route_numbers");
const SHIPMENT_TYPES_URL = apiUrl("/shipping-ng/type_filters");
const ORDER_NUMBERS_URL = apiUrl("/shipping-ng/order_numbers");
const TRIP_PLAN_URL = apiUrl("/shipping-ng/trip_plan_numbers");
const ASSET_IDS_URL = apiUrl("/shipping-ng/asset_ids");
// H1-2342: new fv-shipment-filter handles this endpoint
const TRAIN_IDS_URL = apiUrl("/shipment-filter/train_ids");
const LINE_OF_BUSINESS_URL = apiUrl(
  "/shipment-filter/line_of_business_filters",
);

const exceptionTypeDuck = buildFetchDuck("exceptionTypes");
const statusTypeDuck = buildFetchDuck("statusTypes");
const shipmentIDDuck = buildFetchDuck("shipmentIDs");
const routeIDDuck = buildFetchDuck("routes");
const shipmentTypesDuck = buildFetchDuck("shipmentTypes");
const orderNumbersDuck = buildFetchDuck("orderNumbers");
const tripPlanDuck = buildFetchDuck("tripPlan");
const assetIdsDuck = buildFetchDuck("assetIds");
const trainIdsDuck = buildFetchDuck("trainIds");
const lineOfBusinessDuck = buildFetchDuck("lineOfBusiness");
const serviceCodesDuck = buildFetchDuck("serviceCodes");

export function fetchDomainData() {
  return (dispatch) => {
    dispatch(
      exceptionTypeDuck.fetch(EXCEPTION_TYPES_URL, undefined, (data) => {
        return data.map((exceptionType) => ({
          ...exceptionType,
          // This type is used to identify the exception in other places since
          // the IDs from the API may not be consistent between environments.
          // In the future, this should be swapped out for an ID or reason code.
          type: getShipmentExceptionEnumFromName(exceptionType.name),
        }));
      }),
    );
    dispatch(statusTypeDuck.fetch(STATUS_TYPES_URL));
    dispatch(shipmentIDDuck.fetch(SHIPMENT_IDS_URL));
    dispatch(routeIDDuck.fetch(ROUTE_IDS_URL));
    dispatch(shipmentTypesDuck.fetch(SHIPMENT_TYPES_URL));
    dispatch(orderNumbersDuck.fetch(ORDER_NUMBERS_URL));
    dispatch(tripPlanDuck.fetch(TRIP_PLAN_URL));
    dispatch(assetIdsDuck.fetch(ASSET_IDS_URL));
    dispatch(trainIdsDuck.fetch(TRAIN_IDS_URL));
    dispatch(lineOfBusinessDuck.fetch(LINE_OF_BUSINESS_URL));
    dispatch(serviceCodesDuck.fetch(SERVICE_CODE_URL));
  };
}

// selectors
const selectShipmentModesAlphabetically = alfBy(
  DomainDataState.selectors.getShipmentModes,
  "name",
);
const getExceptionTypes = (state) =>
  state.shipmentsDomainData.exceptionTypes.data;
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");
const getStatusTypes = (state) => {
  return state.shipmentsDomainData.statusTypes.data;
};
const selectStatusTypesAlphabetically = alfBy(getStatusTypes, "name");
// DEV-1070
const selectShipmentEventsAlphabetically = (state) => {
  return state.shipmentsDomainData.statusTypes.data.filter((st) =>
    SHIPMENT_EVENTS_FILTER_OPTION_NAMES.includes(st.name),
  );
};
const getShipmentIDs = (state) => {
  return state.shipmentsDomainData.shipmentIDs.data;
};
const getParts = (state) => {
  return state.shipmentsDomainData.parts.data;
};
const getRouteIDs = (state) => {
  return state.shipmentsDomainData.routeIDs.data;
};
const getShipmentTypes = (state) => {
  return state.shipmentsDomainData.shipmentTypes.data;
};
const getOrderNumbers = (state) => {
  return state.shipmentsDomainData.orderNumbers.data;
};
const getTripPlanNumbers = (state) => {
  return state.shipmentsDomainData.tripPlanNumbers.data;
};
const getAssetIds = (state) => {
  return state.shipmentsDomainData.assetIds.data;
};
const getTrainIds = (state) => {
  return state.shipmentsDomainData.trainIds.data;
};
const getLineOfBusiness = (state) => {
  return state.shipmentsDomainData.lineOfBusiness.data;
};
const getServiceCodes = (state) => {
  return state.shipmentsDomainData.serviceCodes.data;
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    selectShipmentModesAlphabetically,
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getStatusTypes,
    selectStatusTypesAlphabetically,
    selectShipmentEventsAlphabetically,
    getShipmentIDs,
    getParts,
    getRouteIDs,
    getShipmentTypes,
    getOrderNumbers,
    getTripPlanNumbers,
    getAssetIds,
    getTrainIds,
    getLineOfBusiness,
    getServiceCodes,
  },
  reducer: combineReducers({
    exceptionTypes: exceptionTypeDuck.reducer,
    statusTypes: statusTypeDuck.reducer,
    shipmentIDs: shipmentIDDuck.reducer,
    routeIDs: routeIDDuck.reducer,
    shipmentTypes: shipmentTypesDuck.reducer,
    orderNumbers: orderNumbersDuck.reducer,
    tripPlanNumbers: tripPlanDuck.reducer,
    assetIds: assetIdsDuck.reducer,
    trainIds: trainIdsDuck.reducer,
    lineOfBusiness: lineOfBusinessDuck.reducer,
    serviceCodes: serviceCodesDuck.reducer,
  }),
};
