import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import apiUrl from "api-url";

const CarrierViewSubscriptionState = buildSubscriptionState({
  topic: "CarrierView",
  systemType: "entity",
  getSubscribeeId: (subscribee) => subscribee.internal_id,
  getUrl: (id) => apiUrl(`/entity/internal/${id}`),
});

export default CarrierViewSubscriptionState;
