/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import sizeMe from "react-sizeme";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { EventBar } from "../../shipment-detail/ShipmentContentPanel";

const ShipmentTimelineContainer = ({ size, shipment }) => {
  const { t } = useTranslation("shipment-details");
  const [showingLeftSide, setShowingLeftSide] = useState(true);

  const handleArrowClick = () => {
    setShowingLeftSide(!showingLeftSide);
  };

  const width = size.width;
  const numLegs = shipment.shipment_stops.length - 1;
  const scrollThreshold = 4.2;
  const scroll = numLegs > scrollThreshold;
  const orientation = showingLeftSide ? { left: 10 } : { left: -width + 10 };
  const scrollStyles = scroll
    ? {
        overflowX: "hidden",
        overflowY: "hidden",
      }
    : {};

  return (
    <div
      css={{
        position: "relative",
        height: scroll ? "10em" : "100%",
        margin: "0 10px",
        ...scrollStyles,
      }}
    >
      {scroll ? (
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div onClick={handleArrowClick}>
            <IoIosArrowBack
              size="1.5em"
              style={{
                display: showingLeftSide ? "none" : "inline",
                cursor: showingLeftSide ? "none" : "pointer",
              }}
            />
          </div>
          <div onClick={handleArrowClick}>
            <IoIosArrowForward
              size="1.5em"
              style={{
                display: !showingLeftSide ? "none" : "inline",
                cursor: !showingLeftSide ? "none" : "pointer",
              }}
            />
          </div>
        </div>
      ) : null}
      <div
        css={{
          width: scroll ? (width * numLegs) / scrollThreshold : "100%",
          position: scroll ? "absolute" : null,
          ...orientation,
        }}
      >
        <EventBar
          shipment={shipment}
          scrollSize={4}
          t={t}
          mode={"Multimodal"}
        />
      </div>
    </div>
  );
};

ShipmentTimelineContainer.propTypes = {
  size: PropTypes.object,
  shipment: PropTypes.object,
};

export default sizeMe({ monitorHeight: true })(ShipmentTimelineContainer);
