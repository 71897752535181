/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { FlexColDiv } from "../../../styles/container-elements";
import _ from "lodash";

import Colors from "../../../styles/colors";
import { CHICLET_COLORS } from "../../../components/chiclets";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
} from "../../../utils/date-time";

const Addr = styled.div({
  color: "#a1a1a1",
  lineHeight: "1em",
  marginTop: ".5em",
});

function renderAddressField(field) {
  return !_.isNil(field) ? (
    <Addr>
      <span
        style={{ color: Colors.text.DARK_BLUE, fontWeight: "bold" }}
      >{`${field}`}</span>
    </Addr>
  ) : null;
}

export const CoordinateInfoPopup = ({ data, t }) => {
  let col = CHICLET_COLORS.Unclassified;

  const city = data.city ? data.city : _.get(data, "address.City", null);
  const state = data.state ? data.state : _.get(data, "address.State", null);
  const id = data.popUpDetails?.id ?? null;
  const year = data.popUpDetails?.year ?? null;
  const make = data.popUpDetails?.make ?? null;
  const model = data.popUpDetails?.model ?? null;
  const positionType = data.popUpDetails?.positionType
    ? t("map:Position Type") + ": " + data.popUpDetails?.positionType
    : null;

  const parseAddressFromLabel = (address) => {
    return address?.split(",") ?? [];
  };
  const locationName =
    data.popUpDetails?.name ?? _.get(data, "address.name", null);
  const streetAddr =
    data.popUpDetails?.address ??
    parseAddressFromLabel(_.get(data, "address.Label", null))[0];
  const country =
    data.popUpDetails?.country ??
    parseAddressFromLabel(_.get(data, "address.Label", null))[3];

  return (
    <FlexColDiv
      css={{
        backgroundColor: "white",
        border: `4px solid ${col.primary}`,
        borderRadius: 4,
        padding: ".5em",
        fontSize: "x-small",
        width: `${id ? "15em" : "10em"}`, //don't want popup for shipments to be too wide
      }}
    >
      {renderAddressField(id)}
      {renderAddressField(year)}
      {renderAddressField(make)}
      {renderAddressField(model)}
      <Addr>
        <span
          style={{ color: Colors.text.DARK_BLUE, fontWeight: "bold" }}
        >{`${localizedDateFormatter(data.time)}`}</span>
      </Addr>
      <Addr>
        <span
          style={{ color: Colors.text.DARK_BLUE, fontWeight: "bold" }}
        >{`${localizedTimeFormatter(data.time)}`}</span>
      </Addr>
      {(data?.popUpDetails?.type === "currentLocation" ||
        data?.popUpDetails?.type === "currentLocationPackage") &&
      locationName ? (
        <Addr>{locationName} </Addr>
      ) : null}

      {(data?.popUpDetails?.type === "currentLocation" ||
        data?.popUpDetails?.type === "currentLocationPackage") &&
      streetAddr ? (
        <Addr>{streetAddr} </Addr>
      ) : null}

      {!_.isNil(city) && !_.isNil(state) ? (
        <Addr>{`${city}, ${state}`}</Addr>
      ) : null}

      {(data?.popUpDetails?.type === "currentLocation" ||
        data?.popUpDetails?.type === "currentLocationPackage") &&
      country ? (
        <Addr>{country}</Addr>
      ) : null}

      {renderAddressField(positionType)}

      {/* <div
        css={{
          borderLeft: "12px solid transparent",
          borderRight: "12px solid transparent",
          borderTop: `12px solid ${col.primary}`,
          bottom: -6,
          height: 0,
          position: "absolute",
          right: 11,
          width: 0
        }}
      /> */}
    </FlexColDiv>
  );
};
