/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { Text } from "components/atoms/Text.atom";
import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

export const DetailPanelGroupItem = ({
  rowStyle = {},
  label,
  labelStyle = {},
  valueStyle = {},
  "data-qa": dataQa,
  children,
}) => {
  return (
    <FlexRowDiv
      css={{
        padding: "1em 0",
        alignItems: "center",
        borderTop: `1px solid ${Colors.background.GRAY}`,
        ...rowStyle,
      }}
    >
      <span
        css={{
          marginLeft: "1em",
          width: "4em",
          color: Colors.text.GRAY,
          fontSize: ".8em",
          ...labelStyle,
        }}
        data-qa={dataQa ? dataQa + "-label" : null}
      >
        {label}
      </span>
      <Text
        css={{
          color: Colors.text.DARK_BLUE,
          fontSize: "1.25em",
          wordBreak: "break-word",
          ...valueStyle,
        }}
        data-qa={dataQa ? dataQa + "-field" : null}
      >
        {children}
      </Text>
    </FlexRowDiv>
  );
};

DetailPanelGroupItem.propTypes = {
  rowStyle: PropTypes.object,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  valueStyle: PropTypes.object,
  dataQa: PropTypes.string,
  children: PropTypes.node,
  "data-qa": PropTypes.string,
};
