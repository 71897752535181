import { connect } from "react-redux";
import { BulkActionModal } from "./DriveAway.BulkActionModal";
import DriveAwayBulkActionModalState from "../../redux/DriveAwayBulkActionModalState";

const mapStateToProps = (state) => {
  const { getBulkActionStatus, getRequestResults } =
    DriveAwayBulkActionModalState.selectors;

  return {
    bulkActionStatus: getBulkActionStatus(state),
    requestResults: getRequestResults(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { bulkActionSubmit, resetBulkActionStatus } =
    DriveAwayBulkActionModalState.actionCreators;

  return {
    bulkActionSubmit: (
      bulkActionType,
      csvData,
      requesterPhoneNum,
      userPhoneNumberExt,
    ) =>
      dispatch(
        bulkActionSubmit(
          bulkActionType,
          csvData,
          requesterPhoneNum,
          userPhoneNumberExt,
        ),
      ),
    resetBulkActionStatus: () => {
      dispatch(resetBulkActionStatus());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionModal);
