import { connect } from "react-redux";
import DocumentationState from "./DocumentationState";
import { ApiSampleView } from "./ApiSampleView";

function mapStateToProps(state) {
  const filename = state.location.payload.filename;

  return {
    filename: filename,
    isLoading: DocumentationState.selectors.getApiSampleStatus(state),
    apiSample: DocumentationState.selectors.getApiSample(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApiSample: (filename) =>
      dispatch(DocumentationState.actionCreators.fetchApiSample(filename)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiSampleView);
