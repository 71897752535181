/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal } from "components/molecules/Modal.molecule";

import { FormButton } from "components-old/modal-elems";
import { FlexRowDiv } from "styles/container-elements";
import ExportsState, { ExportStatus, ExportType } from "./ExportsState";
import NotificationsState from "../notifications/NotificationsState";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";

const { fetchExportDownload, addExport, updateExport } =
  ExportsState.actionCreators;

const { getExport } = ExportsState.selectors;

const { fetchNotification } = NotificationsState.actionCreators;

const ExportModal = ({
  exportIdentifier,
  exportName,
  resetExport = () => {},
  exportType = ExportType.CSV,
}) => {
  const { t } = useTranslation("exports");
  const dispatch = useDispatch();

  const { status: exportStatus } = useSelector(getExport(exportIdentifier));
  const isExportReady = exportStatus === ExportStatus.READY;

  const [showExportModal, setShowExportModal] = useState(false);

  useEffect(() => {
    if (
      exportStatus === ExportStatus.TIMEOUT ||
      exportStatus === ExportStatus.ERROR
    ) {
      setShowExportModal(false);
      resetExport();
    }
    //eslint-disable-next-line
  }, [exportStatus]);

  useEffect(() => {
    if (exportIdentifier && exportName) {
      dispatch(addExport(exportIdentifier, exportName, {}, exportType));

      setShowExportModal(true);
    } else {
      setShowExportModal(false);
      resetExport();
    }
    dispatch(fetchNotification({ pageSize: 10, refresh: true, page: 0 }));

    //eslint-disable-next-line
  }, [exportIdentifier, exportName, dispatch]);

  const handleMinimizeClick = async () => {
    setShowExportModal(false);
    resetExport();
  };

  const handleDownloadClick = () => {
    dispatch(fetchExportDownload(exportIdentifier, exportType));
    setShowExportModal(false);
    resetExport();
  };

  const handleCancelClick = () => {
    dispatch(updateExport(exportIdentifier, { status: ExportStatus.CANCELED }));
    setShowExportModal(false);
    resetExport();
  };

  return (
    <Modal
      backdrop={"static"}
      show={showExportModal}
      onHide={handleCancelClick}
    >
      <Modal.Header title={t("exports:Exporting Data")} />
      <Modal.Body>
        <div>
          {!isExportReady ? (
            <FlexRowDiv css={{ marginBottom: "1em" }}>
              {t(
                "exports:Depending on the amount of data you've selected to export, this may take some time. You can minimize this to the Notifications area and be notified when the download is complete.",
              )}
            </FlexRowDiv>
          ) : null}
          {isExportReady ? (
            <FlexRowDiv css={{ marginBottom: "1em" }}>
              {t(
                "exports:When the export is complete, you will have 1 hour to download the file before it expires.",
              )}
            </FlexRowDiv>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={!isExportReady ? t("exports:Cancel") : t("exports:Close")}
          clickHandler={handleCancelClick}
        />
        <FormButton
          actionType="ACTION"
          label={t("exports:Minimize")}
          disabled={isExportReady}
          clickHandler={handleMinimizeClick}
        />

        <Button
          disabled={!isExportReady}
          style={{
            backgroundColor: Colors.highlight.GREEN,
            color: "white",
            fontWeight: "300",
            minWidth: 88,
          }}
          onClick={handleDownloadClick}
        >
          {!isExportReady ? (
            <Icon src={faSpinner} spin={true}></Icon>
          ) : (
            <Text>{t("exports:Download")}</Text>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExportModal.propTypes = {
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  resetExport: PropTypes.func,
  exportType: PropTypes.object,
};

export default ExportModal;
