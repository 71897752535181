import { connect } from "react-redux";
import SetManualETARangeTool from "./SetManualETARangeTool.page";
import SetManualETARangeToolState from "../redux/SetManualETARangeToolState";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  const { getActionStatus, getRequestResults } =
    SetManualETARangeToolState.selectors;

  return {
    actionStatus: getActionStatus(state),
    requestResults: getRequestResults(state),
    solutionId: getSolutionId(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { clearActionStatus, submitBulkAction } =
    SetManualETARangeToolState.actionCreators;

  return {
    clearActionStatus: () => {
      dispatch(clearActionStatus());
    },
    submitBulkAction: (solutionId, payload) => {
      dispatch(submitBulkAction(solutionId, payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetManualETARangeTool);
