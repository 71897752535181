import apiUrl from "api-url";
import { combineReducers } from "redux";
import _ from "lodash";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const STORE_MOUNT_POINT = "ctDomainData";
const ctStatusDuck = buildFetchDuck("ctStatusDuck");
const ctLadDuck = buildFetchDuck("ctLadDuck");
const ctActiveExceptionsDuck = buildFetchDuck("ctActiveExceptionsDuck");
const ctBatteryLife = buildFetchDuck("ctBatteryLife");

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getStatus = createSelectorFromProperty("data", "status");
const getLad = createSelectorFromProperty("data", "lads");
const getActiveExceptions = createSelectorFromProperty(
  "data",
  "activeExceptions",
);
const getBatteryLife = createSelectorFromProperty("data", "batteryLife");

export function fetchDomainData() {
  const baseurl = `/containertracking/api/filtervalues`;

  const urlStatus = apiUrl(`${baseurl}?filter_name=CONTAINER_TRACKING_STATUS`);

  const urlLads = apiUrl(`${baseurl}?filter_name=CONTAINER_TRACKING_LAD`);

  const urlActiveExceptions = apiUrl(
    `${baseurl}?filter_name=CONTAINER_TRACKING_EXCEPTIONS`,
  );

  const urlBatteryLife = apiUrl(
    `${baseurl}?filter_name=CONTAINER_TRACKING_BATTERY_LIFE`,
  );

  return (dispatch) => {
    dispatch(ctStatusDuck.fetch(urlStatus));
    dispatch(ctLadDuck.fetch(urlLads));
    dispatch(ctLadDuck.fetch(urlLads));
    dispatch(ctActiveExceptionsDuck.fetch(urlActiveExceptions));
    dispatch(ctBatteryLife.fetch(urlBatteryLife));
  };
}

export const ContainerTrackingDomainData = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getStatus,
    getLad,
    getActiveExceptions,
    getBatteryLife,
  },
  reducer: combineReducers({
    status: ctStatusDuck.reducer,
    lads: ctLadDuck.reducer,
    activeExceptions: ctActiveExceptionsDuck.reducer,
    batteryLife: ctBatteryLife.reducer,
  }),
};
