/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import SelectFilter from "components/organisms/base-table/Filter/SelectFilter";
import i18n from "i18n";

const geofenceFilterOptions = [
  { value: "Point", label: i18n.t("locations:Radial") },
  { value: "Polygon", label: i18n.t("locations:Polygon") },
  { value: "MultiPolygon", label: i18n.t("locations:MultiPolygon") },
];

const GeofenceFilter = ({ onChange }) => {
  return (
    <SelectFilter
      placeholder={null}
      options={geofenceFilterOptions}
      onChange={(option) => {
        onChange(option?.value);
      }}
    />
  );
};

GeofenceFilter.propTypes = {
  onChange: PropTypes.func,
};

export default GeofenceFilter;
