/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormLabel, FormGroup, FormControl } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import {
  PhoneNumberInput,
  isPossiblePhoneNumber,
} from "components/molecules/PhoneInput.molecule";
import { isValidEmail } from "utils/validation-utils";
import Colors from "styles/colors";
import { FederatedUserEmailTrail } from "modules/auth/Authorization";
import AuthenticationUtils from "modules/auth/authentication";

export const AlertMePreferenceForm = ({
  userPreferences,
  setPreferences,
  hasDealerPickUpFeature = false,
}) => {
  const { t } = useTranslation("profile");

  const { email = "", phone = "" } =
    userPreferences?.defaultCommunication ?? {};

  const currentUserEmail = AuthenticationUtils.userEmail ?? "";

  const checkPreferenceUpdateModal = currentUserEmail?.endsWith(
    FederatedUserEmailTrail,
  );

  const [emailAddress, setEmailAddress] = useState(email);
  const [phoneNumber, setPhoneNUmber] = useState(phone);

  useEffect(() => {
    setEmailAddress(email);
  }, [email]);

  const isEmailAddressValid = !emailAddress || isValidEmail(emailAddress);
  const isPhoneNumberValid = !phoneNumber || isPossiblePhoneNumber(phoneNumber);

  const submitPreferences = () => {
    if (checkPreferenceUpdateModal && !emailAddress) {
      AuthenticationUtils.setHasRequestedFederatedUserEmail(false);
    }
    setPreferences({
      ...userPreferences,
      defaultCommunication: {
        email: emailAddress ?? "",
        phone: phoneNumber ?? "",
      },
    });
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.background.LIGHT_GRAY,
        border: "1px solid white",
        borderRadius: 8,
        padding: "1.25em",
      }}
    >
      <Text css={{ fontWeight: 400, marginBottom: "0.5em" }}>
        {t("profile:Alert Me Preferences")}
      </Text>
      <Text
        size={FontSize.size12}
        css={{
          fontWeight: 400,
          marginBottom: "1em",
        }}
      >
        {t(
          "profile:Save a default email address and phone number to subscribe to alerts.",
        )}
      </Text>

      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Email Address */}
        <FormLabel>
          {t("profile:Default Email Address")}
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>
                {hasDealerPickUpFeature
                  ? t(
                      "profile:This email address is used for email notifications from Alert Me subscriptions & Dealer Pick Up Submissions. This may differ from your login email Address.",
                    )
                  : t(
                      "profile:This email address is used for email notifications from alert me subscriptions. This may differ from your login email address.",
                    )}
              </Text>
            }
            showDelayInMs={500}
          >
            <Icon
              src={faQuestionCircle}
              style={{ cursor: "pointer", marginLeft: 8 }}
              data-qa="text-tooltip-email"
            />
          </Tooltip>
        </FormLabel>
        <FormGroup css={{ marginBottom: "1rem" }}>
          <FormControl
            type="text"
            value={emailAddress}
            placeholder={t("profile:Email Address")}
            onChange={(e) => setEmailAddress(e.target.value)}
            data-qa="input-profile-email-address"
          />
        </FormGroup>

        {/* Phone Number */}
        <FormLabel>
          {t("profile:Default Phone Number")}
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>
                {hasDealerPickUpFeature
                  ? t(
                      "profile:This phone number is used for text message notifications from Alert Me subscriptions & Dealer Pick Up Submissions. Choose country code from the drop-down or type the country code along with your mobile phone number.",
                    )
                  : t(
                      "profile:This phone number is used for text message notifications from alert me subscriptions. Choose country code from the drop-down or type the country code along with your mobile phone number.",
                    )}
              </Text>
            }
            showDelayInMs={500}
          >
            <Icon
              src={faQuestionCircle}
              style={{ cursor: "pointer", marginLeft: 8 }}
              data-qa="text-tooltip-phone"
            />
          </Tooltip>
        </FormLabel>

        <PhoneNumberInput
          placeholder={t("profile:Phone Number")}
          value={phoneNumber}
          onChange={(value) => setPhoneNUmber(value)}
          data-qa="input-profile-phone-number"
        />
      </div>

      <Button
        variant="primary"
        disabled={!isEmailAddressValid || !isPhoneNumberValid}
        onClick={submitPreferences}
        css={{ marginTop: "auto", alignSelf: "end" }}
      >
        {t("profile:Save Preferences")}
      </Button>
    </div>
  );
};

AlertMePreferenceForm.propTypes = {
  setPreferences: PropTypes.func.isRequired,
  userPreferences: PropTypes.object,
  hasDealerPickUpFeature: PropTypes.bool,
};
