/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Colors from "styles/colors";

export const ActionNav = ({ eventHandler }) => {
  const { t } = useTranslation("container-tracking");
  const [isOpen, setIsOpen] = useState(false);
  const actionToggle = () => setIsOpen(!isOpen);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        variant="primary"
        open={isOpen}
        text={t("container-tracking:Actions")}
        id="action-selector"
        color={Colors.tableHeadingBackground}
        onToggle={(e) => {
          actionToggle();
        }}
        className="float-end"
      >
        <Dropdown.Item
          onClick={() => {
            eventHandler("MANAGE_LOCATIONS");
          }}
        >
          {t("container-tracking:Manage Route")}
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

ActionNav.propTypes = {
  eventHandler: PropTypes.func.isRequired,
};
