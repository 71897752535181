import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import { isShipper } from "shared/utils/organizations.utils";

export const locationsUrl = (solutionId) => {
  if (solutionId) {
    return apiUrl(
      `/entity-search/solution/${solutionId}/list?driveawayLocations=1`,
    );
  }
  return apiUrl(`/entity-search/list?driveawayLocations=1`);
};

export const ddaDealerRegionUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?ddaDealerRegion=1`);
};

export const ddaDealerZoneUrl = (solutionId) => {
  return apiUrl(`/entity-search/solution/${solutionId}/list?ddaDealerZone=1`);
};

export const ddaDealerDistrictUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?ddaDealerDistrict=1`,
  );
};

export const customersUrl = (solutionId) => {
  return apiUrl(`/entity-search/list?ddaShipper=1`);
};

export const dealersUrl = (solutionId, state) => {
  const isShipperRole = isShipper(state.organizations.activeOrganization);
  if (solutionId && isShipperRole) {
    return apiUrl(`/entity-search/solution/${solutionId}/list?dealer=1`);
  }
  return apiUrl(`/entity-search/list?dealer=1`);
};

export const statusUrl = () => {
  return apiUrl(`/entity-search/list?ddaStatus=1`);
};

export const ddaProductTypeUrl = (solutionId, state) => {
  const isShipperRole = isShipper(state.organizations.activeOrganization);
  if (solutionId && isShipperRole) {
    return apiUrl(
      `/entity-search/solution/${solutionId}/list?ddaProductType=1`,
    );
  }
  return apiUrl(`/entity-search/list?ddaProductType=1`);
};

export const lastMilestoneUrl = (solutionId) => {
  return solutionId
    ? apiUrl(`/entity-search/solution/${solutionId}/list?lastMilestone=1`)
    : apiUrl(`/entity-search/list?lastMilestone=1`);
};

export const destinationUrl = () => {
  return apiUrl(`/entity-search/list?ddaDestinationLocations=1`);
};

const STORE_MOUNT_POINT = "driveAwayDomainData";
const driveAwayDomainDuck = buildFetchDuck("driveAwayDomainDuck");

export function fetchDomainData(solutionId) {
  let url = apiUrl(`/entity-search/list`);

  // params for fetching filter options, for small amount of options
  const domainDataParams = {
    ddaStatus: 1,
    ddaCarrier: 1,
  };

  return (dispatch, getState) => {
    const state = getState();
    const isShipperRole = isShipper(state.organizations.activeOrganization);

    if (solutionId && isShipperRole) {
      url = apiUrl(`/entity-search/solution/${solutionId}/list`);
    }
    dispatch(driveAwayDomainDuck.fetch(url, { params: domainDataParams }));
  };
}

const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent].data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getStatusStates = createSelectorFromProperty(
  "ddaStatus", // target key in response data
  "domainData",
);

const getCarriers = createSelectorFromProperty(
  "ddaCarrier",
  "domainData",
  "carrierName",
);

const getDomainDataLoading = (state) =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getStatusStates,
    getCarriers,
    getDomainDataLoading,
  },
  reducer: combineReducers({
    domainData: driveAwayDomainDuck.reducer,
  }),
};
