/** @jsxImportSource @emotion/react */
import { MdWarning, MdDone } from "react-icons/md";
import Colors from "../../../styles/colors";
import moment from "moment";
import { Privileges } from "../../auth/Authorization";

const warnings = {
  "<24 hours": Colors.highlight.YELLOW,
  "<7 days": Colors.highlight.ORANGE,
  "<7-14 days": Colors.highlight.RED,
  "15+ days": Colors.highlight.GREEN,
};

const WarningRenderer = (props) => {
  if (props.row?.original?.warning) {
    if (props.row.original.warning === "15+ days") {
      return (
        <MdDone
          color={warnings[props.row.original.warning]}
          css={{ width: 24, height: 24 }}
        />
      );
    } else {
      return (
        <MdWarning
          color={warnings[props.row.original.warning]}
          css={{ width: 24, height: 24 }}
        />
      );
    }
  } else {
    return null;
  }
};

const WatchRackRenderer = (props) => {
  const watch = props.row?.original?.watch;
  const { authorization, toggleWatchedRackFlag, solutionId } = props.value;

  // Only users with MANAGE_ENTITY can toggle the watched rack flag
  const isAuthorized = authorization.hasPrivileges([Privileges.MANAGE_ENTITY]);

  return (
    <input
      type="checkbox"
      checked={watch}
      onChange={() => {
        if (isAuthorized) {
          toggleWatchedRackFlag(solutionId, props.row.original);
        }
      }}
      style={{ margin: 0 }}
      disabled={!isAuthorized}
    />
  );
};

const DateRenderer = (props) => {
  return props.value ? (
    <div>{moment(props.value).format("l")}</div>
  ) : (
    <div>N/A</div>
  );
};

const LocationLinkRenderer = (props) => {
  const { chooseLocation, rackLocation } = props.value;
  return (
    <div
      onClick={chooseLocation ? () => chooseLocation(rackLocation) : null}
      style={
        chooseLocation
          ? {
              textDecoration: "underline",
              cursor: "pointer",
              color: Colors.highlight.BRIGHT_BLUE,
            }
          : null
      }
    >
      {rackLocation}
    </div>
  );
};

export const resultsTableColumns = (
  t,
  solutionId,
  authorization,
  toggleWatchedRackFlag,
  chooseLocation,
) => {
  return [
    {
      Header: "",
      id: "watch",
      accessor: (d) => {
        return {
          solutionId: solutionId,
          authorization: authorization,
          toggleWatchedRackFlag: toggleWatchedRackFlag,
        };
      },
      Cell: WatchRackRenderer,
      width: 30,
      disableSortBy: true,
      disableResizing: true,
    },
    {
      Header: "",
      accessor: "warning",
      Cell: WarningRenderer,
      width: 44,
      disableSortBy: true,
      disableResizing: true,
    },
    {
      Header: t("mt-dashboard:Rack ID"),
      accessor: "id",
      width: 180,
      Cell: (cellInfo) => (
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {cellInfo?.row?.original?.id}
        </div>
      ),
    },
    {
      Header: t("mt-dashboard:Tag ID"),
      accessor: "tagId",
    },
    {
      Header: t("mt-dashboard:CSPC"),
      accessor: "cspcId",
      width: 100,
    },
    {
      Header: t("mt-dashboard:Heat Treatment Date"),
      accessor: "heatTreatDate",
      Cell: DateRenderer,
      width: 140,
    },
    {
      Header: t("mt-dashboard:Use By Date"),
      accessor: "useByDate",
      Cell: DateRenderer,
      width: 100,
    },
    {
      Header: t("mt-dashboard:Actual Quantity"),
      accessor: "actualQuantity",
      width: 100,
    },
    {
      Header: t("mt-dashboard:Max Quantity"),
      accessor: "maxQuantity",
      width: 100,
    },
    {
      Header: t("mt-dashboard:Rack Type"),
      accessor: "description",
      width: 100,
    },
    {
      Header: t("mt-dashboard:Rack Location"),
      Cell: LocationLinkRenderer,
      id: "rackLocation",
      accessor: (d) => {
        return {
          rackLocation: d.rackLocation,
          chooseLocation: authorization.hasPrivileges([
            Privileges.VIEW_LOCATIONS,
          ])
            ? chooseLocation
            : null,
        };
      },
    },
  ];
};
