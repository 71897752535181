/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { PickUpWindowsModal } from "../../modals/PickUpWindowsModal";

export const PickupWindowField = (props) => {
  const { pickupWindows, pickUpWindowTz } = props;
  const { t } = useTranslation("driveaway-search");
  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  const [showTimeWindowsDialog, setShowTimeWindowsDialog] = useState(false);
  return (
    <Fragment>
      {pickupWindows?.length > 0 ? (
        <Fragment>
          <div className="mt-2 d-flex flex-column">
            <Text>
              <Text bold>{t("driveaway-search:Pickup Window")}:</Text>
              <Tooltip
                placement="top"
                tooltipChildren={
                  <Text>
                    {t(
                      "driveaway-search:Displayed in time zone of pickup location",
                    )}
                  </Text>
                }
              >
                <Icon src={faInfoCircle} style={{ marginLeft: 8 }} />
              </Tooltip>
            </Text>
            {!_.isNil(pickUpWindowTz) &&
            !_.isNil(pickupWindows[0].startTs) &&
            !_.isNil(pickupWindows[0].endTs) ? (
              pickupWindows?.length === 1 ? (
                <Text>
                  {moment
                    .utc(pickupWindows[0].startTs)
                    .tz(pickUpWindowTz)
                    .format(`${dateFormat} ${timeFormat} z`)}
                  {" - "}
                  {moment
                    .utc(pickupWindows[0].endTs)
                    .tz(pickUpWindowTz)
                    .format(`${timeFormat} z`)}
                </Text>
              ) : (
                <div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowTimeWindowsDialog(true);
                    }}
                  >
                    {t("driveaway-search:Show All Pickup Windows")}
                  </Button>
                </div>
              )
            ) : (
              <Text>
                {t(
                  "driveaway-search:Pickup Window unavailable, please contact FreightVerify",
                )}
              </Text>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PickUpWindowsModal
              show={showTimeWindowsDialog}
              onHide={() => setShowTimeWindowsDialog(false)}
              pickupWindow={pickupWindows}
              pickupWindowTz={pickUpWindowTz}
            ></PickUpWindowsModal>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

PickupWindowField.propTypes = {
  pickupWindows: PropTypes.arrayOf(PropTypes.object),
  pickupWindowTz: PropTypes.string,
};
