import { formatTimestamp } from "utils/date-time";

export const sortByAddress = (rowA, rowB, columnId) => {
  let address1 = rowA.values[columnId];
  let address2 = rowB.values[columnId];

  if (
    (address1.address === null || address1.address.length === 0) &&
    (address2.address === null || address2.address.length === 0)
  ) {
    return 0;
  } else if (address1.address === null || address1.address.length === 0) {
    return 1;
  } else if (address2.address === null || address2.address.length === 0) {
    return -1;
  } else {
    return address1.address > address2.address ? 1 : -1;
  }
};

export const sortByTimestamp = (rowA, rowB, columnId) => {
  let timestamp1 = formatTimestamp(rowA.values[columnId]);
  let timestamp2 = formatTimestamp(rowB.values[columnId]);

  // sort date
  let date1 = timestamp1.date;
  let date2 = timestamp2.date;
  if (date1 > date2) {
    return 1;
  }
  if (date1 < date2) {
    return -1;
  }

  // sort time
  let time1 = timestamp1.time;
  let time2 = timestamp2.time;
  if (time1 > time2) {
    return 1;
  }
  if (time1 < time2) {
    return -1;
  }

  //default case: when equal values
  return 0;
};
