import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import { FORECASTED_VINS_FILTERS } from "../search/InvertoryView.Details.Search.Options";
import InventoryViewDetailsForecastedVinsSearchBarState from "../../redux/InventoryViewDetailsForecastedVinsSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { selectCurrentPositionTypes } from "./InventoryViewSearchFilterSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  searchFilters:
    InventoryViewDetailsForecastedVinsSearchBarState.selectors.getSearchFilters(
      state,
    ),
  solutionId: getSolutionId(state),
  currentPositionTypes: selectCurrentPositionTypes(state),
  hasSearchCriteriaChanged:
    InventoryViewDetailsForecastedVinsSearchBarState.selectors.getHasSearchCriteriaChanged(
      state,
    ),
  canUserSearch:
    InventoryViewDetailsForecastedVinsSearchBarState.selectors.getCanUserSearch(
      state,
    ),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = InventoryViewDetailsForecastedVinsSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FORECASTED_VINS_FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
