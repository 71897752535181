/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { extractLastReportedAt } from "../ShipmentUtils";
import { FlexDiv } from "styles/container-elements";
import Colors from "styles/colors";
import { humanizeTimeString } from "utils/date-time";
import { useTranslation } from "react-i18next";
import { convertMeasurementUnits } from "utils/measurement-utils";
import PropTypes from "prop-types";
import { Mode } from "shared/constants/shipments.const";

const LastUpdateDisplay = ({ shipment }) => {
  const { t } = useTranslation("shipment-details");
  // Display requirement same as ShipmentSearchColumns
  const reportedAt = extractLastReportedAt(
    shipment.active_status_ng,
    shipment.current_status_ng,
    shipment.active_exceptions_ng,
    shipment.current_location,
    shipment.destination_actual_arrival,
    shipment.mode_name,
    t,
  );

  const duplicateCoordinates = shipment.current_location
    ? checkForDuplicateCoordinates(shipment.current_location.updates)
    : null;

  const timeBlock = duplicateCoordinates ? (
    <div
      css={{
        flexDirection: "column",
        marginTop: "1.25em",
      }}
    >
      <div>{reportedAt.formattedLastTime}</div>
      <div css={{ fontWeight: "bold", color: Colors.text.YELLOW }}>
        {duplicateCoordinates}
      </div>
    </div>
  ) : (
    <div>{reportedAt.formattedLastTime}</div>
  );

  // H1-820: Do not show 'miles out' for Ocean & Air shipments
  const hideRemainingDistanceModes = [Mode.OCEAN, Mode.AIR];
  const showRemainingDistance = !hideRemainingDistanceModes.includes(
    shipment.mode_name,
  );
  let distance = 0;
  let unit = "mi";
  let unitLabel = t("miles out");
  if (showRemainingDistance) {
    const { preferredUnit, convertedValue } = convertMeasurementUnits({
      unit: "mi",
      value: shipment.remaining_distance,
    });
    distance = convertedValue;
    unit = preferredUnit;
    if (unit === "km") {
      unitLabel = t("kilometers out");
    }
  }

  return reportedAt.showDistance ? (
    <FlexDiv
      css={{
        alignItems: "center",
        fontSize: "small",
        flex: 1,
        justifyContent: "space-between",
        marginRight: "3em",
        marginLeft: "1.5em",
        marginBottom: "-1.5em",
      }}
      data-qa="text-last-update"
    >
      <div css={{ fontSize: "1.2em", fontWeight: "bold" }}>
        {t("Last Update")}:
      </div>
      <span data-qa="text-time-last-update">{timeBlock}</span>
      <div data-qa="text-city-last-update">{reportedAt.formattedLastCity}</div>
      {showRemainingDistance && shipment.remaining_distance > 0 ? (
        <div data-qa="text-distance-last-update">
          {_.round(distance)} {unitLabel}
        </div>
      ) : (
        <div></div>
      )}
    </FlexDiv>
  ) : null;
};

const checkForDuplicateCoordinates = (updates) => {
  // Sort the time in descending order, so most
  // recent update is at top of list
  const sortedCoords = _.orderBy(updates, ["time"], ["desc"]);

  // See how many of the recent coordinates are duplicates
  let numDuplicates = 0;
  for (let i = 1; i < sortedCoords.length; i++) {
    if (
      sortedCoords[0].longitude === sortedCoords[i].longitude &&
      sortedCoords[0].latitude === sortedCoords[i].latitude
    ) {
      numDuplicates = i;
    } else {
      break;
    }
  }

  if (numDuplicates < 3) {
    return null;
  }

  // Compute the time since the first duplicate.
  const duplicateDuration = humanizeTimeString(
    sortedCoords[numDuplicates].time,
    false,
  );

  return `Identical coordinates for ${duplicateDuration}`;
};

LastUpdateDisplay.propTypes = {
  shipment: PropTypes.shape({
    remaining_distance: PropTypes.number,
    active_status_ng: PropTypes.string,
    current_status_ng: PropTypes.string,
    active_exceptions_ng: PropTypes.string,
    current_location: PropTypes.shape({
      updates: PropTypes.arrayOf(
        PropTypes.shape({
          longitude: PropTypes.number.isRequired,
          latitude: PropTypes.number.isRequired,
          time: PropTypes.string,
        }),
      ),
    }),
    destination_actual_arrival: PropTypes.string,
    mode_name: PropTypes.string,
  }).isRequired,
};

export default LastUpdateDisplay;
