import _ from "lodash";
import { faTruckContainer } from "@fortawesome/pro-light-svg-icons";
import { IconType } from "components/atoms/enums";

export const getExceptionData = (
  exceptions = [],
  exceptionTypes = [],
  entityCount = 0,
) => {
  const updatedExceptions = exceptions.map((exception) => {
    return {
      ...exception,
      name: exception.name === "In Hold" ? "On Hold" : exception.name,
      percentage:
        entityCount === 0
          ? "0"
          : ((exception.count / entityCount) * 100).toFixed(1),
    };
  });

  exceptionTypes.forEach((type) => {
    if (!_.find(updatedExceptions, { reason_code: type.reasonCode })) {
      updatedExceptions.push({
        name: type.name,
        reason_code: type.reasonCode,
        count: 0,
        percentage: 0,
      });
    }
  });

  return updatedExceptions;
};

export const getIconData = (exceptionType) => {
  switch (exceptionType) {
    case "Delivered":
      return {
        type: IconType.FontAwesome,
        src: faTruckContainer,
      };
    default:
      return null;
  }
};
