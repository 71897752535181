import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import Colors from "styles/colors";
import { ShipmentDwellModalMode } from "./ShipmentDwellNotificationSearch.page";

export const useShipmentDwellNotificationSearchColumns = (actionHandler) => {
  const { t } = useTranslation("shipment-dwell");

  let columns = [];

  columns.push({
    Header: t("shipment-dwell:Location Name"),
    accessor: "loc_name",
  });

  columns.push({
    Header: t("shipment-dwell:Location Code"),
    accessor: "loc_code",
    width: 100,
  });

  columns.push({
    Header: t("shipment-dwell:Address"),
    accessor: "loc_address",
    width: 100,
  });

  columns.push({
    Header: t("shipment-dwell:Level 1 Alert Users"),
    accessor: "level_1",
    width: 100,
  });

  columns.push({
    Header: t("shipment-dwell:Level 2 Alert Users"),
    accessor: "level_2",
    width: 100,
  });

  columns.push({
    Header: t("shipment-dwell:Level 3 Alert Users"),
    accessor: "level_3",
    width: 100,
  });

  columns.push({
    Header: t("shipment-dwell:Actions"),
    disableSortBy: true,
    disableResizing: true,
    centerAligned: true,
    width: 300,
    Cell: (cellProps) => {
      const { original } = cellProps.row;

      return (
        <Fragment>
          <Button
            variant="link"
            css={{
              color: Colors.text.HIT_GRAY,
              ":hover": { color: "rgba(0,0,0,0.5)" },
            }}
            onClick={() =>
              actionHandler(ShipmentDwellModalMode.UPDATE, original)
            }
          >
            {t("shipment-dwell:Edit Recipients")} <Icon src={faEdit} />
          </Button>
          <Button
            variant="link"
            css={{
              color: Colors.text.HIT_GRAY,
              ":hover": { color: "rgba(0,0,0,0.5)" },
            }}
            onClick={() =>
              actionHandler(ShipmentDwellModalMode.DELETE, original)
            }
          >
            {t("shipment-dwell:Delete")} <Icon src={faTrashAlt} />
          </Button>
        </Fragment>
      );
    },
  });

  return columns;
};
