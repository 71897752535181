/** @jsxImportSource @emotion/react */
// Third party Libraries
import PropTypes from "prop-types";

// UI Libraries
import { Text } from "components/atoms/Text.atom";

const ModifiedByCell = (props) => {
  const modifiedBy = props.value;
  return (
    <div>
      <Text css={{ wordBreak: "break-all" }}>{modifiedBy}</Text>
    </div>
  );
};

export { ModifiedByCell };

ModifiedByCell.propTypes = {
  value: PropTypes.string,
};
