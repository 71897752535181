export enum ShipmentStatusCode {
  SHIPMENT_CREATED = "SC",
  SHIPMENT_UPDATED = "SU",
  SHIPMENT_CANCELLED = "CA",
  ASSET_ASSIGNED = "XB",
  ASSET_UNASSIGNED = "XX",
  ARRIVED = "X3",
  ARRIVED_AT_DROP_OFF = "X1",
  DEPARTED_PICK_UP = "AF",
  DEPARTED_DROP_OFF = "CD",
  DELIVERED = "D1",
  PICKED_UP = "CP",
  ESTIMATED_DELIVERY = "AG",
  BEHIND_SCHEDULE = "behind_schedule",
  BEHIND_SCHEDULE_CLEARED = "behind_schedule_clear",
  MANUAL_CARRIER_DELAY = "manual_carrier_delay",
  MANUAL_SHIPPER_DELAY = "manual_shipper_delay",
  CLEAR_MANUAL_CARRIER_DELAY = "clear_manual_carrier_delay",
  CLEAR_MANUAL_SHIPPER_DELAY = "clear_manual_shipper_delay",
  BACKORDER = "back_order",
  CLEAR_BACK_ORDER = "clear_back_order",
  OFF_ROUTE = "off_route",
  CLEAR_OFF_ROUTE = "clear_off_route",
  RAIL_ARRIVED_AT_DROP_OFF = "6005",
  RAIL_ARRIVED = "6006",
  RAIL_DEPARTED_DROP_OFF = "6003",
  RAIL_DEPARTED_PICK_UP = "6016",
  RAIL_PICKED_UP = "6001",
  RAIL_DELIVERED = "6003",
  RAIL_PLACEMENT_ACTUAL = "6007",
  RAIL_PLACEMENT_CONSTRUCTIVE = "6009",
  RAIL_BAD_ORDER = "6014",
  PASSING_LOCATION = "7099",
  DEPARTED_FROM_ORIGIN_LOCATION = "9002",
}

export enum ShipmentStatusActorType {
  SYSTEM = "system",
  HUMAN = "human",
}

export enum ShipmentStatusType {
  SENT_BY_CARRIER = 1,
  SYSTEM_GENERATED = 2,
  FOR_SUPPLEMENTAL_DATA = 3,
}

export enum Mode {
  TRUCK = "Truck",
  RAIL = "Rail",
  LTL = "LTL",
  INTERMODAL = "Intermodal",
  OCEAN = "Ocean",
  MULTIMODAL = "Multimodal",
  AIR = "Air",
  PARCEL = "Parcel",
}

export enum ModeId {
  TRUCK = 1,
  RAIL = 2,
  LTL = 3,
  INTERMODAL = 4,
  OCEAN = 5,
  MULTIMODAL = 6,
  AIR = 7,
  PARCEL = 8,
}

export enum ShipmentStatus {
  ACTIVE = "Active",
  ARRIVED = "Arrived",
  AVAILABLE_FOR_UNLOAD = "Available for Unload",
  CANCELED = "Canceled",
  PENDING_ASSET_ID = "Pending: Asset ID",
  RELEASED = "Released",
  SCHEDULED = "Scheduled",
  EXPIRED = "Expired",
}

export enum ShipmentStatusName {
  ASSET_ASSIGNED = "Asset Assigned",
  ESTIMATED_DELIVERY = "Estimated Delivery",
  BAD_ORDER = "Bad order - HOURS TO REPAIR UNKNOWN",
  DEPARTED_FROM_LOCATION = "Departed from location",
  PULL_FROM_PATRON_SIDING = "Pull from Patron Siding",
  RELEASE_EMPTY = "Release Empty",
}

export enum ShipmentEvent {
  ARRIVED_AT_DESTINATION = "Arrived at Destination",
  ARRIVED_AT_ORIGIN = "Arrived at Origin",
  ARRIVED_AT_STOP = "Arrived at Stop",
  AVAILABLE_FOR_DELIVERY = "Available for Delivery",
  DEPARTED_DESTINATION = "Departed Destination",
  DEPARTED_ORIGIN = "Departed Origin",
  DEPARTED_STOP = "Departed Stop",
}

export enum ShipmentException {
  BACKORDER = "Backorder",
  BAD_ORDER = "Bad Order",
  BEHIND_SCHEDULE = "Behind Schedule",
  CARRIER_DELAYED = "Carrier Delayed",
  SHIPPER_DELAYED = "Shipper Delayed",
  EXPIRED = "Expired",
  IDLE_TRAIN = "Idle Train",
  IN_HOLD = "In Hold",
  LOST = "Lost",
  MISSED_DROP_OFF = "Missed Drop-Off",
  MISSED_PICKUP = "Missed Pickup",
  UNDER_REVIEW = "Under Review",
  OFF_ROUTE = "Off Route",
  NOT_APPLICABLE = "N/A",
}

export enum ShipmentType {
  INBOUND = "Inbound",
  RACK_RETURN = "Rack Return",
  MULTI_STOP = "Multi Stop",
}

export enum LineOfBusiness {
  AFTERMARKET = "Aftermarket",
  FINISHED_VEHICLE = "Finished Vehicle",
  INBOUND = "Inbound",
}

export enum LoadedStatus {
  EMPTY = "Empty",
  LOADED = "Loaded",
}

export enum ETA {
  DELAYED = "DELAYED",
  TBD = "TBD",
}

export enum Region {
  AFRICA = "Africa",
  ASIA = "Asia",
  AUSTRALIA = "Australia",
  EUROPE = "Europe",
  NORTH_AMERICA = "North America",
  SOUTH_AMERICA = "South America",
}

export type StatusUpdate = {
  current_city?: string;
  current_state?: string;
  status_details?: {
    geofence_name: string;
  };
  status_code?: string;
  status_name?: string;
  stop_location_name?: string;
  mode_id?: ModeId;
  obc_asset_id?: string;
};

type ShipmentDetails = {
  eta: string | null;
  eta_metadata: string;
  is_multileg: true;
  line_of_business: string;
  major_mode: string;
  mode: Mode;
  related_shipments: Array<{
    active_status: string;
    child_shipment_id: number;
    creator_shipment_id: string;
    current_exception: string | null;
    destination_delivery_window: string;
    origin_pickup_window: string;
    trailer_number: string;
  }>;
  relation: "Parent" | "Child";
  sub_mode: string | null;
  is_back_order?: boolean;
  is_off_route?: boolean;
};

export type Shipment = {
  id: number;
  creator_shipment_id: string;
  shipment_type_ng: string | null;
  mode_name: string;
  active_status_ng: string;
  current_status_ng: string;
  active_exceptions_ng: string;
  current_exception: string;
  shipper_name: string;
  carrier_name: string;
  carrier_scac: string;
  loaded_status: string | null;
  rail_train_id: string | null;
  trailer_equipment_number: string | null;
  current_road_name: string | null;
  current_location: {
    reported_at: string | null;
    latitude: number | null;
    longitude: number | null;
    current_city: string | null;
    current_state: string | null;
    current_country: string | null;
    destination_eta: string | null;
    destination_progress: string | null;
    remaining_miles: number | null;
    updates: Array<{
      time: string | null;
      db_time: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      latitude: number | null;
      longitude: number | null;
      mode_id: number | null;
      equipment_description: string | null;
      event_references: Array<unknown>;
    }>;
  };
  shipment_details: ShipmentDetails;

  origin_name: string;
  origin_address: string;
  origin_city: string;
  origin_state: string;
  origin_postal_code: string;
  origin_country: string;
  origin_earliest_arrival: string | null;
  origin_latest_arrival: string | null;
  origin_actual_arrival: string | null;
  origin_actual_departure: string | null;
  origin_calculated_eta: string | null;

  destination_name: string;
  destination_address: string;
  destination_city: string;
  destination_state: string;
  destination_postal_code: string;
  destination_country: string;
  destination_earliest_arrival: string | null;
  destination_latest_arrival: string | null;
  destination_actual_arrival: string | null;
  destination_actual_departure: string | null;
  destination_calculated_eta: string | null;
  destination_is_fv_eta: boolean;
  destination_frozen_eta: string | null;
  available_for_unload_ts: string | null;
};

export type ShipmentTrip = {
  id: number;
  creator_shipment_id: string;
  mode_name: string;
  shipper_name: string;
  created_by_org_id: number;
  carrier_fv_id: string;
  watched: boolean;
  active_status: string | null;
  active_status_ng: string;
  current_exception: string | null;
  active_exception_ng: string;
  active_shipment: number | null;
  active_child_shipment: string | null;
  child_ids: Array<number>;
  child_shipments: Array<{
    shipment_id: number;
  }>;
  destination_calculated_eta: string | null;
  destination_eta: string;
  shipment_details: ShipmentDetails;
};
