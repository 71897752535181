/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  faPencilAlt,
  faSave,
  faTimes,
  faTrashAlt,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faDrawCircle,
  faDrawSquare,
  faDrawPolygon,
} from "@fortawesome/pro-light-svg-icons";

import Colors from "styles/colors";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { TextInput } from "components/atoms/TextInput.atom";
import { Button } from "components/atoms/Button.atom";
import GeofenceType, {
  allowedGeojsonFeatureTypes,
  getType,
} from "./geofence-types";
import { geofenceWithUpdatedCoordinates } from "../geofence-edit/geofence-coordinates";
import { GeofenceEditPolygonalForm } from "./GeofenceEditPolygonalForm";
import { GeofenceEditRadialForm } from "./GeofenceEditRadialForm";
import { NumberedLadChicletCSS as Chiclet } from "components/chiclets";
import { isCoordValid } from "./geofence-coordinates";

const FenceValidityMarker = ({ isValid }) => {
  const { t } = useTranslation("geofence-edit");
  const color = isValid ? Colors.highlight.GREEN : Colors.highlight.RED;
  const icon = isValid ? faCheckCircle : faExclamationTriangle;
  const text = isValid
    ? t("geofence-edit:Valid Fence")
    : t("geofence-edit:Invalid Fence");

  return (
    <p
      css={{
        marginLeft: "1.5em",
        marginBottom: 0,
        color: color,
        fontSize: "0.9em",
      }}
    >
      <Icon src={icon} style={{ marginRight: 4 }} />
      <Text>{text}</Text>
    </p>
  );
};

FenceValidityMarker.propTypes = {
  isValid: PropTypes.bool,
};

export const FlexRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  ({ styleOverrides }) => {
    return styleOverrides;
  },
);

const PolygonRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    border: "2px",
    borderStyle: "solid",
    borderRadius: "5px",
    padding: "2px 0 2px 0",
    margin: "1% 0",
  },
  ({ highlight = false }) => ({
    borderColor: highlight
      ? Colors.highlight.YELLOW
      : Colors.background.LIGHT_GRAY,
  }),
);

const EditGeofenceTypeButton = ({
  disabled,
  fenceType,
  showUpdateGeofenceTypeModal,
  isReadOnly,
}) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {fenceType === GeofenceType.RADIAL ? (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon
            src={faDrawCircle}
            size={FontSize.size28}
            color={Colors.icon.GEOFENCE}
            style={{ marginRight: 5 }}
          />
          <Text bold>{t("geofence-edit:Radial Fence")}</Text>
        </span>
      ) : fenceType === GeofenceType.POLYGONAL ? (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon
            src={faDrawSquare}
            size={FontSize.size28}
            color={Colors.icon.GEOFENCE}
            style={{ marginRight: 5 }}
          />
          <Text bold>{t("geofence-edit:Polygonal Fence")}</Text>
        </span>
      ) : (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon
            src={faDrawSquare}
            size={FontSize.size28}
            color={Colors.icon.GEOFENCE}
            style={{ marginRight: 1 }}
          />
          <Icon
            src={faDrawPolygon}
            size={FontSize.size28}
            color={Colors.icon.GEOFENCE}
            style={{ marginRight: 5 }}
          />
          <Text bold>{t("geofence-edit:Multi Polygon Fence")}</Text>
        </span>
      )}
      {!isReadOnly && (
        <Button
          disabled={disabled}
          variant={"link"}
          css={linkStyle}
          onClick={() => showUpdateGeofenceTypeModal()}
        >
          <Text>{t("geofence-edit:Edit Type")}</Text>
        </Button>
      )}
    </div>
  );
};

EditGeofenceTypeButton.propTypes = {
  disabled: PropTypes.bool,
  fenceType: PropTypes.object,
  showUpdateGeofenceTypeModal: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

const linkStyle = {
  textDecoration: "underline",
  color: "#acb5be",
  cursor: "pointer",
  fontSize: "0.9em",
  whiteSpace: "nowrap",
};

const CancelTracingButton = ({ cancelTracing }) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div>
      <Button variant={"link"} css={linkStyle} onClick={() => cancelTracing()}>
        {t("geofence-edit:Cancel Tracing")}
      </Button>
    </div>
  );
};

CancelTracingButton.propTypes = {
  cancelTracing: PropTypes.func,
};

const DeletePolygonButton = ({ deletePolygon, polygonIndex, disabled }) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div>
      <Button
        disabled={disabled}
        variant={"link"}
        css={linkStyle}
        onClick={() => deletePolygon(polygonIndex)}
      >
        {t("geofence-edit:Delete")}
        <Icon src={faTrashAlt} style={{ marginLeft: 4 }} />
      </Button>
    </div>
  );
};

DeletePolygonButton.propTypes = {
  deletePolygon: PropTypes.func,
  polygonIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

const MultiPolygonForm = ({
  data,
  geofence,
  updateGeofence,
  isTracing,
  editingPolygonIndex,
  cancelTracing,
  deletePolygon,
  isReadOnly,
}) => {
  const updatePolygonName = (polygonIndex, newName) => {
    let newPolygons = _.cloneDeep(geofence.geometry);
    newPolygons[polygonIndex].name = newName;

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newPolygons);

    updateGeofence(newGeofence);
  };

  if (geofence.geometry) {
    return geofence.geometry
      .sort((p1, p2) => (p1.sequence > p2.sequence ? 1 : -1))
      .map((polygon, polygonIndex) => (
        <MultiPolygon
          key={polygonIndex}
          polygon={polygon}
          polygonIndex={polygonIndex}
          updatePolygonName={updatePolygonName}
          data={data}
          isTracing={isTracing}
          editingPolygonIndex={editingPolygonIndex}
          cancelTracing={cancelTracing}
          deletePolygon={deletePolygon}
          isReadOnly={isReadOnly}
        />
      ));
  }
  return <div />;
};

const MultiPolygon = ({
  polygon,
  polygonIndex,
  updatePolygonName,
  data,
  isTracing,
  editingPolygonIndex,
  cancelTracing,
  deletePolygon,
  isReadOnly,
}) => {
  const { t } = useTranslation("geofence-edit");

  const textInputRef = useRef(null);

  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (polygon?.name) {
      setNewName(polygon.name);
    }
  }, [polygon]);

  useEffect(() => {
    if (isEditingName === true) {
      textInputRef.current.focus();
    }
  }, [isEditingName]);

  const savePolygon = () => {
    updatePolygonName(polygonIndex, newName);
    setIsEditingName(false);
  };

  return (
    <PolygonRow
      key={`polygon-${polygonIndex + 1}`}
      highlight={editingPolygonIndex === polygonIndex}
    >
      <Chiclet
        height={25}
        width={25}
        style={{
          marginLeft: 10,
          marginRight: 10,
          minWidth: 25,
        }}
        lad={data.lad}
        numberLabel={polygonIndex + 1}
      />
      <div
        css={{
          flex: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 0, // https://css-tricks.com/flexbox-truncated-text/
          marginRight: 10,
          justifyContent: "center",
        }}
      >
        {isEditingName ? (
          <TextInput
            ref={textInputRef}
            value={newName}
            placeholder={t("Name")}
            onChange={(value) => {
              setNewName(value);
            }}
            onPressEnter={savePolygon}
          />
        ) : polygon.name ? (
          <Tooltip
            placement="top"
            showDelayInMs={500}
            tooltipChildren={<Text>{polygon.name}</Text>}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Text
              onClick={() => {
                setIsEditingName(true);
              }}
            >
              {polygon.name}
            </Text>
          </Tooltip>
        ) : (
          <Text
            italic
            color={Colors.text.LIGHT_GRAY}
            onClick={() => {
              setIsEditingName(true);
            }}
          >
            {t("Add a name")} ({t("default")}:{polygonIndex + 1})
          </Text>
        )}
        {isEditingName ? (
          <React.Fragment>
            <Tooltip placement="top" tooltipChildren={<Text>{t("Save")}</Text>}>
              <Icon
                src={faSave}
                color={Colors.text.LIGHT_GRAY}
                size={FontSize.size18}
                onClick={savePolygon}
                style={{ marginLeft: 5, cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              tooltipChildren={<Text>{t("Cancel")}</Text>}
            >
              <Icon
                src={faTimes}
                color={Colors.text.LIGHT_GRAY}
                size={FontSize.size18}
                onClick={() => {
                  setNewName(polygon.name ?? "");
                  setIsEditingName(false);
                }}
                style={{ marginLeft: 5, cursor: "pointer" }}
              />
            </Tooltip>
          </React.Fragment>
        ) : (
          <Tooltip placement="top" tooltipChildren={<Text>{t("Edit")}</Text>}>
            <Icon
              src={faPencilAlt}
              color={Colors.text.LIGHT_GRAY}
              onClick={() => {
                setIsEditingName(true);
              }}
              style={{ marginLeft: 5, cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </div>
      <GeofenceEditPolygonalForm
        points={polygon.geometry.coordinates[0]}
        css={{ flex: 1 }}
      />
      {isTracing && polygonIndex === editingPolygonIndex ? (
        <CancelTracingButton cancelTracing={cancelTracing} />
      ) : !isReadOnly ? (
        <DeletePolygonButton
          disabled={isTracing && polygonIndex !== editingPolygonIndex}
          deletePolygon={deletePolygon}
          polygonIndex={polygonIndex}
        />
      ) : null}
    </PolygonRow>
  );
};

MultiPolygon.propTypes = {
  polygon: PropTypes.object,
  polygonIndex: PropTypes.number,
  updatePolygonName: PropTypes.func,
  data: PropTypes.object,
  isTracing: PropTypes.bool,
  editingPolygonIndex: PropTypes.number,
  cancelTracing: PropTypes.func,
  deletePolygon: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

const GeofenceEditForm = ({
  data,
  addPolygon,
  updateGeofence,
  isTracing,
  editingPolygonIndex,
  cancelTracing,
  deletePolygon,
  showUpdateGeofenceTypeModal,
  isReadOnly,
}) => {
  const { t } = useTranslation("geofence-edit");
  const { geofence } = data;
  const fenceType = getType(geofence);
  const center = geofence.properties.center;
  const fenceIsValid = fenceType.isValid(geofence);

  const isAddPolyButtonDisabled = () => {
    const fenceType = getType(data.geofence);
    return (
      isTracing ||
      fenceType === GeofenceType.RADIAL ||
      (fenceType === GeofenceType.POLYGONAL &&
        data?.geofence?.geometry?.coordinates?.length > 0)
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text bold>{t("geofence-edit:Geofence")}</Text>
        <FenceValidityMarker isValid={fenceIsValid} />
        <EditGeofenceTypeButton
          fenceType={fenceType}
          // Disable editing fence type until location is geocoded.
          disabled={
            isTracing || !isCoordValid(center.latitude, center.longitude)
          }
          showUpdateGeofenceTypeModal={showUpdateGeofenceTypeModal}
          isReadOnly={isReadOnly}
        />
      </div>
      {fenceType === GeofenceType.RADIAL && (
        <PolygonRow>
          <GeofenceEditRadialForm
            data={data}
            isTracing={isTracing}
            updateGeofence={updateGeofence}
            isReadOnly={isReadOnly}
          />
        </PolygonRow>
      )}
      {fenceType === GeofenceType.POLYGONAL &&
        geofence &&
        geofence.geometry &&
        geofence.geometry.coordinates &&
        geofence.geometry.coordinates.length > 0 &&
        geofence.geometry.coordinates[0] && (
          <PolygonRow>
            <GeofenceEditPolygonalForm
              points={geofence.geometry.coordinates[0]}
              isReadOnly={isReadOnly}
            />
            {isTracing ? (
              <CancelTracingButton cancelTracing={cancelTracing} />
            ) : !isReadOnly ? (
              <DeletePolygonButton
                deletePolygon={deletePolygon}
                polygonIndex={0}
              />
            ) : null}
          </PolygonRow>
        )}
      {fenceType === GeofenceType.MULTIPOLYGON && (
        <MultiPolygonForm
          data={data}
          geofence={geofence}
          updateGeofence={updateGeofence}
          isTracing={isTracing}
          editingPolygonIndex={editingPolygonIndex}
          deletePolygon={deletePolygon}
          cancelTracing={cancelTracing}
          isReadOnly={isReadOnly}
        />
      )}

      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 5,
          justifyContent: "flex-end",
        }}
      >
        {!isReadOnly && fenceType !== GeofenceType.RADIAL && (
          <Button
            css={{
              display: "flex",
              alignItems: "center",
            }}
            variant={"primary"}
            disabled={isAddPolyButtonDisabled()}
            onClick={() => {
              addPolygon();
            }}
          >
            <Icon
              src={faPlus}
              size={FontSize.size16}
              style={{ marginRight: 8 }}
            />
            <Text>{t("geofence-edit:Add Polygon")}</Text>
          </Button>
        )}
      </div>
    </div>
  );
};

GeofenceEditForm.propTypes = {
  data: PropTypes.shape({
    geofence: PropTypes.shape({
      geometry: PropTypes.oneOfType([
        // Polygon and Radial
        PropTypes.shape({
          type: PropTypes.oneOf(allowedGeojsonFeatureTypes).isRequired,
          coordinates: PropTypes.oneOfType([
            PropTypes.arrayOf(
              PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
            ), // For Polygon
            PropTypes.arrayOf(PropTypes.number), // For Radial
          ]),
        }).isRequired,
        // Multipolygon
        PropTypes.arrayOf(
          PropTypes.shape({
            geometry: PropTypes.shape({
              type: PropTypes.oneOf(allowedGeojsonFeatureTypes).isRequired,
              coordinates: PropTypes.arrayOf(
                PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
              ),
            }).isRequired,
            name: PropTypes.string,
            sequence: PropTypes.number,
          }),
        ).isRequired,
      ]).isRequired,
      properties: PropTypes.shape({
        center: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
      }),
      lastUpdateTime: PropTypes.string,
      lastUpdater: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isTracing: PropTypes.bool.isRequired,
  editingPolygonIndex: PropTypes.number,
  addPolygon: PropTypes.func.isRequired,
  deletePolygon: PropTypes.func.isRequired,
  cancelTracing: PropTypes.func.isRequired,
  updateGeofence: PropTypes.func.isRequired,
  showUpdateGeofenceTypeModal: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default GeofenceEditForm;
