//External packages
import _ from "lodash";

import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: "Search Everything",
    placeholder: "Search Name, Address, Location Code",
    queryBuilder: getEverythingQueryString,
  },
  {
    queryKey: "name_search",
    label: "Name",
    placeholder: "Search Name",
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "full_address_search",
    label: "Address",
    placeholder: "Search Address",
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "code_search",
    label: "Location Code",
    placeholder: "Search Location Code",
    queryBuilder: getBasicQueryString,
  },
];
const _categoriesByKey = _.keyBy(SEARCH_CATEGORIES, "queryKey");
SEARCH_CATEGORIES.byKey = _.partial(_.get, _categoriesByKey);

export const FILTERS = [];
const _filtersByKey = _.keyBy(FILTERS, "queryKey");
FILTERS.byKey = _.partial(_.get, _filtersByKey);
