/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/molecules/Modal.molecule";
import { Text } from "components/atoms/Text.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { ReadOnlyInput, FormButton } from "components-old/modal-elems";
import Colors from "styles/colors";
import { FlexRowDiv } from "styles/container-elements";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";
import { UsersActionStatus } from "modules/users/UsersState";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const DeleteUserModal = ({
  activeOrganization,
  deleteUser,
  actionStatus,
  clearActionStatus,
  hide,
  show,
  user,
}) => {
  const { t } = useTranslation("users");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const userEmail = useMemo(
    () => (user && user.email ? user.email : ""),
    [user],
  );

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization],
  );

  const shouldShowFailureAlert = (actionStatus) => {
    return (
      actionStatus &&
      [UsersActionStatus.DELETE_USER_FAILED].includes(actionStatus)
    );
  };

  const shouldShowSuccessAlert = (actionStatus) => {
    return (
      actionStatus &&
      [UsersActionStatus.DELETE_USER_SUCCEEDED].includes(actionStatus)
    );
  };

  const getAlertMessage = (actionStatus) => {
    switch (actionStatus) {
      case UsersActionStatus.DELETE_USER_SUCCEEDED:
        return t("users:User has been successfully deleted");
      case UsersActionStatus.DELETE_USER_FAILED:
        return t("users:Error occured while attemting to delete user");
      default:
        return "";
    }
  };

  const onHide = () => {
    clearActionStatus();
    hide();
  };

  return (
    <Modal backdrop={"static"} show={show} onHide={onHide}>
      <Modal.Header title={t("users:Delete User")} />
      <Modal.Body>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={userEmail}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        <Alert
          variant={
            shouldShowFailureAlert(actionStatus)
              ? AlertVariant.Danger
              : AlertVariant.Success
          }
          show={
            shouldShowSuccessAlert(actionStatus) ||
            shouldShowFailureAlert(actionStatus)
          }
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {getAlertMessage(actionStatus, t)}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={
            actionStatus === UsersActionStatus.DELETE_USER_SUCCEEDED
              ? t("users:Close")
              : t("users:Cancel")
          }
          clickHandler={onHide}
        />
        <FormButton
          disabled={
            actionStatus === UsersActionStatus.DELETE_USER_IN_PROGRESS ||
            actionStatus === UsersActionStatus.DELETE_USER_SUCCEEDED
          }
          actionType="DANGER"
          label={
            actionStatus === UsersActionStatus.DELETE_USER_IN_PROGRESS ? (
              <Icon src={faSpinner} spin />
            ) : (
              t("users:Delete")
            )
          }
          clickHandler={() => {
            setShowConfirmationDialog(true);
          }}
        />
        <DialogModal
          show={showConfirmationDialog}
          onHide={() => setShowConfirmationDialog(false)}
          title={t("users:Confirm User Deletion")}
          cancelButtonText={t("users:Cancel")}
          submitButtonText={t("users:Delete User")}
          submitButtonVariant="danger"
          onSubmit={() => {
            setShowConfirmationDialog(false);
            deleteUser(user, activeOrganization.organization_id);
          }}
        >
          <Text block>
            {t("users:Are you sure you want to delete this user?")}
          </Text>
          <Text block bold style={{ marginTop: 10 }}>
            {userEmail}
          </Text>
        </DialogModal>
      </Modal.Footer>
    </Modal>
  );
};
DeleteUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  activeOrganization: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
  };
}

const mapDispatchToProps = {
  deleteUser: UsersState.actionCreators.deleteUser,
  clearActionStatus: UsersState.actionCreators.clearActionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
