const createHTMLMapMarker = ({
  OverlayView = window.google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
      super();
      this.latlng = args.latlng;
      this.html = args.html;
      this.data = args.data;
      this.isClickable = args.isClickable;
      this.scaleX = args.scaleX;
      this.scaleY = args.scaleY;
      this.offsetX = args.offsetX;
      this.offsetY = args.offsetY;

      if (this.offsetX === undefined || this.offsetX === null) {
        this.offsetX = 0;
      }

      if (this.offsetY === undefined || this.offsetY === null) {
        this.offsetY = 0;
      }

      this.setMap(args.map);
    }

    createDiv() {
      this.div = document.createElement("div");
      this.div.style.position = "absolute";
      this.div.style.zIndex = "50";
      if (this.isClickable === true) {
        this.div.style.cursor = "pointer";
      }
      this.div.style.transform = `scale(${this.scaleX}, ${this.scaleY})`;
      if (this.html) {
        this.div.innerHTML = this.html;
      }
      window.google.maps.event.addDomListener(this.div, "click", (event) => {
        window.google.maps.event.trigger(this, "click", event, this);
      });
      window.google.maps.event.addDomListener(
        this.div,
        "mouseover",
        (event) => {
          window.google.maps.event.trigger(this, "mouseover", event, this);
        },
      );
      window.google.maps.event.addDomListener(this.div, "mouseout", (event) => {
        window.google.maps.event.trigger(this, "mouseout", event, this);
      });
    }

    appendDivToOverlay() {
      const panes = this.getPanes();

      if (this.isClickable === true) {
        panes.overlayMouseTarget.appendChild(this.div);
      } else {
        panes.overlayLayer.appendChild(this.div);
      }
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point) {
        this.div.style.left = `${point.x - this.offsetX}px`;
        this.div.style.top = `${point.y - this.offsetY}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }

    getData() {
      return this.data;
    }

    getPosition() {
      return this.latlng;
    }

    getDraggable() {
      return false;
    }
  }

  return new HTMLMapMarker();
};

export default createHTMLMapMarker;
