/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import {
  MediaQueries,
  useIsExtraSmall,
  useIsLarge,
} from "components/responsive";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useGetCountryNameByCountryCode } from "pages/administration/utils/location-utils";

import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { PanelGroupTheme } from "components/molecules/enum";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Bubble } from "components/atoms/Bubble.atom";
import { LadChicletCSS as LadChiclet } from "components/chiclets";
import { TimelineMad } from "components/molecules/TripProgessBar.molecule";
import { TripLegReferencesButton } from "shared/components/modals/TripLegReferences.modal";
import Colors from "styles/colors";
import {
  PanelGroup,
  PanelGroupContent,
  PanelGroupHeader,
} from "components/molecules/PanelGroup.molecule";
import { useUpdatesTable } from "shared/hooks/columns/useUpdatesTable.columns";
import { localizedDateFormatter } from "utils/date-time";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";

const StopTimestampText = ({ type, window, dateTime, className }) => {
  const { t } = useTranslation("fv-vin-details");
  const isExtraSmall = useIsExtraSmall();
  const isLarge = useIsLarge();

  // Display pickup or delivery text
  let label = null;
  if (type === "pickup") {
    label = t("fv-vin-details:Scheduled Pickup");
  } else if (type === "delivery") {
    label = t("fv-vin-details:Scheduled Delivery");
  } else if (type === "etaToStop") {
    label = t("fv-vin-details:ETA to Stop");
  } else if (type === "actualDeliveryToStop") {
    label = t("fv-vin-details:Actual Delivery to Stop");
  }

  // Without a label, this data won't make sense to the user.
  if (label === null) {
    return null;
  }

  return (
    <span
      className={className}
      css={{
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "baseline",
        flexWrap: "wrap",
        [MediaQueries.mediumAndUp]: {
          flexDirection: "row",
        },
        [MediaQueries.largeAndUp]: {
          flexDirection: "column",
        },
        [MediaQueries.extraLarge]: {
          flexDirection: "row",
        },
      }}
    >
      <Text
        size={FontSize.size12}
        bold
        className="me-1"
        data-qa={`texts-rows-scheduled-${type}`}
      >
        {label}
      </Text>
      {window ? (
        <DateTimeRange
          from={window[0]}
          to={window[1]}
          size={FontSize.size12}
          localize
          plain
          stack={isExtraSmall || isLarge}
          data-qa={`texts-rows-time-from-to-${type}`}
        />
      ) : dateTime ? (
        <DateTime
          dateTime={dateTime}
          size={FontSize.size12}
          localize
          plain
          stack={isExtraSmall || isLarge}
          data-qa={`texts-rows-time-${type}`}
        />
      ) : null}
    </span>
  );
};

StopTimestampText.propTypes = {
  type: PropTypes.oneOf([
    "pickup",
    "delivery",
    "etaToStop",
    "actualDeliveryToStop",
  ]).isRequired,
  dateTime: PropTypes.string,
  window: PropTypes.array,
  className: PropTypes.string,
};

const removeHoldUpdates = (plannedStops) => {
  return plannedStops.map((plannedStop) => {
    plannedStop.updates =
      plannedStop?.updates?.filter((update) => {
        return !(
          update?.subcode?.includes("4D") ||
          update?.subcodeDescription?.includes("4D") ||
          update?.code?.includes("HoldCreated") ||
          update?.isHold
        );
      }) ?? [];
    return plannedStop;
  });
};

export const TripSummary = ({
  plannedStops,
  hideMadBorder = false,
  showMadTooltip = false,
  hideScheduled = false,
  showUpdates = true,
  showTripLegReferences = true,
  showComments = true,
  showEta = false,
  eta = null,
  etaWindow = null,
  showHolds = true,
  showActiveShipmentEtaToStop = false,
  showActualDeliveryToStop = false,
  isPartView = false,
}) => {
  let plannedStopsForShipper = plannedStops;

  const {
    columns: updatesTableColumns,
    SubComponent: UpdatesTableSubComponent,
  } = useUpdatesTable(showComments, isPartView);
  // H2-3840 The “Delayed” (hold) Milestone should not be added to the
  // VIN Details Trip Summary
  if (!showHolds) {
    plannedStopsForShipper = removeHoldUpdates(plannedStopsForShipper);
  }

  return (
    <div style={{ padding: "25px 20px 10px 15px" }}>
      {plannedStopsForShipper.map((vinInfo, i) => {
        return (
          <PlannedStopsForShipper
            i={i}
            vinInfo={vinInfo}
            showEta={showEta}
            eta={eta}
            etaWindow={etaWindow}
            showActiveShipmentEtaToStop={showActiveShipmentEtaToStop}
            showActualDeliveryToStop={showActualDeliveryToStop}
            plannedStopsForShipper={plannedStopsForShipper}
            hideMadBorder={hideMadBorder}
            showMadTooltip={showMadTooltip}
            showTripLegReferences={showTripLegReferences}
            showUpdates={showUpdates}
            updatesTableColumns={updatesTableColumns}
            isPartView={isPartView}
            UpdatesTableSubComponent={UpdatesTableSubComponent}
            hideScheduled={hideScheduled}
          />
        );
      })}
    </div>
  );
};

TripSummary.propTypes = {
  hideScheduled: PropTypes.bool,
  hideMadBorder: PropTypes.bool,
  showMadTooltip: PropTypes.bool,
  showTripLegReferences: PropTypes.bool,
  showComments: PropTypes.bool,
  showEta: PropTypes.bool,
  eta: PropTypes.string,
  etaWindow: PropTypes.string,
  showHolds: PropTypes.bool,
  showActiveShipmentEtaToStop: PropTypes.bool,
  showActualDeliveryToStop: PropTypes.bool,
  isPartView: PropTypes.bool,
  plannedStops: PropTypes.array.isRequired,
  showUpdates: PropTypes.bool,
};

const PlannedStopsForShipper = ({
  i,
  vinInfo,
  showEta,
  eta,
  etaWindow,
  showActiveShipmentEtaToStop,
  showActualDeliveryToStop,
  plannedStopsForShipper,
  hideMadBorder,
  showMadTooltip,
  showTripLegReferences,
  showUpdates,
  updatesTableColumns,
  isPartView,
  UpdatesTableSubComponent,
  hideScheduled,
}) => {
  const { t } = useTranslation(["fv-vin-details", "domain"]);

  const { getEtaTranslation, isEtaName } = useEtaTranslations();

  const disable = vinInfo.updates.length === 0;
  const showName = !_.isNil(vinInfo.name);
  const showCity = !_.isNil(vinInfo.city);
  const showCountry = !_.isNil(vinInfo.country);

  // Handle datetime, "Pending Dispatch", "Delayed", or "TBD" as the ETA.
  let etaElement = null;
  if (showEta && !_.isNil(eta) && !_.isEmpty(eta)) {
    if (isEtaName(eta)) {
      etaElement = <Text>{getEtaTranslation(eta)}</Text>;
    } else {
      etaElement = <DateTime dateTime={eta} plain localize />;
      if (etaWindow) {
        const parsedEta = JSON.parse(etaWindow);
        etaElement = `${localizedDateFormatter(parsedEta[0])} -
            ${localizedDateFormatter(parsedEta[1])}`;
      }
    }
  }

  let hasDataForEtaToStop = vinInfo.active && !_.isNil(vinInfo.etaToStop);
  let hasDataForActualDeliveryToStop = !_.isNil(vinInfo.actualDeliveryToStop);

  // FIN-5809: Show scheduled delivery if we don't have the ETA or Actual Delivery to Stop.
  let shouldFallbackToScheduledDelivery =
    showActiveShipmentEtaToStop &&
    !hasDataForEtaToStop &&
    showActualDeliveryToStop &&
    !hasDataForActualDeliveryToStop;

  const start = i === 0;
  const end = i === plannedStopsForShipper.length - 1;
  const countryName = useGetCountryNameByCountryCode(vinInfo.country);
  return (
    <div
      css={{ display: "flex" }}
      key={
        vinInfo.shipment_id
          ? `${vinInfo.shipment_id}-${i}`
          : `planned-stop-${_.kebabCase(vinInfo.name)}-${i}`
      }
    >
      <div
        css={{
          padding: "0 5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Display the green circle before the ultimate origin (first LAD) */}
        {start ? (
          <div
            css={{
              border: "3px solid green",
              borderRadius: "50%",
              width: 14,
              height: 14,
              marginTop: -10,
              marginLeft: 13,
            }}
          />
        ) : null}
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 5,
            flexGrow: 1,
            zIndex: 99,
            position: "relative",
          }}
        >
          {/* This is the "progress bar" for the leg on the timeline */}
          <div
            css={{
              width: 3,
              backgroundColor: vinInfo.isPlannedLegFvGenerated
                ? "grey"
                : "green",
              height: 5,
              flexGrow: 1,
            }}
          />

          {!start && (vinInfo.mode || vinInfo.isPlannedLegFvGenerated) ? (
            <TimelineMad
              mode={vinInfo.mode}
              progress={vinInfo.progress}
              hideBorder={hideMadBorder}
              showTooltip={showMadTooltip}
              isPlannedLegFvGenerated={vinInfo.isPlannedLegFvGenerated}
            />
          ) : null}
        </div>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          {/* LAD */}
          <LadChiclet lad={vinInfo.lad} height={40} width={40} />
        </div>
      </div>
      <div css={{ flexGrow: 1, marginLeft: 10, overflow: "auto" }}>
        {!start && showUpdates ? (
          <div>
            <PanelGroup
              collapsible={true}
              disabled={disable}
              initialCollapsed={true}
              theme={PanelGroupTheme.Grey}
            >
              <PanelGroupHeader
                isPanelGroupCollapsible={true}
                style={{ height: "50px" }}
                title={t("fv-vin-details:Updates")}
                titleSize={FontSize.size15}
                leftContent={
                  <Bubble
                    size={FontSize.size15}
                    color={Colors.text.WHITE}
                    bold
                    background={disable ? "#dedbdb" : Colors.holds.LIGHT_BLUE}
                    css={{
                      marginLeft: 7,
                      minWidth: 30,
                      padding: "2px 8px",
                      display: "block",
                    }}
                  >
                    {vinInfo.updates.length}
                  </Bubble>
                }
                rightContent={
                  showTripLegReferences === true ? (
                    <TripLegReferencesButton t={t} vinInfo={vinInfo} />
                  ) : null
                }
              ></PanelGroupHeader>
              <PanelGroupContent
                style={{
                  padding: "0em",
                  overflow: "hidden",
                }}
              >
                <div
                  css={{
                    padding: 10,
                    backgroundColor: "#E8E7E8",
                    borderRadius: "0px 0px 5px 5px",
                  }}
                >
                  {vinInfo.updates && vinInfo.updates.length > 0 ? (
                    <BaseTable
                      data-qa="updates"
                      theme={Themes.LIGHT}
                      data={vinInfo.updates}
                      columns={updatesTableColumns}
                      disablePagination={true}
                      disableSortBy={true}
                      defaultSortColumn={isPartView ? null : "eventTs"}
                      defaultReverseSort={false}
                      subComponent={(row) => {
                        return (
                          <UpdatesTableSubComponent
                            statusUpdate={row.row.original.statusUpdate}
                            comments={row.row.original.comments}
                            t={t}
                          />
                        );
                      }}
                    />
                  ) : null}
                </div>
              </PanelGroupContent>
            </PanelGroup>
          </div>
        ) : !start && !showUpdates ? (
          showTripLegReferences === true ? (
            <div>
              <TripLegReferencesButton t={t} vinInfo={vinInfo} /> <hr></hr>
            </div>
          ) : (
            <div>
              <hr></hr>
            </div>
          )
        ) : null}

        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            [MediaQueries.smallAndUp]: {
              flexDirection: "row",
            },
            [MediaQueries.largeAndUp]: {
              flexDirection: "column",
            },
            [MediaQueries.extraLarge]: {
              flexDirection: "row",
              flexWrap: "wrap",
            },
            padding: "0 10px",
          }}
        >
          {/* Location Name and Info */}
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              [MediaQueries.smallAndUp]: {
                alignSelf: "flex-end",
              },
              [MediaQueries.largeAndUp]: {
                alignSelf: "unset",
              },
              [MediaQueries.extraLarge]: {
                alignSelf: "flex-end",
              },
            }}
          >
            {showName && (
              <Text
                size={FontSize.size14}
                bold
                data-qa="texts-rows-name-location"
              >
                {vinInfo.name} {vinInfo.code ? `(${vinInfo.code})` : null}
              </Text>
            )}
            {showCity && (
              <Text
                size={FontSize.size14}
                color={Colors.text.LIGHT_GRAY}
                bold
                css={{ whiteSpace: "nowrap" }}
                data-qa="texts-rows-city-location"
              >
                {_.startCase(vinInfo.city.toLowerCase())}, {vinInfo.state}
              </Text>
            )}
            {showCountry && <span>{countryName}</span>}
          </div>
          {/* Scheduled Pickup/Delivery Times */}
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              [MediaQueries.smallAndUp]: {
                flexDirection: "column",
                justifyContent: "flex-start",
              },
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
              },
              [MediaQueries.extraLarge]: {
                flexDirection: "column",
                justifyContent: "flex-end",
                marginLeft: "0.5em",
              },
            }}
          >
            {
              /*H2-641 don't show Scheduled Delivery at Ultimate Origin */
              (!hideScheduled || shouldFallbackToScheduledDelivery) &&
                vinInfo.scheduled_delivery_window &&
                !start && (
                  <StopTimestampText
                    type="delivery"
                    window={vinInfo.scheduled_delivery_window}
                    className="me-2 me-sm-0 me-lg-2 me-xl-0"
                  />
                )
            }
            {
              /*H2-641 don't show Scheduled Pickup at Ultimate Destination */
              !hideScheduled && vinInfo.scheduled_pickup_window && !end && (
                <StopTimestampText
                  type="pickup"
                  window={vinInfo.scheduled_pickup_window}
                />
              )
            }
            {etaElement && end ? (
              <div>
                <Text bold>{t("fv-vin-details:ETA")}:</Text> {etaElement}
              </div>
            ) : null}
            {/* FIN-5585: Display ETA and Actual Delivery to next stop.
                Don't show delivery at ultimate origin (!start)
  */}
            {showActiveShipmentEtaToStop && hasDataForEtaToStop && !start ? (
              <StopTimestampText
                type="etaToStop"
                dateTime={vinInfo.etaToStop}
              />
            ) : null}
            {showActualDeliveryToStop &&
            hasDataForActualDeliveryToStop &&
            !start ? (
              <StopTimestampText
                type="actualDeliveryToStop"
                dateTime={vinInfo.actualDeliveryToStop}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

PlannedStopsForShipper.propTypes = {
  i: PropTypes.number.isRequired,
  vinInfo: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    lad: PropTypes.object,
    actualDeliveryToStop: PropTypes.string,
    scheduled_delivery_window: PropTypes.arrayOf(PropTypes.string),
    scheduled_pickup_window: PropTypes.arrayOf(PropTypes.string),
    updates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,

        entityId: PropTypes.string,
        eventTs: PropTypes.string,
        ts: PropTypes.string,
        update: PropTypes.string,
        code: PropTypes.string,
        codeDescription: PropTypes.string,
        subcode: PropTypes.string,
        subcodeDescription: PropTypes.string,
        comments: PropTypes.string,
        isHold: PropTypes.bool,
        statusUpdate: PropTypes.shape({
          ts: PropTypes.string,
          code: PropTypes.string,
          codeDescription: PropTypes.string,
          subcode: PropTypes.string,
          subcodeDescription: PropTypes.string,
          comments: PropTypes.string,
          references: PropTypes.arrayOf(
            PropTypes.shape({
              qualifier: PropTypes.string,
              value: PropTypes.string,
              referenceLocationId: PropTypes.number,
            }),
          ),
          statusLocationId: PropTypes.number,
          senderId: PropTypes.number,
          senderName: PropTypes.string,
          shipperId: PropTypes.number,
          shipperLocationCode: PropTypes.string,
          sourceId: PropTypes.string,
          sourceName: PropTypes.string,
          sourceScac: PropTypes.string,
          location: PropTypes.shape({
            name: PropTypes.string,
            address: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            country: PropTypes.string,
            postalCode: PropTypes.string,
            timezone: PropTypes.string,
          }),
        }),
      }),
    ),
    progress: PropTypes.number,
    mode: PropTypes.string,
    actualCreatorShipmentId: PropTypes.string,
    shipment_id: PropTypes.string,
    isPlannedLegFvGenerated: PropTypes.bool,
    plannedTripLegReferences: PropTypes.arrayOf(
      PropTypes.shape({
        qualifier: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    active: PropTypes.bool,
    etaToStop: PropTypes.string,
  }).isRequired,
  showEta: PropTypes.bool,
  eta: PropTypes.string,
  etaWindow: PropTypes.string,
  showActiveShipmentEtaToStop: PropTypes.bool,
  showActualDeliveryToStop: PropTypes.bool,
  plannedStopsForShipper: PropTypes.array, // This addresses 'plannedStopsForShipper' and 'plannedStopsForShipper.length'

  hideMadBorder: PropTypes.bool,
  showMadTooltip: PropTypes.bool,
  showTripLegReferences: PropTypes.bool,
  showUpdates: PropTypes.bool,
  updatesTableColumns: PropTypes.array.isRequired,
  isPartView: PropTypes.bool,
  UpdatesTableSubComponent: PropTypes.func.isRequired,
  hideScheduled: PropTypes.bool,
};
