/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useShipmentTranslation } from "shared/hooks/useShipmentTranslation";
import ShipmentDetailsCollapsible from "modules/shipment-detail/shipment-detail-styled-components/ShipmentDetailsCollapsible";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import {
  extractLastReportedAt,
  getAssetId,
  getReferenceValue,
} from "modules/shipment-detail/ShipmentUtils";
import { Shipment } from "shared/constants/shipments.const";
import { Organization } from "shared/constants/organization.const";
import { Mode } from "shared/constants/shipments.const";

type PublicShipmentContentPanelProps = {
  shipment: Shipment;
  organization: Organization;
  showEta?: boolean;
};

export const PublicShipmentContentPanel = ({
  shipment,
  organization,
  showEta = true,
}: PublicShipmentContentPanelProps) => {
  const { t } = useTranslation("shipment-details");
  const { getTranslatedStatus } = useShipmentTranslation();

  const status = shipment
    ? `${getTranslatedStatus(shipment.active_status_ng)} ${getTranslatedStatus(
        shipment.current_status_ng,
      )}`
    : "";

  const mode = shipment.mode_name || "";

  const { origin, destination, calculatedEta, frozenEta, isFvEta } =
    getLocationDataFromShipments([shipment]);

  // Get Asset ID
  const assetId = getAssetId(organization, shipment);

  // Get Trailer and Equipment numbers
  // H1-3859: Only show unique values on the UI.
  //   To see all, the user can look at the References tab.
  const trailerAndEquipmentNumbers = _.uniq(
    shipment.trailer_equipment_number ?? [],
  );

  // Get Service Code
  const serviceCode = getReferenceValue(shipment, "service_code");

  //Get delayed by Carrier or Shipper
  let etaWarningMessage;
  if (!_.isNil(frozenEta)) {
    if (
      shipment.active_exceptions_ng === "Carrier Delayed" &&
      shipment.current_exception === "manual_carrier_delay"
    ) {
      etaWarningMessage = t(
        "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier.",
      );
    } else if (
      shipment.active_exceptions_ng === "Carrier Delayed" &&
      shipment.current_exception === "manual_shipper_delay"
    ) {
      etaWarningMessage = t(
        "shipment-details:Shipper has indicated a delay. This ETA is provided by the Shipper.",
      );
    }
  }

  const reportedAt = extractLastReportedAt(
    shipment.active_status_ng,
    shipment.current_status_ng,
    shipment.active_exceptions_ng,
    shipment.current_location,
    shipment.destination_actual_arrival,
    shipment.mode_name,
  );

  return (
    <div css={{ flexDirection: "column", overflow: "visible" }}>
      {/* Details Group */}
      <ShipmentDetailsCollapsible
        shipmentId={shipment.creator_shipment_id}
        assetId={assetId}
        mode={mode}
        status={status}
        shipmentType={shipment.shipment_type_ng}
        shipperName={shipment.shipper_name}
        carrierName={shipment.carrier_name}
        carrierScac={shipment.carrier_scac}
        currentRoad={shipment.current_road_name}
        trailerAndEquipmentNumbers={trailerAndEquipmentNumbers}
        serviceCode={serviceCode}
        showException={false}
        showMadChiclet={shipment.shipment_details.relation === "Child"}
        railTrainId={shipment.rail_train_id}
        loadedStatus={shipment.loaded_status}
      />
      {/* Arrivals and Departures Group */}
      <ArrivalAndDeparturePanelGroup
        origin={origin}
        destination={destination}
        eta={showEta ? (!_.isNil(frozenEta) ? frozenEta : calculatedEta) : null}
        onlyShowEtaDate={mode === Mode.PARCEL || mode === Mode.LTL}
        hideScheduledTimes={mode === Mode.PARCEL || mode === Mode.LTL}
        etaWarningMessage={etaWarningMessage}
        isFvEta={isFvEta}
        visibleFields={{
          availableForUnload: shipment?.mode_name === "Rail",
        }}
        availableForUnloadTs={shipment?.available_for_unload_ts}
        style={{ margin: "1em" }}
      >
        <ArrivalAndDeparturePanelGroup.SubHeaders>
          <ArrivalAndDeparturePanelGroup.LastUpdateSubHeader
            location={reportedAt.lastCity}
            ts={reportedAt.lastTime}
          />
        </ArrivalAndDeparturePanelGroup.SubHeaders>
      </ArrivalAndDeparturePanelGroup>
    </div>
  );
};
