/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";

import Select from "react-select";
import Async from "react-select/async";

import Colors from "styles/colors";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const SelectField = (props) => {
  const {
    options,
    stateValue,
    onChange,
    isAsync,
    isClearable,
    isDisabled,
    isLoading,
    defaultOptions,
    cacheOptions,
    loadOptions,
    id,
    components,
    placeholder,
    noOptionsMessage,
    isMulti,
    isSearchable,
    borderWidth,
    borderColor,
    borderRadius,
    cursor,
    minWidth,
    fontSize,
    minHeight,
    maxMenuHeight,
    placeholderFontStyle,
    boxShadow,
    indicatorPadding,
    indicatorContainerHeight,
    valueContainerStyle,
    ...fieldProps
  } = props;

  let value;

  if (_.isNil(stateValue)) {
    value = null;
  }

  if (Array.isArray(options)) {
    value = options.filter(({ value }) => value === stateValue);
  }

  if (isMulti) {
    value = stateValue;
  }

  const styles = customStyles({
    borderWidth,
    borderColor,
    borderRadius,
    cursor,
    minWidth,
    fontSize,
    minHeight,
    maxMenuHeight,
    placeholderFontStyle,
    boxShadow,
    indicatorPadding,
    indicatorContainerHeight,
    valueContainerStyle,
  });

  if (isAsync) {
    return (
      <Async
        options={options}
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        defaultOptions={defaultOptions}
        cacheOptions={cacheOptions}
        loadOptions={loadOptions}
        id={id}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        styles={styles}
        {...fieldProps}
      />
    );
  }

  return (
    <Select
      isLoading={isLoading}
      options={options}
      value={value}
      onChange={onChange}
      components={components}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      id={id}
      placeholder={placeholder}
      noOptionsMessage={() => noOptionsMessage}
      styles={styles}
      {...fieldProps}
    />
  );
};

SelectField.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  stateValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isAsync: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  components: PropTypes.object,
  cacheOptions: PropTypes.any,
  loadOptions: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  minWidth: PropTypes.string,
  placeholderFontStyle: PropTypes.string,
  boxShadow: PropTypes.string,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.number,
  minHeight: PropTypes.string,
  maxMenuHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  indicatorPadding: PropTypes.bool,
  indicatorContainerHeight: PropTypes.number,
  valueContainerStyle: PropTypes.object,
};

SelectField.defaultProps = {
  isAsync: false,
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isMulti: false,
  isSearchable: true,
  defaultOptions: null,
  cacheOptions: false,
  loadOptions: null,
  components: null,
  id: null,
  placeholder: "select...",
  noOptionsMessage: "No Results Found",
  minWidth: "16em",
  placeholderFontStyle: "regular",
  boxShadow: "none",
  borderWidth: "1px",
  borderColor: "#ccc",
  borderRadius: "5px",
  fontSize: 14,
  minHeight: "3em",
  indicatorPadding: true,
  indicatorContainerHeight: null,
  valueContainerStyle: null,
};

const customStyles = ({
  borderWidth,
  borderColor,
  borderRadius,
  cursor,
  minWidth,
  fontSize,
  minHeight,
  maxMenuHeight,
  placeholderFontStyle,
  boxShadow,
  indicatorPadding,
  indicatorContainerHeight,
  valueContainerStyle,
}) => {
  return {
    control: (provided, { isDisabled, isMulti }) => ({
      ...provided,
      cursor: cursor,
      minWidth: minWidth,
      minHeight: minHeight ?? null,
      height: isMulti ? null : minHeight ? minHeight : null,
      backgroundColor: isDisabled ? Colors.background.DISABLED_INPUT : "#fff",
      boxShadow: boxShadow,
      border: `${borderWidth} solid ${borderColor}`,
      borderRadius: borderRadius,
      fontSize: fontSize,
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...valueContainerStyle,
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => {
      return {
        // If option labels cause overflow in the menu.
        // Set before "provided" so it can be overwritten.
        wordBreak: "break-word",
        ...provided,
        cursor: cursor,
        color: isDisabled
          ? Colors.text.DISABLED
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        ":active": {
          ...provided[":active"],
          backgroundColor: isSelected
            ? "#EBF3FF"
            : isFocused
            ? "#DEEBFF"
            : "#fff",
          ":last-of-type": {
            borderRadius: "0 0 6px 6px",
          },
        },
      };
    },
    placeholder: (provided) => ({
      ...provided,
      fontStyle: placeholderFontStyle,
    }),
    multiValue: (provided) => {
      return {
        ...provided,
        backgroundColor: "#DEEBFF",
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#2684ff",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#2684ff",
      ":hover": {
        backgroundColor: "#B2D4FF",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: indicatorPadding ? "5px" : 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: indicatorContainerHeight ? indicatorContainerHeight : "100%",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      overflow: "hidden",
      borderRadius: "0 0 5px 5px",
      marginTop: 0,
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: "0 0 5px 5px",
      maxHeight: maxMenuHeight ? maxMenuHeight : provided.maxHeight,
    }),
  };
};

export default SelectField;
