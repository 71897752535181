/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSubcomponentOverride } from "components/hooks/useSubcomponentOverride";
import { DateTime } from "components/atoms/DateTime.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { parseDateTime } from "utils/date-time";

/**
 * Displays the given date times, `to` and `from`, as a range.
 *
 * For customization, you may pass in a <DateTime> component with customizations to override both displayed date times.
 *
 * This example forces the date in both date times to always be bold.
 * See the <DateTime> atom for more information.
 *
 * ```jsx
 * <DateTimeRange to={...} from={...}>
 *   <DateTime>
 *     <DateTime.Date bold />
 *   </DateTime>
 * </DateTimeRange>
 * ```
 */
export const DateTimeRange = ({
  from,
  to,
  localize,
  stack,
  plain,
  showTime = true,
  size = FontSize.size14,
  style = {},
  "data-qa": dataQa,
  children,
}) => {
  const { t } = useTranslation("components");

  const { isValid: isValidTo } = parseDateTime(to, localize ?? false);
  const { isValid: isValidFrom } = parseDateTime(from, localize ?? false);

  const dateTimeFromElement = useSubcomponentOverride(
    children,
    <DateTime
      dateTime={from}
      plain={plain}
      stack={stack}
      localize={localize}
      size={size}
      showTime={showTime}
    />,
  );

  const dateTimeToElement = useSubcomponentOverride(
    children,
    <DateTime
      dateTime={to}
      plain={plain}
      stack={stack}
      localize={localize}
      size={size}
      showTime={showTime}
    />,
  );

  if (!isValidTo || !isValidFrom) {
    return null;
  }

  return (
    <span
      css={{
        fontSize: `${size}rem`,
        display: stack ? "inline-flex" : "inline",
        alignItems: "center",
      }}
      style={style}
      data-qa={dataQa}
    >
      {dateTimeFromElement}
      <Text
        style={{
          fontSize: "1em",
          whiteSpace: "pre",
          margin: stack ? "0 0.5em" : "0 0.1em",
        }}
      >
        {" "}
        {t("components:to")}{" "}
      </Text>
      {dateTimeToElement}
    </span>
  );
};

DateTimeRange.propTypes = {
  /**
   * The date time string for the start of the range.
   */
  from: PropTypes.string.isRequired,
  /**
   * The date time string for the end of the range.
   */
  to: PropTypes.string.isRequired,
  /**
   * Displays both date times in the user's timezone.
   */
  localize: PropTypes.bool,
  /**
   * Displays both date times in a stack layout.
   */
  stack: PropTypes.bool,
  /**
   * Removes default styling of the text.
   */
  plain: PropTypes.bool,
  /**
   * The base font size for both date times.
   */
  size: PropTypes.number,
  /**
   * An object that will be used as the element styles for the root element.
   */
  style: PropTypes.object,
  /**
   * The data attribute for QA testing.
   */
  "data-qa": PropTypes.string,
  /**
   * Show the time part of text. Default `true`.
   */
  showTime: PropTypes.bool,
  /**
   * Override styles of the rendered DateTime components.
   */
  children: PropTypes.node,
};

export { FontSize };
