//External Packages
import _ from "lodash";
import { createSelector } from "reselect";

import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";

const filterNullValues = (values) => values.filter((t) => _.isNil(t) === false);

const filterNullValuesAndMapFirstItem = (values) =>
  filterNullValues(values).map((value) => value[0]);

// Asset IDs are returned from API as arrays - convert to strings
export const selectAssetIdOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getAssetIds],
  filterNullValuesAndMapFirstItem,
);

// Train IDs are returned from API as arrays - convert to strings
export const selectTrainIdOptions = createSelector(
  [ShipmentsDomainDataState.selectors.getTrainIds],
  filterNullValuesAndMapFirstItem,
);
