// NOTE:
// - We are relying on redux-first-router to pass along the errorMessage
//   instead of creating a specfic reducer for these messages.
// - Storing things in `payload` will be saved in the `location` object.
//   This represents the current route and related data.
// - Storing things in `query` is also passed in the `location` object
//   but they will be inserted as QSPs in the URL.
// - We are storing the errorMessage in `payload` instead of `query`
//   so it does not appear in the URL.

/**
 * Generates a redux thunk that redirects to the specified route
 * and stores extra information in the `location` object.
 *
 * @param {string} routeActionType The route action from routes.js
 * @returns
 */
const createRedirectAction = (routeActionType) => {
  return (errorMessage) => {
    return (dispatch) => {
      dispatch({ type: routeActionType, payload: { errorMessage } });
    };
  };
};

export default {
  actionCreators: {
    redirectTo404: createRedirectAction("NOT_FOUND"),
    redirectTo403: createRedirectAction("ACCESS_FORBIDDEN_ERROR"),
  },
};
