import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";
import apiUrl from "api-url";
import { parse } from "utils/json-utils";

import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import {
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  FORECASTED_VINS_FILTERS,
} from "../details/search/InvertoryView.Details.Search.Options";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { parseDateTime } from "utils/date-time";

const STORE_MOUNT_POINT = "forecastedVinsSearch";
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

const formatTimeWindow = (timeWindow) => {
  if (!Array.isArray(timeWindow)) {
    return {};
  }

  const startEndRange = {};

  if (timeWindow[0]) {
    startEndRange.start = parseDateTime(timeWindow[0], true);
  }

  if (timeWindow[1]) {
    startEndRange.end = parseDateTime(timeWindow[1], true);
  }

  return startEndRange;
};

const findForecastedPickup = (item) => {
  let timeWindow = [];

  if (item.activeScheduledPickup) {
    timeWindow = parse(item.activeScheduledPickup);
  }

  return formatTimeWindow(timeWindow);
};

const findForecastedArrival = (item) => {
  let timeWindow = [];

  const scheduledWindow = item?.scheduledArrivalWindow;

  if (scheduledWindow) {
    try {
      timeWindow = parse(scheduledWindow);
    } catch (e) {
      timeWindow = [];
    }
  }

  return { start: timeWindow[0] ?? "", end: timeWindow[1] ?? "" };
};

/**
 * Transform the returned data into a format easily understadable by our table
 */
const transformEntities = (payload) => {
  payload.data = payload.data.map((item) => {
    return {
      id: item.id,
      internal_id: item.internal_id,
      productType: item.description || "",
      shippable: item?.shippable ?? false,
      destinationDealer: item?.ultimateDestination,
      carrierName: item.plannedCarrierName,
      currentLocation: item?.lastPositionUpdate,
      forecastedArrival: findForecastedArrival(item),
      forecastedPickup: findForecastedPickup(item),
      forecastedEtaTime: item?.forecastedLocationEta,
      watch: item?.watch ?? false,
    };
  });
  return payload;
};

const formatBaseQueryString = (queryString) => {
  const params = qs.parse(queryString);

  params.lifeCycleState = "Active";
  params.invDetailsSearch = "forecasted";

  return qs.stringify(params);
};

const formatQueryStingForCount = (queryString) => {
  const params = qs.parse(queryString);

  params.pageNumber = undefined;
  params.pageSize = undefined;

  return qs.stringify(params);
};

const entitiesUrl = (solutionId, qs, state) => {
  const formattedQueryString = formatBaseQueryString(qs);
  return apiUrl(
    `/entity/solution/${solutionId}/entity?${formattedQueryString}&forecastedLocation=${state.location.payload.location_id}`,
  );
};

const entitiesUrlForCount = (solutionId, qs, state) => {
  const formattedQueryString = formatQueryStingForCount(
    formatBaseQueryString(qs),
  );
  return apiUrl(
    `/entity/solution/${solutionId}/entity?${formattedQueryString}&forecastedLocation=${state.location.payload.location_id}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=fin_inventoryview",
    },
  };
};

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const url = entitiesUrl(solutionId, queryString, state);
  const config = axiosConfig();

  // Fetch the search
  dispatch(duck.fetch(url, config, transformEntities));

  // Fetch the count
  dispatch(fetchTotalCount(solutionId, queryString, config));
};

const fetchTotalCount = (solutionId, queryString, config, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();

    const url = entitiesUrlForCount(solutionId, queryString, state);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      ...config,
      method: "GET",
      url,
      data: undefined,
      headers: {
        ...config.headers,
        Accept: "application/json;version=count",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

const setWatchEntity = (solutionId, entityId, internal_id, watch = true) => {
  const url = solutionId
    ? apiUrl(`/entity/solution/${solutionId}/entity/${entityId}`)
    : apiUrl(`/entity/internal/${internal_id}`);
  return (dispatch) => {
    return Promise.all([axios.patch(url, { watch })])
      .then((responses) => {
        dispatch(SearchBarState.actionCreators.searchEntities(solutionId));
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

const forecastedVinsSearchState = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  FORECASTED_VINS_FILTERS,
  fetchSearch,
  [forecastedVinsSearchState],
);

SearchBarState.actionCreators.exportSearch = () => {
  return (dispatch, getState) => {
    const currentSolutionId = getSolutionId(getState());
    dispatch(
      SearchBarState.actionCreators.exportEntities(
        entitiesUrl,
        null,
        { headers: { Accept: "text/csv;version=fin_inventoryview" } },
        "inventory-view-vin-search-results",
        currentSolutionId,
      ),
    );
  };
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  setWatchEntity,
};

export default SearchBarState;
