/**
 * Hosts data strucutres that have allow us to handle groups calculations in a
 * map.
 */
import { getDistance, centralGeolocation, lerp } from "./geo";
import _ from "lodash";

// Helpers
let GROUP_ID = 0;
const generateGroupId = () => {
  GROUP_ID++;
  return `group${GROUP_ID}`;
};

export default class MapGroups {
  constructor(currentMapZoom = 3) {
    this._groups = {};
    this.currentMapZoom = currentMapZoom;
  }

  // Add groups of different kinds

  /**
   * Adds a location already ungrouped, useful to add markers that will be
   * shown as single markers.
   *
   * @param {Object} location
   */
  addUngroupedLocation(location) {
    const id = generateGroupId();
    this.add(id, {
      position: location.position,
      locations: [location],
      id,
      singleElementGroup: true,
    });
  }

  /**
   * Adds a new group with just one location but that can receive other
   * locations in the future.
   *
   * @param {Object} location
   */
  addNewGroupWithOneLocation(location) {
    const id = generateGroupId();
    this.add(id, {
      position: location.position,
      id,
      locations: [location],
      singleElementGroup: false,
    });
  }

  /**
   * Search for the best group (in terms of distance) to put the location
   * inside and add the location to the best group.
   *
   * @param {Object} location
   * @return {Boolean} Return true if the location was added to some group, and
   * false if there is no group that match the expected distance.
   */
  addLocationToExistentGroup(location) {
    let shouldCreateNewGroup = true;

    // Check the distance between this and other groups to check in which
    // one the location will enter
    for (let key of this.keys()) {
      const group = this.get(key);

      // We should not add other locations into a single element group
      if (group.singleElementGroup) {
        continue;
      }

      const d = getDistance(group.position, location.position);
      if (
        shouldCreateNewGroup &&
        d < this._getDistanceBetweenLocationsToBeConsideredSameGroup()
      ) {
        shouldCreateNewGroup = false;
        group.locations.push(location);
      }
    }
    const locationWasAdded = !shouldCreateNewGroup;
    return locationWasAdded;
  }

  // Other operations

  /**
   * Search for all groups and change the value of its positions to the center
   * of the locations that make up the group.
   */
  positionGroupsInTheCenterOfItsLocations() {
    for (let key of this.keys()) {
      let group = this.get(key);
      if (group.locations.length > 1) {
        group.position = centralGeolocation(group.locations);
      }
    }
  }

  /**
   * Get all elements that are ungrouped, check for the ones that are in the
   * same location and put them in the same group.
   */
  groupUngroupedSameLocationInTheSameGroup() {
    const ungroupedGroups = this._getUngroupedGroups();

    for (let key in ungroupedGroups) {
      for (let keyOther in ungroupedGroups) {
        if (
          key !== keyOther &&
          key in this._groups &&
          ungroupedGroups[key].locations[0].latitude ===
            ungroupedGroups[keyOther].locations[0].latitude &&
          ungroupedGroups[key].locations[0].longitude ===
            ungroupedGroups[keyOther].locations[0].longitude
        ) {
          ungroupedGroups[key].locations.push(
            ungroupedGroups[keyOther].locations[0],
          );
          ungroupedGroups[key].sameLocationGroup = true;
          this.remove(keyOther);
        }
      }
    }
  }

  /**
   * Ungroup all markers of a specific group
   *
   * @param {String} id Id of the group you want to ungroup.
   *
   * @return {Boolean} True if group was ungrouped, false otherwise.
   */
  ungroup(id) {
    const groupToRemove = this.get(id);
    // Group that contains only same location stuff can not be ungrouped once
    // it is on the limit of ungrouping
    if (groupToRemove.sameLocationGroup) {
      return false;
    }

    const groupRemoved = this.remove(id);
    // Ungroup, adding the new groups to the big groups list
    groupRemoved.locations.forEach((location, i) => {
      const newId = generateGroupId();
      this.add(newId, {
        position: location.position,
        locations: [location],
        id: newId,
        singleElementGroup: true,
      });
      location.ungrouped = true;
    });
    return true;
  }

  // Getters
  _getUngroupedGroups() {
    return Object.fromEntries(
      Object.entries(this._groups).filter(
        (keyValue) => keyValue[1].singleElementGroup,
      ),
    );
  }

  /**
   * Based on the map zoom, calculate the geographic distance that we will
   * consider to put locations in the same group.
   */
  _getDistanceBetweenLocationsToBeConsideredSameGroup() {
    // ZOOM  Distance
    //
    //  3     5.0
    //  8     0.14      10 m
    return lerp(this.currentMapZoom, 3, 8, 5.0, 0.14);
  }

  getGroupByLocation(location) {
    const result = this.values().filter((group) =>
      _.find(group.locations, { id: location.id }),
    );
    return result[0] ?? null;
  }

  // Simple Operations

  remove(key) {
    const groupRemoved = this._groups[key];
    delete this._groups[key];
    return groupRemoved;
  }

  keys() {
    return Object.keys(this._groups);
  }

  values() {
    return Object.values(this._groups);
  }

  clear() {
    this._groups = {};
  }

  add(key, data) {
    this._groups[key] = data;
  }

  get(key) {
    return this._groups[key];
  }
}
