import { createSelector } from "reselect";
import { createOptionsMapper } from "utils/selectors-utils";

import DamageViewDomainDataState from "./DamageViewDomainData";

export const selectCurrentPositionTypes = createSelector(
  [DamageViewDomainDataState.selectors.getCurrentPositionTypes],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);

export const selectStatusFilterOptions = createSelector(
  [DamageViewDomainDataState.selectors.getStatus],
  createOptionsMapper({
    labelKey: (item) => `${item}`,
    valueKey: (item) => `${item}`,
  }),
);

export const selectInprogressStatusFilterOptions = createSelector(
  [DamageViewDomainDataState.selectors.getInProgressStatus],
  createOptionsMapper({
    labelKey: (item) => `${item}`,
    valueKey: (item) => `${item}`,
  }),
);
