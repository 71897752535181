/** @jsxImportSource @emotion/react */
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";

export const DownloadCsvLink = ({
  data = [],
  headers = [],
  filename = "template.csv",
  label = "Download",
  className,
}) => {
  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={filename}
      enclosingCharacter={``}
      className={className}
    >
      {label} <FontAwesomeIcon icon={faDownload} className="ms-2" />
    </CSVLink>
  );
};

DownloadCsvLink.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  filename: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
