/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Button } from "components/atoms/Button.atom";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";
import { getAuthorizedFilters } from "./filters";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import Colors from "styles/colors";
import { MediaQueries } from "components/responsive";
import { FlexRowDiv } from "styles/container-elements";
import { Tooltip, TooltipTheme } from "components/atoms/Tooltip.atom";

const ClearSearch = (props) => (
  <div
    onClick={props.clickHandler}
    css={{
      cursor: "pointer",
      color: Colors.background.DARK_BLUE,

      // Vertically center the icon
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      // Offset it from the right
      position: "absolute",
      top: 0,
      right: "0.6em",
    }}
    data-qa="button-clear-search"
    className="clear-search"
  >
    <IoMdClose />
  </div>
);

ClearSearch.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export const TypeaheadOption = ({ option, position, t }) => {
  if (!option.label) {
    return false;
  }

  return (
    <MenuItem
      position={position}
      option={option}
      className="typeahead-menu-item d-flex flex-row justify-content-start align-items-end"
      data-qa="typeahead-options-input-search"
    >
      <div
        css={{
          color: Colors.text.GRAY,
          fontSize: "small",
          fontStyle: "italic",
          marginRight: "1em",
        }}
      >
        {option.categoryLabel(t)}
      </div>
      {option.label}
    </MenuItem>
  );
};
TypeaheadOption.propTypes = {
  option: PropTypes.object,
  position: PropTypes.number,
  t: PropTypes.func,
};

const AdvancedSearchButton = styled.div(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "9em",
    minHeight: "3.5em",
    borderRadius: "3px",
    fontStyle: "italic",
    [MediaQueries.smallAndUp]: {
      marginLeft: "0.5em",
    },
    ":hover": { cursor: "pointer", color: Colors.background.DARK_BLUE },
  },
  ({
    showFilters = false,
    backgroundColor = Colors.background.LIGHT_GRAY,
  }) => ({
    borderBottomRightRadius: showFilters ? "0px" : "3px",
    borderBottomLeftRadius: showFilters ? "0px" : "3px",
    backgroundColor: showFilters ? backgroundColor : "none",
    textDecoration: showFilters ? "none" : "underline",
  }),
);

const areThereChangesOnLazyLoadedCategoryData = (
  prevProps,
  newProps,
  categories,
) => {
  // Check for any updates to dynamically-loaded category options
  for (const category of categories) {
    const categoryProp = category.property;
    const categoryLoadingProp = category.loadingProperty;
    const hasCategoryAssociatedPropAndLoadingProp =
      categoryProp || categoryLoadingProp;

    if (!hasCategoryAssociatedPropAndLoadingProp) {
      continue;
    }

    const isCategoryDataValid =
      newProps[categoryProp] && newProps[categoryProp].length;
    const isCategoryDataLoading = newProps[categoryLoadingProp];
    const hasCategoryPropDataChanged =
      newProps[categoryProp] !== prevProps[categoryProp];
    if (
      isCategoryDataValid &&
      !isCategoryDataLoading &&
      hasCategoryPropDataChanged
    ) {
      return true;
    }
  }
};

const callLazyLoadPropertyFetchIfItExists = (
  props,
  categories,
  query,
  maxResults,
  selectedCategory,
) => {
  for (const category of categories) {
    const isCategoryDataLazyLoadable = category.loaderProperty;
    const shouldShowResultsOfThisCategory =
      selectedCategory.queryKey === "everything" ||
      category.queryKey === selectedCategory.queryKey;

    if (isCategoryDataLazyLoadable && shouldShowResultsOfThisCategory) {
      // Pass maxResults to the loader property as the pageSize.
      // We will only have that many results available and we request
      // the full amount in case the user has this paticular category selected.
      props[category.loaderProperty](query, maxResults);
    }
  }
};

const areTherePropertiesLazyLoadingData = (props, categories) => {
  for (const category of categories) {
    if (!category.property) {
      continue;
    }
    if (
      category.loaderProperty &&
      category.loadingProperty &&
      props[category.loadingProperty]
    ) {
      return true;
    }
  }
  return false;
};

class SearchInputInternal extends React.Component {
  state = {
    typeaheadOptions: [],
    isLoading: false,
    asyncOptionsLoading: {},
    typeaheadKey: uuidv4(),
  };
  constructor(props) {
    super(props);
    this._constructTypeaheadOptions =
      this._constructTypeaheadOptions.bind(this);
    this._constructTypeaheadLookup = this._constructTypeaheadLookup.bind(this);
    this.resetSearchBar = this.resetSearchBar.bind(this);

    this.typeaheadOptionsMetadata = this.props.typeaheadOptionsMetadata;
    this.typeaheadOptionsLookup = {};
    for (let category of this.typeaheadOptionsMetadata) {
      this.typeaheadOptionsLookup[category.property] = [];
    }

    this.userInput = false;

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentDidMount() {
    this._constructTypeaheadLookup();
  }

  componentDidUpdate(prevProps) {
    const hasSearchTextChanged = this.props.searchText !== prevProps.searchText;
    const hasSearchCategoryDropdownChanged =
      this.props.searchCategory !== prevProps.searchCategory;
    const isLoading = areTherePropertiesLazyLoadingData(
      this.props,
      this.typeaheadOptionsMetadata,
    );

    if (this.state.isLoading !== isLoading) {
      this.setState({ isLoading: isLoading });
    }

    if (hasSearchTextChanged) {
      callLazyLoadPropertyFetchIfItExists(
        this.props,
        this.typeaheadOptionsMetadata,
        this.props.searchText,
        this.props.maxTypeaheadResults,
        this.props.searchCategory,
      );
      // If this change did not come from user input
      // we are getting a new search text string, we need
      // to give our a typeahead control a new key so it
      // will remount with this text displayed
      if (!this.userInput) {
        this.setState({ typeaheadKey: uuidv4() });
      }
      this.userInput = false;
    }

    // Because of the number of parts, construct this
    // map outside of the render loop for increased performance
    this._constructTypeaheadLookup(this.props);

    const hasLazyLoadedCategoryDataChanged =
      areThereChangesOnLazyLoadedCategoryData(
        prevProps,
        this.props,
        this.typeaheadOptionsMetadata,
      );

    // Refresh the typeahead options if any values have changed
    if (hasSearchCategoryDropdownChanged || hasLazyLoadedCategoryDataChanged) {
      this._constructTypeaheadOptions(
        this.props.searchText,
        this.props.searchCategory,
      );
    }
  }

  _constructTypeaheadLookup(nextProps = null) {
    // Here we use the typeahead metadata to get info from props for each
    // category and build the data we will use for lookup
    // If we receive nextProps, we check if there is some change in data coming
    for (const category of this.typeaheadOptionsMetadata) {
      if (!category.property) {
        continue;
      }

      // Check if some data changed
      if (
        nextProps !== null &&
        (this.props[category.property] === nextProps[category.property]) ===
          this.typeaheadOptionsLookup[category.property]
      ) {
        continue;
      }

      if (!(category.property in this.props)) {
        throw Error(
          `Property ${category.property} is not present in SearchBar instance props`,
        );
      }

      let categoryProp = [];
      if (this.props && this.props[category.property]) {
        categoryProp = this.props[category.property];
      }
      if (nextProps && nextProps[category.property]) {
        categoryProp = nextProps[category.property];
      }

      this.typeaheadOptionsLookup[category.property] = categoryProp
        .filter((v) => {
          return _.isNil(v) === false;
        })
        .map((v) => {
          let label = category.itemLabelProperty
            ? v[category.itemLabelProperty]
            : v;
          let value = category.itemValueProperty
            ? v[category.itemValueProperty]
            : v;
          return {
            label: label,
            value: value,
            category: category.queryKey,
            categoryLabel: category.label,
            categoryLoader: category.loaderProperty,
          };
        });
    }
  }

  _constructTypeaheadOptions(query, searchCategory) {
    // If our query is empty, don't do anything
    // otherwise we load everything into the lookahead
    // and the system gets into a bad state
    if (query.length < 1) {
      return;
    }

    const { queryKey } = searchCategory;
    let typeaheadOptions = [];
    for (const category of this.typeaheadOptionsMetadata) {
      if (!category.property) {
        continue;
      }

      if (queryKey === "everything" || queryKey === category.queryKey) {
        typeaheadOptions = typeaheadOptions.concat(
          this.typeaheadOptionsLookup[category.property],
        );
      }
    }
    this.setState({ typeaheadOptions });
  }

  resetSearchBar() {
    this.typeahead.current?.clear();
    this.props.resetSearchBar();
    this.clearSavedSearch();
  }

  clearSavedSearch() {
    this.props.clearSavedSearch();
  }

  onKeyDown(e) {
    this.userInput = true;
    const {
      showSearchOptions,
      typeaheadOptionsMetadata,
      searchEntities,
      solutionId,
      preventSearchOnChange,
      setSearchCategory,
      searchCategory,
      ignoreSearchCategory,
    } = this.props;

    let preventEnterKeySearch = false;
    if (searchCategory.minCharacters) {
      if (e.target.value.length < searchCategory.minCharacters) {
        preventEnterKeySearch = true;
      }
    }

    if (searchCategory.maxCharacters) {
      if (e.target.value.length > searchCategory.maxCharacters) {
        preventEnterKeySearch = true;
      }
    }

    if (e.key === "Enter" && !preventSearchOnChange && !preventEnterKeySearch) {
      searchEntities(solutionId, true);
    }

    // When there are no way for an user to select search optin he is
    // using, the option is selected automatically when selecting one typeahead
    // option. User may remove this by clicking on the 'x' but it makes more
    // sense to also unselect the category when cleaning the data on the input.
    if (
      e.key === "Backspace" &&
      e.target.value.length === 1 && // backspace was not applied yet
      !showSearchOptions &&
      !ignoreSearchCategory
    ) {
      setSearchCategory(typeaheadOptionsMetadata[0].queryKey);
    }
  }

  onChangeText(e) {
    // H1-1659: We needed to add the setSearchValue because of the new behavior
    // of current_road search category. To keep backwards compatibility, I used
    // _.noop function as default, making it setSearchValue an optional prop
    // for SearchBar
    const {
      setSearchCategory,
      setSearchText,
      setSearchValue = _.noop,
      preventSearchOnChange,
    } = this.props;
    if (e.length > 0) {
      setSearchCategory(e[0].category);
      // If we are preventing search on change,
      // we want to ignore this value change in SearchBarState.
      setSearchText(e[0].label, preventSearchOnChange);
      setSearchValue(e[0].value);
    }
  }

  zIndexValues = {
    focused: 5,
  };

  render() {
    const { typeaheadOptions, isLoading } = this.state;
    const {
      auth,
      searchText,
      setSearchText,
      searchCategory,
      setSearchCategory,
      typeaheadOptionsMetadata,
      showSearchOptions = true,
      maxTypeaheadResults = 5,
      getInputProps = () => ({}),
      canUserSearch,
      preventSearchOnChange,
      t,
    } = this.props;

    const searchCategoryDropdownItems = getAuthorizedFilters(
      Array.from(typeaheadOptionsMetadata),
      auth,
    ).map((cat) => (
      <Dropdown.Item
        key={cat.queryKey}
        eventKey={cat.queryKey}
        data-qa={`menu-item-dropdown-${cat.queryKey}`}
      >
        {cat.label(t)}
      </Dropdown.Item>
    ));

    const searchCategoryButtonTitle = searchCategory
      ? searchCategory.label(t)
      : t("components:Search Everything");

    // H2-1706: Passing auth for the instances where we show a different placeholder
    // depending on the features that are available
    // See FinVehicleSearchCategoryDefs' Search Everything category
    const searchCategoryPlaceholder = searchCategory.placeholder(t, auth);

    let errorTooltip = null;
    if (searchCategory.minCharacters) {
      if (searchText.length < searchCategory.minCharacters) {
        errorTooltip =
          t("components:Minimum characters required") +
          ": " +
          searchCategory.minCharacters;
      }
    }

    if (searchCategory.maxCharacters) {
      if (searchText.length > searchCategory.maxCharacters) {
        errorTooltip =
          t("components:Maximum characters allowed") +
          ": " +
          searchCategory.maxCharacters;
      }
    }

    return (
      <div
        className="search-widgets"
        css={{
          position: "relative",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "3px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          flexGrow: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
          width: "100%",
          [MediaQueries.smallAndUp]: {
            flexDirection: "row",
            alignItems: "center",
            height: 42,
            width: "unset",
          },
        }}
      >
        <FlexRowDiv className="search-icon-input" css={{ flexGrow: 2 }}>
          <Tooltip
            trigger={"focus"}
            placement={errorTooltip ? "bottom" : "top"}
            showDelayInMs={errorTooltip ? 0 : 100}
            hideDelayInMs={400}
            theme={errorTooltip ? TooltipTheme.Danger : TooltipTheme.Default}
            tooltipChildren={
              errorTooltip ? errorTooltip : searchCategoryPlaceholder
            }
            style={{ width: "100%" }}
          >
            <AsyncTypeahead
              id="fin-vehicle-search-bar"
              key={this.state.typeaheadKey}
              ref={(typeahead) => (this.typeahead = typeahead)}
              defaultInputValue={searchText}
              disabled={!canUserSearch}
              useCache={false}
              clearButton={false}
              options={typeaheadOptions}
              filterBy={(option, props) => {
                // Only show options with the user's query text in the label
                // (skip options from dynamically-loaded categories since they were already filtered server-side)
                if (
                  option.categoryLoader ||
                  (option.label &&
                    option.label
                      .toLowerCase()
                      .includes(props.text.toLowerCase()))
                ) {
                  return option;
                }
              }}
              renderMenu={(results, menuProps) => {
                if (!isLoading && !results.length) {
                  return null;
                }

                const {
                  // Removing props that Menu isn't using. Likely a bug with the package.
                  newSelectionPrefix,
                  paginationText,
                  renderMenuItemChildren,
                  ...menuPropsToForward
                } = menuProps;

                return (
                  <Menu {...menuPropsToForward}>
                    {results.map((result, index) => (
                      <TypeaheadOption
                        option={result}
                        position={index}
                        t={t}
                        key={index}
                      />
                    ))}
                  </Menu>
                );
              }}
              maxResults={maxTypeaheadResults}
              onInputChange={(inputText) => {
                this.clearSavedSearch();
                // If we are preventing search on change,
                // we want to ignore this value change in SearchBarState.
                setSearchText(inputText, preventSearchOnChange);
              }}
              onKeyDown={this.onKeyDown}
              onChange={this.onChangeText}
              onSearch={(q) =>
                this._constructTypeaheadOptions(q, searchCategory)
              }
              isLoading={isLoading}
              placeholder={searchCategoryPlaceholder}
              css={{
                flex: 1,
                ":focus-within": {
                  zIndex: this.zIndexValues.focused,
                },
                input: {
                  borderColor: "transparent",
                  borderRadius: "3px",
                  height: "2.8em",
                  paddingLeft: "2.4em",
                  width: "100%",
                  minWidth: "10em",
                },
              }}
              inputProps={{
                value: searchText,
                ...getInputProps(),
              }}
            >
              {/* Render icon here to position relative to the AsyncTypeahead component.
               * This prevents the need for zIndex to show above the rendered input */}
              <FaSearch
                style={{
                  color: Colors.text.RIVER_BED,
                  position: "absolute",
                  left: 12,
                  top: 12,
                }}
              />
              {!_.isEmpty(searchText) && (
                <ClearSearch
                  clickHandler={this.resetSearchBar}
                  showSearchOptions={showSearchOptions}
                />
              )}
            </AsyncTypeahead>
          </Tooltip>
        </FlexRowDiv>
        {showSearchOptions ? (
          <Dropdown
            onSelect={(k) => {
              this.clearSavedSearch();
              setSearchCategory(k);
            }}
            align="end"
            css={{ ":focus-within": { zIndex: this.zIndexValues.focused } }}
          >
            <Dropdown.Toggle
              id="input-dropdown-addon"
              style={{
                height: "2.8em",
                minWidth: "11.25em",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "none",
                backgroundColor: Colors.background.LIGHT_GRAY,
              }}
              data-qa="dropdown-button-input-search"
              variant="default"
            >
              {searchCategoryButtonTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu>{searchCategoryDropdownItems}</Dropdown.Menu>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}

export const SearchInput = withTranslation(["components"])(SearchInputInternal);

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.performSearch = this.performSearch.bind(this);
  }

  componentDidMount() {
    const { fetchDomainData, solutionId, domainDataArgs = [] } = this.props;

    // H1-1841: Only fetch data if a fetchDomainData() function has been passed to the SearchBar
    // Example: Shipment Search uses global domain data, so it does not pass a fetch
    //   (global domain data is only fetched on App mount/Org switch)
    // Example: Finished Vehicle Search uses FV-specific domain data, so it does pass a fetch
    if (fetchDomainData) {
      // Sometimes we need custom args passed into the fetch.
      // `domainDataArgs` is an array of the arguments to pass to the fetch if needed.
      if (domainDataArgs?.length > 0) {
        fetchDomainData(...domainDataArgs);
      } else {
        fetchDomainData(solutionId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.domainDataArgs, this.props.domainDataArgs)) {
      this.props.fetchDomainData(...this.props.domainDataArgs);
    }
  }

  performSearch() {
    const { preventSearchOnChange, searchEntities, solutionId } = this.props;

    if (!preventSearchOnChange) {
      return searchEntities(solutionId, true);
    }
  }

  render() {
    const {
      isShowingFilters,
      toggleShowFilters,
      isShowingAdvancedSearchButton = true,
      getButtonProps = () => ({}),
      preventSearchOnChange,
      searchCategory,
      searchText,
      canUserSearch,
      hasSearchCriteriaChanged,
      t,
    } = this.props;

    let disableButton = false;
    if (searchCategory.minCharacters) {
      if (searchText.length < searchCategory.minCharacters) {
        disableButton = true;
      }
    }

    if (searchCategory.maxCharacters) {
      if (searchText.length > searchCategory.maxCharacters) {
        disableButton = true;
      }
    }

    return (
      <FlexRowDiv
        css={{
          flexWrap: "wrap",
          flexDirection: "column",
          [MediaQueries.smallAndUp]: {
            flexDirection: "row",
            justifyContent: "flex-end",
          },
        }}
        className="toolbar"
      >
        <SearchInput {...this.props} data-qa="input-search" />
        {!preventSearchOnChange ? (
          <Button
            {...getButtonProps()}
            className="search-button"
            variant={
              hasSearchCriteriaChanged && canUserSearch ? "primary" : "dark"
            }
            pulse={hasSearchCriteriaChanged && canUserSearch}
            css={{
              display: "inline-block",
              marginBottom: "0.5em",
              marginLeft: "unset",
              [MediaQueries.smallAndUp]: {
                marginBottom: "unset",
                marginLeft: "0.5em",
              },
              paddingLeft: "2em",
              paddingRight: "2em",
            }}
            onClick={this.performSearch}
            disabled={preventSearchOnChange || disableButton || !canUserSearch}
            data-qa="button-search"
          >
            {t("components:Search")}
          </Button>
        ) : null}
        {isShowingAdvancedSearchButton && (
          <AdvancedSearchButton
            showFilters={isShowingFilters}
            backgroundColor={Colors.background.GRAY}
            onClick={toggleShowFilters}
            data-qa="button-advanced-search"
          >
            <div
              css={{ marginTop: "-5px" }}
              className="advanced-search-label-container"
            >
              {t("components:Advanced Search")}
            </div>
          </AdvancedSearchButton>
        )}
      </FlexRowDiv>
    );
  }
}

SearchBar.defaultProps = {
  canUserSearch: true,
};

const SearchBarWithTranslation = withTranslation(["components"])(SearchBar);
export { SearchBarWithTranslation as SearchBar };
