import { connect } from "react-redux";
import { SetCurrentLocationOverridePage } from "./SetCurrentLocationTool.page";
import { getSolutionId } from "modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state),
  };
}

export default connect(mapStateToProps, null)(SetCurrentLocationOverridePage);
