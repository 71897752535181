import { NOT_FOUND } from "redux-first-router";

export const CallbackReducer = (state = null, action = {}) => {
  switch (action.type) {
    case NOT_FOUND:
      return null;
    case "CALLBACK":
    case "SAML_CALLBACK":
      return {
        ...state,
        authCode: action.meta.query.hasOwnProperty("code")
          ? action.meta.query.code
          : null,
        error: action.meta.query.hasOwnProperty("error")
          ? action.meta.query.error
          : null,
        authType: action.type,
      };
    default:
      return state;
  }
};

export default CallbackReducer;
