import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";

const CREATE_CUSTOMER_SUPPORT_TICKET_URL = apiUrl(`/support/ticket/`);
const MOUNT_POINT = "customerSupportData";

const createCustomerSupportTicketDuck = buildFetchDuck(MOUNT_POINT);
const createCustomerSupportTicket = (payload) => {
  return (dispatch) => {
    dispatch(
      createCustomerSupportTicketDuck.fetch(
        CREATE_CUSTOMER_SUPPORT_TICKET_URL,
        {
          method: "POST",
          data: payload,
        },
      ),
    );
  };
};

const resetTicketResponseData = () => {
  return (dispatch) => {
    dispatch(createCustomerSupportTicketDuck.clear());
  };
};

export default {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    createCustomerSupportTicket,
    resetTicketResponseData,
  },
  selectors: {
    getTicketResponseData: createCustomerSupportTicketDuck.selectors.getData,
  },
  reducer: chainReducers([createCustomerSupportTicketDuck.reducer]),
};
