/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text, FontSize } from "./Text.atom";
import Colors from "styles/colors";

export const Bubble = ({
  size,
  children,
  background = Colors.background.DARK_BLUE,
  color = Colors.text.VERY_LIGHT_GRAY,
  bold,
  className,
}) => {
  return (
    <span
      className={className}
      css={{
        background: background,
        color: color,
        padding: `${size * 0.25}rem ${size * 0.5}rem`,
        fontSize: `${size}rem`,
        borderRadius: "100em", // Arbitrarily large to round over the sides
        textAlign: "center",
      }}
    >
      <Text size={size} bold={bold} align="center">
        {children}
      </Text>
    </span>
  );
};

Bubble.propTypes = {
  /**
   * The bubble's content.
   */
  children: PropTypes.any.isRequired,
  /**
   * The size of text.
   *
   * Use the `FontSize` enum for this value.
   */
  size: PropTypes.oneOf(Object.values(FontSize)),
  /**
   * The color of the bubble.
   *
   * Use `Colors` from `styles/colors.js`
   */
  background: PropTypes.string,
  /**
   * The color of the text in the bubble.
   *
   * Use `Colors` from `styles/colors.js`
   */
  color: PropTypes.string,
  /**
   * Bold the text in the bubble.
   */
  bold: PropTypes.bool,
  /**
   * The CSS class name for styling.
   */
  className: PropTypes.string,
};

export { FontSize };
