import { getEverythingQueryString } from "components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    // The categories aren't displayed for this search.
    // No need for a label.
    label: () => null,
    placeholder: (t) => {
      return t("users:Search for user");
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];
