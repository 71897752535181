/** @jsxImportSource @emotion/react */

import PropTypes from "prop-types";

import { Button, FormLabel, FormControl } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

import SelectField from "./forms/fields/SelectField";
import { TimePicker } from "components/atoms/TimePicker.atom";
import { TextInput as TextInputAtom } from "components/atoms/TextInput.atom";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

export const ReadOnlyInput = ({ label, style = {}, value, valueStyles }) => {
  return (
    <div css={{ width: "50%", ...style }}>
      <FormLabel style={{ fontWeight: "normal", color: Colors.text.HIT_GRAY }}>
        {label}:
      </FormLabel>
      <div style={{ ...valueStyles }}>{value}</div>
    </div>
  );
};

ReadOnlyInput.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  valueStyles: PropTypes.object,
};

export const TextInput = ({
  changeHandler,
  label,
  prop,
  style = {},
  value,
  disabled = false,
  helpText,
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>
        {label}{" "}
        {helpText ? (
          <Tooltip tooltipChildren={helpText}>
            <Icon src={faQuestionCircle} />
          </Tooltip>
        ) : null}
      </FormLabel>

      <TextInputAtom
        css={{
          boxShadow: "none",
          "input ": {
            color: disabled ? Colors.text.DISABLED : undefined,
          },
        }}
        value={value}
        onChange={(value) => changeHandler({ [prop]: value })}
        disabled={disabled}
      />
    </div>
  );
};

TextInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.node,
};

export const TimePickerInput = ({
  changeHandler,
  label,
  prop,
  style = {},
  value,
  disabled = false,
  helpText,
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>
        {label}{" "}
        {helpText ? (
          <Tooltip tooltipChildren={helpText}>
            <Icon src={faQuestionCircle} />
          </Tooltip>
        ) : null}
      </FormLabel>
      <TimePicker
        showSecond={false}
        value={value}
        onChange={(value) => changeHandler({ [prop]: value })}
        use12Hours
        allowEmpty={true}
        disabled={disabled}
      />
    </div>
  );
};

TimePickerInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  style: PropTypes.object,
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.node,
};

export const SelectInput = ({
  changeHandler,
  label,
  options,
  prop,
  style = {},
  placeholder,
  value,
  multi = true,
  searchable = true,
  disabled = false,
  helpText,
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>
        {label}{" "}
        {helpText ? (
          <Tooltip tooltipChildren={helpText}>
            <Icon src={faQuestionCircle} />
          </Tooltip>
        ) : null}
      </FormLabel>
      <SelectField
        name="selected-state"
        isMulti={multi}
        isSearchable={searchable}
        isDisabled={disabled}
        minHeight={null}
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        options={options}
        onChange={(selections) => {
          changeHandler({ [prop]: selections });
        }}
      />
    </div>
  );
};

SelectInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  prop: PropTypes.string.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.node,
};

export const PhoneInput = ({
  changeHandler,
  label,
  prop,
  style = {},
  value,
  disabled = false,
  helpText,
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>
        {label}{" "}
        {helpText ? (
          <Tooltip tooltipChildren={helpText}>
            <Icon src={faQuestionCircle} />
          </Tooltip>
        ) : null}
      </FormLabel>
      <FormControl
        type="tel"
        value={value ?? ""}
        onChange={(event) =>
          changeHandler({ [prop]: event.currentTarget.value })
        }
        disabled={disabled}
        css={{ color: disabled ? Colors.text.DISABLED : undefined }}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.node,
};

const actionStyles = {
  backgroundColor: Colors.highlight.GREEN,
  color: "white",
  fontWeight: "300",
};

const dangerStyles = {
  backgroundColor: Colors.highlight.RED,
  color: "white",
  fontWeight: "300",
};

const defaultStyles = {
  backgroundColor: "white",
  color: Colors.background.DARK_BLUE,
};

const getButtonStyles = (actionType) => {
  if (actionType === "ACTION") {
    return actionStyles;
  }
  if (actionType === "DANGER") {
    return dangerStyles;
  }
  return defaultStyles;
};

export const FormButton = ({
  actionType = null,
  clickHandler,
  disabled = false,
  label,
  style = {},
}) => {
  const buttonStyles = getButtonStyles(actionType);

  return (
    <Button
      style={{
        ...buttonStyles,
        ...style,
      }}
      disabled={disabled}
      onClick={() => clickHandler()}
    >
      {label}
    </Button>
  );
};

FormButton.propTypes = {
  actionType: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export const FormAlert = ({ alert, alertType, style = {} }) => {
  return (
    <div
      style={{
        color:
          alertType === "SUCCESS"
            ? Colors.highlight.GREEN
            : Colors.highlight.RED,
        marginTop: "1em",
        marginBottom: "1em",
        ...style,
      }}
    >
      {alert}
    </div>
  );
};

FormAlert.propTypes = {
  alert: PropTypes.node.isRequired,
  alertType: PropTypes.string.isRequired,
  style: PropTypes.object,
};
