import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import {
  locationsUrl,
  customersUrl,
  dealersUrl,
  statusUrl,
  ddaProductTypeUrl,
  ddaDealerRegionUrl,
  ddaDealerZoneUrl,
  ddaDealerDistrictUrl,
  lastMilestoneUrl,
  destinationUrl,
} from "modules/domain-data/DriveAwayDomainData";
import { isShipper } from "shared/utils/organizations.utils";

export const driveAwayLocationsOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayLocationsFilter",
  getUrl: (solutionId, state) => {
    const isShipperRole = isShipper(state.organizations.activeOrganization);
    return locationsUrl(isShipperRole ? solutionId : undefined);
  },
  pageSize: 20, // default is 20
  getResponseData: (data) => {
    return data?.driveawayLocations ?? [];
  },
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: `${option.name} (${option.code})`,
    code: option.code,
    name: option.name,
  }),
});

export const driveAwayDealerRegionOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayDealerRegionFilter",
  getUrl: ddaDealerRegionUrl,
  pageSize: 20,
  getResponseData: (data) => data?.ddaDealerRegion ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const driveAwayDealerZoneOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayDealerZoneFilter",
  getUrl: ddaDealerZoneUrl,
  pageSize: 20,
  getResponseData: (data) => data?.ddaDealerZone ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const driveAwayDealerDistrictOptionsState = buildAsyncFilterOptionsState(
  {
    topic: "driveAwayDealerDistrictFilter",
    getUrl: ddaDealerDistrictUrl,
    pageSize: 20,
    getResponseData: (data) => data?.ddaDealerDistrict ?? [],
    transformResult: (option) => ({ label: option, value: option }),
  },
);

export const driveAwayCustomersOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayCustomerFilter",
  getUrl: (solutionId, state) => {
    return customersUrl(solutionId);
  },
  pageSize: 20,
  getResponseData: (data) => {
    return data?.ddaShipper ?? [];
  },
  transformResult: (ddaShipper) => {
    const hasFV = (solution) => solution.feature === "Finished Vehicle";
    const customerFilterFV = ddaShipper.solutions.find(hasFV);
    return {
      label: ddaShipper.org_name,
      value: customerFilterFV.solution,
    };
  },
});

export const driveAwayDealersOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayDealerFilter",
  getUrl: (solutionId, state) => {
    return dealersUrl(solutionId, state);
  },
  pageSize: 20,
  getResponseData: (data) => {
    return data?.dealers ?? [];
  },
  transformResult: (dealer) => {
    return {
      label: dealer?.dealer_name,
      value: dealer?.dealer_id,
    };
  },
});

export const driveAwayStatusOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayStatusFilter",
  getUrl: () => statusUrl(),
  pageSize: 20,
  getResponseData: (data) => {
    return data ?? [];
  },
  transformResult: (status) => {
    // Doing this to match what NFilterButton needs
    return {
      label: status,
      value: status,
    };
  },
});

export const driveAwayProductTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "driveAwayProductTypeFilter",
  getUrl: (solutionId, state) => {
    return ddaProductTypeUrl(solutionId, state);
  },
  pageSize: 20,
  getResponseData: (data) => {
    return data?.productTypes ?? [];
  },
  transformResult: (option) => ({ label: option, value: option }),
});

export const lastMilestoneOptionsState = buildAsyncFilterOptionsState({
  topic: "lastMilestone",
  getUrl: lastMilestoneUrl,
  getResponseData: (data) => data?.lastMilestone ?? [],
  transformResult: (option) => ({
    label: `${option.codeDescription} (${option.code})`,
    value: `${option.codeDescription} (${option.code})`,
  }),
});

export const driveAwayDestinationOptionsState = buildAsyncFilterOptionsState({
  topic: "destination",
  getUrl: destinationUrl,
  getResponseData: (data) => data?.ddaDestinationLocations ?? [],
  additionalParams: {
    verbose: false,
  },
  additionalQueryOnlyParams: {
    everythingRestrict: "code,name",
  },
  additionalQueryOnlyHeaders: {
    Accept: "application/json,locationCodeName",
  },
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: option.code,
    code: option.code,
    name: option.name,
  }),
});
