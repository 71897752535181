/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import * as math from "mathjs";
import StandardInput from "components-old/forms/inputs/StandardInput";
import NumberInput from "components-old/forms/inputs/NumberInput";
import { FlexRow } from "./GeofenceEditForm";
import { geofenceWithUpdatedCoordinates } from "./geofence-coordinates";

export const GeofenceEditRadialForm = ({
  data,
  updateGeofence,
  isReadOnly,
}) => {
  const { t } = useTranslation("geofence-edit");

  /* DEV-1158 Default radius updated to 1/4 mile */
  const [radiusMiles, setRadiusMiles] = useState(0.25);
  const [lng, lat] = data.geofence.geometry.coordinates;

  useEffect(() => {
    const { buffer } = data.geofence.properties;
    const radiusMeters = math.unit(buffer, "m");
    const radiusMilesRaw = radiusMeters.to("mile");
    const radiusMiles = _.round(radiusMilesRaw.toNumber(), 5);
    setRadiusMiles(radiusMiles);
  }, [data]);

  return (
    <FlexRow>
      <NumberInput
        label={t("geofence-edit:Latitude")}
        value={lat}
        onChange={(val) => {
          const newGeofence = geofenceWithUpdatedCoordinates(data.geofence, [
            lng,
            !_.isEmpty(val) && !_.isNil(val) ? Number.parseFloat(val) : null,
          ]);
          updateGeofence(newGeofence);
        }}
        isReadOnly={isReadOnly}
      />
      <NumberInput
        label={t("geofence-edit:Longitude")}
        value={lng}
        onChange={(val) => {
          const newGeofence = geofenceWithUpdatedCoordinates(data.geofence, [
            !_.isEmpty(val) && !_.isNil(val) ? Number.parseFloat(val) : null,
            lat,
          ]);
          updateGeofence(newGeofence);
        }}
        isReadOnly={isReadOnly}
      />
      <StandardInput
        label={t("geofence-edit:Radius (mi)")}
        value={radiusMiles}
        onChange={(valMiles) => {
          setRadiusMiles(valMiles);
          try {
            const radiusMeters = math.unit(valMiles, "mile").to("m");
            const newGeofence = {
              ...data.geofence,
              properties: {
                ...data.geofence.properties,
                buffer: radiusMeters.toNumber(),
              },
            };
            updateGeofence(newGeofence);
          } catch (err) {
            // ignore invalid number - probably temporary as user continues to edit
          }
        }}
        isReadOnly={isReadOnly}
      />
    </FlexRow>
  );
};

GeofenceEditRadialForm.propTypes = {
  data: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  updateGeofence: PropTypes.func.isRequired,
};
