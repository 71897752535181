/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UsersActionStatus } from "modules/users/UsersState";
import { Modal } from "components/molecules/Modal.molecule";
import { ReadOnlyInput, FormButton } from "components-old/modal-elems";
import { FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import UsersState from "modules/users/UsersState";
import { Icon } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";

const initUser = {
  email: "",
  isActive: false,
};

const UserActiveModal = ({
  show,
  hide,
  user,
  activeOrganization,
  actionStatus,
  clearActionStatus,
  blockUser,
  unblockUser,
}) => {
  const { t } = useTranslation("users");
  const [updateUser, setUser] = useState(initUser);

  useEffect(() => {
    setUser({
      email: user?.email ?? "",
      isActive: user?.status === "Active",
    });
  }, [user]);

  const org = activeOrganization?.org_name ?? "";

  const shouldShowFailureAlert = (actionStatus) => {
    return (
      actionStatus &&
      [UsersActionStatus.UPDATE_USER_FAILED].includes(actionStatus)
    );
  };

  const shouldShowSuccessAlert = (actionStatus) => {
    return (
      actionStatus &&
      [UsersActionStatus.UPDATE_USER_SUCCEEDED].includes(actionStatus)
    );
  };

  const getAlertMessage = (actionStatus) => {
    switch (actionStatus) {
      case UsersActionStatus.UPDATE_USER_SUCCEEDED:
        return t("users:User active status has been successfully changed");

      case UsersActionStatus.UPDATE_USER_FAILED:
        return t(
          "users:Error occured while attemting to update user's active status",
        );
      default:
        return "";
    }
  };

  const onHide = () => {
    hide();
    clearActionStatus();
  };

  return (
    <Modal backdrop={"static"} show={show} onHide={onHide}>
      <Modal.Header
        title={
          updateUser.isActive
            ? t("users:Mark User Inactive")
            : t("users:Mark User Active")
        }
      />
      <Modal.Body>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={updateUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          />
        </FlexRowDiv>
        <Alert
          variant={
            shouldShowFailureAlert(actionStatus)
              ? AlertVariant.Danger
              : AlertVariant.Success
          }
          show={
            shouldShowSuccessAlert(actionStatus) ||
            shouldShowFailureAlert(actionStatus)
          }
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {getAlertMessage(actionStatus, t)}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={
            actionStatus === UsersActionStatus.UPDATE_USER_SUCCEEDED
              ? t("users:Close")
              : t("users:Cancel")
          }
          clickHandler={onHide}
        />
        <FormButton
          disabled={
            actionStatus === UsersActionStatus.UPDATE_USER_IN_PROGRESS ||
            actionStatus === UsersActionStatus.UPDATE_USER_SUCCEEDED
          }
          actionType={updateUser.isActive ? "DANGER" : "ACTION"}
          label={
            actionStatus === UsersActionStatus.UPDATE_USER_IN_PROGRESS ? (
              <Icon src={faSpinner} spin />
            ) : updateUser.isActive ? (
              t("users:Mark Inactive")
            ) : (
              t("users:Mark Active")
            )
          }
          clickHandler={() => {
            updateUser.isActive
              ? blockUser(user, activeOrganization.organization_id)
              : unblockUser(user, activeOrganization.organization_id);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

UserActiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  user: PropTypes.object,
  activeOrganization: PropTypes.object.isRequired,
  blockUser: PropTypes.func.isRequired,
  unblockUser: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
  };
}

const mapDispatchToProps = {
  blockUser: UsersState.actionCreators.blockUser,
  unblockUser: UsersState.actionCreators.unblockUser,
  clearActionStatus: UsersState.actionCreators.clearActionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActiveModal);
