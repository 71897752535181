/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { VictoryVoronoiContainer } from "victory";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryStack,
  VictoryLabel,
  VictoryTooltip,
  Flyout,
} from "victory";
import { formatTickMetric } from "shared/utils/chart.utils";

export const VerticalStackedBarChart = ({
  data = [],
  xAccessor,
  bars = [],
  barStyle,
  axisStyle,
  axisTicksLabelStyle,
  legendStyle,
  crossAxisStyle,
  flyoutStyle,
  onClickLegend,
  xAxisTickFormat,
  height,
  width,
  domain = { x: [0, 5] },
}) => {
  const legendLabels = bars.map((bar) => ({
    name: bar.legendLabel,
    y: bar.y,
    symbol: { type: "square" },
  }));
  const colorScale = bars.map((bar) => bar.color);

  const summedData = data.map((datum) => ({
    ...datum,
    summed: bars
      .map((bar) => datum[bar.y])
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
  }));

  const totalSum = summedData
    .map((ele) => ele.summed)
    .reduce((accumulator, currentVal) => accumulator + currentVal, 0);

  // delegate to onClickLegend prop when legend is clicked
  const onLegendClickHandler = () => [
    {
      target: "data",
      mutation: (props) =>
        onClickLegend ? onClickLegend(props.datum.y) : props,
      propTypes: {
        datum: PropTypes.object,
      },
    },
  ];

  return (
    <VictoryChart
      domainPadding={30}
      padding={{ top: 50, bottom: 90, left: 30, right: 70 }}
      width={width}
      height={height}
      containerComponent={<VictoryVoronoiContainer radius={50} />}
    >
      <VictoryLegend
        style={legendStyle}
        x={20}
        y={10}
        colorScale={colorScale}
        data={legendLabels}
        orientation="horizontal"
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: onLegendClickHandler,
            },
          },
        ]}
      />
      <VictoryAxis
        style={axisStyle}
        tickLabelComponent={
          <VictoryLabel
            dx={-20}
            dy={2}
            angle={20}
            textAnchor="start"
            style={{ fontSize: 12, ...axisTicksLabelStyle }}
          />
        }
        tickFormat={xAxisTickFormat}
      />
      <VictoryAxis
        style={crossAxisStyle}
        dependentAxis
        tickFormat={formatTickMetric}
        tickValues={totalSum === 0 ? [0] : null}
      />
      <VictoryStack style={{ data: { width: 15 } }} colorScale={colorScale}>
        {bars.map((bar) => (
          <VictoryBar
            key={bar.y}
            data={data}
            x={xAccessor}
            y={bar.y}
            domain={domain}
            style={
              bar.showTooltip
                ? barStyle
                : {
                    ...barStyle,
                    data: { ...barStyle.data, cursor: "default" },
                    labels: { display: "none" },
                  }
            }
            labels={({ datum }) => datum.xName}
            labelComponent={
              <StackedTooltip
                bars={bars}
                cornerRadius={0}
                pointerLength={0}
                flyoutStyle={bar.showTooltip ? flyoutStyle : { opacity: 0 }}
              />
            }
            events={[
              {
                target: "data",
                eventHandlers: { onClick: bar.onClick },
              },
            ]}
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
};

VerticalStackedBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      locationId: PropTypes.number,
      name: PropTypes.string,
      dwellCount: PropTypes.number,
      agedCount: PropTypes.number,
    }),
  ),
  /** Defines the accessor for the x value in data */
  // xAccessor should match to a key in an element in data
  // All x values should refer to the same part of data
  // This forces developers to make sure thats true
  xAccessor: PropTypes.string,
  /** Bar definitions for each set in the stack */
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      /** Accessor for the range */
      y: PropTypes.string,
      /** Function thats passed an object with `datum`. Should return a string*/
      // similar to the labels prop on VictoryBar
      getLabelForData: PropTypes.func,
      /** The label for this bar in the legend */
      legendLabel: PropTypes.string,
      /** Defines whether to show/hide the tooltip for the bar chart */
      showTooltip: PropTypes.bool,
      /** The color for this bar and the legend */
      color: PropTypes.string,
      /** The click handler for this bar */
      onClick: PropTypes.func,
    }),
  ),
  barStyle: PropTypes.object,
  axisStyle: PropTypes.object,
  crossAxisStyle: PropTypes.object,
  flyoutStyle: PropTypes.object,
  /**
   * Event handler that fires when clicking any element in the legend
   * It passes the y attribute on the specific datum to the caller which
   * can be used to determine which element in the legend was clicked.
   */
  onClickLegend: PropTypes.func,
  xAxisTickFormat: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  domain: PropTypes.object,
  axisTicksLabelStyle: PropTypes.object,
};

// TODO: Create a tooltip component that renders a single tooltip with all the labels that we need
const StackedTooltip = ({ bars, ...props }) => {
  /* Render multiple tooltips for each bar defined in VerticalStackedBarChart
   * These tooltip components are displayed on top of each by modifying their `y` values
   */

  /* Calculate the approximate height of a tooltip
   * - fontSize: to account for text height (not exact but close)
   * - padding: counted twice for above and below the text
   *
   * This will be the how much each tooltip is offset to each other
   */
  const approximateTooltipHeight =
    props.style.fontSize + props.style.padding * 2;

  return (
    <g>
      {/* For each bar defined, render a tooltip label */}
      {bars.map((bar, index) => (
        <VictoryTooltip
          key={bar.y}
          // Forward all required props to VictoryTooltip
          {...props}
          // Override text with what we want this label to show
          text={bar.getLabelForData({ datum: props.datum })}
          // Take the original y position of the tooltip and move it up by how many tooltips precede it
          // Note: The tooltip will already be centered above the bar (no need to specify `x` or `dx`)
          y={props.y - approximateTooltipHeight * index}
          // Specifying a width so that the each tooltip has the same width
          flyoutComponent={
            <Flyout width={120} height={approximateTooltipHeight} />
          }
        />
      ))}
    </g>
  );
};
StackedTooltip.defaultEvents = VictoryTooltip.defaultEvents;
StackedTooltip.propTypes = {
  // Because we will be forwarding all props except `bars`
  ...VictoryTooltip.propTypes,
  bars: VerticalStackedBarChart.propTypes.bars,
};
