import axios from "axios";
import apiUrl from "api-url";

// URLS
const UNSUBSCRIBE_URL = apiUrl("/subscription/user");
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

// Redux mount point
const STORE_MOUNT_POINT = "fvUnsubscribe";

// Actions
const UNSUBSCRIBE_RESET = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_RESET`;
const FETCH_SUBSCRIPTION_STARTED = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_STARTED`;
const FETCH_SUBSCRIPTION_SUCCESS = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_SUCCESS`;
const FETCH_SUBSCRIPTION_FAILED = `${STORE_MOUNT_POINT}/FETCH_SUBSCRIPTION_FAILED`;
const UNSUBSCRIBE_STARTED = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_STARTED`;
const UNSUBSCRIBE_SUCCESS = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_SUCCESS`;
const UNSUBSCRIBE_FAILED = `${STORE_MOUNT_POINT}/UNSUBSCRIBE_FAILED`;
const FETCH_ORGANIZATION_IMAGES = `${STORE_MOUNT_POINT}/FETCH_ORGANIZATION_IMAGES`;

function resetUnsubscribe() {
  return (dispatch) => {
    dispatch({ type: UNSUBSCRIBE_RESET });
  };
}

function fetchSubscription(guid) {
  return (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_STARTED });

    const url = `${UNSUBSCRIBE_URL}?subscriptionId=${guid}`;

    return axios
      .get(url)
      .then((response) => {
        dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, response: response.data });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_FAILED,
          errorMessage: error?.message,
        });
      });
  };
}

function fetchOrganizationImages(orgId) {
  const url = `${ORGANIZATIONS_URL}/${orgId}/images`;

  return (dispatch) => {
    return axios
      .get(`${url}`)
      .then((response) => {
        let payload = {
          logoPath: response.data?.logoUrl,
        };
        dispatch({
          type: FETCH_ORGANIZATION_IMAGES,
          payload: payload,
        });
      })
      .catch((err) => {});
  };
}

function unsubscribe(guid, category) {
  return (dispatch) => {
    const payload = {
      subscription_id: guid,
      category,
    };

    dispatch({ type: UNSUBSCRIBE_STARTED });

    const url = `${UNSUBSCRIBE_URL}/unsubscribe`;

    return axios
      .post(url, payload)
      .then((response) => {
        dispatch({ type: UNSUBSCRIBE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: UNSUBSCRIBE_FAILED, errorMessage: error?.message });
      });
  };
}

// Selectors
const getSubscription = (state) =>
  state[STORE_MOUNT_POINT].subscription || null;
const getIsFetchingSubscription = (state) =>
  state[STORE_MOUNT_POINT].isFetchingSubscription || false;
const getIsFetchSubscriptionError = (state) =>
  state[STORE_MOUNT_POINT].isFetchSubscriptionError || false;
const getFetchSubscriptionErrorMessage = (state) =>
  state[STORE_MOUNT_POINT].fetchSubscriptionErrorMessage || null;
const getOrganizationImageConfig = (state) =>
  state[STORE_MOUNT_POINT].organizationImageConfig || {};

const getIsUnsubscribeComplete = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribeComplete || false;
const getIsUnsubscribing = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribing || false;
const getIsUnsubscribeError = (state) =>
  state[STORE_MOUNT_POINT].isUnsubscribeError || false;
const getUnsubscribeErrorMessage = (state) =>
  state[STORE_MOUNT_POINT].unsubscribeErrorMessage || null;

// Initial state
const initialState = {
  subscription: null,
  isFetchingSubscription: false,
  isFetchSubscriptionError: false,
  fetchSubscriptionErrorMessage: null,
  isUnsubscribeComplete: false,
  isUnsubscribing: false,
  isUnsubscribeError: false,
  unsubscribeErrorMessage: null,
};

const UnsubscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_RESET:
      return {
        ...state,
        ...initialState,
      };
    case FETCH_SUBSCRIPTION_STARTED:
      return {
        ...state,
        subscription: null,
        isFetchingSubscription: true,
        isFetchSubscriptionError: false,
        fetchSubscriptionErrorMessage: false,
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        subscription: action.response,
        isFetchingSubscription: false,
        isFetchSubscriptionError: false,
        fetchSubscriptionErrorMessage: false,
      };
    case FETCH_SUBSCRIPTION_FAILED:
      return {
        isFetchingSubscription: false,
        isFetchSubscriptionError: true,
        fetchSubscriptionErrorMessage: action.errorMessage,
      };
    case FETCH_ORGANIZATION_IMAGES:
      return {
        ...state,
        organizationImageConfig: action.payload,
      };
    case UNSUBSCRIBE_STARTED:
      return {
        ...state,
        isUnsubscribing: true,
        isUnsubscribeComplete: false,
        isUnsubscribeError: false,
        unsubscribeErrorMessage: null,
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isUnsubscribing: false,
        isUnsubscribeComplete: true,
        isUnsubscribeError: false,
        unsubscribeErrorMessage: null,
      };
    case UNSUBSCRIBE_FAILED:
      return {
        ...state,
        isUnsubscribing: false,
        isUnsubscribeComplete: false,
        isUnsubscribeError: true,
        unsubscribeErrorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

// interface
const UnsubscribeState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    resetUnsubscribe,
    unsubscribe,
    fetchSubscription,
    fetchOrganizationImages,
  },
  selectors: {
    getSubscription,
    getIsFetchingSubscription,
    getIsFetchSubscriptionError,
    getFetchSubscriptionErrorMessage,
    getIsUnsubscribeComplete,
    getIsUnsubscribing,
    getIsUnsubscribeError,
    getUnsubscribeErrorMessage,
    getOrganizationImageConfig,
  },
  reducer: UnsubscribeReducer,
};
export default UnsubscribeState;
