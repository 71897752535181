/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

const pdfCss = {
  width: "100%",
  height: "100%",
  minHeight: "100em",
};

const LinkPdf = (props) => {
  const { filename, baseUrl } = props;

  // Decode the URL
  let decodedUrl = decodeURIComponent(baseUrl);
  if (!decodedUrl.endsWith("/")) {
    decodedUrl += "/";
  }

  return (
    <object
      data={`${decodedUrl}${filename}`}
      type="application/pdf"
      css={pdfCss}
    >
      {filename}
    </object>
  );
};

LinkPdf.propTypes = {
  filename: PropTypes.string,
  baseUrl: PropTypes.string,
};
export default LinkPdf;
