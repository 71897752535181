/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "../search/ContainerTrackingSearchBarContainer";
import FiltersContainer from "../search/ContainerTrackingSearchFiltersContainer";
import { ContainerTrackingCountsWidget } from "./components/summaryview/ContainerTrackingCountsWidget";
import ContainerTrackingMyWatchedContainers from "./components/homepage/ContainerTrackingMyWatchedContainers";
import { ContainerTrackingSavedSearchPanel } from "./components/homepage/ContainerTracking.SavedSearchesPanel";
import { ContainerTrackingContainerTypeTable } from "./components/summaryview/ContainerTrackingContainerTypeTable";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";

import { MediaQueries } from "components/responsive";

function ContainerTrackingDashboard(props) {
  const {
    containerTotals,
    isContainerTotalsLoading,
    fetchContainerTotals,
    searchEntities,
    setSearchFilter,
    resetSearchAndFilters,
  } = props;

  const {
    fetchContainerType,
    isContainerTypeTableLoading,
    containerTypeTableResults,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    defaultSortColumn,
    defaultReverseSort,
    sortColumn,
    reverseSort,
    searchFilters,
    setFilter,
    clearSearchFilter,
    clearSearchFilters,
    clearSearchText,
    exportIdentifier,
    exportName,
    isExporting,
    exportFailed,
    exportContainerTypes,
    resetExport,
  } = props;

  const { t } = useTranslation("container-tracking");
  const [showFilters, setShowFilters] = useState(false);

  useSetTitleOnMount(t("container-tracking:Container Tracking"));
  useSetDescriptionOnMount(
    t(
      "container-tracking:All Containers for your organization and their related exceptions",
    ),
  );

  useEffect(() => {
    fetchContainerTotals();
    resetSearchAndFilters(true);
  }, [fetchContainerTotals, resetSearchAndFilters]);

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>{t("container-tracking:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("container-tracking:My Container Tracking Homepage")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel>
          <div
            css={{
              [MediaQueries.smallAndDown]: {
                marginBottom: "20px",
              },
            }}
          >
            <ContainerTrackingCountsWidget
              containerTotals={containerTotals}
              isContainerTotalsLoading={isContainerTotalsLoading}
              searchEntities={searchEntities}
              setSearchFilter={setSearchFilter}
              resetSearchAndFilters={resetSearchAndFilters}
            />
            <ContainerTrackingContainerTypeTable
              isContainerTypeTableLoading={isContainerTypeTableLoading}
              containerTypeTableResults={containerTypeTableResults}
              searchEntities={searchEntities}
              setSearchFilter={setSearchFilter}
              page={page}
              pageSize={pageSize}
              totalPages={totalPages}
              setPagination={setPagination}
              setSort={setSort}
              defaultSortColumn={defaultSortColumn}
              defaultReverseSort={defaultReverseSort}
              sortColumn={sortColumn}
              reverseSort={reverseSort}
              searchFilters={searchFilters}
              setFilter={setFilter}
              clearSearchFilter={clearSearchFilter}
              clearSearchFilters={clearSearchFilters}
              fetchContainerType={fetchContainerType}
              clearSearchText={clearSearchText}
              exportIdentifier={exportIdentifier}
              exportName={exportName}
              isExporting={isExporting}
              exportFailed={exportFailed}
              exportContainerTypes={exportContainerTypes}
              resetExport={resetExport}
            />
          </div>
        </Dashboard.TabPanel>
        <Dashboard.TabPanel>
          {/* {t("container-tracking:Saved Searches Panel & Watched Containers")} */}
          <ContainerTrackingSavedSearchPanel />
          <ContainerTrackingMyWatchedContainers />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
}

ContainerTrackingDashboard.propTypes = {
  containerTotals: PropTypes.object.isRequired,
  isContainerTotalsLoading: PropTypes.bool.isRequired,
  fetchContainerTotals: PropTypes.func.isRequired,
  searchEntities: PropTypes.func,
  setSearchFilter: PropTypes.func,
  resetSearchAndFilters: PropTypes.func,
  fetchContainerType: PropTypes.func.isRequired,
  isContainerTypeTableLoading: PropTypes.bool.isRequired,
  containerTypeTableResults: PropTypes.array,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setSort: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  defaultSortColumn: PropTypes.string,
  defaultReverseSort: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  searchFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  clearSearchText: PropTypes.func.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  exportContainerTypes: PropTypes.func.isRequired,
  resetExport: PropTypes.func,
};

export default ContainerTrackingDashboard;
