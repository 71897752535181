/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Button } from "components/atoms/Button.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import RoutingMap from "modules/map/components/RoutingMap";
import { RoutingMapContext } from "modules/map/components/RoutingMapContext";
import MapState from "modules/map/MapState";
import { getLocalTimezone } from "utils/date-time";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import HereMapPlatform from "modules/map/platforms/here/HereMapPlatform";
import { isValidEmail } from "utils/validation-utils";

const ShareModal = ({
  show,
  hide,
  mapPlatform,
  clearShareVin,
  shareVin,
  isSharingVin,
  isShareVinSuccessful,
  isShareVinFailed,
  setShouldCaptureScreenshot,
  shouldCaptureScreenshot,
  capturedScreenshot,
  setCapturedScreenshot,
  canShareEta = true,
}) => {
  const { t } = useTranslation("fv-vin-details");

  const routingMapProps = useContext(RoutingMapContext);

  const [emails, setEmails] = useState("");
  const hasValidEmails = emails
    .split(";")
    .every((email) => isValidEmail(email));

  // Grab local timezone so we know what timezone the email should be in.
  // Not ideal, but since we don't know the timezone of the user receiving the email,
  // this is our "best guess" approach.
  const timezone = getLocalTimezone().format("z");

  useEffect(() => {
    if (capturedScreenshot?.length > 0 && emails?.length > 0) {
      shareVin(emails, capturedScreenshot, timezone);
    }
  }, [capturedScreenshot, emails, timezone, shareVin]);

  useEffect(() => clearShareVin(true), [show, clearShareVin]);

  useEffect(() => {
    if (isShareVinSuccessful) {
      clearShareVin(false);
      setEmails("");
      setCapturedScreenshot(null);
    }
  }, [isShareVinSuccessful, clearShareVin, setCapturedScreenshot]);

  // FIN-5718: Allow sharing of VIN without sharing ETA.
  // CarrierView cannot share the iETA with this feature.
  let descriptionLabel = null;
  if (mapPlatform === HereMapPlatform.name) {
    if (canShareEta) {
      descriptionLabel = t(
        "fv-vin-details:By sharing this VIN, the recipient will receive the Current Location, Intelligent ETA, and the map as shown below",
      );
    } else {
      descriptionLabel = t(
        "fv-vin-details:By sharing this VIN, the recipient will receive the Current Location and the map as shown below",
      );
    }
  } else {
    if (canShareEta) {
      descriptionLabel = t(
        "fv-vin-details:By sharing this VIN, the recipient will receive the Current Location and Intelligent ETA",
      );
    } else {
      descriptionLabel = t(
        "fv-vin-details:By sharing this VIN, the recipient will receive the Current Location",
      );
    }
  }

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("fv-vin-details:Share VIN")}
      />

      <Modal.Body
        css={{
          overflow: "hidden",
          paddingRight: 0,
          marginRight: "1rem", // To cut off map on smaller displays
        }}
      >
        <Text>{descriptionLabel}.</Text>
        <div css={{ display: "flex", flexDirection: "row", paddingTop: "1em" }}>
          <Form.Label htmlFor="checkbox-vin-share-emails">
            <Text size={FontSize.size18}>{t("fv-vin-details:To")}:</Text>
          </Form.Label>
          <Form.Group css={{ marginBottom: "1rem", marginLeft: "1em" }}>
            <Form.Control
              id="checkbox-vin-share-emails"
              style={{
                height: "2em",
              }}
              disabled={shouldCaptureScreenshot || isSharingVin}
              type="text"
              value={emails}
              placeholder={t("fv-vin-details:Email(s)")}
              onChange={(e) => {
                setEmails(e.target.value);
              }}
              data-qa="input-vin-shared-emails"
              aria-describedby="emails-help-text"
            />
            <Form.Text id="emails-help-text" muted css={{ display: "block" }}>
              <Text>
                {t(
                  'fv-vin-details:Enter multiple email addresses separated by ";"',
                )}
              </Text>
            </Form.Text>
          </Form.Group>
        </div>
        {mapPlatform === HereMapPlatform.name ? (
          <React.Fragment>
            <div css={{ width: 400, height: 400 }}>
              <RoutingMap
                {...routingMapProps}
                allowCaptureScreenshot={true}
                showHeatmap={false}
              />
            </div>
            <Text color="#aaa">
              {t(
                "fv-vin-details:Customize the desired zoom/location on the map to be shared",
              )}
              .
            </Text>
          </React.Fragment>
        ) : null}

        <Alert
          variant={AlertVariant.Danger}
          show={isShareVinFailed}
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {t("fv-vin-details:Something went wrong while sharing this VIN")}
        </Alert>
        <Alert
          variant={AlertVariant.Success}
          show={isShareVinSuccessful}
          css={{ marginTop: "1em", marginBottom: 0 }}
        >
          {t("fv-vin-details:Successfully shared this VIN")}
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Button
          variant="outline-secondary"
          style={{
            marginRight: "0.5em",
          }}
          onClick={() => {
            hide();
          }}
          data-qa="button-close-vin-share-modal"
        >
          {t("fv-vin-details:Close")}
        </Button>
        <Button
          variant="success"
          disabled={shouldCaptureScreenshot || isSharingVin || !hasValidEmails}
          onClick={() => {
            if (mapPlatform === HereMapPlatform.name) {
              setShouldCaptureScreenshot(true);
            } else {
              shareVin(emails, capturedScreenshot, timezone);
            }
          }}
          data-qa="button-share-vin-share-modal"
        >
          {shouldCaptureScreenshot || isSharingVin ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("fv-vin-details:Share")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ShareModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  mapPlatform: PropTypes.string,
  clearShareVin: PropTypes.func.isRequired,
  shareVin: PropTypes.func.isRequired,
  isSharingVin: PropTypes.bool,
  isShareVinSuccessful: PropTypes.bool,
  isShareVinFailed: PropTypes.bool,
  setShouldCaptureScreenshot: PropTypes.func.isRequired,
  shouldCaptureScreenshot: PropTypes.bool,
  capturedScreenshot: PropTypes.string,
  setCapturedScreenshot: PropTypes.func.isRequired,
  canShareEta: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    mapPlatform: MapState.selectors.getMapPlatform(state),
    shouldCaptureScreenshot:
      MapState.selectors.getShouldCaptureScreenshot(state),
    capturedScreenshot: MapState.selectors.getCapturedScreenshot(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { setShouldCaptureScreenshot, setCapturedScreenshot } =
    MapState.actionCreators;
  return {
    setShouldCaptureScreenshot: (shouldCaptureScreenshot) => {
      dispatch(setShouldCaptureScreenshot(shouldCaptureScreenshot));
    },
    setCapturedScreenshot: (capturedScreenshot) => {
      dispatch(setCapturedScreenshot(capturedScreenshot));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
