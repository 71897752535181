import { connect } from "react-redux";
import FreezePartViewETA from "./FreezePartViewETATool.page";
import FreezePartViewETAState from "../redux/FreezePartViewETAToolState.js";

function mapStateToProps(state) {
  return {
    requestData: FreezePartViewETAState.selectors.getRequestData(state),
  };
}

export default connect(mapStateToProps, {
  freezePartViewEtas: FreezePartViewETAState.actionCreators.freezePartViewEtas,
  requestDataClear: FreezePartViewETAState.actionCreators.requestDataClear,
})(FreezePartViewETA);
