import { getBasicQueryString } from "components/search-bar/search-filter-query-strings";

export const FILTERS = [
  {
    queryKey: "name",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "source",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "code",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "address",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "count",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "validAddress",
    label: () => "",
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString,
  },
];
