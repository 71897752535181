/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { useDiagnosticDataFormatter } from "shared/utils/entity.utils";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import {
  TreeMiddleAndEndCell,
  TreeStartCell,
} from "components/organisms/base-table/Cell/TreeCells";

import Colors from "styles/colors";

const VinHistoricalDataTable = (props) => {
  const {
    solutionId,
    entityId,
    qualifier,
    dealerOrgId,
    fetchHistoricalData,
    getHistoryByQualifier,
  } = props;

  const { t } = useTranslation("fv-vin-details");
  const { getDiagonsticLabel, getColorByState } = useDiagnosticDataFormatter();
  const { columns: diagnosticsDetailsColumns } = useDiagnosticsDataTable(
    t,
    false,
  );

  useEffect(() => {
    fetchHistoricalData(solutionId, entityId, qualifier, dealerOrgId);
  }, [solutionId, entityId, qualifier, dealerOrgId, fetchHistoricalData]);

  const history = useSelector(getHistoryByQualifier(qualifier));
  const historicalData = history?.data ?? [];
  const isLoading = history?.isLoading ?? false;

  const formattedData = historicalData.map((item) => ({
    name: getDiagonsticLabel(item.qualifier.toLowerCase()),
    qualifier: item.qualifier,
    value: item.value,
    units: item.referenceDetails?.units,
    color: getColorByState(item.referenceDetails?.state),
    details: item.referenceDetails,
  }));

  return (
    <BaseTable
      columns={diagnosticsDetailsColumns}
      data={formattedData}
      isLoading={isLoading}
      theme={Themes.LIGHT_CHILD}
      disableSortBy={true}
      hideHeader={true}
      hideBorder={true}
    />
  );
};

VinHistoricalDataTable.propTypes = {
  solutionId: PropTypes.string.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  qualifier: PropTypes.string.isRequired,
  dealerOrgId: PropTypes.string,
  fetchHistoricalData: PropTypes.func.isRequired,
  getHistoryByQualifier: PropTypes.func.isRequired,
};

export const useDiagnosticsDataTable = (t, displayStartColumn) => {
  let columns = [];
  const treeStartColumn = {
    Header: "",
    accessor: "id",
    Cell: TreeStartCell,
    width: 50,
    disableResizing: true,
    disableSortBy: true,
    expandedDataCanExpand: "details.hasHistorical",
    openIcon: faChevronRight,
    closedIcon: faChevronDown,
    openColor: Colors.highlight.DARK_GRAY,
    closedColor: Colors.highlight.DARK_GRAY,
  };

  const treeMiddleAndEndColumn = {
    Header: "",
    accessor: "id",
    Cell: TreeMiddleAndEndCell,
    width: 50,
    disableResizing: true,
    disableSortBy: true,
  };

  columns.push(displayStartColumn ? treeStartColumn : treeMiddleAndEndColumn);

  columns.push({
    Header: t("fv-vin-details:Name"),
    accessor: "name",
    Cell: (cellInfo) => {
      const isExpanded = cellInfo.row.isExpanded ?? false;

      return (
        <Text block bold={isExpanded} size={FontSize.size12}>
          {cellInfo.row.original.name}
        </Text>
      );
    },
  });

  columns.push({
    Header: t("fv-vin-details:Value"),
    accessor: "value",
    Cell: (cellInfo) => {
      const isExpanded = cellInfo.row.isExpanded ?? false;

      return (
        <Text
          block
          size={FontSize.size12}
          bold={isExpanded}
          color={cellInfo.row.original.color}
        >
          {cellInfo.row.original.value} {cellInfo.row.original.unit}
        </Text>
      );
    },
  });

  columns.push({
    Header: t("fv-vin-details:Event Time"),
    accessor: "details.eventTs",
    Cell: (cellInfo) => {
      const isExpanded = cellInfo.row.isExpanded ?? false;

      return (
        <DateTime
          size={FontSize.size12}
          localize={true}
          dateTime={cellInfo.row.original.details?.eventTs}
          fallback={<Fragment></Fragment>}
        >
          <DateTime.Time key="time" bold={isExpanded} />,
          <DateTime.Timezone
            bold={isExpanded}
            color={Colors.text.DARKER_GRAY}
          />
          <DateTime.Date key="date" bold={isExpanded} />
        </DateTime>
      );
    },
  });

  columns.push({
    Header: t("fv-vin-details:Received Time"),
    accessor: "details.receivedTs",
    Cell: (cellInfo) => {
      const isExpanded = cellInfo.row.isExpanded ?? false;

      return (
        <DateTime
          size={FontSize.size12}
          localize={true}
          dateTime={cellInfo.row.original.details?.receivedTs}
          fallback={<Fragment></Fragment>}
        >
          <DateTime.Time key="time" bold={isExpanded} />,
          <DateTime.Timezone
            bold={isExpanded}
            color={Colors.text.DARKER_GRAY}
          />
          <DateTime.Date key="date" bold={isExpanded} />
        </DateTime>
      );
    },
  });

  return { columns, SubComponent: VinHistoricalDataTable };
};
