/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faMapMarkerAltSlash } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text } from "components/atoms/Text.atom";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";
import { useGetCountryNameByCountryCode } from "pages/administration/utils/location-utils";

/**
 * @param props
 * @return {null|*}
 * @constructor
 */
const SimpleAddressCell = (props) => {
  const { t } = useTranslation("domain");

  const { address, address2, city, state, zip, country, validAddress } =
    props.value;

  const countryName = useGetCountryNameByCountryCode(country);

  if (address === "null" || !address) {
    return null;
  }

  const address3 = [city, state, zip].filter((value) => value).join(" ");

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {validAddress === false ? (
        <Tooltip tooltipChildren={<Text>Invalid Address</Text>} placement="top">
          <Icon
            src={faMapMarkerAltSlash}
            style={{ fontSize: "1.2em", paddingRight: "0.5em" }}
          />
        </Tooltip>
      ) : null}

      <div>
        <div>{address}</div>
        {address2 ? <div>{address2}</div> : null}
        {address3 ? <div>{address3}</div> : null}
        {/*Fallback to just the country if CountryName has an error.*/}
        {country ? (
          <ErrorBoundary fallback={country}>
            <div>{t(countryName)}</div>
          </ErrorBoundary>
        ) : null}
      </div>
    </div>
  );
};

export default SimpleAddressCell;

SimpleAddressCell.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    validAddress: PropTypes.bool,
  }),
};
