import { connect } from "react-redux";

import InventoryViewDashboard from "./InventoryView.Dashboard.page";

import InventoryViewDashboardState from "../redux/InventoryViewDashboardState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventoryViewDetailsSearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import InventoryFcVinsSearchBarState from "../redux/InventoryViewDetailsForecastedVinsSearchBarState";

const mapStateToProps = (state) => {
  return {
    watchedLocations:
      InventoryViewDashboardState.selectors.getWatchedLocations(state),
    allWatchedLocations:
      InventoryViewDashboardState.selectors.getAllWatchedLocations(state),
    isWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getWatchedLocationsLoading(state),
    isAllWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getAllWatchedLocationsLoading(
        state,
      ),
    dwellingVinsWidgetPageIndex:
      InventoryViewDashboardState.selectors.getDwellingVinsWidgetPageIndex(
        state,
      ),
    capacityMeterWidgetPageIndex:
      InventoryViewDashboardState.selectors.getCapacityMeterWidgetPageIndex(
        state,
      ),
  };
};

const actionCreators = {
  pushDetailsView: InventoryViewDetailsState.actionCreators.pushAdcDetailsView,
  setSelectedTabIndex:
    InventoryViewDetailsState.actionCreators.setSelectedTabIndex,
  setDwellFilterValue:
    InventoryViewDetailsSearchBarState.actionCreators.setDwellFilterValue,
  fetchWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchWatchedLocations,
  fetchAllWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchAllWatchedLocations,
  saveWatchedLocations:
    InventoryViewDashboardState.actionCreators.saveWatchedLocations,
  setCapacityMeterWidgetPageIndex:
    InventoryViewDashboardState.actionCreators.setCapacityMeterWidgetPageIndex,
  setDwellingVinsWidgetPageIndex:
    InventoryViewDashboardState.actionCreators.setDwellingVinsWidgetPageIndex,
  resetDetailsPageSearchAndFilters: () => (dispatch) => {
    dispatch(
      InventoryViewDetailsSearchBarState.actionCreators.resetSearchAndFilters(),
    );
    dispatch(
      InventoryFcVinsSearchBarState.actionCreators.resetSearchAndFilters(),
    );
  },
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDashboard);
