/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { HealthcareDetailsPanel } from "./components/Healthcare.DetailsPanel.organism";
import MapSearchResults from "components/map-search-results/MapSearchResults";
import HealthcareSearchBarContainer from "../components/search/HealthcareSearchBar.container";
import SimpleMap from "modules/map/components/SimpleMap";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const HealthcareDetails = (props) => {
  const {
    solutionId,
    searchValue,
    addCoordinate,
    clearCoordinatesByType,
    selectedMapCoordinate,
    fetchAssetDetails,
    fetchAssetPositionUpdates,
    updateEntityPositionUpdate,
    clearUpdateEntityPositionUpdateError,
    clearAsset,
    isLoadingAssetDetails,
    assetDetails,
    assetDetailsError,
    isLoadingAssetCoordinates,
    assetCoordinates,
    assetCoordinatesError,
    isLoadingCoordinateUpdate,
    coordinateUpdateError,
  } = props;
  const { t } = useTranslation("healthcare");

  useSetTitleOnMount(t("healthcare:Asset Details"));
  useSetDescriptionOnMount(
    t(
      "healthcare:All assets for your organization and their related position in your facility",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: Healthcare");

  useEffect(() => {
    if (searchValue) {
      // Fetch details and coordinates
      fetchAssetDetails(solutionId, searchValue);
      fetchAssetPositionUpdates(solutionId, searchValue);
    } else {
      // Clear screen
      clearAsset();
      clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
    }
  }, [
    searchValue,
    solutionId,
    fetchAssetDetails,
    fetchAssetPositionUpdates,
    clearAsset,
    clearCoordinatesByType,
  ]);

  return (
    <MapSearchResults
      isCollapsable={false}
      isExporting={false}
      isShowingOverlay={false}
      isShowingRecordLimitModal={false}
      search={
        <div className="m-1">
          <div className="mb-1">
            <HealthcareSearchBarContainer
              isShowingFilters={false}
              isShowingAdvancedSearchButton={false}
              showSearchOptions={true}
              preventSearchOnChange={true}
              maxTypeaheadResults={Infinity}
            />
          </div>
        </div>
      }
      map={<SimpleMap forcedMapPlatform="MAPSINDOORS" />}
      results={
        <HealthcareDetailsPanel
          solutionId={solutionId}
          isLoadingAssetDetails={isLoadingAssetDetails}
          assetDetails={assetDetails}
          assetDetailsError={assetDetailsError}
          isLoadingAssetCoordinates={isLoadingAssetCoordinates}
          assetCoordinates={assetCoordinates}
          assetCoordinatesError={assetCoordinatesError}
          selectedMapCoordinate={selectedMapCoordinate}
          addCoordinate={addCoordinate}
          clearCoordinatesByType={clearCoordinatesByType}
          updateEntityPositionUpdate={updateEntityPositionUpdate}
          clearUpdateEntityPositionUpdateError={
            clearUpdateEntityPositionUpdateError
          }
          isLoadingCoordinateUpdate={isLoadingCoordinateUpdate}
          coordinateUpdateError={coordinateUpdateError}
        />
      }
    />
  );
};

HealthcareDetails.propTypes = {
  solutionId: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool.isRequired,
  fetchAssetDetails: PropTypes.func.isRequired,
  fetchAssetPositionUpdates: PropTypes.func.isRequired,
  updateEntityPositionUpdate: PropTypes.func.isRequired,
  clearUpdateEntityPositionUpdateError: PropTypes.func.isRequired,
  clearAsset: PropTypes.func.isRequired,
  isLoadingAssetDetails: PropTypes.bool.isRequired,
  assetDetails: PropTypes.object,
  assetDetailsError: PropTypes.object,
  isLoadingAssetCoordinates: PropTypes.bool.isRequired,
  assetCoordinates: PropTypes.array,
  assetCoordinatesError: PropTypes.object,
  isLoadingCoordinateUpdate: PropTypes.bool,
  coordinateUpdateError: PropTypes.object,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
};

export default HealthcareDetails;
