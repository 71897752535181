import React from "react";
import { DebugETAForm } from "./components/DebugETAForm";

export const DebugETATool = ({ requestData, debugEta, goToDebugEtaTool }) => {
  return (
    <div>
      <DebugETAForm
        requestData={requestData}
        debugEta={debugEta}
        goToDebugEtaTool={goToDebugEtaTool}
      />
    </div>
  );
};
