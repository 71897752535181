import {
  getBasicQueryString,
  getEverythingQueryString,
} from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("locations:Search Everything"),
    placeholder: (t) => t("locations:Search Name, Address or Location Code"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "name_search",
    label: (t) => t("locations:Name"),
    placeholder: (t) => t("locations:Search Name"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "full_address_search",
    label: (t) => t("locations:Address"),
    placeholder: (t) => t("locations:Search Address"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "code_search",
    label: (t) => t("locations:Location Code"),
    placeholder: (t) => t("locations:Search Location Code"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
];
