import { connect } from "react-redux";

import { FilterCriteriaStep } from "./FilterCriteriaStep.organism";
import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";

const mapStateToProps = (state) => {
  const { getFilterCriteria, getEventType } =
    NotificationManagementWizardState.selectors;
  return {
    filterCriteria: getFilterCriteria(state),
    eventType: getEventType(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const { setFilterCriteria, setIsNextButtonDisabled } =
    NotificationManagementWizardState.actionCreators;

  return {
    setFilterCriteria: (filters) => {
      dispatch(setFilterCriteria(filters));
    },
    setIsNextButtonDisabled: (isNextButtonDisabled) => {
      dispatch(setIsNextButtonDisabled(isNextButtonDisabled));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCriteriaStep);
