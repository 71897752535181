/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import Loader from "react-loader";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SearchBarContainer from "../mt-search/MetalTrackingSearchBarContainer";
import FilterSectionContainer from "../mt-search/MetalTrackingFilterSectionContainer";
import SavedSearchContainer from "../mt-search/MetalTrackingSavedSearchContainer";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import Colors from "../../styles/colors";
import { resultsTableColumns } from "../mt-dashboard/mt-dashboard-components/results-table-columns";
import DataHeader from "../mt-dashboard/mt-dashboard-components/DataHeader";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";

export const MetalTrackingSearchResultsView = ({
  savedSearch,
  loadSavedSearch,
  authorization,
  searchResults,
  isLoading,
  showFilters,
  toggleShowFilters,
  toggleWatchedRackFlag,
  chooseLocation,
  page,
  totalPages,
  setPagination,
  solutionId,
  isExporting,
  exportFailed,
}) => {
  const { t } = useTranslation("mt-search-results");
  useSetTitleOnMount(t("mt-search-results:Search Results"));
  useTrackWithMixpanelOnce("Viewed Page: Metal / Search");

  useEffect(() => {
    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }
  }, [savedSearch, loadSavedSearch]);

  const searchData = isLoading ? [] : searchResults;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <Loader loaded={!isExporting} />
      {exportFailed ? (
        <Alert
          variant="danger"
          style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
        >
          Export Failed.
        </Alert>
      ) : null}
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "20px 20px 0 20px",
        }}
      >
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => toggleShowFilters(!showFilters)}
        />
        <FilterSectionContainer
          show={showFilters}
          backgroundColor={Colors.background.GRAY}
        />
      </div>
      <div
        css={{
          backgroundColor: "#fff",
          padding: "0px 20px 20px 20px",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <DataHeader
            title={t("mt-search-results:Racks")}
            total={searchResults.length || 0}
          />
          <SavedSearchContainer />
        </div>
        <BaseTable
          data={searchData}
          columns={resultsTableColumns(
            t,
            solutionId,
            authorization,
            toggleWatchedRackFlag,
            chooseLocation,
          )}
          isLoading={isLoading}
          pageIndex={page}
          pageSize={100000} // H1-1445: Set page size manually
          pageCount={totalPages}
          fixPaginationToBottom={true}
          onPageChange={(newPage) => {
            setPagination(solutionId, newPage, 100000);
          }}
          onPageSizeChange={(newPageSize) => {
            setPagination(solutionId, 0, newPageSize);
          }}
        />
      </div>
    </div>
  );
};

MetalTrackingSearchResultsView.propTypes = {
  authorization: PropTypes.object.isRequired,
  chooseLocation: PropTypes.any,
  exportFailed: PropTypes.any,
  isExporting: PropTypes.any,
  isLoading: PropTypes.bool,
  loadSavedSearch: PropTypes.func.isRequired,
  page: PropTypes.number,
  pushRackDetailView: PropTypes.any,
  savedSearch: PropTypes.any,
  searchResults: PropTypes.array,
  setPagination: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  solutionId: PropTypes.any,
  toggleShowFilters: PropTypes.func,
  toggleWatchedRackFlag: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};
