import _ from "lodash";

import GeofenceType, {
  getType as getGeofenceType,
} from "../geofence-edit/geofence-types";

export const geofenceWithUpdatedCoordinates = (geofence, newData) => {
  const fenceType = getGeofenceType(geofence);

  if (fenceType === GeofenceType.POLYGONAL) {
    return {
      ...geofence,
      geometry: {
        ...geofence.geometry,
        coordinates: newData,
      },
    };
  } else if (fenceType === GeofenceType.MULTIPOLYGON) {
    return {
      ...geofence,
      geometry: newData,
    };
  } else if (fenceType === GeofenceType.RADIAL) {
    return {
      ...geofence,
      geometry: {
        ...geofence.geometry,
        coordinates: newData,
      },
    };
  }
};

export const isLatValid = (lat) => {
  if (!_.isNumber(lat)) {
    return false;
  }
  if (_.isNaN(lat)) {
    return false;
  }
  if (lat > 90 || lat < -90) {
    return false;
  }
  return true;
};

export const isLngValid = (lng) => {
  if (!_.isNumber(lng)) {
    return false;
  }
  if (_.isNaN(lng)) {
    return false;
  }
  if (lng > 180 || lng < -180) {
    return false;
  }
  return true;
};

export const isCoordValid = (lat, lng) => {
  return isLatValid(lat) && isLngValid(lng);
};
