/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Text } from "components/atoms/Text.atom";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";

export const PickUpWindowsModal = (props) => {
  const { show, onHide, pickupWindow, pickupWindowTz } = props;
  const { t } = useTranslation("driveaway-search");
  const dateFormat = getDateFormatFromUserPreferences();
  const timeFormat = getTimeFormatFromUserPreferences();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogModal
        show={show}
        onHide={onHide}
        title={t("driveaway-search:Pickup Windows")}
        loadStatus="success"
        showCloseButton={false}
        size="sm"
        okButtonText={t("driveaway-search:Close")}
        cancelButtonText=""
        submitButtonText=""
        onSubmit={() => null}
      >
        {!_.isNil(pickupWindowTz) && !_.isNil(pickupWindow) ? (
          pickupWindow?.map((ele, idx) => {
            return (
              <Fragment key={`pickupTimeKey.${idx}`}>
                <div className="container">
                  <div className="row my-1">
                    <div className="col-4">
                      {moment
                        .utc(ele.startTs)
                        .tz(pickupWindowTz)
                        .format(`${dateFormat}`)}
                    </div>
                    <div className="col-8">
                      <Text>
                        {moment
                          .utc(ele.startTs)
                          .tz(pickupWindowTz)
                          .format(`${timeFormat} z`)}
                        {" - "}
                        {moment
                          .utc(ele.endTs)
                          .tz(pickupWindowTz)
                          .format(`${timeFormat} z`)}
                      </Text>
                    </div>
                  </div>
                </div>
                {pickupWindow.length - 1 !== idx ? (
                  <hr style={{ margin: "0.1em 0" }}></hr>
                ) : null}
              </Fragment>
            );
          })
        ) : (
          <Text>
            {t(
              "driveaway-search:Pickup Window unavailable, please contact FreightVerify",
            )}
          </Text>
        )}
      </DialogModal>
    </div>
  );
};

PickUpWindowsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  pickupWindow: PropTypes.arrayOf(PropTypes.object),
  pickupWindowTz: PropTypes.string,
};
