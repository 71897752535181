/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { CoordinatesTable } from "components-old/CoordinatesTable";

import { getReferenceValueByName } from "utils/response-utils";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

export const ConnectedCarCoordinatesTable = ({
  addCoordinate,
  clearCoordinatesByType,
  selectedConnectedCarCoordinate,
  coords,
  maxConnectedCarCoords,
  hasMoreConnectedCarCoords,
  popUpDetails,
}) => {
  const { t } = useTranslation("fv-vin-details");

  return (
    <Fragment>
      <CoordinatesTable
        addCoordinate={(coordinateType, lat, long, time, ind, row) => {
          addCoordinate(
            MapCoordinateType.CURRENT_CONNECTED_CAR,
            lat,
            long,
            time,
            ind,
            {
              PopUpDetails: {
                ...popUpDetails,
                // H2-2164: This is a little weird, but its the simplist way I saw of getting
                // a position update ref into the popup details (otherwise just entity refs)
                positionType: getReferenceValueByName(
                  row.references,
                  "positionType",
                ),
              },
              City: row.city,
              State: row.state,
              Country: row.country,
            },
          );
        }}
        clearCoordinatesByType={clearCoordinatesByType}
        selectedMapCoordinate={selectedConnectedCarCoordinate}
        coords={coords}
        showCityColumn={false}
        showStateColumn={false}
        showCityStateColumn={true}
        showCurrentVehicleLocationColumn={true}
        data-qa="connected-car"
      />
      {hasMoreConnectedCarCoords === true && (
        <div style={{ padding: "10px 0", textAlign: "center" }}>
          {t(
            `fv-vin-details:Connected Car coodinates have been limited to the most recent ${maxConnectedCarCoords} coordinates.`,
          )}
        </div>
      )}
    </Fragment>
  );
};

ConnectedCarCoordinatesTable.propTypes = {
  coords: PropTypes.array,
  maxConnectedCarCoords: PropTypes.number,
  hasMoreConnectedCarCoords: PropTypes.bool,
  popUpDetails: PropTypes.object,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedConnectedCarCoordinate: PropTypes.object,
};
