import PropTypes from "prop-types";
import _ from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { UserAuthorizationNamespace } from "./Authorization";
import UsersState from "modules/users/UsersState";
import * as OrganizationsActions from "modules/organizations/OrganizationsState";
import Authorization from "./Authorization";
import AuthenticationUtils from "./authentication";
import { getAuthorization } from "./AuthorizationSelectors";
import {
  getDealerRedirect,
  getHealthCareRedirect,
  getPartnerRedirect,
} from "pages/loginloading/redirect-helper";
import {
  isDealer,
  isHealthcareProvider,
  isPartner,
} from "shared/utils/organizations.utils";
import { BrowserStorage } from "utils/browser-storage.utils";
import AppConfigurationState from "shared/redux/AppConfigurationState";

// Wraps a component with authorization checks
// If the user is not logged in, redirect to login view
// If the user is not authorized, redirect to error view
export default function AuthorizedComponentWithRedirect(
  WrappedComponent,
  location,
  allowedPrivileges,
  allowedFeatures,
  allowedGlobalAppConfig,
) {
  class WithAuthorization extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      activeOrganization: PropTypes.object,
      federationData: PropTypes.object,
      authorization: PropTypes.object,
      location: PropTypes.object,
      currentUser: PropTypes.object,
      featureData: PropTypes.array,
      featureDataLoading: PropTypes.bool,
      globalAppConfig: PropTypes.object,
      globalAppConfigLoading: PropTypes.bool,
      entitySystemConfigOptions: PropTypes.array,
      entitySystemConfigOptionsLoading: PropTypes.bool,
    };

    constructor(props) {
      super(props);

      this.loginRedirectNeeded = this.loginRedirectNeeded.bind(this);
      this.redirectToLogin = this.redirectToLogin.bind(this);
      this.setCurrentUser = this.setCurrentUser.bind(this);
      this.setCurrentOrganization = this.setCurrentOrganization.bind(this);
      this.userClaims = this.userClaims.bind(this);
    }

    loginRedirectNeeded() {
      return !AuthenticationUtils.isAuthenticated();
    }

    redirectToLogin() {
      const { dispatch, location } = this.props;

      // Get orgId from the URL first
      // - this handles using the orgId param if provided
      let orgId = location?.query?.orgId;
      if (_.isNil(orgId)) {
        // if it doesnt exist, use the orgId in storage
        // - this is for the case of token timeouts
        orgId = BrowserStorage.currentOrganization;
      }

      dispatch(UsersState.actionCreators.setCurrentUser(null));
      AuthenticationUtils.logout();
      AuthenticationUtils.login(orgId);

      return null;
    }

    setCurrentUser() {
      const { dispatch } = this.props;
      const claims = this.userClaims();

      dispatch(UsersState.actionCreators.setCurrentUser(claims));
    }

    setCurrentOrganization() {
      const { dispatch, federationData, location, authorization } = this.props;
      const claims = this.userClaims();

      let organizationId = null;

      if (!AuthenticationUtils.getIsSilentAuthentication()) {
        if (location?.query?.orgId) {
          const parsedOrgId = parseInt(location.query.orgId, 10);
          if (authorization.hasOrganizationAccess(parsedOrgId)) {
            organizationId = parsedOrgId;

            // Remove orgId from URL
            let url = new URL(window.location.href);
            url.searchParams.delete("orgId");
            window.history.replaceState({}, document.title, url.toString());
          } else {
            // If the user doesn't have org access, redirect to 403
            window.location.href = "/accessForbiddenError?orgId=" + parsedOrgId;
            return;
          }
        }

        if (
          organizationId === null &&
          claims?.hasOwnProperty(UserAuthorizationNamespace)
        ) {
          organizationId = BrowserStorage.currentOrganization
            ? BrowserStorage.currentOrganization
            : claims[UserAuthorizationNamespace].organization_id;
        }

        dispatch(OrganizationsActions.setCurrentOrganization(organizationId));
      } else if (!federationData) {
        dispatch(OrganizationsActions.fetchFederationData());
      }

      // Set the current org Id from federation
      if (federationData) {
        dispatch(
          OrganizationsActions.setCurrentOrganization(
            federationData.organization_id,
          ),
        );
      }
    }

    userClaims() {
      return AuthenticationUtils.getDecodedToken();
    }

    componentDidMount() {
      const { currentUser } = this.props;

      if (this.loginRedirectNeeded()) {
        return this.redirectToLogin();
      }

      if (!currentUser) {
        this.setCurrentUser();
        this.setCurrentOrganization();
      }
    }

    componentDidUpdate(prevProps) {
      const { federationData } = this.props;

      if (prevProps.federationData !== federationData) {
        this.setCurrentOrganization();
      }
    }

    render() {
      const {
        currentUser,
        activeOrganization,
        federationData,
        featureData,
        featureDataLoading,
        globalAppConfig,
        globalAppConfigLoading,
        entitySystemConfigOptions,
        entitySystemConfigOptionsLoading,
      } = this.props;

      // PLAT-1062: Prevent showing a 403 when user's token expires.
      // If currentUser is null, that means the app is logging them out.
      // Return null to not disturb the log out process with other redirects.
      if (!currentUser) {
        return null;
      }

      if (featureDataLoading === true) {
        return null;
      }

      if (globalAppConfigLoading || entitySystemConfigOptionsLoading) {
        return null;
      }

      if (_.isEmpty(activeOrganization)) {
        return null;
      }

      const authorization = new Authorization(
        currentUser,
        activeOrganization,
        federationData,
        featureData,
        globalAppConfig,
        entitySystemConfigOptions,
      );

      const isAuthorized = authorization.isAuthorized(
        allowedPrivileges,
        allowedFeatures,
        [],
        [allowedGlobalAppConfig],
      );

      // TODO: Centralize redirect logic for special restrictions below (H1-928, H2-720)

      // H1-928: Redirect PAT-only users to dashboard if attempting to access non-PAT routes
      const patOnlyPath = "/plant-asset";
      if (
        authorization.featureAuthorization.plantAssetTrackingOnly() &&
        !location.pathname.includes(patOnlyPath)
      ) {
        window.location.href = patOnlyPath;
        return null;
      }

      // Partner Redirects
      if (isPartner(activeOrganization)) {
        const partnerRedirectUrl = getPartnerRedirect(location.pathname);

        if (partnerRedirectUrl) {
          window.location.href = partnerRedirectUrl;
          return null;
        }
      }

      // Healthcare Redirects
      if (isHealthcareProvider(activeOrganization)) {
        const healthcareRedirectUrl = getHealthCareRedirect(location.pathname);

        if (healthcareRedirectUrl) {
          window.location.href = healthcareRedirectUrl;
          return null;
        }
      }

      // Dealer Redirects
      if (isDealer(activeOrganization)) {
        const redirectUrl = getDealerRedirect(location.pathname);

        if (redirectUrl) {
          window.location.href = redirectUrl;
          return null;
        }
      }

      if (!isAuthorized) {
        //TODO: create error page for no access
        window.location.href = "/accessForbiddenError";
        return null;
      }

      return WrappedComponent;
    }
  }

  function mapStateToProps(state) {
    return {
      currentUser: state.users.currentUser,
      federationData: state.organizations.federationData,
      featureData: OrganizationsActions.getFeatureData(state),
      featureDataLoading: OrganizationsActions.getFeatureDataLoading(state),
      entitySystemConfigOptions:
        OrganizationsActions.getEntitySystemConfigOptions(state),
      entitySystemConfigOptionsLoading:
        OrganizationsActions.getEntitySystemConfigOptionsLoading(state),
      globalAppConfig:
        AppConfigurationState.selectors.getGlobalAppConfig(state),
      globalAppConfigLoading:
        AppConfigurationState.selectors.getGlobalAppConfigLoading(state),
      activeOrganization: OrganizationsActions.getActiveOrganization(state),
      location: state.location,
      authorization: getAuthorization(state),
    };
  }

  return connect(mapStateToProps)(WithAuthorization);
}
