import axios from "axios";
import { createSelector } from "reselect";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import DomainDataState from "modules/domain-data/DomainDataState";
import _ from "lodash";

const STORE_MOUNT_POINT = "publicShipmentDetailState";

const organizationDuck = buildFetchDuck(STORE_MOUNT_POINT, "organization");

// URLS
const PUBLIC_BASE_URL = apiUrl("/public");

// Actions

const getScopedActionName = (name) => `${STORE_MOUNT_POINT}/${name}`;
const FETCH_SHIPMENT_DETAILS = getScopedActionName("FETCH_SHIPMENT_DETAILS");
const FETCH_MULTIMODAL_DETAILS = getScopedActionName(
  "FETCH_MULTIMODAL_DETAILS",
);
const RECEIVE_MULTIMODAL_DETAILS = getScopedActionName(
  "RECEIVE_MULTIMODAL_DETAILS",
);
const RECEIVE_SHIPMENT_DETAILS = getScopedActionName(
  "RECEIVE_SHIPMENT_DETAILS",
);
const FETCH_CHILD_SHIPMENT_DETAILS = getScopedActionName(
  "FETCH_CHILD_SHIPMENT_DETAILS",
);
const RECEIVE_CHILD_SHIPMENT_DETAILS = getScopedActionName(
  "RECEIVE_CHILD_SHIPMENT_DETAILS",
);
const RECEIVE_ALL_CHILD_SHIPMENT_DETAILS = getScopedActionName(
  "RECEIVE_ALL_CHILD_SHIPMENT_DETAILS",
);
const CLEAR_SHIPMENT_DETAILS = getScopedActionName("CLEAR_SHIPMENT_DETAILS");
const CLEAR_CHILD_SHIPMENT_DETAILS = getScopedActionName(
  "CLEAR_CHILD_SHIPMENT_DETAILS",
);
const FETCH_ROUTE_HEATMAP = getScopedActionName("FETCH_ROUTE_HEATMAP");
const RECEIVE_ROUTE_HEATMAP = getScopedActionName("RECEIVE_ROUTE_HEATMAP");
const RECEIVE_ROUTE_HEATMAP_ERROR = getScopedActionName(
  "RECEIVE_ROUTE_HEATMAP_ERROR",
);

// Action Creators

const fetchShipmentDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SHIPMENT_DETAILS,
    });

    // standard
    dispatch({
      type: FETCH_SHIPMENT_DETAILS,
    });

    axios
      .get(PUBLIC_BASE_URL, {
        params: {
          id: payload.shipmentID,
          token: payload.token,
        },
      })
      .then((response) => {
        const shipmentDetails = response.data;

        dispatch(fetchShipmentRelatedItems(shipmentDetails.related_links));

        if (shipmentDetails?.shipment_details?.is_multileg) {
          dispatch(
            fetchMultimodalShipmentDetails(
              shipmentDetails.related_links.trip.link,
            ),
          );
          dispatch(fetchChildShipmentDetails(shipmentDetails));
        }

        dispatch({
          type: RECEIVE_SHIPMENT_DETAILS,
          data: shipmentDetails,
        });
      })
      .catch((err) => console.log(err));
  };
};

const fetchMultimodalShipmentDetails = (url) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MULTIMODAL_DETAILS,
    });

    axios
      .get(apiUrl(url))
      .then((response) => {
        dispatch({
          type: RECEIVE_MULTIMODAL_DETAILS,
          data: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

const fetchShipmentRelatedItems = (relatedLinks) => {
  return (dispatch) => {
    dispatch(
      organizationDuck.fetch(
        apiUrl(relatedLinks.organization.link),
        undefined,
        (data) => data.response?.[0],
      ),
    );

    dispatch(
      DomainDataState.actionCreators.fetchCountries(
        apiUrl(relatedLinks.countries.link),
      ),
    );
  };
};

const fetchRouteHeatmap = (relatedLinks) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ROUTE_HEATMAP });

    axios
      .get(apiUrl(relatedLinks.heat_map.link))
      .then((response) => {
        let data = response.data;
        dispatch({
          type: RECEIVE_ROUTE_HEATMAP,
          data: data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: RECEIVE_ROUTE_HEATMAP_ERROR });
      });
  };
};

const fetchChildShipmentDetails = (parentShipment) => {
  return (dispatch) => {
    let requests = [];
    let shipmentIdHash = {};

    parentShipment.related_links.children_shipments.forEach((childShipment) => {
      const url = apiUrl(childShipment.link);
      requests.push(axios.get(url));
      shipmentIdHash[url] = childShipment.id;
    });

    dispatch({
      type: FETCH_CHILD_SHIPMENT_DETAILS,
    });

    Promise.all(requests)
      .then((responses) => {
        responses.forEach((resp) => {
          const childShipmentId = shipmentIdHash[resp.config.url];
          dispatch({
            type: RECEIVE_CHILD_SHIPMENT_DETAILS,
            id: childShipmentId,
            data: resp.data,
          });
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS,
        });
      });
  };
};

// Reducer

const initialState = {
  data: {},
  isShipmentDetailsLoading: false,
  parentShipmentDetails: {},
  isParentShipmentDetailsLoading: false,
  childShipmentDetails: {},
  isChildShipmentDetailsLoading: false,
  routeHeatmap: null,
};

function ShipmentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_SHIPMENT_DETAILS:
      return {
        ...state,
        data: initialState.data,
        parentShipmentDetails: initialState.parentShipmentDetails,
        childShipmentDetails: initialState.childShipmentDetails,
      };

    case FETCH_SHIPMENT_DETAILS:
      return {
        ...state,
        data: initialState.data,
        isShipmentDetailsLoading: true,
      };

    case RECEIVE_SHIPMENT_DETAILS:
      return {
        ...state,
        data: action.data,
        isShipmentDetailsLoading: false,
      };

    case FETCH_MULTIMODAL_DETAILS:
      return {
        ...state,
        parentShipmentDetails: initialState.parentShipmentDetails,
        isParentShipmentDetailsLoading: true,
      };

    case RECEIVE_MULTIMODAL_DETAILS:
      return {
        ...state,
        parentShipmentDetails: action.data,
        isParentShipmentDetailsLoading: false,
      };

    case FETCH_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: initialState.childShipmentDetails,
        isChildShipmentDetailsLoading: true,
      };

    case RECEIVE_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: {
          ...state.childShipmentDetails,
          [action.id]: action.data,
        },
      };

    case CLEAR_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: initialState.childShipmentDetails,
      };

    case RECEIVE_ALL_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isChildShipmentDetailsLoading: false,
      };

    case RECEIVE_ROUTE_HEATMAP:
      return {
        ...state,
        routeHeatmap: action.data,
      };

    case FETCH_ROUTE_HEATMAP:
    case RECEIVE_ROUTE_HEATMAP_ERROR:
      return {
        ...state,
        routeHeatmap: [],
      };

    default:
      return state;
  }
}

// selectors

const getShipmentDetails = (state) => {
  let data = state[STORE_MOUNT_POINT].data;

  // Use data from /shipping-ng/trip/:id for multimodal shipments.
  if (data?.shipment_details?.is_multileg) {
    const original = data;
    data = state[STORE_MOUNT_POINT].parentShipmentDetails;

    // Pass along data in the same object. We can't create a new one or the
    // component will have an infinite render loop.
    if (!_.isEmpty(data)) {
      data.eta_time_category = original.eta_time_category;
    }
  }

  return data;
};

// FIXME: this selector will always return true. It's not completely clear what
// it's used for downstream. Some components (like ShipmentDetailView and Panel)
// get the value as an isShipmentDetailsLoaded prop but then don't seem to do
// anything with the prop.
const getShipmentDetailsIsLoaded = (state) =>
  !state[STORE_MOUNT_POINT].isLoading || true;

const getRouteHeatmap = (state) => {
  return state[STORE_MOUNT_POINT].routeHeatmap || null;
};

const getChildShipmentDetails = createSelector(
  // only recalculate when child shipment details changes
  [(state) => state[STORE_MOUNT_POINT].childShipmentDetails],
  (childShipmentDetails) => childShipmentDetails || {},
);

const getIsLoaded = (state) => {
  const {
    isShipmentDetailsLoading = false,
    isParentShipmentDetailsLoading = false,
    isChildShipmentDetailsLoading = false,
  } = state[STORE_MOUNT_POINT];

  return (
    !isShipmentDetailsLoading &&
    !isParentShipmentDetailsLoading &&
    !isChildShipmentDetailsLoading
  );
};

const getActiveOrganization = (state) => {
  return organizationDuck.selectors.getData(state)?.data;
};

const PublicShipmentState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchShipmentDetails,
    fetchShipmentRelatedItems,
    fetchRouteHeatmap,
  },
  selectors: {
    getShipmentDetails,
    getShipmentDetailsIsLoaded,
    getRouteHeatmap,
    getChildShipmentDetails,
    getIsLoaded,
    getActiveOrganization,
  },
  reducer: chainReducers([ShipmentsReducer, organizationDuck.reducer]),
};

export default PublicShipmentState;
