import {
  FILTERS,
  SEARCH_CATEGORIES,
} from "../shipment-eta-validator/ShipmentEtaValidator.searchOptions";
import {
  formatResult,
  isExcludedRow,
} from "../shipment-eta-validator/ShipmentEtaTrigger.utils";

import _ from "lodash";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import qs from "qs";

const getUrl = (solutionId, entityId) => {
  solutionId = "GM_FV"; //Going to assume solutionId will only be GM_FV for now
  return apiUrl(`/eta-shipments/eta_admin/shipment/${entityId}`);
};

const getCsvUrl = (solutionId, queryString, state, entityId = null) => {
  solutionId = "GM_FV"; //Going to assume solutionId will only be GM_FV for now
  const queryStringObj = qs.parse(queryString);
  entityId = queryStringObj.entityId;
  return apiUrl(`/eta-shipments/eta_admin/shipment/${entityId}?${queryString}`);
};

const MOUNT_POINT = "shipmentEtaValidator";

export const getSelectedEntity = (state) => {
  return state[MOUNT_POINT]?.searchFilters?.entityId?.[0]?.entity;
};

// Additional selectors for async search results
// const getVins = state => state[MOUNT_POINT].vins || [];
// const getVinsLoading = state => state[MOUNT_POINT].vinsLoading || false;

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  // Ignore queryString except for the entityId. This will be used as a path param.
  const { entityId } = qs.parse(queryString);

  // Build the URL and fetch.
  const url = getUrl(solutionId, entityId);

  // Get page size to manually calculate the total pages below.
  const pageSize = state[MOUNT_POINT]?.pageSize ?? 1000;

  // Get the entity object from the search filter so we can show all required
  // data in results.
  const entity = getSelectedEntity(state);

  const config = {
    headers: {
      Accept: "*/*",
    },
  };

  return dispatch(
    duck.fetch(url, config, (response = []) => {
      let data = response
        ?.map((result) => formatResult(result, entity))
        .filter((etaTrigger) => !isExcludedRow(etaTrigger));
      let totalCount = data?.length ?? 0;
      let totalPages = Math.ceil(totalCount / pageSize);

      if (response?.[0] === null) {
        totalCount = 0;
      }

      return {
        meta: {
          currentPage: 0,
          totalCount: totalCount,
          totalPages: totalPages,
        },
        data: data,
      };
    }),
  );
};

const SearchBarState = buildSearchBarState(
  MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
);

SearchBarState.actionCreators.exportEntities = _.partial(
  SearchBarState.actionCreators.exportEntities,
  getCsvUrl,
  null,
  { headers: { accept: "text/csv;version=full" } },
  "shipment-eta-validator-results",
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
};

export default SearchBarState;
