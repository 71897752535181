/** @jsxImportSource @emotion/react */
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { FontSize, Text } from "components/atoms/Text.atom";
import {
  ShowMoreList,
  ShowMoreListType,
} from "components/molecules/ShowMoreList.molecule";
import {
  buildExceptionCounts,
  buildExceptionCountsWithSeverity,
  getExceptionColor,
} from "shared/utils/exceptions.utils";

export const ExceptionsBox = ({
  holds = [],
  exceptions = [],
  shouldBuildExceptionsBySeverity,
  showAllException,
  style = {},
  showCount = true,
}: ExceptionsBoxProps) => {
  const { t } = useTranslation("exceptions");
  // H2-3098: Exceptions are displayed differently on FV and VINView
  const exceptionCounts = shouldBuildExceptionsBySeverity
    ? buildExceptionCountsWithSeverity(exceptions, showAllException)
    : buildExceptionCounts(holds, exceptions);

  const totalCount = exceptionCounts?.reduce((total, { count }) => {
    return total + count;
  }, 0);

  // H2-2803: Exception color, display logic differs from FV to VINView
  const exceptionBoxColor = getExceptionColor(totalCount);
  return (
    <div
      style={{
        minHeight: 75,
        background: exceptionBoxColor,
        padding: "0.5em",
        borderRadius: 4,
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        ...style,
      }}
      data-qa="text-active-exceptions"
    >
      <Text size={FontSize.size16}>
        {showCount ? `${totalCount} ` : null}
        {`${t("exceptions:Active Exceptions")}`}
      </Text>
      {exceptionCounts.length > 0 ? (
        <ShowMoreList
          list={exceptionCounts.map((exception) => {
            let exceptionName = t(`exceptions:${exception.text}`);
            let exceptionCount = showCount ? `${exception.count} -` : "";
            if (showAllException) {
              exceptionName = exception.text;
            }
            return `${exceptionCount} ${exceptionName}`;
          })}
          visibleItemCount={4}
          type={ShowMoreListType.TOOLTIP}
          listItemStyle={{ fontWeight: "bold" }}
          showMoreButtonStyle={{ color: "white" }}
          toolTipContainerStyle={{ alignSelf: "center" }}
          buttonText={t("exceptions:View All")}
        />
      ) : (
        <span
          css={{
            marginTop: "0.5em",
            fontSize: "1.25em",
            textAlign: "center",
          }}
        >
          {t("exceptions:No Active Exceptions")}
        </span>
      )}
    </div>
  );
};

type Hold = {
  id: number;
  activatedComments?: string;
  activatedEventDate: string;
  activatedReceiveDate: string;
  clearedComments?: string;
  clearedEventDate?: string;
  clearedReceiveDate?: string;
  status: string;
  type: string;
  typeName: string;
  type_name: string;
};

type Exception = {
  id: number;
  activatedComments?: string;
  activatedEventDate: string;
  activatedReceiveDate: string;
  clearedComments?: string;
  clearedEventDate?: string;
  clearedReceiveDate?: string;
  severity?: "ERROR" | "WARNING" | "OK";
  status: string;
  type: string;
  name?: string;
  typeName: string;
};

type ExceptionsBoxProps = {
  holds: Hold[];
  showAllException?: boolean;
  exceptions: Exception[];
  shouldBuildExceptionsBySeverity?: boolean;
  style: CSSProperties;
  showCount?: boolean;
};
