import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export enum EtaName {
  PENDING_DISPATCH = "pending dispatch",
  DELAYED_PENDING_PICKUP = "delayed pending pickup",
  DELAYED = "delayed",
  TBD = "tbd",
  HOLD = "hold",
  TBD_ON_HOLD = "tbd - on hold",
  DELIVERED = "delivered",
}

export const useEtaTranslations = () => {
  const { t } = useTranslation("domain-data");

  const getEtaTranslation = useCallback(
    (eta: string, etaRangeStart: string, etaRangeEnd: string) => {
      switch (eta?.toLowerCase()) {
        case EtaName.PENDING_DISPATCH:
          return etaRangeStart && etaRangeEnd
            ? t(
                "domain-data:Pending Dispatch - Estimated Delivery [[[fromMonth]]] - [[[toMonthYear]]]",
                {
                  fromMonth: moment.utc(etaRangeStart).format("MMM"),
                  toMonthYear: moment.utc(etaRangeEnd).format("MMM YYYY"),
                },
              )
            : t("domain-data:Pending Dispatch");
        case EtaName.DELAYED_PENDING_PICKUP:
          return t("domain-data:Delayed Pending Pickup");
        case EtaName.DELAYED:
          return t("domain-data:Delayed");
        case EtaName.TBD:
          return t("domain-data:TBD");
        case EtaName.HOLD:
          return t("domain-data:Hold");
        case EtaName.TBD_ON_HOLD:
          return t("domain-data:TBD - On Hold");
        default:
          return eta;
      }
    },
    [t],
  );

  const getEtaTooltipTranslation = useCallback(
    (eta: string, isFvEta: boolean) => {
      if (isFvEta) {
        // Always show a tooltip if it's an intelligent ETA
        return t("domain-data:ETA to Destination, calculated by FreightVerify");
      }
      switch (eta?.toLowerCase()) {
        case EtaName.PENDING_DISPATCH:
          return t(
            "domain-data:This vehicle is pending dispatch and will not have an ETA until it is in transit to the dealership",
          );
        case EtaName.DELAYED_PENDING_PICKUP:
        case EtaName.DELAYED:
          return t("domain-data:VIN is delayed in the logistics network");
        case EtaName.TBD:
        case EtaName.HOLD:
        case EtaName.TBD_ON_HOLD:
          return t("domain-data:No recent updates received");
        default:
          return null;
      }
    },
    [t],
  );

  const isEtaName = useCallback((eta: any) => {
    const etaValue = eta?.toLowerCase();
    return Object.values(EtaName).includes(etaValue);
  }, []);

  return {
    getEtaTranslation,
    getEtaTooltipTranslation,
    isEtaName,
  };
};
