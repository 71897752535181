/** @jsxImportSource @emotion/react */
import SelectFilter from "components/organisms/base-table/Filter/SelectFilter";
import PropTypes from "prop-types";

// Dropdown filter allowing selection of location validity on Manage Locations search results table
const ValidityFilter = (props) => {
  const { setFilter } = props;

  const options = [
    { value: "true", label: "Valid Address" },
    { value: "false", label: "Invalid Address" },
  ];

  return (
    <div style={{ width: "13em" }}>
      <SelectFilter
        placeholder="Address Type"
        isClearable={true}
        options={options}
        onChange={(selected) => {
          const selectedValue = selected ? selected.value : undefined;
          setFilter("validAddress", selectedValue);
        }}
      />
    </div>
  );
};

ValidityFilter.propTypes = {
  setFilter: PropTypes.func,
};

export default ValidityFilter;
