/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, FormControl, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import moment from "moment";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Colors from "styles/colors";
import { FlexColDiv } from "styles/container-elements";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import SelectInput from "components-old/forms/inputs/SelectInput";
import ValidationMessage from "components-old/forms/ValidationMessage";
import { EditShipmentRow } from "./AddAssetModal";
import { Tooltip } from "components/atoms/Tooltip.atom";

const checkTime = (now, ts, tz) => {
  if (ts === null || ts === "") {
    return true;
  }

  const tsAdjusted = tz ? moment(ts).tz(tz) : moment(ts);

  return tsAdjusted < now;
};

const validTimes = (userChangedData, stops, data) => {
  if (userChangedData === false) {
    return false;
  }

  const now = moment();
  let validTime = true;
  for (let i = 0; i < stops.length; i++) {
    const stopTZ = stops[i].timezone;

    validTime &= checkTime(now, data[i].arrivedAt, stopTZ);
    validTime &= checkTime(now, data[i].departedAt, stopTZ);
    validTime &= checkTime(now, data[i].loadedAt, stopTZ);
    validTime &= checkTime(now, data[i].unloadedAt, stopTZ);
  }

  return validTime;
};

const StopPane = ({
  data,
  selectedStopIndex,
  updateField,
  isSubmitLoading,
}) => {
  const { t } = useTranslation("shipment-details");

  return (
    <FlexColDiv
      css={{
        padding: "1em",
      }}
    >
      <EditShipmentRow
        label={t("shipment-details:Arrived")}
        value={data[selectedStopIndex].arrivedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "arrivedAt",
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
        isSubmitLoading={isSubmitLoading}
      />
      <EditShipmentRow
        label={t("shipment-details:Completed Unloading")}
        value={data[selectedStopIndex].unloadedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "unloadedAt",
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
        isSubmitLoading={isSubmitLoading}
      />
      <EditShipmentRow
        label={t("shipment-details:Completed Loading")}
        value={data[selectedStopIndex].loadedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "loadedAt",
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
        isSubmitLoading={isSubmitLoading}
      />
      <EditShipmentRow
        label={t("shipment-details:Departed")}
        value={data[selectedStopIndex].departedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "departedAt",
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
        isSubmitLoading={isSubmitLoading}
      />
    </FlexColDiv>
  );
};

StopPane.propTypes = {
  data: PropTypes.array,
  selectedStopIndex: PropTypes.number,
  updateField: PropTypes.func,
  t: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
};

const AlertMessages = ({
  errorMessages = null,
  responseStatusCode = null,
  showAlert,
  closeAlert,
}) => {
  const { t } = useTranslation("shipment-details");

  const fieldTranslationMap = {
    arrivedAt: t("shipment-details:Arrived"),
    unloadedAt: t("shipment-details:Completed Unloading"),
    loadedAt: t("shipment-details:Completed Loading"),
    departedAt: t("shipment-details:Departed"),
  };

  const translateErrorMessage = (error, field) => {
    if (error === "please try again later") {
      return t(
        `shipment-details:Unable to submit ${field}. Please try again later.`,
      );
    } else {
      return (
        <span>
          {field}: {error}
        </span>
      );
    }
  };
  return (
    <>
      {responseStatusCode ? (
        <Alert
          show={responseStatusCode && showAlert}
          onClose={() => closeAlert()}
          variant={AlertVariant.Success}
          dismissible
        >
          <Text block size={FontSize.size16}>
            {t("shipment-details:Successfully processed event(s).")}
          </Text>
        </Alert>
      ) : null}

      {errorMessages?.length > 0 ? (
        <Alert
          show={!_.isEmpty(errorMessages) && showAlert}
          onClose={() => closeAlert()}
          variant={AlertVariant.Danger}
          dismissible
        >
          <Text block size={FontSize.size16}>
            {t("shipment-details:Something went wrong")}
          </Text>

          {errorMessages?.map((errorMessages, i) => (
            <Text block size={FontSize.size14} key={i}>
              {/* {}:{" "} */}
              {translateErrorMessage(
                errorMessages.error,
                fieldTranslationMap[errorMessages.field],
              )}
            </Text>
          ))}
        </Alert>
      ) : null}
    </>
  );
};

AlertMessages.propTypes = {
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      error: PropTypes.string,
    }),
  ),
  responseStatusCode: PropTypes.number,
  showAlert: PropTypes.bool,
  closeAlert: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
};

export const ShipmentEventsModal = ({
  shipment,
  createShipmentEvents,
  show,
  hide,
  isSubmitLoading,
  errorMessages,
  responseStatusCode,
  clearReportShipmentEvents,
}) => {
  const { t } = useTranslation("shipment-details");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState(data);
  const [noteValue, setNoteValue] = useState("");
  const [selectedStopIndex, setSelectedStopIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [dateInputs, setDateInputs] = useState([]);

  //get date inputs for  the current stop
  const getdateEvent = useCallback(
    (data) => {
      let dataEvent;
      let checkDateEvent;

      if (data.length > 0) {
        for (let i = 0; i <= data.length; i++) {
          if (i === selectedStopIndex) {
            dataEvent = Object.values(data[i]);
          }
        }

        checkDateEvent = dataEvent.filter((value) => value !== null);
        setDateInputs(checkDateEvent);
      }
    },
    [selectedStopIndex],
  );

  useEffect(() => {
    if (errorMessages || responseStatusCode) {
      setShowAlert(true);
    }
    //eslint-disable-next-line
  }, [errorMessages, responseStatusCode]);

  useEffect(() => {
    if (responseStatusCode) {
      resetStopData(shipment, false);
    }
    //eslint-disable-next-line
  }, [responseStatusCode]);

  useEffect(() => {
    resetStopData(shipment);
  }, [shipment]);

  useEffect(() => {
    getdateEvent(data);
  }, [getdateEvent, data]);

  const handleOnChangeNote = (evt) => {
    setNoteValue(evt.target.value);
  };

  const handleOnEnter = () => {
    resetStopData(shipment);
    clearReportShipmentEvents();
  };

  const createNewEvents = () => {
    createShipmentEvents(data, noteValue);
  };

  const resetStopData = (shipment = null, setOriginStop = true) => {
    if (!shipment?.shipment_stops) {
      return null;
    }

    const stopData = shipment.shipment_stops.map((s) => {
      return {
        arrivedAt: null,
        unloadedAt: null,
        loadedAt: null,
        departedAt: null,
      };
    });

    setData(stopData);
    setNoteValue("");
    if (setOriginStop) {
      setSelectedStopIndex(0);
    }
    setInitialData(stopData);
  };

  const handleChangeStop = (value) => {
    setSelectedStopIndex(value);
    setShowAlert(false);
    clearReportShipmentEvents();
    resetStopData(shipment, false);
  };

  const updateField = (field, val) => {
    const fieldData = JSON.parse(field);
    const updatedData = { ...data[fieldData.index], [fieldData.field]: val };
    const newData = [
      ...data.slice(0, fieldData.index),
      updatedData,
      ...data.slice(fieldData.index + 1),
    ];
    setData(newData);
  };

  if (!("shipment_stops" in shipment) || data.length === 0) {
    return null;
  }

  const userChangedData = !_.isEqual(initialData, data);
  const hasValidData = validTimes(
    userChangedData,
    shipment.shipment_stops,
    data,
  );

  const stopOptions = shipment.shipment_stops.map((s, i) => {
    let stopNumber = String(i);
    if (i === 0) {
      stopNumber = "O";
    } else if (i === shipment.shipment_stops.length - 1) {
      stopNumber = "D";
    }

    return {
      value: i,
      label: "(" + stopNumber + ") " + s.location.name,
    };
  });

  const canSubmit = !isSubmitLoading && hasValidData;

  const closeAlert = () => setShowAlert(false);

  return (
    <Modal
      show={show}
      style={{ ":width": "300px" }}
      onHide={() => {
        hide();
        closeAlert();
      }}
      onEnter={handleOnEnter}
    >
      <Modal.Header
        closeButton
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        }}
        title={t("shipment-details:Report Arrival/Departure Events")}
      />

      <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Tooltip
          placement="top"
          tooltipChildren={
            dateInputs.length > 0 ? (
              <Text>
                {t(
                  "shipment-details:Only one Stop may be submitted at a time.",
                )}
              </Text>
            ) : null
          }
        >
          <SelectInput
            isReadOnly={dateInputs.length > 0 || isSubmitLoading}
            label={t("shipment-details:Stops")}
            options={stopOptions}
            value={selectedStopIndex}
            onChange={handleChangeStop}
          />
        </Tooltip>
        <StopPane
          data={data}
          shipment={shipment}
          selectedStopIndex={selectedStopIndex}
          updateField={updateField}
          isSubmitLoading={isSubmitLoading}
        />
        <FlexColDiv
          style={{
            margin: "0.4em",
          }}
        >
          <ValidationMessage
            isValid={hasValidData}
            msg={t("shipment-details:Times must be in the past")}
          />
        </FlexColDiv>
        <FlexColDiv style={{ marginBottom: "16px" }}>
          <FormLabel>{`${t("shipment-details:Notes")}:`}</FormLabel>
          <FormControl
            as="textarea"
            disabled={isSubmitLoading}
            placeholder={t(
              "shipment-details:Enter notes about the change here",
            )}
            value={noteValue}
            onChange={handleOnChangeNote}
            style={{ resize: "none" }}
          />
        </FlexColDiv>

        <AlertMessages
          errorMessages={errorMessages}
          responseStatusCode={responseStatusCode}
          showAlert={showAlert}
          closeAlert={closeAlert}
        />
      </Modal.Body>

      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          disabled={isSubmitLoading}
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em",
          }}
          onClick={() => {
            hide();
            closeAlert();
          }}
        >
          {t("shipment-details:Cancel")}
        </Button>
        <Button
          disabled={!canSubmit}
          style={{
            backgroundColor: Colors.highlight.GREEN,
            color: "white",
            fontWeight: "300",
          }}
          onClick={() => {
            createNewEvents();
          }}
        >
          {isSubmitLoading ? (
            <Icon src={faSpinner} spin />
          ) : (
            t("shipment-details:Submit")
          )}
        </Button>{" "}
      </Modal.Footer>
    </Modal>
  );
};

ShipmentEventsModal.propTypes = {
  shipment: PropTypes.object,
  createShipmentEvents: PropTypes.func,
  show: PropTypes.bool,
  hide: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      error: PropTypes.string,
    }),
  ),
  responseStatusCode: PropTypes.number,
  clearReportShipmentEvents: PropTypes.func,
};
