import _ from "lodash";
import apiUrl from "api-url";
import qs from "qs";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SubmissionsByStatusFilters } from "../components/search/DamageView.searchOptions";

const STORE_MOUNT_POINT = "damageViewSubmissionsByStatus";

// Helpers

const fetchUrl = (solutionId, queryString) => {
  const url = apiUrl(`/damageview/submission`);
  const payload = qs.parse(queryString);

  payload.searchVersion = "allSubmissionsCount";
  payload.pageNumber = undefined;
  payload.pageSize = undefined;

  return `${url}?${qs.stringify(payload)}`;
};

// Actions

const fetchSubmissions = (queryString = "", solutionId, duck, dispatch) => {
  const url = fetchUrl(solutionId, queryString);
  dispatch(duck.fetch(url));
};

const DamageViewSubmissionsByStatusState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  SubmissionsByStatusFilters,
  fetchSubmissions,
  [],
  {},
);

export default DamageViewSubmissionsByStatusState;
