import apiUrl from "api-url";
import { buildEntityHistoricalSlice } from "shared/redux/EntityHistoricalSlice";

const STORE_MOUNT_POINT = "fvEntityHistory";

const getHistoryUrl = (solutionId, entityId, qualifier, dealerOrgId) => {
  return apiUrl(
    `/entity/solution/${solutionId}/entity/${entityId}/reference-history?qualifier=${qualifier}`,
  );
};

const FinVehicleEntityHistoricalState = buildEntityHistoricalSlice(
  STORE_MOUNT_POINT,
  getHistoryUrl,
);
export default FinVehicleEntityHistoricalState;
