import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import {
  SEARCH_CATEGORIES,
  FILTERS,
} from "pages/partview/components/search/PartView.searchOptions";

const STORE_MOUNT_POINT = "partViewSearch";

// Actions
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

// SearchBarStateBuilder need 'solutionId' as first parameter,
// but not required here therefore named it _ignored_solutionId
const entitiesUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(`/partview/app/search?status=active&${queryString}`);
};

const batchSearchUrl = (queryString, batchType) => {
  return apiUrl(
    `/partview/app/search?status=active&${queryString}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=SEARCH_RESULTS",
    },
  };
};

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(queryString, dispatch, batchFilter, duck);
  } else {
    const url = entitiesUrl(null, queryString);
    const config = axiosConfig();

    // Fetch the count
    dispatch(fetchTotalCount(queryString));

    // Fetch the search
    dispatch(duck.fetch(url, config));

    // Redirect to PartView search page
    dispatch({ type: "PARTVIEW_SEARCH" });
  }
};

const batchSearch = (queryString, dispatch, batchFilter, duck) => {
  const batchValueArray = batchFilter.batch_list.split(",");
  const batchType = batchFilter.batch_type;
  const url = batchSearchUrl(queryString, batchType);
  const data = {
    batchList: batchValueArray,
  };
  const config = axiosConfig();

  // Fetch the count
  dispatch(fetchTotalCount(queryString, batchType, data));

  dispatch({
    type: duck.actions.REQUEST,
  });

  axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: response.data ? response.data : [],
      });

      dispatch({ type: "PARTVIEW_SEARCH" });
    })
    .catch((err) => {
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });
};

const fetchTotalCount = (queryString, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();

    // Update pagination params and Fetch the count
    // pageNumber and pageSize is set to default values always
    // in order to receive the meta object in api
    const countParams = qs.parse(queryString);
    countParams.pageNumber = 0;
    countParams.pageSize = 20;
    const countQueryString = qs.stringify(countParams);

    const url = batchType
      ? batchSearchUrl(countQueryString, batchType)
      : entitiesUrl(null, countQueryString);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      method: batchType ? "POST" : "GET",
      url,
      data: batchType ? data : undefined,
      headers: {
        "x-time-zone": moment.tz.guess(),
        Accept: "application/json;version=SEARCH_COUNT",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

// Reducer
const PartViewSearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [PartViewSearchBarReducer],
  { defaultSort: "destinationEta", reverseSort: false },
);

SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  null,
  { headers: { Accept: "text/csv;version=full" } },
  "part-view-search-results",
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

export default SearchBarState;
