/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useIsExtraLarge } from "components/responsive";
import { useShipmentTranslation } from "shared/hooks/useShipmentTranslation";
import { Tabs } from "components/molecules/Tabs.molecule";
import ShipmentDetailsCollapsible from "../../../../modules/shipment-detail/shipment-detail-styled-components/ShipmentDetailsCollapsible";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { PublicShipmentContentPanel } from "./PublicShipmentContentPanel";
import {
  endToEndTab,
  legTabs,
} from "components/multimodal-components/tabComponents";
import {
  tabListStyle,
  tabPanelStyle,
  tabsCss,
} from "components/multimodal-components/tabStyles";
import { getTrailerAndEquipmentNumberFromChildShipments } from "../../../../modules/shipment-detail/ShipmentUtils";
import type { Organization } from "shared/constants/organization.const";
import type { Shipment, ShipmentTrip } from "shared/constants/shipments.const";

type PublicMultimodalShipmentEndToEndTabPanelProps = {
  parentShipment: ShipmentTrip;
  childShipments: {
    [id: number]: Shipment;
  };
};

export function PublicMultimodalShipmentEndToEndTabPanel({
  parentShipment,
  childShipments,
}: PublicMultimodalShipmentEndToEndTabPanelProps) {
  const { t } = useTranslation("shipment-details");
  const { getTranslatedStatus } = useShipmentTranslation();

  const firstShipmentId = parentShipment.child_shipments[0].shipment_id;
  const firstShipment = childShipments[firstShipmentId];

  const { origin, destination, frozenEta, isFvEta } =
    getLocationDataFromShipments(
      parentShipment.child_ids?.map((id) => childShipments[id]),
    );

  // H1-815: Use parent shipment destination ETA for ultimate destination ETA
  let calculatedEta = parentShipment.destination_eta;
  // H1-4822: Override the ETA if we have a frozen one.
  if (!_.isNil(frozenEta)) {
    calculatedEta = frozenEta;
  }

  // Get Trailer and Equipment numbers
  // H1-3859: Only show unique values on the UI.
  const trailerAndEquipmentNumbers = _.uniq(
    getTrailerAndEquipmentNumberFromChildShipments(childShipments ?? {}),
  );

  return (
    <div css={{ overflow: "visible" }}>
      <ShipmentDetailsCollapsible
        shipmentId={parentShipment.creator_shipment_id}
        assetId={null}
        mode={parentShipment.mode_name}
        showMadChiclet={false}
        status={getTranslatedStatus(parentShipment.active_status_ng)}
        shipperName={firstShipment ? firstShipment.shipper_name : ""}
        carrierName={null}
        carrierScac={null}
        trailerAndEquipmentNumbers={trailerAndEquipmentNumbers}
        showException={false}
      />

      <ArrivalAndDeparturePanelGroup
        origin={origin}
        destination={destination}
        eta={calculatedEta}
        etaWarningMessage={
          !_.isNil(frozenEta) &&
          parentShipment.active_exception_ng?.includes("Carrier Delayed")
            ? t(
                "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier.",
              )
            : null
        }
        isFvEta={isFvEta}
        visibleFields={{
          availableForUnload: firstShipment?.mode_name === "Rail",
        }}
        availableForUnloadTs={firstShipment?.available_for_unload_ts}
        style={{ margin: "1em" }}
      />
    </div>
  );
}

type PublicMultimodalShipmentTabsProps = {
  parentShipment: ShipmentTrip;
  childShipments: {
    [id: number]: Shipment;
  };
  organization: Organization;
  setSelectedLegId(id: string | null): void;
};

export function PublicMultimodalShipmentContentPanel({
  parentShipment,
  childShipments,
  organization,
  setSelectedLegId,
}: PublicMultimodalShipmentTabsProps) {
  const isExtraLarge = useIsExtraLarge();

  let legShipments =
    parentShipment?.child_shipments
      .map((shipment) => {
        return childShipments[shipment.shipment_id];
      })
      .filter((shipment) => !_.isNil(shipment)) ?? [];

  const eventHandler = (value: string, action: string) => {
    if (action === "SELECT_LEG") {
      setSelectedLegId(value);
    }
  };

  return (
    <Tabs fullWidthTabs css={{ ...tabsCss, minHeight: "100%" }}>
      <Tabs.TabList
        maxTabs={isExtraLarge ? 5 : 3}
        staticTabs={1}
        style={tabListStyle}
      >
        {endToEndTab(eventHandler)}
        {legTabs(
          parentShipment.child_shipments ?? [],
          childShipments,
          eventHandler,
          true,
        )}
      </Tabs.TabList>
      <Tabs.TabPanel
        style={tabPanelStyle}
        key={parentShipment.creator_shipment_id}
      >
        <PublicMultimodalShipmentEndToEndTabPanel
          parentShipment={parentShipment}
          childShipments={childShipments}
        />
      </Tabs.TabPanel>
      {legShipments.map((shipment) => {
        return (
          <Tabs.TabPanel style={tabPanelStyle} key={shipment.id}>
            <PublicShipmentContentPanel
              shipment={shipment}
              organization={organization}
            />
          </Tabs.TabPanel>
        );
      })}
    </Tabs>
  );
}
