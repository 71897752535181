import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import DealerPartViewSearchBarState, {
  getShowDestinationFilter,
} from "pages/partview/redux/DealerPartViewSearchBarState";

import { FILTERS } from "pages/partview/components/search/DealerPartView.searchOptions";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

function mapStateToProps(state) {
  return {
    auth: getAuthorization(state),
    searchFilters:
      DealerPartViewSearchBarState.selectors.getSearchFilters(state),
    hasSearchCriteriaChanged:
      DealerPartViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
    areAllPrerequisiteFiltersSelected:
      DealerPartViewSearchBarState.selectors.getAreAllPrerequisiteFiltersSelected(
        state,
      ),
    canUserSearch:
      DealerPartViewSearchBarState.selectors.getCanUserSearch(state),
    showDestinationFilter: getShowDestinationFilter(state),
  };
}

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = DealerPartViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    filtersMetadata: FILTERS,
  };
}

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
