import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "../../redux/PartViewSearchBarState";
import {
  partViewShipmentIdOptionsState,
  partViewPartNumberOptionsState,
  partViewOrderNumberOptionsState,
  partViewPackageNumberOptionsState,
  partViewBillOfLadingOptionsState,
} from "../../redux/PartViewSearchCategoryLoader";

function mapStateToProps(state) {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
  } = SearchBarState.selectors;

  return {
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    canUserSearch: getCanUserSearch(state),

    //shipment id
    shipmentIdOptions:
      partViewShipmentIdOptionsState.selectors.getResults(state),
    isShipmentIdOptionsLoading:
      partViewShipmentIdOptionsState.selectors.getIsLoading(state),

    //part number
    partNumberOptions:
      partViewPartNumberOptionsState.selectors.getResults(state),
    isPartNumberOptionsLoading:
      partViewPartNumberOptionsState.selectors.getIsLoading(state),

    //order number
    orderNumberOptions:
      partViewOrderNumberOptionsState.selectors.getResults(state),
    isOrderNumberOptionsLoading:
      partViewOrderNumberOptionsState.selectors.getIsLoading(state),

    //package number
    packageNumberOptions:
      partViewPackageNumberOptionsState.selectors.getResults(state),
    isPackageNumberOptionsLoading:
      partViewPackageNumberOptionsState.selectors.getIsLoading(state),

    //bill of lading
    billOfLadingOptions:
      partViewBillOfLadingOptionsState.selectors.getResults(state),
    isBillOfLadingOptionsLoading:
      partViewBillOfLadingOptionsState.selectors.getIsLoading(state),
  };
}

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
} = SearchBarState.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
  fetchShipmentIdOptions: (query, maxResults) => {
    return (dispatch) => {
      const fetch = partViewShipmentIdOptionsState.actionCreators.fetch;
      return dispatch(fetch(query, maxResults));
    };
  },
  fetchPartNumberOptions: (query, maxResults) => {
    return (dispatch) => {
      const fetch = partViewPartNumberOptionsState.actionCreators.fetch;
      return dispatch(fetch(query, maxResults));
    };
  },
  fetchOrderNumberOptions: (query, maxResults) => {
    return (dispatch) => {
      const fetch = partViewOrderNumberOptionsState.actionCreators.fetch;
      return dispatch(fetch(query, maxResults));
    };
  },
  fetchPackageNumberOptions: (query, maxResults) => {
    return (dispatch) => {
      const fetch = partViewPackageNumberOptionsState.actionCreators.fetch;
      return dispatch(fetch(query, maxResults));
    };
  },
  fetchBillOfLadingOptions: (query, maxResults) => {
    return (dispatch) => {
      const fetch = partViewBillOfLadingOptionsState.actionCreators.fetch;
      return dispatch(fetch(query, maxResults));
    };
  },
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
