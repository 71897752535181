/** @jsxImportSource @emotion/react */
import { Button } from "components/atoms/Button.atom";
import Colors from "../../../styles/colors";
import MilestoneLogsFilterSectionContainer from "./MilestoneLogs.SearchFilters.container";
import {
  useMilestoneLogs,
  useDefaultFilters,
  useInitialSearch,
  useFetchDomainData,
} from "./MilestoneLogsHooks";

const formCss = {
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: Colors.background.GRAY,
  margin: "0 0 0.5em 0",
};

const filtersCss = {
  backgroundColor: Colors.background.GRAY,
  paddingTop: "0.5em",
};

// Search filters and search button displayed on Milestone Logs view
export const MilestoneLogsSearchBar = (props) => {
  const { t, searchEntities, hasSearchCriteriaChanged } = useMilestoneLogs();
  useDefaultFilters();
  useInitialSearch(searchEntities);
  useFetchDomainData();

  const handleSubmit = (event) => {
    event.preventDefault();
    searchEntities();
  };

  return (
    <form css={formCss} onSubmit={handleSubmit}>
      <div css={{ flexGrow: 1 }}>
        <MilestoneLogsFilterSectionContainer
          show={true}
          sectionStyle={filtersCss}
        />
      </div>
      <Button
        type="submit"
        variant={hasSearchCriteriaChanged ? "primary" : "dark"}
        pulse={hasSearchCriteriaChanged}
        css={{
          height: "3em",
          margin: "0.5em",
          padding: "0 2em",
        }}
        data-qa="button-search"
      >
        {t("documentation:Search")}
      </Button>
    </form>
  );
};
