// Perform authorization checks based on user's active organization type
export default class OrganizationFiltersAuthorization {
  constructor(organizationFilters) {
    this.organizationFilter = organizationFilters ? organizationFilters : [];
  }

  hasOrganizationFilter(filter) {
    return this.organizationFilter.indexOf(filter) >= 0;
  }
}
