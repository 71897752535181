/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import { getSolutionId } from "../../organizations/OrganizationsState";
import SearchBarContainer from "../ConnectedCarSearchBarContainer";
import FilterSectionContainer from "../ConnectedCarFilterSectionContainer";
import ConnectedCarSearchBarState from "../ConnectedCarSearchBarState";

const SearchBar = ({ isExporting }) => {
  const dispatch = useDispatch();
  const solutionId = useSelector(getSolutionId);

  return (
    <div>
      <div className="mb-1">
        <SearchBarContainer
          isShowingFilters={false}
          isShowingAdvancedSearchButton={false}
          showSearchOptions={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: Colors.background.GRAY,
          borderRadius: "3px",
        }}
      >
        <div style={{ width: "100%" }}>
          <FilterSectionContainer
            show={true}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{
              marginRight: "0.5em",
              marginBottom: "0",
              paddingRight: "8em",
            }}
          />
        </div>

        <div
          css={{
            display: "flex",
            alignItems: "flex-end",
            paddingBottom: "0.25em",
          }}
        >
          <DownloadDataButton
            css={{
              color: Colors.highlight.DARK_GRAY,
              fontSize: 12.5,
              marginRight: "0.5em",
              paddingLeft: 0,
            }}
            isExporting={isExporting}
            onClick={() => {
              dispatch(
                ConnectedCarSearchBarState.actionCreators.exportSearch(
                  solutionId,
                ),
              );
            }}
            data-qa="button-search-results-export"
          />
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  isExporting: PropTypes.bool,
};

export default SearchBar;
