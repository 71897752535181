import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";

// Exported URLs
export const currentPositionCodesUrl = () => {
  let url = `/entity-search/list?currentPositionCodes=1`;
  return apiUrl(url);
};

export const productTypesUrl = () => {
  let url = `/entity-search/list?productType=1`;
  return apiUrl(url);
};

const STORE_MOUNT_POINT = "carrierViewDomainData";
const carrierViewDomainDuck = buildFetchDuck("carrierViewDomainDuck");
const carrierViewProductTypeDuck = buildFetchDuck("carrierViewProductTypeDuck");
const carrierViewPositionTypesDuck = buildFetchDuck(
  "carrierViewPositionTypesDuck",
);
const carrierViewOrderTypeDuck = buildFetchDuck("carrierViewOrderTypeDuck");
const carrierViewOrderNumberDuck = buildFetchDuck("carrierViewOrderNumberDuck");
const carrierViewExceptionTypesDuck = buildFetchDuck(
  "carrierViewExceptionTypesDuck",
);

export function fetchDomainData() {
  const url = apiUrl(`/entity-search/list`);

  const domainDataParams = {
    shipper: 1,
    // H1-1549: for some reason they use the parameter here as lifecycleState
    // but return this as lifeCycleState, with a capital c, so, this is the only
    // place where whe have this written in this way
    lifecycleState: 1,
  };
  const domainDataCurrentPositionTypesParams = {
    currentPositionTypes: 1,
  };
  const domainDataProductTypeParams = {
    productType: 1,
  };
  const domainDataOrderTypeParams = {
    "ref:OrderType": 1,
  };
  const domainDataOrderNumberParams = {
    "ref:OrderNumber": 1,
  };
  const domainDataExceptionTypeParams = {
    exceptionType: 1,
  };

  return (dispatch) => {
    dispatch(carrierViewDomainDuck.fetch(url, { params: domainDataParams }));
    dispatch(
      carrierViewProductTypeDuck.fetch(url, {
        params: domainDataProductTypeParams,
      }),
    );
    dispatch(
      carrierViewPositionTypesDuck.fetch(url, {
        params: domainDataCurrentPositionTypesParams,
      }),
    );
    dispatch(
      carrierViewOrderTypeDuck.fetch(url, {
        params: domainDataOrderTypeParams,
      }),
    );
    dispatch(
      carrierViewOrderNumberDuck.fetch(url, {
        params: domainDataOrderNumberParams,
      }),
    );
    dispatch(
      carrierViewExceptionTypesDuck.fetch(url, {
        params: domainDataExceptionTypeParams,
      }),
    );
  };
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent].data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getExceptionTypes = createSelectorFromProperty(
  "exceptionTypes",
  "exceptionType",
);
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

// H2-366: Remove VINs from typeahead
const getVINs = (state) => [];

const getProductTypes = createSelectorFromProperty(
  "productTypes",
  "productTypes",
);

const getOrderTypes = createSelectorFromProperty(
  "ref:OrderType",
  "orderType",
  true,
);
const getOrderNumbers = createSelectorFromProperty(
  "ref:OrderNumber",
  "orderNumber",
  true,
);
const getLifeCycleStates = createSelectorFromProperty(
  "lifecycleState",
  "domainData",
);

const getShippers = createSelectorFromProperty(
  "shipper",
  "domainData",
  "org_name",
);

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getDomainDataLoading = (state) =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getLifeCycleStates,
    getVINs,
    getOrderTypes,
    getOrderNumbers,
    getShippers,
    getDomainDataLoading,
    getCurrentPositionTypes,
  },
  reducer: combineReducers({
    domainData: carrierViewDomainDuck.reducer,
    productTypes: carrierViewProductTypeDuck.reducer,
    currentPositionTypes: carrierViewPositionTypesDuck.reducer,
    orderType: carrierViewOrderTypeDuck.reducer,
    orderNumber: carrierViewOrderNumberDuck.reducer,
    exceptionType: carrierViewExceptionTypesDuck.reducer,
  }),
};
