//External hooks or supporting code from React
import { connect } from "react-redux";

//Components
import { FilterSection } from "components/search-bar/FilterSection";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import DomainDataState from "modules/domain-data/DomainDataState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import { FILTERS } from "./Shipments.searchOptions";
import {
  selectCarrierFilterOptions,
  selectModeFilterOptions,
  selectStatusFilterOptions,
  selectShipmentEventFilterOptions,
  selectExceptionTypeFilterOptions,
  selectShipmentTypeFilterOptions,
  selectShipperFilterOptions,
  selectTripPlanNumberFilterOptions,
  getLoadedStatusFilterOptions,
  selectLineOfBusinessFilterOptions,
} from "./Shipments.SearchFilterSelectors";
import LadsState from "modules/lads/LadsState";

const mapStateToProps = (state) => ({
  carrierFilterOptions: selectCarrierFilterOptions(state),
  shipmentModeFilterOptions: selectModeFilterOptions(state),
  locationTypeFilterOptions:
    LadsState.selectors.selectLadsListAlphabetically(state),
  statusFilterOptions: selectStatusFilterOptions(state),
  shipmentEventFilterOptions: selectShipmentEventFilterOptions(state),
  exceptionFilterOptions: selectExceptionTypeFilterOptions(state),
  shipmentTypeFilterOptions: selectShipmentTypeFilterOptions(state),
  shipperFilterOptions: selectShipperFilterOptions(state),
  tripPlanNumberFilterOptions: selectTripPlanNumberFilterOptions(state),
  loadedStatusFilterOptions: getLoadedStatusFilterOptions(state),
  activeOrganization: getActiveOrganization(state),
  lineOfBusinessFilterOptions: selectLineOfBusinessFilterOptions(state),
  regionFilterOptions:
    DomainDataState.selectors.selectRegionFilterOptions(state),
  searchFilters: ShipmentsSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    ShipmentsSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  canUserSearch: ShipmentsSearchBarState.selectors.getCanUserSearch(state),
});

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchFilter,
    clearSearchFilter,
    clearSavedSearch,
    searchEntities,
  } = ShipmentsSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value, ignoreValueChange) =>
      dispatch(setSearchFilter(key, value, ignoreValueChange)),
    clearSearchFilter: (key, ignoreValueChange) =>
      dispatch(clearSearchFilter(key, ignoreValueChange)),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: () => dispatch(searchEntities()),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilterSection);
