/** @jsxImportSource @emotion/react */
import { useMilestoneLogs } from "./MilestoneLogsHooks";
import PageHeader from "../documentation-styled-components/PageHeader";
import { MilestoneLogsSearchBar } from "./MilestoneLogsSearchBar";
import { MilestoneLogsSearchResults } from "./MilestoneLogsSearchResults";
import { MilestoneLogsAlert } from "./MilestoneLogsAlert";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";

// Milestone Logs search dashboard
export const MilestoneLogsView = (props) => {
  const { t } = useMilestoneLogs();

  useSetTitleOnMount(t("documentation:Training & Resources"));

  useTrackWithMixpanelOnce("Viewed Page: Docs / Milestone Logs");

  return (
    <div css={{ padding: "1em" }}>
      <PageHeader title={t("documentation:API Milestone Request Logs")} />
      <MilestoneLogsSearchBar />
      <MilestoneLogsAlert />
      <MilestoneLogsSearchResults />
    </div>
  );
};
