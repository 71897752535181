/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { Button } from "components/atoms/Button.atom";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { useDamageViewStatusTranslation } from "../utils/translation.utils";
import { IoMdTimer } from "react-icons/io";
import moment from "moment";
import { tsToDaysHrsMins } from "utils/date-time";

export const useColumns = (
  selectedIds,
  addSelectedId,
  removeSelectedId,
  selectAllSelections,
  clearAllSelections,
  isAllSelected = false,
  allSubmissionIdsIsLoading = false,
  exportPDFEntities,
  userPreference,
  isExportingPDF,
  solutionId,
  watchActions,
  isShipper = false,
) => {
  const { t } = useTranslation("damageview-search");
  const [unwatchedForm, setUnwatchedForm] = useState([]);
  let unwatchTimers = {};
  const toggleWatch = (submissionId, newWatchValue) => {
    let newUnwatchedForm = [...unwatchedForm];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[submissionId]) {
      clearTimeout(unwatchTimers[submissionId]);
    }

    if (newUnwatchedForm.includes(submissionId)) {
      // Form checkbox has already been unchecked - re-watch it
      newUnwatchedForm = newUnwatchedForm.filter((v) => v !== submissionId);
    } else {
      // Form checkbox is checked - unwatch it
      newUnwatchedForm.push(submissionId);
    }

    setUnwatchedForm(newUnwatchedForm);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[submissionId] = setTimeout(() => {
      watchActions(solutionId, submissionId, newWatchValue);
    }, 2000);
  };

  let columns = [
    {
      Header: t("damageview-search:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const submissionId = cellInfo.row.original.id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={submissionId}
            checked={watched ?? false}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(newWatchValue) => {
              toggleWatch(submissionId, newWatchValue);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("damageview-search:Submission ID"),
      id: "submissionId",
      accessor: (obj) => {
        return {
          submissionId: obj?.id ?? "",
          external_id: obj?.external_id ?? "",
          exportPDFEntities: exportPDFEntities,
          userPreference: userPreference,
        };
      },
      disableSortBy: true,
      disableResizing: true,
      Cell: SubmissionIDCell,
    },
    {
      Header: t("damageview-search:VINs"),
      id: "vins",
      accessor: (obj) => {
        return {
          vinIds: obj?.vins ? getVins(obj.vins) : [],
        };
      },
      minWidth: 200,
      disableSortBy: true,
      Cell: VinsCell,
    },
    {
      Header: t("damageview-search:Last Milestone"),
      id: "lastmilestone",
      accessor: (obj) => {
        return {
          vinIds: obj?.vins ? getVins(obj.vins) : [],
          milestones: obj?.vins ? getLastMilestones(obj.vins, t) : [],
        };
      },
      disableSortBy: true,
      width: 220,
      Cell: LastMilestoneCell,
    },
    {
      Header: t("damageview-search:Status"),
      id: "status",
      accessor: (obj) => {
        return {
          status: obj?.status ?? "",
        };
      },
      disableSortBy: true,
      width: 100,
      Cell: StatusCell,
    },
    {
      Header: t("damageview-search:Status Updated"),
      id: "statusUpdated",
      accessor: (obj) => {
        return {
          statusUpdated: obj?.status_updated ?? "",
        };
      },
      disableSortBy: true,
      Cell: StatusUpdatedCell,
    },
    {
      Header: t("damageview-search:In Progress Status"),
      id: "inProgressStatus",
      accessor: (obj) => {
        return {
          inProgressStatus: obj?.inProgressStatus ?? "",
        };
      },
      disableSortBy: true,
      Cell: InProgressStatusCell,
    },
    {
      Header: t("damageview-search:In Progress Status Updated"),
      id: "inProgressStatusUpdated",
      accessor: (obj) => {
        return {
          inProgressStatusUpdated: obj?.inProgressStatusUpdated ?? "",
        };
      },
      disableSortBy: true,
      Cell: InProgressStatusUpdatedCell,
    },
    {
      Header: t("damageview-search:Stolen VIN"),
      id: "stolenVin",
      accessor: (obj) => {
        return {
          stolenVinMessage: obj?.stolenVin ?? "",
        };
      },
      disableSortBy: true,
      Cell: StolenVinCell,
    },
    {
      Header: t("damageview-search:Reporting Location"),
      id: "reportingLocation",
      accessor: (obj) => {
        return {
          isAtLocation: obj?.location?.toLowerCase() === "at location",
          reportingLocation: obj?.reportingLocation,
        };
      },
      disableSortBy: true,
      minWidth: 290,
      Cell: ReportingLocationCell,
    },
    {
      Header: t("damageview-search:Assignee"),
      id: "assignee",
      accessor: (obj) => {
        return {
          assignee: obj?.assignee_email ?? "",
        };
      },
      disableSortBy: true,
      minWidth: 280,
      Cell: AssigneeCell,
    },
    {
      Header: t("damageview-search:Submitters"),
      id: "submitter",
      accessor: (obj) => {
        return {
          submitter: obj?.submitter_email ?? "",
          phone: obj?.submitter_phone ?? "",
          secondSubmitter: obj?.secondarySubmitterEmail ?? "",
          secondPhone: obj?.secondarySubmitterPhoneNumber ?? "",
        };
      },
      disableSortBy: true,
      width: 270,
      Cell: SubmitterCell,
    },
    {
      Header: t("damageview-search:Submission Date"),
      id: "submission_date",
      accessor: (obj) => {
        return {
          date: obj?.submission_date ?? "",
          status: obj?.status ?? "",
        };
      },
      Cell: SubmissionDateCell,
    },
    {
      Header: t("damageview-search:Inspection Date"),
      id: "inspectionDate",
      accessor: (obj) => {
        return {
          date: obj?.inspection_date ?? "",
        };
      },
      disableSortBy: true,
      Cell: InspectionDateCell,
    },
  ];

  if (isShipper) {
    columns.unshift({
      Header: () => {
        return allSubmissionIdsIsLoading ? null : (
          <Checkbox
            id="selectAll"
            checked={isAllSelected}
            onChange={(newVal) => {
              if (newVal) {
                selectAllSelections();
              } else {
                clearAllSelections();
              }
            }}
          />
        );
      },
      id: "checkBox",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      accessor: (obj) => {
        return {
          submissionId: obj.id,
        };
      },
      Cell: (props) => {
        const { submissionId } = props.value;
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              id={submissionId}
              checked={selectedIds.has(submissionId)}
              onChange={(newVal) => {
                if (newVal) {
                  addSelectedId(submissionId);
                } else {
                  removeSelectedId(submissionId);
                }
              }}
            />
          </div>
        );
      },
    });
  }

  return columns;
};

// Functions
// Shared with Dashboard tables widgets
export const getVins = (vinsArray) => {
  return vinsArray.map((data) => {
    return data.id;
  });
};

export const getLastMilestones = (vinsArray, t) => {
  return vinsArray.map((data) => {
    const code = data?.lastStatusUpdate?.code ?? "";
    const description = data?.lastStatusUpdate?.codeDescription ?? "";
    const value = `${description}${code ? ` (${code})` : ""}`;
    return value ? value : t("damageview-search:No updates available");
  });
};

const CustomPopupContent = ({ vinIds, milestones }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <div>
        <ShowMoreList
          listItemStyle={{ fontWeight: "bold" }}
          list={vinIds}
          visibleItemCount={vinIds.length + 1}
        />
      </div>
      <div>
        <ShowMoreList
          list={milestones}
          visibleItemCount={milestones.length + 1}
        />
      </div>
    </div>
  );
};

CustomPopupContent.propTypes = {
  vinIds: PropTypes.array,
  milestones: PropTypes.array,
};

// Cells
// Shared with Dashboard table widgets

export const SubmissionIDCell = (props) => {
  const { t } = useTranslation("damageview-search");
  const { submissionId, external_id, exportPDFEntities, userPreference } =
    props.value;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {external_id}
      </Text>
      <Button
        css={{ paddingLeft: "unset" }}
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
          exportPDFEntities(submissionId, userPreference?.locale);
        }}
      >
        {t("damageview-search:Download")}
      </Button>
    </div>
  );
};

SubmissionIDCell.propTypes = {
  value: PropTypes.shape({
    submissionId: PropTypes.number,
    external_id: PropTypes.string,
    exportPDFEntities: PropTypes.func,
    userPreference: PropTypes.object,
  }),
};

export const VinsCell = (props) => {
  const { t } = useTranslation("damageview-search");
  const { vinIds } = props.value;

  return (
    <ShowMoreList
      list={vinIds}
      listItemStyle={{ fontWeight: "bold" }}
      visibleItemCount={3}
      title={t("damageview-search:VINs")}
    />
  );
};

VinsCell.propTypes = {
  value: PropTypes.shape({
    vinIds: PropTypes.array,
  }),
};

export const LastMilestoneCell = (props) => {
  const { t } = useTranslation("damageview-search");
  const { vinIds, milestones } = props.value;

  return (
    <ShowMoreList
      customModalBody={
        <CustomPopupContent vinIds={vinIds} milestones={milestones} />
      }
      list={milestones}
      listItemStyle={{ fontWeight: "bold" }}
      visibleItemCount={3}
      title={t("damageview-search:Last Milestone")}
    />
  );
};

LastMilestoneCell.propTypes = {
  value: PropTypes.shape({
    vinIds: PropTypes.array,
    milestones: PropTypes.array,
  }),
};

export const StatusCell = (props) => {
  const { status } = props.value;
  const { getTranslatedStatus } = useDamageViewStatusTranslation();
  return (
    <div>
      <Text block>{getTranslatedStatus(status)}</Text>
    </div>
  );
};

StatusCell.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export const StatusUpdatedCell = (props) => {
  const { statusUpdated } = props.value;
  return (
    <div>
      <Text block>
        <DateTimeCell dateTime={statusUpdated} stack localize />
      </Text>
    </div>
  );
};

StatusUpdatedCell.propTypes = {
  value: PropTypes.shape({
    statusUpdated: PropTypes.string,
  }),
};

export const InProgressStatusCell = (props) => {
  const { inProgressStatus } = props.value;
  const { getTranslatedInProgressStatus } = useDamageViewStatusTranslation();
  return (
    <div>
      <Text block>{getTranslatedInProgressStatus(inProgressStatus)}</Text>
    </div>
  );
};

InProgressStatusCell.propTypes = {
  value: PropTypes.shape({
    inProgressStatus: PropTypes.string,
  }),
};

export const InProgressStatusUpdatedCell = (props) => {
  const { inProgressStatusUpdated } = props.value;
  return (
    <div>
      <Text block>
        <DateTimeCell dateTime={inProgressStatusUpdated} stack localize />
      </Text>
    </div>
  );
};

InProgressStatusUpdatedCell.propTypes = {
  value: PropTypes.shape({
    inProgressStatusUpdated: PropTypes.string,
  }),
};

export const StolenVinCell = (props) => {
  const { stolenVinMessage } = props.value;
  return (
    <div>
      <Text block>{stolenVinMessage}</Text>
    </div>
  );
};

StolenVinCell.propTypes = {
  value: PropTypes.shape({
    stolenVinMessage: PropTypes.string,
  }),
};

export const ReportingLocationCell = (props) => {
  const { isAtLocation, reportingLocation } = props.value;

  const FormattedAddress = ({
    address = "",
    city = "",
    code = "",
    country = "",
    name = "",
    postalCode = "",
    state = "",
  }) => {
    return (
      <div>
        <Text block>{`${name} (${code})`}</Text>
        <Text block>{`${address}`}</Text>
        <Text block>{`${city} ${state} ${postalCode} ${country}`}</Text>
      </div>
    );
  };

  return (
    <div>
      {isAtLocation && typeof reportingLocation === "object"
        ? FormattedAddress(reportingLocation ?? {})
        : null}
      {!isAtLocation && typeof reportingLocation === "string" ? (
        <Text block>{reportingLocation ?? ""}</Text>
      ) : null}
    </div>
  );
};

ReportingLocationCell.propTypes = {
  value: PropTypes.shape({
    isAtLocation: PropTypes.bool,
    reportingLocation: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

export const AssigneeCell = (props) => {
  const { assignee } = props.value;
  return (
    <div>
      <Text block>{assignee}</Text>
    </div>
  );
};

AssigneeCell.propTypes = {
  value: PropTypes.shape({
    assignee: PropTypes.string,
  }),
};

export const SubmitterCell = (props) => {
  const { t } = useTranslation("damageview-search");
  const { submitter, phone, secondSubmitter, secondPhone } = props.value;
  return (
    <div>
      {submitter ? (
        <div>
          <Text block bold>
            {t("damageview-search:Primary Submitter") + ":"}
          </Text>
          <Text block>{submitter}</Text>
          {phone ? <Text block>{phone}</Text> : null}
        </div>
      ) : null}
      {secondSubmitter ? (
        <div>
          <Text block bold>
            {t("damageview-search:Secondary Submitter") + ":"}
          </Text>
          <Text block>{secondSubmitter}</Text>
          {secondPhone ? <Text block>{secondPhone}</Text> : null}
        </div>
      ) : null}
    </div>
  );
};

SubmitterCell.propTypes = {
  value: PropTypes.shape({
    submitter: PropTypes.string,
    phone: PropTypes.string,
    secondSubmitter: PropTypes.string,
    secondPhone: PropTypes.string,
  }),
};

export const SubmissionDateCell = (props) => {
  const { date, status } = props.value;
  const { t } = useTranslation("damageview-search");

  if (!date) {
    return null;
  }

  const differenceInMins = moment(new Date()).diff(moment(date), "minutes");
  const agingHour = Math.floor(differenceInMins / 60);
  const isAging = agingHour >= 72 ? true : false;

  return (
    <div>
      <Text block>
        <DateTimeCell dateTime={date} stack localize />
      </Text>
      {status?.toLowerCase() === "submitted" ? (
        <div css={{ marginTop: "1rem" }}>
          <Text bold block>
            {t("damageview-search:Submission Aging")}
          </Text>
          <div css={{ display: "flex", flexDirection: "row" }}>
            <IoMdTimer
              style={{
                fontSize: 20,
                marginRight: 10,
                color: isAging ? Colors.holds.RED : Colors.holds.YELLOW,
              }}
            />
            <Text bold>{tsToDaysHrsMins(date)}</Text>
          </div>
        </div>
      ) : null}
    </div>
  );
};

SubmissionDateCell.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.string,
    isAging: PropTypes.bool,
    agingHour: PropTypes.number,
    agingMin: PropTypes.number,
    status: PropTypes.string,
  }),
};

export const InspectionDateCell = (props) => {
  const { date } = props.value;
  return (
    <div>
      <Text block>
        <DateTimeCell dateTime={date} stack localize />
      </Text>
    </div>
  );
};

InspectionDateCell.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.string,
  }),
};
