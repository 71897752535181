/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { deleteOrganization } from "../OrganizationsState";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Modal } from "components/molecules/Modal.molecule";
import { ReadOnlyInput } from "components-old/modal-elems";
import { FlexRowDiv } from "../../../styles/container-elements";
import { Text } from "components/atoms/Text.atom";
import OrganizationsSearchBarState from "../OrganizationsSearchBarState";

const initOrg = {
  name: "",
  orgType: "",
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null,
  id: null,
};

const DeleteOrganizationModal = ({
  show,
  hide,
  organization,
  deleteOrganization,
  searchOrganizations,
}) => {
  const { t } = useTranslation("organizations");
  const [org, setOrg] = useState(initOrg);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(
    () =>
      setOrg((prevState) => {
        return {
          ...prevState,
          id:
            organization && organization.organization_id
              ? organization.organization_id
              : null,
          name:
            organization && organization.org_name ? organization.org_name : "",
          email:
            organization && organization.email_address
              ? organization.email_address
              : "",
          freightVerifyId:
            organization && organization.fv_id ? organization.fv_id : "",
          contactName:
            organization && organization.contact_name
              ? organization.contact_name
              : "",
          phoneNumber:
            organization && organization.phone_no ? organization.phone_no : "",
          scac: organization && organization.scac ? organization.scac : "",
          orgType:
            organization && organization.org_type ? organization.org_type : "",
        };
      }),
    [organization],
  );

  const isCarrier = org.orgType === "Carrier";

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header title={t("organizations:Delete Organization")} />
      <Modal.Body>
        <FlexRowDiv>
          <ReadOnlyInput
            label={t("organizations:Organization Name")}
            value={org.name}
          />
          <ReadOnlyInput label={t("organizations:Type")} value={org.orgType} />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput label={t("organizations:Email")} value={org.email} />
          <ReadOnlyInput
            label={t("organizations:FreightVerify ID")}
            value={org.freightVerifyId}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("organizations:Contact Name")}
            value={org.contactName}
          />
          <ReadOnlyInput
            label={t("organizations:Phone Number")}
            value={org.phoneNumber}
          />
        </FlexRowDiv>
        {isCarrier ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <ReadOnlyInput label={t("organizations:SCAC")} value={org.scac} />
          </FlexRowDiv>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setShowConfirmationDialog(true);
          }}
        >
          {t("organizations:Delete")}
        </Button>
      </Modal.Footer>
      <DialogModal
        show={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
        title={t("organizations:Confirm Organization Deletion")}
        cancelButtonText={t("organizations:Cancel")}
        submitButtonText={t("organizations:Delete Organization")}
        submitButtonVariant="danger"
        onSubmit={() => {
          deleteOrganization(org.id).then(() => {
            setShowConfirmationDialog(false);
            hide(); // Hide the original modal
            searchOrganizations();
          });
        }}
      >
        <Text block>
          {t(
            "organizations:Are you sure you want to delete this organization?",
          )}
        </Text>
        <Text block bold style={{ marginTop: 10 }}>
          {org.name}
        </Text>
      </DialogModal>
    </Modal>
  );
};

DeleteOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  deleteOrganization: PropTypes.func.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  deleteOrganization,
  searchOrganizations: () =>
    OrganizationsSearchBarState.actionCreators.searchEntities(
      null,
      false,
      true,
    ),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteOrganizationModal);
