/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { TimeCell } from "components/organisms/base-table/Cell/TimeCell";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import {
  TreeMiddleAndEndCell,
  TreeStartCell,
} from "components/organisms/base-table/Cell/TreeCells";
import { useGetCountryNameByCountryCode } from "pages/administration/utils/location-utils";
import { MilestoneReferencesButton } from "shared/components/modals/MilestoneReferences";
import Colors from "styles/colors";

const UpdateCell = ({ original, isExpanded }) => {
  const countryName = useGetCountryNameByCountryCode(
    original.statusUpdate.location?.country,
  );

  return (
    <div>
      {original.subcodeDescription ? (
        <Text bold size={FontSize.size12}>
          {original.subcodeDescription}
        </Text>
      ) : null}
      {original.subcode ? (
        <Text bold size={FontSize.size12} style={{ marginLeft: 5 }}>
          ({original.subcode})
        </Text>
      ) : null}
      {original.statusUpdate && original.statusUpdate.location && (
        <React.Fragment>
          <Text block size={FontSize.size12} bold={isExpanded}>
            {original.statusUpdate.location.name}
            {original.statusUpdate.shipperLocationCode && (
              <React.Fragment>
                {" "}
                ({original.statusUpdate.shipperLocationCode})
              </React.Fragment>
            )}
          </Text>
          <Text block size={FontSize.size12} bold={isExpanded}>
            {original.statusUpdate.location.address}
          </Text>
          <Text block size={FontSize.size12} bold={isExpanded}>
            {[
              original.statusUpdate.location.city,
              original.statusUpdate.location.state,
              original.statusUpdate.location.postalCode,
              countryName,
            ].join(", ")}
          </Text>
        </React.Fragment>
      )}
    </div>
  );
};

UpdateCell.propTypes = {
  original: PropTypes.object,
  isExpanded: PropTypes.bool,
};

const EventGroupTable = (props) => {
  const { duplicateMilestoneGroup } = props;
  const { columns: updatesColumns } = useUpdatesTable(
    props.showComments,
    false,
    false,
  );

  const formattedData = duplicateMilestoneGroup.map((item) => ({
    eventTs: item.eventTs,
    ts: item.ts,
    update: item.update,
    comments: item.comments,
    showComments: item.showComments,
    code: item.code,
    statusUpdate: item.statusUpdate,
    subcode: item.subcode,
    subcodeDescription: item.subcodeDescription,
  }));

  return (
    <BaseTable
      columns={updatesColumns}
      data={formattedData}
      theme={Themes.LIGHT_CHILD}
      disableSortBy={true}
      hideHeader={true}
      hideBorder={true}
    />
  );
};

EventGroupTable.propTypes = {
  duplicateMilestoneGroup: PropTypes.array.isRequired,
  showComments: PropTypes.bool.isRequired,
};

export const useUpdatesTable = (showComments, isPartView, hasGroupedEvents) => {
  const { t } = useTranslation("components");

  const columns = useMemo(() => {
    const treeStartColumn = {
      Header: "",
      accessor: "id",
      Cell: TreeStartCell,
      width: 50,
      disableResizing: true,
      disableSortBy: true,
      expandedDataCanExpand: "duplicateMilestoneGroup",
      openIcon: faChevronRight,
      closedIcon: faChevronDown,
      openColor: Colors.highlight.DARK_GRAY,
      closedColor: Colors.highlight.DARK_GRAY,
    };

    const treeMiddleAndEndColumn = {
      Header: "",
      accessor: "id",
      Cell: TreeMiddleAndEndCell,
      width: 50,
      disableResizing: true,
      disableSortBy: true,
    };

    const columns_to_return = [
      hasGroupedEvents ? treeStartColumn : treeMiddleAndEndColumn,
      {
        Header: t("components:Event Time"),
        accessor: "eventTs",
        width: 100,
        Cell: (cellInfo) => {
          return (
            <TimeCell
              dateTime={cellInfo?.row?.values?.eventTs}
              localize
              bold={cellInfo.row.isExpanded ?? false}
            />
          );
        },
        disableSortBy: false,
      },
      {
        Header: t("components:Received Time"),
        accessor: "ts",
        width: 100,
        Cell: (cellInfo) => {
          return (
            <TimeCell
              dateTime={cellInfo?.row?.values?.ts}
              localize
              bold={cellInfo.row.isExpanded ?? false}
            />
          );
        },
        disableSortBy: false,
      },
      {
        Header: t("components:Update"),
        accessor: "update",
        Cell: (cellInfo) => {
          if (cellInfo.row.original.code === "StatusUpdated") {
            return (
              <UpdateCell
                t={t}
                original={cellInfo.row.original}
                isExpanded={cellInfo.row.isExpanded ?? false}
              />
            );
          } else {
            const reopenMilestone = _.find(cellInfo.sortedRows, function (s) {
              return s?.original?.code === "RO";
            });

            const reopenDate = new Date(
              reopenMilestone?.values?.eventTs,
            ).getTime();

            const updateDate = new Date(
              cellInfo?.row?.values?.eventTs,
            ).getTime();

            const isBold =
              isPartView && cellInfo?.row?.original?.eventType === "milestone";

            return (
              <Text
                block
                size={FontSize.size12}
                bold={isBold}
                italic={reopenMilestone && !isBold && reopenDate < updateDate}
              >
                {!isPartView
                  ? cellInfo.row.original.update
                  : cellInfo?.row?.original?.updateDescription}
              </Text>
            );
          }
        },
        disableSortBy: true,
      },
    ];

    if (!isPartView && showComments) {
      columns_to_return.push({
        Header: t("components:Comments"),
        accessor: "comments",
        Cell: (cellInfo) => {
          const isExpanded = cellInfo.row.isExpanded ?? false;

          if (cellInfo.row.original.code === "StatusUpdated") {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                {cellInfo.row.original?.showComments ? (
                  <Text block size={FontSize.size12} bold={isExpanded}>
                    {cellInfo.row.original.comments}
                  </Text>
                ) : cellInfo.row.original.statusUpdate &&
                  cellInfo.row.original.statusUpdate.sourceName ? (
                  <Text block size={FontSize.size12} bold={isExpanded}>
                    {cellInfo.row.original.statusUpdate.sourceName}{" "}
                    {cellInfo.row.original.statusUpdate.sourceScac
                      ? ` (${cellInfo.row.original.statusUpdate.sourceScac})`
                      : null}
                  </Text>
                ) : null}

                <div
                  style={{ cursor: "pointer", marginLeft: 10, marginRight: 10 }}
                >
                  <MilestoneReferencesButton
                    t={t}
                    statusUpdate={cellInfo.row.original.statusUpdate}
                    comments={cellInfo.row.original.comments}
                  />{" "}
                </div>
              </div>
            );
          } else if (cellInfo.row.original.subcode === "ITSS") {
            return (
              <div css={{ display: "flex", flexDirection: "column" }}>
                <Text block size={FontSize.size12} bold={isExpanded}>
                  {t("components:VIN added to ITSS ID")}
                </Text>
                <Text block size={FontSize.size12} bold={isExpanded}>
                  {cellInfo.row.original.comments}
                </Text>
              </div>
            );
          } else {
            return (
              <Text block size={FontSize.size12} bold={isExpanded}>
                {cellInfo.row.original.comments}
              </Text>
            );
          }
        },
        disableSortBy: true,
      });
    }

    if (isPartView) {
      columns_to_return.push({
        Header: t("components:Comment"),
        accessor: "comments",
        Cell: (cellInfo) => (
          <Text block size={FontSize.size12}>
            {cellInfo?.row?.original?.comments}
          </Text>
        ),
        disableSortBy: true,
      });
    }

    return columns_to_return;
  }, [t, hasGroupedEvents, isPartView, showComments]);

  return {
    columns,
    SubComponent: EventGroupTable,
  };
};
