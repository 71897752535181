/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import PropTypes from "prop-types";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { getPercentageWithOneDecimalPoint } from "pages/containertracking/utils/ContainerTrackingUtils";
import { convertCountToLocaleString } from "utils/html-utils";
import Colors from "styles/colors";

const percentageBarStyle = {
  alignSelf: "center",
  backgroundColor: Colors.background.MID_GRAY,
  border: `1px solid ${Colors.highlight.DARK_TEAL}`,
  borderRadius: "16px",
  color: Colors.text.WHITE,
  flex: "0 0 4em",
  fontSize: "0.6rem",
  marginLeft: "7px",
  minWidth: "45px",
  padding: "0 7px",
  textAlign: "center",
};

const buttonStyle = {
  color: Colors.linkText,
  cursor: "pointer",
  fontSize: "small",
  padding: "0.375rem 0.575rem",
};

const PercentageBar = ({ value, total }) => {
  return (
    <div css={percentageBarStyle}>
      {`${getPercentageWithOneDecimalPoint(value, total)}%`}
    </div>
  );
};

PercentageBar.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
};

const ContainerTypeRenderer = ({ value }) => {
  return <Text>{value}</Text>;
};

ContainerTypeRenderer.propTypes = {
  value: PropTypes.string,
};

const TotalRenderer = (props) => {
  const {
    total,
    type,
    searchFilters,
    searchEntities,
    setSearchFilter,
    clearSearchFilters,
  } = props.value;
  const onTotalCountClick = useCallback(() => {
    clearSearchFilters();
    setSearchFilter("type:1", [{ label: type, value: type }]);
    Object.keys(searchFilters).forEach((key) => {
      if (key !== "type") {
        setSearchFilter(key, searchFilters[key]);
      }
    });
    searchEntities();
  }, [
    clearSearchFilters,
    setSearchFilter,
    searchEntities,
    type,
    searchFilters,
  ]);

  return (
    <Button
      variant="link"
      css={buttonStyle}
      onClick={() => onTotalCountClick()}
    >
      <Text>{convertCountToLocaleString(total)}</Text>
    </Button>
  );
};

TotalRenderer.propTypes = {
  value: PropTypes.shape({
    total: PropTypes.number,
    type: PropTypes.string,
    searchFilters: PropTypes.object,
    searchEntities: PropTypes.func,
    setSearchFilter: PropTypes.func,
    clearSearchFilters: PropTypes.func,
  }).isRequired,
};

const DataRenderer = (props) => {
  const {
    count,
    filterName,
    filterValue,
    total,
    type,
    searchFilters,
    searchEntities,
    setSearchFilter,
    clearSearchFilters,
  } = props.value;
  const countClick = useCallback(() => {
    clearSearchFilters();
    setSearchFilter("type:1", [{ label: type, value: type }]);
    Object.keys(searchFilters).forEach((key) => {
      if (key !== "type") {
        setSearchFilter(key, searchFilters[key]);
      }
    });
    setSearchFilter(filterName, [filterValue]);
    searchEntities();
  }, [
    clearSearchFilters,
    setSearchFilter,
    searchEntities,
    type,
    searchFilters,
    filterName,
    filterValue,
  ]);
  return (
    <div css={{ display: "flex" }}>
      <Button variant="link" css={buttonStyle} onClick={() => countClick()}>
        <div css={{ minWidth: "31px", textAlign: "right" }}>
          {convertCountToLocaleString(count)}
        </div>
      </Button>
      <PercentageBar value={count} total={total} />
    </div>
  );
};

DataRenderer.propTypes = {
  value: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
    filterName: PropTypes.string,
    filterValue: PropTypes.string,
    type: PropTypes.string,
    searchFilters: PropTypes.object,
    searchEntities: PropTypes.func,
    setSearchFilter: PropTypes.func,
    clearSearchFilters: PropTypes.func,
  }).isRequired,
};

export const getContainerTypeColumns = (
  t,
  containerTypeSearchFilters,
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
) => {
  return [
    {
      Header: t("container-tracking:Container Type"),
      id: "container_type",
      Cell: ContainerTypeRenderer,
      accessor: "container_type",
      width: 110,
    },
    {
      Header: t("container-tracking:Total"),
      id: "total",
      Cell: TotalRenderer,
      accessor: (obj) => {
        return {
          total: obj?.total ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:At Assembly"),
      id: "onsiteAtAssembly",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "lad",
          filterValue: "Assembly",
          total: obj?.total ?? 0,
          count: obj?.onsiteAtAssembly ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:At Supplier"),
      id: "onsiteAtSupplier",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "lad",
          filterValue: "Supplier",
          total: obj?.total ?? 0,
          count: obj?.onsiteAtSupplier ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:At ODC"),
      id: "onsiteAtODC",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "lad",
          filterValue: "Distribution",
          total: obj?.total ?? 0,
          count: obj?.onsiteAtODC ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:At Undefined"),
      id: "onsiteAtUndefined",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "lad",
          filterValue: "Undefined",
          total: obj?.total ?? 0,
          count: obj?.onsiteAtUndefined ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:In Transit"),
      id: "inTransit",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "status",
          filterValue: "In Transit",
          total: obj?.total ?? 0,
          count: obj?.inTransit ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:Unaccounted"),
      id: "unaccounted",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "activeExceptions",
          filterValue: "Unaccounted",
          total: obj?.total ?? 0,
          count: obj?.unaccounted ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:Excessive Dwell"),
      id: "excessiveDwell",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "activeExceptions",
          filterValue: "Excessive Dwell",
          total: obj?.total ?? 0,
          count: obj?.excessiveDwell ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:No Update 30+ Days"),
      id: "noUpdateThirtyDays",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "activeExceptions",
          filterValue: "No Update 30+ Days",
          total: obj?.total ?? 0,
          count: obj?.noUpdateThirtyDays ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:Off Route"),
      id: "offRoute",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "activeExceptions",
          filterValue: "Off Route",
          total: obj?.total ?? 0,
          count: obj?.offRoute ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
    {
      Header: t("container-tracking:Battery Level"),
      id: "batteryLevel",
      Cell: DataRenderer,
      accessor: (obj) => {
        return {
          filterName: "activeExceptions",
          filterValue: "Battery Level",
          total: obj?.total ?? 0,
          count: obj?.batteryLevel ?? 0,
          type: obj?.container_type,
          searchFilters: containerTypeSearchFilters,
          searchEntities,
          setSearchFilter,
          clearSearchFilters,
        };
      },
      width: 110,
      centerAligned: true,
    },
  ];
};
