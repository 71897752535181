import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";

const productTypesUrl = (solutionId, state) => {
  const locationdId = state.location.payload.location_id;
  return apiUrl(
    `/entity-search/solution/${solutionId}/list/${locationdId}?productType=1`,
  );
};

const destinationDealerUrl = (solutionId, state) => {
  const locationdId = state.location.payload.location_id;
  return apiUrl(
    `/entity-search/solution/${solutionId}/list/${locationdId}?destinationDealer=1`,
  );
};

const currentLocationUrl = (solutionId, state) => {
  const locationdId = state.location.payload.location_id;
  return apiUrl(
    `/entity-search/solution/${solutionId}/list/${locationdId}?currentLocation=1`,
  );
};

export const currentPositionCodesUrl = (solutionId) => {
  return apiUrl(
    `/entity-search/solution/${solutionId}/list?currentPositionCodes=1`,
  );
};

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "ivProductTypeFilter",
  getUrl: productTypesUrl,
  getResponseData: (data) => data?.productTypes ?? [],
  transformResult: (option) => ({ label: option, value: option }),
});

export const destinationDealerOptionsState = buildAsyncFilterOptionsState({
  topic: "ivDestinationDealerFilter",
  getUrl: destinationDealerUrl,
  getResponseData: (data) => data?.destinations ?? [],
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: option.code,
  }),
});

export const currentLocationOptionsState = buildAsyncFilterOptionsState({
  topic: "ivcurrentLocationFilter",
  getUrl: currentLocationUrl,
  getResponseData: (data) => data?.currentLocations ?? [],
  transformResult: (option) => ({ label: option.name, value: option.name }),
});

export const currentPositionsCodeOptionsState = buildAsyncFilterOptionsState({
  topic: "ivCurrentPositionCodeFilter",
  getUrl: currentPositionCodesUrl,
  getResponseData: (data) => data?.currentPositionCodes ?? [],
  transformResult: (result) => ({
    label: result.value,
    value: result.id,
  }),
});
