/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";
import SearchBarContainer from "../../location-search/LocationSearchBarContainer";

export const LocationsSearchBar = () => {
  return <SearchBarContainer isShowingAdvancedSearchButton={false} />;
};

LocationsSearchBar.propTypes = {};

export const AddLocationButton = ({ onAddNewLocation, className }) => {
  const { t } = useTranslation("locations");

  return (
    <Button
      variant="success"
      onClick={() => {
        onAddNewLocation();
      }}
      className={className}
    >
      <MdAdd /> {t("locations:Add New Location")}
    </Button>
  );
};

AddLocationButton.propTypes = {
  onAddNewLocation: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const ImportLocationsButton = ({ onImportLocations, className }) => {
  const { t } = useTranslation("locations");

  return (
    <Button
      variant="success"
      onClick={() => {
        onImportLocations();
      }}
      className={className}
    >
      {t("locations:Import Locations")}
    </Button>
  );
};

ImportLocationsButton.propTypes = {
  onImportLocations: PropTypes.func.isRequired,
  className: PropTypes.string,
};
