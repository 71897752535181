import apiUrl from "api-url";
import { buildEntityHistoricalSlice } from "shared/redux/EntityHistoricalSlice";

const STORE_MOUNT_POINT = "vinViewEntityHistory";

const getHistoryUrl = (solutionId, entityId, qualifier, dealerOrgId) => {
  let url = `/entity/internal/${entityId}/reference-history?qualifier=${qualifier}&`;
  url += dealerOrgId ? `dealerOrgId=${dealerOrgId}&` : "";
  return apiUrl(url);
};

const VinViewEntityHistoricalState = buildEntityHistoricalSlice(
  STORE_MOUNT_POINT,
  getHistoryUrl,
);
export default VinViewEntityHistoricalState;
