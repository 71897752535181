import React from "react";
import styled from "@emotion/styled";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "../../utils/date-time";
import { convertLineBreaksToHtml } from "../../utils/html-utils";

const SmallerTextDiv = styled.div({
  fontSize: "smaller",
});

const BoldSmallerTextDiv = styled(SmallerTextDiv)({
  fontWeight: "bold",
});

export const getArrivalWindowText = (loc) => {
  const earliestArrivalDatetime = loc?.earliest_arrival_datetime ?? null;
  const latestArrivalDatetime = loc?.latest_arrival_datetime ?? null;
  const isDateOnly = loc?.isDateOnly ?? false;

  const emptyText = { windowTextLine1: "N/A", windowTextLine2: null };

  if (!earliestArrivalDatetime && !latestArrivalDatetime) {
    return emptyText;
  }

  const earliestTime = localizedTimeFormatter(earliestArrivalDatetime);
  const earliestDate = localizedDateFormatter(earliestArrivalDatetime);
  const latestTime = localizedTimeFormatter(latestArrivalDatetime);
  const latestDate = localizedDateFormatter(latestArrivalDatetime);

  const latestText = !isDateOnly
    ? { windowTextLine1: latestTime, windowTextLine2: latestDate }
    : { windowTextLine1: latestDate, windowTextLine2: null };

  const earliestText = !isDateOnly
    ? { windowTextLine1: earliestTime, windowTextLine2: earliestDate }
    : { windowTextLine1: earliestDate, windowTextLine2: null };

  const rangeText = !isDateOnly
    ? {
        windowTextLine1: [earliestTime, earliestDate, "to"].join("\n"),
        windowTextLine2: [latestTime, latestDate].join("\n"),
      }
    : {
        windowTextLine1: [earliestDate, "to"].join("\n"),
        windowTextLine2: latestDate,
      };

  if (earliestArrivalDatetime === latestArrivalDatetime) {
    return latestText;
  }

  if (earliestArrivalDatetime && latestArrivalDatetime) {
    return rangeText;
  }

  if (latestArrivalDatetime) {
    return latestText;
  }

  if (earliestArrivalDatetime) {
    return earliestText;
  }

  console.error("shouldn't get here!");
  return emptyText;
};

/* H1-1349 Scheduled Delivery has different propeties, added function to extract those for display */
export const getDeliveryWindowText = (loc) => {
  const empty = { windowTextLine1: "N/A", windowTextLine2: null };
  if (
    !loc ||
    !(
      loc.delivery_earliest_arrival_datetime ||
      loc.delivery_latest_arrival_datetime
    )
  ) {
    return empty;
  }

  const latestText = {
    windowTextLine1: localizedTimeFormatter(
      loc.delivery_earliest_arrival_datetime,
    ),
    windowTextLine2: localizedDateFormatter(
      loc.delivery_latest_arrival_datetime,
    ),
  };

  const earliestText = {
    windowTextLine1: localizedTimeFormatter(
      loc.delivery_earliest_arrival_datetime,
    ),
    windowTextLine2: localizedDateFormatter(
      loc.delivery_latest_arrival_datetime,
    ),
  };

  if (
    loc.delivery_earliest_arrival_datetime &&
    loc.delivery_latest_arrival_datetime
  ) {
    // often they are the same
    if (
      loc.delivery_earliest_arrival_datetime ===
      loc.delivery_latest_arrival_datetime
    ) {
      return latestText;
    }

    return {
      windowTextLine1: `${localizedTimeFormatter(
        loc.delivery_earliest_arrival_datetime,
      )}\n${localizedDateFormatter(
        loc.delivery_earliest_arrival_datetime,
      )}\nto`,
      windowTextLine2: `${localizedTimeFormatter(
        loc.delivery_latest_arrival_datetime,
      )}\n${localizedDateFormatter(loc.delivery_latest_arrival_datetime)}`,
    };
  }

  if (loc.delivery_earliest_arrival_datetime) {
    return latestText;
  }
  if (loc.delivery_latest_arrival_datetime) {
    return earliestText;
  }
  console.error("shouldn't get here!");
  return empty;
};

export const ScheduledArrivalWindow = ({ loc }) => {
  const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(loc);
  return (
    <React.Fragment>
      <BoldSmallerTextDiv>
        {convertLineBreaksToHtml(windowTextLine1)}
      </BoldSmallerTextDiv>
      <BoldSmallerTextDiv>
        {convertLineBreaksToHtml(windowTextLine2)}
      </BoldSmallerTextDiv>
    </React.Fragment>
  );
};
