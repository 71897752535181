import _ from "lodash";

import apiUrl from "api-url";
import { SEARCH_CATEGORIES } from "./LocationSearchCategoryDefs";
import { FILTERS } from "./LocationSearchFilterDefs";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "fvLocationsSearch";

// Helpers
const locationsUrl = (_ignored_solutionId, queryString, state) => {
  const hasFilter = SearchBarState.selectors.getAreThereFiltersSelected(state);
  const searchText = SearchBarState.selectors.getSearchText(state);

  // On initial load, we only load parent locations. This is for performance considerations.
  // Then if the user searches using Search Everything or any of the filters,
  // the query will return both parent and child locations.
  if (searchText || hasFilter) {
    queryString += "&includeChildLocations=true";
  }

  return apiUrl(`/location/locations?verbose=false&${queryString}`);
};

// Actions

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
) => {
  const url = locationsUrl(null, queryString, state);
  dispatch(duck.fetch(url));
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "name",
  },
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    locationsUrl,
    null,
    { headers: { Accept: "text/csv" } },
    "location-search-results",
  ),
};

export default SearchBarState;
