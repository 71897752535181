import { BrowserStorage } from "utils/browser-storage.utils";
import { DEFAULT_GEOCODING } from "./utils/geocoding";
import HereMapPlatform from "./platforms/here/HereMapPlatform";

const HEREMAP_API_KEY = "CBej7Q8rRs-7aIzlE2CXA1MNI_lNpM5TWU3_uQDNoyg";
const STORE_MOUNT_POINT = "maps";

const DEFAULT_CENTER = { lat: 41.8625, lng: -87.6166 };

// Actions

const getScopedActionName = (name) => `${STORE_MOUNT_POINT}/${name}`;
const INIT_HERE = getScopedActionName("INIT_HERE");
const SET_MAP_PLATFORM = getScopedActionName("SET_MAP_PLATFORM");
const SET_MAP_TYPE_OVERRIDE = getScopedActionName("SET_MAP_TYPE_OVERRIDE");
const SET_DEFAULT_CENTER = getScopedActionName("SET_DEFAULT_CENTER");

const ADD_COORDINATE = getScopedActionName("ADD_COORDINATE");
const CLEAR_COORDINATES_BY_TYPE = getScopedActionName(
  "CLEAR_COORDINATES_BY_TYPE",
);
const CLEAR_ALL_COORDINATES = getScopedActionName("CLEAR_ALL_COORDINATES");
const SET_VIEW_CENTERED = getScopedActionName("SET_VIEW_CENTERED");
const SET_SHOULD_CAPTURE_SCREENSHOT = getScopedActionName(
  "SET_SHOULD_CAPTURE_SCREENSHOT",
);
const SET_CAPTURED_SCREENSHOT = getScopedActionName("SET_CAPTURED_SCREENSHOT");

// Action creators
function initHere(H, google, mapsindoors) {
  let platform = new H.service.Platform({
    apikey: HEREMAP_API_KEY,
    useCIT: false,
    useHTTPS: true,
  });

  return {
    type: INIT_HERE,
    hereMaps: H,
    hereMapsPlatform: platform,
    googleMaps: google ? google.maps : null,
    mapsIndoors: mapsindoors,
  };
}

const setMapPlatform = (mapPlatform) => {
  return (dispatch) =>
    dispatch({
      type: SET_MAP_PLATFORM,
      mapPlatform,
    });
};

const setMapTypeOverride = (mapType) => {
  BrowserStorage.mapTypeOverride = mapType;
  return {
    type: SET_MAP_TYPE_OVERRIDE,
    mapType: mapType,
  };
};

const setDefaultCenter = (center = {}) => {
  const lat = center?.lat ?? DEFAULT_CENTER.lat;
  const lng = center?.lng ?? DEFAULT_CENTER.lng;
  return {
    type: SET_DEFAULT_CENTER,
    center: { lat, lng },
  };
};

const addCoordinate = (
  coordinateType,
  lat,
  long,
  time,
  ind,
  data = DEFAULT_GEOCODING,
  floor = null,
  radius = null,
) => {
  return (dispatch) =>
    dispatch({
      type: ADD_COORDINATE,
      data: { coordinateType, lat, long, time, ind, data, floor, radius },
    });
};

const clearCoordinatesByType = (coordinateTypes) => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_COORDINATES_BY_TYPE,
      data: { coordinateTypes },
    });
};

const clearAllCoordinates = () => {
  return (dispatch) => dispatch({ type: CLEAR_ALL_COORDINATES });
};

const setViewCentered = (isCentered) => {
  return (dispatch) =>
    dispatch({
      type: SET_VIEW_CENTERED,
      isCentered,
    });
};

const setShouldCaptureScreenshot = (shouldCaptureScreenshot) => {
  return (dispatch) =>
    dispatch({
      type: SET_SHOULD_CAPTURE_SCREENSHOT,
      shouldCaptureScreenshot,
    });
};

const setCapturedScreenshot = (capturedScreenshot) => {
  return (dispatch) =>
    dispatch({
      type: SET_CAPTURED_SCREENSHOT,
      capturedScreenshot,
    });
};

// selectors
const getMapPlatform = (state) =>
  state[STORE_MOUNT_POINT].mapPlatform ?? HereMapPlatform.name;
const getMapTypeOverride = (state) => state[STORE_MOUNT_POINT].mapTypeOverride;
const getDefaultCenter = (state) => state[STORE_MOUNT_POINT].defaultCenter;

const getMapCoordinate = (type) => (state) =>
  state[STORE_MOUNT_POINT].coordinates.find(
    (coordinate) => coordinate.coordinateType === type,
  );

const getIsViewCentered = (state) => state[STORE_MOUNT_POINT].isViewCentered;
const getShouldCaptureScreenshot = (state) =>
  state[STORE_MOUNT_POINT].shouldCaptureScreenshot;
const getCapturedScreenshot = (state) =>
  state[STORE_MOUNT_POINT].capturedScreenshot;
const getCoordiantes = (state) => state[STORE_MOUNT_POINT].coordinates;
export const getGoogleMaps = (state) => state[STORE_MOUNT_POINT].googleMaps;

// Reducer

export const initialState = {
  hereMaps: {},
  hereMapsPlatform: {},
  googleMaps: null,
  mapsIndoors: null,
  mapPlatform: HereMapPlatform.name,
  mapTypeOverride: BrowserStorage.mapTypeOverride ?? null,
  defaultCenter: DEFAULT_CENTER,
  coordinates: [],
  isViewCentered: true,
  shouldCaptureScreenshot: false,
  capturedScreenshot: null,
};

const MapReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT_HERE:
      return Object.assign({}, state, {
        hereMaps: action.hereMaps,
        hereMapsPlatform: action.hereMapsPlatform,
        googleMaps: action.googleMaps,
        mapsIndoors: action.mapsIndoors,
        coordinates: [],
      });

    case SET_MAP_PLATFORM:
      return {
        ...state,
        mapPlatform: action.mapPlatform,
        coordinates: [],
      };

    case SET_MAP_TYPE_OVERRIDE:
      return {
        ...state,
        mapTypeOverride: action.mapType,
      };

    case SET_DEFAULT_CENTER:
      return {
        ...state,
        defaultCenter: action.center,
      };

    case ADD_COORDINATE:
      const { coordinateType } = action.data;
      const filteredCoordinates = state.coordinates.filter(
        (coordinate) => coordinate.coordinateType !== coordinateType,
      );
      return {
        ...state,
        coordinates: [...filteredCoordinates, action.data],
      };

    case CLEAR_COORDINATES_BY_TYPE:
      const { coordinateTypes } = action.data;
      const remainingCoordinates = state.coordinates.filter(
        (coordinate) => !coordinateTypes.includes(coordinate.coordinateType),
      );
      return {
        ...state,
        coordinates: [...remainingCoordinates],
      };

    case CLEAR_ALL_COORDINATES:
      return {
        ...state,
        coordinates: [],
      };

    case SET_VIEW_CENTERED:
      return {
        ...state,
        isViewCentered: action.isCentered,
      };

    case SET_SHOULD_CAPTURE_SCREENSHOT:
      return {
        ...state,
        shouldCaptureScreenshot: action.shouldCaptureScreenshot,
      };

    case SET_CAPTURED_SCREENSHOT:
      return {
        ...state,
        capturedScreenshot: action.capturedScreenshot,
      };

    default:
      return state;
  }
};

// interface

const MapState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    initHere,
    setMapPlatform,
    setMapTypeOverride,
    setDefaultCenter,
    addCoordinate,
    clearCoordinatesByType,
    clearAllCoordinates,
    setViewCentered,
    setShouldCaptureScreenshot,
    setCapturedScreenshot,
  },
  selectors: {
    getMapPlatform,
    getMapTypeOverride,
    getDefaultCenter,
    getMapCoordinate,
    getIsViewCentered,
    getShouldCaptureScreenshot,
    getCapturedScreenshot,
    getCoordiantes,
  },
  reducer: MapReducer,
};

export default MapState;
