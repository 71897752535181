import moment from "moment-timezone";
import _ from "lodash";
import {
  getBasicQueryString,
  getBasicQueryStringFilterValuePriority,
  getMultiSelectQueryString,
} from "../../components/search-bar/search-filter-query-strings";
import {
  AsyncSelectFilterButton,
  DateRangeFilterButton,
} from "../../components/search-bar/FilterButtons";
import { OrganizationType } from "shared/constants/organization.const";
import { milestoneDashboardLocationsOptionsState } from "./MilestoneEventFilterLoaders";

/**
 *
 * @param {*} queryKey_IGNORED
 * @param {{ from: string, to: string }} timeRange
 *
 * @returns {string} a query string of the form `&milestone_start_time=___&milestone_end_time=___`
 */
const getTimestampRangeQueryString = (queryKey_IGNORED, timeRange) => {
  let qs = "";

  if (_.isNil(timeRange)) {
    return qs;
  }

  if (!_.isNil(timeRange.from)) {
    qs += `&milestoneStartTime=${moment(timeRange.from).unix()}`;
  }

  if (!_.isNil(timeRange.to)) {
    qs += `&milestoneEndTime=${moment(timeRange.to).unix()}`;
  }

  return qs;
};

export const FILTERS = [
  {
    queryKey: "locationsCodes",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("milestone-search:Location"),
    optionsState: milestoneDashboardLocationsOptionsState,
    queryBuilder: (queryParameters, filterValue) =>
      getMultiSelectQueryString(queryParameters, filterValue),
    hideFuzzySearch: true,
  },
  {
    queryKey: "milestoneEvents",
    label: (t) => t("milestone-search:Milestone Event"),
    optionsGetter: (props) => props.eventsFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority,
    hideFuzzySearch: true,
  },
  {
    queryKey: "milestoneCodes",
    label: (t) => t("milestone-search:Milestone Status Code"),
    optionsGetter: (props) => props.statusCodesFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority,
    hideFuzzySearch: true,
  },
  {
    queryKey: "unused", // queryBuilder func will supply the query key(s)
    label: (t) => t("milestone-search:Milestone Event Times"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    dateTypeOptions: () => [],
    queryBuilder: getTimestampRangeQueryString,
  },
  {
    queryKey: "customerIds",
    label: (t) => t("milestone-search:Shipper"),
    optionsGetter: (props) => props.shippersFilterOptions,
    queryBuilder: getBasicQueryString,
    requiredOrgTypes: [OrganizationType.PARTNER, OrganizationType.CARRIER],
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
  },
];
