/** @jsxImportSource @emotion/react */
import { AiFillFire } from "react-icons/ai";
import PropTypes from "prop-types";

export const HeatMapButton = ({
  className,
  isLoading,
  isToggled,
  onToggle = () => {},
}) => {
  return (
    <div
      className={className}
      css={{
        transition: "color 333ms",
        color: isToggled ? "darkorange" : "black",
        ":hover": {
          cursor: "pointer",
          color: "darkorange",
        },
      }}
      onClick={!isLoading ? onToggle : null}
    >
      {/* TODO: Use FontAwesome Icon */}
      <AiFillFire size="40px" />
    </div>
  );
};

HeatMapButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isToggled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
