import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { FontSize, Text } from "components/atoms/Text.atom";
/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useMemo } from "react";

import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import ExportModal from "modules/exports/ExportModal";
import Loader from "react-loader";
import PropTypes from "prop-types";
import ShipmentEtaValidatorSearchBarContainer from "./ShipmentEtaValidator.SearchBar.container";
import { isExcludedRow } from "./ShipmentEtaTrigger.utils";
import { useColumns } from "./ShipmentEtaValidator.columns";
import { useSetTitle } from "components/hooks/useSetTitle";
import { useTranslation } from "react-i18next";

export const ShipmentEtaValidatorPage = ({
  totalCount = 0,
  data = [],
  isLoading = false,
  resetSearchAndFilters,
  exportEntities,
  isExporting,
  exportIdentifier,
  exportName,
  resetExport,
}) => {
  const { t } = useTranslation("shipment-eta-validator");

  useSetTitle("Shipment ETA Validator");
  const columns = useColumns();

  let hideSearchResults = false;

  // Reset search page on leave.
  // Doing this on "dismount" so we don't run into waiting for data to clear
  // when switching orgs or coming back to the page with browser nav.
  useEffect(() => {
    // The return of a useEffect is like a "cleanup" method.
    // In the case of an effect on mount, this acts like a "on dismount".
    return () => resetSearchAndFilters();
  }, []);

  const filteredData = useMemo(
    () => data.filter((etaTrigger) => !isExcludedRow(etaTrigger)),
    [data],
  );

  return (
    // TODO: Error messages.
    <div css={{ padding: "1em", width: "100%", position: "relative" }}>
      <ShipmentEtaValidatorSearchBarContainer
        isShowingFilters={false}
        isShowingAdvancedSearchButton={false}
        adminPlaceholder={t(
          "shipment-search:Search Creator Shipment ID or FV Shipment ID",
        )}
        showSearchOptions={false}
        typeaheadOptionsMetadata={[]}
      />

      {/* Alert when VIN has not been selected */}
      {hideSearchResults ? (
        <Alert variant={AlertVariant.Warning} show style={{ margin: "1em 0" }}>
          {t("shipment-eta-validator:Select a VIN to continue.")}
        </Alert>
      ) : null}
      {!hideSearchResults ? (
        <Fragment>
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              bold
              size={FontSize.size16}
              css={{
                position: "relative",
                ".loadedContent": { display: "inline" },
              }}
            >
              <Loader loaded={!isLoading} scale={0.35} left="0.5em">
                {totalCount?.toLocaleString()}
              </Loader>{" "}
              <span css={{ marginLeft: isLoading ? "1.2em" : 0 }}>
                {t(
                  "shipment-eta-validator:Total Triggers - Shipment IDs for Rail Only",
                )}
              </span>
            </Text>
            <DownloadDataButton
              onClick={() => exportEntities()}
              isDisabled={data.length === 0}
              isExporting={isExporting}
            />
          </div>
          <ExportModal
            exportIdentifier={exportIdentifier}
            exportName={exportName}
            resetExport={resetExport}
          />
          <BaseTable
            columns={columns}
            data={filteredData}
            isLoading={isLoading}
            showPagination={true}
            fixPaginationToBottom={true}
            defaultSortColumn="calcTime"
            defaultReverseSort={true}
            showFilters={true}
          />
        </Fragment>
      ) : null}
    </div>
  );
};

ShipmentEtaValidatorPage.propTypes = {
  hasSearchCriteriaChanged: PropTypes.bool,
  areAllPrerequisiteFiltersSelected: PropTypes.bool,
  totalCount: PropTypes.number,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  totalPages: PropTypes.number,
  selectedVin: PropTypes.object,
  searchEntities: PropTypes.func.isRequired,
  resetSearchAndFilters: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  isExporting: PropTypes.bool,
};
