/** @jsxImportSource @emotion/react */
import { MouseEventHandler } from "react";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { FontSize } from "components/atoms/enums";

export const AddListItemButton = ({
  text,
  "data-qa": dataQa,
  onClick,
}: AddListItemButtonProps) => {
  return (
    <Button
      style={{
        color: Colors.text.BLACK,
        backgroundColor: Colors.background.WHITE,
        borderColor: Colors.border.BOTTOM_AXIS_BORDER,
        borderRadius: "4px",
        marginTop: "1em",
        width: "100%",
      }}
      onClick={onClick}
      data-qa={dataQa}
    >
      <div css={{ display: "inline-flex", alignItems: "center" }}>
        <Text align="center">{text}</Text>
        <Icon
          css={{ marginLeft: "0.4em" }}
          src={faPlusCircle}
          color={Colors.text.GREEN}
          size={FontSize.size18}
        />
      </div>
    </Button>
  );
};

export type AddListItemButtonProps = {
  /**
   * The text that appears next to the plus icon.
   */
  text: string;
  /**
   * The event fired when the user clicks the button.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * A data qa attribute that allows QA automation team to easily locate the element
   */
  "data-qa"?: string;
};
