import { combineReducers } from "redux";
import { useSelector } from "react-redux";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { isArray } from "util";
import moment from "moment";

// Redux mount point
const STORE_MOUNT_POINT = "inventoryViewDetails";

// Fetch ducks
const locationDuck = buildFetchDuck("location");
const forecastDuck = buildFetchDuck("forecast");

// URLs
const BASE_LOCATION_URL = apiUrl("/entity/solution");

// Action creators
const SET_SELECTED_TAB_INDEX = `${STORE_MOUNT_POINT}/SET_SELECTED_TAB_INDEX`;
const SET_SELECTED_DATE_RANGE = `${STORE_MOUNT_POINT}/SET_SELECTED_DATE_RANGE`;

const pushAdcDetailsView = (locationId) => (dispatch) =>
  dispatch({
    type: "INVENTORY_VIEW_DETAILS",
    payload: { location_id: locationId },
  });

function fetchLocation(solutionId, locationId) {
  const qs = "?lifeCycleState=Active";

  return (dispatch) => {
    dispatch(locationDuck.clear());
    dispatch(
      locationDuck.fetch(
        `${BASE_LOCATION_URL}/${solutionId}/entity-location/${locationId}${qs}`,
      ),
    );
  };
}

function fetchForecastData(solutionId, locationId) {
  return (dispatch, getState) => {
    const state = getState();
    const dateRange = getForcastDateRange(state);
    const qs = `?startDate=${moment(dateRange.startDate).format(
      "YYYY-MM-DD HH:mm:ss",
    )}&endDate=${moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")}`;
    dispatch(forecastDuck.clear());
    dispatch(
      forecastDuck.fetch(
        `${BASE_LOCATION_URL}/${solutionId}/forecasted-capacity/${locationId}${qs}`,
      ),
    );
  };
}

const setSelectedTabIndex = (tabIndex) => {
  return {
    type: SET_SELECTED_TAB_INDEX,
    payload: { tabIndex },
  };
};

const setForecastDateRange = (startDate, endDate) => {
  return {
    type: SET_SELECTED_DATE_RANGE,
    payload: { startDate, endDate },
  };
};

// Selectors
const getLocation = (state) => {
  const location = state[STORE_MOUNT_POINT].location.data;
  return isArray(location) ? null : location;
};

const getSelectedTabIndex = (state) => {
  return state[STORE_MOUNT_POINT].inventoryviewDetails.selectedTabIndex;
};

const getForcastDateRange = (state) => {
  return state[STORE_MOUNT_POINT].inventoryviewDetails.dateRange;
};

const getForecastData = (state) => {
  return state[STORE_MOUNT_POINT].forecast.data?.data ?? [];
};

const getIsForecastDataLoading = (state) => {
  return state[STORE_MOUNT_POINT].forecast.isLoading ?? false;
};

// Reducers
const inventoryViewDetailsReducer = (
  state = {
    selectedTabIndex: 0,
    dateRange: {
      startDate: moment().add(1, "days").startOf("day").toDate(),
      endDate: moment().add(7, "days").startOf("day").toDate(),
    },
  },
  action,
) => {
  switch (action.type) {
    case SET_SELECTED_TAB_INDEX:
      return {
        ...state,
        selectedTabIndex: action.payload.tabIndex,
      };
    case SET_SELECTED_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    pushAdcDetailsView,
    fetchLocation,
    setSelectedTabIndex,
    fetchForecastData,
    setForecastDateRange,
  },
  selectors: {
    getLocation,
    getSelectedTabIndex,
    getForcastDateRange,
    getForecastData,
    getIsForecastDataLoading,
  },
  reducer: combineReducers({
    location: locationDuck.reducer,
    inventoryviewDetails: inventoryViewDetailsReducer,
    forecast: forecastDuck.reducer,
  }),
};
