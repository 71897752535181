import _ from "lodash";
/**
 * Filter for fields that just check for equality
 */
export const equalsFilter = (item, key, filterValues) => {
  if (!Array.isArray(filterValues)) {
    filterValues = [filterValues];
  }

  for (const filterValue of filterValues) {
    if (String(filterValue) === String(item[key])) {
      return true;
    }
  }
  return false;
};

/**
 * Receives a key and return a filter
 */
export const stringIncludesFilterBuilder = (key) => {
  return (item, filterValue) => {
    return String(item[key])
      .toLowerCase()
      .includes(String(filterValue).toLowerCase());
  };
};

export const getAuthorizedFilters = (filters, auth) => {
  const allowedFilters = [];

  filters.forEach((item) => {
    const needsFeatureCheck = item.requiredFeatures?.length > 0;
    const needsOrgCheck = item.requiredOrgTypes?.length > 0;
    const needsEntitySystemConfigOptionsCheck =
      item.requiredEntitySystemConfig?.length > 0;
    const needsOrganizationFiltersCheck = !_.isNil(item.requiredOrgFilters);
    const needPrivilegeCheck = item.requiredPrivileges?.length > 0;

    let hasRequiredFeatures = true;
    let hasRequiredOrgs = true;
    let hasRequiredEntitySystemConfigOptions = true;
    let hasOrganizationFilters = true;
    let hasRequiredPrivilege = true;

    if (needsFeatureCheck) {
      // Check that it has the required features
      // `requiredFeatures` is already an array so we can pass it
      // directly to `auth.hasFeatures`
      hasRequiredFeatures = auth.hasFeatures(item.requiredFeatures);
    }

    if (needsOrgCheck) {
      // Check that it has the required org types
      // `requiredOrgTypes` is already an array so we can pass it
      // directly to `auth.hasOrganizationTypes`
      hasRequiredOrgs = auth.hasOrganizationTypes(item.requiredOrgTypes);
    }

    if (needsEntitySystemConfigOptionsCheck) {
      // Check that it has the required entity system config options
      // `requiredEntitySystemConfig` is already an array so we can pass it
      // directly to `auth.validateEntitySystemConfigValue`
      hasRequiredEntitySystemConfigOptions =
        auth.validateEntitySystemConfigValue(item.requiredEntitySystemConfig);
    }

    if (needsOrganizationFiltersCheck) {
      // Check that it has the required org types
      // `requiredOrgFilters` is already an string so we can pass it
      // directly to `auth.validateOrganizationFilters`
      hasOrganizationFilters = auth.validateOrganizationFilters(
        item.requiredOrgFilters,
      );
    }

    if (needPrivilegeCheck) {
      // Check that it has the required privilege types
      // `requiredPrivileges` is already an array so we can pass it
      // directly to `auth.hasPrivileges`
      hasRequiredPrivilege = auth.hasPrivileges(item.requiredPrivileges);
    }

    // Push the filter to the array
    if (
      hasRequiredFeatures &&
      hasRequiredOrgs &&
      hasRequiredEntitySystemConfigOptions &&
      hasOrganizationFilters &&
      hasRequiredPrivilege
    ) {
      allowedFilters.push(item);
    }
  });

  return allowedFilters;
};
