/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { Component } from "react";
import Loader from "react-loader";
import sizeMe from "react-sizeme";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

import ReportsComponent from "./components/ReportsComponent";
import MixpanelUtils from "trackers/mixpanel";
import { Privileges } from "modules/auth/Authorization";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Text } from "components/atoms/Text.atom";

const Section = styled.section({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const HeaderSection = styled.section({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "white",
});

class ReportDetail extends Component {
  static propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSubTitle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchReport: PropTypes.func.isRequired,
    fetchFilteredReport: PropTypes.func.isRequired,
    selectedReportId: PropTypes.string.isRequired,
    selectedFilterSetId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isLoading: PropTypes.bool.isRequired,
    report: PropTypes.object,
    saveReport: PropTypes.func.isRequired,
    saveFilteredReport: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    updateReport: PropTypes.func.isRequired,
    updateFilteredReport: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    deleteReport: PropTypes.func.isRequired,
    deleteFilteredReport: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    pushBIDashboardScreen: PropTypes.func.isRequired,
    authorization: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.report = null;

    this.state = {
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const { t, setTitle } = this.props;

    setTitle(t("reports:Report"));

    MixpanelUtils.track("Viewed Page: BI / Reports / Details");

    const {
      selectedReportId,
      selectedFilterSetId,
      fetchReport,
      fetchFilteredReport,
    } = this.props;

    if (selectedFilterSetId) {
      fetchFilteredReport(selectedReportId, selectedFilterSetId);
    } else {
      fetchReport(selectedReportId);
    }
  }

  componentDidUpdate(prevProps) {
    const { setSubTitle, report } = this.props;

    if (report && prevProps.report !== report) {
      if (report.filterSet && report.filterSet.name) {
        setSubTitle(report.filterSet.name);
      } else {
        setSubTitle(report.name);
      }
    }
  }

  setReport = (report) => {
    this.report = report;
  };

  saveReport = (data) => {
    const { saveReport, saveFilteredReport, pushBIDashboardScreen } =
      this.props;

    // Figure out whether this is a private report or not based on the location value
    if (data.location === "my") {
      data.private = true;
    } else if (data.location === "shared") {
      data.private = false;
    } else {
      console.error("Invalid location specified: ", data.location);
    }

    // No longer need location value
    delete data.location;

    if (data.filter_set) {
      saveFilteredReport(data.report_id, data).then(() => {
        // Redirect to BI Dashboard after saving report
        pushBIDashboardScreen();
      });
    } else {
      saveReport(data).then(() => {
        // Redirect to BI Dashboard after saving report
        pushBIDashboardScreen();
      });
    }
  };

  updateReport = (data) => {
    const { updateReport, updateFilteredReport } = this.props;

    // Get the reportId
    let reportId = data.report_number;
    let filterSetId = data.filter_set?.filter_set_id ?? null;

    // And remove it from payload
    delete data.report_number;

    if (filterSetId) {
      updateFilteredReport(reportId, filterSetId, data);
    } else {
      updateReport(reportId, data);
    }
  };

  onPrint = () => {
    if (this.report) {
      this.report.print();
    }
  };

  onDelete = (t) => {
    this.setState({ showConfirmationDialog: true });
  };

  render() {
    const {
      t,
      isLoading,
      isDeleting,
      authorization,
      report,
      deleteReport,
      deleteFilteredReport,
      pushBIDashboardScreen,
    } = this.props;
    const canDeleteReport =
      authorization.hasPrivileges([Privileges.BUILD_REPORT]) &&
      report &&
      report.groupName === "Saved";

    let reportName = report?.filterSet?.name ?? report?.name ?? "";

    const lastUpdated = report?.filterSet?.createdOn || report?.lastUpdated;

    return (
      <React.Fragment>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            height: "100%",
          }}
        >
          <Loader loaded={!isLoading}>
            <HeaderSection>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  css={{
                    height: "1.5em",
                    width: "1.5em",
                    margin: "4px",
                    marginLeft: "1em",
                    ":hover": {
                      cursor: "pointer",
                      color: "rgba(0,0,0,0.5)",
                    },
                  }}
                  icon={faPrint}
                  alt={t("reports:Print")}
                  onClick={this.onPrint}
                />
                {canDeleteReport === true && (
                  <div
                    css={{
                      position: "relative",
                      margin: "4px",
                      marginLeft: "0.5em",
                      "> .loadedContent": { display: "flex" },
                    }}
                  >
                    <Loader loaded={!isDeleting} scale={0.33}>
                      <FontAwesomeIcon
                        css={{
                          height: "1.5em",
                          width: "1.5em",
                          ":hover": {
                            cursor: "pointer",
                            color: "rgba(0,0,0,0.5)",
                          },
                        }}
                        icon={faTrashAlt}
                        alt={t("reports:Delete Report")}
                        onClick={() => {
                          return this.onDelete(t);
                        }}
                      />
                    </Loader>
                  </div>
                )}
              </div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1em",
                }}
              >
                {lastUpdated && (
                  <span style={{ marginLeft: 5 }}>
                    {t("reports:Created on [[[date]]] [[[time]]]", {
                      date: localizedDateFormatter(lastUpdated),
                      time: localizedTimeFormatter(lastUpdated),
                    })}
                  </span>
                )}
              </div>
            </HeaderSection>
            <Section>
              <ReportsComponent
                {...this.props}
                setReport={this.setReport}
                saveReport={this.saveReport}
                updateReport={this.updateReport}
              />
            </Section>
          </Loader>
        </div>
        <DialogModal
          show={this.state.showConfirmationDialog}
          onHide={() => {
            this.setState({ showConfirmationDialog: false });
          }}
          title={t("reports:Confirm Report Deletion")}
          cancelButtonText={t("reports:Cancel")}
          submitButtonText={t("reports:Delete Report")}
          submitButtonVariant="danger"
          onSubmit={() => {
            if (report.filterSet) {
              deleteFilteredReport(
                report.reportId,
                report.filterSet.filterSetId,
              ).then(() => {
                // Redirect to BI Dashboard after deleting report
                pushBIDashboardScreen();
              });
            } else {
              deleteReport(report.reportId).then(() => {
                // Redirect to BI Dashboard after deleting report
                pushBIDashboardScreen();
              });
            }
          }}
        >
          <Text>
            {t("reports:Are you sure you want to delete this report?")}
          </Text>
          <Text block bold style={{ marginTop: 10 }}>
            {reportName}
          </Text>
        </DialogModal>
      </React.Fragment>
    );
  }
}

const withT = withTranslation(["reports"])(ReportDetail);
const sizeMeHOC = sizeMe({ monitorHeight: true })(withT);
export default sizeMeHOC;
