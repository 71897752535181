/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { FormControl } from "react-bootstrap";

import FormCell from "../FormCell";
import Colors from "styles/colors";

const NumberInput = ({ label, value, placeholder, onChange, isReadOnly }) => {
  return (
    <FormCell label={label}>
      <FormControl
        style={{
          backgroundColor: isReadOnly ? Colors.background.LIGHT_GRAY : "white",
          height: "3em",
          boxShadow: "none",
          borderRadius: "5px",
          borderWidth: 2,
        }}
        type="number"
        // Default to "" if we don't have a value, so that it stays a controlled component
        // Undefined values will make it an uncontrolled component.
        value={_.isNil(value) ? "" : value}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        readOnly={isReadOnly}
      />
    </FormCell>
  );
};

NumberInput.propTypes = {
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

export default NumberInput;
