import { useTranslation } from "react-i18next";

export const useNotAllSuccessfulColumns = () => {
  const { t } = useTranslation("driveaway-search");

  return [
    { Header: t("driveaway-search:VIN"), accessor: "vin" },
    {
      Header: t("driveaway-search:Reason"),
      accessor: "reason",
    },
  ];
};
