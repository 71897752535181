/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import debouncePromise from "debounce-promise";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";

import apiUrl from "api-url";
import { OrganizationType } from "shared/constants/organization.const";
import Colors from "styles/colors";

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      cursor: "pointer",
      color: isDisabled
        ? Colors.text.DISABLED
        : isSelected
        ? "#333"
        : isFocused
        ? "#333"
        : "#6d6d6d",
      backgroundColor: isDisabled
        ? Colors.background.LIGHT_GRAY
        : isSelected
        ? "#EBF3FF"
        : isFocused
        ? "#DEEBFF"
        : "#fff",
      ":active": {
        ...provided[":active"],
        backgroundColor: isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        ":last-of-type": {
          borderRadius: "0 0 6px 6px",
        },
      },
    };
  },
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const transformResult = (orgs) =>
  orgs.map((org) => ({
    label: org.org_name,
    value: org.organization_id,
  }));

async function fetchShipperOrganizations(query, loadedOptions, { page }) {
  const params = {
    orgType: OrganizationType.SHIPPER,
    allOrgs: true,
    paginate: true,
    pageNumber: page,
    pageSize: 20,
  };

  if (query) {
    params.everything = query;
  }

  try {
    const res = await axios.get(apiUrl(`/iam/organizations`), { params });
    const hasMore =
      res?.data?.meta?.currentPage + 1 < res?.data?.meta?.totalPages
        ? true
        : false;

    return {
      options: transformResult(res?.data?.response ?? []),
      hasMore: hasMore,
      additional: { page: page + 1 },
    };
  } catch (err) {
    console.error(err);
  }
}

export const ShipperOrganizationsAsyncSelect = ({ value, onChange }) => {
  const { t } = useTranslation("organizations");

  return (
    <AsyncPaginate
      name="async-select-shipper-orgs"
      placeholder={t("organizations:Select...")}
      loadingMessage={() => t("organizations:Loading...")}
      noOptionsMessage={() => t("organizations:No options")}
      isMulti={false}
      loadOptions={debouncePromise((query, loadedOptions, additional) => {
        return fetchShipperOrganizations(query, loadedOptions, additional);
      }, 500)}
      styles={customStyles}
      isClearable={false}
      value={value}
      onChange={onChange}
      additional={{
        page: 0,
      }}
    />
  );
};

ShipperOrganizationsAsyncSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};
