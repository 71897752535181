/** @jsxImportSource @emotion/react */
import { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SearchBarContainer from "./MetalTrackingLocationResultsSearchBarContainer";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { resultsTableColumns } from "../mt-dashboard/mt-dashboard-components/results-table-columns";
import DataHeader from "../mt-dashboard/mt-dashboard-components/DataHeader";
import MixpanelUtils from "../../trackers/mixpanel";

class MetalTrackingSearchResultsView extends Component {
  state = {
    // If the user adds a filter to a search, or edits it in any way, consider this
    // a new search which could be saved.
    searchParamsChanged: false,
    savedSearch: this.props.savedSearch,
    showDeleteSavedSearchModal: false,
  };

  constructor(props) {
    super(props);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentDidMount() {
    const { t, setTitle, savedSearch, loadSavedSearch } = this.props;

    setTitle(t("mt-location-results:Metal Rack Locations"));

    MixpanelUtils.track("Viewed Page: Metal / Search Results");

    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }
  }

  rowClickHandler(rowInfo) {
    this.props.pushRackDetailView(rowInfo.original.id);
  }

  render() {
    const {
      searchResults,
      isLoading,
      toggleWatchedRackFlag,
      page,
      pageSize,
      totalPages,
      setPagination,
      solutionId,
      rackLocation,
      t,
    } = this.props;
    const searchData = isLoading ? [] : searchResults;

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <div
          css={{
            backgroundColor: "#fff",
            padding: "0px 20px 20px 20px",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "20px 0 20px 0",
            }}
          >
            <div
              css={{
                flex: "0 0 40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              <DataHeader
                title={t("mt-location-results:Racks")}
                complement={t("mt-location-results:in") + " " + rackLocation}
                total={searchResults.length || 0}
              />
            </div>
            <div
              css={{
                display: "flex",
                flex: "0 0 60%",
                flexDirection: "column",
              }}
            >
              <SearchBarContainer isShowingAdvancedSearchButton={false} />
            </div>
          </div>
          <BaseTable
            data={searchData}
            columns={resultsTableColumns(t, solutionId, toggleWatchedRackFlag)}
            isLoading={isLoading}
            rowClickHandler={this.rowClickHandler}
            showPagination={true}
            isManualPagination={true}
            pageIndex={page}
            pageSize={pageSize}
            pageCount={totalPages}
            disableSortBy={true}
            fixPaginationToBottom={true}
            onPageChange={(newPage) => {
              setPagination(solutionId, newPage, pageSize);
            }}
            onPageSizeChange={(newPageSize) => {
              setPagination(solutionId, 0, newPageSize);
            }}
          />
        </div>
      </div>
    );
  }
}

MetalTrackingSearchResultsView.propTypes = {
  t: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  loadSavedSearch: PropTypes.func,
  savedSearch: PropTypes.object,
  searchResults: PropTypes.array,
  isLoading: PropTypes.bool,
  toggleWatchedRackFlag: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  setPagination: PropTypes.func,
  solutionId: PropTypes.string,
  rackLocation: PropTypes.string,
  pushRackDetailView: PropTypes.func,
};

export default withTranslation(["mt-location-results"])(
  MetalTrackingSearchResultsView,
);
