/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon, IconPropTypes } from "components/atoms/Icon.atom";

import { convertCountToLocaleString } from "utils/html-utils";
import { FlexDiv, FlexColDiv, FlexRowDiv } from "styles/container-elements";
import Colors from "styles/colors";

export const ExceptionCount = ({
  count,
  percentage,
  name,
  icon,
  clickHandler = null,
  style = {},
  showNumberSeparator = false,
}) => {
  return (
    <FlexRowDiv
      css={{
        justifyContent: "center",
        border: "1px solid white",
        borderRadius: "5px",
        margin: "0.5em",
        padding: "0.35em",
        ":hover": {
          border: `1px solid ${Colors.background.GRAY_BLUE}`,
          backgroundColor: "#fcfcfc",
          cursor: clickHandler ? "pointer" : "auto",
          boxShadow: `inset 0 0 1px ${Colors.background.GRAY_BLUE}`,
        },
        ...style,
      }}
      onClick={clickHandler}
      data-qa={`exception-count-${
        name ? name.replaceAll(/ +/g, "-").toLowerCase() : ""
      }`}
    >
      <FlexColDiv>
        <FlexDiv css={{ justifyContent: "center" }}>
          <span
            css={{
              marginRight: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {icon?.src ? (
              <Icon
                type={icon.type}
                src={icon.src}
                color={icon.color}
                altText={icon.altText}
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  ...icon.style,
                }}
              />
            ) : null}
          </span>
          <Text size={FontSize.size24} color={Colors.background.DARK_BLUE}>
            {showNumberSeparator ? convertCountToLocaleString(count) : count}
          </Text>

          {percentage || percentage === 0 ? (
            <Text
              size={FontSize.size16}
              color={Colors.text.GRAY}
              style={{ padding: "9px 0 0 10px" }}
            >
              {percentage ? `${percentage}%` : "0.0%"}
            </Text>
          ) : null}
        </FlexDiv>
        <Text block italic align="center" color={Colors.background.DARK_BLUE}>
          {name}
        </Text>
      </FlexColDiv>
    </FlexRowDiv>
  );
};

export const ExceptionCountPropTypes = {
  /** The backend ID of the exception. */
  id: PropTypes.number,
  /** Name to be displayed for this exception. */
  name: PropTypes.string,
  /** Total count for the exception. */
  count: PropTypes.number,
  /** Percentage to be displayed next to total count. */
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Data from FV exceptions that will be used in clickHandler. */
  reasonCode: PropTypes.string,
  /** Data from Shipment exceptions that wiil be used in clickHandler. */
  type: PropTypes.string,
  /** Icon data for the exception. */
  icon: PropTypes.exact(IconPropTypes),
  /** Tooltip message for the exception. */
  tooltipText: PropTypes.string,
  /** show comma separator for thousands */
  showNumberSeparator: PropTypes.bool,
};

ExceptionCount.propTypes = {
  ...ExceptionCountPropTypes,
  /** Handler for when the exception is clicked. */
  clickHandler: PropTypes.func,
  /** Style changes to the parent wrapping element for the exception. */
  style: PropTypes.object,
};
