/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text, FontSize } from "components/atoms/Text.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { TimeCell } from "components/organisms/base-table/Cell/TimeCell";

const useColumns = () => {
  const { t } = useTranslation("partview-details");

  const results = [
    {
      Header: t("partview-details:Order #"),
      accessor: "OrderNumber",
      Cell: (cell) => {
        return (
          <Text block size={FontSize.size12}>
            {cell.value}
          </Text>
        );
      },
    },
    {
      Header: t("partview-details:Timestamp"),
      accessor: "OrderTimestamp",
      Cell: (cell) => {
        return <TimeCell dateTime={cell.value} localize />;
      },
    },
  ];

  return results;
};

export const OrderDetailsTable = ({ orders = [] }) => {
  const columns = useColumns();
  return (
    <BaseTable
      data-qa="order-details-table"
      theme={Themes.LIGHT}
      columns={columns}
      data={orders}
      disablePagination
      defaultSortColumn="OrderTimestamp"
      defaultReverseSort={true}
    />
  );
};

OrderDetailsTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      OrderNumber: PropTypes.string,
      OrderTimestamp: PropTypes.string,
    }),
  ),
};
