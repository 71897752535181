import { connect } from "react-redux";

import { HealthcareDetails } from "./Healthcare.Details.page";
import HealthcareSearchBarState from "../redux/HealthcareSearchBarState";
import HealthcareState from "../redux/HealthcareState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import MapState from "modules/map/MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

function mapStateToProps(state) {
  const {
    getSearchValue,
    getSearchResults,
    getTotalEntities,
    getIsLoadingError,
    getIsLoading,
  } = HealthcareSearchBarState.selectors;
  const {
    getIsLoadingEntityDetails,
    getEntityDetails,
    getEntityDetailsError,
    getIsLoadingEntityPositionUpdates,
    getEntityPositionUpdates,
    getEntityPositionUpdatesError,
    getIsUpdatePositionUpdateIsLoading,
    getUpdatePositionUpdateError,
  } = HealthcareState.selectors;
  const { getMapCoordinate } = MapState.selectors;

  return {
    ...state.endToEnd,
    searchValue: getSearchValue(state),
    healthcareEntities: getSearchResults(state),
    totalEntities: getTotalEntities(state),
    isLoading: getIsLoading(state),
    isLoadingError: getIsLoadingError(state),
    solutionId: getSolutionId(state),
    selectedMapCoordinate: getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    isLoadingAssetDetails: getIsLoadingEntityDetails(state),
    assetDetails: getEntityDetails(state),
    assetDetailsError: getEntityDetailsError(state),
    isLoadingAssetCoordinates: getIsLoadingEntityPositionUpdates(state),
    assetCoordinates: getEntityPositionUpdates(state),
    assetCoordinatesError: getEntityPositionUpdatesError(state),
    isLoadingCoordinateUpdate: getIsUpdatePositionUpdateIsLoading(state),
    coordinateUpdateError: getUpdatePositionUpdateError(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { setPagination } = HealthcareSearchBarState.actionCreators;
  const { addCoordinate, clearCoordinatesByType } = MapState.actionCreators;
  const {
    fetchEntityDetails,
    fetchEntityPositionUpdates,
    updateEntityPositionUpdate,
    clearUpdateEntityPositionUpdateError,
    clearEntity,
  } = HealthcareState.actionCreators;
  return {
    setPagination: (solutionId) => {
      // Set the pageSize to 10,000 to ensure that all VINs are displayed on the map
      dispatch(setPagination(solutionId, 0, 1000, true));
    },
    pushVinDetailsScreen: (entityId) => {
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } });
    },
    addCoordinate: (
      coordinateType,
      lat,
      long,
      time,
      ind,
      data,
      floor,
      radius,
    ) =>
      dispatch(
        addCoordinate(
          coordinateType,
          lat,
          long,
          time,
          ind,
          data,
          floor,
          radius,
        ),
      ),
    clearCoordinatesByType,
    fetchAssetDetails: (solutionId, entityId) =>
      dispatch(fetchEntityDetails(solutionId, entityId)),
    fetchAssetPositionUpdates: (solutionId, entityId) =>
      dispatch(fetchEntityPositionUpdates(solutionId, entityId)),
    updateEntityPositionUpdate: (
      solutionId,
      entityId,
      positionUpdateId,
      data,
    ) =>
      dispatch(
        updateEntityPositionUpdate(
          solutionId,
          entityId,
          positionUpdateId,
          data,
        ),
      ),
    clearUpdateEntityPositionUpdateError: () =>
      dispatch(clearUpdateEntityPositionUpdateError()),
    clearAsset: () => dispatch(clearEntity()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareDetails);
