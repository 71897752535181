export enum SourceService {
  CONTAINER_TRACKING = "CONTAINER TRACKING",
  DRIVE_AWAY = "DRIVEAWAY",
  ENTITY = "ENTITY",
  PARTVIEW = "PARTVIEW",
  TRIP_PLAN = "TRIP PLAN",
  SHIPMENT = "SHIPMENT",
  VIN = "VIN",
  LOCATION = "LOCATION",
}
