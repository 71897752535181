import axios from "axios";
import moment from "moment";
import "moment-timezone";
import apiUrl from "../../api-url";
import { options as i18nOptions } from "i18n";
import { DATE_FORMAT_NAMES, TIME_FORMAT_NAMES } from "utils/date-time";
import { findSupportedLanguage } from "utils/language-utils";

const STORE_MOUNT_POINT = "profile";
const zone = moment.tz.guess();

// URLS
const USERS_URL = apiUrl(`/iam/users`);
const USER_PREFERENCES_URL = apiUrl(`/iam/v1/preference`);

// Actions
const CHANGE_PASSWORD = "profiles/CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCEEDED = "profiles/CHANGE_PASSWORD_SUCCEEDED";
const CHANGE_PASSWORD_FAILED = "profiles/CHANGE_PASSWORD_FAILED";
const RESET_PASSWORD_FORM = "profiles/";
const SET_USER_PREFERENCES = "profile/SET_USER_PREFERENCES";
const SET_USER_PREFERENCES_IS_LOADING =
  "profile/SET_USER_PREFERENCES_IS_LOADING";
const SET_USER_PREFERENCES_ERROR = "profile/SET_USER_PREFERENCES_ERROR";

// Action creators
export function changePassword(payload, user_id) {
  return async function (dispatch) {
    dispatch({ type: CHANGE_PASSWORD, payload });

    const url = `${USERS_URL}/${user_id}/change-password`;

    try {
      await axios.patch(url, payload);
      dispatch({ type: CHANGE_PASSWORD_SUCCEEDED });
    } catch (error) {
      return dispatch({ type: CHANGE_PASSWORD_FAILED, error });
    }
  };
}

export function resetPasswordForm() {
  return {
    type: RESET_PASSWORD_FORM,
  };
}

export function setDefaultUserPreference() {
  const supportedLanguage = findSupportedLanguage(i18nOptions.userLanguage);

  const dateformat =
    supportedLanguage === "en"
      ? DATE_FORMAT_NAMES.MONTH_DAY_YEAR
      : DATE_FORMAT_NAMES.DAY_MONTH_YEAR;

  const params = {
    locale: supportedLanguage,
    timezone: zone,
    timeformat: TIME_FORMAT_NAMES.TWENTY_FOUR_HOUR,
    dateformat: dateformat,
  };

  return async (dispatch) => {
    try {
      const resp = await axios.put(USER_PREFERENCES_URL, params);
      const results = resp?.data;
      dispatch({ type: SET_USER_PREFERENCES, payload: results });
    } catch (error) {
      // setting default when the API fails
      const fallbackPreferences = {
        ...params,
        dateformat: params.dateformat,
        timeformat: params.timeformat,
        units_of_measurement: {
          system: "Imperial",
          mass: "lb",
          pressure: "kpa",
          volume: "gal",
          distance: "mi",
          temperature: "F",
        },
      };

      dispatch({
        type: SET_USER_PREFERENCES,
        payload: fallbackPreferences,
      });
    }
  };
}

export function setPreferences(payload) {
  return async (dispatch) => {
    dispatch({ type: SET_USER_PREFERENCES_IS_LOADING });
    try {
      const resp = await axios.put(USER_PREFERENCES_URL, payload);
      const results = resp?.data;
      dispatch({ type: SET_USER_PREFERENCES, payload: results });
    } catch (error) {
      dispatch({ type: SET_USER_PREFERENCES_ERROR });
    }
  };
}

export function fetchUserPreferences() {
  return async (dispatch) => {
    dispatch({ type: SET_USER_PREFERENCES_IS_LOADING });
    try {
      const resp = await axios.get(USER_PREFERENCES_URL);
      const results = resp?.data;
      dispatch({ type: SET_USER_PREFERENCES, payload: results });
    } catch (error) {
      dispatch(setDefaultUserPreference());
    }
  };
}

// Initial state
export const initialState = {
  passwordStatus: null,
  selectedSavedSearch: {},
  isLoading: false,
  isLoadingError: false,
  userPreferences: {
    timezone: zone,
  },
};

function ProfileReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_PASSWORD_SUCCEEDED:
      return Object.assign({}, state, {
        passwordStatus: "CHANGED",
      });
    case CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        passwordStatus: "ERROR",
      });
    case RESET_PASSWORD_FORM:
      return Object.assign({}, state, {
        passwordStatus: null,
      });
    case SET_USER_PREFERENCES_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        isLoadingError: false,
      });
    case SET_USER_PREFERENCES:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingError: false,
        userPreferences: action.payload,
      });
    case SET_USER_PREFERENCES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingError: true,
      });
    default:
      return state;
  }
}

// Selectors
const getUserPreferencesIsLoading = (state) =>
  state[STORE_MOUNT_POINT].isLoading;
const getUserPreferencesIsLoadingError = (state) =>
  state[STORE_MOUNT_POINT].isLoadingError;
const getUserPreferences = (state) => state[STORE_MOUNT_POINT].userPreferences;

export const ProfileState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    changePassword,
    resetPasswordForm,
    fetchUserPreferences,
    setPreferences,
  },
  selectors: {
    getUserPreferencesIsLoading,
    getUserPreferencesIsLoadingError,
    getUserPreferences,
  },
  reducer: ProfileReducer,
};
