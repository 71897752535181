import React from "react";
import { useTranslation } from "react-i18next";
import { ActionCell } from "./components/ActionCell";
import { OrganizationType } from "shared/constants/organization.const";
import { useOrganizationsTranslation } from "shared/utils/organizations.utils";

export const useOrganizationSearchColumns = (
  selectedOrgTypeOption,
  actionHandler,
) => {
  const { t } = useTranslation("organizations");
  const { getTranslatedOrganizationType } = useOrganizationsTranslation();

  let orgType = selectedOrgTypeOption?.value;
  let orgTypeLabel = selectedOrgTypeOption?.label;

  let columns = [];

  columns.push({
    id: "org_name",
    Header: orgTypeLabel
      ? t("organizations:[[[organizationType]]] Name", {
          organizationType: orgTypeLabel,
        })
      : t("organizations:Name"),
    accessor: "org_name",
    minWidth: 200,
  });

  columns.push({
    Header: t("organizations:Organization Type"),
    accessor: "org_type",
    minWidth: 100,
    Cell: ({ value }) => getTranslatedOrganizationType(value),
  });

  columns.push({
    Header: t("organizations:FreightVerify ID"),
    accessor: "fv_id",
    minWidth: 100,
  });

  if (orgType === OrganizationType.CARRIER) {
    columns.push({
      Header: t("organizations:SCAC"),
      accessor: "scac",
      minWidth: 75,
    });
  }

  columns.push({
    Header: t("organizations:# of Users"),
    accessor: "no_of_user",
    minWidth: 100,
  });

  columns.push({
    Header: t("organizations:# of Locations"),
    accessor: "no_of_location",
    minWidth: 100,
  });

  columns.push({
    Header: t("users:Actions"),
    id: "user-actions",
    minWidth: 250,
    width: 250,
    disableSortBy: true,
    disableResizing: true,
    centerAligned: true,
    Cell: (cellProps) => {
      return <ActionCell {...cellProps} actionHandler={actionHandler} />;
    },
  });

  return columns;
};
