/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { DateTime, FontSize } from "components/atoms/DateTime.atom";

const ReceivedTimeCell = ({ coord }) => {
  return (
    <td>
      <DateTime
        stack
        plain
        localize
        dateTime={coord.db_time}
        size={FontSize.size12}
      >
        <DateTime.Time bold size={FontSize.size12} />
        <DateTime.Timezone bold size={FontSize.size12} />
      </DateTime>
    </td>
  );
};

ReceivedTimeCell.propTypes = {
  coord: PropTypes.object,
};

export default ReceivedTimeCell;
