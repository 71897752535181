import { connect } from "react-redux";

import DocumentationState from "./DocumentationState";
import { ApiGroupView } from "./ApiGroupView";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  const filename = state.location.payload.filename;

  return {
    filename: filename,
    apiGroup: DocumentationState.selectors.getApiGroup(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchApiGroup, pushDocumentView } = DocumentationState.actionCreators;

  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    fetchApiGroup: (filename) => dispatch(fetchApiGroup(filename)),
    pushDocumentView: (fileName, fileType, baseUrl) =>
      dispatch(pushDocumentView(fileName, fileType, baseUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiGroupView);
