/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { Text, FontSize } from "components/atoms/Text.atom";
import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import InventoryViewDashboardState from "../../redux/InventoryViewDashboardState";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import Colors from "styles/colors";

export const InvertoryViewWatchLocations = ({
  watchedLocations,
  watchedLocationsIsLoading,
  watchedLocationsPageIndex,
  watchedLocationsPageSize,
  watchedLocationsPageCount,
  fetchWatchedLocations,
  onSave,
  setWatchedLocationsPageIndex,
  saveWatchedLocations,
  watchLocationsError,
}) => {
  const { t } = useTranslation("inventory-view");
  const dispatch = useDispatch();

  const [unwatchedLocations, setUnwatchedLocations] = useState([]);
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  useEffect(() => {
    if (!_.isEmpty(unwatchedLocations)) {
      toggleWatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unwatchedLocations]);

  const toggleWatch = () => {
    const newUnWatchedLocations = [...unwatchedLocations];
    let savePromise = saveWatchedLocations([], newUnWatchedLocations);

    savePromise
      .then((response) => {
        if (response[0].status === 200) {
          setUnwatchedLocations([]);
          onSave();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LocationCell = (props) => {
    const { code, name, address, city, state, locationId } = props.value;
    const dim = unwatchedLocations.includes(locationId);
    return (
      <div css={{ opacity: cellOpacity(dim) }}>
        <Text block size={FontSize.size14}>
          {code}
        </Text>
        <Text block size={FontSize.size14}>
          {name}
        </Text>
        <LocationAddressComplex
          addressColor={Colors.text.BLACK}
          defaultTextColor={Colors.text.BLACK}
          address1={address}
          city={city}
          state={state}
        />
      </div>
    );
  };

  const watchTableColumns = [
    {
      Header: t("inventory-view:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const locationId = cellInfo.row.original.locationId;
        const dim = unwatchedLocations.includes(locationId);
        // API returns boolean in a string
        return (
          <div css={{ opacity: cellOpacity(dim) }}>
            <WatchToggle
              key={locationId}
              checked={true}
              onChange={() => {
                setUnwatchedLocations([...unwatchedLocations, locationId]);
                toggleWatch();
              }}
              iconSize={FontSize.size24}
              color={Colors.nav.NAVY}
              checkedColor={Colors.highlight.YELLOW}
            />
          </div>
        );
      },
    },
    {
      Header: t("inventory-view:Location"),
      accessor: (obj) => {
        return {
          locationId: obj?.locationId ?? "",
          code: obj?.code ?? "",
          name: obj?.name ?? "",
          address: obj?.address ?? "",
          city: obj?.city ?? "",
          state: obj?.state ?? "",
        };
      },
      disableSortBy: true,
      centerAligned: false,
      Cell: LocationCell,
    },
    {
      Header: t("inventory-view:Capacity"),
      accessor: "capacity",
      disableSortBy: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const capacity = cellInfo.row.original.capacity;
        const locationId = cellInfo.row.original.locationId;
        const dim = unwatchedLocations.includes(locationId);
        return (
          <div css={{ opacity: cellOpacity(dim) }}>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Total Allocated")}: ${
                capacity.totalAllocated
              }`}
            </Text>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Onsite")}: ${capacity.onSite}`}
            </Text>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Available")}: ${capacity.available}`}
            </Text>
            <Text size={FontSize.size14}>
              {`${t("inventory-view:Status")}: `}
            </Text>
            <Text
              size={FontSize.size14}
              color={
                capacity.totalAllocated < capacity.onSite
                  ? Colors.highlight.RED
                  : Colors.highlight.GREEN
              }
            >
              {capacity.totalAllocated < capacity.onSite
                ? `${t("inventory-view:Over Capacity")}`
                : `${t("inventory-view:Under Capacity")}`}
            </Text>
          </div>
        );
      },
    },
    {
      Header: t("inventory-view:Dwell"),
      accessor: "dwell",
      disableSortBy: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dwell = cellInfo.row.original.dwell;
        const locationId = cellInfo.row.original.locationId;
        const dim = unwatchedLocations.includes(locationId);
        return (
          <div css={{ opacity: cellOpacity(dim) }}>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Onsite")}: ${dwell.onSite}`}
            </Text>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Dwelling")}: ${dwell.dwelling}`}
            </Text>
            <Text block size={FontSize.size14}>
              {`${t("inventory-view:Aging")}: ${dwell.aging}`}
            </Text>
          </div>
        );
      },
    },
    {
      Header: t("inventory-view:Non-Shippable Count"),
      accessor: "nonShippableCount",
      disableSortBy: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const nonShippableCount = cellInfo.row.original.nonShippable ?? 0;
        const locationId = cellInfo.row.original.locationId;
        const dim = unwatchedLocations.includes(locationId);
        return (
          <div css={{ opacity: cellOpacity(dim) }}>
            <Text block size={FontSize.size14}>
              {nonShippableCount}
            </Text>
          </div>
        );
      },
    },
  ];

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }
    // Navigate to Container Details when clicking row
    dispatch({
      type: "INVENTORY_VIEW_DETAILS",
      payload: { location_id: row.original.locationId },
    });
  };

  const getMessage = () => {
    if (_.isEmpty(watchLocationsError)) {
      return t("inventory-view:Please select your locations");
    } else {
      if (
        watchLocationsError.response &&
        watchLocationsError.response.status === "408"
      ) {
        return t(
          "inventory-view:The network call timed out, please give it a few seconds and try again.",
        );
      }
      return t(`inventory-view:${watchLocationsError.message}`);
    }
  };

  return (
    <PanelGroup style={{ marginTop: "1em" }}>
      <PanelGroup.Header title={t("inventory-view:My Watched Locations")} />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={watchedLocations}
          noDataFoundMessage={getMessage()}
          columns={watchTableColumns}
          theme={Themes.LIGHT}
          isLoading={watchedLocationsIsLoading}
          rowClickHandler={rowClickHandler}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          pageIndex={watchedLocationsPageIndex}
          pageSize={watchedLocationsPageSize}
          pageCount={watchedLocationsPageCount}
          onPageChange={(newPage) => {
            setWatchedLocationsPageIndex(newPage);
            fetchWatchedLocations();
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

InvertoryViewWatchLocations.propTypes = {
  watchedLocations: PropTypes.array,
  watchedLocationsIsLoading: PropTypes.bool,
  watchedLocationsPageIndex: PropTypes.number,
  watchedLocationsPageSize: PropTypes.number,
  watchedLocationsPageCount: PropTypes.number,
  solutionId: PropTypes.string,
  fetchWatchedLocations: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  setWatchedLocationsPageIndex: PropTypes.func.isRequired,
  watchLocationsError: PropTypes.object,
  saveWatchedLocations: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    getWatchedLocationsPageIndex,
    getWatchedLocationsPageSize,
    getWatchedLocationsPageCount,
    getWatchedLocationsError,
  } = InventoryViewDashboardState.selectors;

  return {
    solutionId: getSolutionId(state),
    watchedLocationsPageIndex: getWatchedLocationsPageIndex(state),
    watchedLocationsPageSize: getWatchedLocationsPageSize(state),
    watchedLocationsPageCount: getWatchedLocationsPageCount(state),
    watchLocationsError: getWatchedLocationsError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWatchedLocationsPageIndex: (pageIndex) =>
      dispatch(
        InventoryViewDashboardState.actionCreators.setWatchedLocationsPageIndex(
          pageIndex,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvertoryViewWatchLocations);
