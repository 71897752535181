/** @jsxImportSource @emotion/react */
import { FlexColDiv } from "styles/container-elements";
import { SelectInput } from "components-old/modal-elems";

type ValueType = { label: string; value: string };

type GrantedFeaturesSelectProps = {
  label: string;
  placeholder?: string;
  grantedFeaturesOptions: Array<ValueType>;
  value?: ValueType;
  changeHandler: (value: ValueType) => void;
  disabled?: boolean;
};

export const GrantedFeaturesSelect = ({
  label,
  placeholder,
  grantedFeaturesOptions = [],
  value,
  changeHandler,
  disabled,
}: GrantedFeaturesSelectProps) => {
  return (
    <FlexColDiv
      css={{
        marginTop: "1em",
        gap: "0.5em",
      }}
    >
      <SelectInput
        label={label}
        placeholder={placeholder}
        options={grantedFeaturesOptions}
        value={value}
        changeHandler={changeHandler}
        style={{ width: "100%" }}
        prop="grantedFeatures"
        disabled={disabled}
        multi={true}
      />
    </FlexColDiv>
  );
};
