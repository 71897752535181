/** @jsxImportSource @emotion/react */
import debouncePromise from "debounce-promise";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import apiUrl from "../../../api-url";
import axios from "axios";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: "5px",
    borderWidth: "2px",
    borderColor: "#ced4da",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#DEEBFF",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#2684ff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#2684ff",
    ":hover": {
      backgroundColor: "#B2D4FF",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const asyncBrandingOptions = debouncePromise(async (shipperOrgId) => {
  try {
    const response = await axios.get(
      apiUrl(`/iam/organizations/${shipperOrgId}/branding`),
    );
    return {
      options: response.data,
    };
  } catch (err) {
    console.error(err);
    return { options: [] };
  }
}, 500);

export const BrandingOptionSelect = ({ value, onChange, selectedShipper }) => {
  const { t } = useTranslation(["organizations"]);

  return (
    <AsyncPaginate
      name="async-select-branding-option"
      isMulti={false}
      loadOptions={() => {
        return asyncBrandingOptions(selectedShipper);
      }}
      placeholder={t(`organizations:Search branding`)}
      styles={customStyles}
      value={value}
      onChange={onChange}
      cacheUniqs={[selectedShipper]} // to clear cached options
    />
  );
};

BrandingOptionSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.number,
};
