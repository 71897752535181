/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { FlexDiv } from "../../styles/container-elements";
import ContentSectionOverlay from "./ContentSectionOverlay";

const ContentSection = (props) => {
  const { children, isMobile, isShowingOverlay } = props;
  return (
    <FlexDiv
      css={{
        flex: 1,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        // For ContentSectionOverlay to position itself absolutely
        position: "relative",
        // Prevent overflow in this container to allow children to handle overflow
        overflow: "hidden",
      }}
    >
      {isShowingOverlay ? <ContentSectionOverlay /> : null}
      {children}
    </FlexDiv>
  );
};

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isShowingOverlay: PropTypes.bool.isRequired,
};

export default ContentSection;
