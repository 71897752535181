/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { faBell as faBellRegular } from "@fortawesome/pro-regular-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";

import { Checkbox } from "components/atoms/Checkbox.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
import { isShipper, isCarrier } from "shared/utils/organizations.utils";
import Colors from "styles/colors";

export const AlertMeToggle = ({
  checked,
  subscribeeDetails,
  displayedId,
  subscriptionState,
  onModalOpen,
  onModalHide,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("components");

  const [showAlertMeModal, setShowAlertMeModal] = useState(false);

  const isShipmentSubscriptionLoading = useSelector(
    subscriptionState.selectors.getIsSubscriptionLoading,
  );
  const shipmentSubscription = useSelector(
    subscriptionState.selectors.getSubscription,
  );
  const subscriptionRequestError = useSelector(
    subscriptionState.selectors.getSubscriptionRequestError,
  );
  const isSubscriptionUpdating = useSelector(
    subscriptionState.selectors.getIsSubscriptionUpdating,
  );
  const subscriptionUpdateSuccess = useSelector(
    subscriptionState.selectors.getSubscriptionUpdateSuccess,
  );
  const subscriptionUpdateError = useSelector(
    subscriptionState.selectors.getSubscriptionUpdateError,
  );

  const activeOrganization = useSelector(getActiveOrganization);
  const enableAlertMeAction =
    subscriptionState &&
    (isCarrier(activeOrganization) || isShipper(activeOrganization));

  if (!enableAlertMeAction) {
    return null;
  }

  const onOpen = () => {
    if (onModalOpen) {
      onModalOpen();
    }

    setShowAlertMeModal(true);
  };

  const onHide = () => {
    if (onModalHide) {
      onModalHide();
    }

    setShowAlertMeModal(false);
  };

  const onChange = (e) => {
    dispatch(
      subscriptionState.actionCreators.fetchSubscription(subscribeeDetails),
    );

    onOpen();
  };

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipChildren={<Text>{t("components:Alert Me")}</Text>}
        showDelayInMs={500}
      >
        <Checkbox
          checked={checked}
          onChange={onChange}
          icon={faBellRegular}
          checkedIcon={faBellSolid}
          iconSize={FontSize.size24}
          color={Colors.nav.NAVY}
          checkedColor={Colors.highlight.GREEN}
          css={{ marginRight: 0 }}
        />
      </Tooltip>

      <AlertMeModal
        show={showAlertMeModal}
        hide={onHide}
        subscribeeDetails={subscribeeDetails}
        displayedId={displayedId}
        isSubscriptionLoading={isShipmentSubscriptionLoading}
        subscriptionDetails={shipmentSubscription}
        subscriptionRequestError={subscriptionRequestError}
        subscribe={(...params) =>
          dispatch(subscriptionState.actionCreators.subscribe(...params))
        }
        updateSubscription={(...params) =>
          dispatch(
            subscriptionState.actionCreators.updateSubscription(...params),
          )
        }
        unsubscribe={(...params) =>
          dispatch(subscriptionState.actionCreators.unsubscribe(...params))
        }
        isSubscriptionUpdating={isSubscriptionUpdating}
        subscriptionUpdateSuccess={subscriptionUpdateSuccess}
        subscriptionUpdateError={subscriptionUpdateError}
      />
    </React.Fragment>
  );
};

AlertMeToggle.propTypes = {
  checked: PropTypes.bool,
  subscribeeDetails: PropTypes.object,
  displayedId: PropTypes.string,
  subscriptionState: PropTypes.shape({
    actionCreators: PropTypes.shape({
      fetchSubscription: PropTypes.func,
      subscribe: PropTypes.func,
      updateSubscription: PropTypes.func,
      unsubscribe: PropTypes.func,
    }),
    selectors: PropTypes.shape({
      getSubscription: PropTypes.func,
      getIsSubscriptionLoading: PropTypes.func,
      getSubscriptionRequestError: PropTypes.func,
      getIsSubscriptionUpdating: PropTypes.func,
      getSubscriptionUpdateSuccess: PropTypes.func,
      getSubscriptionUpdateError: PropTypes.func,
    }),
  }),
  onModalOpen: PropTypes.func,
  onModalHide: PropTypes.func,
};
